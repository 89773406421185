import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactHooks from "@apollo/react-hooks";
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _text: any;
  bigint: any;
  date: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type AcModel = {
  __typename?: "ACModel";
  brandName: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  identifier: Scalars["String"];
  modelName: Scalars["String"];
  status: GenericStatus;
};

export type AcModelFilterInput = {
  brandName?: Maybe<Scalars["String"]>;
  modelName?: Maybe<Scalars["String"]>;
};

export type AcModelInput = {
  brandName: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  identifier: Scalars["String"];
  modelName: Scalars["String"];
};

export type AcModelSortInput = {
  brandName?: Maybe<SortOptions>;
  modelName?: Maybe<SortOptions>;
};

export type AcModelsResponse = {
  __typename?: "ACModelsResponse";
  ACModels: Array<AcModel>;
  pagination: GenericPagination;
};

export type ActivateComfortPlusInput = {
  checkInAt: Scalars["String"];
  checkOutAt: Scalars["String"];
  emails: Array<Scalars["String"]>;
  keyId: Scalars["String"];
};

export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars["bigint"]>;
  _gt?: Maybe<Scalars["bigint"]>;
  _gte?: Maybe<Scalars["bigint"]>;
  _in?: Maybe<Array<Scalars["bigint"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["bigint"]>;
  _lte?: Maybe<Scalars["bigint"]>;
  _neq?: Maybe<Scalars["bigint"]>;
  _nin?: Maybe<Array<Scalars["bigint"]>>;
};

export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
};

export type CancelPrecoolCommand = {
  __typename?: "CancelPrecoolCommand";
  canceledCommands?: Maybe<Array<PrecoolCommand>>;
  uncancelableRooms?: Maybe<Array<UncancelablePrecoolRooms>>;
};

export type ChangeBatteryReponse = {
  __typename?: "ChangeBatteryReponse";
  creationDate?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export type Compressor = {
  __typename?: "Compressor";
  compressorId: Scalars["ID"];
  compressorName: Scalars["String"];
};

export type CreateRmaAssetInput = {
  assetUri: Scalars["String"];
  type: Scalars["String"];
};

export type CreateRmaRequestInput = {
  assets: Array<CreateRmaAssetInput>;
  comment?: Maybe<Scalars["String"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["ID"]>;
  nodeId?: Maybe<Scalars["ID"]>;
  positionId: Scalars["String"];
  rootCauseId: Scalars["Int"];
};

export enum Cursor_Ordering {
  Asc = "ASC",
  Desc = "DESC",
}

export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars["date"]>;
  _gt?: Maybe<Scalars["date"]>;
  _gte?: Maybe<Scalars["date"]>;
  _in?: Maybe<Array<Scalars["date"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["date"]>;
  _lte?: Maybe<Scalars["date"]>;
  _neq?: Maybe<Scalars["date"]>;
  _nin?: Maybe<Array<Scalars["date"]>>;
};

export type EmbeddedAnalyticsFilter = {
  column: Scalars["String"];
  operator: EmbeddedAnalyticsFilterOperator;
  values?: Maybe<Array<Scalars["String"]>>;
};

export enum EmbeddedAnalyticsFilterOperator {
  Equals = "EQUALS",
  GreaterThan = "GREATER_THAN",
  GreatThanEqualsTo = "GREAT_THAN_EQUALS_TO",
  In = "IN",
  LessThan = "LESS_THAN",
  LessThanEqualsTo = "LESS_THAN_EQUALS_TO",
  NotEquals = "NOT_EQUALS",
  NotIn = "NOT_IN",
}

export type EmbeddedAnalyticsInfo = {
  __typename?: "EmbeddedAnalyticsInfo";
  token: Scalars["String"];
  url: Scalars["String"];
};

export type EmbeddedAnalyticsInfoInput = {
  filters?: Maybe<Array<EmbeddedAnalyticsFilter>>;
  type: EmbeddedAnalyticsType;
};

export enum EmbeddedAnalyticsType {
  Card = "CARD",
  Dashboard = "DASHBOARD",
}

export type EmonMapInputInfo = {
  __typename?: "EmonMapInputInfo";
  currentMapInput?: Maybe<Scalars["Int"]>;
  unavailableMapInputs: Array<Scalars["Int"]>;
};

export type FilterOption = {
  __typename?: "FilterOption";
  ceilingValue?: Maybe<Scalars["Float"]>;
  count: Scalars["Int"];
  equalValue?: Maybe<Scalars["Float"]>;
  field: Scalars["String"];
  floorValue?: Maybe<Scalars["Float"]>;
  groupName?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  options?: Maybe<Array<Scalars["String"]>>;
  type?: Maybe<FilterOptionType>;
  value: Scalars["String"];
};

export enum FilterOptionType {
  Checkbox = "Checkbox",
  FloorCeilingValue = "FloorCeilingValue",
  InputValue = "InputValue",
  Select = "Select",
  TimePicker = "TimePicker",
}

export type Float_Comparison_Exp = {
  _eq?: Maybe<Scalars["Float"]>;
  _gt?: Maybe<Scalars["Float"]>;
  _gte?: Maybe<Scalars["Float"]>;
  _in?: Maybe<Array<Scalars["Float"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Float"]>;
  _lte?: Maybe<Scalars["Float"]>;
  _neq?: Maybe<Scalars["Float"]>;
  _nin?: Maybe<Array<Scalars["Float"]>>;
};

export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars["float8"]>;
  _gt?: Maybe<Scalars["float8"]>;
  _gte?: Maybe<Scalars["float8"]>;
  _in?: Maybe<Array<Scalars["float8"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["float8"]>;
  _lte?: Maybe<Scalars["float8"]>;
  _neq?: Maybe<Scalars["float8"]>;
  _nin?: Maybe<Array<Scalars["float8"]>>;
};

export type Gateway = {
  __typename?: "Gateway";
  error?: Maybe<GatewayHealthError>;
  gatewayId: Scalars["ID"];
  gatewayMac: Scalars["Int"];
  gatewayName: Scalars["String"];
  hardwareType?: Maybe<GatewayHardwareType>;
  healthStatus?: Maybe<GatewayHealthStatus>;
  locationId: Scalars["String"];
};

export enum GatewayHardwareType {
  MultiAsSingleChannel = "multi_as_single_channel",
  MultiChannel = "multi_channel",
  SingleAsMultiChannel = "single_as_multi_channel",
  SingleChannel = "single_channel",
}

export enum GatewayHealthError {
  ErrorInitialisingLora = "ERROR_INITIALISING_LORA",
  UnknownError = "UNKNOWN_ERROR",
}

export enum GatewayHealthStatus {
  Error = "ERROR",
  ErrorProvisioningImage = "ERROR_PROVISIONING_IMAGE",
  Healthy = "HEALTHY",
  LoraError = "LORA_ERROR",
  NotBooted = "NOT_BOOTED",
  Offline = "OFFLINE",
  Online = "ONLINE",
  PreparingImage = "PREPARING_IMAGE",
  Verifying = "VERIFYING",
}

export type GatewaysCreateInput = {
  gatewayName: Scalars["String"];
  locationId: Scalars["String"];
};

export type GenerateRmaAssetUploadLinkResponse = {
  __typename?: "GenerateRMAAssetUploadLinkResponse";
  assetUri: Scalars["String"];
  expiredInSecs: Scalars["Float"];
  url: Scalars["String"];
};

export type GenericPagination = {
  __typename?: "GenericPagination";
  count?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type GenericPaginationInput = {
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type GenericResponse = {
  __typename?: "GenericResponse";
  message?: Maybe<Scalars["String"]>;
};

export enum GenericStatus {
  Active = "ACTIVE",
  Disabled = "DISABLED",
}

export type GetRmaAssetDownloadLinkResponse = {
  __typename?: "GetRMAAssetDownloadLinkResponse";
  expiredInSecs: Scalars["Float"];
  url: Scalars["String"];
};

export type Group = {
  __typename?: "Group";
  groupId: Scalars["ID"];
  groupName: Scalars["String"];
  groupType: Scalars["String"];
};

export type GuestPrecoolScheduleInput = {
  endedAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  startedAt?: Maybe<Scalars["String"]>;
  time: Scalars["Int"];
  type: Scalars["String"];
  weekDay?: Maybe<Scalars["String"]>;
};

export type Infrastructure = {
  __typename?: "Infrastructure";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Int_Array_Comparison_Exp = {
  _contained_in?: Maybe<Array<Scalars["Int"]>>;
  _contains?: Maybe<Array<Scalars["Int"]>>;
  _eq?: Maybe<Array<Scalars["Int"]>>;
  _gt?: Maybe<Array<Scalars["Int"]>>;
  _gte?: Maybe<Array<Scalars["Int"]>>;
  _in?: Maybe<Array<Array<Scalars["Int"]>>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Array<Scalars["Int"]>>;
  _lte?: Maybe<Array<Scalars["Int"]>>;
  _neq?: Maybe<Array<Scalars["Int"]>>;
  _nin?: Maybe<Array<Array<Scalars["Int"]>>>;
};

export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
};

export type Json_Array_Comparison_Exp = {
  _contained_in?: Maybe<Array<Scalars["json"]>>;
  _contains?: Maybe<Array<Scalars["json"]>>;
  _eq?: Maybe<Array<Scalars["json"]>>;
  _gt?: Maybe<Array<Scalars["json"]>>;
  _gte?: Maybe<Array<Scalars["json"]>>;
  _in?: Maybe<Array<Array<Scalars["json"]>>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Array<Scalars["json"]>>;
  _lte?: Maybe<Array<Scalars["json"]>>;
  _neq?: Maybe<Array<Scalars["json"]>>;
  _nin?: Maybe<Array<Array<Scalars["json"]>>>;
};

export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars["json"]>;
  _gt?: Maybe<Scalars["json"]>;
  _gte?: Maybe<Scalars["json"]>;
  _in?: Maybe<Array<Scalars["json"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["json"]>;
  _lte?: Maybe<Scalars["json"]>;
  _neq?: Maybe<Scalars["json"]>;
  _nin?: Maybe<Array<Scalars["json"]>>;
};

export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  _contained_in?: Maybe<Scalars["jsonb"]>;
  _contains?: Maybe<Scalars["jsonb"]>;
  _eq?: Maybe<Scalars["jsonb"]>;
  _gt?: Maybe<Scalars["jsonb"]>;
  _gte?: Maybe<Scalars["jsonb"]>;
  _has_key?: Maybe<Scalars["String"]>;
  _has_keys_all?: Maybe<Array<Scalars["String"]>>;
  _has_keys_any?: Maybe<Array<Scalars["String"]>>;
  _in?: Maybe<Array<Scalars["jsonb"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["jsonb"]>;
  _lte?: Maybe<Scalars["jsonb"]>;
  _neq?: Maybe<Scalars["jsonb"]>;
  _nin?: Maybe<Array<Scalars["jsonb"]>>;
};

export type Key = {
  __typename?: "Key";
  categoryName: Scalars["String"];
  currentRapidMappingRoom?: Maybe<Room>;
  group?: Maybe<Group>;
  keyId: Scalars["ID"];
  keyName: Scalars["String"];
  keyStats: KeyMappingStats;
  locationId: Scalars["String"];
  nextRapidMappingRoom?: Maybe<Room>;
  possitionType: Scalars["String"];
  rooms: Array<Room>;
  skippedRapidMappingSlot?: Maybe<Slot>;
  taskDueDate?: Maybe<Scalars["String"]>;
};

export type KeyDetailsFilterInput = {
  mappingStatus?: Maybe<MappingStatus>;
};

export type KeyEntries = {
  __typename?: "keyEntries";
  automaticTestRun: Scalars["Boolean"];
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["timestamptz"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  isMeterPosition: Scalars["Boolean"];
  isResumedEntry: Scalars["Boolean"];
  keyEntryId: Scalars["Int"];
  keyPositionId: Scalars["String"];
  lastNotifyAt?: Maybe<Scalars["timestamptz"]>;
  position?: Maybe<Sensorflow_Positions>;
  positionFunction: Scalars["String"];
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  test_runs: Array<TestRuns>;
  test_runs_aggregate: TestRuns_Aggregate;
  user?: Maybe<Sensorflow_Users>;
  userId?: Maybe<Scalars["String"]>;
};

export type KeyEntriesTest_RunsArgs = {
  distinct_on?: Maybe<Array<TestRuns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TestRuns_Order_By>>;
  where?: Maybe<TestRuns_Bool_Exp>;
};

export type KeyEntriesTest_Runs_AggregateArgs = {
  distinct_on?: Maybe<Array<TestRuns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TestRuns_Order_By>>;
  where?: Maybe<TestRuns_Bool_Exp>;
};

export type KeyEntries_Aggregate = {
  __typename?: "keyEntries_aggregate";
  aggregate?: Maybe<KeyEntries_Aggregate_Fields>;
  nodes: Array<KeyEntries>;
};

export type KeyEntries_Aggregate_Fields = {
  __typename?: "keyEntries_aggregate_fields";
  avg?: Maybe<KeyEntries_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<KeyEntries_Max_Fields>;
  min?: Maybe<KeyEntries_Min_Fields>;
  stddev?: Maybe<KeyEntries_Stddev_Fields>;
  stddev_pop?: Maybe<KeyEntries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<KeyEntries_Stddev_Samp_Fields>;
  sum?: Maybe<KeyEntries_Sum_Fields>;
  var_pop?: Maybe<KeyEntries_Var_Pop_Fields>;
  var_samp?: Maybe<KeyEntries_Var_Samp_Fields>;
  variance?: Maybe<KeyEntries_Variance_Fields>;
};

export type KeyEntries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<KeyEntries_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type KeyEntries_Avg_Fields = {
  __typename?: "keyEntries_avg_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type KeyEntries_Bool_Exp = {
  _and?: Maybe<Array<KeyEntries_Bool_Exp>>;
  _not?: Maybe<KeyEntries_Bool_Exp>;
  _or?: Maybe<Array<KeyEntries_Bool_Exp>>;
  automaticTestRun?: Maybe<Boolean_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  commentStatus?: Maybe<String_Comparison_Exp>;
  endedAt?: Maybe<Timestamptz_Comparison_Exp>;
  expiredAt?: Maybe<Timestamptz_Comparison_Exp>;
  isMeterPosition?: Maybe<Boolean_Comparison_Exp>;
  isResumedEntry?: Maybe<Boolean_Comparison_Exp>;
  keyEntryId?: Maybe<Int_Comparison_Exp>;
  keyPositionId?: Maybe<String_Comparison_Exp>;
  lastNotifyAt?: Maybe<Timestamptz_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionFunction?: Maybe<String_Comparison_Exp>;
  startedAt?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  test_runs?: Maybe<TestRuns_Bool_Exp>;
  test_runs_aggregate?: Maybe<TestRuns_Aggregate_Bool_Exp>;
  user?: Maybe<Sensorflow_Users_Bool_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

export enum KeyEntries_Constraint {
  KeyEntriesPkey = "key_entries_pkey",
  UniqueKeyPositionId = "unique_key_position_id",
}

export type KeyEntries_Inc_Input = {
  keyEntryId?: Maybe<Scalars["Int"]>;
};

export type KeyEntries_Insert_Input = {
  automaticTestRun?: Maybe<Scalars["Boolean"]>;
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["timestamptz"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
  isResumedEntry?: Maybe<Scalars["Boolean"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyPositionId?: Maybe<Scalars["String"]>;
  lastNotifyAt?: Maybe<Scalars["timestamptz"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionFunction?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  test_runs?: Maybe<TestRuns_Arr_Rel_Insert_Input>;
  user?: Maybe<Sensorflow_Users_Obj_Rel_Insert_Input>;
  userId?: Maybe<Scalars["String"]>;
};

export type KeyEntries_Max_Fields = {
  __typename?: "keyEntries_max_fields";
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["timestamptz"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyPositionId?: Maybe<Scalars["String"]>;
  lastNotifyAt?: Maybe<Scalars["timestamptz"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type KeyEntries_Min_Fields = {
  __typename?: "keyEntries_min_fields";
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["timestamptz"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyPositionId?: Maybe<Scalars["String"]>;
  lastNotifyAt?: Maybe<Scalars["timestamptz"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type KeyEntries_Mutation_Response = {
  __typename?: "keyEntries_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<KeyEntries>;
};

export type KeyEntries_Obj_Rel_Insert_Input = {
  data: KeyEntries_Insert_Input;
  on_conflict?: Maybe<KeyEntries_On_Conflict>;
};

export type KeyEntries_On_Conflict = {
  constraint: KeyEntries_Constraint;
  update_columns: Array<KeyEntries_Update_Column>;
  where?: Maybe<KeyEntries_Bool_Exp>;
};

export type KeyEntries_Order_By = {
  automaticTestRun?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  commentStatus?: Maybe<Order_By>;
  endedAt?: Maybe<Order_By>;
  expiredAt?: Maybe<Order_By>;
  isMeterPosition?: Maybe<Order_By>;
  isResumedEntry?: Maybe<Order_By>;
  keyEntryId?: Maybe<Order_By>;
  keyPositionId?: Maybe<Order_By>;
  lastNotifyAt?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionFunction?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  test_runs_aggregate?: Maybe<TestRuns_Aggregate_Order_By>;
  user?: Maybe<Sensorflow_Users_Order_By>;
  userId?: Maybe<Order_By>;
};

export type KeyEntries_Pk_Columns_Input = {
  keyEntryId: Scalars["Int"];
};

export enum KeyEntries_Select_Column {
  AutomaticTestRun = "automaticTestRun",
  Comment = "comment",
  CommentStatus = "commentStatus",
  EndedAt = "endedAt",
  ExpiredAt = "expiredAt",
  IsMeterPosition = "isMeterPosition",
  IsResumedEntry = "isResumedEntry",
  KeyEntryId = "keyEntryId",
  KeyPositionId = "keyPositionId",
  LastNotifyAt = "lastNotifyAt",
  PositionFunction = "positionFunction",
  StartedAt = "startedAt",
  Status = "status",
  UserId = "userId",
}

export type KeyEntries_Set_Input = {
  automaticTestRun?: Maybe<Scalars["Boolean"]>;
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["timestamptz"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
  isResumedEntry?: Maybe<Scalars["Boolean"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyPositionId?: Maybe<Scalars["String"]>;
  lastNotifyAt?: Maybe<Scalars["timestamptz"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type KeyEntries_Stddev_Fields = {
  __typename?: "keyEntries_stddev_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type KeyEntries_Stddev_Pop_Fields = {
  __typename?: "keyEntries_stddev_pop_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type KeyEntries_Stddev_Samp_Fields = {
  __typename?: "keyEntries_stddev_samp_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type KeyEntries_Stream_Cursor_Input = {
  initial_value: KeyEntries_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type KeyEntries_Stream_Cursor_Value_Input = {
  automaticTestRun?: Maybe<Scalars["Boolean"]>;
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["timestamptz"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
  isResumedEntry?: Maybe<Scalars["Boolean"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyPositionId?: Maybe<Scalars["String"]>;
  lastNotifyAt?: Maybe<Scalars["timestamptz"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type KeyEntries_Sum_Fields = {
  __typename?: "keyEntries_sum_fields";
  keyEntryId?: Maybe<Scalars["Int"]>;
};

export enum KeyEntries_Update_Column {
  AutomaticTestRun = "automaticTestRun",
  Comment = "comment",
  CommentStatus = "commentStatus",
  EndedAt = "endedAt",
  ExpiredAt = "expiredAt",
  IsMeterPosition = "isMeterPosition",
  IsResumedEntry = "isResumedEntry",
  KeyEntryId = "keyEntryId",
  KeyPositionId = "keyPositionId",
  LastNotifyAt = "lastNotifyAt",
  PositionFunction = "positionFunction",
  StartedAt = "startedAt",
  Status = "status",
  UserId = "userId",
}

export type KeyEntries_Updates = {
  _inc?: Maybe<KeyEntries_Inc_Input>;
  _set?: Maybe<KeyEntries_Set_Input>;
  where: KeyEntries_Bool_Exp;
};

export type KeyEntries_Var_Pop_Fields = {
  __typename?: "keyEntries_var_pop_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type KeyEntries_Var_Samp_Fields = {
  __typename?: "keyEntries_var_samp_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type KeyEntries_Variance_Fields = {
  __typename?: "keyEntries_variance_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type KeyEntry = {
  __typename?: "KeyEntry";
  endedAt?: Maybe<Scalars["String"]>;
  isResumedEntry: Scalars["Boolean"];
  keyEntryId: Scalars["Int"];
  keyPositionId: Scalars["ID"];
  lastNotifyAt?: Maybe<Scalars["String"]>;
  positionFunction: Scalars["String"];
  startedAt?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["ID"]>;
};

export type KeyFilterInput = {
  categoryName?: Maybe<Array<Scalars["String"]>>;
  groupIds?: Maybe<Array<Scalars["String"]>>;
  keyIds?: Maybe<Array<Scalars["ID"]>>;
  keyName?: Maybe<Scalars["String"]>;
  locationId: Scalars["String"];
  mappingStatus?: Maybe<Array<MappingStatus>>;
};

export type KeyMappingStats = {
  __typename?: "KeyMappingStats";
  keyId: Scalars["ID"];
  mappingStatus: MappingStatus;
  nodeHealthStats: NodeHealthStats;
  nodesStats: MappingStats;
  roomsStats: MappingStats;
  tasksStats: TasksStats;
};

export type KeySortInput = {
  categoryName?: Maybe<SortOptions>;
  groupName?: Maybe<SortOptions>;
  keyName?: Maybe<SortOptions>;
  mappingStatus?: Maybe<SortOptions>;
};

export type KeysResponse = {
  __typename?: "KeysResponse";
  filterOptions: Array<FilterOption>;
  keys: Array<Key>;
  pagination: GenericPagination;
};

export type KeyTaskFilterInput = {
  dueDateEndTime?: Maybe<Scalars["String"]>;
  dueDateStartTime?: Maybe<Scalars["String"]>;
  emails?: Maybe<Array<Scalars["String"]>>;
  groupIDs?: Maybe<Array<Scalars["ID"]>>;
  keyName?: Maybe<Scalars["String"]>;
  locationId: Scalars["String"];
  taskStatus?: Maybe<TaskStatus>;
};

export type KeyWithThermostatOff = {
  __typename?: "KeyWithThermostatOff";
  keyId: Scalars["ID"];
  keyName?: Maybe<Scalars["String"]>;
  rooms?: Maybe<Array<Maybe<RoomWithThermostatOff>>>;
};

export type Location = {
  __typename?: "Location";
  ACModelId?: Maybe<Scalars["String"]>;
  beingExportedToClickup?: Maybe<Scalars["Boolean"]>;
  clickupExportDisabled?: Maybe<Scalars["Boolean"]>;
  clickupListId?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  enabledFeatures?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id: Scalars["ID"];
  keysResponse: KeysResponse;
  language?: Maybe<Scalars["String"]>;
  locationMetadata?: Maybe<LocationMetadata>;
  locationMode: LocationMode;
  locationName: Scalars["String"];
  locationStats: LocationStats;
  organisation: Organisation;
  timezone: Scalars["String"];
};

export type LocationKeysResponseArgs = {
  pagination?: Maybe<GenericPaginationInput>;
  sort?: Maybe<KeySortInput>;
};

export type LocationEmbeddedAnalyticsInput = {
  embedId: Scalars["String"];
};

export type LocationFilterInput = {
  locationMode?: Maybe<LocationMode>;
  locationName?: Maybe<Scalars["String"]>;
  mappingStatus?: Maybe<Array<MappingStatus>>;
  organisationId?: Maybe<Scalars["String"]>;
};

export type LocationMetadata = {
  __typename?: "LocationMetadata";
  isAutomationModeDisabled?: Maybe<Scalars["Boolean"]>;
  locationId: Scalars["ID"];
  secondaryWifiPassword?: Maybe<Scalars["String"]>;
  secondaryWifiSsid?: Maybe<Scalars["String"]>;
  showEnergyConsumption?: Maybe<Scalars["Boolean"]>;
  subscription?: Maybe<Scalars["String"]>;
  wifiPassword?: Maybe<Scalars["String"]>;
  wifiSsid?: Maybe<Scalars["String"]>;
};

export enum LocationMode {
  Active = "ACTIVE",
  Disabled = "DISABLED",
}

export type LocationSortInput = {
  locationName?: Maybe<SortOptions>;
  mappingStatus?: Maybe<SortOptions>;
};

export type LocationsResponse = {
  __typename?: "LocationsResponse";
  filterOptions: Array<FilterOption>;
  locations: Array<Location>;
  pagination: GenericPagination;
};

export type LocationStats = {
  __typename?: "LocationStats";
  gatewaysStats: MappingStats;
  keysStats: MappingStats;
  locationId: Scalars["ID"];
  mappingStatus: MappingStatus;
  nodeHealthOverallStats: Array<NodeHealthOverallStats>;
  nodeHealthStats: NodeHealthStats;
  nodeOverallStats: Array<NodeTypeStats>;
  tasksStats: TasksStats;
};

export type MappedNode = {
  __typename?: "MappedNode";
  mappedNode: Node;
  mappedTime: Scalars["String"];
  nodeType: NodeType;
  roomId: Scalars["ID"];
  slotName: Scalars["String"];
};

export type MappingStats = {
  __typename?: "MappingStats";
  mappedCount: Scalars["Int"];
  totalCount: Scalars["Int"];
};

export enum MappingStatus {
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  NotStarted = "NOT_STARTED",
}

export type Mutation_Root = {
  __typename?: "mutation_root";
  ACModelCreate: AcModel;
  ACModelDisable: GenericResponse;
  ACModelUpdate: AcModel;
  activateComfortPlus: Scalars["Boolean"];
  addLocationDomoEmbed?: Maybe<Sensorflow_Location_Domo_Embeds>;
  addPrecoolCommands?: Maybe<Array<KeyWithThermostatOff>>;
  bulkAssignCompressorPositions: Scalars["Boolean"];
  bulkUpdateDefaultPositionConfigurations: Array<PositionConfigurationWithThermostatStatus>;
  bulkUpdatePositionConfigurations: Array<PositionConfigurationWithThermostatStatus>;
  cancelPrecoolCommand?: Maybe<CancelPrecoolCommand>;
  cancelTimerAndResetToDefaultConfig: Array<PositionConfiguration>;
  changeBattery: ChangeBatteryReponse;
  changeDisabledAutomationModeStatus: LocationMetadata;
  changeInfraType: Infrastructure;
  claimKey: KeyEntry;
  createACSettingCommands?: Maybe<Sensorflow_Ac_Setting_Commands_Mutation_Response>;
  createChiller?: Maybe<Sensorflow_Chillers>;
  createCompressor?: Maybe<Sensorflow_Compressors>;
  createCompressorPositionMappings?: Maybe<Sensorflow_Compressor_Mapping_Mutation_Response>;
  createCompressors?: Maybe<Sensorflow_Compressors_Mutation_Response>;
  createCoolingTower?: Maybe<Sensorflow_Cooling_Towers>;
  createDomoEmbed?: Maybe<Sensorflow_Domo_Embeds>;
  createFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases>;
  createGatewayToPositionMapping?: Maybe<Sensorflow_Gateway_To_Position_Mapping>;
  createInfra: Infrastructure;
  createKeyCategory?: Maybe<Sensorflow_Key_Categories>;
  createKeyCategoryTemplates?: Maybe<Sensorflow_Key_Category_Templates_Mutation_Response>;
  createKeyCategoryToKeyMappings?: Maybe<Sensorflow_Key_Category_To_Key_Position_Mutation_Response>;
  createLocation?: Maybe<Sensorflow_Locations>;
  createLocationMetadata?: Maybe<Sensorflow_Location_Metadata>;
  createNodeLifeCycleEvent: NodeLifeCycleEvent;
  createNodeSpareSet: NodeSpareSetReponse;
  createOrganisation?: Maybe<Sensorflow_Organisations>;
  createPipe?: Maybe<Sensorflow_Pipes>;
  createPosition?: Maybe<Sensorflow_Positions>;
  createPositions?: Maybe<Sensorflow_Positions_Mutation_Response>;
  createPrecoolCommands?: Maybe<Sensorflow_Precool_Commands_Mutation_Response>;
  createPump?: Maybe<Sensorflow_Pumps>;
  createRMARequest: RmaJobResponse;
  createRoom: Position;
  createRoomCompressors: Array<Compressor>;
  createSlot?: Maybe<Sensorflow_Node_Slots>;
  createSlots: Array<Slot>;
  createSmartRemValidationHistory?: Maybe<Sensorflow_Smart_Rem_Validation_History>;
  createTestJig?: Maybe<Sf_Testjig_Test_Jig>;
  createTestJigMaintenanceReport?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report>;
  createTestJigMaintenanceReports?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Mutation_Response>;
  createTestJigTestReport?: Maybe<Sf_Testjig_Test_Jig_Test_Report>;
  createTestJigTestReports?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Mutation_Response>;
  createTestJigType?: Maybe<Sf_Testjig_Test_Jig_Type>;
  createTestJigTypes?: Maybe<Sf_Testjig_Test_Jig_Type_Mutation_Response>;
  createTestJigs?: Maybe<Sf_Testjig_Test_Jig_Mutation_Response>;
  deactivateComfortPlus: Scalars["Boolean"];
  deleteCompressor?: Maybe<Sensorflow_Compressors>;
  deleteCompressorPositionMappings?: Maybe<Sensorflow_Compressor_Mapping_Mutation_Response>;
  deleteDomoEmbed?: Maybe<Sensorflow_Domo_Embeds>;
  deleteFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases>;
  deleteGateway: Gateway;
  deleteGroup: Scalars["Boolean"];
  deleteGuestPrecoolSchedule: Scalars["Boolean"];
  deleteInfra: Scalars["Boolean"];
  deleteKeyCategory?: Maybe<Sensorflow_Key_Categories>;
  deleteKeyCategoryTemplates?: Maybe<Sensorflow_Key_Category_Templates_Mutation_Response>;
  deletePosition: Position;
  deletePositions: Array<Position>;
  deleteReservastionGuest?: Maybe<Sensorflow_Reservation_Guests>;
  deleteSlot: Slot;
  delete_keyEntries?: Maybe<KeyEntries_Mutation_Response>;
  delete_keyEntries_by_pk?: Maybe<KeyEntries>;
  delete_positionConfigurations?: Maybe<PositionConfigurations_Mutation_Response>;
  delete_positionConfigurations_by_pk?: Maybe<PositionConfigurations>;
  delete_sensorflow_ac_command_event_stream?: Maybe<Sensorflow_Ac_Command_Event_Stream_Mutation_Response>;
  delete_sensorflow_ac_models?: Maybe<Sensorflow_Ac_Models_Mutation_Response>;
  delete_sensorflow_ac_models_by_pk?: Maybe<Sensorflow_Ac_Models>;
  delete_sensorflow_ac_setting_commands?: Maybe<Sensorflow_Ac_Setting_Commands_Mutation_Response>;
  delete_sensorflow_ac_setting_commands_by_pk?: Maybe<Sensorflow_Ac_Setting_Commands>;
  delete_sensorflow_ai_dt_ac_event_stream?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Mutation_Response>;
  delete_sensorflow_ai_dt_config_event_stream?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Mutation_Response>;
  delete_sensorflow_beacon_slots?: Maybe<Sensorflow_Beacon_Slots_Mutation_Response>;
  delete_sensorflow_beacon_slots_by_pk?: Maybe<Sensorflow_Beacon_Slots>;
  delete_sensorflow_checklist_items?: Maybe<Sensorflow_Checklist_Items_Mutation_Response>;
  delete_sensorflow_checklists?: Maybe<Sensorflow_Checklists_Mutation_Response>;
  delete_sensorflow_checklists_by_pk?: Maybe<Sensorflow_Checklists>;
  delete_sensorflow_chillers?: Maybe<Sensorflow_Chillers_Mutation_Response>;
  delete_sensorflow_chillers_by_pk?: Maybe<Sensorflow_Chillers>;
  delete_sensorflow_clickup_webhook_events?: Maybe<Sensorflow_Clickup_Webhook_Events_Mutation_Response>;
  delete_sensorflow_clickup_webhook_events_by_pk?: Maybe<Sensorflow_Clickup_Webhook_Events>;
  delete_sensorflow_comfortplus_activations?: Maybe<Sensorflow_Comfortplus_Activations_Mutation_Response>;
  delete_sensorflow_comfortplus_activations_by_pk?: Maybe<Sensorflow_Comfortplus_Activations>;
  delete_sensorflow_compressor_mapping_by_pk?: Maybe<Sensorflow_Compressor_Mapping>;
  delete_sensorflow_compressors?: Maybe<Sensorflow_Compressors_Mutation_Response>;
  delete_sensorflow_config_event_stream?: Maybe<Sensorflow_Config_Event_Stream_Mutation_Response>;
  delete_sensorflow_cooling_towers?: Maybe<Sensorflow_Cooling_Towers_Mutation_Response>;
  delete_sensorflow_cooling_towers_by_pk?: Maybe<Sensorflow_Cooling_Towers>;
  delete_sensorflow_currencies?: Maybe<Sensorflow_Currencies_Mutation_Response>;
  delete_sensorflow_currencies_by_pk?: Maybe<Sensorflow_Currencies>;
  delete_sensorflow_domo_embeds?: Maybe<Sensorflow_Domo_Embeds_Mutation_Response>;
  delete_sensorflow_entity_feature_flag_mappings?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Mutation_Response>;
  delete_sensorflow_entity_feature_flag_mappings_by_pk?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings>;
  delete_sensorflow_feature_flags?: Maybe<Sensorflow_Feature_Flags_Mutation_Response>;
  delete_sensorflow_feature_flags_by_pk?: Maybe<Sensorflow_Feature_Flags>;
  delete_sensorflow_firmware_releases?: Maybe<Sensorflow_Firmware_Releases_Mutation_Response>;
  delete_sensorflow_gateway_frequency?: Maybe<Sensorflow_Gateway_Frequency_Mutation_Response>;
  delete_sensorflow_gateway_frequency_by_pk?: Maybe<Sensorflow_Gateway_Frequency>;
  delete_sensorflow_gateway_health_data?: Maybe<Sensorflow_Gateway_Health_Data_Mutation_Response>;
  delete_sensorflow_gateway_to_position_mapping?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Mutation_Response>;
  delete_sensorflow_gateway_to_position_mapping_by_pk?: Maybe<Sensorflow_Gateway_To_Position_Mapping>;
  delete_sensorflow_gateways?: Maybe<Sensorflow_Gateways_Mutation_Response>;
  delete_sensorflow_gateways_by_pk?: Maybe<Sensorflow_Gateways>;
  delete_sensorflow_get_event_stream_view?: Maybe<Sensorflow_Get_Event_Stream_View_Mutation_Response>;
  delete_sensorflow_key_categories?: Maybe<Sensorflow_Key_Categories_Mutation_Response>;
  delete_sensorflow_key_category_room_templates?: Maybe<Sensorflow_Key_Category_Room_Templates_Mutation_Response>;
  delete_sensorflow_key_category_room_templates_by_pk?: Maybe<Sensorflow_Key_Category_Room_Templates>;
  delete_sensorflow_key_category_slot_templates?: Maybe<Sensorflow_Key_Category_Slot_Templates_Mutation_Response>;
  delete_sensorflow_key_category_slot_templates_by_pk?: Maybe<Sensorflow_Key_Category_Slot_Templates>;
  delete_sensorflow_key_category_templates_by_pk?: Maybe<Sensorflow_Key_Category_Templates>;
  delete_sensorflow_key_category_to_key_position?: Maybe<Sensorflow_Key_Category_To_Key_Position_Mutation_Response>;
  delete_sensorflow_key_category_to_key_position_by_pk?: Maybe<Sensorflow_Key_Category_To_Key_Position>;
  delete_sensorflow_location_addresses?: Maybe<Sensorflow_Location_Addresses_Mutation_Response>;
  delete_sensorflow_location_addresses_by_pk?: Maybe<Sensorflow_Location_Addresses>;
  delete_sensorflow_location_consumptions?: Maybe<Sensorflow_Location_Consumptions_Mutation_Response>;
  delete_sensorflow_location_consumptions_by_pk?: Maybe<Sensorflow_Location_Consumptions>;
  delete_sensorflow_location_domo_embeds?: Maybe<Sensorflow_Location_Domo_Embeds_Mutation_Response>;
  delete_sensorflow_location_metadata?: Maybe<Sensorflow_Location_Metadata_Mutation_Response>;
  delete_sensorflow_location_metadata_by_pk?: Maybe<Sensorflow_Location_Metadata>;
  delete_sensorflow_location_occ_customer_data?: Maybe<Sensorflow_Location_Occ_Customer_Data_Mutation_Response>;
  delete_sensorflow_location_occ_customer_data_by_pk?: Maybe<Sensorflow_Location_Occ_Customer_Data>;
  delete_sensorflow_location_occ_derived_data?: Maybe<Sensorflow_Location_Occ_Derived_Data_Mutation_Response>;
  delete_sensorflow_location_occ_derived_data_by_pk?: Maybe<Sensorflow_Location_Occ_Derived_Data>;
  delete_sensorflow_location_to_user_mapping?: Maybe<Sensorflow_Location_To_User_Mapping_Mutation_Response>;
  delete_sensorflow_location_to_user_mapping_by_pk?: Maybe<Sensorflow_Location_To_User_Mapping>;
  delete_sensorflow_locations?: Maybe<Sensorflow_Locations_Mutation_Response>;
  delete_sensorflow_locations_by_pk?: Maybe<Sensorflow_Locations>;
  delete_sensorflow_mapping_coordinates?: Maybe<Sensorflow_Mapping_Coordinates_Mutation_Response>;
  delete_sensorflow_metric_types?: Maybe<Sensorflow_Metric_Types_Mutation_Response>;
  delete_sensorflow_metric_types_by_pk?: Maybe<Sensorflow_Metric_Types>;
  delete_sensorflow_node_exception?: Maybe<Sensorflow_Node_Exception_Mutation_Response>;
  delete_sensorflow_node_exception_by_pk?: Maybe<Sensorflow_Node_Exception>;
  delete_sensorflow_node_exceptions?: Maybe<Sensorflow_Node_Exceptions_Mutation_Response>;
  delete_sensorflow_node_exceptions_by_pk?: Maybe<Sensorflow_Node_Exceptions>;
  delete_sensorflow_node_join_data?: Maybe<Sensorflow_Node_Join_Data_Mutation_Response>;
  delete_sensorflow_node_life_cycle_event_types?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Mutation_Response>;
  delete_sensorflow_node_life_cycle_event_types_by_pk?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types>;
  delete_sensorflow_node_life_cycle_events?: Maybe<Sensorflow_Node_Life_Cycle_Events_Mutation_Response>;
  delete_sensorflow_node_life_cycle_events_by_pk?: Maybe<Sensorflow_Node_Life_Cycle_Events>;
  delete_sensorflow_node_measurements?: Maybe<Sensorflow_Node_Measurements_Mutation_Response>;
  delete_sensorflow_node_meta_data?: Maybe<Sensorflow_Node_Meta_Data_Mutation_Response>;
  delete_sensorflow_node_meta_data_by_pk?: Maybe<Sensorflow_Node_Meta_Data>;
  delete_sensorflow_node_slots?: Maybe<Sensorflow_Node_Slots_Mutation_Response>;
  delete_sensorflow_node_slots_by_pk?: Maybe<Sensorflow_Node_Slots>;
  delete_sensorflow_node_spare_set?: Maybe<Sensorflow_Node_Spare_Set_Mutation_Response>;
  delete_sensorflow_node_spare_set_by_pk?: Maybe<Sensorflow_Node_Spare_Set>;
  delete_sensorflow_node_status_history?: Maybe<Sensorflow_Node_Status_History_Mutation_Response>;
  delete_sensorflow_node_status_history_by_pk?: Maybe<Sensorflow_Node_Status_History>;
  delete_sensorflow_node_statuses?: Maybe<Sensorflow_Node_Statuses_Mutation_Response>;
  delete_sensorflow_node_statuses_by_pk?: Maybe<Sensorflow_Node_Statuses>;
  delete_sensorflow_node_system_states?: Maybe<Sensorflow_Node_System_States_Mutation_Response>;
  delete_sensorflow_node_system_states_by_pk?: Maybe<Sensorflow_Node_System_States>;
  delete_sensorflow_node_to_position_mapping?: Maybe<Sensorflow_Node_To_Position_Mapping_Mutation_Response>;
  delete_sensorflow_node_to_slot_mapping?: Maybe<Sensorflow_Node_To_Slot_Mapping_Mutation_Response>;
  delete_sensorflow_node_to_slot_mapping_by_pk?: Maybe<Sensorflow_Node_To_Slot_Mapping>;
  delete_sensorflow_nodes?: Maybe<Sensorflow_Nodes_Mutation_Response>;
  delete_sensorflow_nodes_by_pk?: Maybe<Sensorflow_Nodes>;
  delete_sensorflow_organisations?: Maybe<Sensorflow_Organisations_Mutation_Response>;
  delete_sensorflow_organisations_by_pk?: Maybe<Sensorflow_Organisations>;
  delete_sensorflow_ota_queues?: Maybe<Sensorflow_Ota_Queues_Mutation_Response>;
  delete_sensorflow_ota_queues_by_pk?: Maybe<Sensorflow_Ota_Queues>;
  delete_sensorflow_ota_runs?: Maybe<Sensorflow_Ota_Runs_Mutation_Response>;
  delete_sensorflow_ota_runs_by_pk?: Maybe<Sensorflow_Ota_Runs>;
  delete_sensorflow_outdoor_temperatures?: Maybe<Sensorflow_Outdoor_Temperatures_Mutation_Response>;
  delete_sensorflow_outdoor_temperatures_by_pk?: Maybe<Sensorflow_Outdoor_Temperatures>;
  delete_sensorflow_pipes?: Maybe<Sensorflow_Pipes_Mutation_Response>;
  delete_sensorflow_pipes_by_pk?: Maybe<Sensorflow_Pipes>;
  delete_sensorflow_position_signal_stats?: Maybe<Sensorflow_Position_Signal_Stats_Mutation_Response>;
  delete_sensorflow_position_signal_stats_by_pk?: Maybe<Sensorflow_Position_Signal_Stats>;
  delete_sensorflow_positions?: Maybe<Sensorflow_Positions_Mutation_Response>;
  delete_sensorflow_positions_by_pk?: Maybe<Sensorflow_Positions>;
  delete_sensorflow_precool_commands?: Maybe<Sensorflow_Precool_Commands_Mutation_Response>;
  delete_sensorflow_precool_commands_by_pk?: Maybe<Sensorflow_Precool_Commands>;
  delete_sensorflow_precool_schedules?: Maybe<Sensorflow_Precool_Schedules_Mutation_Response>;
  delete_sensorflow_precool_schedules_by_pk?: Maybe<Sensorflow_Precool_Schedules>;
  delete_sensorflow_properties_information?: Maybe<Sensorflow_Properties_Information_Mutation_Response>;
  delete_sensorflow_properties_information_by_pk?: Maybe<Sensorflow_Properties_Information>;
  delete_sensorflow_pumps?: Maybe<Sensorflow_Pumps_Mutation_Response>;
  delete_sensorflow_pumps_by_pk?: Maybe<Sensorflow_Pumps>;
  delete_sensorflow_reservation_guests?: Maybe<Sensorflow_Reservation_Guests_Mutation_Response>;
  delete_sensorflow_reservations?: Maybe<Sensorflow_Reservations_Mutation_Response>;
  delete_sensorflow_reservations_by_pk?: Maybe<Sensorflow_Reservations>;
  delete_sensorflow_roles?: Maybe<Sensorflow_Roles_Mutation_Response>;
  delete_sensorflow_roles_by_pk?: Maybe<Sensorflow_Roles>;
  delete_sensorflow_smart_rem_validation_history?: Maybe<Sensorflow_Smart_Rem_Validation_History_Mutation_Response>;
  delete_sensorflow_smart_rem_validation_history_by_pk?: Maybe<Sensorflow_Smart_Rem_Validation_History>;
  delete_sensorflow_smplrspace_floors_to_levels?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Mutation_Response>;
  delete_sensorflow_smplrspace_floors_to_levels_by_pk?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels>;
  delete_sensorflow_smplrspace_levels_group?: Maybe<Sensorflow_Smplrspace_Levels_Group_Mutation_Response>;
  delete_sensorflow_smplrspace_levels_group_by_pk?: Maybe<Sensorflow_Smplrspace_Levels_Group>;
  delete_sensorflow_survey_responses?: Maybe<Sensorflow_Survey_Responses_Mutation_Response>;
  delete_sensorflow_survey_responses_by_pk?: Maybe<Sensorflow_Survey_Responses>;
  delete_sensorflow_task_status_history?: Maybe<Sensorflow_Task_Status_History_Mutation_Response>;
  delete_sensorflow_task_status_history_by_pk?: Maybe<Sensorflow_Task_Status_History>;
  delete_sensorflow_tasks?: Maybe<Sensorflow_Tasks_Mutation_Response>;
  delete_sensorflow_tasks_by_pk?: Maybe<Sensorflow_Tasks>;
  delete_sensorflow_tests?: Maybe<Sensorflow_Tests_Mutation_Response>;
  delete_sensorflow_tests_by_pk?: Maybe<Sensorflow_Tests>;
  delete_sensorflow_timezones?: Maybe<Sensorflow_Timezones_Mutation_Response>;
  delete_sensorflow_timezones_by_pk?: Maybe<Sensorflow_Timezones>;
  delete_sensorflow_user_to_permission_mapping?: Maybe<Sensorflow_User_To_Permission_Mapping_Mutation_Response>;
  delete_sensorflow_users?: Maybe<Sensorflow_Users_Mutation_Response>;
  delete_sensorflow_users_by_pk?: Maybe<Sensorflow_Users>;
  delete_sensorflow_warehouse_kitting_process_metadata?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Mutation_Response>;
  delete_sensorflow_warehouse_kitting_process_metadata_by_pk?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata>;
  delete_sf_firmware_dist_pipeline_firmware_build?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Mutation_Response>;
  delete_sf_firmware_dist_pipeline_firmware_build_by_pk?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build>;
  delete_sf_firmware_dist_pipeline_firmware_numbers?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Mutation_Response>;
  delete_sf_firmware_dist_pipeline_firmware_numbers_by_pk?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers>;
  delete_sf_pms_pms_pmslist?: Maybe<Sf_Pms_Pms_Pmslist_Mutation_Response>;
  delete_sf_pms_pms_pmslist_by_pk?: Maybe<Sf_Pms_Pms_Pmslist>;
  delete_sf_pms_pms_properties?: Maybe<Sf_Pms_Pms_Properties_Mutation_Response>;
  delete_sf_pms_pms_properties_by_pk?: Maybe<Sf_Pms_Pms_Properties>;
  delete_sf_pms_pms_property_eod_surveys?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Mutation_Response>;
  delete_sf_pms_pms_property_eod_surveys_by_pk?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys>;
  delete_sf_pms_pms_reservations?: Maybe<Sf_Pms_Pms_Reservations_Mutation_Response>;
  delete_sf_pms_pms_room_categories?: Maybe<Sf_Pms_Pms_Room_Categories_Mutation_Response>;
  delete_sf_pms_pms_rooms?: Maybe<Sf_Pms_Pms_Rooms_Mutation_Response>;
  delete_sf_pms_smartalloc_report?: Maybe<Sf_Pms_Smartalloc_Report_Mutation_Response>;
  delete_sf_pms_smartalloc_report_by_pk?: Maybe<Sf_Pms_Smartalloc_Report>;
  delete_sf_simulation_instrumentation_commands?: Maybe<Sf_Simulation_Instrumentation_Commands_Mutation_Response>;
  delete_sf_simulation_instrumentation_commands_by_pk?: Maybe<Sf_Simulation_Instrumentation_Commands>;
  delete_sf_support_actions?: Maybe<Sf_Support_Actions_Mutation_Response>;
  delete_sf_support_actions_by_pk?: Maybe<Sf_Support_Actions>;
  delete_sf_support_active_property_alerts?: Maybe<Sf_Support_Active_Property_Alerts_Mutation_Response>;
  delete_sf_support_active_property_alerts_by_pk?: Maybe<Sf_Support_Active_Property_Alerts>;
  delete_sf_support_analytics_ticket_jobs_running_histories?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Mutation_Response>;
  delete_sf_support_analytics_ticket_jobs_running_histories_by_pk?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories>;
  delete_sf_support_root_causes?: Maybe<Sf_Support_Root_Causes_Mutation_Response>;
  delete_sf_support_root_causes_by_pk?: Maybe<Sf_Support_Root_Causes>;
  delete_sf_support_ticket_classes?: Maybe<Sf_Support_Ticket_Classes_Mutation_Response>;
  delete_sf_support_ticket_classes_by_pk?: Maybe<Sf_Support_Ticket_Classes>;
  delete_sf_support_ticket_status?: Maybe<Sf_Support_Ticket_Status_Mutation_Response>;
  delete_sf_support_ticket_status_by_pk?: Maybe<Sf_Support_Ticket_Status>;
  delete_sf_support_tickets?: Maybe<Sf_Support_Tickets_Mutation_Response>;
  delete_sf_support_tickets_by_pk?: Maybe<Sf_Support_Tickets>;
  delete_sf_testjig_test_jig?: Maybe<Sf_Testjig_Test_Jig_Mutation_Response>;
  delete_sf_testjig_test_jig_by_pk?: Maybe<Sf_Testjig_Test_Jig>;
  delete_sf_testjig_test_jig_maintenance_report?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Mutation_Response>;
  delete_sf_testjig_test_jig_maintenance_report_by_pk?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report>;
  delete_sf_testjig_test_jig_report_metadata?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Mutation_Response>;
  delete_sf_testjig_test_jig_report_metadata_by_pk?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata>;
  delete_sf_testjig_test_jig_report_metadatas?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Mutation_Response>;
  delete_sf_testjig_test_jig_report_metadatas_by_pk?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas>;
  delete_sf_testjig_test_jig_test_report?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Mutation_Response>;
  delete_sf_testjig_test_jig_test_report_by_pk?: Maybe<Sf_Testjig_Test_Jig_Test_Report>;
  delete_sf_testjig_test_jig_type?: Maybe<Sf_Testjig_Test_Jig_Type_Mutation_Response>;
  delete_sf_testjig_test_jig_type_by_pk?: Maybe<Sf_Testjig_Test_Jig_Type>;
  delete_testRuns?: Maybe<TestRuns_Mutation_Response>;
  delete_testRuns_by_pk?: Maybe<TestRuns>;
  editComfortPlusDetail: Scalars["Boolean"];
  editGroupOfGateways: Scalars["Boolean"];
  editGroupOfKeys: Scalars["Boolean"];
  exitInstallationMode?: Maybe<KeyEntry>;
  gatewaysCreate: Array<Gateway>;
  initClickupExportProgress: Scalars["Boolean"];
  insertActivePropertyAlerts?: Maybe<Sf_Support_Active_Property_Alerts_Mutation_Response>;
  insertKeyEntry?: Maybe<KeyEntries>;
  insertPositionConfiguration?: Maybe<PositionConfigurations>;
  insertTestRun?: Maybe<TestRuns>;
  insertTestRuns?: Maybe<TestRuns_Mutation_Response>;
  insert_keyEntries?: Maybe<KeyEntries_Mutation_Response>;
  insert_positionConfigurations?: Maybe<PositionConfigurations_Mutation_Response>;
  insert_sensorflow_ac_command_event_stream?: Maybe<Sensorflow_Ac_Command_Event_Stream_Mutation_Response>;
  insert_sensorflow_ac_command_event_stream_one?: Maybe<Sensorflow_Ac_Command_Event_Stream>;
  insert_sensorflow_ac_models?: Maybe<Sensorflow_Ac_Models_Mutation_Response>;
  insert_sensorflow_ac_models_one?: Maybe<Sensorflow_Ac_Models>;
  insert_sensorflow_ac_setting_commands_one?: Maybe<Sensorflow_Ac_Setting_Commands>;
  insert_sensorflow_ai_dt_ac_event_stream?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Mutation_Response>;
  insert_sensorflow_ai_dt_ac_event_stream_one?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream>;
  insert_sensorflow_ai_dt_config_event_stream?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Mutation_Response>;
  insert_sensorflow_ai_dt_config_event_stream_one?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream>;
  insert_sensorflow_beacon_slots?: Maybe<Sensorflow_Beacon_Slots_Mutation_Response>;
  insert_sensorflow_beacon_slots_one?: Maybe<Sensorflow_Beacon_Slots>;
  insert_sensorflow_checklist_items?: Maybe<Sensorflow_Checklist_Items_Mutation_Response>;
  insert_sensorflow_checklist_items_one?: Maybe<Sensorflow_Checklist_Items>;
  insert_sensorflow_checklists?: Maybe<Sensorflow_Checklists_Mutation_Response>;
  insert_sensorflow_checklists_one?: Maybe<Sensorflow_Checklists>;
  insert_sensorflow_chillers?: Maybe<Sensorflow_Chillers_Mutation_Response>;
  insert_sensorflow_clickup_webhook_events?: Maybe<Sensorflow_Clickup_Webhook_Events_Mutation_Response>;
  insert_sensorflow_clickup_webhook_events_one?: Maybe<Sensorflow_Clickup_Webhook_Events>;
  insert_sensorflow_comfortplus_activations?: Maybe<Sensorflow_Comfortplus_Activations_Mutation_Response>;
  insert_sensorflow_comfortplus_activations_one?: Maybe<Sensorflow_Comfortplus_Activations>;
  insert_sensorflow_compressor_mapping_one?: Maybe<Sensorflow_Compressor_Mapping>;
  insert_sensorflow_config_event_stream?: Maybe<Sensorflow_Config_Event_Stream_Mutation_Response>;
  insert_sensorflow_config_event_stream_one?: Maybe<Sensorflow_Config_Event_Stream>;
  insert_sensorflow_cooling_towers?: Maybe<Sensorflow_Cooling_Towers_Mutation_Response>;
  insert_sensorflow_currencies?: Maybe<Sensorflow_Currencies_Mutation_Response>;
  insert_sensorflow_currencies_one?: Maybe<Sensorflow_Currencies>;
  insert_sensorflow_domo_embeds?: Maybe<Sensorflow_Domo_Embeds_Mutation_Response>;
  insert_sensorflow_entity_feature_flag_mappings?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Mutation_Response>;
  insert_sensorflow_entity_feature_flag_mappings_one?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings>;
  insert_sensorflow_feature_flags?: Maybe<Sensorflow_Feature_Flags_Mutation_Response>;
  insert_sensorflow_feature_flags_one?: Maybe<Sensorflow_Feature_Flags>;
  insert_sensorflow_firmware_releases?: Maybe<Sensorflow_Firmware_Releases_Mutation_Response>;
  insert_sensorflow_gateway_frequency?: Maybe<Sensorflow_Gateway_Frequency_Mutation_Response>;
  insert_sensorflow_gateway_frequency_one?: Maybe<Sensorflow_Gateway_Frequency>;
  insert_sensorflow_gateway_health_data?: Maybe<Sensorflow_Gateway_Health_Data_Mutation_Response>;
  insert_sensorflow_gateway_health_data_one?: Maybe<Sensorflow_Gateway_Health_Data>;
  insert_sensorflow_gateway_to_position_mapping?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Mutation_Response>;
  insert_sensorflow_gateways?: Maybe<Sensorflow_Gateways_Mutation_Response>;
  insert_sensorflow_gateways_one?: Maybe<Sensorflow_Gateways>;
  insert_sensorflow_get_event_stream_view?: Maybe<Sensorflow_Get_Event_Stream_View_Mutation_Response>;
  insert_sensorflow_get_event_stream_view_one?: Maybe<Sensorflow_Get_Event_Stream_View>;
  insert_sensorflow_key_categories?: Maybe<Sensorflow_Key_Categories_Mutation_Response>;
  insert_sensorflow_key_category_room_templates?: Maybe<Sensorflow_Key_Category_Room_Templates_Mutation_Response>;
  insert_sensorflow_key_category_room_templates_one?: Maybe<Sensorflow_Key_Category_Room_Templates>;
  insert_sensorflow_key_category_slot_templates?: Maybe<Sensorflow_Key_Category_Slot_Templates_Mutation_Response>;
  insert_sensorflow_key_category_slot_templates_one?: Maybe<Sensorflow_Key_Category_Slot_Templates>;
  insert_sensorflow_key_category_templates_one?: Maybe<Sensorflow_Key_Category_Templates>;
  insert_sensorflow_key_category_to_key_position_one?: Maybe<Sensorflow_Key_Category_To_Key_Position>;
  insert_sensorflow_location_addresses?: Maybe<Sensorflow_Location_Addresses_Mutation_Response>;
  insert_sensorflow_location_addresses_one?: Maybe<Sensorflow_Location_Addresses>;
  insert_sensorflow_location_consumptions?: Maybe<Sensorflow_Location_Consumptions_Mutation_Response>;
  insert_sensorflow_location_consumptions_one?: Maybe<Sensorflow_Location_Consumptions>;
  insert_sensorflow_location_domo_embeds?: Maybe<Sensorflow_Location_Domo_Embeds_Mutation_Response>;
  insert_sensorflow_location_metadata?: Maybe<Sensorflow_Location_Metadata_Mutation_Response>;
  insert_sensorflow_location_occ_customer_data?: Maybe<Sensorflow_Location_Occ_Customer_Data_Mutation_Response>;
  insert_sensorflow_location_occ_customer_data_one?: Maybe<Sensorflow_Location_Occ_Customer_Data>;
  insert_sensorflow_location_occ_derived_data?: Maybe<Sensorflow_Location_Occ_Derived_Data_Mutation_Response>;
  insert_sensorflow_location_occ_derived_data_one?: Maybe<Sensorflow_Location_Occ_Derived_Data>;
  insert_sensorflow_location_to_user_mapping?: Maybe<Sensorflow_Location_To_User_Mapping_Mutation_Response>;
  insert_sensorflow_location_to_user_mapping_one?: Maybe<Sensorflow_Location_To_User_Mapping>;
  insert_sensorflow_locations?: Maybe<Sensorflow_Locations_Mutation_Response>;
  insert_sensorflow_mapping_coordinates?: Maybe<Sensorflow_Mapping_Coordinates_Mutation_Response>;
  insert_sensorflow_mapping_coordinates_one?: Maybe<Sensorflow_Mapping_Coordinates>;
  insert_sensorflow_metric_types?: Maybe<Sensorflow_Metric_Types_Mutation_Response>;
  insert_sensorflow_metric_types_one?: Maybe<Sensorflow_Metric_Types>;
  insert_sensorflow_node_exception?: Maybe<Sensorflow_Node_Exception_Mutation_Response>;
  insert_sensorflow_node_exception_one?: Maybe<Sensorflow_Node_Exception>;
  insert_sensorflow_node_exceptions?: Maybe<Sensorflow_Node_Exceptions_Mutation_Response>;
  insert_sensorflow_node_exceptions_one?: Maybe<Sensorflow_Node_Exceptions>;
  insert_sensorflow_node_join_data?: Maybe<Sensorflow_Node_Join_Data_Mutation_Response>;
  insert_sensorflow_node_join_data_one?: Maybe<Sensorflow_Node_Join_Data>;
  insert_sensorflow_node_life_cycle_event_types?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Mutation_Response>;
  insert_sensorflow_node_life_cycle_event_types_one?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types>;
  insert_sensorflow_node_life_cycle_events?: Maybe<Sensorflow_Node_Life_Cycle_Events_Mutation_Response>;
  insert_sensorflow_node_life_cycle_events_one?: Maybe<Sensorflow_Node_Life_Cycle_Events>;
  insert_sensorflow_node_measurements?: Maybe<Sensorflow_Node_Measurements_Mutation_Response>;
  insert_sensorflow_node_measurements_one?: Maybe<Sensorflow_Node_Measurements>;
  insert_sensorflow_node_meta_data?: Maybe<Sensorflow_Node_Meta_Data_Mutation_Response>;
  insert_sensorflow_node_meta_data_one?: Maybe<Sensorflow_Node_Meta_Data>;
  insert_sensorflow_node_slots?: Maybe<Sensorflow_Node_Slots_Mutation_Response>;
  insert_sensorflow_node_spare_set?: Maybe<Sensorflow_Node_Spare_Set_Mutation_Response>;
  insert_sensorflow_node_spare_set_one?: Maybe<Sensorflow_Node_Spare_Set>;
  insert_sensorflow_node_status_history?: Maybe<Sensorflow_Node_Status_History_Mutation_Response>;
  insert_sensorflow_node_status_history_one?: Maybe<Sensorflow_Node_Status_History>;
  insert_sensorflow_node_statuses?: Maybe<Sensorflow_Node_Statuses_Mutation_Response>;
  insert_sensorflow_node_statuses_one?: Maybe<Sensorflow_Node_Statuses>;
  insert_sensorflow_node_system_states?: Maybe<Sensorflow_Node_System_States_Mutation_Response>;
  insert_sensorflow_node_system_states_one?: Maybe<Sensorflow_Node_System_States>;
  insert_sensorflow_node_to_position_mapping?: Maybe<Sensorflow_Node_To_Position_Mapping_Mutation_Response>;
  insert_sensorflow_node_to_position_mapping_one?: Maybe<Sensorflow_Node_To_Position_Mapping>;
  insert_sensorflow_node_to_slot_mapping?: Maybe<Sensorflow_Node_To_Slot_Mapping_Mutation_Response>;
  insert_sensorflow_node_to_slot_mapping_one?: Maybe<Sensorflow_Node_To_Slot_Mapping>;
  insert_sensorflow_nodes?: Maybe<Sensorflow_Nodes_Mutation_Response>;
  insert_sensorflow_nodes_one?: Maybe<Sensorflow_Nodes>;
  insert_sensorflow_organisations?: Maybe<Sensorflow_Organisations_Mutation_Response>;
  insert_sensorflow_ota_queues?: Maybe<Sensorflow_Ota_Queues_Mutation_Response>;
  insert_sensorflow_ota_queues_one?: Maybe<Sensorflow_Ota_Queues>;
  insert_sensorflow_ota_runs?: Maybe<Sensorflow_Ota_Runs_Mutation_Response>;
  insert_sensorflow_ota_runs_one?: Maybe<Sensorflow_Ota_Runs>;
  insert_sensorflow_outdoor_temperatures?: Maybe<Sensorflow_Outdoor_Temperatures_Mutation_Response>;
  insert_sensorflow_outdoor_temperatures_one?: Maybe<Sensorflow_Outdoor_Temperatures>;
  insert_sensorflow_pipes?: Maybe<Sensorflow_Pipes_Mutation_Response>;
  insert_sensorflow_position_signal_stats?: Maybe<Sensorflow_Position_Signal_Stats_Mutation_Response>;
  insert_sensorflow_position_signal_stats_one?: Maybe<Sensorflow_Position_Signal_Stats>;
  insert_sensorflow_precool_commands_one?: Maybe<Sensorflow_Precool_Commands>;
  insert_sensorflow_precool_schedules?: Maybe<Sensorflow_Precool_Schedules_Mutation_Response>;
  insert_sensorflow_precool_schedules_one?: Maybe<Sensorflow_Precool_Schedules>;
  insert_sensorflow_properties_information?: Maybe<Sensorflow_Properties_Information_Mutation_Response>;
  insert_sensorflow_properties_information_one?: Maybe<Sensorflow_Properties_Information>;
  insert_sensorflow_pumps?: Maybe<Sensorflow_Pumps_Mutation_Response>;
  insert_sensorflow_reservation_guests?: Maybe<Sensorflow_Reservation_Guests_Mutation_Response>;
  insert_sensorflow_reservation_guests_one?: Maybe<Sensorflow_Reservation_Guests>;
  insert_sensorflow_reservations?: Maybe<Sensorflow_Reservations_Mutation_Response>;
  insert_sensorflow_reservations_one?: Maybe<Sensorflow_Reservations>;
  insert_sensorflow_roles?: Maybe<Sensorflow_Roles_Mutation_Response>;
  insert_sensorflow_roles_one?: Maybe<Sensorflow_Roles>;
  insert_sensorflow_smart_rem_validation_history?: Maybe<Sensorflow_Smart_Rem_Validation_History_Mutation_Response>;
  insert_sensorflow_smplrspace_floors_to_levels?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Mutation_Response>;
  insert_sensorflow_smplrspace_floors_to_levels_one?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels>;
  insert_sensorflow_smplrspace_levels_group?: Maybe<Sensorflow_Smplrspace_Levels_Group_Mutation_Response>;
  insert_sensorflow_smplrspace_levels_group_one?: Maybe<Sensorflow_Smplrspace_Levels_Group>;
  insert_sensorflow_survey_responses?: Maybe<Sensorflow_Survey_Responses_Mutation_Response>;
  insert_sensorflow_survey_responses_one?: Maybe<Sensorflow_Survey_Responses>;
  insert_sensorflow_task_status_history?: Maybe<Sensorflow_Task_Status_History_Mutation_Response>;
  insert_sensorflow_task_status_history_one?: Maybe<Sensorflow_Task_Status_History>;
  insert_sensorflow_tasks?: Maybe<Sensorflow_Tasks_Mutation_Response>;
  insert_sensorflow_tasks_one?: Maybe<Sensorflow_Tasks>;
  insert_sensorflow_tests?: Maybe<Sensorflow_Tests_Mutation_Response>;
  insert_sensorflow_tests_one?: Maybe<Sensorflow_Tests>;
  insert_sensorflow_timezones?: Maybe<Sensorflow_Timezones_Mutation_Response>;
  insert_sensorflow_timezones_one?: Maybe<Sensorflow_Timezones>;
  insert_sensorflow_user_to_permission_mapping?: Maybe<Sensorflow_User_To_Permission_Mapping_Mutation_Response>;
  insert_sensorflow_user_to_permission_mapping_one?: Maybe<Sensorflow_User_To_Permission_Mapping>;
  insert_sensorflow_users?: Maybe<Sensorflow_Users_Mutation_Response>;
  insert_sensorflow_users_one?: Maybe<Sensorflow_Users>;
  insert_sensorflow_warehouse_kitting_process_metadata?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Mutation_Response>;
  insert_sensorflow_warehouse_kitting_process_metadata_one?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata>;
  insert_sf_firmware_dist_pipeline_firmware_build?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Mutation_Response>;
  insert_sf_firmware_dist_pipeline_firmware_build_one?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build>;
  insert_sf_firmware_dist_pipeline_firmware_numbers?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Mutation_Response>;
  insert_sf_firmware_dist_pipeline_firmware_numbers_one?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers>;
  insert_sf_pms_pms_pmslist?: Maybe<Sf_Pms_Pms_Pmslist_Mutation_Response>;
  insert_sf_pms_pms_pmslist_one?: Maybe<Sf_Pms_Pms_Pmslist>;
  insert_sf_pms_pms_properties?: Maybe<Sf_Pms_Pms_Properties_Mutation_Response>;
  insert_sf_pms_pms_properties_one?: Maybe<Sf_Pms_Pms_Properties>;
  insert_sf_pms_pms_property_eod_surveys?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Mutation_Response>;
  insert_sf_pms_pms_property_eod_surveys_one?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys>;
  insert_sf_pms_pms_reservations?: Maybe<Sf_Pms_Pms_Reservations_Mutation_Response>;
  insert_sf_pms_pms_reservations_one?: Maybe<Sf_Pms_Pms_Reservations>;
  insert_sf_pms_pms_room_categories?: Maybe<Sf_Pms_Pms_Room_Categories_Mutation_Response>;
  insert_sf_pms_pms_room_categories_one?: Maybe<Sf_Pms_Pms_Room_Categories>;
  insert_sf_pms_pms_rooms?: Maybe<Sf_Pms_Pms_Rooms_Mutation_Response>;
  insert_sf_pms_pms_rooms_one?: Maybe<Sf_Pms_Pms_Rooms>;
  insert_sf_pms_smartalloc_report?: Maybe<Sf_Pms_Smartalloc_Report_Mutation_Response>;
  insert_sf_pms_smartalloc_report_one?: Maybe<Sf_Pms_Smartalloc_Report>;
  insert_sf_simulation_instrumentation_commands?: Maybe<Sf_Simulation_Instrumentation_Commands_Mutation_Response>;
  insert_sf_simulation_instrumentation_commands_one?: Maybe<Sf_Simulation_Instrumentation_Commands>;
  insert_sf_support_actions?: Maybe<Sf_Support_Actions_Mutation_Response>;
  insert_sf_support_actions_one?: Maybe<Sf_Support_Actions>;
  insert_sf_support_active_property_alerts_one?: Maybe<Sf_Support_Active_Property_Alerts>;
  insert_sf_support_analytics_ticket_jobs_running_histories?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Mutation_Response>;
  insert_sf_support_analytics_ticket_jobs_running_histories_one?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories>;
  insert_sf_support_root_causes?: Maybe<Sf_Support_Root_Causes_Mutation_Response>;
  insert_sf_support_root_causes_one?: Maybe<Sf_Support_Root_Causes>;
  insert_sf_support_ticket_classes?: Maybe<Sf_Support_Ticket_Classes_Mutation_Response>;
  insert_sf_support_ticket_classes_one?: Maybe<Sf_Support_Ticket_Classes>;
  insert_sf_support_ticket_status?: Maybe<Sf_Support_Ticket_Status_Mutation_Response>;
  insert_sf_support_ticket_status_one?: Maybe<Sf_Support_Ticket_Status>;
  insert_sf_support_tickets?: Maybe<Sf_Support_Tickets_Mutation_Response>;
  insert_sf_support_tickets_one?: Maybe<Sf_Support_Tickets>;
  insert_sf_testjig_test_jig_report_metadata?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Mutation_Response>;
  insert_sf_testjig_test_jig_report_metadata_one?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata>;
  insert_sf_testjig_test_jig_report_metadatas?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Mutation_Response>;
  insert_sf_testjig_test_jig_report_metadatas_one?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas>;
  mapInfraNode: MappedNode;
  mapNode: NodeMappingResponse;
  markKeyAsDnD: KeyEntry;
  onEnterLiveDataModeConfig: Scalars["Boolean"];
  onExitLiveDataModeConfig: Scalars["Boolean"];
  precoolComfortPlusRoom: Scalars["Boolean"];
  removeLocationDomoEmbed?: Maybe<Sensorflow_Location_Domo_Embeds>;
  removeNodeSpareSet: Scalars["Boolean"];
  renameGateway: Gateway;
  resetPositionConfigurations: Array<PositionConfiguration>;
  root?: Maybe<Scalars["String"]>;
  saveGuestPrecoolSchedule: Scalars["Boolean"];
  sensorflow_ai_f_send_ac_command: Array<Sensorflow_Ac_Setting_Commands>;
  setupTestData?: Maybe<Scalars["String"]>;
  skipRapidMapping: NodeMappingResponse;
  startInstallationMode: KeyEntry;
  startTesting: Scalars["Boolean"];
  swapChannels: Scalars["Boolean"];
  teardownTestData?: Maybe<Scalars["String"]>;
  triggerToUploadGatewayImages: Scalars["Boolean"];
  unClaimKey?: Maybe<KeyEntry>;
  unmapInfraNode: MappedNode;
  unmapNode: NodeMappingResponse;
  updateChiller?: Maybe<Sensorflow_Chillers>;
  updateComfortPlusActivations?: Maybe<Sensorflow_Comfortplus_Activations_Mutation_Response>;
  updateCompressor?: Maybe<Sensorflow_Compressors>;
  updateCompressors?: Maybe<Array<Maybe<Sensorflow_Compressors_Mutation_Response>>>;
  updateCoolingTower?: Maybe<Sensorflow_Cooling_Towers>;
  updateDefaultPositionConfiguration: PositionConfigurationWithThermostatStatus;
  updateDomoEmbed?: Maybe<Sensorflow_Domo_Embeds>;
  updateFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases>;
  updateGatewayHardwareType: Gateway;
  updateGroup: Scalars["Boolean"];
  updateInfraNode: Scalars["Boolean"];
  updateKeyCategory?: Maybe<Sensorflow_Key_Categories>;
  updateKeyCategoryToKeyMappings?: Maybe<Sensorflow_Key_Category_To_Key_Position_Mutation_Response>;
  updateKeyEntries?: Maybe<KeyEntries_Mutation_Response>;
  updateLocation?: Maybe<Sensorflow_Locations>;
  updateLocationBeaconSlotManagement: Scalars["Boolean"];
  updateLocationGatewayHardwareType: Scalars["Boolean"];
  updateLocationGatewayPromiscuousMode: Scalars["Boolean"];
  updateLocationMetadata?: Maybe<Sensorflow_Location_Metadata_Mutation_Response>;
  updateLocationSetEnableNoKeyCardState: Scalars["Boolean"];
  updateNodeToSlotMappings?: Maybe<Sensorflow_Node_To_Slot_Mapping_Mutation_Response>;
  updatePipe?: Maybe<Sensorflow_Pipes>;
  updatePosition?: Maybe<Sensorflow_Positions>;
  updatePositionConfiguration: PositionConfigurationWithThermostatStatus;
  updatePositionConfigurations?: Maybe<PositionConfigurations_Mutation_Response>;
  updatePositionConfigurationsAutomationMode: Array<PositionConfiguration>;
  updatePositions?: Maybe<Sensorflow_Positions_Mutation_Response>;
  updatePump?: Maybe<Sensorflow_Pumps>;
  updateSlot?: Maybe<Sensorflow_Node_Slots>;
  updateSlotTasksToPending: Scalars["Boolean"];
  updateTestJig?: Maybe<Sf_Testjig_Test_Jig>;
  updateTestJigMaintenanceReport?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report>;
  updateTestJigMaintenanceReports?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Mutation_Response>;
  updateTestJigTestReport?: Maybe<Sf_Testjig_Test_Jig_Test_Report>;
  updateTestJigTestReports?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Mutation_Response>;
  updateTestJigType?: Maybe<Sf_Testjig_Test_Jig_Type>;
  updateTestJigTypes?: Maybe<Sf_Testjig_Test_Jig_Type_Mutation_Response>;
  updateTestJigs?: Maybe<Sf_Testjig_Test_Jig_Mutation_Response>;
  updateTestRuns?: Maybe<TestRuns_Mutation_Response>;
  update_keyEntries_by_pk?: Maybe<KeyEntries>;
  update_keyEntries_many?: Maybe<Array<Maybe<KeyEntries_Mutation_Response>>>;
  update_positionConfigurations_by_pk?: Maybe<PositionConfigurations>;
  update_positionConfigurations_many?: Maybe<Array<Maybe<PositionConfigurations_Mutation_Response>>>;
  update_sensorflow_ac_command_event_stream?: Maybe<Sensorflow_Ac_Command_Event_Stream_Mutation_Response>;
  update_sensorflow_ac_command_event_stream_many?: Maybe<
    Array<Maybe<Sensorflow_Ac_Command_Event_Stream_Mutation_Response>>
  >;
  update_sensorflow_ac_models?: Maybe<Sensorflow_Ac_Models_Mutation_Response>;
  update_sensorflow_ac_models_by_pk?: Maybe<Sensorflow_Ac_Models>;
  update_sensorflow_ac_models_many?: Maybe<Array<Maybe<Sensorflow_Ac_Models_Mutation_Response>>>;
  update_sensorflow_ac_setting_commands?: Maybe<Sensorflow_Ac_Setting_Commands_Mutation_Response>;
  update_sensorflow_ac_setting_commands_by_pk?: Maybe<Sensorflow_Ac_Setting_Commands>;
  update_sensorflow_ac_setting_commands_many?: Maybe<Array<Maybe<Sensorflow_Ac_Setting_Commands_Mutation_Response>>>;
  update_sensorflow_ai_dt_ac_event_stream?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Mutation_Response>;
  update_sensorflow_ai_dt_ac_event_stream_many?: Maybe<
    Array<Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Mutation_Response>>
  >;
  update_sensorflow_ai_dt_config_event_stream?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Mutation_Response>;
  update_sensorflow_ai_dt_config_event_stream_many?: Maybe<
    Array<Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Mutation_Response>>
  >;
  update_sensorflow_beacon_slots?: Maybe<Sensorflow_Beacon_Slots_Mutation_Response>;
  update_sensorflow_beacon_slots_by_pk?: Maybe<Sensorflow_Beacon_Slots>;
  update_sensorflow_beacon_slots_many?: Maybe<Array<Maybe<Sensorflow_Beacon_Slots_Mutation_Response>>>;
  update_sensorflow_checklist_items?: Maybe<Sensorflow_Checklist_Items_Mutation_Response>;
  update_sensorflow_checklist_items_many?: Maybe<Array<Maybe<Sensorflow_Checklist_Items_Mutation_Response>>>;
  update_sensorflow_checklists?: Maybe<Sensorflow_Checklists_Mutation_Response>;
  update_sensorflow_checklists_by_pk?: Maybe<Sensorflow_Checklists>;
  update_sensorflow_checklists_many?: Maybe<Array<Maybe<Sensorflow_Checklists_Mutation_Response>>>;
  update_sensorflow_chillers?: Maybe<Sensorflow_Chillers_Mutation_Response>;
  update_sensorflow_chillers_many?: Maybe<Array<Maybe<Sensorflow_Chillers_Mutation_Response>>>;
  update_sensorflow_clickup_webhook_events?: Maybe<Sensorflow_Clickup_Webhook_Events_Mutation_Response>;
  update_sensorflow_clickup_webhook_events_by_pk?: Maybe<Sensorflow_Clickup_Webhook_Events>;
  update_sensorflow_clickup_webhook_events_many?: Maybe<
    Array<Maybe<Sensorflow_Clickup_Webhook_Events_Mutation_Response>>
  >;
  update_sensorflow_comfortplus_activations_by_pk?: Maybe<Sensorflow_Comfortplus_Activations>;
  update_sensorflow_comfortplus_activations_many?: Maybe<
    Array<Maybe<Sensorflow_Comfortplus_Activations_Mutation_Response>>
  >;
  update_sensorflow_compressor_mapping?: Maybe<Sensorflow_Compressor_Mapping_Mutation_Response>;
  update_sensorflow_compressor_mapping_by_pk?: Maybe<Sensorflow_Compressor_Mapping>;
  update_sensorflow_compressor_mapping_many?: Maybe<Array<Maybe<Sensorflow_Compressor_Mapping_Mutation_Response>>>;
  update_sensorflow_compressors?: Maybe<Sensorflow_Compressors_Mutation_Response>;
  update_sensorflow_config_event_stream?: Maybe<Sensorflow_Config_Event_Stream_Mutation_Response>;
  update_sensorflow_config_event_stream_many?: Maybe<Array<Maybe<Sensorflow_Config_Event_Stream_Mutation_Response>>>;
  update_sensorflow_cooling_towers?: Maybe<Sensorflow_Cooling_Towers_Mutation_Response>;
  update_sensorflow_cooling_towers_many?: Maybe<Array<Maybe<Sensorflow_Cooling_Towers_Mutation_Response>>>;
  update_sensorflow_currencies?: Maybe<Sensorflow_Currencies_Mutation_Response>;
  update_sensorflow_currencies_by_pk?: Maybe<Sensorflow_Currencies>;
  update_sensorflow_currencies_many?: Maybe<Array<Maybe<Sensorflow_Currencies_Mutation_Response>>>;
  update_sensorflow_domo_embeds?: Maybe<Sensorflow_Domo_Embeds_Mutation_Response>;
  update_sensorflow_domo_embeds_many?: Maybe<Array<Maybe<Sensorflow_Domo_Embeds_Mutation_Response>>>;
  update_sensorflow_entity_feature_flag_mappings?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Mutation_Response>;
  update_sensorflow_entity_feature_flag_mappings_by_pk?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings>;
  update_sensorflow_entity_feature_flag_mappings_many?: Maybe<
    Array<Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Mutation_Response>>
  >;
  update_sensorflow_feature_flags?: Maybe<Sensorflow_Feature_Flags_Mutation_Response>;
  update_sensorflow_feature_flags_by_pk?: Maybe<Sensorflow_Feature_Flags>;
  update_sensorflow_feature_flags_many?: Maybe<Array<Maybe<Sensorflow_Feature_Flags_Mutation_Response>>>;
  update_sensorflow_firmware_releases?: Maybe<Sensorflow_Firmware_Releases_Mutation_Response>;
  update_sensorflow_firmware_releases_many?: Maybe<Array<Maybe<Sensorflow_Firmware_Releases_Mutation_Response>>>;
  update_sensorflow_gateway_frequency?: Maybe<Sensorflow_Gateway_Frequency_Mutation_Response>;
  update_sensorflow_gateway_frequency_by_pk?: Maybe<Sensorflow_Gateway_Frequency>;
  update_sensorflow_gateway_frequency_many?: Maybe<Array<Maybe<Sensorflow_Gateway_Frequency_Mutation_Response>>>;
  update_sensorflow_gateway_health_data?: Maybe<Sensorflow_Gateway_Health_Data_Mutation_Response>;
  update_sensorflow_gateway_health_data_many?: Maybe<Array<Maybe<Sensorflow_Gateway_Health_Data_Mutation_Response>>>;
  update_sensorflow_gateway_to_position_mapping?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Mutation_Response>;
  update_sensorflow_gateway_to_position_mapping_by_pk?: Maybe<Sensorflow_Gateway_To_Position_Mapping>;
  update_sensorflow_gateway_to_position_mapping_many?: Maybe<
    Array<Maybe<Sensorflow_Gateway_To_Position_Mapping_Mutation_Response>>
  >;
  update_sensorflow_gateways?: Maybe<Sensorflow_Gateways_Mutation_Response>;
  update_sensorflow_gateways_by_pk?: Maybe<Sensorflow_Gateways>;
  update_sensorflow_gateways_many?: Maybe<Array<Maybe<Sensorflow_Gateways_Mutation_Response>>>;
  update_sensorflow_get_event_stream_view?: Maybe<Sensorflow_Get_Event_Stream_View_Mutation_Response>;
  update_sensorflow_get_event_stream_view_many?: Maybe<
    Array<Maybe<Sensorflow_Get_Event_Stream_View_Mutation_Response>>
  >;
  update_sensorflow_key_categories?: Maybe<Sensorflow_Key_Categories_Mutation_Response>;
  update_sensorflow_key_categories_many?: Maybe<Array<Maybe<Sensorflow_Key_Categories_Mutation_Response>>>;
  update_sensorflow_key_category_room_templates?: Maybe<Sensorflow_Key_Category_Room_Templates_Mutation_Response>;
  update_sensorflow_key_category_room_templates_by_pk?: Maybe<Sensorflow_Key_Category_Room_Templates>;
  update_sensorflow_key_category_room_templates_many?: Maybe<
    Array<Maybe<Sensorflow_Key_Category_Room_Templates_Mutation_Response>>
  >;
  update_sensorflow_key_category_slot_templates?: Maybe<Sensorflow_Key_Category_Slot_Templates_Mutation_Response>;
  update_sensorflow_key_category_slot_templates_by_pk?: Maybe<Sensorflow_Key_Category_Slot_Templates>;
  update_sensorflow_key_category_slot_templates_many?: Maybe<
    Array<Maybe<Sensorflow_Key_Category_Slot_Templates_Mutation_Response>>
  >;
  update_sensorflow_key_category_templates?: Maybe<Sensorflow_Key_Category_Templates_Mutation_Response>;
  update_sensorflow_key_category_templates_by_pk?: Maybe<Sensorflow_Key_Category_Templates>;
  update_sensorflow_key_category_templates_many?: Maybe<
    Array<Maybe<Sensorflow_Key_Category_Templates_Mutation_Response>>
  >;
  update_sensorflow_key_category_to_key_position_by_pk?: Maybe<Sensorflow_Key_Category_To_Key_Position>;
  update_sensorflow_key_category_to_key_position_many?: Maybe<
    Array<Maybe<Sensorflow_Key_Category_To_Key_Position_Mutation_Response>>
  >;
  update_sensorflow_location_addresses?: Maybe<Sensorflow_Location_Addresses_Mutation_Response>;
  update_sensorflow_location_addresses_by_pk?: Maybe<Sensorflow_Location_Addresses>;
  update_sensorflow_location_addresses_many?: Maybe<Array<Maybe<Sensorflow_Location_Addresses_Mutation_Response>>>;
  update_sensorflow_location_consumptions?: Maybe<Sensorflow_Location_Consumptions_Mutation_Response>;
  update_sensorflow_location_consumptions_by_pk?: Maybe<Sensorflow_Location_Consumptions>;
  update_sensorflow_location_consumptions_many?: Maybe<
    Array<Maybe<Sensorflow_Location_Consumptions_Mutation_Response>>
  >;
  update_sensorflow_location_domo_embeds?: Maybe<Sensorflow_Location_Domo_Embeds_Mutation_Response>;
  update_sensorflow_location_domo_embeds_by_pk?: Maybe<Sensorflow_Location_Domo_Embeds>;
  update_sensorflow_location_domo_embeds_many?: Maybe<Array<Maybe<Sensorflow_Location_Domo_Embeds_Mutation_Response>>>;
  update_sensorflow_location_metadata_by_pk?: Maybe<Sensorflow_Location_Metadata>;
  update_sensorflow_location_metadata_many?: Maybe<Array<Maybe<Sensorflow_Location_Metadata_Mutation_Response>>>;
  update_sensorflow_location_occ_customer_data?: Maybe<Sensorflow_Location_Occ_Customer_Data_Mutation_Response>;
  update_sensorflow_location_occ_customer_data_by_pk?: Maybe<Sensorflow_Location_Occ_Customer_Data>;
  update_sensorflow_location_occ_customer_data_many?: Maybe<
    Array<Maybe<Sensorflow_Location_Occ_Customer_Data_Mutation_Response>>
  >;
  update_sensorflow_location_occ_derived_data?: Maybe<Sensorflow_Location_Occ_Derived_Data_Mutation_Response>;
  update_sensorflow_location_occ_derived_data_by_pk?: Maybe<Sensorflow_Location_Occ_Derived_Data>;
  update_sensorflow_location_occ_derived_data_many?: Maybe<
    Array<Maybe<Sensorflow_Location_Occ_Derived_Data_Mutation_Response>>
  >;
  update_sensorflow_location_to_user_mapping?: Maybe<Sensorflow_Location_To_User_Mapping_Mutation_Response>;
  update_sensorflow_location_to_user_mapping_by_pk?: Maybe<Sensorflow_Location_To_User_Mapping>;
  update_sensorflow_location_to_user_mapping_many?: Maybe<
    Array<Maybe<Sensorflow_Location_To_User_Mapping_Mutation_Response>>
  >;
  update_sensorflow_locations?: Maybe<Sensorflow_Locations_Mutation_Response>;
  update_sensorflow_locations_many?: Maybe<Array<Maybe<Sensorflow_Locations_Mutation_Response>>>;
  update_sensorflow_mapping_coordinates?: Maybe<Sensorflow_Mapping_Coordinates_Mutation_Response>;
  update_sensorflow_mapping_coordinates_many?: Maybe<Array<Maybe<Sensorflow_Mapping_Coordinates_Mutation_Response>>>;
  update_sensorflow_metric_types?: Maybe<Sensorflow_Metric_Types_Mutation_Response>;
  update_sensorflow_metric_types_by_pk?: Maybe<Sensorflow_Metric_Types>;
  update_sensorflow_metric_types_many?: Maybe<Array<Maybe<Sensorflow_Metric_Types_Mutation_Response>>>;
  update_sensorflow_node_exception?: Maybe<Sensorflow_Node_Exception_Mutation_Response>;
  update_sensorflow_node_exception_by_pk?: Maybe<Sensorflow_Node_Exception>;
  update_sensorflow_node_exception_many?: Maybe<Array<Maybe<Sensorflow_Node_Exception_Mutation_Response>>>;
  update_sensorflow_node_exceptions?: Maybe<Sensorflow_Node_Exceptions_Mutation_Response>;
  update_sensorflow_node_exceptions_by_pk?: Maybe<Sensorflow_Node_Exceptions>;
  update_sensorflow_node_exceptions_many?: Maybe<Array<Maybe<Sensorflow_Node_Exceptions_Mutation_Response>>>;
  update_sensorflow_node_join_data?: Maybe<Sensorflow_Node_Join_Data_Mutation_Response>;
  update_sensorflow_node_join_data_many?: Maybe<Array<Maybe<Sensorflow_Node_Join_Data_Mutation_Response>>>;
  update_sensorflow_node_life_cycle_event_types?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Mutation_Response>;
  update_sensorflow_node_life_cycle_event_types_by_pk?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types>;
  update_sensorflow_node_life_cycle_event_types_many?: Maybe<
    Array<Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Mutation_Response>>
  >;
  update_sensorflow_node_life_cycle_events?: Maybe<Sensorflow_Node_Life_Cycle_Events_Mutation_Response>;
  update_sensorflow_node_life_cycle_events_by_pk?: Maybe<Sensorflow_Node_Life_Cycle_Events>;
  update_sensorflow_node_life_cycle_events_many?: Maybe<
    Array<Maybe<Sensorflow_Node_Life_Cycle_Events_Mutation_Response>>
  >;
  update_sensorflow_node_measurements?: Maybe<Sensorflow_Node_Measurements_Mutation_Response>;
  update_sensorflow_node_measurements_many?: Maybe<Array<Maybe<Sensorflow_Node_Measurements_Mutation_Response>>>;
  update_sensorflow_node_meta_data?: Maybe<Sensorflow_Node_Meta_Data_Mutation_Response>;
  update_sensorflow_node_meta_data_by_pk?: Maybe<Sensorflow_Node_Meta_Data>;
  update_sensorflow_node_meta_data_many?: Maybe<Array<Maybe<Sensorflow_Node_Meta_Data_Mutation_Response>>>;
  update_sensorflow_node_slots?: Maybe<Sensorflow_Node_Slots_Mutation_Response>;
  update_sensorflow_node_slots_many?: Maybe<Array<Maybe<Sensorflow_Node_Slots_Mutation_Response>>>;
  update_sensorflow_node_spare_set?: Maybe<Sensorflow_Node_Spare_Set_Mutation_Response>;
  update_sensorflow_node_spare_set_by_pk?: Maybe<Sensorflow_Node_Spare_Set>;
  update_sensorflow_node_spare_set_many?: Maybe<Array<Maybe<Sensorflow_Node_Spare_Set_Mutation_Response>>>;
  update_sensorflow_node_status_history?: Maybe<Sensorflow_Node_Status_History_Mutation_Response>;
  update_sensorflow_node_status_history_by_pk?: Maybe<Sensorflow_Node_Status_History>;
  update_sensorflow_node_status_history_many?: Maybe<Array<Maybe<Sensorflow_Node_Status_History_Mutation_Response>>>;
  update_sensorflow_node_statuses?: Maybe<Sensorflow_Node_Statuses_Mutation_Response>;
  update_sensorflow_node_statuses_by_pk?: Maybe<Sensorflow_Node_Statuses>;
  update_sensorflow_node_statuses_many?: Maybe<Array<Maybe<Sensorflow_Node_Statuses_Mutation_Response>>>;
  update_sensorflow_node_system_states?: Maybe<Sensorflow_Node_System_States_Mutation_Response>;
  update_sensorflow_node_system_states_by_pk?: Maybe<Sensorflow_Node_System_States>;
  update_sensorflow_node_system_states_many?: Maybe<Array<Maybe<Sensorflow_Node_System_States_Mutation_Response>>>;
  update_sensorflow_node_to_position_mapping?: Maybe<Sensorflow_Node_To_Position_Mapping_Mutation_Response>;
  update_sensorflow_node_to_position_mapping_many?: Maybe<
    Array<Maybe<Sensorflow_Node_To_Position_Mapping_Mutation_Response>>
  >;
  update_sensorflow_node_to_slot_mapping_by_pk?: Maybe<Sensorflow_Node_To_Slot_Mapping>;
  update_sensorflow_node_to_slot_mapping_many?: Maybe<Array<Maybe<Sensorflow_Node_To_Slot_Mapping_Mutation_Response>>>;
  update_sensorflow_nodes?: Maybe<Sensorflow_Nodes_Mutation_Response>;
  update_sensorflow_nodes_by_pk?: Maybe<Sensorflow_Nodes>;
  update_sensorflow_nodes_many?: Maybe<Array<Maybe<Sensorflow_Nodes_Mutation_Response>>>;
  update_sensorflow_organisations?: Maybe<Sensorflow_Organisations_Mutation_Response>;
  update_sensorflow_organisations_by_pk?: Maybe<Sensorflow_Organisations>;
  update_sensorflow_organisations_many?: Maybe<Array<Maybe<Sensorflow_Organisations_Mutation_Response>>>;
  update_sensorflow_ota_queues?: Maybe<Sensorflow_Ota_Queues_Mutation_Response>;
  update_sensorflow_ota_queues_by_pk?: Maybe<Sensorflow_Ota_Queues>;
  update_sensorflow_ota_queues_many?: Maybe<Array<Maybe<Sensorflow_Ota_Queues_Mutation_Response>>>;
  update_sensorflow_ota_runs?: Maybe<Sensorflow_Ota_Runs_Mutation_Response>;
  update_sensorflow_ota_runs_by_pk?: Maybe<Sensorflow_Ota_Runs>;
  update_sensorflow_ota_runs_many?: Maybe<Array<Maybe<Sensorflow_Ota_Runs_Mutation_Response>>>;
  update_sensorflow_outdoor_temperatures?: Maybe<Sensorflow_Outdoor_Temperatures_Mutation_Response>;
  update_sensorflow_outdoor_temperatures_by_pk?: Maybe<Sensorflow_Outdoor_Temperatures>;
  update_sensorflow_outdoor_temperatures_many?: Maybe<Array<Maybe<Sensorflow_Outdoor_Temperatures_Mutation_Response>>>;
  update_sensorflow_pipes?: Maybe<Sensorflow_Pipes_Mutation_Response>;
  update_sensorflow_pipes_many?: Maybe<Array<Maybe<Sensorflow_Pipes_Mutation_Response>>>;
  update_sensorflow_position_signal_stats?: Maybe<Sensorflow_Position_Signal_Stats_Mutation_Response>;
  update_sensorflow_position_signal_stats_by_pk?: Maybe<Sensorflow_Position_Signal_Stats>;
  update_sensorflow_position_signal_stats_many?: Maybe<
    Array<Maybe<Sensorflow_Position_Signal_Stats_Mutation_Response>>
  >;
  update_sensorflow_positions_many?: Maybe<Array<Maybe<Sensorflow_Positions_Mutation_Response>>>;
  update_sensorflow_precool_commands?: Maybe<Sensorflow_Precool_Commands_Mutation_Response>;
  update_sensorflow_precool_commands_by_pk?: Maybe<Sensorflow_Precool_Commands>;
  update_sensorflow_precool_commands_many?: Maybe<Array<Maybe<Sensorflow_Precool_Commands_Mutation_Response>>>;
  update_sensorflow_precool_schedules?: Maybe<Sensorflow_Precool_Schedules_Mutation_Response>;
  update_sensorflow_precool_schedules_by_pk?: Maybe<Sensorflow_Precool_Schedules>;
  update_sensorflow_precool_schedules_many?: Maybe<Array<Maybe<Sensorflow_Precool_Schedules_Mutation_Response>>>;
  update_sensorflow_properties_information?: Maybe<Sensorflow_Properties_Information_Mutation_Response>;
  update_sensorflow_properties_information_by_pk?: Maybe<Sensorflow_Properties_Information>;
  update_sensorflow_properties_information_many?: Maybe<
    Array<Maybe<Sensorflow_Properties_Information_Mutation_Response>>
  >;
  update_sensorflow_pumps?: Maybe<Sensorflow_Pumps_Mutation_Response>;
  update_sensorflow_pumps_many?: Maybe<Array<Maybe<Sensorflow_Pumps_Mutation_Response>>>;
  update_sensorflow_reservation_guests?: Maybe<Sensorflow_Reservation_Guests_Mutation_Response>;
  update_sensorflow_reservation_guests_by_pk?: Maybe<Sensorflow_Reservation_Guests>;
  update_sensorflow_reservation_guests_many?: Maybe<Array<Maybe<Sensorflow_Reservation_Guests_Mutation_Response>>>;
  update_sensorflow_reservations?: Maybe<Sensorflow_Reservations_Mutation_Response>;
  update_sensorflow_reservations_by_pk?: Maybe<Sensorflow_Reservations>;
  update_sensorflow_reservations_many?: Maybe<Array<Maybe<Sensorflow_Reservations_Mutation_Response>>>;
  update_sensorflow_roles?: Maybe<Sensorflow_Roles_Mutation_Response>;
  update_sensorflow_roles_by_pk?: Maybe<Sensorflow_Roles>;
  update_sensorflow_roles_many?: Maybe<Array<Maybe<Sensorflow_Roles_Mutation_Response>>>;
  update_sensorflow_smart_rem_validation_history?: Maybe<Sensorflow_Smart_Rem_Validation_History_Mutation_Response>;
  update_sensorflow_smart_rem_validation_history_by_pk?: Maybe<Sensorflow_Smart_Rem_Validation_History>;
  update_sensorflow_smart_rem_validation_history_many?: Maybe<
    Array<Maybe<Sensorflow_Smart_Rem_Validation_History_Mutation_Response>>
  >;
  update_sensorflow_smplrspace_floors_to_levels?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Mutation_Response>;
  update_sensorflow_smplrspace_floors_to_levels_by_pk?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels>;
  update_sensorflow_smplrspace_floors_to_levels_many?: Maybe<
    Array<Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Mutation_Response>>
  >;
  update_sensorflow_smplrspace_levels_group?: Maybe<Sensorflow_Smplrspace_Levels_Group_Mutation_Response>;
  update_sensorflow_smplrspace_levels_group_by_pk?: Maybe<Sensorflow_Smplrspace_Levels_Group>;
  update_sensorflow_smplrspace_levels_group_many?: Maybe<
    Array<Maybe<Sensorflow_Smplrspace_Levels_Group_Mutation_Response>>
  >;
  update_sensorflow_survey_responses?: Maybe<Sensorflow_Survey_Responses_Mutation_Response>;
  update_sensorflow_survey_responses_by_pk?: Maybe<Sensorflow_Survey_Responses>;
  update_sensorflow_survey_responses_many?: Maybe<Array<Maybe<Sensorflow_Survey_Responses_Mutation_Response>>>;
  update_sensorflow_task_status_history?: Maybe<Sensorflow_Task_Status_History_Mutation_Response>;
  update_sensorflow_task_status_history_by_pk?: Maybe<Sensorflow_Task_Status_History>;
  update_sensorflow_task_status_history_many?: Maybe<Array<Maybe<Sensorflow_Task_Status_History_Mutation_Response>>>;
  update_sensorflow_tasks?: Maybe<Sensorflow_Tasks_Mutation_Response>;
  update_sensorflow_tasks_by_pk?: Maybe<Sensorflow_Tasks>;
  update_sensorflow_tasks_many?: Maybe<Array<Maybe<Sensorflow_Tasks_Mutation_Response>>>;
  update_sensorflow_tests?: Maybe<Sensorflow_Tests_Mutation_Response>;
  update_sensorflow_tests_by_pk?: Maybe<Sensorflow_Tests>;
  update_sensorflow_tests_many?: Maybe<Array<Maybe<Sensorflow_Tests_Mutation_Response>>>;
  update_sensorflow_timezones?: Maybe<Sensorflow_Timezones_Mutation_Response>;
  update_sensorflow_timezones_by_pk?: Maybe<Sensorflow_Timezones>;
  update_sensorflow_timezones_many?: Maybe<Array<Maybe<Sensorflow_Timezones_Mutation_Response>>>;
  update_sensorflow_user_to_permission_mapping?: Maybe<Sensorflow_User_To_Permission_Mapping_Mutation_Response>;
  update_sensorflow_user_to_permission_mapping_many?: Maybe<
    Array<Maybe<Sensorflow_User_To_Permission_Mapping_Mutation_Response>>
  >;
  update_sensorflow_users?: Maybe<Sensorflow_Users_Mutation_Response>;
  update_sensorflow_users_by_pk?: Maybe<Sensorflow_Users>;
  update_sensorflow_users_many?: Maybe<Array<Maybe<Sensorflow_Users_Mutation_Response>>>;
  update_sensorflow_warehouse_kitting_process_metadata?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Mutation_Response>;
  update_sensorflow_warehouse_kitting_process_metadata_by_pk?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata>;
  update_sensorflow_warehouse_kitting_process_metadata_many?: Maybe<
    Array<Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Mutation_Response>>
  >;
  update_sf_firmware_dist_pipeline_firmware_build?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Mutation_Response>;
  update_sf_firmware_dist_pipeline_firmware_build_by_pk?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build>;
  update_sf_firmware_dist_pipeline_firmware_build_many?: Maybe<
    Array<Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Mutation_Response>>
  >;
  update_sf_firmware_dist_pipeline_firmware_numbers?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Mutation_Response>;
  update_sf_firmware_dist_pipeline_firmware_numbers_by_pk?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers>;
  update_sf_firmware_dist_pipeline_firmware_numbers_many?: Maybe<
    Array<Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Mutation_Response>>
  >;
  update_sf_pms_pms_pmslist?: Maybe<Sf_Pms_Pms_Pmslist_Mutation_Response>;
  update_sf_pms_pms_pmslist_by_pk?: Maybe<Sf_Pms_Pms_Pmslist>;
  update_sf_pms_pms_pmslist_many?: Maybe<Array<Maybe<Sf_Pms_Pms_Pmslist_Mutation_Response>>>;
  update_sf_pms_pms_properties?: Maybe<Sf_Pms_Pms_Properties_Mutation_Response>;
  update_sf_pms_pms_properties_by_pk?: Maybe<Sf_Pms_Pms_Properties>;
  update_sf_pms_pms_properties_many?: Maybe<Array<Maybe<Sf_Pms_Pms_Properties_Mutation_Response>>>;
  update_sf_pms_pms_property_eod_surveys?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Mutation_Response>;
  update_sf_pms_pms_property_eod_surveys_by_pk?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys>;
  update_sf_pms_pms_property_eod_surveys_many?: Maybe<Array<Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Mutation_Response>>>;
  update_sf_pms_pms_reservations?: Maybe<Sf_Pms_Pms_Reservations_Mutation_Response>;
  update_sf_pms_pms_reservations_many?: Maybe<Array<Maybe<Sf_Pms_Pms_Reservations_Mutation_Response>>>;
  update_sf_pms_pms_room_categories?: Maybe<Sf_Pms_Pms_Room_Categories_Mutation_Response>;
  update_sf_pms_pms_room_categories_many?: Maybe<Array<Maybe<Sf_Pms_Pms_Room_Categories_Mutation_Response>>>;
  update_sf_pms_pms_rooms?: Maybe<Sf_Pms_Pms_Rooms_Mutation_Response>;
  update_sf_pms_pms_rooms_many?: Maybe<Array<Maybe<Sf_Pms_Pms_Rooms_Mutation_Response>>>;
  update_sf_pms_smartalloc_report?: Maybe<Sf_Pms_Smartalloc_Report_Mutation_Response>;
  update_sf_pms_smartalloc_report_by_pk?: Maybe<Sf_Pms_Smartalloc_Report>;
  update_sf_pms_smartalloc_report_many?: Maybe<Array<Maybe<Sf_Pms_Smartalloc_Report_Mutation_Response>>>;
  update_sf_simulation_instrumentation_commands?: Maybe<Sf_Simulation_Instrumentation_Commands_Mutation_Response>;
  update_sf_simulation_instrumentation_commands_by_pk?: Maybe<Sf_Simulation_Instrumentation_Commands>;
  update_sf_simulation_instrumentation_commands_many?: Maybe<
    Array<Maybe<Sf_Simulation_Instrumentation_Commands_Mutation_Response>>
  >;
  update_sf_support_actions?: Maybe<Sf_Support_Actions_Mutation_Response>;
  update_sf_support_actions_by_pk?: Maybe<Sf_Support_Actions>;
  update_sf_support_actions_many?: Maybe<Array<Maybe<Sf_Support_Actions_Mutation_Response>>>;
  update_sf_support_active_property_alerts?: Maybe<Sf_Support_Active_Property_Alerts_Mutation_Response>;
  update_sf_support_active_property_alerts_by_pk?: Maybe<Sf_Support_Active_Property_Alerts>;
  update_sf_support_active_property_alerts_many?: Maybe<
    Array<Maybe<Sf_Support_Active_Property_Alerts_Mutation_Response>>
  >;
  update_sf_support_analytics_ticket_jobs_running_histories?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Mutation_Response>;
  update_sf_support_analytics_ticket_jobs_running_histories_by_pk?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories>;
  update_sf_support_analytics_ticket_jobs_running_histories_many?: Maybe<
    Array<Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Mutation_Response>>
  >;
  update_sf_support_root_causes?: Maybe<Sf_Support_Root_Causes_Mutation_Response>;
  update_sf_support_root_causes_by_pk?: Maybe<Sf_Support_Root_Causes>;
  update_sf_support_root_causes_many?: Maybe<Array<Maybe<Sf_Support_Root_Causes_Mutation_Response>>>;
  update_sf_support_ticket_classes?: Maybe<Sf_Support_Ticket_Classes_Mutation_Response>;
  update_sf_support_ticket_classes_by_pk?: Maybe<Sf_Support_Ticket_Classes>;
  update_sf_support_ticket_classes_many?: Maybe<Array<Maybe<Sf_Support_Ticket_Classes_Mutation_Response>>>;
  update_sf_support_ticket_status?: Maybe<Sf_Support_Ticket_Status_Mutation_Response>;
  update_sf_support_ticket_status_by_pk?: Maybe<Sf_Support_Ticket_Status>;
  update_sf_support_ticket_status_many?: Maybe<Array<Maybe<Sf_Support_Ticket_Status_Mutation_Response>>>;
  update_sf_support_tickets?: Maybe<Sf_Support_Tickets_Mutation_Response>;
  update_sf_support_tickets_by_pk?: Maybe<Sf_Support_Tickets>;
  update_sf_support_tickets_many?: Maybe<Array<Maybe<Sf_Support_Tickets_Mutation_Response>>>;
  update_sf_testjig_test_jig_maintenance_report_many?: Maybe<
    Array<Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Mutation_Response>>
  >;
  update_sf_testjig_test_jig_many?: Maybe<Array<Maybe<Sf_Testjig_Test_Jig_Mutation_Response>>>;
  update_sf_testjig_test_jig_report_metadata?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Mutation_Response>;
  update_sf_testjig_test_jig_report_metadata_by_pk?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata>;
  update_sf_testjig_test_jig_report_metadata_many?: Maybe<
    Array<Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Mutation_Response>>
  >;
  update_sf_testjig_test_jig_report_metadatas?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Mutation_Response>;
  update_sf_testjig_test_jig_report_metadatas_by_pk?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas>;
  update_sf_testjig_test_jig_report_metadatas_many?: Maybe<
    Array<Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Mutation_Response>>
  >;
  update_sf_testjig_test_jig_test_report_many?: Maybe<Array<Maybe<Sf_Testjig_Test_Jig_Test_Report_Mutation_Response>>>;
  update_sf_testjig_test_jig_type_many?: Maybe<Array<Maybe<Sf_Testjig_Test_Jig_Type_Mutation_Response>>>;
  update_testRuns_by_pk?: Maybe<TestRuns>;
  update_testRuns_many?: Maybe<Array<Maybe<TestRuns_Mutation_Response>>>;
  userCreate: User;
  userDisable: GenericResponse;
  userResendWelcomeEmail: GenericResponse;
  userResetPassword: GenericResponse;
  userUpdate: User;
};

export type Mutation_RootAcModelCreateArgs = {
  ACModel: AcModelInput;
};

export type Mutation_RootAcModelDisableArgs = {
  ACModelId: Scalars["String"];
};

export type Mutation_RootAcModelUpdateArgs = {
  ACModel: AcModelInput;
  ACModelId: Scalars["String"];
};

export type Mutation_RootActivateComfortPlusArgs = {
  input: ActivateComfortPlusInput;
};

export type Mutation_RootAddLocationDomoEmbedArgs = {
  object: Sensorflow_Location_Domo_Embeds_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Location_Domo_Embeds_On_Conflict>;
};

export type Mutation_RootAddPrecoolCommandsArgs = {
  mode: Scalars["Int"];
  positionIds: Array<Scalars["ID"]>;
  startedAt?: Maybe<Scalars["String"]>;
  temperature: Scalars["Int"];
  totalMinutes: Scalars["Int"];
};

export type Mutation_RootBulkAssignCompressorPositionsArgs = {
  compressorIds?: Maybe<Array<Scalars["String"]>>;
  positionId?: Maybe<Scalars["String"]>;
};

export type Mutation_RootBulkUpdateDefaultPositionConfigurationsArgs = {
  positionConfigurations: Array<PositionConfigurationInput>;
};

export type Mutation_RootBulkUpdatePositionConfigurationsArgs = {
  positionConfigurations: Array<PositionConfigurationInput>;
};

export type Mutation_RootCancelPrecoolCommandArgs = {
  roomIds: Array<Scalars["ID"]>;
};

export type Mutation_RootCancelTimerAndResetToDefaultConfigArgs = {
  roomIds: Array<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
};

export type Mutation_RootChangeBatteryArgs = {
  comment?: Maybe<Scalars["String"]>;
  nodeMacId: Scalars["String"];
};

export type Mutation_RootChangeDisabledAutomationModeStatusArgs = {
  isAutomationModeDisabled: Scalars["Boolean"];
  locationId: Scalars["ID"];
};

export type Mutation_RootChangeInfraTypeArgs = {
  coolingSystemId?: Maybe<Scalars["String"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  infraId: Scalars["ID"];
  newType: Scalars["String"];
  oldType: Scalars["String"];
};

export type Mutation_RootClaimKeyArgs = {
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
  keyPositionId: Scalars["ID"];
  positionFunction: Scalars["String"];
  userId: Scalars["ID"];
};

export type Mutation_RootCreateAcSettingCommandsArgs = {
  objects: Array<Sensorflow_Ac_Setting_Commands_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Ac_Setting_Commands_On_Conflict>;
};

export type Mutation_RootCreateChillerArgs = {
  object: Sensorflow_Chillers_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Chillers_On_Conflict>;
};

export type Mutation_RootCreateCompressorArgs = {
  object: Sensorflow_Compressors_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Compressors_On_Conflict>;
};

export type Mutation_RootCreateCompressorPositionMappingsArgs = {
  objects: Array<Sensorflow_Compressor_Mapping_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Compressor_Mapping_On_Conflict>;
};

export type Mutation_RootCreateCompressorsArgs = {
  objects: Array<Sensorflow_Compressors_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Compressors_On_Conflict>;
};

export type Mutation_RootCreateCoolingTowerArgs = {
  object: Sensorflow_Cooling_Towers_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Cooling_Towers_On_Conflict>;
};

export type Mutation_RootCreateDomoEmbedArgs = {
  object: Sensorflow_Domo_Embeds_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Domo_Embeds_On_Conflict>;
};

export type Mutation_RootCreateFirmwareReleaseArgs = {
  object: Sensorflow_Firmware_Releases_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Firmware_Releases_On_Conflict>;
};

export type Mutation_RootCreateGatewayToPositionMappingArgs = {
  object: Sensorflow_Gateway_To_Position_Mapping_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Gateway_To_Position_Mapping_On_Conflict>;
};

export type Mutation_RootCreateInfraArgs = {
  compressorId?: Maybe<Scalars["ID"]>;
  ctMultiplier?: Maybe<Scalars["Float"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  locationId: Scalars["ID"];
  name: Scalars["String"];
  numberOfPhases?: Maybe<Scalars["Int"]>;
  type: Scalars["String"];
};

export type Mutation_RootCreateKeyCategoryArgs = {
  object: Sensorflow_Key_Categories_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Key_Categories_On_Conflict>;
};

export type Mutation_RootCreateKeyCategoryTemplatesArgs = {
  objects: Array<Sensorflow_Key_Category_Templates_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Key_Category_Templates_On_Conflict>;
};

export type Mutation_RootCreateKeyCategoryToKeyMappingsArgs = {
  objects: Array<Sensorflow_Key_Category_To_Key_Position_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Key_Category_To_Key_Position_On_Conflict>;
};

export type Mutation_RootCreateLocationArgs = {
  object: Sensorflow_Locations_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Locations_On_Conflict>;
};

export type Mutation_RootCreateLocationMetadataArgs = {
  object: Sensorflow_Location_Metadata_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Location_Metadata_On_Conflict>;
};

export type Mutation_RootCreateNodeLifeCycleEventArgs = {
  comment?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["String"]>;
  eventType: Scalars["Int"];
  nodeMacId: Scalars["String"];
  nodeTypeCode?: Maybe<Scalars["String"]>;
};

export type Mutation_RootCreateNodeSpareSetArgs = {
  locationId: Scalars["ID"];
  nodeMacId: Scalars["String"];
  nodeSubType?: Maybe<NodeSubType>;
  nodeType: NodeType;
};

export type Mutation_RootCreateOrganisationArgs = {
  object: Sensorflow_Organisations_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Organisations_On_Conflict>;
};

export type Mutation_RootCreatePipeArgs = {
  object: Sensorflow_Pipes_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Pipes_On_Conflict>;
};

export type Mutation_RootCreatePositionArgs = {
  object: Sensorflow_Positions_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Positions_On_Conflict>;
};

export type Mutation_RootCreatePositionsArgs = {
  objects: Array<Sensorflow_Positions_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Positions_On_Conflict>;
};

export type Mutation_RootCreatePrecoolCommandsArgs = {
  objects: Array<Sensorflow_Precool_Commands_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Precool_Commands_On_Conflict>;
};

export type Mutation_RootCreatePumpArgs = {
  object: Sensorflow_Pumps_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Pumps_On_Conflict>;
};

export type Mutation_RootCreateRmaRequestArgs = {
  input: CreateRmaRequestInput;
};

export type Mutation_RootCreateRoomArgs = {
  acModelId?: Maybe<Scalars["String"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  locationId: Scalars["ID"];
  parentPositionId: Scalars["ID"];
  positionName: Scalars["String"];
  slots?: Maybe<Array<Maybe<SlotCreateRoomInput>>>;
};

export type Mutation_RootCreateRoomCompressorsArgs = {
  compressors: Array<RoomCompressorCreateInput>;
};

export type Mutation_RootCreateSlotArgs = {
  object: Sensorflow_Node_Slots_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Slots_On_Conflict>;
};

export type Mutation_RootCreateSlotsArgs = {
  slots: Array<SlotCreateInput>;
};

export type Mutation_RootCreateSmartRemValidationHistoryArgs = {
  object: Sensorflow_Smart_Rem_Validation_History_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Smart_Rem_Validation_History_On_Conflict>;
};

export type Mutation_RootCreateTestJigArgs = {
  object: Sf_Testjig_Test_Jig_Insert_Input;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_On_Conflict>;
};

export type Mutation_RootCreateTestJigMaintenanceReportArgs = {
  object: Sf_Testjig_Test_Jig_Maintenance_Report_Insert_Input;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_On_Conflict>;
};

export type Mutation_RootCreateTestJigMaintenanceReportsArgs = {
  objects: Array<Sf_Testjig_Test_Jig_Maintenance_Report_Insert_Input>;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_On_Conflict>;
};

export type Mutation_RootCreateTestJigTestReportArgs = {
  object: Sf_Testjig_Test_Jig_Test_Report_Insert_Input;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Test_Report_On_Conflict>;
};

export type Mutation_RootCreateTestJigTestReportsArgs = {
  objects: Array<Sf_Testjig_Test_Jig_Test_Report_Insert_Input>;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Test_Report_On_Conflict>;
};

export type Mutation_RootCreateTestJigTypeArgs = {
  object: Sf_Testjig_Test_Jig_Type_Insert_Input;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Type_On_Conflict>;
};

export type Mutation_RootCreateTestJigTypesArgs = {
  objects: Array<Sf_Testjig_Test_Jig_Type_Insert_Input>;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Type_On_Conflict>;
};

export type Mutation_RootCreateTestJigsArgs = {
  objects: Array<Sf_Testjig_Test_Jig_Insert_Input>;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_On_Conflict>;
};

export type Mutation_RootDeactivateComfortPlusArgs = {
  comment: Scalars["String"];
  reservationId: Scalars["Int"];
};

export type Mutation_RootDeleteCompressorArgs = {
  compressorId: Scalars["uuid"];
};

export type Mutation_RootDeleteCompressorPositionMappingsArgs = {
  where: Sensorflow_Compressor_Mapping_Bool_Exp;
};

export type Mutation_RootDeleteDomoEmbedArgs = {
  embed_id: Scalars["String"];
};

export type Mutation_RootDeleteFirmwareReleaseArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDeleteGatewayArgs = {
  gatewayId: Scalars["ID"];
};

export type Mutation_RootDeleteGroupArgs = {
  groupId: Scalars["String"];
};

export type Mutation_RootDeleteGuestPrecoolScheduleArgs = {
  scheduleId: Scalars["Int"];
};

export type Mutation_RootDeleteInfraArgs = {
  infraId: Scalars["ID"];
};

export type Mutation_RootDeleteKeyCategoryArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDeleteKeyCategoryTemplatesArgs = {
  where: Sensorflow_Key_Category_Templates_Bool_Exp;
};

export type Mutation_RootDeletePositionArgs = {
  positionId: Scalars["ID"];
};

export type Mutation_RootDeletePositionsArgs = {
  positionIds: Array<Scalars["ID"]>;
};

export type Mutation_RootDeleteReservastionGuestArgs = {
  reservationId: Scalars["bigint"];
  userId: Scalars["String"];
};

export type Mutation_RootDeleteSlotArgs = {
  id: Scalars["ID"];
};

export type Mutation_RootDelete_KeyEntriesArgs = {
  where: KeyEntries_Bool_Exp;
};

export type Mutation_RootDelete_KeyEntries_By_PkArgs = {
  keyEntryId: Scalars["Int"];
};

export type Mutation_RootDelete_PositionConfigurationsArgs = {
  where: PositionConfigurations_Bool_Exp;
};

export type Mutation_RootDelete_PositionConfigurations_By_PkArgs = {
  creationDate: Scalars["timestamptz"];
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Ac_Command_Event_StreamArgs = {
  where: Sensorflow_Ac_Command_Event_Stream_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Ac_ModelsArgs = {
  where: Sensorflow_Ac_Models_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Ac_Models_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Ac_Setting_CommandsArgs = {
  where: Sensorflow_Ac_Setting_Commands_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Ac_Setting_Commands_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Ai_Dt_Ac_Event_StreamArgs = {
  where: Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Ai_Dt_Config_Event_StreamArgs = {
  where: Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Beacon_SlotsArgs = {
  where: Sensorflow_Beacon_Slots_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Beacon_Slots_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Checklist_ItemsArgs = {
  where: Sensorflow_Checklist_Items_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_ChecklistsArgs = {
  where: Sensorflow_Checklists_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Checklists_By_PkArgs = {
  id: Scalars["String"];
};

export type Mutation_RootDelete_Sensorflow_ChillersArgs = {
  where: Sensorflow_Chillers_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Chillers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Clickup_Webhook_EventsArgs = {
  where: Sensorflow_Clickup_Webhook_Events_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Clickup_Webhook_Events_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Comfortplus_ActivationsArgs = {
  where: Sensorflow_Comfortplus_Activations_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Comfortplus_Activations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Compressor_Mapping_By_PkArgs = {
  compressorId: Scalars["uuid"];
  positionId: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_CompressorsArgs = {
  where: Sensorflow_Compressors_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Config_Event_StreamArgs = {
  where: Sensorflow_Config_Event_Stream_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Cooling_TowersArgs = {
  where: Sensorflow_Cooling_Towers_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Cooling_Towers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_CurrenciesArgs = {
  where: Sensorflow_Currencies_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Currencies_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Domo_EmbedsArgs = {
  where: Sensorflow_Domo_Embeds_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Entity_Feature_Flag_MappingsArgs = {
  where: Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Entity_Feature_Flag_Mappings_By_PkArgs = {
  entity_id: Scalars["String"];
  feature_flag_id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Feature_FlagsArgs = {
  where: Sensorflow_Feature_Flags_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Feature_Flags_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Firmware_ReleasesArgs = {
  where: Sensorflow_Firmware_Releases_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Gateway_FrequencyArgs = {
  where: Sensorflow_Gateway_Frequency_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Gateway_Frequency_By_PkArgs = {
  balena_id: Scalars["String"];
  reserved_frequency: Scalars["bigint"];
};

export type Mutation_RootDelete_Sensorflow_Gateway_Health_DataArgs = {
  where: Sensorflow_Gateway_Health_Data_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Gateway_To_Position_MappingArgs = {
  where: Sensorflow_Gateway_To_Position_Mapping_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Gateway_To_Position_Mapping_By_PkArgs = {
  positionId: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_GatewaysArgs = {
  where: Sensorflow_Gateways_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Gateways_By_PkArgs = {
  gatewayId: Scalars["String"];
};

export type Mutation_RootDelete_Sensorflow_Get_Event_Stream_ViewArgs = {
  where: Sensorflow_Get_Event_Stream_View_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Key_CategoriesArgs = {
  where: Sensorflow_Key_Categories_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Key_Category_Room_TemplatesArgs = {
  where: Sensorflow_Key_Category_Room_Templates_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Key_Category_Room_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Key_Category_Slot_TemplatesArgs = {
  where: Sensorflow_Key_Category_Slot_Templates_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Key_Category_Slot_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Key_Category_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Key_Category_To_Key_PositionArgs = {
  where: Sensorflow_Key_Category_To_Key_Position_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Key_Category_To_Key_Position_By_PkArgs = {
  categoryName: Scalars["String"];
  locationName: Scalars["String"];
  positionId: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Location_AddressesArgs = {
  where: Sensorflow_Location_Addresses_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Location_Addresses_By_PkArgs = {
  location_id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Location_ConsumptionsArgs = {
  where: Sensorflow_Location_Consumptions_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Location_Consumptions_By_PkArgs = {
  consumptionPeriodEnd: Scalars["date"];
  consumptionPeriodStart: Scalars["date"];
  locationId: Scalars["uuid"];
  metricTypeId: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Location_Domo_EmbedsArgs = {
  where: Sensorflow_Location_Domo_Embeds_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Location_MetadataArgs = {
  where: Sensorflow_Location_Metadata_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Location_Metadata_By_PkArgs = {
  locationId: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Location_Occ_Customer_DataArgs = {
  where: Sensorflow_Location_Occ_Customer_Data_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Location_Occ_Customer_Data_By_PkArgs = {
  locationId: Scalars["uuid"];
  periodEnd: Scalars["date"];
  periodStart: Scalars["date"];
};

export type Mutation_RootDelete_Sensorflow_Location_Occ_Derived_DataArgs = {
  where: Sensorflow_Location_Occ_Derived_Data_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Location_Occ_Derived_Data_By_PkArgs = {
  date: Scalars["date"];
  locationId: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Location_To_User_MappingArgs = {
  where: Sensorflow_Location_To_User_Mapping_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Location_To_User_Mapping_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_LocationsArgs = {
  where: Sensorflow_Locations_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Locations_By_PkArgs = {
  locationId: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Mapping_CoordinatesArgs = {
  where: Sensorflow_Mapping_Coordinates_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Metric_TypesArgs = {
  where: Sensorflow_Metric_Types_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Metric_Types_By_PkArgs = {
  metricTypeId: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Node_ExceptionArgs = {
  where: Sensorflow_Node_Exception_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Exception_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Node_ExceptionsArgs = {
  where: Sensorflow_Node_Exceptions_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Exceptions_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Node_Join_DataArgs = {
  where: Sensorflow_Node_Join_Data_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Life_Cycle_Event_TypesArgs = {
  where: Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Life_Cycle_Event_Types_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Node_Life_Cycle_EventsArgs = {
  where: Sensorflow_Node_Life_Cycle_Events_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Life_Cycle_Events_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Node_MeasurementsArgs = {
  where: Sensorflow_Node_Measurements_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Meta_DataArgs = {
  where: Sensorflow_Node_Meta_Data_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Meta_Data_By_PkArgs = {
  measurementType: Scalars["String"];
  nodeMacId: Scalars["String"];
  time: Scalars["timestamptz"];
};

export type Mutation_RootDelete_Sensorflow_Node_SlotsArgs = {
  where: Sensorflow_Node_Slots_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Slots_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Node_Spare_SetArgs = {
  where: Sensorflow_Node_Spare_Set_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Spare_Set_By_PkArgs = {
  locationId: Scalars["uuid"];
  nodeMacId: Scalars["String"];
};

export type Mutation_RootDelete_Sensorflow_Node_Status_HistoryArgs = {
  where: Sensorflow_Node_Status_History_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Status_History_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Node_StatusesArgs = {
  where: Sensorflow_Node_Statuses_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_Statuses_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Node_System_StatesArgs = {
  where: Sensorflow_Node_System_States_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_System_States_By_PkArgs = {
  nodeMacId: Scalars["String"];
  time: Scalars["timestamptz"];
};

export type Mutation_RootDelete_Sensorflow_Node_To_Position_MappingArgs = {
  where: Sensorflow_Node_To_Position_Mapping_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_To_Slot_MappingArgs = {
  where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Node_To_Slot_Mapping_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_NodesArgs = {
  where: Sensorflow_Nodes_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Nodes_By_PkArgs = {
  node_mac_id: Scalars["String"];
};

export type Mutation_RootDelete_Sensorflow_OrganisationsArgs = {
  where: Sensorflow_Organisations_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Organisations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Ota_QueuesArgs = {
  where: Sensorflow_Ota_Queues_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Ota_Queues_By_PkArgs = {
  ota_queue_id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Ota_RunsArgs = {
  where: Sensorflow_Ota_Runs_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Ota_Runs_By_PkArgs = {
  current_firmware_release_id: Scalars["Int"];
  node_mac_id: Scalars["String"];
  ota_queue_id: Scalars["Int"];
  target_firmware_release_id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Outdoor_TemperaturesArgs = {
  where: Sensorflow_Outdoor_Temperatures_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Outdoor_Temperatures_By_PkArgs = {
  positionId: Scalars["uuid"];
  time: Scalars["timestamptz"];
};

export type Mutation_RootDelete_Sensorflow_PipesArgs = {
  where: Sensorflow_Pipes_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Pipes_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Position_Signal_StatsArgs = {
  where: Sensorflow_Position_Signal_Stats_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Position_Signal_Stats_By_PkArgs = {
  balena_id: Scalars["String"];
  position_id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_PositionsArgs = {
  where: Sensorflow_Positions_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Positions_By_PkArgs = {
  positionId: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Precool_CommandsArgs = {
  where: Sensorflow_Precool_Commands_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Precool_Commands_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Precool_SchedulesArgs = {
  where: Sensorflow_Precool_Schedules_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Precool_Schedules_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Mutation_RootDelete_Sensorflow_Properties_InformationArgs = {
  where: Sensorflow_Properties_Information_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Properties_Information_By_PkArgs = {
  locationId: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_PumpsArgs = {
  where: Sensorflow_Pumps_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Pumps_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Reservation_GuestsArgs = {
  where: Sensorflow_Reservation_Guests_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_ReservationsArgs = {
  where: Sensorflow_Reservations_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Reservations_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Mutation_RootDelete_Sensorflow_RolesArgs = {
  where: Sensorflow_Roles_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Roles_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Smart_Rem_Validation_HistoryArgs = {
  where: Sensorflow_Smart_Rem_Validation_History_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Smart_Rem_Validation_History_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Smplrspace_Floors_To_LevelsArgs = {
  where: Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Smplrspace_Floors_To_Levels_By_PkArgs = {
  position_id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Smplrspace_Levels_GroupArgs = {
  where: Sensorflow_Smplrspace_Levels_Group_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Smplrspace_Levels_Group_By_PkArgs = {
  groupId: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_Survey_ResponsesArgs = {
  where: Sensorflow_Survey_Responses_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Survey_Responses_By_PkArgs = {
  responseId: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_Task_Status_HistoryArgs = {
  where: Sensorflow_Task_Status_History_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Task_Status_History_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_TasksArgs = {
  where: Sensorflow_Tasks_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Tasks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sensorflow_TestsArgs = {
  where: Sensorflow_Tests_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Tests_By_PkArgs = {
  testId: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_TimezonesArgs = {
  where: Sensorflow_Timezones_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Timezones_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sensorflow_User_To_Permission_MappingArgs = {
  where: Sensorflow_User_To_Permission_Mapping_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_UsersArgs = {
  where: Sensorflow_Users_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Users_By_PkArgs = {
  userId: Scalars["String"];
};

export type Mutation_RootDelete_Sensorflow_Warehouse_Kitting_Process_MetadataArgs = {
  where: Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp;
};

export type Mutation_RootDelete_Sensorflow_Warehouse_Kitting_Process_Metadata_By_PkArgs = {
  balenaId: Scalars["String"];
};

export type Mutation_RootDelete_Sf_Firmware_Dist_Pipeline_Firmware_BuildArgs = {
  where: Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Firmware_Dist_Pipeline_Firmware_Build_By_PkArgs = {
  buildId: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Firmware_Dist_Pipeline_Firmware_NumbersArgs = {
  where: Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Firmware_Dist_Pipeline_Firmware_Numbers_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Pms_Pms_PmslistArgs = {
  where: Sf_Pms_Pms_Pmslist_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Pms_Pms_Pmslist_By_PkArgs = {
  pms_id: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Pms_Pms_PropertiesArgs = {
  where: Sf_Pms_Pms_Properties_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Pms_Pms_Properties_By_PkArgs = {
  pms_id: Scalars["Int"];
  property_id: Scalars["String"];
};

export type Mutation_RootDelete_Sf_Pms_Pms_Property_Eod_SurveysArgs = {
  where: Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Pms_Pms_Property_Eod_Surveys_By_PkArgs = {
  pms_id: Scalars["Int"];
  property_id: Scalars["String"];
  survey_monkey_id: Scalars["String"];
};

export type Mutation_RootDelete_Sf_Pms_Pms_ReservationsArgs = {
  where: Sf_Pms_Pms_Reservations_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Pms_Pms_Room_CategoriesArgs = {
  where: Sf_Pms_Pms_Room_Categories_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Pms_Pms_RoomsArgs = {
  where: Sf_Pms_Pms_Rooms_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Pms_Smartalloc_ReportArgs = {
  where: Sf_Pms_Smartalloc_Report_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Pms_Smartalloc_Report_By_PkArgs = {
  date: Scalars["date"];
  locationId: Scalars["uuid"];
  type: Scalars["String"];
};

export type Mutation_RootDelete_Sf_Simulation_Instrumentation_CommandsArgs = {
  where: Sf_Simulation_Instrumentation_Commands_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Simulation_Instrumentation_Commands_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Support_ActionsArgs = {
  where: Sf_Support_Actions_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Support_Actions_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Support_Active_Property_AlertsArgs = {
  where: Sf_Support_Active_Property_Alerts_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Support_Active_Property_Alerts_By_PkArgs = {
  locationId: Scalars["uuid"];
  ticketClassId: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Support_Analytics_Ticket_Jobs_Running_HistoriesArgs = {
  where: Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Support_Analytics_Ticket_Jobs_Running_Histories_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Support_Root_CausesArgs = {
  where: Sf_Support_Root_Causes_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Support_Root_Causes_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Support_Ticket_ClassesArgs = {
  where: Sf_Support_Ticket_Classes_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Support_Ticket_Classes_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Support_Ticket_StatusArgs = {
  where: Sf_Support_Ticket_Status_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Support_Ticket_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Support_TicketsArgs = {
  where: Sf_Support_Tickets_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Support_Tickets_By_PkArgs = {
  ticket_id: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Testjig_Test_JigArgs = {
  where: Sf_Testjig_Test_Jig_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_By_PkArgs = {
  testJigId: Scalars["String"];
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_Maintenance_ReportArgs = {
  where: Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_Maintenance_Report_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_Report_MetadataArgs = {
  where: Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_Report_Metadata_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_Report_MetadatasArgs = {
  where: Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_Report_Metadatas_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_Test_ReportArgs = {
  where: Sf_Testjig_Test_Jig_Test_Report_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_Test_Report_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_TypeArgs = {
  where: Sf_Testjig_Test_Jig_Type_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Testjig_Test_Jig_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Mutation_RootDelete_TestRunsArgs = {
  where: TestRuns_Bool_Exp;
};

export type Mutation_RootDelete_TestRuns_By_PkArgs = {
  testRunId: Scalars["Int"];
};

export type Mutation_RootEditComfortPlusDetailArgs = {
  addedEmails?: Maybe<Array<Scalars["String"]>>;
  checkInAt?: Maybe<Scalars["String"]>;
  checkOutAt?: Maybe<Scalars["String"]>;
  reservationId: Scalars["Int"];
};

export type Mutation_RootEditGroupOfGatewaysArgs = {
  gatewayIds?: Maybe<Array<Scalars["String"]>>;
  groupId?: Maybe<Scalars["String"]>;
};

export type Mutation_RootEditGroupOfKeysArgs = {
  groupId?: Maybe<Scalars["String"]>;
  keyIds?: Maybe<Array<Scalars["String"]>>;
};

export type Mutation_RootExitInstallationModeArgs = {
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  keyEntryId: Scalars["Int"];
  status?: Maybe<Scalars["String"]>;
};

export type Mutation_RootGatewaysCreateArgs = {
  gateways: Array<GatewaysCreateInput>;
};

export type Mutation_RootInitClickupExportProgressArgs = {
  clickupListId: Scalars["String"];
  locationId: Scalars["ID"];
};

export type Mutation_RootInsertActivePropertyAlertsArgs = {
  objects: Array<Sf_Support_Active_Property_Alerts_Insert_Input>;
  on_conflict?: Maybe<Sf_Support_Active_Property_Alerts_On_Conflict>;
};

export type Mutation_RootInsertKeyEntryArgs = {
  object: KeyEntries_Insert_Input;
  on_conflict?: Maybe<KeyEntries_On_Conflict>;
};

export type Mutation_RootInsertPositionConfigurationArgs = {
  object: PositionConfigurations_Insert_Input;
  on_conflict?: Maybe<PositionConfigurations_On_Conflict>;
};

export type Mutation_RootInsertTestRunArgs = {
  object: TestRuns_Insert_Input;
  on_conflict?: Maybe<TestRuns_On_Conflict>;
};

export type Mutation_RootInsertTestRunsArgs = {
  objects: Array<TestRuns_Insert_Input>;
  on_conflict?: Maybe<TestRuns_On_Conflict>;
};

export type Mutation_RootInsert_KeyEntriesArgs = {
  objects: Array<KeyEntries_Insert_Input>;
  on_conflict?: Maybe<KeyEntries_On_Conflict>;
};

export type Mutation_RootInsert_PositionConfigurationsArgs = {
  objects: Array<PositionConfigurations_Insert_Input>;
  on_conflict?: Maybe<PositionConfigurations_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Ac_Command_Event_StreamArgs = {
  objects: Array<Sensorflow_Ac_Command_Event_Stream_Insert_Input>;
};

export type Mutation_RootInsert_Sensorflow_Ac_Command_Event_Stream_OneArgs = {
  object: Sensorflow_Ac_Command_Event_Stream_Insert_Input;
};

export type Mutation_RootInsert_Sensorflow_Ac_ModelsArgs = {
  objects: Array<Sensorflow_Ac_Models_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Ac_Models_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Ac_Models_OneArgs = {
  object: Sensorflow_Ac_Models_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Ac_Models_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Ac_Setting_Commands_OneArgs = {
  object: Sensorflow_Ac_Setting_Commands_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Ac_Setting_Commands_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Ai_Dt_Ac_Event_StreamArgs = {
  objects: Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Insert_Input>;
};

export type Mutation_RootInsert_Sensorflow_Ai_Dt_Ac_Event_Stream_OneArgs = {
  object: Sensorflow_Ai_Dt_Ac_Event_Stream_Insert_Input;
};

export type Mutation_RootInsert_Sensorflow_Ai_Dt_Config_Event_StreamArgs = {
  objects: Array<Sensorflow_Ai_Dt_Config_Event_Stream_Insert_Input>;
};

export type Mutation_RootInsert_Sensorflow_Ai_Dt_Config_Event_Stream_OneArgs = {
  object: Sensorflow_Ai_Dt_Config_Event_Stream_Insert_Input;
};

export type Mutation_RootInsert_Sensorflow_Beacon_SlotsArgs = {
  objects: Array<Sensorflow_Beacon_Slots_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Beacon_Slots_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Beacon_Slots_OneArgs = {
  object: Sensorflow_Beacon_Slots_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Beacon_Slots_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Checklist_ItemsArgs = {
  objects: Array<Sensorflow_Checklist_Items_Insert_Input>;
};

export type Mutation_RootInsert_Sensorflow_Checklist_Items_OneArgs = {
  object: Sensorflow_Checklist_Items_Insert_Input;
};

export type Mutation_RootInsert_Sensorflow_ChecklistsArgs = {
  objects: Array<Sensorflow_Checklists_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Checklists_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Checklists_OneArgs = {
  object: Sensorflow_Checklists_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Checklists_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_ChillersArgs = {
  objects: Array<Sensorflow_Chillers_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Chillers_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Clickup_Webhook_EventsArgs = {
  objects: Array<Sensorflow_Clickup_Webhook_Events_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Clickup_Webhook_Events_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Clickup_Webhook_Events_OneArgs = {
  object: Sensorflow_Clickup_Webhook_Events_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Clickup_Webhook_Events_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Comfortplus_ActivationsArgs = {
  objects: Array<Sensorflow_Comfortplus_Activations_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Comfortplus_Activations_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Comfortplus_Activations_OneArgs = {
  object: Sensorflow_Comfortplus_Activations_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Comfortplus_Activations_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Compressor_Mapping_OneArgs = {
  object: Sensorflow_Compressor_Mapping_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Compressor_Mapping_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Config_Event_StreamArgs = {
  objects: Array<Sensorflow_Config_Event_Stream_Insert_Input>;
};

export type Mutation_RootInsert_Sensorflow_Config_Event_Stream_OneArgs = {
  object: Sensorflow_Config_Event_Stream_Insert_Input;
};

export type Mutation_RootInsert_Sensorflow_Cooling_TowersArgs = {
  objects: Array<Sensorflow_Cooling_Towers_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Cooling_Towers_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_CurrenciesArgs = {
  objects: Array<Sensorflow_Currencies_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Currencies_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Currencies_OneArgs = {
  object: Sensorflow_Currencies_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Currencies_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Domo_EmbedsArgs = {
  objects: Array<Sensorflow_Domo_Embeds_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Domo_Embeds_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Entity_Feature_Flag_MappingsArgs = {
  objects: Array<Sensorflow_Entity_Feature_Flag_Mappings_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Entity_Feature_Flag_Mappings_OneArgs = {
  object: Sensorflow_Entity_Feature_Flag_Mappings_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Feature_FlagsArgs = {
  objects: Array<Sensorflow_Feature_Flags_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Feature_Flags_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Feature_Flags_OneArgs = {
  object: Sensorflow_Feature_Flags_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Feature_Flags_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Firmware_ReleasesArgs = {
  objects: Array<Sensorflow_Firmware_Releases_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Firmware_Releases_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Gateway_FrequencyArgs = {
  objects: Array<Sensorflow_Gateway_Frequency_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Gateway_Frequency_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Gateway_Frequency_OneArgs = {
  object: Sensorflow_Gateway_Frequency_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Gateway_Frequency_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Gateway_Health_DataArgs = {
  objects: Array<Sensorflow_Gateway_Health_Data_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Gateway_Health_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Gateway_Health_Data_OneArgs = {
  object: Sensorflow_Gateway_Health_Data_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Gateway_Health_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Gateway_To_Position_MappingArgs = {
  objects: Array<Sensorflow_Gateway_To_Position_Mapping_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Gateway_To_Position_Mapping_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_GatewaysArgs = {
  objects: Array<Sensorflow_Gateways_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Gateways_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Gateways_OneArgs = {
  object: Sensorflow_Gateways_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Gateways_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Get_Event_Stream_ViewArgs = {
  objects: Array<Sensorflow_Get_Event_Stream_View_Insert_Input>;
};

export type Mutation_RootInsert_Sensorflow_Get_Event_Stream_View_OneArgs = {
  object: Sensorflow_Get_Event_Stream_View_Insert_Input;
};

export type Mutation_RootInsert_Sensorflow_Key_CategoriesArgs = {
  objects: Array<Sensorflow_Key_Categories_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Key_Categories_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Key_Category_Room_TemplatesArgs = {
  objects: Array<Sensorflow_Key_Category_Room_Templates_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Key_Category_Room_Templates_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Key_Category_Room_Templates_OneArgs = {
  object: Sensorflow_Key_Category_Room_Templates_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Key_Category_Room_Templates_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Key_Category_Slot_TemplatesArgs = {
  objects: Array<Sensorflow_Key_Category_Slot_Templates_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Key_Category_Slot_Templates_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Key_Category_Slot_Templates_OneArgs = {
  object: Sensorflow_Key_Category_Slot_Templates_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Key_Category_Slot_Templates_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Key_Category_Templates_OneArgs = {
  object: Sensorflow_Key_Category_Templates_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Key_Category_Templates_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Key_Category_To_Key_Position_OneArgs = {
  object: Sensorflow_Key_Category_To_Key_Position_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Key_Category_To_Key_Position_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_AddressesArgs = {
  objects: Array<Sensorflow_Location_Addresses_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Location_Addresses_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_Addresses_OneArgs = {
  object: Sensorflow_Location_Addresses_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Location_Addresses_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_ConsumptionsArgs = {
  objects: Array<Sensorflow_Location_Consumptions_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Location_Consumptions_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_Consumptions_OneArgs = {
  object: Sensorflow_Location_Consumptions_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Location_Consumptions_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_Domo_EmbedsArgs = {
  objects: Array<Sensorflow_Location_Domo_Embeds_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Location_Domo_Embeds_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_MetadataArgs = {
  objects: Array<Sensorflow_Location_Metadata_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Location_Metadata_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_Occ_Customer_DataArgs = {
  objects: Array<Sensorflow_Location_Occ_Customer_Data_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Location_Occ_Customer_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_Occ_Customer_Data_OneArgs = {
  object: Sensorflow_Location_Occ_Customer_Data_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Location_Occ_Customer_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_Occ_Derived_DataArgs = {
  objects: Array<Sensorflow_Location_Occ_Derived_Data_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Location_Occ_Derived_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_Occ_Derived_Data_OneArgs = {
  object: Sensorflow_Location_Occ_Derived_Data_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Location_Occ_Derived_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_To_User_MappingArgs = {
  objects: Array<Sensorflow_Location_To_User_Mapping_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Location_To_User_Mapping_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Location_To_User_Mapping_OneArgs = {
  object: Sensorflow_Location_To_User_Mapping_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Location_To_User_Mapping_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_LocationsArgs = {
  objects: Array<Sensorflow_Locations_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Locations_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Mapping_CoordinatesArgs = {
  objects: Array<Sensorflow_Mapping_Coordinates_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Mapping_Coordinates_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Mapping_Coordinates_OneArgs = {
  object: Sensorflow_Mapping_Coordinates_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Mapping_Coordinates_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Metric_TypesArgs = {
  objects: Array<Sensorflow_Metric_Types_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Metric_Types_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Metric_Types_OneArgs = {
  object: Sensorflow_Metric_Types_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Metric_Types_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_ExceptionArgs = {
  objects: Array<Sensorflow_Node_Exception_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Exception_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Exception_OneArgs = {
  object: Sensorflow_Node_Exception_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Exception_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_ExceptionsArgs = {
  objects: Array<Sensorflow_Node_Exceptions_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Exceptions_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Exceptions_OneArgs = {
  object: Sensorflow_Node_Exceptions_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Exceptions_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Join_DataArgs = {
  objects: Array<Sensorflow_Node_Join_Data_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Join_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Join_Data_OneArgs = {
  object: Sensorflow_Node_Join_Data_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Join_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Life_Cycle_Event_TypesArgs = {
  objects: Array<Sensorflow_Node_Life_Cycle_Event_Types_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Life_Cycle_Event_Types_OneArgs = {
  object: Sensorflow_Node_Life_Cycle_Event_Types_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Life_Cycle_EventsArgs = {
  objects: Array<Sensorflow_Node_Life_Cycle_Events_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Life_Cycle_Events_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Life_Cycle_Events_OneArgs = {
  object: Sensorflow_Node_Life_Cycle_Events_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Life_Cycle_Events_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_MeasurementsArgs = {
  objects: Array<Sensorflow_Node_Measurements_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Measurements_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Measurements_OneArgs = {
  object: Sensorflow_Node_Measurements_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Measurements_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Meta_DataArgs = {
  objects: Array<Sensorflow_Node_Meta_Data_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Meta_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Meta_Data_OneArgs = {
  object: Sensorflow_Node_Meta_Data_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Meta_Data_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_SlotsArgs = {
  objects: Array<Sensorflow_Node_Slots_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Slots_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Spare_SetArgs = {
  objects: Array<Sensorflow_Node_Spare_Set_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Spare_Set_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Spare_Set_OneArgs = {
  object: Sensorflow_Node_Spare_Set_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Spare_Set_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Status_HistoryArgs = {
  objects: Array<Sensorflow_Node_Status_History_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Status_History_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Status_History_OneArgs = {
  object: Sensorflow_Node_Status_History_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Status_History_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_StatusesArgs = {
  objects: Array<Sensorflow_Node_Statuses_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Statuses_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_Statuses_OneArgs = {
  object: Sensorflow_Node_Statuses_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Statuses_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_System_StatesArgs = {
  objects: Array<Sensorflow_Node_System_States_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_System_States_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_System_States_OneArgs = {
  object: Sensorflow_Node_System_States_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_System_States_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_To_Position_MappingArgs = {
  objects: Array<Sensorflow_Node_To_Position_Mapping_Insert_Input>;
};

export type Mutation_RootInsert_Sensorflow_Node_To_Position_Mapping_OneArgs = {
  object: Sensorflow_Node_To_Position_Mapping_Insert_Input;
};

export type Mutation_RootInsert_Sensorflow_Node_To_Slot_MappingArgs = {
  objects: Array<Sensorflow_Node_To_Slot_Mapping_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_To_Slot_Mapping_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Node_To_Slot_Mapping_OneArgs = {
  object: Sensorflow_Node_To_Slot_Mapping_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_To_Slot_Mapping_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_NodesArgs = {
  objects: Array<Sensorflow_Nodes_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Nodes_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Nodes_OneArgs = {
  object: Sensorflow_Nodes_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Nodes_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_OrganisationsArgs = {
  objects: Array<Sensorflow_Organisations_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Organisations_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Ota_QueuesArgs = {
  objects: Array<Sensorflow_Ota_Queues_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Ota_Queues_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Ota_Queues_OneArgs = {
  object: Sensorflow_Ota_Queues_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Ota_Queues_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Ota_RunsArgs = {
  objects: Array<Sensorflow_Ota_Runs_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Ota_Runs_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Ota_Runs_OneArgs = {
  object: Sensorflow_Ota_Runs_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Ota_Runs_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Outdoor_TemperaturesArgs = {
  objects: Array<Sensorflow_Outdoor_Temperatures_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Outdoor_Temperatures_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Outdoor_Temperatures_OneArgs = {
  object: Sensorflow_Outdoor_Temperatures_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Outdoor_Temperatures_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_PipesArgs = {
  objects: Array<Sensorflow_Pipes_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Pipes_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Position_Signal_StatsArgs = {
  objects: Array<Sensorflow_Position_Signal_Stats_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Position_Signal_Stats_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Position_Signal_Stats_OneArgs = {
  object: Sensorflow_Position_Signal_Stats_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Position_Signal_Stats_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Precool_Commands_OneArgs = {
  object: Sensorflow_Precool_Commands_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Precool_Commands_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Precool_SchedulesArgs = {
  objects: Array<Sensorflow_Precool_Schedules_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Precool_Schedules_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Precool_Schedules_OneArgs = {
  object: Sensorflow_Precool_Schedules_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Precool_Schedules_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Properties_InformationArgs = {
  objects: Array<Sensorflow_Properties_Information_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Properties_Information_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Properties_Information_OneArgs = {
  object: Sensorflow_Properties_Information_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Properties_Information_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_PumpsArgs = {
  objects: Array<Sensorflow_Pumps_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Pumps_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Reservation_GuestsArgs = {
  objects: Array<Sensorflow_Reservation_Guests_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Reservation_Guests_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Reservation_Guests_OneArgs = {
  object: Sensorflow_Reservation_Guests_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Reservation_Guests_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_ReservationsArgs = {
  objects: Array<Sensorflow_Reservations_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Reservations_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Reservations_OneArgs = {
  object: Sensorflow_Reservations_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Reservations_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_RolesArgs = {
  objects: Array<Sensorflow_Roles_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Roles_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Roles_OneArgs = {
  object: Sensorflow_Roles_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Roles_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Smart_Rem_Validation_HistoryArgs = {
  objects: Array<Sensorflow_Smart_Rem_Validation_History_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Smart_Rem_Validation_History_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Smplrspace_Floors_To_LevelsArgs = {
  objects: Array<Sensorflow_Smplrspace_Floors_To_Levels_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Smplrspace_Floors_To_Levels_OneArgs = {
  object: Sensorflow_Smplrspace_Floors_To_Levels_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Smplrspace_Levels_GroupArgs = {
  objects: Array<Sensorflow_Smplrspace_Levels_Group_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Smplrspace_Levels_Group_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Smplrspace_Levels_Group_OneArgs = {
  object: Sensorflow_Smplrspace_Levels_Group_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Smplrspace_Levels_Group_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Survey_ResponsesArgs = {
  objects: Array<Sensorflow_Survey_Responses_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Survey_Responses_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Survey_Responses_OneArgs = {
  object: Sensorflow_Survey_Responses_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Survey_Responses_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Task_Status_HistoryArgs = {
  objects: Array<Sensorflow_Task_Status_History_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Task_Status_History_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Task_Status_History_OneArgs = {
  object: Sensorflow_Task_Status_History_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Task_Status_History_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_TasksArgs = {
  objects: Array<Sensorflow_Tasks_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Tasks_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Tasks_OneArgs = {
  object: Sensorflow_Tasks_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Tasks_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_TestsArgs = {
  objects: Array<Sensorflow_Tests_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Tests_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Tests_OneArgs = {
  object: Sensorflow_Tests_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Tests_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_TimezonesArgs = {
  objects: Array<Sensorflow_Timezones_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Timezones_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Timezones_OneArgs = {
  object: Sensorflow_Timezones_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Timezones_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_User_To_Permission_MappingArgs = {
  objects: Array<Sensorflow_User_To_Permission_Mapping_Insert_Input>;
};

export type Mutation_RootInsert_Sensorflow_User_To_Permission_Mapping_OneArgs = {
  object: Sensorflow_User_To_Permission_Mapping_Insert_Input;
};

export type Mutation_RootInsert_Sensorflow_UsersArgs = {
  objects: Array<Sensorflow_Users_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Users_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Users_OneArgs = {
  object: Sensorflow_Users_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Users_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Warehouse_Kitting_Process_MetadataArgs = {
  objects: Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_On_Conflict>;
};

export type Mutation_RootInsert_Sensorflow_Warehouse_Kitting_Process_Metadata_OneArgs = {
  object: Sensorflow_Warehouse_Kitting_Process_Metadata_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Firmware_Dist_Pipeline_Firmware_BuildArgs = {
  objects: Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Insert_Input>;
  on_conflict?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Firmware_Dist_Pipeline_Firmware_Build_OneArgs = {
  object: Sf_Firmware_Dist_Pipeline_Firmware_Build_Insert_Input;
  on_conflict?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Firmware_Dist_Pipeline_Firmware_NumbersArgs = {
  objects: Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Insert_Input>;
  on_conflict?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Firmware_Dist_Pipeline_Firmware_Numbers_OneArgs = {
  object: Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Insert_Input;
  on_conflict?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_PmslistArgs = {
  objects: Array<Sf_Pms_Pms_Pmslist_Insert_Input>;
  on_conflict?: Maybe<Sf_Pms_Pms_Pmslist_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_Pmslist_OneArgs = {
  object: Sf_Pms_Pms_Pmslist_Insert_Input;
  on_conflict?: Maybe<Sf_Pms_Pms_Pmslist_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_PropertiesArgs = {
  objects: Array<Sf_Pms_Pms_Properties_Insert_Input>;
  on_conflict?: Maybe<Sf_Pms_Pms_Properties_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_Properties_OneArgs = {
  object: Sf_Pms_Pms_Properties_Insert_Input;
  on_conflict?: Maybe<Sf_Pms_Pms_Properties_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_Property_Eod_SurveysArgs = {
  objects: Array<Sf_Pms_Pms_Property_Eod_Surveys_Insert_Input>;
  on_conflict?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_Property_Eod_Surveys_OneArgs = {
  object: Sf_Pms_Pms_Property_Eod_Surveys_Insert_Input;
  on_conflict?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_ReservationsArgs = {
  objects: Array<Sf_Pms_Pms_Reservations_Insert_Input>;
  on_conflict?: Maybe<Sf_Pms_Pms_Reservations_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_Reservations_OneArgs = {
  object: Sf_Pms_Pms_Reservations_Insert_Input;
  on_conflict?: Maybe<Sf_Pms_Pms_Reservations_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_Room_CategoriesArgs = {
  objects: Array<Sf_Pms_Pms_Room_Categories_Insert_Input>;
  on_conflict?: Maybe<Sf_Pms_Pms_Room_Categories_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_Room_Categories_OneArgs = {
  object: Sf_Pms_Pms_Room_Categories_Insert_Input;
  on_conflict?: Maybe<Sf_Pms_Pms_Room_Categories_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_RoomsArgs = {
  objects: Array<Sf_Pms_Pms_Rooms_Insert_Input>;
  on_conflict?: Maybe<Sf_Pms_Pms_Rooms_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Pms_Rooms_OneArgs = {
  object: Sf_Pms_Pms_Rooms_Insert_Input;
  on_conflict?: Maybe<Sf_Pms_Pms_Rooms_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Smartalloc_ReportArgs = {
  objects: Array<Sf_Pms_Smartalloc_Report_Insert_Input>;
  on_conflict?: Maybe<Sf_Pms_Smartalloc_Report_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Pms_Smartalloc_Report_OneArgs = {
  object: Sf_Pms_Smartalloc_Report_Insert_Input;
  on_conflict?: Maybe<Sf_Pms_Smartalloc_Report_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Simulation_Instrumentation_CommandsArgs = {
  objects: Array<Sf_Simulation_Instrumentation_Commands_Insert_Input>;
  on_conflict?: Maybe<Sf_Simulation_Instrumentation_Commands_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Simulation_Instrumentation_Commands_OneArgs = {
  object: Sf_Simulation_Instrumentation_Commands_Insert_Input;
  on_conflict?: Maybe<Sf_Simulation_Instrumentation_Commands_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_ActionsArgs = {
  objects: Array<Sf_Support_Actions_Insert_Input>;
  on_conflict?: Maybe<Sf_Support_Actions_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Actions_OneArgs = {
  object: Sf_Support_Actions_Insert_Input;
  on_conflict?: Maybe<Sf_Support_Actions_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Active_Property_Alerts_OneArgs = {
  object: Sf_Support_Active_Property_Alerts_Insert_Input;
  on_conflict?: Maybe<Sf_Support_Active_Property_Alerts_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Analytics_Ticket_Jobs_Running_HistoriesArgs = {
  objects: Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Insert_Input>;
  on_conflict?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Analytics_Ticket_Jobs_Running_Histories_OneArgs = {
  object: Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Insert_Input;
  on_conflict?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Root_CausesArgs = {
  objects: Array<Sf_Support_Root_Causes_Insert_Input>;
  on_conflict?: Maybe<Sf_Support_Root_Causes_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Root_Causes_OneArgs = {
  object: Sf_Support_Root_Causes_Insert_Input;
  on_conflict?: Maybe<Sf_Support_Root_Causes_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Ticket_ClassesArgs = {
  objects: Array<Sf_Support_Ticket_Classes_Insert_Input>;
  on_conflict?: Maybe<Sf_Support_Ticket_Classes_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Ticket_Classes_OneArgs = {
  object: Sf_Support_Ticket_Classes_Insert_Input;
  on_conflict?: Maybe<Sf_Support_Ticket_Classes_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Ticket_StatusArgs = {
  objects: Array<Sf_Support_Ticket_Status_Insert_Input>;
  on_conflict?: Maybe<Sf_Support_Ticket_Status_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Ticket_Status_OneArgs = {
  object: Sf_Support_Ticket_Status_Insert_Input;
  on_conflict?: Maybe<Sf_Support_Ticket_Status_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_TicketsArgs = {
  objects: Array<Sf_Support_Tickets_Insert_Input>;
  on_conflict?: Maybe<Sf_Support_Tickets_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Support_Tickets_OneArgs = {
  object: Sf_Support_Tickets_Insert_Input;
  on_conflict?: Maybe<Sf_Support_Tickets_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Testjig_Test_Jig_Report_MetadataArgs = {
  objects: Array<Sf_Testjig_Test_Jig_Report_Metadata_Insert_Input>;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Testjig_Test_Jig_Report_Metadata_OneArgs = {
  object: Sf_Testjig_Test_Jig_Report_Metadata_Insert_Input;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Testjig_Test_Jig_Report_MetadatasArgs = {
  objects: Array<Sf_Testjig_Test_Jig_Report_Metadatas_Insert_Input>;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Testjig_Test_Jig_Report_Metadatas_OneArgs = {
  object: Sf_Testjig_Test_Jig_Report_Metadatas_Insert_Input;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_On_Conflict>;
};

export type Mutation_RootMapInfraNodeArgs = {
  emonSetting?: Maybe<Scalars["Int"]>;
  infraId: Scalars["ID"];
  mapInput?: Maybe<Scalars["Int"]>;
  nodeMacInput: Scalars["String"];
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType: NodeType;
  slotName: Scalars["String"];
};

export type Mutation_RootMapNodeArgs = {
  nodeMacInput: Scalars["String"];
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType: NodeType;
  roomId: Scalars["ID"];
  slotName: Scalars["String"];
};

export type Mutation_RootMarkKeyAsDnDArgs = {
  comment?: Maybe<Scalars["String"]>;
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
  keyPositionId: Scalars["ID"];
};

export type Mutation_RootOnEnterLiveDataModeConfigArgs = {
  positionIds: Array<Scalars["ID"]>;
  userId: Scalars["String"];
};

export type Mutation_RootOnExitLiveDataModeConfigArgs = {
  positionIds: Array<Scalars["ID"]>;
  userId: Scalars["String"];
};

export type Mutation_RootPrecoolComfortPlusRoomArgs = {
  roomIds: Array<Scalars["ID"]>;
};

export type Mutation_RootRemoveLocationDomoEmbedArgs = {
  embed_id: Scalars["String"];
  location_id: Scalars["uuid"];
};

export type Mutation_RootRemoveNodeSpareSetArgs = {
  nodeMacId: Scalars["String"];
};

export type Mutation_RootRenameGatewayArgs = {
  gatewayId: Scalars["ID"];
  gatewayName: Scalars["String"];
};

export type Mutation_RootResetPositionConfigurationsArgs = {
  positionIds: Array<Scalars["String"]>;
};

export type Mutation_RootSaveGuestPrecoolScheduleArgs = {
  input: GuestPrecoolScheduleInput;
};

export type Mutation_RootSensorflow_Ai_F_Send_Ac_CommandArgs = {
  args: Sensorflow_Ai_F_Send_Ac_Command_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
};

export type Mutation_RootSkipRapidMappingArgs = {
  nodeType: NodeType;
  roomId: Scalars["ID"];
  slotName: Scalars["String"];
};

export type Mutation_RootStartInstallationModeArgs = {
  automaticTestRun?: Maybe<Scalars["Boolean"]>;
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
  isResumedEntry: Scalars["Boolean"];
  keyPositionId: Scalars["ID"];
  positionFunction: Scalars["String"];
  userId: Scalars["ID"];
};

export type Mutation_RootStartTestingArgs = {
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
  keyPositionId: Scalars["ID"];
  positionFunction: Scalars["String"];
  resumePreviousSession?: Maybe<Scalars["Boolean"]>;
};

export type Mutation_RootSwapChannelsArgs = {
  destinationCompressorId: Scalars["ID"];
  startingCompressorId: Scalars["ID"];
};

export type Mutation_RootTriggerToUploadGatewayImagesArgs = {
  gatewayIds: Array<Scalars["String"]>;
};

export type Mutation_RootUnClaimKeyArgs = {
  keyPositionId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type Mutation_RootUnmapInfraNodeArgs = {
  comment?: Maybe<Scalars["String"]>;
  infraId: Scalars["ID"];
  nodeMacId: Scalars["String"];
  rootCauseId?: Maybe<Scalars["Int"]>;
  scannedMacId?: Maybe<Scalars["String"]>;
};

export type Mutation_RootUnmapNodeArgs = {
  comment?: Maybe<Scalars["String"]>;
  nodeMacInput: Scalars["String"];
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType: NodeType;
  roomId: Scalars["ID"];
  rootCauseId?: Maybe<Scalars["Int"]>;
  scannedMacId?: Maybe<Scalars["String"]>;
  slotName: Scalars["String"];
};

export type Mutation_RootUpdateChillerArgs = {
  _inc?: Maybe<Sensorflow_Chillers_Inc_Input>;
  _set?: Maybe<Sensorflow_Chillers_Set_Input>;
  pk_columns: Sensorflow_Chillers_Pk_Columns_Input;
};

export type Mutation_RootUpdateComfortPlusActivationsArgs = {
  _inc?: Maybe<Sensorflow_Comfortplus_Activations_Inc_Input>;
  _set?: Maybe<Sensorflow_Comfortplus_Activations_Set_Input>;
  where: Sensorflow_Comfortplus_Activations_Bool_Exp;
};

export type Mutation_RootUpdateCompressorArgs = {
  _inc?: Maybe<Sensorflow_Compressors_Inc_Input>;
  _set?: Maybe<Sensorflow_Compressors_Set_Input>;
  pk_columns: Sensorflow_Compressors_Pk_Columns_Input;
};

export type Mutation_RootUpdateCompressorsArgs = {
  updates: Array<Sensorflow_Compressors_Updates>;
};

export type Mutation_RootUpdateCoolingTowerArgs = {
  _inc?: Maybe<Sensorflow_Cooling_Towers_Inc_Input>;
  _set?: Maybe<Sensorflow_Cooling_Towers_Set_Input>;
  pk_columns: Sensorflow_Cooling_Towers_Pk_Columns_Input;
};

export type Mutation_RootUpdateDefaultPositionConfigurationArgs = {
  positionConfiguration: PositionConfigurationInput;
};

export type Mutation_RootUpdateDomoEmbedArgs = {
  _set?: Maybe<Sensorflow_Domo_Embeds_Set_Input>;
  pk_columns: Sensorflow_Domo_Embeds_Pk_Columns_Input;
};

export type Mutation_RootUpdateFirmwareReleaseArgs = {
  _inc?: Maybe<Sensorflow_Firmware_Releases_Inc_Input>;
  _set?: Maybe<Sensorflow_Firmware_Releases_Set_Input>;
  pk_columns: Sensorflow_Firmware_Releases_Pk_Columns_Input;
};

export type Mutation_RootUpdateGatewayHardwareTypeArgs = {
  gatewayId: Scalars["ID"];
  type: GatewayHardwareType;
};

export type Mutation_RootUpdateGroupArgs = {
  groupId: Scalars["ID"];
  groupName: Scalars["String"];
  groupType: Scalars["String"];
};

export type Mutation_RootUpdateInfraNodeArgs = {
  emonSetting?: Maybe<Scalars["Int"]>;
  infraId: Scalars["ID"];
  mapInput?: Maybe<Scalars["Int"]>;
  nodeMacId: Scalars["String"];
  slotName: Scalars["String"];
};

export type Mutation_RootUpdateKeyCategoryArgs = {
  _set?: Maybe<Sensorflow_Key_Categories_Set_Input>;
  pk_columns: Sensorflow_Key_Categories_Pk_Columns_Input;
};

export type Mutation_RootUpdateKeyCategoryToKeyMappingsArgs = {
  _set?: Maybe<Sensorflow_Key_Category_To_Key_Position_Set_Input>;
  where: Sensorflow_Key_Category_To_Key_Position_Bool_Exp;
};

export type Mutation_RootUpdateKeyEntriesArgs = {
  _inc?: Maybe<KeyEntries_Inc_Input>;
  _set?: Maybe<KeyEntries_Set_Input>;
  where: KeyEntries_Bool_Exp;
};

export type Mutation_RootUpdateLocationArgs = {
  _inc?: Maybe<Sensorflow_Locations_Inc_Input>;
  _set?: Maybe<Sensorflow_Locations_Set_Input>;
  pk_columns: Sensorflow_Locations_Pk_Columns_Input;
};

export type Mutation_RootUpdateLocationBeaconSlotManagementArgs = {
  beaconSlotManagementEnabled: Scalars["Boolean"];
  locationId: Scalars["ID"];
};

export type Mutation_RootUpdateLocationGatewayHardwareTypeArgs = {
  locationId: Scalars["ID"];
  type: GatewayHardwareType;
};

export type Mutation_RootUpdateLocationGatewayPromiscuousModeArgs = {
  locationId: Scalars["ID"];
  promiscuousModeEnabled: Scalars["Boolean"];
};

export type Mutation_RootUpdateLocationMetadataArgs = {
  _inc?: Maybe<Sensorflow_Location_Metadata_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Metadata_Set_Input>;
  where: Sensorflow_Location_Metadata_Bool_Exp;
};

export type Mutation_RootUpdateLocationSetEnableNoKeyCardStateArgs = {
  enableNoKeyCardState: Scalars["Boolean"];
  locationId: Scalars["ID"];
};

export type Mutation_RootUpdateNodeToSlotMappingsArgs = {
  _inc?: Maybe<Sensorflow_Node_To_Slot_Mapping_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_To_Slot_Mapping_Set_Input>;
  where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
};

export type Mutation_RootUpdatePipeArgs = {
  _set?: Maybe<Sensorflow_Pipes_Set_Input>;
  pk_columns: Sensorflow_Pipes_Pk_Columns_Input;
};

export type Mutation_RootUpdatePositionArgs = {
  _set?: Maybe<Sensorflow_Positions_Set_Input>;
  pk_columns: Sensorflow_Positions_Pk_Columns_Input;
};

export type Mutation_RootUpdatePositionConfigurationArgs = {
  positionConfiguration: PositionConfigurationInput;
};

export type Mutation_RootUpdatePositionConfigurationsArgs = {
  _inc?: Maybe<PositionConfigurations_Inc_Input>;
  _set?: Maybe<PositionConfigurations_Set_Input>;
  where: PositionConfigurations_Bool_Exp;
};

export type Mutation_RootUpdatePositionConfigurationsAutomationModeArgs = {
  automationMode?: Maybe<Scalars["String"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  positionIds: Array<Scalars["String"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Mutation_RootUpdatePositionsArgs = {
  _set?: Maybe<Sensorflow_Positions_Set_Input>;
  where: Sensorflow_Positions_Bool_Exp;
};

export type Mutation_RootUpdatePumpArgs = {
  _inc?: Maybe<Sensorflow_Pumps_Inc_Input>;
  _set?: Maybe<Sensorflow_Pumps_Set_Input>;
  pk_columns: Sensorflow_Pumps_Pk_Columns_Input;
};

export type Mutation_RootUpdateSlotArgs = {
  _set?: Maybe<Sensorflow_Node_Slots_Set_Input>;
  pk_columns: Sensorflow_Node_Slots_Pk_Columns_Input;
};

export type Mutation_RootUpdateSlotTasksToPendingArgs = {
  roomId: Scalars["ID"];
};

export type Mutation_RootUpdateTestJigArgs = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Set_Input>;
  pk_columns: Sf_Testjig_Test_Jig_Pk_Columns_Input;
};

export type Mutation_RootUpdateTestJigMaintenanceReportArgs = {
  _set?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Set_Input>;
  pk_columns: Sf_Testjig_Test_Jig_Maintenance_Report_Pk_Columns_Input;
};

export type Mutation_RootUpdateTestJigMaintenanceReportsArgs = {
  _set?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Set_Input>;
  where: Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp;
};

export type Mutation_RootUpdateTestJigTestReportArgs = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Set_Input>;
  pk_columns: Sf_Testjig_Test_Jig_Test_Report_Pk_Columns_Input;
};

export type Mutation_RootUpdateTestJigTestReportsArgs = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Set_Input>;
  where: Sf_Testjig_Test_Jig_Test_Report_Bool_Exp;
};

export type Mutation_RootUpdateTestJigTypeArgs = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Type_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Type_Set_Input>;
  pk_columns: Sf_Testjig_Test_Jig_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdateTestJigTypesArgs = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Type_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Type_Set_Input>;
  where: Sf_Testjig_Test_Jig_Type_Bool_Exp;
};

export type Mutation_RootUpdateTestJigsArgs = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Set_Input>;
  where: Sf_Testjig_Test_Jig_Bool_Exp;
};

export type Mutation_RootUpdateTestRunsArgs = {
  _inc?: Maybe<TestRuns_Inc_Input>;
  _set?: Maybe<TestRuns_Set_Input>;
  where: TestRuns_Bool_Exp;
};

export type Mutation_RootUpdate_KeyEntries_By_PkArgs = {
  _inc?: Maybe<KeyEntries_Inc_Input>;
  _set?: Maybe<KeyEntries_Set_Input>;
  pk_columns: KeyEntries_Pk_Columns_Input;
};

export type Mutation_RootUpdate_KeyEntries_ManyArgs = {
  updates: Array<KeyEntries_Updates>;
};

export type Mutation_RootUpdate_PositionConfigurations_By_PkArgs = {
  _inc?: Maybe<PositionConfigurations_Inc_Input>;
  _set?: Maybe<PositionConfigurations_Set_Input>;
  pk_columns: PositionConfigurations_Pk_Columns_Input;
};

export type Mutation_RootUpdate_PositionConfigurations_ManyArgs = {
  updates: Array<PositionConfigurations_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Ac_Command_Event_StreamArgs = {
  _set?: Maybe<Sensorflow_Ac_Command_Event_Stream_Set_Input>;
  where: Sensorflow_Ac_Command_Event_Stream_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Ac_Command_Event_Stream_ManyArgs = {
  updates: Array<Sensorflow_Ac_Command_Event_Stream_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Ac_ModelsArgs = {
  _inc?: Maybe<Sensorflow_Ac_Models_Inc_Input>;
  _set?: Maybe<Sensorflow_Ac_Models_Set_Input>;
  where: Sensorflow_Ac_Models_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Ac_Models_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Ac_Models_Inc_Input>;
  _set?: Maybe<Sensorflow_Ac_Models_Set_Input>;
  pk_columns: Sensorflow_Ac_Models_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Ac_Models_ManyArgs = {
  updates: Array<Sensorflow_Ac_Models_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Ac_Setting_CommandsArgs = {
  _inc?: Maybe<Sensorflow_Ac_Setting_Commands_Inc_Input>;
  _set?: Maybe<Sensorflow_Ac_Setting_Commands_Set_Input>;
  where: Sensorflow_Ac_Setting_Commands_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Ac_Setting_Commands_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Ac_Setting_Commands_Inc_Input>;
  _set?: Maybe<Sensorflow_Ac_Setting_Commands_Set_Input>;
  pk_columns: Sensorflow_Ac_Setting_Commands_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Ac_Setting_Commands_ManyArgs = {
  updates: Array<Sensorflow_Ac_Setting_Commands_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Ai_Dt_Ac_Event_StreamArgs = {
  _set?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Set_Input>;
  where: Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Ai_Dt_Ac_Event_Stream_ManyArgs = {
  updates: Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Ai_Dt_Config_Event_StreamArgs = {
  _set?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Set_Input>;
  where: Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Ai_Dt_Config_Event_Stream_ManyArgs = {
  updates: Array<Sensorflow_Ai_Dt_Config_Event_Stream_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Beacon_SlotsArgs = {
  _inc?: Maybe<Sensorflow_Beacon_Slots_Inc_Input>;
  _set?: Maybe<Sensorflow_Beacon_Slots_Set_Input>;
  where: Sensorflow_Beacon_Slots_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Beacon_Slots_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Beacon_Slots_Inc_Input>;
  _set?: Maybe<Sensorflow_Beacon_Slots_Set_Input>;
  pk_columns: Sensorflow_Beacon_Slots_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Beacon_Slots_ManyArgs = {
  updates: Array<Sensorflow_Beacon_Slots_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Checklist_ItemsArgs = {
  _set?: Maybe<Sensorflow_Checklist_Items_Set_Input>;
  where: Sensorflow_Checklist_Items_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Checklist_Items_ManyArgs = {
  updates: Array<Sensorflow_Checklist_Items_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_ChecklistsArgs = {
  _set?: Maybe<Sensorflow_Checklists_Set_Input>;
  where: Sensorflow_Checklists_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Checklists_By_PkArgs = {
  _set?: Maybe<Sensorflow_Checklists_Set_Input>;
  pk_columns: Sensorflow_Checklists_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Checklists_ManyArgs = {
  updates: Array<Sensorflow_Checklists_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_ChillersArgs = {
  _inc?: Maybe<Sensorflow_Chillers_Inc_Input>;
  _set?: Maybe<Sensorflow_Chillers_Set_Input>;
  where: Sensorflow_Chillers_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Chillers_ManyArgs = {
  updates: Array<Sensorflow_Chillers_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Clickup_Webhook_EventsArgs = {
  _inc?: Maybe<Sensorflow_Clickup_Webhook_Events_Inc_Input>;
  _set?: Maybe<Sensorflow_Clickup_Webhook_Events_Set_Input>;
  where: Sensorflow_Clickup_Webhook_Events_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Clickup_Webhook_Events_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Clickup_Webhook_Events_Inc_Input>;
  _set?: Maybe<Sensorflow_Clickup_Webhook_Events_Set_Input>;
  pk_columns: Sensorflow_Clickup_Webhook_Events_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Clickup_Webhook_Events_ManyArgs = {
  updates: Array<Sensorflow_Clickup_Webhook_Events_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Comfortplus_Activations_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Comfortplus_Activations_Inc_Input>;
  _set?: Maybe<Sensorflow_Comfortplus_Activations_Set_Input>;
  pk_columns: Sensorflow_Comfortplus_Activations_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Comfortplus_Activations_ManyArgs = {
  updates: Array<Sensorflow_Comfortplus_Activations_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Compressor_MappingArgs = {
  _set?: Maybe<Sensorflow_Compressor_Mapping_Set_Input>;
  where: Sensorflow_Compressor_Mapping_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Compressor_Mapping_By_PkArgs = {
  _set?: Maybe<Sensorflow_Compressor_Mapping_Set_Input>;
  pk_columns: Sensorflow_Compressor_Mapping_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Compressor_Mapping_ManyArgs = {
  updates: Array<Sensorflow_Compressor_Mapping_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_CompressorsArgs = {
  _inc?: Maybe<Sensorflow_Compressors_Inc_Input>;
  _set?: Maybe<Sensorflow_Compressors_Set_Input>;
  where: Sensorflow_Compressors_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Config_Event_StreamArgs = {
  _set?: Maybe<Sensorflow_Config_Event_Stream_Set_Input>;
  where: Sensorflow_Config_Event_Stream_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Config_Event_Stream_ManyArgs = {
  updates: Array<Sensorflow_Config_Event_Stream_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Cooling_TowersArgs = {
  _inc?: Maybe<Sensorflow_Cooling_Towers_Inc_Input>;
  _set?: Maybe<Sensorflow_Cooling_Towers_Set_Input>;
  where: Sensorflow_Cooling_Towers_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Cooling_Towers_ManyArgs = {
  updates: Array<Sensorflow_Cooling_Towers_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_CurrenciesArgs = {
  _inc?: Maybe<Sensorflow_Currencies_Inc_Input>;
  _set?: Maybe<Sensorflow_Currencies_Set_Input>;
  where: Sensorflow_Currencies_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Currencies_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Currencies_Inc_Input>;
  _set?: Maybe<Sensorflow_Currencies_Set_Input>;
  pk_columns: Sensorflow_Currencies_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Currencies_ManyArgs = {
  updates: Array<Sensorflow_Currencies_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Domo_EmbedsArgs = {
  _set?: Maybe<Sensorflow_Domo_Embeds_Set_Input>;
  where: Sensorflow_Domo_Embeds_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Domo_Embeds_ManyArgs = {
  updates: Array<Sensorflow_Domo_Embeds_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Entity_Feature_Flag_MappingsArgs = {
  _inc?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Inc_Input>;
  _set?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Set_Input>;
  where: Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Entity_Feature_Flag_Mappings_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Inc_Input>;
  _set?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Set_Input>;
  pk_columns: Sensorflow_Entity_Feature_Flag_Mappings_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Entity_Feature_Flag_Mappings_ManyArgs = {
  updates: Array<Sensorflow_Entity_Feature_Flag_Mappings_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Feature_FlagsArgs = {
  _inc?: Maybe<Sensorflow_Feature_Flags_Inc_Input>;
  _set?: Maybe<Sensorflow_Feature_Flags_Set_Input>;
  where: Sensorflow_Feature_Flags_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Feature_Flags_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Feature_Flags_Inc_Input>;
  _set?: Maybe<Sensorflow_Feature_Flags_Set_Input>;
  pk_columns: Sensorflow_Feature_Flags_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Feature_Flags_ManyArgs = {
  updates: Array<Sensorflow_Feature_Flags_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Firmware_ReleasesArgs = {
  _inc?: Maybe<Sensorflow_Firmware_Releases_Inc_Input>;
  _set?: Maybe<Sensorflow_Firmware_Releases_Set_Input>;
  where: Sensorflow_Firmware_Releases_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Firmware_Releases_ManyArgs = {
  updates: Array<Sensorflow_Firmware_Releases_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Gateway_FrequencyArgs = {
  _inc?: Maybe<Sensorflow_Gateway_Frequency_Inc_Input>;
  _set?: Maybe<Sensorflow_Gateway_Frequency_Set_Input>;
  where: Sensorflow_Gateway_Frequency_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Gateway_Frequency_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Gateway_Frequency_Inc_Input>;
  _set?: Maybe<Sensorflow_Gateway_Frequency_Set_Input>;
  pk_columns: Sensorflow_Gateway_Frequency_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Gateway_Frequency_ManyArgs = {
  updates: Array<Sensorflow_Gateway_Frequency_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Gateway_Health_DataArgs = {
  _inc?: Maybe<Sensorflow_Gateway_Health_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Gateway_Health_Data_Set_Input>;
  where: Sensorflow_Gateway_Health_Data_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Gateway_Health_Data_ManyArgs = {
  updates: Array<Sensorflow_Gateway_Health_Data_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Gateway_To_Position_MappingArgs = {
  _set?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Set_Input>;
  where: Sensorflow_Gateway_To_Position_Mapping_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Gateway_To_Position_Mapping_By_PkArgs = {
  _set?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Set_Input>;
  pk_columns: Sensorflow_Gateway_To_Position_Mapping_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Gateway_To_Position_Mapping_ManyArgs = {
  updates: Array<Sensorflow_Gateway_To_Position_Mapping_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_GatewaysArgs = {
  _inc?: Maybe<Sensorflow_Gateways_Inc_Input>;
  _set?: Maybe<Sensorflow_Gateways_Set_Input>;
  where: Sensorflow_Gateways_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Gateways_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Gateways_Inc_Input>;
  _set?: Maybe<Sensorflow_Gateways_Set_Input>;
  pk_columns: Sensorflow_Gateways_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Gateways_ManyArgs = {
  updates: Array<Sensorflow_Gateways_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Get_Event_Stream_ViewArgs = {
  _inc?: Maybe<Sensorflow_Get_Event_Stream_View_Inc_Input>;
  _set?: Maybe<Sensorflow_Get_Event_Stream_View_Set_Input>;
  where: Sensorflow_Get_Event_Stream_View_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Get_Event_Stream_View_ManyArgs = {
  updates: Array<Sensorflow_Get_Event_Stream_View_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Key_CategoriesArgs = {
  _set?: Maybe<Sensorflow_Key_Categories_Set_Input>;
  where: Sensorflow_Key_Categories_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Key_Categories_ManyArgs = {
  updates: Array<Sensorflow_Key_Categories_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_Room_TemplatesArgs = {
  _set?: Maybe<Sensorflow_Key_Category_Room_Templates_Set_Input>;
  where: Sensorflow_Key_Category_Room_Templates_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_Room_Templates_By_PkArgs = {
  _set?: Maybe<Sensorflow_Key_Category_Room_Templates_Set_Input>;
  pk_columns: Sensorflow_Key_Category_Room_Templates_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_Room_Templates_ManyArgs = {
  updates: Array<Sensorflow_Key_Category_Room_Templates_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_Slot_TemplatesArgs = {
  _set?: Maybe<Sensorflow_Key_Category_Slot_Templates_Set_Input>;
  where: Sensorflow_Key_Category_Slot_Templates_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_Slot_Templates_By_PkArgs = {
  _set?: Maybe<Sensorflow_Key_Category_Slot_Templates_Set_Input>;
  pk_columns: Sensorflow_Key_Category_Slot_Templates_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_Slot_Templates_ManyArgs = {
  updates: Array<Sensorflow_Key_Category_Slot_Templates_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_TemplatesArgs = {
  _set?: Maybe<Sensorflow_Key_Category_Templates_Set_Input>;
  where: Sensorflow_Key_Category_Templates_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_Templates_By_PkArgs = {
  _set?: Maybe<Sensorflow_Key_Category_Templates_Set_Input>;
  pk_columns: Sensorflow_Key_Category_Templates_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_Templates_ManyArgs = {
  updates: Array<Sensorflow_Key_Category_Templates_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_To_Key_Position_By_PkArgs = {
  _set?: Maybe<Sensorflow_Key_Category_To_Key_Position_Set_Input>;
  pk_columns: Sensorflow_Key_Category_To_Key_Position_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Key_Category_To_Key_Position_ManyArgs = {
  updates: Array<Sensorflow_Key_Category_To_Key_Position_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Location_AddressesArgs = {
  _inc?: Maybe<Sensorflow_Location_Addresses_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Addresses_Set_Input>;
  where: Sensorflow_Location_Addresses_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Location_Addresses_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Location_Addresses_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Addresses_Set_Input>;
  pk_columns: Sensorflow_Location_Addresses_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Location_Addresses_ManyArgs = {
  updates: Array<Sensorflow_Location_Addresses_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Location_ConsumptionsArgs = {
  _inc?: Maybe<Sensorflow_Location_Consumptions_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Consumptions_Set_Input>;
  where: Sensorflow_Location_Consumptions_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Location_Consumptions_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Location_Consumptions_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Consumptions_Set_Input>;
  pk_columns: Sensorflow_Location_Consumptions_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Location_Consumptions_ManyArgs = {
  updates: Array<Sensorflow_Location_Consumptions_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Location_Domo_EmbedsArgs = {
  _set?: Maybe<Sensorflow_Location_Domo_Embeds_Set_Input>;
  where: Sensorflow_Location_Domo_Embeds_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Location_Domo_Embeds_By_PkArgs = {
  _set?: Maybe<Sensorflow_Location_Domo_Embeds_Set_Input>;
  pk_columns: Sensorflow_Location_Domo_Embeds_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Location_Domo_Embeds_ManyArgs = {
  updates: Array<Sensorflow_Location_Domo_Embeds_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Location_Metadata_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Location_Metadata_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Metadata_Set_Input>;
  pk_columns: Sensorflow_Location_Metadata_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Location_Metadata_ManyArgs = {
  updates: Array<Sensorflow_Location_Metadata_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Location_Occ_Customer_DataArgs = {
  _inc?: Maybe<Sensorflow_Location_Occ_Customer_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Occ_Customer_Data_Set_Input>;
  where: Sensorflow_Location_Occ_Customer_Data_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Location_Occ_Customer_Data_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Location_Occ_Customer_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Occ_Customer_Data_Set_Input>;
  pk_columns: Sensorflow_Location_Occ_Customer_Data_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Location_Occ_Customer_Data_ManyArgs = {
  updates: Array<Sensorflow_Location_Occ_Customer_Data_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Location_Occ_Derived_DataArgs = {
  _inc?: Maybe<Sensorflow_Location_Occ_Derived_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Occ_Derived_Data_Set_Input>;
  where: Sensorflow_Location_Occ_Derived_Data_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Location_Occ_Derived_Data_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Location_Occ_Derived_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Occ_Derived_Data_Set_Input>;
  pk_columns: Sensorflow_Location_Occ_Derived_Data_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Location_Occ_Derived_Data_ManyArgs = {
  updates: Array<Sensorflow_Location_Occ_Derived_Data_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Location_To_User_MappingArgs = {
  _set?: Maybe<Sensorflow_Location_To_User_Mapping_Set_Input>;
  where: Sensorflow_Location_To_User_Mapping_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Location_To_User_Mapping_By_PkArgs = {
  _set?: Maybe<Sensorflow_Location_To_User_Mapping_Set_Input>;
  pk_columns: Sensorflow_Location_To_User_Mapping_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Location_To_User_Mapping_ManyArgs = {
  updates: Array<Sensorflow_Location_To_User_Mapping_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_LocationsArgs = {
  _inc?: Maybe<Sensorflow_Locations_Inc_Input>;
  _set?: Maybe<Sensorflow_Locations_Set_Input>;
  where: Sensorflow_Locations_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Locations_ManyArgs = {
  updates: Array<Sensorflow_Locations_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Mapping_CoordinatesArgs = {
  _set?: Maybe<Sensorflow_Mapping_Coordinates_Set_Input>;
  where: Sensorflow_Mapping_Coordinates_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Mapping_Coordinates_ManyArgs = {
  updates: Array<Sensorflow_Mapping_Coordinates_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Metric_TypesArgs = {
  _inc?: Maybe<Sensorflow_Metric_Types_Inc_Input>;
  _set?: Maybe<Sensorflow_Metric_Types_Set_Input>;
  where: Sensorflow_Metric_Types_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Metric_Types_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Metric_Types_Inc_Input>;
  _set?: Maybe<Sensorflow_Metric_Types_Set_Input>;
  pk_columns: Sensorflow_Metric_Types_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Metric_Types_ManyArgs = {
  updates: Array<Sensorflow_Metric_Types_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_ExceptionArgs = {
  _inc?: Maybe<Sensorflow_Node_Exception_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Exception_Set_Input>;
  where: Sensorflow_Node_Exception_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Exception_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Node_Exception_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Exception_Set_Input>;
  pk_columns: Sensorflow_Node_Exception_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Node_Exception_ManyArgs = {
  updates: Array<Sensorflow_Node_Exception_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_ExceptionsArgs = {
  _inc?: Maybe<Sensorflow_Node_Exceptions_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Exceptions_Set_Input>;
  where: Sensorflow_Node_Exceptions_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Exceptions_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Node_Exceptions_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Exceptions_Set_Input>;
  pk_columns: Sensorflow_Node_Exceptions_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Node_Exceptions_ManyArgs = {
  updates: Array<Sensorflow_Node_Exceptions_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_Join_DataArgs = {
  _inc?: Maybe<Sensorflow_Node_Join_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Join_Data_Set_Input>;
  where: Sensorflow_Node_Join_Data_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Join_Data_ManyArgs = {
  updates: Array<Sensorflow_Node_Join_Data_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_Life_Cycle_Event_TypesArgs = {
  _inc?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Set_Input>;
  where: Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Life_Cycle_Event_Types_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Set_Input>;
  pk_columns: Sensorflow_Node_Life_Cycle_Event_Types_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Node_Life_Cycle_Event_Types_ManyArgs = {
  updates: Array<Sensorflow_Node_Life_Cycle_Event_Types_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_Life_Cycle_EventsArgs = {
  _inc?: Maybe<Sensorflow_Node_Life_Cycle_Events_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Life_Cycle_Events_Set_Input>;
  where: Sensorflow_Node_Life_Cycle_Events_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Life_Cycle_Events_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Node_Life_Cycle_Events_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Life_Cycle_Events_Set_Input>;
  pk_columns: Sensorflow_Node_Life_Cycle_Events_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Node_Life_Cycle_Events_ManyArgs = {
  updates: Array<Sensorflow_Node_Life_Cycle_Events_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_MeasurementsArgs = {
  _inc?: Maybe<Sensorflow_Node_Measurements_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Measurements_Set_Input>;
  where: Sensorflow_Node_Measurements_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Measurements_ManyArgs = {
  updates: Array<Sensorflow_Node_Measurements_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_Meta_DataArgs = {
  _inc?: Maybe<Sensorflow_Node_Meta_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Meta_Data_Set_Input>;
  where: Sensorflow_Node_Meta_Data_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Meta_Data_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Node_Meta_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Meta_Data_Set_Input>;
  pk_columns: Sensorflow_Node_Meta_Data_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Node_Meta_Data_ManyArgs = {
  updates: Array<Sensorflow_Node_Meta_Data_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_SlotsArgs = {
  _set?: Maybe<Sensorflow_Node_Slots_Set_Input>;
  where: Sensorflow_Node_Slots_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Slots_ManyArgs = {
  updates: Array<Sensorflow_Node_Slots_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_Spare_SetArgs = {
  _set?: Maybe<Sensorflow_Node_Spare_Set_Set_Input>;
  where: Sensorflow_Node_Spare_Set_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Spare_Set_By_PkArgs = {
  _set?: Maybe<Sensorflow_Node_Spare_Set_Set_Input>;
  pk_columns: Sensorflow_Node_Spare_Set_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Node_Spare_Set_ManyArgs = {
  updates: Array<Sensorflow_Node_Spare_Set_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_Status_HistoryArgs = {
  _inc?: Maybe<Sensorflow_Node_Status_History_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Status_History_Set_Input>;
  where: Sensorflow_Node_Status_History_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Status_History_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Node_Status_History_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Status_History_Set_Input>;
  pk_columns: Sensorflow_Node_Status_History_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Node_Status_History_ManyArgs = {
  updates: Array<Sensorflow_Node_Status_History_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_StatusesArgs = {
  _inc?: Maybe<Sensorflow_Node_Statuses_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Statuses_Set_Input>;
  where: Sensorflow_Node_Statuses_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_Statuses_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Node_Statuses_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Statuses_Set_Input>;
  pk_columns: Sensorflow_Node_Statuses_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Node_Statuses_ManyArgs = {
  updates: Array<Sensorflow_Node_Statuses_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_System_StatesArgs = {
  _inc?: Maybe<Sensorflow_Node_System_States_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_System_States_Set_Input>;
  where: Sensorflow_Node_System_States_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_System_States_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Node_System_States_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_System_States_Set_Input>;
  pk_columns: Sensorflow_Node_System_States_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Node_System_States_ManyArgs = {
  updates: Array<Sensorflow_Node_System_States_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_To_Position_MappingArgs = {
  _set?: Maybe<Sensorflow_Node_To_Position_Mapping_Set_Input>;
  where: Sensorflow_Node_To_Position_Mapping_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Node_To_Position_Mapping_ManyArgs = {
  updates: Array<Sensorflow_Node_To_Position_Mapping_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Node_To_Slot_Mapping_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Node_To_Slot_Mapping_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_To_Slot_Mapping_Set_Input>;
  pk_columns: Sensorflow_Node_To_Slot_Mapping_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Node_To_Slot_Mapping_ManyArgs = {
  updates: Array<Sensorflow_Node_To_Slot_Mapping_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_NodesArgs = {
  _inc?: Maybe<Sensorflow_Nodes_Inc_Input>;
  _set?: Maybe<Sensorflow_Nodes_Set_Input>;
  where: Sensorflow_Nodes_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Nodes_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Nodes_Inc_Input>;
  _set?: Maybe<Sensorflow_Nodes_Set_Input>;
  pk_columns: Sensorflow_Nodes_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Nodes_ManyArgs = {
  updates: Array<Sensorflow_Nodes_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_OrganisationsArgs = {
  _set?: Maybe<Sensorflow_Organisations_Set_Input>;
  where: Sensorflow_Organisations_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Organisations_By_PkArgs = {
  _set?: Maybe<Sensorflow_Organisations_Set_Input>;
  pk_columns: Sensorflow_Organisations_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Organisations_ManyArgs = {
  updates: Array<Sensorflow_Organisations_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Ota_QueuesArgs = {
  _inc?: Maybe<Sensorflow_Ota_Queues_Inc_Input>;
  _set?: Maybe<Sensorflow_Ota_Queues_Set_Input>;
  where: Sensorflow_Ota_Queues_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Ota_Queues_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Ota_Queues_Inc_Input>;
  _set?: Maybe<Sensorflow_Ota_Queues_Set_Input>;
  pk_columns: Sensorflow_Ota_Queues_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Ota_Queues_ManyArgs = {
  updates: Array<Sensorflow_Ota_Queues_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Ota_RunsArgs = {
  _inc?: Maybe<Sensorflow_Ota_Runs_Inc_Input>;
  _set?: Maybe<Sensorflow_Ota_Runs_Set_Input>;
  where: Sensorflow_Ota_Runs_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Ota_Runs_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Ota_Runs_Inc_Input>;
  _set?: Maybe<Sensorflow_Ota_Runs_Set_Input>;
  pk_columns: Sensorflow_Ota_Runs_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Ota_Runs_ManyArgs = {
  updates: Array<Sensorflow_Ota_Runs_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Outdoor_TemperaturesArgs = {
  _inc?: Maybe<Sensorflow_Outdoor_Temperatures_Inc_Input>;
  _set?: Maybe<Sensorflow_Outdoor_Temperatures_Set_Input>;
  where: Sensorflow_Outdoor_Temperatures_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Outdoor_Temperatures_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Outdoor_Temperatures_Inc_Input>;
  _set?: Maybe<Sensorflow_Outdoor_Temperatures_Set_Input>;
  pk_columns: Sensorflow_Outdoor_Temperatures_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Outdoor_Temperatures_ManyArgs = {
  updates: Array<Sensorflow_Outdoor_Temperatures_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_PipesArgs = {
  _set?: Maybe<Sensorflow_Pipes_Set_Input>;
  where: Sensorflow_Pipes_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Pipes_ManyArgs = {
  updates: Array<Sensorflow_Pipes_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Position_Signal_StatsArgs = {
  _inc?: Maybe<Sensorflow_Position_Signal_Stats_Inc_Input>;
  _set?: Maybe<Sensorflow_Position_Signal_Stats_Set_Input>;
  where: Sensorflow_Position_Signal_Stats_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Position_Signal_Stats_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Position_Signal_Stats_Inc_Input>;
  _set?: Maybe<Sensorflow_Position_Signal_Stats_Set_Input>;
  pk_columns: Sensorflow_Position_Signal_Stats_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Position_Signal_Stats_ManyArgs = {
  updates: Array<Sensorflow_Position_Signal_Stats_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Positions_ManyArgs = {
  updates: Array<Sensorflow_Positions_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Precool_CommandsArgs = {
  _inc?: Maybe<Sensorflow_Precool_Commands_Inc_Input>;
  _set?: Maybe<Sensorflow_Precool_Commands_Set_Input>;
  where: Sensorflow_Precool_Commands_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Precool_Commands_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Precool_Commands_Inc_Input>;
  _set?: Maybe<Sensorflow_Precool_Commands_Set_Input>;
  pk_columns: Sensorflow_Precool_Commands_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Precool_Commands_ManyArgs = {
  updates: Array<Sensorflow_Precool_Commands_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Precool_SchedulesArgs = {
  _inc?: Maybe<Sensorflow_Precool_Schedules_Inc_Input>;
  _set?: Maybe<Sensorflow_Precool_Schedules_Set_Input>;
  where: Sensorflow_Precool_Schedules_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Precool_Schedules_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Precool_Schedules_Inc_Input>;
  _set?: Maybe<Sensorflow_Precool_Schedules_Set_Input>;
  pk_columns: Sensorflow_Precool_Schedules_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Precool_Schedules_ManyArgs = {
  updates: Array<Sensorflow_Precool_Schedules_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Properties_InformationArgs = {
  _inc?: Maybe<Sensorflow_Properties_Information_Inc_Input>;
  _set?: Maybe<Sensorflow_Properties_Information_Set_Input>;
  where: Sensorflow_Properties_Information_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Properties_Information_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Properties_Information_Inc_Input>;
  _set?: Maybe<Sensorflow_Properties_Information_Set_Input>;
  pk_columns: Sensorflow_Properties_Information_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Properties_Information_ManyArgs = {
  updates: Array<Sensorflow_Properties_Information_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_PumpsArgs = {
  _inc?: Maybe<Sensorflow_Pumps_Inc_Input>;
  _set?: Maybe<Sensorflow_Pumps_Set_Input>;
  where: Sensorflow_Pumps_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Pumps_ManyArgs = {
  updates: Array<Sensorflow_Pumps_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Reservation_GuestsArgs = {
  _inc?: Maybe<Sensorflow_Reservation_Guests_Inc_Input>;
  _set?: Maybe<Sensorflow_Reservation_Guests_Set_Input>;
  where: Sensorflow_Reservation_Guests_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Reservation_Guests_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Reservation_Guests_Inc_Input>;
  _set?: Maybe<Sensorflow_Reservation_Guests_Set_Input>;
  pk_columns: Sensorflow_Reservation_Guests_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Reservation_Guests_ManyArgs = {
  updates: Array<Sensorflow_Reservation_Guests_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_ReservationsArgs = {
  _inc?: Maybe<Sensorflow_Reservations_Inc_Input>;
  _set?: Maybe<Sensorflow_Reservations_Set_Input>;
  where: Sensorflow_Reservations_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Reservations_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Reservations_Inc_Input>;
  _set?: Maybe<Sensorflow_Reservations_Set_Input>;
  pk_columns: Sensorflow_Reservations_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Reservations_ManyArgs = {
  updates: Array<Sensorflow_Reservations_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_RolesArgs = {
  _set?: Maybe<Sensorflow_Roles_Set_Input>;
  where: Sensorflow_Roles_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Roles_By_PkArgs = {
  _set?: Maybe<Sensorflow_Roles_Set_Input>;
  pk_columns: Sensorflow_Roles_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Roles_ManyArgs = {
  updates: Array<Sensorflow_Roles_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Smart_Rem_Validation_HistoryArgs = {
  _inc?: Maybe<Sensorflow_Smart_Rem_Validation_History_Inc_Input>;
  _set?: Maybe<Sensorflow_Smart_Rem_Validation_History_Set_Input>;
  where: Sensorflow_Smart_Rem_Validation_History_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Smart_Rem_Validation_History_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Smart_Rem_Validation_History_Inc_Input>;
  _set?: Maybe<Sensorflow_Smart_Rem_Validation_History_Set_Input>;
  pk_columns: Sensorflow_Smart_Rem_Validation_History_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Smart_Rem_Validation_History_ManyArgs = {
  updates: Array<Sensorflow_Smart_Rem_Validation_History_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Smplrspace_Floors_To_LevelsArgs = {
  _set?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Set_Input>;
  where: Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Smplrspace_Floors_To_Levels_By_PkArgs = {
  _set?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Set_Input>;
  pk_columns: Sensorflow_Smplrspace_Floors_To_Levels_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Smplrspace_Floors_To_Levels_ManyArgs = {
  updates: Array<Sensorflow_Smplrspace_Floors_To_Levels_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Smplrspace_Levels_GroupArgs = {
  _set?: Maybe<Sensorflow_Smplrspace_Levels_Group_Set_Input>;
  where: Sensorflow_Smplrspace_Levels_Group_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Smplrspace_Levels_Group_By_PkArgs = {
  _set?: Maybe<Sensorflow_Smplrspace_Levels_Group_Set_Input>;
  pk_columns: Sensorflow_Smplrspace_Levels_Group_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Smplrspace_Levels_Group_ManyArgs = {
  updates: Array<Sensorflow_Smplrspace_Levels_Group_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Survey_ResponsesArgs = {
  _append?: Maybe<Sensorflow_Survey_Responses_Append_Input>;
  _delete_at_path?: Maybe<Sensorflow_Survey_Responses_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sensorflow_Survey_Responses_Delete_Elem_Input>;
  _delete_key?: Maybe<Sensorflow_Survey_Responses_Delete_Key_Input>;
  _inc?: Maybe<Sensorflow_Survey_Responses_Inc_Input>;
  _prepend?: Maybe<Sensorflow_Survey_Responses_Prepend_Input>;
  _set?: Maybe<Sensorflow_Survey_Responses_Set_Input>;
  where: Sensorflow_Survey_Responses_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Survey_Responses_By_PkArgs = {
  _append?: Maybe<Sensorflow_Survey_Responses_Append_Input>;
  _delete_at_path?: Maybe<Sensorflow_Survey_Responses_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sensorflow_Survey_Responses_Delete_Elem_Input>;
  _delete_key?: Maybe<Sensorflow_Survey_Responses_Delete_Key_Input>;
  _inc?: Maybe<Sensorflow_Survey_Responses_Inc_Input>;
  _prepend?: Maybe<Sensorflow_Survey_Responses_Prepend_Input>;
  _set?: Maybe<Sensorflow_Survey_Responses_Set_Input>;
  pk_columns: Sensorflow_Survey_Responses_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Survey_Responses_ManyArgs = {
  updates: Array<Sensorflow_Survey_Responses_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Task_Status_HistoryArgs = {
  _inc?: Maybe<Sensorflow_Task_Status_History_Inc_Input>;
  _set?: Maybe<Sensorflow_Task_Status_History_Set_Input>;
  where: Sensorflow_Task_Status_History_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Task_Status_History_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Task_Status_History_Inc_Input>;
  _set?: Maybe<Sensorflow_Task_Status_History_Set_Input>;
  pk_columns: Sensorflow_Task_Status_History_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Task_Status_History_ManyArgs = {
  updates: Array<Sensorflow_Task_Status_History_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_TasksArgs = {
  _inc?: Maybe<Sensorflow_Tasks_Inc_Input>;
  _set?: Maybe<Sensorflow_Tasks_Set_Input>;
  where: Sensorflow_Tasks_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Tasks_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Tasks_Inc_Input>;
  _set?: Maybe<Sensorflow_Tasks_Set_Input>;
  pk_columns: Sensorflow_Tasks_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Tasks_ManyArgs = {
  updates: Array<Sensorflow_Tasks_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_TestsArgs = {
  _inc?: Maybe<Sensorflow_Tests_Inc_Input>;
  _set?: Maybe<Sensorflow_Tests_Set_Input>;
  where: Sensorflow_Tests_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Tests_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Tests_Inc_Input>;
  _set?: Maybe<Sensorflow_Tests_Set_Input>;
  pk_columns: Sensorflow_Tests_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Tests_ManyArgs = {
  updates: Array<Sensorflow_Tests_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_TimezonesArgs = {
  _inc?: Maybe<Sensorflow_Timezones_Inc_Input>;
  _set?: Maybe<Sensorflow_Timezones_Set_Input>;
  where: Sensorflow_Timezones_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Timezones_By_PkArgs = {
  _inc?: Maybe<Sensorflow_Timezones_Inc_Input>;
  _set?: Maybe<Sensorflow_Timezones_Set_Input>;
  pk_columns: Sensorflow_Timezones_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Timezones_ManyArgs = {
  updates: Array<Sensorflow_Timezones_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_User_To_Permission_MappingArgs = {
  _set?: Maybe<Sensorflow_User_To_Permission_Mapping_Set_Input>;
  where: Sensorflow_User_To_Permission_Mapping_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_User_To_Permission_Mapping_ManyArgs = {
  updates: Array<Sensorflow_User_To_Permission_Mapping_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_UsersArgs = {
  _set?: Maybe<Sensorflow_Users_Set_Input>;
  where: Sensorflow_Users_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Users_By_PkArgs = {
  _set?: Maybe<Sensorflow_Users_Set_Input>;
  pk_columns: Sensorflow_Users_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Users_ManyArgs = {
  updates: Array<Sensorflow_Users_Updates>;
};

export type Mutation_RootUpdate_Sensorflow_Warehouse_Kitting_Process_MetadataArgs = {
  _set?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Set_Input>;
  where: Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp;
};

export type Mutation_RootUpdate_Sensorflow_Warehouse_Kitting_Process_Metadata_By_PkArgs = {
  _set?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Set_Input>;
  pk_columns: Sensorflow_Warehouse_Kitting_Process_Metadata_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sensorflow_Warehouse_Kitting_Process_Metadata_ManyArgs = {
  updates: Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Updates>;
};

export type Mutation_RootUpdate_Sf_Firmware_Dist_Pipeline_Firmware_BuildArgs = {
  _inc?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Inc_Input>;
  _set?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Set_Input>;
  where: Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Firmware_Dist_Pipeline_Firmware_Build_By_PkArgs = {
  _inc?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Inc_Input>;
  _set?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Set_Input>;
  pk_columns: Sf_Firmware_Dist_Pipeline_Firmware_Build_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Firmware_Dist_Pipeline_Firmware_Build_ManyArgs = {
  updates: Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Updates>;
};

export type Mutation_RootUpdate_Sf_Firmware_Dist_Pipeline_Firmware_NumbersArgs = {
  _inc?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Inc_Input>;
  _set?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Set_Input>;
  where: Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Firmware_Dist_Pipeline_Firmware_Numbers_By_PkArgs = {
  _inc?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Inc_Input>;
  _set?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Set_Input>;
  pk_columns: Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Firmware_Dist_Pipeline_Firmware_Numbers_ManyArgs = {
  updates: Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Updates>;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_PmslistArgs = {
  _inc?: Maybe<Sf_Pms_Pms_Pmslist_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Pmslist_Set_Input>;
  where: Sf_Pms_Pms_Pmslist_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Pmslist_By_PkArgs = {
  _inc?: Maybe<Sf_Pms_Pms_Pmslist_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Pmslist_Set_Input>;
  pk_columns: Sf_Pms_Pms_Pmslist_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Pmslist_ManyArgs = {
  updates: Array<Sf_Pms_Pms_Pmslist_Updates>;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_PropertiesArgs = {
  _inc?: Maybe<Sf_Pms_Pms_Properties_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Properties_Set_Input>;
  where: Sf_Pms_Pms_Properties_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Properties_By_PkArgs = {
  _inc?: Maybe<Sf_Pms_Pms_Properties_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Properties_Set_Input>;
  pk_columns: Sf_Pms_Pms_Properties_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Properties_ManyArgs = {
  updates: Array<Sf_Pms_Pms_Properties_Updates>;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Property_Eod_SurveysArgs = {
  _inc?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Set_Input>;
  where: Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Property_Eod_Surveys_By_PkArgs = {
  _inc?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Set_Input>;
  pk_columns: Sf_Pms_Pms_Property_Eod_Surveys_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Property_Eod_Surveys_ManyArgs = {
  updates: Array<Sf_Pms_Pms_Property_Eod_Surveys_Updates>;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_ReservationsArgs = {
  _inc?: Maybe<Sf_Pms_Pms_Reservations_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Reservations_Set_Input>;
  where: Sf_Pms_Pms_Reservations_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Reservations_ManyArgs = {
  updates: Array<Sf_Pms_Pms_Reservations_Updates>;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Room_CategoriesArgs = {
  _inc?: Maybe<Sf_Pms_Pms_Room_Categories_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Room_Categories_Set_Input>;
  where: Sf_Pms_Pms_Room_Categories_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Room_Categories_ManyArgs = {
  updates: Array<Sf_Pms_Pms_Room_Categories_Updates>;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_RoomsArgs = {
  _inc?: Maybe<Sf_Pms_Pms_Rooms_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Rooms_Set_Input>;
  where: Sf_Pms_Pms_Rooms_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Pms_Pms_Rooms_ManyArgs = {
  updates: Array<Sf_Pms_Pms_Rooms_Updates>;
};

export type Mutation_RootUpdate_Sf_Pms_Smartalloc_ReportArgs = {
  _inc?: Maybe<Sf_Pms_Smartalloc_Report_Inc_Input>;
  _set?: Maybe<Sf_Pms_Smartalloc_Report_Set_Input>;
  where: Sf_Pms_Smartalloc_Report_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Pms_Smartalloc_Report_By_PkArgs = {
  _inc?: Maybe<Sf_Pms_Smartalloc_Report_Inc_Input>;
  _set?: Maybe<Sf_Pms_Smartalloc_Report_Set_Input>;
  pk_columns: Sf_Pms_Smartalloc_Report_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Pms_Smartalloc_Report_ManyArgs = {
  updates: Array<Sf_Pms_Smartalloc_Report_Updates>;
};

export type Mutation_RootUpdate_Sf_Simulation_Instrumentation_CommandsArgs = {
  _inc?: Maybe<Sf_Simulation_Instrumentation_Commands_Inc_Input>;
  _set?: Maybe<Sf_Simulation_Instrumentation_Commands_Set_Input>;
  where: Sf_Simulation_Instrumentation_Commands_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Simulation_Instrumentation_Commands_By_PkArgs = {
  _inc?: Maybe<Sf_Simulation_Instrumentation_Commands_Inc_Input>;
  _set?: Maybe<Sf_Simulation_Instrumentation_Commands_Set_Input>;
  pk_columns: Sf_Simulation_Instrumentation_Commands_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Simulation_Instrumentation_Commands_ManyArgs = {
  updates: Array<Sf_Simulation_Instrumentation_Commands_Updates>;
};

export type Mutation_RootUpdate_Sf_Support_ActionsArgs = {
  _inc?: Maybe<Sf_Support_Actions_Inc_Input>;
  _set?: Maybe<Sf_Support_Actions_Set_Input>;
  where: Sf_Support_Actions_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Support_Actions_By_PkArgs = {
  _inc?: Maybe<Sf_Support_Actions_Inc_Input>;
  _set?: Maybe<Sf_Support_Actions_Set_Input>;
  pk_columns: Sf_Support_Actions_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Support_Actions_ManyArgs = {
  updates: Array<Sf_Support_Actions_Updates>;
};

export type Mutation_RootUpdate_Sf_Support_Active_Property_AlertsArgs = {
  _inc?: Maybe<Sf_Support_Active_Property_Alerts_Inc_Input>;
  _set?: Maybe<Sf_Support_Active_Property_Alerts_Set_Input>;
  where: Sf_Support_Active_Property_Alerts_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Support_Active_Property_Alerts_By_PkArgs = {
  _inc?: Maybe<Sf_Support_Active_Property_Alerts_Inc_Input>;
  _set?: Maybe<Sf_Support_Active_Property_Alerts_Set_Input>;
  pk_columns: Sf_Support_Active_Property_Alerts_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Support_Active_Property_Alerts_ManyArgs = {
  updates: Array<Sf_Support_Active_Property_Alerts_Updates>;
};

export type Mutation_RootUpdate_Sf_Support_Analytics_Ticket_Jobs_Running_HistoriesArgs = {
  _inc?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Inc_Input>;
  _set?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Set_Input>;
  where: Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Support_Analytics_Ticket_Jobs_Running_Histories_By_PkArgs = {
  _inc?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Inc_Input>;
  _set?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Set_Input>;
  pk_columns: Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Support_Analytics_Ticket_Jobs_Running_Histories_ManyArgs = {
  updates: Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Updates>;
};

export type Mutation_RootUpdate_Sf_Support_Root_CausesArgs = {
  _inc?: Maybe<Sf_Support_Root_Causes_Inc_Input>;
  _set?: Maybe<Sf_Support_Root_Causes_Set_Input>;
  where: Sf_Support_Root_Causes_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Support_Root_Causes_By_PkArgs = {
  _inc?: Maybe<Sf_Support_Root_Causes_Inc_Input>;
  _set?: Maybe<Sf_Support_Root_Causes_Set_Input>;
  pk_columns: Sf_Support_Root_Causes_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Support_Root_Causes_ManyArgs = {
  updates: Array<Sf_Support_Root_Causes_Updates>;
};

export type Mutation_RootUpdate_Sf_Support_Ticket_ClassesArgs = {
  _inc?: Maybe<Sf_Support_Ticket_Classes_Inc_Input>;
  _set?: Maybe<Sf_Support_Ticket_Classes_Set_Input>;
  where: Sf_Support_Ticket_Classes_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Support_Ticket_Classes_By_PkArgs = {
  _inc?: Maybe<Sf_Support_Ticket_Classes_Inc_Input>;
  _set?: Maybe<Sf_Support_Ticket_Classes_Set_Input>;
  pk_columns: Sf_Support_Ticket_Classes_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Support_Ticket_Classes_ManyArgs = {
  updates: Array<Sf_Support_Ticket_Classes_Updates>;
};

export type Mutation_RootUpdate_Sf_Support_Ticket_StatusArgs = {
  _inc?: Maybe<Sf_Support_Ticket_Status_Inc_Input>;
  _set?: Maybe<Sf_Support_Ticket_Status_Set_Input>;
  where: Sf_Support_Ticket_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Support_Ticket_Status_By_PkArgs = {
  _inc?: Maybe<Sf_Support_Ticket_Status_Inc_Input>;
  _set?: Maybe<Sf_Support_Ticket_Status_Set_Input>;
  pk_columns: Sf_Support_Ticket_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Support_Ticket_Status_ManyArgs = {
  updates: Array<Sf_Support_Ticket_Status_Updates>;
};

export type Mutation_RootUpdate_Sf_Support_TicketsArgs = {
  _inc?: Maybe<Sf_Support_Tickets_Inc_Input>;
  _set?: Maybe<Sf_Support_Tickets_Set_Input>;
  where: Sf_Support_Tickets_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Support_Tickets_By_PkArgs = {
  _inc?: Maybe<Sf_Support_Tickets_Inc_Input>;
  _set?: Maybe<Sf_Support_Tickets_Set_Input>;
  pk_columns: Sf_Support_Tickets_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Support_Tickets_ManyArgs = {
  updates: Array<Sf_Support_Tickets_Updates>;
};

export type Mutation_RootUpdate_Sf_Testjig_Test_Jig_Maintenance_Report_ManyArgs = {
  updates: Array<Sf_Testjig_Test_Jig_Maintenance_Report_Updates>;
};

export type Mutation_RootUpdate_Sf_Testjig_Test_Jig_ManyArgs = {
  updates: Array<Sf_Testjig_Test_Jig_Updates>;
};

export type Mutation_RootUpdate_Sf_Testjig_Test_Jig_Report_MetadataArgs = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Set_Input>;
  where: Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Testjig_Test_Jig_Report_Metadata_By_PkArgs = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Set_Input>;
  pk_columns: Sf_Testjig_Test_Jig_Report_Metadata_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Testjig_Test_Jig_Report_Metadata_ManyArgs = {
  updates: Array<Sf_Testjig_Test_Jig_Report_Metadata_Updates>;
};

export type Mutation_RootUpdate_Sf_Testjig_Test_Jig_Report_MetadatasArgs = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Set_Input>;
  where: Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Testjig_Test_Jig_Report_Metadatas_By_PkArgs = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Set_Input>;
  pk_columns: Sf_Testjig_Test_Jig_Report_Metadatas_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Testjig_Test_Jig_Report_Metadatas_ManyArgs = {
  updates: Array<Sf_Testjig_Test_Jig_Report_Metadatas_Updates>;
};

export type Mutation_RootUpdate_Sf_Testjig_Test_Jig_Test_Report_ManyArgs = {
  updates: Array<Sf_Testjig_Test_Jig_Test_Report_Updates>;
};

export type Mutation_RootUpdate_Sf_Testjig_Test_Jig_Type_ManyArgs = {
  updates: Array<Sf_Testjig_Test_Jig_Type_Updates>;
};

export type Mutation_RootUpdate_TestRuns_By_PkArgs = {
  _inc?: Maybe<TestRuns_Inc_Input>;
  _set?: Maybe<TestRuns_Set_Input>;
  pk_columns: TestRuns_Pk_Columns_Input;
};

export type Mutation_RootUpdate_TestRuns_ManyArgs = {
  updates: Array<TestRuns_Updates>;
};

export type Mutation_RootUserCreateArgs = {
  email: Scalars["String"];
  user: UserInput;
};

export type Mutation_RootUserDisableArgs = {
  userId: Scalars["String"];
};

export type Mutation_RootUserResendWelcomeEmailArgs = {
  userId: Scalars["String"];
};

export type Mutation_RootUserResetPasswordArgs = {
  email: Scalars["String"];
};

export type Mutation_RootUserUpdateArgs = {
  user: UserInput;
  userId: Scalars["String"];
};

export type Node = {
  __typename?: "Node";
  isClimateSensor: Scalars["Boolean"];
  nodeMacId: Scalars["String"];
  subType?: Maybe<Scalars["String"]>;
  type: NodeType;
};

export type NodeHealthOverallStats = {
  __typename?: "NodeHealthOverallStats";
  nodeHealthStats: NodeHealthStats;
  nodeType: NodeType;
};

export type NodeHealthStats = {
  __typename?: "NodeHealthStats";
  bootedCount: Scalars["Int"];
  offlineCount: Scalars["Int"];
  onlineCount: Scalars["Int"];
  totalCount: Scalars["Int"];
};

export type NodeLifeCycleEvent = {
  __typename?: "NodeLifeCycleEvent";
  comment?: Maybe<Scalars["String"]>;
  creationDate: Scalars["String"];
  eventType: Scalars["Int"];
  id: Scalars["Int"];
  nodeMacId: Scalars["String"];
};

export type NodeMappedPreviously = {
  __typename?: "NodeMappedPreviously";
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  nodeMappedPreviously: Scalars["String"];
  nodeType: Scalars["String"];
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  slotName: Scalars["String"];
};

export type NodeMappingResponse = {
  __typename?: "NodeMappingResponse";
  currentKey?: Maybe<Key>;
  currentRapidMappingKey?: Maybe<Key>;
  keysStats: MappingStats;
  locationId: Scalars["ID"];
  locationName: Scalars["String"];
  mappedOrUnmappedKeyId?: Maybe<Scalars["String"]>;
  mappedOrUnmappedKeyName?: Maybe<Scalars["String"]>;
  nextRapidMappingKey?: Maybe<Key>;
  skippedSlot?: Maybe<Slot>;
};

export type NodeSpareSetReponse = {
  __typename?: "NodeSpareSetReponse";
  consumedAt?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["ID"]>;
  mappedAt?: Maybe<Scalars["String"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export enum NodeSubType {
  Aircon_2pfc = "aircon_2pfc",
  Aircon_2pfcAicool = "aircon_2pfc_aicool",
  Aircon_2pfcPlc = "aircon_2pfc_plc",
  Aircon_4pfc = "aircon_4pfc",
  AirconAcir = "aircon_acir",
  AirconDaikin = "aircon_daikin",
  DoorLaser = "door_laser",
  DoorMagnetic = "door_magnetic",
  EnergySwitchless = "energy_switchless",
  FlowMeter = "flow_meter",
  Occupancy = "occupancy",
  OccupancyCeiling = "occupancy_ceiling",
  OccupancyWall = "occupancy_wall",
  PipeTemp = "pipe_temp",
}

export enum NodeType {
  Aircon = "aircon",
  Door = "door",
  Energy = "energy",
  FlowMeter = "flow_meter",
  Occupancy = "occupancy",
  PipeTemp = "pipe_temp",
  Undefined = "undefined",
}

export enum NodeTypeCodeIdentifier {
  NodeTypeCode_00 = "NODE_TYPE_CODE_00",
  NodeTypeCode_01 = "NODE_TYPE_CODE_01",
  NodeTypeCode_02 = "NODE_TYPE_CODE_02",
  NodeTypeCode_03 = "NODE_TYPE_CODE_03",
  NodeTypeCode_04 = "NODE_TYPE_CODE_04",
  NodeTypeCode_05 = "NODE_TYPE_CODE_05",
  NodeTypeCode_06 = "NODE_TYPE_CODE_06",
  NodeTypeCode_07 = "NODE_TYPE_CODE_07",
  NodeTypeCode_08 = "NODE_TYPE_CODE_08",
  NodeTypeCode_23 = "NODE_TYPE_CODE_23",
}

export type NodeTypeStats = {
  __typename?: "NodeTypeStats";
  mappingStats?: Maybe<MappingStats>;
  nodeType: NodeType;
};

export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars["numeric"]>;
  _gt?: Maybe<Scalars["numeric"]>;
  _gte?: Maybe<Scalars["numeric"]>;
  _in?: Maybe<Array<Scalars["numeric"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["numeric"]>;
  _lte?: Maybe<Scalars["numeric"]>;
  _neq?: Maybe<Scalars["numeric"]>;
  _nin?: Maybe<Array<Scalars["numeric"]>>;
};

export enum Order_By {
  Asc = "asc",
  AscNullsFirst = "asc_nulls_first",
  AscNullsLast = "asc_nulls_last",
  Desc = "desc",
  DescNullsFirst = "desc_nulls_first",
  DescNullsLast = "desc_nulls_last",
}

export type Organisation = {
  __typename?: "Organisation";
  id: Scalars["ID"];
  name: Scalars["String"];
  status: GenericStatus;
};

export type OrganisationFilterInput = {
  name?: Maybe<Scalars["String"]>;
};

export type OrganisationSortInput = {
  name?: Maybe<SortOptions>;
};

export type OrganisationsResponse = {
  __typename?: "OrganisationsResponse";
  organisations: Array<Organisation>;
  pagination: GenericPagination;
};

export type Permission = {
  __typename?: "Permission";
  displayName: Scalars["String"];
  id: Scalars["ID"];
  permissionKey: Scalars["String"];
};

export type Position = {
  __typename?: "Position";
  locationId: Scalars["String"];
  positionId: Scalars["ID"];
  positionName: Scalars["String"];
};

export type PositionConfiguration = {
  __typename?: "PositionConfiguration";
  acModelId?: Maybe<Scalars["ID"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  expiredAt?: Maybe<Scalars["String"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Int"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId: Scalars["ID"];
  recordType: Scalars["String"];
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type PositionConfigurationInput = {
  acModelId?: Maybe<Scalars["ID"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["String"]>;
  forceOccupancyAutomation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Int"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["ID"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["Int"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Int"]>;
  nightTimeSetpointEnabled?: Maybe<Scalars["Boolean"]>;
  nightTimeSetpointEnd?: Maybe<Scalars["String"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Int"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Int"]>;
  nightTimeSetpointStart?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId: Scalars["ID"];
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type PositionConfigurations = {
  __typename?: "positionConfigurations";
  acModel?: Maybe<Sensorflow_Ac_Models>;
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate: Scalars["timestamptz"];
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  dotEnabled: Scalars["Boolean"];
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  forceOccupancyAutomation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id: Scalars["uuid"];
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUser?: Maybe<Sensorflow_Users>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nightTimeSetpointDOW: Scalars["smallint"];
  nightTimeSetpointDelay: Scalars["Int"];
  nightTimeSetpointEnabled: Scalars["Boolean"];
  nightTimeSetpointEnd?: Maybe<Scalars["String"]>;
  nightTimeSetpointMaxSetpoint: Scalars["Int"];
  nightTimeSetpointOffset: Scalars["Int"];
  nightTimeSetpointStart?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode: Scalars["String"];
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
  recordType: Scalars["String"];
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstalltionMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurations_Aggregate = {
  __typename?: "positionConfigurations_aggregate";
  aggregate?: Maybe<PositionConfigurations_Aggregate_Fields>;
  nodes: Array<PositionConfigurations>;
};

export type PositionConfigurations_Aggregate_Bool_Exp = {
  avg?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Max>;
  min?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<PositionConfigurations_Aggregate_Bool_Exp_Var_Samp>;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Avg = {
  arguments: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Bool_And = {
  arguments: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Corr = {
  arguments: PositionConfigurations_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Corr_Arguments = {
  X: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<PositionConfigurations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: PositionConfigurations_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Max = {
  arguments: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Min = {
  arguments: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Sum = {
  arguments: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Bool_Exp_Var_Samp = {
  arguments: PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurations_Aggregate_Fields = {
  __typename?: "positionConfigurations_aggregate_fields";
  avg?: Maybe<PositionConfigurations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<PositionConfigurations_Max_Fields>;
  min?: Maybe<PositionConfigurations_Min_Fields>;
  stddev?: Maybe<PositionConfigurations_Stddev_Fields>;
  stddev_pop?: Maybe<PositionConfigurations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PositionConfigurations_Stddev_Samp_Fields>;
  sum?: Maybe<PositionConfigurations_Sum_Fields>;
  var_pop?: Maybe<PositionConfigurations_Var_Pop_Fields>;
  var_samp?: Maybe<PositionConfigurations_Var_Samp_Fields>;
  variance?: Maybe<PositionConfigurations_Variance_Fields>;
};

export type PositionConfigurations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<PositionConfigurations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type PositionConfigurations_Aggregate_Order_By = {
  avg?: Maybe<PositionConfigurations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<PositionConfigurations_Max_Order_By>;
  min?: Maybe<PositionConfigurations_Min_Order_By>;
  stddev?: Maybe<PositionConfigurations_Stddev_Order_By>;
  stddev_pop?: Maybe<PositionConfigurations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<PositionConfigurations_Stddev_Samp_Order_By>;
  sum?: Maybe<PositionConfigurations_Sum_Order_By>;
  var_pop?: Maybe<PositionConfigurations_Var_Pop_Order_By>;
  var_samp?: Maybe<PositionConfigurations_Var_Samp_Order_By>;
  variance?: Maybe<PositionConfigurations_Variance_Order_By>;
};

export type PositionConfigurations_Arr_Rel_Insert_Input = {
  data: Array<PositionConfigurations_Insert_Input>;
  on_conflict?: Maybe<PositionConfigurations_On_Conflict>;
};

export type PositionConfigurations_Avg_Fields = {
  __typename?: "positionConfigurations_avg_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Float"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Float"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurations_Avg_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurations_Bool_Exp = {
  _and?: Maybe<Array<PositionConfigurations_Bool_Exp>>;
  _not?: Maybe<PositionConfigurations_Bool_Exp>;
  _or?: Maybe<Array<PositionConfigurations_Bool_Exp>>;
  acModel?: Maybe<Sensorflow_Ac_Models_Bool_Exp>;
  acModelId?: Maybe<Uuid_Comparison_Exp>;
  acNightEnd?: Maybe<String_Comparison_Exp>;
  acNightStart?: Maybe<String_Comparison_Exp>;
  actingMode?: Maybe<String_Comparison_Exp>;
  actuationTimeoutSeconds?: Maybe<Int_Comparison_Exp>;
  automationMode?: Maybe<String_Comparison_Exp>;
  bridgeAdaptorSetting?: Maybe<Int_Comparison_Exp>;
  creationDate?: Maybe<Timestamptz_Comparison_Exp>;
  desiredEmonSwitchPosition?: Maybe<Int_Comparison_Exp>;
  doorSensorTimeoutMins?: Maybe<Int_Comparison_Exp>;
  dotEnabled?: Maybe<Boolean_Comparison_Exp>;
  enableDoorSensor?: Maybe<Boolean_Comparison_Exp>;
  expiredAt?: Maybe<Timestamptz_Comparison_Exp>;
  forceOccupancyAutomation?: Maybe<Boolean_Comparison_Exp>;
  freezeProtectionTemp?: Maybe<Int_Comparison_Exp>;
  heatingHideSetpoint?: Maybe<Boolean_Comparison_Exp>;
  heatingMaxTemp?: Maybe<Int_Comparison_Exp>;
  heatingMinTemp?: Maybe<Int_Comparison_Exp>;
  heatingUnoccupiedTemp?: Maybe<Int_Comparison_Exp>;
  hideSetpoint?: Maybe<Boolean_Comparison_Exp>;
  hysteresisOffDegrees?: Maybe<Float8_Comparison_Exp>;
  hysteresisOnDegrees?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isDeviatedFromDefaultConfig?: Maybe<Boolean_Comparison_Exp>;
  maxTemp?: Maybe<Int_Comparison_Exp>;
  minTemp?: Maybe<Int_Comparison_Exp>;
  modifiedByUser?: Maybe<Sensorflow_Users_Bool_Exp>;
  modifiedByUserId?: Maybe<String_Comparison_Exp>;
  nightTimeSetpointDOW?: Maybe<Smallint_Comparison_Exp>;
  nightTimeSetpointDelay?: Maybe<Int_Comparison_Exp>;
  nightTimeSetpointEnabled?: Maybe<Boolean_Comparison_Exp>;
  nightTimeSetpointEnd?: Maybe<String_Comparison_Exp>;
  nightTimeSetpointMaxSetpoint?: Maybe<Int_Comparison_Exp>;
  nightTimeSetpointOffset?: Maybe<Int_Comparison_Exp>;
  nightTimeSetpointStart?: Maybe<String_Comparison_Exp>;
  occNightEnd?: Maybe<String_Comparison_Exp>;
  occNightStart?: Maybe<String_Comparison_Exp>;
  occTimeoutDayMins?: Maybe<Int_Comparison_Exp>;
  occTimeoutNightMins?: Maybe<Int_Comparison_Exp>;
  occupancySensitivity?: Maybe<Int_Comparison_Exp>;
  operationalMode?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  recordType?: Maybe<String_Comparison_Exp>;
  softstartWaitTime?: Maybe<Int_Comparison_Exp>;
  specialInstalltionMode?: Maybe<Boolean_Comparison_Exp>;
  unoccupiedTemp?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum PositionConfigurations_Constraint {
  PositionConfigurationsPkey = "position_configurations_pkey",
}

export type PositionConfigurations_Inc_Input = {
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["smallint"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Int"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Int"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Int"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type PositionConfigurations_Insert_Input = {
  acModel?: Maybe<Sensorflow_Ac_Models_Obj_Rel_Insert_Input>;
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  dotEnabled?: Maybe<Scalars["Boolean"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  forceOccupancyAutomation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUser?: Maybe<Sensorflow_Users_Obj_Rel_Insert_Input>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["smallint"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Int"]>;
  nightTimeSetpointEnabled?: Maybe<Scalars["Boolean"]>;
  nightTimeSetpointEnd?: Maybe<Scalars["String"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Int"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Int"]>;
  nightTimeSetpointStart?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstalltionMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurations_Max_Fields = {
  __typename?: "positionConfigurations_max_fields";
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["smallint"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Int"]>;
  nightTimeSetpointEnd?: Maybe<Scalars["String"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Int"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Int"]>;
  nightTimeSetpointStart?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurations_Max_Order_By = {
  acModelId?: Maybe<Order_By>;
  acNightEnd?: Maybe<Order_By>;
  acNightStart?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  actuationTimeoutSeconds?: Maybe<Order_By>;
  automationMode?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  expiredAt?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  modifiedByUserId?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointEnd?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  nightTimeSetpointStart?: Maybe<Order_By>;
  occNightEnd?: Maybe<Order_By>;
  occNightStart?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  recordType?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type PositionConfigurations_Min_Fields = {
  __typename?: "positionConfigurations_min_fields";
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["smallint"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Int"]>;
  nightTimeSetpointEnd?: Maybe<Scalars["String"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Int"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Int"]>;
  nightTimeSetpointStart?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurations_Min_Order_By = {
  acModelId?: Maybe<Order_By>;
  acNightEnd?: Maybe<Order_By>;
  acNightStart?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  actuationTimeoutSeconds?: Maybe<Order_By>;
  automationMode?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  expiredAt?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  modifiedByUserId?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointEnd?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  nightTimeSetpointStart?: Maybe<Order_By>;
  occNightEnd?: Maybe<Order_By>;
  occNightStart?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  recordType?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type PositionConfigurations_Mutation_Response = {
  __typename?: "positionConfigurations_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<PositionConfigurations>;
};

export type PositionConfigurations_On_Conflict = {
  constraint: PositionConfigurations_Constraint;
  update_columns: Array<PositionConfigurations_Update_Column>;
  where?: Maybe<PositionConfigurations_Bool_Exp>;
};

export type PositionConfigurations_Order_By = {
  acModel?: Maybe<Sensorflow_Ac_Models_Order_By>;
  acModelId?: Maybe<Order_By>;
  acNightEnd?: Maybe<Order_By>;
  acNightStart?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  actuationTimeoutSeconds?: Maybe<Order_By>;
  automationMode?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  dotEnabled?: Maybe<Order_By>;
  enableDoorSensor?: Maybe<Order_By>;
  expiredAt?: Maybe<Order_By>;
  forceOccupancyAutomation?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingHideSetpoint?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hideSetpoint?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isDeviatedFromDefaultConfig?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  modifiedByUser?: Maybe<Sensorflow_Users_Order_By>;
  modifiedByUserId?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointEnabled?: Maybe<Order_By>;
  nightTimeSetpointEnd?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  nightTimeSetpointStart?: Maybe<Order_By>;
  occNightEnd?: Maybe<Order_By>;
  occNightStart?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  recordType?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  specialInstalltionMode?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type PositionConfigurations_Pk_Columns_Input = {
  creationDate: Scalars["timestamptz"];
  id: Scalars["uuid"];
};

export enum PositionConfigurations_Select_Column {
  AcModelId = "acModelId",
  AcNightEnd = "acNightEnd",
  AcNightStart = "acNightStart",
  ActingMode = "actingMode",
  ActuationTimeoutSeconds = "actuationTimeoutSeconds",
  AutomationMode = "automationMode",
  BridgeAdaptorSetting = "bridgeAdaptorSetting",
  CreationDate = "creationDate",
  DesiredEmonSwitchPosition = "desiredEmonSwitchPosition",
  DoorSensorTimeoutMins = "doorSensorTimeoutMins",
  DotEnabled = "dotEnabled",
  EnableDoorSensor = "enableDoorSensor",
  ExpiredAt = "expiredAt",
  ForceOccupancyAutomation = "forceOccupancyAutomation",
  FreezeProtectionTemp = "freezeProtectionTemp",
  HeatingHideSetpoint = "heatingHideSetpoint",
  HeatingMaxTemp = "heatingMaxTemp",
  HeatingMinTemp = "heatingMinTemp",
  HeatingUnoccupiedTemp = "heatingUnoccupiedTemp",
  HideSetpoint = "hideSetpoint",
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
  Id = "id",
  IsDeviatedFromDefaultConfig = "isDeviatedFromDefaultConfig",
  MaxTemp = "maxTemp",
  MinTemp = "minTemp",
  ModifiedByUserId = "modifiedByUserId",
  NightTimeSetpointDow = "nightTimeSetpointDOW",
  NightTimeSetpointDelay = "nightTimeSetpointDelay",
  NightTimeSetpointEnabled = "nightTimeSetpointEnabled",
  NightTimeSetpointEnd = "nightTimeSetpointEnd",
  NightTimeSetpointMaxSetpoint = "nightTimeSetpointMaxSetpoint",
  NightTimeSetpointOffset = "nightTimeSetpointOffset",
  NightTimeSetpointStart = "nightTimeSetpointStart",
  OccNightEnd = "occNightEnd",
  OccNightStart = "occNightStart",
  OccTimeoutDayMins = "occTimeoutDayMins",
  OccTimeoutNightMins = "occTimeoutNightMins",
  OccupancySensitivity = "occupancySensitivity",
  OperationalMode = "operationalMode",
  PositionId = "positionId",
  RecordType = "recordType",
  SoftstartWaitTime = "softstartWaitTime",
  SpecialInstalltionMode = "specialInstalltionMode",
  UnoccupiedTemp = "unoccupiedTemp",
  UpdatedAt = "updated_at",
}

export enum PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  DotEnabled = "dotEnabled",
  EnableDoorSensor = "enableDoorSensor",
  ForceOccupancyAutomation = "forceOccupancyAutomation",
  HeatingHideSetpoint = "heatingHideSetpoint",
  HideSetpoint = "hideSetpoint",
  IsDeviatedFromDefaultConfig = "isDeviatedFromDefaultConfig",
  NightTimeSetpointEnabled = "nightTimeSetpointEnabled",
  SpecialInstalltionMode = "specialInstalltionMode",
}

export enum PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  DotEnabled = "dotEnabled",
  EnableDoorSensor = "enableDoorSensor",
  ForceOccupancyAutomation = "forceOccupancyAutomation",
  HeatingHideSetpoint = "heatingHideSetpoint",
  HideSetpoint = "hideSetpoint",
  IsDeviatedFromDefaultConfig = "isDeviatedFromDefaultConfig",
  NightTimeSetpointEnabled = "nightTimeSetpointEnabled",
  SpecialInstalltionMode = "specialInstalltionMode",
}

export enum PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Max_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Min_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurations_Select_Column_PositionConfigurations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export type PositionConfigurations_Set_Input = {
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  dotEnabled?: Maybe<Scalars["Boolean"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  forceOccupancyAutomation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["smallint"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Int"]>;
  nightTimeSetpointEnabled?: Maybe<Scalars["Boolean"]>;
  nightTimeSetpointEnd?: Maybe<Scalars["String"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Int"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Int"]>;
  nightTimeSetpointStart?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstalltionMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurations_Stddev_Fields = {
  __typename?: "positionConfigurations_stddev_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Float"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Float"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurations_Stddev_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurations_Stddev_Pop_Fields = {
  __typename?: "positionConfigurations_stddev_pop_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Float"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Float"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurations_Stddev_Pop_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurations_Stddev_Samp_Fields = {
  __typename?: "positionConfigurations_stddev_samp_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Float"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Float"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurations_Stddev_Samp_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurations_Stream_Cursor_Input = {
  initial_value: PositionConfigurations_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type PositionConfigurations_Stream_Cursor_Value_Input = {
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  dotEnabled?: Maybe<Scalars["Boolean"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  forceOccupancyAutomation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["smallint"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Int"]>;
  nightTimeSetpointEnabled?: Maybe<Scalars["Boolean"]>;
  nightTimeSetpointEnd?: Maybe<Scalars["String"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Int"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Int"]>;
  nightTimeSetpointStart?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstalltionMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurations_Sum_Fields = {
  __typename?: "positionConfigurations_sum_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["smallint"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Int"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Int"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Int"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type PositionConfigurations_Sum_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export enum PositionConfigurations_Update_Column {
  AcModelId = "acModelId",
  AcNightEnd = "acNightEnd",
  AcNightStart = "acNightStart",
  ActingMode = "actingMode",
  ActuationTimeoutSeconds = "actuationTimeoutSeconds",
  AutomationMode = "automationMode",
  BridgeAdaptorSetting = "bridgeAdaptorSetting",
  CreationDate = "creationDate",
  DesiredEmonSwitchPosition = "desiredEmonSwitchPosition",
  DoorSensorTimeoutMins = "doorSensorTimeoutMins",
  DotEnabled = "dotEnabled",
  EnableDoorSensor = "enableDoorSensor",
  ExpiredAt = "expiredAt",
  ForceOccupancyAutomation = "forceOccupancyAutomation",
  FreezeProtectionTemp = "freezeProtectionTemp",
  HeatingHideSetpoint = "heatingHideSetpoint",
  HeatingMaxTemp = "heatingMaxTemp",
  HeatingMinTemp = "heatingMinTemp",
  HeatingUnoccupiedTemp = "heatingUnoccupiedTemp",
  HideSetpoint = "hideSetpoint",
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
  Id = "id",
  IsDeviatedFromDefaultConfig = "isDeviatedFromDefaultConfig",
  MaxTemp = "maxTemp",
  MinTemp = "minTemp",
  ModifiedByUserId = "modifiedByUserId",
  NightTimeSetpointDow = "nightTimeSetpointDOW",
  NightTimeSetpointDelay = "nightTimeSetpointDelay",
  NightTimeSetpointEnabled = "nightTimeSetpointEnabled",
  NightTimeSetpointEnd = "nightTimeSetpointEnd",
  NightTimeSetpointMaxSetpoint = "nightTimeSetpointMaxSetpoint",
  NightTimeSetpointOffset = "nightTimeSetpointOffset",
  NightTimeSetpointStart = "nightTimeSetpointStart",
  OccNightEnd = "occNightEnd",
  OccNightStart = "occNightStart",
  OccTimeoutDayMins = "occTimeoutDayMins",
  OccTimeoutNightMins = "occTimeoutNightMins",
  OccupancySensitivity = "occupancySensitivity",
  OperationalMode = "operationalMode",
  PositionId = "positionId",
  RecordType = "recordType",
  SoftstartWaitTime = "softstartWaitTime",
  SpecialInstalltionMode = "specialInstalltionMode",
  UnoccupiedTemp = "unoccupiedTemp",
  UpdatedAt = "updated_at",
}

export type PositionConfigurations_Updates = {
  _inc?: Maybe<PositionConfigurations_Inc_Input>;
  _set?: Maybe<PositionConfigurations_Set_Input>;
  where: PositionConfigurations_Bool_Exp;
};

export type PositionConfigurations_Var_Pop_Fields = {
  __typename?: "positionConfigurations_var_pop_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Float"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Float"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurations_Var_Pop_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurations_Var_Samp_Fields = {
  __typename?: "positionConfigurations_var_samp_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Float"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Float"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurations_Var_Samp_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurations_Variance_Fields = {
  __typename?: "positionConfigurations_variance_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDOW?: Maybe<Scalars["Float"]>;
  nightTimeSetpointDelay?: Maybe<Scalars["Float"]>;
  nightTimeSetpointMaxSetpoint?: Maybe<Scalars["Float"]>;
  nightTimeSetpointOffset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurations_Variance_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  nightTimeSetpointDOW?: Maybe<Order_By>;
  nightTimeSetpointDelay?: Maybe<Order_By>;
  nightTimeSetpointMaxSetpoint?: Maybe<Order_By>;
  nightTimeSetpointOffset?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurationsForActivityLog = {
  __typename?: "positionConfigurationsForActivityLog";
  acModel?: Maybe<Sensorflow_Ac_Models>;
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  force_occupancy_automation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUser?: Maybe<Sensorflow_Users>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nextPosConfigCreationDate?: Maybe<Scalars["timestamptz"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstallationMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurationsForActivityLog_Aggregate = {
  __typename?: "positionConfigurationsForActivityLog_aggregate";
  aggregate?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Fields>;
  nodes: Array<PositionConfigurationsForActivityLog>;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp = {
  avg?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Max>;
  min?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Var_Samp>;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Avg = {
  arguments: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Bool_And = {
  arguments: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Corr = {
  arguments: PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Corr_Arguments = {
  X: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<PositionConfigurationsForActivityLog_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Max = {
  arguments: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Min = {
  arguments: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Sum = {
  arguments: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Var_Samp = {
  arguments: PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PositionConfigurationsForActivityLog_Aggregate_Fields = {
  __typename?: "positionConfigurationsForActivityLog_aggregate_fields";
  avg?: Maybe<PositionConfigurationsForActivityLog_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<PositionConfigurationsForActivityLog_Max_Fields>;
  min?: Maybe<PositionConfigurationsForActivityLog_Min_Fields>;
  stddev?: Maybe<PositionConfigurationsForActivityLog_Stddev_Fields>;
  stddev_pop?: Maybe<PositionConfigurationsForActivityLog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PositionConfigurationsForActivityLog_Stddev_Samp_Fields>;
  sum?: Maybe<PositionConfigurationsForActivityLog_Sum_Fields>;
  var_pop?: Maybe<PositionConfigurationsForActivityLog_Var_Pop_Fields>;
  var_samp?: Maybe<PositionConfigurationsForActivityLog_Var_Samp_Fields>;
  variance?: Maybe<PositionConfigurationsForActivityLog_Variance_Fields>;
};

export type PositionConfigurationsForActivityLog_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<PositionConfigurationsForActivityLog_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type PositionConfigurationsForActivityLog_Aggregate_Order_By = {
  avg?: Maybe<PositionConfigurationsForActivityLog_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<PositionConfigurationsForActivityLog_Max_Order_By>;
  min?: Maybe<PositionConfigurationsForActivityLog_Min_Order_By>;
  stddev?: Maybe<PositionConfigurationsForActivityLog_Stddev_Order_By>;
  stddev_pop?: Maybe<PositionConfigurationsForActivityLog_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<PositionConfigurationsForActivityLog_Stddev_Samp_Order_By>;
  sum?: Maybe<PositionConfigurationsForActivityLog_Sum_Order_By>;
  var_pop?: Maybe<PositionConfigurationsForActivityLog_Var_Pop_Order_By>;
  var_samp?: Maybe<PositionConfigurationsForActivityLog_Var_Samp_Order_By>;
  variance?: Maybe<PositionConfigurationsForActivityLog_Variance_Order_By>;
};

export type PositionConfigurationsForActivityLog_Arr_Rel_Insert_Input = {
  data: Array<PositionConfigurationsForActivityLog_Insert_Input>;
};

export type PositionConfigurationsForActivityLog_Avg_Fields = {
  __typename?: "positionConfigurationsForActivityLog_avg_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurationsForActivityLog_Avg_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurationsForActivityLog_Bool_Exp = {
  _and?: Maybe<Array<PositionConfigurationsForActivityLog_Bool_Exp>>;
  _not?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  _or?: Maybe<Array<PositionConfigurationsForActivityLog_Bool_Exp>>;
  acModel?: Maybe<Sensorflow_Ac_Models_Bool_Exp>;
  acModelId?: Maybe<Uuid_Comparison_Exp>;
  acNightEnd?: Maybe<String_Comparison_Exp>;
  acNightStart?: Maybe<String_Comparison_Exp>;
  actingMode?: Maybe<String_Comparison_Exp>;
  actuationTimeoutSeconds?: Maybe<Int_Comparison_Exp>;
  automationMode?: Maybe<String_Comparison_Exp>;
  bridgeAdaptorSetting?: Maybe<Int_Comparison_Exp>;
  creationDate?: Maybe<Timestamptz_Comparison_Exp>;
  desiredEmonSwitchPosition?: Maybe<Int_Comparison_Exp>;
  doorSensorTimeoutMins?: Maybe<Int_Comparison_Exp>;
  enableDoorSensor?: Maybe<Boolean_Comparison_Exp>;
  expiredAt?: Maybe<Timestamptz_Comparison_Exp>;
  force_occupancy_automation?: Maybe<Boolean_Comparison_Exp>;
  freezeProtectionTemp?: Maybe<Int_Comparison_Exp>;
  heatingHideSetpoint?: Maybe<Boolean_Comparison_Exp>;
  heatingMaxTemp?: Maybe<Int_Comparison_Exp>;
  heatingMinTemp?: Maybe<Int_Comparison_Exp>;
  heatingUnoccupiedTemp?: Maybe<Int_Comparison_Exp>;
  hideSetpoint?: Maybe<Boolean_Comparison_Exp>;
  hysteresisOffDegrees?: Maybe<Float8_Comparison_Exp>;
  hysteresisOnDegrees?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isDeviatedFromDefaultConfig?: Maybe<Boolean_Comparison_Exp>;
  maxTemp?: Maybe<Int_Comparison_Exp>;
  minTemp?: Maybe<Int_Comparison_Exp>;
  modifiedByUser?: Maybe<Sensorflow_Users_Bool_Exp>;
  modifiedByUserId?: Maybe<String_Comparison_Exp>;
  nextPosConfigCreationDate?: Maybe<Timestamptz_Comparison_Exp>;
  occNightEnd?: Maybe<String_Comparison_Exp>;
  occNightStart?: Maybe<String_Comparison_Exp>;
  occTimeoutDayMins?: Maybe<Int_Comparison_Exp>;
  occTimeoutNightMins?: Maybe<Int_Comparison_Exp>;
  occupancySensitivity?: Maybe<Int_Comparison_Exp>;
  operationalMode?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  recordType?: Maybe<String_Comparison_Exp>;
  softstartWaitTime?: Maybe<Int_Comparison_Exp>;
  specialInstallationMode?: Maybe<Boolean_Comparison_Exp>;
  unoccupiedTemp?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

export type PositionConfigurationsForActivityLog_Insert_Input = {
  acModel?: Maybe<Sensorflow_Ac_Models_Obj_Rel_Insert_Input>;
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  force_occupancy_automation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUser?: Maybe<Sensorflow_Users_Obj_Rel_Insert_Input>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nextPosConfigCreationDate?: Maybe<Scalars["timestamptz"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstallationMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurationsForActivityLog_Max_Fields = {
  __typename?: "positionConfigurationsForActivityLog_max_fields";
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nextPosConfigCreationDate?: Maybe<Scalars["timestamptz"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurationsForActivityLog_Max_Order_By = {
  acModelId?: Maybe<Order_By>;
  acNightEnd?: Maybe<Order_By>;
  acNightStart?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  actuationTimeoutSeconds?: Maybe<Order_By>;
  automationMode?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  expiredAt?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  modifiedByUserId?: Maybe<Order_By>;
  nextPosConfigCreationDate?: Maybe<Order_By>;
  occNightEnd?: Maybe<Order_By>;
  occNightStart?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  recordType?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

export type PositionConfigurationsForActivityLog_Min_Fields = {
  __typename?: "positionConfigurationsForActivityLog_min_fields";
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nextPosConfigCreationDate?: Maybe<Scalars["timestamptz"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurationsForActivityLog_Min_Order_By = {
  acModelId?: Maybe<Order_By>;
  acNightEnd?: Maybe<Order_By>;
  acNightStart?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  actuationTimeoutSeconds?: Maybe<Order_By>;
  automationMode?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  expiredAt?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  modifiedByUserId?: Maybe<Order_By>;
  nextPosConfigCreationDate?: Maybe<Order_By>;
  occNightEnd?: Maybe<Order_By>;
  occNightStart?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  recordType?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

export type PositionConfigurationsForActivityLog_Order_By = {
  acModel?: Maybe<Sensorflow_Ac_Models_Order_By>;
  acModelId?: Maybe<Order_By>;
  acNightEnd?: Maybe<Order_By>;
  acNightStart?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  actuationTimeoutSeconds?: Maybe<Order_By>;
  automationMode?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  enableDoorSensor?: Maybe<Order_By>;
  expiredAt?: Maybe<Order_By>;
  force_occupancy_automation?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingHideSetpoint?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hideSetpoint?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isDeviatedFromDefaultConfig?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  modifiedByUser?: Maybe<Sensorflow_Users_Order_By>;
  modifiedByUserId?: Maybe<Order_By>;
  nextPosConfigCreationDate?: Maybe<Order_By>;
  occNightEnd?: Maybe<Order_By>;
  occNightStart?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  recordType?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  specialInstallationMode?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

export enum PositionConfigurationsForActivityLog_Select_Column {
  AcModelId = "acModelId",
  AcNightEnd = "acNightEnd",
  AcNightStart = "acNightStart",
  ActingMode = "actingMode",
  ActuationTimeoutSeconds = "actuationTimeoutSeconds",
  AutomationMode = "automationMode",
  BridgeAdaptorSetting = "bridgeAdaptorSetting",
  CreationDate = "creationDate",
  DesiredEmonSwitchPosition = "desiredEmonSwitchPosition",
  DoorSensorTimeoutMins = "doorSensorTimeoutMins",
  EnableDoorSensor = "enableDoorSensor",
  ExpiredAt = "expiredAt",
  ForceOccupancyAutomation = "force_occupancy_automation",
  FreezeProtectionTemp = "freezeProtectionTemp",
  HeatingHideSetpoint = "heatingHideSetpoint",
  HeatingMaxTemp = "heatingMaxTemp",
  HeatingMinTemp = "heatingMinTemp",
  HeatingUnoccupiedTemp = "heatingUnoccupiedTemp",
  HideSetpoint = "hideSetpoint",
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
  Id = "id",
  IsDeviatedFromDefaultConfig = "isDeviatedFromDefaultConfig",
  MaxTemp = "maxTemp",
  MinTemp = "minTemp",
  ModifiedByUserId = "modifiedByUserId",
  NextPosConfigCreationDate = "nextPosConfigCreationDate",
  OccNightEnd = "occNightEnd",
  OccNightStart = "occNightStart",
  OccTimeoutDayMins = "occTimeoutDayMins",
  OccTimeoutNightMins = "occTimeoutNightMins",
  OccupancySensitivity = "occupancySensitivity",
  OperationalMode = "operationalMode",
  PositionId = "positionId",
  RecordType = "recordType",
  SoftstartWaitTime = "softstartWaitTime",
  SpecialInstallationMode = "specialInstallationMode",
  UnoccupiedTemp = "unoccupiedTemp",
  UpdatedAt = "updatedAt",
}

export enum PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  EnableDoorSensor = "enableDoorSensor",
  ForceOccupancyAutomation = "force_occupancy_automation",
  HeatingHideSetpoint = "heatingHideSetpoint",
  HideSetpoint = "hideSetpoint",
  IsDeviatedFromDefaultConfig = "isDeviatedFromDefaultConfig",
  SpecialInstallationMode = "specialInstallationMode",
}

export enum PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  EnableDoorSensor = "enableDoorSensor",
  ForceOccupancyAutomation = "force_occupancy_automation",
  HeatingHideSetpoint = "heatingHideSetpoint",
  HideSetpoint = "hideSetpoint",
  IsDeviatedFromDefaultConfig = "isDeviatedFromDefaultConfig",
  SpecialInstallationMode = "specialInstallationMode",
}

export enum PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Max_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Min_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export enum PositionConfigurationsForActivityLog_Select_Column_PositionConfigurationsForActivityLog_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
}

export type PositionConfigurationsForActivityLog_Stddev_Fields = {
  __typename?: "positionConfigurationsForActivityLog_stddev_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurationsForActivityLog_Stddev_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurationsForActivityLog_Stddev_Pop_Fields = {
  __typename?: "positionConfigurationsForActivityLog_stddev_pop_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurationsForActivityLog_Stddev_Pop_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurationsForActivityLog_Stddev_Samp_Fields = {
  __typename?: "positionConfigurationsForActivityLog_stddev_samp_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurationsForActivityLog_Stddev_Samp_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurationsForActivityLog_Stream_Cursor_Input = {
  initial_value: PositionConfigurationsForActivityLog_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type PositionConfigurationsForActivityLog_Stream_Cursor_Value_Input = {
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  force_occupancy_automation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nextPosConfigCreationDate?: Maybe<Scalars["timestamptz"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstallationMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type PositionConfigurationsForActivityLog_Sum_Fields = {
  __typename?: "positionConfigurationsForActivityLog_sum_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type PositionConfigurationsForActivityLog_Sum_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurationsForActivityLog_Var_Pop_Fields = {
  __typename?: "positionConfigurationsForActivityLog_var_pop_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurationsForActivityLog_Var_Pop_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurationsForActivityLog_Var_Samp_Fields = {
  __typename?: "positionConfigurationsForActivityLog_var_samp_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurationsForActivityLog_Var_Samp_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurationsForActivityLog_Variance_Fields = {
  __typename?: "positionConfigurationsForActivityLog_variance_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type PositionConfigurationsForActivityLog_Variance_Order_By = {
  actuationTimeoutSeconds?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export type PositionConfigurationWithThermostatStatus = {
  __typename?: "PositionConfigurationWithThermostatStatus";
  parentPositionId: Scalars["ID"];
  positionId: Scalars["ID"];
  positionName: Scalars["String"];
  thermostatOfflineStatus?: Maybe<Scalars["Boolean"]>;
};

export type PositionInput = {
  locationId: Scalars["ID"];
  parentPositionId: Scalars["ID"];
  positionName: Scalars["String"];
};

export type PrecoolCommand = {
  __typename?: "PrecoolCommand";
  createdBy?: Maybe<Scalars["String"]>;
  createdDate: Scalars["String"];
  failMessage?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  mode?: Maybe<Scalars["Int"]>;
  positionId: Scalars["ID"];
  source: Scalars["String"];
  startedAt?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes: Scalars["Int"];
  updatedAt?: Maybe<Scalars["String"]>;
};

export type Query_Root = {
  __typename?: "query_root";
  ACModel: AcModel;
  ACModels?: Maybe<AcModelsResponse>;
  TestJig?: Maybe<Sf_Testjig_Test_Jig>;
  TestJigMaintenanceReport?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report>;
  TestJigMaintenanceReports: Array<Sf_Testjig_Test_Jig_Maintenance_Report>;
  TestJigReportMetadata: Array<Sf_Testjig_Test_Jig_Report_Metadata>;
  TestJigReportMetadatas: Array<Sf_Testjig_Test_Jig_Report_Metadatas>;
  TestJigTestReport?: Maybe<Sf_Testjig_Test_Jig_Test_Report>;
  TestJigTestReports: Array<Sf_Testjig_Test_Jig_Test_Report>;
  TestJigType?: Maybe<Sf_Testjig_Test_Jig_Type>;
  TestJigTypes: Array<Sf_Testjig_Test_Jig_Type>;
  TestJigs: Array<Sf_Testjig_Test_Jig>;
  commonRoomNames: Array<Scalars["String"]>;
  currentPositionConfiguration: Array<Sensorflow_V_Current_Position_Configurations>;
  domoEmbed?: Maybe<Sensorflow_Domo_Embeds>;
  domoEmbeds: Array<Sensorflow_Domo_Embeds>;
  embeddedAnalyticsInfo: EmbeddedAnalyticsInfo;
  emonMapInputInfo: EmonMapInputInfo;
  firmwareReleases: Array<Sensorflow_Firmware_Releases>;
  firmwareUploadLink: Scalars["String"];
  gatewayImageDownloadUrl: Scalars["String"];
  gatewayKittingProcessMetadata: Array<Sensorflow_Warehouse_Kitting_Process_Metadata>;
  gatewayToPositionMappings: Array<Sensorflow_Gateway_To_Position_Mapping>;
  gateways: Array<Sensorflow_Gateways>;
  generateRMAAssetUploadLink: GenerateRmaAssetUploadLinkResponse;
  getHolisticsToken: Scalars["String"];
  getNodeMappedPreviously?: Maybe<NodeMappedPreviously>;
  getRMAAssetDownloadLink: GetRmaAssetDownloadLinkResponse;
  getSmartRemValidationHistory: Array<Sensorflow_Smart_Rem_Validation_History>;
  infrastructureEmonPowerStatus: Array<Sensorflow_V_Infrastructure_Emon_Power_Status>;
  infrastructures: Array<Sensorflow_V_Infrastructures>;
  key: Key;
  keyCategories: Array<Sensorflow_Key_Categories>;
  keyEntries: Array<KeyEntries>;
  keyEntries_aggregate: KeyEntries_Aggregate;
  keyEntries_by_pk?: Maybe<KeyEntries>;
  keys: KeysResponse;
  keysInLocation: Location;
  keysTasks: KeysResponse;
  location?: Maybe<Location>;
  locationDomoEmbed?: Maybe<Sensorflow_Location_Domo_Embeds>;
  locationDomoEmbeds: Array<Sensorflow_Location_Domo_Embeds>;
  locationEmbeddedAnalyticsInfo: EmbeddedAnalyticsInfo;
  locationList: Array<Sensorflow_Locations>;
  locationMetadata?: Maybe<Sensorflow_Location_Metadata>;
  locationOne?: Maybe<Sensorflow_Locations>;
  locations: LocationsResponse;
  locationsAssignedToUser: LocationsResponse;
  nodeException: Array<Sensorflow_Node_Exception>;
  nodeExceptionAggregate: Sensorflow_Node_Exception_Aggregate;
  nodeExceptionByPk?: Maybe<Sensorflow_Node_Exception>;
  nodeExceptions: Array<Sensorflow_Node_Exceptions>;
  nodeExceptionsAggregate: Sensorflow_Node_Exceptions_Aggregate;
  nodeExceptionsByPk?: Maybe<Sensorflow_Node_Exceptions>;
  nodeJoinStatuses: Array<Sensorflow_Node_Join_Status>;
  nodeOnlineStatuses: Array<Sensorflow_Node_Online_Status>;
  organisation: Organisation;
  organisations: OrganisationsResponse;
  position?: Maybe<Sensorflow_Positions>;
  positionConfigurations: Array<PositionConfigurations>;
  positionConfigurationsForActivityLog: Array<PositionConfigurationsForActivityLog>;
  positionConfigurationsForActivityLog_aggregate: PositionConfigurationsForActivityLog_Aggregate;
  positionConfigurations_aggregate: PositionConfigurations_Aggregate;
  positionConfigurations_by_pk?: Maybe<PositionConfigurations>;
  positionPrecoolStatus: Array<Sensorflow_Position_Precool_Status>;
  positions: Array<Sensorflow_Positions>;
  positionsAggregate: Sensorflow_Positions_Aggregate;
  rapidMapping: NodeMappingResponse;
  roles: Array<Role>;
  room: Room;
  root?: Maybe<Scalars["String"]>;
  sensorflow_ac_command_event_stream: Array<Sensorflow_Ac_Command_Event_Stream>;
  sensorflow_ac_command_event_stream_aggregate: Sensorflow_Ac_Command_Event_Stream_Aggregate;
  sensorflow_ac_models: Array<Sensorflow_Ac_Models>;
  sensorflow_ac_models_aggregate: Sensorflow_Ac_Models_Aggregate;
  sensorflow_ac_models_by_pk?: Maybe<Sensorflow_Ac_Models>;
  sensorflow_ac_setting_commands: Array<Sensorflow_Ac_Setting_Commands>;
  sensorflow_ac_setting_commands_aggregate: Sensorflow_Ac_Setting_Commands_Aggregate;
  sensorflow_ac_setting_commands_by_pk?: Maybe<Sensorflow_Ac_Setting_Commands>;
  sensorflow_ai_dt_ac_event_stream: Array<Sensorflow_Ai_Dt_Ac_Event_Stream>;
  sensorflow_ai_dt_ac_event_stream_aggregate: Sensorflow_Ai_Dt_Ac_Event_Stream_Aggregate;
  sensorflow_ai_dt_config_event_stream: Array<Sensorflow_Ai_Dt_Config_Event_Stream>;
  sensorflow_ai_dt_config_event_stream_aggregate: Sensorflow_Ai_Dt_Config_Event_Stream_Aggregate;
  sensorflow_ai_dt_event_stream: Array<Sensorflow_Ai_Dt_Event_Stream>;
  sensorflow_ai_dt_event_stream_aggregate: Sensorflow_Ai_Dt_Event_Stream_Aggregate;
  sensorflow_ai_dt_room_comfort_report: Array<Sensorflow_Ai_Dt_Room_Comfort_Report>;
  sensorflow_ai_dt_room_comfort_report_aggregate: Sensorflow_Ai_Dt_Room_Comfort_Report_Aggregate;
  sensorflow_ai_dt_synced_raw_data: Array<Sensorflow_Ai_Dt_Synced_Raw_Data>;
  sensorflow_ai_dt_synced_raw_data_aggregate: Sensorflow_Ai_Dt_Synced_Raw_Data_Aggregate;
  sensorflow_ai_f_get_ac_command_event_stream: Array<Sensorflow_Ai_Dt_Ac_Event_Stream>;
  sensorflow_ai_f_get_ac_command_event_stream_aggregate: Sensorflow_Ai_Dt_Ac_Event_Stream_Aggregate;
  sensorflow_ai_f_get_all_event_stream: Array<Sensorflow_Ai_Dt_Ac_Event_Stream>;
  sensorflow_ai_f_get_all_event_stream_aggregate: Sensorflow_Ai_Dt_Ac_Event_Stream_Aggregate;
  sensorflow_ai_f_get_config_event_stream: Array<Sensorflow_Ai_Dt_Config_Event_Stream>;
  sensorflow_ai_f_get_config_event_stream_aggregate: Sensorflow_Ai_Dt_Config_Event_Stream_Aggregate;
  sensorflow_ai_f_get_event_stream: Array<Sensorflow_Ai_Dt_Event_Stream>;
  sensorflow_ai_f_get_event_stream_aggregate: Sensorflow_Ai_Dt_Event_Stream_Aggregate;
  sensorflow_ai_f_get_id_info: Array<Sensorflow_Autosets_With_Keys>;
  sensorflow_ai_f_get_id_info_aggregate: Sensorflow_Autosets_With_Keys_Aggregate;
  sensorflow_ai_f_get_synced_raw_data: Array<Sensorflow_Ai_Dt_Synced_Raw_Data>;
  sensorflow_ai_f_get_synced_raw_data_aggregate: Sensorflow_Ai_Dt_Synced_Raw_Data_Aggregate;
  sensorflow_ai_f_room_comfort_analysis: Array<Sensorflow_Ai_Dt_Room_Comfort_Report>;
  sensorflow_ai_f_room_comfort_analysis_aggregate: Sensorflow_Ai_Dt_Room_Comfort_Report_Aggregate;
  sensorflow_ai_v_get_precool_command_stream: Array<Sensorflow_Ai_V_Get_Precool_Command_Stream>;
  sensorflow_ai_v_get_precool_command_stream_aggregate: Sensorflow_Ai_V_Get_Precool_Command_Stream_Aggregate;
  sensorflow_ai_v_room_status: Array<Sensorflow_Ai_V_Room_Status>;
  sensorflow_ai_v_room_status_aggregate: Sensorflow_Ai_V_Room_Status_Aggregate;
  sensorflow_autosets_with_keys: Array<Sensorflow_Autosets_With_Keys>;
  sensorflow_autosets_with_keys_aggregate: Sensorflow_Autosets_With_Keys_Aggregate;
  sensorflow_avg_position_data: Array<Sensorflow_Avg_Position_Data>;
  sensorflow_avg_position_data_aggregate: Sensorflow_Avg_Position_Data_Aggregate;
  sensorflow_beacon_slots: Array<Sensorflow_Beacon_Slots>;
  sensorflow_beacon_slots_aggregate: Sensorflow_Beacon_Slots_Aggregate;
  sensorflow_beacon_slots_by_pk?: Maybe<Sensorflow_Beacon_Slots>;
  sensorflow_checklist_items: Array<Sensorflow_Checklist_Items>;
  sensorflow_checklist_items_aggregate: Sensorflow_Checklist_Items_Aggregate;
  sensorflow_checklists: Array<Sensorflow_Checklists>;
  sensorflow_checklists_aggregate: Sensorflow_Checklists_Aggregate;
  sensorflow_checklists_by_pk?: Maybe<Sensorflow_Checklists>;
  sensorflow_chillers: Array<Sensorflow_Chillers>;
  sensorflow_chillers_aggregate: Sensorflow_Chillers_Aggregate;
  sensorflow_chillers_by_pk?: Maybe<Sensorflow_Chillers>;
  sensorflow_clickup_webhook_events: Array<Sensorflow_Clickup_Webhook_Events>;
  sensorflow_clickup_webhook_events_aggregate: Sensorflow_Clickup_Webhook_Events_Aggregate;
  sensorflow_clickup_webhook_events_by_pk?: Maybe<Sensorflow_Clickup_Webhook_Events>;
  sensorflow_comfortplus_activations: Array<Sensorflow_Comfortplus_Activations>;
  sensorflow_comfortplus_activations_aggregate: Sensorflow_Comfortplus_Activations_Aggregate;
  sensorflow_comfortplus_activations_by_pk?: Maybe<Sensorflow_Comfortplus_Activations>;
  sensorflow_compressor_mapping: Array<Sensorflow_Compressor_Mapping>;
  sensorflow_compressor_mapping_aggregate: Sensorflow_Compressor_Mapping_Aggregate;
  sensorflow_compressor_mapping_by_pk?: Maybe<Sensorflow_Compressor_Mapping>;
  sensorflow_compressors: Array<Sensorflow_Compressors>;
  sensorflow_compressors_aggregate: Sensorflow_Compressors_Aggregate;
  sensorflow_compressors_by_pk?: Maybe<Sensorflow_Compressors>;
  sensorflow_config_event_stream: Array<Sensorflow_Config_Event_Stream>;
  sensorflow_config_event_stream_aggregate: Sensorflow_Config_Event_Stream_Aggregate;
  sensorflow_cooling_towers: Array<Sensorflow_Cooling_Towers>;
  sensorflow_cooling_towers_aggregate: Sensorflow_Cooling_Towers_Aggregate;
  sensorflow_cooling_towers_by_pk?: Maybe<Sensorflow_Cooling_Towers>;
  sensorflow_currencies: Array<Sensorflow_Currencies>;
  sensorflow_currencies_aggregate: Sensorflow_Currencies_Aggregate;
  sensorflow_currencies_by_pk?: Maybe<Sensorflow_Currencies>;
  sensorflow_domo_embeds_aggregate: Sensorflow_Domo_Embeds_Aggregate;
  sensorflow_entity_feature_flag_mappings: Array<Sensorflow_Entity_Feature_Flag_Mappings>;
  sensorflow_entity_feature_flag_mappings_aggregate: Sensorflow_Entity_Feature_Flag_Mappings_Aggregate;
  sensorflow_entity_feature_flag_mappings_by_pk?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings>;
  sensorflow_events_stream: Array<Sensorflow_Events_Stream>;
  sensorflow_events_stream_aggregate: Sensorflow_Events_Stream_Aggregate;
  sensorflow_f_get_ac_command_event_stream: Array<Sensorflow_Ac_Command_Event_Stream>;
  sensorflow_f_get_ac_command_event_stream_aggregate: Sensorflow_Ac_Command_Event_Stream_Aggregate;
  sensorflow_f_get_all_event_stream: Array<Sensorflow_Ac_Command_Event_Stream>;
  sensorflow_f_get_all_event_stream_aggregate: Sensorflow_Ac_Command_Event_Stream_Aggregate;
  sensorflow_f_get_avg_position_data: Array<Sensorflow_Avg_Position_Data>;
  sensorflow_f_get_avg_position_data_aggregate: Sensorflow_Avg_Position_Data_Aggregate;
  sensorflow_f_get_config_event_stream: Array<Sensorflow_Config_Event_Stream>;
  sensorflow_f_get_config_event_stream_aggregate: Sensorflow_Config_Event_Stream_Aggregate;
  sensorflow_f_get_event_stream: Array<Sensorflow_Get_Event_Stream_View>;
  sensorflow_f_get_event_stream_aggregate: Sensorflow_Get_Event_Stream_View_Aggregate;
  sensorflow_f_get_synced_raw_data: Array<Sensorflow_Synced_Raw_Data>;
  sensorflow_f_get_synced_raw_data_aggregate: Sensorflow_Synced_Raw_Data_Aggregate;
  sensorflow_feature_flags: Array<Sensorflow_Feature_Flags>;
  sensorflow_feature_flags_aggregate: Sensorflow_Feature_Flags_Aggregate;
  sensorflow_feature_flags_by_pk?: Maybe<Sensorflow_Feature_Flags>;
  sensorflow_firmware_releases_aggregate: Sensorflow_Firmware_Releases_Aggregate;
  sensorflow_firmware_releases_by_pk?: Maybe<Sensorflow_Firmware_Releases>;
  sensorflow_gateway_frequency: Array<Sensorflow_Gateway_Frequency>;
  sensorflow_gateway_frequency_aggregate: Sensorflow_Gateway_Frequency_Aggregate;
  sensorflow_gateway_frequency_by_pk?: Maybe<Sensorflow_Gateway_Frequency>;
  sensorflow_gateway_health_data: Array<Sensorflow_Gateway_Health_Data>;
  sensorflow_gateway_health_data_aggregate: Sensorflow_Gateway_Health_Data_Aggregate;
  sensorflow_gateway_to_position_mapping_aggregate: Sensorflow_Gateway_To_Position_Mapping_Aggregate;
  sensorflow_gateway_to_position_mapping_by_pk?: Maybe<Sensorflow_Gateway_To_Position_Mapping>;
  sensorflow_gateways_aggregate: Sensorflow_Gateways_Aggregate;
  sensorflow_gateways_by_pk?: Maybe<Sensorflow_Gateways>;
  sensorflow_get_event_stream_view: Array<Sensorflow_Get_Event_Stream_View>;
  sensorflow_get_event_stream_view_aggregate: Sensorflow_Get_Event_Stream_View_Aggregate;
  sensorflow_get_precool_command_streams: Array<Sensorflow_Get_Precool_Command_Streams>;
  sensorflow_get_precool_command_streams_aggregate: Sensorflow_Get_Precool_Command_Streams_Aggregate;
  sensorflow_key_categories_aggregate: Sensorflow_Key_Categories_Aggregate;
  sensorflow_key_categories_by_pk?: Maybe<Sensorflow_Key_Categories>;
  sensorflow_key_category_room_templates: Array<Sensorflow_Key_Category_Room_Templates>;
  sensorflow_key_category_room_templates_aggregate: Sensorflow_Key_Category_Room_Templates_Aggregate;
  sensorflow_key_category_room_templates_by_pk?: Maybe<Sensorflow_Key_Category_Room_Templates>;
  sensorflow_key_category_slot_templates: Array<Sensorflow_Key_Category_Slot_Templates>;
  sensorflow_key_category_slot_templates_aggregate: Sensorflow_Key_Category_Slot_Templates_Aggregate;
  sensorflow_key_category_slot_templates_by_pk?: Maybe<Sensorflow_Key_Category_Slot_Templates>;
  sensorflow_key_category_templates: Array<Sensorflow_Key_Category_Templates>;
  sensorflow_key_category_templates_aggregate: Sensorflow_Key_Category_Templates_Aggregate;
  sensorflow_key_category_templates_by_pk?: Maybe<Sensorflow_Key_Category_Templates>;
  sensorflow_key_category_to_key_position: Array<Sensorflow_Key_Category_To_Key_Position>;
  sensorflow_key_category_to_key_position_aggregate: Sensorflow_Key_Category_To_Key_Position_Aggregate;
  sensorflow_key_category_to_key_position_by_pk?: Maybe<Sensorflow_Key_Category_To_Key_Position>;
  sensorflow_location_addresses: Array<Sensorflow_Location_Addresses>;
  sensorflow_location_addresses_aggregate: Sensorflow_Location_Addresses_Aggregate;
  sensorflow_location_addresses_by_pk?: Maybe<Sensorflow_Location_Addresses>;
  sensorflow_location_consumptions: Array<Sensorflow_Location_Consumptions>;
  sensorflow_location_consumptions_aggregate: Sensorflow_Location_Consumptions_Aggregate;
  sensorflow_location_consumptions_by_pk?: Maybe<Sensorflow_Location_Consumptions>;
  sensorflow_location_domo_embeds_aggregate: Sensorflow_Location_Domo_Embeds_Aggregate;
  sensorflow_location_metadata: Array<Sensorflow_Location_Metadata>;
  sensorflow_location_metadata_aggregate: Sensorflow_Location_Metadata_Aggregate;
  sensorflow_location_occ_customer_data: Array<Sensorflow_Location_Occ_Customer_Data>;
  sensorflow_location_occ_customer_data_aggregate: Sensorflow_Location_Occ_Customer_Data_Aggregate;
  sensorflow_location_occ_customer_data_by_pk?: Maybe<Sensorflow_Location_Occ_Customer_Data>;
  sensorflow_location_occ_derived_data: Array<Sensorflow_Location_Occ_Derived_Data>;
  sensorflow_location_occ_derived_data_aggregate: Sensorflow_Location_Occ_Derived_Data_Aggregate;
  sensorflow_location_occ_derived_data_by_pk?: Maybe<Sensorflow_Location_Occ_Derived_Data>;
  sensorflow_location_to_user_mapping: Array<Sensorflow_Location_To_User_Mapping>;
  sensorflow_location_to_user_mapping_aggregate: Sensorflow_Location_To_User_Mapping_Aggregate;
  sensorflow_location_to_user_mapping_by_pk?: Maybe<Sensorflow_Location_To_User_Mapping>;
  sensorflow_locations_aggregate: Sensorflow_Locations_Aggregate;
  sensorflow_mapping_coordinates: Array<Sensorflow_Mapping_Coordinates>;
  sensorflow_mapping_coordinates_aggregate: Sensorflow_Mapping_Coordinates_Aggregate;
  sensorflow_metric_types: Array<Sensorflow_Metric_Types>;
  sensorflow_metric_types_aggregate: Sensorflow_Metric_Types_Aggregate;
  sensorflow_metric_types_by_pk?: Maybe<Sensorflow_Metric_Types>;
  sensorflow_node_firmware: Array<Sensorflow_Node_Firmware>;
  sensorflow_node_firmware_aggregate: Sensorflow_Node_Firmware_Aggregate;
  sensorflow_node_join_data: Array<Sensorflow_Node_Join_Data>;
  sensorflow_node_join_data_aggregate: Sensorflow_Node_Join_Data_Aggregate;
  sensorflow_node_join_status_aggregate: Sensorflow_Node_Join_Status_Aggregate;
  sensorflow_node_join_strength_live: Array<Sensorflow_Node_Join_Strength_Live>;
  sensorflow_node_join_strength_live_aggregate: Sensorflow_Node_Join_Strength_Live_Aggregate;
  sensorflow_node_life_cycle_event_types: Array<Sensorflow_Node_Life_Cycle_Event_Types>;
  sensorflow_node_life_cycle_event_types_aggregate: Sensorflow_Node_Life_Cycle_Event_Types_Aggregate;
  sensorflow_node_life_cycle_event_types_by_pk?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types>;
  sensorflow_node_life_cycle_events: Array<Sensorflow_Node_Life_Cycle_Events>;
  sensorflow_node_life_cycle_events_aggregate: Sensorflow_Node_Life_Cycle_Events_Aggregate;
  sensorflow_node_life_cycle_events_by_pk?: Maybe<Sensorflow_Node_Life_Cycle_Events>;
  sensorflow_node_measurement_view: Array<Sensorflow_Node_Measurement_View>;
  sensorflow_node_measurement_view_aggregate: Sensorflow_Node_Measurement_View_Aggregate;
  sensorflow_node_measurements: Array<Sensorflow_Node_Measurements>;
  sensorflow_node_measurements_aggregate: Sensorflow_Node_Measurements_Aggregate;
  sensorflow_node_meta_data: Array<Sensorflow_Node_Meta_Data>;
  sensorflow_node_meta_data_aggregate: Sensorflow_Node_Meta_Data_Aggregate;
  sensorflow_node_meta_data_by_pk?: Maybe<Sensorflow_Node_Meta_Data>;
  sensorflow_node_meta_status: Array<Sensorflow_Node_Meta_Status>;
  sensorflow_node_meta_status_aggregate: Sensorflow_Node_Meta_Status_Aggregate;
  sensorflow_node_online_status_aggregate: Sensorflow_Node_Online_Status_Aggregate;
  sensorflow_node_slots: Array<Sensorflow_Node_Slots>;
  sensorflow_node_slots_aggregate: Sensorflow_Node_Slots_Aggregate;
  sensorflow_node_slots_by_pk?: Maybe<Sensorflow_Node_Slots>;
  sensorflow_node_spare_set: Array<Sensorflow_Node_Spare_Set>;
  sensorflow_node_spare_set_aggregate: Sensorflow_Node_Spare_Set_Aggregate;
  sensorflow_node_spare_set_by_pk?: Maybe<Sensorflow_Node_Spare_Set>;
  sensorflow_node_status_history: Array<Sensorflow_Node_Status_History>;
  sensorflow_node_status_history_aggregate: Sensorflow_Node_Status_History_Aggregate;
  sensorflow_node_status_history_by_pk?: Maybe<Sensorflow_Node_Status_History>;
  sensorflow_node_statuses: Array<Sensorflow_Node_Statuses>;
  sensorflow_node_statuses_aggregate: Sensorflow_Node_Statuses_Aggregate;
  sensorflow_node_statuses_by_pk?: Maybe<Sensorflow_Node_Statuses>;
  sensorflow_node_system_states: Array<Sensorflow_Node_System_States>;
  sensorflow_node_system_states_aggregate: Sensorflow_Node_System_States_Aggregate;
  sensorflow_node_system_states_by_pk?: Maybe<Sensorflow_Node_System_States>;
  sensorflow_node_to_position_mapping: Array<Sensorflow_Node_To_Position_Mapping>;
  sensorflow_node_to_position_mapping_aggregate: Sensorflow_Node_To_Position_Mapping_Aggregate;
  sensorflow_node_to_slot_mapping: Array<Sensorflow_Node_To_Slot_Mapping>;
  sensorflow_node_to_slot_mapping_aggregate: Sensorflow_Node_To_Slot_Mapping_Aggregate;
  sensorflow_node_to_slot_mapping_by_pk?: Maybe<Sensorflow_Node_To_Slot_Mapping>;
  sensorflow_nodes: Array<Sensorflow_Nodes>;
  sensorflow_nodes_aggregate: Sensorflow_Nodes_Aggregate;
  sensorflow_nodes_by_pk?: Maybe<Sensorflow_Nodes>;
  sensorflow_organisations: Array<Sensorflow_Organisations>;
  sensorflow_organisations_aggregate: Sensorflow_Organisations_Aggregate;
  sensorflow_organisations_by_pk?: Maybe<Sensorflow_Organisations>;
  sensorflow_ota_queues: Array<Sensorflow_Ota_Queues>;
  sensorflow_ota_queues_aggregate: Sensorflow_Ota_Queues_Aggregate;
  sensorflow_ota_queues_by_pk?: Maybe<Sensorflow_Ota_Queues>;
  sensorflow_ota_runs: Array<Sensorflow_Ota_Runs>;
  sensorflow_ota_runs_aggregate: Sensorflow_Ota_Runs_Aggregate;
  sensorflow_ota_runs_by_pk?: Maybe<Sensorflow_Ota_Runs>;
  sensorflow_outdoor_temperatures: Array<Sensorflow_Outdoor_Temperatures>;
  sensorflow_outdoor_temperatures_aggregate: Sensorflow_Outdoor_Temperatures_Aggregate;
  sensorflow_outdoor_temperatures_by_pk?: Maybe<Sensorflow_Outdoor_Temperatures>;
  sensorflow_pending_ac_settings_commands_for_gateway: Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway>;
  sensorflow_pending_ac_settings_commands_for_gateway_aggregate: Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Aggregate;
  sensorflow_pipes: Array<Sensorflow_Pipes>;
  sensorflow_pipes_aggregate: Sensorflow_Pipes_Aggregate;
  sensorflow_pipes_by_pk?: Maybe<Sensorflow_Pipes>;
  sensorflow_position_precool_status_aggregate: Sensorflow_Position_Precool_Status_Aggregate;
  sensorflow_position_signal_stats: Array<Sensorflow_Position_Signal_Stats>;
  sensorflow_position_signal_stats_aggregate: Sensorflow_Position_Signal_Stats_Aggregate;
  sensorflow_position_signal_stats_by_pk?: Maybe<Sensorflow_Position_Signal_Stats>;
  sensorflow_precool_commands: Array<Sensorflow_Precool_Commands>;
  sensorflow_precool_commands_aggregate: Sensorflow_Precool_Commands_Aggregate;
  sensorflow_precool_commands_by_pk?: Maybe<Sensorflow_Precool_Commands>;
  sensorflow_precool_schedules: Array<Sensorflow_Precool_Schedules>;
  sensorflow_precool_schedules_aggregate: Sensorflow_Precool_Schedules_Aggregate;
  sensorflow_precool_schedules_by_pk?: Maybe<Sensorflow_Precool_Schedules>;
  sensorflow_properties_information: Array<Sensorflow_Properties_Information>;
  sensorflow_properties_information_aggregate: Sensorflow_Properties_Information_Aggregate;
  sensorflow_properties_information_by_pk?: Maybe<Sensorflow_Properties_Information>;
  sensorflow_pumps: Array<Sensorflow_Pumps>;
  sensorflow_pumps_aggregate: Sensorflow_Pumps_Aggregate;
  sensorflow_pumps_by_pk?: Maybe<Sensorflow_Pumps>;
  sensorflow_reservation_guests: Array<Sensorflow_Reservation_Guests>;
  sensorflow_reservation_guests_aggregate: Sensorflow_Reservation_Guests_Aggregate;
  sensorflow_reservation_guests_by_pk?: Maybe<Sensorflow_Reservation_Guests>;
  sensorflow_reservations: Array<Sensorflow_Reservations>;
  sensorflow_reservations_aggregate: Sensorflow_Reservations_Aggregate;
  sensorflow_reservations_by_pk?: Maybe<Sensorflow_Reservations>;
  sensorflow_roles: Array<Sensorflow_Roles>;
  sensorflow_roles_aggregate: Sensorflow_Roles_Aggregate;
  sensorflow_roles_by_pk?: Maybe<Sensorflow_Roles>;
  sensorflow_smart_rem_validation_history_aggregate: Sensorflow_Smart_Rem_Validation_History_Aggregate;
  sensorflow_smart_rem_validation_history_by_pk?: Maybe<Sensorflow_Smart_Rem_Validation_History>;
  sensorflow_smplrspace_floors_to_levels: Array<Sensorflow_Smplrspace_Floors_To_Levels>;
  sensorflow_smplrspace_floors_to_levels_aggregate: Sensorflow_Smplrspace_Floors_To_Levels_Aggregate;
  sensorflow_smplrspace_floors_to_levels_by_pk?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels>;
  sensorflow_smplrspace_levels_group: Array<Sensorflow_Smplrspace_Levels_Group>;
  sensorflow_smplrspace_levels_group_aggregate: Sensorflow_Smplrspace_Levels_Group_Aggregate;
  sensorflow_smplrspace_levels_group_by_pk?: Maybe<Sensorflow_Smplrspace_Levels_Group>;
  sensorflow_survey_responses: Array<Sensorflow_Survey_Responses>;
  sensorflow_survey_responses_aggregate: Sensorflow_Survey_Responses_Aggregate;
  sensorflow_survey_responses_by_pk?: Maybe<Sensorflow_Survey_Responses>;
  sensorflow_synced_raw_data: Array<Sensorflow_Synced_Raw_Data>;
  sensorflow_synced_raw_data_aggregate: Sensorflow_Synced_Raw_Data_Aggregate;
  sensorflow_task_status_history: Array<Sensorflow_Task_Status_History>;
  sensorflow_task_status_history_aggregate: Sensorflow_Task_Status_History_Aggregate;
  sensorflow_task_status_history_by_pk?: Maybe<Sensorflow_Task_Status_History>;
  sensorflow_tasks: Array<Sensorflow_Tasks>;
  sensorflow_tasks_aggregate: Sensorflow_Tasks_Aggregate;
  sensorflow_tasks_by_pk?: Maybe<Sensorflow_Tasks>;
  sensorflow_tests: Array<Sensorflow_Tests>;
  sensorflow_tests_aggregate: Sensorflow_Tests_Aggregate;
  sensorflow_tests_by_pk?: Maybe<Sensorflow_Tests>;
  sensorflow_timezones: Array<Sensorflow_Timezones>;
  sensorflow_timezones_aggregate: Sensorflow_Timezones_Aggregate;
  sensorflow_timezones_by_pk?: Maybe<Sensorflow_Timezones>;
  sensorflow_updatable_nodes: Array<Sensorflow_Updatable_Nodes>;
  sensorflow_updatable_nodes_aggregate: Sensorflow_Updatable_Nodes_Aggregate;
  sensorflow_user_to_permission_mapping: Array<Sensorflow_User_To_Permission_Mapping>;
  sensorflow_user_to_permission_mapping_aggregate: Sensorflow_User_To_Permission_Mapping_Aggregate;
  sensorflow_users: Array<Sensorflow_Users>;
  sensorflow_users_aggregate: Sensorflow_Users_Aggregate;
  sensorflow_users_by_pk?: Maybe<Sensorflow_Users>;
  sensorflow_v_all_latest_node_configurations: Array<Sensorflow_V_All_Latest_Node_Configurations>;
  sensorflow_v_all_latest_node_configurations_aggregate: Sensorflow_V_All_Latest_Node_Configurations_Aggregate;
  sensorflow_v_combined_user_roles: Array<Sensorflow_V_Combined_User_Roles>;
  sensorflow_v_combined_user_roles_aggregate: Sensorflow_V_Combined_User_Roles_Aggregate;
  sensorflow_v_comfortplus_key_details: Array<Sensorflow_V_Comfortplus_Key_Details>;
  sensorflow_v_comfortplus_key_details_aggregate: Sensorflow_V_Comfortplus_Key_Details_Aggregate;
  sensorflow_v_comfortplus_key_measurements: Array<Sensorflow_V_Comfortplus_Key_Measurements>;
  sensorflow_v_comfortplus_key_measurements_aggregate: Sensorflow_V_Comfortplus_Key_Measurements_Aggregate;
  sensorflow_v_comfortplus_precool_status: Array<Sensorflow_V_Comfortplus_Precool_Status>;
  sensorflow_v_comfortplus_precool_status_aggregate: Sensorflow_V_Comfortplus_Precool_Status_Aggregate;
  sensorflow_v_current_position_configurations_aggregate: Sensorflow_V_Current_Position_Configurations_Aggregate;
  sensorflow_v_group_tasks: Array<Sensorflow_V_Group_Tasks>;
  sensorflow_v_group_tasks_aggregate: Sensorflow_V_Group_Tasks_Aggregate;
  sensorflow_v_group_with_stats: Array<Sensorflow_V_Group_With_Stats>;
  sensorflow_v_group_with_stats_aggregate: Sensorflow_V_Group_With_Stats_Aggregate;
  sensorflow_v_gw_autoset_min_signal: Array<Sensorflow_V_Gw_Autoset_Min_Signal>;
  sensorflow_v_gw_autoset_min_signal_aggregate: Sensorflow_V_Gw_Autoset_Min_Signal_Aggregate;
  sensorflow_v_infrastructure_emon_power_status_aggregate: Sensorflow_V_Infrastructure_Emon_Power_Status_Aggregate;
  sensorflow_v_infrastructures_aggregate: Sensorflow_V_Infrastructures_Aggregate;
  sensorflow_v_key_emon_power_reading: Array<Sensorflow_V_Key_Emon_Power_Reading>;
  sensorflow_v_key_emon_power_reading_aggregate: Sensorflow_V_Key_Emon_Power_Reading_Aggregate;
  sensorflow_v_key_position_latest_entry: Array<Sensorflow_V_Key_Position_Latest_Entry>;
  sensorflow_v_key_position_latest_entry_aggregate: Sensorflow_V_Key_Position_Latest_Entry_Aggregate;
  sensorflow_v_key_task_min_duedate: Array<Sensorflow_V_Key_Task_Min_Duedate>;
  sensorflow_v_key_task_min_duedate_aggregate: Sensorflow_V_Key_Task_Min_Duedate_Aggregate;
  sensorflow_v_location_last_update: Array<Sensorflow_V_Location_Last_Update>;
  sensorflow_v_location_last_update_aggregate: Sensorflow_V_Location_Last_Update_Aggregate;
  sensorflow_v_node_life_cycle_events: Array<Sensorflow_V_Node_Life_Cycle_Events>;
  sensorflow_v_node_life_cycle_events_aggregate: Sensorflow_V_Node_Life_Cycle_Events_Aggregate;
  sensorflow_v_task_with_key_status_history: Array<Sensorflow_V_Task_With_Key_Status_History>;
  sensorflow_v_task_with_key_status_history_aggregate: Sensorflow_V_Task_With_Key_Status_History_Aggregate;
  sensorflow_v_tasks_with_keys_aggregate: Sensorflow_V_Tasks_With_Keys_Aggregate;
  sensorflow_warehouse_kitting_process_metadata_aggregate: Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate;
  sensorflow_warehouse_kitting_process_metadata_by_pk?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata>;
  sensorflow_warehouse_mapping_status_of_slots: Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots>;
  sensorflow_warehouse_mapping_status_of_slots_aggregate: Sensorflow_Warehouse_Mapping_Status_Of_Slots_Aggregate;
  sf_firmware_dist_pipeline_firmware_build: Array<Sf_Firmware_Dist_Pipeline_Firmware_Build>;
  sf_firmware_dist_pipeline_firmware_build_aggregate: Sf_Firmware_Dist_Pipeline_Firmware_Build_Aggregate;
  sf_firmware_dist_pipeline_firmware_build_by_pk?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build>;
  sf_firmware_dist_pipeline_firmware_numbers: Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers>;
  sf_firmware_dist_pipeline_firmware_numbers_aggregate: Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Aggregate;
  sf_firmware_dist_pipeline_firmware_numbers_by_pk?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers>;
  sf_pms_pms_pmslist: Array<Sf_Pms_Pms_Pmslist>;
  sf_pms_pms_pmslist_aggregate: Sf_Pms_Pms_Pmslist_Aggregate;
  sf_pms_pms_pmslist_by_pk?: Maybe<Sf_Pms_Pms_Pmslist>;
  sf_pms_pms_properties: Array<Sf_Pms_Pms_Properties>;
  sf_pms_pms_properties_aggregate: Sf_Pms_Pms_Properties_Aggregate;
  sf_pms_pms_properties_by_pk?: Maybe<Sf_Pms_Pms_Properties>;
  sf_pms_pms_property_eod_surveys: Array<Sf_Pms_Pms_Property_Eod_Surveys>;
  sf_pms_pms_property_eod_surveys_aggregate: Sf_Pms_Pms_Property_Eod_Surveys_Aggregate;
  sf_pms_pms_property_eod_surveys_by_pk?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys>;
  sf_pms_pms_reservations: Array<Sf_Pms_Pms_Reservations>;
  sf_pms_pms_reservations_aggregate: Sf_Pms_Pms_Reservations_Aggregate;
  sf_pms_pms_room_categories: Array<Sf_Pms_Pms_Room_Categories>;
  sf_pms_pms_room_categories_aggregate: Sf_Pms_Pms_Room_Categories_Aggregate;
  sf_pms_pms_rooms: Array<Sf_Pms_Pms_Rooms>;
  sf_pms_pms_rooms_aggregate: Sf_Pms_Pms_Rooms_Aggregate;
  sf_pms_smartalloc_report: Array<Sf_Pms_Smartalloc_Report>;
  sf_pms_smartalloc_report_aggregate: Sf_Pms_Smartalloc_Report_Aggregate;
  sf_pms_smartalloc_report_by_pk?: Maybe<Sf_Pms_Smartalloc_Report>;
  sf_simulation_instrumentation_commands: Array<Sf_Simulation_Instrumentation_Commands>;
  sf_simulation_instrumentation_commands_aggregate: Sf_Simulation_Instrumentation_Commands_Aggregate;
  sf_simulation_instrumentation_commands_by_pk?: Maybe<Sf_Simulation_Instrumentation_Commands>;
  sf_support_actions: Array<Sf_Support_Actions>;
  sf_support_actions_aggregate: Sf_Support_Actions_Aggregate;
  sf_support_actions_by_pk?: Maybe<Sf_Support_Actions>;
  sf_support_active_property_alerts: Array<Sf_Support_Active_Property_Alerts>;
  sf_support_active_property_alerts_aggregate: Sf_Support_Active_Property_Alerts_Aggregate;
  sf_support_active_property_alerts_by_pk?: Maybe<Sf_Support_Active_Property_Alerts>;
  sf_support_analytics_ticket_jobs_running_histories: Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories>;
  sf_support_analytics_ticket_jobs_running_histories_aggregate: Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Aggregate;
  sf_support_analytics_ticket_jobs_running_histories_by_pk?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories>;
  sf_support_root_causes: Array<Sf_Support_Root_Causes>;
  sf_support_root_causes_aggregate: Sf_Support_Root_Causes_Aggregate;
  sf_support_root_causes_by_pk?: Maybe<Sf_Support_Root_Causes>;
  sf_support_ticket_classes_aggregate: Sf_Support_Ticket_Classes_Aggregate;
  sf_support_ticket_classes_by_pk?: Maybe<Sf_Support_Ticket_Classes>;
  sf_support_ticket_status: Array<Sf_Support_Ticket_Status>;
  sf_support_ticket_status_aggregate: Sf_Support_Ticket_Status_Aggregate;
  sf_support_ticket_status_by_pk?: Maybe<Sf_Support_Ticket_Status>;
  sf_support_tickets: Array<Sf_Support_Tickets>;
  sf_support_tickets_aggregate: Sf_Support_Tickets_Aggregate;
  sf_support_tickets_by_pk?: Maybe<Sf_Support_Tickets>;
  sf_testjig_test_jig_aggregate: Sf_Testjig_Test_Jig_Aggregate;
  sf_testjig_test_jig_maintenance_report_aggregate: Sf_Testjig_Test_Jig_Maintenance_Report_Aggregate;
  sf_testjig_test_jig_report_metadata_aggregate: Sf_Testjig_Test_Jig_Report_Metadata_Aggregate;
  sf_testjig_test_jig_report_metadata_by_pk?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata>;
  sf_testjig_test_jig_report_metadatas_aggregate: Sf_Testjig_Test_Jig_Report_Metadatas_Aggregate;
  sf_testjig_test_jig_report_metadatas_by_pk?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas>;
  sf_testjig_test_jig_test_report_aggregate: Sf_Testjig_Test_Jig_Test_Report_Aggregate;
  sf_testjig_test_jig_type_aggregate: Sf_Testjig_Test_Jig_Type_Aggregate;
  sf_testjig_v_latest_report_rma_nodes: Array<Sf_Testjig_V_Latest_Report_Rma_Nodes>;
  sf_testjig_v_latest_report_rma_nodes_aggregate: Sf_Testjig_V_Latest_Report_Rma_Nodes_Aggregate;
  tasksWithKeys: Array<Sensorflow_V_Tasks_With_Keys>;
  testRuns: Array<TestRuns>;
  testRuns_aggregate: TestRuns_Aggregate;
  testRuns_by_pk?: Maybe<TestRuns>;
  ticketClasses: Array<Sf_Support_Ticket_Classes>;
  user: User;
  userWithAuthToken?: Maybe<User>;
  users: UsersResponse;
  validateClickupListId: Scalars["Boolean"];
  validateInfraNodeMapping: Scalars["Boolean"];
  validateNodeBeforeMappingToSlot: Scalars["Boolean"];
};

export type Query_RootAcModelArgs = {
  ACModelId?: Maybe<Scalars["String"]>;
};

export type Query_RootAcModelsArgs = {
  filter?: Maybe<AcModelFilterInput>;
  pagination?: Maybe<GenericPaginationInput>;
  sort?: Maybe<AcModelSortInput>;
};

export type Query_RootTestJigArgs = {
  testJigId: Scalars["String"];
};

export type Query_RootTestJigMaintenanceReportArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTestJigMaintenanceReportsArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp>;
};

export type Query_RootTestJigReportMetadataArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp>;
};

export type Query_RootTestJigReportMetadatasArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp>;
};

export type Query_RootTestJigTestReportArgs = {
  id: Scalars["uuid"];
};

export type Query_RootTestJigTestReportsArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Bool_Exp>;
};

export type Query_RootTestJigTypeArgs = {
  id: Scalars["Int"];
};

export type Query_RootTestJigTypesArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Type_Bool_Exp>;
};

export type Query_RootTestJigsArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Bool_Exp>;
};

export type Query_RootCommonRoomNamesArgs = {
  keyIds: Array<Scalars["ID"]>;
};

export type Query_RootCurrentPositionConfigurationArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Order_By>>;
  where?: Maybe<Sensorflow_V_Current_Position_Configurations_Bool_Exp>;
};

export type Query_RootDomoEmbedArgs = {
  embed_id: Scalars["String"];
};

export type Query_RootDomoEmbedsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Domo_Embeds_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Domo_Embeds_Order_By>>;
  where?: Maybe<Sensorflow_Domo_Embeds_Bool_Exp>;
};

export type Query_RootEmbeddedAnalyticsInfoArgs = {
  embedId: Scalars["ID"];
  input: EmbeddedAnalyticsInfoInput;
};

export type Query_RootEmonMapInputInfoArgs = {
  infraId: Scalars["ID"];
  nodeMacId: Scalars["String"];
};

export type Query_RootFirmwareReleasesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Firmware_Releases_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Firmware_Releases_Order_By>>;
  where?: Maybe<Sensorflow_Firmware_Releases_Bool_Exp>;
};

export type Query_RootFirmwareUploadLinkArgs = {
  fileName: Scalars["String"];
};

export type Query_RootGatewayImageDownloadUrlArgs = {
  gatewayId: Scalars["String"];
};

export type Query_RootGatewayKittingProcessMetadataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Order_By>>;
  where?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
};

export type Query_RootGatewayToPositionMappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
};

export type Query_RootGatewaysArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateways_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateways_Order_By>>;
  where?: Maybe<Sensorflow_Gateways_Bool_Exp>;
};

export type Query_RootGenerateRmaAssetUploadLinkArgs = {
  extension: Scalars["String"];
  type: Scalars["String"];
};

export type Query_RootGetHolisticsTokenArgs = {
  embedId: Scalars["ID"];
  filters?: Maybe<Scalars["String"]>;
  locationId: Scalars["ID"];
};

export type Query_RootGetNodeMappedPreviouslyArgs = {
  infraId: Scalars["ID"];
};

export type Query_RootGetRmaAssetDownloadLinkArgs = {
  jobId: Scalars["String"];
  type: Scalars["String"];
};

export type Query_RootGetSmartRemValidationHistoryArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Order_By>>;
  where?: Maybe<Sensorflow_Smart_Rem_Validation_History_Bool_Exp>;
};

export type Query_RootInfrastructureEmonPowerStatusArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Order_By>>;
  where?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Bool_Exp>;
};

export type Query_RootInfrastructuresArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Infrastructures_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Infrastructures_Order_By>>;
  where?: Maybe<Sensorflow_V_Infrastructures_Bool_Exp>;
};

export type Query_RootKeyArgs = {
  filter?: Maybe<KeyDetailsFilterInput>;
  keyId: Scalars["String"];
};

export type Query_RootKeyCategoriesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Categories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Categories_Order_By>>;
  where?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
};

export type Query_RootKeyEntriesArgs = {
  distinct_on?: Maybe<Array<KeyEntries_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<KeyEntries_Order_By>>;
  where?: Maybe<KeyEntries_Bool_Exp>;
};

export type Query_RootKeyEntries_AggregateArgs = {
  distinct_on?: Maybe<Array<KeyEntries_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<KeyEntries_Order_By>>;
  where?: Maybe<KeyEntries_Bool_Exp>;
};

export type Query_RootKeyEntries_By_PkArgs = {
  keyEntryId: Scalars["Int"];
};

export type Query_RootKeysArgs = {
  filter: KeyFilterInput;
  pagination?: Maybe<GenericPaginationInput>;
  sort?: Maybe<KeySortInput>;
};

export type Query_RootKeysInLocationArgs = {
  locationId: Scalars["String"];
  pagination?: Maybe<GenericPaginationInput>;
  sort?: Maybe<KeySortInput>;
};

export type Query_RootKeysTasksArgs = {
  filter: KeyTaskFilterInput;
  pagination?: Maybe<GenericPaginationInput>;
};

export type Query_RootLocationArgs = {
  locationId: Scalars["String"];
};

export type Query_RootLocationDomoEmbedArgs = {
  embed_id: Scalars["String"];
  location_id: Scalars["uuid"];
};

export type Query_RootLocationDomoEmbedsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Order_By>>;
  where?: Maybe<Sensorflow_Location_Domo_Embeds_Bool_Exp>;
};

export type Query_RootLocationEmbeddedAnalyticsInfoArgs = {
  input: LocationEmbeddedAnalyticsInput;
  locationId: Scalars["ID"];
};

export type Query_RootLocationListArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Locations_Order_By>>;
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
};

export type Query_RootLocationMetadataArgs = {
  locationId: Scalars["uuid"];
};

export type Query_RootLocationOneArgs = {
  locationId: Scalars["uuid"];
};

export type Query_RootLocationsArgs = {
  filter?: Maybe<LocationFilterInput>;
  pagination?: Maybe<GenericPaginationInput>;
  sort?: Maybe<LocationSortInput>;
};

export type Query_RootLocationsAssignedToUserArgs = {
  filter?: Maybe<LocationFilterInput>;
  pagination?: Maybe<GenericPaginationInput>;
  sort?: Maybe<LocationSortInput>;
};

export type Query_RootNodeExceptionArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Exception_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Exception_Order_By>>;
  where?: Maybe<Sensorflow_Node_Exception_Bool_Exp>;
};

export type Query_RootNodeExceptionAggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Exception_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Exception_Order_By>>;
  where?: Maybe<Sensorflow_Node_Exception_Bool_Exp>;
};

export type Query_RootNodeExceptionByPkArgs = {
  id: Scalars["Int"];
};

export type Query_RootNodeExceptionsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Exceptions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Exceptions_Order_By>>;
  where?: Maybe<Sensorflow_Node_Exceptions_Bool_Exp>;
};

export type Query_RootNodeExceptionsAggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Exceptions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Exceptions_Order_By>>;
  where?: Maybe<Sensorflow_Node_Exceptions_Bool_Exp>;
};

export type Query_RootNodeExceptionsByPkArgs = {
  id: Scalars["Int"];
};

export type Query_RootNodeJoinStatusesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
};

export type Query_RootNodeOnlineStatusesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Online_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Online_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Online_Status_Bool_Exp>;
};

export type Query_RootOrganisationArgs = {
  organisationId?: Maybe<Scalars["String"]>;
};

export type Query_RootOrganisationsArgs = {
  filter?: Maybe<OrganisationFilterInput>;
  pagination?: Maybe<GenericPaginationInput>;
  sort?: Maybe<OrganisationSortInput>;
};

export type Query_RootPositionArgs = {
  positionId: Scalars["uuid"];
};

export type Query_RootPositionConfigurationsArgs = {
  distinct_on?: Maybe<Array<PositionConfigurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurations_Order_By>>;
  where?: Maybe<PositionConfigurations_Bool_Exp>;
};

export type Query_RootPositionConfigurationsForActivityLogArgs = {
  distinct_on?: Maybe<Array<PositionConfigurationsForActivityLog_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurationsForActivityLog_Order_By>>;
  where?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
};

export type Query_RootPositionConfigurationsForActivityLog_AggregateArgs = {
  distinct_on?: Maybe<Array<PositionConfigurationsForActivityLog_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurationsForActivityLog_Order_By>>;
  where?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
};

export type Query_RootPositionConfigurations_AggregateArgs = {
  distinct_on?: Maybe<Array<PositionConfigurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurations_Order_By>>;
  where?: Maybe<PositionConfigurations_Bool_Exp>;
};

export type Query_RootPositionConfigurations_By_PkArgs = {
  creationDate: Scalars["timestamptz"];
  id: Scalars["uuid"];
};

export type Query_RootPositionPrecoolStatusArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Position_Precool_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Position_Precool_Status_Order_By>>;
  where?: Maybe<Sensorflow_Position_Precool_Status_Bool_Exp>;
};

export type Query_RootPositionsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Positions_Order_By>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Query_RootPositionsAggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Positions_Order_By>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Query_RootRapidMappingArgs = {
  locationId: Scalars["String"];
};

export type Query_RootRoomArgs = {
  filter?: Maybe<RoomDetailsFilterInput>;
  roomId: Scalars["String"];
};

export type Query_RootSensorflow_Ac_Command_Event_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ac_Command_Event_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ac_ModelsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Models_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Models_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Models_Bool_Exp>;
};

export type Query_RootSensorflow_Ac_Models_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Models_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Models_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Models_Bool_Exp>;
};

export type Query_RootSensorflow_Ac_Models_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Ac_Setting_CommandsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
};

export type Query_RootSensorflow_Ac_Setting_Commands_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
};

export type Query_RootSensorflow_Ac_Setting_Commands_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Ai_Dt_Ac_Event_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_Dt_Ac_Event_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_Dt_Config_Event_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_Dt_Config_Event_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_Dt_Event_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_Dt_Event_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_Dt_Room_Comfort_ReportArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_Dt_Room_Comfort_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_Dt_Synced_Raw_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_Dt_Synced_Raw_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_Ac_Command_Event_StreamArgs = {
  args: Sensorflow_Ai_F_Get_Ac_Command_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_Ac_Command_Event_Stream_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_Ac_Command_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_All_Event_StreamArgs = {
  args: Sensorflow_Ai_F_Get_All_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_All_Event_Stream_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_All_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_Config_Event_StreamArgs = {
  args: Sensorflow_Ai_F_Get_Config_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_Config_Event_Stream_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_Config_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_Event_StreamArgs = {
  args: Sensorflow_Ai_F_Get_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_Event_Stream_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_Id_InfoArgs = {
  args: Sensorflow_Ai_F_Get_Id_Info_Args;
  distinct_on?: Maybe<Array<Sensorflow_Autosets_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Autosets_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_Autosets_With_Keys_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_Id_Info_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_Id_Info_Args;
  distinct_on?: Maybe<Array<Sensorflow_Autosets_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Autosets_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_Autosets_With_Keys_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_Synced_Raw_DataArgs = {
  args: Sensorflow_Ai_F_Get_Synced_Raw_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Get_Synced_Raw_Data_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_Synced_Raw_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Room_Comfort_AnalysisArgs = {
  args: Sensorflow_Ai_F_Room_Comfort_Analysis_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_F_Room_Comfort_Analysis_AggregateArgs = {
  args: Sensorflow_Ai_F_Room_Comfort_Analysis_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_V_Get_Precool_Command_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_V_Get_Precool_Command_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_V_Get_Precool_Command_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_V_Get_Precool_Command_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_V_Room_StatusArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Order_By>>;
  where?: Maybe<Sensorflow_Ai_V_Room_Status_Bool_Exp>;
};

export type Query_RootSensorflow_Ai_V_Room_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Order_By>>;
  where?: Maybe<Sensorflow_Ai_V_Room_Status_Bool_Exp>;
};

export type Query_RootSensorflow_Autosets_With_KeysArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Autosets_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Autosets_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_Autosets_With_Keys_Bool_Exp>;
};

export type Query_RootSensorflow_Autosets_With_Keys_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Autosets_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Autosets_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_Autosets_With_Keys_Bool_Exp>;
};

export type Query_RootSensorflow_Avg_Position_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Avg_Position_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Avg_Position_Data_Order_By>>;
  where?: Maybe<Sensorflow_Avg_Position_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Avg_Position_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Avg_Position_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Avg_Position_Data_Order_By>>;
  where?: Maybe<Sensorflow_Avg_Position_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Beacon_SlotsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Beacon_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Beacon_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Beacon_Slots_Bool_Exp>;
};

export type Query_RootSensorflow_Beacon_Slots_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Beacon_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Beacon_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Beacon_Slots_Bool_Exp>;
};

export type Query_RootSensorflow_Beacon_Slots_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Checklist_ItemsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Checklist_Items_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Checklist_Items_Order_By>>;
  where?: Maybe<Sensorflow_Checklist_Items_Bool_Exp>;
};

export type Query_RootSensorflow_Checklist_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Checklist_Items_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Checklist_Items_Order_By>>;
  where?: Maybe<Sensorflow_Checklist_Items_Bool_Exp>;
};

export type Query_RootSensorflow_ChecklistsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Checklists_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Checklists_Order_By>>;
  where?: Maybe<Sensorflow_Checklists_Bool_Exp>;
};

export type Query_RootSensorflow_Checklists_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Checklists_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Checklists_Order_By>>;
  where?: Maybe<Sensorflow_Checklists_Bool_Exp>;
};

export type Query_RootSensorflow_Checklists_By_PkArgs = {
  id: Scalars["String"];
};

export type Query_RootSensorflow_ChillersArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Chillers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Chillers_Order_By>>;
  where?: Maybe<Sensorflow_Chillers_Bool_Exp>;
};

export type Query_RootSensorflow_Chillers_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Chillers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Chillers_Order_By>>;
  where?: Maybe<Sensorflow_Chillers_Bool_Exp>;
};

export type Query_RootSensorflow_Chillers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Clickup_Webhook_EventsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Order_By>>;
  where?: Maybe<Sensorflow_Clickup_Webhook_Events_Bool_Exp>;
};

export type Query_RootSensorflow_Clickup_Webhook_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Order_By>>;
  where?: Maybe<Sensorflow_Clickup_Webhook_Events_Bool_Exp>;
};

export type Query_RootSensorflow_Clickup_Webhook_Events_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Comfortplus_ActivationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Comfortplus_Activations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Comfortplus_Activations_Order_By>>;
  where?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
};

export type Query_RootSensorflow_Comfortplus_Activations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Comfortplus_Activations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Comfortplus_Activations_Order_By>>;
  where?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
};

export type Query_RootSensorflow_Comfortplus_Activations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Compressor_MappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressor_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressor_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_Compressor_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressor_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressor_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_Compressor_Mapping_By_PkArgs = {
  compressorId: Scalars["uuid"];
  positionId: Scalars["uuid"];
};

export type Query_RootSensorflow_CompressorsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressors_Order_By>>;
  where?: Maybe<Sensorflow_Compressors_Bool_Exp>;
};

export type Query_RootSensorflow_Compressors_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressors_Order_By>>;
  where?: Maybe<Sensorflow_Compressors_Bool_Exp>;
};

export type Query_RootSensorflow_Compressors_By_PkArgs = {
  compressorId: Scalars["uuid"];
};

export type Query_RootSensorflow_Config_Event_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Config_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Config_Event_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Config_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Cooling_TowersArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Cooling_Towers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Cooling_Towers_Order_By>>;
  where?: Maybe<Sensorflow_Cooling_Towers_Bool_Exp>;
};

export type Query_RootSensorflow_Cooling_Towers_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Cooling_Towers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Cooling_Towers_Order_By>>;
  where?: Maybe<Sensorflow_Cooling_Towers_Bool_Exp>;
};

export type Query_RootSensorflow_Cooling_Towers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_CurrenciesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Currencies_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Currencies_Order_By>>;
  where?: Maybe<Sensorflow_Currencies_Bool_Exp>;
};

export type Query_RootSensorflow_Currencies_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Currencies_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Currencies_Order_By>>;
  where?: Maybe<Sensorflow_Currencies_Bool_Exp>;
};

export type Query_RootSensorflow_Currencies_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSensorflow_Domo_Embeds_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Domo_Embeds_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Domo_Embeds_Order_By>>;
  where?: Maybe<Sensorflow_Domo_Embeds_Bool_Exp>;
};

export type Query_RootSensorflow_Entity_Feature_Flag_MappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Order_By>>;
  where?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp>;
};

export type Query_RootSensorflow_Entity_Feature_Flag_Mappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Order_By>>;
  where?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp>;
};

export type Query_RootSensorflow_Entity_Feature_Flag_Mappings_By_PkArgs = {
  entity_id: Scalars["String"];
  feature_flag_id: Scalars["Int"];
};

export type Query_RootSensorflow_Events_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Events_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Events_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Events_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_Events_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Events_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Events_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Events_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_Ac_Command_Event_StreamArgs = {
  args: Sensorflow_F_Get_Ac_Command_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_Ac_Command_Event_Stream_AggregateArgs = {
  args: Sensorflow_F_Get_Ac_Command_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_All_Event_StreamArgs = {
  args: Sensorflow_F_Get_All_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_All_Event_Stream_AggregateArgs = {
  args: Sensorflow_F_Get_All_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_Avg_Position_DataArgs = {
  args: Sensorflow_F_Get_Avg_Position_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Avg_Position_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Avg_Position_Data_Order_By>>;
  where?: Maybe<Sensorflow_Avg_Position_Data_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_Avg_Position_Data_AggregateArgs = {
  args: Sensorflow_F_Get_Avg_Position_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Avg_Position_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Avg_Position_Data_Order_By>>;
  where?: Maybe<Sensorflow_Avg_Position_Data_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_Config_Event_StreamArgs = {
  args: Sensorflow_F_Get_Config_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Config_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_Config_Event_Stream_AggregateArgs = {
  args: Sensorflow_F_Get_Config_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Config_Event_Stream_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_Event_StreamArgs = {
  args: Sensorflow_F_Get_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Order_By>>;
  where?: Maybe<Sensorflow_Get_Event_Stream_View_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_Event_Stream_AggregateArgs = {
  args: Sensorflow_F_Get_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Order_By>>;
  where?: Maybe<Sensorflow_Get_Event_Stream_View_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_Synced_Raw_DataArgs = {
  args: Sensorflow_F_Get_Synced_Raw_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Synced_Raw_Data_Bool_Exp>;
};

export type Query_RootSensorflow_F_Get_Synced_Raw_Data_AggregateArgs = {
  args: Sensorflow_F_Get_Synced_Raw_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Synced_Raw_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Feature_FlagsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Feature_Flags_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Feature_Flags_Order_By>>;
  where?: Maybe<Sensorflow_Feature_Flags_Bool_Exp>;
};

export type Query_RootSensorflow_Feature_Flags_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Feature_Flags_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Feature_Flags_Order_By>>;
  where?: Maybe<Sensorflow_Feature_Flags_Bool_Exp>;
};

export type Query_RootSensorflow_Feature_Flags_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSensorflow_Firmware_Releases_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Firmware_Releases_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Firmware_Releases_Order_By>>;
  where?: Maybe<Sensorflow_Firmware_Releases_Bool_Exp>;
};

export type Query_RootSensorflow_Firmware_Releases_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSensorflow_Gateway_FrequencyArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_Frequency_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_Frequency_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_Frequency_Bool_Exp>;
};

export type Query_RootSensorflow_Gateway_Frequency_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_Frequency_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_Frequency_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_Frequency_Bool_Exp>;
};

export type Query_RootSensorflow_Gateway_Frequency_By_PkArgs = {
  balena_id: Scalars["String"];
  reserved_frequency: Scalars["bigint"];
};

export type Query_RootSensorflow_Gateway_Health_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_Health_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_Health_Data_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Gateway_Health_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_Health_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_Health_Data_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Gateway_To_Position_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_Gateway_To_Position_Mapping_By_PkArgs = {
  positionId: Scalars["uuid"];
};

export type Query_RootSensorflow_Gateways_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateways_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateways_Order_By>>;
  where?: Maybe<Sensorflow_Gateways_Bool_Exp>;
};

export type Query_RootSensorflow_Gateways_By_PkArgs = {
  gatewayId: Scalars["String"];
};

export type Query_RootSensorflow_Get_Event_Stream_ViewArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Order_By>>;
  where?: Maybe<Sensorflow_Get_Event_Stream_View_Bool_Exp>;
};

export type Query_RootSensorflow_Get_Event_Stream_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Order_By>>;
  where?: Maybe<Sensorflow_Get_Event_Stream_View_Bool_Exp>;
};

export type Query_RootSensorflow_Get_Precool_Command_StreamsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Order_By>>;
  where?: Maybe<Sensorflow_Get_Precool_Command_Streams_Bool_Exp>;
};

export type Query_RootSensorflow_Get_Precool_Command_Streams_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Order_By>>;
  where?: Maybe<Sensorflow_Get_Precool_Command_Streams_Bool_Exp>;
};

export type Query_RootSensorflow_Key_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Categories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Categories_Order_By>>;
  where?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
};

export type Query_RootSensorflow_Key_Categories_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Key_Category_Room_TemplatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
};

export type Query_RootSensorflow_Key_Category_Room_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
};

export type Query_RootSensorflow_Key_Category_Room_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Key_Category_Slot_TemplatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
};

export type Query_RootSensorflow_Key_Category_Slot_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
};

export type Query_RootSensorflow_Key_Category_Slot_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Key_Category_TemplatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Templates_Bool_Exp>;
};

export type Query_RootSensorflow_Key_Category_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Templates_Bool_Exp>;
};

export type Query_RootSensorflow_Key_Category_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Key_Category_To_Key_PositionArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
};

export type Query_RootSensorflow_Key_Category_To_Key_Position_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
};

export type Query_RootSensorflow_Key_Category_To_Key_Position_By_PkArgs = {
  categoryName: Scalars["String"];
  locationName: Scalars["String"];
  positionId: Scalars["uuid"];
};

export type Query_RootSensorflow_Location_AddressesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Addresses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Addresses_Order_By>>;
  where?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
};

export type Query_RootSensorflow_Location_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Addresses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Addresses_Order_By>>;
  where?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
};

export type Query_RootSensorflow_Location_Addresses_By_PkArgs = {
  location_id: Scalars["uuid"];
};

export type Query_RootSensorflow_Location_ConsumptionsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Consumptions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Consumptions_Order_By>>;
  where?: Maybe<Sensorflow_Location_Consumptions_Bool_Exp>;
};

export type Query_RootSensorflow_Location_Consumptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Consumptions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Consumptions_Order_By>>;
  where?: Maybe<Sensorflow_Location_Consumptions_Bool_Exp>;
};

export type Query_RootSensorflow_Location_Consumptions_By_PkArgs = {
  consumptionPeriodEnd: Scalars["date"];
  consumptionPeriodStart: Scalars["date"];
  locationId: Scalars["uuid"];
  metricTypeId: Scalars["Int"];
};

export type Query_RootSensorflow_Location_Domo_Embeds_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Order_By>>;
  where?: Maybe<Sensorflow_Location_Domo_Embeds_Bool_Exp>;
};

export type Query_RootSensorflow_Location_MetadataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Metadata_Order_By>>;
  where?: Maybe<Sensorflow_Location_Metadata_Bool_Exp>;
};

export type Query_RootSensorflow_Location_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Metadata_Order_By>>;
  where?: Maybe<Sensorflow_Location_Metadata_Bool_Exp>;
};

export type Query_RootSensorflow_Location_Occ_Customer_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Order_By>>;
  where?: Maybe<Sensorflow_Location_Occ_Customer_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Location_Occ_Customer_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Order_By>>;
  where?: Maybe<Sensorflow_Location_Occ_Customer_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Location_Occ_Customer_Data_By_PkArgs = {
  locationId: Scalars["uuid"];
  periodEnd: Scalars["date"];
  periodStart: Scalars["date"];
};

export type Query_RootSensorflow_Location_Occ_Derived_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Order_By>>;
  where?: Maybe<Sensorflow_Location_Occ_Derived_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Location_Occ_Derived_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Order_By>>;
  where?: Maybe<Sensorflow_Location_Occ_Derived_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Location_Occ_Derived_Data_By_PkArgs = {
  date: Scalars["date"];
  locationId: Scalars["uuid"];
};

export type Query_RootSensorflow_Location_To_User_MappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_Location_To_User_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_Location_To_User_Mapping_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Locations_Order_By>>;
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
};

export type Query_RootSensorflow_Mapping_CoordinatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Mapping_Coordinates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Mapping_Coordinates_Order_By>>;
  where?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
};

export type Query_RootSensorflow_Mapping_Coordinates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Mapping_Coordinates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Mapping_Coordinates_Order_By>>;
  where?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
};

export type Query_RootSensorflow_Metric_TypesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Metric_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Metric_Types_Order_By>>;
  where?: Maybe<Sensorflow_Metric_Types_Bool_Exp>;
};

export type Query_RootSensorflow_Metric_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Metric_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Metric_Types_Order_By>>;
  where?: Maybe<Sensorflow_Metric_Types_Bool_Exp>;
};

export type Query_RootSensorflow_Metric_Types_By_PkArgs = {
  metricTypeId: Scalars["Int"];
};

export type Query_RootSensorflow_Node_FirmwareArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Firmware_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Firmware_Order_By>>;
  where?: Maybe<Sensorflow_Node_Firmware_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Firmware_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Firmware_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Firmware_Order_By>>;
  where?: Maybe<Sensorflow_Node_Firmware_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Join_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Join_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Join_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Join_Strength_LiveArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Strength_Live_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Join_Strength_Live_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Strength_Live_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Life_Cycle_Event_TypesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Order_By>>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Life_Cycle_Event_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Order_By>>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Life_Cycle_Event_Types_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSensorflow_Node_Life_Cycle_EventsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Order_By>>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Life_Cycle_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Order_By>>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Life_Cycle_Events_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSensorflow_Node_Measurement_ViewArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurement_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurement_View_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurement_View_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Measurement_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurement_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurement_View_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurement_View_Bool_Exp>;
};

export type Query_RootSensorflow_Node_MeasurementsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurements_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurements_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Measurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurements_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurements_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Meta_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Meta_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Meta_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Meta_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Meta_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Meta_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Meta_Data_By_PkArgs = {
  measurementType: Scalars["String"];
  nodeMacId: Scalars["String"];
  time: Scalars["timestamptz"];
};

export type Query_RootSensorflow_Node_Meta_StatusArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Meta_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Meta_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Meta_Status_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Meta_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Meta_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Meta_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Meta_Status_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Online_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Online_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Online_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Online_Status_Bool_Exp>;
};

export type Query_RootSensorflow_Node_SlotsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Slots_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Slots_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Node_Spare_SetArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Spare_Set_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Spare_Set_Order_By>>;
  where?: Maybe<Sensorflow_Node_Spare_Set_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Spare_Set_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Spare_Set_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Spare_Set_Order_By>>;
  where?: Maybe<Sensorflow_Node_Spare_Set_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Spare_Set_By_PkArgs = {
  locationId: Scalars["uuid"];
  nodeMacId: Scalars["String"];
};

export type Query_RootSensorflow_Node_Status_HistoryArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_Node_Status_History_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Status_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_Node_Status_History_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Status_History_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSensorflow_Node_StatusesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Statuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Statuses_Order_By>>;
  where?: Maybe<Sensorflow_Node_Statuses_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Statuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Statuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Statuses_Order_By>>;
  where?: Maybe<Sensorflow_Node_Statuses_Bool_Exp>;
};

export type Query_RootSensorflow_Node_Statuses_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSensorflow_Node_System_StatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_System_States_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_System_States_Order_By>>;
  where?: Maybe<Sensorflow_Node_System_States_Bool_Exp>;
};

export type Query_RootSensorflow_Node_System_States_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_System_States_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_System_States_Order_By>>;
  where?: Maybe<Sensorflow_Node_System_States_Bool_Exp>;
};

export type Query_RootSensorflow_Node_System_States_By_PkArgs = {
  nodeMacId: Scalars["String"];
  time: Scalars["timestamptz"];
};

export type Query_RootSensorflow_Node_To_Position_MappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Position_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_Node_To_Position_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Position_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_Node_To_Slot_MappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_Node_To_Slot_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_Node_To_Slot_Mapping_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSensorflow_NodesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Nodes_Order_By>>;
  where?: Maybe<Sensorflow_Nodes_Bool_Exp>;
};

export type Query_RootSensorflow_Nodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Nodes_Order_By>>;
  where?: Maybe<Sensorflow_Nodes_Bool_Exp>;
};

export type Query_RootSensorflow_Nodes_By_PkArgs = {
  node_mac_id: Scalars["String"];
};

export type Query_RootSensorflow_OrganisationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Organisations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Organisations_Order_By>>;
  where?: Maybe<Sensorflow_Organisations_Bool_Exp>;
};

export type Query_RootSensorflow_Organisations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Organisations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Organisations_Order_By>>;
  where?: Maybe<Sensorflow_Organisations_Bool_Exp>;
};

export type Query_RootSensorflow_Organisations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Ota_QueuesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ota_Queues_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ota_Queues_Order_By>>;
  where?: Maybe<Sensorflow_Ota_Queues_Bool_Exp>;
};

export type Query_RootSensorflow_Ota_Queues_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ota_Queues_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ota_Queues_Order_By>>;
  where?: Maybe<Sensorflow_Ota_Queues_Bool_Exp>;
};

export type Query_RootSensorflow_Ota_Queues_By_PkArgs = {
  ota_queue_id: Scalars["Int"];
};

export type Query_RootSensorflow_Ota_RunsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ota_Runs_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ota_Runs_Order_By>>;
  where?: Maybe<Sensorflow_Ota_Runs_Bool_Exp>;
};

export type Query_RootSensorflow_Ota_Runs_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ota_Runs_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ota_Runs_Order_By>>;
  where?: Maybe<Sensorflow_Ota_Runs_Bool_Exp>;
};

export type Query_RootSensorflow_Ota_Runs_By_PkArgs = {
  current_firmware_release_id: Scalars["Int"];
  node_mac_id: Scalars["String"];
  ota_queue_id: Scalars["Int"];
  target_firmware_release_id: Scalars["Int"];
};

export type Query_RootSensorflow_Outdoor_TemperaturesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Order_By>>;
  where?: Maybe<Sensorflow_Outdoor_Temperatures_Bool_Exp>;
};

export type Query_RootSensorflow_Outdoor_Temperatures_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Order_By>>;
  where?: Maybe<Sensorflow_Outdoor_Temperatures_Bool_Exp>;
};

export type Query_RootSensorflow_Outdoor_Temperatures_By_PkArgs = {
  positionId: Scalars["uuid"];
  time: Scalars["timestamptz"];
};

export type Query_RootSensorflow_Pending_Ac_Settings_Commands_For_GatewayArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Order_By>>;
  where?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Bool_Exp>;
};

export type Query_RootSensorflow_Pending_Ac_Settings_Commands_For_Gateway_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Order_By>>;
  where?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Bool_Exp>;
};

export type Query_RootSensorflow_PipesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pipes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pipes_Order_By>>;
  where?: Maybe<Sensorflow_Pipes_Bool_Exp>;
};

export type Query_RootSensorflow_Pipes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pipes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pipes_Order_By>>;
  where?: Maybe<Sensorflow_Pipes_Bool_Exp>;
};

export type Query_RootSensorflow_Pipes_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Position_Precool_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Position_Precool_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Position_Precool_Status_Order_By>>;
  where?: Maybe<Sensorflow_Position_Precool_Status_Bool_Exp>;
};

export type Query_RootSensorflow_Position_Signal_StatsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Position_Signal_Stats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Position_Signal_Stats_Order_By>>;
  where?: Maybe<Sensorflow_Position_Signal_Stats_Bool_Exp>;
};

export type Query_RootSensorflow_Position_Signal_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Position_Signal_Stats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Position_Signal_Stats_Order_By>>;
  where?: Maybe<Sensorflow_Position_Signal_Stats_Bool_Exp>;
};

export type Query_RootSensorflow_Position_Signal_Stats_By_PkArgs = {
  balena_id: Scalars["String"];
  position_id: Scalars["uuid"];
};

export type Query_RootSensorflow_Precool_CommandsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Precool_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Precool_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
};

export type Query_RootSensorflow_Precool_Commands_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Precool_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Precool_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
};

export type Query_RootSensorflow_Precool_Commands_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Precool_SchedulesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Precool_Schedules_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Precool_Schedules_Order_By>>;
  where?: Maybe<Sensorflow_Precool_Schedules_Bool_Exp>;
};

export type Query_RootSensorflow_Precool_Schedules_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Precool_Schedules_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Precool_Schedules_Order_By>>;
  where?: Maybe<Sensorflow_Precool_Schedules_Bool_Exp>;
};

export type Query_RootSensorflow_Precool_Schedules_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootSensorflow_Properties_InformationArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Properties_Information_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Properties_Information_Order_By>>;
  where?: Maybe<Sensorflow_Properties_Information_Bool_Exp>;
};

export type Query_RootSensorflow_Properties_Information_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Properties_Information_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Properties_Information_Order_By>>;
  where?: Maybe<Sensorflow_Properties_Information_Bool_Exp>;
};

export type Query_RootSensorflow_Properties_Information_By_PkArgs = {
  locationId: Scalars["uuid"];
};

export type Query_RootSensorflow_PumpsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pumps_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pumps_Order_By>>;
  where?: Maybe<Sensorflow_Pumps_Bool_Exp>;
};

export type Query_RootSensorflow_Pumps_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pumps_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pumps_Order_By>>;
  where?: Maybe<Sensorflow_Pumps_Bool_Exp>;
};

export type Query_RootSensorflow_Pumps_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Reservation_GuestsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservation_Guests_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservation_Guests_Order_By>>;
  where?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
};

export type Query_RootSensorflow_Reservation_Guests_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservation_Guests_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservation_Guests_Order_By>>;
  where?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
};

export type Query_RootSensorflow_Reservation_Guests_By_PkArgs = {
  reservationId: Scalars["bigint"];
  userId: Scalars["String"];
};

export type Query_RootSensorflow_ReservationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservations_Order_By>>;
  where?: Maybe<Sensorflow_Reservations_Bool_Exp>;
};

export type Query_RootSensorflow_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservations_Order_By>>;
  where?: Maybe<Sensorflow_Reservations_Bool_Exp>;
};

export type Query_RootSensorflow_Reservations_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootSensorflow_RolesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Roles_Order_By>>;
  where?: Maybe<Sensorflow_Roles_Bool_Exp>;
};

export type Query_RootSensorflow_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Roles_Order_By>>;
  where?: Maybe<Sensorflow_Roles_Bool_Exp>;
};

export type Query_RootSensorflow_Roles_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Smart_Rem_Validation_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Order_By>>;
  where?: Maybe<Sensorflow_Smart_Rem_Validation_History_Bool_Exp>;
};

export type Query_RootSensorflow_Smart_Rem_Validation_History_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_Smplrspace_Floors_To_LevelsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Order_By>>;
  where?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
};

export type Query_RootSensorflow_Smplrspace_Floors_To_Levels_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Order_By>>;
  where?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
};

export type Query_RootSensorflow_Smplrspace_Floors_To_Levels_By_PkArgs = {
  position_id: Scalars["uuid"];
};

export type Query_RootSensorflow_Smplrspace_Levels_GroupArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Order_By>>;
  where?: Maybe<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>;
};

export type Query_RootSensorflow_Smplrspace_Levels_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Order_By>>;
  where?: Maybe<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>;
};

export type Query_RootSensorflow_Smplrspace_Levels_Group_By_PkArgs = {
  groupId: Scalars["uuid"];
};

export type Query_RootSensorflow_Survey_ResponsesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Survey_Responses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Survey_Responses_Order_By>>;
  where?: Maybe<Sensorflow_Survey_Responses_Bool_Exp>;
};

export type Query_RootSensorflow_Survey_Responses_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Survey_Responses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Survey_Responses_Order_By>>;
  where?: Maybe<Sensorflow_Survey_Responses_Bool_Exp>;
};

export type Query_RootSensorflow_Survey_Responses_By_PkArgs = {
  responseId: Scalars["Int"];
};

export type Query_RootSensorflow_Synced_Raw_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Synced_Raw_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Synced_Raw_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Synced_Raw_Data_Bool_Exp>;
};

export type Query_RootSensorflow_Task_Status_HistoryArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Task_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Task_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_Task_Status_History_Bool_Exp>;
};

export type Query_RootSensorflow_Task_Status_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Task_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Task_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_Task_Status_History_Bool_Exp>;
};

export type Query_RootSensorflow_Task_Status_History_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSensorflow_TasksArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Tasks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Tasks_Order_By>>;
  where?: Maybe<Sensorflow_Tasks_Bool_Exp>;
};

export type Query_RootSensorflow_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Tasks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Tasks_Order_By>>;
  where?: Maybe<Sensorflow_Tasks_Bool_Exp>;
};

export type Query_RootSensorflow_Tasks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSensorflow_TestsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Tests_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Tests_Order_By>>;
  where?: Maybe<Sensorflow_Tests_Bool_Exp>;
};

export type Query_RootSensorflow_Tests_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Tests_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Tests_Order_By>>;
  where?: Maybe<Sensorflow_Tests_Bool_Exp>;
};

export type Query_RootSensorflow_Tests_By_PkArgs = {
  testId: Scalars["Int"];
};

export type Query_RootSensorflow_TimezonesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Timezones_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Timezones_Order_By>>;
  where?: Maybe<Sensorflow_Timezones_Bool_Exp>;
};

export type Query_RootSensorflow_Timezones_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Timezones_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Timezones_Order_By>>;
  where?: Maybe<Sensorflow_Timezones_Bool_Exp>;
};

export type Query_RootSensorflow_Timezones_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSensorflow_Updatable_NodesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Updatable_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Updatable_Nodes_Order_By>>;
  where?: Maybe<Sensorflow_Updatable_Nodes_Bool_Exp>;
};

export type Query_RootSensorflow_Updatable_Nodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Updatable_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Updatable_Nodes_Order_By>>;
  where?: Maybe<Sensorflow_Updatable_Nodes_Bool_Exp>;
};

export type Query_RootSensorflow_User_To_Permission_MappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_User_To_Permission_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_User_To_Permission_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_User_To_Permission_Mapping_Bool_Exp>;
};

export type Query_RootSensorflow_UsersArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Users_Order_By>>;
  where?: Maybe<Sensorflow_Users_Bool_Exp>;
};

export type Query_RootSensorflow_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Users_Order_By>>;
  where?: Maybe<Sensorflow_Users_Bool_Exp>;
};

export type Query_RootSensorflow_Users_By_PkArgs = {
  userId: Scalars["String"];
};

export type Query_RootSensorflow_V_All_Latest_Node_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Order_By>>;
  where?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Bool_Exp>;
};

export type Query_RootSensorflow_V_All_Latest_Node_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Order_By>>;
  where?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Bool_Exp>;
};

export type Query_RootSensorflow_V_Combined_User_RolesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Order_By>>;
  where?: Maybe<Sensorflow_V_Combined_User_Roles_Bool_Exp>;
};

export type Query_RootSensorflow_V_Combined_User_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Order_By>>;
  where?: Maybe<Sensorflow_V_Combined_User_Roles_Bool_Exp>;
};

export type Query_RootSensorflow_V_Comfortplus_Key_DetailsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Bool_Exp>;
};

export type Query_RootSensorflow_V_Comfortplus_Key_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Bool_Exp>;
};

export type Query_RootSensorflow_V_Comfortplus_Key_MeasurementsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Bool_Exp>;
};

export type Query_RootSensorflow_V_Comfortplus_Key_Measurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Bool_Exp>;
};

export type Query_RootSensorflow_V_Comfortplus_Precool_StatusArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Bool_Exp>;
};

export type Query_RootSensorflow_V_Comfortplus_Precool_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Bool_Exp>;
};

export type Query_RootSensorflow_V_Current_Position_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Order_By>>;
  where?: Maybe<Sensorflow_V_Current_Position_Configurations_Bool_Exp>;
};

export type Query_RootSensorflow_V_Group_TasksArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Group_Tasks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Group_Tasks_Order_By>>;
  where?: Maybe<Sensorflow_V_Group_Tasks_Bool_Exp>;
};

export type Query_RootSensorflow_V_Group_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Group_Tasks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Group_Tasks_Order_By>>;
  where?: Maybe<Sensorflow_V_Group_Tasks_Bool_Exp>;
};

export type Query_RootSensorflow_V_Group_With_StatsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Group_With_Stats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Group_With_Stats_Order_By>>;
  where?: Maybe<Sensorflow_V_Group_With_Stats_Bool_Exp>;
};

export type Query_RootSensorflow_V_Group_With_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Group_With_Stats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Group_With_Stats_Order_By>>;
  where?: Maybe<Sensorflow_V_Group_With_Stats_Bool_Exp>;
};

export type Query_RootSensorflow_V_Gw_Autoset_Min_SignalArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Order_By>>;
  where?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Bool_Exp>;
};

export type Query_RootSensorflow_V_Gw_Autoset_Min_Signal_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Order_By>>;
  where?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Bool_Exp>;
};

export type Query_RootSensorflow_V_Infrastructure_Emon_Power_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Order_By>>;
  where?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Bool_Exp>;
};

export type Query_RootSensorflow_V_Infrastructures_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Infrastructures_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Infrastructures_Order_By>>;
  where?: Maybe<Sensorflow_V_Infrastructures_Bool_Exp>;
};

export type Query_RootSensorflow_V_Key_Emon_Power_ReadingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Bool_Exp>;
};

export type Query_RootSensorflow_V_Key_Emon_Power_Reading_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Bool_Exp>;
};

export type Query_RootSensorflow_V_Key_Position_Latest_EntryArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Bool_Exp>;
};

export type Query_RootSensorflow_V_Key_Position_Latest_Entry_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Bool_Exp>;
};

export type Query_RootSensorflow_V_Key_Task_Min_DuedateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Task_Min_Duedate_Bool_Exp>;
};

export type Query_RootSensorflow_V_Key_Task_Min_Duedate_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Task_Min_Duedate_Bool_Exp>;
};

export type Query_RootSensorflow_V_Location_Last_UpdateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Location_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Location_Last_Update_Order_By>>;
  where?: Maybe<Sensorflow_V_Location_Last_Update_Bool_Exp>;
};

export type Query_RootSensorflow_V_Location_Last_Update_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Location_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Location_Last_Update_Order_By>>;
  where?: Maybe<Sensorflow_V_Location_Last_Update_Bool_Exp>;
};

export type Query_RootSensorflow_V_Node_Life_Cycle_EventsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Order_By>>;
  where?: Maybe<Sensorflow_V_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Query_RootSensorflow_V_Node_Life_Cycle_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Order_By>>;
  where?: Maybe<Sensorflow_V_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Query_RootSensorflow_V_Task_With_Key_Status_HistoryArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Bool_Exp>;
};

export type Query_RootSensorflow_V_Task_With_Key_Status_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Bool_Exp>;
};

export type Query_RootSensorflow_V_Tasks_With_Keys_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_V_Tasks_With_Keys_Bool_Exp>;
};

export type Query_RootSensorflow_Warehouse_Kitting_Process_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Order_By>>;
  where?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
};

export type Query_RootSensorflow_Warehouse_Kitting_Process_Metadata_By_PkArgs = {
  balenaId: Scalars["String"];
};

export type Query_RootSensorflow_Warehouse_Mapping_Status_Of_SlotsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Bool_Exp>;
};

export type Query_RootSensorflow_Warehouse_Mapping_Status_Of_Slots_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Bool_Exp>;
};

export type Query_RootSf_Firmware_Dist_Pipeline_Firmware_BuildArgs = {
  distinct_on?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Order_By>>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp>;
};

export type Query_RootSf_Firmware_Dist_Pipeline_Firmware_Build_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Order_By>>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp>;
};

export type Query_RootSf_Firmware_Dist_Pipeline_Firmware_Build_By_PkArgs = {
  buildId: Scalars["Int"];
};

export type Query_RootSf_Firmware_Dist_Pipeline_Firmware_NumbersArgs = {
  distinct_on?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Order_By>>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp>;
};

export type Query_RootSf_Firmware_Dist_Pipeline_Firmware_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Order_By>>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp>;
};

export type Query_RootSf_Firmware_Dist_Pipeline_Firmware_Numbers_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSf_Pms_Pms_PmslistArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Pmslist_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Pmslist_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Pmslist_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_Pmslist_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Pmslist_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Pmslist_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Pmslist_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_Pmslist_By_PkArgs = {
  pms_id: Scalars["Int"];
};

export type Query_RootSf_Pms_Pms_PropertiesArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Properties_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Properties_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_Properties_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Properties_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Properties_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_Properties_By_PkArgs = {
  pms_id: Scalars["Int"];
  property_id: Scalars["String"];
};

export type Query_RootSf_Pms_Pms_Property_Eod_SurveysArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_Property_Eod_Surveys_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_Property_Eod_Surveys_By_PkArgs = {
  pms_id: Scalars["Int"];
  property_id: Scalars["String"];
  survey_monkey_id: Scalars["String"];
};

export type Query_RootSf_Pms_Pms_ReservationsArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Reservations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Reservations_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Reservations_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Reservations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Reservations_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Reservations_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_Room_CategoriesArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Room_Categories_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_Room_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Room_Categories_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_RoomsArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Rooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Rooms_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Rooms_Bool_Exp>;
};

export type Query_RootSf_Pms_Pms_Rooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Rooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Rooms_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Rooms_Bool_Exp>;
};

export type Query_RootSf_Pms_Smartalloc_ReportArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Smartalloc_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Smartalloc_Report_Order_By>>;
  where?: Maybe<Sf_Pms_Smartalloc_Report_Bool_Exp>;
};

export type Query_RootSf_Pms_Smartalloc_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Smartalloc_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Smartalloc_Report_Order_By>>;
  where?: Maybe<Sf_Pms_Smartalloc_Report_Bool_Exp>;
};

export type Query_RootSf_Pms_Smartalloc_Report_By_PkArgs = {
  date: Scalars["date"];
  locationId: Scalars["uuid"];
  type: Scalars["String"];
};

export type Query_RootSf_Simulation_Instrumentation_CommandsArgs = {
  distinct_on?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Order_By>>;
  where?: Maybe<Sf_Simulation_Instrumentation_Commands_Bool_Exp>;
};

export type Query_RootSf_Simulation_Instrumentation_Commands_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Order_By>>;
  where?: Maybe<Sf_Simulation_Instrumentation_Commands_Bool_Exp>;
};

export type Query_RootSf_Simulation_Instrumentation_Commands_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSf_Support_ActionsArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Actions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Actions_Order_By>>;
  where?: Maybe<Sf_Support_Actions_Bool_Exp>;
};

export type Query_RootSf_Support_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Actions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Actions_Order_By>>;
  where?: Maybe<Sf_Support_Actions_Bool_Exp>;
};

export type Query_RootSf_Support_Actions_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSf_Support_Active_Property_AlertsArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Active_Property_Alerts_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Active_Property_Alerts_Order_By>>;
  where?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
};

export type Query_RootSf_Support_Active_Property_Alerts_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Active_Property_Alerts_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Active_Property_Alerts_Order_By>>;
  where?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
};

export type Query_RootSf_Support_Active_Property_Alerts_By_PkArgs = {
  locationId: Scalars["uuid"];
  ticketClassId: Scalars["Int"];
};

export type Query_RootSf_Support_Analytics_Ticket_Jobs_Running_HistoriesArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Order_By>>;
  where?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp>;
};

export type Query_RootSf_Support_Analytics_Ticket_Jobs_Running_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Order_By>>;
  where?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp>;
};

export type Query_RootSf_Support_Analytics_Ticket_Jobs_Running_Histories_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSf_Support_Root_CausesArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Root_Causes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Root_Causes_Order_By>>;
  where?: Maybe<Sf_Support_Root_Causes_Bool_Exp>;
};

export type Query_RootSf_Support_Root_Causes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Root_Causes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Root_Causes_Order_By>>;
  where?: Maybe<Sf_Support_Root_Causes_Bool_Exp>;
};

export type Query_RootSf_Support_Root_Causes_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSf_Support_Ticket_Classes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Ticket_Classes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Ticket_Classes_Order_By>>;
  where?: Maybe<Sf_Support_Ticket_Classes_Bool_Exp>;
};

export type Query_RootSf_Support_Ticket_Classes_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSf_Support_Ticket_StatusArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Ticket_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Ticket_Status_Order_By>>;
  where?: Maybe<Sf_Support_Ticket_Status_Bool_Exp>;
};

export type Query_RootSf_Support_Ticket_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Ticket_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Ticket_Status_Order_By>>;
  where?: Maybe<Sf_Support_Ticket_Status_Bool_Exp>;
};

export type Query_RootSf_Support_Ticket_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSf_Support_TicketsArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Tickets_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Tickets_Order_By>>;
  where?: Maybe<Sf_Support_Tickets_Bool_Exp>;
};

export type Query_RootSf_Support_Tickets_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Tickets_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Tickets_Order_By>>;
  where?: Maybe<Sf_Support_Tickets_Bool_Exp>;
};

export type Query_RootSf_Support_Tickets_By_PkArgs = {
  ticket_id: Scalars["Int"];
};

export type Query_RootSf_Testjig_Test_Jig_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Bool_Exp>;
};

export type Query_RootSf_Testjig_Test_Jig_Maintenance_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp>;
};

export type Query_RootSf_Testjig_Test_Jig_Report_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp>;
};

export type Query_RootSf_Testjig_Test_Jig_Report_Metadata_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSf_Testjig_Test_Jig_Report_Metadatas_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp>;
};

export type Query_RootSf_Testjig_Test_Jig_Report_Metadatas_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSf_Testjig_Test_Jig_Test_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Bool_Exp>;
};

export type Query_RootSf_Testjig_Test_Jig_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Type_Bool_Exp>;
};

export type Query_RootSf_Testjig_V_Latest_Report_Rma_NodesArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Order_By>>;
  where?: Maybe<Sf_Testjig_V_Latest_Report_Rma_Nodes_Bool_Exp>;
};

export type Query_RootSf_Testjig_V_Latest_Report_Rma_Nodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Order_By>>;
  where?: Maybe<Sf_Testjig_V_Latest_Report_Rma_Nodes_Bool_Exp>;
};

export type Query_RootTasksWithKeysArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_V_Tasks_With_Keys_Bool_Exp>;
};

export type Query_RootTestRunsArgs = {
  distinct_on?: Maybe<Array<TestRuns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TestRuns_Order_By>>;
  where?: Maybe<TestRuns_Bool_Exp>;
};

export type Query_RootTestRuns_AggregateArgs = {
  distinct_on?: Maybe<Array<TestRuns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TestRuns_Order_By>>;
  where?: Maybe<TestRuns_Bool_Exp>;
};

export type Query_RootTestRuns_By_PkArgs = {
  testRunId: Scalars["Int"];
};

export type Query_RootTicketClassesArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Ticket_Classes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Ticket_Classes_Order_By>>;
  where?: Maybe<Sf_Support_Ticket_Classes_Bool_Exp>;
};

export type Query_RootUserArgs = {
  userId: Scalars["String"];
};

export type Query_RootUsersArgs = {
  filter?: Maybe<UserFilterInput>;
  pagination?: Maybe<GenericPaginationInput>;
  sort?: Maybe<UserSortInput>;
};

export type Query_RootValidateClickupListIdArgs = {
  clickupListId: Scalars["String"];
};

export type Query_RootValidateInfraNodeMappingArgs = {
  infraId: Scalars["ID"];
  nodeMacInput: Scalars["String"];
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType: NodeType;
  slotName: Scalars["String"];
};

export type Query_RootValidateNodeBeforeMappingToSlotArgs = {
  nodeMacInput: Scalars["String"];
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType: NodeType;
  roomId: Scalars["ID"];
  slotName: Scalars["String"];
};

export type RmaJobResponse = {
  __typename?: "RMAJobResponse";
  id: Scalars["ID"];
};

export type Role = {
  __typename?: "Role";
  defaultRoute?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  permissions: Array<Permission>;
  userCount?: Maybe<Scalars["Int"]>;
};

export type Room = {
  __typename?: "Room";
  energyConsumption?: Maybe<Scalars["Float"]>;
  positionConfiguration?: Maybe<PositionConfiguration>;
  roomId: Scalars["ID"];
  roomName: Scalars["String"];
  skippedRapidMappingSlot?: Maybe<Slot>;
  slots: Array<Slot>;
};

export type RoomEnergyConsumptionArgs = {
  fromDate: Scalars["String"];
  toDate: Scalars["String"];
};

export type RoomCompressorCreateInput = {
  compressorName: Scalars["String"];
  compressorNumPhases: Scalars["Int"];
  ctMultiplier: Scalars["Float"];
  hvacSystemType: Scalars["String"];
  position: PositionInput;
  positionMappingId: Scalars["ID"];
};

export type RoomDetailsFilterInput = {
  mappingStatus?: Maybe<MappingStatus>;
};

export type RoomWithThermostatOff = {
  __typename?: "RoomWithThermostatOff";
  roomId: Scalars["ID"];
  roomName?: Maybe<Scalars["String"]>;
};

export type SearchPositions_Sensorflow_Locations_Args = {
  searchbykeyname?: Maybe<Scalars["String"]>;
  searchbyroomname?: Maybe<Scalars["String"]>;
};

export type SearchRooms_Sensorflow_Positions_Args = {
  searchautosetname?: Maybe<Scalars["String"]>;
  searchkeyname?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Command_Event_Stream = {
  __typename?: "sensorflow_ac_command_event_stream";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Command_Event_Stream_Aggregate = {
  __typename?: "sensorflow_ac_command_event_stream_aggregate";
  aggregate?: Maybe<Sensorflow_Ac_Command_Event_Stream_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ac_Command_Event_Stream>;
};

export type Sensorflow_Ac_Command_Event_Stream_Aggregate_Fields = {
  __typename?: "sensorflow_ac_command_event_stream_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ac_Command_Event_Stream_Max_Fields>;
  min?: Maybe<Sensorflow_Ac_Command_Event_Stream_Min_Fields>;
};

export type Sensorflow_Ac_Command_Event_Stream_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ac_Command_Event_Stream_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>>;
  ac_settings_type_changed?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  modified_by_user?: Maybe<String_Comparison_Exp>;
  new_ac_settings_value?: Maybe<String_Comparison_Exp>;
  previous_ac_settings_value?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
  user_email?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Ac_Command_Event_Stream_Insert_Input = {
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Command_Event_Stream_Max_Fields = {
  __typename?: "sensorflow_ac_command_event_stream_max_fields";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Command_Event_Stream_Min_Fields = {
  __typename?: "sensorflow_ac_command_event_stream_min_fields";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Command_Event_Stream_Mutation_Response = {
  __typename?: "sensorflow_ac_command_event_stream_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Ac_Command_Event_Stream>;
};

export type Sensorflow_Ac_Command_Event_Stream_Order_By = {
  ac_settings_type_changed?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  modified_by_user?: Maybe<Order_By>;
  new_ac_settings_value?: Maybe<Order_By>;
  previous_ac_settings_value?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  user_email?: Maybe<Order_By>;
};

export enum Sensorflow_Ac_Command_Event_Stream_Select_Column {
  AcSettingsTypeChanged = "ac_settings_type_changed",
  EventType = "event_type",
  ModifiedByUser = "modified_by_user",
  NewAcSettingsValue = "new_ac_settings_value",
  PreviousAcSettingsValue = "previous_ac_settings_value",
  Time = "time",
  UserEmail = "user_email",
}

export type Sensorflow_Ac_Command_Event_Stream_Set_Input = {
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Command_Event_Stream_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ac_Command_Event_Stream_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ac_Command_Event_Stream_Stream_Cursor_Value_Input = {
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Command_Event_Stream_Updates = {
  _set?: Maybe<Sensorflow_Ac_Command_Event_Stream_Set_Input>;
  where: Sensorflow_Ac_Command_Event_Stream_Bool_Exp;
};

export type Sensorflow_Ac_Models = {
  __typename?: "sensorflow_ac_models";
  brandName: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  identifier: Scalars["Int"];
  modelName: Scalars["String"];
  positionConfigurations: Array<PositionConfigurations>;
  positionConfigurations_aggregate: PositionConfigurations_Aggregate;
  status: Scalars["String"];
};

export type Sensorflow_Ac_ModelsPositionConfigurationsArgs = {
  distinct_on?: Maybe<Array<PositionConfigurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurations_Order_By>>;
  where?: Maybe<PositionConfigurations_Bool_Exp>;
};

export type Sensorflow_Ac_ModelsPositionConfigurations_AggregateArgs = {
  distinct_on?: Maybe<Array<PositionConfigurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurations_Order_By>>;
  where?: Maybe<PositionConfigurations_Bool_Exp>;
};

export type Sensorflow_Ac_Models_Aggregate = {
  __typename?: "sensorflow_ac_models_aggregate";
  aggregate?: Maybe<Sensorflow_Ac_Models_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ac_Models>;
};

export type Sensorflow_Ac_Models_Aggregate_Fields = {
  __typename?: "sensorflow_ac_models_aggregate_fields";
  avg?: Maybe<Sensorflow_Ac_Models_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ac_Models_Max_Fields>;
  min?: Maybe<Sensorflow_Ac_Models_Min_Fields>;
  stddev?: Maybe<Sensorflow_Ac_Models_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Ac_Models_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Ac_Models_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Ac_Models_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Ac_Models_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Ac_Models_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Ac_Models_Variance_Fields>;
};

export type Sensorflow_Ac_Models_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ac_Models_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ac_Models_Avg_Fields = {
  __typename?: "sensorflow_ac_models_avg_fields";
  identifier?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Models_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ac_Models_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ac_Models_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ac_Models_Bool_Exp>>;
  brandName?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  identifier?: Maybe<Int_Comparison_Exp>;
  modelName?: Maybe<String_Comparison_Exp>;
  positionConfigurations?: Maybe<PositionConfigurations_Bool_Exp>;
  positionConfigurations_aggregate?: Maybe<PositionConfigurations_Aggregate_Bool_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Ac_Models_Constraint {
  AcModelsPkey = "ac_models_pkey",
}

export type Sensorflow_Ac_Models_Inc_Input = {
  identifier?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ac_Models_Insert_Input = {
  brandName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["Int"]>;
  modelName?: Maybe<Scalars["String"]>;
  positionConfigurations?: Maybe<PositionConfigurations_Arr_Rel_Insert_Input>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Models_Max_Fields = {
  __typename?: "sensorflow_ac_models_max_fields";
  brandName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["Int"]>;
  modelName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Models_Min_Fields = {
  __typename?: "sensorflow_ac_models_min_fields";
  brandName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["Int"]>;
  modelName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Models_Mutation_Response = {
  __typename?: "sensorflow_ac_models_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Ac_Models>;
};

export type Sensorflow_Ac_Models_Obj_Rel_Insert_Input = {
  data: Sensorflow_Ac_Models_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Ac_Models_On_Conflict>;
};

export type Sensorflow_Ac_Models_On_Conflict = {
  constraint: Sensorflow_Ac_Models_Constraint;
  update_columns: Array<Sensorflow_Ac_Models_Update_Column>;
  where?: Maybe<Sensorflow_Ac_Models_Bool_Exp>;
};

export type Sensorflow_Ac_Models_Order_By = {
  brandName?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identifier?: Maybe<Order_By>;
  modelName?: Maybe<Order_By>;
  positionConfigurations_aggregate?: Maybe<PositionConfigurations_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
};

export type Sensorflow_Ac_Models_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Ac_Models_Select_Column {
  BrandName = "brandName",
  Description = "description",
  Id = "id",
  Identifier = "identifier",
  ModelName = "modelName",
  Status = "status",
}

export type Sensorflow_Ac_Models_Set_Input = {
  brandName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["Int"]>;
  modelName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Models_Stddev_Fields = {
  __typename?: "sensorflow_ac_models_stddev_fields";
  identifier?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Models_Stddev_Pop_Fields = {
  __typename?: "sensorflow_ac_models_stddev_pop_fields";
  identifier?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Models_Stddev_Samp_Fields = {
  __typename?: "sensorflow_ac_models_stddev_samp_fields";
  identifier?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Models_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ac_Models_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ac_Models_Stream_Cursor_Value_Input = {
  brandName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["Int"]>;
  modelName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ac_Models_Sum_Fields = {
  __typename?: "sensorflow_ac_models_sum_fields";
  identifier?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Ac_Models_Update_Column {
  BrandName = "brandName",
  Description = "description",
  Id = "id",
  Identifier = "identifier",
  ModelName = "modelName",
  Status = "status",
}

export type Sensorflow_Ac_Models_Updates = {
  _inc?: Maybe<Sensorflow_Ac_Models_Inc_Input>;
  _set?: Maybe<Sensorflow_Ac_Models_Set_Input>;
  where: Sensorflow_Ac_Models_Bool_Exp;
};

export type Sensorflow_Ac_Models_Var_Pop_Fields = {
  __typename?: "sensorflow_ac_models_var_pop_fields";
  identifier?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Models_Var_Samp_Fields = {
  __typename?: "sensorflow_ac_models_var_samp_fields";
  identifier?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Models_Variance_Fields = {
  __typename?: "sensorflow_ac_models_variance_fields";
  identifier?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Setting_Commands = {
  __typename?: "sensorflow_ac_setting_commands";
  createdBy?: Maybe<Scalars["String"]>;
  createdByUser?: Maybe<Sensorflow_Users>;
  creationDate: Scalars["timestamptz"];
  failMessage?: Maybe<Scalars["String"]>;
  fromACMode?: Maybe<Scalars["Int"]>;
  fromACSetPoint?: Maybe<Scalars["Int"]>;
  fromACStatus?: Maybe<Scalars["Int"]>;
  fromFanSpeed?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
  source: Scalars["String"];
  status: Scalars["String"];
  toACMode?: Maybe<Scalars["Int"]>;
  toACSetPoint?: Maybe<Scalars["Int"]>;
  toACStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ac_Setting_Commands_Aggregate = {
  __typename?: "sensorflow_ac_setting_commands_aggregate";
  aggregate?: Maybe<Sensorflow_Ac_Setting_Commands_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ac_Setting_Commands>;
};

export type Sensorflow_Ac_Setting_Commands_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Ac_Setting_Commands_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Ac_Setting_Commands_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Ac_Setting_Commands_Aggregate_Fields = {
  __typename?: "sensorflow_ac_setting_commands_aggregate_fields";
  avg?: Maybe<Sensorflow_Ac_Setting_Commands_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ac_Setting_Commands_Max_Fields>;
  min?: Maybe<Sensorflow_Ac_Setting_Commands_Min_Fields>;
  stddev?: Maybe<Sensorflow_Ac_Setting_Commands_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Ac_Setting_Commands_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Ac_Setting_Commands_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Ac_Setting_Commands_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Ac_Setting_Commands_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Ac_Setting_Commands_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Ac_Setting_Commands_Variance_Fields>;
};

export type Sensorflow_Ac_Setting_Commands_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ac_Setting_Commands_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Ac_Setting_Commands_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Ac_Setting_Commands_Max_Order_By>;
  min?: Maybe<Sensorflow_Ac_Setting_Commands_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Ac_Setting_Commands_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Ac_Setting_Commands_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Ac_Setting_Commands_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Ac_Setting_Commands_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Ac_Setting_Commands_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Ac_Setting_Commands_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Ac_Setting_Commands_Variance_Order_By>;
};

export type Sensorflow_Ac_Setting_Commands_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Ac_Setting_Commands_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Ac_Setting_Commands_On_Conflict>;
};

export type Sensorflow_Ac_Setting_Commands_Avg_Fields = {
  __typename?: "sensorflow_ac_setting_commands_avg_fields";
  fromACMode?: Maybe<Scalars["Float"]>;
  fromACSetPoint?: Maybe<Scalars["Float"]>;
  fromACStatus?: Maybe<Scalars["Float"]>;
  fromFanSpeed?: Maybe<Scalars["Float"]>;
  toACMode?: Maybe<Scalars["Float"]>;
  toACSetPoint?: Maybe<Scalars["Float"]>;
  toACStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Setting_Commands_Avg_Order_By = {
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
};

export type Sensorflow_Ac_Setting_Commands_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Bool_Exp>>;
  createdBy?: Maybe<String_Comparison_Exp>;
  createdByUser?: Maybe<Sensorflow_Users_Bool_Exp>;
  creationDate?: Maybe<Timestamptz_Comparison_Exp>;
  failMessage?: Maybe<String_Comparison_Exp>;
  fromACMode?: Maybe<Int_Comparison_Exp>;
  fromACSetPoint?: Maybe<Int_Comparison_Exp>;
  fromACStatus?: Maybe<Int_Comparison_Exp>;
  fromFanSpeed?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  toACMode?: Maybe<Int_Comparison_Exp>;
  toACSetPoint?: Maybe<Int_Comparison_Exp>;
  toACStatus?: Maybe<Int_Comparison_Exp>;
  toFanSpeed?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Ac_Setting_Commands_Constraint {
  AcSettingCommandsPkey = "ac_setting_commands_pkey",
}

export type Sensorflow_Ac_Setting_Commands_Inc_Input = {
  fromACMode?: Maybe<Scalars["Int"]>;
  fromACSetPoint?: Maybe<Scalars["Int"]>;
  fromACStatus?: Maybe<Scalars["Int"]>;
  fromFanSpeed?: Maybe<Scalars["Int"]>;
  toACMode?: Maybe<Scalars["Int"]>;
  toACSetPoint?: Maybe<Scalars["Int"]>;
  toACStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ac_Setting_Commands_Insert_Input = {
  createdBy?: Maybe<Scalars["String"]>;
  createdByUser?: Maybe<Sensorflow_Users_Obj_Rel_Insert_Input>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  failMessage?: Maybe<Scalars["String"]>;
  fromACMode?: Maybe<Scalars["Int"]>;
  fromACSetPoint?: Maybe<Scalars["Int"]>;
  fromACStatus?: Maybe<Scalars["Int"]>;
  fromFanSpeed?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  toACMode?: Maybe<Scalars["Int"]>;
  toACSetPoint?: Maybe<Scalars["Int"]>;
  toACStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ac_Setting_Commands_Max_Fields = {
  __typename?: "sensorflow_ac_setting_commands_max_fields";
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  failMessage?: Maybe<Scalars["String"]>;
  fromACMode?: Maybe<Scalars["Int"]>;
  fromACSetPoint?: Maybe<Scalars["Int"]>;
  fromACStatus?: Maybe<Scalars["Int"]>;
  fromFanSpeed?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  toACMode?: Maybe<Scalars["Int"]>;
  toACSetPoint?: Maybe<Scalars["Int"]>;
  toACStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ac_Setting_Commands_Max_Order_By = {
  createdBy?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  failMessage?: Maybe<Order_By>;
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

export type Sensorflow_Ac_Setting_Commands_Min_Fields = {
  __typename?: "sensorflow_ac_setting_commands_min_fields";
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  failMessage?: Maybe<Scalars["String"]>;
  fromACMode?: Maybe<Scalars["Int"]>;
  fromACSetPoint?: Maybe<Scalars["Int"]>;
  fromACStatus?: Maybe<Scalars["Int"]>;
  fromFanSpeed?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  toACMode?: Maybe<Scalars["Int"]>;
  toACSetPoint?: Maybe<Scalars["Int"]>;
  toACStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ac_Setting_Commands_Min_Order_By = {
  createdBy?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  failMessage?: Maybe<Order_By>;
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

export type Sensorflow_Ac_Setting_Commands_Mutation_Response = {
  __typename?: "sensorflow_ac_setting_commands_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Ac_Setting_Commands>;
};

export type Sensorflow_Ac_Setting_Commands_On_Conflict = {
  constraint: Sensorflow_Ac_Setting_Commands_Constraint;
  update_columns: Array<Sensorflow_Ac_Setting_Commands_Update_Column>;
  where?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
};

export type Sensorflow_Ac_Setting_Commands_Order_By = {
  createdBy?: Maybe<Order_By>;
  createdByUser?: Maybe<Sensorflow_Users_Order_By>;
  creationDate?: Maybe<Order_By>;
  failMessage?: Maybe<Order_By>;
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

export type Sensorflow_Ac_Setting_Commands_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Ac_Setting_Commands_Select_Column {
  CreatedBy = "createdBy",
  CreationDate = "creationDate",
  FailMessage = "failMessage",
  FromAcMode = "fromACMode",
  FromAcSetPoint = "fromACSetPoint",
  FromAcStatus = "fromACStatus",
  FromFanSpeed = "fromFanSpeed",
  Id = "id",
  PositionId = "positionId",
  Source = "source",
  Status = "status",
  ToAcMode = "toACMode",
  ToAcSetPoint = "toACSetPoint",
  ToAcStatus = "toACStatus",
  ToFanSpeed = "toFanSpeed",
  UpdatedAt = "updatedAt",
}

export type Sensorflow_Ac_Setting_Commands_Set_Input = {
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  failMessage?: Maybe<Scalars["String"]>;
  fromACMode?: Maybe<Scalars["Int"]>;
  fromACSetPoint?: Maybe<Scalars["Int"]>;
  fromACStatus?: Maybe<Scalars["Int"]>;
  fromFanSpeed?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  toACMode?: Maybe<Scalars["Int"]>;
  toACSetPoint?: Maybe<Scalars["Int"]>;
  toACStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ac_Setting_Commands_Stddev_Fields = {
  __typename?: "sensorflow_ac_setting_commands_stddev_fields";
  fromACMode?: Maybe<Scalars["Float"]>;
  fromACSetPoint?: Maybe<Scalars["Float"]>;
  fromACStatus?: Maybe<Scalars["Float"]>;
  fromFanSpeed?: Maybe<Scalars["Float"]>;
  toACMode?: Maybe<Scalars["Float"]>;
  toACSetPoint?: Maybe<Scalars["Float"]>;
  toACStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Setting_Commands_Stddev_Order_By = {
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
};

export type Sensorflow_Ac_Setting_Commands_Stddev_Pop_Fields = {
  __typename?: "sensorflow_ac_setting_commands_stddev_pop_fields";
  fromACMode?: Maybe<Scalars["Float"]>;
  fromACSetPoint?: Maybe<Scalars["Float"]>;
  fromACStatus?: Maybe<Scalars["Float"]>;
  fromFanSpeed?: Maybe<Scalars["Float"]>;
  toACMode?: Maybe<Scalars["Float"]>;
  toACSetPoint?: Maybe<Scalars["Float"]>;
  toACStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Setting_Commands_Stddev_Pop_Order_By = {
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
};

export type Sensorflow_Ac_Setting_Commands_Stddev_Samp_Fields = {
  __typename?: "sensorflow_ac_setting_commands_stddev_samp_fields";
  fromACMode?: Maybe<Scalars["Float"]>;
  fromACSetPoint?: Maybe<Scalars["Float"]>;
  fromACStatus?: Maybe<Scalars["Float"]>;
  fromFanSpeed?: Maybe<Scalars["Float"]>;
  toACMode?: Maybe<Scalars["Float"]>;
  toACSetPoint?: Maybe<Scalars["Float"]>;
  toACStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Setting_Commands_Stddev_Samp_Order_By = {
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
};

export type Sensorflow_Ac_Setting_Commands_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ac_Setting_Commands_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ac_Setting_Commands_Stream_Cursor_Value_Input = {
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  failMessage?: Maybe<Scalars["String"]>;
  fromACMode?: Maybe<Scalars["Int"]>;
  fromACSetPoint?: Maybe<Scalars["Int"]>;
  fromACStatus?: Maybe<Scalars["Int"]>;
  fromFanSpeed?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  toACMode?: Maybe<Scalars["Int"]>;
  toACSetPoint?: Maybe<Scalars["Int"]>;
  toACStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ac_Setting_Commands_Sum_Fields = {
  __typename?: "sensorflow_ac_setting_commands_sum_fields";
  fromACMode?: Maybe<Scalars["Int"]>;
  fromACSetPoint?: Maybe<Scalars["Int"]>;
  fromACStatus?: Maybe<Scalars["Int"]>;
  fromFanSpeed?: Maybe<Scalars["Int"]>;
  toACMode?: Maybe<Scalars["Int"]>;
  toACSetPoint?: Maybe<Scalars["Int"]>;
  toACStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ac_Setting_Commands_Sum_Order_By = {
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
};

export enum Sensorflow_Ac_Setting_Commands_Update_Column {
  CreatedBy = "createdBy",
  CreationDate = "creationDate",
  FailMessage = "failMessage",
  FromAcMode = "fromACMode",
  FromAcSetPoint = "fromACSetPoint",
  FromAcStatus = "fromACStatus",
  FromFanSpeed = "fromFanSpeed",
  Id = "id",
  PositionId = "positionId",
  Source = "source",
  Status = "status",
  ToAcMode = "toACMode",
  ToAcSetPoint = "toACSetPoint",
  ToAcStatus = "toACStatus",
  ToFanSpeed = "toFanSpeed",
  UpdatedAt = "updatedAt",
}

export type Sensorflow_Ac_Setting_Commands_Updates = {
  _inc?: Maybe<Sensorflow_Ac_Setting_Commands_Inc_Input>;
  _set?: Maybe<Sensorflow_Ac_Setting_Commands_Set_Input>;
  where: Sensorflow_Ac_Setting_Commands_Bool_Exp;
};

export type Sensorflow_Ac_Setting_Commands_Var_Pop_Fields = {
  __typename?: "sensorflow_ac_setting_commands_var_pop_fields";
  fromACMode?: Maybe<Scalars["Float"]>;
  fromACSetPoint?: Maybe<Scalars["Float"]>;
  fromACStatus?: Maybe<Scalars["Float"]>;
  fromFanSpeed?: Maybe<Scalars["Float"]>;
  toACMode?: Maybe<Scalars["Float"]>;
  toACSetPoint?: Maybe<Scalars["Float"]>;
  toACStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Setting_Commands_Var_Pop_Order_By = {
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
};

export type Sensorflow_Ac_Setting_Commands_Var_Samp_Fields = {
  __typename?: "sensorflow_ac_setting_commands_var_samp_fields";
  fromACMode?: Maybe<Scalars["Float"]>;
  fromACSetPoint?: Maybe<Scalars["Float"]>;
  fromACStatus?: Maybe<Scalars["Float"]>;
  fromFanSpeed?: Maybe<Scalars["Float"]>;
  toACMode?: Maybe<Scalars["Float"]>;
  toACSetPoint?: Maybe<Scalars["Float"]>;
  toACStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Setting_Commands_Var_Samp_Order_By = {
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
};

export type Sensorflow_Ac_Setting_Commands_Variance_Fields = {
  __typename?: "sensorflow_ac_setting_commands_variance_fields";
  fromACMode?: Maybe<Scalars["Float"]>;
  fromACSetPoint?: Maybe<Scalars["Float"]>;
  fromACStatus?: Maybe<Scalars["Float"]>;
  fromFanSpeed?: Maybe<Scalars["Float"]>;
  toACMode?: Maybe<Scalars["Float"]>;
  toACSetPoint?: Maybe<Scalars["Float"]>;
  toACStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ac_Setting_Commands_Variance_Order_By = {
  fromACMode?: Maybe<Order_By>;
  fromACSetPoint?: Maybe<Order_By>;
  fromACStatus?: Maybe<Order_By>;
  fromFanSpeed?: Maybe<Order_By>;
  toACMode?: Maybe<Order_By>;
  toACSetPoint?: Maybe<Order_By>;
  toACStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream = {
  __typename?: "sensorflow_ai_dt_ac_event_stream";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Aggregate = {
  __typename?: "sensorflow_ai_dt_ac_event_stream_aggregate";
  aggregate?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ai_Dt_Ac_Event_Stream>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Aggregate_Fields = {
  __typename?: "sensorflow_ai_dt_ac_event_stream_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Max_Fields>;
  min?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Min_Fields>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>>;
  ac_settings_type_changed?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  modified_by_user?: Maybe<String_Comparison_Exp>;
  new_ac_settings_value?: Maybe<String_Comparison_Exp>;
  previous_ac_settings_value?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
  user_email?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Insert_Input = {
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Max_Fields = {
  __typename?: "sensorflow_ai_dt_ac_event_stream_max_fields";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Min_Fields = {
  __typename?: "sensorflow_ai_dt_ac_event_stream_min_fields";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Mutation_Response = {
  __typename?: "sensorflow_ai_dt_ac_event_stream_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Ai_Dt_Ac_Event_Stream>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By = {
  ac_settings_type_changed?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  modified_by_user?: Maybe<Order_By>;
  new_ac_settings_value?: Maybe<Order_By>;
  previous_ac_settings_value?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  user_email?: Maybe<Order_By>;
};

export enum Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column {
  AcSettingsTypeChanged = "ac_settings_type_changed",
  EventType = "event_type",
  ModifiedByUser = "modified_by_user",
  NewAcSettingsValue = "new_ac_settings_value",
  PreviousAcSettingsValue = "previous_ac_settings_value",
  Time = "time",
  UserEmail = "user_email",
}

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Set_Input = {
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ai_Dt_Ac_Event_Stream_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Stream_Cursor_Value_Input = {
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Ac_Event_Stream_Updates = {
  _set?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Set_Input>;
  where: Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream = {
  __typename?: "sensorflow_ai_dt_config_event_stream";
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Aggregate = {
  __typename?: "sensorflow_ai_dt_config_event_stream_aggregate";
  aggregate?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ai_Dt_Config_Event_Stream>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Aggregate_Fields = {
  __typename?: "sensorflow_ai_dt_config_event_stream_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Max_Fields>;
  min?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Min_Fields>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>>;
  config_type_changed?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  modified_by_user?: Maybe<String_Comparison_Exp>;
  new_config_value?: Maybe<String_Comparison_Exp>;
  previous_config_value?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
  user_email?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Insert_Input = {
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Max_Fields = {
  __typename?: "sensorflow_ai_dt_config_event_stream_max_fields";
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Min_Fields = {
  __typename?: "sensorflow_ai_dt_config_event_stream_min_fields";
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Mutation_Response = {
  __typename?: "sensorflow_ai_dt_config_event_stream_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Ai_Dt_Config_Event_Stream>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Order_By = {
  config_type_changed?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  modified_by_user?: Maybe<Order_By>;
  new_config_value?: Maybe<Order_By>;
  previous_config_value?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  user_email?: Maybe<Order_By>;
};

export enum Sensorflow_Ai_Dt_Config_Event_Stream_Select_Column {
  ConfigTypeChanged = "config_type_changed",
  EventType = "event_type",
  ModifiedByUser = "modified_by_user",
  NewConfigValue = "new_config_value",
  PreviousConfigValue = "previous_config_value",
  Time = "time",
  UserEmail = "user_email",
}

export type Sensorflow_Ai_Dt_Config_Event_Stream_Set_Input = {
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ai_Dt_Config_Event_Stream_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Stream_Cursor_Value_Input = {
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_Dt_Config_Event_Stream_Updates = {
  _set?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Set_Input>;
  where: Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp;
};

export type Sensorflow_Ai_Dt_Event_Stream = {
  __typename?: "sensorflow_ai_dt_event_stream";
  changed_by?: Maybe<Scalars["String"]>;
  current_value?: Maybe<Scalars["Int"]>;
  event_type?: Maybe<Scalars["String"]>;
  has_changed?: Maybe<Scalars["Int"]>;
  previous_value?: Maybe<Scalars["Int"]>;
  slot_name?: Maybe<Scalars["String"]>;
  src_mac_id?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  uuid?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Aggregate = {
  __typename?: "sensorflow_ai_dt_event_stream_aggregate";
  aggregate?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ai_Dt_Event_Stream>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Aggregate_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_aggregate_fields";
  avg?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Max_Fields>;
  min?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Min_Fields>;
  stddev?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Variance_Fields>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Avg_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_avg_fields";
  current_value?: Maybe<Scalars["Float"]>;
  has_changed?: Maybe<Scalars["Float"]>;
  previous_value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>>;
  changed_by?: Maybe<String_Comparison_Exp>;
  current_value?: Maybe<Int_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  has_changed?: Maybe<Int_Comparison_Exp>;
  previous_value?: Maybe<Int_Comparison_Exp>;
  slot_name?: Maybe<String_Comparison_Exp>;
  src_mac_id?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Max_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_max_fields";
  changed_by?: Maybe<Scalars["String"]>;
  current_value?: Maybe<Scalars["Int"]>;
  event_type?: Maybe<Scalars["String"]>;
  has_changed?: Maybe<Scalars["Int"]>;
  previous_value?: Maybe<Scalars["Int"]>;
  slot_name?: Maybe<Scalars["String"]>;
  src_mac_id?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  uuid?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Min_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_min_fields";
  changed_by?: Maybe<Scalars["String"]>;
  current_value?: Maybe<Scalars["Int"]>;
  event_type?: Maybe<Scalars["String"]>;
  has_changed?: Maybe<Scalars["Int"]>;
  previous_value?: Maybe<Scalars["Int"]>;
  slot_name?: Maybe<Scalars["String"]>;
  src_mac_id?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  uuid?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Order_By = {
  changed_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  has_changed?: Maybe<Order_By>;
  previous_value?: Maybe<Order_By>;
  slot_name?: Maybe<Order_By>;
  src_mac_id?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
};

export enum Sensorflow_Ai_Dt_Event_Stream_Select_Column {
  ChangedBy = "changed_by",
  CurrentValue = "current_value",
  EventType = "event_type",
  HasChanged = "has_changed",
  PreviousValue = "previous_value",
  SlotName = "slot_name",
  SrcMacId = "src_mac_id",
  Time = "time",
  Uuid = "uuid",
}

export type Sensorflow_Ai_Dt_Event_Stream_Stddev_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_stddev_fields";
  current_value?: Maybe<Scalars["Float"]>;
  has_changed?: Maybe<Scalars["Float"]>;
  previous_value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Stddev_Pop_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_stddev_pop_fields";
  current_value?: Maybe<Scalars["Float"]>;
  has_changed?: Maybe<Scalars["Float"]>;
  previous_value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Stddev_Samp_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_stddev_samp_fields";
  current_value?: Maybe<Scalars["Float"]>;
  has_changed?: Maybe<Scalars["Float"]>;
  previous_value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ai_Dt_Event_Stream_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Stream_Cursor_Value_Input = {
  changed_by?: Maybe<Scalars["String"]>;
  current_value?: Maybe<Scalars["Int"]>;
  event_type?: Maybe<Scalars["String"]>;
  has_changed?: Maybe<Scalars["Int"]>;
  previous_value?: Maybe<Scalars["Int"]>;
  slot_name?: Maybe<Scalars["String"]>;
  src_mac_id?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  uuid?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Sum_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_sum_fields";
  current_value?: Maybe<Scalars["Int"]>;
  has_changed?: Maybe<Scalars["Int"]>;
  previous_value?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Var_Pop_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_var_pop_fields";
  current_value?: Maybe<Scalars["Float"]>;
  has_changed?: Maybe<Scalars["Float"]>;
  previous_value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Var_Samp_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_var_samp_fields";
  current_value?: Maybe<Scalars["Float"]>;
  has_changed?: Maybe<Scalars["Float"]>;
  previous_value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Event_Stream_Variance_Fields = {
  __typename?: "sensorflow_ai_dt_event_stream_variance_fields";
  current_value?: Maybe<Scalars["Float"]>;
  has_changed?: Maybe<Scalars["Float"]>;
  previous_value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report = {
  __typename?: "sensorflow_ai_dt_room_comfort_report";
  average_humidity_when_cooling?: Maybe<Scalars["numeric"]>;
  average_temperature_when_cooling?: Maybe<Scalars["numeric"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_overcooling_hours?: Maybe<Scalars["numeric"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["numeric"]>;
  median_discomfort_percentage?: Maybe<Scalars["numeric"]>;
  most_frequent_fan_speed?: Maybe<Scalars["String"]>;
  most_frequent_mode?: Maybe<Scalars["String"]>;
  predominant_setpoint?: Maybe<Scalars["numeric"]>;
  total_days?: Maybe<Scalars["numeric"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["numeric"]>;
  total_minutes_overcooling?: Maybe<Scalars["numeric"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Aggregate = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_aggregate";
  aggregate?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ai_Dt_Room_Comfort_Report>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Aggregate_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_aggregate_fields";
  avg?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Max_Fields>;
  min?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Min_Fields>;
  stddev?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Variance_Fields>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Avg_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_avg_fields";
  average_humidity_when_cooling?: Maybe<Scalars["Float"]>;
  average_temperature_when_cooling?: Maybe<Scalars["Float"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_overcooling_hours?: Maybe<Scalars["Float"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["Float"]>;
  median_discomfort_percentage?: Maybe<Scalars["Float"]>;
  predominant_setpoint?: Maybe<Scalars["Float"]>;
  total_days?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["Float"]>;
  total_minutes_overcooling?: Maybe<Scalars["Float"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>>;
  average_humidity_when_cooling?: Maybe<Numeric_Comparison_Exp>;
  average_temperature_when_cooling?: Maybe<Numeric_Comparison_Exp>;
  daily_high_discomfort_hours?: Maybe<Numeric_Comparison_Exp>;
  daily_overcooling_hours?: Maybe<Numeric_Comparison_Exp>;
  daily_slight_discomfort_hours?: Maybe<Numeric_Comparison_Exp>;
  daily_steady_high_discomfort_hours?: Maybe<Numeric_Comparison_Exp>;
  daily_transient_discomfort_hours?: Maybe<Numeric_Comparison_Exp>;
  median_discomfort_percentage?: Maybe<Numeric_Comparison_Exp>;
  most_frequent_fan_speed?: Maybe<String_Comparison_Exp>;
  most_frequent_mode?: Maybe<String_Comparison_Exp>;
  predominant_setpoint?: Maybe<Numeric_Comparison_Exp>;
  total_days?: Maybe<Numeric_Comparison_Exp>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Numeric_Comparison_Exp>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Numeric_Comparison_Exp>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Numeric_Comparison_Exp>;
  total_minutes_overcooling?: Maybe<Numeric_Comparison_Exp>;
  total_minutes_slightly_uncomfortable?: Maybe<Numeric_Comparison_Exp>;
  total_minutes_very_uncomfortable?: Maybe<Numeric_Comparison_Exp>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Max_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_max_fields";
  average_humidity_when_cooling?: Maybe<Scalars["numeric"]>;
  average_temperature_when_cooling?: Maybe<Scalars["numeric"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_overcooling_hours?: Maybe<Scalars["numeric"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["numeric"]>;
  median_discomfort_percentage?: Maybe<Scalars["numeric"]>;
  most_frequent_fan_speed?: Maybe<Scalars["String"]>;
  most_frequent_mode?: Maybe<Scalars["String"]>;
  predominant_setpoint?: Maybe<Scalars["numeric"]>;
  total_days?: Maybe<Scalars["numeric"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["numeric"]>;
  total_minutes_overcooling?: Maybe<Scalars["numeric"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Min_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_min_fields";
  average_humidity_when_cooling?: Maybe<Scalars["numeric"]>;
  average_temperature_when_cooling?: Maybe<Scalars["numeric"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_overcooling_hours?: Maybe<Scalars["numeric"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["numeric"]>;
  median_discomfort_percentage?: Maybe<Scalars["numeric"]>;
  most_frequent_fan_speed?: Maybe<Scalars["String"]>;
  most_frequent_mode?: Maybe<Scalars["String"]>;
  predominant_setpoint?: Maybe<Scalars["numeric"]>;
  total_days?: Maybe<Scalars["numeric"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["numeric"]>;
  total_minutes_overcooling?: Maybe<Scalars["numeric"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Order_By = {
  average_humidity_when_cooling?: Maybe<Order_By>;
  average_temperature_when_cooling?: Maybe<Order_By>;
  daily_high_discomfort_hours?: Maybe<Order_By>;
  daily_overcooling_hours?: Maybe<Order_By>;
  daily_slight_discomfort_hours?: Maybe<Order_By>;
  daily_steady_high_discomfort_hours?: Maybe<Order_By>;
  daily_transient_discomfort_hours?: Maybe<Order_By>;
  median_discomfort_percentage?: Maybe<Order_By>;
  most_frequent_fan_speed?: Maybe<Order_By>;
  most_frequent_mode?: Maybe<Order_By>;
  predominant_setpoint?: Maybe<Order_By>;
  total_days?: Maybe<Order_By>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Order_By>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Order_By>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Order_By>;
  total_minutes_overcooling?: Maybe<Order_By>;
  total_minutes_slightly_uncomfortable?: Maybe<Order_By>;
  total_minutes_very_uncomfortable?: Maybe<Order_By>;
};

export enum Sensorflow_Ai_Dt_Room_Comfort_Report_Select_Column {
  AverageHumidityWhenCooling = "average_humidity_when_cooling",
  AverageTemperatureWhenCooling = "average_temperature_when_cooling",
  DailyHighDiscomfortHours = "daily_high_discomfort_hours",
  DailyOvercoolingHours = "daily_overcooling_hours",
  DailySlightDiscomfortHours = "daily_slight_discomfort_hours",
  DailySteadyHighDiscomfortHours = "daily_steady_high_discomfort_hours",
  DailyTransientDiscomfortHours = "daily_transient_discomfort_hours",
  MedianDiscomfortPercentage = "median_discomfort_percentage",
  MostFrequentFanSpeed = "most_frequent_fan_speed",
  MostFrequentMode = "most_frequent_mode",
  PredominantSetpoint = "predominant_setpoint",
  TotalDays = "total_days",
  TotalMinutesGuestChoseToBeSlightlyUncomfortable = "total_minutes_guest_chose_to_be_slightly_uncomfortable",
  TotalMinutesGuestChoseToBeVeryUncomfortable = "total_minutes_guest_chose_to_be_very_uncomfortable",
  TotalMinutesOfTransientDiscomfortCausedBySensorflow = "total_minutes_of_transient_discomfort_caused_by_sensorflow",
  TotalMinutesOvercooling = "total_minutes_overcooling",
  TotalMinutesSlightlyUncomfortable = "total_minutes_slightly_uncomfortable",
  TotalMinutesVeryUncomfortable = "total_minutes_very_uncomfortable",
}

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Stddev_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_stddev_fields";
  average_humidity_when_cooling?: Maybe<Scalars["Float"]>;
  average_temperature_when_cooling?: Maybe<Scalars["Float"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_overcooling_hours?: Maybe<Scalars["Float"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["Float"]>;
  median_discomfort_percentage?: Maybe<Scalars["Float"]>;
  predominant_setpoint?: Maybe<Scalars["Float"]>;
  total_days?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["Float"]>;
  total_minutes_overcooling?: Maybe<Scalars["Float"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Stddev_Pop_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_stddev_pop_fields";
  average_humidity_when_cooling?: Maybe<Scalars["Float"]>;
  average_temperature_when_cooling?: Maybe<Scalars["Float"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_overcooling_hours?: Maybe<Scalars["Float"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["Float"]>;
  median_discomfort_percentage?: Maybe<Scalars["Float"]>;
  predominant_setpoint?: Maybe<Scalars["Float"]>;
  total_days?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["Float"]>;
  total_minutes_overcooling?: Maybe<Scalars["Float"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Stddev_Samp_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_stddev_samp_fields";
  average_humidity_when_cooling?: Maybe<Scalars["Float"]>;
  average_temperature_when_cooling?: Maybe<Scalars["Float"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_overcooling_hours?: Maybe<Scalars["Float"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["Float"]>;
  median_discomfort_percentage?: Maybe<Scalars["Float"]>;
  predominant_setpoint?: Maybe<Scalars["Float"]>;
  total_days?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["Float"]>;
  total_minutes_overcooling?: Maybe<Scalars["Float"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ai_Dt_Room_Comfort_Report_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Stream_Cursor_Value_Input = {
  average_humidity_when_cooling?: Maybe<Scalars["numeric"]>;
  average_temperature_when_cooling?: Maybe<Scalars["numeric"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_overcooling_hours?: Maybe<Scalars["numeric"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["numeric"]>;
  median_discomfort_percentage?: Maybe<Scalars["numeric"]>;
  most_frequent_fan_speed?: Maybe<Scalars["String"]>;
  most_frequent_mode?: Maybe<Scalars["String"]>;
  predominant_setpoint?: Maybe<Scalars["numeric"]>;
  total_days?: Maybe<Scalars["numeric"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["numeric"]>;
  total_minutes_overcooling?: Maybe<Scalars["numeric"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Sum_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_sum_fields";
  average_humidity_when_cooling?: Maybe<Scalars["numeric"]>;
  average_temperature_when_cooling?: Maybe<Scalars["numeric"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_overcooling_hours?: Maybe<Scalars["numeric"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["numeric"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["numeric"]>;
  median_discomfort_percentage?: Maybe<Scalars["numeric"]>;
  predominant_setpoint?: Maybe<Scalars["numeric"]>;
  total_days?: Maybe<Scalars["numeric"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["numeric"]>;
  total_minutes_overcooling?: Maybe<Scalars["numeric"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["numeric"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Var_Pop_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_var_pop_fields";
  average_humidity_when_cooling?: Maybe<Scalars["Float"]>;
  average_temperature_when_cooling?: Maybe<Scalars["Float"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_overcooling_hours?: Maybe<Scalars["Float"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["Float"]>;
  median_discomfort_percentage?: Maybe<Scalars["Float"]>;
  predominant_setpoint?: Maybe<Scalars["Float"]>;
  total_days?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["Float"]>;
  total_minutes_overcooling?: Maybe<Scalars["Float"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Var_Samp_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_var_samp_fields";
  average_humidity_when_cooling?: Maybe<Scalars["Float"]>;
  average_temperature_when_cooling?: Maybe<Scalars["Float"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_overcooling_hours?: Maybe<Scalars["Float"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["Float"]>;
  median_discomfort_percentage?: Maybe<Scalars["Float"]>;
  predominant_setpoint?: Maybe<Scalars["Float"]>;
  total_days?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["Float"]>;
  total_minutes_overcooling?: Maybe<Scalars["Float"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Room_Comfort_Report_Variance_Fields = {
  __typename?: "sensorflow_ai_dt_room_comfort_report_variance_fields";
  average_humidity_when_cooling?: Maybe<Scalars["Float"]>;
  average_temperature_when_cooling?: Maybe<Scalars["Float"]>;
  daily_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_overcooling_hours?: Maybe<Scalars["Float"]>;
  daily_slight_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_steady_high_discomfort_hours?: Maybe<Scalars["Float"]>;
  daily_transient_discomfort_hours?: Maybe<Scalars["Float"]>;
  median_discomfort_percentage?: Maybe<Scalars["Float"]>;
  predominant_setpoint?: Maybe<Scalars["Float"]>;
  total_days?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_guest_chose_to_be_very_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_of_transient_discomfort_caused_by_sensorflow?: Maybe<Scalars["Float"]>;
  total_minutes_overcooling?: Maybe<Scalars["Float"]>;
  total_minutes_slightly_uncomfortable?: Maybe<Scalars["Float"]>;
  total_minutes_very_uncomfortable?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data = {
  __typename?: "sensorflow_ai_dt_synced_raw_data";
  ac_mode?: Maybe<Scalars["numeric"]>;
  ambient_humidity?: Maybe<Scalars["numeric"]>;
  ambient_temperature?: Maybe<Scalars["numeric"]>;
  automation_status?: Maybe<Scalars["Int"]>;
  discomfort_index?: Maybe<Scalars["numeric"]>;
  discomfort_percentage?: Maybe<Scalars["numeric"]>;
  door_state?: Maybe<Scalars["numeric"]>;
  fan_speed?: Maybe<Scalars["numeric"]>;
  heat_index?: Maybe<Scalars["numeric"]>;
  occupancy_state?: Maybe<Scalars["numeric"]>;
  pmv?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  powered?: Maybe<Scalars["numeric"]>;
  ppd?: Maybe<Scalars["numeric"]>;
  room_comfort?: Maybe<Scalars["String"]>;
  temperature_set?: Maybe<Scalars["numeric"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Aggregate = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_aggregate";
  aggregate?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ai_Dt_Synced_Raw_Data>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Aggregate_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_aggregate_fields";
  avg?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Max_Fields>;
  min?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Min_Fields>;
  stddev?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Variance_Fields>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Avg_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_avg_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  automation_status?: Maybe<Scalars["Float"]>;
  discomfort_index?: Maybe<Scalars["Float"]>;
  discomfort_percentage?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  heat_index?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  pmv?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  ppd?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>>;
  ac_mode?: Maybe<Numeric_Comparison_Exp>;
  ambient_humidity?: Maybe<Numeric_Comparison_Exp>;
  ambient_temperature?: Maybe<Numeric_Comparison_Exp>;
  automation_status?: Maybe<Int_Comparison_Exp>;
  discomfort_index?: Maybe<Numeric_Comparison_Exp>;
  discomfort_percentage?: Maybe<Numeric_Comparison_Exp>;
  door_state?: Maybe<Numeric_Comparison_Exp>;
  fan_speed?: Maybe<Numeric_Comparison_Exp>;
  heat_index?: Maybe<Numeric_Comparison_Exp>;
  occupancy_state?: Maybe<Numeric_Comparison_Exp>;
  pmv?: Maybe<Numeric_Comparison_Exp>;
  position_id?: Maybe<Uuid_Comparison_Exp>;
  powered?: Maybe<Numeric_Comparison_Exp>;
  ppd?: Maybe<Numeric_Comparison_Exp>;
  room_comfort?: Maybe<String_Comparison_Exp>;
  temperature_set?: Maybe<Numeric_Comparison_Exp>;
  timestamp?: Maybe<Timestamptz_Comparison_Exp>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Max_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_max_fields";
  ac_mode?: Maybe<Scalars["numeric"]>;
  ambient_humidity?: Maybe<Scalars["numeric"]>;
  ambient_temperature?: Maybe<Scalars["numeric"]>;
  automation_status?: Maybe<Scalars["Int"]>;
  discomfort_index?: Maybe<Scalars["numeric"]>;
  discomfort_percentage?: Maybe<Scalars["numeric"]>;
  door_state?: Maybe<Scalars["numeric"]>;
  fan_speed?: Maybe<Scalars["numeric"]>;
  heat_index?: Maybe<Scalars["numeric"]>;
  occupancy_state?: Maybe<Scalars["numeric"]>;
  pmv?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  powered?: Maybe<Scalars["numeric"]>;
  ppd?: Maybe<Scalars["numeric"]>;
  room_comfort?: Maybe<Scalars["String"]>;
  temperature_set?: Maybe<Scalars["numeric"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Min_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_min_fields";
  ac_mode?: Maybe<Scalars["numeric"]>;
  ambient_humidity?: Maybe<Scalars["numeric"]>;
  ambient_temperature?: Maybe<Scalars["numeric"]>;
  automation_status?: Maybe<Scalars["Int"]>;
  discomfort_index?: Maybe<Scalars["numeric"]>;
  discomfort_percentage?: Maybe<Scalars["numeric"]>;
  door_state?: Maybe<Scalars["numeric"]>;
  fan_speed?: Maybe<Scalars["numeric"]>;
  heat_index?: Maybe<Scalars["numeric"]>;
  occupancy_state?: Maybe<Scalars["numeric"]>;
  pmv?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  powered?: Maybe<Scalars["numeric"]>;
  ppd?: Maybe<Scalars["numeric"]>;
  room_comfort?: Maybe<Scalars["String"]>;
  temperature_set?: Maybe<Scalars["numeric"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Order_By = {
  ac_mode?: Maybe<Order_By>;
  ambient_humidity?: Maybe<Order_By>;
  ambient_temperature?: Maybe<Order_By>;
  automation_status?: Maybe<Order_By>;
  discomfort_index?: Maybe<Order_By>;
  discomfort_percentage?: Maybe<Order_By>;
  door_state?: Maybe<Order_By>;
  fan_speed?: Maybe<Order_By>;
  heat_index?: Maybe<Order_By>;
  occupancy_state?: Maybe<Order_By>;
  pmv?: Maybe<Order_By>;
  position_id?: Maybe<Order_By>;
  powered?: Maybe<Order_By>;
  ppd?: Maybe<Order_By>;
  room_comfort?: Maybe<Order_By>;
  temperature_set?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
};

export enum Sensorflow_Ai_Dt_Synced_Raw_Data_Select_Column {
  AcMode = "ac_mode",
  AmbientHumidity = "ambient_humidity",
  AmbientTemperature = "ambient_temperature",
  AutomationStatus = "automation_status",
  DiscomfortIndex = "discomfort_index",
  DiscomfortPercentage = "discomfort_percentage",
  DoorState = "door_state",
  FanSpeed = "fan_speed",
  HeatIndex = "heat_index",
  OccupancyState = "occupancy_state",
  Pmv = "pmv",
  PositionId = "position_id",
  Powered = "powered",
  Ppd = "ppd",
  RoomComfort = "room_comfort",
  TemperatureSet = "temperature_set",
  Timestamp = "timestamp",
}

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Stddev_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_stddev_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  automation_status?: Maybe<Scalars["Float"]>;
  discomfort_index?: Maybe<Scalars["Float"]>;
  discomfort_percentage?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  heat_index?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  pmv?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  ppd?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Stddev_Pop_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_stddev_pop_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  automation_status?: Maybe<Scalars["Float"]>;
  discomfort_index?: Maybe<Scalars["Float"]>;
  discomfort_percentage?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  heat_index?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  pmv?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  ppd?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Stddev_Samp_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_stddev_samp_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  automation_status?: Maybe<Scalars["Float"]>;
  discomfort_index?: Maybe<Scalars["Float"]>;
  discomfort_percentage?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  heat_index?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  pmv?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  ppd?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ai_Dt_Synced_Raw_Data_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Stream_Cursor_Value_Input = {
  ac_mode?: Maybe<Scalars["numeric"]>;
  ambient_humidity?: Maybe<Scalars["numeric"]>;
  ambient_temperature?: Maybe<Scalars["numeric"]>;
  automation_status?: Maybe<Scalars["Int"]>;
  discomfort_index?: Maybe<Scalars["numeric"]>;
  discomfort_percentage?: Maybe<Scalars["numeric"]>;
  door_state?: Maybe<Scalars["numeric"]>;
  fan_speed?: Maybe<Scalars["numeric"]>;
  heat_index?: Maybe<Scalars["numeric"]>;
  occupancy_state?: Maybe<Scalars["numeric"]>;
  pmv?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  powered?: Maybe<Scalars["numeric"]>;
  ppd?: Maybe<Scalars["numeric"]>;
  room_comfort?: Maybe<Scalars["String"]>;
  temperature_set?: Maybe<Scalars["numeric"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Sum_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_sum_fields";
  ac_mode?: Maybe<Scalars["numeric"]>;
  ambient_humidity?: Maybe<Scalars["numeric"]>;
  ambient_temperature?: Maybe<Scalars["numeric"]>;
  automation_status?: Maybe<Scalars["Int"]>;
  discomfort_index?: Maybe<Scalars["numeric"]>;
  discomfort_percentage?: Maybe<Scalars["numeric"]>;
  door_state?: Maybe<Scalars["numeric"]>;
  fan_speed?: Maybe<Scalars["numeric"]>;
  heat_index?: Maybe<Scalars["numeric"]>;
  occupancy_state?: Maybe<Scalars["numeric"]>;
  pmv?: Maybe<Scalars["numeric"]>;
  powered?: Maybe<Scalars["numeric"]>;
  ppd?: Maybe<Scalars["numeric"]>;
  temperature_set?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Var_Pop_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_var_pop_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  automation_status?: Maybe<Scalars["Float"]>;
  discomfort_index?: Maybe<Scalars["Float"]>;
  discomfort_percentage?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  heat_index?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  pmv?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  ppd?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Var_Samp_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_var_samp_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  automation_status?: Maybe<Scalars["Float"]>;
  discomfort_index?: Maybe<Scalars["Float"]>;
  discomfort_percentage?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  heat_index?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  pmv?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  ppd?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_Dt_Synced_Raw_Data_Variance_Fields = {
  __typename?: "sensorflow_ai_dt_synced_raw_data_variance_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  automation_status?: Maybe<Scalars["Float"]>;
  discomfort_index?: Maybe<Scalars["Float"]>;
  discomfort_percentage?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  heat_index?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  pmv?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  ppd?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_F_Get_Ac_Command_Event_Stream_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ai_F_Get_All_Event_Stream_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ai_F_Get_Config_Event_Stream_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ai_F_Get_Event_Stream_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ai_F_Get_Id_Info_Args = {
  hotel_name?: Maybe<Scalars["String"]>;
  room_number?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_F_Get_Synced_Raw_Data_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  intervals?: Maybe<Scalars["String"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ai_F_Room_Comfort_Analysis_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Ai_F_Send_Ac_Command_Args = {
  acmode?: Maybe<Scalars["Int"]>;
  acsetpoint?: Maybe<Scalars["Int"]>;
  acstatus?: Maybe<Scalars["Int"]>;
  fanspeed?: Maybe<Scalars["Int"]>;
  positionid?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Ai_V_Get_Precool_Command_Stream = {
  __typename?: "sensorflow_ai_v_get_precool_command_stream";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamp"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_V_Get_Precool_Command_Stream_Aggregate = {
  __typename?: "sensorflow_ai_v_get_precool_command_stream_aggregate";
  aggregate?: Maybe<Sensorflow_Ai_V_Get_Precool_Command_Stream_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ai_V_Get_Precool_Command_Stream>;
};

export type Sensorflow_Ai_V_Get_Precool_Command_Stream_Aggregate_Fields = {
  __typename?: "sensorflow_ai_v_get_precool_command_stream_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ai_V_Get_Precool_Command_Stream_Max_Fields>;
  min?: Maybe<Sensorflow_Ai_V_Get_Precool_Command_Stream_Min_Fields>;
};

export type Sensorflow_Ai_V_Get_Precool_Command_Stream_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ai_V_Get_Precool_Command_Stream_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ai_V_Get_Precool_Command_Stream_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Bool_Exp>>;
  ac_settings_type_changed?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  modified_by_user?: Maybe<String_Comparison_Exp>;
  new_ac_settings_value?: Maybe<String_Comparison_Exp>;
  position_id?: Maybe<Uuid_Comparison_Exp>;
  previous_ac_settings_value?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
  user_email?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Ai_V_Get_Precool_Command_Stream_Max_Fields = {
  __typename?: "sensorflow_ai_v_get_precool_command_stream_max_fields";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamp"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_V_Get_Precool_Command_Stream_Min_Fields = {
  __typename?: "sensorflow_ai_v_get_precool_command_stream_min_fields";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamp"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_V_Get_Precool_Command_Stream_Order_By = {
  ac_settings_type_changed?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  modified_by_user?: Maybe<Order_By>;
  new_ac_settings_value?: Maybe<Order_By>;
  position_id?: Maybe<Order_By>;
  previous_ac_settings_value?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  user_email?: Maybe<Order_By>;
};

export enum Sensorflow_Ai_V_Get_Precool_Command_Stream_Select_Column {
  AcSettingsTypeChanged = "ac_settings_type_changed",
  EventType = "event_type",
  ModifiedByUser = "modified_by_user",
  NewAcSettingsValue = "new_ac_settings_value",
  PositionId = "position_id",
  PreviousAcSettingsValue = "previous_ac_settings_value",
  Time = "time",
  UserEmail = "user_email",
}

export type Sensorflow_Ai_V_Get_Precool_Command_Stream_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ai_V_Get_Precool_Command_Stream_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ai_V_Get_Precool_Command_Stream_Stream_Cursor_Value_Input = {
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamp"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_V_Room_Status = {
  __typename?: "sensorflow_ai_v_room_status";
  AC_activity?: Maybe<Scalars["String"]>;
  Fan_speed?: Maybe<Scalars["String"]>;
  ac_data_status?: Maybe<Scalars["String"]>;
  ambient_temperature?: Maybe<Scalars["String"]>;
  automation_status?: Maybe<Scalars["String"]>;
  hotel_name?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  no_automation_time?: Maybe<Scalars["String"]>;
  occupancy?: Maybe<Scalars["String"]>;
  occupancy_data_status?: Maybe<Scalars["String"]>;
  overall_comfort?: Maybe<Scalars["String"]>;
  room_comfort?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["uuid"]>;
  room_number?: Maybe<Scalars["String"]>;
  rooms_in_unit?: Maybe<Scalars["bigint"]>;
  unoccupied_behaviour?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_V_Room_Status_Aggregate = {
  __typename?: "sensorflow_ai_v_room_status_aggregate";
  aggregate?: Maybe<Sensorflow_Ai_V_Room_Status_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ai_V_Room_Status>;
};

export type Sensorflow_Ai_V_Room_Status_Aggregate_Fields = {
  __typename?: "sensorflow_ai_v_room_status_aggregate_fields";
  avg?: Maybe<Sensorflow_Ai_V_Room_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ai_V_Room_Status_Max_Fields>;
  min?: Maybe<Sensorflow_Ai_V_Room_Status_Min_Fields>;
  stddev?: Maybe<Sensorflow_Ai_V_Room_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Ai_V_Room_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Ai_V_Room_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Ai_V_Room_Status_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Ai_V_Room_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Ai_V_Room_Status_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Ai_V_Room_Status_Variance_Fields>;
};

export type Sensorflow_Ai_V_Room_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ai_V_Room_Status_Avg_Fields = {
  __typename?: "sensorflow_ai_v_room_status_avg_fields";
  rooms_in_unit?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_V_Room_Status_Bool_Exp = {
  AC_activity?: Maybe<String_Comparison_Exp>;
  Fan_speed?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ai_V_Room_Status_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Bool_Exp>>;
  ac_data_status?: Maybe<String_Comparison_Exp>;
  ambient_temperature?: Maybe<String_Comparison_Exp>;
  automation_status?: Maybe<String_Comparison_Exp>;
  hotel_name?: Maybe<String_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  no_automation_time?: Maybe<String_Comparison_Exp>;
  occupancy?: Maybe<String_Comparison_Exp>;
  occupancy_data_status?: Maybe<String_Comparison_Exp>;
  overall_comfort?: Maybe<String_Comparison_Exp>;
  room_comfort?: Maybe<String_Comparison_Exp>;
  room_id?: Maybe<Uuid_Comparison_Exp>;
  room_number?: Maybe<String_Comparison_Exp>;
  rooms_in_unit?: Maybe<Bigint_Comparison_Exp>;
  unoccupied_behaviour?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Ai_V_Room_Status_Max_Fields = {
  __typename?: "sensorflow_ai_v_room_status_max_fields";
  AC_activity?: Maybe<Scalars["String"]>;
  Fan_speed?: Maybe<Scalars["String"]>;
  ac_data_status?: Maybe<Scalars["String"]>;
  ambient_temperature?: Maybe<Scalars["String"]>;
  automation_status?: Maybe<Scalars["String"]>;
  hotel_name?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  no_automation_time?: Maybe<Scalars["String"]>;
  occupancy?: Maybe<Scalars["String"]>;
  occupancy_data_status?: Maybe<Scalars["String"]>;
  overall_comfort?: Maybe<Scalars["String"]>;
  room_comfort?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["uuid"]>;
  room_number?: Maybe<Scalars["String"]>;
  rooms_in_unit?: Maybe<Scalars["bigint"]>;
  unoccupied_behaviour?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_V_Room_Status_Min_Fields = {
  __typename?: "sensorflow_ai_v_room_status_min_fields";
  AC_activity?: Maybe<Scalars["String"]>;
  Fan_speed?: Maybe<Scalars["String"]>;
  ac_data_status?: Maybe<Scalars["String"]>;
  ambient_temperature?: Maybe<Scalars["String"]>;
  automation_status?: Maybe<Scalars["String"]>;
  hotel_name?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  no_automation_time?: Maybe<Scalars["String"]>;
  occupancy?: Maybe<Scalars["String"]>;
  occupancy_data_status?: Maybe<Scalars["String"]>;
  overall_comfort?: Maybe<Scalars["String"]>;
  room_comfort?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["uuid"]>;
  room_number?: Maybe<Scalars["String"]>;
  rooms_in_unit?: Maybe<Scalars["bigint"]>;
  unoccupied_behaviour?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_V_Room_Status_Order_By = {
  AC_activity?: Maybe<Order_By>;
  Fan_speed?: Maybe<Order_By>;
  ac_data_status?: Maybe<Order_By>;
  ambient_temperature?: Maybe<Order_By>;
  automation_status?: Maybe<Order_By>;
  hotel_name?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  no_automation_time?: Maybe<Order_By>;
  occupancy?: Maybe<Order_By>;
  occupancy_data_status?: Maybe<Order_By>;
  overall_comfort?: Maybe<Order_By>;
  room_comfort?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  room_number?: Maybe<Order_By>;
  rooms_in_unit?: Maybe<Order_By>;
  unoccupied_behaviour?: Maybe<Order_By>;
};

export enum Sensorflow_Ai_V_Room_Status_Select_Column {
  AcActivity = "AC_activity",
  FanSpeed = "Fan_speed",
  AcDataStatus = "ac_data_status",
  AmbientTemperature = "ambient_temperature",
  AutomationStatus = "automation_status",
  HotelName = "hotel_name",
  LocationId = "location_id",
  NoAutomationTime = "no_automation_time",
  Occupancy = "occupancy",
  OccupancyDataStatus = "occupancy_data_status",
  OverallComfort = "overall_comfort",
  RoomComfort = "room_comfort",
  RoomId = "room_id",
  RoomNumber = "room_number",
  RoomsInUnit = "rooms_in_unit",
  UnoccupiedBehaviour = "unoccupied_behaviour",
}

export type Sensorflow_Ai_V_Room_Status_Stddev_Fields = {
  __typename?: "sensorflow_ai_v_room_status_stddev_fields";
  rooms_in_unit?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_V_Room_Status_Stddev_Pop_Fields = {
  __typename?: "sensorflow_ai_v_room_status_stddev_pop_fields";
  rooms_in_unit?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_V_Room_Status_Stddev_Samp_Fields = {
  __typename?: "sensorflow_ai_v_room_status_stddev_samp_fields";
  rooms_in_unit?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_V_Room_Status_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ai_V_Room_Status_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ai_V_Room_Status_Stream_Cursor_Value_Input = {
  AC_activity?: Maybe<Scalars["String"]>;
  Fan_speed?: Maybe<Scalars["String"]>;
  ac_data_status?: Maybe<Scalars["String"]>;
  ambient_temperature?: Maybe<Scalars["String"]>;
  automation_status?: Maybe<Scalars["String"]>;
  hotel_name?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  no_automation_time?: Maybe<Scalars["String"]>;
  occupancy?: Maybe<Scalars["String"]>;
  occupancy_data_status?: Maybe<Scalars["String"]>;
  overall_comfort?: Maybe<Scalars["String"]>;
  room_comfort?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["uuid"]>;
  room_number?: Maybe<Scalars["String"]>;
  rooms_in_unit?: Maybe<Scalars["bigint"]>;
  unoccupied_behaviour?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Ai_V_Room_Status_Sum_Fields = {
  __typename?: "sensorflow_ai_v_room_status_sum_fields";
  rooms_in_unit?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Ai_V_Room_Status_Var_Pop_Fields = {
  __typename?: "sensorflow_ai_v_room_status_var_pop_fields";
  rooms_in_unit?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_V_Room_Status_Var_Samp_Fields = {
  __typename?: "sensorflow_ai_v_room_status_var_samp_fields";
  rooms_in_unit?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ai_V_Room_Status_Variance_Fields = {
  __typename?: "sensorflow_ai_v_room_status_variance_fields";
  rooms_in_unit?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Autosets_With_Keys = {
  __typename?: "sensorflow_autosets_with_keys";
  key?: Maybe<Scalars["String"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  position_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Autosets_With_Keys_Aggregate = {
  __typename?: "sensorflow_autosets_with_keys_aggregate";
  aggregate?: Maybe<Sensorflow_Autosets_With_Keys_Aggregate_Fields>;
  nodes: Array<Sensorflow_Autosets_With_Keys>;
};

export type Sensorflow_Autosets_With_Keys_Aggregate_Fields = {
  __typename?: "sensorflow_autosets_with_keys_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Autosets_With_Keys_Max_Fields>;
  min?: Maybe<Sensorflow_Autosets_With_Keys_Min_Fields>;
};

export type Sensorflow_Autosets_With_Keys_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Autosets_With_Keys_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Autosets_With_Keys_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Autosets_With_Keys_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Autosets_With_Keys_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Autosets_With_Keys_Bool_Exp>>;
  key?: Maybe<String_Comparison_Exp>;
  key_id?: Maybe<Uuid_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  location_name?: Maybe<String_Comparison_Exp>;
  position_id?: Maybe<Uuid_Comparison_Exp>;
  position_name?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Autosets_With_Keys_Max_Fields = {
  __typename?: "sensorflow_autosets_with_keys_max_fields";
  key?: Maybe<Scalars["String"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  position_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Autosets_With_Keys_Min_Fields = {
  __typename?: "sensorflow_autosets_with_keys_min_fields";
  key?: Maybe<Scalars["String"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  position_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Autosets_With_Keys_Order_By = {
  key?: Maybe<Order_By>;
  key_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  location_name?: Maybe<Order_By>;
  position_id?: Maybe<Order_By>;
  position_name?: Maybe<Order_By>;
};

export enum Sensorflow_Autosets_With_Keys_Select_Column {
  Key = "key",
  KeyId = "key_id",
  LocationId = "location_id",
  LocationName = "location_name",
  PositionId = "position_id",
  PositionName = "position_name",
}

export type Sensorflow_Autosets_With_Keys_Stream_Cursor_Input = {
  initial_value: Sensorflow_Autosets_With_Keys_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Autosets_With_Keys_Stream_Cursor_Value_Input = {
  key?: Maybe<Scalars["String"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  position_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Avg_Position_Data = {
  __typename?: "sensorflow_avg_position_data";
  avg_data?: Maybe<Scalars["String"]>;
  data_type?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Avg_Position_Data_Aggregate = {
  __typename?: "sensorflow_avg_position_data_aggregate";
  aggregate?: Maybe<Sensorflow_Avg_Position_Data_Aggregate_Fields>;
  nodes: Array<Sensorflow_Avg_Position_Data>;
};

export type Sensorflow_Avg_Position_Data_Aggregate_Fields = {
  __typename?: "sensorflow_avg_position_data_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Avg_Position_Data_Max_Fields>;
  min?: Maybe<Sensorflow_Avg_Position_Data_Min_Fields>;
};

export type Sensorflow_Avg_Position_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Avg_Position_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Avg_Position_Data_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Avg_Position_Data_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Avg_Position_Data_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Avg_Position_Data_Bool_Exp>>;
  avg_data?: Maybe<String_Comparison_Exp>;
  data_type?: Maybe<String_Comparison_Exp>;
  position_id?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Avg_Position_Data_Max_Fields = {
  __typename?: "sensorflow_avg_position_data_max_fields";
  avg_data?: Maybe<Scalars["String"]>;
  data_type?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Avg_Position_Data_Min_Fields = {
  __typename?: "sensorflow_avg_position_data_min_fields";
  avg_data?: Maybe<Scalars["String"]>;
  data_type?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Avg_Position_Data_Order_By = {
  avg_data?: Maybe<Order_By>;
  data_type?: Maybe<Order_By>;
  position_id?: Maybe<Order_By>;
};

export enum Sensorflow_Avg_Position_Data_Select_Column {
  AvgData = "avg_data",
  DataType = "data_type",
  PositionId = "position_id",
}

export type Sensorflow_Avg_Position_Data_Stream_Cursor_Input = {
  initial_value: Sensorflow_Avg_Position_Data_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Avg_Position_Data_Stream_Cursor_Value_Input = {
  avg_data?: Maybe<Scalars["String"]>;
  data_type?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Beacon_Slots = {
  __typename?: "sensorflow_beacon_slots";
  beaconFreqIndex: Scalars["Int"];
  enableSlotGap: Scalars["Boolean"];
  id: Scalars["uuid"];
  location?: Maybe<Sensorflow_Locations>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxSlots: Scalars["Int"];
  startingSlot: Scalars["Int"];
};

export type Sensorflow_Beacon_Slots_Aggregate = {
  __typename?: "sensorflow_beacon_slots_aggregate";
  aggregate?: Maybe<Sensorflow_Beacon_Slots_Aggregate_Fields>;
  nodes: Array<Sensorflow_Beacon_Slots>;
};

export type Sensorflow_Beacon_Slots_Aggregate_Fields = {
  __typename?: "sensorflow_beacon_slots_aggregate_fields";
  avg?: Maybe<Sensorflow_Beacon_Slots_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Beacon_Slots_Max_Fields>;
  min?: Maybe<Sensorflow_Beacon_Slots_Min_Fields>;
  stddev?: Maybe<Sensorflow_Beacon_Slots_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Beacon_Slots_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Beacon_Slots_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Beacon_Slots_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Beacon_Slots_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Beacon_Slots_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Beacon_Slots_Variance_Fields>;
};

export type Sensorflow_Beacon_Slots_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Beacon_Slots_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Beacon_Slots_Avg_Fields = {
  __typename?: "sensorflow_beacon_slots_avg_fields";
  beaconFreqIndex?: Maybe<Scalars["Float"]>;
  maxSlots?: Maybe<Scalars["Float"]>;
  startingSlot?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Beacon_Slots_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Beacon_Slots_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Beacon_Slots_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Beacon_Slots_Bool_Exp>>;
  beaconFreqIndex?: Maybe<Int_Comparison_Exp>;
  enableSlotGap?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  maxSlots?: Maybe<Int_Comparison_Exp>;
  startingSlot?: Maybe<Int_Comparison_Exp>;
};

export enum Sensorflow_Beacon_Slots_Constraint {
  BeaconSlotsPkey = "beacon_slots_pkey",
}

export type Sensorflow_Beacon_Slots_Inc_Input = {
  beaconFreqIndex?: Maybe<Scalars["Int"]>;
  maxSlots?: Maybe<Scalars["Int"]>;
  startingSlot?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Beacon_Slots_Insert_Input = {
  beaconFreqIndex?: Maybe<Scalars["Int"]>;
  enableSlotGap?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxSlots?: Maybe<Scalars["Int"]>;
  startingSlot?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Beacon_Slots_Max_Fields = {
  __typename?: "sensorflow_beacon_slots_max_fields";
  beaconFreqIndex?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxSlots?: Maybe<Scalars["Int"]>;
  startingSlot?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Beacon_Slots_Min_Fields = {
  __typename?: "sensorflow_beacon_slots_min_fields";
  beaconFreqIndex?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxSlots?: Maybe<Scalars["Int"]>;
  startingSlot?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Beacon_Slots_Mutation_Response = {
  __typename?: "sensorflow_beacon_slots_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Beacon_Slots>;
};

export type Sensorflow_Beacon_Slots_Obj_Rel_Insert_Input = {
  data: Sensorflow_Beacon_Slots_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Beacon_Slots_On_Conflict>;
};

export type Sensorflow_Beacon_Slots_On_Conflict = {
  constraint: Sensorflow_Beacon_Slots_Constraint;
  update_columns: Array<Sensorflow_Beacon_Slots_Update_Column>;
  where?: Maybe<Sensorflow_Beacon_Slots_Bool_Exp>;
};

export type Sensorflow_Beacon_Slots_Order_By = {
  beaconFreqIndex?: Maybe<Order_By>;
  enableSlotGap?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  maxSlots?: Maybe<Order_By>;
  startingSlot?: Maybe<Order_By>;
};

export type Sensorflow_Beacon_Slots_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Beacon_Slots_Select_Column {
  BeaconFreqIndex = "beaconFreqIndex",
  EnableSlotGap = "enableSlotGap",
  Id = "id",
  LocationId = "locationId",
  MaxSlots = "maxSlots",
  StartingSlot = "startingSlot",
}

export type Sensorflow_Beacon_Slots_Set_Input = {
  beaconFreqIndex?: Maybe<Scalars["Int"]>;
  enableSlotGap?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxSlots?: Maybe<Scalars["Int"]>;
  startingSlot?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Beacon_Slots_Stddev_Fields = {
  __typename?: "sensorflow_beacon_slots_stddev_fields";
  beaconFreqIndex?: Maybe<Scalars["Float"]>;
  maxSlots?: Maybe<Scalars["Float"]>;
  startingSlot?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Beacon_Slots_Stddev_Pop_Fields = {
  __typename?: "sensorflow_beacon_slots_stddev_pop_fields";
  beaconFreqIndex?: Maybe<Scalars["Float"]>;
  maxSlots?: Maybe<Scalars["Float"]>;
  startingSlot?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Beacon_Slots_Stddev_Samp_Fields = {
  __typename?: "sensorflow_beacon_slots_stddev_samp_fields";
  beaconFreqIndex?: Maybe<Scalars["Float"]>;
  maxSlots?: Maybe<Scalars["Float"]>;
  startingSlot?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Beacon_Slots_Stream_Cursor_Input = {
  initial_value: Sensorflow_Beacon_Slots_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Beacon_Slots_Stream_Cursor_Value_Input = {
  beaconFreqIndex?: Maybe<Scalars["Int"]>;
  enableSlotGap?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxSlots?: Maybe<Scalars["Int"]>;
  startingSlot?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Beacon_Slots_Sum_Fields = {
  __typename?: "sensorflow_beacon_slots_sum_fields";
  beaconFreqIndex?: Maybe<Scalars["Int"]>;
  maxSlots?: Maybe<Scalars["Int"]>;
  startingSlot?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Beacon_Slots_Update_Column {
  BeaconFreqIndex = "beaconFreqIndex",
  EnableSlotGap = "enableSlotGap",
  Id = "id",
  LocationId = "locationId",
  MaxSlots = "maxSlots",
  StartingSlot = "startingSlot",
}

export type Sensorflow_Beacon_Slots_Updates = {
  _inc?: Maybe<Sensorflow_Beacon_Slots_Inc_Input>;
  _set?: Maybe<Sensorflow_Beacon_Slots_Set_Input>;
  where: Sensorflow_Beacon_Slots_Bool_Exp;
};

export type Sensorflow_Beacon_Slots_Var_Pop_Fields = {
  __typename?: "sensorflow_beacon_slots_var_pop_fields";
  beaconFreqIndex?: Maybe<Scalars["Float"]>;
  maxSlots?: Maybe<Scalars["Float"]>;
  startingSlot?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Beacon_Slots_Var_Samp_Fields = {
  __typename?: "sensorflow_beacon_slots_var_samp_fields";
  beaconFreqIndex?: Maybe<Scalars["Float"]>;
  maxSlots?: Maybe<Scalars["Float"]>;
  startingSlot?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Beacon_Slots_Variance_Fields = {
  __typename?: "sensorflow_beacon_slots_variance_fields";
  beaconFreqIndex?: Maybe<Scalars["Float"]>;
  maxSlots?: Maybe<Scalars["Float"]>;
  startingSlot?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Checklist_Items = {
  __typename?: "sensorflow_checklist_items";
  checklist_id: Scalars["String"];
  clickup_id: Scalars["String"];
  done: Scalars["Boolean"];
  title: Scalars["String"];
};

export type Sensorflow_Checklist_Items_Aggregate = {
  __typename?: "sensorflow_checklist_items_aggregate";
  aggregate?: Maybe<Sensorflow_Checklist_Items_Aggregate_Fields>;
  nodes: Array<Sensorflow_Checklist_Items>;
};

export type Sensorflow_Checklist_Items_Aggregate_Fields = {
  __typename?: "sensorflow_checklist_items_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Checklist_Items_Max_Fields>;
  min?: Maybe<Sensorflow_Checklist_Items_Min_Fields>;
};

export type Sensorflow_Checklist_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Checklist_Items_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Checklist_Items_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Checklist_Items_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Checklist_Items_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Checklist_Items_Bool_Exp>>;
  checklist_id?: Maybe<String_Comparison_Exp>;
  clickup_id?: Maybe<String_Comparison_Exp>;
  done?: Maybe<Boolean_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Checklist_Items_Insert_Input = {
  checklist_id?: Maybe<Scalars["String"]>;
  clickup_id?: Maybe<Scalars["String"]>;
  done?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Checklist_Items_Max_Fields = {
  __typename?: "sensorflow_checklist_items_max_fields";
  checklist_id?: Maybe<Scalars["String"]>;
  clickup_id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Checklist_Items_Min_Fields = {
  __typename?: "sensorflow_checklist_items_min_fields";
  checklist_id?: Maybe<Scalars["String"]>;
  clickup_id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Checklist_Items_Mutation_Response = {
  __typename?: "sensorflow_checklist_items_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Checklist_Items>;
};

export type Sensorflow_Checklist_Items_Order_By = {
  checklist_id?: Maybe<Order_By>;
  clickup_id?: Maybe<Order_By>;
  done?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

export enum Sensorflow_Checklist_Items_Select_Column {
  ChecklistId = "checklist_id",
  ClickupId = "clickup_id",
  Done = "done",
  Title = "title",
}

export type Sensorflow_Checklist_Items_Set_Input = {
  checklist_id?: Maybe<Scalars["String"]>;
  clickup_id?: Maybe<Scalars["String"]>;
  done?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Checklist_Items_Stream_Cursor_Input = {
  initial_value: Sensorflow_Checklist_Items_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Checklist_Items_Stream_Cursor_Value_Input = {
  checklist_id?: Maybe<Scalars["String"]>;
  clickup_id?: Maybe<Scalars["String"]>;
  done?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Checklist_Items_Updates = {
  _set?: Maybe<Sensorflow_Checklist_Items_Set_Input>;
  where: Sensorflow_Checklist_Items_Bool_Exp;
};

export type Sensorflow_Checklists = {
  __typename?: "sensorflow_checklists";
  clickup_id: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  task_id: Scalars["uuid"];
};

export type Sensorflow_Checklists_Aggregate = {
  __typename?: "sensorflow_checklists_aggregate";
  aggregate?: Maybe<Sensorflow_Checklists_Aggregate_Fields>;
  nodes: Array<Sensorflow_Checklists>;
};

export type Sensorflow_Checklists_Aggregate_Fields = {
  __typename?: "sensorflow_checklists_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Checklists_Max_Fields>;
  min?: Maybe<Sensorflow_Checklists_Min_Fields>;
};

export type Sensorflow_Checklists_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Checklists_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Checklists_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Checklists_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Checklists_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Checklists_Bool_Exp>>;
  clickup_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Checklists_Constraint {
  ChecklistsPkey = "checklists_pkey",
}

export type Sensorflow_Checklists_Insert_Input = {
  clickup_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Checklists_Max_Fields = {
  __typename?: "sensorflow_checklists_max_fields";
  clickup_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Checklists_Min_Fields = {
  __typename?: "sensorflow_checklists_min_fields";
  clickup_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Checklists_Mutation_Response = {
  __typename?: "sensorflow_checklists_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Checklists>;
};

export type Sensorflow_Checklists_On_Conflict = {
  constraint: Sensorflow_Checklists_Constraint;
  update_columns: Array<Sensorflow_Checklists_Update_Column>;
  where?: Maybe<Sensorflow_Checklists_Bool_Exp>;
};

export type Sensorflow_Checklists_Order_By = {
  clickup_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

export type Sensorflow_Checklists_Pk_Columns_Input = {
  id: Scalars["String"];
};

export enum Sensorflow_Checklists_Select_Column {
  ClickupId = "clickup_id",
  Id = "id",
  Name = "name",
  TaskId = "task_id",
}

export type Sensorflow_Checklists_Set_Input = {
  clickup_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Checklists_Stream_Cursor_Input = {
  initial_value: Sensorflow_Checklists_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Checklists_Stream_Cursor_Value_Input = {
  clickup_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["uuid"]>;
};

export enum Sensorflow_Checklists_Update_Column {
  ClickupId = "clickup_id",
  Id = "id",
  Name = "name",
  TaskId = "task_id",
}

export type Sensorflow_Checklists_Updates = {
  _set?: Maybe<Sensorflow_Checklists_Set_Input>;
  where: Sensorflow_Checklists_Bool_Exp;
};

export type Sensorflow_Chillers = {
  __typename?: "sensorflow_chillers";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  meterPosition?: Maybe<Sensorflow_Positions>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases: Scalars["Int"];
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
};

export type Sensorflow_Chillers_Aggregate = {
  __typename?: "sensorflow_chillers_aggregate";
  aggregate?: Maybe<Sensorflow_Chillers_Aggregate_Fields>;
  nodes: Array<Sensorflow_Chillers>;
};

export type Sensorflow_Chillers_Aggregate_Fields = {
  __typename?: "sensorflow_chillers_aggregate_fields";
  avg?: Maybe<Sensorflow_Chillers_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Chillers_Max_Fields>;
  min?: Maybe<Sensorflow_Chillers_Min_Fields>;
  stddev?: Maybe<Sensorflow_Chillers_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Chillers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Chillers_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Chillers_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Chillers_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Chillers_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Chillers_Variance_Fields>;
};

export type Sensorflow_Chillers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Chillers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Chillers_Avg_Fields = {
  __typename?: "sensorflow_chillers_avg_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Chillers_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Chillers_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Chillers_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Chillers_Bool_Exp>>;
  compressorId?: Maybe<Uuid_Comparison_Exp>;
  ctMultiplier?: Maybe<Numeric_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  meterPosition?: Maybe<Sensorflow_Positions_Bool_Exp>;
  meterPositionId?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  numPhases?: Maybe<Int_Comparison_Exp>;
  phaseStreamIndex?: Maybe<Int_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Chillers_Constraint {
  ChillersPkey = "chillers_pkey",
}

export type Sensorflow_Chillers_Inc_Input = {
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Chillers_Insert_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPosition?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Chillers_Max_Fields = {
  __typename?: "sensorflow_chillers_max_fields";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Chillers_Min_Fields = {
  __typename?: "sensorflow_chillers_min_fields";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Chillers_Mutation_Response = {
  __typename?: "sensorflow_chillers_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Chillers>;
};

export type Sensorflow_Chillers_On_Conflict = {
  constraint: Sensorflow_Chillers_Constraint;
  update_columns: Array<Sensorflow_Chillers_Update_Column>;
  where?: Maybe<Sensorflow_Chillers_Bool_Exp>;
};

export type Sensorflow_Chillers_Order_By = {
  compressorId?: Maybe<Order_By>;
  ctMultiplier?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meterPosition?: Maybe<Sensorflow_Positions_Order_By>;
  meterPositionId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  numPhases?: Maybe<Order_By>;
  phaseStreamIndex?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
};

export type Sensorflow_Chillers_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Chillers_Select_Column {
  CompressorId = "compressorId",
  CtMultiplier = "ctMultiplier",
  DeletedAt = "deleted_at",
  Id = "id",
  MeterPositionId = "meterPositionId",
  Name = "name",
  NumPhases = "numPhases",
  PhaseStreamIndex = "phaseStreamIndex",
  PositionId = "positionId",
}

export type Sensorflow_Chillers_Set_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Chillers_Stddev_Fields = {
  __typename?: "sensorflow_chillers_stddev_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Chillers_Stddev_Pop_Fields = {
  __typename?: "sensorflow_chillers_stddev_pop_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Chillers_Stddev_Samp_Fields = {
  __typename?: "sensorflow_chillers_stddev_samp_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Chillers_Stream_Cursor_Input = {
  initial_value: Sensorflow_Chillers_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Chillers_Stream_Cursor_Value_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Chillers_Sum_Fields = {
  __typename?: "sensorflow_chillers_sum_fields";
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Chillers_Update_Column {
  CompressorId = "compressorId",
  CtMultiplier = "ctMultiplier",
  DeletedAt = "deleted_at",
  Id = "id",
  MeterPositionId = "meterPositionId",
  Name = "name",
  NumPhases = "numPhases",
  PhaseStreamIndex = "phaseStreamIndex",
  PositionId = "positionId",
}

export type Sensorflow_Chillers_Updates = {
  _inc?: Maybe<Sensorflow_Chillers_Inc_Input>;
  _set?: Maybe<Sensorflow_Chillers_Set_Input>;
  where: Sensorflow_Chillers_Bool_Exp;
};

export type Sensorflow_Chillers_Var_Pop_Fields = {
  __typename?: "sensorflow_chillers_var_pop_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Chillers_Var_Samp_Fields = {
  __typename?: "sensorflow_chillers_var_samp_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Chillers_Variance_Fields = {
  __typename?: "sensorflow_chillers_variance_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Clickup_Webhook_Events = {
  __typename?: "sensorflow_clickup_webhook_events";
  being_processed: Scalars["Boolean"];
  current_retry_count: Scalars["Int"];
  event_id?: Maybe<Scalars["bigint"]>;
  event_seq_no?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  list_id?: Maybe<Scalars["String"]>;
  processed_at?: Maybe<Scalars["timestamptz"]>;
  received_at: Scalars["timestamptz"];
  req_payload: Scalars["json"];
  status: Scalars["String"];
  task_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Clickup_Webhook_EventsReq_PayloadArgs = {
  path?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Aggregate = {
  __typename?: "sensorflow_clickup_webhook_events_aggregate";
  aggregate?: Maybe<Sensorflow_Clickup_Webhook_Events_Aggregate_Fields>;
  nodes: Array<Sensorflow_Clickup_Webhook_Events>;
};

export type Sensorflow_Clickup_Webhook_Events_Aggregate_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_aggregate_fields";
  avg?: Maybe<Sensorflow_Clickup_Webhook_Events_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Clickup_Webhook_Events_Max_Fields>;
  min?: Maybe<Sensorflow_Clickup_Webhook_Events_Min_Fields>;
  stddev?: Maybe<Sensorflow_Clickup_Webhook_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Clickup_Webhook_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Clickup_Webhook_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Clickup_Webhook_Events_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Clickup_Webhook_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Clickup_Webhook_Events_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Clickup_Webhook_Events_Variance_Fields>;
};

export type Sensorflow_Clickup_Webhook_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Avg_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_avg_fields";
  current_retry_count?: Maybe<Scalars["Float"]>;
  event_id?: Maybe<Scalars["Float"]>;
  event_seq_no?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Clickup_Webhook_Events_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Bool_Exp>>;
  being_processed?: Maybe<Boolean_Comparison_Exp>;
  current_retry_count?: Maybe<Int_Comparison_Exp>;
  event_id?: Maybe<Bigint_Comparison_Exp>;
  event_seq_no?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  list_id?: Maybe<String_Comparison_Exp>;
  processed_at?: Maybe<Timestamptz_Comparison_Exp>;
  received_at?: Maybe<Timestamptz_Comparison_Exp>;
  req_payload?: Maybe<Json_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  task_id?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Clickup_Webhook_Events_Constraint {
  ClickupWebhookEventsEventIdKey = "clickup_webhook_events_event_id_key",
  ClickupWebhookEventsPkey = "clickup_webhook_events_pkey",
}

export type Sensorflow_Clickup_Webhook_Events_Inc_Input = {
  current_retry_count?: Maybe<Scalars["Int"]>;
  event_id?: Maybe<Scalars["bigint"]>;
  event_seq_no?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Insert_Input = {
  being_processed?: Maybe<Scalars["Boolean"]>;
  current_retry_count?: Maybe<Scalars["Int"]>;
  event_id?: Maybe<Scalars["bigint"]>;
  event_seq_no?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  list_id?: Maybe<Scalars["String"]>;
  processed_at?: Maybe<Scalars["timestamptz"]>;
  received_at?: Maybe<Scalars["timestamptz"]>;
  req_payload?: Maybe<Scalars["json"]>;
  status?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Max_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_max_fields";
  current_retry_count?: Maybe<Scalars["Int"]>;
  event_id?: Maybe<Scalars["bigint"]>;
  event_seq_no?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  list_id?: Maybe<Scalars["String"]>;
  processed_at?: Maybe<Scalars["timestamptz"]>;
  received_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Min_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_min_fields";
  current_retry_count?: Maybe<Scalars["Int"]>;
  event_id?: Maybe<Scalars["bigint"]>;
  event_seq_no?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  list_id?: Maybe<Scalars["String"]>;
  processed_at?: Maybe<Scalars["timestamptz"]>;
  received_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Mutation_Response = {
  __typename?: "sensorflow_clickup_webhook_events_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Clickup_Webhook_Events>;
};

export type Sensorflow_Clickup_Webhook_Events_On_Conflict = {
  constraint: Sensorflow_Clickup_Webhook_Events_Constraint;
  update_columns: Array<Sensorflow_Clickup_Webhook_Events_Update_Column>;
  where?: Maybe<Sensorflow_Clickup_Webhook_Events_Bool_Exp>;
};

export type Sensorflow_Clickup_Webhook_Events_Order_By = {
  being_processed?: Maybe<Order_By>;
  current_retry_count?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  event_seq_no?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  list_id?: Maybe<Order_By>;
  processed_at?: Maybe<Order_By>;
  received_at?: Maybe<Order_By>;
  req_payload?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
};

export type Sensorflow_Clickup_Webhook_Events_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Clickup_Webhook_Events_Select_Column {
  BeingProcessed = "being_processed",
  CurrentRetryCount = "current_retry_count",
  EventId = "event_id",
  EventSeqNo = "event_seq_no",
  Id = "id",
  ListId = "list_id",
  ProcessedAt = "processed_at",
  ReceivedAt = "received_at",
  ReqPayload = "req_payload",
  Status = "status",
  TaskId = "task_id",
}

export type Sensorflow_Clickup_Webhook_Events_Set_Input = {
  being_processed?: Maybe<Scalars["Boolean"]>;
  current_retry_count?: Maybe<Scalars["Int"]>;
  event_id?: Maybe<Scalars["bigint"]>;
  event_seq_no?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  list_id?: Maybe<Scalars["String"]>;
  processed_at?: Maybe<Scalars["timestamptz"]>;
  received_at?: Maybe<Scalars["timestamptz"]>;
  req_payload?: Maybe<Scalars["json"]>;
  status?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Stddev_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_stddev_fields";
  current_retry_count?: Maybe<Scalars["Float"]>;
  event_id?: Maybe<Scalars["Float"]>;
  event_seq_no?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Stddev_Pop_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_stddev_pop_fields";
  current_retry_count?: Maybe<Scalars["Float"]>;
  event_id?: Maybe<Scalars["Float"]>;
  event_seq_no?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Stddev_Samp_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_stddev_samp_fields";
  current_retry_count?: Maybe<Scalars["Float"]>;
  event_id?: Maybe<Scalars["Float"]>;
  event_seq_no?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Stream_Cursor_Input = {
  initial_value: Sensorflow_Clickup_Webhook_Events_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Clickup_Webhook_Events_Stream_Cursor_Value_Input = {
  being_processed?: Maybe<Scalars["Boolean"]>;
  current_retry_count?: Maybe<Scalars["Int"]>;
  event_id?: Maybe<Scalars["bigint"]>;
  event_seq_no?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  list_id?: Maybe<Scalars["String"]>;
  processed_at?: Maybe<Scalars["timestamptz"]>;
  received_at?: Maybe<Scalars["timestamptz"]>;
  req_payload?: Maybe<Scalars["json"]>;
  status?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Sum_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_sum_fields";
  current_retry_count?: Maybe<Scalars["Int"]>;
  event_id?: Maybe<Scalars["bigint"]>;
  event_seq_no?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Clickup_Webhook_Events_Update_Column {
  BeingProcessed = "being_processed",
  CurrentRetryCount = "current_retry_count",
  EventId = "event_id",
  EventSeqNo = "event_seq_no",
  Id = "id",
  ListId = "list_id",
  ProcessedAt = "processed_at",
  ReceivedAt = "received_at",
  ReqPayload = "req_payload",
  Status = "status",
  TaskId = "task_id",
}

export type Sensorflow_Clickup_Webhook_Events_Updates = {
  _inc?: Maybe<Sensorflow_Clickup_Webhook_Events_Inc_Input>;
  _set?: Maybe<Sensorflow_Clickup_Webhook_Events_Set_Input>;
  where: Sensorflow_Clickup_Webhook_Events_Bool_Exp;
};

export type Sensorflow_Clickup_Webhook_Events_Var_Pop_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_var_pop_fields";
  current_retry_count?: Maybe<Scalars["Float"]>;
  event_id?: Maybe<Scalars["Float"]>;
  event_seq_no?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Var_Samp_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_var_samp_fields";
  current_retry_count?: Maybe<Scalars["Float"]>;
  event_id?: Maybe<Scalars["Float"]>;
  event_seq_no?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Clickup_Webhook_Events_Variance_Fields = {
  __typename?: "sensorflow_clickup_webhook_events_variance_fields";
  current_retry_count?: Maybe<Scalars["Float"]>;
  event_id?: Maybe<Scalars["Float"]>;
  event_seq_no?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Comfortplus_Activations = {
  __typename?: "sensorflow_comfortplus_activations";
  dailyPrecoolLimitResetAt?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  precoolDailyLimit: Scalars["numeric"];
  reservation: Sensorflow_Reservations;
  reservationId: Scalars["bigint"];
};

export type Sensorflow_Comfortplus_Activations_Aggregate = {
  __typename?: "sensorflow_comfortplus_activations_aggregate";
  aggregate?: Maybe<Sensorflow_Comfortplus_Activations_Aggregate_Fields>;
  nodes: Array<Sensorflow_Comfortplus_Activations>;
};

export type Sensorflow_Comfortplus_Activations_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Comfortplus_Activations_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Comfortplus_Activations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Comfortplus_Activations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Comfortplus_Activations_Aggregate_Fields = {
  __typename?: "sensorflow_comfortplus_activations_aggregate_fields";
  avg?: Maybe<Sensorflow_Comfortplus_Activations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Comfortplus_Activations_Max_Fields>;
  min?: Maybe<Sensorflow_Comfortplus_Activations_Min_Fields>;
  stddev?: Maybe<Sensorflow_Comfortplus_Activations_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Comfortplus_Activations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Comfortplus_Activations_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Comfortplus_Activations_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Comfortplus_Activations_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Comfortplus_Activations_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Comfortplus_Activations_Variance_Fields>;
};

export type Sensorflow_Comfortplus_Activations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Comfortplus_Activations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Comfortplus_Activations_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Comfortplus_Activations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Comfortplus_Activations_Max_Order_By>;
  min?: Maybe<Sensorflow_Comfortplus_Activations_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Comfortplus_Activations_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Comfortplus_Activations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Comfortplus_Activations_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Comfortplus_Activations_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Comfortplus_Activations_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Comfortplus_Activations_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Comfortplus_Activations_Variance_Order_By>;
};

export type Sensorflow_Comfortplus_Activations_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Comfortplus_Activations_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Comfortplus_Activations_On_Conflict>;
};

export type Sensorflow_Comfortplus_Activations_Avg_Fields = {
  __typename?: "sensorflow_comfortplus_activations_avg_fields";
  precoolDailyLimit?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Comfortplus_Activations_Avg_Order_By = {
  precoolDailyLimit?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Comfortplus_Activations_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Comfortplus_Activations_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Comfortplus_Activations_Bool_Exp>>;
  dailyPrecoolLimitResetAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  precoolDailyLimit?: Maybe<Numeric_Comparison_Exp>;
  reservation?: Maybe<Sensorflow_Reservations_Bool_Exp>;
  reservationId?: Maybe<Bigint_Comparison_Exp>;
};

export enum Sensorflow_Comfortplus_Activations_Constraint {
  ComfortplusActivationsPkey = "comfortplus_activations_pkey",
  ComfortplusActivationsReservationIdKey = "comfortplus_activations_reservation_id_key",
}

export type Sensorflow_Comfortplus_Activations_Inc_Input = {
  precoolDailyLimit?: Maybe<Scalars["numeric"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Comfortplus_Activations_Insert_Input = {
  dailyPrecoolLimitResetAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  precoolDailyLimit?: Maybe<Scalars["numeric"]>;
  reservation?: Maybe<Sensorflow_Reservations_Obj_Rel_Insert_Input>;
  reservationId?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Comfortplus_Activations_Max_Fields = {
  __typename?: "sensorflow_comfortplus_activations_max_fields";
  dailyPrecoolLimitResetAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  precoolDailyLimit?: Maybe<Scalars["numeric"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Comfortplus_Activations_Max_Order_By = {
  dailyPrecoolLimitResetAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  precoolDailyLimit?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Comfortplus_Activations_Min_Fields = {
  __typename?: "sensorflow_comfortplus_activations_min_fields";
  dailyPrecoolLimitResetAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  precoolDailyLimit?: Maybe<Scalars["numeric"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Comfortplus_Activations_Min_Order_By = {
  dailyPrecoolLimitResetAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  precoolDailyLimit?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Comfortplus_Activations_Mutation_Response = {
  __typename?: "sensorflow_comfortplus_activations_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Comfortplus_Activations>;
};

export type Sensorflow_Comfortplus_Activations_On_Conflict = {
  constraint: Sensorflow_Comfortplus_Activations_Constraint;
  update_columns: Array<Sensorflow_Comfortplus_Activations_Update_Column>;
  where?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
};

export type Sensorflow_Comfortplus_Activations_Order_By = {
  dailyPrecoolLimitResetAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  precoolDailyLimit?: Maybe<Order_By>;
  reservation?: Maybe<Sensorflow_Reservations_Order_By>;
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Comfortplus_Activations_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Comfortplus_Activations_Select_Column {
  DailyPrecoolLimitResetAt = "dailyPrecoolLimitResetAt",
  Id = "id",
  PrecoolDailyLimit = "precoolDailyLimit",
  ReservationId = "reservationId",
}

export type Sensorflow_Comfortplus_Activations_Set_Input = {
  dailyPrecoolLimitResetAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  precoolDailyLimit?: Maybe<Scalars["numeric"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Comfortplus_Activations_Stddev_Fields = {
  __typename?: "sensorflow_comfortplus_activations_stddev_fields";
  precoolDailyLimit?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Comfortplus_Activations_Stddev_Order_By = {
  precoolDailyLimit?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Comfortplus_Activations_Stddev_Pop_Fields = {
  __typename?: "sensorflow_comfortplus_activations_stddev_pop_fields";
  precoolDailyLimit?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Comfortplus_Activations_Stddev_Pop_Order_By = {
  precoolDailyLimit?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Comfortplus_Activations_Stddev_Samp_Fields = {
  __typename?: "sensorflow_comfortplus_activations_stddev_samp_fields";
  precoolDailyLimit?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Comfortplus_Activations_Stddev_Samp_Order_By = {
  precoolDailyLimit?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Comfortplus_Activations_Stream_Cursor_Input = {
  initial_value: Sensorflow_Comfortplus_Activations_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Comfortplus_Activations_Stream_Cursor_Value_Input = {
  dailyPrecoolLimitResetAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  precoolDailyLimit?: Maybe<Scalars["numeric"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Comfortplus_Activations_Sum_Fields = {
  __typename?: "sensorflow_comfortplus_activations_sum_fields";
  precoolDailyLimit?: Maybe<Scalars["numeric"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Comfortplus_Activations_Sum_Order_By = {
  precoolDailyLimit?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
};

export enum Sensorflow_Comfortplus_Activations_Update_Column {
  DailyPrecoolLimitResetAt = "dailyPrecoolLimitResetAt",
  Id = "id",
  PrecoolDailyLimit = "precoolDailyLimit",
  ReservationId = "reservationId",
}

export type Sensorflow_Comfortplus_Activations_Updates = {
  _inc?: Maybe<Sensorflow_Comfortplus_Activations_Inc_Input>;
  _set?: Maybe<Sensorflow_Comfortplus_Activations_Set_Input>;
  where: Sensorflow_Comfortplus_Activations_Bool_Exp;
};

export type Sensorflow_Comfortplus_Activations_Var_Pop_Fields = {
  __typename?: "sensorflow_comfortplus_activations_var_pop_fields";
  precoolDailyLimit?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Comfortplus_Activations_Var_Pop_Order_By = {
  precoolDailyLimit?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Comfortplus_Activations_Var_Samp_Fields = {
  __typename?: "sensorflow_comfortplus_activations_var_samp_fields";
  precoolDailyLimit?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Comfortplus_Activations_Var_Samp_Order_By = {
  precoolDailyLimit?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Comfortplus_Activations_Variance_Fields = {
  __typename?: "sensorflow_comfortplus_activations_variance_fields";
  precoolDailyLimit?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Comfortplus_Activations_Variance_Order_By = {
  precoolDailyLimit?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Compressor_Mapping = {
  __typename?: "sensorflow_compressor_mapping";
  compressor: Sensorflow_Compressors;
  compressorId: Scalars["uuid"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
};

export type Sensorflow_Compressor_Mapping_Aggregate = {
  __typename?: "sensorflow_compressor_mapping_aggregate";
  aggregate?: Maybe<Sensorflow_Compressor_Mapping_Aggregate_Fields>;
  nodes: Array<Sensorflow_Compressor_Mapping>;
};

export type Sensorflow_Compressor_Mapping_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Compressor_Mapping_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Compressor_Mapping_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Compressor_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Compressor_Mapping_Aggregate_Fields = {
  __typename?: "sensorflow_compressor_mapping_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Compressor_Mapping_Max_Fields>;
  min?: Maybe<Sensorflow_Compressor_Mapping_Min_Fields>;
};

export type Sensorflow_Compressor_Mapping_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Compressor_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Compressor_Mapping_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Compressor_Mapping_Max_Order_By>;
  min?: Maybe<Sensorflow_Compressor_Mapping_Min_Order_By>;
};

export type Sensorflow_Compressor_Mapping_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Compressor_Mapping_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Compressor_Mapping_On_Conflict>;
};

export type Sensorflow_Compressor_Mapping_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Compressor_Mapping_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Compressor_Mapping_Bool_Exp>>;
  compressor?: Maybe<Sensorflow_Compressors_Bool_Exp>;
  compressorId?: Maybe<Uuid_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Compressor_Mapping_Constraint {
  CompressorMappingPkey = "compressor_mapping_pkey",
}

export type Sensorflow_Compressor_Mapping_Insert_Input = {
  compressor?: Maybe<Sensorflow_Compressors_Obj_Rel_Insert_Input>;
  compressorId?: Maybe<Scalars["uuid"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Compressor_Mapping_Max_Fields = {
  __typename?: "sensorflow_compressor_mapping_max_fields";
  compressorId?: Maybe<Scalars["uuid"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Compressor_Mapping_Max_Order_By = {
  compressorId?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
};

export type Sensorflow_Compressor_Mapping_Min_Fields = {
  __typename?: "sensorflow_compressor_mapping_min_fields";
  compressorId?: Maybe<Scalars["uuid"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Compressor_Mapping_Min_Order_By = {
  compressorId?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
};

export type Sensorflow_Compressor_Mapping_Mutation_Response = {
  __typename?: "sensorflow_compressor_mapping_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Compressor_Mapping>;
};

export type Sensorflow_Compressor_Mapping_On_Conflict = {
  constraint: Sensorflow_Compressor_Mapping_Constraint;
  update_columns: Array<Sensorflow_Compressor_Mapping_Update_Column>;
  where?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
};

export type Sensorflow_Compressor_Mapping_Order_By = {
  compressor?: Maybe<Sensorflow_Compressors_Order_By>;
  compressorId?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
};

export type Sensorflow_Compressor_Mapping_Pk_Columns_Input = {
  compressorId: Scalars["uuid"];
  positionId: Scalars["uuid"];
};

export enum Sensorflow_Compressor_Mapping_Select_Column {
  CompressorId = "compressorId",
  DeletedAt = "deleted_at",
  PositionId = "positionId",
}

export type Sensorflow_Compressor_Mapping_Set_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Compressor_Mapping_Stream_Cursor_Input = {
  initial_value: Sensorflow_Compressor_Mapping_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Compressor_Mapping_Stream_Cursor_Value_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export enum Sensorflow_Compressor_Mapping_Update_Column {
  CompressorId = "compressorId",
  DeletedAt = "deleted_at",
  PositionId = "positionId",
}

export type Sensorflow_Compressor_Mapping_Updates = {
  _set?: Maybe<Sensorflow_Compressor_Mapping_Set_Input>;
  where: Sensorflow_Compressor_Mapping_Bool_Exp;
};

export type Sensorflow_Compressors = {
  __typename?: "sensorflow_compressors";
  clickupTaskId?: Maybe<Scalars["String"]>;
  compressorId: Scalars["uuid"];
  compressorMeterPositionId?: Maybe<Scalars["uuid"]>;
  compressorName: Scalars["String"];
  compressorPositionId: Scalars["uuid"];
  compressorPositionMappings: Array<Sensorflow_Compressor_Mapping>;
  compressorPositionMappings_aggregate: Sensorflow_Compressor_Mapping_Aggregate;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  meterPosition?: Maybe<Sensorflow_Positions>;
  numberOfPhases: Scalars["Int"];
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  position: Sensorflow_Positions;
};

export type Sensorflow_CompressorsCompressorPositionMappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressor_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressor_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
};

export type Sensorflow_CompressorsCompressorPositionMappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressor_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressor_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
};

export type Sensorflow_Compressors_Aggregate = {
  __typename?: "sensorflow_compressors_aggregate";
  aggregate?: Maybe<Sensorflow_Compressors_Aggregate_Fields>;
  nodes: Array<Sensorflow_Compressors>;
};

export type Sensorflow_Compressors_Aggregate_Fields = {
  __typename?: "sensorflow_compressors_aggregate_fields";
  avg?: Maybe<Sensorflow_Compressors_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Compressors_Max_Fields>;
  min?: Maybe<Sensorflow_Compressors_Min_Fields>;
  stddev?: Maybe<Sensorflow_Compressors_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Compressors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Compressors_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Compressors_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Compressors_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Compressors_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Compressors_Variance_Fields>;
};

export type Sensorflow_Compressors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Compressors_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Compressors_Avg_Fields = {
  __typename?: "sensorflow_compressors_avg_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numberOfPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Compressors_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Compressors_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Compressors_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Compressors_Bool_Exp>>;
  clickupTaskId?: Maybe<String_Comparison_Exp>;
  compressorId?: Maybe<Uuid_Comparison_Exp>;
  compressorMeterPositionId?: Maybe<Uuid_Comparison_Exp>;
  compressorName?: Maybe<String_Comparison_Exp>;
  compressorPositionId?: Maybe<Uuid_Comparison_Exp>;
  compressorPositionMappings?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
  compressorPositionMappings_aggregate?: Maybe<Sensorflow_Compressor_Mapping_Aggregate_Bool_Exp>;
  ctMultiplier?: Maybe<Numeric_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  hvacSystemType?: Maybe<String_Comparison_Exp>;
  meterPosition?: Maybe<Sensorflow_Positions_Bool_Exp>;
  numberOfPhases?: Maybe<Int_Comparison_Exp>;
  phaseStreamIndex?: Maybe<Int_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export enum Sensorflow_Compressors_Constraint {
  CompressorsPkey = "compressors_pkey",
}

export type Sensorflow_Compressors_Inc_Input = {
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  numberOfPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Compressors_Insert_Input = {
  clickupTaskId?: Maybe<Scalars["String"]>;
  compressorId?: Maybe<Scalars["uuid"]>;
  compressorMeterPositionId?: Maybe<Scalars["uuid"]>;
  compressorName?: Maybe<Scalars["String"]>;
  compressorPositionId?: Maybe<Scalars["uuid"]>;
  compressorPositionMappings?: Maybe<Sensorflow_Compressor_Mapping_Arr_Rel_Insert_Input>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  meterPosition?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  numberOfPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
};

export type Sensorflow_Compressors_Max_Fields = {
  __typename?: "sensorflow_compressors_max_fields";
  clickupTaskId?: Maybe<Scalars["String"]>;
  compressorId?: Maybe<Scalars["uuid"]>;
  compressorMeterPositionId?: Maybe<Scalars["uuid"]>;
  compressorName?: Maybe<Scalars["String"]>;
  compressorPositionId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  numberOfPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Compressors_Min_Fields = {
  __typename?: "sensorflow_compressors_min_fields";
  clickupTaskId?: Maybe<Scalars["String"]>;
  compressorId?: Maybe<Scalars["uuid"]>;
  compressorMeterPositionId?: Maybe<Scalars["uuid"]>;
  compressorName?: Maybe<Scalars["String"]>;
  compressorPositionId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  numberOfPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Compressors_Mutation_Response = {
  __typename?: "sensorflow_compressors_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Compressors>;
};

export type Sensorflow_Compressors_Obj_Rel_Insert_Input = {
  data: Sensorflow_Compressors_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Compressors_On_Conflict>;
};

export type Sensorflow_Compressors_On_Conflict = {
  constraint: Sensorflow_Compressors_Constraint;
  update_columns: Array<Sensorflow_Compressors_Update_Column>;
  where?: Maybe<Sensorflow_Compressors_Bool_Exp>;
};

export type Sensorflow_Compressors_Order_By = {
  clickupTaskId?: Maybe<Order_By>;
  compressorId?: Maybe<Order_By>;
  compressorMeterPositionId?: Maybe<Order_By>;
  compressorName?: Maybe<Order_By>;
  compressorPositionId?: Maybe<Order_By>;
  compressorPositionMappings_aggregate?: Maybe<Sensorflow_Compressor_Mapping_Aggregate_Order_By>;
  ctMultiplier?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  hvacSystemType?: Maybe<Order_By>;
  meterPosition?: Maybe<Sensorflow_Positions_Order_By>;
  numberOfPhases?: Maybe<Order_By>;
  phaseStreamIndex?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
};

export type Sensorflow_Compressors_Pk_Columns_Input = {
  compressorId: Scalars["uuid"];
};

export enum Sensorflow_Compressors_Select_Column {
  ClickupTaskId = "clickupTaskId",
  CompressorId = "compressorId",
  CompressorMeterPositionId = "compressorMeterPositionId",
  CompressorName = "compressorName",
  CompressorPositionId = "compressorPositionId",
  CtMultiplier = "ctMultiplier",
  DeletedAt = "deleted_at",
  HvacSystemType = "hvacSystemType",
  NumberOfPhases = "numberOfPhases",
  PhaseStreamIndex = "phaseStreamIndex",
}

export type Sensorflow_Compressors_Set_Input = {
  clickupTaskId?: Maybe<Scalars["String"]>;
  compressorId?: Maybe<Scalars["uuid"]>;
  compressorMeterPositionId?: Maybe<Scalars["uuid"]>;
  compressorName?: Maybe<Scalars["String"]>;
  compressorPositionId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  numberOfPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Compressors_Stddev_Fields = {
  __typename?: "sensorflow_compressors_stddev_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numberOfPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Compressors_Stddev_Pop_Fields = {
  __typename?: "sensorflow_compressors_stddev_pop_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numberOfPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Compressors_Stddev_Samp_Fields = {
  __typename?: "sensorflow_compressors_stddev_samp_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numberOfPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Compressors_Stream_Cursor_Input = {
  initial_value: Sensorflow_Compressors_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Compressors_Stream_Cursor_Value_Input = {
  clickupTaskId?: Maybe<Scalars["String"]>;
  compressorId?: Maybe<Scalars["uuid"]>;
  compressorMeterPositionId?: Maybe<Scalars["uuid"]>;
  compressorName?: Maybe<Scalars["String"]>;
  compressorPositionId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  numberOfPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Compressors_Sum_Fields = {
  __typename?: "sensorflow_compressors_sum_fields";
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  numberOfPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Compressors_Update_Column {
  ClickupTaskId = "clickupTaskId",
  CompressorId = "compressorId",
  CompressorMeterPositionId = "compressorMeterPositionId",
  CompressorName = "compressorName",
  CompressorPositionId = "compressorPositionId",
  CtMultiplier = "ctMultiplier",
  DeletedAt = "deleted_at",
  HvacSystemType = "hvacSystemType",
  NumberOfPhases = "numberOfPhases",
  PhaseStreamIndex = "phaseStreamIndex",
}

export type Sensorflow_Compressors_Updates = {
  _inc?: Maybe<Sensorflow_Compressors_Inc_Input>;
  _set?: Maybe<Sensorflow_Compressors_Set_Input>;
  where: Sensorflow_Compressors_Bool_Exp;
};

export type Sensorflow_Compressors_Var_Pop_Fields = {
  __typename?: "sensorflow_compressors_var_pop_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numberOfPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Compressors_Var_Samp_Fields = {
  __typename?: "sensorflow_compressors_var_samp_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numberOfPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Compressors_Variance_Fields = {
  __typename?: "sensorflow_compressors_variance_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numberOfPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Config_Event_Stream = {
  __typename?: "sensorflow_config_event_stream";
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Config_Event_Stream_Aggregate = {
  __typename?: "sensorflow_config_event_stream_aggregate";
  aggregate?: Maybe<Sensorflow_Config_Event_Stream_Aggregate_Fields>;
  nodes: Array<Sensorflow_Config_Event_Stream>;
};

export type Sensorflow_Config_Event_Stream_Aggregate_Fields = {
  __typename?: "sensorflow_config_event_stream_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Config_Event_Stream_Max_Fields>;
  min?: Maybe<Sensorflow_Config_Event_Stream_Min_Fields>;
};

export type Sensorflow_Config_Event_Stream_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Config_Event_Stream_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Config_Event_Stream_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Config_Event_Stream_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Config_Event_Stream_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Config_Event_Stream_Bool_Exp>>;
  config_type_changed?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  modified_by_user?: Maybe<String_Comparison_Exp>;
  new_config_value?: Maybe<String_Comparison_Exp>;
  previous_config_value?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
  user_email?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Config_Event_Stream_Insert_Input = {
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Config_Event_Stream_Max_Fields = {
  __typename?: "sensorflow_config_event_stream_max_fields";
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Config_Event_Stream_Min_Fields = {
  __typename?: "sensorflow_config_event_stream_min_fields";
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Config_Event_Stream_Mutation_Response = {
  __typename?: "sensorflow_config_event_stream_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Config_Event_Stream>;
};

export type Sensorflow_Config_Event_Stream_Order_By = {
  config_type_changed?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  modified_by_user?: Maybe<Order_By>;
  new_config_value?: Maybe<Order_By>;
  previous_config_value?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  user_email?: Maybe<Order_By>;
};

export enum Sensorflow_Config_Event_Stream_Select_Column {
  ConfigTypeChanged = "config_type_changed",
  EventType = "event_type",
  ModifiedByUser = "modified_by_user",
  NewConfigValue = "new_config_value",
  PreviousConfigValue = "previous_config_value",
  Time = "time",
  UserEmail = "user_email",
}

export type Sensorflow_Config_Event_Stream_Set_Input = {
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Config_Event_Stream_Stream_Cursor_Input = {
  initial_value: Sensorflow_Config_Event_Stream_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Config_Event_Stream_Stream_Cursor_Value_Input = {
  config_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_config_value?: Maybe<Scalars["String"]>;
  previous_config_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Config_Event_Stream_Updates = {
  _set?: Maybe<Sensorflow_Config_Event_Stream_Set_Input>;
  where: Sensorflow_Config_Event_Stream_Bool_Exp;
};

export type Sensorflow_Cooling_Towers = {
  __typename?: "sensorflow_cooling_towers";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  meterPosition?: Maybe<Sensorflow_Positions>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases: Scalars["Int"];
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
};

export type Sensorflow_Cooling_Towers_Aggregate = {
  __typename?: "sensorflow_cooling_towers_aggregate";
  aggregate?: Maybe<Sensorflow_Cooling_Towers_Aggregate_Fields>;
  nodes: Array<Sensorflow_Cooling_Towers>;
};

export type Sensorflow_Cooling_Towers_Aggregate_Fields = {
  __typename?: "sensorflow_cooling_towers_aggregate_fields";
  avg?: Maybe<Sensorflow_Cooling_Towers_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Cooling_Towers_Max_Fields>;
  min?: Maybe<Sensorflow_Cooling_Towers_Min_Fields>;
  stddev?: Maybe<Sensorflow_Cooling_Towers_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Cooling_Towers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Cooling_Towers_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Cooling_Towers_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Cooling_Towers_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Cooling_Towers_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Cooling_Towers_Variance_Fields>;
};

export type Sensorflow_Cooling_Towers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Cooling_Towers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Cooling_Towers_Avg_Fields = {
  __typename?: "sensorflow_cooling_towers_avg_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Cooling_Towers_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Cooling_Towers_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Cooling_Towers_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Cooling_Towers_Bool_Exp>>;
  compressorId?: Maybe<Uuid_Comparison_Exp>;
  ctMultiplier?: Maybe<Numeric_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  meterPosition?: Maybe<Sensorflow_Positions_Bool_Exp>;
  meterPositionId?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  numPhases?: Maybe<Int_Comparison_Exp>;
  phaseStreamIndex?: Maybe<Int_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Cooling_Towers_Constraint {
  CoolingTowersPkey = "cooling_towers_pkey",
}

export type Sensorflow_Cooling_Towers_Inc_Input = {
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Cooling_Towers_Insert_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPosition?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Cooling_Towers_Max_Fields = {
  __typename?: "sensorflow_cooling_towers_max_fields";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Cooling_Towers_Min_Fields = {
  __typename?: "sensorflow_cooling_towers_min_fields";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Cooling_Towers_Mutation_Response = {
  __typename?: "sensorflow_cooling_towers_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Cooling_Towers>;
};

export type Sensorflow_Cooling_Towers_On_Conflict = {
  constraint: Sensorflow_Cooling_Towers_Constraint;
  update_columns: Array<Sensorflow_Cooling_Towers_Update_Column>;
  where?: Maybe<Sensorflow_Cooling_Towers_Bool_Exp>;
};

export type Sensorflow_Cooling_Towers_Order_By = {
  compressorId?: Maybe<Order_By>;
  ctMultiplier?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meterPosition?: Maybe<Sensorflow_Positions_Order_By>;
  meterPositionId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  numPhases?: Maybe<Order_By>;
  phaseStreamIndex?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
};

export type Sensorflow_Cooling_Towers_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Cooling_Towers_Select_Column {
  CompressorId = "compressorId",
  CtMultiplier = "ctMultiplier",
  DeletedAt = "deleted_at",
  Id = "id",
  MeterPositionId = "meterPositionId",
  Name = "name",
  NumPhases = "numPhases",
  PhaseStreamIndex = "phaseStreamIndex",
  PositionId = "positionId",
}

export type Sensorflow_Cooling_Towers_Set_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Cooling_Towers_Stddev_Fields = {
  __typename?: "sensorflow_cooling_towers_stddev_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Cooling_Towers_Stddev_Pop_Fields = {
  __typename?: "sensorflow_cooling_towers_stddev_pop_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Cooling_Towers_Stddev_Samp_Fields = {
  __typename?: "sensorflow_cooling_towers_stddev_samp_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Cooling_Towers_Stream_Cursor_Input = {
  initial_value: Sensorflow_Cooling_Towers_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Cooling_Towers_Stream_Cursor_Value_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Cooling_Towers_Sum_Fields = {
  __typename?: "sensorflow_cooling_towers_sum_fields";
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Cooling_Towers_Update_Column {
  CompressorId = "compressorId",
  CtMultiplier = "ctMultiplier",
  DeletedAt = "deleted_at",
  Id = "id",
  MeterPositionId = "meterPositionId",
  Name = "name",
  NumPhases = "numPhases",
  PhaseStreamIndex = "phaseStreamIndex",
  PositionId = "positionId",
}

export type Sensorflow_Cooling_Towers_Updates = {
  _inc?: Maybe<Sensorflow_Cooling_Towers_Inc_Input>;
  _set?: Maybe<Sensorflow_Cooling_Towers_Set_Input>;
  where: Sensorflow_Cooling_Towers_Bool_Exp;
};

export type Sensorflow_Cooling_Towers_Var_Pop_Fields = {
  __typename?: "sensorflow_cooling_towers_var_pop_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Cooling_Towers_Var_Samp_Fields = {
  __typename?: "sensorflow_cooling_towers_var_samp_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Cooling_Towers_Variance_Fields = {
  __typename?: "sensorflow_cooling_towers_variance_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Currencies = {
  __typename?: "sensorflow_currencies";
  id: Scalars["Int"];
  locations: Array<Sensorflow_Locations>;
  locations_aggregate: Sensorflow_Locations_Aggregate;
  name: Scalars["String"];
  shortCode: Scalars["String"];
};

export type Sensorflow_CurrenciesLocationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Locations_Order_By>>;
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
};

export type Sensorflow_CurrenciesLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Locations_Order_By>>;
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
};

export type Sensorflow_Currencies_Aggregate = {
  __typename?: "sensorflow_currencies_aggregate";
  aggregate?: Maybe<Sensorflow_Currencies_Aggregate_Fields>;
  nodes: Array<Sensorflow_Currencies>;
};

export type Sensorflow_Currencies_Aggregate_Fields = {
  __typename?: "sensorflow_currencies_aggregate_fields";
  avg?: Maybe<Sensorflow_Currencies_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Currencies_Max_Fields>;
  min?: Maybe<Sensorflow_Currencies_Min_Fields>;
  stddev?: Maybe<Sensorflow_Currencies_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Currencies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Currencies_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Currencies_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Currencies_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Currencies_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Currencies_Variance_Fields>;
};

export type Sensorflow_Currencies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Currencies_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Currencies_Avg_Fields = {
  __typename?: "sensorflow_currencies_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Currencies_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Currencies_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Currencies_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Currencies_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  locations?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locations_aggregate?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  shortCode?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Currencies_Constraint {
  CurrenciesPkey = "currencies_pkey",
  UniqueShortCode = "unique_short_code",
}

export type Sensorflow_Currencies_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Currencies_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  locations?: Maybe<Sensorflow_Locations_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars["String"]>;
  shortCode?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Currencies_Max_Fields = {
  __typename?: "sensorflow_currencies_max_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  shortCode?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Currencies_Min_Fields = {
  __typename?: "sensorflow_currencies_min_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  shortCode?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Currencies_Mutation_Response = {
  __typename?: "sensorflow_currencies_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Currencies>;
};

export type Sensorflow_Currencies_Obj_Rel_Insert_Input = {
  data: Sensorflow_Currencies_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Currencies_On_Conflict>;
};

export type Sensorflow_Currencies_On_Conflict = {
  constraint: Sensorflow_Currencies_Constraint;
  update_columns: Array<Sensorflow_Currencies_Update_Column>;
  where?: Maybe<Sensorflow_Currencies_Bool_Exp>;
};

export type Sensorflow_Currencies_Order_By = {
  id?: Maybe<Order_By>;
  locations_aggregate?: Maybe<Sensorflow_Locations_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  shortCode?: Maybe<Order_By>;
};

export type Sensorflow_Currencies_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Currencies_Select_Column {
  Id = "id",
  Name = "name",
  ShortCode = "shortCode",
}

export type Sensorflow_Currencies_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  shortCode?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Currencies_Stddev_Fields = {
  __typename?: "sensorflow_currencies_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Currencies_Stddev_Pop_Fields = {
  __typename?: "sensorflow_currencies_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Currencies_Stddev_Samp_Fields = {
  __typename?: "sensorflow_currencies_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Currencies_Stream_Cursor_Input = {
  initial_value: Sensorflow_Currencies_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Currencies_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  shortCode?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Currencies_Sum_Fields = {
  __typename?: "sensorflow_currencies_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Currencies_Update_Column {
  Id = "id",
  Name = "name",
  ShortCode = "shortCode",
}

export type Sensorflow_Currencies_Updates = {
  _inc?: Maybe<Sensorflow_Currencies_Inc_Input>;
  _set?: Maybe<Sensorflow_Currencies_Set_Input>;
  where: Sensorflow_Currencies_Bool_Exp;
};

export type Sensorflow_Currencies_Var_Pop_Fields = {
  __typename?: "sensorflow_currencies_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Currencies_Var_Samp_Fields = {
  __typename?: "sensorflow_currencies_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Currencies_Variance_Fields = {
  __typename?: "sensorflow_currencies_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Domo_Embeds = {
  __typename?: "sensorflow_domo_embeds";
  description: Scalars["String"];
  embed_id: Scalars["String"];
  holisticsSecretKey?: Maybe<Scalars["String"]>;
  isHolistics: Scalars["Boolean"];
  name: Scalars["String"];
  permissionDatasets?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Domo_Embeds_Aggregate = {
  __typename?: "sensorflow_domo_embeds_aggregate";
  aggregate?: Maybe<Sensorflow_Domo_Embeds_Aggregate_Fields>;
  nodes: Array<Sensorflow_Domo_Embeds>;
};

export type Sensorflow_Domo_Embeds_Aggregate_Fields = {
  __typename?: "sensorflow_domo_embeds_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Domo_Embeds_Max_Fields>;
  min?: Maybe<Sensorflow_Domo_Embeds_Min_Fields>;
};

export type Sensorflow_Domo_Embeds_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Domo_Embeds_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Domo_Embeds_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Domo_Embeds_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Domo_Embeds_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Domo_Embeds_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  embed_id?: Maybe<String_Comparison_Exp>;
  holisticsSecretKey?: Maybe<String_Comparison_Exp>;
  isHolistics?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  permissionDatasets?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Domo_Embeds_Constraint {
  DomoEmbedsPkey = "domo_embeds_pkey",
}

export type Sensorflow_Domo_Embeds_Insert_Input = {
  description?: Maybe<Scalars["String"]>;
  embed_id?: Maybe<Scalars["String"]>;
  holisticsSecretKey?: Maybe<Scalars["String"]>;
  isHolistics?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  permissionDatasets?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Domo_Embeds_Max_Fields = {
  __typename?: "sensorflow_domo_embeds_max_fields";
  description?: Maybe<Scalars["String"]>;
  embed_id?: Maybe<Scalars["String"]>;
  holisticsSecretKey?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  permissionDatasets?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Domo_Embeds_Min_Fields = {
  __typename?: "sensorflow_domo_embeds_min_fields";
  description?: Maybe<Scalars["String"]>;
  embed_id?: Maybe<Scalars["String"]>;
  holisticsSecretKey?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  permissionDatasets?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Domo_Embeds_Mutation_Response = {
  __typename?: "sensorflow_domo_embeds_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Domo_Embeds>;
};

export type Sensorflow_Domo_Embeds_Obj_Rel_Insert_Input = {
  data: Sensorflow_Domo_Embeds_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Domo_Embeds_On_Conflict>;
};

export type Sensorflow_Domo_Embeds_On_Conflict = {
  constraint: Sensorflow_Domo_Embeds_Constraint;
  update_columns: Array<Sensorflow_Domo_Embeds_Update_Column>;
  where?: Maybe<Sensorflow_Domo_Embeds_Bool_Exp>;
};

export type Sensorflow_Domo_Embeds_Order_By = {
  description?: Maybe<Order_By>;
  embed_id?: Maybe<Order_By>;
  holisticsSecretKey?: Maybe<Order_By>;
  isHolistics?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  permissionDatasets?: Maybe<Order_By>;
};

export type Sensorflow_Domo_Embeds_Pk_Columns_Input = {
  embed_id: Scalars["String"];
};

export enum Sensorflow_Domo_Embeds_Select_Column {
  Description = "description",
  EmbedId = "embed_id",
  HolisticsSecretKey = "holisticsSecretKey",
  IsHolistics = "isHolistics",
  Name = "name",
  PermissionDatasets = "permissionDatasets",
}

export type Sensorflow_Domo_Embeds_Set_Input = {
  description?: Maybe<Scalars["String"]>;
  embed_id?: Maybe<Scalars["String"]>;
  holisticsSecretKey?: Maybe<Scalars["String"]>;
  isHolistics?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  permissionDatasets?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Domo_Embeds_Stream_Cursor_Input = {
  initial_value: Sensorflow_Domo_Embeds_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Domo_Embeds_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars["String"]>;
  embed_id?: Maybe<Scalars["String"]>;
  holisticsSecretKey?: Maybe<Scalars["String"]>;
  isHolistics?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  permissionDatasets?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Domo_Embeds_Update_Column {
  Description = "description",
  EmbedId = "embed_id",
  HolisticsSecretKey = "holisticsSecretKey",
  IsHolistics = "isHolistics",
  Name = "name",
  PermissionDatasets = "permissionDatasets",
}

export type Sensorflow_Domo_Embeds_Updates = {
  _set?: Maybe<Sensorflow_Domo_Embeds_Set_Input>;
  where: Sensorflow_Domo_Embeds_Bool_Exp;
};

export type Sensorflow_Entity_Feature_Flag_Mappings = {
  __typename?: "sensorflow_entity_feature_flag_mappings";
  enabled: Scalars["Boolean"];
  entity_id: Scalars["String"];
  entity_type: Scalars["String"];
  feature_flag_id: Scalars["Int"];
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Aggregate = {
  __typename?: "sensorflow_entity_feature_flag_mappings_aggregate";
  aggregate?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Aggregate_Fields>;
  nodes: Array<Sensorflow_Entity_Feature_Flag_Mappings>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Aggregate_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_aggregate_fields";
  avg?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Max_Fields>;
  min?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Min_Fields>;
  stddev?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Variance_Fields>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Avg_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_avg_fields";
  feature_flag_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp>>;
  enabled?: Maybe<Boolean_Comparison_Exp>;
  entity_id?: Maybe<String_Comparison_Exp>;
  entity_type?: Maybe<String_Comparison_Exp>;
  feature_flag_id?: Maybe<Int_Comparison_Exp>;
};

export enum Sensorflow_Entity_Feature_Flag_Mappings_Constraint {
  EntityFeatureFlagMappingsPkey = "entity_feature_flag_mappings_pkey",
}

export type Sensorflow_Entity_Feature_Flag_Mappings_Inc_Input = {
  feature_flag_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Insert_Input = {
  enabled?: Maybe<Scalars["Boolean"]>;
  entity_id?: Maybe<Scalars["String"]>;
  entity_type?: Maybe<Scalars["String"]>;
  feature_flag_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Max_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_max_fields";
  entity_id?: Maybe<Scalars["String"]>;
  entity_type?: Maybe<Scalars["String"]>;
  feature_flag_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Min_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_min_fields";
  entity_id?: Maybe<Scalars["String"]>;
  entity_type?: Maybe<Scalars["String"]>;
  feature_flag_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Mutation_Response = {
  __typename?: "sensorflow_entity_feature_flag_mappings_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Entity_Feature_Flag_Mappings>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_On_Conflict = {
  constraint: Sensorflow_Entity_Feature_Flag_Mappings_Constraint;
  update_columns: Array<Sensorflow_Entity_Feature_Flag_Mappings_Update_Column>;
  where?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Order_By = {
  enabled?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  entity_type?: Maybe<Order_By>;
  feature_flag_id?: Maybe<Order_By>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Pk_Columns_Input = {
  entity_id: Scalars["String"];
  feature_flag_id: Scalars["Int"];
};

export enum Sensorflow_Entity_Feature_Flag_Mappings_Select_Column {
  Enabled = "enabled",
  EntityId = "entity_id",
  EntityType = "entity_type",
  FeatureFlagId = "feature_flag_id",
}

export type Sensorflow_Entity_Feature_Flag_Mappings_Set_Input = {
  enabled?: Maybe<Scalars["Boolean"]>;
  entity_id?: Maybe<Scalars["String"]>;
  entity_type?: Maybe<Scalars["String"]>;
  feature_flag_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Stddev_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_stddev_fields";
  feature_flag_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Stddev_Pop_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_stddev_pop_fields";
  feature_flag_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Stddev_Samp_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_stddev_samp_fields";
  feature_flag_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Stream_Cursor_Input = {
  initial_value: Sensorflow_Entity_Feature_Flag_Mappings_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Stream_Cursor_Value_Input = {
  enabled?: Maybe<Scalars["Boolean"]>;
  entity_id?: Maybe<Scalars["String"]>;
  entity_type?: Maybe<Scalars["String"]>;
  feature_flag_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Sum_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_sum_fields";
  feature_flag_id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Entity_Feature_Flag_Mappings_Update_Column {
  Enabled = "enabled",
  EntityId = "entity_id",
  EntityType = "entity_type",
  FeatureFlagId = "feature_flag_id",
}

export type Sensorflow_Entity_Feature_Flag_Mappings_Updates = {
  _inc?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Inc_Input>;
  _set?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Set_Input>;
  where: Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Var_Pop_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_var_pop_fields";
  feature_flag_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Var_Samp_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_var_samp_fields";
  feature_flag_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Entity_Feature_Flag_Mappings_Variance_Fields = {
  __typename?: "sensorflow_entity_feature_flag_mappings_variance_fields";
  feature_flag_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Events_Stream = {
  __typename?: "sensorflow_events_stream";
  currentValue?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  isUserInteraction?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Events_Stream_Aggregate = {
  __typename?: "sensorflow_events_stream_aggregate";
  aggregate?: Maybe<Sensorflow_Events_Stream_Aggregate_Fields>;
  nodes: Array<Sensorflow_Events_Stream>;
};

export type Sensorflow_Events_Stream_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Events_Stream_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Events_Stream_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Events_Stream_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Events_Stream_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Events_Stream_Aggregate_Fields = {
  __typename?: "sensorflow_events_stream_aggregate_fields";
  avg?: Maybe<Sensorflow_Events_Stream_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Events_Stream_Max_Fields>;
  min?: Maybe<Sensorflow_Events_Stream_Min_Fields>;
  stddev?: Maybe<Sensorflow_Events_Stream_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Events_Stream_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Events_Stream_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Events_Stream_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Events_Stream_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Events_Stream_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Events_Stream_Variance_Fields>;
};

export type Sensorflow_Events_Stream_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Events_Stream_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Events_Stream_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Events_Stream_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Events_Stream_Max_Order_By>;
  min?: Maybe<Sensorflow_Events_Stream_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Events_Stream_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Events_Stream_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Events_Stream_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Events_Stream_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Events_Stream_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Events_Stream_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Events_Stream_Variance_Order_By>;
};

export type Sensorflow_Events_Stream_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Events_Stream_Insert_Input>;
};

export type Sensorflow_Events_Stream_Avg_Fields = {
  __typename?: "sensorflow_events_stream_avg_fields";
  hasChanged?: Maybe<Scalars["Float"]>;
  isUserInteraction?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Events_Stream_Avg_Order_By = {
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
};

export type Sensorflow_Events_Stream_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Events_Stream_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Events_Stream_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Events_Stream_Bool_Exp>>;
  currentValue?: Maybe<String_Comparison_Exp>;
  eventType?: Maybe<String_Comparison_Exp>;
  hasChanged?: Maybe<Numeric_Comparison_Exp>;
  isUserInteraction?: Maybe<Numeric_Comparison_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  previousValue?: Maybe<String_Comparison_Exp>;
  slotName?: Maybe<String_Comparison_Exp>;
  srcMacId?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
};

export type Sensorflow_Events_Stream_Insert_Input = {
  currentValue?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  isUserInteraction?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Events_Stream_Max_Fields = {
  __typename?: "sensorflow_events_stream_max_fields";
  currentValue?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  isUserInteraction?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Events_Stream_Max_Order_By = {
  currentValue?: Maybe<Order_By>;
  eventType?: Maybe<Order_By>;
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  previousValue?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  srcMacId?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Events_Stream_Min_Fields = {
  __typename?: "sensorflow_events_stream_min_fields";
  currentValue?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  isUserInteraction?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Events_Stream_Min_Order_By = {
  currentValue?: Maybe<Order_By>;
  eventType?: Maybe<Order_By>;
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  previousValue?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  srcMacId?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Events_Stream_Order_By = {
  currentValue?: Maybe<Order_By>;
  eventType?: Maybe<Order_By>;
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  previousValue?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  srcMacId?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export enum Sensorflow_Events_Stream_Select_Column {
  CurrentValue = "currentValue",
  EventType = "eventType",
  HasChanged = "hasChanged",
  IsUserInteraction = "isUserInteraction",
  PositionId = "positionId",
  PreviousValue = "previousValue",
  SlotName = "slotName",
  SrcMacId = "srcMacId",
  Time = "time",
}

export type Sensorflow_Events_Stream_Stddev_Fields = {
  __typename?: "sensorflow_events_stream_stddev_fields";
  hasChanged?: Maybe<Scalars["Float"]>;
  isUserInteraction?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Events_Stream_Stddev_Order_By = {
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
};

export type Sensorflow_Events_Stream_Stddev_Pop_Fields = {
  __typename?: "sensorflow_events_stream_stddev_pop_fields";
  hasChanged?: Maybe<Scalars["Float"]>;
  isUserInteraction?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Events_Stream_Stddev_Pop_Order_By = {
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
};

export type Sensorflow_Events_Stream_Stddev_Samp_Fields = {
  __typename?: "sensorflow_events_stream_stddev_samp_fields";
  hasChanged?: Maybe<Scalars["Float"]>;
  isUserInteraction?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Events_Stream_Stddev_Samp_Order_By = {
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
};

export type Sensorflow_Events_Stream_Stream_Cursor_Input = {
  initial_value: Sensorflow_Events_Stream_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Events_Stream_Stream_Cursor_Value_Input = {
  currentValue?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  isUserInteraction?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Events_Stream_Sum_Fields = {
  __typename?: "sensorflow_events_stream_sum_fields";
  hasChanged?: Maybe<Scalars["numeric"]>;
  isUserInteraction?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Events_Stream_Sum_Order_By = {
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
};

export type Sensorflow_Events_Stream_Var_Pop_Fields = {
  __typename?: "sensorflow_events_stream_var_pop_fields";
  hasChanged?: Maybe<Scalars["Float"]>;
  isUserInteraction?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Events_Stream_Var_Pop_Order_By = {
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
};

export type Sensorflow_Events_Stream_Var_Samp_Fields = {
  __typename?: "sensorflow_events_stream_var_samp_fields";
  hasChanged?: Maybe<Scalars["Float"]>;
  isUserInteraction?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Events_Stream_Var_Samp_Order_By = {
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
};

export type Sensorflow_Events_Stream_Variance_Fields = {
  __typename?: "sensorflow_events_stream_variance_fields";
  hasChanged?: Maybe<Scalars["Float"]>;
  isUserInteraction?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Events_Stream_Variance_Order_By = {
  hasChanged?: Maybe<Order_By>;
  isUserInteraction?: Maybe<Order_By>;
};

export type Sensorflow_F_Get_Ac_Command_Event_Stream_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_F_Get_All_Event_Stream_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_F_Get_Avg_Position_Data_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  nm_type?: Maybe<Scalars["String"]>;
  position_ids?: Maybe<Scalars["_text"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_F_Get_Config_Event_Stream_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_F_Get_Event_Stream_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_F_Get_Synced_Raw_Data_Args = {
  from_date?: Maybe<Scalars["timestamptz"]>;
  intervals?: Maybe<Scalars["String"]>;
  positionid?: Maybe<Scalars["uuid"]>;
  to_date?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Feature_Flags = {
  __typename?: "sensorflow_feature_flags";
  deprecated: Scalars["Boolean"];
  description: Scalars["String"];
  enabled_all: Scalars["Boolean"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type Sensorflow_Feature_Flags_Aggregate = {
  __typename?: "sensorflow_feature_flags_aggregate";
  aggregate?: Maybe<Sensorflow_Feature_Flags_Aggregate_Fields>;
  nodes: Array<Sensorflow_Feature_Flags>;
};

export type Sensorflow_Feature_Flags_Aggregate_Fields = {
  __typename?: "sensorflow_feature_flags_aggregate_fields";
  avg?: Maybe<Sensorflow_Feature_Flags_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Feature_Flags_Max_Fields>;
  min?: Maybe<Sensorflow_Feature_Flags_Min_Fields>;
  stddev?: Maybe<Sensorflow_Feature_Flags_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Feature_Flags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Feature_Flags_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Feature_Flags_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Feature_Flags_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Feature_Flags_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Feature_Flags_Variance_Fields>;
};

export type Sensorflow_Feature_Flags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Feature_Flags_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Feature_Flags_Avg_Fields = {
  __typename?: "sensorflow_feature_flags_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Feature_Flags_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Feature_Flags_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Feature_Flags_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Feature_Flags_Bool_Exp>>;
  deprecated?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  enabled_all?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Feature_Flags_Constraint {
  FeatureFlagsPkey = "feature_flags_pkey",
}

export type Sensorflow_Feature_Flags_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Feature_Flags_Insert_Input = {
  deprecated?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  enabled_all?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Feature_Flags_Max_Fields = {
  __typename?: "sensorflow_feature_flags_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Feature_Flags_Min_Fields = {
  __typename?: "sensorflow_feature_flags_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Feature_Flags_Mutation_Response = {
  __typename?: "sensorflow_feature_flags_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Feature_Flags>;
};

export type Sensorflow_Feature_Flags_On_Conflict = {
  constraint: Sensorflow_Feature_Flags_Constraint;
  update_columns: Array<Sensorflow_Feature_Flags_Update_Column>;
  where?: Maybe<Sensorflow_Feature_Flags_Bool_Exp>;
};

export type Sensorflow_Feature_Flags_Order_By = {
  deprecated?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  enabled_all?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

export type Sensorflow_Feature_Flags_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Feature_Flags_Select_Column {
  Deprecated = "deprecated",
  Description = "description",
  EnabledAll = "enabled_all",
  Id = "id",
  Name = "name",
}

export type Sensorflow_Feature_Flags_Set_Input = {
  deprecated?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  enabled_all?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Feature_Flags_Stddev_Fields = {
  __typename?: "sensorflow_feature_flags_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Feature_Flags_Stddev_Pop_Fields = {
  __typename?: "sensorflow_feature_flags_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Feature_Flags_Stddev_Samp_Fields = {
  __typename?: "sensorflow_feature_flags_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Feature_Flags_Stream_Cursor_Input = {
  initial_value: Sensorflow_Feature_Flags_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Feature_Flags_Stream_Cursor_Value_Input = {
  deprecated?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  enabled_all?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Feature_Flags_Sum_Fields = {
  __typename?: "sensorflow_feature_flags_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Feature_Flags_Update_Column {
  Deprecated = "deprecated",
  Description = "description",
  EnabledAll = "enabled_all",
  Id = "id",
  Name = "name",
}

export type Sensorflow_Feature_Flags_Updates = {
  _inc?: Maybe<Sensorflow_Feature_Flags_Inc_Input>;
  _set?: Maybe<Sensorflow_Feature_Flags_Set_Input>;
  where: Sensorflow_Feature_Flags_Bool_Exp;
};

export type Sensorflow_Feature_Flags_Var_Pop_Fields = {
  __typename?: "sensorflow_feature_flags_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Feature_Flags_Var_Samp_Fields = {
  __typename?: "sensorflow_feature_flags_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Feature_Flags_Variance_Fields = {
  __typename?: "sensorflow_feature_flags_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Firmware_Releases = {
  __typename?: "sensorflow_firmware_releases";
  comment?: Maybe<Scalars["String"]>;
  downloadLink: Scalars["String"];
  id: Scalars["Int"];
  isDeprecated?: Maybe<Scalars["Boolean"]>;
  nodeSubType: Scalars["String"];
  uploadedDate: Scalars["timestamptz"];
  versionName: Scalars["String"];
  versionNumber: Scalars["Int"];
};

export type Sensorflow_Firmware_Releases_Aggregate = {
  __typename?: "sensorflow_firmware_releases_aggregate";
  aggregate?: Maybe<Sensorflow_Firmware_Releases_Aggregate_Fields>;
  nodes: Array<Sensorflow_Firmware_Releases>;
};

export type Sensorflow_Firmware_Releases_Aggregate_Fields = {
  __typename?: "sensorflow_firmware_releases_aggregate_fields";
  avg?: Maybe<Sensorflow_Firmware_Releases_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Firmware_Releases_Max_Fields>;
  min?: Maybe<Sensorflow_Firmware_Releases_Min_Fields>;
  stddev?: Maybe<Sensorflow_Firmware_Releases_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Firmware_Releases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Firmware_Releases_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Firmware_Releases_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Firmware_Releases_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Firmware_Releases_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Firmware_Releases_Variance_Fields>;
};

export type Sensorflow_Firmware_Releases_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Firmware_Releases_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Firmware_Releases_Avg_Fields = {
  __typename?: "sensorflow_firmware_releases_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Firmware_Releases_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Firmware_Releases_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Firmware_Releases_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Firmware_Releases_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  downloadLink?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  isDeprecated?: Maybe<Boolean_Comparison_Exp>;
  nodeSubType?: Maybe<String_Comparison_Exp>;
  uploadedDate?: Maybe<Timestamptz_Comparison_Exp>;
  versionName?: Maybe<String_Comparison_Exp>;
  versionNumber?: Maybe<Int_Comparison_Exp>;
};

export enum Sensorflow_Firmware_Releases_Constraint {
  FirmwareReleasesPkey = "firmware_releases_pkey",
  FirmwareReleasesUniqueKey = "firmware_releases_unique_key",
}

export type Sensorflow_Firmware_Releases_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Firmware_Releases_Insert_Input = {
  comment?: Maybe<Scalars["String"]>;
  downloadLink?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  isDeprecated?: Maybe<Scalars["Boolean"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  uploadedDate?: Maybe<Scalars["timestamptz"]>;
  versionName?: Maybe<Scalars["String"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Firmware_Releases_Max_Fields = {
  __typename?: "sensorflow_firmware_releases_max_fields";
  comment?: Maybe<Scalars["String"]>;
  downloadLink?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  uploadedDate?: Maybe<Scalars["timestamptz"]>;
  versionName?: Maybe<Scalars["String"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Firmware_Releases_Min_Fields = {
  __typename?: "sensorflow_firmware_releases_min_fields";
  comment?: Maybe<Scalars["String"]>;
  downloadLink?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  uploadedDate?: Maybe<Scalars["timestamptz"]>;
  versionName?: Maybe<Scalars["String"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Firmware_Releases_Mutation_Response = {
  __typename?: "sensorflow_firmware_releases_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Firmware_Releases>;
};

export type Sensorflow_Firmware_Releases_Obj_Rel_Insert_Input = {
  data: Sensorflow_Firmware_Releases_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Firmware_Releases_On_Conflict>;
};

export type Sensorflow_Firmware_Releases_On_Conflict = {
  constraint: Sensorflow_Firmware_Releases_Constraint;
  update_columns: Array<Sensorflow_Firmware_Releases_Update_Column>;
  where?: Maybe<Sensorflow_Firmware_Releases_Bool_Exp>;
};

export type Sensorflow_Firmware_Releases_Order_By = {
  comment?: Maybe<Order_By>;
  downloadLink?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isDeprecated?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  uploadedDate?: Maybe<Order_By>;
  versionName?: Maybe<Order_By>;
  versionNumber?: Maybe<Order_By>;
};

export type Sensorflow_Firmware_Releases_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Firmware_Releases_Select_Column {
  Comment = "comment",
  DownloadLink = "downloadLink",
  Id = "id",
  IsDeprecated = "isDeprecated",
  NodeSubType = "nodeSubType",
  UploadedDate = "uploadedDate",
  VersionName = "versionName",
  VersionNumber = "versionNumber",
}

export type Sensorflow_Firmware_Releases_Set_Input = {
  comment?: Maybe<Scalars["String"]>;
  downloadLink?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  isDeprecated?: Maybe<Scalars["Boolean"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  uploadedDate?: Maybe<Scalars["timestamptz"]>;
  versionName?: Maybe<Scalars["String"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Firmware_Releases_Stddev_Fields = {
  __typename?: "sensorflow_firmware_releases_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Firmware_Releases_Stddev_Pop_Fields = {
  __typename?: "sensorflow_firmware_releases_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Firmware_Releases_Stddev_Samp_Fields = {
  __typename?: "sensorflow_firmware_releases_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Firmware_Releases_Stream_Cursor_Input = {
  initial_value: Sensorflow_Firmware_Releases_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Firmware_Releases_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars["String"]>;
  downloadLink?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  isDeprecated?: Maybe<Scalars["Boolean"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  uploadedDate?: Maybe<Scalars["timestamptz"]>;
  versionName?: Maybe<Scalars["String"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Firmware_Releases_Sum_Fields = {
  __typename?: "sensorflow_firmware_releases_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Firmware_Releases_Update_Column {
  Comment = "comment",
  DownloadLink = "downloadLink",
  Id = "id",
  IsDeprecated = "isDeprecated",
  NodeSubType = "nodeSubType",
  UploadedDate = "uploadedDate",
  VersionName = "versionName",
  VersionNumber = "versionNumber",
}

export type Sensorflow_Firmware_Releases_Updates = {
  _inc?: Maybe<Sensorflow_Firmware_Releases_Inc_Input>;
  _set?: Maybe<Sensorflow_Firmware_Releases_Set_Input>;
  where: Sensorflow_Firmware_Releases_Bool_Exp;
};

export type Sensorflow_Firmware_Releases_Var_Pop_Fields = {
  __typename?: "sensorflow_firmware_releases_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Firmware_Releases_Var_Samp_Fields = {
  __typename?: "sensorflow_firmware_releases_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Firmware_Releases_Variance_Fields = {
  __typename?: "sensorflow_firmware_releases_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  versionNumber?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Frequency = {
  __typename?: "sensorflow_gateway_frequency";
  balena_id: Scalars["String"];
  gateway: Sensorflow_Gateways;
  is_ota: Scalars["Boolean"];
  location: Sensorflow_Locations;
  location_id: Scalars["uuid"];
  reserved_frequency: Scalars["bigint"];
};

export type Sensorflow_Gateway_Frequency_Aggregate = {
  __typename?: "sensorflow_gateway_frequency_aggregate";
  aggregate?: Maybe<Sensorflow_Gateway_Frequency_Aggregate_Fields>;
  nodes: Array<Sensorflow_Gateway_Frequency>;
};

export type Sensorflow_Gateway_Frequency_Aggregate_Fields = {
  __typename?: "sensorflow_gateway_frequency_aggregate_fields";
  avg?: Maybe<Sensorflow_Gateway_Frequency_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Gateway_Frequency_Max_Fields>;
  min?: Maybe<Sensorflow_Gateway_Frequency_Min_Fields>;
  stddev?: Maybe<Sensorflow_Gateway_Frequency_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Gateway_Frequency_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Gateway_Frequency_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Gateway_Frequency_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Gateway_Frequency_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Gateway_Frequency_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Gateway_Frequency_Variance_Fields>;
};

export type Sensorflow_Gateway_Frequency_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Gateway_Frequency_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Gateway_Frequency_Avg_Fields = {
  __typename?: "sensorflow_gateway_frequency_avg_fields";
  reserved_frequency?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Frequency_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Gateway_Frequency_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Gateway_Frequency_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Gateway_Frequency_Bool_Exp>>;
  balena_id?: Maybe<String_Comparison_Exp>;
  gateway?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  is_ota?: Maybe<Boolean_Comparison_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  reserved_frequency?: Maybe<Bigint_Comparison_Exp>;
};

export enum Sensorflow_Gateway_Frequency_Constraint {
  GatewayFrequencyLocationIdReservedFrequencyKey = "gateway_frequency_location_id_reserved_frequency_key",
  GatewayFrequencyPkey = "gateway_frequency_pkey",
}

export type Sensorflow_Gateway_Frequency_Inc_Input = {
  reserved_frequency?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Gateway_Frequency_Insert_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  gateway?: Maybe<Sensorflow_Gateways_Obj_Rel_Insert_Input>;
  is_ota?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["uuid"]>;
  reserved_frequency?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Gateway_Frequency_Max_Fields = {
  __typename?: "sensorflow_gateway_frequency_max_fields";
  balena_id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  reserved_frequency?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Gateway_Frequency_Min_Fields = {
  __typename?: "sensorflow_gateway_frequency_min_fields";
  balena_id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  reserved_frequency?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Gateway_Frequency_Mutation_Response = {
  __typename?: "sensorflow_gateway_frequency_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Gateway_Frequency>;
};

export type Sensorflow_Gateway_Frequency_On_Conflict = {
  constraint: Sensorflow_Gateway_Frequency_Constraint;
  update_columns: Array<Sensorflow_Gateway_Frequency_Update_Column>;
  where?: Maybe<Sensorflow_Gateway_Frequency_Bool_Exp>;
};

export type Sensorflow_Gateway_Frequency_Order_By = {
  balena_id?: Maybe<Order_By>;
  gateway?: Maybe<Sensorflow_Gateways_Order_By>;
  is_ota?: Maybe<Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  reserved_frequency?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_Frequency_Pk_Columns_Input = {
  balena_id: Scalars["String"];
  reserved_frequency: Scalars["bigint"];
};

export enum Sensorflow_Gateway_Frequency_Select_Column {
  BalenaId = "balena_id",
  IsOta = "is_ota",
  LocationId = "location_id",
  ReservedFrequency = "reserved_frequency",
}

export type Sensorflow_Gateway_Frequency_Set_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  is_ota?: Maybe<Scalars["Boolean"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  reserved_frequency?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Gateway_Frequency_Stddev_Fields = {
  __typename?: "sensorflow_gateway_frequency_stddev_fields";
  reserved_frequency?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Frequency_Stddev_Pop_Fields = {
  __typename?: "sensorflow_gateway_frequency_stddev_pop_fields";
  reserved_frequency?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Frequency_Stddev_Samp_Fields = {
  __typename?: "sensorflow_gateway_frequency_stddev_samp_fields";
  reserved_frequency?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Frequency_Stream_Cursor_Input = {
  initial_value: Sensorflow_Gateway_Frequency_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Gateway_Frequency_Stream_Cursor_Value_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  is_ota?: Maybe<Scalars["Boolean"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  reserved_frequency?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Gateway_Frequency_Sum_Fields = {
  __typename?: "sensorflow_gateway_frequency_sum_fields";
  reserved_frequency?: Maybe<Scalars["bigint"]>;
};

export enum Sensorflow_Gateway_Frequency_Update_Column {
  BalenaId = "balena_id",
  IsOta = "is_ota",
  LocationId = "location_id",
  ReservedFrequency = "reserved_frequency",
}

export type Sensorflow_Gateway_Frequency_Updates = {
  _inc?: Maybe<Sensorflow_Gateway_Frequency_Inc_Input>;
  _set?: Maybe<Sensorflow_Gateway_Frequency_Set_Input>;
  where: Sensorflow_Gateway_Frequency_Bool_Exp;
};

export type Sensorflow_Gateway_Frequency_Var_Pop_Fields = {
  __typename?: "sensorflow_gateway_frequency_var_pop_fields";
  reserved_frequency?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Frequency_Var_Samp_Fields = {
  __typename?: "sensorflow_gateway_frequency_var_samp_fields";
  reserved_frequency?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Frequency_Variance_Fields = {
  __typename?: "sensorflow_gateway_frequency_variance_fields";
  reserved_frequency?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Health_Data = {
  __typename?: "sensorflow_gateway_health_data";
  balenaId: Scalars["String"];
  gateway: Sensorflow_Gateways;
  measurementType: Scalars["String"];
  time: Scalars["timestamptz"];
  value: Scalars["numeric"];
};

export type Sensorflow_Gateway_Health_Data_Aggregate = {
  __typename?: "sensorflow_gateway_health_data_aggregate";
  aggregate?: Maybe<Sensorflow_Gateway_Health_Data_Aggregate_Fields>;
  nodes: Array<Sensorflow_Gateway_Health_Data>;
};

export type Sensorflow_Gateway_Health_Data_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Gateway_Health_Data_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Gateway_Health_Data_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Gateway_Health_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Gateway_Health_Data_Aggregate_Fields = {
  __typename?: "sensorflow_gateway_health_data_aggregate_fields";
  avg?: Maybe<Sensorflow_Gateway_Health_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Gateway_Health_Data_Max_Fields>;
  min?: Maybe<Sensorflow_Gateway_Health_Data_Min_Fields>;
  stddev?: Maybe<Sensorflow_Gateway_Health_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Gateway_Health_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Gateway_Health_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Gateway_Health_Data_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Gateway_Health_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Gateway_Health_Data_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Gateway_Health_Data_Variance_Fields>;
};

export type Sensorflow_Gateway_Health_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Gateway_Health_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Gateway_Health_Data_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Gateway_Health_Data_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Gateway_Health_Data_Max_Order_By>;
  min?: Maybe<Sensorflow_Gateway_Health_Data_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Gateway_Health_Data_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Gateway_Health_Data_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Gateway_Health_Data_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Gateway_Health_Data_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Gateway_Health_Data_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Gateway_Health_Data_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Gateway_Health_Data_Variance_Order_By>;
};

export type Sensorflow_Gateway_Health_Data_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Gateway_Health_Data_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Gateway_Health_Data_On_Conflict>;
};

export type Sensorflow_Gateway_Health_Data_Avg_Fields = {
  __typename?: "sensorflow_gateway_health_data_avg_fields";
  value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Health_Data_Avg_Order_By = {
  value?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_Health_Data_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Gateway_Health_Data_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Gateway_Health_Data_Bool_Exp>>;
  balenaId?: Maybe<String_Comparison_Exp>;
  gateway?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  measurementType?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<Numeric_Comparison_Exp>;
};

export enum Sensorflow_Gateway_Health_Data_Constraint {
  GatewayHealthDataUniqueAskIdx = "gateway_health_data_unique_ask_idx",
}

export type Sensorflow_Gateway_Health_Data_Inc_Input = {
  value?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Gateway_Health_Data_Insert_Input = {
  balenaId?: Maybe<Scalars["String"]>;
  gateway?: Maybe<Sensorflow_Gateways_Obj_Rel_Insert_Input>;
  measurementType?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Gateway_Health_Data_Max_Fields = {
  __typename?: "sensorflow_gateway_health_data_max_fields";
  balenaId?: Maybe<Scalars["String"]>;
  measurementType?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Gateway_Health_Data_Max_Order_By = {
  balenaId?: Maybe<Order_By>;
  measurementType?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_Health_Data_Min_Fields = {
  __typename?: "sensorflow_gateway_health_data_min_fields";
  balenaId?: Maybe<Scalars["String"]>;
  measurementType?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Gateway_Health_Data_Min_Order_By = {
  balenaId?: Maybe<Order_By>;
  measurementType?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_Health_Data_Mutation_Response = {
  __typename?: "sensorflow_gateway_health_data_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Gateway_Health_Data>;
};

export type Sensorflow_Gateway_Health_Data_On_Conflict = {
  constraint: Sensorflow_Gateway_Health_Data_Constraint;
  update_columns: Array<Sensorflow_Gateway_Health_Data_Update_Column>;
  where?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
};

export type Sensorflow_Gateway_Health_Data_Order_By = {
  balenaId?: Maybe<Order_By>;
  gateway?: Maybe<Sensorflow_Gateways_Order_By>;
  measurementType?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

export enum Sensorflow_Gateway_Health_Data_Select_Column {
  BalenaId = "balenaId",
  MeasurementType = "measurementType",
  Time = "time",
  Value = "value",
}

export type Sensorflow_Gateway_Health_Data_Set_Input = {
  balenaId?: Maybe<Scalars["String"]>;
  measurementType?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Gateway_Health_Data_Stddev_Fields = {
  __typename?: "sensorflow_gateway_health_data_stddev_fields";
  value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Health_Data_Stddev_Order_By = {
  value?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_Health_Data_Stddev_Pop_Fields = {
  __typename?: "sensorflow_gateway_health_data_stddev_pop_fields";
  value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Health_Data_Stddev_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_Health_Data_Stddev_Samp_Fields = {
  __typename?: "sensorflow_gateway_health_data_stddev_samp_fields";
  value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Health_Data_Stddev_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_Health_Data_Stream_Cursor_Input = {
  initial_value: Sensorflow_Gateway_Health_Data_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Gateway_Health_Data_Stream_Cursor_Value_Input = {
  balenaId?: Maybe<Scalars["String"]>;
  measurementType?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Gateway_Health_Data_Sum_Fields = {
  __typename?: "sensorflow_gateway_health_data_sum_fields";
  value?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Gateway_Health_Data_Sum_Order_By = {
  value?: Maybe<Order_By>;
};

export enum Sensorflow_Gateway_Health_Data_Update_Column {
  BalenaId = "balenaId",
  MeasurementType = "measurementType",
  Time = "time",
  Value = "value",
}

export type Sensorflow_Gateway_Health_Data_Updates = {
  _inc?: Maybe<Sensorflow_Gateway_Health_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Gateway_Health_Data_Set_Input>;
  where: Sensorflow_Gateway_Health_Data_Bool_Exp;
};

export type Sensorflow_Gateway_Health_Data_Var_Pop_Fields = {
  __typename?: "sensorflow_gateway_health_data_var_pop_fields";
  value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Health_Data_Var_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_Health_Data_Var_Samp_Fields = {
  __typename?: "sensorflow_gateway_health_data_var_samp_fields";
  value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Health_Data_Var_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_Health_Data_Variance_Fields = {
  __typename?: "sensorflow_gateway_health_data_variance_fields";
  value?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateway_Health_Data_Variance_Order_By = {
  value?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_To_Position_Mapping = {
  __typename?: "sensorflow_gateway_to_position_mapping";
  gateway?: Maybe<Sensorflow_Gateways>;
  gatewayId?: Maybe<Scalars["String"]>;
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Aggregate = {
  __typename?: "sensorflow_gateway_to_position_mapping_aggregate";
  aggregate?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Aggregate_Fields>;
  nodes: Array<Sensorflow_Gateway_To_Position_Mapping>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Gateway_To_Position_Mapping_Aggregate_Fields = {
  __typename?: "sensorflow_gateway_to_position_mapping_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Max_Fields>;
  min?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Min_Fields>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Max_Order_By>;
  min?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Min_Order_By>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Gateway_To_Position_Mapping_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Gateway_To_Position_Mapping_On_Conflict>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>>;
  gateway?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  gatewayId?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Gateway_To_Position_Mapping_Constraint {
  GatewayToPositionMappingPkey = "gateway_to_position_mapping_pkey",
}

export type Sensorflow_Gateway_To_Position_Mapping_Insert_Input = {
  gateway?: Maybe<Sensorflow_Gateways_Obj_Rel_Insert_Input>;
  gatewayId?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Max_Fields = {
  __typename?: "sensorflow_gateway_to_position_mapping_max_fields";
  gatewayId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Max_Order_By = {
  gatewayId?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Min_Fields = {
  __typename?: "sensorflow_gateway_to_position_mapping_min_fields";
  gatewayId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Min_Order_By = {
  gatewayId?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Mutation_Response = {
  __typename?: "sensorflow_gateway_to_position_mapping_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Gateway_To_Position_Mapping>;
};

export type Sensorflow_Gateway_To_Position_Mapping_On_Conflict = {
  constraint: Sensorflow_Gateway_To_Position_Mapping_Constraint;
  update_columns: Array<Sensorflow_Gateway_To_Position_Mapping_Update_Column>;
  where?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Order_By = {
  gateway?: Maybe<Sensorflow_Gateways_Order_By>;
  gatewayId?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Pk_Columns_Input = {
  positionId: Scalars["uuid"];
};

export enum Sensorflow_Gateway_To_Position_Mapping_Select_Column {
  GatewayId = "gatewayId",
  PositionId = "positionId",
  UpdatedAt = "updated_at",
}

export type Sensorflow_Gateway_To_Position_Mapping_Set_Input = {
  gatewayId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Stream_Cursor_Input = {
  initial_value: Sensorflow_Gateway_To_Position_Mapping_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Gateway_To_Position_Mapping_Stream_Cursor_Value_Input = {
  gatewayId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export enum Sensorflow_Gateway_To_Position_Mapping_Update_Column {
  GatewayId = "gatewayId",
  PositionId = "positionId",
  UpdatedAt = "updated_at",
}

export type Sensorflow_Gateway_To_Position_Mapping_Updates = {
  _set?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Set_Input>;
  where: Sensorflow_Gateway_To_Position_Mapping_Bool_Exp;
};

export type Sensorflow_Gateways = {
  __typename?: "sensorflow_gateways";
  beaconSlotManagementEnabled?: Maybe<Scalars["Boolean"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  currentBeaconSlot?: Maybe<Scalars["Int"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  enableNoKeyCardState?: Maybe<Scalars["Boolean"]>;
  ethernetMacAddress?: Maybe<Scalars["String"]>;
  gatewayHealthData: Array<Sensorflow_Gateway_Health_Data>;
  gatewayHealthData_aggregate: Sensorflow_Gateway_Health_Data_Aggregate;
  gatewayId: Scalars["String"];
  gatewayMac: Scalars["Int"];
  gatewayName: Scalars["String"];
  hardware_type: Scalars["String"];
  mappingCoordinates?: Maybe<Sensorflow_Mapping_Coordinates>;
  networkConnectionStatus?: Maybe<Scalars["String"]>;
  nodeJoinData: Array<Sensorflow_Node_Join_Data>;
  nodeJoinData_aggregate: Sensorflow_Node_Join_Data_Aggregate;
  nodes: Array<Sensorflow_Nodes>;
  nodes_aggregate: Sensorflow_Nodes_Aggregate;
  position?: Maybe<Sensorflow_Positions>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionMappings: Array<Sensorflow_Gateway_To_Position_Mapping>;
  positionMappings_aggregate: Sensorflow_Gateway_To_Position_Mapping_Aggregate;
  promiscuous_mode_enabled?: Maybe<Scalars["Boolean"]>;
  version?: Maybe<Scalars["String"]>;
  warehouse_kitting_process_metadata: Array<Sensorflow_Warehouse_Kitting_Process_Metadata>;
  warehouse_kitting_process_metadata_aggregate: Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate;
  wifiMacAddress?: Maybe<Scalars["String"]>;
};

export type Sensorflow_GatewaysGatewayHealthDataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_Health_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_Health_Data_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
};

export type Sensorflow_GatewaysGatewayHealthData_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_Health_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_Health_Data_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
};

export type Sensorflow_GatewaysNodeJoinDataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
};

export type Sensorflow_GatewaysNodeJoinData_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
};

export type Sensorflow_GatewaysNodesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Nodes_Order_By>>;
  where?: Maybe<Sensorflow_Nodes_Bool_Exp>;
};

export type Sensorflow_GatewaysNodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Nodes_Order_By>>;
  where?: Maybe<Sensorflow_Nodes_Bool_Exp>;
};

export type Sensorflow_GatewaysPositionMappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
};

export type Sensorflow_GatewaysPositionMappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
};

export type Sensorflow_GatewaysWarehouse_Kitting_Process_MetadataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Order_By>>;
  where?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
};

export type Sensorflow_GatewaysWarehouse_Kitting_Process_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Order_By>>;
  where?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
};

export type Sensorflow_Gateways_Aggregate = {
  __typename?: "sensorflow_gateways_aggregate";
  aggregate?: Maybe<Sensorflow_Gateways_Aggregate_Fields>;
  nodes: Array<Sensorflow_Gateways>;
};

export type Sensorflow_Gateways_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Sensorflow_Gateways_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Sensorflow_Gateways_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Sensorflow_Gateways_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Gateways_Aggregate_Bool_Exp_Bool_And = {
  arguments: Sensorflow_Gateways_Select_Column_Sensorflow_Gateways_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sensorflow_Gateways_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Sensorflow_Gateways_Select_Column_Sensorflow_Gateways_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sensorflow_Gateways_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Gateways_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Gateways_Aggregate_Fields = {
  __typename?: "sensorflow_gateways_aggregate_fields";
  avg?: Maybe<Sensorflow_Gateways_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Gateways_Max_Fields>;
  min?: Maybe<Sensorflow_Gateways_Min_Fields>;
  stddev?: Maybe<Sensorflow_Gateways_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Gateways_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Gateways_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Gateways_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Gateways_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Gateways_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Gateways_Variance_Fields>;
};

export type Sensorflow_Gateways_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Gateways_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Gateways_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Gateways_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Gateways_Max_Order_By>;
  min?: Maybe<Sensorflow_Gateways_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Gateways_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Gateways_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Gateways_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Gateways_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Gateways_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Gateways_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Gateways_Variance_Order_By>;
};

export type Sensorflow_Gateways_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Gateways_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Gateways_On_Conflict>;
};

export type Sensorflow_Gateways_Avg_Fields = {
  __typename?: "sensorflow_gateways_avg_fields";
  currentBeaconSlot?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateways_Avg_Order_By = {
  currentBeaconSlot?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
};

export type Sensorflow_Gateways_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Gateways_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Gateways_Bool_Exp>>;
  beaconSlotManagementEnabled?: Maybe<Boolean_Comparison_Exp>;
  clickupTaskId?: Maybe<String_Comparison_Exp>;
  currentBeaconSlot?: Maybe<Int_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  enableNoKeyCardState?: Maybe<Boolean_Comparison_Exp>;
  ethernetMacAddress?: Maybe<String_Comparison_Exp>;
  gatewayHealthData?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
  gatewayHealthData_aggregate?: Maybe<Sensorflow_Gateway_Health_Data_Aggregate_Bool_Exp>;
  gatewayId?: Maybe<String_Comparison_Exp>;
  gatewayMac?: Maybe<Int_Comparison_Exp>;
  gatewayName?: Maybe<String_Comparison_Exp>;
  hardware_type?: Maybe<String_Comparison_Exp>;
  mappingCoordinates?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
  networkConnectionStatus?: Maybe<String_Comparison_Exp>;
  nodeJoinData?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
  nodeJoinData_aggregate?: Maybe<Sensorflow_Node_Join_Data_Aggregate_Bool_Exp>;
  nodes?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  nodes_aggregate?: Maybe<Sensorflow_Nodes_Aggregate_Bool_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  positionMappings?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
  positionMappings_aggregate?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Aggregate_Bool_Exp>;
  promiscuous_mode_enabled?: Maybe<Boolean_Comparison_Exp>;
  version?: Maybe<String_Comparison_Exp>;
  warehouse_kitting_process_metadata?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
  warehouse_kitting_process_metadata_aggregate?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate_Bool_Exp>;
  wifiMacAddress?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Gateways_Constraint {
  GatewaysPkey = "gateways_pkey",
}

export type Sensorflow_Gateways_Inc_Input = {
  currentBeaconSlot?: Maybe<Scalars["Int"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Gateways_Insert_Input = {
  beaconSlotManagementEnabled?: Maybe<Scalars["Boolean"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  currentBeaconSlot?: Maybe<Scalars["Int"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  enableNoKeyCardState?: Maybe<Scalars["Boolean"]>;
  ethernetMacAddress?: Maybe<Scalars["String"]>;
  gatewayHealthData?: Maybe<Sensorflow_Gateway_Health_Data_Arr_Rel_Insert_Input>;
  gatewayId?: Maybe<Scalars["String"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
  gatewayName?: Maybe<Scalars["String"]>;
  hardware_type?: Maybe<Scalars["String"]>;
  mappingCoordinates?: Maybe<Sensorflow_Mapping_Coordinates_Obj_Rel_Insert_Input>;
  networkConnectionStatus?: Maybe<Scalars["String"]>;
  nodeJoinData?: Maybe<Sensorflow_Node_Join_Data_Arr_Rel_Insert_Input>;
  nodes?: Maybe<Sensorflow_Nodes_Arr_Rel_Insert_Input>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionMappings?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Arr_Rel_Insert_Input>;
  promiscuous_mode_enabled?: Maybe<Scalars["Boolean"]>;
  version?: Maybe<Scalars["String"]>;
  warehouse_kitting_process_metadata?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Arr_Rel_Insert_Input>;
  wifiMacAddress?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Gateways_Max_Fields = {
  __typename?: "sensorflow_gateways_max_fields";
  clickupTaskId?: Maybe<Scalars["String"]>;
  currentBeaconSlot?: Maybe<Scalars["Int"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  ethernetMacAddress?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
  gatewayName?: Maybe<Scalars["String"]>;
  hardware_type?: Maybe<Scalars["String"]>;
  networkConnectionStatus?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  version?: Maybe<Scalars["String"]>;
  wifiMacAddress?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Gateways_Max_Order_By = {
  clickupTaskId?: Maybe<Order_By>;
  currentBeaconSlot?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  ethernetMacAddress?: Maybe<Order_By>;
  gatewayId?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
  gatewayName?: Maybe<Order_By>;
  hardware_type?: Maybe<Order_By>;
  networkConnectionStatus?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
  wifiMacAddress?: Maybe<Order_By>;
};

export type Sensorflow_Gateways_Min_Fields = {
  __typename?: "sensorflow_gateways_min_fields";
  clickupTaskId?: Maybe<Scalars["String"]>;
  currentBeaconSlot?: Maybe<Scalars["Int"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  ethernetMacAddress?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
  gatewayName?: Maybe<Scalars["String"]>;
  hardware_type?: Maybe<Scalars["String"]>;
  networkConnectionStatus?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  version?: Maybe<Scalars["String"]>;
  wifiMacAddress?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Gateways_Min_Order_By = {
  clickupTaskId?: Maybe<Order_By>;
  currentBeaconSlot?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  ethernetMacAddress?: Maybe<Order_By>;
  gatewayId?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
  gatewayName?: Maybe<Order_By>;
  hardware_type?: Maybe<Order_By>;
  networkConnectionStatus?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
  wifiMacAddress?: Maybe<Order_By>;
};

export type Sensorflow_Gateways_Mutation_Response = {
  __typename?: "sensorflow_gateways_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Gateways>;
};

export type Sensorflow_Gateways_Obj_Rel_Insert_Input = {
  data: Sensorflow_Gateways_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Gateways_On_Conflict>;
};

export type Sensorflow_Gateways_On_Conflict = {
  constraint: Sensorflow_Gateways_Constraint;
  update_columns: Array<Sensorflow_Gateways_Update_Column>;
  where?: Maybe<Sensorflow_Gateways_Bool_Exp>;
};

export type Sensorflow_Gateways_Order_By = {
  beaconSlotManagementEnabled?: Maybe<Order_By>;
  clickupTaskId?: Maybe<Order_By>;
  currentBeaconSlot?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  enableNoKeyCardState?: Maybe<Order_By>;
  ethernetMacAddress?: Maybe<Order_By>;
  gatewayHealthData_aggregate?: Maybe<Sensorflow_Gateway_Health_Data_Aggregate_Order_By>;
  gatewayId?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
  gatewayName?: Maybe<Order_By>;
  hardware_type?: Maybe<Order_By>;
  mappingCoordinates?: Maybe<Sensorflow_Mapping_Coordinates_Order_By>;
  networkConnectionStatus?: Maybe<Order_By>;
  nodeJoinData_aggregate?: Maybe<Sensorflow_Node_Join_Data_Aggregate_Order_By>;
  nodes_aggregate?: Maybe<Sensorflow_Nodes_Aggregate_Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  positionMappings_aggregate?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Aggregate_Order_By>;
  promiscuous_mode_enabled?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
  warehouse_kitting_process_metadata_aggregate?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate_Order_By>;
  wifiMacAddress?: Maybe<Order_By>;
};

export type Sensorflow_Gateways_Pk_Columns_Input = {
  gatewayId: Scalars["String"];
};

export enum Sensorflow_Gateways_Select_Column {
  BeaconSlotManagementEnabled = "beaconSlotManagementEnabled",
  ClickupTaskId = "clickupTaskId",
  CurrentBeaconSlot = "currentBeaconSlot",
  DeletedAt = "deletedAt",
  EnableNoKeyCardState = "enableNoKeyCardState",
  EthernetMacAddress = "ethernetMacAddress",
  GatewayId = "gatewayId",
  GatewayMac = "gatewayMac",
  GatewayName = "gatewayName",
  HardwareType = "hardware_type",
  NetworkConnectionStatus = "networkConnectionStatus",
  PositionId = "positionId",
  PromiscuousModeEnabled = "promiscuous_mode_enabled",
  Version = "version",
  WifiMacAddress = "wifiMacAddress",
}

export enum Sensorflow_Gateways_Select_Column_Sensorflow_Gateways_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  BeaconSlotManagementEnabled = "beaconSlotManagementEnabled",
  EnableNoKeyCardState = "enableNoKeyCardState",
  PromiscuousModeEnabled = "promiscuous_mode_enabled",
}

export enum Sensorflow_Gateways_Select_Column_Sensorflow_Gateways_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  BeaconSlotManagementEnabled = "beaconSlotManagementEnabled",
  EnableNoKeyCardState = "enableNoKeyCardState",
  PromiscuousModeEnabled = "promiscuous_mode_enabled",
}

export type Sensorflow_Gateways_Set_Input = {
  beaconSlotManagementEnabled?: Maybe<Scalars["Boolean"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  currentBeaconSlot?: Maybe<Scalars["Int"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  enableNoKeyCardState?: Maybe<Scalars["Boolean"]>;
  ethernetMacAddress?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
  gatewayName?: Maybe<Scalars["String"]>;
  hardware_type?: Maybe<Scalars["String"]>;
  networkConnectionStatus?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  promiscuous_mode_enabled?: Maybe<Scalars["Boolean"]>;
  version?: Maybe<Scalars["String"]>;
  wifiMacAddress?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Gateways_Stddev_Fields = {
  __typename?: "sensorflow_gateways_stddev_fields";
  currentBeaconSlot?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateways_Stddev_Order_By = {
  currentBeaconSlot?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
};

export type Sensorflow_Gateways_Stddev_Pop_Fields = {
  __typename?: "sensorflow_gateways_stddev_pop_fields";
  currentBeaconSlot?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateways_Stddev_Pop_Order_By = {
  currentBeaconSlot?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
};

export type Sensorflow_Gateways_Stddev_Samp_Fields = {
  __typename?: "sensorflow_gateways_stddev_samp_fields";
  currentBeaconSlot?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateways_Stddev_Samp_Order_By = {
  currentBeaconSlot?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
};

export type Sensorflow_Gateways_Stream_Cursor_Input = {
  initial_value: Sensorflow_Gateways_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Gateways_Stream_Cursor_Value_Input = {
  beaconSlotManagementEnabled?: Maybe<Scalars["Boolean"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  currentBeaconSlot?: Maybe<Scalars["Int"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  enableNoKeyCardState?: Maybe<Scalars["Boolean"]>;
  ethernetMacAddress?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
  gatewayName?: Maybe<Scalars["String"]>;
  hardware_type?: Maybe<Scalars["String"]>;
  networkConnectionStatus?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  promiscuous_mode_enabled?: Maybe<Scalars["Boolean"]>;
  version?: Maybe<Scalars["String"]>;
  wifiMacAddress?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Gateways_Sum_Fields = {
  __typename?: "sensorflow_gateways_sum_fields";
  currentBeaconSlot?: Maybe<Scalars["Int"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Gateways_Sum_Order_By = {
  currentBeaconSlot?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
};

export enum Sensorflow_Gateways_Update_Column {
  BeaconSlotManagementEnabled = "beaconSlotManagementEnabled",
  ClickupTaskId = "clickupTaskId",
  CurrentBeaconSlot = "currentBeaconSlot",
  DeletedAt = "deletedAt",
  EnableNoKeyCardState = "enableNoKeyCardState",
  EthernetMacAddress = "ethernetMacAddress",
  GatewayId = "gatewayId",
  GatewayMac = "gatewayMac",
  GatewayName = "gatewayName",
  HardwareType = "hardware_type",
  NetworkConnectionStatus = "networkConnectionStatus",
  PositionId = "positionId",
  PromiscuousModeEnabled = "promiscuous_mode_enabled",
  Version = "version",
  WifiMacAddress = "wifiMacAddress",
}

export type Sensorflow_Gateways_Updates = {
  _inc?: Maybe<Sensorflow_Gateways_Inc_Input>;
  _set?: Maybe<Sensorflow_Gateways_Set_Input>;
  where: Sensorflow_Gateways_Bool_Exp;
};

export type Sensorflow_Gateways_Var_Pop_Fields = {
  __typename?: "sensorflow_gateways_var_pop_fields";
  currentBeaconSlot?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateways_Var_Pop_Order_By = {
  currentBeaconSlot?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
};

export type Sensorflow_Gateways_Var_Samp_Fields = {
  __typename?: "sensorflow_gateways_var_samp_fields";
  currentBeaconSlot?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateways_Var_Samp_Order_By = {
  currentBeaconSlot?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
};

export type Sensorflow_Gateways_Variance_Fields = {
  __typename?: "sensorflow_gateways_variance_fields";
  currentBeaconSlot?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Gateways_Variance_Order_By = {
  currentBeaconSlot?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
};

export type Sensorflow_Get_Event_Stream_View = {
  __typename?: "sensorflow_get_event_stream_view";
  changedBy?: Maybe<Scalars["String"]>;
  currentValue?: Maybe<Scalars["numeric"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  position?: Maybe<Sensorflow_Positions>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Get_Event_Stream_View_Aggregate = {
  __typename?: "sensorflow_get_event_stream_view_aggregate";
  aggregate?: Maybe<Sensorflow_Get_Event_Stream_View_Aggregate_Fields>;
  nodes: Array<Sensorflow_Get_Event_Stream_View>;
};

export type Sensorflow_Get_Event_Stream_View_Aggregate_Fields = {
  __typename?: "sensorflow_get_event_stream_view_aggregate_fields";
  avg?: Maybe<Sensorflow_Get_Event_Stream_View_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Get_Event_Stream_View_Max_Fields>;
  min?: Maybe<Sensorflow_Get_Event_Stream_View_Min_Fields>;
  stddev?: Maybe<Sensorflow_Get_Event_Stream_View_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Get_Event_Stream_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Get_Event_Stream_View_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Get_Event_Stream_View_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Get_Event_Stream_View_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Get_Event_Stream_View_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Get_Event_Stream_View_Variance_Fields>;
};

export type Sensorflow_Get_Event_Stream_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Get_Event_Stream_View_Avg_Fields = {
  __typename?: "sensorflow_get_event_stream_view_avg_fields";
  currentValue?: Maybe<Scalars["Float"]>;
  hasChanged?: Maybe<Scalars["Float"]>;
  previousValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Get_Event_Stream_View_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Get_Event_Stream_View_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Bool_Exp>>;
  changedBy?: Maybe<String_Comparison_Exp>;
  currentValue?: Maybe<Numeric_Comparison_Exp>;
  eventType?: Maybe<String_Comparison_Exp>;
  hasChanged?: Maybe<Numeric_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  previousValue?: Maybe<Numeric_Comparison_Exp>;
  slotName?: Maybe<String_Comparison_Exp>;
  srcMacId?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
};

export type Sensorflow_Get_Event_Stream_View_Inc_Input = {
  currentValue?: Maybe<Scalars["numeric"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  previousValue?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Get_Event_Stream_View_Insert_Input = {
  changedBy?: Maybe<Scalars["String"]>;
  currentValue?: Maybe<Scalars["numeric"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Get_Event_Stream_View_Max_Fields = {
  __typename?: "sensorflow_get_event_stream_view_max_fields";
  changedBy?: Maybe<Scalars["String"]>;
  currentValue?: Maybe<Scalars["numeric"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Get_Event_Stream_View_Min_Fields = {
  __typename?: "sensorflow_get_event_stream_view_min_fields";
  changedBy?: Maybe<Scalars["String"]>;
  currentValue?: Maybe<Scalars["numeric"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Get_Event_Stream_View_Mutation_Response = {
  __typename?: "sensorflow_get_event_stream_view_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Get_Event_Stream_View>;
};

export type Sensorflow_Get_Event_Stream_View_Order_By = {
  changedBy?: Maybe<Order_By>;
  currentValue?: Maybe<Order_By>;
  eventType?: Maybe<Order_By>;
  hasChanged?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  previousValue?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  srcMacId?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export enum Sensorflow_Get_Event_Stream_View_Select_Column {
  ChangedBy = "changedBy",
  CurrentValue = "currentValue",
  EventType = "eventType",
  HasChanged = "hasChanged",
  PositionId = "positionId",
  PreviousValue = "previousValue",
  SlotName = "slotName",
  SrcMacId = "srcMacId",
  Time = "time",
}

export type Sensorflow_Get_Event_Stream_View_Set_Input = {
  changedBy?: Maybe<Scalars["String"]>;
  currentValue?: Maybe<Scalars["numeric"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Get_Event_Stream_View_Stddev_Fields = {
  __typename?: "sensorflow_get_event_stream_view_stddev_fields";
  currentValue?: Maybe<Scalars["Float"]>;
  hasChanged?: Maybe<Scalars["Float"]>;
  previousValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Get_Event_Stream_View_Stddev_Pop_Fields = {
  __typename?: "sensorflow_get_event_stream_view_stddev_pop_fields";
  currentValue?: Maybe<Scalars["Float"]>;
  hasChanged?: Maybe<Scalars["Float"]>;
  previousValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Get_Event_Stream_View_Stddev_Samp_Fields = {
  __typename?: "sensorflow_get_event_stream_view_stddev_samp_fields";
  currentValue?: Maybe<Scalars["Float"]>;
  hasChanged?: Maybe<Scalars["Float"]>;
  previousValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Get_Event_Stream_View_Stream_Cursor_Input = {
  initial_value: Sensorflow_Get_Event_Stream_View_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Get_Event_Stream_View_Stream_Cursor_Value_Input = {
  changedBy?: Maybe<Scalars["String"]>;
  currentValue?: Maybe<Scalars["numeric"]>;
  eventType?: Maybe<Scalars["String"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  previousValue?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Get_Event_Stream_View_Sum_Fields = {
  __typename?: "sensorflow_get_event_stream_view_sum_fields";
  currentValue?: Maybe<Scalars["numeric"]>;
  hasChanged?: Maybe<Scalars["numeric"]>;
  previousValue?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Get_Event_Stream_View_Updates = {
  _inc?: Maybe<Sensorflow_Get_Event_Stream_View_Inc_Input>;
  _set?: Maybe<Sensorflow_Get_Event_Stream_View_Set_Input>;
  where: Sensorflow_Get_Event_Stream_View_Bool_Exp;
};

export type Sensorflow_Get_Event_Stream_View_Var_Pop_Fields = {
  __typename?: "sensorflow_get_event_stream_view_var_pop_fields";
  currentValue?: Maybe<Scalars["Float"]>;
  hasChanged?: Maybe<Scalars["Float"]>;
  previousValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Get_Event_Stream_View_Var_Samp_Fields = {
  __typename?: "sensorflow_get_event_stream_view_var_samp_fields";
  currentValue?: Maybe<Scalars["Float"]>;
  hasChanged?: Maybe<Scalars["Float"]>;
  previousValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Get_Event_Stream_View_Variance_Fields = {
  __typename?: "sensorflow_get_event_stream_view_variance_fields";
  currentValue?: Maybe<Scalars["Float"]>;
  hasChanged?: Maybe<Scalars["Float"]>;
  previousValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Get_Precool_Command_Streams = {
  __typename?: "sensorflow_get_precool_command_streams";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Get_Precool_Command_Streams_Aggregate = {
  __typename?: "sensorflow_get_precool_command_streams_aggregate";
  aggregate?: Maybe<Sensorflow_Get_Precool_Command_Streams_Aggregate_Fields>;
  nodes: Array<Sensorflow_Get_Precool_Command_Streams>;
};

export type Sensorflow_Get_Precool_Command_Streams_Aggregate_Fields = {
  __typename?: "sensorflow_get_precool_command_streams_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Get_Precool_Command_Streams_Max_Fields>;
  min?: Maybe<Sensorflow_Get_Precool_Command_Streams_Min_Fields>;
};

export type Sensorflow_Get_Precool_Command_Streams_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Get_Precool_Command_Streams_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Get_Precool_Command_Streams_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Bool_Exp>>;
  ac_settings_type_changed?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  modified_by_user?: Maybe<String_Comparison_Exp>;
  new_ac_settings_value?: Maybe<String_Comparison_Exp>;
  position_id?: Maybe<Uuid_Comparison_Exp>;
  previous_ac_settings_value?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
  user_email?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Get_Precool_Command_Streams_Max_Fields = {
  __typename?: "sensorflow_get_precool_command_streams_max_fields";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Get_Precool_Command_Streams_Min_Fields = {
  __typename?: "sensorflow_get_precool_command_streams_min_fields";
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Get_Precool_Command_Streams_Order_By = {
  ac_settings_type_changed?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  modified_by_user?: Maybe<Order_By>;
  new_ac_settings_value?: Maybe<Order_By>;
  position_id?: Maybe<Order_By>;
  previous_ac_settings_value?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  user_email?: Maybe<Order_By>;
};

export enum Sensorflow_Get_Precool_Command_Streams_Select_Column {
  AcSettingsTypeChanged = "ac_settings_type_changed",
  EventType = "event_type",
  ModifiedByUser = "modified_by_user",
  NewAcSettingsValue = "new_ac_settings_value",
  PositionId = "position_id",
  PreviousAcSettingsValue = "previous_ac_settings_value",
  Time = "time",
  UserEmail = "user_email",
}

export type Sensorflow_Get_Precool_Command_Streams_Stream_Cursor_Input = {
  initial_value: Sensorflow_Get_Precool_Command_Streams_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Get_Precool_Command_Streams_Stream_Cursor_Value_Input = {
  ac_settings_type_changed?: Maybe<Scalars["String"]>;
  event_type?: Maybe<Scalars["String"]>;
  modified_by_user?: Maybe<Scalars["String"]>;
  new_ac_settings_value?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  previous_ac_settings_value?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
  user_email?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Categories = {
  __typename?: "sensorflow_key_categories";
  categoryName: Scalars["String"];
  id: Scalars["uuid"];
  keyCategoryPositionMappings: Array<Sensorflow_Key_Category_To_Key_Position>;
  keyCategoryPositionMappings_aggregate: Sensorflow_Key_Category_To_Key_Position_Aggregate;
  keyCategoryTemplate?: Maybe<Sensorflow_Key_Category_Templates>;
  location: Sensorflow_Locations;
  locationName: Scalars["String"];
};

export type Sensorflow_Key_CategoriesKeyCategoryPositionMappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
};

export type Sensorflow_Key_CategoriesKeyCategoryPositionMappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
};

export type Sensorflow_Key_Categories_Aggregate = {
  __typename?: "sensorflow_key_categories_aggregate";
  aggregate?: Maybe<Sensorflow_Key_Categories_Aggregate_Fields>;
  nodes: Array<Sensorflow_Key_Categories>;
};

export type Sensorflow_Key_Categories_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Key_Categories_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Key_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Key_Categories_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Key_Categories_Aggregate_Fields = {
  __typename?: "sensorflow_key_categories_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Key_Categories_Max_Fields>;
  min?: Maybe<Sensorflow_Key_Categories_Min_Fields>;
};

export type Sensorflow_Key_Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Key_Categories_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Key_Categories_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Key_Categories_Max_Order_By>;
  min?: Maybe<Sensorflow_Key_Categories_Min_Order_By>;
};

export type Sensorflow_Key_Categories_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Key_Categories_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Key_Categories_On_Conflict>;
};

export type Sensorflow_Key_Categories_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Key_Categories_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Key_Categories_Bool_Exp>>;
  categoryName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  keyCategoryPositionMappings?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
  keyCategoryPositionMappings_aggregate?: Maybe<Sensorflow_Key_Category_To_Key_Position_Aggregate_Bool_Exp>;
  keyCategoryTemplate?: Maybe<Sensorflow_Key_Category_Templates_Bool_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locationName?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Key_Categories_Constraint {
  KeyCategoriesPkey = "key_categories_pkey",
  KeyCategoriesUniqueIdx = "key_categories_unique_idx",
}

export type Sensorflow_Key_Categories_Insert_Input = {
  categoryName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  keyCategoryPositionMappings?: Maybe<Sensorflow_Key_Category_To_Key_Position_Arr_Rel_Insert_Input>;
  keyCategoryTemplate?: Maybe<Sensorflow_Key_Category_Templates_Obj_Rel_Insert_Input>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  locationName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Categories_Max_Fields = {
  __typename?: "sensorflow_key_categories_max_fields";
  categoryName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Categories_Max_Order_By = {
  categoryName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationName?: Maybe<Order_By>;
};

export type Sensorflow_Key_Categories_Min_Fields = {
  __typename?: "sensorflow_key_categories_min_fields";
  categoryName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Categories_Min_Order_By = {
  categoryName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locationName?: Maybe<Order_By>;
};

export type Sensorflow_Key_Categories_Mutation_Response = {
  __typename?: "sensorflow_key_categories_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Key_Categories>;
};

export type Sensorflow_Key_Categories_Obj_Rel_Insert_Input = {
  data: Sensorflow_Key_Categories_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Key_Categories_On_Conflict>;
};

export type Sensorflow_Key_Categories_On_Conflict = {
  constraint: Sensorflow_Key_Categories_Constraint;
  update_columns: Array<Sensorflow_Key_Categories_Update_Column>;
  where?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
};

export type Sensorflow_Key_Categories_Order_By = {
  categoryName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyCategoryPositionMappings_aggregate?: Maybe<Sensorflow_Key_Category_To_Key_Position_Aggregate_Order_By>;
  keyCategoryTemplate?: Maybe<Sensorflow_Key_Category_Templates_Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  locationName?: Maybe<Order_By>;
};

export type Sensorflow_Key_Categories_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Key_Categories_Select_Column {
  CategoryName = "categoryName",
  Id = "id",
  LocationName = "locationName",
}

export type Sensorflow_Key_Categories_Set_Input = {
  categoryName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Categories_Stream_Cursor_Input = {
  initial_value: Sensorflow_Key_Categories_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Key_Categories_Stream_Cursor_Value_Input = {
  categoryName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Key_Categories_Update_Column {
  CategoryName = "categoryName",
  Id = "id",
  LocationName = "locationName",
}

export type Sensorflow_Key_Categories_Updates = {
  _set?: Maybe<Sensorflow_Key_Categories_Set_Input>;
  where: Sensorflow_Key_Categories_Bool_Exp;
};

export type Sensorflow_Key_Category_Room_Templates = {
  __typename?: "sensorflow_key_category_room_templates";
  id: Scalars["uuid"];
  keyCategoryTemplate: Sensorflow_Key_Category_Templates;
  keyCategoryTemplateId: Scalars["uuid"];
  roomName: Scalars["String"];
  slotTemplates: Array<Sensorflow_Key_Category_Slot_Templates>;
  slotTemplates_aggregate: Sensorflow_Key_Category_Slot_Templates_Aggregate;
};

export type Sensorflow_Key_Category_Room_TemplatesSlotTemplatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
};

export type Sensorflow_Key_Category_Room_TemplatesSlotTemplates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
};

export type Sensorflow_Key_Category_Room_Templates_Aggregate = {
  __typename?: "sensorflow_key_category_room_templates_aggregate";
  aggregate?: Maybe<Sensorflow_Key_Category_Room_Templates_Aggregate_Fields>;
  nodes: Array<Sensorflow_Key_Category_Room_Templates>;
};

export type Sensorflow_Key_Category_Room_Templates_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Key_Category_Room_Templates_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Key_Category_Room_Templates_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Key_Category_Room_Templates_Aggregate_Fields = {
  __typename?: "sensorflow_key_category_room_templates_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Key_Category_Room_Templates_Max_Fields>;
  min?: Maybe<Sensorflow_Key_Category_Room_Templates_Min_Fields>;
};

export type Sensorflow_Key_Category_Room_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Key_Category_Room_Templates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Key_Category_Room_Templates_Max_Order_By>;
  min?: Maybe<Sensorflow_Key_Category_Room_Templates_Min_Order_By>;
};

export type Sensorflow_Key_Category_Room_Templates_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Key_Category_Room_Templates_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Key_Category_Room_Templates_On_Conflict>;
};

export type Sensorflow_Key_Category_Room_Templates_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  keyCategoryTemplate?: Maybe<Sensorflow_Key_Category_Templates_Bool_Exp>;
  keyCategoryTemplateId?: Maybe<Uuid_Comparison_Exp>;
  roomName?: Maybe<String_Comparison_Exp>;
  slotTemplates?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
  slotTemplates_aggregate?: Maybe<Sensorflow_Key_Category_Slot_Templates_Aggregate_Bool_Exp>;
};

export enum Sensorflow_Key_Category_Room_Templates_Constraint {
  KeyCategoryRoomTemplatesPkey = "key_category_room_templates_pkey",
  UniqueKeyCategoryRoomTemplate = "unique_key_category_room_template",
}

export type Sensorflow_Key_Category_Room_Templates_Insert_Input = {
  id?: Maybe<Scalars["uuid"]>;
  keyCategoryTemplate?: Maybe<Sensorflow_Key_Category_Templates_Obj_Rel_Insert_Input>;
  keyCategoryTemplateId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
  slotTemplates?: Maybe<Sensorflow_Key_Category_Slot_Templates_Arr_Rel_Insert_Input>;
};

export type Sensorflow_Key_Category_Room_Templates_Max_Fields = {
  __typename?: "sensorflow_key_category_room_templates_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  keyCategoryTemplateId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Category_Room_Templates_Max_Order_By = {
  id?: Maybe<Order_By>;
  keyCategoryTemplateId?: Maybe<Order_By>;
  roomName?: Maybe<Order_By>;
};

export type Sensorflow_Key_Category_Room_Templates_Min_Fields = {
  __typename?: "sensorflow_key_category_room_templates_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  keyCategoryTemplateId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Category_Room_Templates_Min_Order_By = {
  id?: Maybe<Order_By>;
  keyCategoryTemplateId?: Maybe<Order_By>;
  roomName?: Maybe<Order_By>;
};

export type Sensorflow_Key_Category_Room_Templates_Mutation_Response = {
  __typename?: "sensorflow_key_category_room_templates_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Key_Category_Room_Templates>;
};

export type Sensorflow_Key_Category_Room_Templates_Obj_Rel_Insert_Input = {
  data: Sensorflow_Key_Category_Room_Templates_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Key_Category_Room_Templates_On_Conflict>;
};

export type Sensorflow_Key_Category_Room_Templates_On_Conflict = {
  constraint: Sensorflow_Key_Category_Room_Templates_Constraint;
  update_columns: Array<Sensorflow_Key_Category_Room_Templates_Update_Column>;
  where?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
};

export type Sensorflow_Key_Category_Room_Templates_Order_By = {
  id?: Maybe<Order_By>;
  keyCategoryTemplate?: Maybe<Sensorflow_Key_Category_Templates_Order_By>;
  keyCategoryTemplateId?: Maybe<Order_By>;
  roomName?: Maybe<Order_By>;
  slotTemplates_aggregate?: Maybe<Sensorflow_Key_Category_Slot_Templates_Aggregate_Order_By>;
};

export type Sensorflow_Key_Category_Room_Templates_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Key_Category_Room_Templates_Select_Column {
  Id = "id",
  KeyCategoryTemplateId = "keyCategoryTemplateId",
  RoomName = "roomName",
}

export type Sensorflow_Key_Category_Room_Templates_Set_Input = {
  id?: Maybe<Scalars["uuid"]>;
  keyCategoryTemplateId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Category_Room_Templates_Stream_Cursor_Input = {
  initial_value: Sensorflow_Key_Category_Room_Templates_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Key_Category_Room_Templates_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["uuid"]>;
  keyCategoryTemplateId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Key_Category_Room_Templates_Update_Column {
  Id = "id",
  KeyCategoryTemplateId = "keyCategoryTemplateId",
  RoomName = "roomName",
}

export type Sensorflow_Key_Category_Room_Templates_Updates = {
  _set?: Maybe<Sensorflow_Key_Category_Room_Templates_Set_Input>;
  where: Sensorflow_Key_Category_Room_Templates_Bool_Exp;
};

export type Sensorflow_Key_Category_Slot_Templates = {
  __typename?: "sensorflow_key_category_slot_templates";
  acModelId?: Maybe<Scalars["uuid"]>;
  actingMode?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType: Scalars["String"];
  operationalMode?: Maybe<Scalars["String"]>;
  roomTemplate: Sensorflow_Key_Category_Room_Templates;
  roomTemplateId: Scalars["uuid"];
  slotName: Scalars["String"];
};

export type Sensorflow_Key_Category_Slot_Templates_Aggregate = {
  __typename?: "sensorflow_key_category_slot_templates_aggregate";
  aggregate?: Maybe<Sensorflow_Key_Category_Slot_Templates_Aggregate_Fields>;
  nodes: Array<Sensorflow_Key_Category_Slot_Templates>;
};

export type Sensorflow_Key_Category_Slot_Templates_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Key_Category_Slot_Templates_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Key_Category_Slot_Templates_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Key_Category_Slot_Templates_Aggregate_Fields = {
  __typename?: "sensorflow_key_category_slot_templates_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Key_Category_Slot_Templates_Max_Fields>;
  min?: Maybe<Sensorflow_Key_Category_Slot_Templates_Min_Fields>;
};

export type Sensorflow_Key_Category_Slot_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Key_Category_Slot_Templates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Key_Category_Slot_Templates_Max_Order_By>;
  min?: Maybe<Sensorflow_Key_Category_Slot_Templates_Min_Order_By>;
};

export type Sensorflow_Key_Category_Slot_Templates_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Key_Category_Slot_Templates_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Key_Category_Slot_Templates_On_Conflict>;
};

export type Sensorflow_Key_Category_Slot_Templates_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>>;
  acModelId?: Maybe<Uuid_Comparison_Exp>;
  actingMode?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  nodeSubType?: Maybe<String_Comparison_Exp>;
  nodeType?: Maybe<String_Comparison_Exp>;
  operationalMode?: Maybe<String_Comparison_Exp>;
  roomTemplate?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
  roomTemplateId?: Maybe<Uuid_Comparison_Exp>;
  slotName?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Key_Category_Slot_Templates_Constraint {
  KeyCategorySlotTemplatesPkey = "key_category_slot_templates_pkey",
  UniqueKeyCategoryRoomSlotTemplate = "unique_key_category_room_slot_template",
}

export type Sensorflow_Key_Category_Slot_Templates_Insert_Input = {
  acModelId?: Maybe<Scalars["uuid"]>;
  actingMode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  roomTemplate?: Maybe<Sensorflow_Key_Category_Room_Templates_Obj_Rel_Insert_Input>;
  roomTemplateId?: Maybe<Scalars["uuid"]>;
  slotName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Category_Slot_Templates_Max_Fields = {
  __typename?: "sensorflow_key_category_slot_templates_max_fields";
  acModelId?: Maybe<Scalars["uuid"]>;
  actingMode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  roomTemplateId?: Maybe<Scalars["uuid"]>;
  slotName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Category_Slot_Templates_Max_Order_By = {
  acModelId?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  roomTemplateId?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
};

export type Sensorflow_Key_Category_Slot_Templates_Min_Fields = {
  __typename?: "sensorflow_key_category_slot_templates_min_fields";
  acModelId?: Maybe<Scalars["uuid"]>;
  actingMode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  roomTemplateId?: Maybe<Scalars["uuid"]>;
  slotName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Category_Slot_Templates_Min_Order_By = {
  acModelId?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  roomTemplateId?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
};

export type Sensorflow_Key_Category_Slot_Templates_Mutation_Response = {
  __typename?: "sensorflow_key_category_slot_templates_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Key_Category_Slot_Templates>;
};

export type Sensorflow_Key_Category_Slot_Templates_On_Conflict = {
  constraint: Sensorflow_Key_Category_Slot_Templates_Constraint;
  update_columns: Array<Sensorflow_Key_Category_Slot_Templates_Update_Column>;
  where?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
};

export type Sensorflow_Key_Category_Slot_Templates_Order_By = {
  acModelId?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  roomTemplate?: Maybe<Sensorflow_Key_Category_Room_Templates_Order_By>;
  roomTemplateId?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
};

export type Sensorflow_Key_Category_Slot_Templates_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Key_Category_Slot_Templates_Select_Column {
  AcModelId = "acModelId",
  ActingMode = "actingMode",
  Id = "id",
  NodeSubType = "nodeSubType",
  NodeType = "nodeType",
  OperationalMode = "operationalMode",
  RoomTemplateId = "roomTemplateId",
  SlotName = "slotName",
}

export type Sensorflow_Key_Category_Slot_Templates_Set_Input = {
  acModelId?: Maybe<Scalars["uuid"]>;
  actingMode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  roomTemplateId?: Maybe<Scalars["uuid"]>;
  slotName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Category_Slot_Templates_Stream_Cursor_Input = {
  initial_value: Sensorflow_Key_Category_Slot_Templates_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Key_Category_Slot_Templates_Stream_Cursor_Value_Input = {
  acModelId?: Maybe<Scalars["uuid"]>;
  actingMode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  roomTemplateId?: Maybe<Scalars["uuid"]>;
  slotName?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Key_Category_Slot_Templates_Update_Column {
  AcModelId = "acModelId",
  ActingMode = "actingMode",
  Id = "id",
  NodeSubType = "nodeSubType",
  NodeType = "nodeType",
  OperationalMode = "operationalMode",
  RoomTemplateId = "roomTemplateId",
  SlotName = "slotName",
}

export type Sensorflow_Key_Category_Slot_Templates_Updates = {
  _set?: Maybe<Sensorflow_Key_Category_Slot_Templates_Set_Input>;
  where: Sensorflow_Key_Category_Slot_Templates_Bool_Exp;
};

export type Sensorflow_Key_Category_Templates = {
  __typename?: "sensorflow_key_category_templates";
  categoryName: Scalars["String"];
  id: Scalars["uuid"];
  keyCategory?: Maybe<Sensorflow_Key_Categories>;
  location?: Maybe<Sensorflow_Locations>;
  locationName: Scalars["String"];
  roomTemplates: Array<Sensorflow_Key_Category_Room_Templates>;
  roomTemplates_aggregate: Sensorflow_Key_Category_Room_Templates_Aggregate;
};

export type Sensorflow_Key_Category_TemplatesRoomTemplatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
};

export type Sensorflow_Key_Category_TemplatesRoomTemplates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
};

export type Sensorflow_Key_Category_Templates_Aggregate = {
  __typename?: "sensorflow_key_category_templates_aggregate";
  aggregate?: Maybe<Sensorflow_Key_Category_Templates_Aggregate_Fields>;
  nodes: Array<Sensorflow_Key_Category_Templates>;
};

export type Sensorflow_Key_Category_Templates_Aggregate_Fields = {
  __typename?: "sensorflow_key_category_templates_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Key_Category_Templates_Max_Fields>;
  min?: Maybe<Sensorflow_Key_Category_Templates_Min_Fields>;
};

export type Sensorflow_Key_Category_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Key_Category_Templates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Key_Category_Templates_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Key_Category_Templates_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Key_Category_Templates_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Key_Category_Templates_Bool_Exp>>;
  categoryName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  keyCategory?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locationName?: Maybe<String_Comparison_Exp>;
  roomTemplates?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
  roomTemplates_aggregate?: Maybe<Sensorflow_Key_Category_Room_Templates_Aggregate_Bool_Exp>;
};

export enum Sensorflow_Key_Category_Templates_Constraint {
  KeyCategoryTemplatesPkey = "key_category_templates_pkey",
  UniqueCategoryNameLocationName = "unique_category_name_location_name",
}

export type Sensorflow_Key_Category_Templates_Insert_Input = {
  categoryName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  keyCategory?: Maybe<Sensorflow_Key_Categories_Obj_Rel_Insert_Input>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  locationName?: Maybe<Scalars["String"]>;
  roomTemplates?: Maybe<Sensorflow_Key_Category_Room_Templates_Arr_Rel_Insert_Input>;
};

export type Sensorflow_Key_Category_Templates_Max_Fields = {
  __typename?: "sensorflow_key_category_templates_max_fields";
  categoryName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Category_Templates_Min_Fields = {
  __typename?: "sensorflow_key_category_templates_min_fields";
  categoryName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Category_Templates_Mutation_Response = {
  __typename?: "sensorflow_key_category_templates_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Key_Category_Templates>;
};

export type Sensorflow_Key_Category_Templates_Obj_Rel_Insert_Input = {
  data: Sensorflow_Key_Category_Templates_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Key_Category_Templates_On_Conflict>;
};

export type Sensorflow_Key_Category_Templates_On_Conflict = {
  constraint: Sensorflow_Key_Category_Templates_Constraint;
  update_columns: Array<Sensorflow_Key_Category_Templates_Update_Column>;
  where?: Maybe<Sensorflow_Key_Category_Templates_Bool_Exp>;
};

export type Sensorflow_Key_Category_Templates_Order_By = {
  categoryName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyCategory?: Maybe<Sensorflow_Key_Categories_Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  locationName?: Maybe<Order_By>;
  roomTemplates_aggregate?: Maybe<Sensorflow_Key_Category_Room_Templates_Aggregate_Order_By>;
};

export type Sensorflow_Key_Category_Templates_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Key_Category_Templates_Select_Column {
  CategoryName = "categoryName",
  Id = "id",
  LocationName = "locationName",
}

export type Sensorflow_Key_Category_Templates_Set_Input = {
  categoryName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Key_Category_Templates_Stream_Cursor_Input = {
  initial_value: Sensorflow_Key_Category_Templates_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Key_Category_Templates_Stream_Cursor_Value_Input = {
  categoryName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Key_Category_Templates_Update_Column {
  CategoryName = "categoryName",
  Id = "id",
  LocationName = "locationName",
}

export type Sensorflow_Key_Category_Templates_Updates = {
  _set?: Maybe<Sensorflow_Key_Category_Templates_Set_Input>;
  where: Sensorflow_Key_Category_Templates_Bool_Exp;
};

export type Sensorflow_Key_Category_To_Key_Position = {
  __typename?: "sensorflow_key_category_to_key_position";
  categoryName: Scalars["String"];
  keyCategory?: Maybe<Sensorflow_Key_Categories>;
  locationName: Scalars["String"];
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
};

export type Sensorflow_Key_Category_To_Key_Position_Aggregate = {
  __typename?: "sensorflow_key_category_to_key_position_aggregate";
  aggregate?: Maybe<Sensorflow_Key_Category_To_Key_Position_Aggregate_Fields>;
  nodes: Array<Sensorflow_Key_Category_To_Key_Position>;
};

export type Sensorflow_Key_Category_To_Key_Position_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Key_Category_To_Key_Position_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Key_Category_To_Key_Position_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Key_Category_To_Key_Position_Aggregate_Fields = {
  __typename?: "sensorflow_key_category_to_key_position_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Key_Category_To_Key_Position_Max_Fields>;
  min?: Maybe<Sensorflow_Key_Category_To_Key_Position_Min_Fields>;
};

export type Sensorflow_Key_Category_To_Key_Position_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Key_Category_To_Key_Position_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Key_Category_To_Key_Position_Max_Order_By>;
  min?: Maybe<Sensorflow_Key_Category_To_Key_Position_Min_Order_By>;
};

export type Sensorflow_Key_Category_To_Key_Position_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Key_Category_To_Key_Position_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Key_Category_To_Key_Position_On_Conflict>;
};

export type Sensorflow_Key_Category_To_Key_Position_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>>;
  categoryName?: Maybe<String_Comparison_Exp>;
  keyCategory?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
  locationName?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Key_Category_To_Key_Position_Constraint {
  KeyCategoryToKeyPositionPkey = "key_category_to_key_position_pkey",
  UniquePositionId = "unique_position_id",
}

export type Sensorflow_Key_Category_To_Key_Position_Insert_Input = {
  categoryName?: Maybe<Scalars["String"]>;
  keyCategory?: Maybe<Sensorflow_Key_Categories_Obj_Rel_Insert_Input>;
  locationName?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Key_Category_To_Key_Position_Max_Fields = {
  __typename?: "sensorflow_key_category_to_key_position_max_fields";
  categoryName?: Maybe<Scalars["String"]>;
  locationName?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Key_Category_To_Key_Position_Max_Order_By = {
  categoryName?: Maybe<Order_By>;
  locationName?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
};

export type Sensorflow_Key_Category_To_Key_Position_Min_Fields = {
  __typename?: "sensorflow_key_category_to_key_position_min_fields";
  categoryName?: Maybe<Scalars["String"]>;
  locationName?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Key_Category_To_Key_Position_Min_Order_By = {
  categoryName?: Maybe<Order_By>;
  locationName?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
};

export type Sensorflow_Key_Category_To_Key_Position_Mutation_Response = {
  __typename?: "sensorflow_key_category_to_key_position_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Key_Category_To_Key_Position>;
};

export type Sensorflow_Key_Category_To_Key_Position_Obj_Rel_Insert_Input = {
  data: Sensorflow_Key_Category_To_Key_Position_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Key_Category_To_Key_Position_On_Conflict>;
};

export type Sensorflow_Key_Category_To_Key_Position_On_Conflict = {
  constraint: Sensorflow_Key_Category_To_Key_Position_Constraint;
  update_columns: Array<Sensorflow_Key_Category_To_Key_Position_Update_Column>;
  where?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
};

export type Sensorflow_Key_Category_To_Key_Position_Order_By = {
  categoryName?: Maybe<Order_By>;
  keyCategory?: Maybe<Sensorflow_Key_Categories_Order_By>;
  locationName?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
};

export type Sensorflow_Key_Category_To_Key_Position_Pk_Columns_Input = {
  categoryName: Scalars["String"];
  locationName: Scalars["String"];
  positionId: Scalars["uuid"];
};

export enum Sensorflow_Key_Category_To_Key_Position_Select_Column {
  CategoryName = "categoryName",
  LocationName = "locationName",
  PositionId = "positionId",
}

export type Sensorflow_Key_Category_To_Key_Position_Set_Input = {
  categoryName?: Maybe<Scalars["String"]>;
  locationName?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Key_Category_To_Key_Position_Stream_Cursor_Input = {
  initial_value: Sensorflow_Key_Category_To_Key_Position_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Key_Category_To_Key_Position_Stream_Cursor_Value_Input = {
  categoryName?: Maybe<Scalars["String"]>;
  locationName?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export enum Sensorflow_Key_Category_To_Key_Position_Update_Column {
  CategoryName = "categoryName",
  LocationName = "locationName",
  PositionId = "positionId",
}

export type Sensorflow_Key_Category_To_Key_Position_Updates = {
  _set?: Maybe<Sensorflow_Key_Category_To_Key_Position_Set_Input>;
  where: Sensorflow_Key_Category_To_Key_Position_Bool_Exp;
};

export type Sensorflow_Location_Addresses = {
  __typename?: "sensorflow_location_addresses";
  address1: Scalars["String"];
  address2: Scalars["String"];
  city: Scalars["String"];
  country: Scalars["String"];
  location_id: Scalars["uuid"];
  zipCode: Scalars["Int"];
};

export type Sensorflow_Location_Addresses_Aggregate = {
  __typename?: "sensorflow_location_addresses_aggregate";
  aggregate?: Maybe<Sensorflow_Location_Addresses_Aggregate_Fields>;
  nodes: Array<Sensorflow_Location_Addresses>;
};

export type Sensorflow_Location_Addresses_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Location_Addresses_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Location_Addresses_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Location_Addresses_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Location_Addresses_Aggregate_Fields = {
  __typename?: "sensorflow_location_addresses_aggregate_fields";
  avg?: Maybe<Sensorflow_Location_Addresses_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Location_Addresses_Max_Fields>;
  min?: Maybe<Sensorflow_Location_Addresses_Min_Fields>;
  stddev?: Maybe<Sensorflow_Location_Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Location_Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Location_Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Location_Addresses_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Location_Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Location_Addresses_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Location_Addresses_Variance_Fields>;
};

export type Sensorflow_Location_Addresses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Location_Addresses_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Location_Addresses_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Location_Addresses_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Location_Addresses_Max_Order_By>;
  min?: Maybe<Sensorflow_Location_Addresses_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Location_Addresses_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Location_Addresses_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Location_Addresses_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Location_Addresses_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Location_Addresses_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Location_Addresses_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Location_Addresses_Variance_Order_By>;
};

export type Sensorflow_Location_Addresses_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Location_Addresses_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Location_Addresses_On_Conflict>;
};

export type Sensorflow_Location_Addresses_Avg_Fields = {
  __typename?: "sensorflow_location_addresses_avg_fields";
  zipCode?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Addresses_Avg_Order_By = {
  zipCode?: Maybe<Order_By>;
};

export type Sensorflow_Location_Addresses_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Location_Addresses_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Location_Addresses_Bool_Exp>>;
  address1?: Maybe<String_Comparison_Exp>;
  address2?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  zipCode?: Maybe<Int_Comparison_Exp>;
};

export enum Sensorflow_Location_Addresses_Constraint {
  LocationAddressesPkey = "location_addresses_pkey",
}

export type Sensorflow_Location_Addresses_Inc_Input = {
  zipCode?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Location_Addresses_Insert_Input = {
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  zipCode?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Location_Addresses_Max_Fields = {
  __typename?: "sensorflow_location_addresses_max_fields";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  zipCode?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Location_Addresses_Max_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

export type Sensorflow_Location_Addresses_Min_Fields = {
  __typename?: "sensorflow_location_addresses_min_fields";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  zipCode?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Location_Addresses_Min_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

export type Sensorflow_Location_Addresses_Mutation_Response = {
  __typename?: "sensorflow_location_addresses_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Location_Addresses>;
};

export type Sensorflow_Location_Addresses_On_Conflict = {
  constraint: Sensorflow_Location_Addresses_Constraint;
  update_columns: Array<Sensorflow_Location_Addresses_Update_Column>;
  where?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
};

export type Sensorflow_Location_Addresses_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

export type Sensorflow_Location_Addresses_Pk_Columns_Input = {
  location_id: Scalars["uuid"];
};

export enum Sensorflow_Location_Addresses_Select_Column {
  Address1 = "address1",
  Address2 = "address2",
  City = "city",
  Country = "country",
  LocationId = "location_id",
  ZipCode = "zipCode",
}

export type Sensorflow_Location_Addresses_Set_Input = {
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  zipCode?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Location_Addresses_Stddev_Fields = {
  __typename?: "sensorflow_location_addresses_stddev_fields";
  zipCode?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Addresses_Stddev_Order_By = {
  zipCode?: Maybe<Order_By>;
};

export type Sensorflow_Location_Addresses_Stddev_Pop_Fields = {
  __typename?: "sensorflow_location_addresses_stddev_pop_fields";
  zipCode?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Addresses_Stddev_Pop_Order_By = {
  zipCode?: Maybe<Order_By>;
};

export type Sensorflow_Location_Addresses_Stddev_Samp_Fields = {
  __typename?: "sensorflow_location_addresses_stddev_samp_fields";
  zipCode?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Addresses_Stddev_Samp_Order_By = {
  zipCode?: Maybe<Order_By>;
};

export type Sensorflow_Location_Addresses_Stream_Cursor_Input = {
  initial_value: Sensorflow_Location_Addresses_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Location_Addresses_Stream_Cursor_Value_Input = {
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  zipCode?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Location_Addresses_Sum_Fields = {
  __typename?: "sensorflow_location_addresses_sum_fields";
  zipCode?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Location_Addresses_Sum_Order_By = {
  zipCode?: Maybe<Order_By>;
};

export enum Sensorflow_Location_Addresses_Update_Column {
  Address1 = "address1",
  Address2 = "address2",
  City = "city",
  Country = "country",
  LocationId = "location_id",
  ZipCode = "zipCode",
}

export type Sensorflow_Location_Addresses_Updates = {
  _inc?: Maybe<Sensorflow_Location_Addresses_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Addresses_Set_Input>;
  where: Sensorflow_Location_Addresses_Bool_Exp;
};

export type Sensorflow_Location_Addresses_Var_Pop_Fields = {
  __typename?: "sensorflow_location_addresses_var_pop_fields";
  zipCode?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Addresses_Var_Pop_Order_By = {
  zipCode?: Maybe<Order_By>;
};

export type Sensorflow_Location_Addresses_Var_Samp_Fields = {
  __typename?: "sensorflow_location_addresses_var_samp_fields";
  zipCode?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Addresses_Var_Samp_Order_By = {
  zipCode?: Maybe<Order_By>;
};

export type Sensorflow_Location_Addresses_Variance_Fields = {
  __typename?: "sensorflow_location_addresses_variance_fields";
  zipCode?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Addresses_Variance_Order_By = {
  zipCode?: Maybe<Order_By>;
};

export type Sensorflow_Location_Consumptions = {
  __typename?: "sensorflow_location_consumptions";
  actualValue?: Maybe<Scalars["numeric"]>;
  clientForecast?: Maybe<Scalars["numeric"]>;
  consumptionPeriodEnd: Scalars["date"];
  consumptionPeriodStart: Scalars["date"];
  locationId: Scalars["uuid"];
  metricTypeId: Scalars["Int"];
  sensorflowForecast?: Maybe<Scalars["numeric"]>;
  sourceId: Scalars["String"];
  sourceType: Scalars["String"];
  updatedAt: Scalars["date"];
};

export type Sensorflow_Location_Consumptions_Aggregate = {
  __typename?: "sensorflow_location_consumptions_aggregate";
  aggregate?: Maybe<Sensorflow_Location_Consumptions_Aggregate_Fields>;
  nodes: Array<Sensorflow_Location_Consumptions>;
};

export type Sensorflow_Location_Consumptions_Aggregate_Fields = {
  __typename?: "sensorflow_location_consumptions_aggregate_fields";
  avg?: Maybe<Sensorflow_Location_Consumptions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Location_Consumptions_Max_Fields>;
  min?: Maybe<Sensorflow_Location_Consumptions_Min_Fields>;
  stddev?: Maybe<Sensorflow_Location_Consumptions_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Location_Consumptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Location_Consumptions_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Location_Consumptions_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Location_Consumptions_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Location_Consumptions_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Location_Consumptions_Variance_Fields>;
};

export type Sensorflow_Location_Consumptions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Location_Consumptions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Location_Consumptions_Avg_Fields = {
  __typename?: "sensorflow_location_consumptions_avg_fields";
  actualValue?: Maybe<Scalars["Float"]>;
  clientForecast?: Maybe<Scalars["Float"]>;
  metricTypeId?: Maybe<Scalars["Float"]>;
  sensorflowForecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Consumptions_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Location_Consumptions_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Location_Consumptions_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Location_Consumptions_Bool_Exp>>;
  actualValue?: Maybe<Numeric_Comparison_Exp>;
  clientForecast?: Maybe<Numeric_Comparison_Exp>;
  consumptionPeriodEnd?: Maybe<Date_Comparison_Exp>;
  consumptionPeriodStart?: Maybe<Date_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  metricTypeId?: Maybe<Int_Comparison_Exp>;
  sensorflowForecast?: Maybe<Numeric_Comparison_Exp>;
  sourceId?: Maybe<String_Comparison_Exp>;
  sourceType?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Date_Comparison_Exp>;
};

export enum Sensorflow_Location_Consumptions_Constraint {
  LocationConsumptionsPkey = "location_consumptions_pkey",
}

export type Sensorflow_Location_Consumptions_Inc_Input = {
  actualValue?: Maybe<Scalars["numeric"]>;
  clientForecast?: Maybe<Scalars["numeric"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
  sensorflowForecast?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Location_Consumptions_Insert_Input = {
  actualValue?: Maybe<Scalars["numeric"]>;
  clientForecast?: Maybe<Scalars["numeric"]>;
  consumptionPeriodEnd?: Maybe<Scalars["date"]>;
  consumptionPeriodStart?: Maybe<Scalars["date"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
  sensorflowForecast?: Maybe<Scalars["numeric"]>;
  sourceId?: Maybe<Scalars["String"]>;
  sourceType?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["date"]>;
};

export type Sensorflow_Location_Consumptions_Max_Fields = {
  __typename?: "sensorflow_location_consumptions_max_fields";
  actualValue?: Maybe<Scalars["numeric"]>;
  clientForecast?: Maybe<Scalars["numeric"]>;
  consumptionPeriodEnd?: Maybe<Scalars["date"]>;
  consumptionPeriodStart?: Maybe<Scalars["date"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
  sensorflowForecast?: Maybe<Scalars["numeric"]>;
  sourceId?: Maybe<Scalars["String"]>;
  sourceType?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["date"]>;
};

export type Sensorflow_Location_Consumptions_Min_Fields = {
  __typename?: "sensorflow_location_consumptions_min_fields";
  actualValue?: Maybe<Scalars["numeric"]>;
  clientForecast?: Maybe<Scalars["numeric"]>;
  consumptionPeriodEnd?: Maybe<Scalars["date"]>;
  consumptionPeriodStart?: Maybe<Scalars["date"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
  sensorflowForecast?: Maybe<Scalars["numeric"]>;
  sourceId?: Maybe<Scalars["String"]>;
  sourceType?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["date"]>;
};

export type Sensorflow_Location_Consumptions_Mutation_Response = {
  __typename?: "sensorflow_location_consumptions_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Location_Consumptions>;
};

export type Sensorflow_Location_Consumptions_On_Conflict = {
  constraint: Sensorflow_Location_Consumptions_Constraint;
  update_columns: Array<Sensorflow_Location_Consumptions_Update_Column>;
  where?: Maybe<Sensorflow_Location_Consumptions_Bool_Exp>;
};

export type Sensorflow_Location_Consumptions_Order_By = {
  actualValue?: Maybe<Order_By>;
  clientForecast?: Maybe<Order_By>;
  consumptionPeriodEnd?: Maybe<Order_By>;
  consumptionPeriodStart?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  metricTypeId?: Maybe<Order_By>;
  sensorflowForecast?: Maybe<Order_By>;
  sourceId?: Maybe<Order_By>;
  sourceType?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

export type Sensorflow_Location_Consumptions_Pk_Columns_Input = {
  consumptionPeriodEnd: Scalars["date"];
  consumptionPeriodStart: Scalars["date"];
  locationId: Scalars["uuid"];
  metricTypeId: Scalars["Int"];
};

export enum Sensorflow_Location_Consumptions_Select_Column {
  ActualValue = "actualValue",
  ClientForecast = "clientForecast",
  ConsumptionPeriodEnd = "consumptionPeriodEnd",
  ConsumptionPeriodStart = "consumptionPeriodStart",
  LocationId = "locationId",
  MetricTypeId = "metricTypeId",
  SensorflowForecast = "sensorflowForecast",
  SourceId = "sourceId",
  SourceType = "sourceType",
  UpdatedAt = "updatedAt",
}

export type Sensorflow_Location_Consumptions_Set_Input = {
  actualValue?: Maybe<Scalars["numeric"]>;
  clientForecast?: Maybe<Scalars["numeric"]>;
  consumptionPeriodEnd?: Maybe<Scalars["date"]>;
  consumptionPeriodStart?: Maybe<Scalars["date"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
  sensorflowForecast?: Maybe<Scalars["numeric"]>;
  sourceId?: Maybe<Scalars["String"]>;
  sourceType?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["date"]>;
};

export type Sensorflow_Location_Consumptions_Stddev_Fields = {
  __typename?: "sensorflow_location_consumptions_stddev_fields";
  actualValue?: Maybe<Scalars["Float"]>;
  clientForecast?: Maybe<Scalars["Float"]>;
  metricTypeId?: Maybe<Scalars["Float"]>;
  sensorflowForecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Consumptions_Stddev_Pop_Fields = {
  __typename?: "sensorflow_location_consumptions_stddev_pop_fields";
  actualValue?: Maybe<Scalars["Float"]>;
  clientForecast?: Maybe<Scalars["Float"]>;
  metricTypeId?: Maybe<Scalars["Float"]>;
  sensorflowForecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Consumptions_Stddev_Samp_Fields = {
  __typename?: "sensorflow_location_consumptions_stddev_samp_fields";
  actualValue?: Maybe<Scalars["Float"]>;
  clientForecast?: Maybe<Scalars["Float"]>;
  metricTypeId?: Maybe<Scalars["Float"]>;
  sensorflowForecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Consumptions_Stream_Cursor_Input = {
  initial_value: Sensorflow_Location_Consumptions_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Location_Consumptions_Stream_Cursor_Value_Input = {
  actualValue?: Maybe<Scalars["numeric"]>;
  clientForecast?: Maybe<Scalars["numeric"]>;
  consumptionPeriodEnd?: Maybe<Scalars["date"]>;
  consumptionPeriodStart?: Maybe<Scalars["date"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
  sensorflowForecast?: Maybe<Scalars["numeric"]>;
  sourceId?: Maybe<Scalars["String"]>;
  sourceType?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["date"]>;
};

export type Sensorflow_Location_Consumptions_Sum_Fields = {
  __typename?: "sensorflow_location_consumptions_sum_fields";
  actualValue?: Maybe<Scalars["numeric"]>;
  clientForecast?: Maybe<Scalars["numeric"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
  sensorflowForecast?: Maybe<Scalars["numeric"]>;
};

export enum Sensorflow_Location_Consumptions_Update_Column {
  ActualValue = "actualValue",
  ClientForecast = "clientForecast",
  ConsumptionPeriodEnd = "consumptionPeriodEnd",
  ConsumptionPeriodStart = "consumptionPeriodStart",
  LocationId = "locationId",
  MetricTypeId = "metricTypeId",
  SensorflowForecast = "sensorflowForecast",
  SourceId = "sourceId",
  SourceType = "sourceType",
  UpdatedAt = "updatedAt",
}

export type Sensorflow_Location_Consumptions_Updates = {
  _inc?: Maybe<Sensorflow_Location_Consumptions_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Consumptions_Set_Input>;
  where: Sensorflow_Location_Consumptions_Bool_Exp;
};

export type Sensorflow_Location_Consumptions_Var_Pop_Fields = {
  __typename?: "sensorflow_location_consumptions_var_pop_fields";
  actualValue?: Maybe<Scalars["Float"]>;
  clientForecast?: Maybe<Scalars["Float"]>;
  metricTypeId?: Maybe<Scalars["Float"]>;
  sensorflowForecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Consumptions_Var_Samp_Fields = {
  __typename?: "sensorflow_location_consumptions_var_samp_fields";
  actualValue?: Maybe<Scalars["Float"]>;
  clientForecast?: Maybe<Scalars["Float"]>;
  metricTypeId?: Maybe<Scalars["Float"]>;
  sensorflowForecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Consumptions_Variance_Fields = {
  __typename?: "sensorflow_location_consumptions_variance_fields";
  actualValue?: Maybe<Scalars["Float"]>;
  clientForecast?: Maybe<Scalars["Float"]>;
  metricTypeId?: Maybe<Scalars["Float"]>;
  sensorflowForecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Domo_Embeds = {
  __typename?: "sensorflow_location_domo_embeds";
  domo_embed: Sensorflow_Domo_Embeds;
  embed_id: Scalars["String"];
  location: Sensorflow_Locations;
  location_id: Scalars["uuid"];
  location_to_user_mappings: Array<Sensorflow_Location_To_User_Mapping>;
  location_to_user_mappings_aggregate: Sensorflow_Location_To_User_Mapping_Aggregate;
};

export type Sensorflow_Location_Domo_EmbedsLocation_To_User_MappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Sensorflow_Location_Domo_EmbedsLocation_To_User_Mappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Sensorflow_Location_Domo_Embeds_Aggregate = {
  __typename?: "sensorflow_location_domo_embeds_aggregate";
  aggregate?: Maybe<Sensorflow_Location_Domo_Embeds_Aggregate_Fields>;
  nodes: Array<Sensorflow_Location_Domo_Embeds>;
};

export type Sensorflow_Location_Domo_Embeds_Aggregate_Fields = {
  __typename?: "sensorflow_location_domo_embeds_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Location_Domo_Embeds_Max_Fields>;
  min?: Maybe<Sensorflow_Location_Domo_Embeds_Min_Fields>;
};

export type Sensorflow_Location_Domo_Embeds_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Location_Domo_Embeds_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Location_Domo_Embeds_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Bool_Exp>>;
  domo_embed?: Maybe<Sensorflow_Domo_Embeds_Bool_Exp>;
  embed_id?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  location_to_user_mappings?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
  location_to_user_mappings_aggregate?: Maybe<Sensorflow_Location_To_User_Mapping_Aggregate_Bool_Exp>;
};

export enum Sensorflow_Location_Domo_Embeds_Constraint {
  LocationDomoEmbedsPkey = "location_domo_embeds_pkey",
}

export type Sensorflow_Location_Domo_Embeds_Insert_Input = {
  domo_embed?: Maybe<Sensorflow_Domo_Embeds_Obj_Rel_Insert_Input>;
  embed_id?: Maybe<Scalars["String"]>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_to_user_mappings?: Maybe<Sensorflow_Location_To_User_Mapping_Arr_Rel_Insert_Input>;
};

export type Sensorflow_Location_Domo_Embeds_Max_Fields = {
  __typename?: "sensorflow_location_domo_embeds_max_fields";
  embed_id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Location_Domo_Embeds_Min_Fields = {
  __typename?: "sensorflow_location_domo_embeds_min_fields";
  embed_id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Location_Domo_Embeds_Mutation_Response = {
  __typename?: "sensorflow_location_domo_embeds_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Location_Domo_Embeds>;
};

export type Sensorflow_Location_Domo_Embeds_On_Conflict = {
  constraint: Sensorflow_Location_Domo_Embeds_Constraint;
  update_columns: Array<Sensorflow_Location_Domo_Embeds_Update_Column>;
  where?: Maybe<Sensorflow_Location_Domo_Embeds_Bool_Exp>;
};

export type Sensorflow_Location_Domo_Embeds_Order_By = {
  domo_embed?: Maybe<Sensorflow_Domo_Embeds_Order_By>;
  embed_id?: Maybe<Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  location_to_user_mappings_aggregate?: Maybe<Sensorflow_Location_To_User_Mapping_Aggregate_Order_By>;
};

export type Sensorflow_Location_Domo_Embeds_Pk_Columns_Input = {
  embed_id: Scalars["String"];
  location_id: Scalars["uuid"];
};

export enum Sensorflow_Location_Domo_Embeds_Select_Column {
  EmbedId = "embed_id",
  LocationId = "location_id",
}

export type Sensorflow_Location_Domo_Embeds_Set_Input = {
  embed_id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Location_Domo_Embeds_Stream_Cursor_Input = {
  initial_value: Sensorflow_Location_Domo_Embeds_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Location_Domo_Embeds_Stream_Cursor_Value_Input = {
  embed_id?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
};

export enum Sensorflow_Location_Domo_Embeds_Update_Column {
  EmbedId = "embed_id",
  LocationId = "location_id",
}

export type Sensorflow_Location_Domo_Embeds_Updates = {
  _set?: Maybe<Sensorflow_Location_Domo_Embeds_Set_Input>;
  where: Sensorflow_Location_Domo_Embeds_Bool_Exp;
};

export type Sensorflow_Location_Metadata = {
  __typename?: "sensorflow_location_metadata";
  activeAutosetCount?: Maybe<Scalars["Int"]>;
  allowDisableAutomationMode?: Maybe<Scalars["Boolean"]>;
  availableOperationalModes?: Maybe<Scalars["String"]>;
  beaconSlotManagement?: Maybe<Scalars["Boolean"]>;
  enableNoKeyCardState?: Maybe<Scalars["Boolean"]>;
  gatewayHardwareType?: Maybe<Scalars["String"]>;
  gatewayPromiscuousModeEnabled?: Maybe<Scalars["Boolean"]>;
  hasKeyCard?: Maybe<Scalars["Boolean"]>;
  isAutomationModeDisabled?: Maybe<Scalars["Boolean"]>;
  isTimeBasedConfigOnboarded?: Maybe<Scalars["Boolean"]>;
  location: Sensorflow_Locations;
  locationId: Scalars["uuid"];
  location_to_user_mappings: Array<Sensorflow_Location_To_User_Mapping>;
  location_to_user_mappings_aggregate: Sensorflow_Location_To_User_Mapping_Aggregate;
  secondaryWifiPassword?: Maybe<Scalars["String"]>;
  secondaryWifiSsid?: Maybe<Scalars["String"]>;
  showEnergyConsumption?: Maybe<Scalars["Boolean"]>;
  showHeatingMode?: Maybe<Scalars["Boolean"]>;
  smartRemValidation?: Maybe<Scalars["Boolean"]>;
  smplrspaceId?: Maybe<Scalars["uuid"]>;
  subscription?: Maybe<Scalars["String"]>;
  wifiPassword?: Maybe<Scalars["String"]>;
  wifiSsid?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Location_MetadataLocation_To_User_MappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Sensorflow_Location_MetadataLocation_To_User_Mappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Sensorflow_Location_Metadata_Aggregate = {
  __typename?: "sensorflow_location_metadata_aggregate";
  aggregate?: Maybe<Sensorflow_Location_Metadata_Aggregate_Fields>;
  nodes: Array<Sensorflow_Location_Metadata>;
};

export type Sensorflow_Location_Metadata_Aggregate_Fields = {
  __typename?: "sensorflow_location_metadata_aggregate_fields";
  avg?: Maybe<Sensorflow_Location_Metadata_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Location_Metadata_Max_Fields>;
  min?: Maybe<Sensorflow_Location_Metadata_Min_Fields>;
  stddev?: Maybe<Sensorflow_Location_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Location_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Location_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Location_Metadata_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Location_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Location_Metadata_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Location_Metadata_Variance_Fields>;
};

export type Sensorflow_Location_Metadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Location_Metadata_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Location_Metadata_Avg_Fields = {
  __typename?: "sensorflow_location_metadata_avg_fields";
  activeAutosetCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Metadata_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Location_Metadata_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Location_Metadata_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Location_Metadata_Bool_Exp>>;
  activeAutosetCount?: Maybe<Int_Comparison_Exp>;
  allowDisableAutomationMode?: Maybe<Boolean_Comparison_Exp>;
  availableOperationalModes?: Maybe<String_Comparison_Exp>;
  beaconSlotManagement?: Maybe<Boolean_Comparison_Exp>;
  enableNoKeyCardState?: Maybe<Boolean_Comparison_Exp>;
  gatewayHardwareType?: Maybe<String_Comparison_Exp>;
  gatewayPromiscuousModeEnabled?: Maybe<Boolean_Comparison_Exp>;
  hasKeyCard?: Maybe<Boolean_Comparison_Exp>;
  isAutomationModeDisabled?: Maybe<Boolean_Comparison_Exp>;
  isTimeBasedConfigOnboarded?: Maybe<Boolean_Comparison_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  location_to_user_mappings?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
  location_to_user_mappings_aggregate?: Maybe<Sensorflow_Location_To_User_Mapping_Aggregate_Bool_Exp>;
  secondaryWifiPassword?: Maybe<String_Comparison_Exp>;
  secondaryWifiSsid?: Maybe<String_Comparison_Exp>;
  showEnergyConsumption?: Maybe<Boolean_Comparison_Exp>;
  showHeatingMode?: Maybe<Boolean_Comparison_Exp>;
  smartRemValidation?: Maybe<Boolean_Comparison_Exp>;
  smplrspaceId?: Maybe<Uuid_Comparison_Exp>;
  subscription?: Maybe<String_Comparison_Exp>;
  wifiPassword?: Maybe<String_Comparison_Exp>;
  wifiSsid?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Location_Metadata_Constraint {
  LocationMetadataPkey = "location_metadata_pkey",
}

export type Sensorflow_Location_Metadata_Inc_Input = {
  activeAutosetCount?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Location_Metadata_Insert_Input = {
  activeAutosetCount?: Maybe<Scalars["Int"]>;
  allowDisableAutomationMode?: Maybe<Scalars["Boolean"]>;
  availableOperationalModes?: Maybe<Scalars["String"]>;
  beaconSlotManagement?: Maybe<Scalars["Boolean"]>;
  enableNoKeyCardState?: Maybe<Scalars["Boolean"]>;
  gatewayHardwareType?: Maybe<Scalars["String"]>;
  gatewayPromiscuousModeEnabled?: Maybe<Scalars["Boolean"]>;
  hasKeyCard?: Maybe<Scalars["Boolean"]>;
  isAutomationModeDisabled?: Maybe<Scalars["Boolean"]>;
  isTimeBasedConfigOnboarded?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars["uuid"]>;
  location_to_user_mappings?: Maybe<Sensorflow_Location_To_User_Mapping_Arr_Rel_Insert_Input>;
  secondaryWifiPassword?: Maybe<Scalars["String"]>;
  secondaryWifiSsid?: Maybe<Scalars["String"]>;
  showEnergyConsumption?: Maybe<Scalars["Boolean"]>;
  showHeatingMode?: Maybe<Scalars["Boolean"]>;
  smartRemValidation?: Maybe<Scalars["Boolean"]>;
  smplrspaceId?: Maybe<Scalars["uuid"]>;
  subscription?: Maybe<Scalars["String"]>;
  wifiPassword?: Maybe<Scalars["String"]>;
  wifiSsid?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Location_Metadata_Max_Fields = {
  __typename?: "sensorflow_location_metadata_max_fields";
  activeAutosetCount?: Maybe<Scalars["Int"]>;
  availableOperationalModes?: Maybe<Scalars["String"]>;
  gatewayHardwareType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  secondaryWifiPassword?: Maybe<Scalars["String"]>;
  secondaryWifiSsid?: Maybe<Scalars["String"]>;
  smplrspaceId?: Maybe<Scalars["uuid"]>;
  subscription?: Maybe<Scalars["String"]>;
  wifiPassword?: Maybe<Scalars["String"]>;
  wifiSsid?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Location_Metadata_Min_Fields = {
  __typename?: "sensorflow_location_metadata_min_fields";
  activeAutosetCount?: Maybe<Scalars["Int"]>;
  availableOperationalModes?: Maybe<Scalars["String"]>;
  gatewayHardwareType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  secondaryWifiPassword?: Maybe<Scalars["String"]>;
  secondaryWifiSsid?: Maybe<Scalars["String"]>;
  smplrspaceId?: Maybe<Scalars["uuid"]>;
  subscription?: Maybe<Scalars["String"]>;
  wifiPassword?: Maybe<Scalars["String"]>;
  wifiSsid?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Location_Metadata_Mutation_Response = {
  __typename?: "sensorflow_location_metadata_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Location_Metadata>;
};

export type Sensorflow_Location_Metadata_Obj_Rel_Insert_Input = {
  data: Sensorflow_Location_Metadata_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Location_Metadata_On_Conflict>;
};

export type Sensorflow_Location_Metadata_On_Conflict = {
  constraint: Sensorflow_Location_Metadata_Constraint;
  update_columns: Array<Sensorflow_Location_Metadata_Update_Column>;
  where?: Maybe<Sensorflow_Location_Metadata_Bool_Exp>;
};

export type Sensorflow_Location_Metadata_Order_By = {
  activeAutosetCount?: Maybe<Order_By>;
  allowDisableAutomationMode?: Maybe<Order_By>;
  availableOperationalModes?: Maybe<Order_By>;
  beaconSlotManagement?: Maybe<Order_By>;
  enableNoKeyCardState?: Maybe<Order_By>;
  gatewayHardwareType?: Maybe<Order_By>;
  gatewayPromiscuousModeEnabled?: Maybe<Order_By>;
  hasKeyCard?: Maybe<Order_By>;
  isAutomationModeDisabled?: Maybe<Order_By>;
  isTimeBasedConfigOnboarded?: Maybe<Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  location_to_user_mappings_aggregate?: Maybe<Sensorflow_Location_To_User_Mapping_Aggregate_Order_By>;
  secondaryWifiPassword?: Maybe<Order_By>;
  secondaryWifiSsid?: Maybe<Order_By>;
  showEnergyConsumption?: Maybe<Order_By>;
  showHeatingMode?: Maybe<Order_By>;
  smartRemValidation?: Maybe<Order_By>;
  smplrspaceId?: Maybe<Order_By>;
  subscription?: Maybe<Order_By>;
  wifiPassword?: Maybe<Order_By>;
  wifiSsid?: Maybe<Order_By>;
};

export type Sensorflow_Location_Metadata_Pk_Columns_Input = {
  locationId: Scalars["uuid"];
};

export enum Sensorflow_Location_Metadata_Select_Column {
  ActiveAutosetCount = "activeAutosetCount",
  AllowDisableAutomationMode = "allowDisableAutomationMode",
  AvailableOperationalModes = "availableOperationalModes",
  BeaconSlotManagement = "beaconSlotManagement",
  EnableNoKeyCardState = "enableNoKeyCardState",
  GatewayHardwareType = "gatewayHardwareType",
  GatewayPromiscuousModeEnabled = "gatewayPromiscuousModeEnabled",
  HasKeyCard = "hasKeyCard",
  IsAutomationModeDisabled = "isAutomationModeDisabled",
  IsTimeBasedConfigOnboarded = "isTimeBasedConfigOnboarded",
  LocationId = "locationId",
  SecondaryWifiPassword = "secondaryWifiPassword",
  SecondaryWifiSsid = "secondaryWifiSsid",
  ShowEnergyConsumption = "showEnergyConsumption",
  ShowHeatingMode = "showHeatingMode",
  SmartRemValidation = "smartRemValidation",
  SmplrspaceId = "smplrspaceId",
  Subscription = "subscription",
  WifiPassword = "wifiPassword",
  WifiSsid = "wifiSsid",
}

export type Sensorflow_Location_Metadata_Set_Input = {
  activeAutosetCount?: Maybe<Scalars["Int"]>;
  allowDisableAutomationMode?: Maybe<Scalars["Boolean"]>;
  availableOperationalModes?: Maybe<Scalars["String"]>;
  beaconSlotManagement?: Maybe<Scalars["Boolean"]>;
  enableNoKeyCardState?: Maybe<Scalars["Boolean"]>;
  gatewayHardwareType?: Maybe<Scalars["String"]>;
  gatewayPromiscuousModeEnabled?: Maybe<Scalars["Boolean"]>;
  hasKeyCard?: Maybe<Scalars["Boolean"]>;
  isAutomationModeDisabled?: Maybe<Scalars["Boolean"]>;
  isTimeBasedConfigOnboarded?: Maybe<Scalars["Boolean"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  secondaryWifiPassword?: Maybe<Scalars["String"]>;
  secondaryWifiSsid?: Maybe<Scalars["String"]>;
  showEnergyConsumption?: Maybe<Scalars["Boolean"]>;
  showHeatingMode?: Maybe<Scalars["Boolean"]>;
  smartRemValidation?: Maybe<Scalars["Boolean"]>;
  smplrspaceId?: Maybe<Scalars["uuid"]>;
  subscription?: Maybe<Scalars["String"]>;
  wifiPassword?: Maybe<Scalars["String"]>;
  wifiSsid?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Location_Metadata_Stddev_Fields = {
  __typename?: "sensorflow_location_metadata_stddev_fields";
  activeAutosetCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Metadata_Stddev_Pop_Fields = {
  __typename?: "sensorflow_location_metadata_stddev_pop_fields";
  activeAutosetCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Metadata_Stddev_Samp_Fields = {
  __typename?: "sensorflow_location_metadata_stddev_samp_fields";
  activeAutosetCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Metadata_Stream_Cursor_Input = {
  initial_value: Sensorflow_Location_Metadata_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Location_Metadata_Stream_Cursor_Value_Input = {
  activeAutosetCount?: Maybe<Scalars["Int"]>;
  allowDisableAutomationMode?: Maybe<Scalars["Boolean"]>;
  availableOperationalModes?: Maybe<Scalars["String"]>;
  beaconSlotManagement?: Maybe<Scalars["Boolean"]>;
  enableNoKeyCardState?: Maybe<Scalars["Boolean"]>;
  gatewayHardwareType?: Maybe<Scalars["String"]>;
  gatewayPromiscuousModeEnabled?: Maybe<Scalars["Boolean"]>;
  hasKeyCard?: Maybe<Scalars["Boolean"]>;
  isAutomationModeDisabled?: Maybe<Scalars["Boolean"]>;
  isTimeBasedConfigOnboarded?: Maybe<Scalars["Boolean"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  secondaryWifiPassword?: Maybe<Scalars["String"]>;
  secondaryWifiSsid?: Maybe<Scalars["String"]>;
  showEnergyConsumption?: Maybe<Scalars["Boolean"]>;
  showHeatingMode?: Maybe<Scalars["Boolean"]>;
  smartRemValidation?: Maybe<Scalars["Boolean"]>;
  smplrspaceId?: Maybe<Scalars["uuid"]>;
  subscription?: Maybe<Scalars["String"]>;
  wifiPassword?: Maybe<Scalars["String"]>;
  wifiSsid?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Location_Metadata_Sum_Fields = {
  __typename?: "sensorflow_location_metadata_sum_fields";
  activeAutosetCount?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Location_Metadata_Update_Column {
  ActiveAutosetCount = "activeAutosetCount",
  AllowDisableAutomationMode = "allowDisableAutomationMode",
  AvailableOperationalModes = "availableOperationalModes",
  BeaconSlotManagement = "beaconSlotManagement",
  EnableNoKeyCardState = "enableNoKeyCardState",
  GatewayHardwareType = "gatewayHardwareType",
  GatewayPromiscuousModeEnabled = "gatewayPromiscuousModeEnabled",
  HasKeyCard = "hasKeyCard",
  IsAutomationModeDisabled = "isAutomationModeDisabled",
  IsTimeBasedConfigOnboarded = "isTimeBasedConfigOnboarded",
  LocationId = "locationId",
  SecondaryWifiPassword = "secondaryWifiPassword",
  SecondaryWifiSsid = "secondaryWifiSsid",
  ShowEnergyConsumption = "showEnergyConsumption",
  ShowHeatingMode = "showHeatingMode",
  SmartRemValidation = "smartRemValidation",
  SmplrspaceId = "smplrspaceId",
  Subscription = "subscription",
  WifiPassword = "wifiPassword",
  WifiSsid = "wifiSsid",
}

export type Sensorflow_Location_Metadata_Updates = {
  _inc?: Maybe<Sensorflow_Location_Metadata_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Metadata_Set_Input>;
  where: Sensorflow_Location_Metadata_Bool_Exp;
};

export type Sensorflow_Location_Metadata_Var_Pop_Fields = {
  __typename?: "sensorflow_location_metadata_var_pop_fields";
  activeAutosetCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Metadata_Var_Samp_Fields = {
  __typename?: "sensorflow_location_metadata_var_samp_fields";
  activeAutosetCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Metadata_Variance_Fields = {
  __typename?: "sensorflow_location_metadata_variance_fields";
  activeAutosetCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Customer_Data = {
  __typename?: "sensorflow_location_occ_customer_data";
  clientForecast?: Maybe<Scalars["numeric"]>;
  locationId: Scalars["uuid"];
  occupancy?: Maybe<Scalars["numeric"]>;
  periodEnd: Scalars["date"];
  periodStart: Scalars["date"];
};

export type Sensorflow_Location_Occ_Customer_Data_Aggregate = {
  __typename?: "sensorflow_location_occ_customer_data_aggregate";
  aggregate?: Maybe<Sensorflow_Location_Occ_Customer_Data_Aggregate_Fields>;
  nodes: Array<Sensorflow_Location_Occ_Customer_Data>;
};

export type Sensorflow_Location_Occ_Customer_Data_Aggregate_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_aggregate_fields";
  avg?: Maybe<Sensorflow_Location_Occ_Customer_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Location_Occ_Customer_Data_Max_Fields>;
  min?: Maybe<Sensorflow_Location_Occ_Customer_Data_Min_Fields>;
  stddev?: Maybe<Sensorflow_Location_Occ_Customer_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Location_Occ_Customer_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Location_Occ_Customer_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Location_Occ_Customer_Data_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Location_Occ_Customer_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Location_Occ_Customer_Data_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Location_Occ_Customer_Data_Variance_Fields>;
};

export type Sensorflow_Location_Occ_Customer_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Avg_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_avg_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Location_Occ_Customer_Data_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Bool_Exp>>;
  clientForecast?: Maybe<Numeric_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  occupancy?: Maybe<Numeric_Comparison_Exp>;
  periodEnd?: Maybe<Date_Comparison_Exp>;
  periodStart?: Maybe<Date_Comparison_Exp>;
};

export enum Sensorflow_Location_Occ_Customer_Data_Constraint {
  LocationOccCustomerDataPkey = "location_occ_customer_data_pkey",
}

export type Sensorflow_Location_Occ_Customer_Data_Inc_Input = {
  clientForecast?: Maybe<Scalars["numeric"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Insert_Input = {
  clientForecast?: Maybe<Scalars["numeric"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  periodEnd?: Maybe<Scalars["date"]>;
  periodStart?: Maybe<Scalars["date"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Max_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_max_fields";
  clientForecast?: Maybe<Scalars["numeric"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  periodEnd?: Maybe<Scalars["date"]>;
  periodStart?: Maybe<Scalars["date"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Min_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_min_fields";
  clientForecast?: Maybe<Scalars["numeric"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  periodEnd?: Maybe<Scalars["date"]>;
  periodStart?: Maybe<Scalars["date"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Mutation_Response = {
  __typename?: "sensorflow_location_occ_customer_data_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Location_Occ_Customer_Data>;
};

export type Sensorflow_Location_Occ_Customer_Data_On_Conflict = {
  constraint: Sensorflow_Location_Occ_Customer_Data_Constraint;
  update_columns: Array<Sensorflow_Location_Occ_Customer_Data_Update_Column>;
  where?: Maybe<Sensorflow_Location_Occ_Customer_Data_Bool_Exp>;
};

export type Sensorflow_Location_Occ_Customer_Data_Order_By = {
  clientForecast?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  occupancy?: Maybe<Order_By>;
  periodEnd?: Maybe<Order_By>;
  periodStart?: Maybe<Order_By>;
};

export type Sensorflow_Location_Occ_Customer_Data_Pk_Columns_Input = {
  locationId: Scalars["uuid"];
  periodEnd: Scalars["date"];
  periodStart: Scalars["date"];
};

export enum Sensorflow_Location_Occ_Customer_Data_Select_Column {
  ClientForecast = "clientForecast",
  LocationId = "locationId",
  Occupancy = "occupancy",
  PeriodEnd = "periodEnd",
  PeriodStart = "periodStart",
}

export type Sensorflow_Location_Occ_Customer_Data_Set_Input = {
  clientForecast?: Maybe<Scalars["numeric"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  periodEnd?: Maybe<Scalars["date"]>;
  periodStart?: Maybe<Scalars["date"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Stddev_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_stddev_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Stddev_Pop_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_stddev_pop_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Stddev_Samp_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_stddev_samp_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Stream_Cursor_Input = {
  initial_value: Sensorflow_Location_Occ_Customer_Data_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Location_Occ_Customer_Data_Stream_Cursor_Value_Input = {
  clientForecast?: Maybe<Scalars["numeric"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  periodEnd?: Maybe<Scalars["date"]>;
  periodStart?: Maybe<Scalars["date"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Sum_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_sum_fields";
  clientForecast?: Maybe<Scalars["numeric"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
};

export enum Sensorflow_Location_Occ_Customer_Data_Update_Column {
  ClientForecast = "clientForecast",
  LocationId = "locationId",
  Occupancy = "occupancy",
  PeriodEnd = "periodEnd",
  PeriodStart = "periodStart",
}

export type Sensorflow_Location_Occ_Customer_Data_Updates = {
  _inc?: Maybe<Sensorflow_Location_Occ_Customer_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Occ_Customer_Data_Set_Input>;
  where: Sensorflow_Location_Occ_Customer_Data_Bool_Exp;
};

export type Sensorflow_Location_Occ_Customer_Data_Var_Pop_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_var_pop_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Var_Samp_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_var_samp_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Customer_Data_Variance_Fields = {
  __typename?: "sensorflow_location_occ_customer_data_variance_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Derived_Data = {
  __typename?: "sensorflow_location_occ_derived_data";
  clientForecast: Scalars["numeric"];
  date: Scalars["date"];
  locationId: Scalars["uuid"];
  occupancy: Scalars["numeric"];
  sensorflow_forecast: Scalars["numeric"];
};

export type Sensorflow_Location_Occ_Derived_Data_Aggregate = {
  __typename?: "sensorflow_location_occ_derived_data_aggregate";
  aggregate?: Maybe<Sensorflow_Location_Occ_Derived_Data_Aggregate_Fields>;
  nodes: Array<Sensorflow_Location_Occ_Derived_Data>;
};

export type Sensorflow_Location_Occ_Derived_Data_Aggregate_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_aggregate_fields";
  avg?: Maybe<Sensorflow_Location_Occ_Derived_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Location_Occ_Derived_Data_Max_Fields>;
  min?: Maybe<Sensorflow_Location_Occ_Derived_Data_Min_Fields>;
  stddev?: Maybe<Sensorflow_Location_Occ_Derived_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Location_Occ_Derived_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Location_Occ_Derived_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Location_Occ_Derived_Data_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Location_Occ_Derived_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Location_Occ_Derived_Data_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Location_Occ_Derived_Data_Variance_Fields>;
};

export type Sensorflow_Location_Occ_Derived_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Avg_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_avg_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
  sensorflow_forecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Location_Occ_Derived_Data_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Bool_Exp>>;
  clientForecast?: Maybe<Numeric_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  occupancy?: Maybe<Numeric_Comparison_Exp>;
  sensorflow_forecast?: Maybe<Numeric_Comparison_Exp>;
};

export enum Sensorflow_Location_Occ_Derived_Data_Constraint {
  LocationOccDerivedDataPkey = "location_occ_derived_data_pkey",
}

export type Sensorflow_Location_Occ_Derived_Data_Inc_Input = {
  clientForecast?: Maybe<Scalars["numeric"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  sensorflow_forecast?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Insert_Input = {
  clientForecast?: Maybe<Scalars["numeric"]>;
  date?: Maybe<Scalars["date"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  sensorflow_forecast?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Max_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_max_fields";
  clientForecast?: Maybe<Scalars["numeric"]>;
  date?: Maybe<Scalars["date"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  sensorflow_forecast?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Min_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_min_fields";
  clientForecast?: Maybe<Scalars["numeric"]>;
  date?: Maybe<Scalars["date"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  sensorflow_forecast?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Mutation_Response = {
  __typename?: "sensorflow_location_occ_derived_data_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Location_Occ_Derived_Data>;
};

export type Sensorflow_Location_Occ_Derived_Data_On_Conflict = {
  constraint: Sensorflow_Location_Occ_Derived_Data_Constraint;
  update_columns: Array<Sensorflow_Location_Occ_Derived_Data_Update_Column>;
  where?: Maybe<Sensorflow_Location_Occ_Derived_Data_Bool_Exp>;
};

export type Sensorflow_Location_Occ_Derived_Data_Order_By = {
  clientForecast?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  occupancy?: Maybe<Order_By>;
  sensorflow_forecast?: Maybe<Order_By>;
};

export type Sensorflow_Location_Occ_Derived_Data_Pk_Columns_Input = {
  date: Scalars["date"];
  locationId: Scalars["uuid"];
};

export enum Sensorflow_Location_Occ_Derived_Data_Select_Column {
  ClientForecast = "clientForecast",
  Date = "date",
  LocationId = "locationId",
  Occupancy = "occupancy",
  SensorflowForecast = "sensorflow_forecast",
}

export type Sensorflow_Location_Occ_Derived_Data_Set_Input = {
  clientForecast?: Maybe<Scalars["numeric"]>;
  date?: Maybe<Scalars["date"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  sensorflow_forecast?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Stddev_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_stddev_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
  sensorflow_forecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Stddev_Pop_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_stddev_pop_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
  sensorflow_forecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Stddev_Samp_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_stddev_samp_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
  sensorflow_forecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Stream_Cursor_Input = {
  initial_value: Sensorflow_Location_Occ_Derived_Data_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Location_Occ_Derived_Data_Stream_Cursor_Value_Input = {
  clientForecast?: Maybe<Scalars["numeric"]>;
  date?: Maybe<Scalars["date"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  sensorflow_forecast?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Sum_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_sum_fields";
  clientForecast?: Maybe<Scalars["numeric"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
  sensorflow_forecast?: Maybe<Scalars["numeric"]>;
};

export enum Sensorflow_Location_Occ_Derived_Data_Update_Column {
  ClientForecast = "clientForecast",
  Date = "date",
  LocationId = "locationId",
  Occupancy = "occupancy",
  SensorflowForecast = "sensorflow_forecast",
}

export type Sensorflow_Location_Occ_Derived_Data_Updates = {
  _inc?: Maybe<Sensorflow_Location_Occ_Derived_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Location_Occ_Derived_Data_Set_Input>;
  where: Sensorflow_Location_Occ_Derived_Data_Bool_Exp;
};

export type Sensorflow_Location_Occ_Derived_Data_Var_Pop_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_var_pop_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
  sensorflow_forecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Var_Samp_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_var_samp_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
  sensorflow_forecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_Occ_Derived_Data_Variance_Fields = {
  __typename?: "sensorflow_location_occ_derived_data_variance_fields";
  clientForecast?: Maybe<Scalars["Float"]>;
  occupancy?: Maybe<Scalars["Float"]>;
  sensorflow_forecast?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Location_To_User_Mapping = {
  __typename?: "sensorflow_location_to_user_mapping";
  default_homepage?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  location: Sensorflow_Locations;
  location_id: Scalars["uuid"];
  user: Sensorflow_Users;
  user_id: Scalars["String"];
};

export type Sensorflow_Location_To_User_Mapping_Aggregate = {
  __typename?: "sensorflow_location_to_user_mapping_aggregate";
  aggregate?: Maybe<Sensorflow_Location_To_User_Mapping_Aggregate_Fields>;
  nodes: Array<Sensorflow_Location_To_User_Mapping>;
};

export type Sensorflow_Location_To_User_Mapping_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Location_To_User_Mapping_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Location_To_User_Mapping_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Location_To_User_Mapping_Aggregate_Fields = {
  __typename?: "sensorflow_location_to_user_mapping_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Location_To_User_Mapping_Max_Fields>;
  min?: Maybe<Sensorflow_Location_To_User_Mapping_Min_Fields>;
};

export type Sensorflow_Location_To_User_Mapping_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Location_To_User_Mapping_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Location_To_User_Mapping_Max_Order_By>;
  min?: Maybe<Sensorflow_Location_To_User_Mapping_Min_Order_By>;
};

export type Sensorflow_Location_To_User_Mapping_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Location_To_User_Mapping_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Location_To_User_Mapping_On_Conflict>;
};

export type Sensorflow_Location_To_User_Mapping_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Bool_Exp>>;
  default_homepage?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Sensorflow_Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Location_To_User_Mapping_Constraint {
  LocationToUserMappingPkey = "location_to_user_mapping_pkey",
  UniqueLocationIdUserId = "unique_location_id_user_id",
}

export type Sensorflow_Location_To_User_Mapping_Insert_Input = {
  default_homepage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["uuid"]>;
  user?: Maybe<Sensorflow_Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Location_To_User_Mapping_Max_Fields = {
  __typename?: "sensorflow_location_to_user_mapping_max_fields";
  default_homepage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Location_To_User_Mapping_Max_Order_By = {
  default_homepage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

export type Sensorflow_Location_To_User_Mapping_Min_Fields = {
  __typename?: "sensorflow_location_to_user_mapping_min_fields";
  default_homepage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Location_To_User_Mapping_Min_Order_By = {
  default_homepage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

export type Sensorflow_Location_To_User_Mapping_Mutation_Response = {
  __typename?: "sensorflow_location_to_user_mapping_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Location_To_User_Mapping>;
};

export type Sensorflow_Location_To_User_Mapping_On_Conflict = {
  constraint: Sensorflow_Location_To_User_Mapping_Constraint;
  update_columns: Array<Sensorflow_Location_To_User_Mapping_Update_Column>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Sensorflow_Location_To_User_Mapping_Order_By = {
  default_homepage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  user?: Maybe<Sensorflow_Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

export type Sensorflow_Location_To_User_Mapping_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Location_To_User_Mapping_Select_Column {
  DefaultHomepage = "default_homepage",
  Id = "id",
  LocationId = "location_id",
  UserId = "user_id",
}

export type Sensorflow_Location_To_User_Mapping_Set_Input = {
  default_homepage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Location_To_User_Mapping_Stream_Cursor_Input = {
  initial_value: Sensorflow_Location_To_User_Mapping_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Location_To_User_Mapping_Stream_Cursor_Value_Input = {
  default_homepage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Location_To_User_Mapping_Update_Column {
  DefaultHomepage = "default_homepage",
  Id = "id",
  LocationId = "location_id",
  UserId = "user_id",
}

export type Sensorflow_Location_To_User_Mapping_Updates = {
  _set?: Maybe<Sensorflow_Location_To_User_Mapping_Set_Input>;
  where: Sensorflow_Location_To_User_Mapping_Bool_Exp;
};

export type Sensorflow_Locations = {
  __typename?: "sensorflow_locations";
  activePropertyAlerts: Array<Sf_Support_Active_Property_Alerts>;
  activePropertyAlerts_aggregate: Sf_Support_Active_Property_Alerts_Aggregate;
  beaconSlots?: Maybe<Sensorflow_Beacon_Slots>;
  being_exported_to_clickup?: Maybe<Scalars["Boolean"]>;
  clickupExportDisabled: Scalars["Boolean"];
  clickupExportProgress?: Maybe<Scalars["numeric"]>;
  clickupListId?: Maybe<Scalars["String"]>;
  clickup_assignee_id?: Maybe<Scalars["String"]>;
  clickup_webhook_id?: Maybe<Scalars["String"]>;
  clickup_webhook_secret?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  emon_clickup_task_id?: Maybe<Scalars["String"]>;
  expected_number_of_keys?: Maybe<Scalars["String"]>;
  keyCategories: Array<Sensorflow_Key_Categories>;
  keyCategories_aggregate: Sensorflow_Key_Categories_Aggregate;
  language?: Maybe<Scalars["String"]>;
  locationCurrency?: Maybe<Sensorflow_Currencies>;
  locationId: Scalars["uuid"];
  locationMetadata?: Maybe<Sensorflow_Location_Metadata>;
  locationName: Scalars["String"];
  location_address?: Maybe<Scalars["String"]>;
  location_addresses: Array<Sensorflow_Location_Addresses>;
  location_addresses_aggregate: Sensorflow_Location_Addresses_Aggregate;
  location_mode: Scalars["String"];
  location_to_pms_property_mapping?: Maybe<Sf_Pms_Pms_Properties>;
  location_to_user_mappings: Array<Sensorflow_Location_To_User_Mapping>;
  location_to_user_mappings_aggregate: Sensorflow_Location_To_User_Mapping_Aggregate;
  logo_url?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Sensorflow_Organisations>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  origin_latitude?: Maybe<Scalars["float8"]>;
  origin_longitude?: Maybe<Scalars["float8"]>;
  otaIsEnabled?: Maybe<Scalars["Boolean"]>;
  otaMaxRetries?: Maybe<Scalars["Int"]>;
  otaWindowEndTime?: Maybe<Scalars["String"]>;
  otaWindowStartTime?: Maybe<Scalars["String"]>;
  positions: Array<Sensorflow_Positions>;
  positions_aggregate: Sensorflow_Positions_Aggregate;
  searchPositions?: Maybe<Array<Sensorflow_Positions>>;
  timezone: Scalars["String"];
};

export type Sensorflow_LocationsActivePropertyAlertsArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Active_Property_Alerts_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Active_Property_Alerts_Order_By>>;
  where?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
};

export type Sensorflow_LocationsActivePropertyAlerts_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Active_Property_Alerts_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Active_Property_Alerts_Order_By>>;
  where?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
};

export type Sensorflow_LocationsKeyCategoriesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Categories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Categories_Order_By>>;
  where?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
};

export type Sensorflow_LocationsKeyCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Categories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Categories_Order_By>>;
  where?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
};

export type Sensorflow_LocationsLocation_AddressesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Addresses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Addresses_Order_By>>;
  where?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
};

export type Sensorflow_LocationsLocation_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Addresses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Addresses_Order_By>>;
  where?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
};

export type Sensorflow_LocationsLocation_To_User_MappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Sensorflow_LocationsLocation_To_User_Mappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Sensorflow_LocationsPositionsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Positions_Order_By>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Sensorflow_LocationsPositions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Positions_Order_By>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Sensorflow_LocationsSearchPositionsArgs = {
  args: SearchPositions_Sensorflow_Locations_Args;
  distinct_on?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Positions_Order_By>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Sensorflow_Locations_Aggregate = {
  __typename?: "sensorflow_locations_aggregate";
  aggregate?: Maybe<Sensorflow_Locations_Aggregate_Fields>;
  nodes: Array<Sensorflow_Locations>;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp = {
  avg?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp_Var_Samp>;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Avg = {
  arguments: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Corr = {
  arguments: Sensorflow_Locations_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Locations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Sensorflow_Locations_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Max = {
  arguments: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Min = {
  arguments: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Sum = {
  arguments: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Sensorflow_Locations_Aggregate_Fields = {
  __typename?: "sensorflow_locations_aggregate_fields";
  avg?: Maybe<Sensorflow_Locations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Locations_Max_Fields>;
  min?: Maybe<Sensorflow_Locations_Min_Fields>;
  stddev?: Maybe<Sensorflow_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Locations_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Locations_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Locations_Variance_Fields>;
};

export type Sensorflow_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Locations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Locations_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Locations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Locations_Max_Order_By>;
  min?: Maybe<Sensorflow_Locations_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Locations_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Locations_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Locations_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Locations_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Locations_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Locations_Variance_Order_By>;
};

export type Sensorflow_Locations_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Locations_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Locations_On_Conflict>;
};

export type Sensorflow_Locations_Avg_Fields = {
  __typename?: "sensorflow_locations_avg_fields";
  clickupExportProgress?: Maybe<Scalars["Float"]>;
  origin_latitude?: Maybe<Scalars["Float"]>;
  origin_longitude?: Maybe<Scalars["Float"]>;
  otaMaxRetries?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Locations_Avg_Order_By = {
  clickupExportProgress?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
};

export type Sensorflow_Locations_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Locations_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Locations_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Locations_Bool_Exp>>;
  activePropertyAlerts?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
  activePropertyAlerts_aggregate?: Maybe<Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp>;
  beaconSlots?: Maybe<Sensorflow_Beacon_Slots_Bool_Exp>;
  being_exported_to_clickup?: Maybe<Boolean_Comparison_Exp>;
  clickupExportDisabled?: Maybe<Boolean_Comparison_Exp>;
  clickupExportProgress?: Maybe<Numeric_Comparison_Exp>;
  clickupListId?: Maybe<String_Comparison_Exp>;
  clickup_assignee_id?: Maybe<String_Comparison_Exp>;
  clickup_webhook_id?: Maybe<String_Comparison_Exp>;
  clickup_webhook_secret?: Maybe<String_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  emon_clickup_task_id?: Maybe<String_Comparison_Exp>;
  expected_number_of_keys?: Maybe<String_Comparison_Exp>;
  keyCategories?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
  keyCategories_aggregate?: Maybe<Sensorflow_Key_Categories_Aggregate_Bool_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  locationCurrency?: Maybe<Sensorflow_Currencies_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  locationMetadata?: Maybe<Sensorflow_Location_Metadata_Bool_Exp>;
  locationName?: Maybe<String_Comparison_Exp>;
  location_address?: Maybe<String_Comparison_Exp>;
  location_addresses?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
  location_addresses_aggregate?: Maybe<Sensorflow_Location_Addresses_Aggregate_Bool_Exp>;
  location_mode?: Maybe<String_Comparison_Exp>;
  location_to_pms_property_mapping?: Maybe<Sf_Pms_Pms_Properties_Bool_Exp>;
  location_to_user_mappings?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
  location_to_user_mappings_aggregate?: Maybe<Sensorflow_Location_To_User_Mapping_Aggregate_Bool_Exp>;
  logo_url?: Maybe<String_Comparison_Exp>;
  organisation?: Maybe<Sensorflow_Organisations_Bool_Exp>;
  organisation_id?: Maybe<Uuid_Comparison_Exp>;
  origin_latitude?: Maybe<Float8_Comparison_Exp>;
  origin_longitude?: Maybe<Float8_Comparison_Exp>;
  otaIsEnabled?: Maybe<Boolean_Comparison_Exp>;
  otaMaxRetries?: Maybe<Int_Comparison_Exp>;
  otaWindowEndTime?: Maybe<String_Comparison_Exp>;
  otaWindowStartTime?: Maybe<String_Comparison_Exp>;
  positions?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positions_aggregate?: Maybe<Sensorflow_Positions_Aggregate_Bool_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Locations_Constraint {
  LocationsNameK = "locations_name_k",
  LocationsPkey = "locations_pkey",
}

export type Sensorflow_Locations_Inc_Input = {
  clickupExportProgress?: Maybe<Scalars["numeric"]>;
  origin_latitude?: Maybe<Scalars["float8"]>;
  origin_longitude?: Maybe<Scalars["float8"]>;
  otaMaxRetries?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Locations_Insert_Input = {
  activePropertyAlerts?: Maybe<Sf_Support_Active_Property_Alerts_Arr_Rel_Insert_Input>;
  beaconSlots?: Maybe<Sensorflow_Beacon_Slots_Obj_Rel_Insert_Input>;
  being_exported_to_clickup?: Maybe<Scalars["Boolean"]>;
  clickupExportDisabled?: Maybe<Scalars["Boolean"]>;
  clickupExportProgress?: Maybe<Scalars["numeric"]>;
  clickupListId?: Maybe<Scalars["String"]>;
  clickup_assignee_id?: Maybe<Scalars["String"]>;
  clickup_webhook_id?: Maybe<Scalars["String"]>;
  clickup_webhook_secret?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  emon_clickup_task_id?: Maybe<Scalars["String"]>;
  expected_number_of_keys?: Maybe<Scalars["String"]>;
  keyCategories?: Maybe<Sensorflow_Key_Categories_Arr_Rel_Insert_Input>;
  language?: Maybe<Scalars["String"]>;
  locationCurrency?: Maybe<Sensorflow_Currencies_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars["uuid"]>;
  locationMetadata?: Maybe<Sensorflow_Location_Metadata_Obj_Rel_Insert_Input>;
  locationName?: Maybe<Scalars["String"]>;
  location_address?: Maybe<Scalars["String"]>;
  location_addresses?: Maybe<Sensorflow_Location_Addresses_Arr_Rel_Insert_Input>;
  location_mode?: Maybe<Scalars["String"]>;
  location_to_pms_property_mapping?: Maybe<Sf_Pms_Pms_Properties_Obj_Rel_Insert_Input>;
  location_to_user_mappings?: Maybe<Sensorflow_Location_To_User_Mapping_Arr_Rel_Insert_Input>;
  logo_url?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Sensorflow_Organisations_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  origin_latitude?: Maybe<Scalars["float8"]>;
  origin_longitude?: Maybe<Scalars["float8"]>;
  otaIsEnabled?: Maybe<Scalars["Boolean"]>;
  otaMaxRetries?: Maybe<Scalars["Int"]>;
  otaWindowEndTime?: Maybe<Scalars["String"]>;
  otaWindowStartTime?: Maybe<Scalars["String"]>;
  positions?: Maybe<Sensorflow_Positions_Arr_Rel_Insert_Input>;
  timezone?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Locations_Max_Fields = {
  __typename?: "sensorflow_locations_max_fields";
  clickupExportProgress?: Maybe<Scalars["numeric"]>;
  clickupListId?: Maybe<Scalars["String"]>;
  clickup_assignee_id?: Maybe<Scalars["String"]>;
  clickup_webhook_id?: Maybe<Scalars["String"]>;
  clickup_webhook_secret?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  emon_clickup_task_id?: Maybe<Scalars["String"]>;
  expected_number_of_keys?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
  location_address?: Maybe<Scalars["String"]>;
  location_mode?: Maybe<Scalars["String"]>;
  logo_url?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  origin_latitude?: Maybe<Scalars["float8"]>;
  origin_longitude?: Maybe<Scalars["float8"]>;
  otaMaxRetries?: Maybe<Scalars["Int"]>;
  otaWindowEndTime?: Maybe<Scalars["String"]>;
  otaWindowStartTime?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Locations_Max_Order_By = {
  clickupExportProgress?: Maybe<Order_By>;
  clickupListId?: Maybe<Order_By>;
  clickup_assignee_id?: Maybe<Order_By>;
  clickup_webhook_id?: Maybe<Order_By>;
  clickup_webhook_secret?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  emon_clickup_task_id?: Maybe<Order_By>;
  expected_number_of_keys?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  locationName?: Maybe<Order_By>;
  location_address?: Maybe<Order_By>;
  location_mode?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
  otaWindowEndTime?: Maybe<Order_By>;
  otaWindowStartTime?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
};

export type Sensorflow_Locations_Min_Fields = {
  __typename?: "sensorflow_locations_min_fields";
  clickupExportProgress?: Maybe<Scalars["numeric"]>;
  clickupListId?: Maybe<Scalars["String"]>;
  clickup_assignee_id?: Maybe<Scalars["String"]>;
  clickup_webhook_id?: Maybe<Scalars["String"]>;
  clickup_webhook_secret?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  emon_clickup_task_id?: Maybe<Scalars["String"]>;
  expected_number_of_keys?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
  location_address?: Maybe<Scalars["String"]>;
  location_mode?: Maybe<Scalars["String"]>;
  logo_url?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  origin_latitude?: Maybe<Scalars["float8"]>;
  origin_longitude?: Maybe<Scalars["float8"]>;
  otaMaxRetries?: Maybe<Scalars["Int"]>;
  otaWindowEndTime?: Maybe<Scalars["String"]>;
  otaWindowStartTime?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Locations_Min_Order_By = {
  clickupExportProgress?: Maybe<Order_By>;
  clickupListId?: Maybe<Order_By>;
  clickup_assignee_id?: Maybe<Order_By>;
  clickup_webhook_id?: Maybe<Order_By>;
  clickup_webhook_secret?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  emon_clickup_task_id?: Maybe<Order_By>;
  expected_number_of_keys?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  locationName?: Maybe<Order_By>;
  location_address?: Maybe<Order_By>;
  location_mode?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
  otaWindowEndTime?: Maybe<Order_By>;
  otaWindowStartTime?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
};

export type Sensorflow_Locations_Mutation_Response = {
  __typename?: "sensorflow_locations_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Locations>;
};

export type Sensorflow_Locations_Obj_Rel_Insert_Input = {
  data: Sensorflow_Locations_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Locations_On_Conflict>;
};

export type Sensorflow_Locations_On_Conflict = {
  constraint: Sensorflow_Locations_Constraint;
  update_columns: Array<Sensorflow_Locations_Update_Column>;
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
};

export type Sensorflow_Locations_Order_By = {
  activePropertyAlerts_aggregate?: Maybe<Sf_Support_Active_Property_Alerts_Aggregate_Order_By>;
  beaconSlots?: Maybe<Sensorflow_Beacon_Slots_Order_By>;
  being_exported_to_clickup?: Maybe<Order_By>;
  clickupExportDisabled?: Maybe<Order_By>;
  clickupExportProgress?: Maybe<Order_By>;
  clickupListId?: Maybe<Order_By>;
  clickup_assignee_id?: Maybe<Order_By>;
  clickup_webhook_id?: Maybe<Order_By>;
  clickup_webhook_secret?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  emon_clickup_task_id?: Maybe<Order_By>;
  expected_number_of_keys?: Maybe<Order_By>;
  keyCategories_aggregate?: Maybe<Sensorflow_Key_Categories_Aggregate_Order_By>;
  language?: Maybe<Order_By>;
  locationCurrency?: Maybe<Sensorflow_Currencies_Order_By>;
  locationId?: Maybe<Order_By>;
  locationMetadata?: Maybe<Sensorflow_Location_Metadata_Order_By>;
  locationName?: Maybe<Order_By>;
  location_address?: Maybe<Order_By>;
  location_addresses_aggregate?: Maybe<Sensorflow_Location_Addresses_Aggregate_Order_By>;
  location_mode?: Maybe<Order_By>;
  location_to_pms_property_mapping?: Maybe<Sf_Pms_Pms_Properties_Order_By>;
  location_to_user_mappings_aggregate?: Maybe<Sensorflow_Location_To_User_Mapping_Aggregate_Order_By>;
  logo_url?: Maybe<Order_By>;
  organisation?: Maybe<Sensorflow_Organisations_Order_By>;
  organisation_id?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaIsEnabled?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
  otaWindowEndTime?: Maybe<Order_By>;
  otaWindowStartTime?: Maybe<Order_By>;
  positions_aggregate?: Maybe<Sensorflow_Positions_Aggregate_Order_By>;
  timezone?: Maybe<Order_By>;
};

export type Sensorflow_Locations_Pk_Columns_Input = {
  locationId: Scalars["uuid"];
};

export enum Sensorflow_Locations_Select_Column {
  BeingExportedToClickup = "being_exported_to_clickup",
  ClickupExportDisabled = "clickupExportDisabled",
  ClickupExportProgress = "clickupExportProgress",
  ClickupListId = "clickupListId",
  ClickupAssigneeId = "clickup_assignee_id",
  ClickupWebhookId = "clickup_webhook_id",
  ClickupWebhookSecret = "clickup_webhook_secret",
  Currency = "currency",
  EmonClickupTaskId = "emon_clickup_task_id",
  ExpectedNumberOfKeys = "expected_number_of_keys",
  Language = "language",
  LocationId = "locationId",
  LocationName = "locationName",
  LocationAddress = "location_address",
  LocationMode = "location_mode",
  LogoUrl = "logo_url",
  OrganisationId = "organisation_id",
  OriginLatitude = "origin_latitude",
  OriginLongitude = "origin_longitude",
  OtaIsEnabled = "otaIsEnabled",
  OtaMaxRetries = "otaMaxRetries",
  OtaWindowEndTime = "otaWindowEndTime",
  OtaWindowStartTime = "otaWindowStartTime",
  Timezone = "timezone",
}

export enum Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  OriginLatitude = "origin_latitude",
  OriginLongitude = "origin_longitude",
}

export enum Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  BeingExportedToClickup = "being_exported_to_clickup",
  ClickupExportDisabled = "clickupExportDisabled",
  OtaIsEnabled = "otaIsEnabled",
}

export enum Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  BeingExportedToClickup = "being_exported_to_clickup",
  ClickupExportDisabled = "clickupExportDisabled",
  OtaIsEnabled = "otaIsEnabled",
}

export enum Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  OriginLatitude = "origin_latitude",
  OriginLongitude = "origin_longitude",
}

export enum Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  OriginLatitude = "origin_latitude",
  OriginLongitude = "origin_longitude",
}

export enum Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Max_Arguments_Columns {
  OriginLatitude = "origin_latitude",
  OriginLongitude = "origin_longitude",
}

export enum Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Min_Arguments_Columns {
  OriginLatitude = "origin_latitude",
  OriginLongitude = "origin_longitude",
}

export enum Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  OriginLatitude = "origin_latitude",
  OriginLongitude = "origin_longitude",
}

export enum Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  OriginLatitude = "origin_latitude",
  OriginLongitude = "origin_longitude",
}

export enum Sensorflow_Locations_Select_Column_Sensorflow_Locations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  OriginLatitude = "origin_latitude",
  OriginLongitude = "origin_longitude",
}

export type Sensorflow_Locations_Set_Input = {
  being_exported_to_clickup?: Maybe<Scalars["Boolean"]>;
  clickupExportDisabled?: Maybe<Scalars["Boolean"]>;
  clickupExportProgress?: Maybe<Scalars["numeric"]>;
  clickupListId?: Maybe<Scalars["String"]>;
  clickup_assignee_id?: Maybe<Scalars["String"]>;
  clickup_webhook_id?: Maybe<Scalars["String"]>;
  clickup_webhook_secret?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  emon_clickup_task_id?: Maybe<Scalars["String"]>;
  expected_number_of_keys?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
  location_address?: Maybe<Scalars["String"]>;
  location_mode?: Maybe<Scalars["String"]>;
  logo_url?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  origin_latitude?: Maybe<Scalars["float8"]>;
  origin_longitude?: Maybe<Scalars["float8"]>;
  otaIsEnabled?: Maybe<Scalars["Boolean"]>;
  otaMaxRetries?: Maybe<Scalars["Int"]>;
  otaWindowEndTime?: Maybe<Scalars["String"]>;
  otaWindowStartTime?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Locations_Stddev_Fields = {
  __typename?: "sensorflow_locations_stddev_fields";
  clickupExportProgress?: Maybe<Scalars["Float"]>;
  origin_latitude?: Maybe<Scalars["Float"]>;
  origin_longitude?: Maybe<Scalars["Float"]>;
  otaMaxRetries?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Locations_Stddev_Order_By = {
  clickupExportProgress?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
};

export type Sensorflow_Locations_Stddev_Pop_Fields = {
  __typename?: "sensorflow_locations_stddev_pop_fields";
  clickupExportProgress?: Maybe<Scalars["Float"]>;
  origin_latitude?: Maybe<Scalars["Float"]>;
  origin_longitude?: Maybe<Scalars["Float"]>;
  otaMaxRetries?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Locations_Stddev_Pop_Order_By = {
  clickupExportProgress?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
};

export type Sensorflow_Locations_Stddev_Samp_Fields = {
  __typename?: "sensorflow_locations_stddev_samp_fields";
  clickupExportProgress?: Maybe<Scalars["Float"]>;
  origin_latitude?: Maybe<Scalars["Float"]>;
  origin_longitude?: Maybe<Scalars["Float"]>;
  otaMaxRetries?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Locations_Stddev_Samp_Order_By = {
  clickupExportProgress?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
};

export type Sensorflow_Locations_Stream_Cursor_Input = {
  initial_value: Sensorflow_Locations_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Locations_Stream_Cursor_Value_Input = {
  being_exported_to_clickup?: Maybe<Scalars["Boolean"]>;
  clickupExportDisabled?: Maybe<Scalars["Boolean"]>;
  clickupExportProgress?: Maybe<Scalars["numeric"]>;
  clickupListId?: Maybe<Scalars["String"]>;
  clickup_assignee_id?: Maybe<Scalars["String"]>;
  clickup_webhook_id?: Maybe<Scalars["String"]>;
  clickup_webhook_secret?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  emon_clickup_task_id?: Maybe<Scalars["String"]>;
  expected_number_of_keys?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  locationName?: Maybe<Scalars["String"]>;
  location_address?: Maybe<Scalars["String"]>;
  location_mode?: Maybe<Scalars["String"]>;
  logo_url?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["uuid"]>;
  origin_latitude?: Maybe<Scalars["float8"]>;
  origin_longitude?: Maybe<Scalars["float8"]>;
  otaIsEnabled?: Maybe<Scalars["Boolean"]>;
  otaMaxRetries?: Maybe<Scalars["Int"]>;
  otaWindowEndTime?: Maybe<Scalars["String"]>;
  otaWindowStartTime?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Locations_Sum_Fields = {
  __typename?: "sensorflow_locations_sum_fields";
  clickupExportProgress?: Maybe<Scalars["numeric"]>;
  origin_latitude?: Maybe<Scalars["float8"]>;
  origin_longitude?: Maybe<Scalars["float8"]>;
  otaMaxRetries?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Locations_Sum_Order_By = {
  clickupExportProgress?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
};

export enum Sensorflow_Locations_Update_Column {
  BeingExportedToClickup = "being_exported_to_clickup",
  ClickupExportDisabled = "clickupExportDisabled",
  ClickupExportProgress = "clickupExportProgress",
  ClickupListId = "clickupListId",
  ClickupAssigneeId = "clickup_assignee_id",
  ClickupWebhookId = "clickup_webhook_id",
  ClickupWebhookSecret = "clickup_webhook_secret",
  Currency = "currency",
  EmonClickupTaskId = "emon_clickup_task_id",
  ExpectedNumberOfKeys = "expected_number_of_keys",
  Language = "language",
  LocationId = "locationId",
  LocationName = "locationName",
  LocationAddress = "location_address",
  LocationMode = "location_mode",
  LogoUrl = "logo_url",
  OrganisationId = "organisation_id",
  OriginLatitude = "origin_latitude",
  OriginLongitude = "origin_longitude",
  OtaIsEnabled = "otaIsEnabled",
  OtaMaxRetries = "otaMaxRetries",
  OtaWindowEndTime = "otaWindowEndTime",
  OtaWindowStartTime = "otaWindowStartTime",
  Timezone = "timezone",
}

export type Sensorflow_Locations_Updates = {
  _inc?: Maybe<Sensorflow_Locations_Inc_Input>;
  _set?: Maybe<Sensorflow_Locations_Set_Input>;
  where: Sensorflow_Locations_Bool_Exp;
};

export type Sensorflow_Locations_Var_Pop_Fields = {
  __typename?: "sensorflow_locations_var_pop_fields";
  clickupExportProgress?: Maybe<Scalars["Float"]>;
  origin_latitude?: Maybe<Scalars["Float"]>;
  origin_longitude?: Maybe<Scalars["Float"]>;
  otaMaxRetries?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Locations_Var_Pop_Order_By = {
  clickupExportProgress?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
};

export type Sensorflow_Locations_Var_Samp_Fields = {
  __typename?: "sensorflow_locations_var_samp_fields";
  clickupExportProgress?: Maybe<Scalars["Float"]>;
  origin_latitude?: Maybe<Scalars["Float"]>;
  origin_longitude?: Maybe<Scalars["Float"]>;
  otaMaxRetries?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Locations_Var_Samp_Order_By = {
  clickupExportProgress?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
};

export type Sensorflow_Locations_Variance_Fields = {
  __typename?: "sensorflow_locations_variance_fields";
  clickupExportProgress?: Maybe<Scalars["Float"]>;
  origin_latitude?: Maybe<Scalars["Float"]>;
  origin_longitude?: Maybe<Scalars["Float"]>;
  otaMaxRetries?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Locations_Variance_Order_By = {
  clickupExportProgress?: Maybe<Order_By>;
  origin_latitude?: Maybe<Order_By>;
  origin_longitude?: Maybe<Order_By>;
  otaMaxRetries?: Maybe<Order_By>;
};

export type Sensorflow_Mapping_Coordinates = {
  __typename?: "sensorflow_mapping_coordinates";
  coordinates: Array<Scalars["json"]>;
  gateway?: Maybe<Sensorflow_Gateways>;
  gatewayId?: Maybe<Scalars["String"]>;
  nodeSlot?: Maybe<Sensorflow_Node_Slots>;
  position?: Maybe<Sensorflow_Positions>;
  positionId?: Maybe<Scalars["uuid"]>;
  slotId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Mapping_Coordinates_Aggregate = {
  __typename?: "sensorflow_mapping_coordinates_aggregate";
  aggregate?: Maybe<Sensorflow_Mapping_Coordinates_Aggregate_Fields>;
  nodes: Array<Sensorflow_Mapping_Coordinates>;
};

export type Sensorflow_Mapping_Coordinates_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Mapping_Coordinates_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Mapping_Coordinates_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Mapping_Coordinates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Mapping_Coordinates_Aggregate_Fields = {
  __typename?: "sensorflow_mapping_coordinates_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Mapping_Coordinates_Max_Fields>;
  min?: Maybe<Sensorflow_Mapping_Coordinates_Min_Fields>;
};

export type Sensorflow_Mapping_Coordinates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Mapping_Coordinates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Mapping_Coordinates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Mapping_Coordinates_Max_Order_By>;
  min?: Maybe<Sensorflow_Mapping_Coordinates_Min_Order_By>;
};

export type Sensorflow_Mapping_Coordinates_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Mapping_Coordinates_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Mapping_Coordinates_On_Conflict>;
};

export type Sensorflow_Mapping_Coordinates_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Mapping_Coordinates_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Mapping_Coordinates_Bool_Exp>>;
  coordinates?: Maybe<Json_Array_Comparison_Exp>;
  gateway?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  gatewayId?: Maybe<String_Comparison_Exp>;
  nodeSlot?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  slotId?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Mapping_Coordinates_Constraint {
  MappingCoordinatesGatewayIdKey = "mapping_coordinates_gateway_id_key",
  MappingCoordinatesPositionIdKey = "mapping_coordinates_position_id_key",
  MappingCoordinatesSlotIdKey = "mapping_coordinates_slot_id_key",
}

export type Sensorflow_Mapping_Coordinates_Insert_Input = {
  coordinates?: Maybe<Array<Scalars["json"]>>;
  gateway?: Maybe<Sensorflow_Gateways_Obj_Rel_Insert_Input>;
  gatewayId?: Maybe<Scalars["String"]>;
  nodeSlot?: Maybe<Sensorflow_Node_Slots_Obj_Rel_Insert_Input>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  slotId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Mapping_Coordinates_Max_Fields = {
  __typename?: "sensorflow_mapping_coordinates_max_fields";
  coordinates?: Maybe<Array<Scalars["json"]>>;
  gatewayId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  slotId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Mapping_Coordinates_Max_Order_By = {
  coordinates?: Maybe<Order_By>;
  gatewayId?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  slotId?: Maybe<Order_By>;
};

export type Sensorflow_Mapping_Coordinates_Min_Fields = {
  __typename?: "sensorflow_mapping_coordinates_min_fields";
  coordinates?: Maybe<Array<Scalars["json"]>>;
  gatewayId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  slotId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Mapping_Coordinates_Min_Order_By = {
  coordinates?: Maybe<Order_By>;
  gatewayId?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  slotId?: Maybe<Order_By>;
};

export type Sensorflow_Mapping_Coordinates_Mutation_Response = {
  __typename?: "sensorflow_mapping_coordinates_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Mapping_Coordinates>;
};

export type Sensorflow_Mapping_Coordinates_Obj_Rel_Insert_Input = {
  data: Sensorflow_Mapping_Coordinates_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Mapping_Coordinates_On_Conflict>;
};

export type Sensorflow_Mapping_Coordinates_On_Conflict = {
  constraint: Sensorflow_Mapping_Coordinates_Constraint;
  update_columns: Array<Sensorflow_Mapping_Coordinates_Update_Column>;
  where?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
};

export type Sensorflow_Mapping_Coordinates_Order_By = {
  coordinates?: Maybe<Order_By>;
  gateway?: Maybe<Sensorflow_Gateways_Order_By>;
  gatewayId?: Maybe<Order_By>;
  nodeSlot?: Maybe<Sensorflow_Node_Slots_Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  slotId?: Maybe<Order_By>;
};

export enum Sensorflow_Mapping_Coordinates_Select_Column {
  Coordinates = "coordinates",
  GatewayId = "gatewayId",
  PositionId = "positionId",
  SlotId = "slotId",
}

export type Sensorflow_Mapping_Coordinates_Set_Input = {
  coordinates?: Maybe<Array<Scalars["json"]>>;
  gatewayId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  slotId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Mapping_Coordinates_Stream_Cursor_Input = {
  initial_value: Sensorflow_Mapping_Coordinates_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Mapping_Coordinates_Stream_Cursor_Value_Input = {
  coordinates?: Maybe<Array<Scalars["json"]>>;
  gatewayId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  slotId?: Maybe<Scalars["uuid"]>;
};

export enum Sensorflow_Mapping_Coordinates_Update_Column {
  Coordinates = "coordinates",
  GatewayId = "gatewayId",
  PositionId = "positionId",
  SlotId = "slotId",
}

export type Sensorflow_Mapping_Coordinates_Updates = {
  _set?: Maybe<Sensorflow_Mapping_Coordinates_Set_Input>;
  where: Sensorflow_Mapping_Coordinates_Bool_Exp;
};

export type Sensorflow_Metric_Types = {
  __typename?: "sensorflow_metric_types";
  metricName: Scalars["String"];
  metricTypeId: Scalars["Int"];
};

export type Sensorflow_Metric_Types_Aggregate = {
  __typename?: "sensorflow_metric_types_aggregate";
  aggregate?: Maybe<Sensorflow_Metric_Types_Aggregate_Fields>;
  nodes: Array<Sensorflow_Metric_Types>;
};

export type Sensorflow_Metric_Types_Aggregate_Fields = {
  __typename?: "sensorflow_metric_types_aggregate_fields";
  avg?: Maybe<Sensorflow_Metric_Types_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Metric_Types_Max_Fields>;
  min?: Maybe<Sensorflow_Metric_Types_Min_Fields>;
  stddev?: Maybe<Sensorflow_Metric_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Metric_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Metric_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Metric_Types_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Metric_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Metric_Types_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Metric_Types_Variance_Fields>;
};

export type Sensorflow_Metric_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Metric_Types_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Metric_Types_Avg_Fields = {
  __typename?: "sensorflow_metric_types_avg_fields";
  metricTypeId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Metric_Types_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Metric_Types_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Metric_Types_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Metric_Types_Bool_Exp>>;
  metricName?: Maybe<String_Comparison_Exp>;
  metricTypeId?: Maybe<Int_Comparison_Exp>;
};

export enum Sensorflow_Metric_Types_Constraint {
  MetricTypesPkey = "metric_types_pkey",
}

export type Sensorflow_Metric_Types_Inc_Input = {
  metricTypeId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Metric_Types_Insert_Input = {
  metricName?: Maybe<Scalars["String"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Metric_Types_Max_Fields = {
  __typename?: "sensorflow_metric_types_max_fields";
  metricName?: Maybe<Scalars["String"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Metric_Types_Min_Fields = {
  __typename?: "sensorflow_metric_types_min_fields";
  metricName?: Maybe<Scalars["String"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Metric_Types_Mutation_Response = {
  __typename?: "sensorflow_metric_types_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Metric_Types>;
};

export type Sensorflow_Metric_Types_On_Conflict = {
  constraint: Sensorflow_Metric_Types_Constraint;
  update_columns: Array<Sensorflow_Metric_Types_Update_Column>;
  where?: Maybe<Sensorflow_Metric_Types_Bool_Exp>;
};

export type Sensorflow_Metric_Types_Order_By = {
  metricName?: Maybe<Order_By>;
  metricTypeId?: Maybe<Order_By>;
};

export type Sensorflow_Metric_Types_Pk_Columns_Input = {
  metricTypeId: Scalars["Int"];
};

export enum Sensorflow_Metric_Types_Select_Column {
  MetricName = "metricName",
  MetricTypeId = "metricTypeId",
}

export type Sensorflow_Metric_Types_Set_Input = {
  metricName?: Maybe<Scalars["String"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Metric_Types_Stddev_Fields = {
  __typename?: "sensorflow_metric_types_stddev_fields";
  metricTypeId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Metric_Types_Stddev_Pop_Fields = {
  __typename?: "sensorflow_metric_types_stddev_pop_fields";
  metricTypeId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Metric_Types_Stddev_Samp_Fields = {
  __typename?: "sensorflow_metric_types_stddev_samp_fields";
  metricTypeId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Metric_Types_Stream_Cursor_Input = {
  initial_value: Sensorflow_Metric_Types_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Metric_Types_Stream_Cursor_Value_Input = {
  metricName?: Maybe<Scalars["String"]>;
  metricTypeId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Metric_Types_Sum_Fields = {
  __typename?: "sensorflow_metric_types_sum_fields";
  metricTypeId?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Metric_Types_Update_Column {
  MetricName = "metricName",
  MetricTypeId = "metricTypeId",
}

export type Sensorflow_Metric_Types_Updates = {
  _inc?: Maybe<Sensorflow_Metric_Types_Inc_Input>;
  _set?: Maybe<Sensorflow_Metric_Types_Set_Input>;
  where: Sensorflow_Metric_Types_Bool_Exp;
};

export type Sensorflow_Metric_Types_Var_Pop_Fields = {
  __typename?: "sensorflow_metric_types_var_pop_fields";
  metricTypeId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Metric_Types_Var_Samp_Fields = {
  __typename?: "sensorflow_metric_types_var_samp_fields";
  metricTypeId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Metric_Types_Variance_Fields = {
  __typename?: "sensorflow_metric_types_variance_fields";
  metricTypeId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exception = {
  __typename?: "sensorflow_node_exception";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Exception_Aggregate = {
  __typename?: "sensorflow_node_exception_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Exception_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Exception>;
};

export type Sensorflow_Node_Exception_Aggregate_Fields = {
  __typename?: "sensorflow_node_exception_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Exception_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Exception_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Exception_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Exception_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Exception_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Exception_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Exception_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Exception_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Exception_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Exception_Variance_Fields>;
};

export type Sensorflow_Node_Exception_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Exception_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Exception_Avg_Fields = {
  __typename?: "sensorflow_node_exception_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exception_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Exception_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Exception_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Exception_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Node_Exception_Constraint {
  NodeExceptionPkey = "node_exception_pkey",
}

export type Sensorflow_Node_Exception_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Exception_Insert_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Exception_Max_Fields = {
  __typename?: "sensorflow_node_exception_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Exception_Min_Fields = {
  __typename?: "sensorflow_node_exception_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Exception_Mutation_Response = {
  __typename?: "sensorflow_node_exception_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Exception>;
};

export type Sensorflow_Node_Exception_On_Conflict = {
  constraint: Sensorflow_Node_Exception_Constraint;
  update_columns: Array<Sensorflow_Node_Exception_Update_Column>;
  where?: Maybe<Sensorflow_Node_Exception_Bool_Exp>;
};

export type Sensorflow_Node_Exception_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

export type Sensorflow_Node_Exception_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Node_Exception_Select_Column {
  Description = "description",
  Id = "id",
  Name = "name",
}

export type Sensorflow_Node_Exception_Set_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Exception_Stddev_Fields = {
  __typename?: "sensorflow_node_exception_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exception_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_exception_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exception_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_exception_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exception_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Exception_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Exception_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Exception_Sum_Fields = {
  __typename?: "sensorflow_node_exception_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Node_Exception_Update_Column {
  Description = "description",
  Id = "id",
  Name = "name",
}

export type Sensorflow_Node_Exception_Updates = {
  _inc?: Maybe<Sensorflow_Node_Exception_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Exception_Set_Input>;
  where: Sensorflow_Node_Exception_Bool_Exp;
};

export type Sensorflow_Node_Exception_Var_Pop_Fields = {
  __typename?: "sensorflow_node_exception_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exception_Var_Samp_Fields = {
  __typename?: "sensorflow_node_exception_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exception_Variance_Fields = {
  __typename?: "sensorflow_node_exception_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exceptions = {
  __typename?: "sensorflow_node_exceptions";
  exceptionId?: Maybe<Scalars["Int"]>;
  fileId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  lineNumber?: Maybe<Scalars["Int"]>;
  nodeMacId: Scalars["String"];
  rtosTaskId?: Maybe<Scalars["Int"]>;
  time: Scalars["timestamptz"];
};

export type Sensorflow_Node_Exceptions_Aggregate = {
  __typename?: "sensorflow_node_exceptions_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Exceptions_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Exceptions>;
};

export type Sensorflow_Node_Exceptions_Aggregate_Fields = {
  __typename?: "sensorflow_node_exceptions_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Exceptions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Exceptions_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Exceptions_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Exceptions_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Exceptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Exceptions_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Exceptions_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Exceptions_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Exceptions_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Exceptions_Variance_Fields>;
};

export type Sensorflow_Node_Exceptions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Exceptions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Exceptions_Avg_Fields = {
  __typename?: "sensorflow_node_exceptions_avg_fields";
  exceptionId?: Maybe<Scalars["Float"]>;
  fileId?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lineNumber?: Maybe<Scalars["Float"]>;
  rtosTaskId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exceptions_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Exceptions_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Exceptions_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Exceptions_Bool_Exp>>;
  exceptionId?: Maybe<Int_Comparison_Exp>;
  fileId?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  lineNumber?: Maybe<Int_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  rtosTaskId?: Maybe<Int_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Node_Exceptions_Constraint {
  NodeExceptionsPkey = "node_exceptions_pkey",
}

export type Sensorflow_Node_Exceptions_Inc_Input = {
  exceptionId?: Maybe<Scalars["Int"]>;
  fileId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  lineNumber?: Maybe<Scalars["Int"]>;
  rtosTaskId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Exceptions_Insert_Input = {
  exceptionId?: Maybe<Scalars["Int"]>;
  fileId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  lineNumber?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  rtosTaskId?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Exceptions_Max_Fields = {
  __typename?: "sensorflow_node_exceptions_max_fields";
  exceptionId?: Maybe<Scalars["Int"]>;
  fileId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  lineNumber?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  rtosTaskId?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Exceptions_Min_Fields = {
  __typename?: "sensorflow_node_exceptions_min_fields";
  exceptionId?: Maybe<Scalars["Int"]>;
  fileId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  lineNumber?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  rtosTaskId?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Exceptions_Mutation_Response = {
  __typename?: "sensorflow_node_exceptions_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Exceptions>;
};

export type Sensorflow_Node_Exceptions_On_Conflict = {
  constraint: Sensorflow_Node_Exceptions_Constraint;
  update_columns: Array<Sensorflow_Node_Exceptions_Update_Column>;
  where?: Maybe<Sensorflow_Node_Exceptions_Bool_Exp>;
};

export type Sensorflow_Node_Exceptions_Order_By = {
  exceptionId?: Maybe<Order_By>;
  fileId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lineNumber?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  rtosTaskId?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Node_Exceptions_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Node_Exceptions_Select_Column {
  ExceptionId = "exceptionId",
  FileId = "fileId",
  Id = "id",
  LineNumber = "lineNumber",
  NodeMacId = "nodeMacId",
  RtosTaskId = "rtosTaskId",
  Time = "time",
}

export type Sensorflow_Node_Exceptions_Set_Input = {
  exceptionId?: Maybe<Scalars["Int"]>;
  fileId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  lineNumber?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  rtosTaskId?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Exceptions_Stddev_Fields = {
  __typename?: "sensorflow_node_exceptions_stddev_fields";
  exceptionId?: Maybe<Scalars["Float"]>;
  fileId?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lineNumber?: Maybe<Scalars["Float"]>;
  rtosTaskId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exceptions_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_exceptions_stddev_pop_fields";
  exceptionId?: Maybe<Scalars["Float"]>;
  fileId?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lineNumber?: Maybe<Scalars["Float"]>;
  rtosTaskId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exceptions_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_exceptions_stddev_samp_fields";
  exceptionId?: Maybe<Scalars["Float"]>;
  fileId?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lineNumber?: Maybe<Scalars["Float"]>;
  rtosTaskId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exceptions_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Exceptions_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Exceptions_Stream_Cursor_Value_Input = {
  exceptionId?: Maybe<Scalars["Int"]>;
  fileId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  lineNumber?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  rtosTaskId?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Exceptions_Sum_Fields = {
  __typename?: "sensorflow_node_exceptions_sum_fields";
  exceptionId?: Maybe<Scalars["Int"]>;
  fileId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  lineNumber?: Maybe<Scalars["Int"]>;
  rtosTaskId?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Node_Exceptions_Update_Column {
  ExceptionId = "exceptionId",
  FileId = "fileId",
  Id = "id",
  LineNumber = "lineNumber",
  NodeMacId = "nodeMacId",
  RtosTaskId = "rtosTaskId",
  Time = "time",
}

export type Sensorflow_Node_Exceptions_Updates = {
  _inc?: Maybe<Sensorflow_Node_Exceptions_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Exceptions_Set_Input>;
  where: Sensorflow_Node_Exceptions_Bool_Exp;
};

export type Sensorflow_Node_Exceptions_Var_Pop_Fields = {
  __typename?: "sensorflow_node_exceptions_var_pop_fields";
  exceptionId?: Maybe<Scalars["Float"]>;
  fileId?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lineNumber?: Maybe<Scalars["Float"]>;
  rtosTaskId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exceptions_Var_Samp_Fields = {
  __typename?: "sensorflow_node_exceptions_var_samp_fields";
  exceptionId?: Maybe<Scalars["Float"]>;
  fileId?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lineNumber?: Maybe<Scalars["Float"]>;
  rtosTaskId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Exceptions_Variance_Fields = {
  __typename?: "sensorflow_node_exceptions_variance_fields";
  exceptionId?: Maybe<Scalars["Float"]>;
  fileId?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  lineNumber?: Maybe<Scalars["Float"]>;
  rtosTaskId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Firmware = {
  __typename?: "sensorflow_node_firmware";
  lockable?: Maybe<Scalars["Boolean"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  upgradeStatus?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Firmware_Aggregate = {
  __typename?: "sensorflow_node_firmware_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Firmware_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Firmware>;
};

export type Sensorflow_Node_Firmware_Aggregate_Fields = {
  __typename?: "sensorflow_node_firmware_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Firmware_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Firmware_Min_Fields>;
};

export type Sensorflow_Node_Firmware_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Firmware_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Firmware_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Firmware_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Firmware_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Firmware_Bool_Exp>>;
  lockable?: Maybe<Boolean_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  upgradeStatus?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Node_Firmware_Insert_Input = {
  lockable?: Maybe<Scalars["Boolean"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  upgradeStatus?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Firmware_Max_Fields = {
  __typename?: "sensorflow_node_firmware_max_fields";
  nodeMacId?: Maybe<Scalars["String"]>;
  upgradeStatus?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Firmware_Min_Fields = {
  __typename?: "sensorflow_node_firmware_min_fields";
  nodeMacId?: Maybe<Scalars["String"]>;
  upgradeStatus?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Firmware_Obj_Rel_Insert_Input = {
  data: Sensorflow_Node_Firmware_Insert_Input;
};

export type Sensorflow_Node_Firmware_Order_By = {
  lockable?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  upgradeStatus?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Firmware_Select_Column {
  Lockable = "lockable",
  NodeMacId = "nodeMacId",
  UpgradeStatus = "upgradeStatus",
}

export type Sensorflow_Node_Firmware_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Firmware_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Firmware_Stream_Cursor_Value_Input = {
  lockable?: Maybe<Scalars["Boolean"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  upgradeStatus?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Data = {
  __typename?: "sensorflow_node_join_data";
  balena_id: Scalars["String"];
  gateway: Sensorflow_Gateways;
  node: Sensorflow_Nodes;
  node_mac_id: Scalars["String"];
  rssi?: Maybe<Scalars["numeric"]>;
  snr?: Maybe<Scalars["numeric"]>;
  time: Scalars["timestamptz"];
};

export type Sensorflow_Node_Join_Data_Aggregate = {
  __typename?: "sensorflow_node_join_data_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Join_Data_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Join_Data>;
};

export type Sensorflow_Node_Join_Data_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Node_Join_Data_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Node_Join_Data_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Node_Join_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Node_Join_Data_Aggregate_Fields = {
  __typename?: "sensorflow_node_join_data_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Join_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Join_Data_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Join_Data_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Join_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Join_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Join_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Join_Data_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Join_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Join_Data_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Join_Data_Variance_Fields>;
};

export type Sensorflow_Node_Join_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Join_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Join_Data_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Node_Join_Data_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Node_Join_Data_Max_Order_By>;
  min?: Maybe<Sensorflow_Node_Join_Data_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Node_Join_Data_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Node_Join_Data_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Node_Join_Data_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Node_Join_Data_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Node_Join_Data_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Node_Join_Data_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Node_Join_Data_Variance_Order_By>;
};

export type Sensorflow_Node_Join_Data_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Node_Join_Data_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Join_Data_On_Conflict>;
};

export type Sensorflow_Node_Join_Data_Avg_Fields = {
  __typename?: "sensorflow_node_join_data_avg_fields";
  rssi?: Maybe<Scalars["Float"]>;
  snr?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Data_Avg_Order_By = {
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Data_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Join_Data_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Join_Data_Bool_Exp>>;
  balena_id?: Maybe<String_Comparison_Exp>;
  gateway?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  node?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  node_mac_id?: Maybe<String_Comparison_Exp>;
  rssi?: Maybe<Numeric_Comparison_Exp>;
  snr?: Maybe<Numeric_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Node_Join_Data_Constraint {
  NodeJoinDataUniqueAscIdx = "node_join_data_unique_asc_idx",
}

export type Sensorflow_Node_Join_Data_Inc_Input = {
  rssi?: Maybe<Scalars["numeric"]>;
  snr?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Node_Join_Data_Insert_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  gateway?: Maybe<Sensorflow_Gateways_Obj_Rel_Insert_Input>;
  node?: Maybe<Sensorflow_Nodes_Obj_Rel_Insert_Input>;
  node_mac_id?: Maybe<Scalars["String"]>;
  rssi?: Maybe<Scalars["numeric"]>;
  snr?: Maybe<Scalars["numeric"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Join_Data_Max_Fields = {
  __typename?: "sensorflow_node_join_data_max_fields";
  balena_id?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  rssi?: Maybe<Scalars["numeric"]>;
  snr?: Maybe<Scalars["numeric"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Join_Data_Max_Order_By = {
  balena_id?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Data_Min_Fields = {
  __typename?: "sensorflow_node_join_data_min_fields";
  balena_id?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  rssi?: Maybe<Scalars["numeric"]>;
  snr?: Maybe<Scalars["numeric"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Join_Data_Min_Order_By = {
  balena_id?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Data_Mutation_Response = {
  __typename?: "sensorflow_node_join_data_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Join_Data>;
};

export type Sensorflow_Node_Join_Data_On_Conflict = {
  constraint: Sensorflow_Node_Join_Data_Constraint;
  update_columns: Array<Sensorflow_Node_Join_Data_Update_Column>;
  where?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
};

export type Sensorflow_Node_Join_Data_Order_By = {
  balena_id?: Maybe<Order_By>;
  gateway?: Maybe<Sensorflow_Gateways_Order_By>;
  node?: Maybe<Sensorflow_Nodes_Order_By>;
  node_mac_id?: Maybe<Order_By>;
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Join_Data_Select_Column {
  BalenaId = "balena_id",
  NodeMacId = "node_mac_id",
  Rssi = "rssi",
  Snr = "snr",
  Time = "time",
}

export type Sensorflow_Node_Join_Data_Set_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  rssi?: Maybe<Scalars["numeric"]>;
  snr?: Maybe<Scalars["numeric"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Join_Data_Stddev_Fields = {
  __typename?: "sensorflow_node_join_data_stddev_fields";
  rssi?: Maybe<Scalars["Float"]>;
  snr?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Data_Stddev_Order_By = {
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Data_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_join_data_stddev_pop_fields";
  rssi?: Maybe<Scalars["Float"]>;
  snr?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Data_Stddev_Pop_Order_By = {
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Data_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_join_data_stddev_samp_fields";
  rssi?: Maybe<Scalars["Float"]>;
  snr?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Data_Stddev_Samp_Order_By = {
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Data_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Join_Data_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Join_Data_Stream_Cursor_Value_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  rssi?: Maybe<Scalars["numeric"]>;
  snr?: Maybe<Scalars["numeric"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Join_Data_Sum_Fields = {
  __typename?: "sensorflow_node_join_data_sum_fields";
  rssi?: Maybe<Scalars["numeric"]>;
  snr?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Node_Join_Data_Sum_Order_By = {
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Join_Data_Update_Column {
  BalenaId = "balena_id",
  NodeMacId = "node_mac_id",
  Rssi = "rssi",
  Snr = "snr",
  Time = "time",
}

export type Sensorflow_Node_Join_Data_Updates = {
  _inc?: Maybe<Sensorflow_Node_Join_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Join_Data_Set_Input>;
  where: Sensorflow_Node_Join_Data_Bool_Exp;
};

export type Sensorflow_Node_Join_Data_Var_Pop_Fields = {
  __typename?: "sensorflow_node_join_data_var_pop_fields";
  rssi?: Maybe<Scalars["Float"]>;
  snr?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Data_Var_Pop_Order_By = {
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Data_Var_Samp_Fields = {
  __typename?: "sensorflow_node_join_data_var_samp_fields";
  rssi?: Maybe<Scalars["Float"]>;
  snr?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Data_Var_Samp_Order_By = {
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Data_Variance_Fields = {
  __typename?: "sensorflow_node_join_data_variance_fields";
  rssi?: Maybe<Scalars["Float"]>;
  snr?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Data_Variance_Order_By = {
  rssi?: Maybe<Order_By>;
  snr?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Status = {
  __typename?: "sensorflow_node_join_status";
  RSSI?: Maybe<Scalars["numeric"]>;
  SNR?: Maybe<Scalars["numeric"]>;
  bootTime?: Maybe<Scalars["numeric"]>;
  currentGatewayId?: Maybe<Scalars["String"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  joinedGateway?: Maybe<Sensorflow_Gateways>;
  lastSeen?: Maybe<Scalars["timestamptz"]>;
  mappedGateway?: Maybe<Sensorflow_Gateways>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  node?: Maybe<Sensorflow_Nodes>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeStatusInInstallationMode?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions>;
  positionId?: Maybe<Scalars["uuid"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Status_Aggregate = {
  __typename?: "sensorflow_node_join_status_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Join_Status_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Join_Status>;
};

export type Sensorflow_Node_Join_Status_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Node_Join_Status_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Node_Join_Status_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Node_Join_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Node_Join_Status_Aggregate_Fields = {
  __typename?: "sensorflow_node_join_status_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Join_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Join_Status_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Join_Status_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Join_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Join_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Join_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Join_Status_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Join_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Join_Status_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Join_Status_Variance_Fields>;
};

export type Sensorflow_Node_Join_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Join_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Join_Status_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Node_Join_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Node_Join_Status_Max_Order_By>;
  min?: Maybe<Sensorflow_Node_Join_Status_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Node_Join_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Node_Join_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Node_Join_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Node_Join_Status_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Node_Join_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Node_Join_Status_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Node_Join_Status_Variance_Order_By>;
};

export type Sensorflow_Node_Join_Status_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Node_Join_Status_Insert_Input>;
};

export type Sensorflow_Node_Join_Status_Avg_Fields = {
  __typename?: "sensorflow_node_join_status_avg_fields";
  RSSI?: Maybe<Scalars["Float"]>;
  SNR?: Maybe<Scalars["Float"]>;
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Status_Avg_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Status_Bool_Exp = {
  RSSI?: Maybe<Numeric_Comparison_Exp>;
  SNR?: Maybe<Numeric_Comparison_Exp>;
  _and?: Maybe<Array<Sensorflow_Node_Join_Status_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Join_Status_Bool_Exp>>;
  bootTime?: Maybe<Numeric_Comparison_Exp>;
  currentGatewayId?: Maybe<String_Comparison_Exp>;
  firmwareVersion?: Maybe<String_Comparison_Exp>;
  gatewayId?: Maybe<String_Comparison_Exp>;
  joinedGateway?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  lastSeen?: Maybe<Timestamptz_Comparison_Exp>;
  mappedGateway?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  mappedTime?: Maybe<Timestamptz_Comparison_Exp>;
  node?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  nodeStatusInInstallationMode?: Maybe<String_Comparison_Exp>;
  nodeType?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  recentJoinCount?: Maybe<Bigint_Comparison_Exp>;
  signalStrength?: Maybe<Numeric_Comparison_Exp>;
  slotName?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Node_Join_Status_Insert_Input = {
  RSSI?: Maybe<Scalars["numeric"]>;
  SNR?: Maybe<Scalars["numeric"]>;
  bootTime?: Maybe<Scalars["numeric"]>;
  currentGatewayId?: Maybe<Scalars["String"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  joinedGateway?: Maybe<Sensorflow_Gateways_Obj_Rel_Insert_Input>;
  lastSeen?: Maybe<Scalars["timestamptz"]>;
  mappedGateway?: Maybe<Sensorflow_Gateways_Obj_Rel_Insert_Input>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  node?: Maybe<Sensorflow_Nodes_Obj_Rel_Insert_Input>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeStatusInInstallationMode?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Status_Max_Fields = {
  __typename?: "sensorflow_node_join_status_max_fields";
  RSSI?: Maybe<Scalars["numeric"]>;
  SNR?: Maybe<Scalars["numeric"]>;
  bootTime?: Maybe<Scalars["numeric"]>;
  currentGatewayId?: Maybe<Scalars["String"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  lastSeen?: Maybe<Scalars["timestamptz"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeStatusInInstallationMode?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Status_Max_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  currentGatewayId?: Maybe<Order_By>;
  firmwareVersion?: Maybe<Order_By>;
  gatewayId?: Maybe<Order_By>;
  lastSeen?: Maybe<Order_By>;
  mappedTime?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  nodeStatusInInstallationMode?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Status_Min_Fields = {
  __typename?: "sensorflow_node_join_status_min_fields";
  RSSI?: Maybe<Scalars["numeric"]>;
  SNR?: Maybe<Scalars["numeric"]>;
  bootTime?: Maybe<Scalars["numeric"]>;
  currentGatewayId?: Maybe<Scalars["String"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  lastSeen?: Maybe<Scalars["timestamptz"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeStatusInInstallationMode?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Status_Min_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  currentGatewayId?: Maybe<Order_By>;
  firmwareVersion?: Maybe<Order_By>;
  gatewayId?: Maybe<Order_By>;
  lastSeen?: Maybe<Order_By>;
  mappedTime?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  nodeStatusInInstallationMode?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Status_Obj_Rel_Insert_Input = {
  data: Sensorflow_Node_Join_Status_Insert_Input;
};

export type Sensorflow_Node_Join_Status_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  currentGatewayId?: Maybe<Order_By>;
  firmwareVersion?: Maybe<Order_By>;
  gatewayId?: Maybe<Order_By>;
  joinedGateway?: Maybe<Sensorflow_Gateways_Order_By>;
  lastSeen?: Maybe<Order_By>;
  mappedGateway?: Maybe<Sensorflow_Gateways_Order_By>;
  mappedTime?: Maybe<Order_By>;
  node?: Maybe<Sensorflow_Nodes_Order_By>;
  nodeMacId?: Maybe<Order_By>;
  nodeStatusInInstallationMode?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Join_Status_Select_Column {
  Rssi = "RSSI",
  Snr = "SNR",
  BootTime = "bootTime",
  CurrentGatewayId = "currentGatewayId",
  FirmwareVersion = "firmwareVersion",
  GatewayId = "gatewayId",
  LastSeen = "lastSeen",
  MappedTime = "mappedTime",
  NodeMacId = "nodeMacId",
  NodeStatusInInstallationMode = "nodeStatusInInstallationMode",
  NodeType = "nodeType",
  PositionId = "positionId",
  RecentJoinCount = "recentJoinCount",
  SignalStrength = "signalStrength",
  SlotName = "slotName",
  Status = "status",
}

export type Sensorflow_Node_Join_Status_Stddev_Fields = {
  __typename?: "sensorflow_node_join_status_stddev_fields";
  RSSI?: Maybe<Scalars["Float"]>;
  SNR?: Maybe<Scalars["Float"]>;
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Status_Stddev_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Status_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_join_status_stddev_pop_fields";
  RSSI?: Maybe<Scalars["Float"]>;
  SNR?: Maybe<Scalars["Float"]>;
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Status_Stddev_Pop_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Status_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_join_status_stddev_samp_fields";
  RSSI?: Maybe<Scalars["Float"]>;
  SNR?: Maybe<Scalars["Float"]>;
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Status_Stddev_Samp_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Status_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Join_Status_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Join_Status_Stream_Cursor_Value_Input = {
  RSSI?: Maybe<Scalars["numeric"]>;
  SNR?: Maybe<Scalars["numeric"]>;
  bootTime?: Maybe<Scalars["numeric"]>;
  currentGatewayId?: Maybe<Scalars["String"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  lastSeen?: Maybe<Scalars["timestamptz"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeStatusInInstallationMode?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
  slotName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Status_Sum_Fields = {
  __typename?: "sensorflow_node_join_status_sum_fields";
  RSSI?: Maybe<Scalars["numeric"]>;
  SNR?: Maybe<Scalars["numeric"]>;
  bootTime?: Maybe<Scalars["numeric"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Node_Join_Status_Sum_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Status_Var_Pop_Fields = {
  __typename?: "sensorflow_node_join_status_var_pop_fields";
  RSSI?: Maybe<Scalars["Float"]>;
  SNR?: Maybe<Scalars["Float"]>;
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Status_Var_Pop_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Status_Var_Samp_Fields = {
  __typename?: "sensorflow_node_join_status_var_samp_fields";
  RSSI?: Maybe<Scalars["Float"]>;
  SNR?: Maybe<Scalars["Float"]>;
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Status_Var_Samp_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Status_Variance_Fields = {
  __typename?: "sensorflow_node_join_status_variance_fields";
  RSSI?: Maybe<Scalars["Float"]>;
  SNR?: Maybe<Scalars["Float"]>;
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Status_Variance_Order_By = {
  RSSI?: Maybe<Order_By>;
  SNR?: Maybe<Order_By>;
  bootTime?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
};

export type Sensorflow_Node_Join_Strength_Live = {
  __typename?: "sensorflow_node_join_strength_live";
  current_gateway_id?: Maybe<Scalars["String"]>;
  gateway_id?: Maybe<Scalars["String"]>;
  mapped_time?: Maybe<Scalars["timestamptz"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions>;
  position_id?: Maybe<Scalars["uuid"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
  slot_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Aggregate = {
  __typename?: "sensorflow_node_join_strength_live_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Join_Strength_Live_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Join_Strength_Live>;
};

export type Sensorflow_Node_Join_Strength_Live_Aggregate_Fields = {
  __typename?: "sensorflow_node_join_strength_live_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Join_Strength_Live_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Join_Strength_Live_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Join_Strength_Live_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Join_Strength_Live_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Join_Strength_Live_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Join_Strength_Live_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Join_Strength_Live_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Join_Strength_Live_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Join_Strength_Live_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Join_Strength_Live_Variance_Fields>;
};

export type Sensorflow_Node_Join_Strength_Live_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Avg_Fields = {
  __typename?: "sensorflow_node_join_strength_live_avg_fields";
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Join_Strength_Live_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Bool_Exp>>;
  current_gateway_id?: Maybe<String_Comparison_Exp>;
  gateway_id?: Maybe<String_Comparison_Exp>;
  mapped_time?: Maybe<Timestamptz_Comparison_Exp>;
  node_mac_id?: Maybe<String_Comparison_Exp>;
  node_type?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  position_id?: Maybe<Uuid_Comparison_Exp>;
  signalStrength?: Maybe<Numeric_Comparison_Exp>;
  slot_name?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Node_Join_Strength_Live_Insert_Input = {
  current_gateway_id?: Maybe<Scalars["String"]>;
  gateway_id?: Maybe<Scalars["String"]>;
  mapped_time?: Maybe<Scalars["timestamptz"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  position_id?: Maybe<Scalars["uuid"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
  slot_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Max_Fields = {
  __typename?: "sensorflow_node_join_strength_live_max_fields";
  current_gateway_id?: Maybe<Scalars["String"]>;
  gateway_id?: Maybe<Scalars["String"]>;
  mapped_time?: Maybe<Scalars["timestamptz"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
  slot_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Min_Fields = {
  __typename?: "sensorflow_node_join_strength_live_min_fields";
  current_gateway_id?: Maybe<Scalars["String"]>;
  gateway_id?: Maybe<Scalars["String"]>;
  mapped_time?: Maybe<Scalars["timestamptz"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
  slot_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Obj_Rel_Insert_Input = {
  data: Sensorflow_Node_Join_Strength_Live_Insert_Input;
};

export type Sensorflow_Node_Join_Strength_Live_Order_By = {
  current_gateway_id?: Maybe<Order_By>;
  gateway_id?: Maybe<Order_By>;
  mapped_time?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  node_type?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  position_id?: Maybe<Order_By>;
  signalStrength?: Maybe<Order_By>;
  slot_name?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Join_Strength_Live_Select_Column {
  CurrentGatewayId = "current_gateway_id",
  GatewayId = "gateway_id",
  MappedTime = "mapped_time",
  NodeMacId = "node_mac_id",
  NodeType = "node_type",
  PositionId = "position_id",
  SignalStrength = "signalStrength",
  SlotName = "slot_name",
}

export type Sensorflow_Node_Join_Strength_Live_Stddev_Fields = {
  __typename?: "sensorflow_node_join_strength_live_stddev_fields";
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_join_strength_live_stddev_pop_fields";
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_join_strength_live_stddev_samp_fields";
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Join_Strength_Live_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Join_Strength_Live_Stream_Cursor_Value_Input = {
  current_gateway_id?: Maybe<Scalars["String"]>;
  gateway_id?: Maybe<Scalars["String"]>;
  mapped_time?: Maybe<Scalars["timestamptz"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  signalStrength?: Maybe<Scalars["numeric"]>;
  slot_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Sum_Fields = {
  __typename?: "sensorflow_node_join_strength_live_sum_fields";
  signalStrength?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Var_Pop_Fields = {
  __typename?: "sensorflow_node_join_strength_live_var_pop_fields";
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Var_Samp_Fields = {
  __typename?: "sensorflow_node_join_strength_live_var_samp_fields";
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Join_Strength_Live_Variance_Fields = {
  __typename?: "sensorflow_node_join_strength_live_variance_fields";
  signalStrength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types = {
  __typename?: "sensorflow_node_life_cycle_event_types";
  canTrackManually: Scalars["Boolean"];
  id: Scalars["Int"];
  isDefault: Scalars["Boolean"];
  name: Scalars["String"];
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Aggregate = {
  __typename?: "sensorflow_node_life_cycle_event_types_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Life_Cycle_Event_Types>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Aggregate_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Variance_Fields>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Avg_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp>>;
  canTrackManually?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  isDefault?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Node_Life_Cycle_Event_Types_Constraint {
  NodeLifeCycleEventTypesPkey = "node_life_cycle_event_types_pkey",
}

export type Sensorflow_Node_Life_Cycle_Event_Types_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Insert_Input = {
  canTrackManually?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Max_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_max_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Min_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_min_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Mutation_Response = {
  __typename?: "sensorflow_node_life_cycle_event_types_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Life_Cycle_Event_Types>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Obj_Rel_Insert_Input = {
  data: Sensorflow_Node_Life_Cycle_Event_Types_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_On_Conflict>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_On_Conflict = {
  constraint: Sensorflow_Node_Life_Cycle_Event_Types_Constraint;
  update_columns: Array<Sensorflow_Node_Life_Cycle_Event_Types_Update_Column>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Order_By = {
  canTrackManually?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isDefault?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Node_Life_Cycle_Event_Types_Select_Column {
  CanTrackManually = "canTrackManually",
  Id = "id",
  IsDefault = "isDefault",
  Name = "name",
}

export type Sensorflow_Node_Life_Cycle_Event_Types_Set_Input = {
  canTrackManually?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Stddev_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Life_Cycle_Event_Types_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Stream_Cursor_Value_Input = {
  canTrackManually?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Sum_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Node_Life_Cycle_Event_Types_Update_Column {
  CanTrackManually = "canTrackManually",
  Id = "id",
  IsDefault = "isDefault",
  Name = "name",
}

export type Sensorflow_Node_Life_Cycle_Event_Types_Updates = {
  _inc?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Set_Input>;
  where: Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Var_Pop_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Var_Samp_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Event_Types_Variance_Fields = {
  __typename?: "sensorflow_node_life_cycle_event_types_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Events = {
  __typename?: "sensorflow_node_life_cycle_events";
  comment?: Maybe<Scalars["String"]>;
  creationDate: Scalars["timestamptz"];
  eventType: Scalars["Int"];
  id: Scalars["Int"];
  nodeMacId: Scalars["String"];
  node_life_cycle_event_type: Sensorflow_Node_Life_Cycle_Event_Types;
};

export type Sensorflow_Node_Life_Cycle_Events_Aggregate = {
  __typename?: "sensorflow_node_life_cycle_events_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Life_Cycle_Events_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Life_Cycle_Events>;
};

export type Sensorflow_Node_Life_Cycle_Events_Aggregate_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Life_Cycle_Events_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Life_Cycle_Events_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Life_Cycle_Events_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Life_Cycle_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Life_Cycle_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Life_Cycle_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Life_Cycle_Events_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Life_Cycle_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Life_Cycle_Events_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Life_Cycle_Events_Variance_Fields>;
};

export type Sensorflow_Node_Life_Cycle_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Avg_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_avg_fields";
  eventType?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Life_Cycle_Events_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  creationDate?: Maybe<Timestamptz_Comparison_Exp>;
  eventType?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  node_life_cycle_event_type?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp>;
};

export enum Sensorflow_Node_Life_Cycle_Events_Constraint {
  NodeLifeCycleEventsPkey = "node_life_cycle_events_pkey",
}

export type Sensorflow_Node_Life_Cycle_Events_Inc_Input = {
  eventType?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Insert_Input = {
  comment?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  eventType?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  node_life_cycle_event_type?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Obj_Rel_Insert_Input>;
};

export type Sensorflow_Node_Life_Cycle_Events_Max_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_max_fields";
  comment?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  eventType?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Min_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_min_fields";
  comment?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  eventType?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Mutation_Response = {
  __typename?: "sensorflow_node_life_cycle_events_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Life_Cycle_Events>;
};

export type Sensorflow_Node_Life_Cycle_Events_Obj_Rel_Insert_Input = {
  data: Sensorflow_Node_Life_Cycle_Events_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Life_Cycle_Events_On_Conflict>;
};

export type Sensorflow_Node_Life_Cycle_Events_On_Conflict = {
  constraint: Sensorflow_Node_Life_Cycle_Events_Constraint;
  update_columns: Array<Sensorflow_Node_Life_Cycle_Events_Update_Column>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Sensorflow_Node_Life_Cycle_Events_Order_By = {
  comment?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  eventType?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  node_life_cycle_event_type?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Order_By>;
};

export type Sensorflow_Node_Life_Cycle_Events_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Node_Life_Cycle_Events_Select_Column {
  Comment = "comment",
  CreationDate = "creationDate",
  EventType = "eventType",
  Id = "id",
  NodeMacId = "nodeMacId",
}

export type Sensorflow_Node_Life_Cycle_Events_Set_Input = {
  comment?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  eventType?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Stddev_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_stddev_fields";
  eventType?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_stddev_pop_fields";
  eventType?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_stddev_samp_fields";
  eventType?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Life_Cycle_Events_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Life_Cycle_Events_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  eventType?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Sum_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_sum_fields";
  eventType?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Node_Life_Cycle_Events_Update_Column {
  Comment = "comment",
  CreationDate = "creationDate",
  EventType = "eventType",
  Id = "id",
  NodeMacId = "nodeMacId",
}

export type Sensorflow_Node_Life_Cycle_Events_Updates = {
  _inc?: Maybe<Sensorflow_Node_Life_Cycle_Events_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Life_Cycle_Events_Set_Input>;
  where: Sensorflow_Node_Life_Cycle_Events_Bool_Exp;
};

export type Sensorflow_Node_Life_Cycle_Events_Var_Pop_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_var_pop_fields";
  eventType?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Var_Samp_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_var_samp_fields";
  eventType?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Life_Cycle_Events_Variance_Fields = {
  __typename?: "sensorflow_node_life_cycle_events_variance_fields";
  eventType?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurement_View = {
  __typename?: "sensorflow_node_measurement_view";
  acMode?: Maybe<Scalars["numeric"]>;
  acSetPoint?: Maybe<Scalars["numeric"]>;
  acStatus?: Maybe<Scalars["numeric"]>;
  ambientHumidity?: Maybe<Scalars["numeric"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  automationStatus?: Maybe<Scalars["numeric"]>;
  door?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  occupancyState?: Maybe<Scalars["numeric"]>;
  position?: Maybe<Sensorflow_Positions>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Measurement_View_Aggregate = {
  __typename?: "sensorflow_node_measurement_view_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Measurement_View_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Measurement_View>;
};

export type Sensorflow_Node_Measurement_View_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Node_Measurement_View_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Node_Measurement_View_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Node_Measurement_View_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Node_Measurement_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Node_Measurement_View_Aggregate_Fields = {
  __typename?: "sensorflow_node_measurement_view_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Measurement_View_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Measurement_View_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Measurement_View_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Measurement_View_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Measurement_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Measurement_View_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Measurement_View_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Measurement_View_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Measurement_View_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Measurement_View_Variance_Fields>;
};

export type Sensorflow_Node_Measurement_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Measurement_View_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Measurement_View_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Node_Measurement_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Node_Measurement_View_Max_Order_By>;
  min?: Maybe<Sensorflow_Node_Measurement_View_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Node_Measurement_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Node_Measurement_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Node_Measurement_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Node_Measurement_View_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Node_Measurement_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Node_Measurement_View_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Node_Measurement_View_Variance_Order_By>;
};

export type Sensorflow_Node_Measurement_View_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Node_Measurement_View_Insert_Input>;
};

export type Sensorflow_Node_Measurement_View_Avg_Fields = {
  __typename?: "sensorflow_node_measurement_view_avg_fields";
  acMode?: Maybe<Scalars["Float"]>;
  acSetPoint?: Maybe<Scalars["Float"]>;
  acStatus?: Maybe<Scalars["Float"]>;
  ambientHumidity?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  automationStatus?: Maybe<Scalars["Float"]>;
  door?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  occupancyState?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurement_View_Avg_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurement_View_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Measurement_View_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Measurement_View_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Measurement_View_Bool_Exp>>;
  acMode?: Maybe<Numeric_Comparison_Exp>;
  acSetPoint?: Maybe<Numeric_Comparison_Exp>;
  acStatus?: Maybe<Numeric_Comparison_Exp>;
  ambientHumidity?: Maybe<Numeric_Comparison_Exp>;
  ambientTemperature?: Maybe<Numeric_Comparison_Exp>;
  automationStatus?: Maybe<Numeric_Comparison_Exp>;
  door?: Maybe<Numeric_Comparison_Exp>;
  fanSpeed?: Maybe<Numeric_Comparison_Exp>;
  occupancyState?: Maybe<Numeric_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  positionName?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Node_Measurement_View_Insert_Input = {
  acMode?: Maybe<Scalars["numeric"]>;
  acSetPoint?: Maybe<Scalars["numeric"]>;
  acStatus?: Maybe<Scalars["numeric"]>;
  ambientHumidity?: Maybe<Scalars["numeric"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  automationStatus?: Maybe<Scalars["numeric"]>;
  door?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  occupancyState?: Maybe<Scalars["numeric"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Measurement_View_Max_Fields = {
  __typename?: "sensorflow_node_measurement_view_max_fields";
  acMode?: Maybe<Scalars["numeric"]>;
  acSetPoint?: Maybe<Scalars["numeric"]>;
  acStatus?: Maybe<Scalars["numeric"]>;
  ambientHumidity?: Maybe<Scalars["numeric"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  automationStatus?: Maybe<Scalars["numeric"]>;
  door?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  occupancyState?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Measurement_View_Max_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  positionName?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurement_View_Min_Fields = {
  __typename?: "sensorflow_node_measurement_view_min_fields";
  acMode?: Maybe<Scalars["numeric"]>;
  acSetPoint?: Maybe<Scalars["numeric"]>;
  acStatus?: Maybe<Scalars["numeric"]>;
  ambientHumidity?: Maybe<Scalars["numeric"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  automationStatus?: Maybe<Scalars["numeric"]>;
  door?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  occupancyState?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Measurement_View_Min_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  positionName?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurement_View_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  positionName?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Measurement_View_Select_Column {
  AcMode = "acMode",
  AcSetPoint = "acSetPoint",
  AcStatus = "acStatus",
  AmbientHumidity = "ambientHumidity",
  AmbientTemperature = "ambientTemperature",
  AutomationStatus = "automationStatus",
  Door = "door",
  FanSpeed = "fanSpeed",
  OccupancyState = "occupancyState",
  PositionId = "positionId",
  PositionName = "positionName",
}

export type Sensorflow_Node_Measurement_View_Stddev_Fields = {
  __typename?: "sensorflow_node_measurement_view_stddev_fields";
  acMode?: Maybe<Scalars["Float"]>;
  acSetPoint?: Maybe<Scalars["Float"]>;
  acStatus?: Maybe<Scalars["Float"]>;
  ambientHumidity?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  automationStatus?: Maybe<Scalars["Float"]>;
  door?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  occupancyState?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurement_View_Stddev_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurement_View_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_measurement_view_stddev_pop_fields";
  acMode?: Maybe<Scalars["Float"]>;
  acSetPoint?: Maybe<Scalars["Float"]>;
  acStatus?: Maybe<Scalars["Float"]>;
  ambientHumidity?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  automationStatus?: Maybe<Scalars["Float"]>;
  door?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  occupancyState?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurement_View_Stddev_Pop_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurement_View_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_measurement_view_stddev_samp_fields";
  acMode?: Maybe<Scalars["Float"]>;
  acSetPoint?: Maybe<Scalars["Float"]>;
  acStatus?: Maybe<Scalars["Float"]>;
  ambientHumidity?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  automationStatus?: Maybe<Scalars["Float"]>;
  door?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  occupancyState?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurement_View_Stddev_Samp_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurement_View_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Measurement_View_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Measurement_View_Stream_Cursor_Value_Input = {
  acMode?: Maybe<Scalars["numeric"]>;
  acSetPoint?: Maybe<Scalars["numeric"]>;
  acStatus?: Maybe<Scalars["numeric"]>;
  ambientHumidity?: Maybe<Scalars["numeric"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  automationStatus?: Maybe<Scalars["numeric"]>;
  door?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  occupancyState?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Measurement_View_Sum_Fields = {
  __typename?: "sensorflow_node_measurement_view_sum_fields";
  acMode?: Maybe<Scalars["numeric"]>;
  acSetPoint?: Maybe<Scalars["numeric"]>;
  acStatus?: Maybe<Scalars["numeric"]>;
  ambientHumidity?: Maybe<Scalars["numeric"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  automationStatus?: Maybe<Scalars["numeric"]>;
  door?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  occupancyState?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Node_Measurement_View_Sum_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurement_View_Var_Pop_Fields = {
  __typename?: "sensorflow_node_measurement_view_var_pop_fields";
  acMode?: Maybe<Scalars["Float"]>;
  acSetPoint?: Maybe<Scalars["Float"]>;
  acStatus?: Maybe<Scalars["Float"]>;
  ambientHumidity?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  automationStatus?: Maybe<Scalars["Float"]>;
  door?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  occupancyState?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurement_View_Var_Pop_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurement_View_Var_Samp_Fields = {
  __typename?: "sensorflow_node_measurement_view_var_samp_fields";
  acMode?: Maybe<Scalars["Float"]>;
  acSetPoint?: Maybe<Scalars["Float"]>;
  acStatus?: Maybe<Scalars["Float"]>;
  ambientHumidity?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  automationStatus?: Maybe<Scalars["Float"]>;
  door?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  occupancyState?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurement_View_Var_Samp_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurement_View_Variance_Fields = {
  __typename?: "sensorflow_node_measurement_view_variance_fields";
  acMode?: Maybe<Scalars["Float"]>;
  acSetPoint?: Maybe<Scalars["Float"]>;
  acStatus?: Maybe<Scalars["Float"]>;
  ambientHumidity?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  automationStatus?: Maybe<Scalars["Float"]>;
  door?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  occupancyState?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurement_View_Variance_Order_By = {
  acMode?: Maybe<Order_By>;
  acSetPoint?: Maybe<Order_By>;
  acStatus?: Maybe<Order_By>;
  ambientHumidity?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  automationStatus?: Maybe<Order_By>;
  door?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  occupancyState?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurements = {
  __typename?: "sensorflow_node_measurements";
  measurementType: Scalars["String"];
  measurementValue: Scalars["numeric"];
  node: Sensorflow_Nodes;
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
  srcMacId: Scalars["String"];
  streamIndex: Scalars["Int"];
  time: Scalars["timestamptz"];
};

export type Sensorflow_Node_Measurements_Aggregate = {
  __typename?: "sensorflow_node_measurements_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Measurements_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Measurements>;
};

export type Sensorflow_Node_Measurements_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Node_Measurements_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Node_Measurements_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Node_Measurements_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Node_Measurements_Aggregate_Fields = {
  __typename?: "sensorflow_node_measurements_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Measurements_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Measurements_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Measurements_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Measurements_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Measurements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Measurements_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Measurements_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Measurements_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Measurements_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Measurements_Variance_Fields>;
};

export type Sensorflow_Node_Measurements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Measurements_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Measurements_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Node_Measurements_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Node_Measurements_Max_Order_By>;
  min?: Maybe<Sensorflow_Node_Measurements_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Node_Measurements_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Node_Measurements_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Node_Measurements_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Node_Measurements_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Node_Measurements_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Node_Measurements_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Node_Measurements_Variance_Order_By>;
};

export type Sensorflow_Node_Measurements_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Node_Measurements_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Measurements_On_Conflict>;
};

export type Sensorflow_Node_Measurements_Avg_Fields = {
  __typename?: "sensorflow_node_measurements_avg_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
  streamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurements_Avg_Order_By = {
  measurementValue?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurements_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Measurements_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Measurements_Bool_Exp>>;
  measurementType?: Maybe<String_Comparison_Exp>;
  measurementValue?: Maybe<Numeric_Comparison_Exp>;
  node?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  srcMacId?: Maybe<String_Comparison_Exp>;
  streamIndex?: Maybe<Int_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Node_Measurements_Constraint {
  NodeMeasurementsUniqueIdx = "node_measurements_unique_idx",
}

export type Sensorflow_Node_Measurements_Inc_Input = {
  measurementValue?: Maybe<Scalars["numeric"]>;
  streamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Measurements_Insert_Input = {
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  node?: Maybe<Sensorflow_Nodes_Obj_Rel_Insert_Input>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  streamIndex?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Measurements_Max_Fields = {
  __typename?: "sensorflow_node_measurements_max_fields";
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  streamIndex?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Measurements_Max_Order_By = {
  measurementType?: Maybe<Order_By>;
  measurementValue?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  srcMacId?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurements_Min_Fields = {
  __typename?: "sensorflow_node_measurements_min_fields";
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  streamIndex?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Measurements_Min_Order_By = {
  measurementType?: Maybe<Order_By>;
  measurementValue?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  srcMacId?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurements_Mutation_Response = {
  __typename?: "sensorflow_node_measurements_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Measurements>;
};

export type Sensorflow_Node_Measurements_On_Conflict = {
  constraint: Sensorflow_Node_Measurements_Constraint;
  update_columns: Array<Sensorflow_Node_Measurements_Update_Column>;
  where?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
};

export type Sensorflow_Node_Measurements_Order_By = {
  measurementType?: Maybe<Order_By>;
  measurementValue?: Maybe<Order_By>;
  node?: Maybe<Sensorflow_Nodes_Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  srcMacId?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Measurements_Select_Column {
  MeasurementType = "measurementType",
  MeasurementValue = "measurementValue",
  PositionId = "positionId",
  SrcMacId = "srcMacId",
  StreamIndex = "streamIndex",
  Time = "time",
}

export type Sensorflow_Node_Measurements_Set_Input = {
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  streamIndex?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Measurements_Stddev_Fields = {
  __typename?: "sensorflow_node_measurements_stddev_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
  streamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurements_Stddev_Order_By = {
  measurementValue?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurements_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_measurements_stddev_pop_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
  streamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurements_Stddev_Pop_Order_By = {
  measurementValue?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurements_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_measurements_stddev_samp_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
  streamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurements_Stddev_Samp_Order_By = {
  measurementValue?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurements_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Measurements_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Measurements_Stream_Cursor_Value_Input = {
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  srcMacId?: Maybe<Scalars["String"]>;
  streamIndex?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Measurements_Sum_Fields = {
  __typename?: "sensorflow_node_measurements_sum_fields";
  measurementValue?: Maybe<Scalars["numeric"]>;
  streamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Measurements_Sum_Order_By = {
  measurementValue?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Measurements_Update_Column {
  MeasurementType = "measurementType",
  MeasurementValue = "measurementValue",
  PositionId = "positionId",
  SrcMacId = "srcMacId",
  StreamIndex = "streamIndex",
  Time = "time",
}

export type Sensorflow_Node_Measurements_Updates = {
  _inc?: Maybe<Sensorflow_Node_Measurements_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Measurements_Set_Input>;
  where: Sensorflow_Node_Measurements_Bool_Exp;
};

export type Sensorflow_Node_Measurements_Var_Pop_Fields = {
  __typename?: "sensorflow_node_measurements_var_pop_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
  streamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurements_Var_Pop_Order_By = {
  measurementValue?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurements_Var_Samp_Fields = {
  __typename?: "sensorflow_node_measurements_var_samp_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
  streamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurements_Var_Samp_Order_By = {
  measurementValue?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
};

export type Sensorflow_Node_Measurements_Variance_Fields = {
  __typename?: "sensorflow_node_measurements_variance_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
  streamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Measurements_Variance_Order_By = {
  measurementValue?: Maybe<Order_By>;
  streamIndex?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Data = {
  __typename?: "sensorflow_node_meta_data";
  measurementType: Scalars["String"];
  measurementValue: Scalars["numeric"];
  node: Sensorflow_Nodes;
  nodeMacId: Scalars["String"];
  time: Scalars["timestamptz"];
};

export type Sensorflow_Node_Meta_Data_Aggregate = {
  __typename?: "sensorflow_node_meta_data_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Meta_Data_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Meta_Data>;
};

export type Sensorflow_Node_Meta_Data_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Node_Meta_Data_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Node_Meta_Data_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Node_Meta_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Node_Meta_Data_Aggregate_Fields = {
  __typename?: "sensorflow_node_meta_data_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Meta_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Meta_Data_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Meta_Data_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Meta_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Meta_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Meta_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Meta_Data_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Meta_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Meta_Data_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Meta_Data_Variance_Fields>;
};

export type Sensorflow_Node_Meta_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Meta_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Meta_Data_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Node_Meta_Data_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Node_Meta_Data_Max_Order_By>;
  min?: Maybe<Sensorflow_Node_Meta_Data_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Node_Meta_Data_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Node_Meta_Data_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Node_Meta_Data_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Node_Meta_Data_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Node_Meta_Data_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Node_Meta_Data_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Node_Meta_Data_Variance_Order_By>;
};

export type Sensorflow_Node_Meta_Data_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Node_Meta_Data_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Meta_Data_On_Conflict>;
};

export type Sensorflow_Node_Meta_Data_Avg_Fields = {
  __typename?: "sensorflow_node_meta_data_avg_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Data_Avg_Order_By = {
  measurementValue?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Data_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Meta_Data_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Meta_Data_Bool_Exp>>;
  measurementType?: Maybe<String_Comparison_Exp>;
  measurementValue?: Maybe<Numeric_Comparison_Exp>;
  node?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Node_Meta_Data_Constraint {
  NodeMetaDataPk = "node_meta_data_pk",
  NodeMetaDataUniqueIdx = "node_meta_data_unique_idx",
}

export type Sensorflow_Node_Meta_Data_Inc_Input = {
  measurementValue?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Node_Meta_Data_Insert_Input = {
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  node?: Maybe<Sensorflow_Nodes_Obj_Rel_Insert_Input>;
  nodeMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Meta_Data_Max_Fields = {
  __typename?: "sensorflow_node_meta_data_max_fields";
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Meta_Data_Max_Order_By = {
  measurementType?: Maybe<Order_By>;
  measurementValue?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Data_Min_Fields = {
  __typename?: "sensorflow_node_meta_data_min_fields";
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Meta_Data_Min_Order_By = {
  measurementType?: Maybe<Order_By>;
  measurementValue?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Data_Mutation_Response = {
  __typename?: "sensorflow_node_meta_data_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Meta_Data>;
};

export type Sensorflow_Node_Meta_Data_On_Conflict = {
  constraint: Sensorflow_Node_Meta_Data_Constraint;
  update_columns: Array<Sensorflow_Node_Meta_Data_Update_Column>;
  where?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
};

export type Sensorflow_Node_Meta_Data_Order_By = {
  measurementType?: Maybe<Order_By>;
  measurementValue?: Maybe<Order_By>;
  node?: Maybe<Sensorflow_Nodes_Order_By>;
  nodeMacId?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Data_Pk_Columns_Input = {
  measurementType: Scalars["String"];
  nodeMacId: Scalars["String"];
  time: Scalars["timestamptz"];
};

export enum Sensorflow_Node_Meta_Data_Select_Column {
  MeasurementType = "measurementType",
  MeasurementValue = "measurementValue",
  NodeMacId = "nodeMacId",
  Time = "time",
}

export type Sensorflow_Node_Meta_Data_Set_Input = {
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Meta_Data_Stddev_Fields = {
  __typename?: "sensorflow_node_meta_data_stddev_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Data_Stddev_Order_By = {
  measurementValue?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Data_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_meta_data_stddev_pop_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Data_Stddev_Pop_Order_By = {
  measurementValue?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Data_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_meta_data_stddev_samp_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Data_Stddev_Samp_Order_By = {
  measurementValue?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Data_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Meta_Data_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Meta_Data_Stream_Cursor_Value_Input = {
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Meta_Data_Sum_Fields = {
  __typename?: "sensorflow_node_meta_data_sum_fields";
  measurementValue?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Node_Meta_Data_Sum_Order_By = {
  measurementValue?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Meta_Data_Update_Column {
  MeasurementType = "measurementType",
  MeasurementValue = "measurementValue",
  NodeMacId = "nodeMacId",
  Time = "time",
}

export type Sensorflow_Node_Meta_Data_Updates = {
  _inc?: Maybe<Sensorflow_Node_Meta_Data_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Meta_Data_Set_Input>;
  where: Sensorflow_Node_Meta_Data_Bool_Exp;
};

export type Sensorflow_Node_Meta_Data_Var_Pop_Fields = {
  __typename?: "sensorflow_node_meta_data_var_pop_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Data_Var_Pop_Order_By = {
  measurementValue?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Data_Var_Samp_Fields = {
  __typename?: "sensorflow_node_meta_data_var_samp_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Data_Var_Samp_Order_By = {
  measurementValue?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Data_Variance_Fields = {
  __typename?: "sensorflow_node_meta_data_variance_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Data_Variance_Order_By = {
  measurementValue?: Maybe<Order_By>;
};

export type Sensorflow_Node_Meta_Status = {
  __typename?: "sensorflow_node_meta_status";
  bootTime?: Maybe<Scalars["numeric"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions>;
  positionId?: Maybe<Scalars["uuid"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  slotName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Meta_Status_Aggregate = {
  __typename?: "sensorflow_node_meta_status_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Meta_Status_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Meta_Status>;
};

export type Sensorflow_Node_Meta_Status_Aggregate_Fields = {
  __typename?: "sensorflow_node_meta_status_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Meta_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Meta_Status_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Meta_Status_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Meta_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Meta_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Meta_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Meta_Status_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Meta_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Meta_Status_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Meta_Status_Variance_Fields>;
};

export type Sensorflow_Node_Meta_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Meta_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Meta_Status_Avg_Fields = {
  __typename?: "sensorflow_node_meta_status_avg_fields";
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Status_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Meta_Status_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Meta_Status_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Meta_Status_Bool_Exp>>;
  bootTime?: Maybe<Numeric_Comparison_Exp>;
  firmwareVersion?: Maybe<String_Comparison_Exp>;
  mappedTime?: Maybe<Timestamptz_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  nodeType?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  recentJoinCount?: Maybe<Bigint_Comparison_Exp>;
  slotName?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Node_Meta_Status_Insert_Input = {
  bootTime?: Maybe<Scalars["numeric"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  slotName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Meta_Status_Max_Fields = {
  __typename?: "sensorflow_node_meta_status_max_fields";
  bootTime?: Maybe<Scalars["numeric"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  slotName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Meta_Status_Min_Fields = {
  __typename?: "sensorflow_node_meta_status_min_fields";
  bootTime?: Maybe<Scalars["numeric"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  slotName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Meta_Status_Obj_Rel_Insert_Input = {
  data: Sensorflow_Node_Meta_Status_Insert_Input;
};

export type Sensorflow_Node_Meta_Status_Order_By = {
  bootTime?: Maybe<Order_By>;
  firmwareVersion?: Maybe<Order_By>;
  mappedTime?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  recentJoinCount?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Meta_Status_Select_Column {
  BootTime = "bootTime",
  FirmwareVersion = "firmwareVersion",
  MappedTime = "mappedTime",
  NodeMacId = "nodeMacId",
  NodeType = "nodeType",
  PositionId = "positionId",
  RecentJoinCount = "recentJoinCount",
  SlotName = "slotName",
}

export type Sensorflow_Node_Meta_Status_Stddev_Fields = {
  __typename?: "sensorflow_node_meta_status_stddev_fields";
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Status_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_meta_status_stddev_pop_fields";
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Status_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_meta_status_stddev_samp_fields";
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Status_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Meta_Status_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Meta_Status_Stream_Cursor_Value_Input = {
  bootTime?: Maybe<Scalars["numeric"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
  slotName?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Meta_Status_Sum_Fields = {
  __typename?: "sensorflow_node_meta_status_sum_fields";
  bootTime?: Maybe<Scalars["numeric"]>;
  recentJoinCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Node_Meta_Status_Var_Pop_Fields = {
  __typename?: "sensorflow_node_meta_status_var_pop_fields";
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Status_Var_Samp_Fields = {
  __typename?: "sensorflow_node_meta_status_var_samp_fields";
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Meta_Status_Variance_Fields = {
  __typename?: "sensorflow_node_meta_status_variance_fields";
  bootTime?: Maybe<Scalars["Float"]>;
  recentJoinCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Online_Status = {
  __typename?: "sensorflow_node_online_status";
  lastJoinRequest?: Maybe<Scalars["timestamptz"]>;
  lastJoinSuccess?: Maybe<Scalars["timestamptz"]>;
  lastMeasurement?: Maybe<Scalars["timestamptz"]>;
  lastTransmission?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeStatus?: Maybe<Scalars["String"]>;
  nodeStatusInInstallationMode?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_Online_Status_Aggregate = {
  __typename?: "sensorflow_node_online_status_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Online_Status_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Online_Status>;
};

export type Sensorflow_Node_Online_Status_Aggregate_Fields = {
  __typename?: "sensorflow_node_online_status_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Online_Status_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Online_Status_Min_Fields>;
};

export type Sensorflow_Node_Online_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Online_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Online_Status_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Online_Status_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Online_Status_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Online_Status_Bool_Exp>>;
  lastJoinRequest?: Maybe<Timestamptz_Comparison_Exp>;
  lastJoinSuccess?: Maybe<Timestamptz_Comparison_Exp>;
  lastMeasurement?: Maybe<Timestamptz_Comparison_Exp>;
  lastTransmission?: Maybe<Timestamptz_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  nodeStatus?: Maybe<String_Comparison_Exp>;
  nodeStatusInInstallationMode?: Maybe<String_Comparison_Exp>;
  nodeType?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
};

export type Sensorflow_Node_Online_Status_Insert_Input = {
  lastJoinRequest?: Maybe<Scalars["timestamptz"]>;
  lastJoinSuccess?: Maybe<Scalars["timestamptz"]>;
  lastMeasurement?: Maybe<Scalars["timestamptz"]>;
  lastTransmission?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeStatus?: Maybe<Scalars["String"]>;
  nodeStatusInInstallationMode?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_Online_Status_Max_Fields = {
  __typename?: "sensorflow_node_online_status_max_fields";
  lastJoinRequest?: Maybe<Scalars["timestamptz"]>;
  lastJoinSuccess?: Maybe<Scalars["timestamptz"]>;
  lastMeasurement?: Maybe<Scalars["timestamptz"]>;
  lastTransmission?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeStatus?: Maybe<Scalars["String"]>;
  nodeStatusInInstallationMode?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_Online_Status_Min_Fields = {
  __typename?: "sensorflow_node_online_status_min_fields";
  lastJoinRequest?: Maybe<Scalars["timestamptz"]>;
  lastJoinSuccess?: Maybe<Scalars["timestamptz"]>;
  lastMeasurement?: Maybe<Scalars["timestamptz"]>;
  lastTransmission?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeStatus?: Maybe<Scalars["String"]>;
  nodeStatusInInstallationMode?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_Online_Status_Obj_Rel_Insert_Input = {
  data: Sensorflow_Node_Online_Status_Insert_Input;
};

export type Sensorflow_Node_Online_Status_Order_By = {
  lastJoinRequest?: Maybe<Order_By>;
  lastJoinSuccess?: Maybe<Order_By>;
  lastMeasurement?: Maybe<Order_By>;
  lastTransmission?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  nodeStatus?: Maybe<Order_By>;
  nodeStatusInInstallationMode?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
};

export enum Sensorflow_Node_Online_Status_Select_Column {
  LastJoinRequest = "lastJoinRequest",
  LastJoinSuccess = "lastJoinSuccess",
  LastMeasurement = "lastMeasurement",
  LastTransmission = "lastTransmission",
  LocationId = "locationId",
  NodeMacId = "nodeMacId",
  NodeStatus = "nodeStatus",
  NodeStatusInInstallationMode = "nodeStatusInInstallationMode",
  NodeType = "nodeType",
  PositionId = "positionId",
}

export type Sensorflow_Node_Online_Status_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Online_Status_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Online_Status_Stream_Cursor_Value_Input = {
  lastJoinRequest?: Maybe<Scalars["timestamptz"]>;
  lastJoinSuccess?: Maybe<Scalars["timestamptz"]>;
  lastMeasurement?: Maybe<Scalars["timestamptz"]>;
  lastTransmission?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeStatus?: Maybe<Scalars["String"]>;
  nodeStatusInInstallationMode?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_Slots = {
  __typename?: "sensorflow_node_slots";
  id: Scalars["uuid"];
  mappingCoordinates?: Maybe<Sensorflow_Mapping_Coordinates>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType: Scalars["String"];
  parentPositionId: Scalars["uuid"];
  position: Sensorflow_Positions;
  slotCreationDate: Scalars["timestamptz"];
  slotMappings: Array<Sensorflow_Node_To_Slot_Mapping>;
  slotMappings_aggregate: Sensorflow_Node_To_Slot_Mapping_Aggregate;
  slotName: Scalars["String"];
  slotRemoveDate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_SlotsSlotMappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Sensorflow_Node_SlotsSlotMappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Sensorflow_Node_Slots_Aggregate = {
  __typename?: "sensorflow_node_slots_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Slots_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Slots>;
};

export type Sensorflow_Node_Slots_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Node_Slots_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Node_Slots_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Node_Slots_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Node_Slots_Aggregate_Fields = {
  __typename?: "sensorflow_node_slots_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Slots_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Slots_Min_Fields>;
};

export type Sensorflow_Node_Slots_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Slots_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Slots_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Node_Slots_Max_Order_By>;
  min?: Maybe<Sensorflow_Node_Slots_Min_Order_By>;
};

export type Sensorflow_Node_Slots_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Node_Slots_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_Slots_On_Conflict>;
};

export type Sensorflow_Node_Slots_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Slots_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Slots_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mappingCoordinates?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
  nodeSubType?: Maybe<String_Comparison_Exp>;
  nodeType?: Maybe<String_Comparison_Exp>;
  parentPositionId?: Maybe<Uuid_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  slotCreationDate?: Maybe<Timestamptz_Comparison_Exp>;
  slotMappings?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
  slotMappings_aggregate?: Maybe<Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp>;
  slotName?: Maybe<String_Comparison_Exp>;
  slotRemoveDate?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Node_Slots_Constraint {
  NodeSlotsPkey = "node_slots_pkey",
  UniqueParentNodeTypeSlotName = "unique_parent_node_type_slot_name",
}

export type Sensorflow_Node_Slots_Insert_Input = {
  id?: Maybe<Scalars["uuid"]>;
  mappingCoordinates?: Maybe<Sensorflow_Mapping_Coordinates_Obj_Rel_Insert_Input>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  slotCreationDate?: Maybe<Scalars["timestamptz"]>;
  slotMappings?: Maybe<Sensorflow_Node_To_Slot_Mapping_Arr_Rel_Insert_Input>;
  slotName?: Maybe<Scalars["String"]>;
  slotRemoveDate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Slots_Max_Fields = {
  __typename?: "sensorflow_node_slots_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  slotCreationDate?: Maybe<Scalars["timestamptz"]>;
  slotName?: Maybe<Scalars["String"]>;
  slotRemoveDate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Slots_Max_Order_By = {
  id?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  parentPositionId?: Maybe<Order_By>;
  slotCreationDate?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  slotRemoveDate?: Maybe<Order_By>;
};

export type Sensorflow_Node_Slots_Min_Fields = {
  __typename?: "sensorflow_node_slots_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  slotCreationDate?: Maybe<Scalars["timestamptz"]>;
  slotName?: Maybe<Scalars["String"]>;
  slotRemoveDate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Slots_Min_Order_By = {
  id?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  parentPositionId?: Maybe<Order_By>;
  slotCreationDate?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  slotRemoveDate?: Maybe<Order_By>;
};

export type Sensorflow_Node_Slots_Mutation_Response = {
  __typename?: "sensorflow_node_slots_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Slots>;
};

export type Sensorflow_Node_Slots_Obj_Rel_Insert_Input = {
  data: Sensorflow_Node_Slots_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Node_Slots_On_Conflict>;
};

export type Sensorflow_Node_Slots_On_Conflict = {
  constraint: Sensorflow_Node_Slots_Constraint;
  update_columns: Array<Sensorflow_Node_Slots_Update_Column>;
  where?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
};

export type Sensorflow_Node_Slots_Order_By = {
  id?: Maybe<Order_By>;
  mappingCoordinates?: Maybe<Sensorflow_Mapping_Coordinates_Order_By>;
  nodeSubType?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  parentPositionId?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  slotCreationDate?: Maybe<Order_By>;
  slotMappings_aggregate?: Maybe<Sensorflow_Node_To_Slot_Mapping_Aggregate_Order_By>;
  slotName?: Maybe<Order_By>;
  slotRemoveDate?: Maybe<Order_By>;
};

export type Sensorflow_Node_Slots_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Node_Slots_Select_Column {
  Id = "id",
  NodeSubType = "nodeSubType",
  NodeType = "nodeType",
  ParentPositionId = "parentPositionId",
  SlotCreationDate = "slotCreationDate",
  SlotName = "slotName",
  SlotRemoveDate = "slotRemoveDate",
}

export type Sensorflow_Node_Slots_Set_Input = {
  id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  slotCreationDate?: Maybe<Scalars["timestamptz"]>;
  slotName?: Maybe<Scalars["String"]>;
  slotRemoveDate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_Slots_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Slots_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Slots_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  slotCreationDate?: Maybe<Scalars["timestamptz"]>;
  slotName?: Maybe<Scalars["String"]>;
  slotRemoveDate?: Maybe<Scalars["timestamptz"]>;
};

export enum Sensorflow_Node_Slots_Update_Column {
  Id = "id",
  NodeSubType = "nodeSubType",
  NodeType = "nodeType",
  ParentPositionId = "parentPositionId",
  SlotCreationDate = "slotCreationDate",
  SlotName = "slotName",
  SlotRemoveDate = "slotRemoveDate",
}

export type Sensorflow_Node_Slots_Updates = {
  _set?: Maybe<Sensorflow_Node_Slots_Set_Input>;
  where: Sensorflow_Node_Slots_Bool_Exp;
};

export type Sensorflow_Node_Spare_Set = {
  __typename?: "sensorflow_node_spare_set";
  clickupTaskId?: Maybe<Scalars["String"]>;
  consumedAt?: Maybe<Scalars["timestamptz"]>;
  locationId: Scalars["uuid"];
  mappedAt: Scalars["timestamptz"];
  node: Sensorflow_Nodes;
  nodeMacId: Scalars["String"];
};

export type Sensorflow_Node_Spare_Set_Aggregate = {
  __typename?: "sensorflow_node_spare_set_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Spare_Set_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Spare_Set>;
};

export type Sensorflow_Node_Spare_Set_Aggregate_Fields = {
  __typename?: "sensorflow_node_spare_set_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Spare_Set_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Spare_Set_Min_Fields>;
};

export type Sensorflow_Node_Spare_Set_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Spare_Set_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Spare_Set_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Spare_Set_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Spare_Set_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Spare_Set_Bool_Exp>>;
  clickupTaskId?: Maybe<String_Comparison_Exp>;
  consumedAt?: Maybe<Timestamptz_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  mappedAt?: Maybe<Timestamptz_Comparison_Exp>;
  node?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Node_Spare_Set_Constraint {
  PkNodeSpareSet = "pk_node_spare_set",
}

export type Sensorflow_Node_Spare_Set_Insert_Input = {
  clickupTaskId?: Maybe<Scalars["String"]>;
  consumedAt?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  mappedAt?: Maybe<Scalars["timestamptz"]>;
  node?: Maybe<Sensorflow_Nodes_Obj_Rel_Insert_Input>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Spare_Set_Max_Fields = {
  __typename?: "sensorflow_node_spare_set_max_fields";
  clickupTaskId?: Maybe<Scalars["String"]>;
  consumedAt?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  mappedAt?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Spare_Set_Min_Fields = {
  __typename?: "sensorflow_node_spare_set_min_fields";
  clickupTaskId?: Maybe<Scalars["String"]>;
  consumedAt?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  mappedAt?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Spare_Set_Mutation_Response = {
  __typename?: "sensorflow_node_spare_set_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Spare_Set>;
};

export type Sensorflow_Node_Spare_Set_On_Conflict = {
  constraint: Sensorflow_Node_Spare_Set_Constraint;
  update_columns: Array<Sensorflow_Node_Spare_Set_Update_Column>;
  where?: Maybe<Sensorflow_Node_Spare_Set_Bool_Exp>;
};

export type Sensorflow_Node_Spare_Set_Order_By = {
  clickupTaskId?: Maybe<Order_By>;
  consumedAt?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  mappedAt?: Maybe<Order_By>;
  node?: Maybe<Sensorflow_Nodes_Order_By>;
  nodeMacId?: Maybe<Order_By>;
};

export type Sensorflow_Node_Spare_Set_Pk_Columns_Input = {
  locationId: Scalars["uuid"];
  nodeMacId: Scalars["String"];
};

export enum Sensorflow_Node_Spare_Set_Select_Column {
  ClickupTaskId = "clickupTaskId",
  ConsumedAt = "consumedAt",
  LocationId = "locationId",
  MappedAt = "mappedAt",
  NodeMacId = "nodeMacId",
}

export type Sensorflow_Node_Spare_Set_Set_Input = {
  clickupTaskId?: Maybe<Scalars["String"]>;
  consumedAt?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  mappedAt?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Spare_Set_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Spare_Set_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Spare_Set_Stream_Cursor_Value_Input = {
  clickupTaskId?: Maybe<Scalars["String"]>;
  consumedAt?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  mappedAt?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Node_Spare_Set_Update_Column {
  ClickupTaskId = "clickupTaskId",
  ConsumedAt = "consumedAt",
  LocationId = "locationId",
  MappedAt = "mappedAt",
  NodeMacId = "nodeMacId",
}

export type Sensorflow_Node_Spare_Set_Updates = {
  _set?: Maybe<Sensorflow_Node_Spare_Set_Set_Input>;
  where: Sensorflow_Node_Spare_Set_Bool_Exp;
};

export type Sensorflow_Node_Status_History = {
  __typename?: "sensorflow_node_status_history";
  entered_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  node_mac_id: Scalars["String"];
  status_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Status_History_Aggregate = {
  __typename?: "sensorflow_node_status_history_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Status_History_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Status_History>;
};

export type Sensorflow_Node_Status_History_Aggregate_Fields = {
  __typename?: "sensorflow_node_status_history_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Status_History_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Status_History_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Status_History_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Status_History_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Status_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Status_History_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Status_History_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Status_History_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Status_History_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Status_History_Variance_Fields>;
};

export type Sensorflow_Node_Status_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Status_History_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Status_History_Avg_Fields = {
  __typename?: "sensorflow_node_status_history_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Status_History_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Status_History_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Status_History_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Status_History_Bool_Exp>>;
  entered_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  node_mac_id?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<Int_Comparison_Exp>;
};

export enum Sensorflow_Node_Status_History_Constraint {
  NodeStatusHistoryPkey = "node_status_history_pkey",
}

export type Sensorflow_Node_Status_History_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Status_History_Insert_Input = {
  entered_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Status_History_Max_Fields = {
  __typename?: "sensorflow_node_status_history_max_fields";
  entered_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Status_History_Min_Fields = {
  __typename?: "sensorflow_node_status_history_min_fields";
  entered_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Status_History_Mutation_Response = {
  __typename?: "sensorflow_node_status_history_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Status_History>;
};

export type Sensorflow_Node_Status_History_On_Conflict = {
  constraint: Sensorflow_Node_Status_History_Constraint;
  update_columns: Array<Sensorflow_Node_Status_History_Update_Column>;
  where?: Maybe<Sensorflow_Node_Status_History_Bool_Exp>;
};

export type Sensorflow_Node_Status_History_Order_By = {
  entered_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

export type Sensorflow_Node_Status_History_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Node_Status_History_Select_Column {
  EnteredAt = "entered_at",
  Id = "id",
  NodeMacId = "node_mac_id",
  StatusId = "status_id",
}

export type Sensorflow_Node_Status_History_Set_Input = {
  entered_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Status_History_Stddev_Fields = {
  __typename?: "sensorflow_node_status_history_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Status_History_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_status_history_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Status_History_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_status_history_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Status_History_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Status_History_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Status_History_Stream_Cursor_Value_Input = {
  entered_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Status_History_Sum_Fields = {
  __typename?: "sensorflow_node_status_history_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Node_Status_History_Update_Column {
  EnteredAt = "entered_at",
  Id = "id",
  NodeMacId = "node_mac_id",
  StatusId = "status_id",
}

export type Sensorflow_Node_Status_History_Updates = {
  _inc?: Maybe<Sensorflow_Node_Status_History_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Status_History_Set_Input>;
  where: Sensorflow_Node_Status_History_Bool_Exp;
};

export type Sensorflow_Node_Status_History_Var_Pop_Fields = {
  __typename?: "sensorflow_node_status_history_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Status_History_Var_Samp_Fields = {
  __typename?: "sensorflow_node_status_history_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Status_History_Variance_Fields = {
  __typename?: "sensorflow_node_status_history_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Statuses = {
  __typename?: "sensorflow_node_statuses";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type Sensorflow_Node_Statuses_Aggregate = {
  __typename?: "sensorflow_node_statuses_aggregate";
  aggregate?: Maybe<Sensorflow_Node_Statuses_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_Statuses>;
};

export type Sensorflow_Node_Statuses_Aggregate_Fields = {
  __typename?: "sensorflow_node_statuses_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_Statuses_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_Statuses_Max_Fields>;
  min?: Maybe<Sensorflow_Node_Statuses_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_Statuses_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_Statuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_Statuses_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_Statuses_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_Statuses_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_Statuses_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_Statuses_Variance_Fields>;
};

export type Sensorflow_Node_Statuses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_Statuses_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_Statuses_Avg_Fields = {
  __typename?: "sensorflow_node_statuses_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Statuses_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_Statuses_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_Statuses_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_Statuses_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Node_Statuses_Constraint {
  NodeStatusesPkey = "node_statuses_pkey",
}

export type Sensorflow_Node_Statuses_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_Statuses_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Statuses_Max_Fields = {
  __typename?: "sensorflow_node_statuses_max_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Statuses_Min_Fields = {
  __typename?: "sensorflow_node_statuses_min_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Statuses_Mutation_Response = {
  __typename?: "sensorflow_node_statuses_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_Statuses>;
};

export type Sensorflow_Node_Statuses_On_Conflict = {
  constraint: Sensorflow_Node_Statuses_Constraint;
  update_columns: Array<Sensorflow_Node_Statuses_Update_Column>;
  where?: Maybe<Sensorflow_Node_Statuses_Bool_Exp>;
};

export type Sensorflow_Node_Statuses_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

export type Sensorflow_Node_Statuses_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Node_Statuses_Select_Column {
  Id = "id",
  Name = "name",
}

export type Sensorflow_Node_Statuses_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Statuses_Stddev_Fields = {
  __typename?: "sensorflow_node_statuses_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Statuses_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_statuses_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Statuses_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_statuses_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Statuses_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_Statuses_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_Statuses_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Node_Statuses_Sum_Fields = {
  __typename?: "sensorflow_node_statuses_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Node_Statuses_Update_Column {
  Id = "id",
  Name = "name",
}

export type Sensorflow_Node_Statuses_Updates = {
  _inc?: Maybe<Sensorflow_Node_Statuses_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_Statuses_Set_Input>;
  where: Sensorflow_Node_Statuses_Bool_Exp;
};

export type Sensorflow_Node_Statuses_Var_Pop_Fields = {
  __typename?: "sensorflow_node_statuses_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Statuses_Var_Samp_Fields = {
  __typename?: "sensorflow_node_statuses_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_Statuses_Variance_Fields = {
  __typename?: "sensorflow_node_statuses_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_System_States = {
  __typename?: "sensorflow_node_system_states";
  asleepS?: Maybe<Scalars["bigint"]>;
  awakeS?: Maybe<Scalars["bigint"]>;
  extra1S?: Maybe<Scalars["bigint"]>;
  extra2S?: Maybe<Scalars["bigint"]>;
  nodeMacId: Scalars["String"];
  radioRxS?: Maybe<Scalars["bigint"]>;
  radioTxS?: Maybe<Scalars["bigint"]>;
  time: Scalars["timestamptz"];
};

export type Sensorflow_Node_System_States_Aggregate = {
  __typename?: "sensorflow_node_system_states_aggregate";
  aggregate?: Maybe<Sensorflow_Node_System_States_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_System_States>;
};

export type Sensorflow_Node_System_States_Aggregate_Fields = {
  __typename?: "sensorflow_node_system_states_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_System_States_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_System_States_Max_Fields>;
  min?: Maybe<Sensorflow_Node_System_States_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_System_States_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_System_States_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_System_States_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_System_States_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_System_States_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_System_States_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_System_States_Variance_Fields>;
};

export type Sensorflow_Node_System_States_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_System_States_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_System_States_Avg_Fields = {
  __typename?: "sensorflow_node_system_states_avg_fields";
  asleepS?: Maybe<Scalars["Float"]>;
  awakeS?: Maybe<Scalars["Float"]>;
  extra1S?: Maybe<Scalars["Float"]>;
  extra2S?: Maybe<Scalars["Float"]>;
  radioRxS?: Maybe<Scalars["Float"]>;
  radioTxS?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_System_States_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_System_States_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_System_States_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_System_States_Bool_Exp>>;
  asleepS?: Maybe<Bigint_Comparison_Exp>;
  awakeS?: Maybe<Bigint_Comparison_Exp>;
  extra1S?: Maybe<Bigint_Comparison_Exp>;
  extra2S?: Maybe<Bigint_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  radioRxS?: Maybe<Bigint_Comparison_Exp>;
  radioTxS?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Node_System_States_Constraint {
  NodeSystemStatesPkey = "node_system_states_pkey",
}

export type Sensorflow_Node_System_States_Inc_Input = {
  asleepS?: Maybe<Scalars["bigint"]>;
  awakeS?: Maybe<Scalars["bigint"]>;
  extra1S?: Maybe<Scalars["bigint"]>;
  extra2S?: Maybe<Scalars["bigint"]>;
  radioRxS?: Maybe<Scalars["bigint"]>;
  radioTxS?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Node_System_States_Insert_Input = {
  asleepS?: Maybe<Scalars["bigint"]>;
  awakeS?: Maybe<Scalars["bigint"]>;
  extra1S?: Maybe<Scalars["bigint"]>;
  extra2S?: Maybe<Scalars["bigint"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  radioRxS?: Maybe<Scalars["bigint"]>;
  radioTxS?: Maybe<Scalars["bigint"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_System_States_Max_Fields = {
  __typename?: "sensorflow_node_system_states_max_fields";
  asleepS?: Maybe<Scalars["bigint"]>;
  awakeS?: Maybe<Scalars["bigint"]>;
  extra1S?: Maybe<Scalars["bigint"]>;
  extra2S?: Maybe<Scalars["bigint"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  radioRxS?: Maybe<Scalars["bigint"]>;
  radioTxS?: Maybe<Scalars["bigint"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_System_States_Min_Fields = {
  __typename?: "sensorflow_node_system_states_min_fields";
  asleepS?: Maybe<Scalars["bigint"]>;
  awakeS?: Maybe<Scalars["bigint"]>;
  extra1S?: Maybe<Scalars["bigint"]>;
  extra2S?: Maybe<Scalars["bigint"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  radioRxS?: Maybe<Scalars["bigint"]>;
  radioTxS?: Maybe<Scalars["bigint"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_System_States_Mutation_Response = {
  __typename?: "sensorflow_node_system_states_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_System_States>;
};

export type Sensorflow_Node_System_States_On_Conflict = {
  constraint: Sensorflow_Node_System_States_Constraint;
  update_columns: Array<Sensorflow_Node_System_States_Update_Column>;
  where?: Maybe<Sensorflow_Node_System_States_Bool_Exp>;
};

export type Sensorflow_Node_System_States_Order_By = {
  asleepS?: Maybe<Order_By>;
  awakeS?: Maybe<Order_By>;
  extra1S?: Maybe<Order_By>;
  extra2S?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  radioRxS?: Maybe<Order_By>;
  radioTxS?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Node_System_States_Pk_Columns_Input = {
  nodeMacId: Scalars["String"];
  time: Scalars["timestamptz"];
};

export enum Sensorflow_Node_System_States_Select_Column {
  AsleepS = "asleepS",
  AwakeS = "awakeS",
  Extra1S = "extra1S",
  Extra2S = "extra2S",
  NodeMacId = "nodeMacId",
  RadioRxS = "radioRxS",
  RadioTxS = "radioTxS",
  Time = "time",
}

export type Sensorflow_Node_System_States_Set_Input = {
  asleepS?: Maybe<Scalars["bigint"]>;
  awakeS?: Maybe<Scalars["bigint"]>;
  extra1S?: Maybe<Scalars["bigint"]>;
  extra2S?: Maybe<Scalars["bigint"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  radioRxS?: Maybe<Scalars["bigint"]>;
  radioTxS?: Maybe<Scalars["bigint"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_System_States_Stddev_Fields = {
  __typename?: "sensorflow_node_system_states_stddev_fields";
  asleepS?: Maybe<Scalars["Float"]>;
  awakeS?: Maybe<Scalars["Float"]>;
  extra1S?: Maybe<Scalars["Float"]>;
  extra2S?: Maybe<Scalars["Float"]>;
  radioRxS?: Maybe<Scalars["Float"]>;
  radioTxS?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_System_States_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_system_states_stddev_pop_fields";
  asleepS?: Maybe<Scalars["Float"]>;
  awakeS?: Maybe<Scalars["Float"]>;
  extra1S?: Maybe<Scalars["Float"]>;
  extra2S?: Maybe<Scalars["Float"]>;
  radioRxS?: Maybe<Scalars["Float"]>;
  radioTxS?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_System_States_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_system_states_stddev_samp_fields";
  asleepS?: Maybe<Scalars["Float"]>;
  awakeS?: Maybe<Scalars["Float"]>;
  extra1S?: Maybe<Scalars["Float"]>;
  extra2S?: Maybe<Scalars["Float"]>;
  radioRxS?: Maybe<Scalars["Float"]>;
  radioTxS?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_System_States_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_System_States_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_System_States_Stream_Cursor_Value_Input = {
  asleepS?: Maybe<Scalars["bigint"]>;
  awakeS?: Maybe<Scalars["bigint"]>;
  extra1S?: Maybe<Scalars["bigint"]>;
  extra2S?: Maybe<Scalars["bigint"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  radioRxS?: Maybe<Scalars["bigint"]>;
  radioTxS?: Maybe<Scalars["bigint"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_System_States_Sum_Fields = {
  __typename?: "sensorflow_node_system_states_sum_fields";
  asleepS?: Maybe<Scalars["bigint"]>;
  awakeS?: Maybe<Scalars["bigint"]>;
  extra1S?: Maybe<Scalars["bigint"]>;
  extra2S?: Maybe<Scalars["bigint"]>;
  radioRxS?: Maybe<Scalars["bigint"]>;
  radioTxS?: Maybe<Scalars["bigint"]>;
};

export enum Sensorflow_Node_System_States_Update_Column {
  AsleepS = "asleepS",
  AwakeS = "awakeS",
  Extra1S = "extra1S",
  Extra2S = "extra2S",
  NodeMacId = "nodeMacId",
  RadioRxS = "radioRxS",
  RadioTxS = "radioTxS",
  Time = "time",
}

export type Sensorflow_Node_System_States_Updates = {
  _inc?: Maybe<Sensorflow_Node_System_States_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_System_States_Set_Input>;
  where: Sensorflow_Node_System_States_Bool_Exp;
};

export type Sensorflow_Node_System_States_Var_Pop_Fields = {
  __typename?: "sensorflow_node_system_states_var_pop_fields";
  asleepS?: Maybe<Scalars["Float"]>;
  awakeS?: Maybe<Scalars["Float"]>;
  extra1S?: Maybe<Scalars["Float"]>;
  extra2S?: Maybe<Scalars["Float"]>;
  radioRxS?: Maybe<Scalars["Float"]>;
  radioTxS?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_System_States_Var_Samp_Fields = {
  __typename?: "sensorflow_node_system_states_var_samp_fields";
  asleepS?: Maybe<Scalars["Float"]>;
  awakeS?: Maybe<Scalars["Float"]>;
  extra1S?: Maybe<Scalars["Float"]>;
  extra2S?: Maybe<Scalars["Float"]>;
  radioRxS?: Maybe<Scalars["Float"]>;
  radioTxS?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_System_States_Variance_Fields = {
  __typename?: "sensorflow_node_system_states_variance_fields";
  asleepS?: Maybe<Scalars["Float"]>;
  awakeS?: Maybe<Scalars["Float"]>;
  extra1S?: Maybe<Scalars["Float"]>;
  extra2S?: Maybe<Scalars["Float"]>;
  radioRxS?: Maybe<Scalars["Float"]>;
  radioTxS?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_To_Position_Mapping = {
  __typename?: "sensorflow_node_to_position_mapping";
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  installedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_To_Position_Mapping_Aggregate = {
  __typename?: "sensorflow_node_to_position_mapping_aggregate";
  aggregate?: Maybe<Sensorflow_Node_To_Position_Mapping_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_To_Position_Mapping>;
};

export type Sensorflow_Node_To_Position_Mapping_Aggregate_Fields = {
  __typename?: "sensorflow_node_to_position_mapping_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_To_Position_Mapping_Max_Fields>;
  min?: Maybe<Sensorflow_Node_To_Position_Mapping_Min_Fields>;
};

export type Sensorflow_Node_To_Position_Mapping_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_To_Position_Mapping_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_To_Position_Mapping_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Bool_Exp>>;
  decomissionedTime?: Maybe<Timestamptz_Comparison_Exp>;
  installedTime?: Maybe<Timestamptz_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
};

export type Sensorflow_Node_To_Position_Mapping_Insert_Input = {
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  installedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_To_Position_Mapping_Max_Fields = {
  __typename?: "sensorflow_node_to_position_mapping_max_fields";
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  installedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_To_Position_Mapping_Min_Fields = {
  __typename?: "sensorflow_node_to_position_mapping_min_fields";
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  installedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_To_Position_Mapping_Mutation_Response = {
  __typename?: "sensorflow_node_to_position_mapping_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_To_Position_Mapping>;
};

export type Sensorflow_Node_To_Position_Mapping_Order_By = {
  decomissionedTime?: Maybe<Order_By>;
  installedTime?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
};

export enum Sensorflow_Node_To_Position_Mapping_Select_Column {
  DecomissionedTime = "decomissionedTime",
  InstalledTime = "installedTime",
  NodeMacId = "nodeMacId",
  PositionId = "positionId",
}

export type Sensorflow_Node_To_Position_Mapping_Set_Input = {
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  installedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_To_Position_Mapping_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_To_Position_Mapping_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_To_Position_Mapping_Stream_Cursor_Value_Input = {
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  installedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Node_To_Position_Mapping_Updates = {
  _set?: Maybe<Sensorflow_Node_To_Position_Mapping_Set_Input>;
  where: Sensorflow_Node_To_Position_Mapping_Bool_Exp;
};

export type Sensorflow_Node_To_Slot_Mapping = {
  __typename?: "sensorflow_node_to_slot_mapping";
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["Int"];
  isPrimaryNode?: Maybe<Scalars["Boolean"]>;
  mappedTime: Scalars["timestamptz"];
  node: Sensorflow_Nodes;
  nodeMacId: Scalars["String"];
  nodeType: Scalars["String"];
  parentPositionId: Scalars["uuid"];
  position: Sensorflow_Positions;
  rootCauseId?: Maybe<Scalars["Int"]>;
  scannedMacId?: Maybe<Scalars["String"]>;
  slot?: Maybe<Sensorflow_Node_Slots>;
  slotName: Scalars["String"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Aggregate = {
  __typename?: "sensorflow_node_to_slot_mapping_aggregate";
  aggregate?: Maybe<Sensorflow_Node_To_Slot_Mapping_Aggregate_Fields>;
  nodes: Array<Sensorflow_Node_To_Slot_Mapping>;
};

export type Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp_Bool_And = {
  arguments: Sensorflow_Node_To_Slot_Mapping_Select_Column_Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Sensorflow_Node_To_Slot_Mapping_Select_Column_Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Node_To_Slot_Mapping_Aggregate_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_aggregate_fields";
  avg?: Maybe<Sensorflow_Node_To_Slot_Mapping_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Node_To_Slot_Mapping_Max_Fields>;
  min?: Maybe<Sensorflow_Node_To_Slot_Mapping_Min_Fields>;
  stddev?: Maybe<Sensorflow_Node_To_Slot_Mapping_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Node_To_Slot_Mapping_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Node_To_Slot_Mapping_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Node_To_Slot_Mapping_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Node_To_Slot_Mapping_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Node_To_Slot_Mapping_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Node_To_Slot_Mapping_Variance_Fields>;
};

export type Sensorflow_Node_To_Slot_Mapping_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Node_To_Slot_Mapping_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Node_To_Slot_Mapping_Max_Order_By>;
  min?: Maybe<Sensorflow_Node_To_Slot_Mapping_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Node_To_Slot_Mapping_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Node_To_Slot_Mapping_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Node_To_Slot_Mapping_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Node_To_Slot_Mapping_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Node_To_Slot_Mapping_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Node_To_Slot_Mapping_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Node_To_Slot_Mapping_Variance_Order_By>;
};

export type Sensorflow_Node_To_Slot_Mapping_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Node_To_Slot_Mapping_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Node_To_Slot_Mapping_On_Conflict>;
};

export type Sensorflow_Node_To_Slot_Mapping_Avg_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  rootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Avg_Order_By = {
  id?: Maybe<Order_By>;
  rootCauseId?: Maybe<Order_By>;
};

export type Sensorflow_Node_To_Slot_Mapping_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>>;
  clickup_task_id?: Maybe<String_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  decomissionedTime?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  isPrimaryNode?: Maybe<Boolean_Comparison_Exp>;
  mappedTime?: Maybe<Timestamptz_Comparison_Exp>;
  node?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  nodeType?: Maybe<String_Comparison_Exp>;
  parentPositionId?: Maybe<Uuid_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  rootCauseId?: Maybe<Int_Comparison_Exp>;
  scannedMacId?: Maybe<String_Comparison_Exp>;
  slot?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
  slotName?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Node_To_Slot_Mapping_Constraint {
  NodeToSlotMappingPkey = "node_to_slot_mapping_pkey",
  NodeToSlotMappingUindex = "node_to_slot_mapping_uindex",
}

export type Sensorflow_Node_To_Slot_Mapping_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  rootCauseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Insert_Input = {
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  isPrimaryNode?: Maybe<Scalars["Boolean"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  node?: Maybe<Sensorflow_Nodes_Obj_Rel_Insert_Input>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  rootCauseId?: Maybe<Scalars["Int"]>;
  scannedMacId?: Maybe<Scalars["String"]>;
  slot?: Maybe<Sensorflow_Node_Slots_Obj_Rel_Insert_Input>;
  slotName?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Max_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_max_fields";
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  rootCauseId?: Maybe<Scalars["Int"]>;
  scannedMacId?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Max_Order_By = {
  clickup_task_id?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  decomissionedTime?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mappedTime?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  parentPositionId?: Maybe<Order_By>;
  rootCauseId?: Maybe<Order_By>;
  scannedMacId?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Sensorflow_Node_To_Slot_Mapping_Min_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_min_fields";
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  rootCauseId?: Maybe<Scalars["Int"]>;
  scannedMacId?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Min_Order_By = {
  clickup_task_id?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  decomissionedTime?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mappedTime?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  parentPositionId?: Maybe<Order_By>;
  rootCauseId?: Maybe<Order_By>;
  scannedMacId?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Sensorflow_Node_To_Slot_Mapping_Mutation_Response = {
  __typename?: "sensorflow_node_to_slot_mapping_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Node_To_Slot_Mapping>;
};

export type Sensorflow_Node_To_Slot_Mapping_On_Conflict = {
  constraint: Sensorflow_Node_To_Slot_Mapping_Constraint;
  update_columns: Array<Sensorflow_Node_To_Slot_Mapping_Update_Column>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Sensorflow_Node_To_Slot_Mapping_Order_By = {
  clickup_task_id?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  decomissionedTime?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isPrimaryNode?: Maybe<Order_By>;
  mappedTime?: Maybe<Order_By>;
  node?: Maybe<Sensorflow_Nodes_Order_By>;
  nodeMacId?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  parentPositionId?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  rootCauseId?: Maybe<Order_By>;
  scannedMacId?: Maybe<Order_By>;
  slot?: Maybe<Sensorflow_Node_Slots_Order_By>;
  slotName?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Sensorflow_Node_To_Slot_Mapping_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Node_To_Slot_Mapping_Select_Column {
  ClickupTaskId = "clickup_task_id",
  Comment = "comment",
  DecomissionedTime = "decomissionedTime",
  DeletedAt = "deleted_at",
  Id = "id",
  IsPrimaryNode = "isPrimaryNode",
  MappedTime = "mappedTime",
  NodeMacId = "nodeMacId",
  NodeType = "nodeType",
  ParentPositionId = "parentPositionId",
  RootCauseId = "rootCauseId",
  ScannedMacId = "scannedMacId",
  SlotName = "slotName",
  UpdatedAt = "updated_at",
}

export enum Sensorflow_Node_To_Slot_Mapping_Select_Column_Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  IsPrimaryNode = "isPrimaryNode",
}

export enum Sensorflow_Node_To_Slot_Mapping_Select_Column_Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  IsPrimaryNode = "isPrimaryNode",
}

export type Sensorflow_Node_To_Slot_Mapping_Set_Input = {
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  isPrimaryNode?: Maybe<Scalars["Boolean"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  rootCauseId?: Maybe<Scalars["Int"]>;
  scannedMacId?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Stddev_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  rootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  rootCauseId?: Maybe<Order_By>;
};

export type Sensorflow_Node_To_Slot_Mapping_Stddev_Pop_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  rootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  rootCauseId?: Maybe<Order_By>;
};

export type Sensorflow_Node_To_Slot_Mapping_Stddev_Samp_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  rootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  rootCauseId?: Maybe<Order_By>;
};

export type Sensorflow_Node_To_Slot_Mapping_Stream_Cursor_Input = {
  initial_value: Sensorflow_Node_To_Slot_Mapping_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Node_To_Slot_Mapping_Stream_Cursor_Value_Input = {
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  decomissionedTime?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  isPrimaryNode?: Maybe<Scalars["Boolean"]>;
  mappedTime?: Maybe<Scalars["timestamptz"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  rootCauseId?: Maybe<Scalars["Int"]>;
  scannedMacId?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Sum_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  rootCauseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Sum_Order_By = {
  id?: Maybe<Order_By>;
  rootCauseId?: Maybe<Order_By>;
};

export enum Sensorflow_Node_To_Slot_Mapping_Update_Column {
  ClickupTaskId = "clickup_task_id",
  Comment = "comment",
  DecomissionedTime = "decomissionedTime",
  DeletedAt = "deleted_at",
  Id = "id",
  IsPrimaryNode = "isPrimaryNode",
  MappedTime = "mappedTime",
  NodeMacId = "nodeMacId",
  NodeType = "nodeType",
  ParentPositionId = "parentPositionId",
  RootCauseId = "rootCauseId",
  ScannedMacId = "scannedMacId",
  SlotName = "slotName",
  UpdatedAt = "updated_at",
}

export type Sensorflow_Node_To_Slot_Mapping_Updates = {
  _inc?: Maybe<Sensorflow_Node_To_Slot_Mapping_Inc_Input>;
  _set?: Maybe<Sensorflow_Node_To_Slot_Mapping_Set_Input>;
  where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
};

export type Sensorflow_Node_To_Slot_Mapping_Var_Pop_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  rootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  rootCauseId?: Maybe<Order_By>;
};

export type Sensorflow_Node_To_Slot_Mapping_Var_Samp_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  rootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  rootCauseId?: Maybe<Order_By>;
};

export type Sensorflow_Node_To_Slot_Mapping_Variance_Fields = {
  __typename?: "sensorflow_node_to_slot_mapping_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  rootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Node_To_Slot_Mapping_Variance_Order_By = {
  id?: Maybe<Order_By>;
  rootCauseId?: Maybe<Order_By>;
};

export type Sensorflow_Nodes = {
  __typename?: "sensorflow_nodes";
  associated_gateway?: Maybe<Scalars["String"]>;
  climate_sensor?: Maybe<Scalars["Boolean"]>;
  currentFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases>;
  currentFirmwareVersionNumber?: Maybe<Scalars["Int"]>;
  firmwareVersionLocked?: Maybe<Scalars["Boolean"]>;
  gateway?: Maybe<Sensorflow_Gateways>;
  last_seen?: Maybe<Scalars["timestamptz"]>;
  nodeFirmware?: Maybe<Sensorflow_Node_Firmware>;
  nodeJoinData: Array<Sensorflow_Node_Join_Data>;
  nodeJoinData_aggregate: Sensorflow_Node_Join_Data_Aggregate;
  nodeJoinStatus?: Maybe<Sensorflow_Node_Join_Status>;
  nodeJoinStrengthLive?: Maybe<Sensorflow_Node_Join_Strength_Live>;
  nodeMetaData: Array<Sensorflow_Node_Meta_Data>;
  nodeMetaData_aggregate: Sensorflow_Node_Meta_Data_Aggregate;
  nodeMetaStatus?: Maybe<Sensorflow_Node_Meta_Status>;
  nodeOnlineStatus?: Maybe<Sensorflow_Node_Online_Status>;
  nodeSubType?: Maybe<Scalars["String"]>;
  node_mac_id: Scalars["String"];
  node_type: Scalars["String"];
  otaComment?: Maybe<Scalars["String"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Int"]>;
  slotMappings: Array<Sensorflow_Node_To_Slot_Mapping>;
  slotMappings_aggregate: Sensorflow_Node_To_Slot_Mapping_Aggregate;
  status_id?: Maybe<Scalars["Int"]>;
  targetFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases>;
  targetFirmwareReleaseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_NodesNodeJoinDataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
};

export type Sensorflow_NodesNodeJoinData_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
};

export type Sensorflow_NodesNodeMetaDataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Meta_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Meta_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
};

export type Sensorflow_NodesNodeMetaData_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Meta_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Meta_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
};

export type Sensorflow_NodesSlotMappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Sensorflow_NodesSlotMappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Sensorflow_Nodes_Aggregate = {
  __typename?: "sensorflow_nodes_aggregate";
  aggregate?: Maybe<Sensorflow_Nodes_Aggregate_Fields>;
  nodes: Array<Sensorflow_Nodes>;
};

export type Sensorflow_Nodes_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Sensorflow_Nodes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Sensorflow_Nodes_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Sensorflow_Nodes_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Nodes_Aggregate_Bool_Exp_Bool_And = {
  arguments: Sensorflow_Nodes_Select_Column_Sensorflow_Nodes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sensorflow_Nodes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Sensorflow_Nodes_Select_Column_Sensorflow_Nodes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sensorflow_Nodes_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Nodes_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Nodes_Aggregate_Fields = {
  __typename?: "sensorflow_nodes_aggregate_fields";
  avg?: Maybe<Sensorflow_Nodes_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Nodes_Max_Fields>;
  min?: Maybe<Sensorflow_Nodes_Min_Fields>;
  stddev?: Maybe<Sensorflow_Nodes_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Nodes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Nodes_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Nodes_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Nodes_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Nodes_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Nodes_Variance_Fields>;
};

export type Sensorflow_Nodes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Nodes_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Nodes_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Nodes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Nodes_Max_Order_By>;
  min?: Maybe<Sensorflow_Nodes_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Nodes_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Nodes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Nodes_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Nodes_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Nodes_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Nodes_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Nodes_Variance_Order_By>;
};

export type Sensorflow_Nodes_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Nodes_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Nodes_On_Conflict>;
};

export type Sensorflow_Nodes_Avg_Fields = {
  __typename?: "sensorflow_nodes_avg_fields";
  currentFirmwareVersionNumber?: Maybe<Scalars["Float"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Nodes_Avg_Order_By = {
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export type Sensorflow_Nodes_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Nodes_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Nodes_Bool_Exp>>;
  associated_gateway?: Maybe<String_Comparison_Exp>;
  climate_sensor?: Maybe<Boolean_Comparison_Exp>;
  currentFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases_Bool_Exp>;
  currentFirmwareVersionNumber?: Maybe<Int_Comparison_Exp>;
  firmwareVersionLocked?: Maybe<Boolean_Comparison_Exp>;
  gateway?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  last_seen?: Maybe<Timestamptz_Comparison_Exp>;
  nodeFirmware?: Maybe<Sensorflow_Node_Firmware_Bool_Exp>;
  nodeJoinData?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
  nodeJoinData_aggregate?: Maybe<Sensorflow_Node_Join_Data_Aggregate_Bool_Exp>;
  nodeJoinStatus?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
  nodeJoinStrengthLive?: Maybe<Sensorflow_Node_Join_Strength_Live_Bool_Exp>;
  nodeMetaData?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
  nodeMetaData_aggregate?: Maybe<Sensorflow_Node_Meta_Data_Aggregate_Bool_Exp>;
  nodeMetaStatus?: Maybe<Sensorflow_Node_Meta_Status_Bool_Exp>;
  nodeOnlineStatus?: Maybe<Sensorflow_Node_Online_Status_Bool_Exp>;
  nodeSubType?: Maybe<String_Comparison_Exp>;
  node_mac_id?: Maybe<String_Comparison_Exp>;
  node_type?: Maybe<String_Comparison_Exp>;
  otaComment?: Maybe<String_Comparison_Exp>;
  otaFailedUpgradeAttempts?: Maybe<Int_Comparison_Exp>;
  slotMappings?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
  slotMappings_aggregate?: Maybe<Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp>;
  status_id?: Maybe<Int_Comparison_Exp>;
  targetFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases_Bool_Exp>;
  targetFirmwareReleaseId?: Maybe<Int_Comparison_Exp>;
};

export enum Sensorflow_Nodes_Constraint {
  NodesPkey = "nodes_pkey",
}

export type Sensorflow_Nodes_Inc_Input = {
  currentFirmwareVersionNumber?: Maybe<Scalars["Int"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Nodes_Insert_Input = {
  associated_gateway?: Maybe<Scalars["String"]>;
  climate_sensor?: Maybe<Scalars["Boolean"]>;
  currentFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases_Obj_Rel_Insert_Input>;
  currentFirmwareVersionNumber?: Maybe<Scalars["Int"]>;
  firmwareVersionLocked?: Maybe<Scalars["Boolean"]>;
  gateway?: Maybe<Sensorflow_Gateways_Obj_Rel_Insert_Input>;
  last_seen?: Maybe<Scalars["timestamptz"]>;
  nodeFirmware?: Maybe<Sensorflow_Node_Firmware_Obj_Rel_Insert_Input>;
  nodeJoinData?: Maybe<Sensorflow_Node_Join_Data_Arr_Rel_Insert_Input>;
  nodeJoinStatus?: Maybe<Sensorflow_Node_Join_Status_Obj_Rel_Insert_Input>;
  nodeJoinStrengthLive?: Maybe<Sensorflow_Node_Join_Strength_Live_Obj_Rel_Insert_Input>;
  nodeMetaData?: Maybe<Sensorflow_Node_Meta_Data_Arr_Rel_Insert_Input>;
  nodeMetaStatus?: Maybe<Sensorflow_Node_Meta_Status_Obj_Rel_Insert_Input>;
  nodeOnlineStatus?: Maybe<Sensorflow_Node_Online_Status_Obj_Rel_Insert_Input>;
  nodeSubType?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  otaComment?: Maybe<Scalars["String"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Int"]>;
  slotMappings?: Maybe<Sensorflow_Node_To_Slot_Mapping_Arr_Rel_Insert_Input>;
  status_id?: Maybe<Scalars["Int"]>;
  targetFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases_Obj_Rel_Insert_Input>;
  targetFirmwareReleaseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Nodes_Max_Fields = {
  __typename?: "sensorflow_nodes_max_fields";
  associated_gateway?: Maybe<Scalars["String"]>;
  currentFirmwareVersionNumber?: Maybe<Scalars["Int"]>;
  last_seen?: Maybe<Scalars["timestamptz"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  otaComment?: Maybe<Scalars["String"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Nodes_Max_Order_By = {
  associated_gateway?: Maybe<Order_By>;
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  node_type?: Maybe<Order_By>;
  otaComment?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export type Sensorflow_Nodes_Min_Fields = {
  __typename?: "sensorflow_nodes_min_fields";
  associated_gateway?: Maybe<Scalars["String"]>;
  currentFirmwareVersionNumber?: Maybe<Scalars["Int"]>;
  last_seen?: Maybe<Scalars["timestamptz"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  otaComment?: Maybe<Scalars["String"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Nodes_Min_Order_By = {
  associated_gateway?: Maybe<Order_By>;
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  node_type?: Maybe<Order_By>;
  otaComment?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export type Sensorflow_Nodes_Mutation_Response = {
  __typename?: "sensorflow_nodes_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Nodes>;
};

export type Sensorflow_Nodes_Obj_Rel_Insert_Input = {
  data: Sensorflow_Nodes_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Nodes_On_Conflict>;
};

export type Sensorflow_Nodes_On_Conflict = {
  constraint: Sensorflow_Nodes_Constraint;
  update_columns: Array<Sensorflow_Nodes_Update_Column>;
  where?: Maybe<Sensorflow_Nodes_Bool_Exp>;
};

export type Sensorflow_Nodes_Order_By = {
  associated_gateway?: Maybe<Order_By>;
  climate_sensor?: Maybe<Order_By>;
  currentFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases_Order_By>;
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  firmwareVersionLocked?: Maybe<Order_By>;
  gateway?: Maybe<Sensorflow_Gateways_Order_By>;
  last_seen?: Maybe<Order_By>;
  nodeFirmware?: Maybe<Sensorflow_Node_Firmware_Order_By>;
  nodeJoinData_aggregate?: Maybe<Sensorflow_Node_Join_Data_Aggregate_Order_By>;
  nodeJoinStatus?: Maybe<Sensorflow_Node_Join_Status_Order_By>;
  nodeJoinStrengthLive?: Maybe<Sensorflow_Node_Join_Strength_Live_Order_By>;
  nodeMetaData_aggregate?: Maybe<Sensorflow_Node_Meta_Data_Aggregate_Order_By>;
  nodeMetaStatus?: Maybe<Sensorflow_Node_Meta_Status_Order_By>;
  nodeOnlineStatus?: Maybe<Sensorflow_Node_Online_Status_Order_By>;
  nodeSubType?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  node_type?: Maybe<Order_By>;
  otaComment?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  slotMappings_aggregate?: Maybe<Sensorflow_Node_To_Slot_Mapping_Aggregate_Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareRelease?: Maybe<Sensorflow_Firmware_Releases_Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export type Sensorflow_Nodes_Pk_Columns_Input = {
  node_mac_id: Scalars["String"];
};

export enum Sensorflow_Nodes_Select_Column {
  AssociatedGateway = "associated_gateway",
  ClimateSensor = "climate_sensor",
  CurrentFirmwareVersionNumber = "currentFirmwareVersionNumber",
  FirmwareVersionLocked = "firmwareVersionLocked",
  LastSeen = "last_seen",
  NodeSubType = "nodeSubType",
  NodeMacId = "node_mac_id",
  NodeType = "node_type",
  OtaComment = "otaComment",
  OtaFailedUpgradeAttempts = "otaFailedUpgradeAttempts",
  StatusId = "status_id",
  TargetFirmwareReleaseId = "targetFirmwareReleaseId",
}

export enum Sensorflow_Nodes_Select_Column_Sensorflow_Nodes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  ClimateSensor = "climate_sensor",
  FirmwareVersionLocked = "firmwareVersionLocked",
}

export enum Sensorflow_Nodes_Select_Column_Sensorflow_Nodes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  ClimateSensor = "climate_sensor",
  FirmwareVersionLocked = "firmwareVersionLocked",
}

export type Sensorflow_Nodes_Set_Input = {
  associated_gateway?: Maybe<Scalars["String"]>;
  climate_sensor?: Maybe<Scalars["Boolean"]>;
  currentFirmwareVersionNumber?: Maybe<Scalars["Int"]>;
  firmwareVersionLocked?: Maybe<Scalars["Boolean"]>;
  last_seen?: Maybe<Scalars["timestamptz"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  otaComment?: Maybe<Scalars["String"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Nodes_Stddev_Fields = {
  __typename?: "sensorflow_nodes_stddev_fields";
  currentFirmwareVersionNumber?: Maybe<Scalars["Float"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Nodes_Stddev_Order_By = {
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export type Sensorflow_Nodes_Stddev_Pop_Fields = {
  __typename?: "sensorflow_nodes_stddev_pop_fields";
  currentFirmwareVersionNumber?: Maybe<Scalars["Float"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Nodes_Stddev_Pop_Order_By = {
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export type Sensorflow_Nodes_Stddev_Samp_Fields = {
  __typename?: "sensorflow_nodes_stddev_samp_fields";
  currentFirmwareVersionNumber?: Maybe<Scalars["Float"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Nodes_Stddev_Samp_Order_By = {
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export type Sensorflow_Nodes_Stream_Cursor_Input = {
  initial_value: Sensorflow_Nodes_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Nodes_Stream_Cursor_Value_Input = {
  associated_gateway?: Maybe<Scalars["String"]>;
  climate_sensor?: Maybe<Scalars["Boolean"]>;
  currentFirmwareVersionNumber?: Maybe<Scalars["Int"]>;
  firmwareVersionLocked?: Maybe<Scalars["Boolean"]>;
  last_seen?: Maybe<Scalars["timestamptz"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  otaComment?: Maybe<Scalars["String"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Nodes_Sum_Fields = {
  __typename?: "sensorflow_nodes_sum_fields";
  currentFirmwareVersionNumber?: Maybe<Scalars["Int"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Int"]>;
  status_id?: Maybe<Scalars["Int"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Nodes_Sum_Order_By = {
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export enum Sensorflow_Nodes_Update_Column {
  AssociatedGateway = "associated_gateway",
  ClimateSensor = "climate_sensor",
  CurrentFirmwareVersionNumber = "currentFirmwareVersionNumber",
  FirmwareVersionLocked = "firmwareVersionLocked",
  LastSeen = "last_seen",
  NodeSubType = "nodeSubType",
  NodeMacId = "node_mac_id",
  NodeType = "node_type",
  OtaComment = "otaComment",
  OtaFailedUpgradeAttempts = "otaFailedUpgradeAttempts",
  StatusId = "status_id",
  TargetFirmwareReleaseId = "targetFirmwareReleaseId",
}

export type Sensorflow_Nodes_Updates = {
  _inc?: Maybe<Sensorflow_Nodes_Inc_Input>;
  _set?: Maybe<Sensorflow_Nodes_Set_Input>;
  where: Sensorflow_Nodes_Bool_Exp;
};

export type Sensorflow_Nodes_Var_Pop_Fields = {
  __typename?: "sensorflow_nodes_var_pop_fields";
  currentFirmwareVersionNumber?: Maybe<Scalars["Float"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Nodes_Var_Pop_Order_By = {
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export type Sensorflow_Nodes_Var_Samp_Fields = {
  __typename?: "sensorflow_nodes_var_samp_fields";
  currentFirmwareVersionNumber?: Maybe<Scalars["Float"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Nodes_Var_Samp_Order_By = {
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export type Sensorflow_Nodes_Variance_Fields = {
  __typename?: "sensorflow_nodes_variance_fields";
  currentFirmwareVersionNumber?: Maybe<Scalars["Float"]>;
  otaFailedUpgradeAttempts?: Maybe<Scalars["Float"]>;
  status_id?: Maybe<Scalars["Float"]>;
  targetFirmwareReleaseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Nodes_Variance_Order_By = {
  currentFirmwareVersionNumber?: Maybe<Order_By>;
  otaFailedUpgradeAttempts?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  targetFirmwareReleaseId?: Maybe<Order_By>;
};

export type Sensorflow_Organisations = {
  __typename?: "sensorflow_organisations";
  id: Scalars["uuid"];
  locations: Array<Sensorflow_Locations>;
  locations_aggregate: Sensorflow_Locations_Aggregate;
  name: Scalars["String"];
  status: Scalars["String"];
};

export type Sensorflow_OrganisationsLocationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Locations_Order_By>>;
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
};

export type Sensorflow_OrganisationsLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Locations_Order_By>>;
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
};

export type Sensorflow_Organisations_Aggregate = {
  __typename?: "sensorflow_organisations_aggregate";
  aggregate?: Maybe<Sensorflow_Organisations_Aggregate_Fields>;
  nodes: Array<Sensorflow_Organisations>;
};

export type Sensorflow_Organisations_Aggregate_Fields = {
  __typename?: "sensorflow_organisations_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Organisations_Max_Fields>;
  min?: Maybe<Sensorflow_Organisations_Min_Fields>;
};

export type Sensorflow_Organisations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Organisations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Organisations_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Organisations_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Organisations_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Organisations_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locations?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locations_aggregate?: Maybe<Sensorflow_Locations_Aggregate_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Organisations_Constraint {
  OrganisationsPkey = "organisations_pkey",
  UniqueName = "unique_name",
}

export type Sensorflow_Organisations_Insert_Input = {
  id?: Maybe<Scalars["uuid"]>;
  locations?: Maybe<Sensorflow_Locations_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Organisations_Max_Fields = {
  __typename?: "sensorflow_organisations_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Organisations_Min_Fields = {
  __typename?: "sensorflow_organisations_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Organisations_Mutation_Response = {
  __typename?: "sensorflow_organisations_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Organisations>;
};

export type Sensorflow_Organisations_Obj_Rel_Insert_Input = {
  data: Sensorflow_Organisations_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Organisations_On_Conflict>;
};

export type Sensorflow_Organisations_On_Conflict = {
  constraint: Sensorflow_Organisations_Constraint;
  update_columns: Array<Sensorflow_Organisations_Update_Column>;
  where?: Maybe<Sensorflow_Organisations_Bool_Exp>;
};

export type Sensorflow_Organisations_Order_By = {
  id?: Maybe<Order_By>;
  locations_aggregate?: Maybe<Sensorflow_Locations_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

export type Sensorflow_Organisations_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Organisations_Select_Column {
  Id = "id",
  Name = "name",
  Status = "status",
}

export type Sensorflow_Organisations_Set_Input = {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Organisations_Stream_Cursor_Input = {
  initial_value: Sensorflow_Organisations_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Organisations_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Organisations_Update_Column {
  Id = "id",
  Name = "name",
  Status = "status",
}

export type Sensorflow_Organisations_Updates = {
  _set?: Maybe<Sensorflow_Organisations_Set_Input>;
  where: Sensorflow_Organisations_Bool_Exp;
};

export type Sensorflow_Ota_Queues = {
  __typename?: "sensorflow_ota_queues";
  balena_id: Scalars["String"];
  created_time: Scalars["timestamptz"];
  end_time?: Maybe<Scalars["timestamptz"]>;
  gateway: Sensorflow_Gateways;
  location: Sensorflow_Locations;
  location_id: Scalars["uuid"];
  ota_queue_id: Scalars["Int"];
  start_time?: Maybe<Scalars["timestamptz"]>;
  status: Scalars["String"];
  target_firmare_release_id: Scalars["Int"];
};

export type Sensorflow_Ota_Queues_Aggregate = {
  __typename?: "sensorflow_ota_queues_aggregate";
  aggregate?: Maybe<Sensorflow_Ota_Queues_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ota_Queues>;
};

export type Sensorflow_Ota_Queues_Aggregate_Fields = {
  __typename?: "sensorflow_ota_queues_aggregate_fields";
  avg?: Maybe<Sensorflow_Ota_Queues_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ota_Queues_Max_Fields>;
  min?: Maybe<Sensorflow_Ota_Queues_Min_Fields>;
  stddev?: Maybe<Sensorflow_Ota_Queues_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Ota_Queues_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Ota_Queues_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Ota_Queues_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Ota_Queues_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Ota_Queues_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Ota_Queues_Variance_Fields>;
};

export type Sensorflow_Ota_Queues_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ota_Queues_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ota_Queues_Avg_Fields = {
  __typename?: "sensorflow_ota_queues_avg_fields";
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmare_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Queues_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ota_Queues_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ota_Queues_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ota_Queues_Bool_Exp>>;
  balena_id?: Maybe<String_Comparison_Exp>;
  created_time?: Maybe<Timestamptz_Comparison_Exp>;
  end_time?: Maybe<Timestamptz_Comparison_Exp>;
  gateway?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  ota_queue_id?: Maybe<Int_Comparison_Exp>;
  start_time?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  target_firmare_release_id?: Maybe<Int_Comparison_Exp>;
};

export enum Sensorflow_Ota_Queues_Constraint {
  OtaQueuesPkey = "ota_queues_pkey",
}

export type Sensorflow_Ota_Queues_Inc_Input = {
  ota_queue_id?: Maybe<Scalars["Int"]>;
  target_firmare_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Queues_Insert_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  end_time?: Maybe<Scalars["timestamptz"]>;
  gateway?: Maybe<Sensorflow_Gateways_Obj_Rel_Insert_Input>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["uuid"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  target_firmare_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Queues_Max_Fields = {
  __typename?: "sensorflow_ota_queues_max_fields";
  balena_id?: Maybe<Scalars["String"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  end_time?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  target_firmare_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Queues_Min_Fields = {
  __typename?: "sensorflow_ota_queues_min_fields";
  balena_id?: Maybe<Scalars["String"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  end_time?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  target_firmare_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Queues_Mutation_Response = {
  __typename?: "sensorflow_ota_queues_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Ota_Queues>;
};

export type Sensorflow_Ota_Queues_On_Conflict = {
  constraint: Sensorflow_Ota_Queues_Constraint;
  update_columns: Array<Sensorflow_Ota_Queues_Update_Column>;
  where?: Maybe<Sensorflow_Ota_Queues_Bool_Exp>;
};

export type Sensorflow_Ota_Queues_Order_By = {
  balena_id?: Maybe<Order_By>;
  created_time?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  gateway?: Maybe<Sensorflow_Gateways_Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  ota_queue_id?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  target_firmare_release_id?: Maybe<Order_By>;
};

export type Sensorflow_Ota_Queues_Pk_Columns_Input = {
  ota_queue_id: Scalars["Int"];
};

export enum Sensorflow_Ota_Queues_Select_Column {
  BalenaId = "balena_id",
  CreatedTime = "created_time",
  EndTime = "end_time",
  LocationId = "location_id",
  OtaQueueId = "ota_queue_id",
  StartTime = "start_time",
  Status = "status",
  TargetFirmareReleaseId = "target_firmare_release_id",
}

export type Sensorflow_Ota_Queues_Set_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  end_time?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  target_firmare_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Queues_Stddev_Fields = {
  __typename?: "sensorflow_ota_queues_stddev_fields";
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmare_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Queues_Stddev_Pop_Fields = {
  __typename?: "sensorflow_ota_queues_stddev_pop_fields";
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmare_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Queues_Stddev_Samp_Fields = {
  __typename?: "sensorflow_ota_queues_stddev_samp_fields";
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmare_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Queues_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ota_Queues_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ota_Queues_Stream_Cursor_Value_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  end_time?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  start_time?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  target_firmare_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Queues_Sum_Fields = {
  __typename?: "sensorflow_ota_queues_sum_fields";
  ota_queue_id?: Maybe<Scalars["Int"]>;
  target_firmare_release_id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Ota_Queues_Update_Column {
  BalenaId = "balena_id",
  CreatedTime = "created_time",
  EndTime = "end_time",
  LocationId = "location_id",
  OtaQueueId = "ota_queue_id",
  StartTime = "start_time",
  Status = "status",
  TargetFirmareReleaseId = "target_firmare_release_id",
}

export type Sensorflow_Ota_Queues_Updates = {
  _inc?: Maybe<Sensorflow_Ota_Queues_Inc_Input>;
  _set?: Maybe<Sensorflow_Ota_Queues_Set_Input>;
  where: Sensorflow_Ota_Queues_Bool_Exp;
};

export type Sensorflow_Ota_Queues_Var_Pop_Fields = {
  __typename?: "sensorflow_ota_queues_var_pop_fields";
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmare_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Queues_Var_Samp_Fields = {
  __typename?: "sensorflow_ota_queues_var_samp_fields";
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmare_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Queues_Variance_Fields = {
  __typename?: "sensorflow_ota_queues_variance_fields";
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmare_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Runs = {
  __typename?: "sensorflow_ota_runs";
  current_firmware_release_id: Scalars["Int"];
  node_mac_id: Scalars["String"];
  ota_queue_id: Scalars["Int"];
  result: Scalars["String"];
  target_firmware_release_id: Scalars["Int"];
};

export type Sensorflow_Ota_Runs_Aggregate = {
  __typename?: "sensorflow_ota_runs_aggregate";
  aggregate?: Maybe<Sensorflow_Ota_Runs_Aggregate_Fields>;
  nodes: Array<Sensorflow_Ota_Runs>;
};

export type Sensorflow_Ota_Runs_Aggregate_Fields = {
  __typename?: "sensorflow_ota_runs_aggregate_fields";
  avg?: Maybe<Sensorflow_Ota_Runs_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Ota_Runs_Max_Fields>;
  min?: Maybe<Sensorflow_Ota_Runs_Min_Fields>;
  stddev?: Maybe<Sensorflow_Ota_Runs_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Ota_Runs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Ota_Runs_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Ota_Runs_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Ota_Runs_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Ota_Runs_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Ota_Runs_Variance_Fields>;
};

export type Sensorflow_Ota_Runs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Ota_Runs_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Ota_Runs_Avg_Fields = {
  __typename?: "sensorflow_ota_runs_avg_fields";
  current_firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Runs_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Ota_Runs_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Ota_Runs_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Ota_Runs_Bool_Exp>>;
  current_firmware_release_id?: Maybe<Int_Comparison_Exp>;
  node_mac_id?: Maybe<String_Comparison_Exp>;
  ota_queue_id?: Maybe<Int_Comparison_Exp>;
  result?: Maybe<String_Comparison_Exp>;
  target_firmware_release_id?: Maybe<Int_Comparison_Exp>;
};

export enum Sensorflow_Ota_Runs_Constraint {
  OtaRunsPkey = "ota_runs_pkey",
}

export type Sensorflow_Ota_Runs_Inc_Input = {
  current_firmware_release_id?: Maybe<Scalars["Int"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Runs_Insert_Input = {
  current_firmware_release_id?: Maybe<Scalars["Int"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  result?: Maybe<Scalars["String"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Runs_Max_Fields = {
  __typename?: "sensorflow_ota_runs_max_fields";
  current_firmware_release_id?: Maybe<Scalars["Int"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  result?: Maybe<Scalars["String"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Runs_Min_Fields = {
  __typename?: "sensorflow_ota_runs_min_fields";
  current_firmware_release_id?: Maybe<Scalars["Int"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  result?: Maybe<Scalars["String"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Runs_Mutation_Response = {
  __typename?: "sensorflow_ota_runs_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Ota_Runs>;
};

export type Sensorflow_Ota_Runs_On_Conflict = {
  constraint: Sensorflow_Ota_Runs_Constraint;
  update_columns: Array<Sensorflow_Ota_Runs_Update_Column>;
  where?: Maybe<Sensorflow_Ota_Runs_Bool_Exp>;
};

export type Sensorflow_Ota_Runs_Order_By = {
  current_firmware_release_id?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  ota_queue_id?: Maybe<Order_By>;
  result?: Maybe<Order_By>;
  target_firmware_release_id?: Maybe<Order_By>;
};

export type Sensorflow_Ota_Runs_Pk_Columns_Input = {
  current_firmware_release_id: Scalars["Int"];
  node_mac_id: Scalars["String"];
  ota_queue_id: Scalars["Int"];
  target_firmware_release_id: Scalars["Int"];
};

export enum Sensorflow_Ota_Runs_Select_Column {
  CurrentFirmwareReleaseId = "current_firmware_release_id",
  NodeMacId = "node_mac_id",
  OtaQueueId = "ota_queue_id",
  Result = "result",
  TargetFirmwareReleaseId = "target_firmware_release_id",
}

export type Sensorflow_Ota_Runs_Set_Input = {
  current_firmware_release_id?: Maybe<Scalars["Int"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  result?: Maybe<Scalars["String"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Runs_Stddev_Fields = {
  __typename?: "sensorflow_ota_runs_stddev_fields";
  current_firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Runs_Stddev_Pop_Fields = {
  __typename?: "sensorflow_ota_runs_stddev_pop_fields";
  current_firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Runs_Stddev_Samp_Fields = {
  __typename?: "sensorflow_ota_runs_stddev_samp_fields";
  current_firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Runs_Stream_Cursor_Input = {
  initial_value: Sensorflow_Ota_Runs_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Ota_Runs_Stream_Cursor_Value_Input = {
  current_firmware_release_id?: Maybe<Scalars["Int"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  result?: Maybe<Scalars["String"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Ota_Runs_Sum_Fields = {
  __typename?: "sensorflow_ota_runs_sum_fields";
  current_firmware_release_id?: Maybe<Scalars["Int"]>;
  ota_queue_id?: Maybe<Scalars["Int"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Ota_Runs_Update_Column {
  CurrentFirmwareReleaseId = "current_firmware_release_id",
  NodeMacId = "node_mac_id",
  OtaQueueId = "ota_queue_id",
  Result = "result",
  TargetFirmwareReleaseId = "target_firmware_release_id",
}

export type Sensorflow_Ota_Runs_Updates = {
  _inc?: Maybe<Sensorflow_Ota_Runs_Inc_Input>;
  _set?: Maybe<Sensorflow_Ota_Runs_Set_Input>;
  where: Sensorflow_Ota_Runs_Bool_Exp;
};

export type Sensorflow_Ota_Runs_Var_Pop_Fields = {
  __typename?: "sensorflow_ota_runs_var_pop_fields";
  current_firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Runs_Var_Samp_Fields = {
  __typename?: "sensorflow_ota_runs_var_samp_fields";
  current_firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Ota_Runs_Variance_Fields = {
  __typename?: "sensorflow_ota_runs_variance_fields";
  current_firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_queue_id?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Outdoor_Temperatures = {
  __typename?: "sensorflow_outdoor_temperatures";
  locations?: Maybe<Sensorflow_Locations>;
  measurementType: Scalars["String"];
  measurementValue: Scalars["numeric"];
  positionId: Scalars["uuid"];
  source: Scalars["String"];
  time: Scalars["timestamptz"];
};

export type Sensorflow_Outdoor_Temperatures_Aggregate = {
  __typename?: "sensorflow_outdoor_temperatures_aggregate";
  aggregate?: Maybe<Sensorflow_Outdoor_Temperatures_Aggregate_Fields>;
  nodes: Array<Sensorflow_Outdoor_Temperatures>;
};

export type Sensorflow_Outdoor_Temperatures_Aggregate_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_aggregate_fields";
  avg?: Maybe<Sensorflow_Outdoor_Temperatures_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Outdoor_Temperatures_Max_Fields>;
  min?: Maybe<Sensorflow_Outdoor_Temperatures_Min_Fields>;
  stddev?: Maybe<Sensorflow_Outdoor_Temperatures_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Outdoor_Temperatures_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Outdoor_Temperatures_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Outdoor_Temperatures_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Outdoor_Temperatures_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Outdoor_Temperatures_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Outdoor_Temperatures_Variance_Fields>;
};

export type Sensorflow_Outdoor_Temperatures_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Outdoor_Temperatures_Avg_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_avg_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Outdoor_Temperatures_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Outdoor_Temperatures_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Bool_Exp>>;
  locations?: Maybe<Sensorflow_Locations_Bool_Exp>;
  measurementType?: Maybe<String_Comparison_Exp>;
  measurementValue?: Maybe<Numeric_Comparison_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Outdoor_Temperatures_Constraint {
  OutdoorTemperaturesPkey = "outdoor_temperatures_pkey",
}

export type Sensorflow_Outdoor_Temperatures_Inc_Input = {
  measurementValue?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Outdoor_Temperatures_Insert_Input = {
  locations?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Outdoor_Temperatures_Max_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_max_fields";
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Outdoor_Temperatures_Min_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_min_fields";
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Outdoor_Temperatures_Mutation_Response = {
  __typename?: "sensorflow_outdoor_temperatures_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Outdoor_Temperatures>;
};

export type Sensorflow_Outdoor_Temperatures_On_Conflict = {
  constraint: Sensorflow_Outdoor_Temperatures_Constraint;
  update_columns: Array<Sensorflow_Outdoor_Temperatures_Update_Column>;
  where?: Maybe<Sensorflow_Outdoor_Temperatures_Bool_Exp>;
};

export type Sensorflow_Outdoor_Temperatures_Order_By = {
  locations?: Maybe<Sensorflow_Locations_Order_By>;
  measurementType?: Maybe<Order_By>;
  measurementValue?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

export type Sensorflow_Outdoor_Temperatures_Pk_Columns_Input = {
  positionId: Scalars["uuid"];
  time: Scalars["timestamptz"];
};

export enum Sensorflow_Outdoor_Temperatures_Select_Column {
  MeasurementType = "measurementType",
  MeasurementValue = "measurementValue",
  PositionId = "positionId",
  Source = "source",
  Time = "time",
}

export type Sensorflow_Outdoor_Temperatures_Set_Input = {
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Outdoor_Temperatures_Stddev_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_stddev_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Outdoor_Temperatures_Stddev_Pop_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_stddev_pop_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Outdoor_Temperatures_Stddev_Samp_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_stddev_samp_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Outdoor_Temperatures_Stream_Cursor_Input = {
  initial_value: Sensorflow_Outdoor_Temperatures_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Outdoor_Temperatures_Stream_Cursor_Value_Input = {
  measurementType?: Maybe<Scalars["String"]>;
  measurementValue?: Maybe<Scalars["numeric"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Outdoor_Temperatures_Sum_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_sum_fields";
  measurementValue?: Maybe<Scalars["numeric"]>;
};

export enum Sensorflow_Outdoor_Temperatures_Update_Column {
  MeasurementType = "measurementType",
  MeasurementValue = "measurementValue",
  PositionId = "positionId",
  Source = "source",
  Time = "time",
}

export type Sensorflow_Outdoor_Temperatures_Updates = {
  _inc?: Maybe<Sensorflow_Outdoor_Temperatures_Inc_Input>;
  _set?: Maybe<Sensorflow_Outdoor_Temperatures_Set_Input>;
  where: Sensorflow_Outdoor_Temperatures_Bool_Exp;
};

export type Sensorflow_Outdoor_Temperatures_Var_Pop_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_var_pop_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Outdoor_Temperatures_Var_Samp_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_var_samp_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Outdoor_Temperatures_Variance_Fields = {
  __typename?: "sensorflow_outdoor_temperatures_variance_fields";
  measurementValue?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway";
  balenaId?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  position?: Maybe<Sensorflow_Positions>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  toAcMode?: Maybe<Scalars["Int"]>;
  toAcSetPoint?: Maybe<Scalars["Int"]>;
  toAcStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Aggregate = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_aggregate";
  aggregate?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Aggregate_Fields>;
  nodes: Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Aggregate_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_aggregate_fields";
  avg?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Max_Fields>;
  min?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Min_Fields>;
  stddev?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Variance_Fields>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Avg_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_avg_fields";
  toAcMode?: Maybe<Scalars["Float"]>;
  toAcSetPoint?: Maybe<Scalars["Float"]>;
  toAcStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Bool_Exp>>;
  balenaId?: Maybe<String_Comparison_Exp>;
  creationDate?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  toAcMode?: Maybe<Int_Comparison_Exp>;
  toAcSetPoint?: Maybe<Int_Comparison_Exp>;
  toAcStatus?: Maybe<Int_Comparison_Exp>;
  toFanSpeed?: Maybe<Int_Comparison_Exp>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Max_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_max_fields";
  balenaId?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  toAcMode?: Maybe<Scalars["Int"]>;
  toAcSetPoint?: Maybe<Scalars["Int"]>;
  toAcStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Min_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_min_fields";
  balenaId?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  toAcMode?: Maybe<Scalars["Int"]>;
  toAcSetPoint?: Maybe<Scalars["Int"]>;
  toAcStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Order_By = {
  balenaId?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  toAcMode?: Maybe<Order_By>;
  toAcSetPoint?: Maybe<Order_By>;
  toAcStatus?: Maybe<Order_By>;
  toFanSpeed?: Maybe<Order_By>;
};

export enum Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Select_Column {
  BalenaId = "balenaId",
  CreationDate = "creationDate",
  Id = "id",
  PositionId = "positionId",
  Source = "source",
  ToAcMode = "toAcMode",
  ToAcSetPoint = "toAcSetPoint",
  ToAcStatus = "toAcStatus",
  ToFanSpeed = "toFanSpeed",
}

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Stddev_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_stddev_fields";
  toAcMode?: Maybe<Scalars["Float"]>;
  toAcSetPoint?: Maybe<Scalars["Float"]>;
  toAcStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Stddev_Pop_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_stddev_pop_fields";
  toAcMode?: Maybe<Scalars["Float"]>;
  toAcSetPoint?: Maybe<Scalars["Float"]>;
  toAcStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Stddev_Samp_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_stddev_samp_fields";
  toAcMode?: Maybe<Scalars["Float"]>;
  toAcSetPoint?: Maybe<Scalars["Float"]>;
  toAcStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Stream_Cursor_Input = {
  initial_value: Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Stream_Cursor_Value_Input = {
  balenaId?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  source?: Maybe<Scalars["String"]>;
  toAcMode?: Maybe<Scalars["Int"]>;
  toAcSetPoint?: Maybe<Scalars["Int"]>;
  toAcStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Sum_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_sum_fields";
  toAcMode?: Maybe<Scalars["Int"]>;
  toAcSetPoint?: Maybe<Scalars["Int"]>;
  toAcStatus?: Maybe<Scalars["Int"]>;
  toFanSpeed?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Var_Pop_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_var_pop_fields";
  toAcMode?: Maybe<Scalars["Float"]>;
  toAcSetPoint?: Maybe<Scalars["Float"]>;
  toAcStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Var_Samp_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_var_samp_fields";
  toAcMode?: Maybe<Scalars["Float"]>;
  toAcSetPoint?: Maybe<Scalars["Float"]>;
  toAcStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Variance_Fields = {
  __typename?: "sensorflow_pending_ac_settings_commands_for_gateway_variance_fields";
  toAcMode?: Maybe<Scalars["Float"]>;
  toAcSetPoint?: Maybe<Scalars["Float"]>;
  toAcStatus?: Maybe<Scalars["Float"]>;
  toFanSpeed?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pipes = {
  __typename?: "sensorflow_pipes";
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  meterPosition?: Maybe<Sensorflow_Positions>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
};

export type Sensorflow_Pipes_Aggregate = {
  __typename?: "sensorflow_pipes_aggregate";
  aggregate?: Maybe<Sensorflow_Pipes_Aggregate_Fields>;
  nodes: Array<Sensorflow_Pipes>;
};

export type Sensorflow_Pipes_Aggregate_Fields = {
  __typename?: "sensorflow_pipes_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Pipes_Max_Fields>;
  min?: Maybe<Sensorflow_Pipes_Min_Fields>;
};

export type Sensorflow_Pipes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Pipes_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Pipes_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Pipes_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Pipes_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Pipes_Bool_Exp>>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  meterPosition?: Maybe<Sensorflow_Positions_Bool_Exp>;
  meterPositionId?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Pipes_Constraint {
  PipesPkey = "pipes_pkey",
}

export type Sensorflow_Pipes_Insert_Input = {
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPosition?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Pipes_Max_Fields = {
  __typename?: "sensorflow_pipes_max_fields";
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Pipes_Min_Fields = {
  __typename?: "sensorflow_pipes_min_fields";
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Pipes_Mutation_Response = {
  __typename?: "sensorflow_pipes_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Pipes>;
};

export type Sensorflow_Pipes_On_Conflict = {
  constraint: Sensorflow_Pipes_Constraint;
  update_columns: Array<Sensorflow_Pipes_Update_Column>;
  where?: Maybe<Sensorflow_Pipes_Bool_Exp>;
};

export type Sensorflow_Pipes_Order_By = {
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meterPosition?: Maybe<Sensorflow_Positions_Order_By>;
  meterPositionId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
};

export type Sensorflow_Pipes_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Pipes_Select_Column {
  DeletedAt = "deleted_at",
  Id = "id",
  MeterPositionId = "meterPositionId",
  Name = "name",
  PositionId = "positionId",
}

export type Sensorflow_Pipes_Set_Input = {
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Pipes_Stream_Cursor_Input = {
  initial_value: Sensorflow_Pipes_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Pipes_Stream_Cursor_Value_Input = {
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export enum Sensorflow_Pipes_Update_Column {
  DeletedAt = "deleted_at",
  Id = "id",
  MeterPositionId = "meterPositionId",
  Name = "name",
  PositionId = "positionId",
}

export type Sensorflow_Pipes_Updates = {
  _set?: Maybe<Sensorflow_Pipes_Set_Input>;
  where: Sensorflow_Pipes_Bool_Exp;
};

export type Sensorflow_Position_Precool_Status = {
  __typename?: "sensorflow_position_precool_status";
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  mode?: Maybe<Scalars["Int"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  precoolCommandId?: Maybe<Scalars["uuid"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Position_Precool_Status_Aggregate = {
  __typename?: "sensorflow_position_precool_status_aggregate";
  aggregate?: Maybe<Sensorflow_Position_Precool_Status_Aggregate_Fields>;
  nodes: Array<Sensorflow_Position_Precool_Status>;
};

export type Sensorflow_Position_Precool_Status_Aggregate_Fields = {
  __typename?: "sensorflow_position_precool_status_aggregate_fields";
  avg?: Maybe<Sensorflow_Position_Precool_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Position_Precool_Status_Max_Fields>;
  min?: Maybe<Sensorflow_Position_Precool_Status_Min_Fields>;
  stddev?: Maybe<Sensorflow_Position_Precool_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Position_Precool_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Position_Precool_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Position_Precool_Status_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Position_Precool_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Position_Precool_Status_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Position_Precool_Status_Variance_Fields>;
};

export type Sensorflow_Position_Precool_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Position_Precool_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Position_Precool_Status_Avg_Fields = {
  __typename?: "sensorflow_position_precool_status_avg_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Precool_Status_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Position_Precool_Status_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Position_Precool_Status_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Position_Precool_Status_Bool_Exp>>;
  createdBy?: Maybe<String_Comparison_Exp>;
  creationDate?: Maybe<Timestamptz_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  mode?: Maybe<Int_Comparison_Exp>;
  parentPositionId?: Maybe<Uuid_Comparison_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  precoolCommandId?: Maybe<Uuid_Comparison_Exp>;
  startedAt?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  temperature?: Maybe<Int_Comparison_Exp>;
  totalMinutes?: Maybe<Int_Comparison_Exp>;
};

export type Sensorflow_Position_Precool_Status_Max_Fields = {
  __typename?: "sensorflow_position_precool_status_max_fields";
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  mode?: Maybe<Scalars["Int"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  precoolCommandId?: Maybe<Scalars["uuid"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Position_Precool_Status_Min_Fields = {
  __typename?: "sensorflow_position_precool_status_min_fields";
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  mode?: Maybe<Scalars["Int"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  precoolCommandId?: Maybe<Scalars["uuid"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Position_Precool_Status_Order_By = {
  createdBy?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  mode?: Maybe<Order_By>;
  parentPositionId?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  precoolCommandId?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
};

export enum Sensorflow_Position_Precool_Status_Select_Column {
  CreatedBy = "createdBy",
  CreationDate = "creationDate",
  LocationId = "locationId",
  Mode = "mode",
  ParentPositionId = "parentPositionId",
  PositionId = "positionId",
  PrecoolCommandId = "precoolCommandId",
  StartedAt = "startedAt",
  Status = "status",
  Temperature = "temperature",
  TotalMinutes = "totalMinutes",
}

export type Sensorflow_Position_Precool_Status_Stddev_Fields = {
  __typename?: "sensorflow_position_precool_status_stddev_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Precool_Status_Stddev_Pop_Fields = {
  __typename?: "sensorflow_position_precool_status_stddev_pop_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Precool_Status_Stddev_Samp_Fields = {
  __typename?: "sensorflow_position_precool_status_stddev_samp_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Precool_Status_Stream_Cursor_Input = {
  initial_value: Sensorflow_Position_Precool_Status_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Position_Precool_Status_Stream_Cursor_Value_Input = {
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  mode?: Maybe<Scalars["Int"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  precoolCommandId?: Maybe<Scalars["uuid"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Position_Precool_Status_Sum_Fields = {
  __typename?: "sensorflow_position_precool_status_sum_fields";
  mode?: Maybe<Scalars["Int"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Position_Precool_Status_Var_Pop_Fields = {
  __typename?: "sensorflow_position_precool_status_var_pop_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Precool_Status_Var_Samp_Fields = {
  __typename?: "sensorflow_position_precool_status_var_samp_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Precool_Status_Variance_Fields = {
  __typename?: "sensorflow_position_precool_status_variance_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Signal_Stats = {
  __typename?: "sensorflow_position_signal_stats";
  balena_id: Scalars["String"];
  last_updated: Scalars["timestamptz"];
  lowest_signal?: Maybe<Scalars["numeric"]>;
  position_id: Scalars["uuid"];
};

export type Sensorflow_Position_Signal_Stats_Aggregate = {
  __typename?: "sensorflow_position_signal_stats_aggregate";
  aggregate?: Maybe<Sensorflow_Position_Signal_Stats_Aggregate_Fields>;
  nodes: Array<Sensorflow_Position_Signal_Stats>;
};

export type Sensorflow_Position_Signal_Stats_Aggregate_Fields = {
  __typename?: "sensorflow_position_signal_stats_aggregate_fields";
  avg?: Maybe<Sensorflow_Position_Signal_Stats_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Position_Signal_Stats_Max_Fields>;
  min?: Maybe<Sensorflow_Position_Signal_Stats_Min_Fields>;
  stddev?: Maybe<Sensorflow_Position_Signal_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Position_Signal_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Position_Signal_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Position_Signal_Stats_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Position_Signal_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Position_Signal_Stats_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Position_Signal_Stats_Variance_Fields>;
};

export type Sensorflow_Position_Signal_Stats_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Position_Signal_Stats_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Position_Signal_Stats_Avg_Fields = {
  __typename?: "sensorflow_position_signal_stats_avg_fields";
  lowest_signal?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Signal_Stats_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Position_Signal_Stats_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Position_Signal_Stats_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Position_Signal_Stats_Bool_Exp>>;
  balena_id?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  lowest_signal?: Maybe<Numeric_Comparison_Exp>;
  position_id?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Position_Signal_Stats_Constraint {
  PositionSignalStatsPkey = "position_signal_stats_pkey",
}

export type Sensorflow_Position_Signal_Stats_Inc_Input = {
  lowest_signal?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Position_Signal_Stats_Insert_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  lowest_signal?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Position_Signal_Stats_Max_Fields = {
  __typename?: "sensorflow_position_signal_stats_max_fields";
  balena_id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  lowest_signal?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Position_Signal_Stats_Min_Fields = {
  __typename?: "sensorflow_position_signal_stats_min_fields";
  balena_id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  lowest_signal?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Position_Signal_Stats_Mutation_Response = {
  __typename?: "sensorflow_position_signal_stats_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Position_Signal_Stats>;
};

export type Sensorflow_Position_Signal_Stats_On_Conflict = {
  constraint: Sensorflow_Position_Signal_Stats_Constraint;
  update_columns: Array<Sensorflow_Position_Signal_Stats_Update_Column>;
  where?: Maybe<Sensorflow_Position_Signal_Stats_Bool_Exp>;
};

export type Sensorflow_Position_Signal_Stats_Order_By = {
  balena_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  lowest_signal?: Maybe<Order_By>;
  position_id?: Maybe<Order_By>;
};

export type Sensorflow_Position_Signal_Stats_Pk_Columns_Input = {
  balena_id: Scalars["String"];
  position_id: Scalars["uuid"];
};

export enum Sensorflow_Position_Signal_Stats_Select_Column {
  BalenaId = "balena_id",
  LastUpdated = "last_updated",
  LowestSignal = "lowest_signal",
  PositionId = "position_id",
}

export type Sensorflow_Position_Signal_Stats_Set_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  lowest_signal?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Position_Signal_Stats_Stddev_Fields = {
  __typename?: "sensorflow_position_signal_stats_stddev_fields";
  lowest_signal?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Signal_Stats_Stddev_Pop_Fields = {
  __typename?: "sensorflow_position_signal_stats_stddev_pop_fields";
  lowest_signal?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Signal_Stats_Stddev_Samp_Fields = {
  __typename?: "sensorflow_position_signal_stats_stddev_samp_fields";
  lowest_signal?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Signal_Stats_Stream_Cursor_Input = {
  initial_value: Sensorflow_Position_Signal_Stats_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Position_Signal_Stats_Stream_Cursor_Value_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  lowest_signal?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Position_Signal_Stats_Sum_Fields = {
  __typename?: "sensorflow_position_signal_stats_sum_fields";
  lowest_signal?: Maybe<Scalars["numeric"]>;
};

export enum Sensorflow_Position_Signal_Stats_Update_Column {
  BalenaId = "balena_id",
  LastUpdated = "last_updated",
  LowestSignal = "lowest_signal",
  PositionId = "position_id",
}

export type Sensorflow_Position_Signal_Stats_Updates = {
  _inc?: Maybe<Sensorflow_Position_Signal_Stats_Inc_Input>;
  _set?: Maybe<Sensorflow_Position_Signal_Stats_Set_Input>;
  where: Sensorflow_Position_Signal_Stats_Bool_Exp;
};

export type Sensorflow_Position_Signal_Stats_Var_Pop_Fields = {
  __typename?: "sensorflow_position_signal_stats_var_pop_fields";
  lowest_signal?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Signal_Stats_Var_Samp_Fields = {
  __typename?: "sensorflow_position_signal_stats_var_samp_fields";
  lowest_signal?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Position_Signal_Stats_Variance_Fields = {
  __typename?: "sensorflow_position_signal_stats_variance_fields";
  lowest_signal?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Positions = {
  __typename?: "sensorflow_positions";
  acSettingCommands: Array<Sensorflow_Ac_Setting_Commands>;
  acSettingCommands_aggregate: Sensorflow_Ac_Setting_Commands_Aggregate;
  clickup_task_id?: Maybe<Scalars["String"]>;
  compressor?: Maybe<Sensorflow_Compressors>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  eventsStream: Array<Sensorflow_Events_Stream>;
  eventsStream_aggregate: Sensorflow_Events_Stream_Aggregate;
  gatewayMapping: Array<Sensorflow_Gateway_To_Position_Mapping>;
  gatewayMapping_aggregate: Sensorflow_Gateway_To_Position_Mapping_Aggregate;
  gateways: Array<Sensorflow_Gateways>;
  gateways_aggregate: Sensorflow_Gateways_Aggregate;
  infrastructure?: Maybe<Sensorflow_V_Infrastructures>;
  keyCategoryPositionMapping?: Maybe<Sensorflow_Key_Category_To_Key_Position>;
  location?: Maybe<Sensorflow_Locations>;
  locationId: Scalars["uuid"];
  mappingCoordinates: Array<Sensorflow_Mapping_Coordinates>;
  mappingCoordinates_aggregate: Sensorflow_Mapping_Coordinates_Aggregate;
  nodeJoinStatuses: Array<Sensorflow_Node_Join_Status>;
  nodeJoinStatuses_aggregate: Sensorflow_Node_Join_Status_Aggregate;
  nodeMeasurements: Array<Sensorflow_Node_Measurement_View>;
  nodeMeasurements_aggregate: Sensorflow_Node_Measurement_View_Aggregate;
  nodeSlots: Array<Sensorflow_Node_Slots>;
  nodeSlots_aggregate: Sensorflow_Node_Slots_Aggregate;
  node_measurements: Array<Sensorflow_Node_Measurements>;
  node_measurements_aggregate: Sensorflow_Node_Measurements_Aggregate;
  parentPosition?: Maybe<Sensorflow_Positions>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  positionConfiguration: Array<PositionConfigurations>;
  positionConfiguration_aggregate: PositionConfigurations_Aggregate;
  positionConfigurationsForActivityLog: Array<PositionConfigurationsForActivityLog>;
  positionConfigurationsForActivityLog_aggregate: PositionConfigurationsForActivityLog_Aggregate;
  positionFunction: Scalars["String"];
  positionId: Scalars["uuid"];
  positionName: Scalars["String"];
  positionType: Scalars["String"];
  precoolCommands: Array<Sensorflow_Precool_Commands>;
  precoolCommands_aggregate: Sensorflow_Precool_Commands_Aggregate;
  reservation: Array<Sensorflow_Reservations>;
  reservation_aggregate: Sensorflow_Reservations_Aggregate;
  rooms: Array<Sensorflow_Positions>;
  rooms_aggregate: Sensorflow_Positions_Aggregate;
  searchRooms?: Maybe<Array<Sensorflow_Positions>>;
  slotMappings: Array<Sensorflow_Node_To_Slot_Mapping>;
  slotMappings_aggregate: Sensorflow_Node_To_Slot_Mapping_Aggregate;
};

export type Sensorflow_PositionsAcSettingCommandsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
};

export type Sensorflow_PositionsAcSettingCommands_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
};

export type Sensorflow_PositionsEventsStreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Events_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Events_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Events_Stream_Bool_Exp>;
};

export type Sensorflow_PositionsEventsStream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Events_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Events_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Events_Stream_Bool_Exp>;
};

export type Sensorflow_PositionsGatewayMappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
};

export type Sensorflow_PositionsGatewayMapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
};

export type Sensorflow_PositionsGatewaysArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateways_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateways_Order_By>>;
  where?: Maybe<Sensorflow_Gateways_Bool_Exp>;
};

export type Sensorflow_PositionsGateways_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateways_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateways_Order_By>>;
  where?: Maybe<Sensorflow_Gateways_Bool_Exp>;
};

export type Sensorflow_PositionsMappingCoordinatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Mapping_Coordinates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Mapping_Coordinates_Order_By>>;
  where?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
};

export type Sensorflow_PositionsMappingCoordinates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Mapping_Coordinates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Mapping_Coordinates_Order_By>>;
  where?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
};

export type Sensorflow_PositionsNodeJoinStatusesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
};

export type Sensorflow_PositionsNodeJoinStatuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
};

export type Sensorflow_PositionsNodeMeasurementsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurement_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurement_View_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurement_View_Bool_Exp>;
};

export type Sensorflow_PositionsNodeMeasurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurement_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurement_View_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurement_View_Bool_Exp>;
};

export type Sensorflow_PositionsNodeSlotsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
};

export type Sensorflow_PositionsNodeSlots_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
};

export type Sensorflow_PositionsNode_MeasurementsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurements_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurements_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
};

export type Sensorflow_PositionsNode_Measurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurements_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurements_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
};

export type Sensorflow_PositionsPositionConfigurationArgs = {
  distinct_on?: Maybe<Array<PositionConfigurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurations_Order_By>>;
  where?: Maybe<PositionConfigurations_Bool_Exp>;
};

export type Sensorflow_PositionsPositionConfiguration_AggregateArgs = {
  distinct_on?: Maybe<Array<PositionConfigurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurations_Order_By>>;
  where?: Maybe<PositionConfigurations_Bool_Exp>;
};

export type Sensorflow_PositionsPositionConfigurationsForActivityLogArgs = {
  distinct_on?: Maybe<Array<PositionConfigurationsForActivityLog_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurationsForActivityLog_Order_By>>;
  where?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
};

export type Sensorflow_PositionsPositionConfigurationsForActivityLog_AggregateArgs = {
  distinct_on?: Maybe<Array<PositionConfigurationsForActivityLog_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurationsForActivityLog_Order_By>>;
  where?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
};

export type Sensorflow_PositionsPrecoolCommandsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Precool_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Precool_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
};

export type Sensorflow_PositionsPrecoolCommands_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Precool_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Precool_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
};

export type Sensorflow_PositionsReservationArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservations_Order_By>>;
  where?: Maybe<Sensorflow_Reservations_Bool_Exp>;
};

export type Sensorflow_PositionsReservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservations_Order_By>>;
  where?: Maybe<Sensorflow_Reservations_Bool_Exp>;
};

export type Sensorflow_PositionsRoomsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Positions_Order_By>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Sensorflow_PositionsRooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Positions_Order_By>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Sensorflow_PositionsSearchRoomsArgs = {
  args: SearchRooms_Sensorflow_Positions_Args;
  distinct_on?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Positions_Order_By>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Sensorflow_PositionsSlotMappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Sensorflow_PositionsSlotMappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Sensorflow_Positions_Aggregate = {
  __typename?: "sensorflow_positions_aggregate";
  aggregate?: Maybe<Sensorflow_Positions_Aggregate_Fields>;
  nodes: Array<Sensorflow_Positions>;
};

export type Sensorflow_Positions_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Positions_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Positions_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Positions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Positions_Aggregate_Fields = {
  __typename?: "sensorflow_positions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Positions_Max_Fields>;
  min?: Maybe<Sensorflow_Positions_Min_Fields>;
};

export type Sensorflow_Positions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Positions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Positions_Max_Order_By>;
  min?: Maybe<Sensorflow_Positions_Min_Order_By>;
};

export type Sensorflow_Positions_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Positions_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Positions_On_Conflict>;
};

export type Sensorflow_Positions_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Positions_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Positions_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Positions_Bool_Exp>>;
  acSettingCommands?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
  acSettingCommands_aggregate?: Maybe<Sensorflow_Ac_Setting_Commands_Aggregate_Bool_Exp>;
  clickup_task_id?: Maybe<String_Comparison_Exp>;
  compressor?: Maybe<Sensorflow_Compressors_Bool_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  eventsStream?: Maybe<Sensorflow_Events_Stream_Bool_Exp>;
  eventsStream_aggregate?: Maybe<Sensorflow_Events_Stream_Aggregate_Bool_Exp>;
  gatewayMapping?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
  gatewayMapping_aggregate?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Aggregate_Bool_Exp>;
  gateways?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  gateways_aggregate?: Maybe<Sensorflow_Gateways_Aggregate_Bool_Exp>;
  infrastructure?: Maybe<Sensorflow_V_Infrastructures_Bool_Exp>;
  keyCategoryPositionMapping?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  mappingCoordinates?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
  mappingCoordinates_aggregate?: Maybe<Sensorflow_Mapping_Coordinates_Aggregate_Bool_Exp>;
  nodeJoinStatuses?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
  nodeJoinStatuses_aggregate?: Maybe<Sensorflow_Node_Join_Status_Aggregate_Bool_Exp>;
  nodeMeasurements?: Maybe<Sensorflow_Node_Measurement_View_Bool_Exp>;
  nodeMeasurements_aggregate?: Maybe<Sensorflow_Node_Measurement_View_Aggregate_Bool_Exp>;
  nodeSlots?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
  nodeSlots_aggregate?: Maybe<Sensorflow_Node_Slots_Aggregate_Bool_Exp>;
  node_measurements?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
  node_measurements_aggregate?: Maybe<Sensorflow_Node_Measurements_Aggregate_Bool_Exp>;
  parentPosition?: Maybe<Sensorflow_Positions_Bool_Exp>;
  parentPositionId?: Maybe<Uuid_Comparison_Exp>;
  positionConfiguration?: Maybe<PositionConfigurations_Bool_Exp>;
  positionConfiguration_aggregate?: Maybe<PositionConfigurations_Aggregate_Bool_Exp>;
  positionConfigurationsForActivityLog?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
  positionConfigurationsForActivityLog_aggregate?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Bool_Exp>;
  positionFunction?: Maybe<String_Comparison_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  positionName?: Maybe<String_Comparison_Exp>;
  positionType?: Maybe<String_Comparison_Exp>;
  precoolCommands?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
  precoolCommands_aggregate?: Maybe<Sensorflow_Precool_Commands_Aggregate_Bool_Exp>;
  reservation?: Maybe<Sensorflow_Reservations_Bool_Exp>;
  reservation_aggregate?: Maybe<Sensorflow_Reservations_Aggregate_Bool_Exp>;
  rooms?: Maybe<Sensorflow_Positions_Bool_Exp>;
  rooms_aggregate?: Maybe<Sensorflow_Positions_Aggregate_Bool_Exp>;
  slotMappings?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
  slotMappings_aggregate?: Maybe<Sensorflow_Node_To_Slot_Mapping_Aggregate_Bool_Exp>;
};

export enum Sensorflow_Positions_Constraint {
  PositionsPkey = "positions_pkey",
  PositionsUniqueIndex = "positions_unique_index",
}

export type Sensorflow_Positions_Insert_Input = {
  acSettingCommands?: Maybe<Sensorflow_Ac_Setting_Commands_Arr_Rel_Insert_Input>;
  clickup_task_id?: Maybe<Scalars["String"]>;
  compressor?: Maybe<Sensorflow_Compressors_Obj_Rel_Insert_Input>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  eventsStream?: Maybe<Sensorflow_Events_Stream_Arr_Rel_Insert_Input>;
  gatewayMapping?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Arr_Rel_Insert_Input>;
  gateways?: Maybe<Sensorflow_Gateways_Arr_Rel_Insert_Input>;
  infrastructure?: Maybe<Sensorflow_V_Infrastructures_Obj_Rel_Insert_Input>;
  keyCategoryPositionMapping?: Maybe<Sensorflow_Key_Category_To_Key_Position_Obj_Rel_Insert_Input>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars["uuid"]>;
  mappingCoordinates?: Maybe<Sensorflow_Mapping_Coordinates_Arr_Rel_Insert_Input>;
  nodeJoinStatuses?: Maybe<Sensorflow_Node_Join_Status_Arr_Rel_Insert_Input>;
  nodeMeasurements?: Maybe<Sensorflow_Node_Measurement_View_Arr_Rel_Insert_Input>;
  nodeSlots?: Maybe<Sensorflow_Node_Slots_Arr_Rel_Insert_Input>;
  node_measurements?: Maybe<Sensorflow_Node_Measurements_Arr_Rel_Insert_Input>;
  parentPosition?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  positionConfiguration?: Maybe<PositionConfigurations_Arr_Rel_Insert_Input>;
  positionConfigurationsForActivityLog?: Maybe<PositionConfigurationsForActivityLog_Arr_Rel_Insert_Input>;
  positionFunction?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
  precoolCommands?: Maybe<Sensorflow_Precool_Commands_Arr_Rel_Insert_Input>;
  reservation?: Maybe<Sensorflow_Reservations_Arr_Rel_Insert_Input>;
  rooms?: Maybe<Sensorflow_Positions_Arr_Rel_Insert_Input>;
  slotMappings?: Maybe<Sensorflow_Node_To_Slot_Mapping_Arr_Rel_Insert_Input>;
};

export type Sensorflow_Positions_Max_Fields = {
  __typename?: "sensorflow_positions_max_fields";
  clickup_task_id?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Positions_Max_Order_By = {
  clickup_task_id?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  parentPositionId?: Maybe<Order_By>;
  positionFunction?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  positionName?: Maybe<Order_By>;
  positionType?: Maybe<Order_By>;
};

export type Sensorflow_Positions_Min_Fields = {
  __typename?: "sensorflow_positions_min_fields";
  clickup_task_id?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Positions_Min_Order_By = {
  clickup_task_id?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  parentPositionId?: Maybe<Order_By>;
  positionFunction?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  positionName?: Maybe<Order_By>;
  positionType?: Maybe<Order_By>;
};

export type Sensorflow_Positions_Mutation_Response = {
  __typename?: "sensorflow_positions_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Positions>;
};

export type Sensorflow_Positions_Obj_Rel_Insert_Input = {
  data: Sensorflow_Positions_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Positions_On_Conflict>;
};

export type Sensorflow_Positions_On_Conflict = {
  constraint: Sensorflow_Positions_Constraint;
  update_columns: Array<Sensorflow_Positions_Update_Column>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Sensorflow_Positions_Order_By = {
  acSettingCommands_aggregate?: Maybe<Sensorflow_Ac_Setting_Commands_Aggregate_Order_By>;
  clickup_task_id?: Maybe<Order_By>;
  compressor?: Maybe<Sensorflow_Compressors_Order_By>;
  deletedAt?: Maybe<Order_By>;
  eventsStream_aggregate?: Maybe<Sensorflow_Events_Stream_Aggregate_Order_By>;
  gatewayMapping_aggregate?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Aggregate_Order_By>;
  gateways_aggregate?: Maybe<Sensorflow_Gateways_Aggregate_Order_By>;
  infrastructure?: Maybe<Sensorflow_V_Infrastructures_Order_By>;
  keyCategoryPositionMapping?: Maybe<Sensorflow_Key_Category_To_Key_Position_Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  mappingCoordinates_aggregate?: Maybe<Sensorflow_Mapping_Coordinates_Aggregate_Order_By>;
  nodeJoinStatuses_aggregate?: Maybe<Sensorflow_Node_Join_Status_Aggregate_Order_By>;
  nodeMeasurements_aggregate?: Maybe<Sensorflow_Node_Measurement_View_Aggregate_Order_By>;
  nodeSlots_aggregate?: Maybe<Sensorflow_Node_Slots_Aggregate_Order_By>;
  node_measurements_aggregate?: Maybe<Sensorflow_Node_Measurements_Aggregate_Order_By>;
  parentPosition?: Maybe<Sensorflow_Positions_Order_By>;
  parentPositionId?: Maybe<Order_By>;
  positionConfiguration_aggregate?: Maybe<PositionConfigurations_Aggregate_Order_By>;
  positionConfigurationsForActivityLog_aggregate?: Maybe<PositionConfigurationsForActivityLog_Aggregate_Order_By>;
  positionFunction?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  positionName?: Maybe<Order_By>;
  positionType?: Maybe<Order_By>;
  precoolCommands_aggregate?: Maybe<Sensorflow_Precool_Commands_Aggregate_Order_By>;
  reservation_aggregate?: Maybe<Sensorflow_Reservations_Aggregate_Order_By>;
  rooms_aggregate?: Maybe<Sensorflow_Positions_Aggregate_Order_By>;
  slotMappings_aggregate?: Maybe<Sensorflow_Node_To_Slot_Mapping_Aggregate_Order_By>;
};

export type Sensorflow_Positions_Pk_Columns_Input = {
  positionId: Scalars["uuid"];
};

export enum Sensorflow_Positions_Select_Column {
  ClickupTaskId = "clickup_task_id",
  DeletedAt = "deletedAt",
  LocationId = "locationId",
  ParentPositionId = "parentPositionId",
  PositionFunction = "positionFunction",
  PositionId = "positionId",
  PositionName = "positionName",
  PositionType = "positionType",
}

export type Sensorflow_Positions_Set_Input = {
  clickup_task_id?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Positions_Stream_Cursor_Input = {
  initial_value: Sensorflow_Positions_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Positions_Stream_Cursor_Value_Input = {
  clickup_task_id?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  parentPositionId?: Maybe<Scalars["uuid"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Positions_Update_Column {
  ClickupTaskId = "clickup_task_id",
  DeletedAt = "deletedAt",
  LocationId = "locationId",
  ParentPositionId = "parentPositionId",
  PositionFunction = "positionFunction",
  PositionId = "positionId",
  PositionName = "positionName",
  PositionType = "positionType",
}

export type Sensorflow_Positions_Updates = {
  _set?: Maybe<Sensorflow_Positions_Set_Input>;
  where: Sensorflow_Positions_Bool_Exp;
};

export type Sensorflow_Precool_Commands = {
  __typename?: "sensorflow_precool_commands";
  createdBy?: Maybe<Scalars["String"]>;
  creationDate: Scalars["timestamptz"];
  failMessage?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  mode?: Maybe<Scalars["Int"]>;
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
  schedule_id?: Maybe<Scalars["String"]>;
  source: Scalars["String"];
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status: Scalars["String"];
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes: Scalars["Int"];
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Sensorflow_Users>;
};

export type Sensorflow_Precool_Commands_Aggregate = {
  __typename?: "sensorflow_precool_commands_aggregate";
  aggregate?: Maybe<Sensorflow_Precool_Commands_Aggregate_Fields>;
  nodes: Array<Sensorflow_Precool_Commands>;
};

export type Sensorflow_Precool_Commands_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Precool_Commands_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Precool_Commands_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Precool_Commands_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Precool_Commands_Aggregate_Fields = {
  __typename?: "sensorflow_precool_commands_aggregate_fields";
  avg?: Maybe<Sensorflow_Precool_Commands_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Precool_Commands_Max_Fields>;
  min?: Maybe<Sensorflow_Precool_Commands_Min_Fields>;
  stddev?: Maybe<Sensorflow_Precool_Commands_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Precool_Commands_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Precool_Commands_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Precool_Commands_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Precool_Commands_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Precool_Commands_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Precool_Commands_Variance_Fields>;
};

export type Sensorflow_Precool_Commands_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Precool_Commands_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Precool_Commands_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Precool_Commands_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Precool_Commands_Max_Order_By>;
  min?: Maybe<Sensorflow_Precool_Commands_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Precool_Commands_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Precool_Commands_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Precool_Commands_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Precool_Commands_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Precool_Commands_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Precool_Commands_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Precool_Commands_Variance_Order_By>;
};

export type Sensorflow_Precool_Commands_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Precool_Commands_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Precool_Commands_On_Conflict>;
};

export type Sensorflow_Precool_Commands_Avg_Fields = {
  __typename?: "sensorflow_precool_commands_avg_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Commands_Avg_Order_By = {
  mode?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
};

export type Sensorflow_Precool_Commands_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Precool_Commands_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Precool_Commands_Bool_Exp>>;
  createdBy?: Maybe<String_Comparison_Exp>;
  creationDate?: Maybe<Timestamptz_Comparison_Exp>;
  failMessage?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mode?: Maybe<Int_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  schedule_id?: Maybe<String_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  startedAt?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  temperature?: Maybe<Int_Comparison_Exp>;
  totalMinutes?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Sensorflow_Users_Bool_Exp>;
};

export enum Sensorflow_Precool_Commands_Constraint {
  PrecoolCommandsPkey = "precool_commands_pkey",
}

export type Sensorflow_Precool_Commands_Inc_Input = {
  mode?: Maybe<Scalars["Int"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Precool_Commands_Insert_Input = {
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  failMessage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  mode?: Maybe<Scalars["Int"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  schedule_id?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Sensorflow_Users_Obj_Rel_Insert_Input>;
};

export type Sensorflow_Precool_Commands_Max_Fields = {
  __typename?: "sensorflow_precool_commands_max_fields";
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  failMessage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  mode?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  schedule_id?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Precool_Commands_Max_Order_By = {
  createdBy?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  failMessage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mode?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  schedule_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

export type Sensorflow_Precool_Commands_Min_Fields = {
  __typename?: "sensorflow_precool_commands_min_fields";
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  failMessage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  mode?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  schedule_id?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Precool_Commands_Min_Order_By = {
  createdBy?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  failMessage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mode?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  schedule_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

export type Sensorflow_Precool_Commands_Mutation_Response = {
  __typename?: "sensorflow_precool_commands_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Precool_Commands>;
};

export type Sensorflow_Precool_Commands_On_Conflict = {
  constraint: Sensorflow_Precool_Commands_Constraint;
  update_columns: Array<Sensorflow_Precool_Commands_Update_Column>;
  where?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
};

export type Sensorflow_Precool_Commands_Order_By = {
  createdBy?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  failMessage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mode?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  schedule_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  user?: Maybe<Sensorflow_Users_Order_By>;
};

export type Sensorflow_Precool_Commands_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Precool_Commands_Select_Column {
  CreatedBy = "createdBy",
  CreationDate = "creationDate",
  FailMessage = "failMessage",
  Id = "id",
  Mode = "mode",
  PositionId = "positionId",
  ScheduleId = "schedule_id",
  Source = "source",
  StartedAt = "startedAt",
  Status = "status",
  Temperature = "temperature",
  TotalMinutes = "totalMinutes",
  UpdatedAt = "updatedAt",
}

export type Sensorflow_Precool_Commands_Set_Input = {
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  failMessage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  mode?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  schedule_id?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Precool_Commands_Stddev_Fields = {
  __typename?: "sensorflow_precool_commands_stddev_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Commands_Stddev_Order_By = {
  mode?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
};

export type Sensorflow_Precool_Commands_Stddev_Pop_Fields = {
  __typename?: "sensorflow_precool_commands_stddev_pop_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Commands_Stddev_Pop_Order_By = {
  mode?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
};

export type Sensorflow_Precool_Commands_Stddev_Samp_Fields = {
  __typename?: "sensorflow_precool_commands_stddev_samp_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Commands_Stddev_Samp_Order_By = {
  mode?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
};

export type Sensorflow_Precool_Commands_Stream_Cursor_Input = {
  initial_value: Sensorflow_Precool_Commands_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Precool_Commands_Stream_Cursor_Value_Input = {
  createdBy?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  failMessage?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  mode?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  schedule_id?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Precool_Commands_Sum_Fields = {
  __typename?: "sensorflow_precool_commands_sum_fields";
  mode?: Maybe<Scalars["Int"]>;
  temperature?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Precool_Commands_Sum_Order_By = {
  mode?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
};

export enum Sensorflow_Precool_Commands_Update_Column {
  CreatedBy = "createdBy",
  CreationDate = "creationDate",
  FailMessage = "failMessage",
  Id = "id",
  Mode = "mode",
  PositionId = "positionId",
  ScheduleId = "schedule_id",
  Source = "source",
  StartedAt = "startedAt",
  Status = "status",
  Temperature = "temperature",
  TotalMinutes = "totalMinutes",
  UpdatedAt = "updatedAt",
}

export type Sensorflow_Precool_Commands_Updates = {
  _inc?: Maybe<Sensorflow_Precool_Commands_Inc_Input>;
  _set?: Maybe<Sensorflow_Precool_Commands_Set_Input>;
  where: Sensorflow_Precool_Commands_Bool_Exp;
};

export type Sensorflow_Precool_Commands_Var_Pop_Fields = {
  __typename?: "sensorflow_precool_commands_var_pop_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Commands_Var_Pop_Order_By = {
  mode?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
};

export type Sensorflow_Precool_Commands_Var_Samp_Fields = {
  __typename?: "sensorflow_precool_commands_var_samp_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Commands_Var_Samp_Order_By = {
  mode?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
};

export type Sensorflow_Precool_Commands_Variance_Fields = {
  __typename?: "sensorflow_precool_commands_variance_fields";
  mode?: Maybe<Scalars["Float"]>;
  temperature?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Commands_Variance_Order_By = {
  mode?: Maybe<Order_By>;
  temperature?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
};

export type Sensorflow_Precool_Schedules = {
  __typename?: "sensorflow_precool_schedules";
  endedAt?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["bigint"];
  reservation: Sensorflow_Reservations;
  reservationId: Scalars["bigint"];
  startedAt?: Maybe<Scalars["timestamptz"]>;
  time?: Maybe<Scalars["smallint"]>;
  type?: Maybe<Scalars["String"]>;
  userId: Scalars["String"];
  weekDay?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Precool_Schedules_Aggregate = {
  __typename?: "sensorflow_precool_schedules_aggregate";
  aggregate?: Maybe<Sensorflow_Precool_Schedules_Aggregate_Fields>;
  nodes: Array<Sensorflow_Precool_Schedules>;
};

export type Sensorflow_Precool_Schedules_Aggregate_Fields = {
  __typename?: "sensorflow_precool_schedules_aggregate_fields";
  avg?: Maybe<Sensorflow_Precool_Schedules_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Precool_Schedules_Max_Fields>;
  min?: Maybe<Sensorflow_Precool_Schedules_Min_Fields>;
  stddev?: Maybe<Sensorflow_Precool_Schedules_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Precool_Schedules_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Precool_Schedules_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Precool_Schedules_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Precool_Schedules_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Precool_Schedules_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Precool_Schedules_Variance_Fields>;
};

export type Sensorflow_Precool_Schedules_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Precool_Schedules_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Precool_Schedules_Avg_Fields = {
  __typename?: "sensorflow_precool_schedules_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
  time?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Schedules_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Precool_Schedules_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Precool_Schedules_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Precool_Schedules_Bool_Exp>>;
  endedAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  reservation?: Maybe<Sensorflow_Reservations_Bool_Exp>;
  reservationId?: Maybe<Bigint_Comparison_Exp>;
  startedAt?: Maybe<Timestamptz_Comparison_Exp>;
  time?: Maybe<Smallint_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
  weekDay?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Precool_Schedules_Constraint {
  PrecoolSchedulesPk = "precool_schedules_pk",
}

export type Sensorflow_Precool_Schedules_Inc_Input = {
  id?: Maybe<Scalars["bigint"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  time?: Maybe<Scalars["smallint"]>;
};

export type Sensorflow_Precool_Schedules_Insert_Input = {
  endedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  reservation?: Maybe<Sensorflow_Reservations_Obj_Rel_Insert_Input>;
  reservationId?: Maybe<Scalars["bigint"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  time?: Maybe<Scalars["smallint"]>;
  type?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  weekDay?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Precool_Schedules_Max_Fields = {
  __typename?: "sensorflow_precool_schedules_max_fields";
  endedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  time?: Maybe<Scalars["smallint"]>;
  type?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  weekDay?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Precool_Schedules_Min_Fields = {
  __typename?: "sensorflow_precool_schedules_min_fields";
  endedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  time?: Maybe<Scalars["smallint"]>;
  type?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  weekDay?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Precool_Schedules_Mutation_Response = {
  __typename?: "sensorflow_precool_schedules_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Precool_Schedules>;
};

export type Sensorflow_Precool_Schedules_On_Conflict = {
  constraint: Sensorflow_Precool_Schedules_Constraint;
  update_columns: Array<Sensorflow_Precool_Schedules_Update_Column>;
  where?: Maybe<Sensorflow_Precool_Schedules_Bool_Exp>;
};

export type Sensorflow_Precool_Schedules_Order_By = {
  endedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reservation?: Maybe<Sensorflow_Reservations_Order_By>;
  reservationId?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
  weekDay?: Maybe<Order_By>;
};

export type Sensorflow_Precool_Schedules_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

export enum Sensorflow_Precool_Schedules_Select_Column {
  EndedAt = "endedAt",
  Id = "id",
  ReservationId = "reservationId",
  StartedAt = "startedAt",
  Time = "time",
  Type = "type",
  UserId = "userId",
  WeekDay = "weekDay",
}

export type Sensorflow_Precool_Schedules_Set_Input = {
  endedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  time?: Maybe<Scalars["smallint"]>;
  type?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  weekDay?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Precool_Schedules_Stddev_Fields = {
  __typename?: "sensorflow_precool_schedules_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
  time?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Schedules_Stddev_Pop_Fields = {
  __typename?: "sensorflow_precool_schedules_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
  time?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Schedules_Stddev_Samp_Fields = {
  __typename?: "sensorflow_precool_schedules_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
  time?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Schedules_Stream_Cursor_Input = {
  initial_value: Sensorflow_Precool_Schedules_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Precool_Schedules_Stream_Cursor_Value_Input = {
  endedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  time?: Maybe<Scalars["smallint"]>;
  type?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  weekDay?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Precool_Schedules_Sum_Fields = {
  __typename?: "sensorflow_precool_schedules_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  time?: Maybe<Scalars["smallint"]>;
};

export enum Sensorflow_Precool_Schedules_Update_Column {
  EndedAt = "endedAt",
  Id = "id",
  ReservationId = "reservationId",
  StartedAt = "startedAt",
  Time = "time",
  Type = "type",
  UserId = "userId",
  WeekDay = "weekDay",
}

export type Sensorflow_Precool_Schedules_Updates = {
  _inc?: Maybe<Sensorflow_Precool_Schedules_Inc_Input>;
  _set?: Maybe<Sensorflow_Precool_Schedules_Set_Input>;
  where: Sensorflow_Precool_Schedules_Bool_Exp;
};

export type Sensorflow_Precool_Schedules_Var_Pop_Fields = {
  __typename?: "sensorflow_precool_schedules_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
  time?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Schedules_Var_Samp_Fields = {
  __typename?: "sensorflow_precool_schedules_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
  time?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Precool_Schedules_Variance_Fields = {
  __typename?: "sensorflow_precool_schedules_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  reservationId?: Maybe<Scalars["Float"]>;
  time?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Properties_Information = {
  __typename?: "sensorflow_properties_information";
  backOfficesFloorPercentage?: Maybe<Scalars["numeric"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  createdAt: Scalars["date"];
  electricityBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Int"]>;
  energyInitiatives?: Maybe<Scalars["String"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Int"]>;
  hasEnergyInitiatives: Scalars["Boolean"];
  hasOtherHVACSolutions: Scalars["Boolean"];
  hospitalityType?: Maybe<Scalars["String"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["numeric"]>;
  hvacSolutions?: Maybe<Scalars["String"]>;
  hvacSystemType: Scalars["String"];
  locationId: Scalars["uuid"];
  noOf1Bedrooms?: Maybe<Scalars["Int"]>;
  noOf2Bedrooms?: Maybe<Scalars["Int"]>;
  noOf3Bedrooms?: Maybe<Scalars["Int"]>;
  noOfStudios?: Maybe<Scalars["Int"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["numeric"]>;
  roomsFloorPercentage?: Maybe<Scalars["numeric"]>;
  spasFloorPercentage?: Maybe<Scalars["numeric"]>;
  totalGuestRooms?: Maybe<Scalars["Int"]>;
  totalSquareFootage?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["date"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Int"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Properties_Information_Aggregate = {
  __typename?: "sensorflow_properties_information_aggregate";
  aggregate?: Maybe<Sensorflow_Properties_Information_Aggregate_Fields>;
  nodes: Array<Sensorflow_Properties_Information>;
};

export type Sensorflow_Properties_Information_Aggregate_Fields = {
  __typename?: "sensorflow_properties_information_aggregate_fields";
  avg?: Maybe<Sensorflow_Properties_Information_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Properties_Information_Max_Fields>;
  min?: Maybe<Sensorflow_Properties_Information_Min_Fields>;
  stddev?: Maybe<Sensorflow_Properties_Information_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Properties_Information_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Properties_Information_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Properties_Information_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Properties_Information_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Properties_Information_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Properties_Information_Variance_Fields>;
};

export type Sensorflow_Properties_Information_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Properties_Information_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Properties_Information_Avg_Fields = {
  __typename?: "sensorflow_properties_information_avg_fields";
  backOfficesFloorPercentage?: Maybe<Scalars["Float"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Float"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Float"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["Float"]>;
  noOf1Bedrooms?: Maybe<Scalars["Float"]>;
  noOf2Bedrooms?: Maybe<Scalars["Float"]>;
  noOf3Bedrooms?: Maybe<Scalars["Float"]>;
  noOfStudios?: Maybe<Scalars["Float"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["Float"]>;
  roomsFloorPercentage?: Maybe<Scalars["Float"]>;
  spasFloorPercentage?: Maybe<Scalars["Float"]>;
  totalGuestRooms?: Maybe<Scalars["Float"]>;
  totalSquareFootage?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Float"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Properties_Information_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Properties_Information_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Properties_Information_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Properties_Information_Bool_Exp>>;
  backOfficesFloorPercentage?: Maybe<Numeric_Comparison_Exp>;
  commonAreasFloorPercentage?: Maybe<Numeric_Comparison_Exp>;
  createdAt?: Maybe<Date_Comparison_Exp>;
  electricityBillingPeriodEnd?: Maybe<Int_Comparison_Exp>;
  electricityBillingPeriodStart?: Maybe<Int_Comparison_Exp>;
  energyInitiatives?: Maybe<String_Comparison_Exp>;
  gasBillingPeriodEnd?: Maybe<Int_Comparison_Exp>;
  gasBillingPeriodStart?: Maybe<Int_Comparison_Exp>;
  hasEnergyInitiatives?: Maybe<Boolean_Comparison_Exp>;
  hasOtherHVACSolutions?: Maybe<Boolean_Comparison_Exp>;
  hospitalityType?: Maybe<String_Comparison_Exp>;
  hvacConsumptionPercentage?: Maybe<Numeric_Comparison_Exp>;
  hvacSolutions?: Maybe<String_Comparison_Exp>;
  hvacSystemType?: Maybe<String_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  noOf1Bedrooms?: Maybe<Int_Comparison_Exp>;
  noOf2Bedrooms?: Maybe<Int_Comparison_Exp>;
  noOf3Bedrooms?: Maybe<Int_Comparison_Exp>;
  noOfStudios?: Maybe<Int_Comparison_Exp>;
  otherAreasFloorPercentage?: Maybe<Numeric_Comparison_Exp>;
  restaurantsFloorPercentage?: Maybe<Numeric_Comparison_Exp>;
  roomsFloorPercentage?: Maybe<Numeric_Comparison_Exp>;
  spasFloorPercentage?: Maybe<Numeric_Comparison_Exp>;
  totalGuestRooms?: Maybe<Int_Comparison_Exp>;
  totalSquareFootage?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Date_Comparison_Exp>;
  wasteBillingPeriodEnd?: Maybe<Int_Comparison_Exp>;
  wasteBillingPeriodStart?: Maybe<Int_Comparison_Exp>;
  waterBillingPeriodEnd?: Maybe<Int_Comparison_Exp>;
  waterBillingPeriodStart?: Maybe<Int_Comparison_Exp>;
};

export enum Sensorflow_Properties_Information_Constraint {
  PropertiesInformationPkey = "properties_information_pkey",
}

export type Sensorflow_Properties_Information_Inc_Input = {
  backOfficesFloorPercentage?: Maybe<Scalars["numeric"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Int"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Int"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["numeric"]>;
  noOf1Bedrooms?: Maybe<Scalars["Int"]>;
  noOf2Bedrooms?: Maybe<Scalars["Int"]>;
  noOf3Bedrooms?: Maybe<Scalars["Int"]>;
  noOfStudios?: Maybe<Scalars["Int"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["numeric"]>;
  roomsFloorPercentage?: Maybe<Scalars["numeric"]>;
  spasFloorPercentage?: Maybe<Scalars["numeric"]>;
  totalGuestRooms?: Maybe<Scalars["Int"]>;
  totalSquareFootage?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Int"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Properties_Information_Insert_Input = {
  backOfficesFloorPercentage?: Maybe<Scalars["numeric"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  createdAt?: Maybe<Scalars["date"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Int"]>;
  energyInitiatives?: Maybe<Scalars["String"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Int"]>;
  hasEnergyInitiatives?: Maybe<Scalars["Boolean"]>;
  hasOtherHVACSolutions?: Maybe<Scalars["Boolean"]>;
  hospitalityType?: Maybe<Scalars["String"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["numeric"]>;
  hvacSolutions?: Maybe<Scalars["String"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  noOf1Bedrooms?: Maybe<Scalars["Int"]>;
  noOf2Bedrooms?: Maybe<Scalars["Int"]>;
  noOf3Bedrooms?: Maybe<Scalars["Int"]>;
  noOfStudios?: Maybe<Scalars["Int"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["numeric"]>;
  roomsFloorPercentage?: Maybe<Scalars["numeric"]>;
  spasFloorPercentage?: Maybe<Scalars["numeric"]>;
  totalGuestRooms?: Maybe<Scalars["Int"]>;
  totalSquareFootage?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["date"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Int"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Properties_Information_Max_Fields = {
  __typename?: "sensorflow_properties_information_max_fields";
  backOfficesFloorPercentage?: Maybe<Scalars["numeric"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  createdAt?: Maybe<Scalars["date"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Int"]>;
  energyInitiatives?: Maybe<Scalars["String"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Int"]>;
  hospitalityType?: Maybe<Scalars["String"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["numeric"]>;
  hvacSolutions?: Maybe<Scalars["String"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  noOf1Bedrooms?: Maybe<Scalars["Int"]>;
  noOf2Bedrooms?: Maybe<Scalars["Int"]>;
  noOf3Bedrooms?: Maybe<Scalars["Int"]>;
  noOfStudios?: Maybe<Scalars["Int"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["numeric"]>;
  roomsFloorPercentage?: Maybe<Scalars["numeric"]>;
  spasFloorPercentage?: Maybe<Scalars["numeric"]>;
  totalGuestRooms?: Maybe<Scalars["Int"]>;
  totalSquareFootage?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["date"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Int"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Properties_Information_Min_Fields = {
  __typename?: "sensorflow_properties_information_min_fields";
  backOfficesFloorPercentage?: Maybe<Scalars["numeric"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  createdAt?: Maybe<Scalars["date"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Int"]>;
  energyInitiatives?: Maybe<Scalars["String"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Int"]>;
  hospitalityType?: Maybe<Scalars["String"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["numeric"]>;
  hvacSolutions?: Maybe<Scalars["String"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  noOf1Bedrooms?: Maybe<Scalars["Int"]>;
  noOf2Bedrooms?: Maybe<Scalars["Int"]>;
  noOf3Bedrooms?: Maybe<Scalars["Int"]>;
  noOfStudios?: Maybe<Scalars["Int"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["numeric"]>;
  roomsFloorPercentage?: Maybe<Scalars["numeric"]>;
  spasFloorPercentage?: Maybe<Scalars["numeric"]>;
  totalGuestRooms?: Maybe<Scalars["Int"]>;
  totalSquareFootage?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["date"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Int"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Properties_Information_Mutation_Response = {
  __typename?: "sensorflow_properties_information_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Properties_Information>;
};

export type Sensorflow_Properties_Information_On_Conflict = {
  constraint: Sensorflow_Properties_Information_Constraint;
  update_columns: Array<Sensorflow_Properties_Information_Update_Column>;
  where?: Maybe<Sensorflow_Properties_Information_Bool_Exp>;
};

export type Sensorflow_Properties_Information_Order_By = {
  backOfficesFloorPercentage?: Maybe<Order_By>;
  commonAreasFloorPercentage?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  electricityBillingPeriodEnd?: Maybe<Order_By>;
  electricityBillingPeriodStart?: Maybe<Order_By>;
  energyInitiatives?: Maybe<Order_By>;
  gasBillingPeriodEnd?: Maybe<Order_By>;
  gasBillingPeriodStart?: Maybe<Order_By>;
  hasEnergyInitiatives?: Maybe<Order_By>;
  hasOtherHVACSolutions?: Maybe<Order_By>;
  hospitalityType?: Maybe<Order_By>;
  hvacConsumptionPercentage?: Maybe<Order_By>;
  hvacSolutions?: Maybe<Order_By>;
  hvacSystemType?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  noOf1Bedrooms?: Maybe<Order_By>;
  noOf2Bedrooms?: Maybe<Order_By>;
  noOf3Bedrooms?: Maybe<Order_By>;
  noOfStudios?: Maybe<Order_By>;
  otherAreasFloorPercentage?: Maybe<Order_By>;
  restaurantsFloorPercentage?: Maybe<Order_By>;
  roomsFloorPercentage?: Maybe<Order_By>;
  spasFloorPercentage?: Maybe<Order_By>;
  totalGuestRooms?: Maybe<Order_By>;
  totalSquareFootage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wasteBillingPeriodEnd?: Maybe<Order_By>;
  wasteBillingPeriodStart?: Maybe<Order_By>;
  waterBillingPeriodEnd?: Maybe<Order_By>;
  waterBillingPeriodStart?: Maybe<Order_By>;
};

export type Sensorflow_Properties_Information_Pk_Columns_Input = {
  locationId: Scalars["uuid"];
};

export enum Sensorflow_Properties_Information_Select_Column {
  BackOfficesFloorPercentage = "backOfficesFloorPercentage",
  CommonAreasFloorPercentage = "commonAreasFloorPercentage",
  CreatedAt = "createdAt",
  ElectricityBillingPeriodEnd = "electricityBillingPeriodEnd",
  ElectricityBillingPeriodStart = "electricityBillingPeriodStart",
  EnergyInitiatives = "energyInitiatives",
  GasBillingPeriodEnd = "gasBillingPeriodEnd",
  GasBillingPeriodStart = "gasBillingPeriodStart",
  HasEnergyInitiatives = "hasEnergyInitiatives",
  HasOtherHvacSolutions = "hasOtherHVACSolutions",
  HospitalityType = "hospitalityType",
  HvacConsumptionPercentage = "hvacConsumptionPercentage",
  HvacSolutions = "hvacSolutions",
  HvacSystemType = "hvacSystemType",
  LocationId = "locationId",
  NoOf1Bedrooms = "noOf1Bedrooms",
  NoOf2Bedrooms = "noOf2Bedrooms",
  NoOf3Bedrooms = "noOf3Bedrooms",
  NoOfStudios = "noOfStudios",
  OtherAreasFloorPercentage = "otherAreasFloorPercentage",
  RestaurantsFloorPercentage = "restaurantsFloorPercentage",
  RoomsFloorPercentage = "roomsFloorPercentage",
  SpasFloorPercentage = "spasFloorPercentage",
  TotalGuestRooms = "totalGuestRooms",
  TotalSquareFootage = "totalSquareFootage",
  UpdatedAt = "updated_at",
  WasteBillingPeriodEnd = "wasteBillingPeriodEnd",
  WasteBillingPeriodStart = "wasteBillingPeriodStart",
  WaterBillingPeriodEnd = "waterBillingPeriodEnd",
  WaterBillingPeriodStart = "waterBillingPeriodStart",
}

export type Sensorflow_Properties_Information_Set_Input = {
  backOfficesFloorPercentage?: Maybe<Scalars["numeric"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  createdAt?: Maybe<Scalars["date"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Int"]>;
  energyInitiatives?: Maybe<Scalars["String"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Int"]>;
  hasEnergyInitiatives?: Maybe<Scalars["Boolean"]>;
  hasOtherHVACSolutions?: Maybe<Scalars["Boolean"]>;
  hospitalityType?: Maybe<Scalars["String"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["numeric"]>;
  hvacSolutions?: Maybe<Scalars["String"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  noOf1Bedrooms?: Maybe<Scalars["Int"]>;
  noOf2Bedrooms?: Maybe<Scalars["Int"]>;
  noOf3Bedrooms?: Maybe<Scalars["Int"]>;
  noOfStudios?: Maybe<Scalars["Int"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["numeric"]>;
  roomsFloorPercentage?: Maybe<Scalars["numeric"]>;
  spasFloorPercentage?: Maybe<Scalars["numeric"]>;
  totalGuestRooms?: Maybe<Scalars["Int"]>;
  totalSquareFootage?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["date"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Int"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Properties_Information_Stddev_Fields = {
  __typename?: "sensorflow_properties_information_stddev_fields";
  backOfficesFloorPercentage?: Maybe<Scalars["Float"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Float"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Float"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["Float"]>;
  noOf1Bedrooms?: Maybe<Scalars["Float"]>;
  noOf2Bedrooms?: Maybe<Scalars["Float"]>;
  noOf3Bedrooms?: Maybe<Scalars["Float"]>;
  noOfStudios?: Maybe<Scalars["Float"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["Float"]>;
  roomsFloorPercentage?: Maybe<Scalars["Float"]>;
  spasFloorPercentage?: Maybe<Scalars["Float"]>;
  totalGuestRooms?: Maybe<Scalars["Float"]>;
  totalSquareFootage?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Float"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Properties_Information_Stddev_Pop_Fields = {
  __typename?: "sensorflow_properties_information_stddev_pop_fields";
  backOfficesFloorPercentage?: Maybe<Scalars["Float"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Float"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Float"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["Float"]>;
  noOf1Bedrooms?: Maybe<Scalars["Float"]>;
  noOf2Bedrooms?: Maybe<Scalars["Float"]>;
  noOf3Bedrooms?: Maybe<Scalars["Float"]>;
  noOfStudios?: Maybe<Scalars["Float"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["Float"]>;
  roomsFloorPercentage?: Maybe<Scalars["Float"]>;
  spasFloorPercentage?: Maybe<Scalars["Float"]>;
  totalGuestRooms?: Maybe<Scalars["Float"]>;
  totalSquareFootage?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Float"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Properties_Information_Stddev_Samp_Fields = {
  __typename?: "sensorflow_properties_information_stddev_samp_fields";
  backOfficesFloorPercentage?: Maybe<Scalars["Float"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Float"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Float"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["Float"]>;
  noOf1Bedrooms?: Maybe<Scalars["Float"]>;
  noOf2Bedrooms?: Maybe<Scalars["Float"]>;
  noOf3Bedrooms?: Maybe<Scalars["Float"]>;
  noOfStudios?: Maybe<Scalars["Float"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["Float"]>;
  roomsFloorPercentage?: Maybe<Scalars["Float"]>;
  spasFloorPercentage?: Maybe<Scalars["Float"]>;
  totalGuestRooms?: Maybe<Scalars["Float"]>;
  totalSquareFootage?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Float"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Properties_Information_Stream_Cursor_Input = {
  initial_value: Sensorflow_Properties_Information_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Properties_Information_Stream_Cursor_Value_Input = {
  backOfficesFloorPercentage?: Maybe<Scalars["numeric"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  createdAt?: Maybe<Scalars["date"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Int"]>;
  energyInitiatives?: Maybe<Scalars["String"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Int"]>;
  hasEnergyInitiatives?: Maybe<Scalars["Boolean"]>;
  hasOtherHVACSolutions?: Maybe<Scalars["Boolean"]>;
  hospitalityType?: Maybe<Scalars["String"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["numeric"]>;
  hvacSolutions?: Maybe<Scalars["String"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  noOf1Bedrooms?: Maybe<Scalars["Int"]>;
  noOf2Bedrooms?: Maybe<Scalars["Int"]>;
  noOf3Bedrooms?: Maybe<Scalars["Int"]>;
  noOfStudios?: Maybe<Scalars["Int"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["numeric"]>;
  roomsFloorPercentage?: Maybe<Scalars["numeric"]>;
  spasFloorPercentage?: Maybe<Scalars["numeric"]>;
  totalGuestRooms?: Maybe<Scalars["Int"]>;
  totalSquareFootage?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["date"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Int"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Properties_Information_Sum_Fields = {
  __typename?: "sensorflow_properties_information_sum_fields";
  backOfficesFloorPercentage?: Maybe<Scalars["numeric"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Int"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Int"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["numeric"]>;
  noOf1Bedrooms?: Maybe<Scalars["Int"]>;
  noOf2Bedrooms?: Maybe<Scalars["Int"]>;
  noOf3Bedrooms?: Maybe<Scalars["Int"]>;
  noOfStudios?: Maybe<Scalars["Int"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["numeric"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["numeric"]>;
  roomsFloorPercentage?: Maybe<Scalars["numeric"]>;
  spasFloorPercentage?: Maybe<Scalars["numeric"]>;
  totalGuestRooms?: Maybe<Scalars["Int"]>;
  totalSquareFootage?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Int"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Properties_Information_Update_Column {
  BackOfficesFloorPercentage = "backOfficesFloorPercentage",
  CommonAreasFloorPercentage = "commonAreasFloorPercentage",
  CreatedAt = "createdAt",
  ElectricityBillingPeriodEnd = "electricityBillingPeriodEnd",
  ElectricityBillingPeriodStart = "electricityBillingPeriodStart",
  EnergyInitiatives = "energyInitiatives",
  GasBillingPeriodEnd = "gasBillingPeriodEnd",
  GasBillingPeriodStart = "gasBillingPeriodStart",
  HasEnergyInitiatives = "hasEnergyInitiatives",
  HasOtherHvacSolutions = "hasOtherHVACSolutions",
  HospitalityType = "hospitalityType",
  HvacConsumptionPercentage = "hvacConsumptionPercentage",
  HvacSolutions = "hvacSolutions",
  HvacSystemType = "hvacSystemType",
  LocationId = "locationId",
  NoOf1Bedrooms = "noOf1Bedrooms",
  NoOf2Bedrooms = "noOf2Bedrooms",
  NoOf3Bedrooms = "noOf3Bedrooms",
  NoOfStudios = "noOfStudios",
  OtherAreasFloorPercentage = "otherAreasFloorPercentage",
  RestaurantsFloorPercentage = "restaurantsFloorPercentage",
  RoomsFloorPercentage = "roomsFloorPercentage",
  SpasFloorPercentage = "spasFloorPercentage",
  TotalGuestRooms = "totalGuestRooms",
  TotalSquareFootage = "totalSquareFootage",
  UpdatedAt = "updated_at",
  WasteBillingPeriodEnd = "wasteBillingPeriodEnd",
  WasteBillingPeriodStart = "wasteBillingPeriodStart",
  WaterBillingPeriodEnd = "waterBillingPeriodEnd",
  WaterBillingPeriodStart = "waterBillingPeriodStart",
}

export type Sensorflow_Properties_Information_Updates = {
  _inc?: Maybe<Sensorflow_Properties_Information_Inc_Input>;
  _set?: Maybe<Sensorflow_Properties_Information_Set_Input>;
  where: Sensorflow_Properties_Information_Bool_Exp;
};

export type Sensorflow_Properties_Information_Var_Pop_Fields = {
  __typename?: "sensorflow_properties_information_var_pop_fields";
  backOfficesFloorPercentage?: Maybe<Scalars["Float"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Float"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Float"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["Float"]>;
  noOf1Bedrooms?: Maybe<Scalars["Float"]>;
  noOf2Bedrooms?: Maybe<Scalars["Float"]>;
  noOf3Bedrooms?: Maybe<Scalars["Float"]>;
  noOfStudios?: Maybe<Scalars["Float"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["Float"]>;
  roomsFloorPercentage?: Maybe<Scalars["Float"]>;
  spasFloorPercentage?: Maybe<Scalars["Float"]>;
  totalGuestRooms?: Maybe<Scalars["Float"]>;
  totalSquareFootage?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Float"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Properties_Information_Var_Samp_Fields = {
  __typename?: "sensorflow_properties_information_var_samp_fields";
  backOfficesFloorPercentage?: Maybe<Scalars["Float"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Float"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Float"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["Float"]>;
  noOf1Bedrooms?: Maybe<Scalars["Float"]>;
  noOf2Bedrooms?: Maybe<Scalars["Float"]>;
  noOf3Bedrooms?: Maybe<Scalars["Float"]>;
  noOfStudios?: Maybe<Scalars["Float"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["Float"]>;
  roomsFloorPercentage?: Maybe<Scalars["Float"]>;
  spasFloorPercentage?: Maybe<Scalars["Float"]>;
  totalGuestRooms?: Maybe<Scalars["Float"]>;
  totalSquareFootage?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Float"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Properties_Information_Variance_Fields = {
  __typename?: "sensorflow_properties_information_variance_fields";
  backOfficesFloorPercentage?: Maybe<Scalars["Float"]>;
  commonAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  electricityBillingPeriodStart?: Maybe<Scalars["Float"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Float"]>;
  hvacConsumptionPercentage?: Maybe<Scalars["Float"]>;
  noOf1Bedrooms?: Maybe<Scalars["Float"]>;
  noOf2Bedrooms?: Maybe<Scalars["Float"]>;
  noOf3Bedrooms?: Maybe<Scalars["Float"]>;
  noOfStudios?: Maybe<Scalars["Float"]>;
  otherAreasFloorPercentage?: Maybe<Scalars["Float"]>;
  restaurantsFloorPercentage?: Maybe<Scalars["Float"]>;
  roomsFloorPercentage?: Maybe<Scalars["Float"]>;
  spasFloorPercentage?: Maybe<Scalars["Float"]>;
  totalGuestRooms?: Maybe<Scalars["Float"]>;
  totalSquareFootage?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Float"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Float"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pumps = {
  __typename?: "sensorflow_pumps";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  meterPosition?: Maybe<Sensorflow_Positions>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases: Scalars["Int"];
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  position: Sensorflow_Positions;
  positionId: Scalars["uuid"];
};

export type Sensorflow_Pumps_Aggregate = {
  __typename?: "sensorflow_pumps_aggregate";
  aggregate?: Maybe<Sensorflow_Pumps_Aggregate_Fields>;
  nodes: Array<Sensorflow_Pumps>;
};

export type Sensorflow_Pumps_Aggregate_Fields = {
  __typename?: "sensorflow_pumps_aggregate_fields";
  avg?: Maybe<Sensorflow_Pumps_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Pumps_Max_Fields>;
  min?: Maybe<Sensorflow_Pumps_Min_Fields>;
  stddev?: Maybe<Sensorflow_Pumps_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Pumps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Pumps_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Pumps_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Pumps_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Pumps_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Pumps_Variance_Fields>;
};

export type Sensorflow_Pumps_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Pumps_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Pumps_Avg_Fields = {
  __typename?: "sensorflow_pumps_avg_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pumps_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Pumps_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Pumps_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Pumps_Bool_Exp>>;
  compressorId?: Maybe<Uuid_Comparison_Exp>;
  ctMultiplier?: Maybe<Numeric_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  meterPosition?: Maybe<Sensorflow_Positions_Bool_Exp>;
  meterPositionId?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  numPhases?: Maybe<Int_Comparison_Exp>;
  phaseStreamIndex?: Maybe<Int_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Pumps_Constraint {
  PumpsPkey = "pumps_pkey",
}

export type Sensorflow_Pumps_Inc_Input = {
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Pumps_Insert_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPosition?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Pumps_Max_Fields = {
  __typename?: "sensorflow_pumps_max_fields";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Pumps_Min_Fields = {
  __typename?: "sensorflow_pumps_min_fields";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Pumps_Mutation_Response = {
  __typename?: "sensorflow_pumps_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Pumps>;
};

export type Sensorflow_Pumps_On_Conflict = {
  constraint: Sensorflow_Pumps_Constraint;
  update_columns: Array<Sensorflow_Pumps_Update_Column>;
  where?: Maybe<Sensorflow_Pumps_Bool_Exp>;
};

export type Sensorflow_Pumps_Order_By = {
  compressorId?: Maybe<Order_By>;
  ctMultiplier?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meterPosition?: Maybe<Sensorflow_Positions_Order_By>;
  meterPositionId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  numPhases?: Maybe<Order_By>;
  phaseStreamIndex?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
};

export type Sensorflow_Pumps_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Pumps_Select_Column {
  CompressorId = "compressorId",
  CtMultiplier = "ctMultiplier",
  DeletedAt = "deleted_at",
  Id = "id",
  MeterPositionId = "meterPositionId",
  Name = "name",
  NumPhases = "numPhases",
  PhaseStreamIndex = "phaseStreamIndex",
  PositionId = "positionId",
}

export type Sensorflow_Pumps_Set_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Pumps_Stddev_Fields = {
  __typename?: "sensorflow_pumps_stddev_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pumps_Stddev_Pop_Fields = {
  __typename?: "sensorflow_pumps_stddev_pop_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pumps_Stddev_Samp_Fields = {
  __typename?: "sensorflow_pumps_stddev_samp_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pumps_Stream_Cursor_Input = {
  initial_value: Sensorflow_Pumps_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Pumps_Stream_Cursor_Value_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Pumps_Sum_Fields = {
  __typename?: "sensorflow_pumps_sum_fields";
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  numPhases?: Maybe<Scalars["Int"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Pumps_Update_Column {
  CompressorId = "compressorId",
  CtMultiplier = "ctMultiplier",
  DeletedAt = "deleted_at",
  Id = "id",
  MeterPositionId = "meterPositionId",
  Name = "name",
  NumPhases = "numPhases",
  PhaseStreamIndex = "phaseStreamIndex",
  PositionId = "positionId",
}

export type Sensorflow_Pumps_Updates = {
  _inc?: Maybe<Sensorflow_Pumps_Inc_Input>;
  _set?: Maybe<Sensorflow_Pumps_Set_Input>;
  where: Sensorflow_Pumps_Bool_Exp;
};

export type Sensorflow_Pumps_Var_Pop_Fields = {
  __typename?: "sensorflow_pumps_var_pop_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pumps_Var_Samp_Fields = {
  __typename?: "sensorflow_pumps_var_samp_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Pumps_Variance_Fields = {
  __typename?: "sensorflow_pumps_variance_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservation_Guests = {
  __typename?: "sensorflow_reservation_guests";
  comfortplusAccessToken?: Maybe<Scalars["String"]>;
  reservation: Sensorflow_Reservations;
  reservationId: Scalars["bigint"];
  user: Sensorflow_Users;
  userId: Scalars["String"];
};

export type Sensorflow_Reservation_Guests_Aggregate = {
  __typename?: "sensorflow_reservation_guests_aggregate";
  aggregate?: Maybe<Sensorflow_Reservation_Guests_Aggregate_Fields>;
  nodes: Array<Sensorflow_Reservation_Guests>;
};

export type Sensorflow_Reservation_Guests_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Reservation_Guests_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Reservation_Guests_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Reservation_Guests_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Reservation_Guests_Aggregate_Fields = {
  __typename?: "sensorflow_reservation_guests_aggregate_fields";
  avg?: Maybe<Sensorflow_Reservation_Guests_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Reservation_Guests_Max_Fields>;
  min?: Maybe<Sensorflow_Reservation_Guests_Min_Fields>;
  stddev?: Maybe<Sensorflow_Reservation_Guests_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Reservation_Guests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Reservation_Guests_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Reservation_Guests_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Reservation_Guests_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Reservation_Guests_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Reservation_Guests_Variance_Fields>;
};

export type Sensorflow_Reservation_Guests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Reservation_Guests_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Reservation_Guests_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Reservation_Guests_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Reservation_Guests_Max_Order_By>;
  min?: Maybe<Sensorflow_Reservation_Guests_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Reservation_Guests_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Reservation_Guests_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Reservation_Guests_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Reservation_Guests_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Reservation_Guests_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Reservation_Guests_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Reservation_Guests_Variance_Order_By>;
};

export type Sensorflow_Reservation_Guests_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Reservation_Guests_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Reservation_Guests_On_Conflict>;
};

export type Sensorflow_Reservation_Guests_Avg_Fields = {
  __typename?: "sensorflow_reservation_guests_avg_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservation_Guests_Avg_Order_By = {
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Reservation_Guests_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Reservation_Guests_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Reservation_Guests_Bool_Exp>>;
  comfortplusAccessToken?: Maybe<String_Comparison_Exp>;
  reservation?: Maybe<Sensorflow_Reservations_Bool_Exp>;
  reservationId?: Maybe<Bigint_Comparison_Exp>;
  user?: Maybe<Sensorflow_Users_Bool_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Reservation_Guests_Constraint {
  ReservationGuestsPkey = "reservation_guests_pkey",
}

export type Sensorflow_Reservation_Guests_Inc_Input = {
  reservationId?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Reservation_Guests_Insert_Input = {
  comfortplusAccessToken?: Maybe<Scalars["String"]>;
  reservation?: Maybe<Sensorflow_Reservations_Obj_Rel_Insert_Input>;
  reservationId?: Maybe<Scalars["bigint"]>;
  user?: Maybe<Sensorflow_Users_Obj_Rel_Insert_Input>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Reservation_Guests_Max_Fields = {
  __typename?: "sensorflow_reservation_guests_max_fields";
  comfortplusAccessToken?: Maybe<Scalars["String"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Reservation_Guests_Max_Order_By = {
  comfortplusAccessToken?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

export type Sensorflow_Reservation_Guests_Min_Fields = {
  __typename?: "sensorflow_reservation_guests_min_fields";
  comfortplusAccessToken?: Maybe<Scalars["String"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Reservation_Guests_Min_Order_By = {
  comfortplusAccessToken?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

export type Sensorflow_Reservation_Guests_Mutation_Response = {
  __typename?: "sensorflow_reservation_guests_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Reservation_Guests>;
};

export type Sensorflow_Reservation_Guests_On_Conflict = {
  constraint: Sensorflow_Reservation_Guests_Constraint;
  update_columns: Array<Sensorflow_Reservation_Guests_Update_Column>;
  where?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
};

export type Sensorflow_Reservation_Guests_Order_By = {
  comfortplusAccessToken?: Maybe<Order_By>;
  reservation?: Maybe<Sensorflow_Reservations_Order_By>;
  reservationId?: Maybe<Order_By>;
  user?: Maybe<Sensorflow_Users_Order_By>;
  userId?: Maybe<Order_By>;
};

export type Sensorflow_Reservation_Guests_Pk_Columns_Input = {
  reservationId: Scalars["bigint"];
  userId: Scalars["String"];
};

export enum Sensorflow_Reservation_Guests_Select_Column {
  ComfortplusAccessToken = "comfortplusAccessToken",
  ReservationId = "reservationId",
  UserId = "userId",
}

export type Sensorflow_Reservation_Guests_Set_Input = {
  comfortplusAccessToken?: Maybe<Scalars["String"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Reservation_Guests_Stddev_Fields = {
  __typename?: "sensorflow_reservation_guests_stddev_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservation_Guests_Stddev_Order_By = {
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Reservation_Guests_Stddev_Pop_Fields = {
  __typename?: "sensorflow_reservation_guests_stddev_pop_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservation_Guests_Stddev_Pop_Order_By = {
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Reservation_Guests_Stddev_Samp_Fields = {
  __typename?: "sensorflow_reservation_guests_stddev_samp_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservation_Guests_Stddev_Samp_Order_By = {
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Reservation_Guests_Stream_Cursor_Input = {
  initial_value: Sensorflow_Reservation_Guests_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Reservation_Guests_Stream_Cursor_Value_Input = {
  comfortplusAccessToken?: Maybe<Scalars["String"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Reservation_Guests_Sum_Fields = {
  __typename?: "sensorflow_reservation_guests_sum_fields";
  reservationId?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Reservation_Guests_Sum_Order_By = {
  reservationId?: Maybe<Order_By>;
};

export enum Sensorflow_Reservation_Guests_Update_Column {
  ComfortplusAccessToken = "comfortplusAccessToken",
  ReservationId = "reservationId",
  UserId = "userId",
}

export type Sensorflow_Reservation_Guests_Updates = {
  _inc?: Maybe<Sensorflow_Reservation_Guests_Inc_Input>;
  _set?: Maybe<Sensorflow_Reservation_Guests_Set_Input>;
  where: Sensorflow_Reservation_Guests_Bool_Exp;
};

export type Sensorflow_Reservation_Guests_Var_Pop_Fields = {
  __typename?: "sensorflow_reservation_guests_var_pop_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservation_Guests_Var_Pop_Order_By = {
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Reservation_Guests_Var_Samp_Fields = {
  __typename?: "sensorflow_reservation_guests_var_samp_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservation_Guests_Var_Samp_Order_By = {
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Reservation_Guests_Variance_Fields = {
  __typename?: "sensorflow_reservation_guests_variance_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservation_Guests_Variance_Order_By = {
  reservationId?: Maybe<Order_By>;
};

export type Sensorflow_Reservations = {
  __typename?: "sensorflow_reservations";
  checkInAt?: Maybe<Scalars["timestamptz"]>;
  checkOutAt?: Maybe<Scalars["timestamptz"]>;
  comfortplusActivations: Array<Sensorflow_Comfortplus_Activations>;
  comfortplusActivations_aggregate: Sensorflow_Comfortplus_Activations_Aggregate;
  comment?: Maybe<Scalars["String"]>;
  id: Scalars["bigint"];
  keyPosition: Sensorflow_Positions;
  keyPositionId: Scalars["uuid"];
  reservationGuests: Array<Sensorflow_Reservation_Guests>;
  reservationGuests_aggregate: Sensorflow_Reservation_Guests_Aggregate;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_ReservationsComfortplusActivationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Comfortplus_Activations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Comfortplus_Activations_Order_By>>;
  where?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
};

export type Sensorflow_ReservationsComfortplusActivations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Comfortplus_Activations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Comfortplus_Activations_Order_By>>;
  where?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
};

export type Sensorflow_ReservationsReservationGuestsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservation_Guests_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservation_Guests_Order_By>>;
  where?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
};

export type Sensorflow_ReservationsReservationGuests_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservation_Guests_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservation_Guests_Order_By>>;
  where?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
};

export type Sensorflow_Reservations_Aggregate = {
  __typename?: "sensorflow_reservations_aggregate";
  aggregate?: Maybe<Sensorflow_Reservations_Aggregate_Fields>;
  nodes: Array<Sensorflow_Reservations>;
};

export type Sensorflow_Reservations_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Reservations_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Reservations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Reservations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Reservations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Reservations_Aggregate_Fields = {
  __typename?: "sensorflow_reservations_aggregate_fields";
  avg?: Maybe<Sensorflow_Reservations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Reservations_Max_Fields>;
  min?: Maybe<Sensorflow_Reservations_Min_Fields>;
  stddev?: Maybe<Sensorflow_Reservations_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Reservations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Reservations_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Reservations_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Reservations_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Reservations_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Reservations_Variance_Fields>;
};

export type Sensorflow_Reservations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Reservations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Reservations_Aggregate_Order_By = {
  avg?: Maybe<Sensorflow_Reservations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Reservations_Max_Order_By>;
  min?: Maybe<Sensorflow_Reservations_Min_Order_By>;
  stddev?: Maybe<Sensorflow_Reservations_Stddev_Order_By>;
  stddev_pop?: Maybe<Sensorflow_Reservations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sensorflow_Reservations_Stddev_Samp_Order_By>;
  sum?: Maybe<Sensorflow_Reservations_Sum_Order_By>;
  var_pop?: Maybe<Sensorflow_Reservations_Var_Pop_Order_By>;
  var_samp?: Maybe<Sensorflow_Reservations_Var_Samp_Order_By>;
  variance?: Maybe<Sensorflow_Reservations_Variance_Order_By>;
};

export type Sensorflow_Reservations_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Reservations_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Reservations_On_Conflict>;
};

export type Sensorflow_Reservations_Avg_Fields = {
  __typename?: "sensorflow_reservations_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservations_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

export type Sensorflow_Reservations_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Reservations_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Reservations_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Reservations_Bool_Exp>>;
  checkInAt?: Maybe<Timestamptz_Comparison_Exp>;
  checkOutAt?: Maybe<Timestamptz_Comparison_Exp>;
  comfortplusActivations?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
  comfortplusActivations_aggregate?: Maybe<Sensorflow_Comfortplus_Activations_Aggregate_Bool_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  keyPosition?: Maybe<Sensorflow_Positions_Bool_Exp>;
  keyPositionId?: Maybe<Uuid_Comparison_Exp>;
  reservationGuests?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
  reservationGuests_aggregate?: Maybe<Sensorflow_Reservation_Guests_Aggregate_Bool_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Reservations_Constraint {
  ReservationsPkey = "reservations_pkey",
}

export type Sensorflow_Reservations_Inc_Input = {
  id?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Reservations_Insert_Input = {
  checkInAt?: Maybe<Scalars["timestamptz"]>;
  checkOutAt?: Maybe<Scalars["timestamptz"]>;
  comfortplusActivations?: Maybe<Sensorflow_Comfortplus_Activations_Arr_Rel_Insert_Input>;
  comment?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  keyPosition?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  keyPositionId?: Maybe<Scalars["uuid"]>;
  reservationGuests?: Maybe<Sensorflow_Reservation_Guests_Arr_Rel_Insert_Input>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Reservations_Max_Fields = {
  __typename?: "sensorflow_reservations_max_fields";
  checkInAt?: Maybe<Scalars["timestamptz"]>;
  checkOutAt?: Maybe<Scalars["timestamptz"]>;
  comment?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  keyPositionId?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Reservations_Max_Order_By = {
  checkInAt?: Maybe<Order_By>;
  checkOutAt?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyPositionId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

export type Sensorflow_Reservations_Min_Fields = {
  __typename?: "sensorflow_reservations_min_fields";
  checkInAt?: Maybe<Scalars["timestamptz"]>;
  checkOutAt?: Maybe<Scalars["timestamptz"]>;
  comment?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  keyPositionId?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Reservations_Min_Order_By = {
  checkInAt?: Maybe<Order_By>;
  checkOutAt?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyPositionId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

export type Sensorflow_Reservations_Mutation_Response = {
  __typename?: "sensorflow_reservations_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Reservations>;
};

export type Sensorflow_Reservations_Obj_Rel_Insert_Input = {
  data: Sensorflow_Reservations_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Reservations_On_Conflict>;
};

export type Sensorflow_Reservations_On_Conflict = {
  constraint: Sensorflow_Reservations_Constraint;
  update_columns: Array<Sensorflow_Reservations_Update_Column>;
  where?: Maybe<Sensorflow_Reservations_Bool_Exp>;
};

export type Sensorflow_Reservations_Order_By = {
  checkInAt?: Maybe<Order_By>;
  checkOutAt?: Maybe<Order_By>;
  comfortplusActivations_aggregate?: Maybe<Sensorflow_Comfortplus_Activations_Aggregate_Order_By>;
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyPosition?: Maybe<Sensorflow_Positions_Order_By>;
  keyPositionId?: Maybe<Order_By>;
  reservationGuests_aggregate?: Maybe<Sensorflow_Reservation_Guests_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
};

export type Sensorflow_Reservations_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

export enum Sensorflow_Reservations_Select_Column {
  CheckInAt = "checkInAt",
  CheckOutAt = "checkOutAt",
  Comment = "comment",
  Id = "id",
  KeyPositionId = "keyPositionId",
  Status = "status",
}

export type Sensorflow_Reservations_Set_Input = {
  checkInAt?: Maybe<Scalars["timestamptz"]>;
  checkOutAt?: Maybe<Scalars["timestamptz"]>;
  comment?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  keyPositionId?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Reservations_Stddev_Fields = {
  __typename?: "sensorflow_reservations_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservations_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

export type Sensorflow_Reservations_Stddev_Pop_Fields = {
  __typename?: "sensorflow_reservations_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservations_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

export type Sensorflow_Reservations_Stddev_Samp_Fields = {
  __typename?: "sensorflow_reservations_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservations_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

export type Sensorflow_Reservations_Stream_Cursor_Input = {
  initial_value: Sensorflow_Reservations_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Reservations_Stream_Cursor_Value_Input = {
  checkInAt?: Maybe<Scalars["timestamptz"]>;
  checkOutAt?: Maybe<Scalars["timestamptz"]>;
  comment?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  keyPositionId?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Reservations_Sum_Fields = {
  __typename?: "sensorflow_reservations_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_Reservations_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

export enum Sensorflow_Reservations_Update_Column {
  CheckInAt = "checkInAt",
  CheckOutAt = "checkOutAt",
  Comment = "comment",
  Id = "id",
  KeyPositionId = "keyPositionId",
  Status = "status",
}

export type Sensorflow_Reservations_Updates = {
  _inc?: Maybe<Sensorflow_Reservations_Inc_Input>;
  _set?: Maybe<Sensorflow_Reservations_Set_Input>;
  where: Sensorflow_Reservations_Bool_Exp;
};

export type Sensorflow_Reservations_Var_Pop_Fields = {
  __typename?: "sensorflow_reservations_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservations_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

export type Sensorflow_Reservations_Var_Samp_Fields = {
  __typename?: "sensorflow_reservations_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservations_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

export type Sensorflow_Reservations_Variance_Fields = {
  __typename?: "sensorflow_reservations_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Reservations_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

export type Sensorflow_Roles = {
  __typename?: "sensorflow_roles";
  default_route?: Maybe<Scalars["String"]>;
  hidden_in_dashboard?: Maybe<Scalars["Boolean"]>;
  id: Scalars["uuid"];
  name: Scalars["String"];
};

export type Sensorflow_Roles_Aggregate = {
  __typename?: "sensorflow_roles_aggregate";
  aggregate?: Maybe<Sensorflow_Roles_Aggregate_Fields>;
  nodes: Array<Sensorflow_Roles>;
};

export type Sensorflow_Roles_Aggregate_Fields = {
  __typename?: "sensorflow_roles_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Roles_Max_Fields>;
  min?: Maybe<Sensorflow_Roles_Min_Fields>;
};

export type Sensorflow_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Roles_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Roles_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Roles_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Roles_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Roles_Bool_Exp>>;
  default_route?: Maybe<String_Comparison_Exp>;
  hidden_in_dashboard?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Roles_Constraint {
  RolesPkey = "roles_pkey",
}

export type Sensorflow_Roles_Insert_Input = {
  default_route?: Maybe<Scalars["String"]>;
  hidden_in_dashboard?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Roles_Max_Fields = {
  __typename?: "sensorflow_roles_max_fields";
  default_route?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Roles_Min_Fields = {
  __typename?: "sensorflow_roles_min_fields";
  default_route?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Roles_Mutation_Response = {
  __typename?: "sensorflow_roles_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Roles>;
};

export type Sensorflow_Roles_On_Conflict = {
  constraint: Sensorflow_Roles_Constraint;
  update_columns: Array<Sensorflow_Roles_Update_Column>;
  where?: Maybe<Sensorflow_Roles_Bool_Exp>;
};

export type Sensorflow_Roles_Order_By = {
  default_route?: Maybe<Order_By>;
  hidden_in_dashboard?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

export type Sensorflow_Roles_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Roles_Select_Column {
  DefaultRoute = "default_route",
  HiddenInDashboard = "hidden_in_dashboard",
  Id = "id",
  Name = "name",
}

export type Sensorflow_Roles_Set_Input = {
  default_route?: Maybe<Scalars["String"]>;
  hidden_in_dashboard?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Roles_Stream_Cursor_Input = {
  initial_value: Sensorflow_Roles_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Roles_Stream_Cursor_Value_Input = {
  default_route?: Maybe<Scalars["String"]>;
  hidden_in_dashboard?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Roles_Update_Column {
  DefaultRoute = "default_route",
  HiddenInDashboard = "hidden_in_dashboard",
  Id = "id",
  Name = "name",
}

export type Sensorflow_Roles_Updates = {
  _set?: Maybe<Sensorflow_Roles_Set_Input>;
  where: Sensorflow_Roles_Bool_Exp;
};

export type Sensorflow_Smart_Rem_Validation_History = {
  __typename?: "sensorflow_smart_rem_validation_history";
  automationMode?: Maybe<Scalars["String"]>;
  currentToggleState?: Maybe<Scalars["String"]>;
  desiredToggleHour?: Maybe<Scalars["Int"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  lastToggleTimestamp?: Maybe<Scalars["timestamptz"]>;
  locationId: Scalars["uuid"];
  smartsaveSetpoint?: Maybe<Scalars["Int"]>;
  validationEndDate?: Maybe<Scalars["timestamptz"]>;
  validationStartDate: Scalars["timestamptz"];
};

export type Sensorflow_Smart_Rem_Validation_History_Aggregate = {
  __typename?: "sensorflow_smart_rem_validation_history_aggregate";
  aggregate?: Maybe<Sensorflow_Smart_Rem_Validation_History_Aggregate_Fields>;
  nodes: Array<Sensorflow_Smart_Rem_Validation_History>;
};

export type Sensorflow_Smart_Rem_Validation_History_Aggregate_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_aggregate_fields";
  avg?: Maybe<Sensorflow_Smart_Rem_Validation_History_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Smart_Rem_Validation_History_Max_Fields>;
  min?: Maybe<Sensorflow_Smart_Rem_Validation_History_Min_Fields>;
  stddev?: Maybe<Sensorflow_Smart_Rem_Validation_History_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Smart_Rem_Validation_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Smart_Rem_Validation_History_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Smart_Rem_Validation_History_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Smart_Rem_Validation_History_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Smart_Rem_Validation_History_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Smart_Rem_Validation_History_Variance_Fields>;
};

export type Sensorflow_Smart_Rem_Validation_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Avg_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_avg_fields";
  desiredToggleHour?: Maybe<Scalars["Float"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Float"]>;
  smartsaveSetpoint?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Smart_Rem_Validation_History_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Bool_Exp>>;
  automationMode?: Maybe<String_Comparison_Exp>;
  currentToggleState?: Maybe<String_Comparison_Exp>;
  desiredToggleHour?: Maybe<Int_Comparison_Exp>;
  desiredToggleTimeoutHours?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastToggleTimestamp?: Maybe<Timestamptz_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  smartsaveSetpoint?: Maybe<Int_Comparison_Exp>;
  validationEndDate?: Maybe<Timestamptz_Comparison_Exp>;
  validationStartDate?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Smart_Rem_Validation_History_Constraint {
  SmartRemValidationHistoryPkey = "smart_rem_validation_history_pkey",
}

export type Sensorflow_Smart_Rem_Validation_History_Inc_Input = {
  desiredToggleHour?: Maybe<Scalars["Int"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Int"]>;
  smartsaveSetpoint?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Insert_Input = {
  automationMode?: Maybe<Scalars["String"]>;
  currentToggleState?: Maybe<Scalars["String"]>;
  desiredToggleHour?: Maybe<Scalars["Int"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastToggleTimestamp?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  smartsaveSetpoint?: Maybe<Scalars["Int"]>;
  validationEndDate?: Maybe<Scalars["timestamptz"]>;
  validationStartDate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Max_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_max_fields";
  automationMode?: Maybe<Scalars["String"]>;
  currentToggleState?: Maybe<Scalars["String"]>;
  desiredToggleHour?: Maybe<Scalars["Int"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastToggleTimestamp?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  smartsaveSetpoint?: Maybe<Scalars["Int"]>;
  validationEndDate?: Maybe<Scalars["timestamptz"]>;
  validationStartDate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Min_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_min_fields";
  automationMode?: Maybe<Scalars["String"]>;
  currentToggleState?: Maybe<Scalars["String"]>;
  desiredToggleHour?: Maybe<Scalars["Int"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastToggleTimestamp?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  smartsaveSetpoint?: Maybe<Scalars["Int"]>;
  validationEndDate?: Maybe<Scalars["timestamptz"]>;
  validationStartDate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Mutation_Response = {
  __typename?: "sensorflow_smart_rem_validation_history_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Smart_Rem_Validation_History>;
};

export type Sensorflow_Smart_Rem_Validation_History_On_Conflict = {
  constraint: Sensorflow_Smart_Rem_Validation_History_Constraint;
  update_columns: Array<Sensorflow_Smart_Rem_Validation_History_Update_Column>;
  where?: Maybe<Sensorflow_Smart_Rem_Validation_History_Bool_Exp>;
};

export type Sensorflow_Smart_Rem_Validation_History_Order_By = {
  automationMode?: Maybe<Order_By>;
  currentToggleState?: Maybe<Order_By>;
  desiredToggleHour?: Maybe<Order_By>;
  desiredToggleTimeoutHours?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastToggleTimestamp?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  smartsaveSetpoint?: Maybe<Order_By>;
  validationEndDate?: Maybe<Order_By>;
  validationStartDate?: Maybe<Order_By>;
};

export type Sensorflow_Smart_Rem_Validation_History_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Smart_Rem_Validation_History_Select_Column {
  AutomationMode = "automationMode",
  CurrentToggleState = "currentToggleState",
  DesiredToggleHour = "desiredToggleHour",
  DesiredToggleTimeoutHours = "desiredToggleTimeoutHours",
  Id = "id",
  LastToggleTimestamp = "lastToggleTimestamp",
  LocationId = "locationId",
  SmartsaveSetpoint = "smartsaveSetpoint",
  ValidationEndDate = "validationEndDate",
  ValidationStartDate = "validationStartDate",
}

export type Sensorflow_Smart_Rem_Validation_History_Set_Input = {
  automationMode?: Maybe<Scalars["String"]>;
  currentToggleState?: Maybe<Scalars["String"]>;
  desiredToggleHour?: Maybe<Scalars["Int"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastToggleTimestamp?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  smartsaveSetpoint?: Maybe<Scalars["Int"]>;
  validationEndDate?: Maybe<Scalars["timestamptz"]>;
  validationStartDate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Stddev_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_stddev_fields";
  desiredToggleHour?: Maybe<Scalars["Float"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Float"]>;
  smartsaveSetpoint?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Stddev_Pop_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_stddev_pop_fields";
  desiredToggleHour?: Maybe<Scalars["Float"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Float"]>;
  smartsaveSetpoint?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Stddev_Samp_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_stddev_samp_fields";
  desiredToggleHour?: Maybe<Scalars["Float"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Float"]>;
  smartsaveSetpoint?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Stream_Cursor_Input = {
  initial_value: Sensorflow_Smart_Rem_Validation_History_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Smart_Rem_Validation_History_Stream_Cursor_Value_Input = {
  automationMode?: Maybe<Scalars["String"]>;
  currentToggleState?: Maybe<Scalars["String"]>;
  desiredToggleHour?: Maybe<Scalars["Int"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastToggleTimestamp?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  smartsaveSetpoint?: Maybe<Scalars["Int"]>;
  validationEndDate?: Maybe<Scalars["timestamptz"]>;
  validationStartDate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Sum_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_sum_fields";
  desiredToggleHour?: Maybe<Scalars["Int"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Int"]>;
  smartsaveSetpoint?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Smart_Rem_Validation_History_Update_Column {
  AutomationMode = "automationMode",
  CurrentToggleState = "currentToggleState",
  DesiredToggleHour = "desiredToggleHour",
  DesiredToggleTimeoutHours = "desiredToggleTimeoutHours",
  Id = "id",
  LastToggleTimestamp = "lastToggleTimestamp",
  LocationId = "locationId",
  SmartsaveSetpoint = "smartsaveSetpoint",
  ValidationEndDate = "validationEndDate",
  ValidationStartDate = "validationStartDate",
}

export type Sensorflow_Smart_Rem_Validation_History_Updates = {
  _inc?: Maybe<Sensorflow_Smart_Rem_Validation_History_Inc_Input>;
  _set?: Maybe<Sensorflow_Smart_Rem_Validation_History_Set_Input>;
  where: Sensorflow_Smart_Rem_Validation_History_Bool_Exp;
};

export type Sensorflow_Smart_Rem_Validation_History_Var_Pop_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_var_pop_fields";
  desiredToggleHour?: Maybe<Scalars["Float"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Float"]>;
  smartsaveSetpoint?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Var_Samp_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_var_samp_fields";
  desiredToggleHour?: Maybe<Scalars["Float"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Float"]>;
  smartsaveSetpoint?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Smart_Rem_Validation_History_Variance_Fields = {
  __typename?: "sensorflow_smart_rem_validation_history_variance_fields";
  desiredToggleHour?: Maybe<Scalars["Float"]>;
  desiredToggleTimeoutHours?: Maybe<Scalars["Float"]>;
  smartsaveSetpoint?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels = {
  __typename?: "sensorflow_smplrspace_floors_to_levels";
  group_id: Scalars["uuid"];
  levelsGroup: Sensorflow_Smplrspace_Levels_Group;
  position_id: Scalars["uuid"];
  positions: Sensorflow_Positions;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Aggregate = {
  __typename?: "sensorflow_smplrspace_floors_to_levels_aggregate";
  aggregate?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Aggregate_Fields>;
  nodes: Array<Sensorflow_Smplrspace_Floors_To_Levels>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Aggregate_Fields = {
  __typename?: "sensorflow_smplrspace_floors_to_levels_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Max_Fields>;
  min?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Min_Fields>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Max_Order_By>;
  min?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Min_Order_By>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Smplrspace_Floors_To_Levels_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_On_Conflict>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  levelsGroup?: Maybe<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>;
  position_id?: Maybe<Uuid_Comparison_Exp>;
  positions?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export enum Sensorflow_Smplrspace_Floors_To_Levels_Constraint {
  SmplrspaceFloorsToLevelsPkey = "smplrspace_floors_to_levels_pkey",
  SmplrspaceFloorsToLevelsPositionIdKey = "smplrspace_floors_to_levels_position_id_key",
}

export type Sensorflow_Smplrspace_Floors_To_Levels_Insert_Input = {
  group_id?: Maybe<Scalars["uuid"]>;
  levelsGroup?: Maybe<Sensorflow_Smplrspace_Levels_Group_Obj_Rel_Insert_Input>;
  position_id?: Maybe<Scalars["uuid"]>;
  positions?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Max_Fields = {
  __typename?: "sensorflow_smplrspace_floors_to_levels_max_fields";
  group_id?: Maybe<Scalars["uuid"]>;
  position_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Max_Order_By = {
  group_id?: Maybe<Order_By>;
  position_id?: Maybe<Order_By>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Min_Fields = {
  __typename?: "sensorflow_smplrspace_floors_to_levels_min_fields";
  group_id?: Maybe<Scalars["uuid"]>;
  position_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Min_Order_By = {
  group_id?: Maybe<Order_By>;
  position_id?: Maybe<Order_By>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Mutation_Response = {
  __typename?: "sensorflow_smplrspace_floors_to_levels_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Smplrspace_Floors_To_Levels>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_On_Conflict = {
  constraint: Sensorflow_Smplrspace_Floors_To_Levels_Constraint;
  update_columns: Array<Sensorflow_Smplrspace_Floors_To_Levels_Update_Column>;
  where?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Order_By = {
  group_id?: Maybe<Order_By>;
  levelsGroup?: Maybe<Sensorflow_Smplrspace_Levels_Group_Order_By>;
  position_id?: Maybe<Order_By>;
  positions?: Maybe<Sensorflow_Positions_Order_By>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Pk_Columns_Input = {
  position_id: Scalars["uuid"];
};

export enum Sensorflow_Smplrspace_Floors_To_Levels_Select_Column {
  GroupId = "group_id",
  PositionId = "position_id",
}

export type Sensorflow_Smplrspace_Floors_To_Levels_Set_Input = {
  group_id?: Maybe<Scalars["uuid"]>;
  position_id?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Stream_Cursor_Input = {
  initial_value: Sensorflow_Smplrspace_Floors_To_Levels_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Smplrspace_Floors_To_Levels_Stream_Cursor_Value_Input = {
  group_id?: Maybe<Scalars["uuid"]>;
  position_id?: Maybe<Scalars["uuid"]>;
};

export enum Sensorflow_Smplrspace_Floors_To_Levels_Update_Column {
  GroupId = "group_id",
  PositionId = "position_id",
}

export type Sensorflow_Smplrspace_Floors_To_Levels_Updates = {
  _set?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Set_Input>;
  where: Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp;
};

export type Sensorflow_Smplrspace_Levels_Group = {
  __typename?: "sensorflow_smplrspace_levels_group";
  floorsToLevels: Array<Sensorflow_Smplrspace_Floors_To_Levels>;
  floorsToLevels_aggregate: Sensorflow_Smplrspace_Floors_To_Levels_Aggregate;
  groupId: Scalars["uuid"];
  groupName?: Maybe<Scalars["String"]>;
  levelIndex?: Maybe<Array<Scalars["Int"]>>;
  spaceId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Smplrspace_Levels_GroupFloorsToLevelsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Order_By>>;
  where?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
};

export type Sensorflow_Smplrspace_Levels_GroupFloorsToLevels_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Order_By>>;
  where?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
};

export type Sensorflow_Smplrspace_Levels_Group_Aggregate = {
  __typename?: "sensorflow_smplrspace_levels_group_aggregate";
  aggregate?: Maybe<Sensorflow_Smplrspace_Levels_Group_Aggregate_Fields>;
  nodes: Array<Sensorflow_Smplrspace_Levels_Group>;
};

export type Sensorflow_Smplrspace_Levels_Group_Aggregate_Fields = {
  __typename?: "sensorflow_smplrspace_levels_group_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Smplrspace_Levels_Group_Max_Fields>;
  min?: Maybe<Sensorflow_Smplrspace_Levels_Group_Min_Fields>;
};

export type Sensorflow_Smplrspace_Levels_Group_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Smplrspace_Levels_Group_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>>;
  floorsToLevels?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
  floorsToLevels_aggregate?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Aggregate_Bool_Exp>;
  groupId?: Maybe<Uuid_Comparison_Exp>;
  groupName?: Maybe<String_Comparison_Exp>;
  levelIndex?: Maybe<Int_Array_Comparison_Exp>;
  spaceId?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Smplrspace_Levels_Group_Constraint {
  MapFloorPlanPkey = "map_floor_plan_pkey",
}

export type Sensorflow_Smplrspace_Levels_Group_Insert_Input = {
  floorsToLevels?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Arr_Rel_Insert_Input>;
  groupId?: Maybe<Scalars["uuid"]>;
  groupName?: Maybe<Scalars["String"]>;
  levelIndex?: Maybe<Array<Scalars["Int"]>>;
  spaceId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Smplrspace_Levels_Group_Max_Fields = {
  __typename?: "sensorflow_smplrspace_levels_group_max_fields";
  groupId?: Maybe<Scalars["uuid"]>;
  groupName?: Maybe<Scalars["String"]>;
  levelIndex?: Maybe<Array<Scalars["Int"]>>;
  spaceId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Smplrspace_Levels_Group_Min_Fields = {
  __typename?: "sensorflow_smplrspace_levels_group_min_fields";
  groupId?: Maybe<Scalars["uuid"]>;
  groupName?: Maybe<Scalars["String"]>;
  levelIndex?: Maybe<Array<Scalars["Int"]>>;
  spaceId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Smplrspace_Levels_Group_Mutation_Response = {
  __typename?: "sensorflow_smplrspace_levels_group_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Smplrspace_Levels_Group>;
};

export type Sensorflow_Smplrspace_Levels_Group_Obj_Rel_Insert_Input = {
  data: Sensorflow_Smplrspace_Levels_Group_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Smplrspace_Levels_Group_On_Conflict>;
};

export type Sensorflow_Smplrspace_Levels_Group_On_Conflict = {
  constraint: Sensorflow_Smplrspace_Levels_Group_Constraint;
  update_columns: Array<Sensorflow_Smplrspace_Levels_Group_Update_Column>;
  where?: Maybe<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>;
};

export type Sensorflow_Smplrspace_Levels_Group_Order_By = {
  floorsToLevels_aggregate?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Aggregate_Order_By>;
  groupId?: Maybe<Order_By>;
  groupName?: Maybe<Order_By>;
  levelIndex?: Maybe<Order_By>;
  spaceId?: Maybe<Order_By>;
};

export type Sensorflow_Smplrspace_Levels_Group_Pk_Columns_Input = {
  groupId: Scalars["uuid"];
};

export enum Sensorflow_Smplrspace_Levels_Group_Select_Column {
  GroupId = "groupId",
  GroupName = "groupName",
  LevelIndex = "levelIndex",
  SpaceId = "spaceId",
}

export type Sensorflow_Smplrspace_Levels_Group_Set_Input = {
  groupId?: Maybe<Scalars["uuid"]>;
  groupName?: Maybe<Scalars["String"]>;
  levelIndex?: Maybe<Array<Scalars["Int"]>>;
  spaceId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Smplrspace_Levels_Group_Stream_Cursor_Input = {
  initial_value: Sensorflow_Smplrspace_Levels_Group_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Smplrspace_Levels_Group_Stream_Cursor_Value_Input = {
  groupId?: Maybe<Scalars["uuid"]>;
  groupName?: Maybe<Scalars["String"]>;
  levelIndex?: Maybe<Array<Scalars["Int"]>>;
  spaceId?: Maybe<Scalars["uuid"]>;
};

export enum Sensorflow_Smplrspace_Levels_Group_Update_Column {
  GroupId = "groupId",
  GroupName = "groupName",
  LevelIndex = "levelIndex",
  SpaceId = "spaceId",
}

export type Sensorflow_Smplrspace_Levels_Group_Updates = {
  _set?: Maybe<Sensorflow_Smplrspace_Levels_Group_Set_Input>;
  where: Sensorflow_Smplrspace_Levels_Group_Bool_Exp;
};

export type Sensorflow_Survey_Responses = {
  __typename?: "sensorflow_survey_responses";
  consumptionFileURL?: Maybe<Scalars["String"]>;
  location: Sensorflow_Locations;
  locationId: Scalars["uuid"];
  responseId: Scalars["Int"];
  submissionDate: Scalars["timestamptz"];
  surveyResponse: Scalars["jsonb"];
  user: Sensorflow_Users;
  userId: Scalars["String"];
};

export type Sensorflow_Survey_ResponsesSurveyResponseArgs = {
  path?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Survey_Responses_Aggregate = {
  __typename?: "sensorflow_survey_responses_aggregate";
  aggregate?: Maybe<Sensorflow_Survey_Responses_Aggregate_Fields>;
  nodes: Array<Sensorflow_Survey_Responses>;
};

export type Sensorflow_Survey_Responses_Aggregate_Fields = {
  __typename?: "sensorflow_survey_responses_aggregate_fields";
  avg?: Maybe<Sensorflow_Survey_Responses_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Survey_Responses_Max_Fields>;
  min?: Maybe<Sensorflow_Survey_Responses_Min_Fields>;
  stddev?: Maybe<Sensorflow_Survey_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Survey_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Survey_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Survey_Responses_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Survey_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Survey_Responses_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Survey_Responses_Variance_Fields>;
};

export type Sensorflow_Survey_Responses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Survey_Responses_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Survey_Responses_Append_Input = {
  surveyResponse?: Maybe<Scalars["jsonb"]>;
};

export type Sensorflow_Survey_Responses_Avg_Fields = {
  __typename?: "sensorflow_survey_responses_avg_fields";
  responseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Survey_Responses_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Survey_Responses_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Survey_Responses_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Survey_Responses_Bool_Exp>>;
  consumptionFileURL?: Maybe<String_Comparison_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  responseId?: Maybe<Int_Comparison_Exp>;
  submissionDate?: Maybe<Timestamptz_Comparison_Exp>;
  surveyResponse?: Maybe<Jsonb_Comparison_Exp>;
  user?: Maybe<Sensorflow_Users_Bool_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Survey_Responses_Constraint {
  SurveyResponsesPkey = "survey_responses_pkey",
}

export type Sensorflow_Survey_Responses_Delete_At_Path_Input = {
  surveyResponse?: Maybe<Array<Scalars["String"]>>;
};

export type Sensorflow_Survey_Responses_Delete_Elem_Input = {
  surveyResponse?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Survey_Responses_Delete_Key_Input = {
  surveyResponse?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Survey_Responses_Inc_Input = {
  responseId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Survey_Responses_Insert_Input = {
  consumptionFileURL?: Maybe<Scalars["String"]>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars["uuid"]>;
  responseId?: Maybe<Scalars["Int"]>;
  submissionDate?: Maybe<Scalars["timestamptz"]>;
  surveyResponse?: Maybe<Scalars["jsonb"]>;
  user?: Maybe<Sensorflow_Users_Obj_Rel_Insert_Input>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Survey_Responses_Max_Fields = {
  __typename?: "sensorflow_survey_responses_max_fields";
  consumptionFileURL?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  responseId?: Maybe<Scalars["Int"]>;
  submissionDate?: Maybe<Scalars["timestamptz"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Survey_Responses_Min_Fields = {
  __typename?: "sensorflow_survey_responses_min_fields";
  consumptionFileURL?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  responseId?: Maybe<Scalars["Int"]>;
  submissionDate?: Maybe<Scalars["timestamptz"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Survey_Responses_Mutation_Response = {
  __typename?: "sensorflow_survey_responses_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Survey_Responses>;
};

export type Sensorflow_Survey_Responses_On_Conflict = {
  constraint: Sensorflow_Survey_Responses_Constraint;
  update_columns: Array<Sensorflow_Survey_Responses_Update_Column>;
  where?: Maybe<Sensorflow_Survey_Responses_Bool_Exp>;
};

export type Sensorflow_Survey_Responses_Order_By = {
  consumptionFileURL?: Maybe<Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  responseId?: Maybe<Order_By>;
  submissionDate?: Maybe<Order_By>;
  surveyResponse?: Maybe<Order_By>;
  user?: Maybe<Sensorflow_Users_Order_By>;
  userId?: Maybe<Order_By>;
};

export type Sensorflow_Survey_Responses_Pk_Columns_Input = {
  responseId: Scalars["Int"];
};

export type Sensorflow_Survey_Responses_Prepend_Input = {
  surveyResponse?: Maybe<Scalars["jsonb"]>;
};

export enum Sensorflow_Survey_Responses_Select_Column {
  ConsumptionFileUrl = "consumptionFileURL",
  LocationId = "locationId",
  ResponseId = "responseId",
  SubmissionDate = "submissionDate",
  SurveyResponse = "surveyResponse",
  UserId = "userId",
}

export type Sensorflow_Survey_Responses_Set_Input = {
  consumptionFileURL?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  responseId?: Maybe<Scalars["Int"]>;
  submissionDate?: Maybe<Scalars["timestamptz"]>;
  surveyResponse?: Maybe<Scalars["jsonb"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Survey_Responses_Stddev_Fields = {
  __typename?: "sensorflow_survey_responses_stddev_fields";
  responseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Survey_Responses_Stddev_Pop_Fields = {
  __typename?: "sensorflow_survey_responses_stddev_pop_fields";
  responseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Survey_Responses_Stddev_Samp_Fields = {
  __typename?: "sensorflow_survey_responses_stddev_samp_fields";
  responseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Survey_Responses_Stream_Cursor_Input = {
  initial_value: Sensorflow_Survey_Responses_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Survey_Responses_Stream_Cursor_Value_Input = {
  consumptionFileURL?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  responseId?: Maybe<Scalars["Int"]>;
  submissionDate?: Maybe<Scalars["timestamptz"]>;
  surveyResponse?: Maybe<Scalars["jsonb"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Survey_Responses_Sum_Fields = {
  __typename?: "sensorflow_survey_responses_sum_fields";
  responseId?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Survey_Responses_Update_Column {
  ConsumptionFileUrl = "consumptionFileURL",
  LocationId = "locationId",
  ResponseId = "responseId",
  SubmissionDate = "submissionDate",
  SurveyResponse = "surveyResponse",
  UserId = "userId",
}

export type Sensorflow_Survey_Responses_Updates = {
  _append?: Maybe<Sensorflow_Survey_Responses_Append_Input>;
  _delete_at_path?: Maybe<Sensorflow_Survey_Responses_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sensorflow_Survey_Responses_Delete_Elem_Input>;
  _delete_key?: Maybe<Sensorflow_Survey_Responses_Delete_Key_Input>;
  _inc?: Maybe<Sensorflow_Survey_Responses_Inc_Input>;
  _prepend?: Maybe<Sensorflow_Survey_Responses_Prepend_Input>;
  _set?: Maybe<Sensorflow_Survey_Responses_Set_Input>;
  where: Sensorflow_Survey_Responses_Bool_Exp;
};

export type Sensorflow_Survey_Responses_Var_Pop_Fields = {
  __typename?: "sensorflow_survey_responses_var_pop_fields";
  responseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Survey_Responses_Var_Samp_Fields = {
  __typename?: "sensorflow_survey_responses_var_samp_fields";
  responseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Survey_Responses_Variance_Fields = {
  __typename?: "sensorflow_survey_responses_variance_fields";
  responseId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Synced_Raw_Data = {
  __typename?: "sensorflow_synced_raw_data";
  ac_mode?: Maybe<Scalars["numeric"]>;
  ambient_humidity?: Maybe<Scalars["numeric"]>;
  ambient_temperature?: Maybe<Scalars["numeric"]>;
  door_state?: Maybe<Scalars["numeric"]>;
  fan_speed?: Maybe<Scalars["numeric"]>;
  occupancy_state?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  powered?: Maybe<Scalars["numeric"]>;
  temperature_set?: Maybe<Scalars["numeric"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Synced_Raw_Data_Aggregate = {
  __typename?: "sensorflow_synced_raw_data_aggregate";
  aggregate?: Maybe<Sensorflow_Synced_Raw_Data_Aggregate_Fields>;
  nodes: Array<Sensorflow_Synced_Raw_Data>;
};

export type Sensorflow_Synced_Raw_Data_Aggregate_Fields = {
  __typename?: "sensorflow_synced_raw_data_aggregate_fields";
  avg?: Maybe<Sensorflow_Synced_Raw_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Synced_Raw_Data_Max_Fields>;
  min?: Maybe<Sensorflow_Synced_Raw_Data_Min_Fields>;
  stddev?: Maybe<Sensorflow_Synced_Raw_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Synced_Raw_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Synced_Raw_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Synced_Raw_Data_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Synced_Raw_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Synced_Raw_Data_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Synced_Raw_Data_Variance_Fields>;
};

export type Sensorflow_Synced_Raw_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Synced_Raw_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Synced_Raw_Data_Avg_Fields = {
  __typename?: "sensorflow_synced_raw_data_avg_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Synced_Raw_Data_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Synced_Raw_Data_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Synced_Raw_Data_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Synced_Raw_Data_Bool_Exp>>;
  ac_mode?: Maybe<Numeric_Comparison_Exp>;
  ambient_humidity?: Maybe<Numeric_Comparison_Exp>;
  ambient_temperature?: Maybe<Numeric_Comparison_Exp>;
  door_state?: Maybe<Numeric_Comparison_Exp>;
  fan_speed?: Maybe<Numeric_Comparison_Exp>;
  occupancy_state?: Maybe<Numeric_Comparison_Exp>;
  position_id?: Maybe<Uuid_Comparison_Exp>;
  powered?: Maybe<Numeric_Comparison_Exp>;
  temperature_set?: Maybe<Numeric_Comparison_Exp>;
  timestamp?: Maybe<Timestamptz_Comparison_Exp>;
};

export type Sensorflow_Synced_Raw_Data_Max_Fields = {
  __typename?: "sensorflow_synced_raw_data_max_fields";
  ac_mode?: Maybe<Scalars["numeric"]>;
  ambient_humidity?: Maybe<Scalars["numeric"]>;
  ambient_temperature?: Maybe<Scalars["numeric"]>;
  door_state?: Maybe<Scalars["numeric"]>;
  fan_speed?: Maybe<Scalars["numeric"]>;
  occupancy_state?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  powered?: Maybe<Scalars["numeric"]>;
  temperature_set?: Maybe<Scalars["numeric"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Synced_Raw_Data_Min_Fields = {
  __typename?: "sensorflow_synced_raw_data_min_fields";
  ac_mode?: Maybe<Scalars["numeric"]>;
  ambient_humidity?: Maybe<Scalars["numeric"]>;
  ambient_temperature?: Maybe<Scalars["numeric"]>;
  door_state?: Maybe<Scalars["numeric"]>;
  fan_speed?: Maybe<Scalars["numeric"]>;
  occupancy_state?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  powered?: Maybe<Scalars["numeric"]>;
  temperature_set?: Maybe<Scalars["numeric"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Synced_Raw_Data_Order_By = {
  ac_mode?: Maybe<Order_By>;
  ambient_humidity?: Maybe<Order_By>;
  ambient_temperature?: Maybe<Order_By>;
  door_state?: Maybe<Order_By>;
  fan_speed?: Maybe<Order_By>;
  occupancy_state?: Maybe<Order_By>;
  position_id?: Maybe<Order_By>;
  powered?: Maybe<Order_By>;
  temperature_set?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
};

export enum Sensorflow_Synced_Raw_Data_Select_Column {
  AcMode = "ac_mode",
  AmbientHumidity = "ambient_humidity",
  AmbientTemperature = "ambient_temperature",
  DoorState = "door_state",
  FanSpeed = "fan_speed",
  OccupancyState = "occupancy_state",
  PositionId = "position_id",
  Powered = "powered",
  TemperatureSet = "temperature_set",
  Timestamp = "timestamp",
}

export type Sensorflow_Synced_Raw_Data_Stddev_Fields = {
  __typename?: "sensorflow_synced_raw_data_stddev_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Synced_Raw_Data_Stddev_Pop_Fields = {
  __typename?: "sensorflow_synced_raw_data_stddev_pop_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Synced_Raw_Data_Stddev_Samp_Fields = {
  __typename?: "sensorflow_synced_raw_data_stddev_samp_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Synced_Raw_Data_Stream_Cursor_Input = {
  initial_value: Sensorflow_Synced_Raw_Data_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Synced_Raw_Data_Stream_Cursor_Value_Input = {
  ac_mode?: Maybe<Scalars["numeric"]>;
  ambient_humidity?: Maybe<Scalars["numeric"]>;
  ambient_temperature?: Maybe<Scalars["numeric"]>;
  door_state?: Maybe<Scalars["numeric"]>;
  fan_speed?: Maybe<Scalars["numeric"]>;
  occupancy_state?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  powered?: Maybe<Scalars["numeric"]>;
  temperature_set?: Maybe<Scalars["numeric"]>;
  timestamp?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Synced_Raw_Data_Sum_Fields = {
  __typename?: "sensorflow_synced_raw_data_sum_fields";
  ac_mode?: Maybe<Scalars["numeric"]>;
  ambient_humidity?: Maybe<Scalars["numeric"]>;
  ambient_temperature?: Maybe<Scalars["numeric"]>;
  door_state?: Maybe<Scalars["numeric"]>;
  fan_speed?: Maybe<Scalars["numeric"]>;
  occupancy_state?: Maybe<Scalars["numeric"]>;
  powered?: Maybe<Scalars["numeric"]>;
  temperature_set?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_Synced_Raw_Data_Var_Pop_Fields = {
  __typename?: "sensorflow_synced_raw_data_var_pop_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Synced_Raw_Data_Var_Samp_Fields = {
  __typename?: "sensorflow_synced_raw_data_var_samp_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Synced_Raw_Data_Variance_Fields = {
  __typename?: "sensorflow_synced_raw_data_variance_fields";
  ac_mode?: Maybe<Scalars["Float"]>;
  ambient_humidity?: Maybe<Scalars["Float"]>;
  ambient_temperature?: Maybe<Scalars["Float"]>;
  door_state?: Maybe<Scalars["Float"]>;
  fan_speed?: Maybe<Scalars["Float"]>;
  occupancy_state?: Maybe<Scalars["Float"]>;
  powered?: Maybe<Scalars["Float"]>;
  temperature_set?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Task_Status_History = {
  __typename?: "sensorflow_task_status_history";
  createdAt: Scalars["timestamptz"];
  id: Scalars["Int"];
  status: Scalars["String"];
  taskId: Scalars["uuid"];
};

export type Sensorflow_Task_Status_History_Aggregate = {
  __typename?: "sensorflow_task_status_history_aggregate";
  aggregate?: Maybe<Sensorflow_Task_Status_History_Aggregate_Fields>;
  nodes: Array<Sensorflow_Task_Status_History>;
};

export type Sensorflow_Task_Status_History_Aggregate_Fields = {
  __typename?: "sensorflow_task_status_history_aggregate_fields";
  avg?: Maybe<Sensorflow_Task_Status_History_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Task_Status_History_Max_Fields>;
  min?: Maybe<Sensorflow_Task_Status_History_Min_Fields>;
  stddev?: Maybe<Sensorflow_Task_Status_History_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Task_Status_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Task_Status_History_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Task_Status_History_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Task_Status_History_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Task_Status_History_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Task_Status_History_Variance_Fields>;
};

export type Sensorflow_Task_Status_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Task_Status_History_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Task_Status_History_Avg_Fields = {
  __typename?: "sensorflow_task_status_history_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Task_Status_History_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Task_Status_History_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Task_Status_History_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Task_Status_History_Bool_Exp>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  taskId?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sensorflow_Task_Status_History_Constraint {
  TaskStatusHistoryPkey = "task_status_history_pkey",
}

export type Sensorflow_Task_Status_History_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Task_Status_History_Insert_Input = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Task_Status_History_Max_Fields = {
  __typename?: "sensorflow_task_status_history_max_fields";
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Task_Status_History_Min_Fields = {
  __typename?: "sensorflow_task_status_history_min_fields";
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Task_Status_History_Mutation_Response = {
  __typename?: "sensorflow_task_status_history_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Task_Status_History>;
};

export type Sensorflow_Task_Status_History_On_Conflict = {
  constraint: Sensorflow_Task_Status_History_Constraint;
  update_columns: Array<Sensorflow_Task_Status_History_Update_Column>;
  where?: Maybe<Sensorflow_Task_Status_History_Bool_Exp>;
};

export type Sensorflow_Task_Status_History_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  taskId?: Maybe<Order_By>;
};

export type Sensorflow_Task_Status_History_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Task_Status_History_Select_Column {
  CreatedAt = "createdAt",
  Id = "id",
  Status = "status",
  TaskId = "taskId",
}

export type Sensorflow_Task_Status_History_Set_Input = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Task_Status_History_Stddev_Fields = {
  __typename?: "sensorflow_task_status_history_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Task_Status_History_Stddev_Pop_Fields = {
  __typename?: "sensorflow_task_status_history_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Task_Status_History_Stddev_Samp_Fields = {
  __typename?: "sensorflow_task_status_history_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Task_Status_History_Stream_Cursor_Input = {
  initial_value: Sensorflow_Task_Status_History_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Task_Status_History_Stream_Cursor_Value_Input = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_Task_Status_History_Sum_Fields = {
  __typename?: "sensorflow_task_status_history_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Task_Status_History_Update_Column {
  CreatedAt = "createdAt",
  Id = "id",
  Status = "status",
  TaskId = "taskId",
}

export type Sensorflow_Task_Status_History_Updates = {
  _inc?: Maybe<Sensorflow_Task_Status_History_Inc_Input>;
  _set?: Maybe<Sensorflow_Task_Status_History_Set_Input>;
  where: Sensorflow_Task_Status_History_Bool_Exp;
};

export type Sensorflow_Task_Status_History_Var_Pop_Fields = {
  __typename?: "sensorflow_task_status_history_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Task_Status_History_Var_Samp_Fields = {
  __typename?: "sensorflow_task_status_history_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Task_Status_History_Variance_Fields = {
  __typename?: "sensorflow_task_status_history_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tasks = {
  __typename?: "sensorflow_tasks";
  action_id?: Maybe<Scalars["Int"]>;
  associatedSlotId?: Maybe<Scalars["uuid"]>;
  claimerEmail?: Maybe<Scalars["String"]>;
  clickupTaskId: Scalars["String"];
  completedAt?: Maybe<Scalars["timestamptz"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  executor?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  keyEntryId?: Maybe<Scalars["Int"]>;
  parentEntityId: Scalars["String"];
  parentEntityType: Scalars["String"];
  status: Scalars["String"];
  support_ticket_id?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

export type Sensorflow_Tasks_Aggregate = {
  __typename?: "sensorflow_tasks_aggregate";
  aggregate?: Maybe<Sensorflow_Tasks_Aggregate_Fields>;
  nodes: Array<Sensorflow_Tasks>;
};

export type Sensorflow_Tasks_Aggregate_Fields = {
  __typename?: "sensorflow_tasks_aggregate_fields";
  avg?: Maybe<Sensorflow_Tasks_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Tasks_Max_Fields>;
  min?: Maybe<Sensorflow_Tasks_Min_Fields>;
  stddev?: Maybe<Sensorflow_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Tasks_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Tasks_Variance_Fields>;
};

export type Sensorflow_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Tasks_Avg_Fields = {
  __typename?: "sensorflow_tasks_avg_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
  support_ticket_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Tasks_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Tasks_Bool_Exp>>;
  action_id?: Maybe<Int_Comparison_Exp>;
  associatedSlotId?: Maybe<Uuid_Comparison_Exp>;
  claimerEmail?: Maybe<String_Comparison_Exp>;
  clickupTaskId?: Maybe<String_Comparison_Exp>;
  completedAt?: Maybe<Timestamptz_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  due_date?: Maybe<Timestamptz_Comparison_Exp>;
  executor?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  keyEntryId?: Maybe<Int_Comparison_Exp>;
  parentEntityId?: Maybe<String_Comparison_Exp>;
  parentEntityType?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  support_ticket_id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Tasks_Constraint {
  TasksClickupTaskIdKey = "tasks_clickup_task_id_key",
  TasksPkey = "tasks_pkey",
  TestTaskUnique = "test_task_unique",
}

export type Sensorflow_Tasks_Inc_Input = {
  action_id?: Maybe<Scalars["Int"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  support_ticket_id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Tasks_Insert_Input = {
  action_id?: Maybe<Scalars["Int"]>;
  associatedSlotId?: Maybe<Scalars["uuid"]>;
  claimerEmail?: Maybe<Scalars["String"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["timestamptz"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  executor?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  parentEntityId?: Maybe<Scalars["String"]>;
  parentEntityType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  support_ticket_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Tasks_Max_Fields = {
  __typename?: "sensorflow_tasks_max_fields";
  action_id?: Maybe<Scalars["Int"]>;
  associatedSlotId?: Maybe<Scalars["uuid"]>;
  claimerEmail?: Maybe<Scalars["String"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["timestamptz"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  executor?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  parentEntityId?: Maybe<Scalars["String"]>;
  parentEntityType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  support_ticket_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Tasks_Min_Fields = {
  __typename?: "sensorflow_tasks_min_fields";
  action_id?: Maybe<Scalars["Int"]>;
  associatedSlotId?: Maybe<Scalars["uuid"]>;
  claimerEmail?: Maybe<Scalars["String"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["timestamptz"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  executor?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  parentEntityId?: Maybe<Scalars["String"]>;
  parentEntityType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  support_ticket_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Tasks_Mutation_Response = {
  __typename?: "sensorflow_tasks_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Tasks>;
};

export type Sensorflow_Tasks_On_Conflict = {
  constraint: Sensorflow_Tasks_Constraint;
  update_columns: Array<Sensorflow_Tasks_Update_Column>;
  where?: Maybe<Sensorflow_Tasks_Bool_Exp>;
};

export type Sensorflow_Tasks_Order_By = {
  action_id?: Maybe<Order_By>;
  associatedSlotId?: Maybe<Order_By>;
  claimerEmail?: Maybe<Order_By>;
  clickupTaskId?: Maybe<Order_By>;
  completedAt?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  executor?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  keyEntryId?: Maybe<Order_By>;
  parentEntityId?: Maybe<Order_By>;
  parentEntityType?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  support_ticket_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

export type Sensorflow_Tasks_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sensorflow_Tasks_Select_Column {
  ActionId = "action_id",
  AssociatedSlotId = "associatedSlotId",
  ClaimerEmail = "claimerEmail",
  ClickupTaskId = "clickupTaskId",
  CompletedAt = "completedAt",
  CreatedAt = "createdAt",
  Description = "description",
  DueDate = "due_date",
  Executor = "executor",
  Id = "id",
  KeyEntryId = "keyEntryId",
  ParentEntityId = "parentEntityId",
  ParentEntityType = "parentEntityType",
  Status = "status",
  SupportTicketId = "support_ticket_id",
  Title = "title",
  Type = "type",
}

export type Sensorflow_Tasks_Set_Input = {
  action_id?: Maybe<Scalars["Int"]>;
  associatedSlotId?: Maybe<Scalars["uuid"]>;
  claimerEmail?: Maybe<Scalars["String"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["timestamptz"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  executor?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  parentEntityId?: Maybe<Scalars["String"]>;
  parentEntityType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  support_ticket_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Tasks_Stddev_Fields = {
  __typename?: "sensorflow_tasks_stddev_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
  support_ticket_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tasks_Stddev_Pop_Fields = {
  __typename?: "sensorflow_tasks_stddev_pop_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
  support_ticket_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tasks_Stddev_Samp_Fields = {
  __typename?: "sensorflow_tasks_stddev_samp_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
  support_ticket_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tasks_Stream_Cursor_Input = {
  initial_value: Sensorflow_Tasks_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Tasks_Stream_Cursor_Value_Input = {
  action_id?: Maybe<Scalars["Int"]>;
  associatedSlotId?: Maybe<Scalars["uuid"]>;
  claimerEmail?: Maybe<Scalars["String"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["timestamptz"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  executor?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  parentEntityId?: Maybe<Scalars["String"]>;
  parentEntityType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  support_ticket_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Tasks_Sum_Fields = {
  __typename?: "sensorflow_tasks_sum_fields";
  action_id?: Maybe<Scalars["Int"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  support_ticket_id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Tasks_Update_Column {
  ActionId = "action_id",
  AssociatedSlotId = "associatedSlotId",
  ClaimerEmail = "claimerEmail",
  ClickupTaskId = "clickupTaskId",
  CompletedAt = "completedAt",
  CreatedAt = "createdAt",
  Description = "description",
  DueDate = "due_date",
  Executor = "executor",
  Id = "id",
  KeyEntryId = "keyEntryId",
  ParentEntityId = "parentEntityId",
  ParentEntityType = "parentEntityType",
  Status = "status",
  SupportTicketId = "support_ticket_id",
  Title = "title",
  Type = "type",
}

export type Sensorflow_Tasks_Updates = {
  _inc?: Maybe<Sensorflow_Tasks_Inc_Input>;
  _set?: Maybe<Sensorflow_Tasks_Set_Input>;
  where: Sensorflow_Tasks_Bool_Exp;
};

export type Sensorflow_Tasks_Var_Pop_Fields = {
  __typename?: "sensorflow_tasks_var_pop_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
  support_ticket_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tasks_Var_Samp_Fields = {
  __typename?: "sensorflow_tasks_var_samp_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
  support_ticket_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tasks_Variance_Fields = {
  __typename?: "sensorflow_tasks_variance_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
  support_ticket_id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tests = {
  __typename?: "sensorflow_tests";
  description: Scalars["String"];
  isActive: Scalars["Boolean"];
  isManual: Scalars["Boolean"];
  name: Scalars["String"];
  positionFunction: Scalars["String"];
  query?: Maybe<Scalars["String"]>;
  testId: Scalars["Int"];
  test_runs: Array<TestRuns>;
  test_runs_aggregate: TestRuns_Aggregate;
  troubleshootingLink: Scalars["String"];
};

export type Sensorflow_TestsTest_RunsArgs = {
  distinct_on?: Maybe<Array<TestRuns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TestRuns_Order_By>>;
  where?: Maybe<TestRuns_Bool_Exp>;
};

export type Sensorflow_TestsTest_Runs_AggregateArgs = {
  distinct_on?: Maybe<Array<TestRuns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TestRuns_Order_By>>;
  where?: Maybe<TestRuns_Bool_Exp>;
};

export type Sensorflow_Tests_Aggregate = {
  __typename?: "sensorflow_tests_aggregate";
  aggregate?: Maybe<Sensorflow_Tests_Aggregate_Fields>;
  nodes: Array<Sensorflow_Tests>;
};

export type Sensorflow_Tests_Aggregate_Fields = {
  __typename?: "sensorflow_tests_aggregate_fields";
  avg?: Maybe<Sensorflow_Tests_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Tests_Max_Fields>;
  min?: Maybe<Sensorflow_Tests_Min_Fields>;
  stddev?: Maybe<Sensorflow_Tests_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Tests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Tests_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Tests_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Tests_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Tests_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Tests_Variance_Fields>;
};

export type Sensorflow_Tests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Tests_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Tests_Avg_Fields = {
  __typename?: "sensorflow_tests_avg_fields";
  testId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tests_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Tests_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Tests_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Tests_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  isActive?: Maybe<Boolean_Comparison_Exp>;
  isManual?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  positionFunction?: Maybe<String_Comparison_Exp>;
  query?: Maybe<String_Comparison_Exp>;
  testId?: Maybe<Int_Comparison_Exp>;
  test_runs?: Maybe<TestRuns_Bool_Exp>;
  test_runs_aggregate?: Maybe<TestRuns_Aggregate_Bool_Exp>;
  troubleshootingLink?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Tests_Constraint {
  TestsPkey = "tests_pkey",
}

export type Sensorflow_Tests_Inc_Input = {
  testId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Tests_Insert_Input = {
  description?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isManual?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  query?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["Int"]>;
  test_runs?: Maybe<TestRuns_Arr_Rel_Insert_Input>;
  troubleshootingLink?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Tests_Max_Fields = {
  __typename?: "sensorflow_tests_max_fields";
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  query?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["Int"]>;
  troubleshootingLink?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Tests_Min_Fields = {
  __typename?: "sensorflow_tests_min_fields";
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  query?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["Int"]>;
  troubleshootingLink?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Tests_Mutation_Response = {
  __typename?: "sensorflow_tests_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Tests>;
};

export type Sensorflow_Tests_Obj_Rel_Insert_Input = {
  data: Sensorflow_Tests_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Tests_On_Conflict>;
};

export type Sensorflow_Tests_On_Conflict = {
  constraint: Sensorflow_Tests_Constraint;
  update_columns: Array<Sensorflow_Tests_Update_Column>;
  where?: Maybe<Sensorflow_Tests_Bool_Exp>;
};

export type Sensorflow_Tests_Order_By = {
  description?: Maybe<Order_By>;
  isActive?: Maybe<Order_By>;
  isManual?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  positionFunction?: Maybe<Order_By>;
  query?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  test_runs_aggregate?: Maybe<TestRuns_Aggregate_Order_By>;
  troubleshootingLink?: Maybe<Order_By>;
};

export type Sensorflow_Tests_Pk_Columns_Input = {
  testId: Scalars["Int"];
};

export enum Sensorflow_Tests_Select_Column {
  Description = "description",
  IsActive = "isActive",
  IsManual = "isManual",
  Name = "name",
  PositionFunction = "positionFunction",
  Query = "query",
  TestId = "testId",
  TroubleshootingLink = "troubleshootingLink",
}

export type Sensorflow_Tests_Set_Input = {
  description?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isManual?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  query?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["Int"]>;
  troubleshootingLink?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Tests_Stddev_Fields = {
  __typename?: "sensorflow_tests_stddev_fields";
  testId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tests_Stddev_Pop_Fields = {
  __typename?: "sensorflow_tests_stddev_pop_fields";
  testId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tests_Stddev_Samp_Fields = {
  __typename?: "sensorflow_tests_stddev_samp_fields";
  testId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tests_Stream_Cursor_Input = {
  initial_value: Sensorflow_Tests_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Tests_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isManual?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  query?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["Int"]>;
  troubleshootingLink?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Tests_Sum_Fields = {
  __typename?: "sensorflow_tests_sum_fields";
  testId?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Tests_Update_Column {
  Description = "description",
  IsActive = "isActive",
  IsManual = "isManual",
  Name = "name",
  PositionFunction = "positionFunction",
  Query = "query",
  TestId = "testId",
  TroubleshootingLink = "troubleshootingLink",
}

export type Sensorflow_Tests_Updates = {
  _inc?: Maybe<Sensorflow_Tests_Inc_Input>;
  _set?: Maybe<Sensorflow_Tests_Set_Input>;
  where: Sensorflow_Tests_Bool_Exp;
};

export type Sensorflow_Tests_Var_Pop_Fields = {
  __typename?: "sensorflow_tests_var_pop_fields";
  testId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tests_Var_Samp_Fields = {
  __typename?: "sensorflow_tests_var_samp_fields";
  testId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Tests_Variance_Fields = {
  __typename?: "sensorflow_tests_variance_fields";
  testId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Timezones = {
  __typename?: "sensorflow_timezones";
  id: Scalars["Int"];
  timezone: Scalars["String"];
};

export type Sensorflow_Timezones_Aggregate = {
  __typename?: "sensorflow_timezones_aggregate";
  aggregate?: Maybe<Sensorflow_Timezones_Aggregate_Fields>;
  nodes: Array<Sensorflow_Timezones>;
};

export type Sensorflow_Timezones_Aggregate_Fields = {
  __typename?: "sensorflow_timezones_aggregate_fields";
  avg?: Maybe<Sensorflow_Timezones_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Timezones_Max_Fields>;
  min?: Maybe<Sensorflow_Timezones_Min_Fields>;
  stddev?: Maybe<Sensorflow_Timezones_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Timezones_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Timezones_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Timezones_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Timezones_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Timezones_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Timezones_Variance_Fields>;
};

export type Sensorflow_Timezones_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Timezones_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Timezones_Avg_Fields = {
  __typename?: "sensorflow_timezones_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Timezones_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Timezones_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Timezones_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Timezones_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Timezones_Constraint {
  TimezonesPkey = "timezones_pkey",
  TimezonesTimezoneUdx = "timezones_timezone_udx",
}

export type Sensorflow_Timezones_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Timezones_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  timezone?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Timezones_Max_Fields = {
  __typename?: "sensorflow_timezones_max_fields";
  id?: Maybe<Scalars["Int"]>;
  timezone?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Timezones_Min_Fields = {
  __typename?: "sensorflow_timezones_min_fields";
  id?: Maybe<Scalars["Int"]>;
  timezone?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Timezones_Mutation_Response = {
  __typename?: "sensorflow_timezones_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Timezones>;
};

export type Sensorflow_Timezones_On_Conflict = {
  constraint: Sensorflow_Timezones_Constraint;
  update_columns: Array<Sensorflow_Timezones_Update_Column>;
  where?: Maybe<Sensorflow_Timezones_Bool_Exp>;
};

export type Sensorflow_Timezones_Order_By = {
  id?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
};

export type Sensorflow_Timezones_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sensorflow_Timezones_Select_Column {
  Id = "id",
  Timezone = "timezone",
}

export type Sensorflow_Timezones_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  timezone?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Timezones_Stddev_Fields = {
  __typename?: "sensorflow_timezones_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Timezones_Stddev_Pop_Fields = {
  __typename?: "sensorflow_timezones_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Timezones_Stddev_Samp_Fields = {
  __typename?: "sensorflow_timezones_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Timezones_Stream_Cursor_Input = {
  initial_value: Sensorflow_Timezones_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Timezones_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["Int"]>;
  timezone?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Timezones_Sum_Fields = {
  __typename?: "sensorflow_timezones_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sensorflow_Timezones_Update_Column {
  Id = "id",
  Timezone = "timezone",
}

export type Sensorflow_Timezones_Updates = {
  _inc?: Maybe<Sensorflow_Timezones_Inc_Input>;
  _set?: Maybe<Sensorflow_Timezones_Set_Input>;
  where: Sensorflow_Timezones_Bool_Exp;
};

export type Sensorflow_Timezones_Var_Pop_Fields = {
  __typename?: "sensorflow_timezones_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Timezones_Var_Samp_Fields = {
  __typename?: "sensorflow_timezones_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Timezones_Variance_Fields = {
  __typename?: "sensorflow_timezones_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Updatable_Nodes = {
  __typename?: "sensorflow_updatable_nodes";
  current_firmware_version_number?: Maybe<Scalars["Int"]>;
  download_link?: Maybe<Scalars["String"]>;
  firmware_release_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_sub_type?: Maybe<Scalars["String"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Int"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
  version_number?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Updatable_Nodes_Aggregate = {
  __typename?: "sensorflow_updatable_nodes_aggregate";
  aggregate?: Maybe<Sensorflow_Updatable_Nodes_Aggregate_Fields>;
  nodes: Array<Sensorflow_Updatable_Nodes>;
};

export type Sensorflow_Updatable_Nodes_Aggregate_Fields = {
  __typename?: "sensorflow_updatable_nodes_aggregate_fields";
  avg?: Maybe<Sensorflow_Updatable_Nodes_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Updatable_Nodes_Max_Fields>;
  min?: Maybe<Sensorflow_Updatable_Nodes_Min_Fields>;
  stddev?: Maybe<Sensorflow_Updatable_Nodes_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_Updatable_Nodes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_Updatable_Nodes_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_Updatable_Nodes_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_Updatable_Nodes_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_Updatable_Nodes_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_Updatable_Nodes_Variance_Fields>;
};

export type Sensorflow_Updatable_Nodes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Updatable_Nodes_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Updatable_Nodes_Avg_Fields = {
  __typename?: "sensorflow_updatable_nodes_avg_fields";
  current_firmware_version_number?: Maybe<Scalars["Float"]>;
  firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
  version_number?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Updatable_Nodes_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Updatable_Nodes_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Updatable_Nodes_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Updatable_Nodes_Bool_Exp>>;
  current_firmware_version_number?: Maybe<Int_Comparison_Exp>;
  download_link?: Maybe<String_Comparison_Exp>;
  firmware_release_id?: Maybe<Int_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  node_mac_id?: Maybe<String_Comparison_Exp>;
  node_sub_type?: Maybe<String_Comparison_Exp>;
  ota_failed_upgrade_attempts?: Maybe<Int_Comparison_Exp>;
  target_firmware_release_id?: Maybe<Int_Comparison_Exp>;
  version_number?: Maybe<Int_Comparison_Exp>;
};

export type Sensorflow_Updatable_Nodes_Max_Fields = {
  __typename?: "sensorflow_updatable_nodes_max_fields";
  current_firmware_version_number?: Maybe<Scalars["Int"]>;
  download_link?: Maybe<Scalars["String"]>;
  firmware_release_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_sub_type?: Maybe<Scalars["String"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Int"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
  version_number?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Updatable_Nodes_Min_Fields = {
  __typename?: "sensorflow_updatable_nodes_min_fields";
  current_firmware_version_number?: Maybe<Scalars["Int"]>;
  download_link?: Maybe<Scalars["String"]>;
  firmware_release_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_sub_type?: Maybe<Scalars["String"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Int"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
  version_number?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Updatable_Nodes_Order_By = {
  current_firmware_version_number?: Maybe<Order_By>;
  download_link?: Maybe<Order_By>;
  firmware_release_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  node_sub_type?: Maybe<Order_By>;
  ota_failed_upgrade_attempts?: Maybe<Order_By>;
  target_firmware_release_id?: Maybe<Order_By>;
  version_number?: Maybe<Order_By>;
};

export enum Sensorflow_Updatable_Nodes_Select_Column {
  CurrentFirmwareVersionNumber = "current_firmware_version_number",
  DownloadLink = "download_link",
  FirmwareReleaseId = "firmware_release_id",
  LocationId = "location_id",
  NodeMacId = "node_mac_id",
  NodeSubType = "node_sub_type",
  OtaFailedUpgradeAttempts = "ota_failed_upgrade_attempts",
  TargetFirmwareReleaseId = "target_firmware_release_id",
  VersionNumber = "version_number",
}

export type Sensorflow_Updatable_Nodes_Stddev_Fields = {
  __typename?: "sensorflow_updatable_nodes_stddev_fields";
  current_firmware_version_number?: Maybe<Scalars["Float"]>;
  firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
  version_number?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Updatable_Nodes_Stddev_Pop_Fields = {
  __typename?: "sensorflow_updatable_nodes_stddev_pop_fields";
  current_firmware_version_number?: Maybe<Scalars["Float"]>;
  firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
  version_number?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Updatable_Nodes_Stddev_Samp_Fields = {
  __typename?: "sensorflow_updatable_nodes_stddev_samp_fields";
  current_firmware_version_number?: Maybe<Scalars["Float"]>;
  firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
  version_number?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Updatable_Nodes_Stream_Cursor_Input = {
  initial_value: Sensorflow_Updatable_Nodes_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Updatable_Nodes_Stream_Cursor_Value_Input = {
  current_firmware_version_number?: Maybe<Scalars["Int"]>;
  download_link?: Maybe<Scalars["String"]>;
  firmware_release_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_sub_type?: Maybe<Scalars["String"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Int"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
  version_number?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Updatable_Nodes_Sum_Fields = {
  __typename?: "sensorflow_updatable_nodes_sum_fields";
  current_firmware_version_number?: Maybe<Scalars["Int"]>;
  firmware_release_id?: Maybe<Scalars["Int"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Int"]>;
  target_firmware_release_id?: Maybe<Scalars["Int"]>;
  version_number?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_Updatable_Nodes_Var_Pop_Fields = {
  __typename?: "sensorflow_updatable_nodes_var_pop_fields";
  current_firmware_version_number?: Maybe<Scalars["Float"]>;
  firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
  version_number?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Updatable_Nodes_Var_Samp_Fields = {
  __typename?: "sensorflow_updatable_nodes_var_samp_fields";
  current_firmware_version_number?: Maybe<Scalars["Float"]>;
  firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
  version_number?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Updatable_Nodes_Variance_Fields = {
  __typename?: "sensorflow_updatable_nodes_variance_fields";
  current_firmware_version_number?: Maybe<Scalars["Float"]>;
  firmware_release_id?: Maybe<Scalars["Float"]>;
  ota_failed_upgrade_attempts?: Maybe<Scalars["Float"]>;
  target_firmware_release_id?: Maybe<Scalars["Float"]>;
  version_number?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_User_To_Permission_Mapping = {
  __typename?: "sensorflow_user_to_permission_mapping";
  is_granted: Scalars["Boolean"];
  permission_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_User_To_Permission_Mapping_Aggregate = {
  __typename?: "sensorflow_user_to_permission_mapping_aggregate";
  aggregate?: Maybe<Sensorflow_User_To_Permission_Mapping_Aggregate_Fields>;
  nodes: Array<Sensorflow_User_To_Permission_Mapping>;
};

export type Sensorflow_User_To_Permission_Mapping_Aggregate_Fields = {
  __typename?: "sensorflow_user_to_permission_mapping_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_User_To_Permission_Mapping_Max_Fields>;
  min?: Maybe<Sensorflow_User_To_Permission_Mapping_Min_Fields>;
};

export type Sensorflow_User_To_Permission_Mapping_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_User_To_Permission_Mapping_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Bool_Exp>>;
  _not?: Maybe<Sensorflow_User_To_Permission_Mapping_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Bool_Exp>>;
  is_granted?: Maybe<Boolean_Comparison_Exp>;
  permission_id?: Maybe<Uuid_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_User_To_Permission_Mapping_Insert_Input = {
  is_granted?: Maybe<Scalars["Boolean"]>;
  permission_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_User_To_Permission_Mapping_Max_Fields = {
  __typename?: "sensorflow_user_to_permission_mapping_max_fields";
  permission_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_User_To_Permission_Mapping_Min_Fields = {
  __typename?: "sensorflow_user_to_permission_mapping_min_fields";
  permission_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_User_To_Permission_Mapping_Mutation_Response = {
  __typename?: "sensorflow_user_to_permission_mapping_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_User_To_Permission_Mapping>;
};

export type Sensorflow_User_To_Permission_Mapping_Order_By = {
  is_granted?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

export enum Sensorflow_User_To_Permission_Mapping_Select_Column {
  IsGranted = "is_granted",
  PermissionId = "permission_id",
  UserId = "user_id",
}

export type Sensorflow_User_To_Permission_Mapping_Set_Input = {
  is_granted?: Maybe<Scalars["Boolean"]>;
  permission_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_User_To_Permission_Mapping_Stream_Cursor_Input = {
  initial_value: Sensorflow_User_To_Permission_Mapping_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_User_To_Permission_Mapping_Stream_Cursor_Value_Input = {
  is_granted?: Maybe<Scalars["Boolean"]>;
  permission_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_User_To_Permission_Mapping_Updates = {
  _set?: Maybe<Sensorflow_User_To_Permission_Mapping_Set_Input>;
  where: Sensorflow_User_To_Permission_Mapping_Bool_Exp;
};

export type Sensorflow_Users = {
  __typename?: "sensorflow_users";
  comfortplus_onboard_shown?: Maybe<Scalars["Boolean"]>;
  companyRole?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  email_verified: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  isContractor?: Maybe<Scalars["Boolean"]>;
  isDev?: Maybe<Scalars["Boolean"]>;
  isManager?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  last_login_timestamp?: Maybe<Scalars["String"]>;
  location_to_user_mappings: Array<Sensorflow_Location_To_User_Mapping>;
  location_to_user_mappings_aggregate: Sensorflow_Location_To_User_Mapping_Aggregate;
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  status: Scalars["String"];
  userId: Scalars["String"];
};

export type Sensorflow_UsersLocation_To_User_MappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Sensorflow_UsersLocation_To_User_Mappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Sensorflow_Users_Aggregate = {
  __typename?: "sensorflow_users_aggregate";
  aggregate?: Maybe<Sensorflow_Users_Aggregate_Fields>;
  nodes: Array<Sensorflow_Users>;
};

export type Sensorflow_Users_Aggregate_Fields = {
  __typename?: "sensorflow_users_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Users_Max_Fields>;
  min?: Maybe<Sensorflow_Users_Min_Fields>;
};

export type Sensorflow_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Users_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Users_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Users_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Users_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Users_Bool_Exp>>;
  comfortplus_onboard_shown?: Maybe<Boolean_Comparison_Exp>;
  companyRole?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email_verified?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  isContractor?: Maybe<Boolean_Comparison_Exp>;
  isDev?: Maybe<Boolean_Comparison_Exp>;
  isManager?: Maybe<Boolean_Comparison_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  last_login_timestamp?: Maybe<String_Comparison_Exp>;
  location_to_user_mappings?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
  location_to_user_mappings_aggregate?: Maybe<Sensorflow_Location_To_User_Mapping_Aggregate_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phoneNumber?: Maybe<String_Comparison_Exp>;
  role_id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

export enum Sensorflow_Users_Constraint {
  UsersPkey = "users_pkey",
}

export type Sensorflow_Users_Insert_Input = {
  comfortplus_onboard_shown?: Maybe<Scalars["Boolean"]>;
  companyRole?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email_verified?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  isContractor?: Maybe<Scalars["Boolean"]>;
  isDev?: Maybe<Scalars["Boolean"]>;
  isManager?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  last_login_timestamp?: Maybe<Scalars["String"]>;
  location_to_user_mappings?: Maybe<Sensorflow_Location_To_User_Mapping_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Users_Max_Fields = {
  __typename?: "sensorflow_users_max_fields";
  companyRole?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email_verified?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  last_login_timestamp?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Users_Min_Fields = {
  __typename?: "sensorflow_users_min_fields";
  companyRole?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email_verified?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  last_login_timestamp?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Users_Mutation_Response = {
  __typename?: "sensorflow_users_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Users>;
};

export type Sensorflow_Users_Obj_Rel_Insert_Input = {
  data: Sensorflow_Users_Insert_Input;
  on_conflict?: Maybe<Sensorflow_Users_On_Conflict>;
};

export type Sensorflow_Users_On_Conflict = {
  constraint: Sensorflow_Users_Constraint;
  update_columns: Array<Sensorflow_Users_Update_Column>;
  where?: Maybe<Sensorflow_Users_Bool_Exp>;
};

export type Sensorflow_Users_Order_By = {
  comfortplus_onboard_shown?: Maybe<Order_By>;
  companyRole?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_verified?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  isContractor?: Maybe<Order_By>;
  isDev?: Maybe<Order_By>;
  isManager?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  last_login_timestamp?: Maybe<Order_By>;
  location_to_user_mappings_aggregate?: Maybe<Sensorflow_Location_To_User_Mapping_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  phoneNumber?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

export type Sensorflow_Users_Pk_Columns_Input = {
  userId: Scalars["String"];
};

export enum Sensorflow_Users_Select_Column {
  ComfortplusOnboardShown = "comfortplus_onboard_shown",
  CompanyRole = "companyRole",
  Email = "email",
  EmailVerified = "email_verified",
  FirstName = "firstName",
  IsContractor = "isContractor",
  IsDev = "isDev",
  IsManager = "isManager",
  LastName = "lastName",
  LastLoginTimestamp = "last_login_timestamp",
  Name = "name",
  PhoneNumber = "phoneNumber",
  RoleId = "role_id",
  Status = "status",
  UserId = "userId",
}

export type Sensorflow_Users_Set_Input = {
  comfortplus_onboard_shown?: Maybe<Scalars["Boolean"]>;
  companyRole?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email_verified?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  isContractor?: Maybe<Scalars["Boolean"]>;
  isDev?: Maybe<Scalars["Boolean"]>;
  isManager?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  last_login_timestamp?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Users_Stream_Cursor_Input = {
  initial_value: Sensorflow_Users_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Users_Stream_Cursor_Value_Input = {
  comfortplus_onboard_shown?: Maybe<Scalars["Boolean"]>;
  companyRole?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  email_verified?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  isContractor?: Maybe<Scalars["Boolean"]>;
  isDev?: Maybe<Scalars["Boolean"]>;
  isManager?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  last_login_timestamp?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export enum Sensorflow_Users_Update_Column {
  ComfortplusOnboardShown = "comfortplus_onboard_shown",
  CompanyRole = "companyRole",
  Email = "email",
  EmailVerified = "email_verified",
  FirstName = "firstName",
  IsContractor = "isContractor",
  IsDev = "isDev",
  IsManager = "isManager",
  LastName = "lastName",
  LastLoginTimestamp = "last_login_timestamp",
  Name = "name",
  PhoneNumber = "phoneNumber",
  RoleId = "role_id",
  Status = "status",
  UserId = "userId",
}

export type Sensorflow_Users_Updates = {
  _set?: Maybe<Sensorflow_Users_Set_Input>;
  where: Sensorflow_Users_Bool_Exp;
};

export type Sensorflow_V_All_Latest_Node_Configurations = {
  __typename?: "sensorflow_v_all_latest_node_configurations";
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  balenaId?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  decommissionedTime?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  dot_enabled?: Maybe<Scalars["Boolean"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  forceOccupancyAutomation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["Int"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  isPrimaryNode?: Maybe<Scalars["Boolean"]>;
  keyId?: Maybe<Scalars["uuid"]>;
  lastUpdated?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["smallint"]>;
  nighttime_setpoint_enabled?: Maybe<Scalars["Boolean"]>;
  nighttime_setpoint_end?: Maybe<Scalars["String"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_start?: Maybe<Scalars["String"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstallationMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Aggregate = {
  __typename?: "sensorflow_v_all_latest_node_configurations_aggregate";
  aggregate?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_All_Latest_Node_Configurations>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Aggregate_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_aggregate_fields";
  avg?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Max_Fields>;
  min?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Variance_Fields>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Avg_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_avg_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  identifier?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Bool_Exp>>;
  acModelId?: Maybe<Uuid_Comparison_Exp>;
  acNightEnd?: Maybe<String_Comparison_Exp>;
  acNightStart?: Maybe<String_Comparison_Exp>;
  actingMode?: Maybe<String_Comparison_Exp>;
  actuationTimeoutSeconds?: Maybe<Int_Comparison_Exp>;
  automationMode?: Maybe<String_Comparison_Exp>;
  balenaId?: Maybe<String_Comparison_Exp>;
  bridgeAdaptorSetting?: Maybe<Int_Comparison_Exp>;
  creationDate?: Maybe<Timestamptz_Comparison_Exp>;
  decommissionedTime?: Maybe<Timestamptz_Comparison_Exp>;
  desiredEmonSwitchPosition?: Maybe<Int_Comparison_Exp>;
  doorSensorTimeoutMins?: Maybe<Int_Comparison_Exp>;
  dot_enabled?: Maybe<Boolean_Comparison_Exp>;
  enableDoorSensor?: Maybe<Boolean_Comparison_Exp>;
  expiredAt?: Maybe<Timestamptz_Comparison_Exp>;
  forceOccupancyAutomation?: Maybe<Boolean_Comparison_Exp>;
  freezeProtectionTemp?: Maybe<Int_Comparison_Exp>;
  gatewayMac?: Maybe<Int_Comparison_Exp>;
  heatingHideSetpoint?: Maybe<Boolean_Comparison_Exp>;
  heatingMaxTemp?: Maybe<Int_Comparison_Exp>;
  heatingMinTemp?: Maybe<Int_Comparison_Exp>;
  heatingUnoccupiedTemp?: Maybe<Int_Comparison_Exp>;
  hideSetpoint?: Maybe<Boolean_Comparison_Exp>;
  hysteresisOffDegrees?: Maybe<Float8_Comparison_Exp>;
  hysteresisOnDegrees?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  identifier?: Maybe<Int_Comparison_Exp>;
  isDeviatedFromDefaultConfig?: Maybe<Boolean_Comparison_Exp>;
  isPrimaryNode?: Maybe<Boolean_Comparison_Exp>;
  keyId?: Maybe<Uuid_Comparison_Exp>;
  lastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  maxTemp?: Maybe<Int_Comparison_Exp>;
  minTemp?: Maybe<Int_Comparison_Exp>;
  modifiedByUserId?: Maybe<String_Comparison_Exp>;
  nighttime_setpoint_delay?: Maybe<Int_Comparison_Exp>;
  nighttime_setpoint_dow?: Maybe<Smallint_Comparison_Exp>;
  nighttime_setpoint_enabled?: Maybe<Boolean_Comparison_Exp>;
  nighttime_setpoint_end?: Maybe<String_Comparison_Exp>;
  nighttime_setpoint_max_setpoint?: Maybe<Int_Comparison_Exp>;
  nighttime_setpoint_offset?: Maybe<Int_Comparison_Exp>;
  nighttime_setpoint_start?: Maybe<String_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  nodeType?: Maybe<String_Comparison_Exp>;
  occNightEnd?: Maybe<String_Comparison_Exp>;
  occNightStart?: Maybe<String_Comparison_Exp>;
  occTimeoutDayMins?: Maybe<Int_Comparison_Exp>;
  occTimeoutNightMins?: Maybe<Int_Comparison_Exp>;
  occupancySensitivity?: Maybe<Int_Comparison_Exp>;
  operationalMode?: Maybe<String_Comparison_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  recordType?: Maybe<String_Comparison_Exp>;
  softstartWaitTime?: Maybe<Int_Comparison_Exp>;
  specialInstallationMode?: Maybe<Boolean_Comparison_Exp>;
  unoccupiedTemp?: Maybe<Int_Comparison_Exp>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Max_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_max_fields";
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  balenaId?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  decommissionedTime?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["Int"]>;
  keyId?: Maybe<Scalars["uuid"]>;
  lastUpdated?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["smallint"]>;
  nighttime_setpoint_end?: Maybe<Scalars["String"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_start?: Maybe<Scalars["String"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Min_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_min_fields";
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  balenaId?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  decommissionedTime?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["Int"]>;
  keyId?: Maybe<Scalars["uuid"]>;
  lastUpdated?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["smallint"]>;
  nighttime_setpoint_end?: Maybe<Scalars["String"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_start?: Maybe<Scalars["String"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Order_By = {
  acModelId?: Maybe<Order_By>;
  acNightEnd?: Maybe<Order_By>;
  acNightStart?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  actuationTimeoutSeconds?: Maybe<Order_By>;
  automationMode?: Maybe<Order_By>;
  balenaId?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  decommissionedTime?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  dot_enabled?: Maybe<Order_By>;
  enableDoorSensor?: Maybe<Order_By>;
  expiredAt?: Maybe<Order_By>;
  forceOccupancyAutomation?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  gatewayMac?: Maybe<Order_By>;
  heatingHideSetpoint?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hideSetpoint?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identifier?: Maybe<Order_By>;
  isDeviatedFromDefaultConfig?: Maybe<Order_By>;
  isPrimaryNode?: Maybe<Order_By>;
  keyId?: Maybe<Order_By>;
  lastUpdated?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  modifiedByUserId?: Maybe<Order_By>;
  nighttime_setpoint_delay?: Maybe<Order_By>;
  nighttime_setpoint_dow?: Maybe<Order_By>;
  nighttime_setpoint_enabled?: Maybe<Order_By>;
  nighttime_setpoint_end?: Maybe<Order_By>;
  nighttime_setpoint_max_setpoint?: Maybe<Order_By>;
  nighttime_setpoint_offset?: Maybe<Order_By>;
  nighttime_setpoint_start?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  occNightEnd?: Maybe<Order_By>;
  occNightStart?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  recordType?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  specialInstallationMode?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export enum Sensorflow_V_All_Latest_Node_Configurations_Select_Column {
  AcModelId = "acModelId",
  AcNightEnd = "acNightEnd",
  AcNightStart = "acNightStart",
  ActingMode = "actingMode",
  ActuationTimeoutSeconds = "actuationTimeoutSeconds",
  AutomationMode = "automationMode",
  BalenaId = "balenaId",
  BridgeAdaptorSetting = "bridgeAdaptorSetting",
  CreationDate = "creationDate",
  DecommissionedTime = "decommissionedTime",
  DesiredEmonSwitchPosition = "desiredEmonSwitchPosition",
  DoorSensorTimeoutMins = "doorSensorTimeoutMins",
  DotEnabled = "dot_enabled",
  EnableDoorSensor = "enableDoorSensor",
  ExpiredAt = "expiredAt",
  ForceOccupancyAutomation = "forceOccupancyAutomation",
  FreezeProtectionTemp = "freezeProtectionTemp",
  GatewayMac = "gatewayMac",
  HeatingHideSetpoint = "heatingHideSetpoint",
  HeatingMaxTemp = "heatingMaxTemp",
  HeatingMinTemp = "heatingMinTemp",
  HeatingUnoccupiedTemp = "heatingUnoccupiedTemp",
  HideSetpoint = "hideSetpoint",
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
  Id = "id",
  Identifier = "identifier",
  IsDeviatedFromDefaultConfig = "isDeviatedFromDefaultConfig",
  IsPrimaryNode = "isPrimaryNode",
  KeyId = "keyId",
  LastUpdated = "lastUpdated",
  LocationId = "locationId",
  MaxTemp = "maxTemp",
  MinTemp = "minTemp",
  ModifiedByUserId = "modifiedByUserId",
  NighttimeSetpointDelay = "nighttime_setpoint_delay",
  NighttimeSetpointDow = "nighttime_setpoint_dow",
  NighttimeSetpointEnabled = "nighttime_setpoint_enabled",
  NighttimeSetpointEnd = "nighttime_setpoint_end",
  NighttimeSetpointMaxSetpoint = "nighttime_setpoint_max_setpoint",
  NighttimeSetpointOffset = "nighttime_setpoint_offset",
  NighttimeSetpointStart = "nighttime_setpoint_start",
  NodeMacId = "nodeMacId",
  NodeType = "nodeType",
  OccNightEnd = "occNightEnd",
  OccNightStart = "occNightStart",
  OccTimeoutDayMins = "occTimeoutDayMins",
  OccTimeoutNightMins = "occTimeoutNightMins",
  OccupancySensitivity = "occupancySensitivity",
  OperationalMode = "operationalMode",
  PositionId = "positionId",
  RecordType = "recordType",
  SoftstartWaitTime = "softstartWaitTime",
  SpecialInstallationMode = "specialInstallationMode",
  UnoccupiedTemp = "unoccupiedTemp",
}

export type Sensorflow_V_All_Latest_Node_Configurations_Stddev_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_stddev_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  identifier?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_stddev_pop_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  identifier?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_stddev_samp_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  identifier?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_All_Latest_Node_Configurations_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Stream_Cursor_Value_Input = {
  acModelId?: Maybe<Scalars["uuid"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  balenaId?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  decommissionedTime?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  dot_enabled?: Maybe<Scalars["Boolean"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  forceOccupancyAutomation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  identifier?: Maybe<Scalars["Int"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  isPrimaryNode?: Maybe<Scalars["Boolean"]>;
  keyId?: Maybe<Scalars["uuid"]>;
  lastUpdated?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["smallint"]>;
  nighttime_setpoint_enabled?: Maybe<Scalars["Boolean"]>;
  nighttime_setpoint_end?: Maybe<Scalars["String"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_start?: Maybe<Scalars["String"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstallationMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Sum_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_sum_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  gatewayMac?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  identifier?: Maybe<Scalars["Int"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["smallint"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Int"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Int"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Var_Pop_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_var_pop_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  identifier?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Var_Samp_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_var_samp_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  identifier?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_All_Latest_Node_Configurations_Variance_Fields = {
  __typename?: "sensorflow_v_all_latest_node_configurations_variance_fields";
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  gatewayMac?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  identifier?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_delay?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_dow?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_max_setpoint?: Maybe<Scalars["Float"]>;
  nighttime_setpoint_offset?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Combined_User_Roles = {
  __typename?: "sensorflow_v_combined_user_roles";
  display_name?: Maybe<Scalars["String"]>;
  inherited_from?: Maybe<Scalars["String"]>;
  permission_key?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Combined_User_Roles_Aggregate = {
  __typename?: "sensorflow_v_combined_user_roles_aggregate";
  aggregate?: Maybe<Sensorflow_V_Combined_User_Roles_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Combined_User_Roles>;
};

export type Sensorflow_V_Combined_User_Roles_Aggregate_Fields = {
  __typename?: "sensorflow_v_combined_user_roles_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Combined_User_Roles_Max_Fields>;
  min?: Maybe<Sensorflow_V_Combined_User_Roles_Min_Fields>;
};

export type Sensorflow_V_Combined_User_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Combined_User_Roles_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Combined_User_Roles_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Bool_Exp>>;
  display_name?: Maybe<String_Comparison_Exp>;
  inherited_from?: Maybe<String_Comparison_Exp>;
  permission_key?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_V_Combined_User_Roles_Max_Fields = {
  __typename?: "sensorflow_v_combined_user_roles_max_fields";
  display_name?: Maybe<Scalars["String"]>;
  inherited_from?: Maybe<Scalars["String"]>;
  permission_key?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Combined_User_Roles_Min_Fields = {
  __typename?: "sensorflow_v_combined_user_roles_min_fields";
  display_name?: Maybe<Scalars["String"]>;
  inherited_from?: Maybe<Scalars["String"]>;
  permission_key?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Combined_User_Roles_Order_By = {
  display_name?: Maybe<Order_By>;
  inherited_from?: Maybe<Order_By>;
  permission_key?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

export enum Sensorflow_V_Combined_User_Roles_Select_Column {
  DisplayName = "display_name",
  InheritedFrom = "inherited_from",
  PermissionKey = "permission_key",
  UserId = "user_id",
}

export type Sensorflow_V_Combined_User_Roles_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Combined_User_Roles_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Combined_User_Roles_Stream_Cursor_Value_Input = {
  display_name?: Maybe<Scalars["String"]>;
  inherited_from?: Maybe<Scalars["String"]>;
  permission_key?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details = {
  __typename?: "sensorflow_v_comfortplus_key_details";
  activation_id?: Maybe<Scalars["uuid"]>;
  checkInDate?: Maybe<Scalars["timestamptz"]>;
  checkOutDate?: Maybe<Scalars["timestamptz"]>;
  locationName?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  onboarded?: Maybe<Scalars["Boolean"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  timezone?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Aggregate = {
  __typename?: "sensorflow_v_comfortplus_key_details_aggregate";
  aggregate?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Comfortplus_Key_Details>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Aggregate_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Max_Fields>;
  min?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Variance_Fields>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Avg_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_avg_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Bool_Exp>>;
  activation_id?: Maybe<Uuid_Comparison_Exp>;
  checkInDate?: Maybe<Timestamptz_Comparison_Exp>;
  checkOutDate?: Maybe<Timestamptz_Comparison_Exp>;
  locationName?: Maybe<String_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  logoUrl?: Maybe<String_Comparison_Exp>;
  onboarded?: Maybe<Boolean_Comparison_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  positionName?: Maybe<String_Comparison_Exp>;
  reservationId?: Maybe<Bigint_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Max_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_max_fields";
  activation_id?: Maybe<Scalars["uuid"]>;
  checkInDate?: Maybe<Scalars["timestamptz"]>;
  checkOutDate?: Maybe<Scalars["timestamptz"]>;
  locationName?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  timezone?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Min_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_min_fields";
  activation_id?: Maybe<Scalars["uuid"]>;
  checkInDate?: Maybe<Scalars["timestamptz"]>;
  checkOutDate?: Maybe<Scalars["timestamptz"]>;
  locationName?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  timezone?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Order_By = {
  activation_id?: Maybe<Order_By>;
  checkInDate?: Maybe<Order_By>;
  checkOutDate?: Maybe<Order_By>;
  locationName?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  logoUrl?: Maybe<Order_By>;
  onboarded?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  positionName?: Maybe<Order_By>;
  reservationId?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

export enum Sensorflow_V_Comfortplus_Key_Details_Select_Column {
  ActivationId = "activation_id",
  CheckInDate = "checkInDate",
  CheckOutDate = "checkOutDate",
  LocationName = "locationName",
  LocationId = "location_id",
  LogoUrl = "logoUrl",
  Onboarded = "onboarded",
  PositionId = "positionId",
  PositionName = "positionName",
  ReservationId = "reservationId",
  Timezone = "timezone",
  UserId = "userId",
}

export type Sensorflow_V_Comfortplus_Key_Details_Stddev_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_stddev_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_stddev_pop_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_stddev_samp_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Comfortplus_Key_Details_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Stream_Cursor_Value_Input = {
  activation_id?: Maybe<Scalars["uuid"]>;
  checkInDate?: Maybe<Scalars["timestamptz"]>;
  checkOutDate?: Maybe<Scalars["timestamptz"]>;
  locationName?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  onboarded?: Maybe<Scalars["Boolean"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  reservationId?: Maybe<Scalars["bigint"]>;
  timezone?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Sum_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_sum_fields";
  reservationId?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Var_Pop_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_var_pop_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Var_Samp_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_var_samp_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Details_Variance_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_details_variance_fields";
  reservationId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements = {
  __typename?: "sensorflow_v_comfortplus_key_measurements";
  acMode?: Maybe<Scalars["numeric"]>;
  activationId?: Maybe<Scalars["uuid"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  keyPositionId?: Maybe<Scalars["uuid"]>;
  lastPrecoolSuccessTime?: Maybe<Scalars["timestamptz"]>;
  precoolPendingCount?: Maybe<Scalars["bigint"]>;
  roomId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
  scheduledCount?: Maybe<Scalars["bigint"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Aggregate = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_aggregate";
  aggregate?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Comfortplus_Key_Measurements>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Aggregate_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Max_Fields>;
  min?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Variance_Fields>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Avg_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_avg_fields";
  acMode?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  precoolPendingCount?: Maybe<Scalars["Float"]>;
  scheduledCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Bool_Exp>>;
  acMode?: Maybe<Numeric_Comparison_Exp>;
  activationId?: Maybe<Uuid_Comparison_Exp>;
  ambientTemperature?: Maybe<Numeric_Comparison_Exp>;
  fanSpeed?: Maybe<Numeric_Comparison_Exp>;
  keyPositionId?: Maybe<Uuid_Comparison_Exp>;
  lastPrecoolSuccessTime?: Maybe<Timestamptz_Comparison_Exp>;
  precoolPendingCount?: Maybe<Bigint_Comparison_Exp>;
  roomId?: Maybe<Uuid_Comparison_Exp>;
  roomName?: Maybe<String_Comparison_Exp>;
  scheduledCount?: Maybe<Bigint_Comparison_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Max_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_max_fields";
  acMode?: Maybe<Scalars["numeric"]>;
  activationId?: Maybe<Scalars["uuid"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  keyPositionId?: Maybe<Scalars["uuid"]>;
  lastPrecoolSuccessTime?: Maybe<Scalars["timestamptz"]>;
  precoolPendingCount?: Maybe<Scalars["bigint"]>;
  roomId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
  scheduledCount?: Maybe<Scalars["bigint"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Min_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_min_fields";
  acMode?: Maybe<Scalars["numeric"]>;
  activationId?: Maybe<Scalars["uuid"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  keyPositionId?: Maybe<Scalars["uuid"]>;
  lastPrecoolSuccessTime?: Maybe<Scalars["timestamptz"]>;
  precoolPendingCount?: Maybe<Scalars["bigint"]>;
  roomId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
  scheduledCount?: Maybe<Scalars["bigint"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Order_By = {
  acMode?: Maybe<Order_By>;
  activationId?: Maybe<Order_By>;
  ambientTemperature?: Maybe<Order_By>;
  fanSpeed?: Maybe<Order_By>;
  keyPositionId?: Maybe<Order_By>;
  lastPrecoolSuccessTime?: Maybe<Order_By>;
  precoolPendingCount?: Maybe<Order_By>;
  roomId?: Maybe<Order_By>;
  roomName?: Maybe<Order_By>;
  scheduledCount?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

export enum Sensorflow_V_Comfortplus_Key_Measurements_Select_Column {
  AcMode = "acMode",
  ActivationId = "activationId",
  AmbientTemperature = "ambientTemperature",
  FanSpeed = "fanSpeed",
  KeyPositionId = "keyPositionId",
  LastPrecoolSuccessTime = "lastPrecoolSuccessTime",
  PrecoolPendingCount = "precoolPendingCount",
  RoomId = "roomId",
  RoomName = "roomName",
  ScheduledCount = "scheduledCount",
  UserId = "userId",
}

export type Sensorflow_V_Comfortplus_Key_Measurements_Stddev_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_stddev_fields";
  acMode?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  precoolPendingCount?: Maybe<Scalars["Float"]>;
  scheduledCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_stddev_pop_fields";
  acMode?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  precoolPendingCount?: Maybe<Scalars["Float"]>;
  scheduledCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_stddev_samp_fields";
  acMode?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  precoolPendingCount?: Maybe<Scalars["Float"]>;
  scheduledCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Comfortplus_Key_Measurements_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Stream_Cursor_Value_Input = {
  acMode?: Maybe<Scalars["numeric"]>;
  activationId?: Maybe<Scalars["uuid"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  keyPositionId?: Maybe<Scalars["uuid"]>;
  lastPrecoolSuccessTime?: Maybe<Scalars["timestamptz"]>;
  precoolPendingCount?: Maybe<Scalars["bigint"]>;
  roomId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
  scheduledCount?: Maybe<Scalars["bigint"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Sum_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_sum_fields";
  acMode?: Maybe<Scalars["numeric"]>;
  ambientTemperature?: Maybe<Scalars["numeric"]>;
  fanSpeed?: Maybe<Scalars["numeric"]>;
  precoolPendingCount?: Maybe<Scalars["bigint"]>;
  scheduledCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Var_Pop_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_var_pop_fields";
  acMode?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  precoolPendingCount?: Maybe<Scalars["Float"]>;
  scheduledCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Var_Samp_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_var_samp_fields";
  acMode?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  precoolPendingCount?: Maybe<Scalars["Float"]>;
  scheduledCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Key_Measurements_Variance_Fields = {
  __typename?: "sensorflow_v_comfortplus_key_measurements_variance_fields";
  acMode?: Maybe<Scalars["Float"]>;
  ambientTemperature?: Maybe<Scalars["Float"]>;
  fanSpeed?: Maybe<Scalars["Float"]>;
  precoolPendingCount?: Maybe<Scalars["Float"]>;
  scheduledCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status = {
  __typename?: "sensorflow_v_comfortplus_precool_status";
  activation_id?: Maybe<Scalars["uuid"]>;
  failMessage?: Maybe<Scalars["String"]>;
  mode?: Maybe<Scalars["Int"]>;
  positionName?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Aggregate = {
  __typename?: "sensorflow_v_comfortplus_precool_status_aggregate";
  aggregate?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Comfortplus_Precool_Status>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Aggregate_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Max_Fields>;
  min?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Variance_Fields>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Avg_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_avg_fields";
  mode?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Bool_Exp>>;
  activation_id?: Maybe<Uuid_Comparison_Exp>;
  failMessage?: Maybe<String_Comparison_Exp>;
  mode?: Maybe<Int_Comparison_Exp>;
  positionName?: Maybe<String_Comparison_Exp>;
  startedAt?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  totalMinutes?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Max_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_max_fields";
  activation_id?: Maybe<Scalars["uuid"]>;
  failMessage?: Maybe<Scalars["String"]>;
  mode?: Maybe<Scalars["Int"]>;
  positionName?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Min_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_min_fields";
  activation_id?: Maybe<Scalars["uuid"]>;
  failMessage?: Maybe<Scalars["String"]>;
  mode?: Maybe<Scalars["Int"]>;
  positionName?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Order_By = {
  activation_id?: Maybe<Order_By>;
  failMessage?: Maybe<Order_By>;
  mode?: Maybe<Order_By>;
  positionName?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  totalMinutes?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

export enum Sensorflow_V_Comfortplus_Precool_Status_Select_Column {
  ActivationId = "activation_id",
  FailMessage = "failMessage",
  Mode = "mode",
  PositionName = "positionName",
  StartedAt = "startedAt",
  Status = "status",
  TotalMinutes = "totalMinutes",
  UserId = "user_id",
}

export type Sensorflow_V_Comfortplus_Precool_Status_Stddev_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_stddev_fields";
  mode?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_stddev_pop_fields";
  mode?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_stddev_samp_fields";
  mode?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Comfortplus_Precool_Status_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Stream_Cursor_Value_Input = {
  activation_id?: Maybe<Scalars["uuid"]>;
  failMessage?: Maybe<Scalars["String"]>;
  mode?: Maybe<Scalars["Int"]>;
  positionName?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Sum_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_sum_fields";
  mode?: Maybe<Scalars["Int"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Var_Pop_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_var_pop_fields";
  mode?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Var_Samp_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_var_samp_fields";
  mode?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Comfortplus_Precool_Status_Variance_Fields = {
  __typename?: "sensorflow_v_comfortplus_precool_status_variance_fields";
  mode?: Maybe<Scalars["Float"]>;
  totalMinutes?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Current_Position_Configurations = {
  __typename?: "sensorflow_v_current_position_configurations";
  acModelId?: Maybe<Scalars["uuid"]>;
  acModelIdentifier?: Maybe<Scalars["Int"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  forceOccupancyAutomation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstallationMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Aggregate = {
  __typename?: "sensorflow_v_current_position_configurations_aggregate";
  aggregate?: Maybe<Sensorflow_V_Current_Position_Configurations_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Current_Position_Configurations>;
};

export type Sensorflow_V_Current_Position_Configurations_Aggregate_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Current_Position_Configurations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Current_Position_Configurations_Max_Fields>;
  min?: Maybe<Sensorflow_V_Current_Position_Configurations_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Current_Position_Configurations_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Current_Position_Configurations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Current_Position_Configurations_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Current_Position_Configurations_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Current_Position_Configurations_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Current_Position_Configurations_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Current_Position_Configurations_Variance_Fields>;
};

export type Sensorflow_V_Current_Position_Configurations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Avg_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_avg_fields";
  acModelIdentifier?: Maybe<Scalars["Float"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Current_Position_Configurations_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Bool_Exp>>;
  acModelId?: Maybe<Uuid_Comparison_Exp>;
  acModelIdentifier?: Maybe<Int_Comparison_Exp>;
  acNightEnd?: Maybe<String_Comparison_Exp>;
  acNightStart?: Maybe<String_Comparison_Exp>;
  actingMode?: Maybe<String_Comparison_Exp>;
  actuationTimeoutSeconds?: Maybe<Int_Comparison_Exp>;
  automationMode?: Maybe<String_Comparison_Exp>;
  bridgeAdaptorSetting?: Maybe<Int_Comparison_Exp>;
  creationDate?: Maybe<Timestamptz_Comparison_Exp>;
  desiredEmonSwitchPosition?: Maybe<Int_Comparison_Exp>;
  doorSensorTimeoutMins?: Maybe<Int_Comparison_Exp>;
  enableDoorSensor?: Maybe<Boolean_Comparison_Exp>;
  expiredAt?: Maybe<Timestamptz_Comparison_Exp>;
  forceOccupancyAutomation?: Maybe<Boolean_Comparison_Exp>;
  freezeProtectionTemp?: Maybe<Int_Comparison_Exp>;
  heatingHideSetpoint?: Maybe<Boolean_Comparison_Exp>;
  heatingMaxTemp?: Maybe<Int_Comparison_Exp>;
  heatingMinTemp?: Maybe<Int_Comparison_Exp>;
  heatingUnoccupiedTemp?: Maybe<Int_Comparison_Exp>;
  hideSetpoint?: Maybe<Boolean_Comparison_Exp>;
  hysteresisOffDegrees?: Maybe<Float8_Comparison_Exp>;
  hysteresisOnDegrees?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isDeviatedFromDefaultConfig?: Maybe<Boolean_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  maxTemp?: Maybe<Int_Comparison_Exp>;
  minTemp?: Maybe<Int_Comparison_Exp>;
  modifiedByUserId?: Maybe<String_Comparison_Exp>;
  occNightEnd?: Maybe<String_Comparison_Exp>;
  occNightStart?: Maybe<String_Comparison_Exp>;
  occTimeoutDayMins?: Maybe<Int_Comparison_Exp>;
  occTimeoutNightMins?: Maybe<Int_Comparison_Exp>;
  occupancySensitivity?: Maybe<Int_Comparison_Exp>;
  operationalMode?: Maybe<String_Comparison_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  recordType?: Maybe<String_Comparison_Exp>;
  softstartWaitTime?: Maybe<Int_Comparison_Exp>;
  specialInstallationMode?: Maybe<Boolean_Comparison_Exp>;
  unoccupiedTemp?: Maybe<Int_Comparison_Exp>;
};

export type Sensorflow_V_Current_Position_Configurations_Max_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_max_fields";
  acModelId?: Maybe<Scalars["uuid"]>;
  acModelIdentifier?: Maybe<Scalars["Int"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Min_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_min_fields";
  acModelId?: Maybe<Scalars["uuid"]>;
  acModelIdentifier?: Maybe<Scalars["Int"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Order_By = {
  acModelId?: Maybe<Order_By>;
  acModelIdentifier?: Maybe<Order_By>;
  acNightEnd?: Maybe<Order_By>;
  acNightStart?: Maybe<Order_By>;
  actingMode?: Maybe<Order_By>;
  actuationTimeoutSeconds?: Maybe<Order_By>;
  automationMode?: Maybe<Order_By>;
  bridgeAdaptorSetting?: Maybe<Order_By>;
  creationDate?: Maybe<Order_By>;
  desiredEmonSwitchPosition?: Maybe<Order_By>;
  doorSensorTimeoutMins?: Maybe<Order_By>;
  enableDoorSensor?: Maybe<Order_By>;
  expiredAt?: Maybe<Order_By>;
  forceOccupancyAutomation?: Maybe<Order_By>;
  freezeProtectionTemp?: Maybe<Order_By>;
  heatingHideSetpoint?: Maybe<Order_By>;
  heatingMaxTemp?: Maybe<Order_By>;
  heatingMinTemp?: Maybe<Order_By>;
  heatingUnoccupiedTemp?: Maybe<Order_By>;
  hideSetpoint?: Maybe<Order_By>;
  hysteresisOffDegrees?: Maybe<Order_By>;
  hysteresisOnDegrees?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isDeviatedFromDefaultConfig?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  maxTemp?: Maybe<Order_By>;
  minTemp?: Maybe<Order_By>;
  modifiedByUserId?: Maybe<Order_By>;
  occNightEnd?: Maybe<Order_By>;
  occNightStart?: Maybe<Order_By>;
  occTimeoutDayMins?: Maybe<Order_By>;
  occTimeoutNightMins?: Maybe<Order_By>;
  occupancySensitivity?: Maybe<Order_By>;
  operationalMode?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  recordType?: Maybe<Order_By>;
  softstartWaitTime?: Maybe<Order_By>;
  specialInstallationMode?: Maybe<Order_By>;
  unoccupiedTemp?: Maybe<Order_By>;
};

export enum Sensorflow_V_Current_Position_Configurations_Select_Column {
  AcModelId = "acModelId",
  AcModelIdentifier = "acModelIdentifier",
  AcNightEnd = "acNightEnd",
  AcNightStart = "acNightStart",
  ActingMode = "actingMode",
  ActuationTimeoutSeconds = "actuationTimeoutSeconds",
  AutomationMode = "automationMode",
  BridgeAdaptorSetting = "bridgeAdaptorSetting",
  CreationDate = "creationDate",
  DesiredEmonSwitchPosition = "desiredEmonSwitchPosition",
  DoorSensorTimeoutMins = "doorSensorTimeoutMins",
  EnableDoorSensor = "enableDoorSensor",
  ExpiredAt = "expiredAt",
  ForceOccupancyAutomation = "forceOccupancyAutomation",
  FreezeProtectionTemp = "freezeProtectionTemp",
  HeatingHideSetpoint = "heatingHideSetpoint",
  HeatingMaxTemp = "heatingMaxTemp",
  HeatingMinTemp = "heatingMinTemp",
  HeatingUnoccupiedTemp = "heatingUnoccupiedTemp",
  HideSetpoint = "hideSetpoint",
  HysteresisOffDegrees = "hysteresisOffDegrees",
  HysteresisOnDegrees = "hysteresisOnDegrees",
  Id = "id",
  IsDeviatedFromDefaultConfig = "isDeviatedFromDefaultConfig",
  LocationId = "locationId",
  MaxTemp = "maxTemp",
  MinTemp = "minTemp",
  ModifiedByUserId = "modifiedByUserId",
  OccNightEnd = "occNightEnd",
  OccNightStart = "occNightStart",
  OccTimeoutDayMins = "occTimeoutDayMins",
  OccTimeoutNightMins = "occTimeoutNightMins",
  OccupancySensitivity = "occupancySensitivity",
  OperationalMode = "operationalMode",
  PositionId = "positionId",
  RecordType = "recordType",
  SoftstartWaitTime = "softstartWaitTime",
  SpecialInstallationMode = "specialInstallationMode",
  UnoccupiedTemp = "unoccupiedTemp",
}

export type Sensorflow_V_Current_Position_Configurations_Stddev_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_stddev_fields";
  acModelIdentifier?: Maybe<Scalars["Float"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_stddev_pop_fields";
  acModelIdentifier?: Maybe<Scalars["Float"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_stddev_samp_fields";
  acModelIdentifier?: Maybe<Scalars["Float"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Current_Position_Configurations_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Current_Position_Configurations_Stream_Cursor_Value_Input = {
  acModelId?: Maybe<Scalars["uuid"]>;
  acModelIdentifier?: Maybe<Scalars["Int"]>;
  acNightEnd?: Maybe<Scalars["String"]>;
  acNightStart?: Maybe<Scalars["String"]>;
  actingMode?: Maybe<Scalars["String"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  automationMode?: Maybe<Scalars["String"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  creationDate?: Maybe<Scalars["timestamptz"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  enableDoorSensor?: Maybe<Scalars["Boolean"]>;
  expiredAt?: Maybe<Scalars["timestamptz"]>;
  forceOccupancyAutomation?: Maybe<Scalars["Boolean"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingHideSetpoint?: Maybe<Scalars["Boolean"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hideSetpoint?: Maybe<Scalars["Boolean"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  modifiedByUserId?: Maybe<Scalars["String"]>;
  occNightEnd?: Maybe<Scalars["String"]>;
  occNightStart?: Maybe<Scalars["String"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  operationalMode?: Maybe<Scalars["String"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  recordType?: Maybe<Scalars["String"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  specialInstallationMode?: Maybe<Scalars["Boolean"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Sum_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_sum_fields";
  acModelIdentifier?: Maybe<Scalars["Int"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Int"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Int"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Int"]>;
  freezeProtectionTemp?: Maybe<Scalars["Int"]>;
  heatingMaxTemp?: Maybe<Scalars["Int"]>;
  heatingMinTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
  hysteresisOffDegrees?: Maybe<Scalars["float8"]>;
  hysteresisOnDegrees?: Maybe<Scalars["float8"]>;
  maxTemp?: Maybe<Scalars["Int"]>;
  minTemp?: Maybe<Scalars["Int"]>;
  occTimeoutDayMins?: Maybe<Scalars["Int"]>;
  occTimeoutNightMins?: Maybe<Scalars["Int"]>;
  occupancySensitivity?: Maybe<Scalars["Int"]>;
  softstartWaitTime?: Maybe<Scalars["Int"]>;
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Var_Pop_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_var_pop_fields";
  acModelIdentifier?: Maybe<Scalars["Float"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Var_Samp_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_var_samp_fields";
  acModelIdentifier?: Maybe<Scalars["Float"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Current_Position_Configurations_Variance_Fields = {
  __typename?: "sensorflow_v_current_position_configurations_variance_fields";
  acModelIdentifier?: Maybe<Scalars["Float"]>;
  actuationTimeoutSeconds?: Maybe<Scalars["Float"]>;
  bridgeAdaptorSetting?: Maybe<Scalars["Float"]>;
  desiredEmonSwitchPosition?: Maybe<Scalars["Float"]>;
  doorSensorTimeoutMins?: Maybe<Scalars["Float"]>;
  freezeProtectionTemp?: Maybe<Scalars["Float"]>;
  heatingMaxTemp?: Maybe<Scalars["Float"]>;
  heatingMinTemp?: Maybe<Scalars["Float"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Float"]>;
  hysteresisOffDegrees?: Maybe<Scalars["Float"]>;
  hysteresisOnDegrees?: Maybe<Scalars["Float"]>;
  maxTemp?: Maybe<Scalars["Float"]>;
  minTemp?: Maybe<Scalars["Float"]>;
  occTimeoutDayMins?: Maybe<Scalars["Float"]>;
  occTimeoutNightMins?: Maybe<Scalars["Float"]>;
  occupancySensitivity?: Maybe<Scalars["Float"]>;
  softstartWaitTime?: Maybe<Scalars["Float"]>;
  unoccupiedTemp?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_Tasks = {
  __typename?: "sensorflow_v_group_tasks";
  keysCount?: Maybe<Scalars["bigint"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  openJobEntryCount?: Maybe<Scalars["bigint"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Group_Tasks_Aggregate = {
  __typename?: "sensorflow_v_group_tasks_aggregate";
  aggregate?: Maybe<Sensorflow_V_Group_Tasks_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Group_Tasks>;
};

export type Sensorflow_V_Group_Tasks_Aggregate_Fields = {
  __typename?: "sensorflow_v_group_tasks_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Group_Tasks_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Group_Tasks_Max_Fields>;
  min?: Maybe<Sensorflow_V_Group_Tasks_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Group_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Group_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Group_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Group_Tasks_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Group_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Group_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Group_Tasks_Variance_Fields>;
};

export type Sensorflow_V_Group_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Group_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Group_Tasks_Avg_Fields = {
  __typename?: "sensorflow_v_group_tasks_avg_fields";
  keysCount?: Maybe<Scalars["Float"]>;
  openJobEntryCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Group_Tasks_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Group_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Group_Tasks_Bool_Exp>>;
  keysCount?: Maybe<Bigint_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  openJobEntryCount?: Maybe<Bigint_Comparison_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  positionName?: Maybe<String_Comparison_Exp>;
  positionType?: Maybe<String_Comparison_Exp>;
  roomsCount?: Maybe<Bigint_Comparison_Exp>;
};

export type Sensorflow_V_Group_Tasks_Max_Fields = {
  __typename?: "sensorflow_v_group_tasks_max_fields";
  keysCount?: Maybe<Scalars["bigint"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  openJobEntryCount?: Maybe<Scalars["bigint"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Group_Tasks_Min_Fields = {
  __typename?: "sensorflow_v_group_tasks_min_fields";
  keysCount?: Maybe<Scalars["bigint"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  openJobEntryCount?: Maybe<Scalars["bigint"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Group_Tasks_Order_By = {
  keysCount?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  openJobEntryCount?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  positionName?: Maybe<Order_By>;
  positionType?: Maybe<Order_By>;
  roomsCount?: Maybe<Order_By>;
};

export enum Sensorflow_V_Group_Tasks_Select_Column {
  KeysCount = "keysCount",
  LocationId = "locationId",
  OpenJobEntryCount = "openJobEntryCount",
  PositionId = "positionId",
  PositionName = "positionName",
  PositionType = "positionType",
  RoomsCount = "roomsCount",
}

export type Sensorflow_V_Group_Tasks_Stddev_Fields = {
  __typename?: "sensorflow_v_group_tasks_stddev_fields";
  keysCount?: Maybe<Scalars["Float"]>;
  openJobEntryCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_Tasks_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_group_tasks_stddev_pop_fields";
  keysCount?: Maybe<Scalars["Float"]>;
  openJobEntryCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_Tasks_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_group_tasks_stddev_samp_fields";
  keysCount?: Maybe<Scalars["Float"]>;
  openJobEntryCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_Tasks_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Group_Tasks_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Group_Tasks_Stream_Cursor_Value_Input = {
  keysCount?: Maybe<Scalars["bigint"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  openJobEntryCount?: Maybe<Scalars["bigint"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Group_Tasks_Sum_Fields = {
  __typename?: "sensorflow_v_group_tasks_sum_fields";
  keysCount?: Maybe<Scalars["bigint"]>;
  openJobEntryCount?: Maybe<Scalars["bigint"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Group_Tasks_Var_Pop_Fields = {
  __typename?: "sensorflow_v_group_tasks_var_pop_fields";
  keysCount?: Maybe<Scalars["Float"]>;
  openJobEntryCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_Tasks_Var_Samp_Fields = {
  __typename?: "sensorflow_v_group_tasks_var_samp_fields";
  keysCount?: Maybe<Scalars["Float"]>;
  openJobEntryCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_Tasks_Variance_Fields = {
  __typename?: "sensorflow_v_group_tasks_variance_fields";
  keysCount?: Maybe<Scalars["Float"]>;
  openJobEntryCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_With_Stats = {
  __typename?: "sensorflow_v_group_with_stats";
  compressorsCount?: Maybe<Scalars["bigint"]>;
  gatewaysCount?: Maybe<Scalars["bigint"]>;
  keysCount?: Maybe<Scalars["bigint"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Group_With_Stats_Aggregate = {
  __typename?: "sensorflow_v_group_with_stats_aggregate";
  aggregate?: Maybe<Sensorflow_V_Group_With_Stats_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Group_With_Stats>;
};

export type Sensorflow_V_Group_With_Stats_Aggregate_Fields = {
  __typename?: "sensorflow_v_group_with_stats_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Group_With_Stats_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Group_With_Stats_Max_Fields>;
  min?: Maybe<Sensorflow_V_Group_With_Stats_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Group_With_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Group_With_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Group_With_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Group_With_Stats_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Group_With_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Group_With_Stats_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Group_With_Stats_Variance_Fields>;
};

export type Sensorflow_V_Group_With_Stats_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Group_With_Stats_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Group_With_Stats_Avg_Fields = {
  __typename?: "sensorflow_v_group_with_stats_avg_fields";
  compressorsCount?: Maybe<Scalars["Float"]>;
  gatewaysCount?: Maybe<Scalars["Float"]>;
  keysCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_With_Stats_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Group_With_Stats_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Group_With_Stats_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Group_With_Stats_Bool_Exp>>;
  compressorsCount?: Maybe<Bigint_Comparison_Exp>;
  gatewaysCount?: Maybe<Bigint_Comparison_Exp>;
  keysCount?: Maybe<Bigint_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  positionName?: Maybe<String_Comparison_Exp>;
  positionType?: Maybe<String_Comparison_Exp>;
  roomsCount?: Maybe<Bigint_Comparison_Exp>;
};

export type Sensorflow_V_Group_With_Stats_Max_Fields = {
  __typename?: "sensorflow_v_group_with_stats_max_fields";
  compressorsCount?: Maybe<Scalars["bigint"]>;
  gatewaysCount?: Maybe<Scalars["bigint"]>;
  keysCount?: Maybe<Scalars["bigint"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Group_With_Stats_Min_Fields = {
  __typename?: "sensorflow_v_group_with_stats_min_fields";
  compressorsCount?: Maybe<Scalars["bigint"]>;
  gatewaysCount?: Maybe<Scalars["bigint"]>;
  keysCount?: Maybe<Scalars["bigint"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Group_With_Stats_Order_By = {
  compressorsCount?: Maybe<Order_By>;
  gatewaysCount?: Maybe<Order_By>;
  keysCount?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  positionId?: Maybe<Order_By>;
  positionName?: Maybe<Order_By>;
  positionType?: Maybe<Order_By>;
  roomsCount?: Maybe<Order_By>;
};

export enum Sensorflow_V_Group_With_Stats_Select_Column {
  CompressorsCount = "compressorsCount",
  GatewaysCount = "gatewaysCount",
  KeysCount = "keysCount",
  LocationId = "locationId",
  PositionId = "positionId",
  PositionName = "positionName",
  PositionType = "positionType",
  RoomsCount = "roomsCount",
}

export type Sensorflow_V_Group_With_Stats_Stddev_Fields = {
  __typename?: "sensorflow_v_group_with_stats_stddev_fields";
  compressorsCount?: Maybe<Scalars["Float"]>;
  gatewaysCount?: Maybe<Scalars["Float"]>;
  keysCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_With_Stats_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_group_with_stats_stddev_pop_fields";
  compressorsCount?: Maybe<Scalars["Float"]>;
  gatewaysCount?: Maybe<Scalars["Float"]>;
  keysCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_With_Stats_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_group_with_stats_stddev_samp_fields";
  compressorsCount?: Maybe<Scalars["Float"]>;
  gatewaysCount?: Maybe<Scalars["Float"]>;
  keysCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_With_Stats_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Group_With_Stats_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Group_With_Stats_Stream_Cursor_Value_Input = {
  compressorsCount?: Maybe<Scalars["bigint"]>;
  gatewaysCount?: Maybe<Scalars["bigint"]>;
  keysCount?: Maybe<Scalars["bigint"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  positionName?: Maybe<Scalars["String"]>;
  positionType?: Maybe<Scalars["String"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Group_With_Stats_Sum_Fields = {
  __typename?: "sensorflow_v_group_with_stats_sum_fields";
  compressorsCount?: Maybe<Scalars["bigint"]>;
  gatewaysCount?: Maybe<Scalars["bigint"]>;
  keysCount?: Maybe<Scalars["bigint"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Group_With_Stats_Var_Pop_Fields = {
  __typename?: "sensorflow_v_group_with_stats_var_pop_fields";
  compressorsCount?: Maybe<Scalars["Float"]>;
  gatewaysCount?: Maybe<Scalars["Float"]>;
  keysCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_With_Stats_Var_Samp_Fields = {
  __typename?: "sensorflow_v_group_with_stats_var_samp_fields";
  compressorsCount?: Maybe<Scalars["Float"]>;
  gatewaysCount?: Maybe<Scalars["Float"]>;
  keysCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Group_With_Stats_Variance_Fields = {
  __typename?: "sensorflow_v_group_with_stats_variance_fields";
  compressorsCount?: Maybe<Scalars["Float"]>;
  gatewaysCount?: Maybe<Scalars["Float"]>;
  keysCount?: Maybe<Scalars["Float"]>;
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal = {
  __typename?: "sensorflow_v_gw_autoset_min_signal";
  balena_id?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  min_signal_strength?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  position_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Aggregate = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_aggregate";
  aggregate?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Gw_Autoset_Min_Signal>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Aggregate_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Max_Fields>;
  min?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Variance_Fields>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Avg_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_avg_fields";
  min_signal_strength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Bool_Exp>>;
  balena_id?: Maybe<String_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  min_signal_strength?: Maybe<Numeric_Comparison_Exp>;
  position_id?: Maybe<Uuid_Comparison_Exp>;
  position_name?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Max_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_max_fields";
  balena_id?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  min_signal_strength?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  position_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Min_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_min_fields";
  balena_id?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  min_signal_strength?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  position_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Order_By = {
  balena_id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  min_signal_strength?: Maybe<Order_By>;
  position_id?: Maybe<Order_By>;
  position_name?: Maybe<Order_By>;
};

export enum Sensorflow_V_Gw_Autoset_Min_Signal_Select_Column {
  BalenaId = "balena_id",
  Key = "key",
  MinSignalStrength = "min_signal_strength",
  PositionId = "position_id",
  PositionName = "position_name",
}

export type Sensorflow_V_Gw_Autoset_Min_Signal_Stddev_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_stddev_fields";
  min_signal_strength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_stddev_pop_fields";
  min_signal_strength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_stddev_samp_fields";
  min_signal_strength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Gw_Autoset_Min_Signal_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Stream_Cursor_Value_Input = {
  balena_id?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  min_signal_strength?: Maybe<Scalars["numeric"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  position_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Sum_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_sum_fields";
  min_signal_strength?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Var_Pop_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_var_pop_fields";
  min_signal_strength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Var_Samp_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_var_samp_fields";
  min_signal_strength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Gw_Autoset_Min_Signal_Variance_Fields = {
  __typename?: "sensorflow_v_gw_autoset_min_signal_variance_fields";
  min_signal_strength?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status";
  channel?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  isMyChannel?: Maybe<Scalars["Boolean"]>;
  lastPowerReading?: Maybe<Scalars["numeric"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Aggregate = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_aggregate";
  aggregate?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Infrastructure_Emon_Power_Status>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Aggregate_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Max_Fields>;
  min?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Variance_Fields>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Avg_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_avg_fields";
  channel?: Maybe<Scalars["Float"]>;
  lastPowerReading?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Bool_Exp>>;
  channel?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isMyChannel?: Maybe<Boolean_Comparison_Exp>;
  lastPowerReading?: Maybe<Numeric_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  numPhases?: Maybe<Int_Comparison_Exp>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Max_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_max_fields";
  channel?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastPowerReading?: Maybe<Scalars["numeric"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Min_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_min_fields";
  channel?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  lastPowerReading?: Maybe<Scalars["numeric"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Order_By = {
  channel?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isMyChannel?: Maybe<Order_By>;
  lastPowerReading?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  numPhases?: Maybe<Order_By>;
};

export enum Sensorflow_V_Infrastructure_Emon_Power_Status_Select_Column {
  Channel = "channel",
  Id = "id",
  IsMyChannel = "isMyChannel",
  LastPowerReading = "lastPowerReading",
  NodeMacId = "nodeMacId",
  NumPhases = "numPhases",
}

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Stddev_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_stddev_fields";
  channel?: Maybe<Scalars["Float"]>;
  lastPowerReading?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_stddev_pop_fields";
  channel?: Maybe<Scalars["Float"]>;
  lastPowerReading?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_stddev_samp_fields";
  channel?: Maybe<Scalars["Float"]>;
  lastPowerReading?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Infrastructure_Emon_Power_Status_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Stream_Cursor_Value_Input = {
  channel?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  isMyChannel?: Maybe<Scalars["Boolean"]>;
  lastPowerReading?: Maybe<Scalars["numeric"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  numPhases?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Sum_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_sum_fields";
  channel?: Maybe<Scalars["Int"]>;
  lastPowerReading?: Maybe<Scalars["numeric"]>;
  numPhases?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Var_Pop_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_var_pop_fields";
  channel?: Maybe<Scalars["Float"]>;
  lastPowerReading?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Var_Samp_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_var_samp_fields";
  channel?: Maybe<Scalars["Float"]>;
  lastPowerReading?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructure_Emon_Power_Status_Variance_Fields = {
  __typename?: "sensorflow_v_infrastructure_emon_power_status_variance_fields";
  channel?: Maybe<Scalars["Float"]>;
  lastPowerReading?: Maybe<Scalars["Float"]>;
  numPhases?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructures = {
  __typename?: "sensorflow_v_infrastructures";
  compressorId?: Maybe<Scalars["uuid"]>;
  compressorPositionMappings: Array<Sensorflow_Compressor_Mapping>;
  compressorPositionMappings_aggregate: Sensorflow_Compressor_Mapping_Aggregate;
  coolingSystem?: Maybe<Sensorflow_Compressors>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPosition?: Maybe<Sensorflow_Positions>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  position?: Maybe<Sensorflow_Positions>;
  positionId?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_InfrastructuresCompressorPositionMappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressor_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressor_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
};

export type Sensorflow_V_InfrastructuresCompressorPositionMappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressor_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressor_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
};

export type Sensorflow_V_Infrastructures_Aggregate = {
  __typename?: "sensorflow_v_infrastructures_aggregate";
  aggregate?: Maybe<Sensorflow_V_Infrastructures_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Infrastructures>;
};

export type Sensorflow_V_Infrastructures_Aggregate_Fields = {
  __typename?: "sensorflow_v_infrastructures_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Infrastructures_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Infrastructures_Max_Fields>;
  min?: Maybe<Sensorflow_V_Infrastructures_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Infrastructures_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Infrastructures_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Infrastructures_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Infrastructures_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Infrastructures_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Infrastructures_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Infrastructures_Variance_Fields>;
};

export type Sensorflow_V_Infrastructures_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Infrastructures_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Infrastructures_Avg_Fields = {
  __typename?: "sensorflow_v_infrastructures_avg_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructures_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Infrastructures_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Infrastructures_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Infrastructures_Bool_Exp>>;
  compressorId?: Maybe<Uuid_Comparison_Exp>;
  compressorPositionMappings?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
  compressorPositionMappings_aggregate?: Maybe<Sensorflow_Compressor_Mapping_Aggregate_Bool_Exp>;
  coolingSystem?: Maybe<Sensorflow_Compressors_Bool_Exp>;
  ctMultiplier?: Maybe<Numeric_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  hvacSystemType?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  meterPosition?: Maybe<Sensorflow_Positions_Bool_Exp>;
  meterPositionId?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phaseStreamIndex?: Maybe<Int_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  positionId?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_V_Infrastructures_Insert_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  compressorPositionMappings?: Maybe<Sensorflow_Compressor_Mapping_Arr_Rel_Insert_Input>;
  coolingSystem?: Maybe<Sensorflow_Compressors_Obj_Rel_Insert_Input>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPosition?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  positionId?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Infrastructures_Max_Fields = {
  __typename?: "sensorflow_v_infrastructures_max_fields";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Infrastructures_Min_Fields = {
  __typename?: "sensorflow_v_infrastructures_min_fields";
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Infrastructures_Obj_Rel_Insert_Input = {
  data: Sensorflow_V_Infrastructures_Insert_Input;
};

export type Sensorflow_V_Infrastructures_Order_By = {
  compressorId?: Maybe<Order_By>;
  compressorPositionMappings_aggregate?: Maybe<Sensorflow_Compressor_Mapping_Aggregate_Order_By>;
  coolingSystem?: Maybe<Sensorflow_Compressors_Order_By>;
  ctMultiplier?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  hvacSystemType?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meterPosition?: Maybe<Sensorflow_Positions_Order_By>;
  meterPositionId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phaseStreamIndex?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  positionId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

export enum Sensorflow_V_Infrastructures_Select_Column {
  CompressorId = "compressorId",
  CtMultiplier = "ctMultiplier",
  DeletedAt = "deletedAt",
  HvacSystemType = "hvacSystemType",
  Id = "id",
  MeterPositionId = "meterPositionId",
  Name = "name",
  PhaseStreamIndex = "phaseStreamIndex",
  PositionId = "positionId",
  Type = "type",
}

export type Sensorflow_V_Infrastructures_Stddev_Fields = {
  __typename?: "sensorflow_v_infrastructures_stddev_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructures_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_infrastructures_stddev_pop_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructures_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_infrastructures_stddev_samp_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructures_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Infrastructures_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Infrastructures_Stream_Cursor_Value_Input = {
  compressorId?: Maybe<Scalars["uuid"]>;
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  meterPositionId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
  positionId?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Infrastructures_Sum_Fields = {
  __typename?: "sensorflow_v_infrastructures_sum_fields";
  ctMultiplier?: Maybe<Scalars["numeric"]>;
  phaseStreamIndex?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Infrastructures_Var_Pop_Fields = {
  __typename?: "sensorflow_v_infrastructures_var_pop_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructures_Var_Samp_Fields = {
  __typename?: "sensorflow_v_infrastructures_var_samp_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Infrastructures_Variance_Fields = {
  __typename?: "sensorflow_v_infrastructures_variance_fields";
  ctMultiplier?: Maybe<Scalars["Float"]>;
  phaseStreamIndex?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading = {
  __typename?: "sensorflow_v_key_emon_power_reading";
  associated_gateway?: Maybe<Scalars["String"]>;
  autoset_id?: Maybe<Scalars["uuid"]>;
  compressor_id?: Maybe<Scalars["uuid"]>;
  compressor_name?: Maybe<Scalars["String"]>;
  compressor_node_position_id?: Maybe<Scalars["uuid"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_status?: Maybe<Scalars["String"]>;
  parent_position_id?: Maybe<Scalars["uuid"]>;
  power?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Aggregate = {
  __typename?: "sensorflow_v_key_emon_power_reading_aggregate";
  aggregate?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Key_Emon_Power_Reading>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Aggregate_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Max_Fields>;
  min?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Variance_Fields>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Avg_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_avg_fields";
  power?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Bool_Exp>>;
  associated_gateway?: Maybe<String_Comparison_Exp>;
  autoset_id?: Maybe<Uuid_Comparison_Exp>;
  compressor_id?: Maybe<Uuid_Comparison_Exp>;
  compressor_name?: Maybe<String_Comparison_Exp>;
  compressor_node_position_id?: Maybe<Uuid_Comparison_Exp>;
  key_id?: Maybe<Uuid_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  node_mac_id?: Maybe<String_Comparison_Exp>;
  node_status?: Maybe<String_Comparison_Exp>;
  parent_position_id?: Maybe<Uuid_Comparison_Exp>;
  power?: Maybe<Numeric_Comparison_Exp>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Max_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_max_fields";
  associated_gateway?: Maybe<Scalars["String"]>;
  autoset_id?: Maybe<Scalars["uuid"]>;
  compressor_id?: Maybe<Scalars["uuid"]>;
  compressor_name?: Maybe<Scalars["String"]>;
  compressor_node_position_id?: Maybe<Scalars["uuid"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_status?: Maybe<Scalars["String"]>;
  parent_position_id?: Maybe<Scalars["uuid"]>;
  power?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Min_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_min_fields";
  associated_gateway?: Maybe<Scalars["String"]>;
  autoset_id?: Maybe<Scalars["uuid"]>;
  compressor_id?: Maybe<Scalars["uuid"]>;
  compressor_name?: Maybe<Scalars["String"]>;
  compressor_node_position_id?: Maybe<Scalars["uuid"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_status?: Maybe<Scalars["String"]>;
  parent_position_id?: Maybe<Scalars["uuid"]>;
  power?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Order_By = {
  associated_gateway?: Maybe<Order_By>;
  autoset_id?: Maybe<Order_By>;
  compressor_id?: Maybe<Order_By>;
  compressor_name?: Maybe<Order_By>;
  compressor_node_position_id?: Maybe<Order_By>;
  key_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  node_status?: Maybe<Order_By>;
  parent_position_id?: Maybe<Order_By>;
  power?: Maybe<Order_By>;
};

export enum Sensorflow_V_Key_Emon_Power_Reading_Select_Column {
  AssociatedGateway = "associated_gateway",
  AutosetId = "autoset_id",
  CompressorId = "compressor_id",
  CompressorName = "compressor_name",
  CompressorNodePositionId = "compressor_node_position_id",
  KeyId = "key_id",
  LocationId = "location_id",
  NodeMacId = "node_mac_id",
  NodeStatus = "node_status",
  ParentPositionId = "parent_position_id",
  Power = "power",
}

export type Sensorflow_V_Key_Emon_Power_Reading_Stddev_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_stddev_fields";
  power?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_stddev_pop_fields";
  power?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_stddev_samp_fields";
  power?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Key_Emon_Power_Reading_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Stream_Cursor_Value_Input = {
  associated_gateway?: Maybe<Scalars["String"]>;
  autoset_id?: Maybe<Scalars["uuid"]>;
  compressor_id?: Maybe<Scalars["uuid"]>;
  compressor_name?: Maybe<Scalars["String"]>;
  compressor_node_position_id?: Maybe<Scalars["uuid"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_status?: Maybe<Scalars["String"]>;
  parent_position_id?: Maybe<Scalars["uuid"]>;
  power?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Sum_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_sum_fields";
  power?: Maybe<Scalars["numeric"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Var_Pop_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_var_pop_fields";
  power?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Var_Samp_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_var_samp_fields";
  power?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Emon_Power_Reading_Variance_Fields = {
  __typename?: "sensorflow_v_key_emon_power_reading_variance_fields";
  power?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry = {
  __typename?: "sensorflow_v_key_position_latest_entry";
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["timestamptz"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyPositionId?: Maybe<Scalars["String"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  user?: Maybe<Sensorflow_Users>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Aggregate = {
  __typename?: "sensorflow_v_key_position_latest_entry_aggregate";
  aggregate?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Key_Position_Latest_Entry>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Aggregate_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Max_Fields>;
  min?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Variance_Fields>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Avg_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_avg_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  commentStatus?: Maybe<String_Comparison_Exp>;
  endedAt?: Maybe<Timestamptz_Comparison_Exp>;
  keyEntryId?: Maybe<Int_Comparison_Exp>;
  keyPositionId?: Maybe<String_Comparison_Exp>;
  positionFunction?: Maybe<String_Comparison_Exp>;
  startedAt?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Sensorflow_Users_Bool_Exp>;
  userId?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Max_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_max_fields";
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["timestamptz"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyPositionId?: Maybe<Scalars["String"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Min_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_min_fields";
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["timestamptz"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyPositionId?: Maybe<Scalars["String"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Order_By = {
  comment?: Maybe<Order_By>;
  commentStatus?: Maybe<Order_By>;
  endedAt?: Maybe<Order_By>;
  keyEntryId?: Maybe<Order_By>;
  keyPositionId?: Maybe<Order_By>;
  positionFunction?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  user?: Maybe<Sensorflow_Users_Order_By>;
  userId?: Maybe<Order_By>;
};

export enum Sensorflow_V_Key_Position_Latest_Entry_Select_Column {
  Comment = "comment",
  CommentStatus = "commentStatus",
  EndedAt = "endedAt",
  KeyEntryId = "keyEntryId",
  KeyPositionId = "keyPositionId",
  PositionFunction = "positionFunction",
  StartedAt = "startedAt",
  Status = "status",
  UserId = "userId",
}

export type Sensorflow_V_Key_Position_Latest_Entry_Stddev_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_stddev_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_stddev_pop_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_stddev_samp_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Key_Position_Latest_Entry_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["timestamptz"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyPositionId?: Maybe<Scalars["String"]>;
  positionFunction?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Sum_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_sum_fields";
  keyEntryId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Var_Pop_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_var_pop_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Var_Samp_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_var_samp_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Position_Latest_Entry_Variance_Fields = {
  __typename?: "sensorflow_v_key_position_latest_entry_variance_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Key_Task_Min_Duedate = {
  __typename?: "sensorflow_v_key_task_min_duedate";
  keyId?: Maybe<Scalars["String"]>;
  keyTaskDuedate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_V_Key_Task_Min_Duedate_Aggregate = {
  __typename?: "sensorflow_v_key_task_min_duedate_aggregate";
  aggregate?: Maybe<Sensorflow_V_Key_Task_Min_Duedate_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Key_Task_Min_Duedate>;
};

export type Sensorflow_V_Key_Task_Min_Duedate_Aggregate_Fields = {
  __typename?: "sensorflow_v_key_task_min_duedate_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Key_Task_Min_Duedate_Max_Fields>;
  min?: Maybe<Sensorflow_V_Key_Task_Min_Duedate_Min_Fields>;
};

export type Sensorflow_V_Key_Task_Min_Duedate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Key_Task_Min_Duedate_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Key_Task_Min_Duedate_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Bool_Exp>>;
  keyId?: Maybe<String_Comparison_Exp>;
  keyTaskDuedate?: Maybe<Timestamptz_Comparison_Exp>;
};

export type Sensorflow_V_Key_Task_Min_Duedate_Max_Fields = {
  __typename?: "sensorflow_v_key_task_min_duedate_max_fields";
  keyId?: Maybe<Scalars["String"]>;
  keyTaskDuedate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_V_Key_Task_Min_Duedate_Min_Fields = {
  __typename?: "sensorflow_v_key_task_min_duedate_min_fields";
  keyId?: Maybe<Scalars["String"]>;
  keyTaskDuedate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_V_Key_Task_Min_Duedate_Order_By = {
  keyId?: Maybe<Order_By>;
  keyTaskDuedate?: Maybe<Order_By>;
};

export enum Sensorflow_V_Key_Task_Min_Duedate_Select_Column {
  KeyId = "keyId",
  KeyTaskDuedate = "keyTaskDuedate",
}

export type Sensorflow_V_Key_Task_Min_Duedate_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Key_Task_Min_Duedate_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Key_Task_Min_Duedate_Stream_Cursor_Value_Input = {
  keyId?: Maybe<Scalars["String"]>;
  keyTaskDuedate?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_V_Location_Last_Update = {
  __typename?: "sensorflow_v_location_last_update";
  lastConfigUpdated?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_V_Location_Last_Update_Aggregate = {
  __typename?: "sensorflow_v_location_last_update_aggregate";
  aggregate?: Maybe<Sensorflow_V_Location_Last_Update_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Location_Last_Update>;
};

export type Sensorflow_V_Location_Last_Update_Aggregate_Fields = {
  __typename?: "sensorflow_v_location_last_update_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Location_Last_Update_Max_Fields>;
  min?: Maybe<Sensorflow_V_Location_Last_Update_Min_Fields>;
};

export type Sensorflow_V_Location_Last_Update_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Location_Last_Update_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Location_Last_Update_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Location_Last_Update_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Location_Last_Update_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Location_Last_Update_Bool_Exp>>;
  lastConfigUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
};

export type Sensorflow_V_Location_Last_Update_Max_Fields = {
  __typename?: "sensorflow_v_location_last_update_max_fields";
  lastConfigUpdated?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_V_Location_Last_Update_Min_Fields = {
  __typename?: "sensorflow_v_location_last_update_min_fields";
  lastConfigUpdated?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_V_Location_Last_Update_Order_By = {
  lastConfigUpdated?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
};

export enum Sensorflow_V_Location_Last_Update_Select_Column {
  LastConfigUpdated = "lastConfigUpdated",
  LocationId = "locationId",
}

export type Sensorflow_V_Location_Last_Update_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Location_Last_Update_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Location_Last_Update_Stream_Cursor_Value_Input = {
  lastConfigUpdated?: Maybe<Scalars["timestamptz"]>;
  locationId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_V_Node_Life_Cycle_Events = {
  __typename?: "sensorflow_v_node_life_cycle_events";
  changedAt?: Maybe<Scalars["timestamptz"]>;
  comment?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Scalars["String"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Node_Life_Cycle_Events_Aggregate = {
  __typename?: "sensorflow_v_node_life_cycle_events_aggregate";
  aggregate?: Maybe<Sensorflow_V_Node_Life_Cycle_Events_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Node_Life_Cycle_Events>;
};

export type Sensorflow_V_Node_Life_Cycle_Events_Aggregate_Fields = {
  __typename?: "sensorflow_v_node_life_cycle_events_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Node_Life_Cycle_Events_Max_Fields>;
  min?: Maybe<Sensorflow_V_Node_Life_Cycle_Events_Min_Fields>;
};

export type Sensorflow_V_Node_Life_Cycle_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Node_Life_Cycle_Events_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Node_Life_Cycle_Events_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Bool_Exp>>;
  changedAt?: Maybe<Timestamptz_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  eventType?: Maybe<String_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  nodeSubType?: Maybe<String_Comparison_Exp>;
  nodeType?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_V_Node_Life_Cycle_Events_Max_Fields = {
  __typename?: "sensorflow_v_node_life_cycle_events_max_fields";
  changedAt?: Maybe<Scalars["timestamptz"]>;
  comment?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Scalars["String"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Node_Life_Cycle_Events_Min_Fields = {
  __typename?: "sensorflow_v_node_life_cycle_events_min_fields";
  changedAt?: Maybe<Scalars["timestamptz"]>;
  comment?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Scalars["String"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Node_Life_Cycle_Events_Order_By = {
  changedAt?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  eventType?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
};

export enum Sensorflow_V_Node_Life_Cycle_Events_Select_Column {
  ChangedAt = "changedAt",
  Comment = "comment",
  EventType = "eventType",
  NodeMacId = "nodeMacId",
  NodeSubType = "nodeSubType",
  NodeType = "nodeType",
}

export type Sensorflow_V_Node_Life_Cycle_Events_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Node_Life_Cycle_Events_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Node_Life_Cycle_Events_Stream_Cursor_Value_Input = {
  changedAt?: Maybe<Scalars["timestamptz"]>;
  comment?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Scalars["String"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History = {
  __typename?: "sensorflow_v_task_with_key_status_history";
  categoryName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  keyId?: Maybe<Scalars["String"]>;
  keyName?: Maybe<Scalars["String"]>;
  keyPositionType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Aggregate = {
  __typename?: "sensorflow_v_task_with_key_status_history_aggregate";
  aggregate?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Task_With_Key_Status_History>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Aggregate_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Max_Fields>;
  min?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Variance_Fields>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Avg_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_avg_fields";
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Bool_Exp>>;
  categoryName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  keyId?: Maybe<String_Comparison_Exp>;
  keyName?: Maybe<String_Comparison_Exp>;
  keyPositionType?: Maybe<String_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  roomsCount?: Maybe<Bigint_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  taskId?: Maybe<Uuid_Comparison_Exp>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Max_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_max_fields";
  categoryName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  keyId?: Maybe<Scalars["String"]>;
  keyName?: Maybe<Scalars["String"]>;
  keyPositionType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Min_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_min_fields";
  categoryName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  keyId?: Maybe<Scalars["String"]>;
  keyName?: Maybe<Scalars["String"]>;
  keyPositionType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Order_By = {
  categoryName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  keyId?: Maybe<Order_By>;
  keyName?: Maybe<Order_By>;
  keyPositionType?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  roomsCount?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  taskId?: Maybe<Order_By>;
};

export enum Sensorflow_V_Task_With_Key_Status_History_Select_Column {
  CategoryName = "categoryName",
  CreatedAt = "createdAt",
  KeyId = "keyId",
  KeyName = "keyName",
  KeyPositionType = "keyPositionType",
  LocationId = "locationId",
  RoomsCount = "roomsCount",
  Status = "status",
  TaskId = "taskId",
}

export type Sensorflow_V_Task_With_Key_Status_History_Stddev_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_stddev_fields";
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_stddev_pop_fields";
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_stddev_samp_fields";
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Task_With_Key_Status_History_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Stream_Cursor_Value_Input = {
  categoryName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  keyId?: Maybe<Scalars["String"]>;
  keyName?: Maybe<Scalars["String"]>;
  keyPositionType?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  roomsCount?: Maybe<Scalars["bigint"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Sum_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_sum_fields";
  roomsCount?: Maybe<Scalars["bigint"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Var_Pop_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_var_pop_fields";
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Var_Samp_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_var_samp_fields";
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Task_With_Key_Status_History_Variance_Fields = {
  __typename?: "sensorflow_v_task_with_key_status_history_variance_fields";
  roomsCount?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Tasks_With_Keys = {
  __typename?: "sensorflow_v_tasks_with_keys";
  action?: Maybe<Sf_Support_Actions>;
  action_id?: Maybe<Scalars["Int"]>;
  associatedSlotId?: Maybe<Scalars["uuid"]>;
  claimerEmail?: Maybe<Scalars["String"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["timestamptz"]>;
  executor?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyId?: Maybe<Scalars["String"]>;
  keyName?: Maybe<Scalars["String"]>;
  key_position_type?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentEntityType?: Maybe<Scalars["String"]>;
  roomId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
  taskType?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Aggregate = {
  __typename?: "sensorflow_v_tasks_with_keys_aggregate";
  aggregate?: Maybe<Sensorflow_V_Tasks_With_Keys_Aggregate_Fields>;
  nodes: Array<Sensorflow_V_Tasks_With_Keys>;
};

export type Sensorflow_V_Tasks_With_Keys_Aggregate_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_aggregate_fields";
  avg?: Maybe<Sensorflow_V_Tasks_With_Keys_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_V_Tasks_With_Keys_Max_Fields>;
  min?: Maybe<Sensorflow_V_Tasks_With_Keys_Min_Fields>;
  stddev?: Maybe<Sensorflow_V_Tasks_With_Keys_Stddev_Fields>;
  stddev_pop?: Maybe<Sensorflow_V_Tasks_With_Keys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sensorflow_V_Tasks_With_Keys_Stddev_Samp_Fields>;
  sum?: Maybe<Sensorflow_V_Tasks_With_Keys_Sum_Fields>;
  var_pop?: Maybe<Sensorflow_V_Tasks_With_Keys_Var_Pop_Fields>;
  var_samp?: Maybe<Sensorflow_V_Tasks_With_Keys_Var_Samp_Fields>;
  variance?: Maybe<Sensorflow_V_Tasks_With_Keys_Variance_Fields>;
};

export type Sensorflow_V_Tasks_With_Keys_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Avg_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_avg_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Bool_Exp>>;
  _not?: Maybe<Sensorflow_V_Tasks_With_Keys_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Bool_Exp>>;
  action?: Maybe<Sf_Support_Actions_Bool_Exp>;
  action_id?: Maybe<Int_Comparison_Exp>;
  associatedSlotId?: Maybe<Uuid_Comparison_Exp>;
  claimerEmail?: Maybe<String_Comparison_Exp>;
  clickupTaskId?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  dueDate?: Maybe<Timestamptz_Comparison_Exp>;
  executor?: Maybe<String_Comparison_Exp>;
  group_id?: Maybe<Uuid_Comparison_Exp>;
  keyEntryId?: Maybe<Int_Comparison_Exp>;
  keyId?: Maybe<String_Comparison_Exp>;
  keyName?: Maybe<String_Comparison_Exp>;
  key_position_type?: Maybe<String_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  nodeSubType?: Maybe<String_Comparison_Exp>;
  nodeType?: Maybe<String_Comparison_Exp>;
  parentEntityType?: Maybe<String_Comparison_Exp>;
  roomId?: Maybe<Uuid_Comparison_Exp>;
  roomName?: Maybe<String_Comparison_Exp>;
  slotName?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  taskId?: Maybe<Uuid_Comparison_Exp>;
  taskType?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_V_Tasks_With_Keys_Max_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_max_fields";
  action_id?: Maybe<Scalars["Int"]>;
  associatedSlotId?: Maybe<Scalars["uuid"]>;
  claimerEmail?: Maybe<Scalars["String"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["timestamptz"]>;
  executor?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyId?: Maybe<Scalars["String"]>;
  keyName?: Maybe<Scalars["String"]>;
  key_position_type?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentEntityType?: Maybe<Scalars["String"]>;
  roomId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
  taskType?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Min_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_min_fields";
  action_id?: Maybe<Scalars["Int"]>;
  associatedSlotId?: Maybe<Scalars["uuid"]>;
  claimerEmail?: Maybe<Scalars["String"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["timestamptz"]>;
  executor?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyId?: Maybe<Scalars["String"]>;
  keyName?: Maybe<Scalars["String"]>;
  key_position_type?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentEntityType?: Maybe<Scalars["String"]>;
  roomId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
  taskType?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Order_By = {
  action?: Maybe<Sf_Support_Actions_Order_By>;
  action_id?: Maybe<Order_By>;
  associatedSlotId?: Maybe<Order_By>;
  claimerEmail?: Maybe<Order_By>;
  clickupTaskId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  dueDate?: Maybe<Order_By>;
  executor?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  keyEntryId?: Maybe<Order_By>;
  keyId?: Maybe<Order_By>;
  keyName?: Maybe<Order_By>;
  key_position_type?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  nodeType?: Maybe<Order_By>;
  parentEntityType?: Maybe<Order_By>;
  roomId?: Maybe<Order_By>;
  roomName?: Maybe<Order_By>;
  slotName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  taskId?: Maybe<Order_By>;
  taskType?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

export enum Sensorflow_V_Tasks_With_Keys_Select_Column {
  ActionId = "action_id",
  AssociatedSlotId = "associatedSlotId",
  ClaimerEmail = "claimerEmail",
  ClickupTaskId = "clickupTaskId",
  CreatedAt = "createdAt",
  Description = "description",
  DueDate = "dueDate",
  Executor = "executor",
  GroupId = "group_id",
  KeyEntryId = "keyEntryId",
  KeyId = "keyId",
  KeyName = "keyName",
  KeyPositionType = "key_position_type",
  LocationId = "location_id",
  NodeSubType = "nodeSubType",
  NodeType = "nodeType",
  ParentEntityType = "parentEntityType",
  RoomId = "roomId",
  RoomName = "roomName",
  SlotName = "slotName",
  Status = "status",
  TaskId = "taskId",
  TaskType = "taskType",
  Title = "title",
}

export type Sensorflow_V_Tasks_With_Keys_Stddev_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_stddev_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Stddev_Pop_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_stddev_pop_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Stddev_Samp_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_stddev_samp_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Stream_Cursor_Input = {
  initial_value: Sensorflow_V_Tasks_With_Keys_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_V_Tasks_With_Keys_Stream_Cursor_Value_Input = {
  action_id?: Maybe<Scalars["Int"]>;
  associatedSlotId?: Maybe<Scalars["uuid"]>;
  claimerEmail?: Maybe<Scalars["String"]>;
  clickupTaskId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["timestamptz"]>;
  executor?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  keyId?: Maybe<Scalars["String"]>;
  keyName?: Maybe<Scalars["String"]>;
  key_position_type?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<Scalars["String"]>;
  parentEntityType?: Maybe<Scalars["String"]>;
  roomId?: Maybe<Scalars["uuid"]>;
  roomName?: Maybe<Scalars["String"]>;
  slotName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["uuid"]>;
  taskType?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Sum_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_sum_fields";
  action_id?: Maybe<Scalars["Int"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Var_Pop_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_var_pop_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Var_Samp_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_var_samp_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_V_Tasks_With_Keys_Variance_Fields = {
  __typename?: "sensorflow_v_tasks_with_keys_variance_fields";
  action_id?: Maybe<Scalars["Float"]>;
  keyEntryId?: Maybe<Scalars["Float"]>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata = {
  __typename?: "sensorflow_warehouse_kitting_process_metadata";
  balenaId: Scalars["String"];
  gateway: Sensorflow_Gateways;
  location: Sensorflow_Locations;
  locationId: Scalars["uuid"];
  status: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate = {
  __typename?: "sensorflow_warehouse_kitting_process_metadata_aggregate";
  aggregate?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate_Fields>;
  nodes: Array<Sensorflow_Warehouse_Kitting_Process_Metadata>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate_Bool_Exp = {
  count?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate_Bool_Exp_Count>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate_Fields = {
  __typename?: "sensorflow_warehouse_kitting_process_metadata_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Max_Fields>;
  min?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Min_Fields>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Max_Order_By>;
  min?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Min_Order_By>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Arr_Rel_Insert_Input = {
  data: Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Insert_Input>;
  on_conflict?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_On_Conflict>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>>;
  balenaId?: Maybe<String_Comparison_Exp>;
  gateway?: Maybe<Sensorflow_Gateways_Bool_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sensorflow_Warehouse_Kitting_Process_Metadata_Constraint {
  UniqueBalenaId = "unique_balena_id",
  WarehouseKittingProcessMetadataPkey = "warehouse_kitting_process_metadata_pkey",
}

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Insert_Input = {
  balenaId?: Maybe<Scalars["String"]>;
  gateway?: Maybe<Sensorflow_Gateways_Obj_Rel_Insert_Input>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Max_Fields = {
  __typename?: "sensorflow_warehouse_kitting_process_metadata_max_fields";
  balenaId?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Max_Order_By = {
  balenaId?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Min_Fields = {
  __typename?: "sensorflow_warehouse_kitting_process_metadata_min_fields";
  balenaId?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Min_Order_By = {
  balenaId?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Mutation_Response = {
  __typename?: "sensorflow_warehouse_kitting_process_metadata_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sensorflow_Warehouse_Kitting_Process_Metadata>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_On_Conflict = {
  constraint: Sensorflow_Warehouse_Kitting_Process_Metadata_Constraint;
  update_columns: Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Update_Column>;
  where?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Order_By = {
  balenaId?: Maybe<Order_By>;
  gateway?: Maybe<Sensorflow_Gateways_Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Pk_Columns_Input = {
  balenaId: Scalars["String"];
};

export enum Sensorflow_Warehouse_Kitting_Process_Metadata_Select_Column {
  BalenaId = "balenaId",
  LocationId = "locationId",
  Status = "status",
  UpdatedAt = "updated_at",
}

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Set_Input = {
  balenaId?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Stream_Cursor_Input = {
  initial_value: Sensorflow_Warehouse_Kitting_Process_Metadata_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Stream_Cursor_Value_Input = {
  balenaId?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export enum Sensorflow_Warehouse_Kitting_Process_Metadata_Update_Column {
  BalenaId = "balenaId",
  LocationId = "locationId",
  Status = "status",
  UpdatedAt = "updated_at",
}

export type Sensorflow_Warehouse_Kitting_Process_Metadata_Updates = {
  _set?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Set_Input>;
  where: Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp;
};

export type Sensorflow_Warehouse_Mapping_Status_Of_Slots = {
  __typename?: "sensorflow_warehouse_mapping_status_of_slots";
  id?: Maybe<Scalars["uuid"]>;
  key?: Maybe<Scalars["String"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_sub_type?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["uuid"]>;
  room_name?: Maybe<Scalars["String"]>;
  slot_mapping_status?: Maybe<Scalars["String"]>;
  slot_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Warehouse_Mapping_Status_Of_Slots_Aggregate = {
  __typename?: "sensorflow_warehouse_mapping_status_of_slots_aggregate";
  aggregate?: Maybe<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Aggregate_Fields>;
  nodes: Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots>;
};

export type Sensorflow_Warehouse_Mapping_Status_Of_Slots_Aggregate_Fields = {
  __typename?: "sensorflow_warehouse_mapping_status_of_slots_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Max_Fields>;
  min?: Maybe<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Min_Fields>;
};

export type Sensorflow_Warehouse_Mapping_Status_Of_Slots_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sensorflow_Warehouse_Mapping_Status_Of_Slots_Bool_Exp = {
  _and?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Bool_Exp>>;
  _not?: Maybe<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Bool_Exp>;
  _or?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  key_id?: Maybe<Uuid_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  location_name?: Maybe<String_Comparison_Exp>;
  node_mac_id?: Maybe<String_Comparison_Exp>;
  node_sub_type?: Maybe<String_Comparison_Exp>;
  node_type?: Maybe<String_Comparison_Exp>;
  room_id?: Maybe<Uuid_Comparison_Exp>;
  room_name?: Maybe<String_Comparison_Exp>;
  slot_mapping_status?: Maybe<String_Comparison_Exp>;
  slot_name?: Maybe<String_Comparison_Exp>;
};

export type Sensorflow_Warehouse_Mapping_Status_Of_Slots_Max_Fields = {
  __typename?: "sensorflow_warehouse_mapping_status_of_slots_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  key?: Maybe<Scalars["String"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_sub_type?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["uuid"]>;
  room_name?: Maybe<Scalars["String"]>;
  slot_mapping_status?: Maybe<Scalars["String"]>;
  slot_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Warehouse_Mapping_Status_Of_Slots_Min_Fields = {
  __typename?: "sensorflow_warehouse_mapping_status_of_slots_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  key?: Maybe<Scalars["String"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_sub_type?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["uuid"]>;
  room_name?: Maybe<Scalars["String"]>;
  slot_mapping_status?: Maybe<Scalars["String"]>;
  slot_name?: Maybe<Scalars["String"]>;
};

export type Sensorflow_Warehouse_Mapping_Status_Of_Slots_Order_By = {
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  key_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  location_name?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  node_sub_type?: Maybe<Order_By>;
  node_type?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  room_name?: Maybe<Order_By>;
  slot_mapping_status?: Maybe<Order_By>;
  slot_name?: Maybe<Order_By>;
};

export enum Sensorflow_Warehouse_Mapping_Status_Of_Slots_Select_Column {
  Id = "id",
  Key = "key",
  KeyId = "key_id",
  LocationId = "location_id",
  LocationName = "location_name",
  NodeMacId = "node_mac_id",
  NodeSubType = "node_sub_type",
  NodeType = "node_type",
  RoomId = "room_id",
  RoomName = "room_name",
  SlotMappingStatus = "slot_mapping_status",
  SlotName = "slot_name",
}

export type Sensorflow_Warehouse_Mapping_Status_Of_Slots_Stream_Cursor_Input = {
  initial_value: Sensorflow_Warehouse_Mapping_Status_Of_Slots_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sensorflow_Warehouse_Mapping_Status_Of_Slots_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["uuid"]>;
  key?: Maybe<Scalars["String"]>;
  key_id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  node_sub_type?: Maybe<Scalars["String"]>;
  node_type?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["uuid"]>;
  room_name?: Maybe<Scalars["String"]>;
  slot_mapping_status?: Maybe<Scalars["String"]>;
  slot_name?: Maybe<Scalars["String"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build";
  buildId: Scalars["Int"];
  comment?: Maybe<Scalars["String"]>;
  deprecated: Scalars["Boolean"];
  downloadLink: Scalars["String"];
  isTest: Scalars["Boolean"];
  packageName: Scalars["String"];
  releaseType: Scalars["Int"];
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Aggregate = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_aggregate";
  aggregate?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Aggregate_Fields>;
  nodes: Array<Sf_Firmware_Dist_Pipeline_Firmware_Build>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Aggregate_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_aggregate_fields";
  avg?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Max_Fields>;
  min?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Min_Fields>;
  stddev?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Sum_Fields>;
  var_pop?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Var_Samp_Fields>;
  variance?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Variance_Fields>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Avg_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_avg_fields";
  buildId?: Maybe<Scalars["Float"]>;
  releaseType?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp = {
  _and?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp>>;
  _not?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp>;
  _or?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp>>;
  buildId?: Maybe<Int_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  deprecated?: Maybe<Boolean_Comparison_Exp>;
  downloadLink?: Maybe<String_Comparison_Exp>;
  isTest?: Maybe<Boolean_Comparison_Exp>;
  packageName?: Maybe<String_Comparison_Exp>;
  releaseType?: Maybe<Int_Comparison_Exp>;
  uploadTime?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sf_Firmware_Dist_Pipeline_Firmware_Build_Constraint {
  PipelineFirmwareBuildPkey = "pipeline_firmware_build_pkey",
}

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Inc_Input = {
  buildId?: Maybe<Scalars["Int"]>;
  releaseType?: Maybe<Scalars["Int"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Insert_Input = {
  buildId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  deprecated?: Maybe<Scalars["Boolean"]>;
  downloadLink?: Maybe<Scalars["String"]>;
  isTest?: Maybe<Scalars["Boolean"]>;
  packageName?: Maybe<Scalars["String"]>;
  releaseType?: Maybe<Scalars["Int"]>;
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Max_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_max_fields";
  buildId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  downloadLink?: Maybe<Scalars["String"]>;
  packageName?: Maybe<Scalars["String"]>;
  releaseType?: Maybe<Scalars["Int"]>;
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Min_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_min_fields";
  buildId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  downloadLink?: Maybe<Scalars["String"]>;
  packageName?: Maybe<Scalars["String"]>;
  releaseType?: Maybe<Scalars["Int"]>;
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Mutation_Response = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Firmware_Dist_Pipeline_Firmware_Build>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Obj_Rel_Insert_Input = {
  data: Sf_Firmware_Dist_Pipeline_Firmware_Build_Insert_Input;
  on_conflict?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_On_Conflict>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_On_Conflict = {
  constraint: Sf_Firmware_Dist_Pipeline_Firmware_Build_Constraint;
  update_columns: Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Update_Column>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Order_By = {
  buildId?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  deprecated?: Maybe<Order_By>;
  downloadLink?: Maybe<Order_By>;
  isTest?: Maybe<Order_By>;
  packageName?: Maybe<Order_By>;
  releaseType?: Maybe<Order_By>;
  uploadTime?: Maybe<Order_By>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Pk_Columns_Input = {
  buildId: Scalars["Int"];
};

export enum Sf_Firmware_Dist_Pipeline_Firmware_Build_Select_Column {
  BuildId = "buildId",
  Comment = "comment",
  Deprecated = "deprecated",
  DownloadLink = "downloadLink",
  IsTest = "isTest",
  PackageName = "packageName",
  ReleaseType = "releaseType",
  UploadTime = "uploadTime",
}

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Set_Input = {
  buildId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  deprecated?: Maybe<Scalars["Boolean"]>;
  downloadLink?: Maybe<Scalars["String"]>;
  isTest?: Maybe<Scalars["Boolean"]>;
  packageName?: Maybe<Scalars["String"]>;
  releaseType?: Maybe<Scalars["Int"]>;
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Stddev_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_stddev_fields";
  buildId?: Maybe<Scalars["Float"]>;
  releaseType?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Stddev_Pop_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_stddev_pop_fields";
  buildId?: Maybe<Scalars["Float"]>;
  releaseType?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Stddev_Samp_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_stddev_samp_fields";
  buildId?: Maybe<Scalars["Float"]>;
  releaseType?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Stream_Cursor_Input = {
  initial_value: Sf_Firmware_Dist_Pipeline_Firmware_Build_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Stream_Cursor_Value_Input = {
  buildId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  deprecated?: Maybe<Scalars["Boolean"]>;
  downloadLink?: Maybe<Scalars["String"]>;
  isTest?: Maybe<Scalars["Boolean"]>;
  packageName?: Maybe<Scalars["String"]>;
  releaseType?: Maybe<Scalars["Int"]>;
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Sum_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_sum_fields";
  buildId?: Maybe<Scalars["Int"]>;
  releaseType?: Maybe<Scalars["Int"]>;
};

export enum Sf_Firmware_Dist_Pipeline_Firmware_Build_Update_Column {
  BuildId = "buildId",
  Comment = "comment",
  Deprecated = "deprecated",
  DownloadLink = "downloadLink",
  IsTest = "isTest",
  PackageName = "packageName",
  ReleaseType = "releaseType",
  UploadTime = "uploadTime",
}

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Updates = {
  _inc?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Inc_Input>;
  _set?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Set_Input>;
  where: Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Var_Pop_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_var_pop_fields";
  buildId?: Maybe<Scalars["Float"]>;
  releaseType?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Var_Samp_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_var_samp_fields";
  buildId?: Maybe<Scalars["Float"]>;
  releaseType?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Build_Variance_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_build_variance_fields";
  buildId?: Maybe<Scalars["Float"]>;
  releaseType?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers";
  buildId: Scalars["Int"];
  comment?: Maybe<Scalars["String"]>;
  deprecated: Scalars["Boolean"];
  firmwareReleaseId?: Maybe<Scalars["Int"]>;
  firmwareVersionNum: Scalars["Int"];
  id: Scalars["Int"];
  nodeSubType: Scalars["String"];
  pipeline_firmware_build: Sf_Firmware_Dist_Pipeline_Firmware_Build;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Aggregate = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_aggregate";
  aggregate?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Aggregate_Fields>;
  nodes: Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Aggregate_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_aggregate_fields";
  avg?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Max_Fields>;
  min?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Min_Fields>;
  stddev?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Sum_Fields>;
  var_pop?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Var_Samp_Fields>;
  variance?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Variance_Fields>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Avg_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_avg_fields";
  buildId?: Maybe<Scalars["Float"]>;
  firmwareReleaseId?: Maybe<Scalars["Float"]>;
  firmwareVersionNum?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp = {
  _and?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp>>;
  _not?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp>;
  _or?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp>>;
  buildId?: Maybe<Int_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  deprecated?: Maybe<Boolean_Comparison_Exp>;
  firmwareReleaseId?: Maybe<Int_Comparison_Exp>;
  firmwareVersionNum?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  nodeSubType?: Maybe<String_Comparison_Exp>;
  pipeline_firmware_build?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp>;
};

export enum Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Constraint {
  PipelineFirmwareNumbersPkey = "pipeline_firmware_numbers_pkey",
}

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Inc_Input = {
  buildId?: Maybe<Scalars["Int"]>;
  firmwareReleaseId?: Maybe<Scalars["Int"]>;
  firmwareVersionNum?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Insert_Input = {
  buildId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  deprecated?: Maybe<Scalars["Boolean"]>;
  firmwareReleaseId?: Maybe<Scalars["Int"]>;
  firmwareVersionNum?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
  pipeline_firmware_build?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Obj_Rel_Insert_Input>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Max_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_max_fields";
  buildId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  firmwareReleaseId?: Maybe<Scalars["Int"]>;
  firmwareVersionNum?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Min_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_min_fields";
  buildId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  firmwareReleaseId?: Maybe<Scalars["Int"]>;
  firmwareVersionNum?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Mutation_Response = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_On_Conflict = {
  constraint: Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Constraint;
  update_columns: Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Update_Column>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Order_By = {
  buildId?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  deprecated?: Maybe<Order_By>;
  firmwareReleaseId?: Maybe<Order_By>;
  firmwareVersionNum?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  nodeSubType?: Maybe<Order_By>;
  pipeline_firmware_build?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Order_By>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Select_Column {
  BuildId = "buildId",
  Comment = "comment",
  Deprecated = "deprecated",
  FirmwareReleaseId = "firmwareReleaseId",
  FirmwareVersionNum = "firmwareVersionNum",
  Id = "id",
  NodeSubType = "nodeSubType",
}

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Set_Input = {
  buildId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  deprecated?: Maybe<Scalars["Boolean"]>;
  firmwareReleaseId?: Maybe<Scalars["Int"]>;
  firmwareVersionNum?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Stddev_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_stddev_fields";
  buildId?: Maybe<Scalars["Float"]>;
  firmwareReleaseId?: Maybe<Scalars["Float"]>;
  firmwareVersionNum?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Stddev_Pop_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_stddev_pop_fields";
  buildId?: Maybe<Scalars["Float"]>;
  firmwareReleaseId?: Maybe<Scalars["Float"]>;
  firmwareVersionNum?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Stddev_Samp_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_stddev_samp_fields";
  buildId?: Maybe<Scalars["Float"]>;
  firmwareReleaseId?: Maybe<Scalars["Float"]>;
  firmwareVersionNum?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Stream_Cursor_Input = {
  initial_value: Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Stream_Cursor_Value_Input = {
  buildId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  deprecated?: Maybe<Scalars["Boolean"]>;
  firmwareReleaseId?: Maybe<Scalars["Int"]>;
  firmwareVersionNum?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Sum_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_sum_fields";
  buildId?: Maybe<Scalars["Int"]>;
  firmwareReleaseId?: Maybe<Scalars["Int"]>;
  firmwareVersionNum?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Update_Column {
  BuildId = "buildId",
  Comment = "comment",
  Deprecated = "deprecated",
  FirmwareReleaseId = "firmwareReleaseId",
  FirmwareVersionNum = "firmwareVersionNum",
  Id = "id",
  NodeSubType = "nodeSubType",
}

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Updates = {
  _inc?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Inc_Input>;
  _set?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Set_Input>;
  where: Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Var_Pop_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_var_pop_fields";
  buildId?: Maybe<Scalars["Float"]>;
  firmwareReleaseId?: Maybe<Scalars["Float"]>;
  firmwareVersionNum?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Var_Samp_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_var_samp_fields";
  buildId?: Maybe<Scalars["Float"]>;
  firmwareReleaseId?: Maybe<Scalars["Float"]>;
  firmwareVersionNum?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Variance_Fields = {
  __typename?: "sf_firmware_dist_pipeline_firmware_numbers_variance_fields";
  buildId?: Maybe<Scalars["Float"]>;
  firmwareReleaseId?: Maybe<Scalars["Float"]>;
  firmwareVersionNum?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Pmslist = {
  __typename?: "sf_pms_pms_pmslist";
  pms_id: Scalars["Int"];
  pms_name: Scalars["String"];
};

export type Sf_Pms_Pms_Pmslist_Aggregate = {
  __typename?: "sf_pms_pms_pmslist_aggregate";
  aggregate?: Maybe<Sf_Pms_Pms_Pmslist_Aggregate_Fields>;
  nodes: Array<Sf_Pms_Pms_Pmslist>;
};

export type Sf_Pms_Pms_Pmslist_Aggregate_Fields = {
  __typename?: "sf_pms_pms_pmslist_aggregate_fields";
  avg?: Maybe<Sf_Pms_Pms_Pmslist_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Pms_Pms_Pmslist_Max_Fields>;
  min?: Maybe<Sf_Pms_Pms_Pmslist_Min_Fields>;
  stddev?: Maybe<Sf_Pms_Pms_Pmslist_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Pms_Pms_Pmslist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Pms_Pms_Pmslist_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Pms_Pms_Pmslist_Sum_Fields>;
  var_pop?: Maybe<Sf_Pms_Pms_Pmslist_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Pms_Pms_Pmslist_Var_Samp_Fields>;
  variance?: Maybe<Sf_Pms_Pms_Pmslist_Variance_Fields>;
};

export type Sf_Pms_Pms_Pmslist_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Pms_Pms_Pmslist_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Pms_Pms_Pmslist_Avg_Fields = {
  __typename?: "sf_pms_pms_pmslist_avg_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Pmslist_Bool_Exp = {
  _and?: Maybe<Array<Sf_Pms_Pms_Pmslist_Bool_Exp>>;
  _not?: Maybe<Sf_Pms_Pms_Pmslist_Bool_Exp>;
  _or?: Maybe<Array<Sf_Pms_Pms_Pmslist_Bool_Exp>>;
  pms_id?: Maybe<Int_Comparison_Exp>;
  pms_name?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Pms_Pms_Pmslist_Constraint {
  PmsPmslistPk = "pms_pmslist_pk",
}

export type Sf_Pms_Pms_Pmslist_Inc_Input = {
  pms_id?: Maybe<Scalars["Int"]>;
};

export type Sf_Pms_Pms_Pmslist_Insert_Input = {
  pms_id?: Maybe<Scalars["Int"]>;
  pms_name?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Pmslist_Max_Fields = {
  __typename?: "sf_pms_pms_pmslist_max_fields";
  pms_id?: Maybe<Scalars["Int"]>;
  pms_name?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Pmslist_Min_Fields = {
  __typename?: "sf_pms_pms_pmslist_min_fields";
  pms_id?: Maybe<Scalars["Int"]>;
  pms_name?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Pmslist_Mutation_Response = {
  __typename?: "sf_pms_pms_pmslist_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Pms_Pms_Pmslist>;
};

export type Sf_Pms_Pms_Pmslist_On_Conflict = {
  constraint: Sf_Pms_Pms_Pmslist_Constraint;
  update_columns: Array<Sf_Pms_Pms_Pmslist_Update_Column>;
  where?: Maybe<Sf_Pms_Pms_Pmslist_Bool_Exp>;
};

export type Sf_Pms_Pms_Pmslist_Order_By = {
  pms_id?: Maybe<Order_By>;
  pms_name?: Maybe<Order_By>;
};

export type Sf_Pms_Pms_Pmslist_Pk_Columns_Input = {
  pms_id: Scalars["Int"];
};

export enum Sf_Pms_Pms_Pmslist_Select_Column {
  PmsId = "pms_id",
  PmsName = "pms_name",
}

export type Sf_Pms_Pms_Pmslist_Set_Input = {
  pms_id?: Maybe<Scalars["Int"]>;
  pms_name?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Pmslist_Stddev_Fields = {
  __typename?: "sf_pms_pms_pmslist_stddev_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Pmslist_Stddev_Pop_Fields = {
  __typename?: "sf_pms_pms_pmslist_stddev_pop_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Pmslist_Stddev_Samp_Fields = {
  __typename?: "sf_pms_pms_pmslist_stddev_samp_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Pmslist_Stream_Cursor_Input = {
  initial_value: Sf_Pms_Pms_Pmslist_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Pms_Pms_Pmslist_Stream_Cursor_Value_Input = {
  pms_id?: Maybe<Scalars["Int"]>;
  pms_name?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Pmslist_Sum_Fields = {
  __typename?: "sf_pms_pms_pmslist_sum_fields";
  pms_id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Pms_Pms_Pmslist_Update_Column {
  PmsId = "pms_id",
  PmsName = "pms_name",
}

export type Sf_Pms_Pms_Pmslist_Updates = {
  _inc?: Maybe<Sf_Pms_Pms_Pmslist_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Pmslist_Set_Input>;
  where: Sf_Pms_Pms_Pmslist_Bool_Exp;
};

export type Sf_Pms_Pms_Pmslist_Var_Pop_Fields = {
  __typename?: "sf_pms_pms_pmslist_var_pop_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Pmslist_Var_Samp_Fields = {
  __typename?: "sf_pms_pms_pmslist_var_samp_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Pmslist_Variance_Fields = {
  __typename?: "sf_pms_pms_pmslist_variance_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Properties = {
  __typename?: "sf_pms_pms_properties";
  is_ready: Scalars["Boolean"];
  location: Sensorflow_Locations;
  location_id: Scalars["uuid"];
  pms_id: Scalars["Int"];
  pms_integration_creds?: Maybe<Scalars["json"]>;
  property_id: Scalars["String"];
  property_name: Scalars["String"];
  smartalloc_enable: Scalars["Boolean"];
  smartalloc_version?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_PropertiesPms_Integration_CredsArgs = {
  path?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Properties_Aggregate = {
  __typename?: "sf_pms_pms_properties_aggregate";
  aggregate?: Maybe<Sf_Pms_Pms_Properties_Aggregate_Fields>;
  nodes: Array<Sf_Pms_Pms_Properties>;
};

export type Sf_Pms_Pms_Properties_Aggregate_Fields = {
  __typename?: "sf_pms_pms_properties_aggregate_fields";
  avg?: Maybe<Sf_Pms_Pms_Properties_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Pms_Pms_Properties_Max_Fields>;
  min?: Maybe<Sf_Pms_Pms_Properties_Min_Fields>;
  stddev?: Maybe<Sf_Pms_Pms_Properties_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Pms_Pms_Properties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Pms_Pms_Properties_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Pms_Pms_Properties_Sum_Fields>;
  var_pop?: Maybe<Sf_Pms_Pms_Properties_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Pms_Pms_Properties_Var_Samp_Fields>;
  variance?: Maybe<Sf_Pms_Pms_Properties_Variance_Fields>;
};

export type Sf_Pms_Pms_Properties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Pms_Pms_Properties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Pms_Pms_Properties_Avg_Fields = {
  __typename?: "sf_pms_pms_properties_avg_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Properties_Bool_Exp = {
  _and?: Maybe<Array<Sf_Pms_Pms_Properties_Bool_Exp>>;
  _not?: Maybe<Sf_Pms_Pms_Properties_Bool_Exp>;
  _or?: Maybe<Array<Sf_Pms_Pms_Properties_Bool_Exp>>;
  is_ready?: Maybe<Boolean_Comparison_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  pms_id?: Maybe<Int_Comparison_Exp>;
  pms_integration_creds?: Maybe<Json_Comparison_Exp>;
  property_id?: Maybe<String_Comparison_Exp>;
  property_name?: Maybe<String_Comparison_Exp>;
  smartalloc_enable?: Maybe<Boolean_Comparison_Exp>;
  smartalloc_version?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Pms_Pms_Properties_Constraint {
  PmsPropertyToLocationMappingPk = "pms_property_to_location_mapping_pk",
}

export type Sf_Pms_Pms_Properties_Inc_Input = {
  pms_id?: Maybe<Scalars["Int"]>;
};

export type Sf_Pms_Pms_Properties_Insert_Input = {
  is_ready?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["uuid"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  pms_integration_creds?: Maybe<Scalars["json"]>;
  property_id?: Maybe<Scalars["String"]>;
  property_name?: Maybe<Scalars["String"]>;
  smartalloc_enable?: Maybe<Scalars["Boolean"]>;
  smartalloc_version?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Properties_Max_Fields = {
  __typename?: "sf_pms_pms_properties_max_fields";
  location_id?: Maybe<Scalars["uuid"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  property_name?: Maybe<Scalars["String"]>;
  smartalloc_version?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Properties_Min_Fields = {
  __typename?: "sf_pms_pms_properties_min_fields";
  location_id?: Maybe<Scalars["uuid"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  property_name?: Maybe<Scalars["String"]>;
  smartalloc_version?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Properties_Mutation_Response = {
  __typename?: "sf_pms_pms_properties_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Pms_Pms_Properties>;
};

export type Sf_Pms_Pms_Properties_Obj_Rel_Insert_Input = {
  data: Sf_Pms_Pms_Properties_Insert_Input;
  on_conflict?: Maybe<Sf_Pms_Pms_Properties_On_Conflict>;
};

export type Sf_Pms_Pms_Properties_On_Conflict = {
  constraint: Sf_Pms_Pms_Properties_Constraint;
  update_columns: Array<Sf_Pms_Pms_Properties_Update_Column>;
  where?: Maybe<Sf_Pms_Pms_Properties_Bool_Exp>;
};

export type Sf_Pms_Pms_Properties_Order_By = {
  is_ready?: Maybe<Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  pms_id?: Maybe<Order_By>;
  pms_integration_creds?: Maybe<Order_By>;
  property_id?: Maybe<Order_By>;
  property_name?: Maybe<Order_By>;
  smartalloc_enable?: Maybe<Order_By>;
  smartalloc_version?: Maybe<Order_By>;
};

export type Sf_Pms_Pms_Properties_Pk_Columns_Input = {
  pms_id: Scalars["Int"];
  property_id: Scalars["String"];
};

export enum Sf_Pms_Pms_Properties_Select_Column {
  IsReady = "is_ready",
  LocationId = "location_id",
  PmsId = "pms_id",
  PmsIntegrationCreds = "pms_integration_creds",
  PropertyId = "property_id",
  PropertyName = "property_name",
  SmartallocEnable = "smartalloc_enable",
  SmartallocVersion = "smartalloc_version",
}

export type Sf_Pms_Pms_Properties_Set_Input = {
  is_ready?: Maybe<Scalars["Boolean"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  pms_integration_creds?: Maybe<Scalars["json"]>;
  property_id?: Maybe<Scalars["String"]>;
  property_name?: Maybe<Scalars["String"]>;
  smartalloc_enable?: Maybe<Scalars["Boolean"]>;
  smartalloc_version?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Properties_Stddev_Fields = {
  __typename?: "sf_pms_pms_properties_stddev_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Properties_Stddev_Pop_Fields = {
  __typename?: "sf_pms_pms_properties_stddev_pop_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Properties_Stddev_Samp_Fields = {
  __typename?: "sf_pms_pms_properties_stddev_samp_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Properties_Stream_Cursor_Input = {
  initial_value: Sf_Pms_Pms_Properties_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Pms_Pms_Properties_Stream_Cursor_Value_Input = {
  is_ready?: Maybe<Scalars["Boolean"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  pms_integration_creds?: Maybe<Scalars["json"]>;
  property_id?: Maybe<Scalars["String"]>;
  property_name?: Maybe<Scalars["String"]>;
  smartalloc_enable?: Maybe<Scalars["Boolean"]>;
  smartalloc_version?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Properties_Sum_Fields = {
  __typename?: "sf_pms_pms_properties_sum_fields";
  pms_id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Pms_Pms_Properties_Update_Column {
  IsReady = "is_ready",
  LocationId = "location_id",
  PmsId = "pms_id",
  PmsIntegrationCreds = "pms_integration_creds",
  PropertyId = "property_id",
  PropertyName = "property_name",
  SmartallocEnable = "smartalloc_enable",
  SmartallocVersion = "smartalloc_version",
}

export type Sf_Pms_Pms_Properties_Updates = {
  _inc?: Maybe<Sf_Pms_Pms_Properties_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Properties_Set_Input>;
  where: Sf_Pms_Pms_Properties_Bool_Exp;
};

export type Sf_Pms_Pms_Properties_Var_Pop_Fields = {
  __typename?: "sf_pms_pms_properties_var_pop_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Properties_Var_Samp_Fields = {
  __typename?: "sf_pms_pms_properties_var_samp_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Properties_Variance_Fields = {
  __typename?: "sf_pms_pms_properties_variance_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys = {
  __typename?: "sf_pms_pms_property_eod_surveys";
  created_at: Scalars["timestamptz"];
  data: Scalars["json"];
  pms_id: Scalars["Int"];
  pms_properties_mapping?: Maybe<Sf_Pms_Pms_Properties>;
  property_id: Scalars["String"];
  status: Scalars["String"];
  survey_monkey_id: Scalars["String"];
};

export type Sf_Pms_Pms_Property_Eod_SurveysDataArgs = {
  path?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Aggregate = {
  __typename?: "sf_pms_pms_property_eod_surveys_aggregate";
  aggregate?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Aggregate_Fields>;
  nodes: Array<Sf_Pms_Pms_Property_Eod_Surveys>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Aggregate_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_aggregate_fields";
  avg?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Max_Fields>;
  min?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Min_Fields>;
  stddev?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Sum_Fields>;
  var_pop?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Var_Samp_Fields>;
  variance?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Variance_Fields>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Avg_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_avg_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp = {
  _and?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp>>;
  _not?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp>;
  _or?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Json_Comparison_Exp>;
  pms_id?: Maybe<Int_Comparison_Exp>;
  pms_properties_mapping?: Maybe<Sf_Pms_Pms_Properties_Bool_Exp>;
  property_id?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  survey_monkey_id?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Pms_Pms_Property_Eod_Surveys_Constraint {
  PmsPropertyEodSurveysPkey = "pms_property_eod_surveys_pkey",
}

export type Sf_Pms_Pms_Property_Eod_Surveys_Inc_Input = {
  pms_id?: Maybe<Scalars["Int"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  data?: Maybe<Scalars["json"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  pms_properties_mapping?: Maybe<Sf_Pms_Pms_Properties_Obj_Rel_Insert_Input>;
  property_id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  survey_monkey_id?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Max_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  survey_monkey_id?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Min_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  survey_monkey_id?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Mutation_Response = {
  __typename?: "sf_pms_pms_property_eod_surveys_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Pms_Pms_Property_Eod_Surveys>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_On_Conflict = {
  constraint: Sf_Pms_Pms_Property_Eod_Surveys_Constraint;
  update_columns: Array<Sf_Pms_Pms_Property_Eod_Surveys_Update_Column>;
  where?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  pms_id?: Maybe<Order_By>;
  pms_properties_mapping?: Maybe<Sf_Pms_Pms_Properties_Order_By>;
  property_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  survey_monkey_id?: Maybe<Order_By>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Pk_Columns_Input = {
  pms_id: Scalars["Int"];
  property_id: Scalars["String"];
  survey_monkey_id: Scalars["String"];
};

export enum Sf_Pms_Pms_Property_Eod_Surveys_Select_Column {
  CreatedAt = "created_at",
  Data = "data",
  PmsId = "pms_id",
  PropertyId = "property_id",
  Status = "status",
  SurveyMonkeyId = "survey_monkey_id",
}

export type Sf_Pms_Pms_Property_Eod_Surveys_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  data?: Maybe<Scalars["json"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  survey_monkey_id?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Stddev_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_stddev_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Stddev_Pop_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_stddev_pop_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Stddev_Samp_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_stddev_samp_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Stream_Cursor_Input = {
  initial_value: Sf_Pms_Pms_Property_Eod_Surveys_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  data?: Maybe<Scalars["json"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  survey_monkey_id?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Sum_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_sum_fields";
  pms_id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Pms_Pms_Property_Eod_Surveys_Update_Column {
  CreatedAt = "created_at",
  Data = "data",
  PmsId = "pms_id",
  PropertyId = "property_id",
  Status = "status",
  SurveyMonkeyId = "survey_monkey_id",
}

export type Sf_Pms_Pms_Property_Eod_Surveys_Updates = {
  _inc?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Set_Input>;
  where: Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Var_Pop_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_var_pop_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Var_Samp_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_var_samp_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Property_Eod_Surveys_Variance_Fields = {
  __typename?: "sf_pms_pms_property_eod_surveys_variance_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Reservations = {
  __typename?: "sf_pms_pms_reservations";
  category_id?: Maybe<Scalars["String"]>;
  check_in_date: Scalars["timestamp"];
  check_out_date: Scalars["timestamp"];
  created_date?: Maybe<Scalars["timestamp"]>;
  guest_id?: Maybe<Scalars["String"]>;
  is_fixed: Scalars["Boolean"];
  modified_date?: Maybe<Scalars["timestamp"]>;
  notes?: Maybe<Scalars["String"]>;
  number_of_rooms: Scalars["Int"];
  pms_id: Scalars["Int"];
  pms_reservation_recommended_room_mapping?: Maybe<Sf_Pms_Pms_Rooms>;
  pms_room_category?: Maybe<Sf_Pms_Pms_Room_Categories>;
  pms_room_to_reservation_mapping?: Maybe<Sf_Pms_Pms_Rooms>;
  property_id: Scalars["String"];
  recommended_room_id?: Maybe<Scalars["String"]>;
  reservation_id: Scalars["String"];
  reservation_ref_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  shared_with_reservation_id?: Maybe<Array<Scalars["String"]>>;
  status: Scalars["String"];
};

export type Sf_Pms_Pms_Reservations_Aggregate = {
  __typename?: "sf_pms_pms_reservations_aggregate";
  aggregate?: Maybe<Sf_Pms_Pms_Reservations_Aggregate_Fields>;
  nodes: Array<Sf_Pms_Pms_Reservations>;
};

export type Sf_Pms_Pms_Reservations_Aggregate_Fields = {
  __typename?: "sf_pms_pms_reservations_aggregate_fields";
  avg?: Maybe<Sf_Pms_Pms_Reservations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Pms_Pms_Reservations_Max_Fields>;
  min?: Maybe<Sf_Pms_Pms_Reservations_Min_Fields>;
  stddev?: Maybe<Sf_Pms_Pms_Reservations_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Pms_Pms_Reservations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Pms_Pms_Reservations_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Pms_Pms_Reservations_Sum_Fields>;
  var_pop?: Maybe<Sf_Pms_Pms_Reservations_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Pms_Pms_Reservations_Var_Samp_Fields>;
  variance?: Maybe<Sf_Pms_Pms_Reservations_Variance_Fields>;
};

export type Sf_Pms_Pms_Reservations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Pms_Pms_Reservations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Pms_Pms_Reservations_Avg_Fields = {
  __typename?: "sf_pms_pms_reservations_avg_fields";
  number_of_rooms?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Reservations_Bool_Exp = {
  _and?: Maybe<Array<Sf_Pms_Pms_Reservations_Bool_Exp>>;
  _not?: Maybe<Sf_Pms_Pms_Reservations_Bool_Exp>;
  _or?: Maybe<Array<Sf_Pms_Pms_Reservations_Bool_Exp>>;
  category_id?: Maybe<String_Comparison_Exp>;
  check_in_date?: Maybe<Timestamp_Comparison_Exp>;
  check_out_date?: Maybe<Timestamp_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  guest_id?: Maybe<String_Comparison_Exp>;
  is_fixed?: Maybe<Boolean_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  number_of_rooms?: Maybe<Int_Comparison_Exp>;
  pms_id?: Maybe<Int_Comparison_Exp>;
  pms_reservation_recommended_room_mapping?: Maybe<Sf_Pms_Pms_Rooms_Bool_Exp>;
  pms_room_category?: Maybe<Sf_Pms_Pms_Room_Categories_Bool_Exp>;
  pms_room_to_reservation_mapping?: Maybe<Sf_Pms_Pms_Rooms_Bool_Exp>;
  property_id?: Maybe<String_Comparison_Exp>;
  recommended_room_id?: Maybe<String_Comparison_Exp>;
  reservation_id?: Maybe<String_Comparison_Exp>;
  reservation_ref_id?: Maybe<String_Comparison_Exp>;
  room_id?: Maybe<String_Comparison_Exp>;
  shared_with_reservation_id?: Maybe<String_Array_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Pms_Pms_Reservations_Constraint {
  PmsReservationsPk = "pms_reservations_pk",
}

export type Sf_Pms_Pms_Reservations_Inc_Input = {
  number_of_rooms?: Maybe<Scalars["Int"]>;
  pms_id?: Maybe<Scalars["Int"]>;
};

export type Sf_Pms_Pms_Reservations_Insert_Input = {
  category_id?: Maybe<Scalars["String"]>;
  check_in_date?: Maybe<Scalars["timestamp"]>;
  check_out_date?: Maybe<Scalars["timestamp"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  guest_id?: Maybe<Scalars["String"]>;
  is_fixed?: Maybe<Scalars["Boolean"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  notes?: Maybe<Scalars["String"]>;
  number_of_rooms?: Maybe<Scalars["Int"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  pms_reservation_recommended_room_mapping?: Maybe<Sf_Pms_Pms_Rooms_Obj_Rel_Insert_Input>;
  pms_room_category?: Maybe<Sf_Pms_Pms_Room_Categories_Obj_Rel_Insert_Input>;
  pms_room_to_reservation_mapping?: Maybe<Sf_Pms_Pms_Rooms_Obj_Rel_Insert_Input>;
  property_id?: Maybe<Scalars["String"]>;
  recommended_room_id?: Maybe<Scalars["String"]>;
  reservation_id?: Maybe<Scalars["String"]>;
  reservation_ref_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  shared_with_reservation_id?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Reservations_Max_Fields = {
  __typename?: "sf_pms_pms_reservations_max_fields";
  category_id?: Maybe<Scalars["String"]>;
  check_in_date?: Maybe<Scalars["timestamp"]>;
  check_out_date?: Maybe<Scalars["timestamp"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  guest_id?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  notes?: Maybe<Scalars["String"]>;
  number_of_rooms?: Maybe<Scalars["Int"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  recommended_room_id?: Maybe<Scalars["String"]>;
  reservation_id?: Maybe<Scalars["String"]>;
  reservation_ref_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  shared_with_reservation_id?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Reservations_Min_Fields = {
  __typename?: "sf_pms_pms_reservations_min_fields";
  category_id?: Maybe<Scalars["String"]>;
  check_in_date?: Maybe<Scalars["timestamp"]>;
  check_out_date?: Maybe<Scalars["timestamp"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  guest_id?: Maybe<Scalars["String"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  notes?: Maybe<Scalars["String"]>;
  number_of_rooms?: Maybe<Scalars["Int"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  recommended_room_id?: Maybe<Scalars["String"]>;
  reservation_id?: Maybe<Scalars["String"]>;
  reservation_ref_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  shared_with_reservation_id?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Reservations_Mutation_Response = {
  __typename?: "sf_pms_pms_reservations_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Pms_Pms_Reservations>;
};

export type Sf_Pms_Pms_Reservations_On_Conflict = {
  constraint: Sf_Pms_Pms_Reservations_Constraint;
  update_columns: Array<Sf_Pms_Pms_Reservations_Update_Column>;
  where?: Maybe<Sf_Pms_Pms_Reservations_Bool_Exp>;
};

export type Sf_Pms_Pms_Reservations_Order_By = {
  category_id?: Maybe<Order_By>;
  check_in_date?: Maybe<Order_By>;
  check_out_date?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  guest_id?: Maybe<Order_By>;
  is_fixed?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  number_of_rooms?: Maybe<Order_By>;
  pms_id?: Maybe<Order_By>;
  pms_reservation_recommended_room_mapping?: Maybe<Sf_Pms_Pms_Rooms_Order_By>;
  pms_room_category?: Maybe<Sf_Pms_Pms_Room_Categories_Order_By>;
  pms_room_to_reservation_mapping?: Maybe<Sf_Pms_Pms_Rooms_Order_By>;
  property_id?: Maybe<Order_By>;
  recommended_room_id?: Maybe<Order_By>;
  reservation_id?: Maybe<Order_By>;
  reservation_ref_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  shared_with_reservation_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

export enum Sf_Pms_Pms_Reservations_Select_Column {
  CategoryId = "category_id",
  CheckInDate = "check_in_date",
  CheckOutDate = "check_out_date",
  CreatedDate = "created_date",
  GuestId = "guest_id",
  IsFixed = "is_fixed",
  ModifiedDate = "modified_date",
  Notes = "notes",
  NumberOfRooms = "number_of_rooms",
  PmsId = "pms_id",
  PropertyId = "property_id",
  RecommendedRoomId = "recommended_room_id",
  ReservationId = "reservation_id",
  ReservationRefId = "reservation_ref_id",
  RoomId = "room_id",
  SharedWithReservationId = "shared_with_reservation_id",
  Status = "status",
}

export type Sf_Pms_Pms_Reservations_Set_Input = {
  category_id?: Maybe<Scalars["String"]>;
  check_in_date?: Maybe<Scalars["timestamp"]>;
  check_out_date?: Maybe<Scalars["timestamp"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  guest_id?: Maybe<Scalars["String"]>;
  is_fixed?: Maybe<Scalars["Boolean"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  notes?: Maybe<Scalars["String"]>;
  number_of_rooms?: Maybe<Scalars["Int"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  recommended_room_id?: Maybe<Scalars["String"]>;
  reservation_id?: Maybe<Scalars["String"]>;
  reservation_ref_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  shared_with_reservation_id?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Reservations_Stddev_Fields = {
  __typename?: "sf_pms_pms_reservations_stddev_fields";
  number_of_rooms?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Reservations_Stddev_Pop_Fields = {
  __typename?: "sf_pms_pms_reservations_stddev_pop_fields";
  number_of_rooms?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Reservations_Stddev_Samp_Fields = {
  __typename?: "sf_pms_pms_reservations_stddev_samp_fields";
  number_of_rooms?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Reservations_Stream_Cursor_Input = {
  initial_value: Sf_Pms_Pms_Reservations_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Pms_Pms_Reservations_Stream_Cursor_Value_Input = {
  category_id?: Maybe<Scalars["String"]>;
  check_in_date?: Maybe<Scalars["timestamp"]>;
  check_out_date?: Maybe<Scalars["timestamp"]>;
  created_date?: Maybe<Scalars["timestamp"]>;
  guest_id?: Maybe<Scalars["String"]>;
  is_fixed?: Maybe<Scalars["Boolean"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  notes?: Maybe<Scalars["String"]>;
  number_of_rooms?: Maybe<Scalars["Int"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  recommended_room_id?: Maybe<Scalars["String"]>;
  reservation_id?: Maybe<Scalars["String"]>;
  reservation_ref_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  shared_with_reservation_id?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Reservations_Sum_Fields = {
  __typename?: "sf_pms_pms_reservations_sum_fields";
  number_of_rooms?: Maybe<Scalars["Int"]>;
  pms_id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Pms_Pms_Reservations_Update_Column {
  CategoryId = "category_id",
  CheckInDate = "check_in_date",
  CheckOutDate = "check_out_date",
  CreatedDate = "created_date",
  GuestId = "guest_id",
  IsFixed = "is_fixed",
  ModifiedDate = "modified_date",
  Notes = "notes",
  NumberOfRooms = "number_of_rooms",
  PmsId = "pms_id",
  PropertyId = "property_id",
  RecommendedRoomId = "recommended_room_id",
  ReservationId = "reservation_id",
  ReservationRefId = "reservation_ref_id",
  RoomId = "room_id",
  SharedWithReservationId = "shared_with_reservation_id",
  Status = "status",
}

export type Sf_Pms_Pms_Reservations_Updates = {
  _inc?: Maybe<Sf_Pms_Pms_Reservations_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Reservations_Set_Input>;
  where: Sf_Pms_Pms_Reservations_Bool_Exp;
};

export type Sf_Pms_Pms_Reservations_Var_Pop_Fields = {
  __typename?: "sf_pms_pms_reservations_var_pop_fields";
  number_of_rooms?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Reservations_Var_Samp_Fields = {
  __typename?: "sf_pms_pms_reservations_var_samp_fields";
  number_of_rooms?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Reservations_Variance_Fields = {
  __typename?: "sf_pms_pms_reservations_variance_fields";
  number_of_rooms?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Room_Categories = {
  __typename?: "sf_pms_pms_room_categories";
  allow_book_by_category: Scalars["Boolean"];
  is_inactive?: Maybe<Scalars["Boolean"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  pms_property?: Maybe<Sf_Pms_Pms_Properties>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_category_name: Scalars["String"];
  sf_category_id?: Maybe<Scalars["uuid"]>;
};

export type Sf_Pms_Pms_Room_Categories_Aggregate = {
  __typename?: "sf_pms_pms_room_categories_aggregate";
  aggregate?: Maybe<Sf_Pms_Pms_Room_Categories_Aggregate_Fields>;
  nodes: Array<Sf_Pms_Pms_Room_Categories>;
};

export type Sf_Pms_Pms_Room_Categories_Aggregate_Fields = {
  __typename?: "sf_pms_pms_room_categories_aggregate_fields";
  avg?: Maybe<Sf_Pms_Pms_Room_Categories_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Pms_Pms_Room_Categories_Max_Fields>;
  min?: Maybe<Sf_Pms_Pms_Room_Categories_Min_Fields>;
  stddev?: Maybe<Sf_Pms_Pms_Room_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Pms_Pms_Room_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Pms_Pms_Room_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Pms_Pms_Room_Categories_Sum_Fields>;
  var_pop?: Maybe<Sf_Pms_Pms_Room_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Pms_Pms_Room_Categories_Var_Samp_Fields>;
  variance?: Maybe<Sf_Pms_Pms_Room_Categories_Variance_Fields>;
};

export type Sf_Pms_Pms_Room_Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Pms_Pms_Room_Categories_Avg_Fields = {
  __typename?: "sf_pms_pms_room_categories_avg_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Room_Categories_Bool_Exp = {
  _and?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Bool_Exp>>;
  _not?: Maybe<Sf_Pms_Pms_Room_Categories_Bool_Exp>;
  _or?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Bool_Exp>>;
  allow_book_by_category?: Maybe<Boolean_Comparison_Exp>;
  is_inactive?: Maybe<Boolean_Comparison_Exp>;
  pms_id?: Maybe<Int_Comparison_Exp>;
  pms_property?: Maybe<Sf_Pms_Pms_Properties_Bool_Exp>;
  property_id?: Maybe<String_Comparison_Exp>;
  room_category_id?: Maybe<String_Comparison_Exp>;
  room_category_name?: Maybe<String_Comparison_Exp>;
  sf_category_id?: Maybe<Uuid_Comparison_Exp>;
};

export enum Sf_Pms_Pms_Room_Categories_Constraint {
  PmsRoomCategoriesPk = "pms_room_categories_pk",
  PmsRoomCategoriesSfCategoryIdUniqueKey = "pms_room_categories_sf_category_id_unique_key",
}

export type Sf_Pms_Pms_Room_Categories_Inc_Input = {
  pms_id?: Maybe<Scalars["Int"]>;
};

export type Sf_Pms_Pms_Room_Categories_Insert_Input = {
  allow_book_by_category?: Maybe<Scalars["Boolean"]>;
  is_inactive?: Maybe<Scalars["Boolean"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  pms_property?: Maybe<Sf_Pms_Pms_Properties_Obj_Rel_Insert_Input>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_category_name?: Maybe<Scalars["String"]>;
  sf_category_id?: Maybe<Scalars["uuid"]>;
};

export type Sf_Pms_Pms_Room_Categories_Max_Fields = {
  __typename?: "sf_pms_pms_room_categories_max_fields";
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_category_name?: Maybe<Scalars["String"]>;
  sf_category_id?: Maybe<Scalars["uuid"]>;
};

export type Sf_Pms_Pms_Room_Categories_Min_Fields = {
  __typename?: "sf_pms_pms_room_categories_min_fields";
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_category_name?: Maybe<Scalars["String"]>;
  sf_category_id?: Maybe<Scalars["uuid"]>;
};

export type Sf_Pms_Pms_Room_Categories_Mutation_Response = {
  __typename?: "sf_pms_pms_room_categories_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Pms_Pms_Room_Categories>;
};

export type Sf_Pms_Pms_Room_Categories_Obj_Rel_Insert_Input = {
  data: Sf_Pms_Pms_Room_Categories_Insert_Input;
  on_conflict?: Maybe<Sf_Pms_Pms_Room_Categories_On_Conflict>;
};

export type Sf_Pms_Pms_Room_Categories_On_Conflict = {
  constraint: Sf_Pms_Pms_Room_Categories_Constraint;
  update_columns: Array<Sf_Pms_Pms_Room_Categories_Update_Column>;
  where?: Maybe<Sf_Pms_Pms_Room_Categories_Bool_Exp>;
};

export type Sf_Pms_Pms_Room_Categories_Order_By = {
  allow_book_by_category?: Maybe<Order_By>;
  is_inactive?: Maybe<Order_By>;
  pms_id?: Maybe<Order_By>;
  pms_property?: Maybe<Sf_Pms_Pms_Properties_Order_By>;
  property_id?: Maybe<Order_By>;
  room_category_id?: Maybe<Order_By>;
  room_category_name?: Maybe<Order_By>;
  sf_category_id?: Maybe<Order_By>;
};

export enum Sf_Pms_Pms_Room_Categories_Select_Column {
  AllowBookByCategory = "allow_book_by_category",
  IsInactive = "is_inactive",
  PmsId = "pms_id",
  PropertyId = "property_id",
  RoomCategoryId = "room_category_id",
  RoomCategoryName = "room_category_name",
  SfCategoryId = "sf_category_id",
}

export type Sf_Pms_Pms_Room_Categories_Set_Input = {
  allow_book_by_category?: Maybe<Scalars["Boolean"]>;
  is_inactive?: Maybe<Scalars["Boolean"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_category_name?: Maybe<Scalars["String"]>;
  sf_category_id?: Maybe<Scalars["uuid"]>;
};

export type Sf_Pms_Pms_Room_Categories_Stddev_Fields = {
  __typename?: "sf_pms_pms_room_categories_stddev_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Room_Categories_Stddev_Pop_Fields = {
  __typename?: "sf_pms_pms_room_categories_stddev_pop_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Room_Categories_Stddev_Samp_Fields = {
  __typename?: "sf_pms_pms_room_categories_stddev_samp_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Room_Categories_Stream_Cursor_Input = {
  initial_value: Sf_Pms_Pms_Room_Categories_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Pms_Pms_Room_Categories_Stream_Cursor_Value_Input = {
  allow_book_by_category?: Maybe<Scalars["Boolean"]>;
  is_inactive?: Maybe<Scalars["Boolean"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_category_name?: Maybe<Scalars["String"]>;
  sf_category_id?: Maybe<Scalars["uuid"]>;
};

export type Sf_Pms_Pms_Room_Categories_Sum_Fields = {
  __typename?: "sf_pms_pms_room_categories_sum_fields";
  pms_id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Pms_Pms_Room_Categories_Update_Column {
  AllowBookByCategory = "allow_book_by_category",
  IsInactive = "is_inactive",
  PmsId = "pms_id",
  PropertyId = "property_id",
  RoomCategoryId = "room_category_id",
  RoomCategoryName = "room_category_name",
  SfCategoryId = "sf_category_id",
}

export type Sf_Pms_Pms_Room_Categories_Updates = {
  _inc?: Maybe<Sf_Pms_Pms_Room_Categories_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Room_Categories_Set_Input>;
  where: Sf_Pms_Pms_Room_Categories_Bool_Exp;
};

export type Sf_Pms_Pms_Room_Categories_Var_Pop_Fields = {
  __typename?: "sf_pms_pms_room_categories_var_pop_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Room_Categories_Var_Samp_Fields = {
  __typename?: "sf_pms_pms_room_categories_var_samp_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Room_Categories_Variance_Fields = {
  __typename?: "sf_pms_pms_room_categories_variance_fields";
  pms_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Rooms = {
  __typename?: "sf_pms_pms_rooms";
  interconnecting: Scalars["Boolean"];
  interconnecting_room_id?: Maybe<Scalars["String"]>;
  is_inactive?: Maybe<Scalars["Boolean"]>;
  max_occupants?: Maybe<Scalars["Int"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  position?: Maybe<Sensorflow_Positions>;
  position_id?: Maybe<Scalars["uuid"]>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  room_name: Scalars["String"];
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
  status: Scalars["String"];
};

export type Sf_Pms_Pms_Rooms_Aggregate = {
  __typename?: "sf_pms_pms_rooms_aggregate";
  aggregate?: Maybe<Sf_Pms_Pms_Rooms_Aggregate_Fields>;
  nodes: Array<Sf_Pms_Pms_Rooms>;
};

export type Sf_Pms_Pms_Rooms_Aggregate_Fields = {
  __typename?: "sf_pms_pms_rooms_aggregate_fields";
  avg?: Maybe<Sf_Pms_Pms_Rooms_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Pms_Pms_Rooms_Max_Fields>;
  min?: Maybe<Sf_Pms_Pms_Rooms_Min_Fields>;
  stddev?: Maybe<Sf_Pms_Pms_Rooms_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Pms_Pms_Rooms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Pms_Pms_Rooms_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Pms_Pms_Rooms_Sum_Fields>;
  var_pop?: Maybe<Sf_Pms_Pms_Rooms_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Pms_Pms_Rooms_Var_Samp_Fields>;
  variance?: Maybe<Sf_Pms_Pms_Rooms_Variance_Fields>;
};

export type Sf_Pms_Pms_Rooms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Pms_Pms_Rooms_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Pms_Pms_Rooms_Avg_Fields = {
  __typename?: "sf_pms_pms_rooms_avg_fields";
  max_occupants?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Rooms_Bool_Exp = {
  _and?: Maybe<Array<Sf_Pms_Pms_Rooms_Bool_Exp>>;
  _not?: Maybe<Sf_Pms_Pms_Rooms_Bool_Exp>;
  _or?: Maybe<Array<Sf_Pms_Pms_Rooms_Bool_Exp>>;
  interconnecting?: Maybe<Boolean_Comparison_Exp>;
  interconnecting_room_id?: Maybe<String_Comparison_Exp>;
  is_inactive?: Maybe<Boolean_Comparison_Exp>;
  max_occupants?: Maybe<Int_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  pms_id?: Maybe<Int_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  position_id?: Maybe<Uuid_Comparison_Exp>;
  property_id?: Maybe<String_Comparison_Exp>;
  room_category_id?: Maybe<String_Comparison_Exp>;
  room_id?: Maybe<String_Comparison_Exp>;
  room_name?: Maybe<String_Comparison_Exp>;
  room_size_square_meters?: Maybe<Float_Comparison_Exp>;
  room_weight_ratio?: Maybe<Float_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Pms_Pms_Rooms_Constraint {
  PmsRoomsPk = "pms_rooms_pk",
  PmsRoomsPositionIdUniqueIdx = "pms_rooms_position_id_unique_idx",
}

export type Sf_Pms_Pms_Rooms_Inc_Input = {
  max_occupants?: Maybe<Scalars["Int"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Rooms_Insert_Input = {
  interconnecting?: Maybe<Scalars["Boolean"]>;
  interconnecting_room_id?: Maybe<Scalars["String"]>;
  is_inactive?: Maybe<Scalars["Boolean"]>;
  max_occupants?: Maybe<Scalars["Int"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  position_id?: Maybe<Scalars["uuid"]>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  room_name?: Maybe<Scalars["String"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Rooms_Max_Fields = {
  __typename?: "sf_pms_pms_rooms_max_fields";
  interconnecting_room_id?: Maybe<Scalars["String"]>;
  max_occupants?: Maybe<Scalars["Int"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  room_name?: Maybe<Scalars["String"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Rooms_Min_Fields = {
  __typename?: "sf_pms_pms_rooms_min_fields";
  interconnecting_room_id?: Maybe<Scalars["String"]>;
  max_occupants?: Maybe<Scalars["Int"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  room_name?: Maybe<Scalars["String"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Rooms_Mutation_Response = {
  __typename?: "sf_pms_pms_rooms_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Pms_Pms_Rooms>;
};

export type Sf_Pms_Pms_Rooms_Obj_Rel_Insert_Input = {
  data: Sf_Pms_Pms_Rooms_Insert_Input;
  on_conflict?: Maybe<Sf_Pms_Pms_Rooms_On_Conflict>;
};

export type Sf_Pms_Pms_Rooms_On_Conflict = {
  constraint: Sf_Pms_Pms_Rooms_Constraint;
  update_columns: Array<Sf_Pms_Pms_Rooms_Update_Column>;
  where?: Maybe<Sf_Pms_Pms_Rooms_Bool_Exp>;
};

export type Sf_Pms_Pms_Rooms_Order_By = {
  interconnecting?: Maybe<Order_By>;
  interconnecting_room_id?: Maybe<Order_By>;
  is_inactive?: Maybe<Order_By>;
  max_occupants?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  pms_id?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  position_id?: Maybe<Order_By>;
  property_id?: Maybe<Order_By>;
  room_category_id?: Maybe<Order_By>;
  room_id?: Maybe<Order_By>;
  room_name?: Maybe<Order_By>;
  room_size_square_meters?: Maybe<Order_By>;
  room_weight_ratio?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

export enum Sf_Pms_Pms_Rooms_Select_Column {
  Interconnecting = "interconnecting",
  InterconnectingRoomId = "interconnecting_room_id",
  IsInactive = "is_inactive",
  MaxOccupants = "max_occupants",
  ModifiedDate = "modified_date",
  PmsId = "pms_id",
  PositionId = "position_id",
  PropertyId = "property_id",
  RoomCategoryId = "room_category_id",
  RoomId = "room_id",
  RoomName = "room_name",
  RoomSizeSquareMeters = "room_size_square_meters",
  RoomWeightRatio = "room_weight_ratio",
  Status = "status",
}

export type Sf_Pms_Pms_Rooms_Set_Input = {
  interconnecting?: Maybe<Scalars["Boolean"]>;
  interconnecting_room_id?: Maybe<Scalars["String"]>;
  is_inactive?: Maybe<Scalars["Boolean"]>;
  max_occupants?: Maybe<Scalars["Int"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  room_name?: Maybe<Scalars["String"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Rooms_Stddev_Fields = {
  __typename?: "sf_pms_pms_rooms_stddev_fields";
  max_occupants?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Rooms_Stddev_Pop_Fields = {
  __typename?: "sf_pms_pms_rooms_stddev_pop_fields";
  max_occupants?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Rooms_Stddev_Samp_Fields = {
  __typename?: "sf_pms_pms_rooms_stddev_samp_fields";
  max_occupants?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Rooms_Stream_Cursor_Input = {
  initial_value: Sf_Pms_Pms_Rooms_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Pms_Pms_Rooms_Stream_Cursor_Value_Input = {
  interconnecting?: Maybe<Scalars["Boolean"]>;
  interconnecting_room_id?: Maybe<Scalars["String"]>;
  is_inactive?: Maybe<Scalars["Boolean"]>;
  max_occupants?: Maybe<Scalars["Int"]>;
  modified_date?: Maybe<Scalars["timestamp"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  position_id?: Maybe<Scalars["uuid"]>;
  property_id?: Maybe<Scalars["String"]>;
  room_category_id?: Maybe<Scalars["String"]>;
  room_id?: Maybe<Scalars["String"]>;
  room_name?: Maybe<Scalars["String"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Pms_Rooms_Sum_Fields = {
  __typename?: "sf_pms_pms_rooms_sum_fields";
  max_occupants?: Maybe<Scalars["Int"]>;
  pms_id?: Maybe<Scalars["Int"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
};

export enum Sf_Pms_Pms_Rooms_Update_Column {
  Interconnecting = "interconnecting",
  InterconnectingRoomId = "interconnecting_room_id",
  IsInactive = "is_inactive",
  MaxOccupants = "max_occupants",
  ModifiedDate = "modified_date",
  PmsId = "pms_id",
  PositionId = "position_id",
  PropertyId = "property_id",
  RoomCategoryId = "room_category_id",
  RoomId = "room_id",
  RoomName = "room_name",
  RoomSizeSquareMeters = "room_size_square_meters",
  RoomWeightRatio = "room_weight_ratio",
  Status = "status",
}

export type Sf_Pms_Pms_Rooms_Updates = {
  _inc?: Maybe<Sf_Pms_Pms_Rooms_Inc_Input>;
  _set?: Maybe<Sf_Pms_Pms_Rooms_Set_Input>;
  where: Sf_Pms_Pms_Rooms_Bool_Exp;
};

export type Sf_Pms_Pms_Rooms_Var_Pop_Fields = {
  __typename?: "sf_pms_pms_rooms_var_pop_fields";
  max_occupants?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Rooms_Var_Samp_Fields = {
  __typename?: "sf_pms_pms_rooms_var_samp_fields";
  max_occupants?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Pms_Rooms_Variance_Fields = {
  __typename?: "sf_pms_pms_rooms_variance_fields";
  max_occupants?: Maybe<Scalars["Float"]>;
  pms_id?: Maybe<Scalars["Float"]>;
  room_size_square_meters?: Maybe<Scalars["Float"]>;
  room_weight_ratio?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Smartalloc_Report = {
  __typename?: "sf_pms_smartalloc_report";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  billingCurrency: Scalars["String"];
  date: Scalars["date"];
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  lastModified?: Maybe<Scalars["timestamp"]>;
  location?: Maybe<Sensorflow_Locations>;
  locationId: Scalars["uuid"];
  ninetyAdherenceSavingsKwh: Scalars["Float"];
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd: Scalars["Float"];
  numberOfFollowedRecommendation?: Maybe<Scalars["Int"]>;
  numberOfRecommendation?: Maybe<Scalars["Int"]>;
  numberOfReservations?: Maybe<Scalars["Int"]>;
  savingsKwh: Scalars["Float"];
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
  type: Scalars["String"];
};

export type Sf_Pms_Smartalloc_Report_Aggregate = {
  __typename?: "sf_pms_smartalloc_report_aggregate";
  aggregate?: Maybe<Sf_Pms_Smartalloc_Report_Aggregate_Fields>;
  nodes: Array<Sf_Pms_Smartalloc_Report>;
};

export type Sf_Pms_Smartalloc_Report_Aggregate_Fields = {
  __typename?: "sf_pms_smartalloc_report_aggregate_fields";
  avg?: Maybe<Sf_Pms_Smartalloc_Report_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Pms_Smartalloc_Report_Max_Fields>;
  min?: Maybe<Sf_Pms_Smartalloc_Report_Min_Fields>;
  stddev?: Maybe<Sf_Pms_Smartalloc_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Pms_Smartalloc_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Pms_Smartalloc_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Pms_Smartalloc_Report_Sum_Fields>;
  var_pop?: Maybe<Sf_Pms_Smartalloc_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Pms_Smartalloc_Report_Var_Samp_Fields>;
  variance?: Maybe<Sf_Pms_Smartalloc_Report_Variance_Fields>;
};

export type Sf_Pms_Smartalloc_Report_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Pms_Smartalloc_Report_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Pms_Smartalloc_Report_Avg_Fields = {
  __typename?: "sf_pms_smartalloc_report_avg_fields";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Float"]>;
  numberOfRecommendation?: Maybe<Scalars["Float"]>;
  numberOfReservations?: Maybe<Scalars["Float"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Smartalloc_Report_Bool_Exp = {
  _and?: Maybe<Array<Sf_Pms_Smartalloc_Report_Bool_Exp>>;
  _not?: Maybe<Sf_Pms_Smartalloc_Report_Bool_Exp>;
  _or?: Maybe<Array<Sf_Pms_Smartalloc_Report_Bool_Exp>>;
  averageDailyRunningCompressors?: Maybe<Float_Comparison_Exp>;
  averageOccupancy?: Maybe<Float_Comparison_Exp>;
  billingCurrency?: Maybe<String_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  followedRecommendationRatio?: Maybe<Float_Comparison_Exp>;
  lastModified?: Maybe<Timestamp_Comparison_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  ninetyAdherenceSavingsKwh?: Maybe<Float_Comparison_Exp>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Float_Comparison_Exp>;
  ninetyAdherenceSavingsSgd?: Maybe<Float_Comparison_Exp>;
  numberOfFollowedRecommendation?: Maybe<Int_Comparison_Exp>;
  numberOfRecommendation?: Maybe<Int_Comparison_Exp>;
  numberOfReservations?: Maybe<Int_Comparison_Exp>;
  savingsKwh?: Maybe<Float_Comparison_Exp>;
  savingsLocalCurrency?: Maybe<Float_Comparison_Exp>;
  savingsPercentage?: Maybe<Float_Comparison_Exp>;
  savingsSgd?: Maybe<Float_Comparison_Exp>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Float_Comparison_Exp>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Float_Comparison_Exp>;
  totalConsumptionKwh?: Maybe<Float_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Pms_Smartalloc_Report_Constraint {
  SmartallocReportPkey = "smartalloc_report_pkey",
}

export type Sf_Pms_Smartalloc_Report_Inc_Input = {
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Int"]>;
  numberOfRecommendation?: Maybe<Scalars["Int"]>;
  numberOfReservations?: Maybe<Scalars["Int"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Smartalloc_Report_Insert_Input = {
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  billingCurrency?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["date"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  lastModified?: Maybe<Scalars["timestamp"]>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars["uuid"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Int"]>;
  numberOfRecommendation?: Maybe<Scalars["Int"]>;
  numberOfReservations?: Maybe<Scalars["Int"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Smartalloc_Report_Max_Fields = {
  __typename?: "sf_pms_smartalloc_report_max_fields";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  billingCurrency?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["date"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  lastModified?: Maybe<Scalars["timestamp"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Int"]>;
  numberOfRecommendation?: Maybe<Scalars["Int"]>;
  numberOfReservations?: Maybe<Scalars["Int"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Smartalloc_Report_Min_Fields = {
  __typename?: "sf_pms_smartalloc_report_min_fields";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  billingCurrency?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["date"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  lastModified?: Maybe<Scalars["timestamp"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Int"]>;
  numberOfRecommendation?: Maybe<Scalars["Int"]>;
  numberOfReservations?: Maybe<Scalars["Int"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Smartalloc_Report_Mutation_Response = {
  __typename?: "sf_pms_smartalloc_report_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Pms_Smartalloc_Report>;
};

export type Sf_Pms_Smartalloc_Report_On_Conflict = {
  constraint: Sf_Pms_Smartalloc_Report_Constraint;
  update_columns: Array<Sf_Pms_Smartalloc_Report_Update_Column>;
  where?: Maybe<Sf_Pms_Smartalloc_Report_Bool_Exp>;
};

export type Sf_Pms_Smartalloc_Report_Order_By = {
  averageDailyRunningCompressors?: Maybe<Order_By>;
  averageOccupancy?: Maybe<Order_By>;
  billingCurrency?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  followedRecommendationRatio?: Maybe<Order_By>;
  lastModified?: Maybe<Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  ninetyAdherenceSavingsKwh?: Maybe<Order_By>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Order_By>;
  ninetyAdherenceSavingsSgd?: Maybe<Order_By>;
  numberOfFollowedRecommendation?: Maybe<Order_By>;
  numberOfRecommendation?: Maybe<Order_By>;
  numberOfReservations?: Maybe<Order_By>;
  savingsKwh?: Maybe<Order_By>;
  savingsLocalCurrency?: Maybe<Order_By>;
  savingsPercentage?: Maybe<Order_By>;
  savingsSgd?: Maybe<Order_By>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Order_By>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Order_By>;
  totalConsumptionKwh?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

export type Sf_Pms_Smartalloc_Report_Pk_Columns_Input = {
  date: Scalars["date"];
  locationId: Scalars["uuid"];
  type: Scalars["String"];
};

export enum Sf_Pms_Smartalloc_Report_Select_Column {
  AverageDailyRunningCompressors = "averageDailyRunningCompressors",
  AverageOccupancy = "averageOccupancy",
  BillingCurrency = "billingCurrency",
  Date = "date",
  FollowedRecommendationRatio = "followedRecommendationRatio",
  LastModified = "lastModified",
  LocationId = "locationId",
  NinetyAdherenceSavingsKwh = "ninetyAdherenceSavingsKwh",
  NinetyAdherenceSavingsLocalCurrency = "ninetyAdherenceSavingsLocalCurrency",
  NinetyAdherenceSavingsSgd = "ninetyAdherenceSavingsSgd",
  NumberOfFollowedRecommendation = "numberOfFollowedRecommendation",
  NumberOfRecommendation = "numberOfRecommendation",
  NumberOfReservations = "numberOfReservations",
  SavingsKwh = "savingsKwh",
  SavingsLocalCurrency = "savingsLocalCurrency",
  SavingsPercentage = "savingsPercentage",
  SavingsSgd = "savingsSgd",
  SimulatedNoSmartallocConsumptionKwh = "simulatedNoSmartallocConsumptionKwh",
  SimulatedNoSmartallocDailyCompressors = "simulatedNoSmartallocDailyCompressors",
  TotalConsumptionKwh = "totalConsumptionKwh",
  Type = "type",
}

export type Sf_Pms_Smartalloc_Report_Set_Input = {
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  billingCurrency?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["date"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  lastModified?: Maybe<Scalars["timestamp"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Int"]>;
  numberOfRecommendation?: Maybe<Scalars["Int"]>;
  numberOfReservations?: Maybe<Scalars["Int"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Smartalloc_Report_Stddev_Fields = {
  __typename?: "sf_pms_smartalloc_report_stddev_fields";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Float"]>;
  numberOfRecommendation?: Maybe<Scalars["Float"]>;
  numberOfReservations?: Maybe<Scalars["Float"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Smartalloc_Report_Stddev_Pop_Fields = {
  __typename?: "sf_pms_smartalloc_report_stddev_pop_fields";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Float"]>;
  numberOfRecommendation?: Maybe<Scalars["Float"]>;
  numberOfReservations?: Maybe<Scalars["Float"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Smartalloc_Report_Stddev_Samp_Fields = {
  __typename?: "sf_pms_smartalloc_report_stddev_samp_fields";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Float"]>;
  numberOfRecommendation?: Maybe<Scalars["Float"]>;
  numberOfReservations?: Maybe<Scalars["Float"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Smartalloc_Report_Stream_Cursor_Input = {
  initial_value: Sf_Pms_Smartalloc_Report_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Pms_Smartalloc_Report_Stream_Cursor_Value_Input = {
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  billingCurrency?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["date"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  lastModified?: Maybe<Scalars["timestamp"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Int"]>;
  numberOfRecommendation?: Maybe<Scalars["Int"]>;
  numberOfReservations?: Maybe<Scalars["Int"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Sf_Pms_Smartalloc_Report_Sum_Fields = {
  __typename?: "sf_pms_smartalloc_report_sum_fields";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Int"]>;
  numberOfRecommendation?: Maybe<Scalars["Int"]>;
  numberOfReservations?: Maybe<Scalars["Int"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
};

export enum Sf_Pms_Smartalloc_Report_Update_Column {
  AverageDailyRunningCompressors = "averageDailyRunningCompressors",
  AverageOccupancy = "averageOccupancy",
  BillingCurrency = "billingCurrency",
  Date = "date",
  FollowedRecommendationRatio = "followedRecommendationRatio",
  LastModified = "lastModified",
  LocationId = "locationId",
  NinetyAdherenceSavingsKwh = "ninetyAdherenceSavingsKwh",
  NinetyAdherenceSavingsLocalCurrency = "ninetyAdherenceSavingsLocalCurrency",
  NinetyAdherenceSavingsSgd = "ninetyAdherenceSavingsSgd",
  NumberOfFollowedRecommendation = "numberOfFollowedRecommendation",
  NumberOfRecommendation = "numberOfRecommendation",
  NumberOfReservations = "numberOfReservations",
  SavingsKwh = "savingsKwh",
  SavingsLocalCurrency = "savingsLocalCurrency",
  SavingsPercentage = "savingsPercentage",
  SavingsSgd = "savingsSgd",
  SimulatedNoSmartallocConsumptionKwh = "simulatedNoSmartallocConsumptionKwh",
  SimulatedNoSmartallocDailyCompressors = "simulatedNoSmartallocDailyCompressors",
  TotalConsumptionKwh = "totalConsumptionKwh",
  Type = "type",
}

export type Sf_Pms_Smartalloc_Report_Updates = {
  _inc?: Maybe<Sf_Pms_Smartalloc_Report_Inc_Input>;
  _set?: Maybe<Sf_Pms_Smartalloc_Report_Set_Input>;
  where: Sf_Pms_Smartalloc_Report_Bool_Exp;
};

export type Sf_Pms_Smartalloc_Report_Var_Pop_Fields = {
  __typename?: "sf_pms_smartalloc_report_var_pop_fields";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Float"]>;
  numberOfRecommendation?: Maybe<Scalars["Float"]>;
  numberOfReservations?: Maybe<Scalars["Float"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Smartalloc_Report_Var_Samp_Fields = {
  __typename?: "sf_pms_smartalloc_report_var_samp_fields";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Float"]>;
  numberOfRecommendation?: Maybe<Scalars["Float"]>;
  numberOfReservations?: Maybe<Scalars["Float"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
};

export type Sf_Pms_Smartalloc_Report_Variance_Fields = {
  __typename?: "sf_pms_smartalloc_report_variance_fields";
  averageDailyRunningCompressors?: Maybe<Scalars["Float"]>;
  averageOccupancy?: Maybe<Scalars["Float"]>;
  followedRecommendationRatio?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsKwh?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsLocalCurrency?: Maybe<Scalars["Float"]>;
  ninetyAdherenceSavingsSgd?: Maybe<Scalars["Float"]>;
  numberOfFollowedRecommendation?: Maybe<Scalars["Float"]>;
  numberOfRecommendation?: Maybe<Scalars["Float"]>;
  numberOfReservations?: Maybe<Scalars["Float"]>;
  savingsKwh?: Maybe<Scalars["Float"]>;
  savingsLocalCurrency?: Maybe<Scalars["Float"]>;
  savingsPercentage?: Maybe<Scalars["Float"]>;
  savingsSgd?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocConsumptionKwh?: Maybe<Scalars["Float"]>;
  simulatedNoSmartallocDailyCompressors?: Maybe<Scalars["Float"]>;
  totalConsumptionKwh?: Maybe<Scalars["Float"]>;
};

export type Sf_Simulation_Instrumentation_Commands = {
  __typename?: "sf_simulation_instrumentation_commands";
  commands: Scalars["json"];
  data: Scalars["json"];
  id: Scalars["Int"];
  location: Sensorflow_Locations;
  locationId: Scalars["uuid"];
  node: Sensorflow_Nodes;
  nodeMacId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

export type Sf_Simulation_Instrumentation_CommandsCommandsArgs = {
  path?: Maybe<Scalars["String"]>;
};

export type Sf_Simulation_Instrumentation_CommandsDataArgs = {
  path?: Maybe<Scalars["String"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Aggregate = {
  __typename?: "sf_simulation_instrumentation_commands_aggregate";
  aggregate?: Maybe<Sf_Simulation_Instrumentation_Commands_Aggregate_Fields>;
  nodes: Array<Sf_Simulation_Instrumentation_Commands>;
};

export type Sf_Simulation_Instrumentation_Commands_Aggregate_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_aggregate_fields";
  avg?: Maybe<Sf_Simulation_Instrumentation_Commands_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Simulation_Instrumentation_Commands_Max_Fields>;
  min?: Maybe<Sf_Simulation_Instrumentation_Commands_Min_Fields>;
  stddev?: Maybe<Sf_Simulation_Instrumentation_Commands_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Simulation_Instrumentation_Commands_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Simulation_Instrumentation_Commands_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Simulation_Instrumentation_Commands_Sum_Fields>;
  var_pop?: Maybe<Sf_Simulation_Instrumentation_Commands_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Simulation_Instrumentation_Commands_Var_Samp_Fields>;
  variance?: Maybe<Sf_Simulation_Instrumentation_Commands_Variance_Fields>;
};

export type Sf_Simulation_Instrumentation_Commands_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Avg_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Bool_Exp = {
  _and?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Bool_Exp>>;
  _not?: Maybe<Sf_Simulation_Instrumentation_Commands_Bool_Exp>;
  _or?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Bool_Exp>>;
  commands?: Maybe<Json_Comparison_Exp>;
  data?: Maybe<Json_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  location?: Maybe<Sensorflow_Locations_Bool_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  node?: Maybe<Sensorflow_Nodes_Bool_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sf_Simulation_Instrumentation_Commands_Constraint {
  InstrumentationCommandsPkey = "instrumentation_commands_pkey",
}

export type Sf_Simulation_Instrumentation_Commands_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Insert_Input = {
  commands?: Maybe<Scalars["json"]>;
  data?: Maybe<Scalars["json"]>;
  id?: Maybe<Scalars["Int"]>;
  location?: Maybe<Sensorflow_Locations_Obj_Rel_Insert_Input>;
  locationId?: Maybe<Scalars["uuid"]>;
  node?: Maybe<Sensorflow_Nodes_Obj_Rel_Insert_Input>;
  nodeMacId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Max_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_max_fields";
  id?: Maybe<Scalars["Int"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Min_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_min_fields";
  id?: Maybe<Scalars["Int"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Mutation_Response = {
  __typename?: "sf_simulation_instrumentation_commands_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Simulation_Instrumentation_Commands>;
};

export type Sf_Simulation_Instrumentation_Commands_On_Conflict = {
  constraint: Sf_Simulation_Instrumentation_Commands_Constraint;
  update_columns: Array<Sf_Simulation_Instrumentation_Commands_Update_Column>;
  where?: Maybe<Sf_Simulation_Instrumentation_Commands_Bool_Exp>;
};

export type Sf_Simulation_Instrumentation_Commands_Order_By = {
  commands?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Sensorflow_Locations_Order_By>;
  locationId?: Maybe<Order_By>;
  node?: Maybe<Sensorflow_Nodes_Order_By>;
  nodeMacId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

export type Sf_Simulation_Instrumentation_Commands_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sf_Simulation_Instrumentation_Commands_Select_Column {
  Commands = "commands",
  Data = "data",
  Id = "id",
  LocationId = "locationId",
  NodeMacId = "nodeMacId",
  UpdatedAt = "updatedAt",
}

export type Sf_Simulation_Instrumentation_Commands_Set_Input = {
  commands?: Maybe<Scalars["json"]>;
  data?: Maybe<Scalars["json"]>;
  id?: Maybe<Scalars["Int"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Stddev_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Stddev_Pop_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Stddev_Samp_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Stream_Cursor_Input = {
  initial_value: Sf_Simulation_Instrumentation_Commands_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Simulation_Instrumentation_Commands_Stream_Cursor_Value_Input = {
  commands?: Maybe<Scalars["json"]>;
  data?: Maybe<Scalars["json"]>;
  id?: Maybe<Scalars["Int"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Sum_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Simulation_Instrumentation_Commands_Update_Column {
  Commands = "commands",
  Data = "data",
  Id = "id",
  LocationId = "locationId",
  NodeMacId = "nodeMacId",
  UpdatedAt = "updatedAt",
}

export type Sf_Simulation_Instrumentation_Commands_Updates = {
  _inc?: Maybe<Sf_Simulation_Instrumentation_Commands_Inc_Input>;
  _set?: Maybe<Sf_Simulation_Instrumentation_Commands_Set_Input>;
  where: Sf_Simulation_Instrumentation_Commands_Bool_Exp;
};

export type Sf_Simulation_Instrumentation_Commands_Var_Pop_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Var_Samp_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Simulation_Instrumentation_Commands_Variance_Fields = {
  __typename?: "sf_simulation_instrumentation_commands_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Actions = {
  __typename?: "sf_support_actions";
  action: Scalars["String"];
  id: Scalars["Int"];
  instructionLink?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Actions_Aggregate = {
  __typename?: "sf_support_actions_aggregate";
  aggregate?: Maybe<Sf_Support_Actions_Aggregate_Fields>;
  nodes: Array<Sf_Support_Actions>;
};

export type Sf_Support_Actions_Aggregate_Fields = {
  __typename?: "sf_support_actions_aggregate_fields";
  avg?: Maybe<Sf_Support_Actions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Support_Actions_Max_Fields>;
  min?: Maybe<Sf_Support_Actions_Min_Fields>;
  stddev?: Maybe<Sf_Support_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Support_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Support_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Support_Actions_Sum_Fields>;
  var_pop?: Maybe<Sf_Support_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Support_Actions_Var_Samp_Fields>;
  variance?: Maybe<Sf_Support_Actions_Variance_Fields>;
};

export type Sf_Support_Actions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Support_Actions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Support_Actions_Avg_Fields = {
  __typename?: "sf_support_actions_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Actions_Bool_Exp = {
  _and?: Maybe<Array<Sf_Support_Actions_Bool_Exp>>;
  _not?: Maybe<Sf_Support_Actions_Bool_Exp>;
  _or?: Maybe<Array<Sf_Support_Actions_Bool_Exp>>;
  action?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  instructionLink?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Support_Actions_Constraint {
  ActionsPkey = "actions_pkey",
}

export type Sf_Support_Actions_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Actions_Insert_Input = {
  action?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  instructionLink?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Actions_Max_Fields = {
  __typename?: "sf_support_actions_max_fields";
  action?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  instructionLink?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Actions_Min_Fields = {
  __typename?: "sf_support_actions_min_fields";
  action?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  instructionLink?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Actions_Mutation_Response = {
  __typename?: "sf_support_actions_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Support_Actions>;
};

export type Sf_Support_Actions_Obj_Rel_Insert_Input = {
  data: Sf_Support_Actions_Insert_Input;
  on_conflict?: Maybe<Sf_Support_Actions_On_Conflict>;
};

export type Sf_Support_Actions_On_Conflict = {
  constraint: Sf_Support_Actions_Constraint;
  update_columns: Array<Sf_Support_Actions_Update_Column>;
  where?: Maybe<Sf_Support_Actions_Bool_Exp>;
};

export type Sf_Support_Actions_Order_By = {
  action?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instructionLink?: Maybe<Order_By>;
};

export type Sf_Support_Actions_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sf_Support_Actions_Select_Column {
  Action = "action",
  Id = "id",
  InstructionLink = "instructionLink",
}

export type Sf_Support_Actions_Set_Input = {
  action?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  instructionLink?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Actions_Stddev_Fields = {
  __typename?: "sf_support_actions_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Actions_Stddev_Pop_Fields = {
  __typename?: "sf_support_actions_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Actions_Stddev_Samp_Fields = {
  __typename?: "sf_support_actions_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Actions_Stream_Cursor_Input = {
  initial_value: Sf_Support_Actions_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Support_Actions_Stream_Cursor_Value_Input = {
  action?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  instructionLink?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Actions_Sum_Fields = {
  __typename?: "sf_support_actions_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Support_Actions_Update_Column {
  Action = "action",
  Id = "id",
  InstructionLink = "instructionLink",
}

export type Sf_Support_Actions_Updates = {
  _inc?: Maybe<Sf_Support_Actions_Inc_Input>;
  _set?: Maybe<Sf_Support_Actions_Set_Input>;
  where: Sf_Support_Actions_Bool_Exp;
};

export type Sf_Support_Actions_Var_Pop_Fields = {
  __typename?: "sf_support_actions_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Actions_Var_Samp_Fields = {
  __typename?: "sf_support_actions_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Actions_Variance_Fields = {
  __typename?: "sf_support_actions_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Active_Property_Alerts = {
  __typename?: "sf_support_active_property_alerts";
  active?: Maybe<Scalars["Boolean"]>;
  locationId: Scalars["uuid"];
  ticketClassId: Scalars["Int"];
};

export type Sf_Support_Active_Property_Alerts_Aggregate = {
  __typename?: "sf_support_active_property_alerts_aggregate";
  aggregate?: Maybe<Sf_Support_Active_Property_Alerts_Aggregate_Fields>;
  nodes: Array<Sf_Support_Active_Property_Alerts>;
};

export type Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp_Count>;
};

export type Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Sf_Support_Active_Property_Alerts_Select_Column_Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Sf_Support_Active_Property_Alerts_Select_Column_Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sf_Support_Active_Property_Alerts_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Sf_Support_Active_Property_Alerts_Aggregate_Fields = {
  __typename?: "sf_support_active_property_alerts_aggregate_fields";
  avg?: Maybe<Sf_Support_Active_Property_Alerts_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Support_Active_Property_Alerts_Max_Fields>;
  min?: Maybe<Sf_Support_Active_Property_Alerts_Min_Fields>;
  stddev?: Maybe<Sf_Support_Active_Property_Alerts_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Support_Active_Property_Alerts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Support_Active_Property_Alerts_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Support_Active_Property_Alerts_Sum_Fields>;
  var_pop?: Maybe<Sf_Support_Active_Property_Alerts_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Support_Active_Property_Alerts_Var_Samp_Fields>;
  variance?: Maybe<Sf_Support_Active_Property_Alerts_Variance_Fields>;
};

export type Sf_Support_Active_Property_Alerts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Support_Active_Property_Alerts_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Support_Active_Property_Alerts_Aggregate_Order_By = {
  avg?: Maybe<Sf_Support_Active_Property_Alerts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sf_Support_Active_Property_Alerts_Max_Order_By>;
  min?: Maybe<Sf_Support_Active_Property_Alerts_Min_Order_By>;
  stddev?: Maybe<Sf_Support_Active_Property_Alerts_Stddev_Order_By>;
  stddev_pop?: Maybe<Sf_Support_Active_Property_Alerts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sf_Support_Active_Property_Alerts_Stddev_Samp_Order_By>;
  sum?: Maybe<Sf_Support_Active_Property_Alerts_Sum_Order_By>;
  var_pop?: Maybe<Sf_Support_Active_Property_Alerts_Var_Pop_Order_By>;
  var_samp?: Maybe<Sf_Support_Active_Property_Alerts_Var_Samp_Order_By>;
  variance?: Maybe<Sf_Support_Active_Property_Alerts_Variance_Order_By>;
};

export type Sf_Support_Active_Property_Alerts_Arr_Rel_Insert_Input = {
  data: Array<Sf_Support_Active_Property_Alerts_Insert_Input>;
  on_conflict?: Maybe<Sf_Support_Active_Property_Alerts_On_Conflict>;
};

export type Sf_Support_Active_Property_Alerts_Avg_Fields = {
  __typename?: "sf_support_active_property_alerts_avg_fields";
  ticketClassId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Active_Property_Alerts_Avg_Order_By = {
  ticketClassId?: Maybe<Order_By>;
};

export type Sf_Support_Active_Property_Alerts_Bool_Exp = {
  _and?: Maybe<Array<Sf_Support_Active_Property_Alerts_Bool_Exp>>;
  _not?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
  _or?: Maybe<Array<Sf_Support_Active_Property_Alerts_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  locationId?: Maybe<Uuid_Comparison_Exp>;
  ticketClassId?: Maybe<Int_Comparison_Exp>;
};

export enum Sf_Support_Active_Property_Alerts_Constraint {
  ActivePropertyAlertsPkey = "active_property_alerts_pkey",
}

export type Sf_Support_Active_Property_Alerts_Inc_Input = {
  ticketClassId?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Active_Property_Alerts_Insert_Input = {
  active?: Maybe<Scalars["Boolean"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  ticketClassId?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Active_Property_Alerts_Max_Fields = {
  __typename?: "sf_support_active_property_alerts_max_fields";
  locationId?: Maybe<Scalars["uuid"]>;
  ticketClassId?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Active_Property_Alerts_Max_Order_By = {
  locationId?: Maybe<Order_By>;
  ticketClassId?: Maybe<Order_By>;
};

export type Sf_Support_Active_Property_Alerts_Min_Fields = {
  __typename?: "sf_support_active_property_alerts_min_fields";
  locationId?: Maybe<Scalars["uuid"]>;
  ticketClassId?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Active_Property_Alerts_Min_Order_By = {
  locationId?: Maybe<Order_By>;
  ticketClassId?: Maybe<Order_By>;
};

export type Sf_Support_Active_Property_Alerts_Mutation_Response = {
  __typename?: "sf_support_active_property_alerts_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Support_Active_Property_Alerts>;
};

export type Sf_Support_Active_Property_Alerts_On_Conflict = {
  constraint: Sf_Support_Active_Property_Alerts_Constraint;
  update_columns: Array<Sf_Support_Active_Property_Alerts_Update_Column>;
  where?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
};

export type Sf_Support_Active_Property_Alerts_Order_By = {
  active?: Maybe<Order_By>;
  locationId?: Maybe<Order_By>;
  ticketClassId?: Maybe<Order_By>;
};

export type Sf_Support_Active_Property_Alerts_Pk_Columns_Input = {
  locationId: Scalars["uuid"];
  ticketClassId: Scalars["Int"];
};

export enum Sf_Support_Active_Property_Alerts_Select_Column {
  Active = "active",
  LocationId = "locationId",
  TicketClassId = "ticketClassId",
}

export enum Sf_Support_Active_Property_Alerts_Select_Column_Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  Active = "active",
}

export enum Sf_Support_Active_Property_Alerts_Select_Column_Sf_Support_Active_Property_Alerts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  Active = "active",
}

export type Sf_Support_Active_Property_Alerts_Set_Input = {
  active?: Maybe<Scalars["Boolean"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  ticketClassId?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Active_Property_Alerts_Stddev_Fields = {
  __typename?: "sf_support_active_property_alerts_stddev_fields";
  ticketClassId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Active_Property_Alerts_Stddev_Order_By = {
  ticketClassId?: Maybe<Order_By>;
};

export type Sf_Support_Active_Property_Alerts_Stddev_Pop_Fields = {
  __typename?: "sf_support_active_property_alerts_stddev_pop_fields";
  ticketClassId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Active_Property_Alerts_Stddev_Pop_Order_By = {
  ticketClassId?: Maybe<Order_By>;
};

export type Sf_Support_Active_Property_Alerts_Stddev_Samp_Fields = {
  __typename?: "sf_support_active_property_alerts_stddev_samp_fields";
  ticketClassId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Active_Property_Alerts_Stddev_Samp_Order_By = {
  ticketClassId?: Maybe<Order_By>;
};

export type Sf_Support_Active_Property_Alerts_Stream_Cursor_Input = {
  initial_value: Sf_Support_Active_Property_Alerts_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Support_Active_Property_Alerts_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars["Boolean"]>;
  locationId?: Maybe<Scalars["uuid"]>;
  ticketClassId?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Active_Property_Alerts_Sum_Fields = {
  __typename?: "sf_support_active_property_alerts_sum_fields";
  ticketClassId?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Active_Property_Alerts_Sum_Order_By = {
  ticketClassId?: Maybe<Order_By>;
};

export enum Sf_Support_Active_Property_Alerts_Update_Column {
  Active = "active",
  LocationId = "locationId",
  TicketClassId = "ticketClassId",
}

export type Sf_Support_Active_Property_Alerts_Updates = {
  _inc?: Maybe<Sf_Support_Active_Property_Alerts_Inc_Input>;
  _set?: Maybe<Sf_Support_Active_Property_Alerts_Set_Input>;
  where: Sf_Support_Active_Property_Alerts_Bool_Exp;
};

export type Sf_Support_Active_Property_Alerts_Var_Pop_Fields = {
  __typename?: "sf_support_active_property_alerts_var_pop_fields";
  ticketClassId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Active_Property_Alerts_Var_Pop_Order_By = {
  ticketClassId?: Maybe<Order_By>;
};

export type Sf_Support_Active_Property_Alerts_Var_Samp_Fields = {
  __typename?: "sf_support_active_property_alerts_var_samp_fields";
  ticketClassId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Active_Property_Alerts_Var_Samp_Order_By = {
  ticketClassId?: Maybe<Order_By>;
};

export type Sf_Support_Active_Property_Alerts_Variance_Fields = {
  __typename?: "sf_support_active_property_alerts_variance_fields";
  ticketClassId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Active_Property_Alerts_Variance_Order_By = {
  ticketClassId?: Maybe<Order_By>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories";
  id: Scalars["Int"];
  latest_analytics_run?: Maybe<Scalars["timestamptz"]>;
  latest_daily_run?: Maybe<Scalars["timestamptz"]>;
  run_at: Scalars["timestamptz"];
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Aggregate = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_aggregate";
  aggregate?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Aggregate_Fields>;
  nodes: Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Aggregate_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_aggregate_fields";
  avg?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Max_Fields>;
  min?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Min_Fields>;
  stddev?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Sum_Fields>;
  var_pop?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Var_Samp_Fields>;
  variance?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Variance_Fields>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Avg_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp = {
  _and?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp>>;
  _not?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp>;
  _or?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  latest_analytics_run?: Maybe<Timestamptz_Comparison_Exp>;
  latest_daily_run?: Maybe<Timestamptz_Comparison_Exp>;
  run_at?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Constraint {
  AnalyticsTicketJobsRunningHistoriesPkey = "analytics_ticket_jobs_running_histories_pkey",
}

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  latest_analytics_run?: Maybe<Scalars["timestamptz"]>;
  latest_daily_run?: Maybe<Scalars["timestamptz"]>;
  run_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Max_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_max_fields";
  id?: Maybe<Scalars["Int"]>;
  latest_analytics_run?: Maybe<Scalars["timestamptz"]>;
  latest_daily_run?: Maybe<Scalars["timestamptz"]>;
  run_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Min_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_min_fields";
  id?: Maybe<Scalars["Int"]>;
  latest_analytics_run?: Maybe<Scalars["timestamptz"]>;
  latest_daily_run?: Maybe<Scalars["timestamptz"]>;
  run_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Mutation_Response = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_On_Conflict = {
  constraint: Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Constraint;
  update_columns: Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Update_Column>;
  where?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Order_By = {
  id?: Maybe<Order_By>;
  latest_analytics_run?: Maybe<Order_By>;
  latest_daily_run?: Maybe<Order_By>;
  run_at?: Maybe<Order_By>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Select_Column {
  Id = "id",
  LatestAnalyticsRun = "latest_analytics_run",
  LatestDailyRun = "latest_daily_run",
  RunAt = "run_at",
}

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  latest_analytics_run?: Maybe<Scalars["timestamptz"]>;
  latest_daily_run?: Maybe<Scalars["timestamptz"]>;
  run_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Stddev_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Stddev_Pop_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Stddev_Samp_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Stream_Cursor_Input = {
  initial_value: Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["Int"]>;
  latest_analytics_run?: Maybe<Scalars["timestamptz"]>;
  latest_daily_run?: Maybe<Scalars["timestamptz"]>;
  run_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Sum_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Update_Column {
  Id = "id",
  LatestAnalyticsRun = "latest_analytics_run",
  LatestDailyRun = "latest_daily_run",
  RunAt = "run_at",
}

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Updates = {
  _inc?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Inc_Input>;
  _set?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Set_Input>;
  where: Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Var_Pop_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Var_Samp_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Variance_Fields = {
  __typename?: "sf_support_analytics_ticket_jobs_running_histories_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Root_Causes = {
  __typename?: "sf_support_root_causes";
  id: Scalars["Int"];
  root_cause: Scalars["String"];
};

export type Sf_Support_Root_Causes_Aggregate = {
  __typename?: "sf_support_root_causes_aggregate";
  aggregate?: Maybe<Sf_Support_Root_Causes_Aggregate_Fields>;
  nodes: Array<Sf_Support_Root_Causes>;
};

export type Sf_Support_Root_Causes_Aggregate_Fields = {
  __typename?: "sf_support_root_causes_aggregate_fields";
  avg?: Maybe<Sf_Support_Root_Causes_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Support_Root_Causes_Max_Fields>;
  min?: Maybe<Sf_Support_Root_Causes_Min_Fields>;
  stddev?: Maybe<Sf_Support_Root_Causes_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Support_Root_Causes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Support_Root_Causes_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Support_Root_Causes_Sum_Fields>;
  var_pop?: Maybe<Sf_Support_Root_Causes_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Support_Root_Causes_Var_Samp_Fields>;
  variance?: Maybe<Sf_Support_Root_Causes_Variance_Fields>;
};

export type Sf_Support_Root_Causes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Support_Root_Causes_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Support_Root_Causes_Avg_Fields = {
  __typename?: "sf_support_root_causes_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Root_Causes_Bool_Exp = {
  _and?: Maybe<Array<Sf_Support_Root_Causes_Bool_Exp>>;
  _not?: Maybe<Sf_Support_Root_Causes_Bool_Exp>;
  _or?: Maybe<Array<Sf_Support_Root_Causes_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  root_cause?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Support_Root_Causes_Constraint {
  RootCausesPkey = "root_causes_pkey",
  RootcauseUniqueIdx = "rootcause_unique_idx",
}

export type Sf_Support_Root_Causes_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Root_Causes_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  root_cause?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Root_Causes_Max_Fields = {
  __typename?: "sf_support_root_causes_max_fields";
  id?: Maybe<Scalars["Int"]>;
  root_cause?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Root_Causes_Min_Fields = {
  __typename?: "sf_support_root_causes_min_fields";
  id?: Maybe<Scalars["Int"]>;
  root_cause?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Root_Causes_Mutation_Response = {
  __typename?: "sf_support_root_causes_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Support_Root_Causes>;
};

export type Sf_Support_Root_Causes_Obj_Rel_Insert_Input = {
  data: Sf_Support_Root_Causes_Insert_Input;
  on_conflict?: Maybe<Sf_Support_Root_Causes_On_Conflict>;
};

export type Sf_Support_Root_Causes_On_Conflict = {
  constraint: Sf_Support_Root_Causes_Constraint;
  update_columns: Array<Sf_Support_Root_Causes_Update_Column>;
  where?: Maybe<Sf_Support_Root_Causes_Bool_Exp>;
};

export type Sf_Support_Root_Causes_Order_By = {
  id?: Maybe<Order_By>;
  root_cause?: Maybe<Order_By>;
};

export type Sf_Support_Root_Causes_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sf_Support_Root_Causes_Select_Column {
  Id = "id",
  RootCause = "root_cause",
}

export type Sf_Support_Root_Causes_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  root_cause?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Root_Causes_Stddev_Fields = {
  __typename?: "sf_support_root_causes_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Root_Causes_Stddev_Pop_Fields = {
  __typename?: "sf_support_root_causes_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Root_Causes_Stddev_Samp_Fields = {
  __typename?: "sf_support_root_causes_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Root_Causes_Stream_Cursor_Input = {
  initial_value: Sf_Support_Root_Causes_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Support_Root_Causes_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["Int"]>;
  root_cause?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Root_Causes_Sum_Fields = {
  __typename?: "sf_support_root_causes_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Support_Root_Causes_Update_Column {
  Id = "id",
  RootCause = "root_cause",
}

export type Sf_Support_Root_Causes_Updates = {
  _inc?: Maybe<Sf_Support_Root_Causes_Inc_Input>;
  _set?: Maybe<Sf_Support_Root_Causes_Set_Input>;
  where: Sf_Support_Root_Causes_Bool_Exp;
};

export type Sf_Support_Root_Causes_Var_Pop_Fields = {
  __typename?: "sf_support_root_causes_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Root_Causes_Var_Samp_Fields = {
  __typename?: "sf_support_root_causes_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Root_Causes_Variance_Fields = {
  __typename?: "sf_support_root_causes_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Classes = {
  __typename?: "sf_support_ticket_classes";
  accelo_class_id?: Maybe<Scalars["Int"]>;
  active?: Maybe<Scalars["Boolean"]>;
  auto_closeable: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  straight_to_jrf?: Maybe<Scalars["Boolean"]>;
  suggestedActionId?: Maybe<Scalars["Int"]>;
  suggestedRootCauseId?: Maybe<Scalars["Int"]>;
  supportManualLink?: Maybe<Scalars["String"]>;
  ticketClass: Scalars["String"];
};

export type Sf_Support_Ticket_Classes_Aggregate = {
  __typename?: "sf_support_ticket_classes_aggregate";
  aggregate?: Maybe<Sf_Support_Ticket_Classes_Aggregate_Fields>;
  nodes: Array<Sf_Support_Ticket_Classes>;
};

export type Sf_Support_Ticket_Classes_Aggregate_Fields = {
  __typename?: "sf_support_ticket_classes_aggregate_fields";
  avg?: Maybe<Sf_Support_Ticket_Classes_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Support_Ticket_Classes_Max_Fields>;
  min?: Maybe<Sf_Support_Ticket_Classes_Min_Fields>;
  stddev?: Maybe<Sf_Support_Ticket_Classes_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Support_Ticket_Classes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Support_Ticket_Classes_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Support_Ticket_Classes_Sum_Fields>;
  var_pop?: Maybe<Sf_Support_Ticket_Classes_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Support_Ticket_Classes_Var_Samp_Fields>;
  variance?: Maybe<Sf_Support_Ticket_Classes_Variance_Fields>;
};

export type Sf_Support_Ticket_Classes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Support_Ticket_Classes_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Support_Ticket_Classes_Avg_Fields = {
  __typename?: "sf_support_ticket_classes_avg_fields";
  accelo_class_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  suggestedActionId?: Maybe<Scalars["Float"]>;
  suggestedRootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Classes_Bool_Exp = {
  _and?: Maybe<Array<Sf_Support_Ticket_Classes_Bool_Exp>>;
  _not?: Maybe<Sf_Support_Ticket_Classes_Bool_Exp>;
  _or?: Maybe<Array<Sf_Support_Ticket_Classes_Bool_Exp>>;
  accelo_class_id?: Maybe<Int_Comparison_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  auto_closeable?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  straight_to_jrf?: Maybe<Boolean_Comparison_Exp>;
  suggestedActionId?: Maybe<Int_Comparison_Exp>;
  suggestedRootCauseId?: Maybe<Int_Comparison_Exp>;
  supportManualLink?: Maybe<String_Comparison_Exp>;
  ticketClass?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Support_Ticket_Classes_Constraint {
  TicketClassesPkey = "ticket_classes_pkey",
}

export type Sf_Support_Ticket_Classes_Inc_Input = {
  accelo_class_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  suggestedActionId?: Maybe<Scalars["Int"]>;
  suggestedRootCauseId?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Ticket_Classes_Insert_Input = {
  accelo_class_id?: Maybe<Scalars["Int"]>;
  active?: Maybe<Scalars["Boolean"]>;
  auto_closeable?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  straight_to_jrf?: Maybe<Scalars["Boolean"]>;
  suggestedActionId?: Maybe<Scalars["Int"]>;
  suggestedRootCauseId?: Maybe<Scalars["Int"]>;
  supportManualLink?: Maybe<Scalars["String"]>;
  ticketClass?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Classes_Max_Fields = {
  __typename?: "sf_support_ticket_classes_max_fields";
  accelo_class_id?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  suggestedActionId?: Maybe<Scalars["Int"]>;
  suggestedRootCauseId?: Maybe<Scalars["Int"]>;
  supportManualLink?: Maybe<Scalars["String"]>;
  ticketClass?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Classes_Min_Fields = {
  __typename?: "sf_support_ticket_classes_min_fields";
  accelo_class_id?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  suggestedActionId?: Maybe<Scalars["Int"]>;
  suggestedRootCauseId?: Maybe<Scalars["Int"]>;
  supportManualLink?: Maybe<Scalars["String"]>;
  ticketClass?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Classes_Mutation_Response = {
  __typename?: "sf_support_ticket_classes_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Support_Ticket_Classes>;
};

export type Sf_Support_Ticket_Classes_On_Conflict = {
  constraint: Sf_Support_Ticket_Classes_Constraint;
  update_columns: Array<Sf_Support_Ticket_Classes_Update_Column>;
  where?: Maybe<Sf_Support_Ticket_Classes_Bool_Exp>;
};

export type Sf_Support_Ticket_Classes_Order_By = {
  accelo_class_id?: Maybe<Order_By>;
  active?: Maybe<Order_By>;
  auto_closeable?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  straight_to_jrf?: Maybe<Order_By>;
  suggestedActionId?: Maybe<Order_By>;
  suggestedRootCauseId?: Maybe<Order_By>;
  supportManualLink?: Maybe<Order_By>;
  ticketClass?: Maybe<Order_By>;
};

export type Sf_Support_Ticket_Classes_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sf_Support_Ticket_Classes_Select_Column {
  AcceloClassId = "accelo_class_id",
  Active = "active",
  AutoCloseable = "auto_closeable",
  Description = "description",
  Id = "id",
  StraightToJrf = "straight_to_jrf",
  SuggestedActionId = "suggestedActionId",
  SuggestedRootCauseId = "suggestedRootCauseId",
  SupportManualLink = "supportManualLink",
  TicketClass = "ticketClass",
}

export type Sf_Support_Ticket_Classes_Set_Input = {
  accelo_class_id?: Maybe<Scalars["Int"]>;
  active?: Maybe<Scalars["Boolean"]>;
  auto_closeable?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  straight_to_jrf?: Maybe<Scalars["Boolean"]>;
  suggestedActionId?: Maybe<Scalars["Int"]>;
  suggestedRootCauseId?: Maybe<Scalars["Int"]>;
  supportManualLink?: Maybe<Scalars["String"]>;
  ticketClass?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Classes_Stddev_Fields = {
  __typename?: "sf_support_ticket_classes_stddev_fields";
  accelo_class_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  suggestedActionId?: Maybe<Scalars["Float"]>;
  suggestedRootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Classes_Stddev_Pop_Fields = {
  __typename?: "sf_support_ticket_classes_stddev_pop_fields";
  accelo_class_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  suggestedActionId?: Maybe<Scalars["Float"]>;
  suggestedRootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Classes_Stddev_Samp_Fields = {
  __typename?: "sf_support_ticket_classes_stddev_samp_fields";
  accelo_class_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  suggestedActionId?: Maybe<Scalars["Float"]>;
  suggestedRootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Classes_Stream_Cursor_Input = {
  initial_value: Sf_Support_Ticket_Classes_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Support_Ticket_Classes_Stream_Cursor_Value_Input = {
  accelo_class_id?: Maybe<Scalars["Int"]>;
  active?: Maybe<Scalars["Boolean"]>;
  auto_closeable?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  straight_to_jrf?: Maybe<Scalars["Boolean"]>;
  suggestedActionId?: Maybe<Scalars["Int"]>;
  suggestedRootCauseId?: Maybe<Scalars["Int"]>;
  supportManualLink?: Maybe<Scalars["String"]>;
  ticketClass?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Classes_Sum_Fields = {
  __typename?: "sf_support_ticket_classes_sum_fields";
  accelo_class_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  suggestedActionId?: Maybe<Scalars["Int"]>;
  suggestedRootCauseId?: Maybe<Scalars["Int"]>;
};

export enum Sf_Support_Ticket_Classes_Update_Column {
  AcceloClassId = "accelo_class_id",
  Active = "active",
  AutoCloseable = "auto_closeable",
  Description = "description",
  Id = "id",
  StraightToJrf = "straight_to_jrf",
  SuggestedActionId = "suggestedActionId",
  SuggestedRootCauseId = "suggestedRootCauseId",
  SupportManualLink = "supportManualLink",
  TicketClass = "ticketClass",
}

export type Sf_Support_Ticket_Classes_Updates = {
  _inc?: Maybe<Sf_Support_Ticket_Classes_Inc_Input>;
  _set?: Maybe<Sf_Support_Ticket_Classes_Set_Input>;
  where: Sf_Support_Ticket_Classes_Bool_Exp;
};

export type Sf_Support_Ticket_Classes_Var_Pop_Fields = {
  __typename?: "sf_support_ticket_classes_var_pop_fields";
  accelo_class_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  suggestedActionId?: Maybe<Scalars["Float"]>;
  suggestedRootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Classes_Var_Samp_Fields = {
  __typename?: "sf_support_ticket_classes_var_samp_fields";
  accelo_class_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  suggestedActionId?: Maybe<Scalars["Float"]>;
  suggestedRootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Classes_Variance_Fields = {
  __typename?: "sf_support_ticket_classes_variance_fields";
  accelo_class_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  suggestedActionId?: Maybe<Scalars["Float"]>;
  suggestedRootCauseId?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Status = {
  __typename?: "sf_support_ticket_status";
  accelo_id: Scalars["Int"];
  clickup_status_name?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Status_Aggregate = {
  __typename?: "sf_support_ticket_status_aggregate";
  aggregate?: Maybe<Sf_Support_Ticket_Status_Aggregate_Fields>;
  nodes: Array<Sf_Support_Ticket_Status>;
};

export type Sf_Support_Ticket_Status_Aggregate_Fields = {
  __typename?: "sf_support_ticket_status_aggregate_fields";
  avg?: Maybe<Sf_Support_Ticket_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Support_Ticket_Status_Max_Fields>;
  min?: Maybe<Sf_Support_Ticket_Status_Min_Fields>;
  stddev?: Maybe<Sf_Support_Ticket_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Support_Ticket_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Support_Ticket_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Support_Ticket_Status_Sum_Fields>;
  var_pop?: Maybe<Sf_Support_Ticket_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Support_Ticket_Status_Var_Samp_Fields>;
  variance?: Maybe<Sf_Support_Ticket_Status_Variance_Fields>;
};

export type Sf_Support_Ticket_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Support_Ticket_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Support_Ticket_Status_Avg_Fields = {
  __typename?: "sf_support_ticket_status_avg_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Status_Bool_Exp = {
  _and?: Maybe<Array<Sf_Support_Ticket_Status_Bool_Exp>>;
  _not?: Maybe<Sf_Support_Ticket_Status_Bool_Exp>;
  _or?: Maybe<Array<Sf_Support_Ticket_Status_Bool_Exp>>;
  accelo_id?: Maybe<Int_Comparison_Exp>;
  clickup_status_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Support_Ticket_Status_Constraint {
  TicketStatusPkey = "ticket_status_pkey",
}

export type Sf_Support_Ticket_Status_Inc_Input = {
  accelo_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Ticket_Status_Insert_Input = {
  accelo_id?: Maybe<Scalars["Int"]>;
  clickup_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Status_Max_Fields = {
  __typename?: "sf_support_ticket_status_max_fields";
  accelo_id?: Maybe<Scalars["Int"]>;
  clickup_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Status_Min_Fields = {
  __typename?: "sf_support_ticket_status_min_fields";
  accelo_id?: Maybe<Scalars["Int"]>;
  clickup_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Status_Mutation_Response = {
  __typename?: "sf_support_ticket_status_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Support_Ticket_Status>;
};

export type Sf_Support_Ticket_Status_On_Conflict = {
  constraint: Sf_Support_Ticket_Status_Constraint;
  update_columns: Array<Sf_Support_Ticket_Status_Update_Column>;
  where?: Maybe<Sf_Support_Ticket_Status_Bool_Exp>;
};

export type Sf_Support_Ticket_Status_Order_By = {
  accelo_id?: Maybe<Order_By>;
  clickup_status_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

export type Sf_Support_Ticket_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sf_Support_Ticket_Status_Select_Column {
  AcceloId = "accelo_id",
  ClickupStatusName = "clickup_status_name",
  Id = "id",
  Name = "name",
}

export type Sf_Support_Ticket_Status_Set_Input = {
  accelo_id?: Maybe<Scalars["Int"]>;
  clickup_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Status_Stddev_Fields = {
  __typename?: "sf_support_ticket_status_stddev_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Status_Stddev_Pop_Fields = {
  __typename?: "sf_support_ticket_status_stddev_pop_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Status_Stddev_Samp_Fields = {
  __typename?: "sf_support_ticket_status_stddev_samp_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Status_Stream_Cursor_Input = {
  initial_value: Sf_Support_Ticket_Status_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Support_Ticket_Status_Stream_Cursor_Value_Input = {
  accelo_id?: Maybe<Scalars["Int"]>;
  clickup_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Support_Ticket_Status_Sum_Fields = {
  __typename?: "sf_support_ticket_status_sum_fields";
  accelo_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Support_Ticket_Status_Update_Column {
  AcceloId = "accelo_id",
  ClickupStatusName = "clickup_status_name",
  Id = "id",
  Name = "name",
}

export type Sf_Support_Ticket_Status_Updates = {
  _inc?: Maybe<Sf_Support_Ticket_Status_Inc_Input>;
  _set?: Maybe<Sf_Support_Ticket_Status_Set_Input>;
  where: Sf_Support_Ticket_Status_Bool_Exp;
};

export type Sf_Support_Ticket_Status_Var_Pop_Fields = {
  __typename?: "sf_support_ticket_status_var_pop_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Status_Var_Samp_Fields = {
  __typename?: "sf_support_ticket_status_var_samp_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Ticket_Status_Variance_Fields = {
  __typename?: "sf_support_ticket_status_variance_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Tickets = {
  __typename?: "sf_support_tickets";
  accelo_id?: Maybe<Scalars["Int"]>;
  action?: Maybe<Sf_Support_Actions>;
  action_id?: Maybe<Scalars["Int"]>;
  claimed_at?: Maybe<Scalars["timestamptz"]>;
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamp"]>;
  excludeFromVHS: Scalars["Boolean"];
  is_claimed?: Maybe<Scalars["Boolean"]>;
  last_detected?: Maybe<Scalars["timestamptz"]>;
  location_id: Scalars["uuid"];
  observable_id?: Maybe<Scalars["String"]>;
  observable_type?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  root_cause?: Maybe<Sf_Support_Root_Causes>;
  root_cause_id?: Maybe<Scalars["Int"]>;
  ticket_class_id?: Maybe<Scalars["Int"]>;
  ticket_created?: Maybe<Scalars["timestamp"]>;
  ticket_id: Scalars["Int"];
  ticket_status_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Tickets_Aggregate = {
  __typename?: "sf_support_tickets_aggregate";
  aggregate?: Maybe<Sf_Support_Tickets_Aggregate_Fields>;
  nodes: Array<Sf_Support_Tickets>;
};

export type Sf_Support_Tickets_Aggregate_Fields = {
  __typename?: "sf_support_tickets_aggregate_fields";
  avg?: Maybe<Sf_Support_Tickets_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Support_Tickets_Max_Fields>;
  min?: Maybe<Sf_Support_Tickets_Min_Fields>;
  stddev?: Maybe<Sf_Support_Tickets_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Support_Tickets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Support_Tickets_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Support_Tickets_Sum_Fields>;
  var_pop?: Maybe<Sf_Support_Tickets_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Support_Tickets_Var_Samp_Fields>;
  variance?: Maybe<Sf_Support_Tickets_Variance_Fields>;
};

export type Sf_Support_Tickets_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Support_Tickets_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Support_Tickets_Avg_Fields = {
  __typename?: "sf_support_tickets_avg_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  action_id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
  root_cause_id?: Maybe<Scalars["Float"]>;
  ticket_class_id?: Maybe<Scalars["Float"]>;
  ticket_id?: Maybe<Scalars["Float"]>;
  ticket_status_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Tickets_Bool_Exp = {
  _and?: Maybe<Array<Sf_Support_Tickets_Bool_Exp>>;
  _not?: Maybe<Sf_Support_Tickets_Bool_Exp>;
  _or?: Maybe<Array<Sf_Support_Tickets_Bool_Exp>>;
  accelo_id?: Maybe<Int_Comparison_Exp>;
  action?: Maybe<Sf_Support_Actions_Bool_Exp>;
  action_id?: Maybe<Int_Comparison_Exp>;
  claimed_at?: Maybe<Timestamptz_Comparison_Exp>;
  clickup_task_id?: Maybe<String_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamp_Comparison_Exp>;
  excludeFromVHS?: Maybe<Boolean_Comparison_Exp>;
  is_claimed?: Maybe<Boolean_Comparison_Exp>;
  last_detected?: Maybe<Timestamptz_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  observable_id?: Maybe<String_Comparison_Exp>;
  observable_type?: Maybe<String_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
  root_cause?: Maybe<Sf_Support_Root_Causes_Bool_Exp>;
  root_cause_id?: Maybe<Int_Comparison_Exp>;
  ticket_class_id?: Maybe<Int_Comparison_Exp>;
  ticket_created?: Maybe<Timestamp_Comparison_Exp>;
  ticket_id?: Maybe<Int_Comparison_Exp>;
  ticket_status_id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sf_Support_Tickets_Constraint {
  TicketsPkey = "tickets_pkey",
  TicketsUnique = "tickets_unique",
}

export type Sf_Support_Tickets_Inc_Input = {
  accelo_id?: Maybe<Scalars["Int"]>;
  action_id?: Maybe<Scalars["Int"]>;
  priority?: Maybe<Scalars["Int"]>;
  root_cause_id?: Maybe<Scalars["Int"]>;
  ticket_class_id?: Maybe<Scalars["Int"]>;
  ticket_id?: Maybe<Scalars["Int"]>;
  ticket_status_id?: Maybe<Scalars["Int"]>;
};

export type Sf_Support_Tickets_Insert_Input = {
  accelo_id?: Maybe<Scalars["Int"]>;
  action?: Maybe<Sf_Support_Actions_Obj_Rel_Insert_Input>;
  action_id?: Maybe<Scalars["Int"]>;
  claimed_at?: Maybe<Scalars["timestamptz"]>;
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamp"]>;
  excludeFromVHS?: Maybe<Scalars["Boolean"]>;
  is_claimed?: Maybe<Scalars["Boolean"]>;
  last_detected?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  observable_id?: Maybe<Scalars["String"]>;
  observable_type?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  root_cause?: Maybe<Sf_Support_Root_Causes_Obj_Rel_Insert_Input>;
  root_cause_id?: Maybe<Scalars["Int"]>;
  ticket_class_id?: Maybe<Scalars["Int"]>;
  ticket_created?: Maybe<Scalars["timestamp"]>;
  ticket_id?: Maybe<Scalars["Int"]>;
  ticket_status_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Tickets_Max_Fields = {
  __typename?: "sf_support_tickets_max_fields";
  accelo_id?: Maybe<Scalars["Int"]>;
  action_id?: Maybe<Scalars["Int"]>;
  claimed_at?: Maybe<Scalars["timestamptz"]>;
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamp"]>;
  last_detected?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  observable_id?: Maybe<Scalars["String"]>;
  observable_type?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  root_cause_id?: Maybe<Scalars["Int"]>;
  ticket_class_id?: Maybe<Scalars["Int"]>;
  ticket_created?: Maybe<Scalars["timestamp"]>;
  ticket_id?: Maybe<Scalars["Int"]>;
  ticket_status_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Tickets_Min_Fields = {
  __typename?: "sf_support_tickets_min_fields";
  accelo_id?: Maybe<Scalars["Int"]>;
  action_id?: Maybe<Scalars["Int"]>;
  claimed_at?: Maybe<Scalars["timestamptz"]>;
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamp"]>;
  last_detected?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  observable_id?: Maybe<Scalars["String"]>;
  observable_type?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  root_cause_id?: Maybe<Scalars["Int"]>;
  ticket_class_id?: Maybe<Scalars["Int"]>;
  ticket_created?: Maybe<Scalars["timestamp"]>;
  ticket_id?: Maybe<Scalars["Int"]>;
  ticket_status_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Tickets_Mutation_Response = {
  __typename?: "sf_support_tickets_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Support_Tickets>;
};

export type Sf_Support_Tickets_On_Conflict = {
  constraint: Sf_Support_Tickets_Constraint;
  update_columns: Array<Sf_Support_Tickets_Update_Column>;
  where?: Maybe<Sf_Support_Tickets_Bool_Exp>;
};

export type Sf_Support_Tickets_Order_By = {
  accelo_id?: Maybe<Order_By>;
  action?: Maybe<Sf_Support_Actions_Order_By>;
  action_id?: Maybe<Order_By>;
  claimed_at?: Maybe<Order_By>;
  clickup_task_id?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  excludeFromVHS?: Maybe<Order_By>;
  is_claimed?: Maybe<Order_By>;
  last_detected?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  observable_id?: Maybe<Order_By>;
  observable_type?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  root_cause?: Maybe<Sf_Support_Root_Causes_Order_By>;
  root_cause_id?: Maybe<Order_By>;
  ticket_class_id?: Maybe<Order_By>;
  ticket_created?: Maybe<Order_By>;
  ticket_id?: Maybe<Order_By>;
  ticket_status_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

export type Sf_Support_Tickets_Pk_Columns_Input = {
  ticket_id: Scalars["Int"];
};

export enum Sf_Support_Tickets_Select_Column {
  AcceloId = "accelo_id",
  ActionId = "action_id",
  ClaimedAt = "claimed_at",
  ClickupTaskId = "clickup_task_id",
  Comment = "comment",
  CreatedAt = "created_at",
  DeletedAt = "deleted_at",
  ExcludeFromVhs = "excludeFromVHS",
  IsClaimed = "is_claimed",
  LastDetected = "last_detected",
  LocationId = "location_id",
  ObservableId = "observable_id",
  ObservableType = "observable_type",
  Priority = "priority",
  RootCauseId = "root_cause_id",
  TicketClassId = "ticket_class_id",
  TicketCreated = "ticket_created",
  TicketId = "ticket_id",
  TicketStatusId = "ticket_status_id",
  Title = "title",
  Type = "type",
  UpdatedAt = "updated_at",
}

export type Sf_Support_Tickets_Set_Input = {
  accelo_id?: Maybe<Scalars["Int"]>;
  action_id?: Maybe<Scalars["Int"]>;
  claimed_at?: Maybe<Scalars["timestamptz"]>;
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamp"]>;
  excludeFromVHS?: Maybe<Scalars["Boolean"]>;
  is_claimed?: Maybe<Scalars["Boolean"]>;
  last_detected?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  observable_id?: Maybe<Scalars["String"]>;
  observable_type?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  root_cause_id?: Maybe<Scalars["Int"]>;
  ticket_class_id?: Maybe<Scalars["Int"]>;
  ticket_created?: Maybe<Scalars["timestamp"]>;
  ticket_id?: Maybe<Scalars["Int"]>;
  ticket_status_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Tickets_Stddev_Fields = {
  __typename?: "sf_support_tickets_stddev_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  action_id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
  root_cause_id?: Maybe<Scalars["Float"]>;
  ticket_class_id?: Maybe<Scalars["Float"]>;
  ticket_id?: Maybe<Scalars["Float"]>;
  ticket_status_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Tickets_Stddev_Pop_Fields = {
  __typename?: "sf_support_tickets_stddev_pop_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  action_id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
  root_cause_id?: Maybe<Scalars["Float"]>;
  ticket_class_id?: Maybe<Scalars["Float"]>;
  ticket_id?: Maybe<Scalars["Float"]>;
  ticket_status_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Tickets_Stddev_Samp_Fields = {
  __typename?: "sf_support_tickets_stddev_samp_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  action_id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
  root_cause_id?: Maybe<Scalars["Float"]>;
  ticket_class_id?: Maybe<Scalars["Float"]>;
  ticket_id?: Maybe<Scalars["Float"]>;
  ticket_status_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Tickets_Stream_Cursor_Input = {
  initial_value: Sf_Support_Tickets_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Support_Tickets_Stream_Cursor_Value_Input = {
  accelo_id?: Maybe<Scalars["Int"]>;
  action_id?: Maybe<Scalars["Int"]>;
  claimed_at?: Maybe<Scalars["timestamptz"]>;
  clickup_task_id?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamp"]>;
  excludeFromVHS?: Maybe<Scalars["Boolean"]>;
  is_claimed?: Maybe<Scalars["Boolean"]>;
  last_detected?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  observable_id?: Maybe<Scalars["String"]>;
  observable_type?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  root_cause_id?: Maybe<Scalars["Int"]>;
  ticket_class_id?: Maybe<Scalars["Int"]>;
  ticket_created?: Maybe<Scalars["timestamp"]>;
  ticket_id?: Maybe<Scalars["Int"]>;
  ticket_status_id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Support_Tickets_Sum_Fields = {
  __typename?: "sf_support_tickets_sum_fields";
  accelo_id?: Maybe<Scalars["Int"]>;
  action_id?: Maybe<Scalars["Int"]>;
  priority?: Maybe<Scalars["Int"]>;
  root_cause_id?: Maybe<Scalars["Int"]>;
  ticket_class_id?: Maybe<Scalars["Int"]>;
  ticket_id?: Maybe<Scalars["Int"]>;
  ticket_status_id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Support_Tickets_Update_Column {
  AcceloId = "accelo_id",
  ActionId = "action_id",
  ClaimedAt = "claimed_at",
  ClickupTaskId = "clickup_task_id",
  Comment = "comment",
  CreatedAt = "created_at",
  DeletedAt = "deleted_at",
  ExcludeFromVhs = "excludeFromVHS",
  IsClaimed = "is_claimed",
  LastDetected = "last_detected",
  LocationId = "location_id",
  ObservableId = "observable_id",
  ObservableType = "observable_type",
  Priority = "priority",
  RootCauseId = "root_cause_id",
  TicketClassId = "ticket_class_id",
  TicketCreated = "ticket_created",
  TicketId = "ticket_id",
  TicketStatusId = "ticket_status_id",
  Title = "title",
  Type = "type",
  UpdatedAt = "updated_at",
}

export type Sf_Support_Tickets_Updates = {
  _inc?: Maybe<Sf_Support_Tickets_Inc_Input>;
  _set?: Maybe<Sf_Support_Tickets_Set_Input>;
  where: Sf_Support_Tickets_Bool_Exp;
};

export type Sf_Support_Tickets_Var_Pop_Fields = {
  __typename?: "sf_support_tickets_var_pop_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  action_id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
  root_cause_id?: Maybe<Scalars["Float"]>;
  ticket_class_id?: Maybe<Scalars["Float"]>;
  ticket_id?: Maybe<Scalars["Float"]>;
  ticket_status_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Tickets_Var_Samp_Fields = {
  __typename?: "sf_support_tickets_var_samp_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  action_id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
  root_cause_id?: Maybe<Scalars["Float"]>;
  ticket_class_id?: Maybe<Scalars["Float"]>;
  ticket_id?: Maybe<Scalars["Float"]>;
  ticket_status_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Support_Tickets_Variance_Fields = {
  __typename?: "sf_support_tickets_variance_fields";
  accelo_id?: Maybe<Scalars["Float"]>;
  action_id?: Maybe<Scalars["Float"]>;
  priority?: Maybe<Scalars["Float"]>;
  root_cause_id?: Maybe<Scalars["Float"]>;
  ticket_class_id?: Maybe<Scalars["Float"]>;
  ticket_id?: Maybe<Scalars["Float"]>;
  ticket_status_id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig = {
  __typename?: "sf_testjig_test_jig";
  description?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  testJigId: Scalars["String"];
  testJigType: Scalars["Int"];
};

export type Sf_Testjig_Test_Jig_Aggregate = {
  __typename?: "sf_testjig_test_jig_aggregate";
  aggregate?: Maybe<Sf_Testjig_Test_Jig_Aggregate_Fields>;
  nodes: Array<Sf_Testjig_Test_Jig>;
};

export type Sf_Testjig_Test_Jig_Aggregate_Fields = {
  __typename?: "sf_testjig_test_jig_aggregate_fields";
  avg?: Maybe<Sf_Testjig_Test_Jig_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Testjig_Test_Jig_Max_Fields>;
  min?: Maybe<Sf_Testjig_Test_Jig_Min_Fields>;
  stddev?: Maybe<Sf_Testjig_Test_Jig_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Testjig_Test_Jig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Testjig_Test_Jig_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Testjig_Test_Jig_Sum_Fields>;
  var_pop?: Maybe<Sf_Testjig_Test_Jig_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Testjig_Test_Jig_Var_Samp_Fields>;
  variance?: Maybe<Sf_Testjig_Test_Jig_Variance_Fields>;
};

export type Sf_Testjig_Test_Jig_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Testjig_Test_Jig_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Testjig_Test_Jig_Avg_Fields = {
  __typename?: "sf_testjig_test_jig_avg_fields";
  testJigType?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Bool_Exp = {
  _and?: Maybe<Array<Sf_Testjig_Test_Jig_Bool_Exp>>;
  _not?: Maybe<Sf_Testjig_Test_Jig_Bool_Exp>;
  _or?: Maybe<Array<Sf_Testjig_Test_Jig_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  location?: Maybe<String_Comparison_Exp>;
  testJigId?: Maybe<String_Comparison_Exp>;
  testJigType?: Maybe<Int_Comparison_Exp>;
};

export enum Sf_Testjig_Test_Jig_Constraint {
  TestJigPkey = "test_jig_pkey",
}

export type Sf_Testjig_Test_Jig_Inc_Input = {
  testJigType?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Insert_Input = {
  description?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  testJigType?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report = {
  __typename?: "sf_testjig_test_jig_maintenance_report";
  id: Scalars["uuid"];
  reportLink?: Maybe<Scalars["String"]>;
  testJig: Sf_Testjig_Test_Jig;
  testJigId: Scalars["String"];
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Aggregate = {
  __typename?: "sf_testjig_test_jig_maintenance_report_aggregate";
  aggregate?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Aggregate_Fields>;
  nodes: Array<Sf_Testjig_Test_Jig_Maintenance_Report>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Aggregate_Fields = {
  __typename?: "sf_testjig_test_jig_maintenance_report_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Max_Fields>;
  min?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Min_Fields>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp = {
  _and?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp>>;
  _not?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp>;
  _or?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  reportLink?: Maybe<String_Comparison_Exp>;
  testJig?: Maybe<Sf_Testjig_Test_Jig_Bool_Exp>;
  testJigId?: Maybe<String_Comparison_Exp>;
  uploadTime?: Maybe<Timestamptz_Comparison_Exp>;
};

export enum Sf_Testjig_Test_Jig_Maintenance_Report_Constraint {
  TestJigMaintenanceReportPkey = "test_jig_maintenance_report_pkey",
}

export type Sf_Testjig_Test_Jig_Maintenance_Report_Insert_Input = {
  id?: Maybe<Scalars["uuid"]>;
  reportLink?: Maybe<Scalars["String"]>;
  testJig?: Maybe<Sf_Testjig_Test_Jig_Obj_Rel_Insert_Input>;
  testJigId?: Maybe<Scalars["String"]>;
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Max_Fields = {
  __typename?: "sf_testjig_test_jig_maintenance_report_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  reportLink?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Min_Fields = {
  __typename?: "sf_testjig_test_jig_maintenance_report_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  reportLink?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Mutation_Response = {
  __typename?: "sf_testjig_test_jig_maintenance_report_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Testjig_Test_Jig_Maintenance_Report>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_On_Conflict = {
  constraint: Sf_Testjig_Test_Jig_Maintenance_Report_Constraint;
  update_columns: Array<Sf_Testjig_Test_Jig_Maintenance_Report_Update_Column>;
  where?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Order_By = {
  id?: Maybe<Order_By>;
  reportLink?: Maybe<Order_By>;
  testJig?: Maybe<Sf_Testjig_Test_Jig_Order_By>;
  testJigId?: Maybe<Order_By>;
  uploadTime?: Maybe<Order_By>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sf_Testjig_Test_Jig_Maintenance_Report_Select_Column {
  Id = "id",
  ReportLink = "reportLink",
  TestJigId = "testJigId",
  UploadTime = "uploadTime",
}

export type Sf_Testjig_Test_Jig_Maintenance_Report_Set_Input = {
  id?: Maybe<Scalars["uuid"]>;
  reportLink?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Stream_Cursor_Input = {
  initial_value: Sf_Testjig_Test_Jig_Maintenance_Report_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Testjig_Test_Jig_Maintenance_Report_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["uuid"]>;
  reportLink?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  uploadTime?: Maybe<Scalars["timestamptz"]>;
};

export enum Sf_Testjig_Test_Jig_Maintenance_Report_Update_Column {
  Id = "id",
  ReportLink = "reportLink",
  TestJigId = "testJigId",
  UploadTime = "uploadTime",
}

export type Sf_Testjig_Test_Jig_Maintenance_Report_Updates = {
  _set?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Set_Input>;
  where: Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp;
};

export type Sf_Testjig_Test_Jig_Max_Fields = {
  __typename?: "sf_testjig_test_jig_max_fields";
  description?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  testJigType?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Min_Fields = {
  __typename?: "sf_testjig_test_jig_min_fields";
  description?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  testJigType?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Mutation_Response = {
  __typename?: "sf_testjig_test_jig_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Testjig_Test_Jig>;
};

export type Sf_Testjig_Test_Jig_Obj_Rel_Insert_Input = {
  data: Sf_Testjig_Test_Jig_Insert_Input;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_On_Conflict>;
};

export type Sf_Testjig_Test_Jig_On_Conflict = {
  constraint: Sf_Testjig_Test_Jig_Constraint;
  update_columns: Array<Sf_Testjig_Test_Jig_Update_Column>;
  where?: Maybe<Sf_Testjig_Test_Jig_Bool_Exp>;
};

export type Sf_Testjig_Test_Jig_Order_By = {
  description?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  testJigId?: Maybe<Order_By>;
  testJigType?: Maybe<Order_By>;
};

export type Sf_Testjig_Test_Jig_Pk_Columns_Input = {
  testJigId: Scalars["String"];
};

export type Sf_Testjig_Test_Jig_Report_Metadata = {
  __typename?: "sf_testjig_test_jig_report_metadata";
  comment?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  isPassed: Scalars["Boolean"];
  metadataId: Scalars["Int"];
  testJigReportId: Scalars["uuid"];
  testJigReportMetadata: Sf_Testjig_Test_Jig_Report_Metadatas;
  testJigTestReport: Sf_Testjig_Test_Jig_Test_Report;
  value: Scalars["String"];
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Aggregate = {
  __typename?: "sf_testjig_test_jig_report_metadata_aggregate";
  aggregate?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Aggregate_Fields>;
  nodes: Array<Sf_Testjig_Test_Jig_Report_Metadata>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Aggregate_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_aggregate_fields";
  avg?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Max_Fields>;
  min?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Min_Fields>;
  stddev?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Sum_Fields>;
  var_pop?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Var_Samp_Fields>;
  variance?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Variance_Fields>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Avg_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_avg_fields";
  metadataId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp = {
  _and?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp>>;
  _not?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp>;
  _or?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isPassed?: Maybe<Boolean_Comparison_Exp>;
  metadataId?: Maybe<Int_Comparison_Exp>;
  testJigReportId?: Maybe<Uuid_Comparison_Exp>;
  testJigReportMetadata?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp>;
  testJigTestReport?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Testjig_Test_Jig_Report_Metadata_Constraint {
  TestJigReportMetadataPkey = "test_jig_report_metadata_pkey",
}

export type Sf_Testjig_Test_Jig_Report_Metadata_Inc_Input = {
  metadataId?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Insert_Input = {
  comment?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  isPassed?: Maybe<Scalars["Boolean"]>;
  metadataId?: Maybe<Scalars["Int"]>;
  testJigReportId?: Maybe<Scalars["uuid"]>;
  testJigReportMetadata?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Obj_Rel_Insert_Input>;
  testJigTestReport?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Obj_Rel_Insert_Input>;
  value?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Max_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_max_fields";
  comment?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  metadataId?: Maybe<Scalars["Int"]>;
  testJigReportId?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Min_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_min_fields";
  comment?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  metadataId?: Maybe<Scalars["Int"]>;
  testJigReportId?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Mutation_Response = {
  __typename?: "sf_testjig_test_jig_report_metadata_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Testjig_Test_Jig_Report_Metadata>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_On_Conflict = {
  constraint: Sf_Testjig_Test_Jig_Report_Metadata_Constraint;
  update_columns: Array<Sf_Testjig_Test_Jig_Report_Metadata_Update_Column>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Order_By = {
  comment?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isPassed?: Maybe<Order_By>;
  metadataId?: Maybe<Order_By>;
  testJigReportId?: Maybe<Order_By>;
  testJigReportMetadata?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Order_By>;
  testJigTestReport?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Order_By>;
  value?: Maybe<Order_By>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sf_Testjig_Test_Jig_Report_Metadata_Select_Column {
  Comment = "comment",
  Id = "id",
  IsPassed = "isPassed",
  MetadataId = "metadataId",
  TestJigReportId = "testJigReportId",
  Value = "value",
}

export type Sf_Testjig_Test_Jig_Report_Metadata_Set_Input = {
  comment?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  isPassed?: Maybe<Scalars["Boolean"]>;
  metadataId?: Maybe<Scalars["Int"]>;
  testJigReportId?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Stddev_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_stddev_fields";
  metadataId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Stddev_Pop_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_stddev_pop_fields";
  metadataId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Stddev_Samp_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_stddev_samp_fields";
  metadataId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Stream_Cursor_Input = {
  initial_value: Sf_Testjig_Test_Jig_Report_Metadata_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  isPassed?: Maybe<Scalars["Boolean"]>;
  metadataId?: Maybe<Scalars["Int"]>;
  testJigReportId?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Sum_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_sum_fields";
  metadataId?: Maybe<Scalars["Int"]>;
};

export enum Sf_Testjig_Test_Jig_Report_Metadata_Update_Column {
  Comment = "comment",
  Id = "id",
  IsPassed = "isPassed",
  MetadataId = "metadataId",
  TestJigReportId = "testJigReportId",
  Value = "value",
}

export type Sf_Testjig_Test_Jig_Report_Metadata_Updates = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Set_Input>;
  where: Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Var_Pop_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_var_pop_fields";
  metadataId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Var_Samp_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_var_samp_fields";
  metadataId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadata_Variance_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadata_variance_fields";
  metadataId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas = {
  __typename?: "sf_testjig_test_jig_report_metadatas";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isString: Scalars["Boolean"];
  metadata: Scalars["String"];
  thresholdMax?: Maybe<Scalars["String"]>;
  thresholdMin?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Aggregate = {
  __typename?: "sf_testjig_test_jig_report_metadatas_aggregate";
  aggregate?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Aggregate_Fields>;
  nodes: Array<Sf_Testjig_Test_Jig_Report_Metadatas>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Aggregate_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_aggregate_fields";
  avg?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Max_Fields>;
  min?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Min_Fields>;
  stddev?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Sum_Fields>;
  var_pop?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Var_Samp_Fields>;
  variance?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Variance_Fields>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Avg_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp = {
  _and?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp>>;
  _not?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp>;
  _or?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  isString?: Maybe<Boolean_Comparison_Exp>;
  metadata?: Maybe<String_Comparison_Exp>;
  thresholdMax?: Maybe<String_Comparison_Exp>;
  thresholdMin?: Maybe<String_Comparison_Exp>;
  unit?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Testjig_Test_Jig_Report_Metadatas_Constraint {
  TestJigReportMetadatasMetadataUniqueIdx = "test_jig_report_metadatas_metadata_unique_idx",
  TestJigReportMetadatasPkey = "test_jig_report_metadatas_pkey",
}

export type Sf_Testjig_Test_Jig_Report_Metadatas_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Insert_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  isString?: Maybe<Scalars["Boolean"]>;
  metadata?: Maybe<Scalars["String"]>;
  thresholdMax?: Maybe<Scalars["String"]>;
  thresholdMin?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Max_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  metadata?: Maybe<Scalars["String"]>;
  thresholdMax?: Maybe<Scalars["String"]>;
  thresholdMin?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Min_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  metadata?: Maybe<Scalars["String"]>;
  thresholdMax?: Maybe<Scalars["String"]>;
  thresholdMin?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Mutation_Response = {
  __typename?: "sf_testjig_test_jig_report_metadatas_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Testjig_Test_Jig_Report_Metadatas>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Obj_Rel_Insert_Input = {
  data: Sf_Testjig_Test_Jig_Report_Metadatas_Insert_Input;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_On_Conflict>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_On_Conflict = {
  constraint: Sf_Testjig_Test_Jig_Report_Metadatas_Constraint;
  update_columns: Array<Sf_Testjig_Test_Jig_Report_Metadatas_Update_Column>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isString?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  thresholdMax?: Maybe<Order_By>;
  thresholdMin?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sf_Testjig_Test_Jig_Report_Metadatas_Select_Column {
  Description = "description",
  Id = "id",
  IsString = "isString",
  Metadata = "metadata",
  ThresholdMax = "thresholdMax",
  ThresholdMin = "thresholdMin",
  Unit = "unit",
}

export type Sf_Testjig_Test_Jig_Report_Metadatas_Set_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  isString?: Maybe<Scalars["Boolean"]>;
  metadata?: Maybe<Scalars["String"]>;
  thresholdMax?: Maybe<Scalars["String"]>;
  thresholdMin?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Stddev_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Stddev_Pop_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Stddev_Samp_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Stream_Cursor_Input = {
  initial_value: Sf_Testjig_Test_Jig_Report_Metadatas_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  isString?: Maybe<Scalars["Boolean"]>;
  metadata?: Maybe<Scalars["String"]>;
  thresholdMax?: Maybe<Scalars["String"]>;
  thresholdMin?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Sum_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Testjig_Test_Jig_Report_Metadatas_Update_Column {
  Description = "description",
  Id = "id",
  IsString = "isString",
  Metadata = "metadata",
  ThresholdMax = "thresholdMax",
  ThresholdMin = "thresholdMin",
  Unit = "unit",
}

export type Sf_Testjig_Test_Jig_Report_Metadatas_Updates = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Set_Input>;
  where: Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Var_Pop_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Var_Samp_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Report_Metadatas_Variance_Fields = {
  __typename?: "sf_testjig_test_jig_report_metadatas_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export enum Sf_Testjig_Test_Jig_Select_Column {
  Description = "description",
  Location = "location",
  TestJigId = "testJigId",
  TestJigType = "testJigType",
}

export type Sf_Testjig_Test_Jig_Set_Input = {
  description?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  testJigType?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Stddev_Fields = {
  __typename?: "sf_testjig_test_jig_stddev_fields";
  testJigType?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Stddev_Pop_Fields = {
  __typename?: "sf_testjig_test_jig_stddev_pop_fields";
  testJigType?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Stddev_Samp_Fields = {
  __typename?: "sf_testjig_test_jig_stddev_samp_fields";
  testJigType?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Stream_Cursor_Input = {
  initial_value: Sf_Testjig_Test_Jig_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Testjig_Test_Jig_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  testJigType?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Sum_Fields = {
  __typename?: "sf_testjig_test_jig_sum_fields";
  testJigType?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report = {
  __typename?: "sf_testjig_test_jig_test_report";
  comment?: Maybe<Scalars["String"]>;
  entryTime: Scalars["timestamptz"];
  id: Scalars["uuid"];
  nodeLifeCycleEvent?: Maybe<Sensorflow_Node_Life_Cycle_Events>;
  nodeLifeCycleEventId?: Maybe<Scalars["Int"]>;
  nodeMacId: Scalars["String"];
  testJig: Sf_Testjig_Test_Jig;
  testJigId: Scalars["String"];
  testResult: Scalars["String"];
};

export type Sf_Testjig_Test_Jig_Test_Report_Aggregate = {
  __typename?: "sf_testjig_test_jig_test_report_aggregate";
  aggregate?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Aggregate_Fields>;
  nodes: Array<Sf_Testjig_Test_Jig_Test_Report>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Aggregate_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_aggregate_fields";
  avg?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Max_Fields>;
  min?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Min_Fields>;
  stddev?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Sum_Fields>;
  var_pop?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Var_Samp_Fields>;
  variance?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Variance_Fields>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Avg_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_avg_fields";
  nodeLifeCycleEventId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Bool_Exp = {
  _and?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Bool_Exp>>;
  _not?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Bool_Exp>;
  _or?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  entryTime?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  nodeLifeCycleEvent?: Maybe<Sensorflow_Node_Life_Cycle_Events_Bool_Exp>;
  nodeLifeCycleEventId?: Maybe<Int_Comparison_Exp>;
  nodeMacId?: Maybe<String_Comparison_Exp>;
  testJig?: Maybe<Sf_Testjig_Test_Jig_Bool_Exp>;
  testJigId?: Maybe<String_Comparison_Exp>;
  testResult?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Testjig_Test_Jig_Test_Report_Constraint {
  TestJigTestReportPkey = "test_jig_test_report_pkey",
}

export type Sf_Testjig_Test_Jig_Test_Report_Inc_Input = {
  nodeLifeCycleEventId?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Insert_Input = {
  comment?: Maybe<Scalars["String"]>;
  entryTime?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  nodeLifeCycleEvent?: Maybe<Sensorflow_Node_Life_Cycle_Events_Obj_Rel_Insert_Input>;
  nodeLifeCycleEventId?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  testJig?: Maybe<Sf_Testjig_Test_Jig_Obj_Rel_Insert_Input>;
  testJigId?: Maybe<Scalars["String"]>;
  testResult?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Max_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_max_fields";
  comment?: Maybe<Scalars["String"]>;
  entryTime?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  nodeLifeCycleEventId?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  testResult?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Min_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_min_fields";
  comment?: Maybe<Scalars["String"]>;
  entryTime?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  nodeLifeCycleEventId?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  testResult?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Mutation_Response = {
  __typename?: "sf_testjig_test_jig_test_report_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Testjig_Test_Jig_Test_Report>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Obj_Rel_Insert_Input = {
  data: Sf_Testjig_Test_Jig_Test_Report_Insert_Input;
  on_conflict?: Maybe<Sf_Testjig_Test_Jig_Test_Report_On_Conflict>;
};

export type Sf_Testjig_Test_Jig_Test_Report_On_Conflict = {
  constraint: Sf_Testjig_Test_Jig_Test_Report_Constraint;
  update_columns: Array<Sf_Testjig_Test_Jig_Test_Report_Update_Column>;
  where?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Bool_Exp>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Order_By = {
  comment?: Maybe<Order_By>;
  entryTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  nodeLifeCycleEvent?: Maybe<Sensorflow_Node_Life_Cycle_Events_Order_By>;
  nodeLifeCycleEventId?: Maybe<Order_By>;
  nodeMacId?: Maybe<Order_By>;
  testJig?: Maybe<Sf_Testjig_Test_Jig_Order_By>;
  testJigId?: Maybe<Order_By>;
  testResult?: Maybe<Order_By>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

export enum Sf_Testjig_Test_Jig_Test_Report_Select_Column {
  Comment = "comment",
  EntryTime = "entryTime",
  Id = "id",
  NodeLifeCycleEventId = "nodeLifeCycleEventId",
  NodeMacId = "nodeMacId",
  TestJigId = "testJigId",
  TestResult = "testResult",
}

export type Sf_Testjig_Test_Jig_Test_Report_Set_Input = {
  comment?: Maybe<Scalars["String"]>;
  entryTime?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  nodeLifeCycleEventId?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  testResult?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Stddev_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_stddev_fields";
  nodeLifeCycleEventId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Stddev_Pop_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_stddev_pop_fields";
  nodeLifeCycleEventId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Stddev_Samp_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_stddev_samp_fields";
  nodeLifeCycleEventId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Stream_Cursor_Input = {
  initial_value: Sf_Testjig_Test_Jig_Test_Report_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars["String"]>;
  entryTime?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  nodeLifeCycleEventId?: Maybe<Scalars["Int"]>;
  nodeMacId?: Maybe<Scalars["String"]>;
  testJigId?: Maybe<Scalars["String"]>;
  testResult?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Sum_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_sum_fields";
  nodeLifeCycleEventId?: Maybe<Scalars["Int"]>;
};

export enum Sf_Testjig_Test_Jig_Test_Report_Update_Column {
  Comment = "comment",
  EntryTime = "entryTime",
  Id = "id",
  NodeLifeCycleEventId = "nodeLifeCycleEventId",
  NodeMacId = "nodeMacId",
  TestJigId = "testJigId",
  TestResult = "testResult",
}

export type Sf_Testjig_Test_Jig_Test_Report_Updates = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Set_Input>;
  where: Sf_Testjig_Test_Jig_Test_Report_Bool_Exp;
};

export type Sf_Testjig_Test_Jig_Test_Report_Var_Pop_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_var_pop_fields";
  nodeLifeCycleEventId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Var_Samp_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_var_samp_fields";
  nodeLifeCycleEventId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Test_Report_Variance_Fields = {
  __typename?: "sf_testjig_test_jig_test_report_variance_fields";
  nodeLifeCycleEventId?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Type = {
  __typename?: "sf_testjig_test_jig_type";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type Sf_Testjig_Test_Jig_Type_Aggregate = {
  __typename?: "sf_testjig_test_jig_type_aggregate";
  aggregate?: Maybe<Sf_Testjig_Test_Jig_Type_Aggregate_Fields>;
  nodes: Array<Sf_Testjig_Test_Jig_Type>;
};

export type Sf_Testjig_Test_Jig_Type_Aggregate_Fields = {
  __typename?: "sf_testjig_test_jig_type_aggregate_fields";
  avg?: Maybe<Sf_Testjig_Test_Jig_Type_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sf_Testjig_Test_Jig_Type_Max_Fields>;
  min?: Maybe<Sf_Testjig_Test_Jig_Type_Min_Fields>;
  stddev?: Maybe<Sf_Testjig_Test_Jig_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Sf_Testjig_Test_Jig_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sf_Testjig_Test_Jig_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Sf_Testjig_Test_Jig_Type_Sum_Fields>;
  var_pop?: Maybe<Sf_Testjig_Test_Jig_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Sf_Testjig_Test_Jig_Type_Var_Samp_Fields>;
  variance?: Maybe<Sf_Testjig_Test_Jig_Type_Variance_Fields>;
};

export type Sf_Testjig_Test_Jig_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Testjig_Test_Jig_Type_Avg_Fields = {
  __typename?: "sf_testjig_test_jig_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Type_Bool_Exp = {
  _and?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Bool_Exp>>;
  _not?: Maybe<Sf_Testjig_Test_Jig_Type_Bool_Exp>;
  _or?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

export enum Sf_Testjig_Test_Jig_Type_Constraint {
  TestJigTypePkey = "test_jig_type_pkey",
}

export type Sf_Testjig_Test_Jig_Type_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

export type Sf_Testjig_Test_Jig_Type_Insert_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Type_Max_Fields = {
  __typename?: "sf_testjig_test_jig_type_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Type_Min_Fields = {
  __typename?: "sf_testjig_test_jig_type_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Type_Mutation_Response = {
  __typename?: "sf_testjig_test_jig_type_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<Sf_Testjig_Test_Jig_Type>;
};

export type Sf_Testjig_Test_Jig_Type_On_Conflict = {
  constraint: Sf_Testjig_Test_Jig_Type_Constraint;
  update_columns: Array<Sf_Testjig_Test_Jig_Type_Update_Column>;
  where?: Maybe<Sf_Testjig_Test_Jig_Type_Bool_Exp>;
};

export type Sf_Testjig_Test_Jig_Type_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

export type Sf_Testjig_Test_Jig_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

export enum Sf_Testjig_Test_Jig_Type_Select_Column {
  Description = "description",
  Id = "id",
  Name = "name",
}

export type Sf_Testjig_Test_Jig_Type_Set_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Type_Stddev_Fields = {
  __typename?: "sf_testjig_test_jig_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Type_Stddev_Pop_Fields = {
  __typename?: "sf_testjig_test_jig_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Type_Stddev_Samp_Fields = {
  __typename?: "sf_testjig_test_jig_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Type_Stream_Cursor_Input = {
  initial_value: Sf_Testjig_Test_Jig_Type_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Testjig_Test_Jig_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Sf_Testjig_Test_Jig_Type_Sum_Fields = {
  __typename?: "sf_testjig_test_jig_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

export enum Sf_Testjig_Test_Jig_Type_Update_Column {
  Description = "description",
  Id = "id",
  Name = "name",
}

export type Sf_Testjig_Test_Jig_Type_Updates = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Type_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Type_Set_Input>;
  where: Sf_Testjig_Test_Jig_Type_Bool_Exp;
};

export type Sf_Testjig_Test_Jig_Type_Var_Pop_Fields = {
  __typename?: "sf_testjig_test_jig_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Type_Var_Samp_Fields = {
  __typename?: "sf_testjig_test_jig_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Type_Variance_Fields = {
  __typename?: "sf_testjig_test_jig_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export enum Sf_Testjig_Test_Jig_Update_Column {
  Description = "description",
  Location = "location",
  TestJigId = "testJigId",
  TestJigType = "testJigType",
}

export type Sf_Testjig_Test_Jig_Updates = {
  _inc?: Maybe<Sf_Testjig_Test_Jig_Inc_Input>;
  _set?: Maybe<Sf_Testjig_Test_Jig_Set_Input>;
  where: Sf_Testjig_Test_Jig_Bool_Exp;
};

export type Sf_Testjig_Test_Jig_Var_Pop_Fields = {
  __typename?: "sf_testjig_test_jig_var_pop_fields";
  testJigType?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Var_Samp_Fields = {
  __typename?: "sf_testjig_test_jig_var_samp_fields";
  testJigType?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_Test_Jig_Variance_Fields = {
  __typename?: "sf_testjig_test_jig_variance_fields";
  testJigType?: Maybe<Scalars["Float"]>;
};

export type Sf_Testjig_V_Latest_Report_Rma_Nodes = {
  __typename?: "sf_testjig_v_latest_report_rma_nodes";
  entry_time?: Maybe<Scalars["timestamptz"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  report_id?: Maybe<Scalars["uuid"]>;
  rma?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Testjig_V_Latest_Report_Rma_Nodes_Aggregate = {
  __typename?: "sf_testjig_v_latest_report_rma_nodes_aggregate";
  aggregate?: Maybe<Sf_Testjig_V_Latest_Report_Rma_Nodes_Aggregate_Fields>;
  nodes: Array<Sf_Testjig_V_Latest_Report_Rma_Nodes>;
};

export type Sf_Testjig_V_Latest_Report_Rma_Nodes_Aggregate_Fields = {
  __typename?: "sf_testjig_v_latest_report_rma_nodes_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sf_Testjig_V_Latest_Report_Rma_Nodes_Max_Fields>;
  min?: Maybe<Sf_Testjig_V_Latest_Report_Rma_Nodes_Min_Fields>;
};

export type Sf_Testjig_V_Latest_Report_Rma_Nodes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Sf_Testjig_V_Latest_Report_Rma_Nodes_Bool_Exp = {
  _and?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Bool_Exp>>;
  _not?: Maybe<Sf_Testjig_V_Latest_Report_Rma_Nodes_Bool_Exp>;
  _or?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Bool_Exp>>;
  entry_time?: Maybe<Timestamptz_Comparison_Exp>;
  node_mac_id?: Maybe<String_Comparison_Exp>;
  report_id?: Maybe<Uuid_Comparison_Exp>;
  rma?: Maybe<Boolean_Comparison_Exp>;
};

export type Sf_Testjig_V_Latest_Report_Rma_Nodes_Max_Fields = {
  __typename?: "sf_testjig_v_latest_report_rma_nodes_max_fields";
  entry_time?: Maybe<Scalars["timestamptz"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  report_id?: Maybe<Scalars["uuid"]>;
};

export type Sf_Testjig_V_Latest_Report_Rma_Nodes_Min_Fields = {
  __typename?: "sf_testjig_v_latest_report_rma_nodes_min_fields";
  entry_time?: Maybe<Scalars["timestamptz"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  report_id?: Maybe<Scalars["uuid"]>;
};

export type Sf_Testjig_V_Latest_Report_Rma_Nodes_Order_By = {
  entry_time?: Maybe<Order_By>;
  node_mac_id?: Maybe<Order_By>;
  report_id?: Maybe<Order_By>;
  rma?: Maybe<Order_By>;
};

export enum Sf_Testjig_V_Latest_Report_Rma_Nodes_Select_Column {
  EntryTime = "entry_time",
  NodeMacId = "node_mac_id",
  ReportId = "report_id",
  Rma = "rma",
}

export type Sf_Testjig_V_Latest_Report_Rma_Nodes_Stream_Cursor_Input = {
  initial_value: Sf_Testjig_V_Latest_Report_Rma_Nodes_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type Sf_Testjig_V_Latest_Report_Rma_Nodes_Stream_Cursor_Value_Input = {
  entry_time?: Maybe<Scalars["timestamptz"]>;
  node_mac_id?: Maybe<Scalars["String"]>;
  report_id?: Maybe<Scalars["uuid"]>;
  rma?: Maybe<Scalars["Boolean"]>;
};

export type Slot = {
  __typename?: "Slot";
  creationDate: Scalars["String"];
  currentMappedNode?: Maybe<MappedNode>;
  id: Scalars["ID"];
  isCurrentRapidMappingSlot: Scalars["Boolean"];
  mappingStatus: SlotRapidMappingStatus;
  nodeSubType?: Maybe<NodeSubType>;
  nodeType: NodeType;
  nodeTypeCodeIdentifier: NodeTypeCodeIdentifier;
  removedDate?: Maybe<Scalars["String"]>;
  roomId: Scalars["ID"];
  skippedRapidMappingSlot?: Maybe<Slot>;
  slotName: Scalars["String"];
};

export type SlotCreateInput = {
  nodeType: Scalars["String"];
  position: PositionInput;
  slotName: Scalars["String"];
};

export type SlotCreateRoomInput = {
  nodeSubType?: Maybe<Scalars["String"]>;
  nodeType: NodeType;
  slotName?: Maybe<Scalars["String"]>;
};

export enum SlotRapidMappingStatus {
  Mapped = "MAPPED",
  NotStarted = "NOT_STARTED",
  Skipped = "SKIPPED",
}

export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars["smallint"]>;
  _gt?: Maybe<Scalars["smallint"]>;
  _gte?: Maybe<Scalars["smallint"]>;
  _in?: Maybe<Array<Scalars["smallint"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["smallint"]>;
  _lte?: Maybe<Scalars["smallint"]>;
  _neq?: Maybe<Scalars["smallint"]>;
  _nin?: Maybe<Array<Scalars["smallint"]>>;
};

export enum SortOptions {
  Asc = "ASC",
  Desc = "DESC",
}

export type String_Array_Comparison_Exp = {
  _contained_in?: Maybe<Array<Scalars["String"]>>;
  _contains?: Maybe<Array<Scalars["String"]>>;
  _eq?: Maybe<Array<Scalars["String"]>>;
  _gt?: Maybe<Array<Scalars["String"]>>;
  _gte?: Maybe<Array<Scalars["String"]>>;
  _in?: Maybe<Array<Array<Scalars["String"]>>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Array<Scalars["String"]>>;
  _lte?: Maybe<Array<Scalars["String"]>>;
  _neq?: Maybe<Array<Scalars["String"]>>;
  _nin?: Maybe<Array<Array<Scalars["String"]>>>;
};

export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  _iregex?: Maybe<Scalars["String"]>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  _niregex?: Maybe<Scalars["String"]>;
  _nlike?: Maybe<Scalars["String"]>;
  _nregex?: Maybe<Scalars["String"]>;
  _nsimilar?: Maybe<Scalars["String"]>;
  _regex?: Maybe<Scalars["String"]>;
  _similar?: Maybe<Scalars["String"]>;
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  TestJig?: Maybe<Sf_Testjig_Test_Jig>;
  TestJigMaintenanceReport?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report>;
  TestJigMaintenanceReports: Array<Sf_Testjig_Test_Jig_Maintenance_Report>;
  TestJigReportMetadata: Array<Sf_Testjig_Test_Jig_Report_Metadata>;
  TestJigReportMetadatas: Array<Sf_Testjig_Test_Jig_Report_Metadatas>;
  TestJigTestReport?: Maybe<Sf_Testjig_Test_Jig_Test_Report>;
  TestJigTestReports: Array<Sf_Testjig_Test_Jig_Test_Report>;
  TestJigType?: Maybe<Sf_Testjig_Test_Jig_Type>;
  TestJigTypes: Array<Sf_Testjig_Test_Jig_Type>;
  TestJigs: Array<Sf_Testjig_Test_Jig>;
  currentPositionConfiguration: Array<Sensorflow_V_Current_Position_Configurations>;
  domoEmbed?: Maybe<Sensorflow_Domo_Embeds>;
  domoEmbeds: Array<Sensorflow_Domo_Embeds>;
  firmwareReleases: Array<Sensorflow_Firmware_Releases>;
  gatewayKittingProcessMetadata: Array<Sensorflow_Warehouse_Kitting_Process_Metadata>;
  gatewayToPositionMappings: Array<Sensorflow_Gateway_To_Position_Mapping>;
  gateways: Array<Sensorflow_Gateways>;
  getSmartRemValidationHistory: Array<Sensorflow_Smart_Rem_Validation_History>;
  infrastructureEmonPowerStatus: Array<Sensorflow_V_Infrastructure_Emon_Power_Status>;
  infrastructures: Array<Sensorflow_V_Infrastructures>;
  keyCategories: Array<Sensorflow_Key_Categories>;
  keyEntries: Array<KeyEntries>;
  keyEntries_aggregate: KeyEntries_Aggregate;
  keyEntries_by_pk?: Maybe<KeyEntries>;
  keyEntries_stream: Array<KeyEntries>;
  locationDomoEmbed?: Maybe<Sensorflow_Location_Domo_Embeds>;
  locationDomoEmbeds: Array<Sensorflow_Location_Domo_Embeds>;
  locationList: Array<Sensorflow_Locations>;
  locationMetadata?: Maybe<Sensorflow_Location_Metadata>;
  locationOne?: Maybe<Sensorflow_Locations>;
  nodeException: Array<Sensorflow_Node_Exception>;
  nodeExceptionAggregate: Sensorflow_Node_Exception_Aggregate;
  nodeExceptionByPk?: Maybe<Sensorflow_Node_Exception>;
  nodeExceptions: Array<Sensorflow_Node_Exceptions>;
  nodeExceptionsAggregate: Sensorflow_Node_Exceptions_Aggregate;
  nodeExceptionsByPk?: Maybe<Sensorflow_Node_Exceptions>;
  nodeJoinStatuses: Array<Sensorflow_Node_Join_Status>;
  nodeOnlineStatuses: Array<Sensorflow_Node_Online_Status>;
  position?: Maybe<Sensorflow_Positions>;
  positionConfigurations: Array<PositionConfigurations>;
  positionConfigurationsForActivityLog: Array<PositionConfigurationsForActivityLog>;
  positionConfigurationsForActivityLog_aggregate: PositionConfigurationsForActivityLog_Aggregate;
  positionConfigurationsForActivityLog_stream: Array<PositionConfigurationsForActivityLog>;
  positionConfigurations_aggregate: PositionConfigurations_Aggregate;
  positionConfigurations_by_pk?: Maybe<PositionConfigurations>;
  positionConfigurations_stream: Array<PositionConfigurations>;
  positionPrecoolStatus: Array<Sensorflow_Position_Precool_Status>;
  positions: Array<Sensorflow_Positions>;
  positionsAggregate: Sensorflow_Positions_Aggregate;
  root?: Maybe<Scalars["String"]>;
  sensorflow_ac_command_event_stream: Array<Sensorflow_Ac_Command_Event_Stream>;
  sensorflow_ac_command_event_stream_aggregate: Sensorflow_Ac_Command_Event_Stream_Aggregate;
  sensorflow_ac_command_event_stream_stream: Array<Sensorflow_Ac_Command_Event_Stream>;
  sensorflow_ac_models: Array<Sensorflow_Ac_Models>;
  sensorflow_ac_models_aggregate: Sensorflow_Ac_Models_Aggregate;
  sensorflow_ac_models_by_pk?: Maybe<Sensorflow_Ac_Models>;
  sensorflow_ac_models_stream: Array<Sensorflow_Ac_Models>;
  sensorflow_ac_setting_commands: Array<Sensorflow_Ac_Setting_Commands>;
  sensorflow_ac_setting_commands_aggregate: Sensorflow_Ac_Setting_Commands_Aggregate;
  sensorflow_ac_setting_commands_by_pk?: Maybe<Sensorflow_Ac_Setting_Commands>;
  sensorflow_ac_setting_commands_stream: Array<Sensorflow_Ac_Setting_Commands>;
  sensorflow_ai_dt_ac_event_stream: Array<Sensorflow_Ai_Dt_Ac_Event_Stream>;
  sensorflow_ai_dt_ac_event_stream_aggregate: Sensorflow_Ai_Dt_Ac_Event_Stream_Aggregate;
  sensorflow_ai_dt_ac_event_stream_stream: Array<Sensorflow_Ai_Dt_Ac_Event_Stream>;
  sensorflow_ai_dt_config_event_stream: Array<Sensorflow_Ai_Dt_Config_Event_Stream>;
  sensorflow_ai_dt_config_event_stream_aggregate: Sensorflow_Ai_Dt_Config_Event_Stream_Aggregate;
  sensorflow_ai_dt_config_event_stream_stream: Array<Sensorflow_Ai_Dt_Config_Event_Stream>;
  sensorflow_ai_dt_event_stream: Array<Sensorflow_Ai_Dt_Event_Stream>;
  sensorflow_ai_dt_event_stream_aggregate: Sensorflow_Ai_Dt_Event_Stream_Aggregate;
  sensorflow_ai_dt_event_stream_stream: Array<Sensorflow_Ai_Dt_Event_Stream>;
  sensorflow_ai_dt_room_comfort_report: Array<Sensorflow_Ai_Dt_Room_Comfort_Report>;
  sensorflow_ai_dt_room_comfort_report_aggregate: Sensorflow_Ai_Dt_Room_Comfort_Report_Aggregate;
  sensorflow_ai_dt_room_comfort_report_stream: Array<Sensorflow_Ai_Dt_Room_Comfort_Report>;
  sensorflow_ai_dt_synced_raw_data: Array<Sensorflow_Ai_Dt_Synced_Raw_Data>;
  sensorflow_ai_dt_synced_raw_data_aggregate: Sensorflow_Ai_Dt_Synced_Raw_Data_Aggregate;
  sensorflow_ai_dt_synced_raw_data_stream: Array<Sensorflow_Ai_Dt_Synced_Raw_Data>;
  sensorflow_ai_f_get_ac_command_event_stream: Array<Sensorflow_Ai_Dt_Ac_Event_Stream>;
  sensorflow_ai_f_get_ac_command_event_stream_aggregate: Sensorflow_Ai_Dt_Ac_Event_Stream_Aggregate;
  sensorflow_ai_f_get_all_event_stream: Array<Sensorflow_Ai_Dt_Ac_Event_Stream>;
  sensorflow_ai_f_get_all_event_stream_aggregate: Sensorflow_Ai_Dt_Ac_Event_Stream_Aggregate;
  sensorflow_ai_f_get_config_event_stream: Array<Sensorflow_Ai_Dt_Config_Event_Stream>;
  sensorflow_ai_f_get_config_event_stream_aggregate: Sensorflow_Ai_Dt_Config_Event_Stream_Aggregate;
  sensorflow_ai_f_get_event_stream: Array<Sensorflow_Ai_Dt_Event_Stream>;
  sensorflow_ai_f_get_event_stream_aggregate: Sensorflow_Ai_Dt_Event_Stream_Aggregate;
  sensorflow_ai_f_get_id_info: Array<Sensorflow_Autosets_With_Keys>;
  sensorflow_ai_f_get_id_info_aggregate: Sensorflow_Autosets_With_Keys_Aggregate;
  sensorflow_ai_f_get_synced_raw_data: Array<Sensorflow_Ai_Dt_Synced_Raw_Data>;
  sensorflow_ai_f_get_synced_raw_data_aggregate: Sensorflow_Ai_Dt_Synced_Raw_Data_Aggregate;
  sensorflow_ai_f_room_comfort_analysis: Array<Sensorflow_Ai_Dt_Room_Comfort_Report>;
  sensorflow_ai_f_room_comfort_analysis_aggregate: Sensorflow_Ai_Dt_Room_Comfort_Report_Aggregate;
  sensorflow_ai_v_get_precool_command_stream: Array<Sensorflow_Ai_V_Get_Precool_Command_Stream>;
  sensorflow_ai_v_get_precool_command_stream_aggregate: Sensorflow_Ai_V_Get_Precool_Command_Stream_Aggregate;
  sensorflow_ai_v_get_precool_command_stream_stream: Array<Sensorflow_Ai_V_Get_Precool_Command_Stream>;
  sensorflow_ai_v_room_status: Array<Sensorflow_Ai_V_Room_Status>;
  sensorflow_ai_v_room_status_aggregate: Sensorflow_Ai_V_Room_Status_Aggregate;
  sensorflow_ai_v_room_status_stream: Array<Sensorflow_Ai_V_Room_Status>;
  sensorflow_autosets_with_keys: Array<Sensorflow_Autosets_With_Keys>;
  sensorflow_autosets_with_keys_aggregate: Sensorflow_Autosets_With_Keys_Aggregate;
  sensorflow_autosets_with_keys_stream: Array<Sensorflow_Autosets_With_Keys>;
  sensorflow_avg_position_data: Array<Sensorflow_Avg_Position_Data>;
  sensorflow_avg_position_data_aggregate: Sensorflow_Avg_Position_Data_Aggregate;
  sensorflow_avg_position_data_stream: Array<Sensorflow_Avg_Position_Data>;
  sensorflow_beacon_slots: Array<Sensorflow_Beacon_Slots>;
  sensorflow_beacon_slots_aggregate: Sensorflow_Beacon_Slots_Aggregate;
  sensorflow_beacon_slots_by_pk?: Maybe<Sensorflow_Beacon_Slots>;
  sensorflow_beacon_slots_stream: Array<Sensorflow_Beacon_Slots>;
  sensorflow_checklist_items: Array<Sensorflow_Checklist_Items>;
  sensorflow_checklist_items_aggregate: Sensorflow_Checklist_Items_Aggregate;
  sensorflow_checklist_items_stream: Array<Sensorflow_Checklist_Items>;
  sensorflow_checklists: Array<Sensorflow_Checklists>;
  sensorflow_checklists_aggregate: Sensorflow_Checklists_Aggregate;
  sensorflow_checklists_by_pk?: Maybe<Sensorflow_Checklists>;
  sensorflow_checklists_stream: Array<Sensorflow_Checklists>;
  sensorflow_chillers: Array<Sensorflow_Chillers>;
  sensorflow_chillers_aggregate: Sensorflow_Chillers_Aggregate;
  sensorflow_chillers_by_pk?: Maybe<Sensorflow_Chillers>;
  sensorflow_chillers_stream: Array<Sensorflow_Chillers>;
  sensorflow_clickup_webhook_events: Array<Sensorflow_Clickup_Webhook_Events>;
  sensorflow_clickup_webhook_events_aggregate: Sensorflow_Clickup_Webhook_Events_Aggregate;
  sensorflow_clickup_webhook_events_by_pk?: Maybe<Sensorflow_Clickup_Webhook_Events>;
  sensorflow_clickup_webhook_events_stream: Array<Sensorflow_Clickup_Webhook_Events>;
  sensorflow_comfortplus_activations: Array<Sensorflow_Comfortplus_Activations>;
  sensorflow_comfortplus_activations_aggregate: Sensorflow_Comfortplus_Activations_Aggregate;
  sensorflow_comfortplus_activations_by_pk?: Maybe<Sensorflow_Comfortplus_Activations>;
  sensorflow_comfortplus_activations_stream: Array<Sensorflow_Comfortplus_Activations>;
  sensorflow_compressor_mapping: Array<Sensorflow_Compressor_Mapping>;
  sensorflow_compressor_mapping_aggregate: Sensorflow_Compressor_Mapping_Aggregate;
  sensorflow_compressor_mapping_by_pk?: Maybe<Sensorflow_Compressor_Mapping>;
  sensorflow_compressor_mapping_stream: Array<Sensorflow_Compressor_Mapping>;
  sensorflow_compressors: Array<Sensorflow_Compressors>;
  sensorflow_compressors_aggregate: Sensorflow_Compressors_Aggregate;
  sensorflow_compressors_by_pk?: Maybe<Sensorflow_Compressors>;
  sensorflow_compressors_stream: Array<Sensorflow_Compressors>;
  sensorflow_config_event_stream: Array<Sensorflow_Config_Event_Stream>;
  sensorflow_config_event_stream_aggregate: Sensorflow_Config_Event_Stream_Aggregate;
  sensorflow_config_event_stream_stream: Array<Sensorflow_Config_Event_Stream>;
  sensorflow_cooling_towers: Array<Sensorflow_Cooling_Towers>;
  sensorflow_cooling_towers_aggregate: Sensorflow_Cooling_Towers_Aggregate;
  sensorflow_cooling_towers_by_pk?: Maybe<Sensorflow_Cooling_Towers>;
  sensorflow_cooling_towers_stream: Array<Sensorflow_Cooling_Towers>;
  sensorflow_currencies: Array<Sensorflow_Currencies>;
  sensorflow_currencies_aggregate: Sensorflow_Currencies_Aggregate;
  sensorflow_currencies_by_pk?: Maybe<Sensorflow_Currencies>;
  sensorflow_currencies_stream: Array<Sensorflow_Currencies>;
  sensorflow_domo_embeds_aggregate: Sensorflow_Domo_Embeds_Aggregate;
  sensorflow_domo_embeds_stream: Array<Sensorflow_Domo_Embeds>;
  sensorflow_entity_feature_flag_mappings: Array<Sensorflow_Entity_Feature_Flag_Mappings>;
  sensorflow_entity_feature_flag_mappings_aggregate: Sensorflow_Entity_Feature_Flag_Mappings_Aggregate;
  sensorflow_entity_feature_flag_mappings_by_pk?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings>;
  sensorflow_entity_feature_flag_mappings_stream: Array<Sensorflow_Entity_Feature_Flag_Mappings>;
  sensorflow_events_stream: Array<Sensorflow_Events_Stream>;
  sensorflow_events_stream_aggregate: Sensorflow_Events_Stream_Aggregate;
  sensorflow_events_stream_stream: Array<Sensorflow_Events_Stream>;
  sensorflow_f_get_ac_command_event_stream: Array<Sensorflow_Ac_Command_Event_Stream>;
  sensorflow_f_get_ac_command_event_stream_aggregate: Sensorflow_Ac_Command_Event_Stream_Aggregate;
  sensorflow_f_get_all_event_stream: Array<Sensorflow_Ac_Command_Event_Stream>;
  sensorflow_f_get_all_event_stream_aggregate: Sensorflow_Ac_Command_Event_Stream_Aggregate;
  sensorflow_f_get_avg_position_data: Array<Sensorflow_Avg_Position_Data>;
  sensorflow_f_get_avg_position_data_aggregate: Sensorflow_Avg_Position_Data_Aggregate;
  sensorflow_f_get_config_event_stream: Array<Sensorflow_Config_Event_Stream>;
  sensorflow_f_get_config_event_stream_aggregate: Sensorflow_Config_Event_Stream_Aggregate;
  sensorflow_f_get_event_stream: Array<Sensorflow_Get_Event_Stream_View>;
  sensorflow_f_get_event_stream_aggregate: Sensorflow_Get_Event_Stream_View_Aggregate;
  sensorflow_f_get_synced_raw_data: Array<Sensorflow_Synced_Raw_Data>;
  sensorflow_f_get_synced_raw_data_aggregate: Sensorflow_Synced_Raw_Data_Aggregate;
  sensorflow_feature_flags: Array<Sensorflow_Feature_Flags>;
  sensorflow_feature_flags_aggregate: Sensorflow_Feature_Flags_Aggregate;
  sensorflow_feature_flags_by_pk?: Maybe<Sensorflow_Feature_Flags>;
  sensorflow_feature_flags_stream: Array<Sensorflow_Feature_Flags>;
  sensorflow_firmware_releases_aggregate: Sensorflow_Firmware_Releases_Aggregate;
  sensorflow_firmware_releases_by_pk?: Maybe<Sensorflow_Firmware_Releases>;
  sensorflow_firmware_releases_stream: Array<Sensorflow_Firmware_Releases>;
  sensorflow_gateway_frequency: Array<Sensorflow_Gateway_Frequency>;
  sensorflow_gateway_frequency_aggregate: Sensorflow_Gateway_Frequency_Aggregate;
  sensorflow_gateway_frequency_by_pk?: Maybe<Sensorflow_Gateway_Frequency>;
  sensorflow_gateway_frequency_stream: Array<Sensorflow_Gateway_Frequency>;
  sensorflow_gateway_health_data: Array<Sensorflow_Gateway_Health_Data>;
  sensorflow_gateway_health_data_aggregate: Sensorflow_Gateway_Health_Data_Aggregate;
  sensorflow_gateway_health_data_stream: Array<Sensorflow_Gateway_Health_Data>;
  sensorflow_gateway_to_position_mapping_aggregate: Sensorflow_Gateway_To_Position_Mapping_Aggregate;
  sensorflow_gateway_to_position_mapping_by_pk?: Maybe<Sensorflow_Gateway_To_Position_Mapping>;
  sensorflow_gateway_to_position_mapping_stream: Array<Sensorflow_Gateway_To_Position_Mapping>;
  sensorflow_gateways_aggregate: Sensorflow_Gateways_Aggregate;
  sensorflow_gateways_by_pk?: Maybe<Sensorflow_Gateways>;
  sensorflow_gateways_stream: Array<Sensorflow_Gateways>;
  sensorflow_get_event_stream_view: Array<Sensorflow_Get_Event_Stream_View>;
  sensorflow_get_event_stream_view_aggregate: Sensorflow_Get_Event_Stream_View_Aggregate;
  sensorflow_get_event_stream_view_stream: Array<Sensorflow_Get_Event_Stream_View>;
  sensorflow_get_precool_command_streams: Array<Sensorflow_Get_Precool_Command_Streams>;
  sensorflow_get_precool_command_streams_aggregate: Sensorflow_Get_Precool_Command_Streams_Aggregate;
  sensorflow_get_precool_command_streams_stream: Array<Sensorflow_Get_Precool_Command_Streams>;
  sensorflow_key_categories_aggregate: Sensorflow_Key_Categories_Aggregate;
  sensorflow_key_categories_by_pk?: Maybe<Sensorflow_Key_Categories>;
  sensorflow_key_categories_stream: Array<Sensorflow_Key_Categories>;
  sensorflow_key_category_room_templates: Array<Sensorflow_Key_Category_Room_Templates>;
  sensorflow_key_category_room_templates_aggregate: Sensorflow_Key_Category_Room_Templates_Aggregate;
  sensorflow_key_category_room_templates_by_pk?: Maybe<Sensorflow_Key_Category_Room_Templates>;
  sensorflow_key_category_room_templates_stream: Array<Sensorflow_Key_Category_Room_Templates>;
  sensorflow_key_category_slot_templates: Array<Sensorflow_Key_Category_Slot_Templates>;
  sensorflow_key_category_slot_templates_aggregate: Sensorflow_Key_Category_Slot_Templates_Aggregate;
  sensorflow_key_category_slot_templates_by_pk?: Maybe<Sensorflow_Key_Category_Slot_Templates>;
  sensorflow_key_category_slot_templates_stream: Array<Sensorflow_Key_Category_Slot_Templates>;
  sensorflow_key_category_templates: Array<Sensorflow_Key_Category_Templates>;
  sensorflow_key_category_templates_aggregate: Sensorflow_Key_Category_Templates_Aggregate;
  sensorflow_key_category_templates_by_pk?: Maybe<Sensorflow_Key_Category_Templates>;
  sensorflow_key_category_templates_stream: Array<Sensorflow_Key_Category_Templates>;
  sensorflow_key_category_to_key_position: Array<Sensorflow_Key_Category_To_Key_Position>;
  sensorflow_key_category_to_key_position_aggregate: Sensorflow_Key_Category_To_Key_Position_Aggregate;
  sensorflow_key_category_to_key_position_by_pk?: Maybe<Sensorflow_Key_Category_To_Key_Position>;
  sensorflow_key_category_to_key_position_stream: Array<Sensorflow_Key_Category_To_Key_Position>;
  sensorflow_location_addresses: Array<Sensorflow_Location_Addresses>;
  sensorflow_location_addresses_aggregate: Sensorflow_Location_Addresses_Aggregate;
  sensorflow_location_addresses_by_pk?: Maybe<Sensorflow_Location_Addresses>;
  sensorflow_location_addresses_stream: Array<Sensorflow_Location_Addresses>;
  sensorflow_location_consumptions: Array<Sensorflow_Location_Consumptions>;
  sensorflow_location_consumptions_aggregate: Sensorflow_Location_Consumptions_Aggregate;
  sensorflow_location_consumptions_by_pk?: Maybe<Sensorflow_Location_Consumptions>;
  sensorflow_location_consumptions_stream: Array<Sensorflow_Location_Consumptions>;
  sensorflow_location_domo_embeds_aggregate: Sensorflow_Location_Domo_Embeds_Aggregate;
  sensorflow_location_domo_embeds_stream: Array<Sensorflow_Location_Domo_Embeds>;
  sensorflow_location_metadata: Array<Sensorflow_Location_Metadata>;
  sensorflow_location_metadata_aggregate: Sensorflow_Location_Metadata_Aggregate;
  sensorflow_location_metadata_stream: Array<Sensorflow_Location_Metadata>;
  sensorflow_location_occ_customer_data: Array<Sensorflow_Location_Occ_Customer_Data>;
  sensorflow_location_occ_customer_data_aggregate: Sensorflow_Location_Occ_Customer_Data_Aggregate;
  sensorflow_location_occ_customer_data_by_pk?: Maybe<Sensorflow_Location_Occ_Customer_Data>;
  sensorflow_location_occ_customer_data_stream: Array<Sensorflow_Location_Occ_Customer_Data>;
  sensorflow_location_occ_derived_data: Array<Sensorflow_Location_Occ_Derived_Data>;
  sensorflow_location_occ_derived_data_aggregate: Sensorflow_Location_Occ_Derived_Data_Aggregate;
  sensorflow_location_occ_derived_data_by_pk?: Maybe<Sensorflow_Location_Occ_Derived_Data>;
  sensorflow_location_occ_derived_data_stream: Array<Sensorflow_Location_Occ_Derived_Data>;
  sensorflow_location_to_user_mapping: Array<Sensorflow_Location_To_User_Mapping>;
  sensorflow_location_to_user_mapping_aggregate: Sensorflow_Location_To_User_Mapping_Aggregate;
  sensorflow_location_to_user_mapping_by_pk?: Maybe<Sensorflow_Location_To_User_Mapping>;
  sensorflow_location_to_user_mapping_stream: Array<Sensorflow_Location_To_User_Mapping>;
  sensorflow_locations_aggregate: Sensorflow_Locations_Aggregate;
  sensorflow_locations_stream: Array<Sensorflow_Locations>;
  sensorflow_mapping_coordinates: Array<Sensorflow_Mapping_Coordinates>;
  sensorflow_mapping_coordinates_aggregate: Sensorflow_Mapping_Coordinates_Aggregate;
  sensorflow_mapping_coordinates_stream: Array<Sensorflow_Mapping_Coordinates>;
  sensorflow_metric_types: Array<Sensorflow_Metric_Types>;
  sensorflow_metric_types_aggregate: Sensorflow_Metric_Types_Aggregate;
  sensorflow_metric_types_by_pk?: Maybe<Sensorflow_Metric_Types>;
  sensorflow_metric_types_stream: Array<Sensorflow_Metric_Types>;
  sensorflow_node_exception_stream: Array<Sensorflow_Node_Exception>;
  sensorflow_node_exceptions_stream: Array<Sensorflow_Node_Exceptions>;
  sensorflow_node_firmware: Array<Sensorflow_Node_Firmware>;
  sensorflow_node_firmware_aggregate: Sensorflow_Node_Firmware_Aggregate;
  sensorflow_node_firmware_stream: Array<Sensorflow_Node_Firmware>;
  sensorflow_node_join_data: Array<Sensorflow_Node_Join_Data>;
  sensorflow_node_join_data_aggregate: Sensorflow_Node_Join_Data_Aggregate;
  sensorflow_node_join_data_stream: Array<Sensorflow_Node_Join_Data>;
  sensorflow_node_join_status_aggregate: Sensorflow_Node_Join_Status_Aggregate;
  sensorflow_node_join_status_stream: Array<Sensorflow_Node_Join_Status>;
  sensorflow_node_join_strength_live: Array<Sensorflow_Node_Join_Strength_Live>;
  sensorflow_node_join_strength_live_aggregate: Sensorflow_Node_Join_Strength_Live_Aggregate;
  sensorflow_node_join_strength_live_stream: Array<Sensorflow_Node_Join_Strength_Live>;
  sensorflow_node_life_cycle_event_types: Array<Sensorflow_Node_Life_Cycle_Event_Types>;
  sensorflow_node_life_cycle_event_types_aggregate: Sensorflow_Node_Life_Cycle_Event_Types_Aggregate;
  sensorflow_node_life_cycle_event_types_by_pk?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types>;
  sensorflow_node_life_cycle_event_types_stream: Array<Sensorflow_Node_Life_Cycle_Event_Types>;
  sensorflow_node_life_cycle_events: Array<Sensorflow_Node_Life_Cycle_Events>;
  sensorflow_node_life_cycle_events_aggregate: Sensorflow_Node_Life_Cycle_Events_Aggregate;
  sensorflow_node_life_cycle_events_by_pk?: Maybe<Sensorflow_Node_Life_Cycle_Events>;
  sensorflow_node_life_cycle_events_stream: Array<Sensorflow_Node_Life_Cycle_Events>;
  sensorflow_node_measurement_view: Array<Sensorflow_Node_Measurement_View>;
  sensorflow_node_measurement_view_aggregate: Sensorflow_Node_Measurement_View_Aggregate;
  sensorflow_node_measurement_view_stream: Array<Sensorflow_Node_Measurement_View>;
  sensorflow_node_measurements: Array<Sensorflow_Node_Measurements>;
  sensorflow_node_measurements_aggregate: Sensorflow_Node_Measurements_Aggregate;
  sensorflow_node_measurements_stream: Array<Sensorflow_Node_Measurements>;
  sensorflow_node_meta_data: Array<Sensorflow_Node_Meta_Data>;
  sensorflow_node_meta_data_aggregate: Sensorflow_Node_Meta_Data_Aggregate;
  sensorflow_node_meta_data_by_pk?: Maybe<Sensorflow_Node_Meta_Data>;
  sensorflow_node_meta_data_stream: Array<Sensorflow_Node_Meta_Data>;
  sensorflow_node_meta_status: Array<Sensorflow_Node_Meta_Status>;
  sensorflow_node_meta_status_aggregate: Sensorflow_Node_Meta_Status_Aggregate;
  sensorflow_node_meta_status_stream: Array<Sensorflow_Node_Meta_Status>;
  sensorflow_node_online_status_aggregate: Sensorflow_Node_Online_Status_Aggregate;
  sensorflow_node_online_status_stream: Array<Sensorflow_Node_Online_Status>;
  sensorflow_node_slots: Array<Sensorflow_Node_Slots>;
  sensorflow_node_slots_aggregate: Sensorflow_Node_Slots_Aggregate;
  sensorflow_node_slots_by_pk?: Maybe<Sensorflow_Node_Slots>;
  sensorflow_node_slots_stream: Array<Sensorflow_Node_Slots>;
  sensorflow_node_spare_set: Array<Sensorflow_Node_Spare_Set>;
  sensorflow_node_spare_set_aggregate: Sensorflow_Node_Spare_Set_Aggregate;
  sensorflow_node_spare_set_by_pk?: Maybe<Sensorflow_Node_Spare_Set>;
  sensorflow_node_spare_set_stream: Array<Sensorflow_Node_Spare_Set>;
  sensorflow_node_status_history: Array<Sensorflow_Node_Status_History>;
  sensorflow_node_status_history_aggregate: Sensorflow_Node_Status_History_Aggregate;
  sensorflow_node_status_history_by_pk?: Maybe<Sensorflow_Node_Status_History>;
  sensorflow_node_status_history_stream: Array<Sensorflow_Node_Status_History>;
  sensorflow_node_statuses: Array<Sensorflow_Node_Statuses>;
  sensorflow_node_statuses_aggregate: Sensorflow_Node_Statuses_Aggregate;
  sensorflow_node_statuses_by_pk?: Maybe<Sensorflow_Node_Statuses>;
  sensorflow_node_statuses_stream: Array<Sensorflow_Node_Statuses>;
  sensorflow_node_system_states: Array<Sensorflow_Node_System_States>;
  sensorflow_node_system_states_aggregate: Sensorflow_Node_System_States_Aggregate;
  sensorflow_node_system_states_by_pk?: Maybe<Sensorflow_Node_System_States>;
  sensorflow_node_system_states_stream: Array<Sensorflow_Node_System_States>;
  sensorflow_node_to_position_mapping: Array<Sensorflow_Node_To_Position_Mapping>;
  sensorflow_node_to_position_mapping_aggregate: Sensorflow_Node_To_Position_Mapping_Aggregate;
  sensorflow_node_to_position_mapping_stream: Array<Sensorflow_Node_To_Position_Mapping>;
  sensorflow_node_to_slot_mapping: Array<Sensorflow_Node_To_Slot_Mapping>;
  sensorflow_node_to_slot_mapping_aggregate: Sensorflow_Node_To_Slot_Mapping_Aggregate;
  sensorflow_node_to_slot_mapping_by_pk?: Maybe<Sensorflow_Node_To_Slot_Mapping>;
  sensorflow_node_to_slot_mapping_stream: Array<Sensorflow_Node_To_Slot_Mapping>;
  sensorflow_nodes: Array<Sensorflow_Nodes>;
  sensorflow_nodes_aggregate: Sensorflow_Nodes_Aggregate;
  sensorflow_nodes_by_pk?: Maybe<Sensorflow_Nodes>;
  sensorflow_nodes_stream: Array<Sensorflow_Nodes>;
  sensorflow_organisations: Array<Sensorflow_Organisations>;
  sensorflow_organisations_aggregate: Sensorflow_Organisations_Aggregate;
  sensorflow_organisations_by_pk?: Maybe<Sensorflow_Organisations>;
  sensorflow_organisations_stream: Array<Sensorflow_Organisations>;
  sensorflow_ota_queues: Array<Sensorflow_Ota_Queues>;
  sensorflow_ota_queues_aggregate: Sensorflow_Ota_Queues_Aggregate;
  sensorflow_ota_queues_by_pk?: Maybe<Sensorflow_Ota_Queues>;
  sensorflow_ota_queues_stream: Array<Sensorflow_Ota_Queues>;
  sensorflow_ota_runs: Array<Sensorflow_Ota_Runs>;
  sensorflow_ota_runs_aggregate: Sensorflow_Ota_Runs_Aggregate;
  sensorflow_ota_runs_by_pk?: Maybe<Sensorflow_Ota_Runs>;
  sensorflow_ota_runs_stream: Array<Sensorflow_Ota_Runs>;
  sensorflow_outdoor_temperatures: Array<Sensorflow_Outdoor_Temperatures>;
  sensorflow_outdoor_temperatures_aggregate: Sensorflow_Outdoor_Temperatures_Aggregate;
  sensorflow_outdoor_temperatures_by_pk?: Maybe<Sensorflow_Outdoor_Temperatures>;
  sensorflow_outdoor_temperatures_stream: Array<Sensorflow_Outdoor_Temperatures>;
  sensorflow_pending_ac_settings_commands_for_gateway: Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway>;
  sensorflow_pending_ac_settings_commands_for_gateway_aggregate: Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Aggregate;
  sensorflow_pending_ac_settings_commands_for_gateway_stream: Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway>;
  sensorflow_pipes: Array<Sensorflow_Pipes>;
  sensorflow_pipes_aggregate: Sensorflow_Pipes_Aggregate;
  sensorflow_pipes_by_pk?: Maybe<Sensorflow_Pipes>;
  sensorflow_pipes_stream: Array<Sensorflow_Pipes>;
  sensorflow_position_precool_status_aggregate: Sensorflow_Position_Precool_Status_Aggregate;
  sensorflow_position_precool_status_stream: Array<Sensorflow_Position_Precool_Status>;
  sensorflow_position_signal_stats: Array<Sensorflow_Position_Signal_Stats>;
  sensorflow_position_signal_stats_aggregate: Sensorflow_Position_Signal_Stats_Aggregate;
  sensorflow_position_signal_stats_by_pk?: Maybe<Sensorflow_Position_Signal_Stats>;
  sensorflow_position_signal_stats_stream: Array<Sensorflow_Position_Signal_Stats>;
  sensorflow_positions_stream: Array<Sensorflow_Positions>;
  sensorflow_precool_commands: Array<Sensorflow_Precool_Commands>;
  sensorflow_precool_commands_aggregate: Sensorflow_Precool_Commands_Aggregate;
  sensorflow_precool_commands_by_pk?: Maybe<Sensorflow_Precool_Commands>;
  sensorflow_precool_commands_stream: Array<Sensorflow_Precool_Commands>;
  sensorflow_precool_schedules: Array<Sensorflow_Precool_Schedules>;
  sensorflow_precool_schedules_aggregate: Sensorflow_Precool_Schedules_Aggregate;
  sensorflow_precool_schedules_by_pk?: Maybe<Sensorflow_Precool_Schedules>;
  sensorflow_precool_schedules_stream: Array<Sensorflow_Precool_Schedules>;
  sensorflow_properties_information: Array<Sensorflow_Properties_Information>;
  sensorflow_properties_information_aggregate: Sensorflow_Properties_Information_Aggregate;
  sensorflow_properties_information_by_pk?: Maybe<Sensorflow_Properties_Information>;
  sensorflow_properties_information_stream: Array<Sensorflow_Properties_Information>;
  sensorflow_pumps: Array<Sensorflow_Pumps>;
  sensorflow_pumps_aggregate: Sensorflow_Pumps_Aggregate;
  sensorflow_pumps_by_pk?: Maybe<Sensorflow_Pumps>;
  sensorflow_pumps_stream: Array<Sensorflow_Pumps>;
  sensorflow_reservation_guests: Array<Sensorflow_Reservation_Guests>;
  sensorflow_reservation_guests_aggregate: Sensorflow_Reservation_Guests_Aggregate;
  sensorflow_reservation_guests_by_pk?: Maybe<Sensorflow_Reservation_Guests>;
  sensorflow_reservation_guests_stream: Array<Sensorflow_Reservation_Guests>;
  sensorflow_reservations: Array<Sensorflow_Reservations>;
  sensorflow_reservations_aggregate: Sensorflow_Reservations_Aggregate;
  sensorflow_reservations_by_pk?: Maybe<Sensorflow_Reservations>;
  sensorflow_reservations_stream: Array<Sensorflow_Reservations>;
  sensorflow_roles: Array<Sensorflow_Roles>;
  sensorflow_roles_aggregate: Sensorflow_Roles_Aggregate;
  sensorflow_roles_by_pk?: Maybe<Sensorflow_Roles>;
  sensorflow_roles_stream: Array<Sensorflow_Roles>;
  sensorflow_smart_rem_validation_history_aggregate: Sensorflow_Smart_Rem_Validation_History_Aggregate;
  sensorflow_smart_rem_validation_history_by_pk?: Maybe<Sensorflow_Smart_Rem_Validation_History>;
  sensorflow_smart_rem_validation_history_stream: Array<Sensorflow_Smart_Rem_Validation_History>;
  sensorflow_smplrspace_floors_to_levels: Array<Sensorflow_Smplrspace_Floors_To_Levels>;
  sensorflow_smplrspace_floors_to_levels_aggregate: Sensorflow_Smplrspace_Floors_To_Levels_Aggregate;
  sensorflow_smplrspace_floors_to_levels_by_pk?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels>;
  sensorflow_smplrspace_floors_to_levels_stream: Array<Sensorflow_Smplrspace_Floors_To_Levels>;
  sensorflow_smplrspace_levels_group: Array<Sensorflow_Smplrspace_Levels_Group>;
  sensorflow_smplrspace_levels_group_aggregate: Sensorflow_Smplrspace_Levels_Group_Aggregate;
  sensorflow_smplrspace_levels_group_by_pk?: Maybe<Sensorflow_Smplrspace_Levels_Group>;
  sensorflow_smplrspace_levels_group_stream: Array<Sensorflow_Smplrspace_Levels_Group>;
  sensorflow_survey_responses: Array<Sensorflow_Survey_Responses>;
  sensorflow_survey_responses_aggregate: Sensorflow_Survey_Responses_Aggregate;
  sensorflow_survey_responses_by_pk?: Maybe<Sensorflow_Survey_Responses>;
  sensorflow_survey_responses_stream: Array<Sensorflow_Survey_Responses>;
  sensorflow_synced_raw_data: Array<Sensorflow_Synced_Raw_Data>;
  sensorflow_synced_raw_data_aggregate: Sensorflow_Synced_Raw_Data_Aggregate;
  sensorflow_synced_raw_data_stream: Array<Sensorflow_Synced_Raw_Data>;
  sensorflow_task_status_history: Array<Sensorflow_Task_Status_History>;
  sensorflow_task_status_history_aggregate: Sensorflow_Task_Status_History_Aggregate;
  sensorflow_task_status_history_by_pk?: Maybe<Sensorflow_Task_Status_History>;
  sensorflow_task_status_history_stream: Array<Sensorflow_Task_Status_History>;
  sensorflow_tasks: Array<Sensorflow_Tasks>;
  sensorflow_tasks_aggregate: Sensorflow_Tasks_Aggregate;
  sensorflow_tasks_by_pk?: Maybe<Sensorflow_Tasks>;
  sensorflow_tasks_stream: Array<Sensorflow_Tasks>;
  sensorflow_tests: Array<Sensorflow_Tests>;
  sensorflow_tests_aggregate: Sensorflow_Tests_Aggregate;
  sensorflow_tests_by_pk?: Maybe<Sensorflow_Tests>;
  sensorflow_tests_stream: Array<Sensorflow_Tests>;
  sensorflow_timezones: Array<Sensorflow_Timezones>;
  sensorflow_timezones_aggregate: Sensorflow_Timezones_Aggregate;
  sensorflow_timezones_by_pk?: Maybe<Sensorflow_Timezones>;
  sensorflow_timezones_stream: Array<Sensorflow_Timezones>;
  sensorflow_updatable_nodes: Array<Sensorflow_Updatable_Nodes>;
  sensorflow_updatable_nodes_aggregate: Sensorflow_Updatable_Nodes_Aggregate;
  sensorflow_updatable_nodes_stream: Array<Sensorflow_Updatable_Nodes>;
  sensorflow_user_to_permission_mapping: Array<Sensorflow_User_To_Permission_Mapping>;
  sensorflow_user_to_permission_mapping_aggregate: Sensorflow_User_To_Permission_Mapping_Aggregate;
  sensorflow_user_to_permission_mapping_stream: Array<Sensorflow_User_To_Permission_Mapping>;
  sensorflow_users: Array<Sensorflow_Users>;
  sensorflow_users_aggregate: Sensorflow_Users_Aggregate;
  sensorflow_users_by_pk?: Maybe<Sensorflow_Users>;
  sensorflow_users_stream: Array<Sensorflow_Users>;
  sensorflow_v_all_latest_node_configurations: Array<Sensorflow_V_All_Latest_Node_Configurations>;
  sensorflow_v_all_latest_node_configurations_aggregate: Sensorflow_V_All_Latest_Node_Configurations_Aggregate;
  sensorflow_v_all_latest_node_configurations_stream: Array<Sensorflow_V_All_Latest_Node_Configurations>;
  sensorflow_v_combined_user_roles: Array<Sensorflow_V_Combined_User_Roles>;
  sensorflow_v_combined_user_roles_aggregate: Sensorflow_V_Combined_User_Roles_Aggregate;
  sensorflow_v_combined_user_roles_stream: Array<Sensorflow_V_Combined_User_Roles>;
  sensorflow_v_comfortplus_key_details: Array<Sensorflow_V_Comfortplus_Key_Details>;
  sensorflow_v_comfortplus_key_details_aggregate: Sensorflow_V_Comfortplus_Key_Details_Aggregate;
  sensorflow_v_comfortplus_key_details_stream: Array<Sensorflow_V_Comfortplus_Key_Details>;
  sensorflow_v_comfortplus_key_measurements: Array<Sensorflow_V_Comfortplus_Key_Measurements>;
  sensorflow_v_comfortplus_key_measurements_aggregate: Sensorflow_V_Comfortplus_Key_Measurements_Aggregate;
  sensorflow_v_comfortplus_key_measurements_stream: Array<Sensorflow_V_Comfortplus_Key_Measurements>;
  sensorflow_v_comfortplus_precool_status: Array<Sensorflow_V_Comfortplus_Precool_Status>;
  sensorflow_v_comfortplus_precool_status_aggregate: Sensorflow_V_Comfortplus_Precool_Status_Aggregate;
  sensorflow_v_comfortplus_precool_status_stream: Array<Sensorflow_V_Comfortplus_Precool_Status>;
  sensorflow_v_current_position_configurations_aggregate: Sensorflow_V_Current_Position_Configurations_Aggregate;
  sensorflow_v_current_position_configurations_stream: Array<Sensorflow_V_Current_Position_Configurations>;
  sensorflow_v_group_tasks: Array<Sensorflow_V_Group_Tasks>;
  sensorflow_v_group_tasks_aggregate: Sensorflow_V_Group_Tasks_Aggregate;
  sensorflow_v_group_tasks_stream: Array<Sensorflow_V_Group_Tasks>;
  sensorflow_v_group_with_stats: Array<Sensorflow_V_Group_With_Stats>;
  sensorflow_v_group_with_stats_aggregate: Sensorflow_V_Group_With_Stats_Aggregate;
  sensorflow_v_group_with_stats_stream: Array<Sensorflow_V_Group_With_Stats>;
  sensorflow_v_gw_autoset_min_signal: Array<Sensorflow_V_Gw_Autoset_Min_Signal>;
  sensorflow_v_gw_autoset_min_signal_aggregate: Sensorflow_V_Gw_Autoset_Min_Signal_Aggregate;
  sensorflow_v_gw_autoset_min_signal_stream: Array<Sensorflow_V_Gw_Autoset_Min_Signal>;
  sensorflow_v_infrastructure_emon_power_status_aggregate: Sensorflow_V_Infrastructure_Emon_Power_Status_Aggregate;
  sensorflow_v_infrastructure_emon_power_status_stream: Array<Sensorflow_V_Infrastructure_Emon_Power_Status>;
  sensorflow_v_infrastructures_aggregate: Sensorflow_V_Infrastructures_Aggregate;
  sensorflow_v_infrastructures_stream: Array<Sensorflow_V_Infrastructures>;
  sensorflow_v_key_emon_power_reading: Array<Sensorflow_V_Key_Emon_Power_Reading>;
  sensorflow_v_key_emon_power_reading_aggregate: Sensorflow_V_Key_Emon_Power_Reading_Aggregate;
  sensorflow_v_key_emon_power_reading_stream: Array<Sensorflow_V_Key_Emon_Power_Reading>;
  sensorflow_v_key_position_latest_entry: Array<Sensorflow_V_Key_Position_Latest_Entry>;
  sensorflow_v_key_position_latest_entry_aggregate: Sensorflow_V_Key_Position_Latest_Entry_Aggregate;
  sensorflow_v_key_position_latest_entry_stream: Array<Sensorflow_V_Key_Position_Latest_Entry>;
  sensorflow_v_key_task_min_duedate: Array<Sensorflow_V_Key_Task_Min_Duedate>;
  sensorflow_v_key_task_min_duedate_aggregate: Sensorflow_V_Key_Task_Min_Duedate_Aggregate;
  sensorflow_v_key_task_min_duedate_stream: Array<Sensorflow_V_Key_Task_Min_Duedate>;
  sensorflow_v_location_last_update: Array<Sensorflow_V_Location_Last_Update>;
  sensorflow_v_location_last_update_aggregate: Sensorflow_V_Location_Last_Update_Aggregate;
  sensorflow_v_location_last_update_stream: Array<Sensorflow_V_Location_Last_Update>;
  sensorflow_v_node_life_cycle_events: Array<Sensorflow_V_Node_Life_Cycle_Events>;
  sensorflow_v_node_life_cycle_events_aggregate: Sensorflow_V_Node_Life_Cycle_Events_Aggregate;
  sensorflow_v_node_life_cycle_events_stream: Array<Sensorflow_V_Node_Life_Cycle_Events>;
  sensorflow_v_task_with_key_status_history: Array<Sensorflow_V_Task_With_Key_Status_History>;
  sensorflow_v_task_with_key_status_history_aggregate: Sensorflow_V_Task_With_Key_Status_History_Aggregate;
  sensorflow_v_task_with_key_status_history_stream: Array<Sensorflow_V_Task_With_Key_Status_History>;
  sensorflow_v_tasks_with_keys_aggregate: Sensorflow_V_Tasks_With_Keys_Aggregate;
  sensorflow_v_tasks_with_keys_stream: Array<Sensorflow_V_Tasks_With_Keys>;
  sensorflow_warehouse_kitting_process_metadata_aggregate: Sensorflow_Warehouse_Kitting_Process_Metadata_Aggregate;
  sensorflow_warehouse_kitting_process_metadata_by_pk?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata>;
  sensorflow_warehouse_kitting_process_metadata_stream: Array<Sensorflow_Warehouse_Kitting_Process_Metadata>;
  sensorflow_warehouse_mapping_status_of_slots: Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots>;
  sensorflow_warehouse_mapping_status_of_slots_aggregate: Sensorflow_Warehouse_Mapping_Status_Of_Slots_Aggregate;
  sensorflow_warehouse_mapping_status_of_slots_stream: Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots>;
  sf_firmware_dist_pipeline_firmware_build: Array<Sf_Firmware_Dist_Pipeline_Firmware_Build>;
  sf_firmware_dist_pipeline_firmware_build_aggregate: Sf_Firmware_Dist_Pipeline_Firmware_Build_Aggregate;
  sf_firmware_dist_pipeline_firmware_build_by_pk?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build>;
  sf_firmware_dist_pipeline_firmware_build_stream: Array<Sf_Firmware_Dist_Pipeline_Firmware_Build>;
  sf_firmware_dist_pipeline_firmware_numbers: Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers>;
  sf_firmware_dist_pipeline_firmware_numbers_aggregate: Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Aggregate;
  sf_firmware_dist_pipeline_firmware_numbers_by_pk?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers>;
  sf_firmware_dist_pipeline_firmware_numbers_stream: Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers>;
  sf_pms_pms_pmslist: Array<Sf_Pms_Pms_Pmslist>;
  sf_pms_pms_pmslist_aggregate: Sf_Pms_Pms_Pmslist_Aggregate;
  sf_pms_pms_pmslist_by_pk?: Maybe<Sf_Pms_Pms_Pmslist>;
  sf_pms_pms_pmslist_stream: Array<Sf_Pms_Pms_Pmslist>;
  sf_pms_pms_properties: Array<Sf_Pms_Pms_Properties>;
  sf_pms_pms_properties_aggregate: Sf_Pms_Pms_Properties_Aggregate;
  sf_pms_pms_properties_by_pk?: Maybe<Sf_Pms_Pms_Properties>;
  sf_pms_pms_properties_stream: Array<Sf_Pms_Pms_Properties>;
  sf_pms_pms_property_eod_surveys: Array<Sf_Pms_Pms_Property_Eod_Surveys>;
  sf_pms_pms_property_eod_surveys_aggregate: Sf_Pms_Pms_Property_Eod_Surveys_Aggregate;
  sf_pms_pms_property_eod_surveys_by_pk?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys>;
  sf_pms_pms_property_eod_surveys_stream: Array<Sf_Pms_Pms_Property_Eod_Surveys>;
  sf_pms_pms_reservations: Array<Sf_Pms_Pms_Reservations>;
  sf_pms_pms_reservations_aggregate: Sf_Pms_Pms_Reservations_Aggregate;
  sf_pms_pms_reservations_stream: Array<Sf_Pms_Pms_Reservations>;
  sf_pms_pms_room_categories: Array<Sf_Pms_Pms_Room_Categories>;
  sf_pms_pms_room_categories_aggregate: Sf_Pms_Pms_Room_Categories_Aggregate;
  sf_pms_pms_room_categories_stream: Array<Sf_Pms_Pms_Room_Categories>;
  sf_pms_pms_rooms: Array<Sf_Pms_Pms_Rooms>;
  sf_pms_pms_rooms_aggregate: Sf_Pms_Pms_Rooms_Aggregate;
  sf_pms_pms_rooms_stream: Array<Sf_Pms_Pms_Rooms>;
  sf_pms_smartalloc_report: Array<Sf_Pms_Smartalloc_Report>;
  sf_pms_smartalloc_report_aggregate: Sf_Pms_Smartalloc_Report_Aggregate;
  sf_pms_smartalloc_report_by_pk?: Maybe<Sf_Pms_Smartalloc_Report>;
  sf_pms_smartalloc_report_stream: Array<Sf_Pms_Smartalloc_Report>;
  sf_simulation_instrumentation_commands: Array<Sf_Simulation_Instrumentation_Commands>;
  sf_simulation_instrumentation_commands_aggregate: Sf_Simulation_Instrumentation_Commands_Aggregate;
  sf_simulation_instrumentation_commands_by_pk?: Maybe<Sf_Simulation_Instrumentation_Commands>;
  sf_simulation_instrumentation_commands_stream: Array<Sf_Simulation_Instrumentation_Commands>;
  sf_support_actions: Array<Sf_Support_Actions>;
  sf_support_actions_aggregate: Sf_Support_Actions_Aggregate;
  sf_support_actions_by_pk?: Maybe<Sf_Support_Actions>;
  sf_support_actions_stream: Array<Sf_Support_Actions>;
  sf_support_active_property_alerts: Array<Sf_Support_Active_Property_Alerts>;
  sf_support_active_property_alerts_aggregate: Sf_Support_Active_Property_Alerts_Aggregate;
  sf_support_active_property_alerts_by_pk?: Maybe<Sf_Support_Active_Property_Alerts>;
  sf_support_active_property_alerts_stream: Array<Sf_Support_Active_Property_Alerts>;
  sf_support_analytics_ticket_jobs_running_histories: Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories>;
  sf_support_analytics_ticket_jobs_running_histories_aggregate: Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Aggregate;
  sf_support_analytics_ticket_jobs_running_histories_by_pk?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories>;
  sf_support_analytics_ticket_jobs_running_histories_stream: Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories>;
  sf_support_root_causes: Array<Sf_Support_Root_Causes>;
  sf_support_root_causes_aggregate: Sf_Support_Root_Causes_Aggregate;
  sf_support_root_causes_by_pk?: Maybe<Sf_Support_Root_Causes>;
  sf_support_root_causes_stream: Array<Sf_Support_Root_Causes>;
  sf_support_ticket_classes_aggregate: Sf_Support_Ticket_Classes_Aggregate;
  sf_support_ticket_classes_by_pk?: Maybe<Sf_Support_Ticket_Classes>;
  sf_support_ticket_classes_stream: Array<Sf_Support_Ticket_Classes>;
  sf_support_ticket_status: Array<Sf_Support_Ticket_Status>;
  sf_support_ticket_status_aggregate: Sf_Support_Ticket_Status_Aggregate;
  sf_support_ticket_status_by_pk?: Maybe<Sf_Support_Ticket_Status>;
  sf_support_ticket_status_stream: Array<Sf_Support_Ticket_Status>;
  sf_support_tickets: Array<Sf_Support_Tickets>;
  sf_support_tickets_aggregate: Sf_Support_Tickets_Aggregate;
  sf_support_tickets_by_pk?: Maybe<Sf_Support_Tickets>;
  sf_support_tickets_stream: Array<Sf_Support_Tickets>;
  sf_testjig_test_jig_aggregate: Sf_Testjig_Test_Jig_Aggregate;
  sf_testjig_test_jig_maintenance_report_aggregate: Sf_Testjig_Test_Jig_Maintenance_Report_Aggregate;
  sf_testjig_test_jig_maintenance_report_stream: Array<Sf_Testjig_Test_Jig_Maintenance_Report>;
  sf_testjig_test_jig_report_metadata_aggregate: Sf_Testjig_Test_Jig_Report_Metadata_Aggregate;
  sf_testjig_test_jig_report_metadata_by_pk?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata>;
  sf_testjig_test_jig_report_metadata_stream: Array<Sf_Testjig_Test_Jig_Report_Metadata>;
  sf_testjig_test_jig_report_metadatas_aggregate: Sf_Testjig_Test_Jig_Report_Metadatas_Aggregate;
  sf_testjig_test_jig_report_metadatas_by_pk?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas>;
  sf_testjig_test_jig_report_metadatas_stream: Array<Sf_Testjig_Test_Jig_Report_Metadatas>;
  sf_testjig_test_jig_stream: Array<Sf_Testjig_Test_Jig>;
  sf_testjig_test_jig_test_report_aggregate: Sf_Testjig_Test_Jig_Test_Report_Aggregate;
  sf_testjig_test_jig_test_report_stream: Array<Sf_Testjig_Test_Jig_Test_Report>;
  sf_testjig_test_jig_type_aggregate: Sf_Testjig_Test_Jig_Type_Aggregate;
  sf_testjig_test_jig_type_stream: Array<Sf_Testjig_Test_Jig_Type>;
  sf_testjig_v_latest_report_rma_nodes: Array<Sf_Testjig_V_Latest_Report_Rma_Nodes>;
  sf_testjig_v_latest_report_rma_nodes_aggregate: Sf_Testjig_V_Latest_Report_Rma_Nodes_Aggregate;
  sf_testjig_v_latest_report_rma_nodes_stream: Array<Sf_Testjig_V_Latest_Report_Rma_Nodes>;
  tasksWithKeys: Array<Sensorflow_V_Tasks_With_Keys>;
  testRuns: Array<TestRuns>;
  testRuns_aggregate: TestRuns_Aggregate;
  testRuns_by_pk?: Maybe<TestRuns>;
  testRuns_stream: Array<TestRuns>;
  ticketClasses: Array<Sf_Support_Ticket_Classes>;
};

export type Subscription_RootTestJigArgs = {
  testJigId: Scalars["String"];
};

export type Subscription_RootTestJigMaintenanceReportArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTestJigMaintenanceReportsArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp>;
};

export type Subscription_RootTestJigReportMetadataArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp>;
};

export type Subscription_RootTestJigReportMetadatasArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp>;
};

export type Subscription_RootTestJigTestReportArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootTestJigTestReportsArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Bool_Exp>;
};

export type Subscription_RootTestJigTypeArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootTestJigTypesArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Type_Bool_Exp>;
};

export type Subscription_RootTestJigsArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Bool_Exp>;
};

export type Subscription_RootCurrentPositionConfigurationArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Order_By>>;
  where?: Maybe<Sensorflow_V_Current_Position_Configurations_Bool_Exp>;
};

export type Subscription_RootDomoEmbedArgs = {
  embed_id: Scalars["String"];
};

export type Subscription_RootDomoEmbedsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Domo_Embeds_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Domo_Embeds_Order_By>>;
  where?: Maybe<Sensorflow_Domo_Embeds_Bool_Exp>;
};

export type Subscription_RootFirmwareReleasesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Firmware_Releases_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Firmware_Releases_Order_By>>;
  where?: Maybe<Sensorflow_Firmware_Releases_Bool_Exp>;
};

export type Subscription_RootGatewayKittingProcessMetadataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Order_By>>;
  where?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
};

export type Subscription_RootGatewayToPositionMappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
};

export type Subscription_RootGatewaysArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateways_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateways_Order_By>>;
  where?: Maybe<Sensorflow_Gateways_Bool_Exp>;
};

export type Subscription_RootGetSmartRemValidationHistoryArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Order_By>>;
  where?: Maybe<Sensorflow_Smart_Rem_Validation_History_Bool_Exp>;
};

export type Subscription_RootInfrastructureEmonPowerStatusArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Order_By>>;
  where?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Bool_Exp>;
};

export type Subscription_RootInfrastructuresArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Infrastructures_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Infrastructures_Order_By>>;
  where?: Maybe<Sensorflow_V_Infrastructures_Bool_Exp>;
};

export type Subscription_RootKeyCategoriesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Categories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Categories_Order_By>>;
  where?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
};

export type Subscription_RootKeyEntriesArgs = {
  distinct_on?: Maybe<Array<KeyEntries_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<KeyEntries_Order_By>>;
  where?: Maybe<KeyEntries_Bool_Exp>;
};

export type Subscription_RootKeyEntries_AggregateArgs = {
  distinct_on?: Maybe<Array<KeyEntries_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<KeyEntries_Order_By>>;
  where?: Maybe<KeyEntries_Bool_Exp>;
};

export type Subscription_RootKeyEntries_By_PkArgs = {
  keyEntryId: Scalars["Int"];
};

export type Subscription_RootKeyEntries_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<KeyEntries_Stream_Cursor_Input>>;
  where?: Maybe<KeyEntries_Bool_Exp>;
};

export type Subscription_RootLocationDomoEmbedArgs = {
  embed_id: Scalars["String"];
  location_id: Scalars["uuid"];
};

export type Subscription_RootLocationDomoEmbedsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Order_By>>;
  where?: Maybe<Sensorflow_Location_Domo_Embeds_Bool_Exp>;
};

export type Subscription_RootLocationListArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Locations_Order_By>>;
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
};

export type Subscription_RootLocationMetadataArgs = {
  locationId: Scalars["uuid"];
};

export type Subscription_RootLocationOneArgs = {
  locationId: Scalars["uuid"];
};

export type Subscription_RootNodeExceptionArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Exception_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Exception_Order_By>>;
  where?: Maybe<Sensorflow_Node_Exception_Bool_Exp>;
};

export type Subscription_RootNodeExceptionAggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Exception_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Exception_Order_By>>;
  where?: Maybe<Sensorflow_Node_Exception_Bool_Exp>;
};

export type Subscription_RootNodeExceptionByPkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootNodeExceptionsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Exceptions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Exceptions_Order_By>>;
  where?: Maybe<Sensorflow_Node_Exceptions_Bool_Exp>;
};

export type Subscription_RootNodeExceptionsAggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Exceptions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Exceptions_Order_By>>;
  where?: Maybe<Sensorflow_Node_Exceptions_Bool_Exp>;
};

export type Subscription_RootNodeExceptionsByPkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootNodeJoinStatusesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
};

export type Subscription_RootNodeOnlineStatusesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Online_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Online_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Online_Status_Bool_Exp>;
};

export type Subscription_RootPositionArgs = {
  positionId: Scalars["uuid"];
};

export type Subscription_RootPositionConfigurationsArgs = {
  distinct_on?: Maybe<Array<PositionConfigurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurations_Order_By>>;
  where?: Maybe<PositionConfigurations_Bool_Exp>;
};

export type Subscription_RootPositionConfigurationsForActivityLogArgs = {
  distinct_on?: Maybe<Array<PositionConfigurationsForActivityLog_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurationsForActivityLog_Order_By>>;
  where?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
};

export type Subscription_RootPositionConfigurationsForActivityLog_AggregateArgs = {
  distinct_on?: Maybe<Array<PositionConfigurationsForActivityLog_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurationsForActivityLog_Order_By>>;
  where?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
};

export type Subscription_RootPositionConfigurationsForActivityLog_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PositionConfigurationsForActivityLog_Stream_Cursor_Input>>;
  where?: Maybe<PositionConfigurationsForActivityLog_Bool_Exp>;
};

export type Subscription_RootPositionConfigurations_AggregateArgs = {
  distinct_on?: Maybe<Array<PositionConfigurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PositionConfigurations_Order_By>>;
  where?: Maybe<PositionConfigurations_Bool_Exp>;
};

export type Subscription_RootPositionConfigurations_By_PkArgs = {
  creationDate: Scalars["timestamptz"];
  id: Scalars["uuid"];
};

export type Subscription_RootPositionConfigurations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PositionConfigurations_Stream_Cursor_Input>>;
  where?: Maybe<PositionConfigurations_Bool_Exp>;
};

export type Subscription_RootPositionPrecoolStatusArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Position_Precool_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Position_Precool_Status_Order_By>>;
  where?: Maybe<Sensorflow_Position_Precool_Status_Bool_Exp>;
};

export type Subscription_RootPositionsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Positions_Order_By>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Subscription_RootPositionsAggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Positions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Positions_Order_By>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ac_Command_Event_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ac_Command_Event_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ac_Command_Event_Stream_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ac_Command_Event_Stream_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ac_ModelsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Models_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Models_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Models_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ac_Models_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Models_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Models_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Models_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ac_Models_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Ac_Models_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ac_Models_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ac_Models_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ac_Setting_CommandsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ac_Setting_Commands_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Setting_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ac_Setting_Commands_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Ac_Setting_Commands_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ac_Setting_Commands_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ac_Setting_Commands_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Ac_Event_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Ac_Event_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Ac_Event_Stream_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Config_Event_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Config_Event_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Config_Event_Stream_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Event_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Event_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Event_Stream_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ai_Dt_Event_Stream_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Room_Comfort_ReportArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Room_Comfort_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Room_Comfort_Report_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Synced_Raw_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Synced_Raw_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_Dt_Synced_Raw_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_Ac_Command_Event_StreamArgs = {
  args: Sensorflow_Ai_F_Get_Ac_Command_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_Ac_Command_Event_Stream_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_Ac_Command_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_All_Event_StreamArgs = {
  args: Sensorflow_Ai_F_Get_All_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_All_Event_Stream_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_All_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Ac_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Ac_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_Config_Event_StreamArgs = {
  args: Sensorflow_Ai_F_Get_Config_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_Config_Event_Stream_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_Config_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Config_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_Event_StreamArgs = {
  args: Sensorflow_Ai_F_Get_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_Event_Stream_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_Id_InfoArgs = {
  args: Sensorflow_Ai_F_Get_Id_Info_Args;
  distinct_on?: Maybe<Array<Sensorflow_Autosets_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Autosets_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_Autosets_With_Keys_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_Id_Info_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_Id_Info_Args;
  distinct_on?: Maybe<Array<Sensorflow_Autosets_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Autosets_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_Autosets_With_Keys_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_Synced_Raw_DataArgs = {
  args: Sensorflow_Ai_F_Get_Synced_Raw_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Get_Synced_Raw_Data_AggregateArgs = {
  args: Sensorflow_Ai_F_Get_Synced_Raw_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Synced_Raw_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Room_Comfort_AnalysisArgs = {
  args: Sensorflow_Ai_F_Room_Comfort_Analysis_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_F_Room_Comfort_Analysis_AggregateArgs = {
  args: Sensorflow_Ai_F_Room_Comfort_Analysis_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_Dt_Room_Comfort_Report_Order_By>>;
  where?: Maybe<Sensorflow_Ai_Dt_Room_Comfort_Report_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_V_Get_Precool_Command_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_V_Get_Precool_Command_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_V_Get_Precool_Command_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_V_Get_Precool_Command_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ai_V_Get_Precool_Command_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_V_Get_Precool_Command_Stream_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ai_V_Get_Precool_Command_Stream_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ai_V_Get_Precool_Command_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_V_Room_StatusArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Order_By>>;
  where?: Maybe<Sensorflow_Ai_V_Room_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_V_Room_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ai_V_Room_Status_Order_By>>;
  where?: Maybe<Sensorflow_Ai_V_Room_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ai_V_Room_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ai_V_Room_Status_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ai_V_Room_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Autosets_With_KeysArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Autosets_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Autosets_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_Autosets_With_Keys_Bool_Exp>;
};

export type Subscription_RootSensorflow_Autosets_With_Keys_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Autosets_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Autosets_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_Autosets_With_Keys_Bool_Exp>;
};

export type Subscription_RootSensorflow_Autosets_With_Keys_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Autosets_With_Keys_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Autosets_With_Keys_Bool_Exp>;
};

export type Subscription_RootSensorflow_Avg_Position_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Avg_Position_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Avg_Position_Data_Order_By>>;
  where?: Maybe<Sensorflow_Avg_Position_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Avg_Position_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Avg_Position_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Avg_Position_Data_Order_By>>;
  where?: Maybe<Sensorflow_Avg_Position_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Avg_Position_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Avg_Position_Data_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Avg_Position_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Beacon_SlotsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Beacon_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Beacon_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Beacon_Slots_Bool_Exp>;
};

export type Subscription_RootSensorflow_Beacon_Slots_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Beacon_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Beacon_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Beacon_Slots_Bool_Exp>;
};

export type Subscription_RootSensorflow_Beacon_Slots_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Beacon_Slots_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Beacon_Slots_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Beacon_Slots_Bool_Exp>;
};

export type Subscription_RootSensorflow_Checklist_ItemsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Checklist_Items_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Checklist_Items_Order_By>>;
  where?: Maybe<Sensorflow_Checklist_Items_Bool_Exp>;
};

export type Subscription_RootSensorflow_Checklist_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Checklist_Items_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Checklist_Items_Order_By>>;
  where?: Maybe<Sensorflow_Checklist_Items_Bool_Exp>;
};

export type Subscription_RootSensorflow_Checklist_Items_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Checklist_Items_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Checklist_Items_Bool_Exp>;
};

export type Subscription_RootSensorflow_ChecklistsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Checklists_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Checklists_Order_By>>;
  where?: Maybe<Sensorflow_Checklists_Bool_Exp>;
};

export type Subscription_RootSensorflow_Checklists_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Checklists_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Checklists_Order_By>>;
  where?: Maybe<Sensorflow_Checklists_Bool_Exp>;
};

export type Subscription_RootSensorflow_Checklists_By_PkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootSensorflow_Checklists_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Checklists_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Checklists_Bool_Exp>;
};

export type Subscription_RootSensorflow_ChillersArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Chillers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Chillers_Order_By>>;
  where?: Maybe<Sensorflow_Chillers_Bool_Exp>;
};

export type Subscription_RootSensorflow_Chillers_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Chillers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Chillers_Order_By>>;
  where?: Maybe<Sensorflow_Chillers_Bool_Exp>;
};

export type Subscription_RootSensorflow_Chillers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Chillers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Chillers_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Chillers_Bool_Exp>;
};

export type Subscription_RootSensorflow_Clickup_Webhook_EventsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Order_By>>;
  where?: Maybe<Sensorflow_Clickup_Webhook_Events_Bool_Exp>;
};

export type Subscription_RootSensorflow_Clickup_Webhook_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Clickup_Webhook_Events_Order_By>>;
  where?: Maybe<Sensorflow_Clickup_Webhook_Events_Bool_Exp>;
};

export type Subscription_RootSensorflow_Clickup_Webhook_Events_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Clickup_Webhook_Events_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Clickup_Webhook_Events_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Clickup_Webhook_Events_Bool_Exp>;
};

export type Subscription_RootSensorflow_Comfortplus_ActivationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Comfortplus_Activations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Comfortplus_Activations_Order_By>>;
  where?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
};

export type Subscription_RootSensorflow_Comfortplus_Activations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Comfortplus_Activations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Comfortplus_Activations_Order_By>>;
  where?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
};

export type Subscription_RootSensorflow_Comfortplus_Activations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Comfortplus_Activations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Comfortplus_Activations_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Comfortplus_Activations_Bool_Exp>;
};

export type Subscription_RootSensorflow_Compressor_MappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressor_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressor_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Compressor_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressor_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressor_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Compressor_Mapping_By_PkArgs = {
  compressorId: Scalars["uuid"];
  positionId: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Compressor_Mapping_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Compressor_Mapping_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Compressor_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_CompressorsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressors_Order_By>>;
  where?: Maybe<Sensorflow_Compressors_Bool_Exp>;
};

export type Subscription_RootSensorflow_Compressors_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Compressors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Compressors_Order_By>>;
  where?: Maybe<Sensorflow_Compressors_Bool_Exp>;
};

export type Subscription_RootSensorflow_Compressors_By_PkArgs = {
  compressorId: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Compressors_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Compressors_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Compressors_Bool_Exp>;
};

export type Subscription_RootSensorflow_Config_Event_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Config_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Config_Event_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Config_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Config_Event_Stream_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Config_Event_Stream_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Config_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Cooling_TowersArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Cooling_Towers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Cooling_Towers_Order_By>>;
  where?: Maybe<Sensorflow_Cooling_Towers_Bool_Exp>;
};

export type Subscription_RootSensorflow_Cooling_Towers_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Cooling_Towers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Cooling_Towers_Order_By>>;
  where?: Maybe<Sensorflow_Cooling_Towers_Bool_Exp>;
};

export type Subscription_RootSensorflow_Cooling_Towers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Cooling_Towers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Cooling_Towers_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Cooling_Towers_Bool_Exp>;
};

export type Subscription_RootSensorflow_CurrenciesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Currencies_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Currencies_Order_By>>;
  where?: Maybe<Sensorflow_Currencies_Bool_Exp>;
};

export type Subscription_RootSensorflow_Currencies_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Currencies_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Currencies_Order_By>>;
  where?: Maybe<Sensorflow_Currencies_Bool_Exp>;
};

export type Subscription_RootSensorflow_Currencies_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Currencies_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Currencies_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Currencies_Bool_Exp>;
};

export type Subscription_RootSensorflow_Domo_Embeds_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Domo_Embeds_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Domo_Embeds_Order_By>>;
  where?: Maybe<Sensorflow_Domo_Embeds_Bool_Exp>;
};

export type Subscription_RootSensorflow_Domo_Embeds_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Domo_Embeds_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Domo_Embeds_Bool_Exp>;
};

export type Subscription_RootSensorflow_Entity_Feature_Flag_MappingsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Order_By>>;
  where?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp>;
};

export type Subscription_RootSensorflow_Entity_Feature_Flag_Mappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Entity_Feature_Flag_Mappings_Order_By>>;
  where?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp>;
};

export type Subscription_RootSensorflow_Entity_Feature_Flag_Mappings_By_PkArgs = {
  entity_id: Scalars["String"];
  feature_flag_id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Entity_Feature_Flag_Mappings_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Entity_Feature_Flag_Mappings_Bool_Exp>;
};

export type Subscription_RootSensorflow_Events_StreamArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Events_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Events_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Events_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Events_Stream_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Events_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Events_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Events_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_Events_Stream_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Events_Stream_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Events_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_Ac_Command_Event_StreamArgs = {
  args: Sensorflow_F_Get_Ac_Command_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_Ac_Command_Event_Stream_AggregateArgs = {
  args: Sensorflow_F_Get_Ac_Command_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_All_Event_StreamArgs = {
  args: Sensorflow_F_Get_All_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_All_Event_Stream_AggregateArgs = {
  args: Sensorflow_F_Get_All_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ac_Command_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Ac_Command_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_Avg_Position_DataArgs = {
  args: Sensorflow_F_Get_Avg_Position_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Avg_Position_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Avg_Position_Data_Order_By>>;
  where?: Maybe<Sensorflow_Avg_Position_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_Avg_Position_Data_AggregateArgs = {
  args: Sensorflow_F_Get_Avg_Position_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Avg_Position_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Avg_Position_Data_Order_By>>;
  where?: Maybe<Sensorflow_Avg_Position_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_Config_Event_StreamArgs = {
  args: Sensorflow_F_Get_Config_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Config_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_Config_Event_Stream_AggregateArgs = {
  args: Sensorflow_F_Get_Config_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Config_Event_Stream_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Config_Event_Stream_Order_By>>;
  where?: Maybe<Sensorflow_Config_Event_Stream_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_Event_StreamArgs = {
  args: Sensorflow_F_Get_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Order_By>>;
  where?: Maybe<Sensorflow_Get_Event_Stream_View_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_Event_Stream_AggregateArgs = {
  args: Sensorflow_F_Get_Event_Stream_Args;
  distinct_on?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Order_By>>;
  where?: Maybe<Sensorflow_Get_Event_Stream_View_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_Synced_Raw_DataArgs = {
  args: Sensorflow_F_Get_Synced_Raw_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Synced_Raw_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_F_Get_Synced_Raw_Data_AggregateArgs = {
  args: Sensorflow_F_Get_Synced_Raw_Data_Args;
  distinct_on?: Maybe<Array<Sensorflow_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Synced_Raw_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Feature_FlagsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Feature_Flags_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Feature_Flags_Order_By>>;
  where?: Maybe<Sensorflow_Feature_Flags_Bool_Exp>;
};

export type Subscription_RootSensorflow_Feature_Flags_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Feature_Flags_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Feature_Flags_Order_By>>;
  where?: Maybe<Sensorflow_Feature_Flags_Bool_Exp>;
};

export type Subscription_RootSensorflow_Feature_Flags_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Feature_Flags_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Feature_Flags_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Feature_Flags_Bool_Exp>;
};

export type Subscription_RootSensorflow_Firmware_Releases_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Firmware_Releases_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Firmware_Releases_Order_By>>;
  where?: Maybe<Sensorflow_Firmware_Releases_Bool_Exp>;
};

export type Subscription_RootSensorflow_Firmware_Releases_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Firmware_Releases_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Firmware_Releases_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Firmware_Releases_Bool_Exp>;
};

export type Subscription_RootSensorflow_Gateway_FrequencyArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_Frequency_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_Frequency_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_Frequency_Bool_Exp>;
};

export type Subscription_RootSensorflow_Gateway_Frequency_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_Frequency_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_Frequency_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_Frequency_Bool_Exp>;
};

export type Subscription_RootSensorflow_Gateway_Frequency_By_PkArgs = {
  balena_id: Scalars["String"];
  reserved_frequency: Scalars["bigint"];
};

export type Subscription_RootSensorflow_Gateway_Frequency_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Gateway_Frequency_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Gateway_Frequency_Bool_Exp>;
};

export type Subscription_RootSensorflow_Gateway_Health_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_Health_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_Health_Data_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Gateway_Health_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_Health_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_Health_Data_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Gateway_Health_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Gateway_Health_Data_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Gateway_Health_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Gateway_To_Position_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateway_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Gateway_To_Position_Mapping_By_PkArgs = {
  positionId: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Gateway_To_Position_Mapping_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Gateway_To_Position_Mapping_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Gateway_To_Position_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Gateways_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Gateways_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Gateways_Order_By>>;
  where?: Maybe<Sensorflow_Gateways_Bool_Exp>;
};

export type Subscription_RootSensorflow_Gateways_By_PkArgs = {
  gatewayId: Scalars["String"];
};

export type Subscription_RootSensorflow_Gateways_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Gateways_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Gateways_Bool_Exp>;
};

export type Subscription_RootSensorflow_Get_Event_Stream_ViewArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Order_By>>;
  where?: Maybe<Sensorflow_Get_Event_Stream_View_Bool_Exp>;
};

export type Subscription_RootSensorflow_Get_Event_Stream_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Event_Stream_View_Order_By>>;
  where?: Maybe<Sensorflow_Get_Event_Stream_View_Bool_Exp>;
};

export type Subscription_RootSensorflow_Get_Event_Stream_View_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Get_Event_Stream_View_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Get_Event_Stream_View_Bool_Exp>;
};

export type Subscription_RootSensorflow_Get_Precool_Command_StreamsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Order_By>>;
  where?: Maybe<Sensorflow_Get_Precool_Command_Streams_Bool_Exp>;
};

export type Subscription_RootSensorflow_Get_Precool_Command_Streams_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Get_Precool_Command_Streams_Order_By>>;
  where?: Maybe<Sensorflow_Get_Precool_Command_Streams_Bool_Exp>;
};

export type Subscription_RootSensorflow_Get_Precool_Command_Streams_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Get_Precool_Command_Streams_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Get_Precool_Command_Streams_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Categories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Categories_Order_By>>;
  where?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Categories_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Key_Categories_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Key_Categories_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Key_Categories_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_Room_TemplatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_Room_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Room_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_Room_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Key_Category_Room_Templates_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Key_Category_Room_Templates_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Key_Category_Room_Templates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_Slot_TemplatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_Slot_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Slot_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_Slot_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Key_Category_Slot_Templates_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Key_Category_Slot_Templates_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Key_Category_Slot_Templates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_TemplatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Templates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_Templates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_Templates_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_Templates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_Templates_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Key_Category_Templates_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Key_Category_Templates_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Key_Category_Templates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_To_Key_PositionArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_To_Key_Position_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Key_Category_To_Key_Position_Order_By>>;
  where?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
};

export type Subscription_RootSensorflow_Key_Category_To_Key_Position_By_PkArgs = {
  categoryName: Scalars["String"];
  locationName: Scalars["String"];
  positionId: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Key_Category_To_Key_Position_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Key_Category_To_Key_Position_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Key_Category_To_Key_Position_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_AddressesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Addresses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Addresses_Order_By>>;
  where?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Addresses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Addresses_Order_By>>;
  where?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Addresses_By_PkArgs = {
  location_id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Location_Addresses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Location_Addresses_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Location_Addresses_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_ConsumptionsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Consumptions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Consumptions_Order_By>>;
  where?: Maybe<Sensorflow_Location_Consumptions_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Consumptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Consumptions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Consumptions_Order_By>>;
  where?: Maybe<Sensorflow_Location_Consumptions_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Consumptions_By_PkArgs = {
  consumptionPeriodEnd: Scalars["date"];
  consumptionPeriodStart: Scalars["date"];
  locationId: Scalars["uuid"];
  metricTypeId: Scalars["Int"];
};

export type Subscription_RootSensorflow_Location_Consumptions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Location_Consumptions_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Location_Consumptions_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Domo_Embeds_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Domo_Embeds_Order_By>>;
  where?: Maybe<Sensorflow_Location_Domo_Embeds_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Domo_Embeds_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Location_Domo_Embeds_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Location_Domo_Embeds_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_MetadataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Metadata_Order_By>>;
  where?: Maybe<Sensorflow_Location_Metadata_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Metadata_Order_By>>;
  where?: Maybe<Sensorflow_Location_Metadata_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Metadata_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Location_Metadata_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Location_Metadata_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Occ_Customer_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Order_By>>;
  where?: Maybe<Sensorflow_Location_Occ_Customer_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Occ_Customer_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Occ_Customer_Data_Order_By>>;
  where?: Maybe<Sensorflow_Location_Occ_Customer_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Occ_Customer_Data_By_PkArgs = {
  locationId: Scalars["uuid"];
  periodEnd: Scalars["date"];
  periodStart: Scalars["date"];
};

export type Subscription_RootSensorflow_Location_Occ_Customer_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Location_Occ_Customer_Data_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Location_Occ_Customer_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Occ_Derived_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Order_By>>;
  where?: Maybe<Sensorflow_Location_Occ_Derived_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Occ_Derived_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_Occ_Derived_Data_Order_By>>;
  where?: Maybe<Sensorflow_Location_Occ_Derived_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_Occ_Derived_Data_By_PkArgs = {
  date: Scalars["date"];
  locationId: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Location_Occ_Derived_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Location_Occ_Derived_Data_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Location_Occ_Derived_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_To_User_MappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_To_User_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Location_To_User_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Location_To_User_Mapping_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Location_To_User_Mapping_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Location_To_User_Mapping_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Location_To_User_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Locations_Order_By>>;
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
};

export type Subscription_RootSensorflow_Locations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Locations_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
};

export type Subscription_RootSensorflow_Mapping_CoordinatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Mapping_Coordinates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Mapping_Coordinates_Order_By>>;
  where?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Mapping_Coordinates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Mapping_Coordinates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Mapping_Coordinates_Order_By>>;
  where?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Mapping_Coordinates_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Mapping_Coordinates_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Mapping_Coordinates_Bool_Exp>;
};

export type Subscription_RootSensorflow_Metric_TypesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Metric_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Metric_Types_Order_By>>;
  where?: Maybe<Sensorflow_Metric_Types_Bool_Exp>;
};

export type Subscription_RootSensorflow_Metric_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Metric_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Metric_Types_Order_By>>;
  where?: Maybe<Sensorflow_Metric_Types_Bool_Exp>;
};

export type Subscription_RootSensorflow_Metric_Types_By_PkArgs = {
  metricTypeId: Scalars["Int"];
};

export type Subscription_RootSensorflow_Metric_Types_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Metric_Types_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Metric_Types_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Exception_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Exception_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Exception_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Exceptions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Exceptions_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Exceptions_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_FirmwareArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Firmware_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Firmware_Order_By>>;
  where?: Maybe<Sensorflow_Node_Firmware_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Firmware_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Firmware_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Firmware_Order_By>>;
  where?: Maybe<Sensorflow_Node_Firmware_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Firmware_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Firmware_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Firmware_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Join_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Join_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Join_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Join_Data_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Join_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Join_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Join_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Join_Status_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Join_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Join_Strength_LiveArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Strength_Live_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Join_Strength_Live_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Join_Strength_Live_Order_By>>;
  where?: Maybe<Sensorflow_Node_Join_Strength_Live_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Join_Strength_Live_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Join_Strength_Live_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Join_Strength_Live_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Life_Cycle_Event_TypesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Order_By>>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Life_Cycle_Event_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Life_Cycle_Event_Types_Order_By>>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Life_Cycle_Event_Types_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Node_Life_Cycle_Event_Types_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Event_Types_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Life_Cycle_EventsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Order_By>>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Life_Cycle_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Order_By>>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Life_Cycle_Events_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Node_Life_Cycle_Events_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Life_Cycle_Events_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Measurement_ViewArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurement_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurement_View_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurement_View_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Measurement_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurement_View_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurement_View_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurement_View_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Measurement_View_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Measurement_View_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Measurement_View_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_MeasurementsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurements_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurements_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Measurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Measurements_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Measurements_Order_By>>;
  where?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Measurements_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Measurements_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Measurements_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Meta_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Meta_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Meta_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Meta_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Meta_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Meta_Data_Order_By>>;
  where?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Meta_Data_By_PkArgs = {
  measurementType: Scalars["String"];
  nodeMacId: Scalars["String"];
  time: Scalars["timestamptz"];
};

export type Subscription_RootSensorflow_Node_Meta_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Meta_Data_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Meta_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Meta_StatusArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Meta_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Meta_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Meta_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Meta_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Meta_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Meta_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Meta_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Meta_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Meta_Status_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Meta_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Online_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Online_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Online_Status_Order_By>>;
  where?: Maybe<Sensorflow_Node_Online_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Online_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Online_Status_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Online_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_SlotsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Slots_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Slots_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Node_Slots_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Slots_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Slots_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Spare_SetArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Spare_Set_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Spare_Set_Order_By>>;
  where?: Maybe<Sensorflow_Node_Spare_Set_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Spare_Set_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Spare_Set_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Spare_Set_Order_By>>;
  where?: Maybe<Sensorflow_Node_Spare_Set_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Spare_Set_By_PkArgs = {
  locationId: Scalars["uuid"];
  nodeMacId: Scalars["String"];
};

export type Subscription_RootSensorflow_Node_Spare_Set_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Spare_Set_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Spare_Set_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Status_HistoryArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_Node_Status_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Status_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_Node_Status_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Status_History_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Node_Status_History_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Status_History_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Status_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_StatusesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Statuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Statuses_Order_By>>;
  where?: Maybe<Sensorflow_Node_Statuses_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Statuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_Statuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_Statuses_Order_By>>;
  where?: Maybe<Sensorflow_Node_Statuses_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_Statuses_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Node_Statuses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_Statuses_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_Statuses_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_System_StatesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_System_States_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_System_States_Order_By>>;
  where?: Maybe<Sensorflow_Node_System_States_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_System_States_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_System_States_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_System_States_Order_By>>;
  where?: Maybe<Sensorflow_Node_System_States_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_System_States_By_PkArgs = {
  nodeMacId: Scalars["String"];
  time: Scalars["timestamptz"];
};

export type Subscription_RootSensorflow_Node_System_States_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_System_States_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_System_States_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_To_Position_MappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Position_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_To_Position_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Position_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Position_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_To_Position_Mapping_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_To_Position_Mapping_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_To_Position_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_To_Slot_MappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_To_Slot_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_Node_To_Slot_Mapping_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Node_To_Slot_Mapping_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Node_To_Slot_Mapping_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_NodesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Nodes_Order_By>>;
  where?: Maybe<Sensorflow_Nodes_Bool_Exp>;
};

export type Subscription_RootSensorflow_Nodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Nodes_Order_By>>;
  where?: Maybe<Sensorflow_Nodes_Bool_Exp>;
};

export type Subscription_RootSensorflow_Nodes_By_PkArgs = {
  node_mac_id: Scalars["String"];
};

export type Subscription_RootSensorflow_Nodes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Nodes_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Nodes_Bool_Exp>;
};

export type Subscription_RootSensorflow_OrganisationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Organisations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Organisations_Order_By>>;
  where?: Maybe<Sensorflow_Organisations_Bool_Exp>;
};

export type Subscription_RootSensorflow_Organisations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Organisations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Organisations_Order_By>>;
  where?: Maybe<Sensorflow_Organisations_Bool_Exp>;
};

export type Subscription_RootSensorflow_Organisations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Organisations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Organisations_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Organisations_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ota_QueuesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ota_Queues_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ota_Queues_Order_By>>;
  where?: Maybe<Sensorflow_Ota_Queues_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ota_Queues_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ota_Queues_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ota_Queues_Order_By>>;
  where?: Maybe<Sensorflow_Ota_Queues_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ota_Queues_By_PkArgs = {
  ota_queue_id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Ota_Queues_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ota_Queues_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ota_Queues_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ota_RunsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ota_Runs_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ota_Runs_Order_By>>;
  where?: Maybe<Sensorflow_Ota_Runs_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ota_Runs_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Ota_Runs_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Ota_Runs_Order_By>>;
  where?: Maybe<Sensorflow_Ota_Runs_Bool_Exp>;
};

export type Subscription_RootSensorflow_Ota_Runs_By_PkArgs = {
  current_firmware_release_id: Scalars["Int"];
  node_mac_id: Scalars["String"];
  ota_queue_id: Scalars["Int"];
  target_firmware_release_id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Ota_Runs_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Ota_Runs_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Ota_Runs_Bool_Exp>;
};

export type Subscription_RootSensorflow_Outdoor_TemperaturesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Order_By>>;
  where?: Maybe<Sensorflow_Outdoor_Temperatures_Bool_Exp>;
};

export type Subscription_RootSensorflow_Outdoor_Temperatures_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Outdoor_Temperatures_Order_By>>;
  where?: Maybe<Sensorflow_Outdoor_Temperatures_Bool_Exp>;
};

export type Subscription_RootSensorflow_Outdoor_Temperatures_By_PkArgs = {
  positionId: Scalars["uuid"];
  time: Scalars["timestamptz"];
};

export type Subscription_RootSensorflow_Outdoor_Temperatures_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Outdoor_Temperatures_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Outdoor_Temperatures_Bool_Exp>;
};

export type Subscription_RootSensorflow_Pending_Ac_Settings_Commands_For_GatewayArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Order_By>>;
  where?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Bool_Exp>;
};

export type Subscription_RootSensorflow_Pending_Ac_Settings_Commands_For_Gateway_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Order_By>>;
  where?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Bool_Exp>;
};

export type Subscription_RootSensorflow_Pending_Ac_Settings_Commands_For_Gateway_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Pending_Ac_Settings_Commands_For_Gateway_Bool_Exp>;
};

export type Subscription_RootSensorflow_PipesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pipes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pipes_Order_By>>;
  where?: Maybe<Sensorflow_Pipes_Bool_Exp>;
};

export type Subscription_RootSensorflow_Pipes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pipes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pipes_Order_By>>;
  where?: Maybe<Sensorflow_Pipes_Bool_Exp>;
};

export type Subscription_RootSensorflow_Pipes_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Pipes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Pipes_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Pipes_Bool_Exp>;
};

export type Subscription_RootSensorflow_Position_Precool_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Position_Precool_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Position_Precool_Status_Order_By>>;
  where?: Maybe<Sensorflow_Position_Precool_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Position_Precool_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Position_Precool_Status_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Position_Precool_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_Position_Signal_StatsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Position_Signal_Stats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Position_Signal_Stats_Order_By>>;
  where?: Maybe<Sensorflow_Position_Signal_Stats_Bool_Exp>;
};

export type Subscription_RootSensorflow_Position_Signal_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Position_Signal_Stats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Position_Signal_Stats_Order_By>>;
  where?: Maybe<Sensorflow_Position_Signal_Stats_Bool_Exp>;
};

export type Subscription_RootSensorflow_Position_Signal_Stats_By_PkArgs = {
  balena_id: Scalars["String"];
  position_id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Position_Signal_Stats_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Position_Signal_Stats_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Position_Signal_Stats_Bool_Exp>;
};

export type Subscription_RootSensorflow_Positions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Positions_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type Subscription_RootSensorflow_Precool_CommandsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Precool_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Precool_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
};

export type Subscription_RootSensorflow_Precool_Commands_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Precool_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Precool_Commands_Order_By>>;
  where?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
};

export type Subscription_RootSensorflow_Precool_Commands_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Precool_Commands_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Precool_Commands_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
};

export type Subscription_RootSensorflow_Precool_SchedulesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Precool_Schedules_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Precool_Schedules_Order_By>>;
  where?: Maybe<Sensorflow_Precool_Schedules_Bool_Exp>;
};

export type Subscription_RootSensorflow_Precool_Schedules_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Precool_Schedules_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Precool_Schedules_Order_By>>;
  where?: Maybe<Sensorflow_Precool_Schedules_Bool_Exp>;
};

export type Subscription_RootSensorflow_Precool_Schedules_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootSensorflow_Precool_Schedules_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Precool_Schedules_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Precool_Schedules_Bool_Exp>;
};

export type Subscription_RootSensorflow_Properties_InformationArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Properties_Information_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Properties_Information_Order_By>>;
  where?: Maybe<Sensorflow_Properties_Information_Bool_Exp>;
};

export type Subscription_RootSensorflow_Properties_Information_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Properties_Information_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Properties_Information_Order_By>>;
  where?: Maybe<Sensorflow_Properties_Information_Bool_Exp>;
};

export type Subscription_RootSensorflow_Properties_Information_By_PkArgs = {
  locationId: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Properties_Information_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Properties_Information_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Properties_Information_Bool_Exp>;
};

export type Subscription_RootSensorflow_PumpsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pumps_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pumps_Order_By>>;
  where?: Maybe<Sensorflow_Pumps_Bool_Exp>;
};

export type Subscription_RootSensorflow_Pumps_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Pumps_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Pumps_Order_By>>;
  where?: Maybe<Sensorflow_Pumps_Bool_Exp>;
};

export type Subscription_RootSensorflow_Pumps_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Pumps_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Pumps_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Pumps_Bool_Exp>;
};

export type Subscription_RootSensorflow_Reservation_GuestsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservation_Guests_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservation_Guests_Order_By>>;
  where?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
};

export type Subscription_RootSensorflow_Reservation_Guests_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservation_Guests_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservation_Guests_Order_By>>;
  where?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
};

export type Subscription_RootSensorflow_Reservation_Guests_By_PkArgs = {
  reservationId: Scalars["bigint"];
  userId: Scalars["String"];
};

export type Subscription_RootSensorflow_Reservation_Guests_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Reservation_Guests_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Reservation_Guests_Bool_Exp>;
};

export type Subscription_RootSensorflow_ReservationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservations_Order_By>>;
  where?: Maybe<Sensorflow_Reservations_Bool_Exp>;
};

export type Subscription_RootSensorflow_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Reservations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Reservations_Order_By>>;
  where?: Maybe<Sensorflow_Reservations_Bool_Exp>;
};

export type Subscription_RootSensorflow_Reservations_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootSensorflow_Reservations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Reservations_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Reservations_Bool_Exp>;
};

export type Subscription_RootSensorflow_RolesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Roles_Order_By>>;
  where?: Maybe<Sensorflow_Roles_Bool_Exp>;
};

export type Subscription_RootSensorflow_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Roles_Order_By>>;
  where?: Maybe<Sensorflow_Roles_Bool_Exp>;
};

export type Subscription_RootSensorflow_Roles_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Roles_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Roles_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Roles_Bool_Exp>;
};

export type Subscription_RootSensorflow_Smart_Rem_Validation_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smart_Rem_Validation_History_Order_By>>;
  where?: Maybe<Sensorflow_Smart_Rem_Validation_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_Smart_Rem_Validation_History_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Smart_Rem_Validation_History_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Smart_Rem_Validation_History_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Smart_Rem_Validation_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_Smplrspace_Floors_To_LevelsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Order_By>>;
  where?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
};

export type Subscription_RootSensorflow_Smplrspace_Floors_To_Levels_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smplrspace_Floors_To_Levels_Order_By>>;
  where?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
};

export type Subscription_RootSensorflow_Smplrspace_Floors_To_Levels_By_PkArgs = {
  position_id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Smplrspace_Floors_To_Levels_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
};

export type Subscription_RootSensorflow_Smplrspace_Levels_GroupArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Order_By>>;
  where?: Maybe<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>;
};

export type Subscription_RootSensorflow_Smplrspace_Levels_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Smplrspace_Levels_Group_Order_By>>;
  where?: Maybe<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>;
};

export type Subscription_RootSensorflow_Smplrspace_Levels_Group_By_PkArgs = {
  groupId: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Smplrspace_Levels_Group_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Smplrspace_Levels_Group_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>;
};

export type Subscription_RootSensorflow_Survey_ResponsesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Survey_Responses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Survey_Responses_Order_By>>;
  where?: Maybe<Sensorflow_Survey_Responses_Bool_Exp>;
};

export type Subscription_RootSensorflow_Survey_Responses_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Survey_Responses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Survey_Responses_Order_By>>;
  where?: Maybe<Sensorflow_Survey_Responses_Bool_Exp>;
};

export type Subscription_RootSensorflow_Survey_Responses_By_PkArgs = {
  responseId: Scalars["Int"];
};

export type Subscription_RootSensorflow_Survey_Responses_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Survey_Responses_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Survey_Responses_Bool_Exp>;
};

export type Subscription_RootSensorflow_Synced_Raw_DataArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Synced_Raw_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Synced_Raw_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Synced_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Synced_Raw_Data_Order_By>>;
  where?: Maybe<Sensorflow_Synced_Raw_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Synced_Raw_Data_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Synced_Raw_Data_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Synced_Raw_Data_Bool_Exp>;
};

export type Subscription_RootSensorflow_Task_Status_HistoryArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Task_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Task_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_Task_Status_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_Task_Status_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Task_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Task_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_Task_Status_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_Task_Status_History_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Task_Status_History_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Task_Status_History_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Task_Status_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_TasksArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Tasks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Tasks_Order_By>>;
  where?: Maybe<Sensorflow_Tasks_Bool_Exp>;
};

export type Subscription_RootSensorflow_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Tasks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Tasks_Order_By>>;
  where?: Maybe<Sensorflow_Tasks_Bool_Exp>;
};

export type Subscription_RootSensorflow_Tasks_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSensorflow_Tasks_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Tasks_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Tasks_Bool_Exp>;
};

export type Subscription_RootSensorflow_TestsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Tests_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Tests_Order_By>>;
  where?: Maybe<Sensorflow_Tests_Bool_Exp>;
};

export type Subscription_RootSensorflow_Tests_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Tests_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Tests_Order_By>>;
  where?: Maybe<Sensorflow_Tests_Bool_Exp>;
};

export type Subscription_RootSensorflow_Tests_By_PkArgs = {
  testId: Scalars["Int"];
};

export type Subscription_RootSensorflow_Tests_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Tests_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Tests_Bool_Exp>;
};

export type Subscription_RootSensorflow_TimezonesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Timezones_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Timezones_Order_By>>;
  where?: Maybe<Sensorflow_Timezones_Bool_Exp>;
};

export type Subscription_RootSensorflow_Timezones_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Timezones_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Timezones_Order_By>>;
  where?: Maybe<Sensorflow_Timezones_Bool_Exp>;
};

export type Subscription_RootSensorflow_Timezones_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSensorflow_Timezones_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Timezones_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Timezones_Bool_Exp>;
};

export type Subscription_RootSensorflow_Updatable_NodesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Updatable_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Updatable_Nodes_Order_By>>;
  where?: Maybe<Sensorflow_Updatable_Nodes_Bool_Exp>;
};

export type Subscription_RootSensorflow_Updatable_Nodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Updatable_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Updatable_Nodes_Order_By>>;
  where?: Maybe<Sensorflow_Updatable_Nodes_Bool_Exp>;
};

export type Subscription_RootSensorflow_Updatable_Nodes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Updatable_Nodes_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Updatable_Nodes_Bool_Exp>;
};

export type Subscription_RootSensorflow_User_To_Permission_MappingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_User_To_Permission_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_User_To_Permission_Mapping_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_User_To_Permission_Mapping_Order_By>>;
  where?: Maybe<Sensorflow_User_To_Permission_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_User_To_Permission_Mapping_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_User_To_Permission_Mapping_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_User_To_Permission_Mapping_Bool_Exp>;
};

export type Subscription_RootSensorflow_UsersArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Users_Order_By>>;
  where?: Maybe<Sensorflow_Users_Bool_Exp>;
};

export type Subscription_RootSensorflow_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Users_Order_By>>;
  where?: Maybe<Sensorflow_Users_Bool_Exp>;
};

export type Subscription_RootSensorflow_Users_By_PkArgs = {
  userId: Scalars["String"];
};

export type Subscription_RootSensorflow_Users_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Users_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Users_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_All_Latest_Node_ConfigurationsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Order_By>>;
  where?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_All_Latest_Node_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_All_Latest_Node_Configurations_Order_By>>;
  where?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_All_Latest_Node_Configurations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_All_Latest_Node_Configurations_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_All_Latest_Node_Configurations_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Combined_User_RolesArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Order_By>>;
  where?: Maybe<Sensorflow_V_Combined_User_Roles_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Combined_User_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Combined_User_Roles_Order_By>>;
  where?: Maybe<Sensorflow_V_Combined_User_Roles_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Combined_User_Roles_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Combined_User_Roles_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Combined_User_Roles_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Comfortplus_Key_DetailsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Comfortplus_Key_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Details_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Comfortplus_Key_Details_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Comfortplus_Key_Details_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Key_Details_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Comfortplus_Key_MeasurementsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Comfortplus_Key_Measurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Key_Measurements_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Comfortplus_Key_Measurements_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Key_Measurements_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Comfortplus_Precool_StatusArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Comfortplus_Precool_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Comfortplus_Precool_Status_Order_By>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Comfortplus_Precool_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Comfortplus_Precool_Status_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Comfortplus_Precool_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Current_Position_Configurations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Current_Position_Configurations_Order_By>>;
  where?: Maybe<Sensorflow_V_Current_Position_Configurations_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Current_Position_Configurations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Current_Position_Configurations_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Current_Position_Configurations_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Group_TasksArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Group_Tasks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Group_Tasks_Order_By>>;
  where?: Maybe<Sensorflow_V_Group_Tasks_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Group_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Group_Tasks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Group_Tasks_Order_By>>;
  where?: Maybe<Sensorflow_V_Group_Tasks_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Group_Tasks_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Group_Tasks_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Group_Tasks_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Group_With_StatsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Group_With_Stats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Group_With_Stats_Order_By>>;
  where?: Maybe<Sensorflow_V_Group_With_Stats_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Group_With_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Group_With_Stats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Group_With_Stats_Order_By>>;
  where?: Maybe<Sensorflow_V_Group_With_Stats_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Group_With_Stats_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Group_With_Stats_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Group_With_Stats_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Gw_Autoset_Min_SignalArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Order_By>>;
  where?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Gw_Autoset_Min_Signal_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Gw_Autoset_Min_Signal_Order_By>>;
  where?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Gw_Autoset_Min_Signal_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Gw_Autoset_Min_Signal_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Infrastructure_Emon_Power_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Infrastructure_Emon_Power_Status_Order_By>>;
  where?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Infrastructure_Emon_Power_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Infrastructure_Emon_Power_Status_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Infrastructures_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Infrastructures_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Infrastructures_Order_By>>;
  where?: Maybe<Sensorflow_V_Infrastructures_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Infrastructures_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Infrastructures_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Infrastructures_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Key_Emon_Power_ReadingArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Key_Emon_Power_Reading_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Emon_Power_Reading_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Key_Emon_Power_Reading_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Key_Emon_Power_Reading_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Key_Emon_Power_Reading_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Key_Position_Latest_EntryArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Key_Position_Latest_Entry_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Position_Latest_Entry_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Key_Position_Latest_Entry_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Key_Position_Latest_Entry_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Key_Task_Min_DuedateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Task_Min_Duedate_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Key_Task_Min_Duedate_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Key_Task_Min_Duedate_Order_By>>;
  where?: Maybe<Sensorflow_V_Key_Task_Min_Duedate_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Key_Task_Min_Duedate_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Key_Task_Min_Duedate_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Key_Task_Min_Duedate_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Location_Last_UpdateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Location_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Location_Last_Update_Order_By>>;
  where?: Maybe<Sensorflow_V_Location_Last_Update_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Location_Last_Update_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Location_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Location_Last_Update_Order_By>>;
  where?: Maybe<Sensorflow_V_Location_Last_Update_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Location_Last_Update_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Location_Last_Update_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Location_Last_Update_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Node_Life_Cycle_EventsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Order_By>>;
  where?: Maybe<Sensorflow_V_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Node_Life_Cycle_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Order_By>>;
  where?: Maybe<Sensorflow_V_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Node_Life_Cycle_Events_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Node_Life_Cycle_Events_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Node_Life_Cycle_Events_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Task_With_Key_Status_HistoryArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Task_With_Key_Status_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Task_With_Key_Status_History_Order_By>>;
  where?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Task_With_Key_Status_History_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Task_With_Key_Status_History_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Task_With_Key_Status_History_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Tasks_With_Keys_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_V_Tasks_With_Keys_Bool_Exp>;
};

export type Subscription_RootSensorflow_V_Tasks_With_Keys_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_V_Tasks_With_Keys_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_V_Tasks_With_Keys_Bool_Exp>;
};

export type Subscription_RootSensorflow_Warehouse_Kitting_Process_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Warehouse_Kitting_Process_Metadata_Order_By>>;
  where?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
};

export type Subscription_RootSensorflow_Warehouse_Kitting_Process_Metadata_By_PkArgs = {
  balenaId: Scalars["String"];
};

export type Subscription_RootSensorflow_Warehouse_Kitting_Process_Metadata_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Warehouse_Kitting_Process_Metadata_Bool_Exp>;
};

export type Subscription_RootSensorflow_Warehouse_Mapping_Status_Of_SlotsArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Bool_Exp>;
};

export type Subscription_RootSensorflow_Warehouse_Mapping_Status_Of_Slots_AggregateArgs = {
  distinct_on?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Order_By>>;
  where?: Maybe<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Bool_Exp>;
};

export type Subscription_RootSensorflow_Warehouse_Mapping_Status_Of_Slots_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Stream_Cursor_Input>>;
  where?: Maybe<Sensorflow_Warehouse_Mapping_Status_Of_Slots_Bool_Exp>;
};

export type Subscription_RootSf_Firmware_Dist_Pipeline_Firmware_BuildArgs = {
  distinct_on?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Order_By>>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp>;
};

export type Subscription_RootSf_Firmware_Dist_Pipeline_Firmware_Build_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Build_Order_By>>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp>;
};

export type Subscription_RootSf_Firmware_Dist_Pipeline_Firmware_Build_By_PkArgs = {
  buildId: Scalars["Int"];
};

export type Subscription_RootSf_Firmware_Dist_Pipeline_Firmware_Build_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Build_Bool_Exp>;
};

export type Subscription_RootSf_Firmware_Dist_Pipeline_Firmware_NumbersArgs = {
  distinct_on?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Order_By>>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp>;
};

export type Subscription_RootSf_Firmware_Dist_Pipeline_Firmware_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Order_By>>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp>;
};

export type Subscription_RootSf_Firmware_Dist_Pipeline_Firmware_Numbers_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSf_Firmware_Dist_Pipeline_Firmware_Numbers_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Firmware_Dist_Pipeline_Firmware_Numbers_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_PmslistArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Pmslist_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Pmslist_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Pmslist_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Pmslist_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Pmslist_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Pmslist_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Pmslist_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Pmslist_By_PkArgs = {
  pms_id: Scalars["Int"];
};

export type Subscription_RootSf_Pms_Pms_Pmslist_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Pms_Pms_Pmslist_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Pms_Pms_Pmslist_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_PropertiesArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Properties_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Properties_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Properties_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Properties_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Properties_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Properties_By_PkArgs = {
  pms_id: Scalars["Int"];
  property_id: Scalars["String"];
};

export type Subscription_RootSf_Pms_Pms_Properties_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Pms_Pms_Properties_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Pms_Pms_Properties_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Property_Eod_SurveysArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Property_Eod_Surveys_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Property_Eod_Surveys_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Property_Eod_Surveys_By_PkArgs = {
  pms_id: Scalars["Int"];
  property_id: Scalars["String"];
  survey_monkey_id: Scalars["String"];
};

export type Subscription_RootSf_Pms_Pms_Property_Eod_Surveys_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Pms_Pms_Property_Eod_Surveys_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_ReservationsArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Reservations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Reservations_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Reservations_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Reservations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Reservations_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Reservations_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Reservations_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Pms_Pms_Reservations_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Pms_Pms_Reservations_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Room_CategoriesArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Room_Categories_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Room_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Room_Categories_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Room_Categories_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Pms_Pms_Room_Categories_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Pms_Pms_Room_Categories_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_RoomsArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Rooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Rooms_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Rooms_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Rooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Pms_Rooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Pms_Rooms_Order_By>>;
  where?: Maybe<Sf_Pms_Pms_Rooms_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Pms_Rooms_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Pms_Pms_Rooms_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Pms_Pms_Rooms_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Smartalloc_ReportArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Smartalloc_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Smartalloc_Report_Order_By>>;
  where?: Maybe<Sf_Pms_Smartalloc_Report_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Smartalloc_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Pms_Smartalloc_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Pms_Smartalloc_Report_Order_By>>;
  where?: Maybe<Sf_Pms_Smartalloc_Report_Bool_Exp>;
};

export type Subscription_RootSf_Pms_Smartalloc_Report_By_PkArgs = {
  date: Scalars["date"];
  locationId: Scalars["uuid"];
  type: Scalars["String"];
};

export type Subscription_RootSf_Pms_Smartalloc_Report_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Pms_Smartalloc_Report_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Pms_Smartalloc_Report_Bool_Exp>;
};

export type Subscription_RootSf_Simulation_Instrumentation_CommandsArgs = {
  distinct_on?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Order_By>>;
  where?: Maybe<Sf_Simulation_Instrumentation_Commands_Bool_Exp>;
};

export type Subscription_RootSf_Simulation_Instrumentation_Commands_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Simulation_Instrumentation_Commands_Order_By>>;
  where?: Maybe<Sf_Simulation_Instrumentation_Commands_Bool_Exp>;
};

export type Subscription_RootSf_Simulation_Instrumentation_Commands_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSf_Simulation_Instrumentation_Commands_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Simulation_Instrumentation_Commands_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Simulation_Instrumentation_Commands_Bool_Exp>;
};

export type Subscription_RootSf_Support_ActionsArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Actions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Actions_Order_By>>;
  where?: Maybe<Sf_Support_Actions_Bool_Exp>;
};

export type Subscription_RootSf_Support_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Actions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Actions_Order_By>>;
  where?: Maybe<Sf_Support_Actions_Bool_Exp>;
};

export type Subscription_RootSf_Support_Actions_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSf_Support_Actions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Support_Actions_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Support_Actions_Bool_Exp>;
};

export type Subscription_RootSf_Support_Active_Property_AlertsArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Active_Property_Alerts_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Active_Property_Alerts_Order_By>>;
  where?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
};

export type Subscription_RootSf_Support_Active_Property_Alerts_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Active_Property_Alerts_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Active_Property_Alerts_Order_By>>;
  where?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
};

export type Subscription_RootSf_Support_Active_Property_Alerts_By_PkArgs = {
  locationId: Scalars["uuid"];
  ticketClassId: Scalars["Int"];
};

export type Subscription_RootSf_Support_Active_Property_Alerts_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Support_Active_Property_Alerts_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Support_Active_Property_Alerts_Bool_Exp>;
};

export type Subscription_RootSf_Support_Analytics_Ticket_Jobs_Running_HistoriesArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Order_By>>;
  where?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp>;
};

export type Subscription_RootSf_Support_Analytics_Ticket_Jobs_Running_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Order_By>>;
  where?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp>;
};

export type Subscription_RootSf_Support_Analytics_Ticket_Jobs_Running_Histories_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSf_Support_Analytics_Ticket_Jobs_Running_Histories_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Support_Analytics_Ticket_Jobs_Running_Histories_Bool_Exp>;
};

export type Subscription_RootSf_Support_Root_CausesArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Root_Causes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Root_Causes_Order_By>>;
  where?: Maybe<Sf_Support_Root_Causes_Bool_Exp>;
};

export type Subscription_RootSf_Support_Root_Causes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Root_Causes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Root_Causes_Order_By>>;
  where?: Maybe<Sf_Support_Root_Causes_Bool_Exp>;
};

export type Subscription_RootSf_Support_Root_Causes_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSf_Support_Root_Causes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Support_Root_Causes_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Support_Root_Causes_Bool_Exp>;
};

export type Subscription_RootSf_Support_Ticket_Classes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Ticket_Classes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Ticket_Classes_Order_By>>;
  where?: Maybe<Sf_Support_Ticket_Classes_Bool_Exp>;
};

export type Subscription_RootSf_Support_Ticket_Classes_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSf_Support_Ticket_Classes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Support_Ticket_Classes_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Support_Ticket_Classes_Bool_Exp>;
};

export type Subscription_RootSf_Support_Ticket_StatusArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Ticket_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Ticket_Status_Order_By>>;
  where?: Maybe<Sf_Support_Ticket_Status_Bool_Exp>;
};

export type Subscription_RootSf_Support_Ticket_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Ticket_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Ticket_Status_Order_By>>;
  where?: Maybe<Sf_Support_Ticket_Status_Bool_Exp>;
};

export type Subscription_RootSf_Support_Ticket_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSf_Support_Ticket_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Support_Ticket_Status_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Support_Ticket_Status_Bool_Exp>;
};

export type Subscription_RootSf_Support_TicketsArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Tickets_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Tickets_Order_By>>;
  where?: Maybe<Sf_Support_Tickets_Bool_Exp>;
};

export type Subscription_RootSf_Support_Tickets_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Tickets_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Tickets_Order_By>>;
  where?: Maybe<Sf_Support_Tickets_Bool_Exp>;
};

export type Subscription_RootSf_Support_Tickets_By_PkArgs = {
  ticket_id: Scalars["Int"];
};

export type Subscription_RootSf_Support_Tickets_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Support_Tickets_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Support_Tickets_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_Maintenance_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Maintenance_Report_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_Maintenance_Report_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Maintenance_Report_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_Report_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadata_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_Report_Metadata_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSf_Testjig_Test_Jig_Report_Metadata_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadata_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_Report_Metadatas_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Report_Metadatas_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_Report_Metadatas_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSf_Testjig_Test_Jig_Report_Metadatas_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Report_Metadatas_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Testjig_Test_Jig_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_Test_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Test_Report_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_Test_Report_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Testjig_Test_Jig_Test_Report_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Test_Report_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_Test_Jig_Type_Order_By>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Type_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_Test_Jig_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Testjig_Test_Jig_Type_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Testjig_Test_Jig_Type_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_V_Latest_Report_Rma_NodesArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Order_By>>;
  where?: Maybe<Sf_Testjig_V_Latest_Report_Rma_Nodes_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_V_Latest_Report_Rma_Nodes_AggregateArgs = {
  distinct_on?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Testjig_V_Latest_Report_Rma_Nodes_Order_By>>;
  where?: Maybe<Sf_Testjig_V_Latest_Report_Rma_Nodes_Bool_Exp>;
};

export type Subscription_RootSf_Testjig_V_Latest_Report_Rma_Nodes_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Sf_Testjig_V_Latest_Report_Rma_Nodes_Stream_Cursor_Input>>;
  where?: Maybe<Sf_Testjig_V_Latest_Report_Rma_Nodes_Bool_Exp>;
};

export type Subscription_RootTasksWithKeysArgs = {
  distinct_on?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sensorflow_V_Tasks_With_Keys_Order_By>>;
  where?: Maybe<Sensorflow_V_Tasks_With_Keys_Bool_Exp>;
};

export type Subscription_RootTestRunsArgs = {
  distinct_on?: Maybe<Array<TestRuns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TestRuns_Order_By>>;
  where?: Maybe<TestRuns_Bool_Exp>;
};

export type Subscription_RootTestRuns_AggregateArgs = {
  distinct_on?: Maybe<Array<TestRuns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TestRuns_Order_By>>;
  where?: Maybe<TestRuns_Bool_Exp>;
};

export type Subscription_RootTestRuns_By_PkArgs = {
  testRunId: Scalars["Int"];
};

export type Subscription_RootTestRuns_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<TestRuns_Stream_Cursor_Input>>;
  where?: Maybe<TestRuns_Bool_Exp>;
};

export type Subscription_RootTicketClassesArgs = {
  distinct_on?: Maybe<Array<Sf_Support_Ticket_Classes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Sf_Support_Ticket_Classes_Order_By>>;
  where?: Maybe<Sf_Support_Ticket_Classes_Bool_Exp>;
};

export type TasksStats = {
  __typename?: "TasksStats";
  doneCount: Scalars["Int"];
  inProgressCount: Scalars["Int"];
  pendingCount: Scalars["Int"];
  totalCount: Scalars["Int"];
};

export enum TaskStatus {
  Done = "DONE",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export type TestRuns = {
  __typename?: "testRuns";
  autosetPositionId: Scalars["String"];
  details?: Maybe<Scalars["String"]>;
  first_passed_at?: Maybe<Scalars["timestamptz"]>;
  isIgnored: Scalars["Boolean"];
  keyEntryId: Scalars["Int"];
  key_entry: KeyEntries;
  lastPassedAt?: Maybe<Scalars["timestamptz"]>;
  lastTestedAt?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status: Scalars["String"];
  test: Sensorflow_Tests;
  testId: Scalars["Int"];
  testRunId: Scalars["Int"];
};

export type TestRuns_Aggregate = {
  __typename?: "testRuns_aggregate";
  aggregate?: Maybe<TestRuns_Aggregate_Fields>;
  nodes: Array<TestRuns>;
};

export type TestRuns_Aggregate_Bool_Exp = {
  bool_and?: Maybe<TestRuns_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<TestRuns_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<TestRuns_Aggregate_Bool_Exp_Count>;
};

export type TestRuns_Aggregate_Bool_Exp_Bool_And = {
  arguments: TestRuns_Select_Column_TestRuns_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<TestRuns_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type TestRuns_Aggregate_Bool_Exp_Bool_Or = {
  arguments: TestRuns_Select_Column_TestRuns_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<TestRuns_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type TestRuns_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<TestRuns_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<TestRuns_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type TestRuns_Aggregate_Fields = {
  __typename?: "testRuns_aggregate_fields";
  avg?: Maybe<TestRuns_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<TestRuns_Max_Fields>;
  min?: Maybe<TestRuns_Min_Fields>;
  stddev?: Maybe<TestRuns_Stddev_Fields>;
  stddev_pop?: Maybe<TestRuns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TestRuns_Stddev_Samp_Fields>;
  sum?: Maybe<TestRuns_Sum_Fields>;
  var_pop?: Maybe<TestRuns_Var_Pop_Fields>;
  var_samp?: Maybe<TestRuns_Var_Samp_Fields>;
  variance?: Maybe<TestRuns_Variance_Fields>;
};

export type TestRuns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<TestRuns_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type TestRuns_Aggregate_Order_By = {
  avg?: Maybe<TestRuns_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<TestRuns_Max_Order_By>;
  min?: Maybe<TestRuns_Min_Order_By>;
  stddev?: Maybe<TestRuns_Stddev_Order_By>;
  stddev_pop?: Maybe<TestRuns_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<TestRuns_Stddev_Samp_Order_By>;
  sum?: Maybe<TestRuns_Sum_Order_By>;
  var_pop?: Maybe<TestRuns_Var_Pop_Order_By>;
  var_samp?: Maybe<TestRuns_Var_Samp_Order_By>;
  variance?: Maybe<TestRuns_Variance_Order_By>;
};

export type TestRuns_Arr_Rel_Insert_Input = {
  data: Array<TestRuns_Insert_Input>;
  on_conflict?: Maybe<TestRuns_On_Conflict>;
};

export type TestRuns_Avg_Fields = {
  __typename?: "testRuns_avg_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
  testId?: Maybe<Scalars["Float"]>;
  testRunId?: Maybe<Scalars["Float"]>;
};

export type TestRuns_Avg_Order_By = {
  keyEntryId?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export type TestRuns_Bool_Exp = {
  _and?: Maybe<Array<TestRuns_Bool_Exp>>;
  _not?: Maybe<TestRuns_Bool_Exp>;
  _or?: Maybe<Array<TestRuns_Bool_Exp>>;
  autosetPositionId?: Maybe<String_Comparison_Exp>;
  details?: Maybe<String_Comparison_Exp>;
  first_passed_at?: Maybe<Timestamptz_Comparison_Exp>;
  isIgnored?: Maybe<Boolean_Comparison_Exp>;
  keyEntryId?: Maybe<Int_Comparison_Exp>;
  key_entry?: Maybe<KeyEntries_Bool_Exp>;
  lastPassedAt?: Maybe<Timestamptz_Comparison_Exp>;
  lastTestedAt?: Maybe<Timestamptz_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Sensorflow_Positions_Bool_Exp>;
  startedAt?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  test?: Maybe<Sensorflow_Tests_Bool_Exp>;
  testId?: Maybe<Int_Comparison_Exp>;
  testRunId?: Maybe<Int_Comparison_Exp>;
};

export enum TestRuns_Constraint {
  TestRunsPkey = "test_runs_pkey",
  TestRunsTestIdKeyEntryIdAutosetPositionIdKey = "test_runs_test_id_key_entry_id_autoset_position_id_key",
}

export type TestRuns_Inc_Input = {
  keyEntryId?: Maybe<Scalars["Int"]>;
  testId?: Maybe<Scalars["Int"]>;
  testRunId?: Maybe<Scalars["Int"]>;
};

export type TestRuns_Insert_Input = {
  autosetPositionId?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  first_passed_at?: Maybe<Scalars["timestamptz"]>;
  isIgnored?: Maybe<Scalars["Boolean"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  key_entry?: Maybe<KeyEntries_Obj_Rel_Insert_Input>;
  lastPassedAt?: Maybe<Scalars["timestamptz"]>;
  lastTestedAt?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  position?: Maybe<Sensorflow_Positions_Obj_Rel_Insert_Input>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  test?: Maybe<Sensorflow_Tests_Obj_Rel_Insert_Input>;
  testId?: Maybe<Scalars["Int"]>;
  testRunId?: Maybe<Scalars["Int"]>;
};

export type TestRuns_Max_Fields = {
  __typename?: "testRuns_max_fields";
  autosetPositionId?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  first_passed_at?: Maybe<Scalars["timestamptz"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  lastPassedAt?: Maybe<Scalars["timestamptz"]>;
  lastTestedAt?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["Int"]>;
  testRunId?: Maybe<Scalars["Int"]>;
};

export type TestRuns_Max_Order_By = {
  autosetPositionId?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  first_passed_at?: Maybe<Order_By>;
  keyEntryId?: Maybe<Order_By>;
  lastPassedAt?: Maybe<Order_By>;
  lastTestedAt?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export type TestRuns_Min_Fields = {
  __typename?: "testRuns_min_fields";
  autosetPositionId?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  first_passed_at?: Maybe<Scalars["timestamptz"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  lastPassedAt?: Maybe<Scalars["timestamptz"]>;
  lastTestedAt?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["Int"]>;
  testRunId?: Maybe<Scalars["Int"]>;
};

export type TestRuns_Min_Order_By = {
  autosetPositionId?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  first_passed_at?: Maybe<Order_By>;
  keyEntryId?: Maybe<Order_By>;
  lastPassedAt?: Maybe<Order_By>;
  lastTestedAt?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export type TestRuns_Mutation_Response = {
  __typename?: "testRuns_mutation_response";
  affected_rows: Scalars["Int"];
  returning: Array<TestRuns>;
};

export type TestRuns_On_Conflict = {
  constraint: TestRuns_Constraint;
  update_columns: Array<TestRuns_Update_Column>;
  where?: Maybe<TestRuns_Bool_Exp>;
};

export type TestRuns_Order_By = {
  autosetPositionId?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  first_passed_at?: Maybe<Order_By>;
  isIgnored?: Maybe<Order_By>;
  keyEntryId?: Maybe<Order_By>;
  key_entry?: Maybe<KeyEntries_Order_By>;
  lastPassedAt?: Maybe<Order_By>;
  lastTestedAt?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  position?: Maybe<Sensorflow_Positions_Order_By>;
  startedAt?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  test?: Maybe<Sensorflow_Tests_Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export type TestRuns_Pk_Columns_Input = {
  testRunId: Scalars["Int"];
};

export enum TestRuns_Select_Column {
  AutosetPositionId = "autosetPositionId",
  Details = "details",
  FirstPassedAt = "first_passed_at",
  IsIgnored = "isIgnored",
  KeyEntryId = "keyEntryId",
  LastPassedAt = "lastPassedAt",
  LastTestedAt = "lastTestedAt",
  Note = "note",
  StartedAt = "startedAt",
  Status = "status",
  TestId = "testId",
  TestRunId = "testRunId",
}

export enum TestRuns_Select_Column_TestRuns_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  IsIgnored = "isIgnored",
}

export enum TestRuns_Select_Column_TestRuns_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  IsIgnored = "isIgnored",
}

export type TestRuns_Set_Input = {
  autosetPositionId?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  first_passed_at?: Maybe<Scalars["timestamptz"]>;
  isIgnored?: Maybe<Scalars["Boolean"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  lastPassedAt?: Maybe<Scalars["timestamptz"]>;
  lastTestedAt?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["Int"]>;
  testRunId?: Maybe<Scalars["Int"]>;
};

export type TestRuns_Stddev_Fields = {
  __typename?: "testRuns_stddev_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
  testId?: Maybe<Scalars["Float"]>;
  testRunId?: Maybe<Scalars["Float"]>;
};

export type TestRuns_Stddev_Order_By = {
  keyEntryId?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export type TestRuns_Stddev_Pop_Fields = {
  __typename?: "testRuns_stddev_pop_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
  testId?: Maybe<Scalars["Float"]>;
  testRunId?: Maybe<Scalars["Float"]>;
};

export type TestRuns_Stddev_Pop_Order_By = {
  keyEntryId?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export type TestRuns_Stddev_Samp_Fields = {
  __typename?: "testRuns_stddev_samp_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
  testId?: Maybe<Scalars["Float"]>;
  testRunId?: Maybe<Scalars["Float"]>;
};

export type TestRuns_Stddev_Samp_Order_By = {
  keyEntryId?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export type TestRuns_Stream_Cursor_Input = {
  initial_value: TestRuns_Stream_Cursor_Value_Input;
  ordering?: Maybe<Cursor_Ordering>;
};

export type TestRuns_Stream_Cursor_Value_Input = {
  autosetPositionId?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  first_passed_at?: Maybe<Scalars["timestamptz"]>;
  isIgnored?: Maybe<Scalars["Boolean"]>;
  keyEntryId?: Maybe<Scalars["Int"]>;
  lastPassedAt?: Maybe<Scalars["timestamptz"]>;
  lastTestedAt?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
  testId?: Maybe<Scalars["Int"]>;
  testRunId?: Maybe<Scalars["Int"]>;
};

export type TestRuns_Sum_Fields = {
  __typename?: "testRuns_sum_fields";
  keyEntryId?: Maybe<Scalars["Int"]>;
  testId?: Maybe<Scalars["Int"]>;
  testRunId?: Maybe<Scalars["Int"]>;
};

export type TestRuns_Sum_Order_By = {
  keyEntryId?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export enum TestRuns_Update_Column {
  AutosetPositionId = "autosetPositionId",
  Details = "details",
  FirstPassedAt = "first_passed_at",
  IsIgnored = "isIgnored",
  KeyEntryId = "keyEntryId",
  LastPassedAt = "lastPassedAt",
  LastTestedAt = "lastTestedAt",
  Note = "note",
  StartedAt = "startedAt",
  Status = "status",
  TestId = "testId",
  TestRunId = "testRunId",
}

export type TestRuns_Updates = {
  _inc?: Maybe<TestRuns_Inc_Input>;
  _set?: Maybe<TestRuns_Set_Input>;
  where: TestRuns_Bool_Exp;
};

export type TestRuns_Var_Pop_Fields = {
  __typename?: "testRuns_var_pop_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
  testId?: Maybe<Scalars["Float"]>;
  testRunId?: Maybe<Scalars["Float"]>;
};

export type TestRuns_Var_Pop_Order_By = {
  keyEntryId?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export type TestRuns_Var_Samp_Fields = {
  __typename?: "testRuns_var_samp_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
  testId?: Maybe<Scalars["Float"]>;
  testRunId?: Maybe<Scalars["Float"]>;
};

export type TestRuns_Var_Samp_Order_By = {
  keyEntryId?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export type TestRuns_Variance_Fields = {
  __typename?: "testRuns_variance_fields";
  keyEntryId?: Maybe<Scalars["Float"]>;
  testId?: Maybe<Scalars["Float"]>;
  testRunId?: Maybe<Scalars["Float"]>;
};

export type TestRuns_Variance_Order_By = {
  keyEntryId?: Maybe<Order_By>;
  testId?: Maybe<Order_By>;
  testRunId?: Maybe<Order_By>;
};

export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamp"]>;
  _gt?: Maybe<Scalars["timestamp"]>;
  _gte?: Maybe<Scalars["timestamp"]>;
  _in?: Maybe<Array<Scalars["timestamp"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamp"]>;
  _lte?: Maybe<Scalars["timestamp"]>;
  _neq?: Maybe<Scalars["timestamp"]>;
  _nin?: Maybe<Array<Scalars["timestamp"]>>;
};

export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
};

export type UncancelablePrecoolRooms = {
  __typename?: "UncancelablePrecoolRooms";
  roomId: Scalars["String"];
  roomName: Scalars["String"];
};

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  emailVerified: UserEmailVerified;
  isContractor?: Maybe<Scalars["Boolean"]>;
  isDev?: Maybe<Scalars["Boolean"]>;
  isManager?: Maybe<Scalars["Boolean"]>;
  lastLogin?: Maybe<Scalars["String"]>;
  locations: Array<Location>;
  name: Scalars["String"];
  organisation: Organisation;
  role: Role;
  status: UserStatus;
  userId: Scalars["String"];
};

export enum UserEmailVerified {
  Unverified = "UNVERIFIED",
  Verified = "VERIFIED",
}

export type UserFilterInput = {
  roleIds?: Maybe<Array<Scalars["String"]>>;
  search?: Maybe<Scalars["String"]>;
};

export type UserInput = {
  isContractor: Scalars["Boolean"];
  isManager: Scalars["Boolean"];
  locationIds: Array<Maybe<Scalars["String"]>>;
  name: Scalars["String"];
  organisationId: Scalars["String"];
  roleId: Scalars["String"];
};

export type UserSortInput = {
  email?: Maybe<SortOptions>;
  name?: Maybe<SortOptions>;
};

export type UsersResponse = {
  __typename?: "UsersResponse";
  pagination: GenericPagination;
  users: Array<User>;
};

export enum UserStatus {
  Active = "ACTIVE",
  Disabled = "DISABLED",
}

export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars["uuid"]>;
  _gt?: Maybe<Scalars["uuid"]>;
  _gte?: Maybe<Scalars["uuid"]>;
  _in?: Maybe<Array<Scalars["uuid"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["uuid"]>;
  _lte?: Maybe<Scalars["uuid"]>;
  _neq?: Maybe<Scalars["uuid"]>;
  _nin?: Maybe<Array<Scalars["uuid"]>>;
};

export type ComfortplusKeyDetailsQueryVariables = {};

export type ComfortplusKeyDetailsQuery = { __typename?: "query_root" } & {
  data: Array<
    { __typename?: "sensorflow_v_comfortplus_key_details" } & Pick<
      Sensorflow_V_Comfortplus_Key_Details,
      | "checkInDate"
      | "checkOutDate"
      | "positionName"
      | "onboarded"
      | "locationName"
      | "logoUrl"
      | "timezone"
      | "reservationId"
    >
  >;
};

export type ComfortplusKeyMeasurementsSubscriptionVariables = {};

export type ComfortplusKeyMeasurementsSubscription = { __typename?: "subscription_root" } & {
  data: Array<
    { __typename?: "sensorflow_v_comfortplus_key_measurements" } & Pick<
      Sensorflow_V_Comfortplus_Key_Measurements,
      | "ambientTemperature"
      | "roomId"
      | "roomName"
      | "acMode"
      | "fanSpeed"
      | "scheduledCount"
      | "precoolPendingCount"
      | "lastPrecoolSuccessTime"
    >
  >;
};

export type ComfortPlusResetDailyLimitMutationVariables = {
  reservationId: Scalars["bigint"];
  dailyPrecoolLimitResetAt: Scalars["timestamptz"];
};

export type ComfortPlusResetDailyLimitMutation = { __typename?: "mutation_root" } & {
  updateComfortPlusActivations?: Maybe<
    { __typename?: "sensorflow_comfortplus_activations_mutation_response" } & Pick<
      Sensorflow_Comfortplus_Activations_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type SetComfortPlusUserOnboardedMutationVariables = {};

export type SetComfortPlusUserOnboardedMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_users?: Maybe<
    { __typename?: "sensorflow_users_mutation_response" } & Pick<Sensorflow_Users_Mutation_Response, "affected_rows">
  >;
};

export type PrecoolComfortPlusRoomMutationVariables = {
  roomIds: Array<Scalars["ID"]>;
};

export type PrecoolComfortPlusRoomMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "precoolComfortPlusRoom"
>;

export type ComfortPlusPrecoolStatusSubscriptionVariables = {};

export type ComfortPlusPrecoolStatusSubscription = { __typename?: "subscription_root" } & {
  sensorflow_v_comfortplus_precool_status: Array<
    { __typename?: "sensorflow_v_comfortplus_precool_status" } & Pick<
      Sensorflow_V_Comfortplus_Precool_Status,
      "positionName" | "totalMinutes" | "startedAt" | "failMessage" | "status" | "mode"
    >
  >;
};

export type CreateLocationMutationVariables = {
  locationInput: Sensorflow_Locations_Insert_Input;
};

export type CreateLocationMutation = { __typename?: "mutation_root" } & {
  createLocation?: Maybe<
    { __typename?: "sensorflow_locations" } & Pick<Sensorflow_Locations, "locationId" | "locationName" | "currency">
  >;
};

export type RenameLocationMutationVariables = {
  primaryKey: Sensorflow_Locations_Pk_Columns_Input;
  updateLocationInput: Sensorflow_Locations_Set_Input;
};

export type RenameLocationMutation = { __typename?: "mutation_root" } & {
  updateLocation?: Maybe<
    { __typename?: "sensorflow_locations" } & Pick<Sensorflow_Locations, "locationId" | "locationName">
  >;
};

export type UpsertLocationMutationVariables = {
  locationInput: Sensorflow_Locations_Insert_Input;
};

export type UpsertLocationMutation = { __typename?: "mutation_root" } & {
  createLocation?: Maybe<
    { __typename?: "sensorflow_locations" } & Pick<Sensorflow_Locations, "locationId" | "locationName">
  >;
};

export type LocationOneQueryVariables = {
  locationId: Scalars["uuid"];
};

export type LocationOneQuery = { __typename?: "query_root" } & {
  locationOne?: Maybe<
    { __typename?: "sensorflow_locations" } & Pick<
      Sensorflow_Locations,
      "locationId" | "locationName" | "currency" | "timezone" | "origin_latitude" | "origin_longitude" | "clickupListId"
    > & {
        organisation?: Maybe<
          { __typename?: "sensorflow_organisations" } & Pick<Sensorflow_Organisations, "id" | "name">
        >;
      }
  >;
};

export type CreateKeysMutationVariables = {
  keysInput: Array<Sensorflow_Key_Category_To_Key_Position_Insert_Input>;
};

export type CreateKeysMutation = { __typename?: "mutation_root" } & {
  createKeyCategoryToKeyMappings?: Maybe<
    { __typename?: "sensorflow_key_category_to_key_position_mutation_response" } & {
      returning: Array<
        { __typename?: "sensorflow_key_category_to_key_position" } & Pick<
          Sensorflow_Key_Category_To_Key_Position,
          "categoryName"
        > & {
            position: { __typename?: "sensorflow_positions" } & Pick<
              Sensorflow_Positions,
              "positionId" | "positionName"
            >;
          }
      >;
    }
  >;
};

export type UpsertKeyCategoryTemplatesMutationVariables = {
  locationName: Scalars["String"];
  templatesInput: Array<Sensorflow_Key_Category_Templates_Insert_Input>;
};

export type UpsertKeyCategoryTemplatesMutation = { __typename?: "mutation_root" } & {
  deleteKeyCategoryTemplates?: Maybe<
    { __typename?: "sensorflow_key_category_templates_mutation_response" } & Pick<
      Sensorflow_Key_Category_Templates_Mutation_Response,
      "affected_rows"
    >
  >;
  createKeyCategoryTemplates?: Maybe<
    { __typename?: "sensorflow_key_category_templates_mutation_response" } & {
      returning: Array<
        { __typename?: "sensorflow_key_category_templates" } & Pick<
          Sensorflow_Key_Category_Templates,
          "id" | "categoryName" | "locationName"
        > & {
            location?: Maybe<{ __typename?: "sensorflow_locations" } & Pick<Sensorflow_Locations, "locationId">>;
            roomTemplates: Array<
              { __typename?: "sensorflow_key_category_room_templates" } & Pick<
                Sensorflow_Key_Category_Room_Templates,
                "id" | "roomName"
              > & {
                  slotTemplates: Array<
                    { __typename?: "sensorflow_key_category_slot_templates" } & Pick<
                      Sensorflow_Key_Category_Slot_Templates,
                      "id" | "nodeType" | "nodeSubType" | "slotName" | "acModelId" | "actingMode" | "operationalMode"
                    >
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type KeyCategoriesWithTemplatesQueryVariables = {
  locationName: Scalars["String"];
};

export type KeyCategoriesWithTemplatesQuery = { __typename?: "query_root" } & {
  keyCategories: Array<
    { __typename?: "sensorflow_key_categories" } & Pick<Sensorflow_Key_Categories, "categoryName"> & {
        keyCategoryTemplate?: Maybe<
          { __typename?: "sensorflow_key_category_templates" } & Pick<Sensorflow_Key_Category_Templates, "id"> & {
              roomTemplates: Array<
                { __typename?: "sensorflow_key_category_room_templates" } & Pick<
                  Sensorflow_Key_Category_Room_Templates,
                  "id" | "roomName"
                > & {
                    slotTemplates: Array<
                      { __typename?: "sensorflow_key_category_slot_templates" } & Pick<
                        Sensorflow_Key_Category_Slot_Templates,
                        "id" | "nodeType" | "nodeSubType" | "slotName" | "acModelId"
                      >
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type UpdatePositionMutationVariables = {
  positionId: Scalars["uuid"];
  updatePositionInput?: Maybe<Sensorflow_Positions_Set_Input>;
};

export type UpdatePositionMutation = { __typename?: "mutation_root" } & {
  updatePosition?: Maybe<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">
  >;
};

export type UpdateKeyCategoryToKeyMappingMutationVariables = {
  positionId: Scalars["uuid"];
  categoryName: Scalars["String"];
};

export type UpdateKeyCategoryToKeyMappingMutation = { __typename?: "mutation_root" } & {
  updateKeyCategoryToKeyMappings?: Maybe<
    { __typename?: "sensorflow_key_category_to_key_position_mutation_response" } & {
      returning: Array<
        { __typename?: "sensorflow_key_category_to_key_position" } & Pick<
          Sensorflow_Key_Category_To_Key_Position,
          "positionId" | "categoryName"
        >
      >;
    }
  >;
};

export type CreateSlotMutationVariables = {
  slotInput: Sensorflow_Node_Slots_Insert_Input;
};

export type CreateSlotMutation = { __typename?: "mutation_root" } & {
  createSlot?: Maybe<
    { __typename?: "sensorflow_node_slots" } & Pick<
      Sensorflow_Node_Slots,
      "id" | "nodeType" | "nodeSubType" | "slotName" | "parentPositionId"
    >
  >;
};

export type UpdateSlotMutationVariables = {
  id: Scalars["uuid"];
  updateSlotInput?: Maybe<Sensorflow_Node_Slots_Set_Input>;
};

export type UpdateSlotMutation = { __typename?: "mutation_root" } & {
  updateSlot?: Maybe<
    { __typename?: "sensorflow_node_slots" } & Pick<Sensorflow_Node_Slots, "id" | "nodeType" | "slotName">
  >;
};

export type CreateKeyCategoryMutationVariables = {
  input: Sensorflow_Key_Categories_Insert_Input;
};

export type CreateKeyCategoryMutation = { __typename?: "mutation_root" } & {
  createKeyCategory?: Maybe<
    { __typename?: "sensorflow_key_categories" } & Pick<Sensorflow_Key_Categories, "categoryName">
  >;
};

export type RenameKeyCategoryMutationVariables = {
  primaryKey: Sensorflow_Key_Categories_Pk_Columns_Input;
  updateKeyCategoryInput: Sensorflow_Key_Categories_Set_Input;
};

export type RenameKeyCategoryMutation = { __typename?: "mutation_root" } & {
  updateKeyCategory?: Maybe<
    { __typename?: "sensorflow_key_categories" } & Pick<Sensorflow_Key_Categories, "categoryName">
  >;
};

export type KeyCategoriesQueryVariables = {
  locationIdInput: Sensorflow_Key_Categories_Bool_Exp;
};

export type KeyCategoriesQuery = { __typename?: "query_root" } & {
  keyCategories: Array<
    { __typename?: "sensorflow_key_categories" } & Pick<
      Sensorflow_Key_Categories,
      "id" | "categoryName" | "locationName"
    >
  >;
};

export type UpsertLocationMetadataMutationVariables = {
  input: Sensorflow_Location_Metadata_Insert_Input;
};

export type UpsertLocationMetadataMutation = { __typename?: "mutation_root" } & {
  createLocationMetadata?: Maybe<
    { __typename?: "sensorflow_location_metadata" } & Pick<Sensorflow_Location_Metadata, "locationId">
  >;
};

export type UpdateLocationMetadataMutationVariables = {
  where: Sensorflow_Location_Metadata_Bool_Exp;
  input?: Maybe<Sensorflow_Location_Metadata_Set_Input>;
};

export type UpdateLocationMetadataMutation = { __typename?: "mutation_root" } & {
  updateLocationMetadata?: Maybe<
    { __typename?: "sensorflow_location_metadata_mutation_response" } & {
      returning: Array<
        { __typename?: "sensorflow_location_metadata" } & Pick<Sensorflow_Location_Metadata, "locationId">
      >;
    }
  >;
};

export type GatewayKittingProcessMetadataSubscriptionVariables = {
  locationId: Scalars["uuid"];
};

export type GatewayKittingProcessMetadataSubscription = { __typename?: "subscription_root" } & {
  gatewayKittingProcessMetadata: Array<
    { __typename?: "sensorflow_warehouse_kitting_process_metadata" } & Pick<
      Sensorflow_Warehouse_Kitting_Process_Metadata,
      "balenaId" | "locationId" | "status"
    > & {
        gateway: { __typename?: "sensorflow_gateways" } & Pick<
          Sensorflow_Gateways,
          "gatewayMac" | "gatewayName" | "wifiMacAddress" | "ethernetMacAddress" | "networkConnectionStatus"
        > & {
            gatewayHealthData: Array<
              { __typename?: "sensorflow_gateway_health_data" } & Pick<
                Sensorflow_Gateway_Health_Data,
                "measurementType" | "time" | "value"
              >
            >;
          };
      }
  >;
};

export type TemporaryGatewayKittingProcessMetadataQueryVariables = {
  locationId: Scalars["uuid"];
};

export type TemporaryGatewayKittingProcessMetadataQuery = { __typename?: "query_root" } & {
  gatewayKittingProcessMetadata: Array<
    { __typename?: "sensorflow_warehouse_kitting_process_metadata" } & Pick<
      Sensorflow_Warehouse_Kitting_Process_Metadata,
      "balenaId" | "locationId" | "status"
    > & {
        gateway: { __typename?: "sensorflow_gateways" } & Pick<
          Sensorflow_Gateways,
          | "gatewayMac"
          | "gatewayName"
          | "wifiMacAddress"
          | "ethernetMacAddress"
          | "networkConnectionStatus"
          | "hardware_type"
        > & {
            position?: Maybe<
              { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionName" | "positionId">
            >;
            gatewayHealthData: Array<
              { __typename?: "sensorflow_gateway_health_data" } & Pick<
                Sensorflow_Gateway_Health_Data,
                "measurementType" | "time" | "value"
              >
            >;
            wlanLinkGatewayHealthData: Array<
              { __typename?: "sensorflow_gateway_health_data" } & Pick<
                Sensorflow_Gateway_Health_Data,
                "measurementType" | "time" | "value"
              >
            >;
            wlan1LinkGatewayHealthData: Array<
              { __typename?: "sensorflow_gateway_health_data" } & Pick<
                Sensorflow_Gateway_Health_Data,
                "measurementType" | "time" | "value"
              >
            >;
          };
      }
  >;
};

export type GatewayMappingsSubscriptionVariables = {
  locationId: Scalars["uuid"];
  gatewayMac: Scalars["Int"];
  monitorNode: Array<Scalars["String"]>;
};

export type GatewayMappingsSubscription = { __typename?: "subscription_root" } & {
  positions: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId"> & {
        gatewayMapping: Array<
          { __typename?: "sensorflow_gateway_to_position_mapping" } & {
            gateway?: Maybe<{ __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayMac">>;
          }
        >;
        slotMappings: Array<
          { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
            Sensorflow_Node_To_Slot_Mapping,
            "nodeMacId" | "nodeType" | "isPrimaryNode"
          >
        >;
      }
  >;
};

export type TemporaryGatewayMappingsQueryVariables = {
  locationId: Scalars["uuid"];
  gatewayMac: Scalars["Int"];
  monitorNode: Array<Scalars["String"]>;
};

export type TemporaryGatewayMappingsQuery = { __typename?: "query_root" } & {
  positions: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId"> & {
        gatewayMapping: Array<
          { __typename?: "sensorflow_gateway_to_position_mapping" } & {
            gateway?: Maybe<{ __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayMac">>;
          }
        >;
        slotMappings: Array<
          { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
            Sensorflow_Node_To_Slot_Mapping,
            "nodeMacId" | "nodeType" | "isPrimaryNode"
          >
        >;
      }
  >;
};

export type GatewaysQueryVariables = {
  locationId: Scalars["uuid"];
};

export type GatewaysQuery = { __typename?: "query_root" } & {
  gateways: Array<{ __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayId" | "gatewayName">>;
};

export type UpsertPositionGatewayMutationVariables = {
  positionId: Scalars["uuid"];
  gatewayId: Scalars["String"];
};

export type UpsertPositionGatewayMutation = { __typename?: "mutation_root" } & {
  createGatewayToPositionMapping?: Maybe<
    { __typename?: "sensorflow_gateway_to_position_mapping" } & Pick<
      Sensorflow_Gateway_To_Position_Mapping,
      "positionId" | "gatewayId"
    >
  >;
};

export type RoomNodeMappingsSubscriptionVariables = {
  keyId: Scalars["uuid"];
};

export type RoomNodeMappingsSubscription = { __typename?: "subscription_root" } & {
  positions: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
        gatewayMapping: Array<
          { __typename?: "sensorflow_gateway_to_position_mapping" } & {
            gateway?: Maybe<
              { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayId" | "gatewayName">
            >;
          }
        >;
        slotMappings: Array<
          { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
            Sensorflow_Node_To_Slot_Mapping,
            "slotName" | "nodeType" | "nodeMacId" | "mappedTime" | "isPrimaryNode"
          > & {
              slot?: Maybe<{ __typename?: "sensorflow_node_slots" } & Pick<Sensorflow_Node_Slots, "id">>;
              node: { __typename?: "sensorflow_nodes" } & {
                nodeJoinStatus?: Maybe<
                  { __typename?: "sensorflow_node_join_status" } & Pick<
                    Sensorflow_Node_Join_Status,
                    "status" | "lastSeen" | "recentJoinCount" | "bootTime" | "firmwareVersion" | "signalStrength"
                  >
                >;
              };
            }
        >;
      }
  >;
};

export type RoomNodeStatusSubscriptionVariables = {
  keyId: Scalars["uuid"];
  filterSlotMappings?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type RoomNodeStatusSubscription = { __typename?: "subscription_root" } & {
  positions: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId"> & {
        slotMappings: Array<
          { __typename?: "sensorflow_node_to_slot_mapping" } & {
            slot?: Maybe<{ __typename?: "sensorflow_node_slots" } & Pick<Sensorflow_Node_Slots, "id">>;
            node: { __typename?: "sensorflow_nodes" } & Pick<
              Sensorflow_Nodes,
              "node_mac_id" | "nodeSubType" | "node_type"
            > & {
                nodeOnlineStatus?: Maybe<
                  { __typename?: "sensorflow_node_online_status" } & Pick<
                    Sensorflow_Node_Online_Status,
                    "nodeStatus" | "lastTransmission" | "nodeStatusInInstallationMode"
                  >
                >;
                nodeJoinStrengthLive?: Maybe<
                  { __typename?: "sensorflow_node_join_strength_live" } & Pick<
                    Sensorflow_Node_Join_Strength_Live,
                    "signalStrength"
                  >
                >;
              };
          }
        >;
      }
  >;
};

export type TemporaryRoomNodeMappingsQueryVariables = {
  keyId: Scalars["uuid"];
};

export type TemporaryRoomNodeMappingsQuery = { __typename?: "query_root" } & {
  positions: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
        gatewayMapping: Array<
          { __typename?: "sensorflow_gateway_to_position_mapping" } & {
            gateway?: Maybe<
              { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayId" | "gatewayName">
            >;
          }
        >;
        slotMappings: Array<
          { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
            Sensorflow_Node_To_Slot_Mapping,
            "slotName" | "nodeType" | "nodeMacId" | "mappedTime" | "isPrimaryNode"
          > & {
              slot?: Maybe<{ __typename?: "sensorflow_node_slots" } & Pick<Sensorflow_Node_Slots, "id">>;
              node: { __typename?: "sensorflow_nodes" } & {
                nodeMetaStatus?: Maybe<
                  { __typename?: "sensorflow_node_meta_status" } & Pick<
                    Sensorflow_Node_Meta_Status,
                    "bootTime" | "firmwareVersion" | "recentJoinCount"
                  >
                >;
              };
            }
        >;
      }
  >;
};

export type NodeListSubscriptionVariables = {
  where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
};

export type NodeListSubscription = { __typename?: "subscription_root" } & {
  sensorflow_node_to_slot_mapping: Array<
    { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
      Sensorflow_Node_To_Slot_Mapping,
      "nodeMacId" | "nodeType" | "mappedTime"
    > & {
        position: { __typename?: "sensorflow_positions" } & Pick<
          Sensorflow_Positions,
          "positionId" | "positionName"
        > & {
            parentPosition?: Maybe<
              { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">
            >;
          };
        node: { __typename?: "sensorflow_nodes" } & Pick<Sensorflow_Nodes, "nodeSubType"> & {
            gateway?: Maybe<{ __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayName">>;
            nodeJoinStatus?: Maybe<
              { __typename?: "sensorflow_node_join_status" } & Pick<
                Sensorflow_Node_Join_Status,
                "status" | "lastSeen" | "recentJoinCount" | "RSSI" | "SNR" | "bootTime" | "firmwareVersion"
              >
            >;
          };
      }
  >;
};

export type TemporaryNodeListQueryVariables = {
  where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
};

export type TemporaryNodeListQuery = { __typename?: "query_root" } & {
  sensorflow_node_to_slot_mapping: Array<
    { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
      Sensorflow_Node_To_Slot_Mapping,
      "nodeMacId" | "nodeType" | "mappedTime"
    > & {
        position: { __typename?: "sensorflow_positions" } & Pick<
          Sensorflow_Positions,
          "positionId" | "positionName"
        > & {
            parentPosition?: Maybe<
              { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">
            >;
          };
        node: { __typename?: "sensorflow_nodes" } & Pick<Sensorflow_Nodes, "nodeSubType"> & {
            gateway?: Maybe<{ __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayName">>;
            nodeJoinStatus?: Maybe<
              { __typename?: "sensorflow_node_join_status" } & Pick<
                Sensorflow_Node_Join_Status,
                "lastSeen" | "recentJoinCount" | "bootTime" | "firmwareVersion" | "signalStrength"
              >
            >;
            nodeOnlineStatus?: Maybe<
              { __typename?: "sensorflow_node_online_status" } & Pick<Sensorflow_Node_Online_Status, "nodeStatus">
            >;
          };
      }
  >;
};

export type NodeListAggregateSubscriptionVariables = {
  where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
};

export type NodeListAggregateSubscription = { __typename?: "subscription_root" } & {
  sensorflow_node_to_slot_mapping_aggregate: { __typename?: "sensorflow_node_to_slot_mapping_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "sensorflow_node_to_slot_mapping_aggregate_fields" } & Pick<
        Sensorflow_Node_To_Slot_Mapping_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type TemporaryNodeListAggregateQueryVariables = {
  where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
};

export type TemporaryNodeListAggregateQuery = { __typename?: "query_root" } & {
  sensorflow_node_to_slot_mapping_aggregate: { __typename?: "sensorflow_node_to_slot_mapping_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "sensorflow_node_to_slot_mapping_aggregate_fields" } & Pick<
        Sensorflow_Node_To_Slot_Mapping_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type UpdatePrimaryNodeMutationVariables = {
  positionId: Scalars["uuid"];
  nodeMacId: Scalars["String"];
};

export type UpdatePrimaryNodeMutation = { __typename?: "mutation_root" } & {
  clearPrimaryNode?: Maybe<
    { __typename?: "sensorflow_node_to_slot_mapping_mutation_response" } & Pick<
      Sensorflow_Node_To_Slot_Mapping_Mutation_Response,
      "affected_rows"
    >
  >;
  updatePrimaryNode?: Maybe<
    { __typename?: "sensorflow_node_to_slot_mapping_mutation_response" } & {
      returning: Array<
        { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
          Sensorflow_Node_To_Slot_Mapping,
          "slotName" | "nodeMacId" | "isPrimaryNode"
        >
      >;
    }
  >;
};

export type KeyNodeHealthSubscriptionVariables = {
  keyId: Scalars["uuid"];
};

export type KeyNodeHealthSubscription = { __typename?: "subscription_root" } & {
  nodeJoinStatuses: Array<
    { __typename?: "sensorflow_node_join_status" } & Pick<Sensorflow_Node_Join_Status, "nodeMacId" | "status">
  >;
};

export type TemporaryKeyNodeHealthQueryVariables = {
  keyId: Scalars["uuid"];
};

export type TemporaryKeyNodeHealthQuery = { __typename?: "query_root" } & {
  nodeOnlineStatuses: Array<
    { __typename?: "sensorflow_node_online_status" } & Pick<Sensorflow_Node_Online_Status, "nodeMacId" | "nodeStatus">
  >;
};

export type CreateCompressorMutationVariables = {
  locationId: Scalars["uuid"];
  name: Scalars["String"];
  numberOfPhases: Scalars["Int"];
};

export type CreateCompressorMutation = { __typename?: "mutation_root" } & {
  createCompressor?: Maybe<
    { __typename?: "sensorflow_compressors" } & Pick<
      Sensorflow_Compressors,
      "compressorId" | "compressorName" | "compressorMeterPositionId" | "compressorPositionId" | "numberOfPhases"
    >
  >;
};

export type CreatePipeMutationVariables = {
  locationId: Scalars["uuid"];
  name: Scalars["String"];
};

export type CreatePipeMutation = { __typename?: "mutation_root" } & {
  createPipe?: Maybe<{ __typename?: "sensorflow_pipes" } & Pick<Sensorflow_Pipes, "id" | "name">>;
};

export type CreatePumpMutationVariables = {
  locationId: Scalars["uuid"];
  name: Scalars["String"];
  numberOfPhases: Scalars["Int"];
};

export type CreatePumpMutation = { __typename?: "mutation_root" } & {
  createPump?: Maybe<{ __typename?: "sensorflow_pumps" } & Pick<Sensorflow_Pumps, "id" | "name">>;
};

export type CreateChillerMutationVariables = {
  locationId: Scalars["uuid"];
  name: Scalars["String"];
  numberOfPhases: Scalars["Int"];
};

export type CreateChillerMutation = { __typename?: "mutation_root" } & {
  createChiller?: Maybe<{ __typename?: "sensorflow_chillers" } & Pick<Sensorflow_Chillers, "id" | "name">>;
};

export type CreateCoolingTowerMutationVariables = {
  locationId: Scalars["uuid"];
  name: Scalars["String"];
  numberOfPhases: Scalars["Int"];
};

export type CreateCoolingTowerMutation = { __typename?: "mutation_root" } & {
  createCoolingTower?: Maybe<
    { __typename?: "sensorflow_cooling_towers" } & Pick<Sensorflow_Cooling_Towers, "id" | "name">
  >;
};

export type KeyListWithNodeMeasurementsSubscriptionVariables = {
  positionName: Scalars["String"];
  locationId: Scalars["uuid"];
  where: Sensorflow_Positions_Bool_Exp;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
};

export type KeyListWithNodeMeasurementsSubscription = { __typename?: "subscription_root" } & {
  locationOne?: Maybe<
    { __typename?: "sensorflow_locations" } & Pick<Sensorflow_Locations, "locationId"> & {
        keyCategories: Array<
          { __typename?: "sensorflow_key_categories" } & Pick<Sensorflow_Key_Categories, "categoryName">
        >;
        positionsAggregate: { __typename?: "sensorflow_positions_aggregate" } & {
          aggregate?: Maybe<
            { __typename?: "sensorflow_positions_aggregate_fields" } & Pick<
              Sensorflow_Positions_Aggregate_Fields,
              "count"
            >
          >;
        };
        searchPositions?: Maybe<
          Array<
            { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
                keyCategoryPositionMapping?: Maybe<
                  { __typename?: "sensorflow_key_category_to_key_position" } & Pick<
                    Sensorflow_Key_Category_To_Key_Position,
                    "categoryName"
                  >
                >;
                rooms: Array<
                  { __typename?: "sensorflow_positions" } & Pick<
                    Sensorflow_Positions,
                    "positionId" | "positionName"
                  > & {
                      nodeMeasurements: Array<
                        { __typename?: "sensorflow_node_measurement_view" } & Pick<
                          Sensorflow_Node_Measurement_View,
                          | "occupancyState"
                          | "door"
                          | "acMode"
                          | "acSetPoint"
                          | "fanSpeed"
                          | "ambientTemperature"
                          | "ambientHumidity"
                          | "acStatus"
                        >
                      >;
                      positionConfiguration: Array<
                        { __typename?: "positionConfigurations" } & Pick<
                          PositionConfigurations,
                          "automationMode" | "isDeviatedFromDefaultConfig"
                        >
                      >;
                      precoolCommands: Array<
                        { __typename?: "sensorflow_precool_commands" } & Pick<
                          Sensorflow_Precool_Commands,
                          "totalMinutes" | "creationDate"
                        >
                      >;
                      slotMappings: Array<
                        { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
                          Sensorflow_Node_To_Slot_Mapping,
                          "nodeType" | "nodeMacId"
                        >
                      >;
                    }
                >;
              }
          >
        >;
      }
  >;
};

export type TemporaryKeyListWithNodeMeasurementsQueryVariables = {
  positionName: Scalars["String"];
  locationId: Scalars["uuid"];
  where: Sensorflow_Positions_Bool_Exp;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
};

export type TemporaryKeyListWithNodeMeasurementsQuery = { __typename?: "query_root" } & {
  locationOne?: Maybe<
    { __typename?: "sensorflow_locations" } & Pick<Sensorflow_Locations, "locationId"> & {
        keyCategories: Array<
          { __typename?: "sensorflow_key_categories" } & Pick<Sensorflow_Key_Categories, "categoryName">
        >;
        positionsAggregate: { __typename?: "sensorflow_positions_aggregate" } & {
          aggregate?: Maybe<
            { __typename?: "sensorflow_positions_aggregate_fields" } & Pick<
              Sensorflow_Positions_Aggregate_Fields,
              "count"
            >
          >;
        };
        locationKeys?: Maybe<
          Array<
            { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
                keyCategoryPositionMapping?: Maybe<
                  { __typename?: "sensorflow_key_category_to_key_position" } & Pick<
                    Sensorflow_Key_Category_To_Key_Position,
                    "categoryName"
                  >
                >;
                rooms: Array<
                  { __typename?: "sensorflow_positions" } & Pick<
                    Sensorflow_Positions,
                    "positionId" | "positionName"
                  > & {
                      positionConfiguration: Array<
                        { __typename?: "positionConfigurations" } & Pick<
                          PositionConfigurations,
                          "isDeviatedFromDefaultConfig"
                        >
                      >;
                    }
                >;
              }
          >
        >;
      }
  >;
};

export type CustomerKeyListWithNodeMeasurementsSubscriptionVariables = {
  where: Sensorflow_Positions_Bool_Exp;
};

export type CustomerKeyListWithNodeMeasurementsSubscription = { __typename?: "subscription_root" } & {
  keyList: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId"> & {
        parentPosition?: Maybe<
          { __typename?: "sensorflow_positions" } & Pick<
            Sensorflow_Positions,
            "positionId" | "positionName" | "positionType" | "positionFunction"
          >
        >;
        rooms: Array<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId"> & {
              nodeMeasurements: Array<
                { __typename?: "sensorflow_node_measurement_view" } & Pick<
                  Sensorflow_Node_Measurement_View,
                  | "occupancyState"
                  | "door"
                  | "acMode"
                  | "acSetPoint"
                  | "fanSpeed"
                  | "ambientTemperature"
                  | "ambientHumidity"
                  | "acStatus"
                >
              >;
              positionConfiguration: Array<
                { __typename?: "positionConfigurations" } & Pick<PositionConfigurations, "automationMode">
              >;
              precoolCommands: Array<
                { __typename?: "sensorflow_precool_commands" } & Pick<
                  Sensorflow_Precool_Commands,
                  "status" | "startedAt" | "totalMinutes" | "creationDate"
                >
              >;
              slotMappings: Array<
                { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
                  Sensorflow_Node_To_Slot_Mapping,
                  "nodeType" | "nodeMacId"
                >
              >;
            }
        >;
        reservation: Array<
          { __typename?: "sensorflow_reservations" } & Pick<
            Sensorflow_Reservations,
            "checkInAt" | "checkOutAt" | "id" | "keyPositionId" | "status"
          > & {
              comfortplusActivations: Array<
                { __typename?: "sensorflow_comfortplus_activations" } & Pick<
                  Sensorflow_Comfortplus_Activations,
                  "precoolDailyLimit"
                >
              >;
              reservationGuests: Array<
                { __typename?: "sensorflow_reservation_guests" } & Pick<
                  Sensorflow_Reservation_Guests,
                  "comfortplusAccessToken"
                > & { user: { __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "userId" | "name" | "email"> }
              >;
            }
        >;
      }
  >;
};

export type LocationKeysAndRoomsQueryVariables = {
  locationId: Scalars["uuid"];
};

export type LocationKeysAndRoomsQuery = { __typename?: "query_root" } & {
  positions: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
        rooms: Array<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">
        >;
      }
  >;
};

export type LocationGroupsAndKeysQueryVariables = {
  locationId: Scalars["uuid"];
};

export type LocationGroupsAndKeysQuery = { __typename?: "query_root" } & {
  positions: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
        rooms: Array<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
              rooms: Array<
                { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">
              >;
            }
        >;
      }
  >;
};

export type InfrastructuresQueryVariables = {
  where: Sensorflow_V_Infrastructures_Bool_Exp;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
};

export type InfrastructuresQuery = { __typename?: "query_root" } & {
  infrastructures: Array<
    { __typename?: "sensorflow_v_infrastructures" } & Pick<
      Sensorflow_V_Infrastructures,
      "id" | "meterPositionId" | "name" | "type" | "phaseStreamIndex"
    > & {
        rooms: Array<
          { __typename?: "sensorflow_compressor_mapping" } & {
            position: { __typename?: "sensorflow_positions" } & {
              parentPosition?: Maybe<
                { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionName">
              >;
            };
          }
        >;
      }
  >;
};

export type InfrastructuresWithOrderByAndTotalCountQueryVariables = {
  where: Sensorflow_V_Infrastructures_Bool_Exp;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  order_by?: Maybe<Array<Sensorflow_V_Infrastructures_Order_By>>;
};

export type InfrastructuresWithOrderByAndTotalCountQuery = { __typename?: "query_root" } & {
  infrastructures: Array<
    { __typename?: "sensorflow_v_infrastructures" } & Pick<
      Sensorflow_V_Infrastructures,
      "id" | "meterPositionId" | "name" | "type" | "phaseStreamIndex"
    > & {
        position?: Maybe<
          { __typename?: "sensorflow_positions" } & Pick<
            Sensorflow_Positions,
            "positionId" | "positionName" | "positionType"
          >
        >;
        rooms: Array<
          { __typename?: "sensorflow_compressor_mapping" } & {
            position: { __typename?: "sensorflow_positions" } & {
              parentPosition?: Maybe<
                { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionName">
              >;
            };
          }
        >;
      }
  >;
  sensorflow_v_infrastructures_aggregate: { __typename?: "sensorflow_v_infrastructures_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "sensorflow_v_infrastructures_aggregate_fields" } & Pick<
        Sensorflow_V_Infrastructures_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type InfrastructureWithMeterPositionSubscriptionVariables = {
  id: Scalars["uuid"];
};

export type InfrastructureWithMeterPositionSubscription = { __typename?: "subscription_root" } & {
  infrastructures: Array<
    { __typename?: "sensorflow_v_infrastructures" } & Pick<Sensorflow_V_Infrastructures, "meterPositionId"> & {
        meterPosition?: Maybe<
          { __typename?: "sensorflow_positions" } & {
            nodes: Array<
              { __typename?: "sensorflow_node_join_status" } & Pick<
                Sensorflow_Node_Join_Status,
                | "nodeMacId"
                | "nodeType"
                | "slotName"
                | "lastSeen"
                | "status"
                | "RSSI"
                | "SNR"
                | "mappedTime"
                | "signalStrength"
              > & {
                  mappedGateway?: Maybe<
                    { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayName">
                  >;
                  joinedGateway?: Maybe<
                    { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayName">
                  >;
                }
            >;
          }
        >;
      }
  >;
};

export type InfrastructureSubscriptionVariables = {
  id: Scalars["uuid"];
};

export type InfrastructureSubscription = { __typename?: "subscription_root" } & {
  infrastructures: Array<
    { __typename?: "sensorflow_v_infrastructures" } & Pick<Sensorflow_V_Infrastructures, "id" | "name" | "type"> & {
        rooms: Array<
          { __typename?: "sensorflow_compressor_mapping" } & {
            position: { __typename?: "sensorflow_positions" } & Pick<
              Sensorflow_Positions,
              "positionId" | "positionName"
            > & {
                parentPosition?: Maybe<
                  { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionName">
                >;
              };
          }
        >;
        meterPosition?: Maybe<
          { __typename?: "sensorflow_positions" } & {
            nodes: Array<
              { __typename?: "sensorflow_node_join_status" } & Pick<
                Sensorflow_Node_Join_Status,
                "nodeMacId" | "nodeType" | "slotName" | "lastSeen" | "status" | "RSSI" | "SNR" | "mappedTime"
              > & {
                  mappedGateway?: Maybe<
                    { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayName">
                  >;
                  joinedGateway?: Maybe<
                    { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayName">
                  >;
                }
            >;
            positionConfiguration: Array<
              { __typename?: "positionConfigurations" } & Pick<PositionConfigurations, "desiredEmonSwitchPosition">
            >;
            nodeSlots: Array<
              { __typename?: "sensorflow_node_slots" } & Pick<Sensorflow_Node_Slots, "slotName" | "nodeType">
            >;
          }
        >;
      }
  >;
};

export type TemporaryInfrastructureQueryVariables = {
  id: Scalars["uuid"];
};

export type TemporaryInfrastructureQuery = { __typename?: "query_root" } & {
  infrastructures: Array<
    { __typename?: "sensorflow_v_infrastructures" } & Pick<
      Sensorflow_V_Infrastructures,
      "id" | "name" | "type" | "hvacSystemType" | "ctMultiplier" | "meterPositionId"
    > & {
        coolingSystem?: Maybe<
          { __typename?: "sensorflow_compressors" } & Pick<Sensorflow_Compressors, "compressorId" | "compressorName">
        >;
        position?: Maybe<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">
        >;
        rooms: Array<
          { __typename?: "sensorflow_compressor_mapping" } & {
            position: { __typename?: "sensorflow_positions" } & Pick<
              Sensorflow_Positions,
              "positionId" | "positionName"
            > & {
                parentPosition?: Maybe<
                  { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionName">
                >;
              };
          }
        >;
        meterPosition?: Maybe<
          { __typename?: "sensorflow_positions" } & {
            nodes: Array<
              { __typename?: "sensorflow_node_join_status" } & Pick<
                Sensorflow_Node_Join_Status,
                | "nodeMacId"
                | "nodeType"
                | "slotName"
                | "positionId"
                | "lastSeen"
                | "status"
                | "signalStrength"
                | "mappedTime"
              > & {
                  mappedGateway?: Maybe<
                    { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayName" | "gatewayId">
                  >;
                  joinedGateway?: Maybe<
                    { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayName">
                  >;
                }
            >;
            positionConfiguration: Array<
              { __typename?: "positionConfigurations" } & Pick<PositionConfigurations, "desiredEmonSwitchPosition">
            >;
            nodeSlots: Array<
              { __typename?: "sensorflow_node_slots" } & Pick<Sensorflow_Node_Slots, "slotName" | "nodeType">
            >;
          }
        >;
      }
  >;
};

export type UpdateCompressorMutationVariables = {
  id: Scalars["uuid"];
  name: Scalars["String"];
  object?: Maybe<Sensorflow_Compressors_Set_Input>;
};

export type UpdateCompressorMutation = { __typename?: "mutation_root" } & {
  updatePositions?: Maybe<
    { __typename?: "sensorflow_positions_mutation_response" } & Pick<
      Sensorflow_Positions_Mutation_Response,
      "affected_rows"
    >
  >;
  updateCompressor?: Maybe<{ __typename?: "sensorflow_compressors" } & Pick<Sensorflow_Compressors, "compressorName">>;
};

export type UpdateChillerMutationVariables = {
  id: Scalars["uuid"];
  name: Scalars["String"];
  object?: Maybe<Sensorflow_Chillers_Set_Input>;
};

export type UpdateChillerMutation = { __typename?: "mutation_root" } & {
  updatePositions?: Maybe<
    { __typename?: "sensorflow_positions_mutation_response" } & Pick<
      Sensorflow_Positions_Mutation_Response,
      "affected_rows"
    >
  >;
  updateChiller?: Maybe<{ __typename?: "sensorflow_chillers" } & Pick<Sensorflow_Chillers, "name">>;
};

export type UpdateCoolingTowerMutationVariables = {
  id: Scalars["uuid"];
  name: Scalars["String"];
  object?: Maybe<Sensorflow_Cooling_Towers_Set_Input>;
};

export type UpdateCoolingTowerMutation = { __typename?: "mutation_root" } & {
  updatePositions?: Maybe<
    { __typename?: "sensorflow_positions_mutation_response" } & Pick<
      Sensorflow_Positions_Mutation_Response,
      "affected_rows"
    >
  >;
  updateCoolingTower?: Maybe<{ __typename?: "sensorflow_cooling_towers" } & Pick<Sensorflow_Cooling_Towers, "name">>;
};

export type UpdatePipeMutationVariables = {
  id: Scalars["uuid"];
  name: Scalars["String"];
  object?: Maybe<Sensorflow_Pipes_Set_Input>;
};

export type UpdatePipeMutation = { __typename?: "mutation_root" } & {
  updatePositions?: Maybe<
    { __typename?: "sensorflow_positions_mutation_response" } & Pick<
      Sensorflow_Positions_Mutation_Response,
      "affected_rows"
    >
  >;
  updatePipe?: Maybe<{ __typename?: "sensorflow_pipes" } & Pick<Sensorflow_Pipes, "name">>;
};

export type UpdatePumpMutationVariables = {
  id: Scalars["uuid"];
  name: Scalars["String"];
  object?: Maybe<Sensorflow_Pumps_Set_Input>;
};

export type UpdatePumpMutation = { __typename?: "mutation_root" } & {
  updatePositions?: Maybe<
    { __typename?: "sensorflow_positions_mutation_response" } & Pick<
      Sensorflow_Positions_Mutation_Response,
      "affected_rows"
    >
  >;
  updatePump?: Maybe<{ __typename?: "sensorflow_pumps" } & Pick<Sensorflow_Pumps, "name">>;
};

export type UpsertCompressorPositionMappingsMutationVariables = {
  compressorId: Scalars["uuid"];
  mappingsInput: Array<Sensorflow_Compressor_Mapping_Insert_Input>;
};

export type UpsertCompressorPositionMappingsMutation = { __typename?: "mutation_root" } & {
  deleteCompressorPositionMappings?: Maybe<
    { __typename?: "sensorflow_compressor_mapping_mutation_response" } & Pick<
      Sensorflow_Compressor_Mapping_Mutation_Response,
      "affected_rows"
    >
  >;
  createCompressorPositionMappings?: Maybe<
    { __typename?: "sensorflow_compressor_mapping_mutation_response" } & Pick<
      Sensorflow_Compressor_Mapping_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type PositionConfigurationsByKeyQueryVariables = {
  keyId: Scalars["uuid"];
};

export type PositionConfigurationsByKeyQuery = { __typename?: "query_root" } & {
  position?: Maybe<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
        rooms: Array<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
              positionConfiguration: Array<
                { __typename?: "positionConfigurations" } & Pick<
                  PositionConfigurations,
                  | "automationMode"
                  | "isDeviatedFromDefaultConfig"
                  | "creationDate"
                  | "occNightStart"
                  | "occNightEnd"
                  | "occTimeoutNightMins"
                  | "occTimeoutDayMins"
                  | "occupancySensitivity"
                  | "doorSensorTimeoutMins"
                  | "softstartWaitTime"
                  | "hysteresisOffDegrees"
                  | "hysteresisOnDegrees"
                  | "hideSetpoint"
                  | "heatingHideSetpoint"
                  | "unoccupiedTemp"
                  | "heatingUnoccupiedTemp"
                  | "minTemp"
                  | "maxTemp"
                  | "heatingMinTemp"
                  | "heatingMaxTemp"
                  | "acNightEnd"
                  | "acNightStart"
                  | "actuationTimeoutSeconds"
                  | "bridgeAdaptorSetting"
                  | "desiredEmonSwitchPosition"
                  | "freezeProtectionTemp"
                  | "recordType"
                  | "actingMode"
                  | "operationalMode"
                  | "expiredAt"
                  | "enableDoorSensor"
                  | "nightTimeSetpointEnabled"
                  | "nightTimeSetpointDOW"
                  | "nightTimeSetpointDelay"
                  | "nightTimeSetpointStart"
                  | "nightTimeSetpointEnd"
                  | "nightTimeSetpointOffset"
                  | "nightTimeSetpointMaxSetpoint"
                  | "forceOccupancyAutomation"
                > & {
                    modifiedByUser?: Maybe<
                      { __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "userId" | "name">
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type CreatePrecoolCommandsMutationVariables = {
  precoolCommands: Array<Sensorflow_Precool_Commands_Insert_Input>;
};

export type CreatePrecoolCommandsMutation = { __typename?: "mutation_root" } & {
  createPrecoolCommands?: Maybe<
    { __typename?: "sensorflow_precool_commands_mutation_response" } & {
      returning: Array<
        { __typename?: "sensorflow_precool_commands" } & Pick<Sensorflow_Precool_Commands, "id" | "totalMinutes">
      >;
    }
  >;
};

export type CreateAcSettingCommandsMutationVariables = {
  acSettingCommands: Array<Sensorflow_Ac_Setting_Commands_Insert_Input>;
};

export type CreateAcSettingCommandsMutation = { __typename?: "mutation_root" } & {
  createACSettingCommands?: Maybe<
    { __typename?: "sensorflow_ac_setting_commands_mutation_response" } & {
      returning: Array<
        { __typename?: "sensorflow_ac_setting_commands" } & Pick<
          Sensorflow_Ac_Setting_Commands,
          | "id"
          | "fromACStatus"
          | "toACStatus"
          | "fromACSetPoint"
          | "toACSetPoint"
          | "fromACMode"
          | "toACMode"
          | "fromFanSpeed"
          | "toFanSpeed"
        >
      >;
    }
  >;
};

export type KeyDetailWithNodeMeasurementsSubscriptionVariables = {
  keyId: Scalars["uuid"];
};

export type KeyDetailWithNodeMeasurementsSubscription = { __typename?: "subscription_root" } & {
  position?: Maybe<
    { __typename?: "sensorflow_positions" } & {
      rooms: Array<
        { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
            nodeMeasurements: Array<
              { __typename?: "sensorflow_node_measurement_view" } & Pick<
                Sensorflow_Node_Measurement_View,
                | "ambientTemperature"
                | "ambientHumidity"
                | "door"
                | "occupancyState"
                | "acStatus"
                | "acMode"
                | "acSetPoint"
                | "fanSpeed"
                | "automationStatus"
              >
            >;
            positionConfiguration: Array<
              { __typename?: "positionConfigurations" } & Pick<
                PositionConfigurations,
                "minTemp" | "maxTemp" | "heatingMinTemp" | "heatingMaxTemp"
              >
            >;
            precoolCommands: Array<
              { __typename?: "sensorflow_precool_commands" } & Pick<
                Sensorflow_Precool_Commands,
                "totalMinutes" | "creationDate" | "status" | "startedAt"
              >
            >;
            acSettingCommands: Array<
              { __typename?: "sensorflow_ac_setting_commands" } & Pick<
                Sensorflow_Ac_Setting_Commands,
                "creationDate"
              > & {
                  createdByUser?: Maybe<
                    { __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "userId" | "name">
                  >;
                }
            >;
            lastACSettingCommands: Array<
              { __typename?: "sensorflow_ac_setting_commands" } & Pick<
                Sensorflow_Ac_Setting_Commands,
                "status" | "creationDate" | "toACStatus" | "toACSetPoint" | "toACMode" | "toFanSpeed"
              >
            >;
            slotMappings: Array<
              { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
                Sensorflow_Node_To_Slot_Mapping,
                "nodeType" | "nodeMacId"
              >
            >;
          }
      >;
    }
  >;
};

export type ActivityLogsSubscriptionVariables = {
  keyId: Scalars["uuid"];
  fromDate: Scalars["timestamptz"];
  toDate: Scalars["timestamptz"];
};

export type ActivityLogsSubscription = { __typename?: "subscription_root" } & {
  key?: Maybe<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionName"> & {
        rooms: Array<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
              acSettingCommands: Array<
                { __typename?: "sensorflow_ac_setting_commands" } & Pick<
                  Sensorflow_Ac_Setting_Commands,
                  | "source"
                  | "fromACStatus"
                  | "toACStatus"
                  | "fromACSetPoint"
                  | "toACSetPoint"
                  | "fromACMode"
                  | "toACMode"
                  | "fromFanSpeed"
                  | "toFanSpeed"
                  | "creationDate"
                > & {
                    createdByUser?: Maybe<
                      { __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "userId" | "name">
                    >;
                  }
              >;
              positionConfiguration: Array<
                { __typename?: "positionConfigurationsForActivityLog" } & Pick<
                  PositionConfigurationsForActivityLog,
                  | "automationMode"
                  | "isDeviatedFromDefaultConfig"
                  | "creationDate"
                  | "occNightStart"
                  | "occNightEnd"
                  | "occTimeoutNightMins"
                  | "occTimeoutDayMins"
                  | "occupancySensitivity"
                  | "doorSensorTimeoutMins"
                  | "softstartWaitTime"
                  | "hysteresisOffDegrees"
                  | "hysteresisOnDegrees"
                  | "hideSetpoint"
                  | "heatingHideSetpoint"
                  | "unoccupiedTemp"
                  | "minTemp"
                  | "maxTemp"
                  | "heatingMinTemp"
                  | "heatingMaxTemp"
                  | "acNightEnd"
                  | "acNightStart"
                  | "actuationTimeoutSeconds"
                  | "bridgeAdaptorSetting"
                  | "desiredEmonSwitchPosition"
                > & {
                    modifiedByUser?: Maybe<
                      { __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "userId" | "name">
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type TemporaryActivityLogsQueryVariables = {
  keyId: Scalars["uuid"];
  fromDate: Scalars["timestamptz"];
  toDate: Scalars["timestamptz"];
};

export type TemporaryActivityLogsQuery = { __typename?: "query_root" } & {
  key?: Maybe<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionName"> & {
        rooms: Array<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
              acSettingCommands: Array<
                { __typename?: "sensorflow_ac_setting_commands" } & Pick<
                  Sensorflow_Ac_Setting_Commands,
                  | "source"
                  | "fromACStatus"
                  | "toACStatus"
                  | "fromACSetPoint"
                  | "toACSetPoint"
                  | "fromACMode"
                  | "toACMode"
                  | "fromFanSpeed"
                  | "toFanSpeed"
                  | "creationDate"
                > & {
                    createdByUser?: Maybe<
                      { __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "userId" | "name">
                    >;
                  }
              >;
              positionConfiguration: Array<
                { __typename?: "positionConfigurations" } & Pick<
                  PositionConfigurations,
                  | "automationMode"
                  | "isDeviatedFromDefaultConfig"
                  | "creationDate"
                  | "occNightStart"
                  | "occNightEnd"
                  | "occTimeoutNightMins"
                  | "occTimeoutDayMins"
                  | "occupancySensitivity"
                  | "doorSensorTimeoutMins"
                  | "softstartWaitTime"
                  | "hysteresisOffDegrees"
                  | "hysteresisOnDegrees"
                  | "hideSetpoint"
                  | "heatingHideSetpoint"
                  | "unoccupiedTemp"
                  | "minTemp"
                  | "maxTemp"
                  | "heatingMinTemp"
                  | "heatingMaxTemp"
                  | "acNightEnd"
                  | "acNightStart"
                  | "actuationTimeoutSeconds"
                  | "bridgeAdaptorSetting"
                  | "desiredEmonSwitchPosition"
                > & {
                    modifiedByUser?: Maybe<
                      { __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "userId" | "name">
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type ActivityLogsEventStreamSubscriptionVariables = {
  positionId: Scalars["uuid"];
  fromDate: Scalars["timestamptz"];
  toDate: Scalars["timestamptz"];
};

export type ActivityLogsEventStreamSubscription = { __typename?: "subscription_root" } & {
  sensorflow_f_get_event_stream: Array<
    { __typename?: "sensorflow_get_event_stream_view" } & Pick<
      Sensorflow_Get_Event_Stream_View,
      "positionId" | "eventType" | "currentValue" | "previousValue" | "changedBy" | "hasChanged" | "time"
    >
  >;
};

export type TemporaryActivityLogsEventStreamQueryVariables = {
  positionId: Scalars["uuid"];
  fromDate: Scalars["timestamptz"];
  toDate: Scalars["timestamptz"];
};

export type TemporaryActivityLogsEventStreamQuery = { __typename?: "query_root" } & {
  sensorflow_f_get_event_stream: Array<
    { __typename?: "sensorflow_get_event_stream_view" } & Pick<
      Sensorflow_Get_Event_Stream_View,
      "positionId" | "eventType" | "currentValue" | "previousValue" | "changedBy" | "hasChanged" | "time"
    >
  >;
};

export type GetAllActivityLogsEventStreamQueryVariables = {
  positionId: Scalars["uuid"];
  fromDate: Scalars["timestamptz"];
  toDate: Scalars["timestamptz"];
};

export type GetAllActivityLogsEventStreamQuery = { __typename?: "query_root" } & {
  sensorflow_f_get_all_event_stream: Array<
    { __typename?: "sensorflow_ac_command_event_stream" } & Pick<
      Sensorflow_Ac_Command_Event_Stream,
      | "event_type"
      | "new_ac_settings_value"
      | "previous_ac_settings_value"
      | "modified_by_user"
      | "ac_settings_type_changed"
      | "time"
    >
  >;
};

export type KeyListWithPositionConfigurationQueryVariables = {
  keyName?: Maybe<Scalars["String"]>;
  autosetName?: Maybe<Scalars["String"]>;
  locationId: Scalars["uuid"];
  where: Sensorflow_Positions_Bool_Exp;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
};

export type KeyListWithPositionConfigurationQuery = { __typename?: "query_root" } & {
  locationOne?: Maybe<
    { __typename?: "sensorflow_locations" } & Pick<Sensorflow_Locations, "locationId"> & {
        keyCategories: Array<
          { __typename?: "sensorflow_key_categories" } & Pick<Sensorflow_Key_Categories, "categoryName">
        >;
        positionsAggregate: { __typename?: "sensorflow_positions_aggregate" } & {
          aggregate?: Maybe<
            { __typename?: "sensorflow_positions_aggregate_fields" } & Pick<
              Sensorflow_Positions_Aggregate_Fields,
              "count"
            >
          >;
        };
        searchPositions?: Maybe<
          Array<
            { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
                keyCategoryPositionMapping?: Maybe<
                  { __typename?: "sensorflow_key_category_to_key_position" } & Pick<
                    Sensorflow_Key_Category_To_Key_Position,
                    "categoryName"
                  >
                >;
                searchRooms?: Maybe<
                  Array<
                    { __typename?: "sensorflow_positions" } & Pick<
                      Sensorflow_Positions,
                      "positionId" | "positionName"
                    > & {
                        positionConfiguration: Array<
                          { __typename?: "positionConfigurations" } & Pick<
                            PositionConfigurations,
                            | "automationMode"
                            | "isDeviatedFromDefaultConfig"
                            | "minTemp"
                            | "maxTemp"
                            | "heatingMinTemp"
                            | "heatingMaxTemp"
                            | "hideSetpoint"
                            | "heatingHideSetpoint"
                            | "acNightStart"
                            | "acNightEnd"
                            | "unoccupiedTemp"
                            | "heatingUnoccupiedTemp"
                            | "operationalMode"
                            | "actingMode"
                            | "expiredAt"
                          >
                        >;
                      }
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type GetOnePositionConfigQueryVariables = {
  where: PositionConfigurations_Bool_Exp;
};

export type GetOnePositionConfigQuery = { __typename?: "query_root" } & {
  positionConfigurations: Array<{ __typename?: "positionConfigurations" } & Pick<PositionConfigurations, "positionId">>;
};

export type KeyBeforeAndAfterQueryVariables = {
  keyId: Scalars["uuid"];
  keyName: Scalars["String"];
};

export type KeyBeforeAndAfterQuery = { __typename?: "query_root" } & {
  key?: Maybe<
    { __typename?: "sensorflow_positions" } & {
      location?: Maybe<
        { __typename?: "sensorflow_locations" } & {
          keyAfter: Array<
            { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">
          >;
          keyBefore: Array<
            { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">
          >;
        }
      >;
    }
  >;
};

export type KeysWithRoomIdsQueryVariables = {
  where: Sensorflow_Positions_Bool_Exp;
  filterByRoomName?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type KeysWithRoomIdsQuery = { __typename?: "query_root" } & {
  keys: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId"> & {
        rooms: Array<{ __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId">>;
      }
  >;
};

export type LocationMetadataQueryVariables = {
  locationId: Scalars["uuid"];
};

export type LocationMetadataQuery = { __typename?: "query_root" } & {
  locationMetadata?: Maybe<
    { __typename?: "sensorflow_location_metadata" } & Pick<
      Sensorflow_Location_Metadata,
      | "showEnergyConsumption"
      | "subscription"
      | "activeAutosetCount"
      | "showHeatingMode"
      | "allowDisableAutomationMode"
      | "isAutomationModeDisabled"
      | "smartRemValidation"
      | "availableOperationalModes"
      | "smplrspaceId"
      | "isTimeBasedConfigOnboarded"
    >
  >;
};

export type LocationSettingsQueryVariables = {
  locationId: Scalars["uuid"];
};

export type LocationSettingsQuery = { __typename?: "query_root" } & {
  locationMetadata?: Maybe<
    { __typename?: "sensorflow_location_metadata" } & Pick<
      Sensorflow_Location_Metadata,
      | "showEnergyConsumption"
      | "subscription"
      | "showHeatingMode"
      | "activeAutosetCount"
      | "allowDisableAutomationMode"
      | "smartRemValidation"
      | "availableOperationalModes"
      | "gatewayHardwareType"
      | "gatewayPromiscuousModeEnabled"
      | "hasKeyCard"
      | "enableNoKeyCardState"
      | "beaconSlotManagement"
    >
  >;
  locationOne?: Maybe<
    { __typename?: "sensorflow_locations" } & {
      activePropertyAlerts: Array<
        { __typename?: "sf_support_active_property_alerts" } & Pick<
          Sf_Support_Active_Property_Alerts,
          "active" | "locationId" | "ticketClassId"
        >
      >;
    }
  >;
};

export type NodeToSlotMappingByLocationAggregateQueryVariables = {
  locationId: Scalars["uuid"];
  nodeType: Scalars["String"];
};

export type NodeToSlotMappingByLocationAggregateQuery = { __typename?: "query_root" } & {
  nodeToSlotMappingByLocationAggregate: { __typename?: "sensorflow_node_to_slot_mapping_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "sensorflow_node_to_slot_mapping_aggregate_fields" } & Pick<
        Sensorflow_Node_To_Slot_Mapping_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type FirmwareReleasesQueryVariables = {
  where: Sensorflow_Firmware_Releases_Bool_Exp;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  order_by?: Maybe<Array<Sensorflow_Firmware_Releases_Order_By>>;
};

export type FirmwareReleasesQuery = { __typename?: "query_root" } & {
  firmwareReleases: Array<
    { __typename?: "sensorflow_firmware_releases" } & Pick<
      Sensorflow_Firmware_Releases,
      | "id"
      | "downloadLink"
      | "comment"
      | "isDeprecated"
      | "nodeSubType"
      | "uploadedDate"
      | "versionName"
      | "versionNumber"
    >
  >;
  firmwareReleaseAggregate: { __typename?: "sensorflow_firmware_releases_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "sensorflow_firmware_releases_aggregate_fields" } & Pick<
        Sensorflow_Firmware_Releases_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type CreateFirmwareReleaseMutationVariables = {
  input: Sensorflow_Firmware_Releases_Insert_Input;
};

export type CreateFirmwareReleaseMutation = { __typename?: "mutation_root" } & {
  createFirmwareRelease?: Maybe<
    { __typename?: "sensorflow_firmware_releases" } & Pick<
      Sensorflow_Firmware_Releases,
      "id" | "versionName" | "versionNumber"
    >
  >;
};

export type UpdateFirmwareReleaseMutationVariables = {
  id: Scalars["Int"];
  input: Sensorflow_Firmware_Releases_Set_Input;
};

export type UpdateFirmwareReleaseMutation = { __typename?: "mutation_root" } & {
  updateFirmwareRelease?: Maybe<
    { __typename?: "sensorflow_firmware_releases" } & Pick<Sensorflow_Firmware_Releases, "id">
  >;
};

export type DeleteFirmwareReleaseMutationVariables = {
  id: Scalars["Int"];
};

export type DeleteFirmwareReleaseMutation = { __typename?: "mutation_root" } & {
  deleteFirmwareRelease?: Maybe<
    { __typename?: "sensorflow_firmware_releases" } & Pick<Sensorflow_Firmware_Releases, "id">
  >;
};

export type LocationWithOtaSettingsQueryVariables = {
  where?: Maybe<Sensorflow_Locations_Bool_Exp>;
  order_by?: Maybe<Array<Sensorflow_Locations_Order_By>>;
};

export type LocationWithOtaSettingsQuery = { __typename?: "query_root" } & {
  locations: Array<
    { __typename?: "sensorflow_locations" } & Pick<
      Sensorflow_Locations,
      "locationId" | "locationName" | "otaMaxRetries" | "otaIsEnabled" | "otaWindowStartTime" | "otaWindowEndTime"
    >
  >;
};

export type UpdateOtaSettingsMutationVariables = {
  primaryKey: Sensorflow_Locations_Pk_Columns_Input;
  updateLocationInput: Sensorflow_Locations_Set_Input;
};

export type UpdateOtaSettingsMutation = { __typename?: "mutation_root" } & {
  updateLocation?: Maybe<
    { __typename?: "sensorflow_locations" } & Pick<
      Sensorflow_Locations,
      "locationId" | "locationName" | "otaIsEnabled" | "otaMaxRetries" | "otaWindowEndTime" | "otaWindowStartTime"
    >
  >;
};

export type NodeListForOtaQueryVariables = {
  where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  order_by?: Maybe<Array<Sensorflow_Node_To_Slot_Mapping_Order_By>>;
};

export type NodeListForOtaQuery = { __typename?: "query_root" } & {
  nodes: Array<
    { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
      Sensorflow_Node_To_Slot_Mapping,
      "nodeMacId" | "nodeType"
    > & {
        position: { __typename?: "sensorflow_positions" } & Pick<
          Sensorflow_Positions,
          "positionId" | "positionName"
        > & {
            parentPosition?: Maybe<
              { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">
            >;
            location?: Maybe<
              { __typename?: "sensorflow_locations" } & Pick<
                Sensorflow_Locations,
                "locationId" | "locationName" | "otaMaxRetries"
              >
            >;
          };
        node: { __typename?: "sensorflow_nodes" } & Pick<
          Sensorflow_Nodes,
          "nodeSubType" | "firmwareVersionLocked" | "otaFailedUpgradeAttempts" | "otaComment"
        > & {
            currentFirmwareRelease?: Maybe<
              { __typename?: "sensorflow_firmware_releases" } & Pick<
                Sensorflow_Firmware_Releases,
                "versionNumber" | "versionName" | "isDeprecated"
              >
            >;
            nodeOnlineStatus?: Maybe<
              { __typename?: "sensorflow_node_online_status" } & Pick<Sensorflow_Node_Online_Status, "nodeStatus">
            >;
            targetFirmwareRelease?: Maybe<
              { __typename?: "sensorflow_firmware_releases" } & Pick<
                Sensorflow_Firmware_Releases,
                "versionNumber" | "versionName"
              >
            >;
            nodeFirmware?: Maybe<
              { __typename?: "sensorflow_node_firmware" } & Pick<Sensorflow_Node_Firmware, "upgradeStatus" | "lockable">
            >;
          };
      }
  >;
  nodeAggregate: { __typename?: "sensorflow_node_to_slot_mapping_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "sensorflow_node_to_slot_mapping_aggregate_fields" } & Pick<
        Sensorflow_Node_To_Slot_Mapping_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type NodeIdsQueryVariables = {
  where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
};

export type NodeIdsQuery = { __typename?: "query_root" } & {
  nodeIds: Array<
    { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<Sensorflow_Node_To_Slot_Mapping, "nodeMacId">
  >;
};

export type FirmwareReleasesForDropdownQueryVariables = {
  where?: Maybe<Sensorflow_Firmware_Releases_Bool_Exp>;
};

export type FirmwareReleasesForDropdownQuery = { __typename?: "query_root" } & {
  firmwareReleases: Array<
    { __typename?: "sensorflow_firmware_releases" } & Pick<
      Sensorflow_Firmware_Releases,
      "id" | "isDeprecated" | "nodeSubType" | "versionName" | "versionNumber"
    >
  >;
};

export type BulkNodeUpdateMutationVariables = {
  nodeIds?: Maybe<Array<Scalars["String"]>>;
  input: Sensorflow_Nodes_Set_Input;
};

export type BulkNodeUpdateMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_nodes?: Maybe<
    { __typename?: "sensorflow_nodes_mutation_response" } & Pick<Sensorflow_Nodes_Mutation_Response, "affected_rows">
  >;
};

export type BulkLockFirmwareMutationVariables = {
  nodeIds?: Maybe<Array<Scalars["String"]>>;
  input: Sensorflow_Nodes_Set_Input;
};

export type BulkLockFirmwareMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_nodes?: Maybe<
    { __typename?: "sensorflow_nodes_mutation_response" } & Pick<Sensorflow_Nodes_Mutation_Response, "affected_rows">
  >;
};

export type BulkUpdateFirmwareMutationVariables = {
  nodeIds?: Maybe<Array<Scalars["String"]>>;
  input: Sensorflow_Nodes_Set_Input;
};

export type BulkUpdateFirmwareMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_nodes?: Maybe<
    { __typename?: "sensorflow_nodes_mutation_response" } & Pick<Sensorflow_Nodes_Mutation_Response, "affected_rows">
  >;
};

export type GetTicketClassesForDropdownQueryVariables = {
  ticketClass?: Maybe<Scalars["String"]>;
};

export type GetTicketClassesForDropdownQuery = { __typename?: "query_root" } & {
  ticketClasses: Array<
    { __typename?: "sf_support_ticket_classes" } & Pick<Sf_Support_Ticket_Classes, "id" | "ticketClass">
  >;
};

export type UpsertActivePropertyAlertsMutationVariables = {
  objects: Array<Sf_Support_Active_Property_Alerts_Insert_Input>;
};

export type UpsertActivePropertyAlertsMutation = { __typename?: "mutation_root" } & {
  insertActivePropertyAlerts?: Maybe<
    { __typename?: "sf_support_active_property_alerts_mutation_response" } & Pick<
      Sf_Support_Active_Property_Alerts_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type GetShowHeatingModeQueryVariables = {
  locationId: Scalars["uuid"];
};

export type GetShowHeatingModeQuery = { __typename?: "query_root" } & {
  locationMetadata?: Maybe<
    { __typename?: "sensorflow_location_metadata" } & Pick<
      Sensorflow_Location_Metadata,
      "showHeatingMode" | "availableOperationalModes"
    >
  >;
};

export type GetUserLocationMetadataStatsQueryVariables = {
  where?: Maybe<Sensorflow_Location_Metadata_Bool_Exp>;
};

export type GetUserLocationMetadataStatsQuery = { __typename?: "query_root" } & {
  stats: { __typename?: "sensorflow_location_metadata_aggregate" } & {
    data?: Maybe<
      { __typename?: "sensorflow_location_metadata_aggregate_fields" } & Pick<
        Sensorflow_Location_Metadata_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type CurrentKeyEntriesSubscriptionVariables = {
  where?: Maybe<KeyEntries_Bool_Exp>;
  orderBy?: Maybe<Array<KeyEntries_Order_By>>;
  limit?: Maybe<Scalars["Int"]>;
};

export type CurrentKeyEntriesSubscription = { __typename?: "subscription_root" } & {
  keyEntries: Array<
    { __typename?: "keyEntries" } & Pick<
      KeyEntries,
      | "keyEntryId"
      | "startedAt"
      | "endedAt"
      | "userId"
      | "keyPositionId"
      | "positionFunction"
      | "expiredAt"
      | "status"
      | "comment"
      | "commentStatus"
      | "automaticTestRun"
      | "isMeterPosition"
    > & { user?: Maybe<{ __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "name">> }
  >;
};

export type GetCurrentKeyEntryOfInstallerQueryVariables = {
  userId: Scalars["String"];
};

export type GetCurrentKeyEntryOfInstallerQuery = { __typename?: "query_root" } & {
  keyEntries: Array<
    { __typename?: "keyEntries" } & Pick<
      KeyEntries,
      "keyEntryId" | "startedAt" | "endedAt" | "userId" | "keyPositionId" | "positionFunction" | "expiredAt" | "status"
    > & { position?: Maybe<{ __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "locationId">> }
  >;
};

export type KeyEntryDetailSubscriptionVariables = {
  where?: Maybe<KeyEntries_Bool_Exp>;
};

export type KeyEntryDetailSubscription = { __typename?: "subscription_root" } & {
  keyEntries: Array<
    { __typename?: "keyEntries" } & Pick<
      KeyEntries,
      "keyEntryId" | "startedAt" | "endedAt" | "expiredAt" | "status"
    > & {
        testRuns: Array<
          { __typename?: "testRuns" } & Pick<
            TestRuns,
            | "autosetPositionId"
            | "testRunId"
            | "status"
            | "lastPassedAt"
            | "lastTestedAt"
            | "note"
            | "details"
            | "isIgnored"
          > & {
              test: { __typename?: "sensorflow_tests" } & Pick<
                Sensorflow_Tests,
                "testId" | "name" | "description" | "troubleshootingLink" | "isManual"
              >;
            }
        >;
      }
  >;
};

export type UpdateTestRunStatusMutationVariables = {
  testRunId: Scalars["Int"];
  status: Scalars["String"];
  lastPassedAt?: Maybe<Scalars["timestamptz"]>;
  lastTestedAt?: Maybe<Scalars["timestamptz"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
};

export type UpdateTestRunStatusMutation = { __typename?: "mutation_root" } & {
  updateTestRuns?: Maybe<
    { __typename?: "testRuns_mutation_response" } & {
      returning: Array<
        { __typename?: "testRuns" } & Pick<
          TestRuns,
          "keyEntryId" | "lastPassedAt" | "lastTestedAt" | "startedAt" | "note" | "status" | "testRunId"
        > & { test: { __typename?: "sensorflow_tests" } & Pick<Sensorflow_Tests, "testId" | "name" | "description"> }
      >;
    }
  >;
};

export type UpdateTestRunNoteMutationVariables = {
  testRunId: Scalars["Int"];
  note?: Maybe<Scalars["String"]>;
};

export type UpdateTestRunNoteMutation = { __typename?: "mutation_root" } & {
  updateTestRuns?: Maybe<
    { __typename?: "testRuns_mutation_response" } & {
      returning: Array<{ __typename?: "testRuns" } & Pick<TestRuns, "testRunId">>;
    }
  >;
};

export type GetPositionNameQueryVariables = {
  keyId: Scalars["uuid"];
};

export type GetPositionNameQuery = { __typename?: "query_root" } & {
  positions: Array<{ __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionName" | "positionId">>;
};

export type InfrastructureEmonPowerStatusSubscriptionVariables = {
  id: Scalars["uuid"];
};

export type InfrastructureEmonPowerStatusSubscription = { __typename?: "subscription_root" } & {
  infrastructureEmonPowerStatus: Array<
    { __typename?: "sensorflow_v_infrastructure_emon_power_status" } & Pick<
      Sensorflow_V_Infrastructure_Emon_Power_Status,
      "channel" | "lastPowerReading" | "isMyChannel" | "numPhases"
    >
  >;
};

export type PositionConfigurationSubscriptionVariables = {
  keyId: Scalars["uuid"];
};

export type PositionConfigurationSubscription = { __typename?: "subscription_root" } & {
  position?: Maybe<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
        rooms: Array<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
              positionConfiguration: Array<
                { __typename?: "positionConfigurations" } & Pick<
                  PositionConfigurations,
                  | "automationMode"
                  | "isDeviatedFromDefaultConfig"
                  | "creationDate"
                  | "occNightStart"
                  | "occNightEnd"
                  | "occTimeoutNightMins"
                  | "occTimeoutDayMins"
                  | "occupancySensitivity"
                  | "doorSensorTimeoutMins"
                  | "softstartWaitTime"
                  | "hysteresisOffDegrees"
                  | "hysteresisOnDegrees"
                  | "hideSetpoint"
                  | "heatingHideSetpoint"
                  | "unoccupiedTemp"
                  | "heatingUnoccupiedTemp"
                  | "minTemp"
                  | "maxTemp"
                  | "heatingMinTemp"
                  | "heatingMaxTemp"
                  | "acNightEnd"
                  | "acNightStart"
                  | "actuationTimeoutSeconds"
                  | "bridgeAdaptorSetting"
                  | "desiredEmonSwitchPosition"
                  | "freezeProtectionTemp"
                  | "specialInstalltionMode"
                  | "recordType"
                  | "actingMode"
                  | "operationalMode"
                  | "expiredAt"
                  | "enableDoorSensor"
                  | "nightTimeSetpointEnabled"
                  | "nightTimeSetpointDOW"
                  | "nightTimeSetpointDelay"
                  | "nightTimeSetpointStart"
                  | "nightTimeSetpointEnd"
                  | "nightTimeSetpointOffset"
                  | "nightTimeSetpointMaxSetpoint"
                  | "forceOccupancyAutomation"
                > & {
                    modifiedByUser?: Maybe<
                      { __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "userId" | "name">
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type GetKeysForDropdownQueryVariables = {
  locationId: Scalars["uuid"];
};

export type GetKeysForDropdownQuery = { __typename?: "query_root" } & {
  positions: Array<{ __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">>;
};

export type AutomationModeDisabledSubscriptionVariables = {
  locationId: Scalars["uuid"];
};

export type AutomationModeDisabledSubscription = { __typename?: "subscription_root" } & {
  locationMetadata?: Maybe<
    { __typename?: "sensorflow_location_metadata" } & Pick<Sensorflow_Location_Metadata, "isAutomationModeDisabled">
  >;
};

export type ChangeDisabledAutomationModeStatusMutationVariables = {
  locationId: Scalars["ID"];
  isAutomationModeDisabled: Scalars["Boolean"];
};

export type ChangeDisabledAutomationModeStatusMutation = { __typename?: "mutation_root" } & {
  changeDisabledAutomationModeStatus: { __typename?: "LocationMetadata" } & Pick<
    LocationMetadata,
    "isAutomationModeDisabled"
  >;
};

export type GetIsManagerQueryVariables = {
  userId: Scalars["String"];
};

export type GetIsManagerQuery = { __typename?: "query_root" } & {
  sensorflow_users_by_pk?: Maybe<{ __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "isManager">>;
};

export type GetNodeLifeCycleEventTypesQueryVariables = {};

export type GetNodeLifeCycleEventTypesQuery = { __typename?: "query_root" } & {
  sensorflow_node_life_cycle_event_types: Array<
    { __typename?: "sensorflow_node_life_cycle_event_types" } & Pick<
      Sensorflow_Node_Life_Cycle_Event_Types,
      "id" | "name" | "isDefault" | "canTrackManually"
    >
  >;
};

export type GetNodeLifeCycleEventsQueryVariables = {
  order_by?: Maybe<Array<Sensorflow_Node_Life_Cycle_Events_Order_By>>;
};

export type GetNodeLifeCycleEventsQuery = { __typename?: "query_root" } & {
  sensorflow_node_life_cycle_events: Array<
    { __typename?: "sensorflow_node_life_cycle_events" } & Pick<
      Sensorflow_Node_Life_Cycle_Events,
      "id" | "nodeMacId" | "creationDate" | "comment"
    > & {
        node_life_cycle_event_type: { __typename?: "sensorflow_node_life_cycle_event_types" } & Pick<
          Sensorflow_Node_Life_Cycle_Event_Types,
          "id" | "name"
        >;
      }
  >;
};

export type CreateNodeLifeCycleEventMutationVariables = {
  input: Sensorflow_Node_Life_Cycle_Events_Insert_Input;
};

export type CreateNodeLifeCycleEventMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_node_life_cycle_events_one?: Maybe<
    { __typename?: "sensorflow_node_life_cycle_events" } & Pick<
      Sensorflow_Node_Life_Cycle_Events,
      "id" | "nodeMacId" | "creationDate" | "eventType" | "comment"
    >
  >;
};

export type CreateNodeLifeCycleEventsMutationVariables = {
  input: Array<Sensorflow_Node_Life_Cycle_Events_Insert_Input>;
};

export type CreateNodeLifeCycleEventsMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_node_life_cycle_events?: Maybe<
    { __typename?: "sensorflow_node_life_cycle_events_mutation_response" } & Pick<
      Sensorflow_Node_Life_Cycle_Events_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type UpdateNodeLifeCycleEventMutationVariables = {
  id: Scalars["Int"];
  input?: Maybe<Sensorflow_Node_Life_Cycle_Events_Set_Input>;
};

export type UpdateNodeLifeCycleEventMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_node_life_cycle_events_by_pk?: Maybe<
    { __typename?: "sensorflow_node_life_cycle_events" } & Pick<
      Sensorflow_Node_Life_Cycle_Events,
      "id" | "nodeMacId" | "creationDate" | "eventType" | "comment"
    >
  >;
};

export type UpsertSmartRemValidationHistoryMutationVariables = {
  object: Sensorflow_Smart_Rem_Validation_History_Insert_Input;
};

export type UpsertSmartRemValidationHistoryMutation = { __typename?: "mutation_root" } & {
  createSmartRemValidationHistory?: Maybe<
    { __typename?: "sensorflow_smart_rem_validation_history" } & Pick<
      Sensorflow_Smart_Rem_Validation_History,
      "locationId" | "validationStartDate" | "validationEndDate"
    >
  >;
};

export type GetSmartRemValidationHistoryQueryVariables = {
  locationId: Scalars["uuid"];
};

export type GetSmartRemValidationHistoryQuery = { __typename?: "query_root" } & {
  getSmartRemValidationHistory: Array<
    { __typename?: "sensorflow_smart_rem_validation_history" } & Pick<
      Sensorflow_Smart_Rem_Validation_History,
      "id" | "locationId" | "validationStartDate" | "validationEndDate" | "automationMode" | "smartsaveSetpoint"
    >
  >;
};

export type CreateRmaRequestMutationVariables = {
  input: CreateRmaRequestInput;
};

export type CreateRmaRequestMutation = { __typename?: "mutation_root" } & {
  createRMARequest: { __typename?: "RMAJobResponse" } & Pick<RmaJobResponse, "id">;
};

export type GenerateRmaAssetUploadLinkQueryVariables = {
  type: Scalars["String"];
  extension: Scalars["String"];
};

export type GenerateRmaAssetUploadLinkQuery = { __typename?: "query_root" } & {
  generateRMAAssetUploadLink: { __typename?: "GenerateRMAAssetUploadLinkResponse" } & Pick<
    GenerateRmaAssetUploadLinkResponse,
    "url" | "expiredInSecs" | "assetUri"
  >;
};

export type GetRmaAssetDownloadLinkQueryVariables = {
  jobId: Scalars["String"];
  type: Scalars["String"];
};

export type GetRmaAssetDownloadLinkQuery = { __typename?: "query_root" } & {
  getRMAAssetDownloadLink: { __typename?: "GetRMAAssetDownloadLinkResponse" } & Pick<
    GetRmaAssetDownloadLinkResponse,
    "url" | "expiredInSecs"
  >;
};

export type NodeByMacIdQueryVariables = {
  nodeMacId: Scalars["String"];
};

export type NodeByMacIdQuery = { __typename?: "query_root" } & {
  sensorflow_node_to_slot_mapping: Array<
    { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
      Sensorflow_Node_To_Slot_Mapping,
      "nodeMacId" | "nodeType" | "mappedTime"
    > & {
        position: { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">;
        node: { __typename?: "sensorflow_nodes" } & Pick<Sensorflow_Nodes, "nodeSubType"> & {
            gateway?: Maybe<
              { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayId" | "gatewayName">
            >;
          };
      }
  >;
};

export type GetRmaRootCausesQueryVariables = {};

export type GetRmaRootCausesQuery = { __typename?: "query_root" } & {
  sf_support_root_causes: Array<
    { __typename?: "sf_support_root_causes" } & Pick<Sf_Support_Root_Causes, "id" | "root_cause">
  >;
};

export type GetLastNodeLifeCycleEventQueryVariables = {
  nodeMacId: Scalars["String"];
};

export type GetLastNodeLifeCycleEventQuery = { __typename?: "query_root" } & {
  sensorflow_node_life_cycle_events: Array<
    { __typename?: "sensorflow_node_life_cycle_events" } & Pick<
      Sensorflow_Node_Life_Cycle_Events,
      "id" | "nodeMacId" | "eventType" | "creationDate" | "comment"
    >
  >;
};

export type GetCurrentNodeToPositionMappingQueryVariables = {
  nodeMacId: Scalars["String"];
};

export type GetCurrentNodeToPositionMappingQuery = { __typename?: "query_root" } & {
  sensorflow_node_to_position_mapping: Array<
    { __typename?: "sensorflow_node_to_position_mapping" } & Pick<
      Sensorflow_Node_To_Position_Mapping,
      "decomissionedTime" | "installedTime" | "nodeMacId" | "positionId"
    >
  >;
};

export type InsertNodeLifeCycleEventMutationVariables = {
  object: Sensorflow_Node_Life_Cycle_Events_Insert_Input;
};

export type InsertNodeLifeCycleEventMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_node_life_cycle_events_one?: Maybe<
    { __typename?: "sensorflow_node_life_cycle_events" } & Pick<
      Sensorflow_Node_Life_Cycle_Events,
      "id" | "nodeMacId" | "eventType" | "creationDate" | "comment"
    >
  >;
};

export type InfrastructureListSubscriptionVariables = {
  where: Sensorflow_V_Infrastructures_Bool_Exp;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
};

export type InfrastructureListSubscription = { __typename?: "subscription_root" } & {
  infrastructures: Array<
    { __typename?: "sensorflow_v_infrastructures" } & Pick<
      Sensorflow_V_Infrastructures,
      "id" | "meterPositionId" | "name" | "type" | "phaseStreamIndex"
    > & {
        position?: Maybe<
          { __typename?: "sensorflow_positions" } & Pick<
            Sensorflow_Positions,
            "positionId" | "positionName" | "positionType"
          >
        >;
        rooms: Array<
          { __typename?: "sensorflow_compressor_mapping" } & {
            position: { __typename?: "sensorflow_positions" } & {
              parentPosition?: Maybe<
                { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionName">
              >;
            };
          }
        >;
        meterPosition?: Maybe<
          { __typename?: "sensorflow_positions" } & {
            nodes: Array<
              { __typename?: "sensorflow_node_join_status" } & Pick<
                Sensorflow_Node_Join_Status,
                "nodeType" | "slotName" | "nodeMacId" | "lastSeen" | "status" | "nodeStatusInInstallationMode"
              >
            >;
          }
        >;
      }
  >;
};

export type GetNodeSpareSetsQueryVariables = {
  where: Sensorflow_Node_Spare_Set_Bool_Exp;
  order_by?: Maybe<Array<Sensorflow_Node_Spare_Set_Order_By>>;
};

export type GetNodeSpareSetsQuery = { __typename?: "query_root" } & {
  sensorflow_node_spare_set: Array<
    { __typename?: "sensorflow_node_spare_set" } & Pick<
      Sensorflow_Node_Spare_Set,
      "nodeMacId" | "locationId" | "mappedAt" | "consumedAt"
    > & { node: { __typename?: "sensorflow_nodes" } & Pick<Sensorflow_Nodes, "node_type" | "nodeSubType"> }
  >;
};

export type GetNodeLifeCycleHistoryQueryVariables = {
  where?: Maybe<Sensorflow_V_Node_Life_Cycle_Events_Bool_Exp>;
  order_by?: Maybe<Array<Sensorflow_V_Node_Life_Cycle_Events_Order_By>>;
};

export type GetNodeLifeCycleHistoryQuery = { __typename?: "query_root" } & {
  sensorflow_v_node_life_cycle_events: Array<
    { __typename?: "sensorflow_v_node_life_cycle_events" } & Pick<
      Sensorflow_V_Node_Life_Cycle_Events,
      "nodeMacId" | "changedAt" | "eventType" | "comment" | "nodeSubType" | "nodeType"
    >
  >;
};

export type GetNodeSlotsQueryVariables = {
  roomId?: Maybe<Scalars["uuid"]>;
};

export type GetNodeSlotsQuery = { __typename?: "query_root" } & {
  sensorflow_node_slots: Array<
    { __typename?: "sensorflow_node_slots" } & Pick<Sensorflow_Node_Slots, "nodeSubType" | "nodeType" | "slotName">
  >;
};

export type GetInfrasByPositionMeterIdQueryVariables = {
  meterPositionId: Scalars["uuid"];
};

export type GetInfrasByPositionMeterIdQuery = { __typename?: "query_root" } & {
  infrastructures: Array<
    { __typename?: "sensorflow_v_infrastructures" } & Pick<
      Sensorflow_V_Infrastructures,
      "id" | "name" | "phaseStreamIndex" | "type"
    > & {
        meterPosition?: Maybe<
          { __typename?: "sensorflow_positions" } & {
            slotMappings: Array<
              { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
                Sensorflow_Node_To_Slot_Mapping,
                "nodeMacId" | "slotName"
              >
            >;
          }
        >;
      }
  >;
};

export type UpdateKeyEntryExpiredAtMutationVariables = {
  keyEntryId: Scalars["Int"];
  expiredAt: Scalars["timestamptz"];
};

export type UpdateKeyEntryExpiredAtMutation = { __typename?: "mutation_root" } & {
  update_keyEntries_by_pk?: Maybe<{ __typename?: "keyEntries" } & Pick<KeyEntries, "keyEntryId" | "expiredAt">>;
};

export type GetEntryHasUnResolvedCommentQueryVariables = {
  keyPositionId: Scalars["String"];
};

export type GetEntryHasUnResolvedCommentQuery = { __typename?: "query_root" } & {
  keyEntries: Array<{ __typename?: "keyEntries" } & Pick<KeyEntries, "keyEntryId" | "comment" | "commentStatus">>;
};

export type UpdateKeyEntryCommentMutationVariables = {
  keyEntryId: Scalars["Int"];
  comment: Scalars["String"];
  commentStatus: Scalars["String"];
};

export type UpdateKeyEntryCommentMutation = { __typename?: "mutation_root" } & {
  update_keyEntries_by_pk?: Maybe<{ __typename?: "keyEntries" } & Pick<KeyEntries, "keyEntryId" | "commentStatus">>;
};

export type GetMeterPositionIdMappedNodeMacIdQueryVariables = {
  nodeMacId?: Maybe<Scalars["String"]>;
};

export type GetMeterPositionIdMappedNodeMacIdQuery = { __typename?: "query_root" } & {
  sensorflow_node_to_slot_mapping: Array<
    { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<Sensorflow_Node_To_Slot_Mapping, "parentPositionId">
  >;
};

export type OnlineGatewayHealthDataByLocationQueryVariables = {
  locationId: Scalars["uuid"];
  offlineTime?: Maybe<Scalars["timestamptz"]>;
};

export type OnlineGatewayHealthDataByLocationQuery = { __typename?: "query_root" } & {
  gateways: Array<
    { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayId"> & {
        gatewayHealthData: Array<
          { __typename?: "sensorflow_gateway_health_data" } & Pick<
            Sensorflow_Gateway_Health_Data,
            "measurementType" | "time" | "value"
          >
        >;
        wlanLinkGatewayHealthData: Array<
          { __typename?: "sensorflow_gateway_health_data" } & Pick<
            Sensorflow_Gateway_Health_Data,
            "measurementType" | "time" | "value"
          >
        >;
        wlan1LinkGatewayHealthData: Array<
          { __typename?: "sensorflow_gateway_health_data" } & Pick<
            Sensorflow_Gateway_Health_Data,
            "measurementType" | "time" | "value"
          >
        >;
      }
  >;
};

export type PositionConfigurationByPositionIdsQueryVariables = {
  positionIds?: Maybe<Array<Scalars["uuid"]>>;
};

export type PositionConfigurationByPositionIdsQuery = { __typename?: "query_root" } & {
  positionConfigurations: Array<
    { __typename?: "positionConfigurations" } & Pick<
      PositionConfigurations,
      | "acModelId"
      | "automationMode"
      | "isDeviatedFromDefaultConfig"
      | "creationDate"
      | "occNightStart"
      | "occNightEnd"
      | "occTimeoutNightMins"
      | "occTimeoutDayMins"
      | "occupancySensitivity"
      | "doorSensorTimeoutMins"
      | "softstartWaitTime"
      | "hysteresisOffDegrees"
      | "hysteresisOnDegrees"
      | "hideSetpoint"
      | "heatingHideSetpoint"
      | "unoccupiedTemp"
      | "heatingUnoccupiedTemp"
      | "minTemp"
      | "maxTemp"
      | "heatingMinTemp"
      | "heatingMaxTemp"
      | "acNightEnd"
      | "acNightStart"
      | "actuationTimeoutSeconds"
      | "bridgeAdaptorSetting"
      | "desiredEmonSwitchPosition"
      | "freezeProtectionTemp"
      | "recordType"
      | "actingMode"
      | "operationalMode"
      | "expiredAt"
      | "enableDoorSensor"
    > & { modifiedByUser?: Maybe<{ __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "userId" | "name">> }
  >;
};

export type GetCurrencyListQueryVariables = {};

export type GetCurrencyListQuery = { __typename?: "query_root" } & {
  currencies: Array<
    { __typename?: "sensorflow_currencies" } & Pick<Sensorflow_Currencies, "name" | "shortCode" | "id">
  >;
};

export type GetTimeZoneListQueryVariables = {};

export type GetTimeZoneListQuery = { __typename?: "query_root" } & {
  sensorflow_timezones: Array<{ __typename?: "sensorflow_timezones" } & Pick<Sensorflow_Timezones, "id" | "timezone">>;
};

export type PrecoolCommandsQueryVariables = {
  where?: Maybe<Sensorflow_Precool_Commands_Bool_Exp>;
};

export type PrecoolCommandsQuery = { __typename?: "query_root" } & {
  sensorflow_precool_commands: Array<
    { __typename?: "sensorflow_precool_commands" } & Pick<
      Sensorflow_Precool_Commands,
      "id" | "positionId" | "createdBy" | "source" | "updatedAt" | "mode" | "temperature" | "startedAt"
    > & { user?: Maybe<{ __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "name">> }
  >;
};

export type PositionPrecoolStatusByLocationIdSubscriptionVariables = {
  locationId: Scalars["uuid"];
};

export type PositionPrecoolStatusByLocationIdSubscription = { __typename?: "subscription_root" } & {
  positionPrecoolStatus: Array<
    { __typename?: "sensorflow_position_precool_status" } & Pick<
      Sensorflow_Position_Precool_Status,
      | "precoolCommandId"
      | "status"
      | "startedAt"
      | "temperature"
      | "totalMinutes"
      | "mode"
      | "positionId"
      | "parentPositionId"
      | "createdBy"
      | "creationDate"
    >
  >;
};

export type ExportClickupProressSubscriptionSubscriptionVariables = {
  locationId: Scalars["uuid"];
};

export type ExportClickupProressSubscriptionSubscription = { __typename?: "subscription_root" } & {
  locationOne?: Maybe<
    { __typename?: "sensorflow_locations" } & Pick<
      Sensorflow_Locations,
      "locationId" | "clickupListId" | "clickupExportProgress"
    >
  >;
};

export type GetUsesForLocationQueryVariables = {
  locationId: Scalars["uuid"];
};

export type GetUsesForLocationQuery = { __typename?: "query_root" } & {
  sensorflow_location_to_user_mapping: Array<
    { __typename?: "sensorflow_location_to_user_mapping" } & {
      user: { __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "name" | "email">;
    }
  >;
};

export type CreatePositionMutationVariables = {
  input: Sensorflow_Positions_Insert_Input;
};

export type CreatePositionMutation = { __typename?: "mutation_root" } & {
  createPosition?: Maybe<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "locationId" | "positionId">
  >;
};

export type GroupsQueryVariables = {
  where: Sensorflow_V_Group_With_Stats_Bool_Exp;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
};

export type GroupsQuery = { __typename?: "query_root" } & {
  sensorflow_v_group_with_stats: Array<
    { __typename?: "sensorflow_v_group_with_stats" } & Pick<
      Sensorflow_V_Group_With_Stats,
      "positionId" | "positionName" | "positionType" | "keysCount" | "gatewaysCount" | "compressorsCount"
    >
  >;
  groupsAggregate: { __typename?: "sensorflow_v_group_with_stats_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "sensorflow_v_group_with_stats_aggregate_fields" } & Pick<
        Sensorflow_V_Group_With_Stats_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type GetGroupsForDropdownQueryVariables = {
  where?: Maybe<Sensorflow_Positions_Bool_Exp>;
};

export type GetGroupsForDropdownQuery = { __typename?: "query_root" } & {
  positions: Array<{ __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">>;
};

export type GroupTasksQueryVariables = {
  where: Sensorflow_V_Group_Tasks_Bool_Exp;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
};

export type GroupTasksQuery = { __typename?: "query_root" } & {
  sensorflow_v_group_tasks: Array<
    { __typename?: "sensorflow_v_group_tasks" } & Pick<
      Sensorflow_V_Group_Tasks,
      "positionId" | "positionName" | "positionType" | "keysCount" | "roomsCount" | "openJobEntryCount"
    >
  >;
  groupsAggregate: { __typename?: "sensorflow_v_group_tasks_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "sensorflow_v_group_tasks_aggregate_fields" } & Pick<
        Sensorflow_V_Group_Tasks_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type GetKeyIdsForGroupQueryVariables = {
  groupId: Scalars["uuid"];
};

export type GetKeyIdsForGroupQuery = { __typename?: "query_root" } & {
  positions: Array<{ __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName">>;
};

export type GetPositionQueryVariables = {
  positionId: Scalars["uuid"];
};

export type GetPositionQuery = { __typename?: "query_root" } & {
  position?: Maybe<
    { __typename?: "sensorflow_positions" } & Pick<
      Sensorflow_Positions,
      "positionId" | "positionName" | "positionType" | "positionFunction"
    >
  >;
};

export type GetWorkInfoQueryVariables = {
  where: Sensorflow_V_Tasks_With_Keys_Bool_Exp;
};

export type GetWorkInfoQuery = { __typename?: "query_root" } & {
  tasksWithKeys: Array<
    { __typename?: "sensorflow_v_tasks_with_keys" } & Pick<
      Sensorflow_V_Tasks_With_Keys,
      | "keyId"
      | "keyName"
      | "roomId"
      | "roomName"
      | "associatedSlotId"
      | "slotName"
      | "nodeType"
      | "nodeSubType"
      | "taskId"
      | "status"
      | "taskType"
      | "title"
      | "description"
      | "clickupTaskId"
      | "parentEntityType"
      | "claimerEmail"
      | "executor"
      | "createdAt"
      | "keyEntryId"
    >
  >;
};

export type WorkInfoSubscriptionVariables = {
  where: Sensorflow_V_Tasks_With_Keys_Bool_Exp;
};

export type WorkInfoSubscription = { __typename?: "subscription_root" } & {
  tasksWithKeys: Array<
    { __typename?: "sensorflow_v_tasks_with_keys" } & Pick<
      Sensorflow_V_Tasks_With_Keys,
      | "keyId"
      | "keyName"
      | "roomId"
      | "roomName"
      | "associatedSlotId"
      | "slotName"
      | "nodeType"
      | "nodeSubType"
      | "taskId"
      | "status"
      | "taskType"
      | "title"
      | "description"
      | "clickupTaskId"
      | "parentEntityType"
      | "claimerEmail"
      | "executor"
      | "createdAt"
      | "keyEntryId"
    > & {
        action?: Maybe<{ __typename?: "sf_support_actions" } & Pick<Sf_Support_Actions, "action" | "instructionLink">>;
      }
  >;
};

export type UpdateTaskMutationVariables = {
  taskId: Scalars["uuid"];
  update: Sensorflow_Tasks_Set_Input;
};

export type UpdateTaskMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_tasks?: Maybe<
    { __typename?: "sensorflow_tasks_mutation_response" } & {
      returning: Array<{ __typename?: "sensorflow_tasks" } & Pick<Sensorflow_Tasks, "id" | "title" | "status">>;
    }
  >;
};

export type TaskHistoryStreamSubscriptionVariables = {
  locationId: Scalars["uuid"];
  startTrackingTime: Scalars["timestamptz"];
};

export type TaskHistoryStreamSubscription = { __typename?: "subscription_root" } & {
  sensorflow_v_task_with_key_status_history_stream: Array<
    { __typename?: "sensorflow_v_task_with_key_status_history" } & Pick<
      Sensorflow_V_Task_With_Key_Status_History,
      "keyId" | "keyName" | "keyPositionType" | "categoryName" | "roomsCount"
    >
  >;
};

export type UpdateCompressorInstallTaskToPendingMutationVariables = {
  compressorId: Scalars["String"];
};

export type UpdateCompressorInstallTaskToPendingMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_tasks?: Maybe<
    { __typename?: "sensorflow_tasks_mutation_response" } & {
      returning: Array<{ __typename?: "sensorflow_tasks" } & Pick<Sensorflow_Tasks, "id" | "title" | "status">>;
    }
  >;
};

export type RemoveGuestFromComfortPlusActivationMutationVariables = {
  userId: Scalars["String"];
  reservationId: Scalars["bigint"];
};

export type RemoveGuestFromComfortPlusActivationMutation = { __typename?: "mutation_root" } & {
  deleteReservastionGuest?: Maybe<
    { __typename?: "sensorflow_reservation_guests" } & Pick<
      Sensorflow_Reservation_Guests,
      "userId" | "reservationId" | "comfortplusAccessToken"
    >
  >;
};

export type GetPrecoolSchedulesQueryVariables = {};

export type GetPrecoolSchedulesQuery = { __typename?: "query_root" } & {
  sensorflow_precool_schedules: Array<
    { __typename?: "sensorflow_precool_schedules" } & Pick<
      Sensorflow_Precool_Schedules,
      "id" | "startedAt" | "endedAt" | "weekDay" | "time" | "type"
    >
  >;
};

export type UpcomingPrecoolSchedulesSubscriptionVariables = {};

export type UpcomingPrecoolSchedulesSubscription = { __typename?: "subscription_root" } & {
  sensorflow_precool_commands: Array<
    { __typename?: "sensorflow_precool_commands" } & Pick<Sensorflow_Precool_Commands, "startedAt">
  >;
};

export type DomoEmbedsQueryVariables = {};

export type DomoEmbedsQuery = { __typename?: "query_root" } & {
  domoEmbeds: Array<
    { __typename?: "sensorflow_domo_embeds" } & Pick<
      Sensorflow_Domo_Embeds,
      "embed_id" | "name" | "holisticsSecretKey" | "permissionDatasets" | "isHolistics"
    >
  >;
};

export type LocationDomoEmbedsQueryVariables = {
  where?: Maybe<Sensorflow_Location_Domo_Embeds_Bool_Exp>;
};

export type LocationDomoEmbedsQuery = { __typename?: "query_root" } & {
  locationDomoEmbeds: Array<
    { __typename?: "sensorflow_location_domo_embeds" } & {
      domo_embed: { __typename?: "sensorflow_domo_embeds" } & Pick<
        Sensorflow_Domo_Embeds,
        "embed_id" | "name" | "description" | "holisticsSecretKey" | "isHolistics"
      >;
    }
  >;
};

export type AddLocationDomoEmbedMutationVariables = {
  object: Sensorflow_Location_Domo_Embeds_Insert_Input;
};

export type AddLocationDomoEmbedMutation = { __typename?: "mutation_root" } & {
  addLocationDomoEmbed?: Maybe<{ __typename: "sensorflow_location_domo_embeds" }>;
};

export type RemoveLocationDomoEmbedMutationVariables = {
  location_id: Scalars["uuid"];
  embed_id: Scalars["String"];
};

export type RemoveLocationDomoEmbedMutation = { __typename?: "mutation_root" } & {
  removeLocationDomoEmbed?: Maybe<{ __typename: "sensorflow_location_domo_embeds" }>;
};

export type KeyWithTaskDuedateSubscriptionVariables = {
  keyId?: Maybe<Array<Scalars["String"]>>;
};

export type KeyWithTaskDuedateSubscription = { __typename?: "subscription_root" } & {
  sensorflow_v_key_task_min_duedate: Array<
    { __typename?: "sensorflow_v_key_task_min_duedate" } & Pick<
      Sensorflow_V_Key_Task_Min_Duedate,
      "keyId" | "keyTaskDuedate"
    >
  >;
};

export type RoomStatusByGroupSubscriptionVariables = {
  keyIds: Array<Scalars["uuid"]>;
  positionType: Scalars["String"];
  filterSlotMappings?: Maybe<Sensorflow_Node_To_Slot_Mapping_Bool_Exp>;
};

export type RoomStatusByGroupSubscription = { __typename?: "subscription_root" } & {
  positions: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
        rooms: Array<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
              slotMappings: Array<
                { __typename?: "sensorflow_node_to_slot_mapping" } & {
                  node: { __typename?: "sensorflow_nodes" } & Pick<
                    Sensorflow_Nodes,
                    "node_mac_id" | "node_type" | "nodeSubType"
                  > & {
                      nodeOnlineStatus?: Maybe<
                        { __typename?: "sensorflow_node_online_status" } & Pick<
                          Sensorflow_Node_Online_Status,
                          "nodeStatus" | "lastTransmission" | "nodeStatusInInstallationMode"
                        >
                      >;
                      nodeJoinStrengthLive?: Maybe<
                        { __typename?: "sensorflow_node_join_strength_live" } & Pick<
                          Sensorflow_Node_Join_Strength_Live,
                          "signalStrength"
                        >
                      >;
                    };
                  slot?: Maybe<{ __typename?: "sensorflow_node_slots" } & Pick<Sensorflow_Node_Slots, "id">>;
                }
              >;
            }
        >;
      }
  >;
};

export type SimpleOnlineGatewayHealthDataByLocationSubscriptionVariables = {
  locationId: Scalars["uuid"];
  offlineTime?: Maybe<Scalars["timestamptz"]>;
};

export type SimpleOnlineGatewayHealthDataByLocationSubscription = { __typename?: "subscription_root" } & {
  gateways: Array<
    { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "gatewayId" | "gatewayName"> & {
        gatewayHealthData: Array<
          { __typename?: "sensorflow_gateway_health_data" } & Pick<
            Sensorflow_Gateway_Health_Data,
            "measurementType" | "time" | "value"
          >
        >;
      }
  >;
};

export type GetNonEmptyKeysRoomsByGroupQueryVariables = {
  locationId: Scalars["uuid"];
  groupIds: Array<Scalars["uuid"]>;
};

export type GetNonEmptyKeysRoomsByGroupQuery = { __typename?: "query_root" } & {
  positions: Array<
    { __typename?: "sensorflow_positions" } & Pick<
      Sensorflow_Positions,
      "parentPositionId" | "positionId" | "positionName"
    > & {
        mappingCoordinates: Array<
          { __typename?: "sensorflow_mapping_coordinates" } & Pick<Sensorflow_Mapping_Coordinates, "coordinates">
        >;
        rooms: Array<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
              mappingCoordinates: Array<
                { __typename?: "sensorflow_mapping_coordinates" } & Pick<Sensorflow_Mapping_Coordinates, "coordinates">
              >;
              slotMappings: Array<
                { __typename?: "sensorflow_node_to_slot_mapping" } & Pick<
                  Sensorflow_Node_To_Slot_Mapping,
                  "slotName"
                > & {
                    slot?: Maybe<
                      { __typename?: "sensorflow_node_slots" } & Pick<Sensorflow_Node_Slots, "id"> & {
                          mappingCoordinates?: Maybe<
                            { __typename?: "sensorflow_mapping_coordinates" } & Pick<
                              Sensorflow_Mapping_Coordinates,
                              "coordinates"
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
        >;
      }
  >;
  gateways: Array<
    { __typename?: "sensorflow_gateways" } & Pick<Sensorflow_Gateways, "positionId" | "gatewayId" | "gatewayName"> & {
        mappingCoordinates?: Maybe<
          { __typename?: "sensorflow_mapping_coordinates" } & Pick<Sensorflow_Mapping_Coordinates, "coordinates">
        >;
      }
  >;
};

export type SaveMappingCoordinatesMutationVariables = {
  mappingCoordinates: Array<Sensorflow_Mapping_Coordinates_Insert_Input>;
  constraint: Sensorflow_Mapping_Coordinates_Constraint;
};

export type SaveMappingCoordinatesMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_mapping_coordinates?: Maybe<
    { __typename?: "sensorflow_mapping_coordinates_mutation_response" } & Pick<
      Sensorflow_Mapping_Coordinates_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type DeleteMappingCoordinatesMutationVariables = {
  filter: Array<Sensorflow_Mapping_Coordinates_Bool_Exp>;
};

export type DeleteMappingCoordinatesMutation = { __typename?: "mutation_root" } & {
  delete_sensorflow_mapping_coordinates?: Maybe<
    { __typename?: "sensorflow_mapping_coordinates_mutation_response" } & Pick<
      Sensorflow_Mapping_Coordinates_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type GetGatewayAssociatedNodesQueryVariables = {
  gatewayId: Scalars["String"];
};

export type GetGatewayAssociatedNodesQuery = { __typename?: "query_root" } & {
  gatewayToPositionMappings: Array<
    { __typename?: "sensorflow_gateway_to_position_mapping" } & Pick<
      Sensorflow_Gateway_To_Position_Mapping,
      "positionId"
    >
  >;
};

export type GetAllPositionsInLiveEntryModeSubscriptionVariables = {
  gatewayId: Scalars["String"];
};

export type GetAllPositionsInLiveEntryModeSubscription = { __typename?: "subscription_root" } & {
  gatewayToPositionMappings: Array<
    { __typename?: "sensorflow_gateway_to_position_mapping" } & {
      position: { __typename?: "sensorflow_positions" } & {
        positionConfiguration: Array<
          { __typename?: "positionConfigurations" } & Pick<PositionConfigurations, "positionId">
        >;
      };
    }
  >;
};

export type GetAmbientTemperatureAndHumiditySubscriptionVariables = {
  locationId: Scalars["uuid"];
  groupIds: Array<Scalars["uuid"]>;
};

export type GetAmbientTemperatureAndHumiditySubscription = { __typename?: "subscription_root" } & {
  positions: Array<
    { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionName" | "parentPositionId"> & {
        rooms: Array<
          { __typename?: "sensorflow_positions" } & Pick<Sensorflow_Positions, "positionId" | "positionName"> & {
              nodeMeasurements: Array<
                { __typename?: "sensorflow_node_measurement_view" } & Pick<
                  Sensorflow_Node_Measurement_View,
                  "ambientTemperature" | "ambientHumidity"
                >
              >;
            }
        >;
      }
  >;
};

export type GetAverageTemperatureOrHumidityQueryVariables = {
  positionIds?: Maybe<Scalars["_text"]>;
  nmType: Scalars["String"];
  fromDate: Scalars["timestamptz"];
  toDate: Scalars["timestamptz"];
};

export type GetAverageTemperatureOrHumidityQuery = { __typename?: "query_root" } & {
  sensorflow_f_get_avg_position_data: Array<
    { __typename?: "sensorflow_avg_position_data" } & Pick<
      Sensorflow_Avg_Position_Data,
      "position_id" | "avg_data" | "data_type"
    >
  >;
};

export type GetAutosetMinimumSignalQueryVariables = {
  gatewayId: Scalars["String"];
};

export type GetAutosetMinimumSignalQuery = { __typename?: "query_root" } & {
  sensorflow_v_gw_autoset_min_signal: Array<
    { __typename?: "sensorflow_v_gw_autoset_min_signal" } & Pick<
      Sensorflow_V_Gw_Autoset_Min_Signal,
      "balena_id" | "key" | "min_signal_strength" | "position_id" | "position_name"
    >
  >;
};

export type GetLiveAutosetMinimumSignalSubscriptionVariables = {
  gatewayId: Scalars["String"];
};

export type GetLiveAutosetMinimumSignalSubscription = { __typename?: "subscription_root" } & {
  sensorflow_v_gw_autoset_min_signal: Array<
    { __typename?: "sensorflow_v_gw_autoset_min_signal" } & Pick<
      Sensorflow_V_Gw_Autoset_Min_Signal,
      "balena_id" | "key" | "min_signal_strength" | "position_id" | "position_name"
    >
  >;
};

export type UpdateDomoEmbedMutationVariables = {
  object: Sensorflow_Domo_Embeds_Set_Input;
  where: Sensorflow_Domo_Embeds_Bool_Exp;
};

export type UpdateDomoEmbedMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_domo_embeds?: Maybe<
    { __typename: "sensorflow_domo_embeds_mutation_response" } & Pick<
      Sensorflow_Domo_Embeds_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type DeleteDomoEmbedMutationVariables = {
  where: Sensorflow_Domo_Embeds_Bool_Exp;
};

export type DeleteDomoEmbedMutation = { __typename?: "mutation_root" } & {
  delete_sensorflow_domo_embeds?: Maybe<
    { __typename: "sensorflow_domo_embeds_mutation_response" } & Pick<
      Sensorflow_Domo_Embeds_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type AddDomoEmbedMutationVariables = {
  objects: Array<Sensorflow_Domo_Embeds_Insert_Input>;
};

export type AddDomoEmbedMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_domo_embeds?: Maybe<{ __typename: "sensorflow_domo_embeds_mutation_response" }>;
};

export type QueryBeaconSlotsByLocationIdQueryVariables = {
  locationId: Scalars["uuid"];
};

export type QueryBeaconSlotsByLocationIdQuery = { __typename?: "query_root" } & {
  sensorflow_beacon_slots: Array<
    { __typename: "sensorflow_beacon_slots" } & Pick<
      Sensorflow_Beacon_Slots,
      "id" | "enableSlotGap" | "beaconFreqIndex" | "locationId" | "maxSlots" | "startingSlot"
    >
  >;
};

export type UpdateBeaconSlotsMutationVariables = {
  locationId: Scalars["uuid"];
  startingSlot: Scalars["Int"];
  maxSlots: Scalars["Int"];
};

export type UpdateBeaconSlotsMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_beacon_slots?: Maybe<
    { __typename: "sensorflow_beacon_slots_mutation_response" } & Pick<
      Sensorflow_Beacon_Slots_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type SaveLevelsGroupMutationVariables = {
  mapLevelsGroup: Array<Sensorflow_Smplrspace_Levels_Group_Insert_Input>;
  constraint: Sensorflow_Smplrspace_Levels_Group_Constraint;
};

export type SaveLevelsGroupMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_smplrspace_levels_group?: Maybe<
    { __typename?: "sensorflow_smplrspace_levels_group_mutation_response" } & Pick<
      Sensorflow_Smplrspace_Levels_Group_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type DeleteLevelsGroupMutationVariables = {
  filter: Array<Sensorflow_Smplrspace_Levels_Group_Bool_Exp>;
};

export type DeleteLevelsGroupMutation = { __typename?: "mutation_root" } & {
  delete_sensorflow_smplrspace_levels_group?: Maybe<
    { __typename?: "sensorflow_smplrspace_levels_group_mutation_response" } & Pick<
      Sensorflow_Smplrspace_Levels_Group_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type UpdateLevelsGroupMutationVariables = {
  groupName: Scalars["String"];
  levelIndex: Array<Scalars["Int"]>;
  groupId: Scalars["uuid"];
};

export type UpdateLevelsGroupMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_smplrspace_levels_group?: Maybe<
    { __typename?: "sensorflow_smplrspace_levels_group_mutation_response" } & Pick<
      Sensorflow_Smplrspace_Levels_Group_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type GetLevelsGroupQueryVariables = {
  spaceId: Scalars["uuid"];
};

export type GetLevelsGroupQuery = { __typename?: "query_root" } & {
  sensorflow_smplrspace_levels_group: Array<
    { __typename?: "sensorflow_smplrspace_levels_group" } & Pick<
      Sensorflow_Smplrspace_Levels_Group,
      "groupId" | "groupName" | "levelIndex" | "spaceId"
    >
  >;
};

export type SaveFloorsToLevelsMutationVariables = {
  mapFloorsToLevels: Array<Sensorflow_Smplrspace_Floors_To_Levels_Insert_Input>;
  constraint: Sensorflow_Smplrspace_Floors_To_Levels_Constraint;
};

export type SaveFloorsToLevelsMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_smplrspace_floors_to_levels?: Maybe<
    { __typename?: "sensorflow_smplrspace_floors_to_levels_mutation_response" } & Pick<
      Sensorflow_Smplrspace_Floors_To_Levels_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type DeleteFloorsToLevelsMutationVariables = {
  filter: Array<Sensorflow_Smplrspace_Floors_To_Levels_Bool_Exp>;
};

export type DeleteFloorsToLevelsMutation = { __typename?: "mutation_root" } & {
  delete_sensorflow_smplrspace_floors_to_levels?: Maybe<
    { __typename?: "sensorflow_smplrspace_floors_to_levels_mutation_response" } & Pick<
      Sensorflow_Smplrspace_Floors_To_Levels_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type GetFloorsToLevelsQueryVariables = {
  positionIds: Array<Scalars["uuid"]>;
};

export type GetFloorsToLevelsQuery = { __typename?: "query_root" } & {
  sensorflow_smplrspace_floors_to_levels: Array<
    { __typename?: "sensorflow_smplrspace_floors_to_levels" } & Pick<
      Sensorflow_Smplrspace_Floors_To_Levels,
      "group_id" | "position_id"
    > & {
        levelsGroup: { __typename?: "sensorflow_smplrspace_levels_group" } & Pick<
          Sensorflow_Smplrspace_Levels_Group,
          "levelIndex" | "groupName"
        >;
      }
  >;
};

export type UpdateFloorsToLevelsMutationVariables = {
  groupId: Scalars["uuid"];
  positionId: Scalars["uuid"];
};

export type UpdateFloorsToLevelsMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_smplrspace_floors_to_levels?: Maybe<
    { __typename?: "sensorflow_smplrspace_floors_to_levels_mutation_response" } & Pick<
      Sensorflow_Smplrspace_Floors_To_Levels_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type GetDefaultHomepageQueryVariables = {
  locationId: Scalars["uuid"];
  userId: Scalars["String"];
};

export type GetDefaultHomepageQuery = { __typename?: "query_root" } & {
  sensorflow_location_to_user_mapping: Array<
    { __typename?: "sensorflow_location_to_user_mapping" } & Pick<
      Sensorflow_Location_To_User_Mapping,
      "default_homepage"
    >
  >;
};

export type UpdateDefaultHomepageMutationVariables = {
  locationId: Scalars["uuid"];
  userId: Scalars["String"];
  defaultHomepage: Scalars["String"];
};

export type UpdateDefaultHomepageMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_location_to_user_mapping?: Maybe<
    { __typename?: "sensorflow_location_to_user_mapping_mutation_response" } & Pick<
      Sensorflow_Location_To_User_Mapping_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type CreateLocationAddressMutationVariables = {
  locationAddress: Sensorflow_Location_Addresses_Insert_Input;
};

export type CreateLocationAddressMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_location_addresses_one?: Maybe<
    { __typename?: "sensorflow_location_addresses" } & Pick<
      Sensorflow_Location_Addresses,
      "location_id" | "address1" | "address2" | "city" | "zipCode" | "country"
    >
  >;
};

export type CreatePropertyInformationMutationVariables = {
  propertyInfo: Sensorflow_Properties_Information_Insert_Input;
};

export type CreatePropertyInformationMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_properties_information_one?: Maybe<
    { __typename?: "sensorflow_properties_information" } & Pick<
      Sensorflow_Properties_Information,
      | "locationId"
      | "totalSquareFootage"
      | "hospitalityType"
      | "totalGuestRooms"
      | "noOfStudios"
      | "noOf1Bedrooms"
      | "noOf2Bedrooms"
      | "noOf3Bedrooms"
      | "roomsFloorPercentage"
      | "commonAreasFloorPercentage"
      | "restaurantsFloorPercentage"
      | "spasFloorPercentage"
      | "backOfficesFloorPercentage"
      | "otherAreasFloorPercentage"
      | "hvacSystemType"
      | "energyInitiatives"
      | "createdAt"
      | "updated_at"
      | "electricityBillingPeriodEnd"
      | "electricityBillingPeriodStart"
      | "gasBillingPeriodEnd"
      | "gasBillingPeriodStart"
      | "hasEnergyInitiatives"
      | "hasOtherHVACSolutions"
      | "hvacConsumptionPercentage"
      | "hvacSolutions"
      | "wasteBillingPeriodEnd"
      | "wasteBillingPeriodStart"
      | "waterBillingPeriodEnd"
      | "waterBillingPeriodStart"
    >
  >;
};

export type CreateConsumptionRecordsMutationVariables = {
  records: Array<Sensorflow_Location_Consumptions_Insert_Input>;
};

export type CreateConsumptionRecordsMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_location_consumptions?: Maybe<
    { __typename?: "sensorflow_location_consumptions_mutation_response" } & Pick<
      Sensorflow_Location_Consumptions_Mutation_Response,
      "affected_rows"
    > & {
        returning: Array<
          { __typename?: "sensorflow_location_consumptions" } & Pick<
            Sensorflow_Location_Consumptions,
            | "locationId"
            | "consumptionPeriodStart"
            | "consumptionPeriodEnd"
            | "actualValue"
            | "clientForecast"
            | "sensorflowForecast"
            | "metricTypeId"
            | "sourceId"
            | "sourceType"
            | "updatedAt"
          >
        >;
      }
  >;
};

export type UpdateUserProfileMutationVariables = {
  userId: Scalars["String"];
  userInput: Sensorflow_Users_Set_Input;
};

export type UpdateUserProfileMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_users_by_pk?: Maybe<
    { __typename: "sensorflow_users" } & Pick<
      Sensorflow_Users,
      "userId" | "firstName" | "lastName" | "companyRole" | "phoneNumber" | "email" | "name"
    >
  >;
};

export type CreateLocationOccCustomerDataMutationVariables = {
  customerData: Sensorflow_Location_Occ_Customer_Data_Insert_Input;
};

export type CreateLocationOccCustomerDataMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_location_occ_customer_data_one?: Maybe<
    { __typename: "sensorflow_location_occ_customer_data" } & Pick<
      Sensorflow_Location_Occ_Customer_Data,
      "locationId" | "clientForecast" | "occupancy" | "periodStart" | "periodEnd"
    >
  >;
};

export type CreateBulkLocationOccCustomerDataMutationVariables = {
  records: Array<Sensorflow_Location_Occ_Customer_Data_Insert_Input>;
};

export type CreateBulkLocationOccCustomerDataMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_location_occ_customer_data?: Maybe<
    { __typename?: "sensorflow_location_occ_customer_data_mutation_response" } & Pick<
      Sensorflow_Location_Occ_Customer_Data_Mutation_Response,
      "affected_rows"
    > & {
        returning: Array<
          { __typename: "sensorflow_location_occ_customer_data" } & Pick<
            Sensorflow_Location_Occ_Customer_Data,
            "locationId" | "clientForecast" | "occupancy" | "periodStart" | "periodEnd"
          >
        >;
      }
  >;
};

export type UpdateLocationOccCustomerDataMutationVariables = {
  locationId: Scalars["uuid"];
  periodStart: Scalars["date"];
  periodEnd: Scalars["date"];
  clientForecast?: Maybe<Scalars["numeric"]>;
  occupancy?: Maybe<Scalars["numeric"]>;
};

export type UpdateLocationOccCustomerDataMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_location_occ_customer_data_by_pk?: Maybe<
    { __typename: "sensorflow_location_occ_customer_data" } & Pick<
      Sensorflow_Location_Occ_Customer_Data,
      "locationId" | "clientForecast" | "occupancy" | "periodStart" | "periodEnd"
    >
  >;
};

export type CreateSurveyResponseMutationVariables = {
  surveyResponse: Sensorflow_Survey_Responses_Insert_Input;
};

export type CreateSurveyResponseMutation = { __typename?: "mutation_root" } & {
  insert_sensorflow_survey_responses_one?: Maybe<
    { __typename: "sensorflow_survey_responses" } & Pick<
      Sensorflow_Survey_Responses,
      "responseId" | "locationId" | "userId" | "submissionDate" | "surveyResponse" | "consumptionFileURL"
    >
  >;
};

export type GetSurveyResponsesQueryVariables = {
  locationId: Scalars["uuid"];
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type GetSurveyResponsesQuery = { __typename?: "query_root" } & {
  sensorflow_survey_responses: Array<
    { __typename: "sensorflow_survey_responses" } & Pick<
      Sensorflow_Survey_Responses,
      "responseId" | "locationId" | "userId" | "submissionDate" | "surveyResponse" | "consumptionFileURL"
    > & {
        user: { __typename: "sensorflow_users" } & Pick<
          Sensorflow_Users,
          "userId" | "firstName" | "lastName" | "email"
        >;
      }
  >;
};

export type GetLocationConsumptionsQueryVariables = {
  locationId: Scalars["uuid"];
  startDate?: Maybe<Scalars["date"]>;
  endDate?: Maybe<Scalars["date"]>;
  metricTypeIds?: Maybe<Array<Scalars["Int"]>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type GetLocationConsumptionsQuery = { __typename?: "query_root" } & {
  sensorflow_location_consumptions: Array<
    { __typename: "sensorflow_location_consumptions" } & Pick<
      Sensorflow_Location_Consumptions,
      | "locationId"
      | "consumptionPeriodStart"
      | "consumptionPeriodEnd"
      | "actualValue"
      | "clientForecast"
      | "sensorflowForecast"
      | "metricTypeId"
      | "sourceId"
      | "sourceType"
      | "updatedAt"
    >
  >;
};

export type SensorflowLocationConsumptionsQueryVariables = {
  locationId: Scalars["uuid"];
  startDate: Scalars["date"];
  endDate: Scalars["date"];
};

export type SensorflowLocationConsumptionsQuery = { __typename?: "query_root" } & {
  sensorflow_location_consumptions: Array<
    { __typename?: "sensorflow_location_consumptions" } & Pick<
      Sensorflow_Location_Consumptions,
      | "locationId"
      | "metricTypeId"
      | "consumptionPeriodStart"
      | "consumptionPeriodEnd"
      | "actualValue"
      | "clientForecast"
      | "sensorflowForecast"
      | "sourceType"
      | "sourceId"
      | "updatedAt"
    >
  >;
};

export type SensorflowMetricTypesQueryVariables = {};

export type SensorflowMetricTypesQuery = { __typename?: "query_root" } & {
  sensorflow_metric_types: Array<
    { __typename?: "sensorflow_metric_types" } & Pick<Sensorflow_Metric_Types, "metricTypeId" | "metricName">
  >;
};

export type UpdateLocationConsumptionMutationVariables = {
  locationId: Scalars["uuid"];
  metricTypeId: Scalars["Int"];
  consumptionPeriodStart: Scalars["date"];
  consumptionPeriodEnd: Scalars["date"];
  actualValue?: Maybe<Scalars["numeric"]>;
  clientForecast?: Maybe<Scalars["numeric"]>;
};

export type UpdateLocationConsumptionMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_location_consumptions_by_pk?: Maybe<
    { __typename?: "sensorflow_location_consumptions" } & Pick<
      Sensorflow_Location_Consumptions,
      | "locationId"
      | "metricTypeId"
      | "consumptionPeriodStart"
      | "consumptionPeriodEnd"
      | "actualValue"
      | "clientForecast"
      | "updatedAt"
    >
  >;
};

export type UpdateLocationConsumptionWithSourceMutationVariables = {
  locationId: Scalars["uuid"];
  metricTypeId: Scalars["Int"];
  consumptionPeriodStart: Scalars["date"];
  consumptionPeriodEnd: Scalars["date"];
  actualValue?: Maybe<Scalars["numeric"]>;
  clientForecast?: Maybe<Scalars["numeric"]>;
  sourceType: Scalars["String"];
  sourceId: Scalars["String"];
};

export type UpdateLocationConsumptionWithSourceMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_location_consumptions_by_pk?: Maybe<
    { __typename?: "sensorflow_location_consumptions" } & Pick<
      Sensorflow_Location_Consumptions,
      | "locationId"
      | "metricTypeId"
      | "consumptionPeriodStart"
      | "consumptionPeriodEnd"
      | "actualValue"
      | "clientForecast"
      | "sourceType"
      | "sourceId"
      | "updatedAt"
    >
  >;
};

export type GetPropertiesInformationQueryVariables = {
  locationId: Scalars["uuid"];
};

export type GetPropertiesInformationQuery = { __typename?: "query_root" } & {
  sensorflow_properties_information: Array<
    { __typename: "sensorflow_properties_information" } & Pick<
      Sensorflow_Properties_Information,
      | "locationId"
      | "totalSquareFootage"
      | "hospitalityType"
      | "totalGuestRooms"
      | "noOfStudios"
      | "noOf1Bedrooms"
      | "noOf2Bedrooms"
      | "noOf3Bedrooms"
      | "roomsFloorPercentage"
      | "commonAreasFloorPercentage"
      | "restaurantsFloorPercentage"
      | "spasFloorPercentage"
      | "backOfficesFloorPercentage"
      | "otherAreasFloorPercentage"
      | "hvacSystemType"
      | "energyInitiatives"
      | "createdAt"
      | "updated_at"
      | "hasEnergyInitiatives"
      | "hasOtherHVACSolutions"
      | "hvacConsumptionPercentage"
      | "hvacSolutions"
      | "electricityBillingPeriodStart"
      | "electricityBillingPeriodEnd"
      | "gasBillingPeriodStart"
      | "gasBillingPeriodEnd"
      | "waterBillingPeriodStart"
      | "waterBillingPeriodEnd"
      | "wasteBillingPeriodStart"
      | "wasteBillingPeriodEnd"
    >
  >;
};

export type GetLocationOccCustomerDataQueryVariables = {
  locationId: Scalars["uuid"];
  periodStart: Scalars["date"];
  periodEnd: Scalars["date"];
};

export type GetLocationOccCustomerDataQuery = { __typename?: "query_root" } & {
  sensorflow_location_occ_customer_data: Array<
    { __typename: "sensorflow_location_occ_customer_data" } & Pick<
      Sensorflow_Location_Occ_Customer_Data,
      "locationId" | "clientForecast" | "occupancy" | "periodStart" | "periodEnd"
    >
  >;
};

export type DeleteLocationConsumptionByPkMutationVariables = {
  locationId: Scalars["uuid"];
  metricTypeId: Scalars["Int"];
  consumptionPeriodStart: Scalars["date"];
  consumptionPeriodEnd: Scalars["date"];
};

export type DeleteLocationConsumptionByPkMutation = { __typename?: "mutation_root" } & {
  delete_sensorflow_location_consumptions_by_pk?: Maybe<
    { __typename?: "sensorflow_location_consumptions" } & Pick<
      Sensorflow_Location_Consumptions,
      "locationId" | "metricTypeId" | "consumptionPeriodStart" | "consumptionPeriodEnd"
    >
  >;
};

export type UpdatePropertyBillingPeriodsMutationVariables = {
  locationId: Scalars["uuid"];
  electricityBillingPeriodStart?: Maybe<Scalars["Int"]>;
  electricityBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  waterBillingPeriodStart?: Maybe<Scalars["Int"]>;
  waterBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  gasBillingPeriodStart?: Maybe<Scalars["Int"]>;
  gasBillingPeriodEnd?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodStart?: Maybe<Scalars["Int"]>;
  wasteBillingPeriodEnd?: Maybe<Scalars["Int"]>;
};

export type UpdatePropertyBillingPeriodsMutation = { __typename?: "mutation_root" } & {
  update_sensorflow_properties_information?: Maybe<
    { __typename?: "sensorflow_properties_information_mutation_response" } & Pick<
      Sensorflow_Properties_Information_Mutation_Response,
      "affected_rows"
    > & {
        returning: Array<
          { __typename?: "sensorflow_properties_information" } & Pick<
            Sensorflow_Properties_Information,
            | "locationId"
            | "electricityBillingPeriodStart"
            | "electricityBillingPeriodEnd"
            | "waterBillingPeriodStart"
            | "waterBillingPeriodEnd"
            | "gasBillingPeriodStart"
            | "gasBillingPeriodEnd"
            | "wasteBillingPeriodStart"
            | "wasteBillingPeriodEnd"
          >
        >;
      }
  >;
};

export type JobEntryDataSubscriptionVariables = {
  where?: Maybe<Sensorflow_V_Key_Position_Latest_Entry_Bool_Exp>;
};

export type JobEntryDataSubscription = { __typename?: "subscription_root" } & {
  jobEntries: Array<
    { __typename?: "sensorflow_v_key_position_latest_entry" } & Pick<
      Sensorflow_V_Key_Position_Latest_Entry,
      "keyPositionId" | "positionFunction" | "status" | "comment" | "commentStatus" | "startedAt" | "endedAt"
    > & { user?: Maybe<{ __typename?: "sensorflow_users" } & Pick<Sensorflow_Users, "userId" | "name">> }
  >;
};

export type GetPmsReservationsQueryVariables = {
  where?: Maybe<Sf_Pms_Pms_Reservations_Bool_Exp>;
  orderBy?: Maybe<Array<Sf_Pms_Pms_Reservations_Order_By>>;
  limit?: Scalars["Int"];
  offset?: Scalars["Int"];
};

export type GetPmsReservationsQuery = { __typename?: "query_root" } & {
  reservations: Array<
    { __typename?: "sf_pms_pms_reservations" } & Pick<Sf_Pms_Pms_Reservations, "status"> & {
        id: Sf_Pms_Pms_Reservations["reservation_id"];
        checkInAt: Sf_Pms_Pms_Reservations["check_in_date"];
        checkOutAt: Sf_Pms_Pms_Reservations["check_out_date"];
        lastUpdatedAt: Sf_Pms_Pms_Reservations["modified_date"];
        guestId: Sf_Pms_Pms_Reservations["guest_id"];
      } & {
        category?: Maybe<
          { __typename?: "sf_pms_pms_room_categories" } & {
            name: Sf_Pms_Pms_Room_Categories["room_category_name"];
            id: Sf_Pms_Pms_Room_Categories["room_category_id"];
          }
        >;
        recommendedRoom?: Maybe<
          { __typename?: "sf_pms_pms_rooms" } & { name: Sf_Pms_Pms_Rooms["room_name"]; id: Sf_Pms_Pms_Rooms["room_id"] }
        >;
        allocatedRoom?: Maybe<
          { __typename?: "sf_pms_pms_rooms" } & { name: Sf_Pms_Pms_Rooms["room_name"]; id: Sf_Pms_Pms_Rooms["room_id"] }
        >;
      }
  >;
  aggregation: { __typename?: "sf_pms_pms_reservations_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "sf_pms_pms_reservations_aggregate_fields" } & Pick<
        Sf_Pms_Pms_Reservations_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type GetPmsPropertyByLocationQueryVariables = {
  locationId: Scalars["uuid"];
};

export type GetPmsPropertyByLocationQuery = { __typename?: "query_root" } & {
  property: Array<
    { __typename?: "sf_pms_pms_properties" } & {
      pmsId: Sf_Pms_Pms_Properties["pms_id"];
      propertyId: Sf_Pms_Pms_Properties["property_id"];
      locationId: Sf_Pms_Pms_Properties["location_id"];
      isReady: Sf_Pms_Pms_Properties["is_ready"];
    }
  >;
};

export type GetPmsRoomCategoriesQueryVariables = {
  where?: Maybe<Sf_Pms_Pms_Room_Categories_Bool_Exp>;
  orderBy?: Maybe<Array<Sf_Pms_Pms_Room_Categories_Order_By>>;
  limit?: Scalars["Int"];
  offset?: Scalars["Int"];
};

export type GetPmsRoomCategoriesQuery = { __typename?: "query_root" } & {
  categories: Array<
    { __typename?: "sf_pms_pms_room_categories" } & {
      id: Sf_Pms_Pms_Room_Categories["room_category_id"];
      name: Sf_Pms_Pms_Room_Categories["room_category_name"];
    }
  >;
};

export type GetSmartAllocReportQueryVariables = {
  where?: Maybe<Sf_Pms_Smartalloc_Report_Bool_Exp>;
};

export type GetSmartAllocReportQuery = { __typename?: "query_root" } & {
  sf_pms_smartalloc_report: Array<
    { __typename?: "sf_pms_smartalloc_report" } & Pick<
      Sf_Pms_Smartalloc_Report,
      | "averageDailyRunningCompressors"
      | "averageOccupancy"
      | "billingCurrency"
      | "followedRecommendationRatio"
      | "locationId"
      | "ninetyAdherenceSavingsKwh"
      | "ninetyAdherenceSavingsSgd"
      | "ninetyAdherenceSavingsLocalCurrency"
      | "numberOfFollowedRecommendation"
      | "numberOfRecommendation"
      | "numberOfReservations"
      | "savingsKwh"
      | "savingsLocalCurrency"
      | "savingsPercentage"
      | "savingsSgd"
      | "simulatedNoSmartallocConsumptionKwh"
      | "simulatedNoSmartallocDailyCompressors"
      | "totalConsumptionKwh"
      | "date"
      | "type"
    >
  >;
};

export type PmsPropertyEodSurveysSubscriptionVariables = {
  pmsId: Scalars["Int"];
  propertyId: Scalars["String"];
  offset: Scalars["Int"];
  limit: Scalars["Int"];
};

export type PmsPropertyEodSurveysSubscription = { __typename?: "subscription_root" } & {
  surveys: Array<
    { __typename?: "sf_pms_pms_property_eod_surveys" } & Pick<Sf_Pms_Pms_Property_Eod_Surveys, "data" | "status"> & {
        createdAt: Sf_Pms_Pms_Property_Eod_Surveys["created_at"];
        pmsId: Sf_Pms_Pms_Property_Eod_Surveys["pms_id"];
        propertyId: Sf_Pms_Pms_Property_Eod_Surveys["property_id"];
        surveyMonkeyId: Sf_Pms_Pms_Property_Eod_Surveys["survey_monkey_id"];
      }
  >;
};

export type UserWithAuthTokenQueryVariables = {};

export type UserWithAuthTokenQuery = { __typename?: "query_root" } & {
  userWithAuthToken?: Maybe<
    { __typename?: "User" } & Pick<
      User,
      "userId" | "name" | "email" | "status" | "emailVerified" | "lastLogin" | "isContractor" | "isManager" | "isDev"
    > & {
        role: { __typename?: "Role" } & Pick<Role, "id" | "name" | "defaultRoute"> & {
            permissions: Array<
              { __typename?: "Permission" } & Pick<Permission, "id" | "permissionKey" | "displayName">
            >;
          };
      }
  >;
};

export type LocationsAssignedToUserQueryVariables = {
  filter?: Maybe<LocationFilterInput>;
  sort?: Maybe<LocationSortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type LocationsAssignedToUserQuery = { __typename?: "query_root" } & {
  locationsAssignedToUser: { __typename?: "LocationsResponse" } & {
    locations: Array<
      { __typename?: "Location" } & Pick<Location, "id" | "locationName" | "locationMode" | "timezone"> & {
          organisation: { __typename?: "Organisation" } & Pick<Organisation, "id" | "name">;
        }
    >;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type LocationsQueryVariables = {
  filter?: Maybe<LocationFilterInput>;
  sort?: Maybe<LocationSortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type LocationsQuery = { __typename?: "query_root" } & {
  locationsAssignedToUser: { __typename?: "LocationsResponse" } & {
    locations: Array<
      { __typename?: "Location" } & Pick<Location, "id" | "locationName" | "currency"> & {
          locationStats: { __typename?: "LocationStats" } & Pick<LocationStats, "mappingStatus"> & {
              keysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
              gatewaysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
            };
        }
    >;
    filterOptions: Array<{ __typename?: "FilterOption" } & Pick<FilterOption, "field" | "value" | "label" | "count">>;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type LocationsForCustomerQueryVariables = {
  filter?: Maybe<LocationFilterInput>;
  sort?: Maybe<LocationSortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type LocationsForCustomerQuery = { __typename?: "query_root" } & {
  locationsAssignedToUser: { __typename?: "LocationsResponse" } & {
    locations: Array<
      { __typename?: "Location" } & Pick<Location, "id" | "locationName"> & {
          locationMetadata?: Maybe<
            { __typename?: "LocationMetadata" } & Pick<LocationMetadata, "subscription" | "showEnergyConsumption">
          >;
        }
    >;
    filterOptions: Array<{ __typename?: "FilterOption" } & Pick<FilterOption, "field" | "value" | "label" | "count">>;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type LocationsWithNodeHealthQueryVariables = {
  filter?: Maybe<LocationFilterInput>;
  sort?: Maybe<LocationSortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type LocationsWithNodeHealthQuery = { __typename?: "query_root" } & {
  locationsAssignedToUser: { __typename?: "LocationsResponse" } & {
    locations: Array<
      { __typename?: "Location" } & Pick<Location, "id" | "locationName" | "currency"> & {
          locationStats: { __typename?: "LocationStats" } & Pick<LocationStats, "mappingStatus"> & {
              keysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
              gatewaysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
              nodeHealthStats: { __typename?: "NodeHealthStats" } & Pick<
                NodeHealthStats,
                "totalCount" | "onlineCount" | "bootedCount" | "offlineCount"
              >;
            };
        }
    >;
    filterOptions: Array<{ __typename?: "FilterOption" } & Pick<FilterOption, "field" | "value" | "label" | "count">>;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type LocationsWithNodeHealthAndTasksQueryVariables = {
  filter?: Maybe<LocationFilterInput>;
  sort?: Maybe<LocationSortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type LocationsWithNodeHealthAndTasksQuery = { __typename?: "query_root" } & {
  locationsAssignedToUser: { __typename?: "LocationsResponse" } & {
    locations: Array<
      { __typename?: "Location" } & Pick<Location, "id" | "locationName" | "currency"> & {
          locationStats: { __typename?: "LocationStats" } & Pick<LocationStats, "mappingStatus"> & {
              keysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
              gatewaysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
              nodeHealthStats: { __typename?: "NodeHealthStats" } & Pick<
                NodeHealthStats,
                "totalCount" | "onlineCount" | "bootedCount" | "offlineCount"
              >;
              tasksStats: { __typename?: "TasksStats" } & Pick<
                TasksStats,
                "totalCount" | "pendingCount" | "inProgressCount" | "doneCount"
              >;
            };
        }
    >;
    filterOptions: Array<{ __typename?: "FilterOption" } & Pick<FilterOption, "field" | "value" | "label" | "count">>;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type LocationNodeHealthQueryVariables = {
  locationId: Scalars["String"];
};

export type LocationNodeHealthQuery = { __typename?: "query_root" } & {
  location?: Maybe<
    { __typename?: "Location" } & Pick<Location, "id"> & {
        locationStats: { __typename?: "LocationStats" } & {
          nodeHealthStats: { __typename?: "NodeHealthStats" } & Pick<
            NodeHealthStats,
            "totalCount" | "onlineCount" | "bootedCount" | "offlineCount"
          >;
          nodeHealthOverallStats: Array<
            { __typename: "NodeHealthOverallStats" } & Pick<NodeHealthOverallStats, "nodeType"> & {
                nodeHealthStats: { __typename: "NodeHealthStats" } & Pick<
                  NodeHealthStats,
                  "totalCount" | "onlineCount" | "offlineCount" | "bootedCount"
                >;
              }
          >;
        };
      }
  >;
};

export type KeysQueryVariables = {
  filter: KeyFilterInput;
  sort?: Maybe<KeySortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type KeysQuery = { __typename?: "query_root" } & {
  keys: { __typename?: "KeysResponse" } & {
    keys: Array<
      { __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName"> & {
          group?: Maybe<{ __typename?: "Group" } & Pick<Group, "groupId" | "groupName">>;
          keyStats: { __typename?: "KeyMappingStats" } & Pick<KeyMappingStats, "mappingStatus"> & {
              nodesStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
              roomsStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
            };
        }
    >;
    filterOptions: Array<{ __typename?: "FilterOption" } & Pick<FilterOption, "field" | "value" | "label" | "count">>;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type KeysWithNodeHealthQueryVariables = {
  filter: KeyFilterInput;
  sort?: Maybe<KeySortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type KeysWithNodeHealthQuery = { __typename?: "query_root" } & {
  keys: { __typename?: "KeysResponse" } & {
    keys: Array<
      { __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName"> & {
          group?: Maybe<{ __typename?: "Group" } & Pick<Group, "groupName" | "groupId">>;
          keyStats: { __typename?: "KeyMappingStats" } & Pick<KeyMappingStats, "mappingStatus"> & {
              nodesStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
              roomsStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
              nodeHealthStats: { __typename?: "NodeHealthStats" } & Pick<
                NodeHealthStats,
                "totalCount" | "onlineCount" | "bootedCount" | "offlineCount"
              >;
            };
        }
    >;
    filterOptions: Array<{ __typename?: "FilterOption" } & Pick<FilterOption, "field" | "value" | "label" | "count">>;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type JobEntryQueryVariables = {
  filter: KeyTaskFilterInput;
  pagination?: Maybe<GenericPaginationInput>;
};

export type JobEntryQuery = { __typename?: "query_root" } & {
  keysTasks: { __typename?: "KeysResponse" } & {
    keys: Array<
      { __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName" | "possitionType" | "taskDueDate"> & {
          group?: Maybe<{ __typename?: "Group" } & Pick<Group, "groupId" | "groupName" | "groupType">>;
          keyStats: { __typename?: "KeyMappingStats" } & Pick<KeyMappingStats, "mappingStatus"> & {
              nodesStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
              roomsStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
              nodeHealthStats: { __typename?: "NodeHealthStats" } & Pick<
                NodeHealthStats,
                "totalCount" | "onlineCount" | "bootedCount" | "offlineCount"
              >;
              tasksStats: { __typename?: "TasksStats" } & Pick<
                TasksStats,
                "totalCount" | "pendingCount" | "inProgressCount" | "doneCount"
              >;
            };
        }
    >;
    filterOptions: Array<{ __typename?: "FilterOption" } & Pick<FilterOption, "field" | "value" | "label" | "count">>;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type SimpleKeyListQueryVariables = {
  filter: KeyFilterInput;
  sort?: Maybe<KeySortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type SimpleKeyListQuery = { __typename?: "query_root" } & {
  keys: { __typename?: "KeysResponse" } & {
    keys: Array<
      { __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName"> & {
          group?: Maybe<{ __typename?: "Group" } & Pick<Group, "groupId" | "groupName">>;
          keyStats: { __typename?: "KeyMappingStats" } & {
            nodesStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount">;
            roomsStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount">;
          };
        }
    >;
    filterOptions: Array<{ __typename?: "FilterOption" } & Pick<FilterOption, "field" | "value" | "label" | "count">>;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type KeyQueryVariables = {
  keyId: Scalars["String"];
};

export type KeyQuery = { __typename?: "query_root" } & {
  key: { __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName"> & {
      keyStats: { __typename?: "KeyMappingStats" } & Pick<KeyMappingStats, "mappingStatus"> & {
          nodesStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
        };
      rooms: Array<
        { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
            positionConfiguration?: Maybe<
              { __typename?: "PositionConfiguration" } & Pick<
                PositionConfiguration,
                "id" | "acModelId" | "actingMode" | "operationalMode"
              >
            >;
            slots: Array<
              { __typename?: "Slot" } & Pick<
                Slot,
                "id" | "slotName" | "nodeType" | "nodeSubType" | "nodeTypeCodeIdentifier"
              > & {
                  currentMappedNode?: Maybe<
                    { __typename?: "MappedNode" } & {
                      mappedNode: { __typename?: "Node" } & Pick<Node, "nodeMacId" | "type" | "subType">;
                    }
                  >;
                }
            >;
          }
      >;
    };
};

export type LabelPrintingKeyRoomsQueryVariables = {
  keyId: Scalars["String"];
};

export type LabelPrintingKeyRoomsQuery = { __typename?: "query_root" } & {
  key: { __typename?: "Key" } & Pick<Key, "keyId" | "keyName"> & {
      rooms: Array<{ __typename?: "Room" } & Pick<Room, "roomId" | "roomName">>;
    };
};

export type LabelDetailsForKeyQueryVariables = {
  keyId: Scalars["String"];
};

export type LabelDetailsForKeyQuery = { __typename?: "query_root" } & {
  key: { __typename?: "Key" } & Pick<Key, "keyId" | "keyName"> & {
      rooms: Array<
        { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
            slots: Array<
              { __typename?: "Slot" } & Pick<
                Slot,
                "slotName" | "nodeType" | "nodeSubType" | "nodeTypeCodeIdentifier"
              > & {
                  currentMappedNode?: Maybe<
                    { __typename?: "MappedNode" } & { mappedNode: { __typename?: "Node" } & Pick<Node, "nodeMacId"> }
                  >;
                }
            >;
          }
      >;
    };
};

export type LabelDetailsForRoomQueryVariables = {
  roomId: Scalars["String"];
};

export type LabelDetailsForRoomQuery = { __typename?: "query_root" } & {
  room: { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
      slots: Array<
        { __typename?: "Slot" } & Pick<Slot, "slotName" | "nodeType" | "nodeSubType" | "nodeTypeCodeIdentifier"> & {
            currentMappedNode?: Maybe<
              { __typename?: "MappedNode" } & { mappedNode: { __typename?: "Node" } & Pick<Node, "nodeMacId"> }
            >;
          }
      >;
    };
};

export type LocationWithKeysQueryVariables = {
  locationId: Scalars["String"];
  sort?: Maybe<KeySortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type LocationWithKeysQuery = { __typename?: "query_root" } & {
  keysInLocation: { __typename?: "Location" } & Pick<Location, "id" | "locationName"> & {
      locationStats: { __typename?: "LocationStats" } & Pick<LocationStats, "mappingStatus"> & {
          keysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
        };
      keysResponse: { __typename?: "KeysResponse" } & {
        keys: Array<{ __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName">>;
        pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
      };
    };
};

export type UserQueryVariables = {
  userId: Scalars["String"];
};

export type UserQuery = { __typename?: "query_root" } & {
  user: { __typename?: "User" } & Pick<
    User,
    "userId" | "name" | "email" | "status" | "emailVerified" | "lastLogin" | "isManager" | "isContractor" | "isDev"
  > & {
      organisation: { __typename?: "Organisation" } & Pick<Organisation, "id" | "name">;
      locations: Array<{ __typename?: "Location" } & Pick<Location, "id" | "locationName">>;
      role: { __typename?: "Role" } & Pick<Role, "id" | "name">;
    };
};

export type UsersQueryVariables = {
  filter?: Maybe<UserFilterInput>;
  sort?: Maybe<UserSortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type UsersQuery = { __typename?: "query_root" } & {
  users: { __typename?: "UsersResponse" } & {
    users: Array<
      { __typename?: "User" } & Pick<User, "userId" | "name" | "email" | "status" | "emailVerified" | "lastLogin"> & {
          role: { __typename?: "Role" } & Pick<Role, "id" | "name">;
          locations: Array<{ __typename?: "Location" } & Pick<Location, "locationName">>;
        }
    >;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type RolesQueryVariables = {};

export type RolesQuery = { __typename?: "query_root" } & {
  roles: Array<{ __typename?: "Role" } & Pick<Role, "id" | "name" | "defaultRoute">>;
};

export type RolesWithStatsQueryVariables = {};

export type RolesWithStatsQuery = { __typename?: "query_root" } & {
  roles: Array<{ __typename?: "Role" } & Pick<Role, "id" | "name" | "userCount">>;
};

export type RapidMappingQueryVariables = {
  locationId: Scalars["String"];
};

export type RapidMappingQuery = { __typename?: "query_root" } & {
  rapidMapping: { __typename?: "NodeMappingResponse" } & Pick<NodeMappingResponse, "locationName"> & {
      keysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
      currentRapidMappingKey?: Maybe<
        { __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName"> & {
            currentRapidMappingRoom?: Maybe<
              { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
                  slots: Array<
                    { __typename?: "Slot" } & Pick<
                      Slot,
                      | "nodeType"
                      | "nodeSubType"
                      | "nodeTypeCodeIdentifier"
                      | "slotName"
                      | "isCurrentRapidMappingSlot"
                      | "mappingStatus"
                    >
                  >;
                }
            >;
          }
      >;
      nextRapidMappingKey?: Maybe<
        { __typename?: "Key" } & Pick<Key, "keyId" | "keyName"> & {
            nextRapidMappingRoom?: Maybe<
              { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
                  slots: Array<
                    { __typename?: "Slot" } & Pick<
                      Slot,
                      "nodeType" | "nodeSubType" | "nodeTypeCodeIdentifier" | "slotName"
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type SkipRapidMappingMutationVariables = {
  roomId: Scalars["ID"];
  slotName: Scalars["String"];
  nodeType: NodeType;
};

export type SkipRapidMappingMutation = { __typename?: "mutation_root" } & {
  skipRapidMapping: { __typename?: "NodeMappingResponse" } & Pick<NodeMappingResponse, "locationName"> & {
      keysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
      currentRapidMappingKey?: Maybe<
        { __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName"> & {
            currentRapidMappingRoom?: Maybe<
              { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
                  slots: Array<
                    { __typename?: "Slot" } & Pick<
                      Slot,
                      | "nodeType"
                      | "nodeSubType"
                      | "nodeTypeCodeIdentifier"
                      | "slotName"
                      | "isCurrentRapidMappingSlot"
                      | "mappingStatus"
                    >
                  >;
                }
            >;
          }
      >;
      nextRapidMappingKey?: Maybe<
        { __typename?: "Key" } & Pick<Key, "keyId" | "keyName"> & {
            nextRapidMappingRoom?: Maybe<
              { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
                  slots: Array<
                    { __typename?: "Slot" } & Pick<
                      Slot,
                      "nodeType" | "nodeSubType" | "nodeTypeCodeIdentifier" | "slotName"
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type RapidMapMutationVariables = {
  roomId: Scalars["ID"];
  slotName: Scalars["String"];
  nodeType: NodeType;
  nodeMacInput: Scalars["String"];
  nodeSubType?: Maybe<Scalars["String"]>;
};

export type RapidMapMutation = { __typename?: "mutation_root" } & {
  mapNode: { __typename?: "NodeMappingResponse" } & Pick<NodeMappingResponse, "locationName"> & {
      keysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
      currentRapidMappingKey?: Maybe<
        { __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName"> & {
            currentRapidMappingRoom?: Maybe<
              { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
                  slots: Array<
                    { __typename?: "Slot" } & Pick<
                      Slot,
                      | "nodeType"
                      | "nodeSubType"
                      | "nodeTypeCodeIdentifier"
                      | "slotName"
                      | "isCurrentRapidMappingSlot"
                      | "mappingStatus"
                    >
                  >;
                }
            >;
          }
      >;
      nextRapidMappingKey?: Maybe<
        { __typename?: "Key" } & Pick<Key, "keyId" | "keyName"> & {
            nextRapidMappingRoom?: Maybe<
              { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
                  slots: Array<
                    { __typename?: "Slot" } & Pick<
                      Slot,
                      "nodeType" | "nodeSubType" | "nodeTypeCodeIdentifier" | "slotName"
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type SingleMapNodeMutationVariables = {
  roomId: Scalars["ID"];
  slotName: Scalars["String"];
  nodeType: NodeType;
  nodeMacInput: Scalars["String"];
  nodeSubType?: Maybe<Scalars["String"]>;
};

export type SingleMapNodeMutation = { __typename?: "mutation_root" } & {
  mapNode: { __typename?: "NodeMappingResponse" } & {
    currentKey?: Maybe<
      { __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName"> & {
          keyStats: { __typename?: "KeyMappingStats" } & Pick<KeyMappingStats, "mappingStatus"> & {
              nodesStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
            };
          rooms: Array<
            { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
                slots: Array<
                  { __typename?: "Slot" } & Pick<
                    Slot,
                    "nodeType" | "nodeSubType" | "nodeTypeCodeIdentifier" | "slotName" | "mappingStatus"
                  > & {
                      currentMappedNode?: Maybe<
                        { __typename?: "MappedNode" } & {
                          mappedNode: { __typename?: "Node" } & Pick<Node, "nodeMacId" | "subType" | "type">;
                        }
                      >;
                    }
                >;
              }
          >;
        }
    >;
  };
};

export type UnmapNodeMutationVariables = {
  roomId: Scalars["ID"];
  slotName: Scalars["String"];
  nodeType: NodeType;
  nodeMacInput: Scalars["String"];
  scannedMacId?: Maybe<Scalars["String"]>;
  rootCauseId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
};

export type UnmapNodeMutation = { __typename?: "mutation_root" } & {
  unmapNode: { __typename?: "NodeMappingResponse" } & {
    currentKey?: Maybe<
      { __typename?: "Key" } & Pick<Key, "keyId" | "keyName" | "categoryName"> & {
          keyStats: { __typename?: "KeyMappingStats" } & Pick<KeyMappingStats, "mappingStatus"> & {
              nodesStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
            };
          rooms: Array<
            { __typename?: "Room" } & Pick<Room, "roomId" | "roomName"> & {
                slots: Array<
                  { __typename?: "Slot" } & Pick<
                    Slot,
                    "nodeType" | "nodeSubType" | "nodeTypeCodeIdentifier" | "slotName" | "mappingStatus"
                  >
                >;
              }
          >;
        }
    >;
  };
};

export type ValidateInfraNodeMappingQueryVariables = {
  infraId: Scalars["ID"];
  slotName: Scalars["String"];
  nodeType: NodeType;
  nodeMacInput: Scalars["String"];
  nodeSubType?: Maybe<Scalars["String"]>;
};

export type ValidateInfraNodeMappingQuery = { __typename?: "query_root" } & Pick<
  Query_Root,
  "validateInfraNodeMapping"
>;

export type ValidateNodeBeforeMappingToSlotQueryVariables = {
  roomId: Scalars["ID"];
  slotName: Scalars["String"];
  nodeType: NodeType;
  nodeMacInput: Scalars["String"];
  nodeSubType?: Maybe<Scalars["String"]>;
};

export type ValidateNodeBeforeMappingToSlotQuery = { __typename?: "query_root" } & Pick<
  Query_Root,
  "validateNodeBeforeMappingToSlot"
>;

export type MapInfraNodeMutationVariables = {
  infraId: Scalars["ID"];
  slotName: Scalars["String"];
  nodeType: NodeType;
  nodeMacInput: Scalars["String"];
  emonSetting?: Maybe<Scalars["Int"]>;
  mapInput?: Maybe<Scalars["Int"]>;
  nodeSubType?: Maybe<Scalars["String"]>;
};

export type MapInfraNodeMutation = { __typename?: "mutation_root" } & {
  mapInfraNode: { __typename?: "MappedNode" } & Pick<MappedNode, "nodeType" | "slotName"> & {
      mappedNode: { __typename?: "Node" } & Pick<Node, "nodeMacId">;
    };
};

export type UnmapInfraNodeMutationVariables = {
  infraId: Scalars["ID"];
  nodeMacId: Scalars["String"];
  scannedMacId?: Maybe<Scalars["String"]>;
  rootCauseId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
};

export type UnmapInfraNodeMutation = { __typename?: "mutation_root" } & {
  unmapInfraNode: { __typename?: "MappedNode" } & Pick<MappedNode, "nodeType" | "slotName">;
};

export type UpdateInfraNodeMutationVariables = {
  infraId: Scalars["ID"];
  nodeMacId: Scalars["String"];
  slotName: Scalars["String"];
  emonSetting?: Maybe<Scalars["Int"]>;
  mapInput?: Maybe<Scalars["Int"]>;
};

export type UpdateInfraNodeMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "updateInfraNode">;

export type ChangeBatteryMutationVariables = {
  nodeMacId: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
};

export type ChangeBatteryMutation = { __typename?: "mutation_root" } & {
  changeBattery: { __typename?: "ChangeBatteryReponse" } & Pick<
    ChangeBatteryReponse,
    "id" | "nodeMacId" | "creationDate"
  >;
};

export type EmonMapInputInfoQueryVariables = {
  infraId: Scalars["ID"];
  nodeMacId: Scalars["String"];
};

export type EmonMapInputInfoQuery = { __typename?: "query_root" } & {
  emonMapInputInfo: { __typename?: "EmonMapInputInfo" } & Pick<
    EmonMapInputInfo,
    "currentMapInput" | "unavailableMapInputs"
  >;
};

export type UserCreateMutationVariables = {
  email: Scalars["String"];
  user: UserInput;
};

export type UserCreateMutation = { __typename?: "mutation_root" } & {
  userCreate: { __typename?: "User" } & Pick<
    User,
    "userId" | "name" | "email" | "status" | "emailVerified" | "lastLogin" | "isManager" | "isContractor"
  > & {
      role: { __typename?: "Role" } & Pick<Role, "id" | "name">;
      organisation: { __typename?: "Organisation" } & Pick<Organisation, "id" | "name">;
      locations: Array<{ __typename?: "Location" } & Pick<Location, "id" | "locationName">>;
    };
};

export type UserUpdateMutationVariables = {
  userId: Scalars["String"];
  user: UserInput;
};

export type UserUpdateMutation = { __typename?: "mutation_root" } & {
  userUpdate: { __typename?: "User" } & Pick<
    User,
    "userId" | "name" | "email" | "status" | "emailVerified" | "lastLogin" | "isManager" | "isContractor"
  > & {
      role: { __typename?: "Role" } & Pick<Role, "id" | "name">;
      organisation: { __typename?: "Organisation" } & Pick<Organisation, "id" | "name">;
      locations: Array<{ __typename?: "Location" } & Pick<Location, "id" | "locationName">>;
    };
};

export type UserResendWelcomeEmailMutationVariables = {
  userId: Scalars["String"];
};

export type UserResendWelcomeEmailMutation = { __typename?: "mutation_root" } & {
  userResendWelcomeEmail: { __typename?: "GenericResponse" } & Pick<GenericResponse, "message">;
};

export type UserResetPasswordMutationVariables = {
  email: Scalars["String"];
};

export type UserResetPasswordMutation = { __typename?: "mutation_root" } & {
  userResetPassword: { __typename?: "GenericResponse" } & Pick<GenericResponse, "message">;
};

export type UserDisableMutationVariables = {
  userId: Scalars["String"];
};

export type UserDisableMutation = { __typename?: "mutation_root" } & {
  userDisable: { __typename?: "GenericResponse" } & Pick<GenericResponse, "message">;
};

export type LocationQueryVariables = {
  locationId: Scalars["String"];
};

export type LocationQuery = { __typename?: "query_root" } & {
  location?: Maybe<
    { __typename?: "Location" } & Pick<
      Location,
      | "id"
      | "locationName"
      | "locationMode"
      | "timezone"
      | "ACModelId"
      | "currency"
      | "language"
      | "clickupExportDisabled"
      | "beingExportedToClickup"
      | "clickupListId"
      | "enabledFeatures"
    > & { organisation: { __typename?: "Organisation" } & Pick<Organisation, "id" | "name"> }
  >;
};

export type LocationKeyStatsQueryVariables = {
  locationId: Scalars["String"];
};

export type LocationKeyStatsQuery = { __typename?: "query_root" } & {
  location?: Maybe<
    { __typename?: "Location" } & Pick<Location, "id" | "locationName"> & {
        locationStats: { __typename?: "LocationStats" } & {
          keysStats: { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">;
          nodeOverallStats: Array<
            { __typename?: "NodeTypeStats" } & Pick<NodeTypeStats, "nodeType"> & {
                mappingStats?: Maybe<
                  { __typename?: "MappingStats" } & Pick<MappingStats, "totalCount" | "mappedCount">
                >;
              }
          >;
        };
      }
  >;
};

export type LocationsForAutocompleteQueryVariables = {
  filter?: Maybe<LocationFilterInput>;
};

export type LocationsForAutocompleteQuery = { __typename?: "query_root" } & {
  locations: { __typename?: "LocationsResponse" } & {
    locations: Array<{ __typename?: "Location" } & Pick<Location, "id" | "locationName" | "locationMode">>;
  };
};

export type OrganisationsQueryVariables = {
  filter?: Maybe<OrganisationFilterInput>;
  sort?: Maybe<OrganisationSortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type OrganisationsQuery = { __typename?: "query_root" } & {
  organisations: { __typename?: "OrganisationsResponse" } & {
    organisations: Array<{ __typename?: "Organisation" } & Pick<Organisation, "id" | "name">>;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
  };
};

export type OrganisationsForAutocompleteQueryVariables = {
  filter?: Maybe<OrganisationFilterInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type OrganisationsForAutocompleteQuery = { __typename?: "query_root" } & {
  organisations: { __typename?: "OrganisationsResponse" } & {
    organisations: Array<{ __typename?: "Organisation" } & Pick<Organisation, "id" | "name">>;
  };
};

export type OrganisationQueryVariables = {
  organisationId: Scalars["String"];
};

export type OrganisationQuery = { __typename?: "query_root" } & {
  organisation: { __typename?: "Organisation" } & Pick<Organisation, "id" | "name">;
};

export type AcModelsQueryVariables = {
  filter?: Maybe<AcModelFilterInput>;
  sort?: Maybe<AcModelSortInput>;
  pagination?: Maybe<GenericPaginationInput>;
};

export type AcModelsQuery = { __typename?: "query_root" } & {
  ACModels?: Maybe<
    { __typename?: "ACModelsResponse" } & {
      ACModels: Array<
        { __typename?: "ACModel" } & Pick<AcModel, "id" | "brandName" | "modelName" | "status" | "identifier">
      >;
      pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "limit" | "offset" | "count">;
    }
  >;
};

export type AcModelQueryVariables = {
  ACModelId: Scalars["String"];
};

export type AcModelQuery = { __typename?: "query_root" } & {
  ACModel: { __typename?: "ACModel" } & Pick<AcModel, "id" | "brandName" | "modelName" | "status">;
};

export type GatewayImageDownloadUrlQueryVariables = {
  gatewayId: Scalars["String"];
};

export type GatewayImageDownloadUrlQuery = { __typename?: "query_root" } & Pick<Query_Root, "gatewayImageDownloadUrl">;

export type AcModelCreateMutationVariables = {
  ACModel: AcModelInput;
};

export type AcModelCreateMutation = { __typename?: "mutation_root" } & {
  ACModelCreate: { __typename?: "ACModel" } & Pick<
    AcModel,
    "id" | "brandName" | "modelName" | "identifier" | "description"
  >;
};

export type AcModelUpdateMutationVariables = {
  ACModelId: Scalars["String"];
  ACModel: AcModelInput;
};

export type AcModelUpdateMutation = { __typename?: "mutation_root" } & {
  ACModelUpdate: { __typename?: "ACModel" } & Pick<
    AcModel,
    "id" | "brandName" | "modelName" | "identifier" | "description"
  >;
};

export type AcModelDisableMutationVariables = {
  ACModelId: Scalars["String"];
};

export type AcModelDisableMutation = { __typename?: "mutation_root" } & {
  ACModelDisable: { __typename?: "GenericResponse" } & Pick<GenericResponse, "message">;
};

export type GatewaysCreateMutationVariables = {
  gateways: Array<GatewaysCreateInput>;
};

export type GatewaysCreateMutation = { __typename?: "mutation_root" } & {
  gatewaysCreate: Array<
    { __typename?: "Gateway" } & Pick<Gateway, "gatewayId" | "gatewayName" | "gatewayMac" | "locationId">
  >;
};

export type DeleteGatewayMutationVariables = {
  gatewayId: Scalars["ID"];
};

export type DeleteGatewayMutation = { __typename?: "mutation_root" } & {
  deleteGateway: { __typename?: "Gateway" } & Pick<Gateway, "gatewayId">;
};

export type RenameGatewayMutationVariables = {
  gatewayId: Scalars["ID"];
  gatewayName: Scalars["String"];
};

export type RenameGatewayMutation = { __typename?: "mutation_root" } & {
  renameGateway: { __typename?: "Gateway" } & Pick<Gateway, "gatewayId" | "gatewayName">;
};

export type UpdateGatewayHardwareTypeMutationVariables = {
  gatewayId: Scalars["ID"];
  type: GatewayHardwareType;
};

export type UpdateGatewayHardwareTypeMutation = { __typename?: "mutation_root" } & {
  result: { __typename?: "Gateway" } & Pick<Gateway, "gatewayId" | "gatewayName" | "hardwareType">;
};

export type DeleteSlotMutationVariables = {
  id: Scalars["ID"];
};

export type DeleteSlotMutation = { __typename?: "mutation_root" } & {
  deleteSlot: { __typename?: "Slot" } & Pick<Slot, "id">;
};

export type DeletePositionMutationVariables = {
  positionId: Scalars["ID"];
};

export type DeletePositionMutation = { __typename?: "mutation_root" } & {
  deletePosition: { __typename?: "Position" } & Pick<Position, "positionId">;
};

export type DeletePositionsMutationVariables = {
  positionIds: Array<Scalars["ID"]>;
};

export type DeletePositionsMutation = { __typename?: "mutation_root" } & {
  deletePositions: Array<{ __typename?: "Position" } & Pick<Position, "positionId">>;
};

export type CommonRoomNamesQueryVariables = {
  keyIds: Array<Scalars["ID"]>;
};

export type CommonRoomNamesQuery = { __typename?: "query_root" } & Pick<Query_Root, "commonRoomNames">;

export type SetupTestDataMutationVariables = {};

export type SetupTestDataMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "setupTestData">;

export type TeardownTestDataMutationVariables = {};

export type TeardownTestDataMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "teardownTestData">;

export type ResetPositionConfigurationsMutationVariables = {
  positionIds: Array<Scalars["String"]>;
};

export type ResetPositionConfigurationsMutation = { __typename?: "mutation_root" } & {
  resetPositionConfigurations: Array<
    { __typename?: "PositionConfiguration" } & Pick<PositionConfiguration, "positionId">
  >;
};

export type UpdatePositionConfigurationsAutomationModeMutationVariables = {
  positionIds: Array<Scalars["String"]>;
  automationMode: Scalars["String"];
  unoccupiedTemp?: Maybe<Scalars["Int"]>;
  heatingUnoccupiedTemp?: Maybe<Scalars["Int"]>;
};

export type UpdatePositionConfigurationsAutomationModeMutation = { __typename?: "mutation_root" } & {
  updatePositionConfigurationsAutomationMode: Array<
    { __typename?: "PositionConfiguration" } & Pick<
      PositionConfiguration,
      "positionId" | "automationMode" | "unoccupiedTemp" | "heatingUnoccupiedTemp"
    >
  >;
};

export type UpdatePositionConfigurationMutationVariables = {
  positionConfiguration: PositionConfigurationInput;
};

export type UpdatePositionConfigurationMutation = { __typename?: "mutation_root" } & {
  updatePositionConfiguration: { __typename?: "PositionConfigurationWithThermostatStatus" } & Pick<
    PositionConfigurationWithThermostatStatus,
    "positionId" | "positionName" | "parentPositionId" | "thermostatOfflineStatus"
  >;
};

export type UpdateDefaultPositionConfigurationMutationVariables = {
  positionConfiguration: PositionConfigurationInput;
};

export type UpdateDefaultPositionConfigurationMutation = { __typename?: "mutation_root" } & {
  updateDefaultPositionConfiguration: { __typename?: "PositionConfigurationWithThermostatStatus" } & Pick<
    PositionConfigurationWithThermostatStatus,
    "positionId" | "positionName" | "parentPositionId" | "thermostatOfflineStatus"
  >;
};

export type BulkUpdatePositionConfigurationsMutationVariables = {
  positionConfigurations: Array<PositionConfigurationInput>;
};

export type BulkUpdatePositionConfigurationsMutation = { __typename?: "mutation_root" } & {
  bulkUpdatePositionConfigurations: Array<
    { __typename?: "PositionConfigurationWithThermostatStatus" } & Pick<
      PositionConfigurationWithThermostatStatus,
      "positionId" | "positionName" | "parentPositionId" | "thermostatOfflineStatus"
    >
  >;
};

export type BulkUpdateDefaultPositionConfigurationsMutationVariables = {
  positionConfigurations: Array<PositionConfigurationInput>;
};

export type BulkUpdateDefaultPositionConfigurationsMutation = { __typename?: "mutation_root" } & {
  bulkUpdateDefaultPositionConfigurations: Array<
    { __typename?: "PositionConfigurationWithThermostatStatus" } & Pick<
      PositionConfigurationWithThermostatStatus,
      "positionId" | "positionName" | "parentPositionId" | "thermostatOfflineStatus"
    >
  >;
};

export type DeleteInfraMutationVariables = {
  infraId: Scalars["ID"];
};

export type DeleteInfraMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "deleteInfra">;

export type KeysWithEnergyConsumptionQueryVariables = {
  filter: KeyFilterInput;
  fromDate: Scalars["String"];
  toDate: Scalars["String"];
  pagination?: Maybe<GenericPaginationInput>;
};

export type KeysWithEnergyConsumptionQuery = { __typename?: "query_root" } & {
  keys: { __typename?: "KeysResponse" } & {
    keys: Array<
      { __typename?: "Key" } & Pick<Key, "keyId" | "keyName"> & {
          rooms: Array<{ __typename?: "Room" } & Pick<Room, "roomId" | "roomName" | "energyConsumption">>;
        }
    >;
    pagination: { __typename?: "GenericPagination" } & Pick<GenericPagination, "count" | "limit" | "offset">;
  };
};

export type TriggerToUploadGatewayImagesMutationVariables = {
  gatewayIds: Array<Scalars["String"]>;
};

export type TriggerToUploadGatewayImagesMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "triggerToUploadGatewayImages"
>;

export type FirmwareUploadLinkQueryVariables = {
  fileName: Scalars["String"];
};

export type FirmwareUploadLinkQuery = { __typename?: "query_root" } & Pick<Query_Root, "firmwareUploadLink">;

export type LocationsAssignedToUserForAutocompleteQueryVariables = {
  filter?: Maybe<LocationFilterInput>;
};

export type LocationsAssignedToUserForAutocompleteQuery = { __typename?: "query_root" } & {
  locationsAssignedToUser: { __typename?: "LocationsResponse" } & {
    locations: Array<{ __typename?: "Location" } & Pick<Location, "id" | "locationName">>;
  };
};

export type CreateInfraMutationVariables = {
  locationId: Scalars["ID"];
  name: Scalars["String"];
  type: Scalars["String"];
  numberOfPhases?: Maybe<Scalars["Int"]>;
  hvacSystemType?: Maybe<Scalars["String"]>;
  compressorId?: Maybe<Scalars["ID"]>;
  ctMultiplier?: Maybe<Scalars["Float"]>;
};

export type CreateInfraMutation = { __typename?: "mutation_root" } & {
  createInfra: { __typename?: "Infrastructure" } & Pick<Infrastructure, "id" | "name">;
};

export type CreateRoomCompressorsMutationVariables = {
  compressorsInput: Array<RoomCompressorCreateInput>;
};

export type CreateRoomCompressorsMutation = { __typename?: "mutation_root" } & {
  createRoomCompressors: Array<{ __typename?: "Compressor" } & Pick<Compressor, "compressorId" | "compressorName">>;
};

export type CreateSlotsMutationVariables = {
  slotsInput: Array<SlotCreateInput>;
};

export type CreateSlotsMutation = { __typename?: "mutation_root" } & {
  createSlots: Array<{ __typename?: "Slot" } & Pick<Slot, "id" | "nodeType" | "nodeSubType" | "slotName">>;
};

export type GetCoolingSystemQueryVariables = {
  locationId: Scalars["uuid"];
};

export type GetCoolingSystemQuery = { __typename?: "query_root" } & {
  infrastructures: Array<
    { __typename?: "sensorflow_v_infrastructures" } & Pick<Sensorflow_V_Infrastructures, "name" | "id" | "positionId">
  >;
};

export type ChangeInfraTypeMutationVariables = {
  infraId: Scalars["ID"];
  oldType: Scalars["String"];
  newType: Scalars["String"];
  hvacSystemType?: Maybe<Scalars["String"]>;
  coolingSystemId?: Maybe<Scalars["String"]>;
};

export type ChangeInfraTypeMutation = { __typename?: "mutation_root" } & {
  changeInfraType: { __typename?: "Infrastructure" } & Pick<Infrastructure, "name" | "id">;
};

export type GetNodeMappedPreviouslyQueryVariables = {
  infraId: Scalars["ID"];
};

export type GetNodeMappedPreviouslyQuery = { __typename?: "query_root" } & {
  getNodeMappedPreviously?: Maybe<
    { __typename?: "NodeMappedPreviously" } & Pick<
      NodeMappedPreviously,
      "slotName" | "phaseStreamIndex" | "nodeType" | "nodeMappedPreviously" | "desiredEmonSwitchPosition"
    >
  >;
};

export type EndKeyEntryMutationVariables = {
  keyEntryId: Scalars["Int"];
  status?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  commentStatus?: Maybe<Scalars["String"]>;
};

export type EndKeyEntryMutation = { __typename?: "mutation_root" } & {
  exitInstallationMode?: Maybe<
    { __typename?: "KeyEntry" } & Pick<
      KeyEntry,
      | "keyEntryId"
      | "keyPositionId"
      | "startedAt"
      | "userId"
      | "endedAt"
      | "positionFunction"
      | "isResumedEntry"
      | "lastNotifyAt"
    >
  >;
};

export type CreateRoomMutationVariables = {
  positionName: Scalars["String"];
  locationId: Scalars["ID"];
  parentPositionId: Scalars["ID"];
  slots?: Maybe<Array<Maybe<SlotCreateRoomInput>>>;
  acModelId?: Maybe<Scalars["String"]>;
  isDeviatedFromDefaultConfig?: Maybe<Scalars["Boolean"]>;
};

export type CreateRoomMutation = { __typename?: "mutation_root" } & {
  createRoom: { __typename?: "Position" } & Pick<Position, "positionName" | "positionId" | "locationId">;
};

export type CreateNodeSpareSetMutationVariables = {
  locationId: Scalars["ID"];
  nodeMacId: Scalars["String"];
  nodeType: NodeType;
  nodeSubType?: Maybe<NodeSubType>;
};

export type CreateNodeSpareSetMutation = { __typename?: "mutation_root" } & {
  createNodeSpareSet: { __typename?: "NodeSpareSetReponse" } & Pick<
    NodeSpareSetReponse,
    "nodeMacId" | "locationId" | "mappedAt" | "consumedAt"
  >;
};

export type RemoveNodeSpareSetMutationVariables = {
  nodeMacId: Scalars["String"];
};

export type RemoveNodeSpareSetMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "removeNodeSpareSet">;

export type StartKeyEntryMutationVariables = {
  keyPositionId: Scalars["ID"];
  userId: Scalars["ID"];
  positionFunction: Scalars["String"];
  isResumedEntry: Scalars["Boolean"];
  automaticTestRun?: Maybe<Scalars["Boolean"]>;
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
};

export type StartKeyEntryMutation = { __typename?: "mutation_root" } & {
  startInstallationMode: { __typename?: "KeyEntry" } & Pick<
    KeyEntry,
    | "keyEntryId"
    | "keyPositionId"
    | "startedAt"
    | "userId"
    | "endedAt"
    | "positionFunction"
    | "isResumedEntry"
    | "lastNotifyAt"
  >;
};

export type ClaimKeyMutationVariables = {
  keyPositionId: Scalars["ID"];
  userId: Scalars["ID"];
  positionFunction: Scalars["String"];
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
};

export type ClaimKeyMutation = { __typename?: "mutation_root" } & {
  claimKey: { __typename?: "KeyEntry" } & Pick<
    KeyEntry,
    | "keyEntryId"
    | "keyPositionId"
    | "startedAt"
    | "userId"
    | "endedAt"
    | "positionFunction"
    | "isResumedEntry"
    | "lastNotifyAt"
  >;
};

export type UnClaimKeyMutationVariables = {
  keyPositionId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type UnClaimKeyMutation = { __typename?: "mutation_root" } & {
  unClaimKey?: Maybe<
    { __typename?: "KeyEntry" } & Pick<
      KeyEntry,
      | "keyEntryId"
      | "keyPositionId"
      | "startedAt"
      | "userId"
      | "endedAt"
      | "positionFunction"
      | "isResumedEntry"
      | "lastNotifyAt"
    >
  >;
};

export type SwapChannelsMutationVariables = {
  startingCompressorId: Scalars["ID"];
  destinationCompressorId: Scalars["ID"];
};

export type SwapChannelsMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "swapChannels">;

export type CancelTimerAndResetToDefaultConfigMutationVariables = {
  roomIds: Array<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
};

export type CancelTimerAndResetToDefaultConfigMutation = { __typename?: "mutation_root" } & {
  cancelTimerAndResetToDefaultConfig: Array<
    { __typename?: "PositionConfiguration" } & Pick<PositionConfiguration, "positionId">
  >;
};

export type CancelPrecoolCommandMutationVariables = {
  roomIds: Array<Scalars["ID"]>;
};

export type CancelPrecoolCommandMutation = { __typename?: "mutation_root" } & {
  cancelPrecoolCommand?: Maybe<
    { __typename?: "CancelPrecoolCommand" } & {
      canceledCommands?: Maybe<Array<{ __typename?: "PrecoolCommand" } & Pick<PrecoolCommand, "id">>>;
      uncancelableRooms?: Maybe<
        Array<{ __typename?: "UncancelablePrecoolRooms" } & Pick<UncancelablePrecoolRooms, "roomId" | "roomName">>
      >;
    }
  >;
};

export type AddPrecoolCommandsMutationVariables = {
  positionIds: Array<Scalars["ID"]>;
  temperature: Scalars["Int"];
  totalMinutes: Scalars["Int"];
  mode: Scalars["Int"];
  startedAt?: Maybe<Scalars["String"]>;
};

export type AddPrecoolCommandsMutation = { __typename?: "mutation_root" } & {
  addPrecoolCommands?: Maybe<
    Array<
      { __typename?: "KeyWithThermostatOff" } & Pick<KeyWithThermostatOff, "keyId" | "keyName"> & {
          rooms?: Maybe<
            Array<Maybe<{ __typename?: "RoomWithThermostatOff" } & Pick<RoomWithThermostatOff, "roomId" | "roomName">>>
          >;
        }
    >
  >;
};

export type InitClickupExportProgressMutationVariables = {
  locationId: Scalars["ID"];
  clickupListId: Scalars["String"];
};

export type InitClickupExportProgressMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "initClickupExportProgress"
>;

export type RecordNewNodeLifeCycleEventMutationVariables = {
  nodeMacId: Scalars["String"];
  eventType: Scalars["Int"];
  comment?: Maybe<Scalars["String"]>;
  nodeTypeCode?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["String"]>;
};

export type RecordNewNodeLifeCycleEventMutation = { __typename?: "mutation_root" } & {
  createNodeLifeCycleEvent: { __typename?: "NodeLifeCycleEvent" } & Pick<
    NodeLifeCycleEvent,
    "id" | "nodeMacId" | "eventType" | "creationDate" | "comment"
  >;
};

export type EditGroupOfKeysMutationVariables = {
  keyIds?: Maybe<Array<Scalars["String"]>>;
  groupId?: Maybe<Scalars["String"]>;
};

export type EditGroupOfKeysMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "editGroupOfKeys">;

export type UpdateGroupMutationVariables = {
  groupId: Scalars["ID"];
  groupName: Scalars["String"];
  groupType: Scalars["String"];
};

export type UpdateGroupMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "updateGroup">;

export type StartTestingMutationVariables = {
  keyPositionId: Scalars["ID"];
  positionFunction: Scalars["String"];
  resumePreviousSession?: Maybe<Scalars["Boolean"]>;
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
};

export type StartTestingMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "startTesting">;

export type DeleteGroupMutationVariables = {
  groupId: Scalars["String"];
};

export type DeleteGroupMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "deleteGroup">;

export type MarkKeyAsDndMutationVariables = {
  keyPositionId: Scalars["ID"];
  comment: Scalars["String"];
  isMeterPosition?: Maybe<Scalars["Boolean"]>;
};

export type MarkKeyAsDndMutation = { __typename?: "mutation_root" } & {
  keyEntry: { __typename?: "KeyEntry" } & Pick<KeyEntry, "keyPositionId" | "startedAt" | "endedAt">;
};

export type EditGroupOfGatewaysMutationVariables = {
  gatewayIds?: Maybe<Array<Scalars["String"]>>;
  groupId?: Maybe<Scalars["String"]>;
};

export type EditGroupOfGatewaysMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "editGroupOfGateways">;

export type ValidateClickupListIdQueryVariables = {
  clickupListId: Scalars["String"];
};

export type ValidateClickupListIdQuery = { __typename?: "query_root" } & Pick<Query_Root, "validateClickupListId">;

export type UpdateSlotTasksToPendingMutationVariables = {
  roomId: Scalars["ID"];
};

export type UpdateSlotTasksToPendingMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "updateSlotTasksToPending"
>;

export type BulkAssignCompressorPositionsMutationVariables = {
  compressorIds?: Maybe<Array<Scalars["String"]>>;
  positionId?: Maybe<Scalars["String"]>;
};

export type BulkAssignCompressorPositionsMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "bulkAssignCompressorPositions"
>;

export type ActivateComfortPlusMutationVariables = {
  input: ActivateComfortPlusInput;
};

export type ActivateComfortPlusMutation = { __typename?: "mutation_root" } & Pick<Mutation_Root, "activateComfortPlus">;

export type EditComfortPlusDetailMutationVariables = {
  reservationId: Scalars["Int"];
  checkInAt?: Maybe<Scalars["String"]>;
  checkOutAt?: Maybe<Scalars["String"]>;
  addedEmails?: Maybe<Array<Scalars["String"]>>;
};

export type EditComfortPlusDetailMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "editComfortPlusDetail"
>;

export type DeactivateComfortPlusMutationVariables = {
  reservationId: Scalars["Int"];
  comment: Scalars["String"];
};

export type DeactivateComfortPlusMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "deactivateComfortPlus"
>;

export type SaveGuestPrecoolScheduleMutationVariables = {
  input: GuestPrecoolScheduleInput;
};

export type SaveGuestPrecoolScheduleMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "saveGuestPrecoolSchedule"
>;

export type DeleteGuestPrecoolScheduleMutationVariables = {
  scheduleId: Scalars["Int"];
};

export type DeleteGuestPrecoolScheduleMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "deleteGuestPrecoolSchedule"
>;

export type UpdateLocationGatewayHardwareTypeMutationVariables = {
  locationId: Scalars["ID"];
  type: GatewayHardwareType;
};

export type UpdateLocationGatewayHardwareTypeMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "updateLocationGatewayHardwareType"
>;

export type UpdateLocationGatewayPromiscuousModeMutationVariables = {
  locationId: Scalars["ID"];
  promiscuousModeEnabled: Scalars["Boolean"];
};

export type UpdateLocationGatewayPromiscuousModeMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "updateLocationGatewayPromiscuousMode"
>;

export type UpdateLocationSetEnableNoKeyCardStateMutationVariables = {
  locationId: Scalars["ID"];
  enableNoKeyCardState: Scalars["Boolean"];
};

export type UpdateLocationSetEnableNoKeyCardStateMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "updateLocationSetEnableNoKeyCardState"
>;

export type UpdateLocationBeaconSlotManagementMutationVariables = {
  locationId: Scalars["ID"];
  beaconSlotManagementEnabled: Scalars["Boolean"];
};

export type UpdateLocationBeaconSlotManagementMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "updateLocationBeaconSlotManagement"
>;

export type LocationEmbeddedAnalyticsInfoQueryVariables = {
  locationId: Scalars["ID"];
  input: LocationEmbeddedAnalyticsInput;
};

export type LocationEmbeddedAnalyticsInfoQuery = { __typename?: "query_root" } & {
  locationEmbeddedAnalyticsInfo: { __typename?: "EmbeddedAnalyticsInfo" } & Pick<
    EmbeddedAnalyticsInfo,
    "token" | "url"
  >;
};

export type OnEnterLiveDataModeConfigMutationVariables = {
  positionIds: Array<Scalars["ID"]>;
  userId: Scalars["String"];
};

export type OnEnterLiveDataModeConfigMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "onEnterLiveDataModeConfig"
>;

export type OnExitLiveDataModeConfigMutationVariables = {
  positionIds: Array<Scalars["ID"]>;
  userId: Scalars["String"];
};

export type OnExitLiveDataModeConfigMutation = { __typename?: "mutation_root" } & Pick<
  Mutation_Root,
  "onExitLiveDataModeConfig"
>;

export type GetHolisticsTokenQueryVariables = {
  embedId: Scalars["ID"];
  locationId: Scalars["ID"];
  filters?: Maybe<Scalars["String"]>;
};

export type GetHolisticsTokenQuery = { __typename?: "query_root" } & Pick<Query_Root, "getHolisticsToken">;

export const ComfortplusKeyDetailsDocument = gql`
  query comfortplusKeyDetails {
    data: sensorflow_v_comfortplus_key_details {
      checkInDate
      checkOutDate
      positionName
      onboarded
      locationName
      logoUrl
      timezone
      reservationId
    }
  }
`;

/**
 * __useComfortplusKeyDetailsQuery__
 *
 * To run a query within a React component, call `useComfortplusKeyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useComfortplusKeyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComfortplusKeyDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useComfortplusKeyDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ComfortplusKeyDetailsQuery, ComfortplusKeyDetailsQueryVariables>
) {
  return ApolloReactHooks.useQuery<ComfortplusKeyDetailsQuery, ComfortplusKeyDetailsQueryVariables>(
    ComfortplusKeyDetailsDocument,
    baseOptions
  );
}
export function useComfortplusKeyDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ComfortplusKeyDetailsQuery, ComfortplusKeyDetailsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ComfortplusKeyDetailsQuery, ComfortplusKeyDetailsQueryVariables>(
    ComfortplusKeyDetailsDocument,
    baseOptions
  );
}
export type ComfortplusKeyDetailsQueryHookResult = ReturnType<typeof useComfortplusKeyDetailsQuery>;
export type ComfortplusKeyDetailsLazyQueryHookResult = ReturnType<typeof useComfortplusKeyDetailsLazyQuery>;
export type ComfortplusKeyDetailsQueryResult = ApolloReactCommon.QueryResult<
  ComfortplusKeyDetailsQuery,
  ComfortplusKeyDetailsQueryVariables
>;
export const ComfortplusKeyMeasurementsDocument = gql`
  subscription comfortplusKeyMeasurements {
    data: sensorflow_v_comfortplus_key_measurements {
      ambientTemperature
      roomId
      roomName
      acMode
      fanSpeed
      scheduledCount
      precoolPendingCount
      lastPrecoolSuccessTime
    }
  }
`;

/**
 * __useComfortplusKeyMeasurementsSubscription__
 *
 * To run a query within a React component, call `useComfortplusKeyMeasurementsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useComfortplusKeyMeasurementsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComfortplusKeyMeasurementsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useComfortplusKeyMeasurementsSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ComfortplusKeyMeasurementsSubscription,
    ComfortplusKeyMeasurementsSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    ComfortplusKeyMeasurementsSubscription,
    ComfortplusKeyMeasurementsSubscriptionVariables
  >(ComfortplusKeyMeasurementsDocument, baseOptions);
}
export type ComfortplusKeyMeasurementsSubscriptionHookResult = ReturnType<
  typeof useComfortplusKeyMeasurementsSubscription
>;
export type ComfortplusKeyMeasurementsSubscriptionResult = ApolloReactCommon.SubscriptionResult<ComfortplusKeyMeasurementsSubscription>;
export const ComfortPlusResetDailyLimitDocument = gql`
  mutation comfortPlusResetDailyLimit($reservationId: bigint!, $dailyPrecoolLimitResetAt: timestamptz!) {
    updateComfortPlusActivations(
      _set: { dailyPrecoolLimitResetAt: $dailyPrecoolLimitResetAt }
      where: { reservationId: { _eq: $reservationId } }
    ) {
      affected_rows
    }
  }
`;
export type ComfortPlusResetDailyLimitMutationFn = ApolloReactCommon.MutationFunction<
  ComfortPlusResetDailyLimitMutation,
  ComfortPlusResetDailyLimitMutationVariables
>;

/**
 * __useComfortPlusResetDailyLimitMutation__
 *
 * To run a mutation, you first call `useComfortPlusResetDailyLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useComfortPlusResetDailyLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [comfortPlusResetDailyLimitMutation, { data, loading, error }] = useComfortPlusResetDailyLimitMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      dailyPrecoolLimitResetAt: // value for 'dailyPrecoolLimitResetAt'
 *   },
 * });
 */
export function useComfortPlusResetDailyLimitMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ComfortPlusResetDailyLimitMutation,
    ComfortPlusResetDailyLimitMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<ComfortPlusResetDailyLimitMutation, ComfortPlusResetDailyLimitMutationVariables>(
    ComfortPlusResetDailyLimitDocument,
    baseOptions
  );
}
export type ComfortPlusResetDailyLimitMutationHookResult = ReturnType<typeof useComfortPlusResetDailyLimitMutation>;
export type ComfortPlusResetDailyLimitMutationResult = ApolloReactCommon.MutationResult<ComfortPlusResetDailyLimitMutation>;
export type ComfortPlusResetDailyLimitMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ComfortPlusResetDailyLimitMutation,
  ComfortPlusResetDailyLimitMutationVariables
>;
export const SetComfortPlusUserOnboardedDocument = gql`
  mutation setComfortPlusUserOnboarded {
    update_sensorflow_users(_set: { comfortplus_onboard_shown: true }, where: {}) {
      affected_rows
    }
  }
`;
export type SetComfortPlusUserOnboardedMutationFn = ApolloReactCommon.MutationFunction<
  SetComfortPlusUserOnboardedMutation,
  SetComfortPlusUserOnboardedMutationVariables
>;

/**
 * __useSetComfortPlusUserOnboardedMutation__
 *
 * To run a mutation, you first call `useSetComfortPlusUserOnboardedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetComfortPlusUserOnboardedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setComfortPlusUserOnboardedMutation, { data, loading, error }] = useSetComfortPlusUserOnboardedMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetComfortPlusUserOnboardedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetComfortPlusUserOnboardedMutation,
    SetComfortPlusUserOnboardedMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetComfortPlusUserOnboardedMutation,
    SetComfortPlusUserOnboardedMutationVariables
  >(SetComfortPlusUserOnboardedDocument, baseOptions);
}
export type SetComfortPlusUserOnboardedMutationHookResult = ReturnType<typeof useSetComfortPlusUserOnboardedMutation>;
export type SetComfortPlusUserOnboardedMutationResult = ApolloReactCommon.MutationResult<SetComfortPlusUserOnboardedMutation>;
export type SetComfortPlusUserOnboardedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetComfortPlusUserOnboardedMutation,
  SetComfortPlusUserOnboardedMutationVariables
>;
export const PrecoolComfortPlusRoomDocument = gql`
  mutation precoolComfortPlusRoom($roomIds: [ID!]!) {
    precoolComfortPlusRoom(roomIds: $roomIds)
  }
`;
export type PrecoolComfortPlusRoomMutationFn = ApolloReactCommon.MutationFunction<
  PrecoolComfortPlusRoomMutation,
  PrecoolComfortPlusRoomMutationVariables
>;

/**
 * __usePrecoolComfortPlusRoomMutation__
 *
 * To run a mutation, you first call `usePrecoolComfortPlusRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrecoolComfortPlusRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [precoolComfortPlusRoomMutation, { data, loading, error }] = usePrecoolComfortPlusRoomMutation({
 *   variables: {
 *      roomIds: // value for 'roomIds'
 *   },
 * });
 */
export function usePrecoolComfortPlusRoomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PrecoolComfortPlusRoomMutation,
    PrecoolComfortPlusRoomMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<PrecoolComfortPlusRoomMutation, PrecoolComfortPlusRoomMutationVariables>(
    PrecoolComfortPlusRoomDocument,
    baseOptions
  );
}
export type PrecoolComfortPlusRoomMutationHookResult = ReturnType<typeof usePrecoolComfortPlusRoomMutation>;
export type PrecoolComfortPlusRoomMutationResult = ApolloReactCommon.MutationResult<PrecoolComfortPlusRoomMutation>;
export type PrecoolComfortPlusRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PrecoolComfortPlusRoomMutation,
  PrecoolComfortPlusRoomMutationVariables
>;
export const ComfortPlusPrecoolStatusDocument = gql`
  subscription comfortPlusPrecoolStatus {
    sensorflow_v_comfortplus_precool_status(where: {}) {
      positionName
      totalMinutes
      startedAt
      failMessage
      status
      mode
    }
  }
`;

/**
 * __useComfortPlusPrecoolStatusSubscription__
 *
 * To run a query within a React component, call `useComfortPlusPrecoolStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useComfortPlusPrecoolStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComfortPlusPrecoolStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useComfortPlusPrecoolStatusSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ComfortPlusPrecoolStatusSubscription,
    ComfortPlusPrecoolStatusSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    ComfortPlusPrecoolStatusSubscription,
    ComfortPlusPrecoolStatusSubscriptionVariables
  >(ComfortPlusPrecoolStatusDocument, baseOptions);
}
export type ComfortPlusPrecoolStatusSubscriptionHookResult = ReturnType<typeof useComfortPlusPrecoolStatusSubscription>;
export type ComfortPlusPrecoolStatusSubscriptionResult = ApolloReactCommon.SubscriptionResult<ComfortPlusPrecoolStatusSubscription>;
export const CreateLocationDocument = gql`
  mutation createLocation($locationInput: sensorflow_locations_insert_input!) {
    createLocation(object: $locationInput) {
      locationId
      locationName
      currency
    }
  }
`;
export type CreateLocationMutationFn = ApolloReactCommon.MutationFunction<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      locationInput: // value for 'locationInput'
 *   },
 * });
 */
export function useCreateLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(
    CreateLocationDocument,
    baseOptions
  );
}
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = ApolloReactCommon.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;
export const RenameLocationDocument = gql`
  mutation renameLocation(
    $primaryKey: sensorflow_locations_pk_columns_input!
    $updateLocationInput: sensorflow_locations_set_input!
  ) {
    updateLocation(pk_columns: $primaryKey, _set: $updateLocationInput) {
      locationId
      locationName
    }
  }
`;
export type RenameLocationMutationFn = ApolloReactCommon.MutationFunction<
  RenameLocationMutation,
  RenameLocationMutationVariables
>;

/**
 * __useRenameLocationMutation__
 *
 * To run a mutation, you first call `useRenameLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameLocationMutation, { data, loading, error }] = useRenameLocationMutation({
 *   variables: {
 *      primaryKey: // value for 'primaryKey'
 *      updateLocationInput: // value for 'updateLocationInput'
 *   },
 * });
 */
export function useRenameLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RenameLocationMutation, RenameLocationMutationVariables>
) {
  return ApolloReactHooks.useMutation<RenameLocationMutation, RenameLocationMutationVariables>(
    RenameLocationDocument,
    baseOptions
  );
}
export type RenameLocationMutationHookResult = ReturnType<typeof useRenameLocationMutation>;
export type RenameLocationMutationResult = ApolloReactCommon.MutationResult<RenameLocationMutation>;
export type RenameLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenameLocationMutation,
  RenameLocationMutationVariables
>;
export const UpsertLocationDocument = gql`
  mutation upsertLocation($locationInput: sensorflow_locations_insert_input!) {
    createLocation(
      object: $locationInput
      on_conflict: {
        constraint: locations_pkey
        update_columns: [locationName, currency, timezone, origin_latitude, origin_longitude, organisation_id]
      }
    ) {
      locationId
      locationName
    }
  }
`;
export type UpsertLocationMutationFn = ApolloReactCommon.MutationFunction<
  UpsertLocationMutation,
  UpsertLocationMutationVariables
>;

/**
 * __useUpsertLocationMutation__
 *
 * To run a mutation, you first call `useUpsertLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLocationMutation, { data, loading, error }] = useUpsertLocationMutation({
 *   variables: {
 *      locationInput: // value for 'locationInput'
 *   },
 * });
 */
export function useUpsertLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertLocationMutation, UpsertLocationMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpsertLocationMutation, UpsertLocationMutationVariables>(
    UpsertLocationDocument,
    baseOptions
  );
}
export type UpsertLocationMutationHookResult = ReturnType<typeof useUpsertLocationMutation>;
export type UpsertLocationMutationResult = ApolloReactCommon.MutationResult<UpsertLocationMutation>;
export type UpsertLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertLocationMutation,
  UpsertLocationMutationVariables
>;
export const LocationOneDocument = gql`
  query locationOne($locationId: uuid!) {
    locationOne(locationId: $locationId) {
      locationId
      locationName
      currency
      timezone
      origin_latitude
      origin_longitude
      organisation {
        id
        name
      }
      clickupListId
    }
  }
`;

/**
 * __useLocationOneQuery__
 *
 * To run a query within a React component, call `useLocationOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationOneQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationOneQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationOneQuery, LocationOneQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationOneQuery, LocationOneQueryVariables>(LocationOneDocument, baseOptions);
}
export function useLocationOneLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationOneQuery, LocationOneQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationOneQuery, LocationOneQueryVariables>(LocationOneDocument, baseOptions);
}
export type LocationOneQueryHookResult = ReturnType<typeof useLocationOneQuery>;
export type LocationOneLazyQueryHookResult = ReturnType<typeof useLocationOneLazyQuery>;
export type LocationOneQueryResult = ApolloReactCommon.QueryResult<LocationOneQuery, LocationOneQueryVariables>;
export const CreateKeysDocument = gql`
  mutation createKeys($keysInput: [sensorflow_key_category_to_key_position_insert_input!]!) {
    createKeyCategoryToKeyMappings(objects: $keysInput) {
      returning {
        categoryName
        position {
          positionId
          positionName
        }
      }
    }
  }
`;
export type CreateKeysMutationFn = ApolloReactCommon.MutationFunction<CreateKeysMutation, CreateKeysMutationVariables>;

/**
 * __useCreateKeysMutation__
 *
 * To run a mutation, you first call `useCreateKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeysMutation, { data, loading, error }] = useCreateKeysMutation({
 *   variables: {
 *      keysInput: // value for 'keysInput'
 *   },
 * });
 */
export function useCreateKeysMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateKeysMutation, CreateKeysMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateKeysMutation, CreateKeysMutationVariables>(CreateKeysDocument, baseOptions);
}
export type CreateKeysMutationHookResult = ReturnType<typeof useCreateKeysMutation>;
export type CreateKeysMutationResult = ApolloReactCommon.MutationResult<CreateKeysMutation>;
export type CreateKeysMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateKeysMutation,
  CreateKeysMutationVariables
>;
export const UpsertKeyCategoryTemplatesDocument = gql`
  mutation upsertKeyCategoryTemplates(
    $locationName: String!
    $templatesInput: [sensorflow_key_category_templates_insert_input!]!
  ) {
    deleteKeyCategoryTemplates(where: { locationName: { _eq: $locationName } }) {
      affected_rows
    }
    createKeyCategoryTemplates(objects: $templatesInput) {
      returning {
        id
        categoryName
        locationName
        location {
          locationId
        }
        roomTemplates {
          id
          roomName
          slotTemplates {
            id
            nodeType
            nodeSubType
            slotName
            acModelId
            actingMode
            operationalMode
          }
        }
      }
    }
  }
`;
export type UpsertKeyCategoryTemplatesMutationFn = ApolloReactCommon.MutationFunction<
  UpsertKeyCategoryTemplatesMutation,
  UpsertKeyCategoryTemplatesMutationVariables
>;

/**
 * __useUpsertKeyCategoryTemplatesMutation__
 *
 * To run a mutation, you first call `useUpsertKeyCategoryTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertKeyCategoryTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertKeyCategoryTemplatesMutation, { data, loading, error }] = useUpsertKeyCategoryTemplatesMutation({
 *   variables: {
 *      locationName: // value for 'locationName'
 *      templatesInput: // value for 'templatesInput'
 *   },
 * });
 */
export function useUpsertKeyCategoryTemplatesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertKeyCategoryTemplatesMutation,
    UpsertKeyCategoryTemplatesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpsertKeyCategoryTemplatesMutation, UpsertKeyCategoryTemplatesMutationVariables>(
    UpsertKeyCategoryTemplatesDocument,
    baseOptions
  );
}
export type UpsertKeyCategoryTemplatesMutationHookResult = ReturnType<typeof useUpsertKeyCategoryTemplatesMutation>;
export type UpsertKeyCategoryTemplatesMutationResult = ApolloReactCommon.MutationResult<UpsertKeyCategoryTemplatesMutation>;
export type UpsertKeyCategoryTemplatesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertKeyCategoryTemplatesMutation,
  UpsertKeyCategoryTemplatesMutationVariables
>;
export const KeyCategoriesWithTemplatesDocument = gql`
  query keyCategoriesWithTemplates($locationName: String!) {
    keyCategories(where: { locationName: { _eq: $locationName } }) {
      categoryName
      keyCategoryTemplate {
        id
        roomTemplates {
          id
          roomName
          slotTemplates {
            id
            nodeType
            nodeSubType
            slotName
            acModelId
          }
        }
      }
    }
  }
`;

/**
 * __useKeyCategoriesWithTemplatesQuery__
 *
 * To run a query within a React component, call `useKeyCategoriesWithTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyCategoriesWithTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyCategoriesWithTemplatesQuery({
 *   variables: {
 *      locationName: // value for 'locationName'
 *   },
 * });
 */
export function useKeyCategoriesWithTemplatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    KeyCategoriesWithTemplatesQuery,
    KeyCategoriesWithTemplatesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<KeyCategoriesWithTemplatesQuery, KeyCategoriesWithTemplatesQueryVariables>(
    KeyCategoriesWithTemplatesDocument,
    baseOptions
  );
}
export function useKeyCategoriesWithTemplatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    KeyCategoriesWithTemplatesQuery,
    KeyCategoriesWithTemplatesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<KeyCategoriesWithTemplatesQuery, KeyCategoriesWithTemplatesQueryVariables>(
    KeyCategoriesWithTemplatesDocument,
    baseOptions
  );
}
export type KeyCategoriesWithTemplatesQueryHookResult = ReturnType<typeof useKeyCategoriesWithTemplatesQuery>;
export type KeyCategoriesWithTemplatesLazyQueryHookResult = ReturnType<typeof useKeyCategoriesWithTemplatesLazyQuery>;
export type KeyCategoriesWithTemplatesQueryResult = ApolloReactCommon.QueryResult<
  KeyCategoriesWithTemplatesQuery,
  KeyCategoriesWithTemplatesQueryVariables
>;
export const UpdatePositionDocument = gql`
  mutation updatePosition($positionId: uuid!, $updatePositionInput: sensorflow_positions_set_input) {
    updatePosition(pk_columns: { positionId: $positionId }, _set: $updatePositionInput) {
      positionId
      positionName
    }
  }
`;
export type UpdatePositionMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePositionMutation,
  UpdatePositionMutationVariables
>;

/**
 * __useUpdatePositionMutation__
 *
 * To run a mutation, you first call `useUpdatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionMutation, { data, loading, error }] = useUpdatePositionMutation({
 *   variables: {
 *      positionId: // value for 'positionId'
 *      updatePositionInput: // value for 'updatePositionInput'
 *   },
 * });
 */
export function useUpdatePositionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePositionMutation, UpdatePositionMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdatePositionMutation, UpdatePositionMutationVariables>(
    UpdatePositionDocument,
    baseOptions
  );
}
export type UpdatePositionMutationHookResult = ReturnType<typeof useUpdatePositionMutation>;
export type UpdatePositionMutationResult = ApolloReactCommon.MutationResult<UpdatePositionMutation>;
export type UpdatePositionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePositionMutation,
  UpdatePositionMutationVariables
>;
export const UpdateKeyCategoryToKeyMappingDocument = gql`
  mutation updateKeyCategoryToKeyMapping($positionId: uuid!, $categoryName: String!) {
    updateKeyCategoryToKeyMappings(where: { positionId: { _eq: $positionId } }, _set: { categoryName: $categoryName }) {
      returning {
        positionId
        categoryName
      }
    }
  }
`;
export type UpdateKeyCategoryToKeyMappingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateKeyCategoryToKeyMappingMutation,
  UpdateKeyCategoryToKeyMappingMutationVariables
>;

/**
 * __useUpdateKeyCategoryToKeyMappingMutation__
 *
 * To run a mutation, you first call `useUpdateKeyCategoryToKeyMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeyCategoryToKeyMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeyCategoryToKeyMappingMutation, { data, loading, error }] = useUpdateKeyCategoryToKeyMappingMutation({
 *   variables: {
 *      positionId: // value for 'positionId'
 *      categoryName: // value for 'categoryName'
 *   },
 * });
 */
export function useUpdateKeyCategoryToKeyMappingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateKeyCategoryToKeyMappingMutation,
    UpdateKeyCategoryToKeyMappingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateKeyCategoryToKeyMappingMutation,
    UpdateKeyCategoryToKeyMappingMutationVariables
  >(UpdateKeyCategoryToKeyMappingDocument, baseOptions);
}
export type UpdateKeyCategoryToKeyMappingMutationHookResult = ReturnType<
  typeof useUpdateKeyCategoryToKeyMappingMutation
>;
export type UpdateKeyCategoryToKeyMappingMutationResult = ApolloReactCommon.MutationResult<UpdateKeyCategoryToKeyMappingMutation>;
export type UpdateKeyCategoryToKeyMappingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateKeyCategoryToKeyMappingMutation,
  UpdateKeyCategoryToKeyMappingMutationVariables
>;
export const CreateSlotDocument = gql`
  mutation createSlot($slotInput: sensorflow_node_slots_insert_input!) {
    createSlot(object: $slotInput) {
      id
      nodeType
      nodeSubType
      slotName
      parentPositionId
    }
  }
`;
export type CreateSlotMutationFn = ApolloReactCommon.MutationFunction<CreateSlotMutation, CreateSlotMutationVariables>;

/**
 * __useCreateSlotMutation__
 *
 * To run a mutation, you first call `useCreateSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlotMutation, { data, loading, error }] = useCreateSlotMutation({
 *   variables: {
 *      slotInput: // value for 'slotInput'
 *   },
 * });
 */
export function useCreateSlotMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSlotMutation, CreateSlotMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateSlotMutation, CreateSlotMutationVariables>(CreateSlotDocument, baseOptions);
}
export type CreateSlotMutationHookResult = ReturnType<typeof useCreateSlotMutation>;
export type CreateSlotMutationResult = ApolloReactCommon.MutationResult<CreateSlotMutation>;
export type CreateSlotMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSlotMutation,
  CreateSlotMutationVariables
>;
export const UpdateSlotDocument = gql`
  mutation updateSlot($id: uuid!, $updateSlotInput: sensorflow_node_slots_set_input) {
    updateSlot(pk_columns: { id: $id }, _set: $updateSlotInput) {
      id
      nodeType
      slotName
    }
  }
`;
export type UpdateSlotMutationFn = ApolloReactCommon.MutationFunction<UpdateSlotMutation, UpdateSlotMutationVariables>;

/**
 * __useUpdateSlotMutation__
 *
 * To run a mutation, you first call `useUpdateSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSlotMutation, { data, loading, error }] = useUpdateSlotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateSlotInput: // value for 'updateSlotInput'
 *   },
 * });
 */
export function useUpdateSlotMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSlotMutation, UpdateSlotMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateSlotMutation, UpdateSlotMutationVariables>(UpdateSlotDocument, baseOptions);
}
export type UpdateSlotMutationHookResult = ReturnType<typeof useUpdateSlotMutation>;
export type UpdateSlotMutationResult = ApolloReactCommon.MutationResult<UpdateSlotMutation>;
export type UpdateSlotMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSlotMutation,
  UpdateSlotMutationVariables
>;
export const CreateKeyCategoryDocument = gql`
  mutation createKeyCategory($input: sensorflow_key_categories_insert_input!) {
    createKeyCategory(object: $input) {
      categoryName
    }
  }
`;
export type CreateKeyCategoryMutationFn = ApolloReactCommon.MutationFunction<
  CreateKeyCategoryMutation,
  CreateKeyCategoryMutationVariables
>;

/**
 * __useCreateKeyCategoryMutation__
 *
 * To run a mutation, you first call `useCreateKeyCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeyCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeyCategoryMutation, { data, loading, error }] = useCreateKeyCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKeyCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateKeyCategoryMutation, CreateKeyCategoryMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateKeyCategoryMutation, CreateKeyCategoryMutationVariables>(
    CreateKeyCategoryDocument,
    baseOptions
  );
}
export type CreateKeyCategoryMutationHookResult = ReturnType<typeof useCreateKeyCategoryMutation>;
export type CreateKeyCategoryMutationResult = ApolloReactCommon.MutationResult<CreateKeyCategoryMutation>;
export type CreateKeyCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateKeyCategoryMutation,
  CreateKeyCategoryMutationVariables
>;
export const RenameKeyCategoryDocument = gql`
  mutation renameKeyCategory(
    $primaryKey: sensorflow_key_categories_pk_columns_input!
    $updateKeyCategoryInput: sensorflow_key_categories_set_input!
  ) {
    updateKeyCategory(pk_columns: $primaryKey, _set: $updateKeyCategoryInput) {
      categoryName
    }
  }
`;
export type RenameKeyCategoryMutationFn = ApolloReactCommon.MutationFunction<
  RenameKeyCategoryMutation,
  RenameKeyCategoryMutationVariables
>;

/**
 * __useRenameKeyCategoryMutation__
 *
 * To run a mutation, you first call `useRenameKeyCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameKeyCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameKeyCategoryMutation, { data, loading, error }] = useRenameKeyCategoryMutation({
 *   variables: {
 *      primaryKey: // value for 'primaryKey'
 *      updateKeyCategoryInput: // value for 'updateKeyCategoryInput'
 *   },
 * });
 */
export function useRenameKeyCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RenameKeyCategoryMutation, RenameKeyCategoryMutationVariables>
) {
  return ApolloReactHooks.useMutation<RenameKeyCategoryMutation, RenameKeyCategoryMutationVariables>(
    RenameKeyCategoryDocument,
    baseOptions
  );
}
export type RenameKeyCategoryMutationHookResult = ReturnType<typeof useRenameKeyCategoryMutation>;
export type RenameKeyCategoryMutationResult = ApolloReactCommon.MutationResult<RenameKeyCategoryMutation>;
export type RenameKeyCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenameKeyCategoryMutation,
  RenameKeyCategoryMutationVariables
>;
export const KeyCategoriesDocument = gql`
  query keyCategories($locationIdInput: sensorflow_key_categories_bool_exp!) {
    keyCategories(where: $locationIdInput) {
      id
      categoryName
      locationName
    }
  }
`;

/**
 * __useKeyCategoriesQuery__
 *
 * To run a query within a React component, call `useKeyCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyCategoriesQuery({
 *   variables: {
 *      locationIdInput: // value for 'locationIdInput'
 *   },
 * });
 */
export function useKeyCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<KeyCategoriesQuery, KeyCategoriesQueryVariables>
) {
  return ApolloReactHooks.useQuery<KeyCategoriesQuery, KeyCategoriesQueryVariables>(KeyCategoriesDocument, baseOptions);
}
export function useKeyCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeyCategoriesQuery, KeyCategoriesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<KeyCategoriesQuery, KeyCategoriesQueryVariables>(
    KeyCategoriesDocument,
    baseOptions
  );
}
export type KeyCategoriesQueryHookResult = ReturnType<typeof useKeyCategoriesQuery>;
export type KeyCategoriesLazyQueryHookResult = ReturnType<typeof useKeyCategoriesLazyQuery>;
export type KeyCategoriesQueryResult = ApolloReactCommon.QueryResult<KeyCategoriesQuery, KeyCategoriesQueryVariables>;
export const UpsertLocationMetadataDocument = gql`
  mutation upsertLocationMetadata($input: sensorflow_location_metadata_insert_input!) {
    createLocationMetadata(
      object: $input
      on_conflict: {
        constraint: location_metadata_pkey
        update_columns: [
          wifiSsid
          wifiPassword
          secondaryWifiSsid
          secondaryWifiPassword
          showEnergyConsumption
          subscription
          showHeatingMode
          activeAutosetCount
          allowDisableAutomationMode
          smartRemValidation
          availableOperationalModes
          hasKeyCard
          enableNoKeyCardState
          beaconSlotManagement
        ]
      }
    ) {
      locationId
    }
  }
`;
export type UpsertLocationMetadataMutationFn = ApolloReactCommon.MutationFunction<
  UpsertLocationMetadataMutation,
  UpsertLocationMetadataMutationVariables
>;

/**
 * __useUpsertLocationMetadataMutation__
 *
 * To run a mutation, you first call `useUpsertLocationMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLocationMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLocationMetadataMutation, { data, loading, error }] = useUpsertLocationMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLocationMetadataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertLocationMetadataMutation,
    UpsertLocationMetadataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpsertLocationMetadataMutation, UpsertLocationMetadataMutationVariables>(
    UpsertLocationMetadataDocument,
    baseOptions
  );
}
export type UpsertLocationMetadataMutationHookResult = ReturnType<typeof useUpsertLocationMetadataMutation>;
export type UpsertLocationMetadataMutationResult = ApolloReactCommon.MutationResult<UpsertLocationMetadataMutation>;
export type UpsertLocationMetadataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertLocationMetadataMutation,
  UpsertLocationMetadataMutationVariables
>;
export const UpdateLocationMetadataDocument = gql`
  mutation updateLocationMetadata(
    $where: sensorflow_location_metadata_bool_exp!
    $input: sensorflow_location_metadata_set_input
  ) {
    updateLocationMetadata(_set: $input, where: $where) {
      returning {
        locationId
      }
    }
  }
`;
export type UpdateLocationMetadataMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLocationMetadataMutation,
  UpdateLocationMetadataMutationVariables
>;

/**
 * __useUpdateLocationMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMetadataMutation, { data, loading, error }] = useUpdateLocationMetadataMutation({
 *   variables: {
 *      where: // value for 'where'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMetadataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLocationMetadataMutation,
    UpdateLocationMetadataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateLocationMetadataMutation, UpdateLocationMetadataMutationVariables>(
    UpdateLocationMetadataDocument,
    baseOptions
  );
}
export type UpdateLocationMetadataMutationHookResult = ReturnType<typeof useUpdateLocationMetadataMutation>;
export type UpdateLocationMetadataMutationResult = ApolloReactCommon.MutationResult<UpdateLocationMetadataMutation>;
export type UpdateLocationMetadataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLocationMetadataMutation,
  UpdateLocationMetadataMutationVariables
>;
export const GatewayKittingProcessMetadataDocument = gql`
  subscription gatewayKittingProcessMetadata($locationId: uuid!) {
    gatewayKittingProcessMetadata(where: { locationId: { _eq: $locationId } }) {
      balenaId
      locationId
      status
      gateway {
        gatewayMac
        gatewayName
        wifiMacAddress
        ethernetMacAddress
        networkConnectionStatus
        gatewayHealthData(limit: 1, order_by: { time: desc }) {
          measurementType
          time
          value
        }
      }
    }
  }
`;

/**
 * __useGatewayKittingProcessMetadataSubscription__
 *
 * To run a query within a React component, call `useGatewayKittingProcessMetadataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGatewayKittingProcessMetadataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayKittingProcessMetadataSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGatewayKittingProcessMetadataSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    GatewayKittingProcessMetadataSubscription,
    GatewayKittingProcessMetadataSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    GatewayKittingProcessMetadataSubscription,
    GatewayKittingProcessMetadataSubscriptionVariables
  >(GatewayKittingProcessMetadataDocument, baseOptions);
}
export type GatewayKittingProcessMetadataSubscriptionHookResult = ReturnType<
  typeof useGatewayKittingProcessMetadataSubscription
>;
export type GatewayKittingProcessMetadataSubscriptionResult = ApolloReactCommon.SubscriptionResult<GatewayKittingProcessMetadataSubscription>;
export const TemporaryGatewayKittingProcessMetadataDocument = gql`
  query temporaryGatewayKittingProcessMetadata($locationId: uuid!) {
    gatewayKittingProcessMetadata(
      where: {
        locationId: { _eq: $locationId }
        gateway: { gatewayId: { _is_null: false }, deletedAt: { _is_null: true } }
      }
    ) {
      balenaId
      locationId
      status
      gateway {
        gatewayMac
        gatewayName
        wifiMacAddress
        ethernetMacAddress
        networkConnectionStatus
        hardware_type
        position {
          positionName
          positionId
        }
        gatewayHealthData(limit: 1, order_by: { time: desc }, where: { measurementType: { _eq: "wlan_level" } }) {
          measurementType
          time
          value
        }
        wlanLinkGatewayHealthData: gatewayHealthData(
          limit: 1
          order_by: { time: desc }
          where: { measurementType: { _eq: "wlan_link" } }
        ) {
          measurementType
          time
          value
        }
        wlan1LinkGatewayHealthData: gatewayHealthData(
          limit: 1
          order_by: { time: desc }
          where: { measurementType: { _eq: "wlan1_link" } }
        ) {
          measurementType
          time
          value
        }
      }
    }
  }
`;

/**
 * __useTemporaryGatewayKittingProcessMetadataQuery__
 *
 * To run a query within a React component, call `useTemporaryGatewayKittingProcessMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryGatewayKittingProcessMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryGatewayKittingProcessMetadataQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useTemporaryGatewayKittingProcessMetadataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TemporaryGatewayKittingProcessMetadataQuery,
    TemporaryGatewayKittingProcessMetadataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TemporaryGatewayKittingProcessMetadataQuery,
    TemporaryGatewayKittingProcessMetadataQueryVariables
  >(TemporaryGatewayKittingProcessMetadataDocument, baseOptions);
}
export function useTemporaryGatewayKittingProcessMetadataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TemporaryGatewayKittingProcessMetadataQuery,
    TemporaryGatewayKittingProcessMetadataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TemporaryGatewayKittingProcessMetadataQuery,
    TemporaryGatewayKittingProcessMetadataQueryVariables
  >(TemporaryGatewayKittingProcessMetadataDocument, baseOptions);
}
export type TemporaryGatewayKittingProcessMetadataQueryHookResult = ReturnType<
  typeof useTemporaryGatewayKittingProcessMetadataQuery
>;
export type TemporaryGatewayKittingProcessMetadataLazyQueryHookResult = ReturnType<
  typeof useTemporaryGatewayKittingProcessMetadataLazyQuery
>;
export type TemporaryGatewayKittingProcessMetadataQueryResult = ApolloReactCommon.QueryResult<
  TemporaryGatewayKittingProcessMetadataQuery,
  TemporaryGatewayKittingProcessMetadataQueryVariables
>;
export const GatewayMappingsDocument = gql`
  subscription gatewayMappings($locationId: uuid!, $gatewayMac: Int!, $monitorNode: [String!]!) {
    positions(
      where: {
        locationId: { _eq: $locationId }
        _or: [
          { nodeSlots: { nodeType: { _in: $monitorNode } } }
          {
            positionFunction: { _eq: "autoset" }
            _or: [
              { gatewayMapping: { gateway: { gatewayMac: { _eq: $gatewayMac } } } }
              { gatewayMapping: { gatewayId: { _is_null: true } } }
              { _not: { gatewayMapping: {} } }
            ]
          }
        ]
      }
    ) {
      positionId
      gatewayMapping {
        gateway {
          gatewayMac
        }
      }
      slotMappings {
        nodeMacId
        nodeType
        isPrimaryNode
      }
    }
  }
`;

/**
 * __useGatewayMappingsSubscription__
 *
 * To run a query within a React component, call `useGatewayMappingsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGatewayMappingsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayMappingsSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      gatewayMac: // value for 'gatewayMac'
 *      monitorNode: // value for 'monitorNode'
 *   },
 * });
 */
export function useGatewayMappingsSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    GatewayMappingsSubscription,
    GatewayMappingsSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<GatewayMappingsSubscription, GatewayMappingsSubscriptionVariables>(
    GatewayMappingsDocument,
    baseOptions
  );
}
export type GatewayMappingsSubscriptionHookResult = ReturnType<typeof useGatewayMappingsSubscription>;
export type GatewayMappingsSubscriptionResult = ApolloReactCommon.SubscriptionResult<GatewayMappingsSubscription>;
export const TemporaryGatewayMappingsDocument = gql`
  query temporaryGatewayMappings($locationId: uuid!, $gatewayMac: Int!, $monitorNode: [String!]!) {
    positions(
      where: {
        locationId: { _eq: $locationId }
        _or: [
          { nodeSlots: { nodeType: { _in: $monitorNode } } }
          {
            positionFunction: { _eq: "autoset" }
            _or: [
              { gatewayMapping: { gateway: { gatewayMac: { _eq: $gatewayMac } } } }
              { gatewayMapping: { gatewayId: { _is_null: true } } }
              { _not: { gatewayMapping: {} } }
            ]
          }
        ]
      }
    ) {
      positionId
      gatewayMapping {
        gateway {
          gatewayMac
        }
      }
      slotMappings {
        nodeMacId
        nodeType
        isPrimaryNode
      }
    }
  }
`;

/**
 * __useTemporaryGatewayMappingsQuery__
 *
 * To run a query within a React component, call `useTemporaryGatewayMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryGatewayMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryGatewayMappingsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      gatewayMac: // value for 'gatewayMac'
 *      monitorNode: // value for 'monitorNode'
 *   },
 * });
 */
export function useTemporaryGatewayMappingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TemporaryGatewayMappingsQuery, TemporaryGatewayMappingsQueryVariables>
) {
  return ApolloReactHooks.useQuery<TemporaryGatewayMappingsQuery, TemporaryGatewayMappingsQueryVariables>(
    TemporaryGatewayMappingsDocument,
    baseOptions
  );
}
export function useTemporaryGatewayMappingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TemporaryGatewayMappingsQuery,
    TemporaryGatewayMappingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TemporaryGatewayMappingsQuery, TemporaryGatewayMappingsQueryVariables>(
    TemporaryGatewayMappingsDocument,
    baseOptions
  );
}
export type TemporaryGatewayMappingsQueryHookResult = ReturnType<typeof useTemporaryGatewayMappingsQuery>;
export type TemporaryGatewayMappingsLazyQueryHookResult = ReturnType<typeof useTemporaryGatewayMappingsLazyQuery>;
export type TemporaryGatewayMappingsQueryResult = ApolloReactCommon.QueryResult<
  TemporaryGatewayMappingsQuery,
  TemporaryGatewayMappingsQueryVariables
>;
export const GatewaysDocument = gql`
  query gateways($locationId: uuid!) {
    gateways(where: { positionId: { _eq: $locationId }, deletedAt: { _is_null: true } }) {
      gatewayId
      gatewayName
    }
  }
`;

/**
 * __useGatewaysQuery__
 *
 * To run a query within a React component, call `useGatewaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewaysQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGatewaysQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GatewaysQuery, GatewaysQueryVariables>
) {
  return ApolloReactHooks.useQuery<GatewaysQuery, GatewaysQueryVariables>(GatewaysDocument, baseOptions);
}
export function useGatewaysLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GatewaysQuery, GatewaysQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GatewaysQuery, GatewaysQueryVariables>(GatewaysDocument, baseOptions);
}
export type GatewaysQueryHookResult = ReturnType<typeof useGatewaysQuery>;
export type GatewaysLazyQueryHookResult = ReturnType<typeof useGatewaysLazyQuery>;
export type GatewaysQueryResult = ApolloReactCommon.QueryResult<GatewaysQuery, GatewaysQueryVariables>;
export const UpsertPositionGatewayDocument = gql`
  mutation upsertPositionGateway($positionId: uuid!, $gatewayId: String!) {
    createGatewayToPositionMapping(
      object: { positionId: $positionId, gatewayId: $gatewayId }
      on_conflict: { constraint: gateway_to_position_mapping_pkey, update_columns: gatewayId }
    ) {
      positionId
      gatewayId
    }
  }
`;
export type UpsertPositionGatewayMutationFn = ApolloReactCommon.MutationFunction<
  UpsertPositionGatewayMutation,
  UpsertPositionGatewayMutationVariables
>;

/**
 * __useUpsertPositionGatewayMutation__
 *
 * To run a mutation, you first call `useUpsertPositionGatewayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPositionGatewayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPositionGatewayMutation, { data, loading, error }] = useUpsertPositionGatewayMutation({
 *   variables: {
 *      positionId: // value for 'positionId'
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useUpsertPositionGatewayMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertPositionGatewayMutation,
    UpsertPositionGatewayMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpsertPositionGatewayMutation, UpsertPositionGatewayMutationVariables>(
    UpsertPositionGatewayDocument,
    baseOptions
  );
}
export type UpsertPositionGatewayMutationHookResult = ReturnType<typeof useUpsertPositionGatewayMutation>;
export type UpsertPositionGatewayMutationResult = ApolloReactCommon.MutationResult<UpsertPositionGatewayMutation>;
export type UpsertPositionGatewayMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertPositionGatewayMutation,
  UpsertPositionGatewayMutationVariables
>;
export const RoomNodeMappingsDocument = gql`
  subscription roomNodeMappings($keyId: uuid!) {
    positions(where: { parentPositionId: { _eq: $keyId } }) {
      positionId
      positionName
      gatewayMapping {
        gateway {
          gatewayId
          gatewayName
        }
      }
      slotMappings {
        slotName
        nodeType
        nodeMacId
        mappedTime
        isPrimaryNode
        slot {
          id
        }
        node {
          nodeJoinStatus {
            status
            lastSeen
            recentJoinCount
            bootTime
            firmwareVersion
            signalStrength
          }
        }
      }
    }
  }
`;

/**
 * __useRoomNodeMappingsSubscription__
 *
 * To run a query within a React component, call `useRoomNodeMappingsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomNodeMappingsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomNodeMappingsSubscription({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useRoomNodeMappingsSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    RoomNodeMappingsSubscription,
    RoomNodeMappingsSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<RoomNodeMappingsSubscription, RoomNodeMappingsSubscriptionVariables>(
    RoomNodeMappingsDocument,
    baseOptions
  );
}
export type RoomNodeMappingsSubscriptionHookResult = ReturnType<typeof useRoomNodeMappingsSubscription>;
export type RoomNodeMappingsSubscriptionResult = ApolloReactCommon.SubscriptionResult<RoomNodeMappingsSubscription>;
export const RoomNodeStatusDocument = gql`
  subscription roomNodeStatus($keyId: uuid!, $filterSlotMappings: sensorflow_node_to_slot_mapping_bool_exp) {
    positions(where: { parentPositionId: { _eq: $keyId } }) {
      positionId
      slotMappings(where: $filterSlotMappings) {
        slot {
          id
        }
        node {
          node_mac_id
          nodeOnlineStatus {
            nodeStatus
            lastTransmission
            nodeStatusInInstallationMode
          }
          nodeJoinStrengthLive {
            signalStrength
          }
          nodeSubType
          node_type
        }
      }
    }
  }
`;

/**
 * __useRoomNodeStatusSubscription__
 *
 * To run a query within a React component, call `useRoomNodeStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomNodeStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomNodeStatusSubscription({
 *   variables: {
 *      keyId: // value for 'keyId'
 *      filterSlotMappings: // value for 'filterSlotMappings'
 *   },
 * });
 */
export function useRoomNodeStatusSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    RoomNodeStatusSubscription,
    RoomNodeStatusSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<RoomNodeStatusSubscription, RoomNodeStatusSubscriptionVariables>(
    RoomNodeStatusDocument,
    baseOptions
  );
}
export type RoomNodeStatusSubscriptionHookResult = ReturnType<typeof useRoomNodeStatusSubscription>;
export type RoomNodeStatusSubscriptionResult = ApolloReactCommon.SubscriptionResult<RoomNodeStatusSubscription>;
export const TemporaryRoomNodeMappingsDocument = gql`
  query temporaryRoomNodeMappings($keyId: uuid!) {
    positions(where: { parentPositionId: { _eq: $keyId } }) {
      positionId
      positionName
      gatewayMapping {
        gateway {
          gatewayId
          gatewayName
        }
      }
      slotMappings(where: { decomissionedTime: { _is_null: true } }) {
        slotName
        nodeType
        nodeMacId
        mappedTime
        isPrimaryNode
        slot {
          id
        }
        node {
          nodeMetaStatus {
            bootTime
            firmwareVersion
            recentJoinCount
          }
        }
      }
    }
  }
`;

/**
 * __useTemporaryRoomNodeMappingsQuery__
 *
 * To run a query within a React component, call `useTemporaryRoomNodeMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryRoomNodeMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryRoomNodeMappingsQuery({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useTemporaryRoomNodeMappingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TemporaryRoomNodeMappingsQuery,
    TemporaryRoomNodeMappingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TemporaryRoomNodeMappingsQuery, TemporaryRoomNodeMappingsQueryVariables>(
    TemporaryRoomNodeMappingsDocument,
    baseOptions
  );
}
export function useTemporaryRoomNodeMappingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TemporaryRoomNodeMappingsQuery,
    TemporaryRoomNodeMappingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TemporaryRoomNodeMappingsQuery, TemporaryRoomNodeMappingsQueryVariables>(
    TemporaryRoomNodeMappingsDocument,
    baseOptions
  );
}
export type TemporaryRoomNodeMappingsQueryHookResult = ReturnType<typeof useTemporaryRoomNodeMappingsQuery>;
export type TemporaryRoomNodeMappingsLazyQueryHookResult = ReturnType<typeof useTemporaryRoomNodeMappingsLazyQuery>;
export type TemporaryRoomNodeMappingsQueryResult = ApolloReactCommon.QueryResult<
  TemporaryRoomNodeMappingsQuery,
  TemporaryRoomNodeMappingsQueryVariables
>;
export const NodeListDocument = gql`
  subscription nodeList(
    $where: sensorflow_node_to_slot_mapping_bool_exp!
    $limit: Int!
    $offset: Int!
    $order_by: [sensorflow_node_to_slot_mapping_order_by!]
  ) {
    sensorflow_node_to_slot_mapping(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      position {
        positionId
        positionName
        parentPosition {
          positionId
          positionName
        }
      }
      nodeMacId
      nodeType
      mappedTime
      node {
        nodeSubType
        gateway {
          gatewayName
        }
        nodeJoinStatus {
          status
          lastSeen
          recentJoinCount
          RSSI
          SNR
          bootTime
          firmwareVersion
        }
      }
    }
  }
`;

/**
 * __useNodeListSubscription__
 *
 * To run a query within a React component, call `useNodeListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNodeListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeListSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useNodeListSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NodeListSubscription, NodeListSubscriptionVariables>
) {
  return ApolloReactHooks.useSubscription<NodeListSubscription, NodeListSubscriptionVariables>(
    NodeListDocument,
    baseOptions
  );
}
export type NodeListSubscriptionHookResult = ReturnType<typeof useNodeListSubscription>;
export type NodeListSubscriptionResult = ApolloReactCommon.SubscriptionResult<NodeListSubscription>;
export const TemporaryNodeListDocument = gql`
  query temporaryNodeList(
    $where: sensorflow_node_to_slot_mapping_bool_exp!
    $limit: Int!
    $offset: Int!
    $order_by: [sensorflow_node_to_slot_mapping_order_by!]
  ) {
    sensorflow_node_to_slot_mapping(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      position {
        positionId
        positionName
        parentPosition {
          positionId
          positionName
        }
      }
      nodeMacId
      nodeType
      mappedTime
      node {
        nodeSubType
        gateway {
          gatewayName
        }
        nodeJoinStatus {
          lastSeen
          recentJoinCount
          bootTime
          firmwareVersion
          signalStrength
        }
        nodeOnlineStatus {
          nodeStatus
        }
      }
    }
  }
`;

/**
 * __useTemporaryNodeListQuery__
 *
 * To run a query within a React component, call `useTemporaryNodeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryNodeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryNodeListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useTemporaryNodeListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TemporaryNodeListQuery, TemporaryNodeListQueryVariables>
) {
  return ApolloReactHooks.useQuery<TemporaryNodeListQuery, TemporaryNodeListQueryVariables>(
    TemporaryNodeListDocument,
    baseOptions
  );
}
export function useTemporaryNodeListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemporaryNodeListQuery, TemporaryNodeListQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TemporaryNodeListQuery, TemporaryNodeListQueryVariables>(
    TemporaryNodeListDocument,
    baseOptions
  );
}
export type TemporaryNodeListQueryHookResult = ReturnType<typeof useTemporaryNodeListQuery>;
export type TemporaryNodeListLazyQueryHookResult = ReturnType<typeof useTemporaryNodeListLazyQuery>;
export type TemporaryNodeListQueryResult = ApolloReactCommon.QueryResult<
  TemporaryNodeListQuery,
  TemporaryNodeListQueryVariables
>;
export const NodeListAggregateDocument = gql`
  subscription nodeListAggregate($where: sensorflow_node_to_slot_mapping_bool_exp!) {
    sensorflow_node_to_slot_mapping_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useNodeListAggregateSubscription__
 *
 * To run a query within a React component, call `useNodeListAggregateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNodeListAggregateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeListAggregateSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNodeListAggregateSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    NodeListAggregateSubscription,
    NodeListAggregateSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<NodeListAggregateSubscription, NodeListAggregateSubscriptionVariables>(
    NodeListAggregateDocument,
    baseOptions
  );
}
export type NodeListAggregateSubscriptionHookResult = ReturnType<typeof useNodeListAggregateSubscription>;
export type NodeListAggregateSubscriptionResult = ApolloReactCommon.SubscriptionResult<NodeListAggregateSubscription>;
export const TemporaryNodeListAggregateDocument = gql`
  query temporaryNodeListAggregate($where: sensorflow_node_to_slot_mapping_bool_exp!) {
    sensorflow_node_to_slot_mapping_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useTemporaryNodeListAggregateQuery__
 *
 * To run a query within a React component, call `useTemporaryNodeListAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryNodeListAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryNodeListAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTemporaryNodeListAggregateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TemporaryNodeListAggregateQuery,
    TemporaryNodeListAggregateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TemporaryNodeListAggregateQuery, TemporaryNodeListAggregateQueryVariables>(
    TemporaryNodeListAggregateDocument,
    baseOptions
  );
}
export function useTemporaryNodeListAggregateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TemporaryNodeListAggregateQuery,
    TemporaryNodeListAggregateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TemporaryNodeListAggregateQuery, TemporaryNodeListAggregateQueryVariables>(
    TemporaryNodeListAggregateDocument,
    baseOptions
  );
}
export type TemporaryNodeListAggregateQueryHookResult = ReturnType<typeof useTemporaryNodeListAggregateQuery>;
export type TemporaryNodeListAggregateLazyQueryHookResult = ReturnType<typeof useTemporaryNodeListAggregateLazyQuery>;
export type TemporaryNodeListAggregateQueryResult = ApolloReactCommon.QueryResult<
  TemporaryNodeListAggregateQuery,
  TemporaryNodeListAggregateQueryVariables
>;
export const UpdatePrimaryNodeDocument = gql`
  mutation updatePrimaryNode($positionId: uuid!, $nodeMacId: String!) {
    clearPrimaryNode: updateNodeToSlotMappings(
      where: { parentPositionId: { _eq: $positionId }, isPrimaryNode: { _eq: true } }
      _set: { isPrimaryNode: false }
    ) {
      affected_rows
    }
    updatePrimaryNode: updateNodeToSlotMappings(
      where: { parentPositionId: { _eq: $positionId }, nodeMacId: { _eq: $nodeMacId } }
      _set: { isPrimaryNode: true }
    ) {
      returning {
        slotName
        nodeMacId
        isPrimaryNode
      }
    }
  }
`;
export type UpdatePrimaryNodeMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePrimaryNodeMutation,
  UpdatePrimaryNodeMutationVariables
>;

/**
 * __useUpdatePrimaryNodeMutation__
 *
 * To run a mutation, you first call `useUpdatePrimaryNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrimaryNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrimaryNodeMutation, { data, loading, error }] = useUpdatePrimaryNodeMutation({
 *   variables: {
 *      positionId: // value for 'positionId'
 *      nodeMacId: // value for 'nodeMacId'
 *   },
 * });
 */
export function useUpdatePrimaryNodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePrimaryNodeMutation, UpdatePrimaryNodeMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdatePrimaryNodeMutation, UpdatePrimaryNodeMutationVariables>(
    UpdatePrimaryNodeDocument,
    baseOptions
  );
}
export type UpdatePrimaryNodeMutationHookResult = ReturnType<typeof useUpdatePrimaryNodeMutation>;
export type UpdatePrimaryNodeMutationResult = ApolloReactCommon.MutationResult<UpdatePrimaryNodeMutation>;
export type UpdatePrimaryNodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePrimaryNodeMutation,
  UpdatePrimaryNodeMutationVariables
>;
export const KeyNodeHealthDocument = gql`
  subscription keyNodeHealth($keyId: uuid!) {
    nodeJoinStatuses(where: { position: { parentPositionId: { _eq: $keyId } } }) {
      nodeMacId
      status
    }
  }
`;

/**
 * __useKeyNodeHealthSubscription__
 *
 * To run a query within a React component, call `useKeyNodeHealthSubscription` and pass it any options that fit your needs.
 * When your component renders, `useKeyNodeHealthSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyNodeHealthSubscription({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useKeyNodeHealthSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<KeyNodeHealthSubscription, KeyNodeHealthSubscriptionVariables>
) {
  return ApolloReactHooks.useSubscription<KeyNodeHealthSubscription, KeyNodeHealthSubscriptionVariables>(
    KeyNodeHealthDocument,
    baseOptions
  );
}
export type KeyNodeHealthSubscriptionHookResult = ReturnType<typeof useKeyNodeHealthSubscription>;
export type KeyNodeHealthSubscriptionResult = ApolloReactCommon.SubscriptionResult<KeyNodeHealthSubscription>;
export const TemporaryKeyNodeHealthDocument = gql`
  query temporaryKeyNodeHealth($keyId: uuid!) {
    nodeOnlineStatuses(where: { position: { parentPositionId: { _eq: $keyId } } }) {
      nodeMacId
      nodeStatus
    }
  }
`;

/**
 * __useTemporaryKeyNodeHealthQuery__
 *
 * To run a query within a React component, call `useTemporaryKeyNodeHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryKeyNodeHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryKeyNodeHealthQuery({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useTemporaryKeyNodeHealthQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TemporaryKeyNodeHealthQuery, TemporaryKeyNodeHealthQueryVariables>
) {
  return ApolloReactHooks.useQuery<TemporaryKeyNodeHealthQuery, TemporaryKeyNodeHealthQueryVariables>(
    TemporaryKeyNodeHealthDocument,
    baseOptions
  );
}
export function useTemporaryKeyNodeHealthLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemporaryKeyNodeHealthQuery, TemporaryKeyNodeHealthQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TemporaryKeyNodeHealthQuery, TemporaryKeyNodeHealthQueryVariables>(
    TemporaryKeyNodeHealthDocument,
    baseOptions
  );
}
export type TemporaryKeyNodeHealthQueryHookResult = ReturnType<typeof useTemporaryKeyNodeHealthQuery>;
export type TemporaryKeyNodeHealthLazyQueryHookResult = ReturnType<typeof useTemporaryKeyNodeHealthLazyQuery>;
export type TemporaryKeyNodeHealthQueryResult = ApolloReactCommon.QueryResult<
  TemporaryKeyNodeHealthQuery,
  TemporaryKeyNodeHealthQueryVariables
>;
export const CreateCompressorDocument = gql`
  mutation createCompressor($locationId: uuid!, $name: String!, $numberOfPhases: Int!) {
    createCompressor(
      object: {
        compressorName: $name
        numberOfPhases: $numberOfPhases
        position: {
          data: {
            positionName: $name
            positionType: "compressor"
            positionFunction: "Energy_consumer"
            parentPositionId: $locationId
            locationId: $locationId
          }
        }
      }
    ) {
      compressorId
      compressorName
      compressorMeterPositionId
      compressorPositionId
      numberOfPhases
    }
  }
`;
export type CreateCompressorMutationFn = ApolloReactCommon.MutationFunction<
  CreateCompressorMutation,
  CreateCompressorMutationVariables
>;

/**
 * __useCreateCompressorMutation__
 *
 * To run a mutation, you first call `useCreateCompressorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompressorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompressorMutation, { data, loading, error }] = useCreateCompressorMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      name: // value for 'name'
 *      numberOfPhases: // value for 'numberOfPhases'
 *   },
 * });
 */
export function useCreateCompressorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompressorMutation, CreateCompressorMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateCompressorMutation, CreateCompressorMutationVariables>(
    CreateCompressorDocument,
    baseOptions
  );
}
export type CreateCompressorMutationHookResult = ReturnType<typeof useCreateCompressorMutation>;
export type CreateCompressorMutationResult = ApolloReactCommon.MutationResult<CreateCompressorMutation>;
export type CreateCompressorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCompressorMutation,
  CreateCompressorMutationVariables
>;
export const CreatePipeDocument = gql`
  mutation createPipe($locationId: uuid!, $name: String!) {
    createPipe(
      object: {
        name: $name
        position: {
          data: {
            positionName: $name
            positionType: "pipe"
            positionFunction: "water_consumer"
            parentPositionId: $locationId
            locationId: $locationId
          }
        }
      }
    ) {
      id
      name
    }
  }
`;
export type CreatePipeMutationFn = ApolloReactCommon.MutationFunction<CreatePipeMutation, CreatePipeMutationVariables>;

/**
 * __useCreatePipeMutation__
 *
 * To run a mutation, you first call `useCreatePipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPipeMutation, { data, loading, error }] = useCreatePipeMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreatePipeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePipeMutation, CreatePipeMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreatePipeMutation, CreatePipeMutationVariables>(CreatePipeDocument, baseOptions);
}
export type CreatePipeMutationHookResult = ReturnType<typeof useCreatePipeMutation>;
export type CreatePipeMutationResult = ApolloReactCommon.MutationResult<CreatePipeMutation>;
export type CreatePipeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePipeMutation,
  CreatePipeMutationVariables
>;
export const CreatePumpDocument = gql`
  mutation createPump($locationId: uuid!, $name: String!, $numberOfPhases: Int!) {
    createPump(
      object: {
        numPhases: $numberOfPhases
        name: $name
        position: {
          data: {
            positionName: $name
            positionType: "pump"
            positionFunction: "Energy_consumer"
            parentPositionId: $locationId
            locationId: $locationId
          }
        }
      }
    ) {
      id
      name
    }
  }
`;
export type CreatePumpMutationFn = ApolloReactCommon.MutationFunction<CreatePumpMutation, CreatePumpMutationVariables>;

/**
 * __useCreatePumpMutation__
 *
 * To run a mutation, you first call `useCreatePumpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePumpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPumpMutation, { data, loading, error }] = useCreatePumpMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      name: // value for 'name'
 *      numberOfPhases: // value for 'numberOfPhases'
 *   },
 * });
 */
export function useCreatePumpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePumpMutation, CreatePumpMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreatePumpMutation, CreatePumpMutationVariables>(CreatePumpDocument, baseOptions);
}
export type CreatePumpMutationHookResult = ReturnType<typeof useCreatePumpMutation>;
export type CreatePumpMutationResult = ApolloReactCommon.MutationResult<CreatePumpMutation>;
export type CreatePumpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePumpMutation,
  CreatePumpMutationVariables
>;
export const CreateChillerDocument = gql`
  mutation createChiller($locationId: uuid!, $name: String!, $numberOfPhases: Int!) {
    createChiller(
      object: {
        numPhases: $numberOfPhases
        name: $name
        position: {
          data: {
            positionName: $name
            positionType: "chiller"
            positionFunction: "Energy_consumer"
            parentPositionId: $locationId
            locationId: $locationId
          }
        }
      }
    ) {
      id
      name
    }
  }
`;
export type CreateChillerMutationFn = ApolloReactCommon.MutationFunction<
  CreateChillerMutation,
  CreateChillerMutationVariables
>;

/**
 * __useCreateChillerMutation__
 *
 * To run a mutation, you first call `useCreateChillerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChillerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChillerMutation, { data, loading, error }] = useCreateChillerMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      name: // value for 'name'
 *      numberOfPhases: // value for 'numberOfPhases'
 *   },
 * });
 */
export function useCreateChillerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateChillerMutation, CreateChillerMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateChillerMutation, CreateChillerMutationVariables>(
    CreateChillerDocument,
    baseOptions
  );
}
export type CreateChillerMutationHookResult = ReturnType<typeof useCreateChillerMutation>;
export type CreateChillerMutationResult = ApolloReactCommon.MutationResult<CreateChillerMutation>;
export type CreateChillerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateChillerMutation,
  CreateChillerMutationVariables
>;
export const CreateCoolingTowerDocument = gql`
  mutation createCoolingTower($locationId: uuid!, $name: String!, $numberOfPhases: Int!) {
    createCoolingTower(
      object: {
        numPhases: $numberOfPhases
        name: $name
        position: {
          data: {
            positionName: $name
            positionType: "cooling_tower"
            positionFunction: "Energy_consumer"
            parentPositionId: $locationId
            locationId: $locationId
          }
        }
      }
    ) {
      id
      name
    }
  }
`;
export type CreateCoolingTowerMutationFn = ApolloReactCommon.MutationFunction<
  CreateCoolingTowerMutation,
  CreateCoolingTowerMutationVariables
>;

/**
 * __useCreateCoolingTowerMutation__
 *
 * To run a mutation, you first call `useCreateCoolingTowerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoolingTowerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoolingTowerMutation, { data, loading, error }] = useCreateCoolingTowerMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      name: // value for 'name'
 *      numberOfPhases: // value for 'numberOfPhases'
 *   },
 * });
 */
export function useCreateCoolingTowerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCoolingTowerMutation, CreateCoolingTowerMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateCoolingTowerMutation, CreateCoolingTowerMutationVariables>(
    CreateCoolingTowerDocument,
    baseOptions
  );
}
export type CreateCoolingTowerMutationHookResult = ReturnType<typeof useCreateCoolingTowerMutation>;
export type CreateCoolingTowerMutationResult = ApolloReactCommon.MutationResult<CreateCoolingTowerMutation>;
export type CreateCoolingTowerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCoolingTowerMutation,
  CreateCoolingTowerMutationVariables
>;
export const KeyListWithNodeMeasurementsDocument = gql`
  subscription keyListWithNodeMeasurements(
    $positionName: String!
    $locationId: uuid!
    $where: sensorflow_positions_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    locationOne(locationId: $locationId) {
      locationId
      keyCategories {
        categoryName
      }
      positionsAggregate: positions_aggregate(where: $where) {
        aggregate {
          count
        }
      }
      searchPositions(
        args: { searchbykeyname: $positionName, searchbyroomname: "%%" }
        where: $where
        limit: $limit
        offset: $offset
      ) {
        positionId
        positionName
        keyCategoryPositionMapping {
          categoryName
        }
        rooms {
          positionId
          positionName
          nodeMeasurements {
            occupancyState
            door
            acMode
            acSetPoint
            fanSpeed
            ambientTemperature
            ambientHumidity
            acStatus
          }
          positionConfiguration(where: { recordType: { _eq: "CURRENT" } }) {
            automationMode
            isDeviatedFromDefaultConfig
          }
          precoolCommands(where: { status: { _eq: "SUCCESS" } }, order_by: [{ creationDate: desc }], limit: 1) {
            totalMinutes
            creationDate
          }
          slotMappings {
            nodeType
            nodeMacId
          }
        }
      }
    }
  }
`;

/**
 * __useKeyListWithNodeMeasurementsSubscription__
 *
 * To run a query within a React component, call `useKeyListWithNodeMeasurementsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useKeyListWithNodeMeasurementsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyListWithNodeMeasurementsSubscription({
 *   variables: {
 *      positionName: // value for 'positionName'
 *      locationId: // value for 'locationId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useKeyListWithNodeMeasurementsSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    KeyListWithNodeMeasurementsSubscription,
    KeyListWithNodeMeasurementsSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    KeyListWithNodeMeasurementsSubscription,
    KeyListWithNodeMeasurementsSubscriptionVariables
  >(KeyListWithNodeMeasurementsDocument, baseOptions);
}
export type KeyListWithNodeMeasurementsSubscriptionHookResult = ReturnType<
  typeof useKeyListWithNodeMeasurementsSubscription
>;
export type KeyListWithNodeMeasurementsSubscriptionResult = ApolloReactCommon.SubscriptionResult<KeyListWithNodeMeasurementsSubscription>;
export const TemporaryKeyListWithNodeMeasurementsDocument = gql`
  query temporaryKeyListWithNodeMeasurements(
    $positionName: String!
    $locationId: uuid!
    $where: sensorflow_positions_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    locationOne(locationId: $locationId) {
      locationId
      keyCategories {
        categoryName
      }
      positionsAggregate: positions_aggregate(where: $where) {
        aggregate {
          count
        }
      }
      locationKeys: searchPositions(
        args: { searchbykeyname: $positionName, searchbyroomname: "%%" }
        where: $where
        limit: $limit
        offset: $offset
      ) {
        positionId
        positionName
        keyCategoryPositionMapping {
          categoryName
        }
        rooms {
          positionId
          positionName
          positionConfiguration(where: { recordType: { _eq: "CURRENT" } }) {
            isDeviatedFromDefaultConfig
          }
        }
      }
    }
  }
`;

/**
 * __useTemporaryKeyListWithNodeMeasurementsQuery__
 *
 * To run a query within a React component, call `useTemporaryKeyListWithNodeMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryKeyListWithNodeMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryKeyListWithNodeMeasurementsQuery({
 *   variables: {
 *      positionName: // value for 'positionName'
 *      locationId: // value for 'locationId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useTemporaryKeyListWithNodeMeasurementsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TemporaryKeyListWithNodeMeasurementsQuery,
    TemporaryKeyListWithNodeMeasurementsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TemporaryKeyListWithNodeMeasurementsQuery,
    TemporaryKeyListWithNodeMeasurementsQueryVariables
  >(TemporaryKeyListWithNodeMeasurementsDocument, baseOptions);
}
export function useTemporaryKeyListWithNodeMeasurementsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TemporaryKeyListWithNodeMeasurementsQuery,
    TemporaryKeyListWithNodeMeasurementsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TemporaryKeyListWithNodeMeasurementsQuery,
    TemporaryKeyListWithNodeMeasurementsQueryVariables
  >(TemporaryKeyListWithNodeMeasurementsDocument, baseOptions);
}
export type TemporaryKeyListWithNodeMeasurementsQueryHookResult = ReturnType<
  typeof useTemporaryKeyListWithNodeMeasurementsQuery
>;
export type TemporaryKeyListWithNodeMeasurementsLazyQueryHookResult = ReturnType<
  typeof useTemporaryKeyListWithNodeMeasurementsLazyQuery
>;
export type TemporaryKeyListWithNodeMeasurementsQueryResult = ApolloReactCommon.QueryResult<
  TemporaryKeyListWithNodeMeasurementsQuery,
  TemporaryKeyListWithNodeMeasurementsQueryVariables
>;
export const CustomerKeyListWithNodeMeasurementsDocument = gql`
  subscription customerKeyListWithNodeMeasurements($where: sensorflow_positions_bool_exp!) {
    keyList: positions(where: $where) {
      positionId
      parentPosition {
        positionId
        positionName
        positionType
        positionFunction
      }
      rooms {
        positionId
        nodeMeasurements {
          occupancyState
          door
          acMode
          acSetPoint
          fanSpeed
          ambientTemperature
          ambientHumidity
          acStatus
        }
        positionConfiguration(where: { recordType: { _eq: "CURRENT" } }) {
          automationMode
        }
        precoolCommands(
          where: { status: { _in: ["SUCCESS", "SCHEDULED", "ACCEPTED", "PENDING"] } }
          order_by: [{ creationDate: desc }]
          limit: 1
        ) {
          status
          startedAt
          totalMinutes
          creationDate
        }
        slotMappings {
          nodeType
          nodeMacId
        }
      }
      reservation(limit: 1, order_by: { id: desc }) {
        checkInAt
        checkOutAt
        id
        keyPositionId
        status
        comfortplusActivations {
          precoolDailyLimit
        }
        reservationGuests {
          user {
            userId
            name
            email
          }
          comfortplusAccessToken
        }
      }
    }
  }
`;

/**
 * __useCustomerKeyListWithNodeMeasurementsSubscription__
 *
 * To run a query within a React component, call `useCustomerKeyListWithNodeMeasurementsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCustomerKeyListWithNodeMeasurementsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerKeyListWithNodeMeasurementsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomerKeyListWithNodeMeasurementsSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    CustomerKeyListWithNodeMeasurementsSubscription,
    CustomerKeyListWithNodeMeasurementsSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    CustomerKeyListWithNodeMeasurementsSubscription,
    CustomerKeyListWithNodeMeasurementsSubscriptionVariables
  >(CustomerKeyListWithNodeMeasurementsDocument, baseOptions);
}
export type CustomerKeyListWithNodeMeasurementsSubscriptionHookResult = ReturnType<
  typeof useCustomerKeyListWithNodeMeasurementsSubscription
>;
export type CustomerKeyListWithNodeMeasurementsSubscriptionResult = ApolloReactCommon.SubscriptionResult<CustomerKeyListWithNodeMeasurementsSubscription>;
export const LocationKeysAndRoomsDocument = gql`
  query locationKeysAndRooms($locationId: uuid!) {
    positions(where: { locationId: { _eq: $locationId }, positionType: { _eq: "key" } }) {
      positionId
      positionName
      rooms(where: { positionType: { _eq: "room" }, deletedAt: { _is_null: true } }) {
        positionId
        positionName
      }
    }
  }
`;

/**
 * __useLocationKeysAndRoomsQuery__
 *
 * To run a query within a React component, call `useLocationKeysAndRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationKeysAndRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationKeysAndRoomsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationKeysAndRoomsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationKeysAndRoomsQuery, LocationKeysAndRoomsQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationKeysAndRoomsQuery, LocationKeysAndRoomsQueryVariables>(
    LocationKeysAndRoomsDocument,
    baseOptions
  );
}
export function useLocationKeysAndRoomsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationKeysAndRoomsQuery, LocationKeysAndRoomsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationKeysAndRoomsQuery, LocationKeysAndRoomsQueryVariables>(
    LocationKeysAndRoomsDocument,
    baseOptions
  );
}
export type LocationKeysAndRoomsQueryHookResult = ReturnType<typeof useLocationKeysAndRoomsQuery>;
export type LocationKeysAndRoomsLazyQueryHookResult = ReturnType<typeof useLocationKeysAndRoomsLazyQuery>;
export type LocationKeysAndRoomsQueryResult = ApolloReactCommon.QueryResult<
  LocationKeysAndRoomsQuery,
  LocationKeysAndRoomsQueryVariables
>;
export const LocationGroupsAndKeysDocument = gql`
  query locationGroupsAndKeys($locationId: uuid!) {
    positions(where: { locationId: { _eq: $locationId }, positionType: { _eq: "root" } }) {
      positionId
      positionName
      rooms(
        where: {
          _or: [
            { positionType: { _in: ["group", "zone", "floor"] } }
            { positionType: { _eq: "key" }, parentPositionId: { _eq: $locationId } }
          ]
        }
        order_by: { positionFunction: asc, positionName: asc }
      ) {
        positionId
        positionName
        rooms(where: { positionType: { _eq: "key" } }, order_by: { positionName: asc }) {
          positionId
          positionName
        }
      }
    }
  }
`;

/**
 * __useLocationGroupsAndKeysQuery__
 *
 * To run a query within a React component, call `useLocationGroupsAndKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationGroupsAndKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationGroupsAndKeysQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationGroupsAndKeysQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationGroupsAndKeysQuery, LocationGroupsAndKeysQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationGroupsAndKeysQuery, LocationGroupsAndKeysQueryVariables>(
    LocationGroupsAndKeysDocument,
    baseOptions
  );
}
export function useLocationGroupsAndKeysLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationGroupsAndKeysQuery, LocationGroupsAndKeysQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationGroupsAndKeysQuery, LocationGroupsAndKeysQueryVariables>(
    LocationGroupsAndKeysDocument,
    baseOptions
  );
}
export type LocationGroupsAndKeysQueryHookResult = ReturnType<typeof useLocationGroupsAndKeysQuery>;
export type LocationGroupsAndKeysLazyQueryHookResult = ReturnType<typeof useLocationGroupsAndKeysLazyQuery>;
export type LocationGroupsAndKeysQueryResult = ApolloReactCommon.QueryResult<
  LocationGroupsAndKeysQuery,
  LocationGroupsAndKeysQueryVariables
>;
export const InfrastructuresDocument = gql`
  query infrastructures($where: sensorflow_v_infrastructures_bool_exp!, $offset: Int!, $limit: Int!) {
    infrastructures(where: $where, offset: $offset, limit: $limit, order_by: { name: asc }) {
      id
      meterPositionId
      name
      type
      phaseStreamIndex
      rooms: compressorPositionMappings {
        position {
          parentPosition {
            positionName
          }
        }
      }
    }
  }
`;

/**
 * __useInfrastructuresQuery__
 *
 * To run a query within a React component, call `useInfrastructuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfrastructuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfrastructuresQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useInfrastructuresQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<InfrastructuresQuery, InfrastructuresQueryVariables>
) {
  return ApolloReactHooks.useQuery<InfrastructuresQuery, InfrastructuresQueryVariables>(
    InfrastructuresDocument,
    baseOptions
  );
}
export function useInfrastructuresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InfrastructuresQuery, InfrastructuresQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<InfrastructuresQuery, InfrastructuresQueryVariables>(
    InfrastructuresDocument,
    baseOptions
  );
}
export type InfrastructuresQueryHookResult = ReturnType<typeof useInfrastructuresQuery>;
export type InfrastructuresLazyQueryHookResult = ReturnType<typeof useInfrastructuresLazyQuery>;
export type InfrastructuresQueryResult = ApolloReactCommon.QueryResult<
  InfrastructuresQuery,
  InfrastructuresQueryVariables
>;
export const InfrastructuresWithOrderByAndTotalCountDocument = gql`
  query infrastructuresWithOrderByAndTotalCount(
    $where: sensorflow_v_infrastructures_bool_exp!
    $offset: Int!
    $limit: Int!
    $order_by: [sensorflow_v_infrastructures_order_by!]
  ) {
    infrastructures(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      id
      meterPositionId
      name
      type
      phaseStreamIndex
      position {
        positionId
        positionName
        positionType
      }
      rooms: compressorPositionMappings {
        position {
          parentPosition {
            positionName
          }
        }
      }
    }
    sensorflow_v_infrastructures_aggregate: sensorflow_v_infrastructures_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useInfrastructuresWithOrderByAndTotalCountQuery__
 *
 * To run a query within a React component, call `useInfrastructuresWithOrderByAndTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfrastructuresWithOrderByAndTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfrastructuresWithOrderByAndTotalCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useInfrastructuresWithOrderByAndTotalCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InfrastructuresWithOrderByAndTotalCountQuery,
    InfrastructuresWithOrderByAndTotalCountQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    InfrastructuresWithOrderByAndTotalCountQuery,
    InfrastructuresWithOrderByAndTotalCountQueryVariables
  >(InfrastructuresWithOrderByAndTotalCountDocument, baseOptions);
}
export function useInfrastructuresWithOrderByAndTotalCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InfrastructuresWithOrderByAndTotalCountQuery,
    InfrastructuresWithOrderByAndTotalCountQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    InfrastructuresWithOrderByAndTotalCountQuery,
    InfrastructuresWithOrderByAndTotalCountQueryVariables
  >(InfrastructuresWithOrderByAndTotalCountDocument, baseOptions);
}
export type InfrastructuresWithOrderByAndTotalCountQueryHookResult = ReturnType<
  typeof useInfrastructuresWithOrderByAndTotalCountQuery
>;
export type InfrastructuresWithOrderByAndTotalCountLazyQueryHookResult = ReturnType<
  typeof useInfrastructuresWithOrderByAndTotalCountLazyQuery
>;
export type InfrastructuresWithOrderByAndTotalCountQueryResult = ApolloReactCommon.QueryResult<
  InfrastructuresWithOrderByAndTotalCountQuery,
  InfrastructuresWithOrderByAndTotalCountQueryVariables
>;
export const InfrastructureWithMeterPositionDocument = gql`
  subscription infrastructureWithMeterPosition($id: uuid!) {
    infrastructures(where: { id: { _eq: $id } }, limit: 1) {
      meterPositionId
      meterPosition {
        nodes: nodeJoinStatuses {
          nodeMacId
          nodeType
          slotName
          lastSeen
          status
          RSSI
          SNR
          mappedTime
          signalStrength
          mappedGateway {
            gatewayName
          }
          joinedGateway {
            gatewayName
          }
        }
      }
    }
  }
`;

/**
 * __useInfrastructureWithMeterPositionSubscription__
 *
 * To run a query within a React component, call `useInfrastructureWithMeterPositionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInfrastructureWithMeterPositionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfrastructureWithMeterPositionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInfrastructureWithMeterPositionSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    InfrastructureWithMeterPositionSubscription,
    InfrastructureWithMeterPositionSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    InfrastructureWithMeterPositionSubscription,
    InfrastructureWithMeterPositionSubscriptionVariables
  >(InfrastructureWithMeterPositionDocument, baseOptions);
}
export type InfrastructureWithMeterPositionSubscriptionHookResult = ReturnType<
  typeof useInfrastructureWithMeterPositionSubscription
>;
export type InfrastructureWithMeterPositionSubscriptionResult = ApolloReactCommon.SubscriptionResult<InfrastructureWithMeterPositionSubscription>;
export const InfrastructureDocument = gql`
  subscription infrastructure($id: uuid!) {
    infrastructures(where: { id: { _eq: $id } }, limit: 1) {
      id
      name
      type
      rooms: compressorPositionMappings {
        position {
          positionId
          positionName
          parentPosition {
            positionName
          }
        }
      }
      meterPosition {
        nodes: nodeJoinStatuses {
          nodeMacId
          nodeType
          slotName
          lastSeen
          status
          RSSI
          SNR
          mappedTime
          mappedGateway {
            gatewayName
          }
          joinedGateway {
            gatewayName
          }
        }
        positionConfiguration(where: { recordType: { _eq: "CURRENT" } }) {
          desiredEmonSwitchPosition
        }
        nodeSlots(order_by: { slotCreationDate: desc }) {
          slotName
          nodeType
        }
      }
    }
  }
`;

/**
 * __useInfrastructureSubscription__
 *
 * To run a query within a React component, call `useInfrastructureSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInfrastructureSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfrastructureSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInfrastructureSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    InfrastructureSubscription,
    InfrastructureSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<InfrastructureSubscription, InfrastructureSubscriptionVariables>(
    InfrastructureDocument,
    baseOptions
  );
}
export type InfrastructureSubscriptionHookResult = ReturnType<typeof useInfrastructureSubscription>;
export type InfrastructureSubscriptionResult = ApolloReactCommon.SubscriptionResult<InfrastructureSubscription>;
export const TemporaryInfrastructureDocument = gql`
  query temporaryInfrastructure($id: uuid!) {
    infrastructures(where: { id: { _eq: $id } }, limit: 1) {
      id
      name
      type
      hvacSystemType
      ctMultiplier
      meterPositionId
      coolingSystem {
        compressorId
        compressorName
      }
      position {
        positionId
        positionName
      }
      rooms: compressorPositionMappings {
        position {
          positionId
          positionName
          parentPosition {
            positionName
          }
        }
      }
      meterPosition {
        nodes: nodeJoinStatuses {
          nodeMacId
          nodeType
          slotName
          positionId
          lastSeen
          status
          signalStrength
          mappedTime
          mappedGateway {
            gatewayName
            gatewayId
          }
          joinedGateway {
            gatewayName
          }
        }
        positionConfiguration(where: { recordType: { _eq: "CURRENT" } }) {
          desiredEmonSwitchPosition
        }
        nodeSlots(order_by: { slotCreationDate: desc }) {
          slotName
          nodeType
        }
      }
    }
  }
`;

/**
 * __useTemporaryInfrastructureQuery__
 *
 * To run a query within a React component, call `useTemporaryInfrastructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryInfrastructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryInfrastructureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemporaryInfrastructureQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TemporaryInfrastructureQuery, TemporaryInfrastructureQueryVariables>
) {
  return ApolloReactHooks.useQuery<TemporaryInfrastructureQuery, TemporaryInfrastructureQueryVariables>(
    TemporaryInfrastructureDocument,
    baseOptions
  );
}
export function useTemporaryInfrastructureLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TemporaryInfrastructureQuery,
    TemporaryInfrastructureQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TemporaryInfrastructureQuery, TemporaryInfrastructureQueryVariables>(
    TemporaryInfrastructureDocument,
    baseOptions
  );
}
export type TemporaryInfrastructureQueryHookResult = ReturnType<typeof useTemporaryInfrastructureQuery>;
export type TemporaryInfrastructureLazyQueryHookResult = ReturnType<typeof useTemporaryInfrastructureLazyQuery>;
export type TemporaryInfrastructureQueryResult = ApolloReactCommon.QueryResult<
  TemporaryInfrastructureQuery,
  TemporaryInfrastructureQueryVariables
>;
export const UpdateCompressorDocument = gql`
  mutation updateCompressor($id: uuid!, $name: String!, $object: sensorflow_compressors_set_input) {
    updatePositions(where: { infrastructure: { id: { _eq: $id } } }, _set: { positionName: $name }) {
      affected_rows
    }
    updateCompressor(pk_columns: { compressorId: $id }, _set: $object) {
      compressorName
    }
  }
`;
export type UpdateCompressorMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCompressorMutation,
  UpdateCompressorMutationVariables
>;

/**
 * __useUpdateCompressorMutation__
 *
 * To run a mutation, you first call `useUpdateCompressorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompressorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompressorMutation, { data, loading, error }] = useUpdateCompressorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateCompressorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompressorMutation, UpdateCompressorMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateCompressorMutation, UpdateCompressorMutationVariables>(
    UpdateCompressorDocument,
    baseOptions
  );
}
export type UpdateCompressorMutationHookResult = ReturnType<typeof useUpdateCompressorMutation>;
export type UpdateCompressorMutationResult = ApolloReactCommon.MutationResult<UpdateCompressorMutation>;
export type UpdateCompressorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCompressorMutation,
  UpdateCompressorMutationVariables
>;
export const UpdateChillerDocument = gql`
  mutation updateChiller($id: uuid!, $name: String!, $object: sensorflow_chillers_set_input) {
    updatePositions(where: { infrastructure: { id: { _eq: $id } } }, _set: { positionName: $name }) {
      affected_rows
    }
    updateChiller(pk_columns: { id: $id }, _set: $object) {
      name
    }
  }
`;
export type UpdateChillerMutationFn = ApolloReactCommon.MutationFunction<
  UpdateChillerMutation,
  UpdateChillerMutationVariables
>;

/**
 * __useUpdateChillerMutation__
 *
 * To run a mutation, you first call `useUpdateChillerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChillerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChillerMutation, { data, loading, error }] = useUpdateChillerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateChillerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateChillerMutation, UpdateChillerMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateChillerMutation, UpdateChillerMutationVariables>(
    UpdateChillerDocument,
    baseOptions
  );
}
export type UpdateChillerMutationHookResult = ReturnType<typeof useUpdateChillerMutation>;
export type UpdateChillerMutationResult = ApolloReactCommon.MutationResult<UpdateChillerMutation>;
export type UpdateChillerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateChillerMutation,
  UpdateChillerMutationVariables
>;
export const UpdateCoolingTowerDocument = gql`
  mutation updateCoolingTower($id: uuid!, $name: String!, $object: sensorflow_cooling_towers_set_input) {
    updatePositions(where: { infrastructure: { id: { _eq: $id } } }, _set: { positionName: $name }) {
      affected_rows
    }
    updateCoolingTower(pk_columns: { id: $id }, _set: $object) {
      name
    }
  }
`;
export type UpdateCoolingTowerMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCoolingTowerMutation,
  UpdateCoolingTowerMutationVariables
>;

/**
 * __useUpdateCoolingTowerMutation__
 *
 * To run a mutation, you first call `useUpdateCoolingTowerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoolingTowerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoolingTowerMutation, { data, loading, error }] = useUpdateCoolingTowerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateCoolingTowerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCoolingTowerMutation, UpdateCoolingTowerMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateCoolingTowerMutation, UpdateCoolingTowerMutationVariables>(
    UpdateCoolingTowerDocument,
    baseOptions
  );
}
export type UpdateCoolingTowerMutationHookResult = ReturnType<typeof useUpdateCoolingTowerMutation>;
export type UpdateCoolingTowerMutationResult = ApolloReactCommon.MutationResult<UpdateCoolingTowerMutation>;
export type UpdateCoolingTowerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCoolingTowerMutation,
  UpdateCoolingTowerMutationVariables
>;
export const UpdatePipeDocument = gql`
  mutation updatePipe($id: uuid!, $name: String!, $object: sensorflow_pipes_set_input) {
    updatePositions(where: { infrastructure: { id: { _eq: $id } } }, _set: { positionName: $name }) {
      affected_rows
    }
    updatePipe(pk_columns: { id: $id }, _set: $object) {
      name
    }
  }
`;
export type UpdatePipeMutationFn = ApolloReactCommon.MutationFunction<UpdatePipeMutation, UpdatePipeMutationVariables>;

/**
 * __useUpdatePipeMutation__
 *
 * To run a mutation, you first call `useUpdatePipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipeMutation, { data, loading, error }] = useUpdatePipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdatePipeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePipeMutation, UpdatePipeMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdatePipeMutation, UpdatePipeMutationVariables>(UpdatePipeDocument, baseOptions);
}
export type UpdatePipeMutationHookResult = ReturnType<typeof useUpdatePipeMutation>;
export type UpdatePipeMutationResult = ApolloReactCommon.MutationResult<UpdatePipeMutation>;
export type UpdatePipeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePipeMutation,
  UpdatePipeMutationVariables
>;
export const UpdatePumpDocument = gql`
  mutation updatePump($id: uuid!, $name: String!, $object: sensorflow_pumps_set_input) {
    updatePositions(where: { infrastructure: { id: { _eq: $id } } }, _set: { positionName: $name }) {
      affected_rows
    }
    updatePump(pk_columns: { id: $id }, _set: $object) {
      name
    }
  }
`;
export type UpdatePumpMutationFn = ApolloReactCommon.MutationFunction<UpdatePumpMutation, UpdatePumpMutationVariables>;

/**
 * __useUpdatePumpMutation__
 *
 * To run a mutation, you first call `useUpdatePumpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePumpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePumpMutation, { data, loading, error }] = useUpdatePumpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdatePumpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePumpMutation, UpdatePumpMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdatePumpMutation, UpdatePumpMutationVariables>(UpdatePumpDocument, baseOptions);
}
export type UpdatePumpMutationHookResult = ReturnType<typeof useUpdatePumpMutation>;
export type UpdatePumpMutationResult = ApolloReactCommon.MutationResult<UpdatePumpMutation>;
export type UpdatePumpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePumpMutation,
  UpdatePumpMutationVariables
>;
export const UpsertCompressorPositionMappingsDocument = gql`
  mutation upsertCompressorPositionMappings(
    $compressorId: uuid!
    $mappingsInput: [sensorflow_compressor_mapping_insert_input!]!
  ) {
    deleteCompressorPositionMappings(where: { compressorId: { _eq: $compressorId } }) {
      affected_rows
    }
    createCompressorPositionMappings(objects: $mappingsInput) {
      affected_rows
    }
  }
`;
export type UpsertCompressorPositionMappingsMutationFn = ApolloReactCommon.MutationFunction<
  UpsertCompressorPositionMappingsMutation,
  UpsertCompressorPositionMappingsMutationVariables
>;

/**
 * __useUpsertCompressorPositionMappingsMutation__
 *
 * To run a mutation, you first call `useUpsertCompressorPositionMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCompressorPositionMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCompressorPositionMappingsMutation, { data, loading, error }] = useUpsertCompressorPositionMappingsMutation({
 *   variables: {
 *      compressorId: // value for 'compressorId'
 *      mappingsInput: // value for 'mappingsInput'
 *   },
 * });
 */
export function useUpsertCompressorPositionMappingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertCompressorPositionMappingsMutation,
    UpsertCompressorPositionMappingsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpsertCompressorPositionMappingsMutation,
    UpsertCompressorPositionMappingsMutationVariables
  >(UpsertCompressorPositionMappingsDocument, baseOptions);
}
export type UpsertCompressorPositionMappingsMutationHookResult = ReturnType<
  typeof useUpsertCompressorPositionMappingsMutation
>;
export type UpsertCompressorPositionMappingsMutationResult = ApolloReactCommon.MutationResult<UpsertCompressorPositionMappingsMutation>;
export type UpsertCompressorPositionMappingsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertCompressorPositionMappingsMutation,
  UpsertCompressorPositionMappingsMutationVariables
>;
export const PositionConfigurationsByKeyDocument = gql`
  query positionConfigurationsByKey($keyId: uuid!) {
    position(positionId: $keyId) {
      positionId
      positionName
      rooms(where: { deletedAt: { _is_null: true } }) {
        positionId
        positionName
        positionConfiguration(where: { recordType: { _in: ["CURRENT", "DEFAULT"] } }) {
          automationMode
          isDeviatedFromDefaultConfig
          creationDate
          occNightStart
          occNightEnd
          occTimeoutNightMins
          occTimeoutDayMins
          occupancySensitivity
          doorSensorTimeoutMins
          softstartWaitTime
          hysteresisOffDegrees
          hysteresisOnDegrees
          hideSetpoint
          heatingHideSetpoint
          unoccupiedTemp
          heatingUnoccupiedTemp
          minTemp
          maxTemp
          heatingMinTemp
          heatingMaxTemp
          acNightEnd
          acNightStart
          actuationTimeoutSeconds
          bridgeAdaptorSetting
          desiredEmonSwitchPosition
          freezeProtectionTemp
          modifiedByUser {
            userId
            name
          }
          recordType
          actingMode
          operationalMode
          expiredAt
          enableDoorSensor
          nightTimeSetpointEnabled
          nightTimeSetpointDOW
          nightTimeSetpointDelay
          nightTimeSetpointStart
          nightTimeSetpointEnd
          nightTimeSetpointOffset
          nightTimeSetpointMaxSetpoint
          forceOccupancyAutomation
        }
      }
    }
  }
`;

/**
 * __usePositionConfigurationsByKeyQuery__
 *
 * To run a query within a React component, call `usePositionConfigurationsByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionConfigurationsByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionConfigurationsByKeyQuery({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function usePositionConfigurationsByKeyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PositionConfigurationsByKeyQuery,
    PositionConfigurationsByKeyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PositionConfigurationsByKeyQuery, PositionConfigurationsByKeyQueryVariables>(
    PositionConfigurationsByKeyDocument,
    baseOptions
  );
}
export function usePositionConfigurationsByKeyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PositionConfigurationsByKeyQuery,
    PositionConfigurationsByKeyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PositionConfigurationsByKeyQuery, PositionConfigurationsByKeyQueryVariables>(
    PositionConfigurationsByKeyDocument,
    baseOptions
  );
}
export type PositionConfigurationsByKeyQueryHookResult = ReturnType<typeof usePositionConfigurationsByKeyQuery>;
export type PositionConfigurationsByKeyLazyQueryHookResult = ReturnType<typeof usePositionConfigurationsByKeyLazyQuery>;
export type PositionConfigurationsByKeyQueryResult = ApolloReactCommon.QueryResult<
  PositionConfigurationsByKeyQuery,
  PositionConfigurationsByKeyQueryVariables
>;
export const CreatePrecoolCommandsDocument = gql`
  mutation createPrecoolCommands($precoolCommands: [sensorflow_precool_commands_insert_input!]!) {
    createPrecoolCommands(objects: $precoolCommands) {
      returning {
        id
        totalMinutes
      }
    }
  }
`;
export type CreatePrecoolCommandsMutationFn = ApolloReactCommon.MutationFunction<
  CreatePrecoolCommandsMutation,
  CreatePrecoolCommandsMutationVariables
>;

/**
 * __useCreatePrecoolCommandsMutation__
 *
 * To run a mutation, you first call `useCreatePrecoolCommandsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrecoolCommandsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrecoolCommandsMutation, { data, loading, error }] = useCreatePrecoolCommandsMutation({
 *   variables: {
 *      precoolCommands: // value for 'precoolCommands'
 *   },
 * });
 */
export function useCreatePrecoolCommandsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePrecoolCommandsMutation,
    CreatePrecoolCommandsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreatePrecoolCommandsMutation, CreatePrecoolCommandsMutationVariables>(
    CreatePrecoolCommandsDocument,
    baseOptions
  );
}
export type CreatePrecoolCommandsMutationHookResult = ReturnType<typeof useCreatePrecoolCommandsMutation>;
export type CreatePrecoolCommandsMutationResult = ApolloReactCommon.MutationResult<CreatePrecoolCommandsMutation>;
export type CreatePrecoolCommandsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePrecoolCommandsMutation,
  CreatePrecoolCommandsMutationVariables
>;
export const CreateAcSettingCommandsDocument = gql`
  mutation createACSettingCommands($acSettingCommands: [sensorflow_ac_setting_commands_insert_input!]!) {
    createACSettingCommands(objects: $acSettingCommands) {
      returning {
        id
        fromACStatus
        toACStatus
        fromACSetPoint
        toACSetPoint
        fromACMode
        toACMode
        fromFanSpeed
        toFanSpeed
      }
    }
  }
`;
export type CreateAcSettingCommandsMutationFn = ApolloReactCommon.MutationFunction<
  CreateAcSettingCommandsMutation,
  CreateAcSettingCommandsMutationVariables
>;

/**
 * __useCreateAcSettingCommandsMutation__
 *
 * To run a mutation, you first call `useCreateAcSettingCommandsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAcSettingCommandsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAcSettingCommandsMutation, { data, loading, error }] = useCreateAcSettingCommandsMutation({
 *   variables: {
 *      acSettingCommands: // value for 'acSettingCommands'
 *   },
 * });
 */
export function useCreateAcSettingCommandsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateAcSettingCommandsMutation,
    CreateAcSettingCommandsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateAcSettingCommandsMutation, CreateAcSettingCommandsMutationVariables>(
    CreateAcSettingCommandsDocument,
    baseOptions
  );
}
export type CreateAcSettingCommandsMutationHookResult = ReturnType<typeof useCreateAcSettingCommandsMutation>;
export type CreateAcSettingCommandsMutationResult = ApolloReactCommon.MutationResult<CreateAcSettingCommandsMutation>;
export type CreateAcSettingCommandsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateAcSettingCommandsMutation,
  CreateAcSettingCommandsMutationVariables
>;
export const KeyDetailWithNodeMeasurementsDocument = gql`
  subscription keyDetailWithNodeMeasurements($keyId: uuid!) {
    position(positionId: $keyId) {
      rooms(where: { deletedAt: { _is_null: true } }) {
        positionId
        positionName
        nodeMeasurements {
          ambientTemperature
          ambientHumidity
          door
          occupancyState
          acStatus
          acMode
          acSetPoint
          fanSpeed
          automationStatus
        }
        positionConfiguration(where: { recordType: { _eq: "CURRENT" } }) {
          minTemp
          maxTemp
          heatingMinTemp
          heatingMaxTemp
        }
        precoolCommands(
          where: { status: { _in: ["SUCCESS", "SCHEDULED"] } }
          order_by: [{ creationDate: desc }]
          limit: 1
        ) {
          totalMinutes
          creationDate
          status
          startedAt
        }
        acSettingCommands(where: { status: { _eq: "SUCCESS" } }, order_by: [{ creationDate: desc }], limit: 1) {
          creationDate
          createdByUser {
            userId
            name
          }
        }
        lastACSettingCommands: acSettingCommands(order_by: [{ creationDate: desc }], limit: 1) {
          status
          creationDate
          toACStatus
          toACSetPoint
          toACMode
          toFanSpeed
        }
        slotMappings {
          nodeType
          nodeMacId
        }
      }
    }
  }
`;

/**
 * __useKeyDetailWithNodeMeasurementsSubscription__
 *
 * To run a query within a React component, call `useKeyDetailWithNodeMeasurementsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useKeyDetailWithNodeMeasurementsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyDetailWithNodeMeasurementsSubscription({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useKeyDetailWithNodeMeasurementsSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    KeyDetailWithNodeMeasurementsSubscription,
    KeyDetailWithNodeMeasurementsSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    KeyDetailWithNodeMeasurementsSubscription,
    KeyDetailWithNodeMeasurementsSubscriptionVariables
  >(KeyDetailWithNodeMeasurementsDocument, baseOptions);
}
export type KeyDetailWithNodeMeasurementsSubscriptionHookResult = ReturnType<
  typeof useKeyDetailWithNodeMeasurementsSubscription
>;
export type KeyDetailWithNodeMeasurementsSubscriptionResult = ApolloReactCommon.SubscriptionResult<KeyDetailWithNodeMeasurementsSubscription>;
export const ActivityLogsDocument = gql`
  subscription activityLogs($keyId: uuid!, $fromDate: timestamptz!, $toDate: timestamptz!) {
    key: position(positionId: $keyId) {
      positionName
      rooms(where: { deletedAt: { _is_null: true } }) {
        positionId
        positionName
        acSettingCommands(
          where: { status: { _eq: "SUCCESS" }, creationDate: { _gte: $fromDate, _lte: $toDate } }
          order_by: { creationDate: desc }
        ) {
          source
          createdByUser {
            userId
            name
          }
          fromACStatus
          toACStatus
          fromACSetPoint
          toACSetPoint
          fromACMode
          toACMode
          fromFanSpeed
          toFanSpeed
          creationDate
        }
        positionConfiguration: positionConfigurationsForActivityLog(
          where: {
            creationDate: { _lte: $toDate }
            _or: [{ nextPosConfigCreationDate: { _gte: $fromDate } }, { nextPosConfigCreationDate: { _is_null: true } }]
          }
          order_by: { creationDate: desc }
        ) {
          automationMode
          isDeviatedFromDefaultConfig
          creationDate
          occNightStart
          occNightEnd
          occTimeoutNightMins
          occTimeoutDayMins
          occupancySensitivity
          doorSensorTimeoutMins
          softstartWaitTime
          hysteresisOffDegrees
          hysteresisOnDegrees
          hideSetpoint
          heatingHideSetpoint
          unoccupiedTemp
          minTemp
          maxTemp
          heatingMinTemp
          heatingMaxTemp
          acNightEnd
          acNightStart
          actuationTimeoutSeconds
          bridgeAdaptorSetting
          desiredEmonSwitchPosition
          modifiedByUser {
            userId
            name
          }
        }
      }
    }
  }
`;

/**
 * __useActivityLogsSubscription__
 *
 * To run a query within a React component, call `useActivityLogsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActivityLogsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityLogsSubscription({
 *   variables: {
 *      keyId: // value for 'keyId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useActivityLogsSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ActivityLogsSubscription, ActivityLogsSubscriptionVariables>
) {
  return ApolloReactHooks.useSubscription<ActivityLogsSubscription, ActivityLogsSubscriptionVariables>(
    ActivityLogsDocument,
    baseOptions
  );
}
export type ActivityLogsSubscriptionHookResult = ReturnType<typeof useActivityLogsSubscription>;
export type ActivityLogsSubscriptionResult = ApolloReactCommon.SubscriptionResult<ActivityLogsSubscription>;
export const TemporaryActivityLogsDocument = gql`
  query temporaryActivityLogs($keyId: uuid!, $fromDate: timestamptz!, $toDate: timestamptz!) {
    key: position(positionId: $keyId) {
      positionName
      rooms {
        positionId
        positionName
        acSettingCommands(
          where: { status: { _eq: "SUCCESS" }, creationDate: { _gte: $fromDate, _lte: $toDate } }
          order_by: { creationDate: desc }
        ) {
          source
          createdByUser {
            userId
            name
          }
          fromACStatus
          toACStatus
          fromACSetPoint
          toACSetPoint
          fromACMode
          toACMode
          fromFanSpeed
          toFanSpeed
          creationDate
        }
        positionConfiguration(
          where: { recordType: { _in: ["CURRENT", "ARCHIVE"] }, creationDate: { _gte: $fromDate, _lte: $toDate } }
          order_by: { creationDate: desc }
        ) {
          automationMode
          isDeviatedFromDefaultConfig
          creationDate
          occNightStart
          occNightEnd
          occTimeoutNightMins
          occTimeoutDayMins
          occupancySensitivity
          doorSensorTimeoutMins
          softstartWaitTime
          hysteresisOffDegrees
          hysteresisOnDegrees
          hideSetpoint
          heatingHideSetpoint
          unoccupiedTemp
          minTemp
          maxTemp
          heatingMinTemp
          heatingMaxTemp
          acNightEnd
          acNightStart
          actuationTimeoutSeconds
          bridgeAdaptorSetting
          desiredEmonSwitchPosition
          modifiedByUser {
            userId
            name
          }
        }
      }
    }
  }
`;

/**
 * __useTemporaryActivityLogsQuery__
 *
 * To run a query within a React component, call `useTemporaryActivityLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryActivityLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryActivityLogsQuery({
 *   variables: {
 *      keyId: // value for 'keyId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useTemporaryActivityLogsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TemporaryActivityLogsQuery, TemporaryActivityLogsQueryVariables>
) {
  return ApolloReactHooks.useQuery<TemporaryActivityLogsQuery, TemporaryActivityLogsQueryVariables>(
    TemporaryActivityLogsDocument,
    baseOptions
  );
}
export function useTemporaryActivityLogsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemporaryActivityLogsQuery, TemporaryActivityLogsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<TemporaryActivityLogsQuery, TemporaryActivityLogsQueryVariables>(
    TemporaryActivityLogsDocument,
    baseOptions
  );
}
export type TemporaryActivityLogsQueryHookResult = ReturnType<typeof useTemporaryActivityLogsQuery>;
export type TemporaryActivityLogsLazyQueryHookResult = ReturnType<typeof useTemporaryActivityLogsLazyQuery>;
export type TemporaryActivityLogsQueryResult = ApolloReactCommon.QueryResult<
  TemporaryActivityLogsQuery,
  TemporaryActivityLogsQueryVariables
>;
export const ActivityLogsEventStreamDocument = gql`
  subscription activityLogsEventStream($positionId: uuid!, $fromDate: timestamptz!, $toDate: timestamptz!) {
    sensorflow_f_get_event_stream(
      args: { from_date: $fromDate, to_date: $toDate, positionid: $positionId }
      where: { hasChanged: { _neq: 0 } }
      order_by: { time: desc }
    ) {
      positionId
      eventType
      currentValue
      previousValue
      changedBy
      hasChanged
      time
    }
  }
`;

/**
 * __useActivityLogsEventStreamSubscription__
 *
 * To run a query within a React component, call `useActivityLogsEventStreamSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActivityLogsEventStreamSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityLogsEventStreamSubscription({
 *   variables: {
 *      positionId: // value for 'positionId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useActivityLogsEventStreamSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ActivityLogsEventStreamSubscription,
    ActivityLogsEventStreamSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    ActivityLogsEventStreamSubscription,
    ActivityLogsEventStreamSubscriptionVariables
  >(ActivityLogsEventStreamDocument, baseOptions);
}
export type ActivityLogsEventStreamSubscriptionHookResult = ReturnType<typeof useActivityLogsEventStreamSubscription>;
export type ActivityLogsEventStreamSubscriptionResult = ApolloReactCommon.SubscriptionResult<ActivityLogsEventStreamSubscription>;
export const TemporaryActivityLogsEventStreamDocument = gql`
  query temporaryActivityLogsEventStream($positionId: uuid!, $fromDate: timestamptz!, $toDate: timestamptz!) {
    sensorflow_f_get_event_stream(
      args: { from_date: $fromDate, to_date: $toDate, positionid: $positionId }
      where: { hasChanged: { _neq: 0 } }
      order_by: { time: desc }
    ) {
      positionId
      eventType
      currentValue
      previousValue
      changedBy
      hasChanged
      time
    }
  }
`;

/**
 * __useTemporaryActivityLogsEventStreamQuery__
 *
 * To run a query within a React component, call `useTemporaryActivityLogsEventStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryActivityLogsEventStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryActivityLogsEventStreamQuery({
 *   variables: {
 *      positionId: // value for 'positionId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useTemporaryActivityLogsEventStreamQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TemporaryActivityLogsEventStreamQuery,
    TemporaryActivityLogsEventStreamQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TemporaryActivityLogsEventStreamQuery,
    TemporaryActivityLogsEventStreamQueryVariables
  >(TemporaryActivityLogsEventStreamDocument, baseOptions);
}
export function useTemporaryActivityLogsEventStreamLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TemporaryActivityLogsEventStreamQuery,
    TemporaryActivityLogsEventStreamQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TemporaryActivityLogsEventStreamQuery,
    TemporaryActivityLogsEventStreamQueryVariables
  >(TemporaryActivityLogsEventStreamDocument, baseOptions);
}
export type TemporaryActivityLogsEventStreamQueryHookResult = ReturnType<
  typeof useTemporaryActivityLogsEventStreamQuery
>;
export type TemporaryActivityLogsEventStreamLazyQueryHookResult = ReturnType<
  typeof useTemporaryActivityLogsEventStreamLazyQuery
>;
export type TemporaryActivityLogsEventStreamQueryResult = ApolloReactCommon.QueryResult<
  TemporaryActivityLogsEventStreamQuery,
  TemporaryActivityLogsEventStreamQueryVariables
>;
export const GetAllActivityLogsEventStreamDocument = gql`
  query getAllActivityLogsEventStream($positionId: uuid!, $fromDate: timestamptz!, $toDate: timestamptz!) {
    sensorflow_f_get_all_event_stream(
      args: { from_date: $fromDate, to_date: $toDate, positionid: $positionId }
      order_by: { time: desc }
    ) {
      event_type
      new_ac_settings_value
      previous_ac_settings_value
      modified_by_user
      ac_settings_type_changed
      time
    }
  }
`;

/**
 * __useGetAllActivityLogsEventStreamQuery__
 *
 * To run a query within a React component, call `useGetAllActivityLogsEventStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllActivityLogsEventStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllActivityLogsEventStreamQuery({
 *   variables: {
 *      positionId: // value for 'positionId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetAllActivityLogsEventStreamQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllActivityLogsEventStreamQuery,
    GetAllActivityLogsEventStreamQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAllActivityLogsEventStreamQuery, GetAllActivityLogsEventStreamQueryVariables>(
    GetAllActivityLogsEventStreamDocument,
    baseOptions
  );
}
export function useGetAllActivityLogsEventStreamLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllActivityLogsEventStreamQuery,
    GetAllActivityLogsEventStreamQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAllActivityLogsEventStreamQuery, GetAllActivityLogsEventStreamQueryVariables>(
    GetAllActivityLogsEventStreamDocument,
    baseOptions
  );
}
export type GetAllActivityLogsEventStreamQueryHookResult = ReturnType<typeof useGetAllActivityLogsEventStreamQuery>;
export type GetAllActivityLogsEventStreamLazyQueryHookResult = ReturnType<
  typeof useGetAllActivityLogsEventStreamLazyQuery
>;
export type GetAllActivityLogsEventStreamQueryResult = ApolloReactCommon.QueryResult<
  GetAllActivityLogsEventStreamQuery,
  GetAllActivityLogsEventStreamQueryVariables
>;
export const KeyListWithPositionConfigurationDocument = gql`
  query keyListWithPositionConfiguration(
    $keyName: String
    $autosetName: String
    $locationId: uuid!
    $where: sensorflow_positions_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    locationOne(locationId: $locationId) {
      locationId
      keyCategories {
        categoryName
      }
      positionsAggregate: positions_aggregate(where: $where) {
        aggregate {
          count
        }
      }
      searchPositions(
        args: { searchbykeyname: $keyName, searchbyroomname: $autosetName }
        limit: $limit
        offset: $offset
        where: $where
      ) {
        positionId
        positionName
        keyCategoryPositionMapping {
          categoryName
        }
        searchRooms(args: { searchautosetname: $autosetName, searchkeyname: $keyName }) {
          positionId
          positionName
          positionConfiguration(where: { recordType: { _eq: "CURRENT" } }) {
            automationMode
            isDeviatedFromDefaultConfig
            minTemp
            maxTemp
            heatingMinTemp
            heatingMaxTemp
            hideSetpoint
            heatingHideSetpoint
            acNightStart
            acNightEnd
            unoccupiedTemp
            heatingUnoccupiedTemp
            operationalMode
            actingMode
            expiredAt
          }
        }
      }
    }
  }
`;

/**
 * __useKeyListWithPositionConfigurationQuery__
 *
 * To run a query within a React component, call `useKeyListWithPositionConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyListWithPositionConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyListWithPositionConfigurationQuery({
 *   variables: {
 *      keyName: // value for 'keyName'
 *      autosetName: // value for 'autosetName'
 *      locationId: // value for 'locationId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useKeyListWithPositionConfigurationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    KeyListWithPositionConfigurationQuery,
    KeyListWithPositionConfigurationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    KeyListWithPositionConfigurationQuery,
    KeyListWithPositionConfigurationQueryVariables
  >(KeyListWithPositionConfigurationDocument, baseOptions);
}
export function useKeyListWithPositionConfigurationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    KeyListWithPositionConfigurationQuery,
    KeyListWithPositionConfigurationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    KeyListWithPositionConfigurationQuery,
    KeyListWithPositionConfigurationQueryVariables
  >(KeyListWithPositionConfigurationDocument, baseOptions);
}
export type KeyListWithPositionConfigurationQueryHookResult = ReturnType<
  typeof useKeyListWithPositionConfigurationQuery
>;
export type KeyListWithPositionConfigurationLazyQueryHookResult = ReturnType<
  typeof useKeyListWithPositionConfigurationLazyQuery
>;
export type KeyListWithPositionConfigurationQueryResult = ApolloReactCommon.QueryResult<
  KeyListWithPositionConfigurationQuery,
  KeyListWithPositionConfigurationQueryVariables
>;
export const GetOnePositionConfigDocument = gql`
  query getOnePositionConfig($where: positionConfigurations_bool_exp!) {
    positionConfigurations(where: $where, limit: 1) {
      positionId
    }
  }
`;

/**
 * __useGetOnePositionConfigQuery__
 *
 * To run a query within a React component, call `useGetOnePositionConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnePositionConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnePositionConfigQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetOnePositionConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetOnePositionConfigQuery, GetOnePositionConfigQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetOnePositionConfigQuery, GetOnePositionConfigQueryVariables>(
    GetOnePositionConfigDocument,
    baseOptions
  );
}
export function useGetOnePositionConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOnePositionConfigQuery, GetOnePositionConfigQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetOnePositionConfigQuery, GetOnePositionConfigQueryVariables>(
    GetOnePositionConfigDocument,
    baseOptions
  );
}
export type GetOnePositionConfigQueryHookResult = ReturnType<typeof useGetOnePositionConfigQuery>;
export type GetOnePositionConfigLazyQueryHookResult = ReturnType<typeof useGetOnePositionConfigLazyQuery>;
export type GetOnePositionConfigQueryResult = ApolloReactCommon.QueryResult<
  GetOnePositionConfigQuery,
  GetOnePositionConfigQueryVariables
>;
export const KeyBeforeAndAfterDocument = gql`
  query keyBeforeAndAfter($keyId: uuid!, $keyName: String!) {
    key: position(positionId: $keyId) {
      location {
        keyAfter: positions(
          where: { positionName: { _gt: $keyName }, positionType: { _eq: "key" } }
          limit: 1
          order_by: { positionName: asc }
        ) {
          positionId
          positionName
        }
        keyBefore: positions(
          where: { positionName: { _lt: $keyName }, positionType: { _eq: "key" } }
          limit: 1
          order_by: { positionName: desc }
        ) {
          positionId
          positionName
        }
      }
    }
  }
`;

/**
 * __useKeyBeforeAndAfterQuery__
 *
 * To run a query within a React component, call `useKeyBeforeAndAfterQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyBeforeAndAfterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyBeforeAndAfterQuery({
 *   variables: {
 *      keyId: // value for 'keyId'
 *      keyName: // value for 'keyName'
 *   },
 * });
 */
export function useKeyBeforeAndAfterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<KeyBeforeAndAfterQuery, KeyBeforeAndAfterQueryVariables>
) {
  return ApolloReactHooks.useQuery<KeyBeforeAndAfterQuery, KeyBeforeAndAfterQueryVariables>(
    KeyBeforeAndAfterDocument,
    baseOptions
  );
}
export function useKeyBeforeAndAfterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeyBeforeAndAfterQuery, KeyBeforeAndAfterQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<KeyBeforeAndAfterQuery, KeyBeforeAndAfterQueryVariables>(
    KeyBeforeAndAfterDocument,
    baseOptions
  );
}
export type KeyBeforeAndAfterQueryHookResult = ReturnType<typeof useKeyBeforeAndAfterQuery>;
export type KeyBeforeAndAfterLazyQueryHookResult = ReturnType<typeof useKeyBeforeAndAfterLazyQuery>;
export type KeyBeforeAndAfterQueryResult = ApolloReactCommon.QueryResult<
  KeyBeforeAndAfterQuery,
  KeyBeforeAndAfterQueryVariables
>;
export const KeysWithRoomIdsDocument = gql`
  query keysWithRoomIds($where: sensorflow_positions_bool_exp!, $filterByRoomName: sensorflow_positions_bool_exp) {
    keys: positions(where: $where) {
      positionId
      rooms(where: $filterByRoomName) {
        positionId
      }
    }
  }
`;

/**
 * __useKeysWithRoomIdsQuery__
 *
 * To run a query within a React component, call `useKeysWithRoomIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeysWithRoomIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeysWithRoomIdsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      filterByRoomName: // value for 'filterByRoomName'
 *   },
 * });
 */
export function useKeysWithRoomIdsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<KeysWithRoomIdsQuery, KeysWithRoomIdsQueryVariables>
) {
  return ApolloReactHooks.useQuery<KeysWithRoomIdsQuery, KeysWithRoomIdsQueryVariables>(
    KeysWithRoomIdsDocument,
    baseOptions
  );
}
export function useKeysWithRoomIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeysWithRoomIdsQuery, KeysWithRoomIdsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<KeysWithRoomIdsQuery, KeysWithRoomIdsQueryVariables>(
    KeysWithRoomIdsDocument,
    baseOptions
  );
}
export type KeysWithRoomIdsQueryHookResult = ReturnType<typeof useKeysWithRoomIdsQuery>;
export type KeysWithRoomIdsLazyQueryHookResult = ReturnType<typeof useKeysWithRoomIdsLazyQuery>;
export type KeysWithRoomIdsQueryResult = ApolloReactCommon.QueryResult<
  KeysWithRoomIdsQuery,
  KeysWithRoomIdsQueryVariables
>;
export const LocationMetadataDocument = gql`
  query locationMetadata($locationId: uuid!) {
    locationMetadata(locationId: $locationId) {
      showEnergyConsumption
      subscription
      activeAutosetCount
      showHeatingMode
      allowDisableAutomationMode
      isAutomationModeDisabled
      smartRemValidation
      availableOperationalModes
      smplrspaceId
      isTimeBasedConfigOnboarded
    }
  }
`;

/**
 * __useLocationMetadataQuery__
 *
 * To run a query within a React component, call `useLocationMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationMetadataQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationMetadataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationMetadataQuery, LocationMetadataQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationMetadataQuery, LocationMetadataQueryVariables>(
    LocationMetadataDocument,
    baseOptions
  );
}
export function useLocationMetadataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationMetadataQuery, LocationMetadataQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationMetadataQuery, LocationMetadataQueryVariables>(
    LocationMetadataDocument,
    baseOptions
  );
}
export type LocationMetadataQueryHookResult = ReturnType<typeof useLocationMetadataQuery>;
export type LocationMetadataLazyQueryHookResult = ReturnType<typeof useLocationMetadataLazyQuery>;
export type LocationMetadataQueryResult = ApolloReactCommon.QueryResult<
  LocationMetadataQuery,
  LocationMetadataQueryVariables
>;
export const LocationSettingsDocument = gql`
  query locationSettings($locationId: uuid!) {
    locationMetadata(locationId: $locationId) {
      showEnergyConsumption
      subscription
      showHeatingMode
      activeAutosetCount
      allowDisableAutomationMode
      smartRemValidation
      availableOperationalModes
      gatewayHardwareType
      gatewayPromiscuousModeEnabled
      hasKeyCard
      enableNoKeyCardState
      beaconSlotManagement
    }
    locationOne(locationId: $locationId) {
      activePropertyAlerts {
        active
        locationId
        ticketClassId
      }
    }
  }
`;

/**
 * __useLocationSettingsQuery__
 *
 * To run a query within a React component, call `useLocationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationSettingsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationSettingsQuery, LocationSettingsQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationSettingsQuery, LocationSettingsQueryVariables>(
    LocationSettingsDocument,
    baseOptions
  );
}
export function useLocationSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationSettingsQuery, LocationSettingsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationSettingsQuery, LocationSettingsQueryVariables>(
    LocationSettingsDocument,
    baseOptions
  );
}
export type LocationSettingsQueryHookResult = ReturnType<typeof useLocationSettingsQuery>;
export type LocationSettingsLazyQueryHookResult = ReturnType<typeof useLocationSettingsLazyQuery>;
export type LocationSettingsQueryResult = ApolloReactCommon.QueryResult<
  LocationSettingsQuery,
  LocationSettingsQueryVariables
>;
export const NodeToSlotMappingByLocationAggregateDocument = gql`
  query nodeToSlotMappingByLocationAggregate($locationId: uuid!, $nodeType: String!) {
    nodeToSlotMappingByLocationAggregate: sensorflow_node_to_slot_mapping_aggregate(
      where: { position: { locationId: { _eq: $locationId } }, nodeType: { _eq: $nodeType } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useNodeToSlotMappingByLocationAggregateQuery__
 *
 * To run a query within a React component, call `useNodeToSlotMappingByLocationAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeToSlotMappingByLocationAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeToSlotMappingByLocationAggregateQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      nodeType: // value for 'nodeType'
 *   },
 * });
 */
export function useNodeToSlotMappingByLocationAggregateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    NodeToSlotMappingByLocationAggregateQuery,
    NodeToSlotMappingByLocationAggregateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    NodeToSlotMappingByLocationAggregateQuery,
    NodeToSlotMappingByLocationAggregateQueryVariables
  >(NodeToSlotMappingByLocationAggregateDocument, baseOptions);
}
export function useNodeToSlotMappingByLocationAggregateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NodeToSlotMappingByLocationAggregateQuery,
    NodeToSlotMappingByLocationAggregateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    NodeToSlotMappingByLocationAggregateQuery,
    NodeToSlotMappingByLocationAggregateQueryVariables
  >(NodeToSlotMappingByLocationAggregateDocument, baseOptions);
}
export type NodeToSlotMappingByLocationAggregateQueryHookResult = ReturnType<
  typeof useNodeToSlotMappingByLocationAggregateQuery
>;
export type NodeToSlotMappingByLocationAggregateLazyQueryHookResult = ReturnType<
  typeof useNodeToSlotMappingByLocationAggregateLazyQuery
>;
export type NodeToSlotMappingByLocationAggregateQueryResult = ApolloReactCommon.QueryResult<
  NodeToSlotMappingByLocationAggregateQuery,
  NodeToSlotMappingByLocationAggregateQueryVariables
>;
export const FirmwareReleasesDocument = gql`
  query firmwareReleases(
    $where: sensorflow_firmware_releases_bool_exp!
    $offset: Int!
    $limit: Int!
    $order_by: [sensorflow_firmware_releases_order_by!]
  ) {
    firmwareReleases(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      id
      downloadLink
      comment
      isDeprecated
      nodeSubType
      uploadedDate
      versionName
      versionNumber
    }
    firmwareReleaseAggregate: sensorflow_firmware_releases_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useFirmwareReleasesQuery__
 *
 * To run a query within a React component, call `useFirmwareReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirmwareReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmwareReleasesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useFirmwareReleasesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FirmwareReleasesQuery, FirmwareReleasesQueryVariables>
) {
  return ApolloReactHooks.useQuery<FirmwareReleasesQuery, FirmwareReleasesQueryVariables>(
    FirmwareReleasesDocument,
    baseOptions
  );
}
export function useFirmwareReleasesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FirmwareReleasesQuery, FirmwareReleasesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<FirmwareReleasesQuery, FirmwareReleasesQueryVariables>(
    FirmwareReleasesDocument,
    baseOptions
  );
}
export type FirmwareReleasesQueryHookResult = ReturnType<typeof useFirmwareReleasesQuery>;
export type FirmwareReleasesLazyQueryHookResult = ReturnType<typeof useFirmwareReleasesLazyQuery>;
export type FirmwareReleasesQueryResult = ApolloReactCommon.QueryResult<
  FirmwareReleasesQuery,
  FirmwareReleasesQueryVariables
>;
export const CreateFirmwareReleaseDocument = gql`
  mutation createFirmwareRelease($input: sensorflow_firmware_releases_insert_input!) {
    createFirmwareRelease(object: $input) {
      id
      versionName
      versionNumber
    }
  }
`;
export type CreateFirmwareReleaseMutationFn = ApolloReactCommon.MutationFunction<
  CreateFirmwareReleaseMutation,
  CreateFirmwareReleaseMutationVariables
>;

/**
 * __useCreateFirmwareReleaseMutation__
 *
 * To run a mutation, you first call `useCreateFirmwareReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFirmwareReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFirmwareReleaseMutation, { data, loading, error }] = useCreateFirmwareReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFirmwareReleaseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateFirmwareReleaseMutation,
    CreateFirmwareReleaseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateFirmwareReleaseMutation, CreateFirmwareReleaseMutationVariables>(
    CreateFirmwareReleaseDocument,
    baseOptions
  );
}
export type CreateFirmwareReleaseMutationHookResult = ReturnType<typeof useCreateFirmwareReleaseMutation>;
export type CreateFirmwareReleaseMutationResult = ApolloReactCommon.MutationResult<CreateFirmwareReleaseMutation>;
export type CreateFirmwareReleaseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateFirmwareReleaseMutation,
  CreateFirmwareReleaseMutationVariables
>;
export const UpdateFirmwareReleaseDocument = gql`
  mutation updateFirmwareRelease($id: Int!, $input: sensorflow_firmware_releases_set_input!) {
    updateFirmwareRelease(pk_columns: { id: $id }, _set: $input) {
      id
    }
  }
`;
export type UpdateFirmwareReleaseMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFirmwareReleaseMutation,
  UpdateFirmwareReleaseMutationVariables
>;

/**
 * __useUpdateFirmwareReleaseMutation__
 *
 * To run a mutation, you first call `useUpdateFirmwareReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFirmwareReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFirmwareReleaseMutation, { data, loading, error }] = useUpdateFirmwareReleaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFirmwareReleaseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateFirmwareReleaseMutation,
    UpdateFirmwareReleaseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateFirmwareReleaseMutation, UpdateFirmwareReleaseMutationVariables>(
    UpdateFirmwareReleaseDocument,
    baseOptions
  );
}
export type UpdateFirmwareReleaseMutationHookResult = ReturnType<typeof useUpdateFirmwareReleaseMutation>;
export type UpdateFirmwareReleaseMutationResult = ApolloReactCommon.MutationResult<UpdateFirmwareReleaseMutation>;
export type UpdateFirmwareReleaseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateFirmwareReleaseMutation,
  UpdateFirmwareReleaseMutationVariables
>;
export const DeleteFirmwareReleaseDocument = gql`
  mutation deleteFirmwareRelease($id: Int!) {
    deleteFirmwareRelease(id: $id) {
      id
    }
  }
`;
export type DeleteFirmwareReleaseMutationFn = ApolloReactCommon.MutationFunction<
  DeleteFirmwareReleaseMutation,
  DeleteFirmwareReleaseMutationVariables
>;

/**
 * __useDeleteFirmwareReleaseMutation__
 *
 * To run a mutation, you first call `useDeleteFirmwareReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFirmwareReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFirmwareReleaseMutation, { data, loading, error }] = useDeleteFirmwareReleaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFirmwareReleaseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteFirmwareReleaseMutation,
    DeleteFirmwareReleaseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteFirmwareReleaseMutation, DeleteFirmwareReleaseMutationVariables>(
    DeleteFirmwareReleaseDocument,
    baseOptions
  );
}
export type DeleteFirmwareReleaseMutationHookResult = ReturnType<typeof useDeleteFirmwareReleaseMutation>;
export type DeleteFirmwareReleaseMutationResult = ApolloReactCommon.MutationResult<DeleteFirmwareReleaseMutation>;
export type DeleteFirmwareReleaseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteFirmwareReleaseMutation,
  DeleteFirmwareReleaseMutationVariables
>;
export const LocationWithOtaSettingsDocument = gql`
  query locationWithOtaSettings($where: sensorflow_locations_bool_exp, $order_by: [sensorflow_locations_order_by!]) {
    locations: locationList(where: $where, order_by: $order_by) {
      locationId
      locationName
      otaMaxRetries
      otaIsEnabled
      otaWindowStartTime
      otaWindowEndTime
    }
  }
`;

/**
 * __useLocationWithOtaSettingsQuery__
 *
 * To run a query within a React component, call `useLocationWithOtaSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationWithOtaSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationWithOtaSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useLocationWithOtaSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationWithOtaSettingsQuery, LocationWithOtaSettingsQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationWithOtaSettingsQuery, LocationWithOtaSettingsQueryVariables>(
    LocationWithOtaSettingsDocument,
    baseOptions
  );
}
export function useLocationWithOtaSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationWithOtaSettingsQuery,
    LocationWithOtaSettingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<LocationWithOtaSettingsQuery, LocationWithOtaSettingsQueryVariables>(
    LocationWithOtaSettingsDocument,
    baseOptions
  );
}
export type LocationWithOtaSettingsQueryHookResult = ReturnType<typeof useLocationWithOtaSettingsQuery>;
export type LocationWithOtaSettingsLazyQueryHookResult = ReturnType<typeof useLocationWithOtaSettingsLazyQuery>;
export type LocationWithOtaSettingsQueryResult = ApolloReactCommon.QueryResult<
  LocationWithOtaSettingsQuery,
  LocationWithOtaSettingsQueryVariables
>;
export const UpdateOtaSettingsDocument = gql`
  mutation updateOtaSettings(
    $primaryKey: sensorflow_locations_pk_columns_input!
    $updateLocationInput: sensorflow_locations_set_input!
  ) {
    updateLocation(pk_columns: $primaryKey, _set: $updateLocationInput) {
      locationId
      locationName
      otaIsEnabled
      otaMaxRetries
      otaWindowEndTime
      otaWindowStartTime
    }
  }
`;
export type UpdateOtaSettingsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateOtaSettingsMutation,
  UpdateOtaSettingsMutationVariables
>;

/**
 * __useUpdateOtaSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOtaSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOtaSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOtaSettingsMutation, { data, loading, error }] = useUpdateOtaSettingsMutation({
 *   variables: {
 *      primaryKey: // value for 'primaryKey'
 *      updateLocationInput: // value for 'updateLocationInput'
 *   },
 * });
 */
export function useUpdateOtaSettingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOtaSettingsMutation, UpdateOtaSettingsMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateOtaSettingsMutation, UpdateOtaSettingsMutationVariables>(
    UpdateOtaSettingsDocument,
    baseOptions
  );
}
export type UpdateOtaSettingsMutationHookResult = ReturnType<typeof useUpdateOtaSettingsMutation>;
export type UpdateOtaSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateOtaSettingsMutation>;
export type UpdateOtaSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOtaSettingsMutation,
  UpdateOtaSettingsMutationVariables
>;
export const NodeListForOtaDocument = gql`
  query nodeListForOta(
    $where: sensorflow_node_to_slot_mapping_bool_exp!
    $limit: Int!
    $offset: Int!
    $order_by: [sensorflow_node_to_slot_mapping_order_by!]
  ) {
    nodes: sensorflow_node_to_slot_mapping(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      nodeMacId
      nodeType
      position {
        positionId
        positionName
        parentPosition {
          positionId
          positionName
        }
        location {
          locationId
          locationName
          otaMaxRetries
        }
      }
      node {
        nodeSubType
        firmwareVersionLocked
        otaFailedUpgradeAttempts
        otaComment
        currentFirmwareRelease {
          versionNumber
          versionName
          isDeprecated
        }
        nodeOnlineStatus {
          nodeStatus
        }
        targetFirmwareRelease {
          versionNumber
          versionName
        }
        nodeFirmware {
          upgradeStatus
          lockable
        }
      }
    }
    nodeAggregate: sensorflow_node_to_slot_mapping_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useNodeListForOtaQuery__
 *
 * To run a query within a React component, call `useNodeListForOtaQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeListForOtaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeListForOtaQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useNodeListForOtaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<NodeListForOtaQuery, NodeListForOtaQueryVariables>
) {
  return ApolloReactHooks.useQuery<NodeListForOtaQuery, NodeListForOtaQueryVariables>(
    NodeListForOtaDocument,
    baseOptions
  );
}
export function useNodeListForOtaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NodeListForOtaQuery, NodeListForOtaQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<NodeListForOtaQuery, NodeListForOtaQueryVariables>(
    NodeListForOtaDocument,
    baseOptions
  );
}
export type NodeListForOtaQueryHookResult = ReturnType<typeof useNodeListForOtaQuery>;
export type NodeListForOtaLazyQueryHookResult = ReturnType<typeof useNodeListForOtaLazyQuery>;
export type NodeListForOtaQueryResult = ApolloReactCommon.QueryResult<
  NodeListForOtaQuery,
  NodeListForOtaQueryVariables
>;
export const NodeIdsDocument = gql`
  query nodeIds($where: sensorflow_node_to_slot_mapping_bool_exp!) {
    nodeIds: sensorflow_node_to_slot_mapping(where: $where) {
      nodeMacId
    }
  }
`;

/**
 * __useNodeIdsQuery__
 *
 * To run a query within a React component, call `useNodeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeIdsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNodeIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NodeIdsQuery, NodeIdsQueryVariables>) {
  return ApolloReactHooks.useQuery<NodeIdsQuery, NodeIdsQueryVariables>(NodeIdsDocument, baseOptions);
}
export function useNodeIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NodeIdsQuery, NodeIdsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<NodeIdsQuery, NodeIdsQueryVariables>(NodeIdsDocument, baseOptions);
}
export type NodeIdsQueryHookResult = ReturnType<typeof useNodeIdsQuery>;
export type NodeIdsLazyQueryHookResult = ReturnType<typeof useNodeIdsLazyQuery>;
export type NodeIdsQueryResult = ApolloReactCommon.QueryResult<NodeIdsQuery, NodeIdsQueryVariables>;
export const FirmwareReleasesForDropdownDocument = gql`
  query firmwareReleasesForDropdown($where: sensorflow_firmware_releases_bool_exp) {
    firmwareReleases(where: $where) {
      id
      isDeprecated
      nodeSubType
      versionName
      versionNumber
    }
  }
`;

/**
 * __useFirmwareReleasesForDropdownQuery__
 *
 * To run a query within a React component, call `useFirmwareReleasesForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirmwareReleasesForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmwareReleasesForDropdownQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFirmwareReleasesForDropdownQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FirmwareReleasesForDropdownQuery,
    FirmwareReleasesForDropdownQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<FirmwareReleasesForDropdownQuery, FirmwareReleasesForDropdownQueryVariables>(
    FirmwareReleasesForDropdownDocument,
    baseOptions
  );
}
export function useFirmwareReleasesForDropdownLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FirmwareReleasesForDropdownQuery,
    FirmwareReleasesForDropdownQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<FirmwareReleasesForDropdownQuery, FirmwareReleasesForDropdownQueryVariables>(
    FirmwareReleasesForDropdownDocument,
    baseOptions
  );
}
export type FirmwareReleasesForDropdownQueryHookResult = ReturnType<typeof useFirmwareReleasesForDropdownQuery>;
export type FirmwareReleasesForDropdownLazyQueryHookResult = ReturnType<typeof useFirmwareReleasesForDropdownLazyQuery>;
export type FirmwareReleasesForDropdownQueryResult = ApolloReactCommon.QueryResult<
  FirmwareReleasesForDropdownQuery,
  FirmwareReleasesForDropdownQueryVariables
>;
export const BulkNodeUpdateDocument = gql`
  mutation bulkNodeUpdate($nodeIds: [String!], $input: sensorflow_nodes_set_input!) {
    update_sensorflow_nodes(where: { node_mac_id: { _in: $nodeIds } }, _set: $input) {
      affected_rows
    }
  }
`;
export type BulkNodeUpdateMutationFn = ApolloReactCommon.MutationFunction<
  BulkNodeUpdateMutation,
  BulkNodeUpdateMutationVariables
>;

/**
 * __useBulkNodeUpdateMutation__
 *
 * To run a mutation, you first call `useBulkNodeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkNodeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkNodeUpdateMutation, { data, loading, error }] = useBulkNodeUpdateMutation({
 *   variables: {
 *      nodeIds: // value for 'nodeIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkNodeUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<BulkNodeUpdateMutation, BulkNodeUpdateMutationVariables>
) {
  return ApolloReactHooks.useMutation<BulkNodeUpdateMutation, BulkNodeUpdateMutationVariables>(
    BulkNodeUpdateDocument,
    baseOptions
  );
}
export type BulkNodeUpdateMutationHookResult = ReturnType<typeof useBulkNodeUpdateMutation>;
export type BulkNodeUpdateMutationResult = ApolloReactCommon.MutationResult<BulkNodeUpdateMutation>;
export type BulkNodeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BulkNodeUpdateMutation,
  BulkNodeUpdateMutationVariables
>;
export const BulkLockFirmwareDocument = gql`
  mutation bulkLockFirmware($nodeIds: [String!], $input: sensorflow_nodes_set_input!) {
    update_sensorflow_nodes(
      where: { node_mac_id: { _in: $nodeIds }, nodeFirmware: { lockable: { _eq: true } } }
      _set: $input
    ) {
      affected_rows
    }
  }
`;
export type BulkLockFirmwareMutationFn = ApolloReactCommon.MutationFunction<
  BulkLockFirmwareMutation,
  BulkLockFirmwareMutationVariables
>;

/**
 * __useBulkLockFirmwareMutation__
 *
 * To run a mutation, you first call `useBulkLockFirmwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkLockFirmwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkLockFirmwareMutation, { data, loading, error }] = useBulkLockFirmwareMutation({
 *   variables: {
 *      nodeIds: // value for 'nodeIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkLockFirmwareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<BulkLockFirmwareMutation, BulkLockFirmwareMutationVariables>
) {
  return ApolloReactHooks.useMutation<BulkLockFirmwareMutation, BulkLockFirmwareMutationVariables>(
    BulkLockFirmwareDocument,
    baseOptions
  );
}
export type BulkLockFirmwareMutationHookResult = ReturnType<typeof useBulkLockFirmwareMutation>;
export type BulkLockFirmwareMutationResult = ApolloReactCommon.MutationResult<BulkLockFirmwareMutation>;
export type BulkLockFirmwareMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BulkLockFirmwareMutation,
  BulkLockFirmwareMutationVariables
>;
export const BulkUpdateFirmwareDocument = gql`
  mutation bulkUpdateFirmware($nodeIds: [String!], $input: sensorflow_nodes_set_input!) {
    update_sensorflow_nodes(
      where: { node_mac_id: { _in: $nodeIds }, firmwareVersionLocked: { _eq: false } }
      _set: $input
    ) {
      affected_rows
    }
  }
`;
export type BulkUpdateFirmwareMutationFn = ApolloReactCommon.MutationFunction<
  BulkUpdateFirmwareMutation,
  BulkUpdateFirmwareMutationVariables
>;

/**
 * __useBulkUpdateFirmwareMutation__
 *
 * To run a mutation, you first call `useBulkUpdateFirmwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateFirmwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateFirmwareMutation, { data, loading, error }] = useBulkUpdateFirmwareMutation({
 *   variables: {
 *      nodeIds: // value for 'nodeIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateFirmwareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<BulkUpdateFirmwareMutation, BulkUpdateFirmwareMutationVariables>
) {
  return ApolloReactHooks.useMutation<BulkUpdateFirmwareMutation, BulkUpdateFirmwareMutationVariables>(
    BulkUpdateFirmwareDocument,
    baseOptions
  );
}
export type BulkUpdateFirmwareMutationHookResult = ReturnType<typeof useBulkUpdateFirmwareMutation>;
export type BulkUpdateFirmwareMutationResult = ApolloReactCommon.MutationResult<BulkUpdateFirmwareMutation>;
export type BulkUpdateFirmwareMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BulkUpdateFirmwareMutation,
  BulkUpdateFirmwareMutationVariables
>;
export const GetTicketClassesForDropdownDocument = gql`
  query getTicketClassesForDropdown($ticketClass: String) {
    ticketClasses(where: { active: { _eq: true }, ticketClass: { _ilike: $ticketClass } }) {
      id
      ticketClass
    }
  }
`;

/**
 * __useGetTicketClassesForDropdownQuery__
 *
 * To run a query within a React component, call `useGetTicketClassesForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketClassesForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketClassesForDropdownQuery({
 *   variables: {
 *      ticketClass: // value for 'ticketClass'
 *   },
 * });
 */
export function useGetTicketClassesForDropdownQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTicketClassesForDropdownQuery,
    GetTicketClassesForDropdownQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetTicketClassesForDropdownQuery, GetTicketClassesForDropdownQueryVariables>(
    GetTicketClassesForDropdownDocument,
    baseOptions
  );
}
export function useGetTicketClassesForDropdownLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTicketClassesForDropdownQuery,
    GetTicketClassesForDropdownQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetTicketClassesForDropdownQuery, GetTicketClassesForDropdownQueryVariables>(
    GetTicketClassesForDropdownDocument,
    baseOptions
  );
}
export type GetTicketClassesForDropdownQueryHookResult = ReturnType<typeof useGetTicketClassesForDropdownQuery>;
export type GetTicketClassesForDropdownLazyQueryHookResult = ReturnType<typeof useGetTicketClassesForDropdownLazyQuery>;
export type GetTicketClassesForDropdownQueryResult = ApolloReactCommon.QueryResult<
  GetTicketClassesForDropdownQuery,
  GetTicketClassesForDropdownQueryVariables
>;
export const UpsertActivePropertyAlertsDocument = gql`
  mutation upsertActivePropertyAlerts($objects: [sf_support_active_property_alerts_insert_input!]!) {
    insertActivePropertyAlerts(
      objects: $objects
      on_conflict: { constraint: active_property_alerts_pkey, update_columns: [active] }
    ) {
      affected_rows
    }
  }
`;
export type UpsertActivePropertyAlertsMutationFn = ApolloReactCommon.MutationFunction<
  UpsertActivePropertyAlertsMutation,
  UpsertActivePropertyAlertsMutationVariables
>;

/**
 * __useUpsertActivePropertyAlertsMutation__
 *
 * To run a mutation, you first call `useUpsertActivePropertyAlertsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertActivePropertyAlertsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertActivePropertyAlertsMutation, { data, loading, error }] = useUpsertActivePropertyAlertsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertActivePropertyAlertsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertActivePropertyAlertsMutation,
    UpsertActivePropertyAlertsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpsertActivePropertyAlertsMutation, UpsertActivePropertyAlertsMutationVariables>(
    UpsertActivePropertyAlertsDocument,
    baseOptions
  );
}
export type UpsertActivePropertyAlertsMutationHookResult = ReturnType<typeof useUpsertActivePropertyAlertsMutation>;
export type UpsertActivePropertyAlertsMutationResult = ApolloReactCommon.MutationResult<UpsertActivePropertyAlertsMutation>;
export type UpsertActivePropertyAlertsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertActivePropertyAlertsMutation,
  UpsertActivePropertyAlertsMutationVariables
>;
export const GetShowHeatingModeDocument = gql`
  query getShowHeatingMode($locationId: uuid!) {
    locationMetadata(locationId: $locationId) {
      showHeatingMode
      availableOperationalModes
    }
  }
`;

/**
 * __useGetShowHeatingModeQuery__
 *
 * To run a query within a React component, call `useGetShowHeatingModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowHeatingModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowHeatingModeQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetShowHeatingModeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowHeatingModeQuery, GetShowHeatingModeQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetShowHeatingModeQuery, GetShowHeatingModeQueryVariables>(
    GetShowHeatingModeDocument,
    baseOptions
  );
}
export function useGetShowHeatingModeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowHeatingModeQuery, GetShowHeatingModeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetShowHeatingModeQuery, GetShowHeatingModeQueryVariables>(
    GetShowHeatingModeDocument,
    baseOptions
  );
}
export type GetShowHeatingModeQueryHookResult = ReturnType<typeof useGetShowHeatingModeQuery>;
export type GetShowHeatingModeLazyQueryHookResult = ReturnType<typeof useGetShowHeatingModeLazyQuery>;
export type GetShowHeatingModeQueryResult = ApolloReactCommon.QueryResult<
  GetShowHeatingModeQuery,
  GetShowHeatingModeQueryVariables
>;
export const GetUserLocationMetadataStatsDocument = gql`
  query getUserLocationMetadataStats($where: sensorflow_location_metadata_bool_exp) {
    stats: sensorflow_location_metadata_aggregate(where: $where) {
      data: aggregate {
        count
      }
    }
  }
`;

/**
 * __useGetUserLocationMetadataStatsQuery__
 *
 * To run a query within a React component, call `useGetUserLocationMetadataStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLocationMetadataStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLocationMetadataStatsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserLocationMetadataStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUserLocationMetadataStatsQuery,
    GetUserLocationMetadataStatsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetUserLocationMetadataStatsQuery, GetUserLocationMetadataStatsQueryVariables>(
    GetUserLocationMetadataStatsDocument,
    baseOptions
  );
}
export function useGetUserLocationMetadataStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserLocationMetadataStatsQuery,
    GetUserLocationMetadataStatsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetUserLocationMetadataStatsQuery, GetUserLocationMetadataStatsQueryVariables>(
    GetUserLocationMetadataStatsDocument,
    baseOptions
  );
}
export type GetUserLocationMetadataStatsQueryHookResult = ReturnType<typeof useGetUserLocationMetadataStatsQuery>;
export type GetUserLocationMetadataStatsLazyQueryHookResult = ReturnType<
  typeof useGetUserLocationMetadataStatsLazyQuery
>;
export type GetUserLocationMetadataStatsQueryResult = ApolloReactCommon.QueryResult<
  GetUserLocationMetadataStatsQuery,
  GetUserLocationMetadataStatsQueryVariables
>;
export const CurrentKeyEntriesDocument = gql`
  subscription currentKeyEntries($where: keyEntries_bool_exp, $orderBy: [keyEntries_order_by!], $limit: Int) {
    keyEntries(where: $where, order_by: $orderBy, limit: $limit) {
      keyEntryId
      startedAt
      endedAt
      userId
      keyPositionId
      positionFunction
      expiredAt
      status
      user {
        name
      }
      comment
      commentStatus
      automaticTestRun
      isMeterPosition
    }
  }
`;

/**
 * __useCurrentKeyEntriesSubscription__
 *
 * To run a query within a React component, call `useCurrentKeyEntriesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCurrentKeyEntriesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentKeyEntriesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCurrentKeyEntriesSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    CurrentKeyEntriesSubscription,
    CurrentKeyEntriesSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<CurrentKeyEntriesSubscription, CurrentKeyEntriesSubscriptionVariables>(
    CurrentKeyEntriesDocument,
    baseOptions
  );
}
export type CurrentKeyEntriesSubscriptionHookResult = ReturnType<typeof useCurrentKeyEntriesSubscription>;
export type CurrentKeyEntriesSubscriptionResult = ApolloReactCommon.SubscriptionResult<CurrentKeyEntriesSubscription>;
export const GetCurrentKeyEntryOfInstallerDocument = gql`
  query getCurrentKeyEntryOfInstaller($userId: String!) {
    keyEntries(where: { endedAt: { _is_null: true }, userId: { _eq: $userId }, startedAt: { _is_null: false } }) {
      keyEntryId
      startedAt
      endedAt
      userId
      keyPositionId
      positionFunction
      expiredAt
      status
      position {
        locationId
      }
    }
  }
`;

/**
 * __useGetCurrentKeyEntryOfInstallerQuery__
 *
 * To run a query within a React component, call `useGetCurrentKeyEntryOfInstallerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentKeyEntryOfInstallerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentKeyEntryOfInstallerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCurrentKeyEntryOfInstallerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCurrentKeyEntryOfInstallerQuery,
    GetCurrentKeyEntryOfInstallerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCurrentKeyEntryOfInstallerQuery, GetCurrentKeyEntryOfInstallerQueryVariables>(
    GetCurrentKeyEntryOfInstallerDocument,
    baseOptions
  );
}
export function useGetCurrentKeyEntryOfInstallerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCurrentKeyEntryOfInstallerQuery,
    GetCurrentKeyEntryOfInstallerQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetCurrentKeyEntryOfInstallerQuery, GetCurrentKeyEntryOfInstallerQueryVariables>(
    GetCurrentKeyEntryOfInstallerDocument,
    baseOptions
  );
}
export type GetCurrentKeyEntryOfInstallerQueryHookResult = ReturnType<typeof useGetCurrentKeyEntryOfInstallerQuery>;
export type GetCurrentKeyEntryOfInstallerLazyQueryHookResult = ReturnType<
  typeof useGetCurrentKeyEntryOfInstallerLazyQuery
>;
export type GetCurrentKeyEntryOfInstallerQueryResult = ApolloReactCommon.QueryResult<
  GetCurrentKeyEntryOfInstallerQuery,
  GetCurrentKeyEntryOfInstallerQueryVariables
>;
export const KeyEntryDetailDocument = gql`
  subscription keyEntryDetail($where: keyEntries_bool_exp) {
    keyEntries(where: $where, order_by: { keyEntryId: desc }, limit: 1) {
      keyEntryId
      startedAt
      endedAt
      expiredAt
      status
      testRuns: test_runs(order_by: { testRunId: asc }) {
        autosetPositionId
        testRunId
        test {
          testId
          name
          description
          troubleshootingLink
          isManual
        }
        status
        lastPassedAt
        lastTestedAt
        note
        details
        isIgnored
      }
    }
  }
`;

/**
 * __useKeyEntryDetailSubscription__
 *
 * To run a query within a React component, call `useKeyEntryDetailSubscription` and pass it any options that fit your needs.
 * When your component renders, `useKeyEntryDetailSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyEntryDetailSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useKeyEntryDetailSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    KeyEntryDetailSubscription,
    KeyEntryDetailSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<KeyEntryDetailSubscription, KeyEntryDetailSubscriptionVariables>(
    KeyEntryDetailDocument,
    baseOptions
  );
}
export type KeyEntryDetailSubscriptionHookResult = ReturnType<typeof useKeyEntryDetailSubscription>;
export type KeyEntryDetailSubscriptionResult = ApolloReactCommon.SubscriptionResult<KeyEntryDetailSubscription>;
export const UpdateTestRunStatusDocument = gql`
  mutation updateTestRunStatus(
    $testRunId: Int!
    $status: String!
    $lastPassedAt: timestamptz
    $lastTestedAt: timestamptz
    $startedAt: timestamptz
  ) {
    updateTestRuns(
      where: { testRunId: { _eq: $testRunId } }
      _set: { status: $status, lastPassedAt: $lastPassedAt, lastTestedAt: $lastTestedAt, startedAt: $startedAt }
    ) {
      returning {
        keyEntryId
        lastPassedAt
        lastTestedAt
        startedAt
        note
        status
        test {
          testId
          name
          description
        }
        testRunId
      }
    }
  }
`;
export type UpdateTestRunStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTestRunStatusMutation,
  UpdateTestRunStatusMutationVariables
>;

/**
 * __useUpdateTestRunStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTestRunStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTestRunStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTestRunStatusMutation, { data, loading, error }] = useUpdateTestRunStatusMutation({
 *   variables: {
 *      testRunId: // value for 'testRunId'
 *      status: // value for 'status'
 *      lastPassedAt: // value for 'lastPassedAt'
 *      lastTestedAt: // value for 'lastTestedAt'
 *      startedAt: // value for 'startedAt'
 *   },
 * });
 */
export function useUpdateTestRunStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTestRunStatusMutation, UpdateTestRunStatusMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateTestRunStatusMutation, UpdateTestRunStatusMutationVariables>(
    UpdateTestRunStatusDocument,
    baseOptions
  );
}
export type UpdateTestRunStatusMutationHookResult = ReturnType<typeof useUpdateTestRunStatusMutation>;
export type UpdateTestRunStatusMutationResult = ApolloReactCommon.MutationResult<UpdateTestRunStatusMutation>;
export type UpdateTestRunStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTestRunStatusMutation,
  UpdateTestRunStatusMutationVariables
>;
export const UpdateTestRunNoteDocument = gql`
  mutation updateTestRunNote($testRunId: Int!, $note: String) {
    updateTestRuns(where: { testRunId: { _eq: $testRunId } }, _set: { note: $note }) {
      returning {
        testRunId
      }
    }
  }
`;
export type UpdateTestRunNoteMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTestRunNoteMutation,
  UpdateTestRunNoteMutationVariables
>;

/**
 * __useUpdateTestRunNoteMutation__
 *
 * To run a mutation, you first call `useUpdateTestRunNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTestRunNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTestRunNoteMutation, { data, loading, error }] = useUpdateTestRunNoteMutation({
 *   variables: {
 *      testRunId: // value for 'testRunId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateTestRunNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTestRunNoteMutation, UpdateTestRunNoteMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateTestRunNoteMutation, UpdateTestRunNoteMutationVariables>(
    UpdateTestRunNoteDocument,
    baseOptions
  );
}
export type UpdateTestRunNoteMutationHookResult = ReturnType<typeof useUpdateTestRunNoteMutation>;
export type UpdateTestRunNoteMutationResult = ApolloReactCommon.MutationResult<UpdateTestRunNoteMutation>;
export type UpdateTestRunNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTestRunNoteMutation,
  UpdateTestRunNoteMutationVariables
>;
export const GetPositionNameDocument = gql`
  query getPositionName($keyId: uuid!) {
    positions(where: { parentPositionId: { _eq: $keyId } }) {
      positionName
      positionId
    }
  }
`;

/**
 * __useGetPositionNameQuery__
 *
 * To run a query within a React component, call `useGetPositionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionNameQuery({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useGetPositionNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPositionNameQuery, GetPositionNameQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetPositionNameQuery, GetPositionNameQueryVariables>(
    GetPositionNameDocument,
    baseOptions
  );
}
export function useGetPositionNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPositionNameQuery, GetPositionNameQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetPositionNameQuery, GetPositionNameQueryVariables>(
    GetPositionNameDocument,
    baseOptions
  );
}
export type GetPositionNameQueryHookResult = ReturnType<typeof useGetPositionNameQuery>;
export type GetPositionNameLazyQueryHookResult = ReturnType<typeof useGetPositionNameLazyQuery>;
export type GetPositionNameQueryResult = ApolloReactCommon.QueryResult<
  GetPositionNameQuery,
  GetPositionNameQueryVariables
>;
export const InfrastructureEmonPowerStatusDocument = gql`
  subscription infrastructureEmonPowerStatus($id: uuid!) {
    infrastructureEmonPowerStatus(where: { id: { _eq: $id } }, order_by: { channel: asc }) {
      channel
      lastPowerReading
      isMyChannel
      numPhases
    }
  }
`;

/**
 * __useInfrastructureEmonPowerStatusSubscription__
 *
 * To run a query within a React component, call `useInfrastructureEmonPowerStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInfrastructureEmonPowerStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfrastructureEmonPowerStatusSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInfrastructureEmonPowerStatusSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    InfrastructureEmonPowerStatusSubscription,
    InfrastructureEmonPowerStatusSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    InfrastructureEmonPowerStatusSubscription,
    InfrastructureEmonPowerStatusSubscriptionVariables
  >(InfrastructureEmonPowerStatusDocument, baseOptions);
}
export type InfrastructureEmonPowerStatusSubscriptionHookResult = ReturnType<
  typeof useInfrastructureEmonPowerStatusSubscription
>;
export type InfrastructureEmonPowerStatusSubscriptionResult = ApolloReactCommon.SubscriptionResult<InfrastructureEmonPowerStatusSubscription>;
export const PositionConfigurationDocument = gql`
  subscription positionConfiguration($keyId: uuid!) {
    position(positionId: $keyId) {
      positionId
      positionName
      rooms(where: { deletedAt: { _is_null: true } }) {
        positionId
        positionName
        positionConfiguration(where: { recordType: { _in: ["CURRENT", "DEFAULT"] } }) {
          automationMode
          isDeviatedFromDefaultConfig
          creationDate
          occNightStart
          occNightEnd
          occTimeoutNightMins
          occTimeoutDayMins
          occupancySensitivity
          doorSensorTimeoutMins
          softstartWaitTime
          hysteresisOffDegrees
          hysteresisOnDegrees
          hideSetpoint
          heatingHideSetpoint
          unoccupiedTemp
          heatingUnoccupiedTemp
          minTemp
          maxTemp
          heatingMinTemp
          heatingMaxTemp
          acNightEnd
          acNightStart
          actuationTimeoutSeconds
          bridgeAdaptorSetting
          desiredEmonSwitchPosition
          freezeProtectionTemp
          specialInstalltionMode
          modifiedByUser {
            userId
            name
          }
          recordType
          actingMode
          operationalMode
          expiredAt
          enableDoorSensor
          nightTimeSetpointEnabled
          nightTimeSetpointDOW
          nightTimeSetpointDelay
          nightTimeSetpointStart
          nightTimeSetpointEnd
          nightTimeSetpointOffset
          nightTimeSetpointMaxSetpoint
          forceOccupancyAutomation
        }
      }
    }
  }
`;

/**
 * __usePositionConfigurationSubscription__
 *
 * To run a query within a React component, call `usePositionConfigurationSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePositionConfigurationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionConfigurationSubscription({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function usePositionConfigurationSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    PositionConfigurationSubscription,
    PositionConfigurationSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    PositionConfigurationSubscription,
    PositionConfigurationSubscriptionVariables
  >(PositionConfigurationDocument, baseOptions);
}
export type PositionConfigurationSubscriptionHookResult = ReturnType<typeof usePositionConfigurationSubscription>;
export type PositionConfigurationSubscriptionResult = ApolloReactCommon.SubscriptionResult<PositionConfigurationSubscription>;
export const GetKeysForDropdownDocument = gql`
  query getKeysForDropdown($locationId: uuid!) {
    positions(where: { locationId: { _eq: $locationId }, positionType: { _eq: "key" } }) {
      positionId
      positionName
    }
  }
`;

/**
 * __useGetKeysForDropdownQuery__
 *
 * To run a query within a React component, call `useGetKeysForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeysForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeysForDropdownQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetKeysForDropdownQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetKeysForDropdownQuery, GetKeysForDropdownQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetKeysForDropdownQuery, GetKeysForDropdownQueryVariables>(
    GetKeysForDropdownDocument,
    baseOptions
  );
}
export function useGetKeysForDropdownLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetKeysForDropdownQuery, GetKeysForDropdownQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetKeysForDropdownQuery, GetKeysForDropdownQueryVariables>(
    GetKeysForDropdownDocument,
    baseOptions
  );
}
export type GetKeysForDropdownQueryHookResult = ReturnType<typeof useGetKeysForDropdownQuery>;
export type GetKeysForDropdownLazyQueryHookResult = ReturnType<typeof useGetKeysForDropdownLazyQuery>;
export type GetKeysForDropdownQueryResult = ApolloReactCommon.QueryResult<
  GetKeysForDropdownQuery,
  GetKeysForDropdownQueryVariables
>;
export const AutomationModeDisabledDocument = gql`
  subscription automationModeDisabled($locationId: uuid!) {
    locationMetadata(locationId: $locationId) {
      isAutomationModeDisabled
    }
  }
`;

/**
 * __useAutomationModeDisabledSubscription__
 *
 * To run a query within a React component, call `useAutomationModeDisabledSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAutomationModeDisabledSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutomationModeDisabledSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useAutomationModeDisabledSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    AutomationModeDisabledSubscription,
    AutomationModeDisabledSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    AutomationModeDisabledSubscription,
    AutomationModeDisabledSubscriptionVariables
  >(AutomationModeDisabledDocument, baseOptions);
}
export type AutomationModeDisabledSubscriptionHookResult = ReturnType<typeof useAutomationModeDisabledSubscription>;
export type AutomationModeDisabledSubscriptionResult = ApolloReactCommon.SubscriptionResult<AutomationModeDisabledSubscription>;
export const ChangeDisabledAutomationModeStatusDocument = gql`
  mutation changeDisabledAutomationModeStatus($locationId: ID!, $isAutomationModeDisabled: Boolean!) {
    changeDisabledAutomationModeStatus(locationId: $locationId, isAutomationModeDisabled: $isAutomationModeDisabled) {
      isAutomationModeDisabled
    }
  }
`;
export type ChangeDisabledAutomationModeStatusMutationFn = ApolloReactCommon.MutationFunction<
  ChangeDisabledAutomationModeStatusMutation,
  ChangeDisabledAutomationModeStatusMutationVariables
>;

/**
 * __useChangeDisabledAutomationModeStatusMutation__
 *
 * To run a mutation, you first call `useChangeDisabledAutomationModeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDisabledAutomationModeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDisabledAutomationModeStatusMutation, { data, loading, error }] = useChangeDisabledAutomationModeStatusMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      isAutomationModeDisabled: // value for 'isAutomationModeDisabled'
 *   },
 * });
 */
export function useChangeDisabledAutomationModeStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeDisabledAutomationModeStatusMutation,
    ChangeDisabledAutomationModeStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ChangeDisabledAutomationModeStatusMutation,
    ChangeDisabledAutomationModeStatusMutationVariables
  >(ChangeDisabledAutomationModeStatusDocument, baseOptions);
}
export type ChangeDisabledAutomationModeStatusMutationHookResult = ReturnType<
  typeof useChangeDisabledAutomationModeStatusMutation
>;
export type ChangeDisabledAutomationModeStatusMutationResult = ApolloReactCommon.MutationResult<ChangeDisabledAutomationModeStatusMutation>;
export type ChangeDisabledAutomationModeStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeDisabledAutomationModeStatusMutation,
  ChangeDisabledAutomationModeStatusMutationVariables
>;
export const GetIsManagerDocument = gql`
  query getIsManager($userId: String!) {
    sensorflow_users_by_pk(userId: $userId) {
      isManager
    }
  }
`;

/**
 * __useGetIsManagerQuery__
 *
 * To run a query within a React component, call `useGetIsManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsManagerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetIsManagerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetIsManagerQuery, GetIsManagerQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetIsManagerQuery, GetIsManagerQueryVariables>(GetIsManagerDocument, baseOptions);
}
export function useGetIsManagerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIsManagerQuery, GetIsManagerQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetIsManagerQuery, GetIsManagerQueryVariables>(
    GetIsManagerDocument,
    baseOptions
  );
}
export type GetIsManagerQueryHookResult = ReturnType<typeof useGetIsManagerQuery>;
export type GetIsManagerLazyQueryHookResult = ReturnType<typeof useGetIsManagerLazyQuery>;
export type GetIsManagerQueryResult = ApolloReactCommon.QueryResult<GetIsManagerQuery, GetIsManagerQueryVariables>;
export const GetNodeLifeCycleEventTypesDocument = gql`
  query getNodeLifeCycleEventTypes {
    sensorflow_node_life_cycle_event_types(where: { canTrackManually: { _eq: true } }, order_by: { id: asc }) {
      id
      name
      isDefault
      canTrackManually
    }
  }
`;

/**
 * __useGetNodeLifeCycleEventTypesQuery__
 *
 * To run a query within a React component, call `useGetNodeLifeCycleEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeLifeCycleEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeLifeCycleEventTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNodeLifeCycleEventTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetNodeLifeCycleEventTypesQuery,
    GetNodeLifeCycleEventTypesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetNodeLifeCycleEventTypesQuery, GetNodeLifeCycleEventTypesQueryVariables>(
    GetNodeLifeCycleEventTypesDocument,
    baseOptions
  );
}
export function useGetNodeLifeCycleEventTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNodeLifeCycleEventTypesQuery,
    GetNodeLifeCycleEventTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetNodeLifeCycleEventTypesQuery, GetNodeLifeCycleEventTypesQueryVariables>(
    GetNodeLifeCycleEventTypesDocument,
    baseOptions
  );
}
export type GetNodeLifeCycleEventTypesQueryHookResult = ReturnType<typeof useGetNodeLifeCycleEventTypesQuery>;
export type GetNodeLifeCycleEventTypesLazyQueryHookResult = ReturnType<typeof useGetNodeLifeCycleEventTypesLazyQuery>;
export type GetNodeLifeCycleEventTypesQueryResult = ApolloReactCommon.QueryResult<
  GetNodeLifeCycleEventTypesQuery,
  GetNodeLifeCycleEventTypesQueryVariables
>;
export const GetNodeLifeCycleEventsDocument = gql`
  query getNodeLifeCycleEvents($order_by: [sensorflow_node_life_cycle_events_order_by!]) {
    sensorflow_node_life_cycle_events(order_by: $order_by) {
      id
      nodeMacId
      creationDate
      node_life_cycle_event_type {
        id
        name
      }
      comment
    }
  }
`;

/**
 * __useGetNodeLifeCycleEventsQuery__
 *
 * To run a query within a React component, call `useGetNodeLifeCycleEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeLifeCycleEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeLifeCycleEventsQuery({
 *   variables: {
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetNodeLifeCycleEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetNodeLifeCycleEventsQuery, GetNodeLifeCycleEventsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetNodeLifeCycleEventsQuery, GetNodeLifeCycleEventsQueryVariables>(
    GetNodeLifeCycleEventsDocument,
    baseOptions
  );
}
export function useGetNodeLifeCycleEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNodeLifeCycleEventsQuery, GetNodeLifeCycleEventsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetNodeLifeCycleEventsQuery, GetNodeLifeCycleEventsQueryVariables>(
    GetNodeLifeCycleEventsDocument,
    baseOptions
  );
}
export type GetNodeLifeCycleEventsQueryHookResult = ReturnType<typeof useGetNodeLifeCycleEventsQuery>;
export type GetNodeLifeCycleEventsLazyQueryHookResult = ReturnType<typeof useGetNodeLifeCycleEventsLazyQuery>;
export type GetNodeLifeCycleEventsQueryResult = ApolloReactCommon.QueryResult<
  GetNodeLifeCycleEventsQuery,
  GetNodeLifeCycleEventsQueryVariables
>;
export const CreateNodeLifeCycleEventDocument = gql`
  mutation createNodeLifeCycleEvent($input: sensorflow_node_life_cycle_events_insert_input!) {
    insert_sensorflow_node_life_cycle_events_one(object: $input) {
      id
      nodeMacId
      creationDate
      eventType
      comment
    }
  }
`;
export type CreateNodeLifeCycleEventMutationFn = ApolloReactCommon.MutationFunction<
  CreateNodeLifeCycleEventMutation,
  CreateNodeLifeCycleEventMutationVariables
>;

/**
 * __useCreateNodeLifeCycleEventMutation__
 *
 * To run a mutation, you first call `useCreateNodeLifeCycleEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNodeLifeCycleEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNodeLifeCycleEventMutation, { data, loading, error }] = useCreateNodeLifeCycleEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNodeLifeCycleEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateNodeLifeCycleEventMutation,
    CreateNodeLifeCycleEventMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateNodeLifeCycleEventMutation, CreateNodeLifeCycleEventMutationVariables>(
    CreateNodeLifeCycleEventDocument,
    baseOptions
  );
}
export type CreateNodeLifeCycleEventMutationHookResult = ReturnType<typeof useCreateNodeLifeCycleEventMutation>;
export type CreateNodeLifeCycleEventMutationResult = ApolloReactCommon.MutationResult<CreateNodeLifeCycleEventMutation>;
export type CreateNodeLifeCycleEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateNodeLifeCycleEventMutation,
  CreateNodeLifeCycleEventMutationVariables
>;
export const CreateNodeLifeCycleEventsDocument = gql`
  mutation createNodeLifeCycleEvents($input: [sensorflow_node_life_cycle_events_insert_input!]!) {
    insert_sensorflow_node_life_cycle_events(objects: $input) {
      affected_rows
    }
  }
`;
export type CreateNodeLifeCycleEventsMutationFn = ApolloReactCommon.MutationFunction<
  CreateNodeLifeCycleEventsMutation,
  CreateNodeLifeCycleEventsMutationVariables
>;

/**
 * __useCreateNodeLifeCycleEventsMutation__
 *
 * To run a mutation, you first call `useCreateNodeLifeCycleEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNodeLifeCycleEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNodeLifeCycleEventsMutation, { data, loading, error }] = useCreateNodeLifeCycleEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNodeLifeCycleEventsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateNodeLifeCycleEventsMutation,
    CreateNodeLifeCycleEventsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateNodeLifeCycleEventsMutation, CreateNodeLifeCycleEventsMutationVariables>(
    CreateNodeLifeCycleEventsDocument,
    baseOptions
  );
}
export type CreateNodeLifeCycleEventsMutationHookResult = ReturnType<typeof useCreateNodeLifeCycleEventsMutation>;
export type CreateNodeLifeCycleEventsMutationResult = ApolloReactCommon.MutationResult<CreateNodeLifeCycleEventsMutation>;
export type CreateNodeLifeCycleEventsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateNodeLifeCycleEventsMutation,
  CreateNodeLifeCycleEventsMutationVariables
>;
export const UpdateNodeLifeCycleEventDocument = gql`
  mutation updateNodeLifeCycleEvent($id: Int!, $input: sensorflow_node_life_cycle_events_set_input) {
    update_sensorflow_node_life_cycle_events_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
      nodeMacId
      creationDate
      eventType
      comment
    }
  }
`;
export type UpdateNodeLifeCycleEventMutationFn = ApolloReactCommon.MutationFunction<
  UpdateNodeLifeCycleEventMutation,
  UpdateNodeLifeCycleEventMutationVariables
>;

/**
 * __useUpdateNodeLifeCycleEventMutation__
 *
 * To run a mutation, you first call `useUpdateNodeLifeCycleEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNodeLifeCycleEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNodeLifeCycleEventMutation, { data, loading, error }] = useUpdateNodeLifeCycleEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNodeLifeCycleEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateNodeLifeCycleEventMutation,
    UpdateNodeLifeCycleEventMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateNodeLifeCycleEventMutation, UpdateNodeLifeCycleEventMutationVariables>(
    UpdateNodeLifeCycleEventDocument,
    baseOptions
  );
}
export type UpdateNodeLifeCycleEventMutationHookResult = ReturnType<typeof useUpdateNodeLifeCycleEventMutation>;
export type UpdateNodeLifeCycleEventMutationResult = ApolloReactCommon.MutationResult<UpdateNodeLifeCycleEventMutation>;
export type UpdateNodeLifeCycleEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateNodeLifeCycleEventMutation,
  UpdateNodeLifeCycleEventMutationVariables
>;
export const UpsertSmartRemValidationHistoryDocument = gql`
  mutation upsertSmartRemValidationHistory($object: sensorflow_smart_rem_validation_history_insert_input!) {
    createSmartRemValidationHistory(
      object: $object
      on_conflict: { constraint: smart_rem_validation_history_pkey, update_columns: [validationEndDate] }
    ) {
      locationId
      validationStartDate
      validationEndDate
    }
  }
`;
export type UpsertSmartRemValidationHistoryMutationFn = ApolloReactCommon.MutationFunction<
  UpsertSmartRemValidationHistoryMutation,
  UpsertSmartRemValidationHistoryMutationVariables
>;

/**
 * __useUpsertSmartRemValidationHistoryMutation__
 *
 * To run a mutation, you first call `useUpsertSmartRemValidationHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSmartRemValidationHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSmartRemValidationHistoryMutation, { data, loading, error }] = useUpsertSmartRemValidationHistoryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertSmartRemValidationHistoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertSmartRemValidationHistoryMutation,
    UpsertSmartRemValidationHistoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpsertSmartRemValidationHistoryMutation,
    UpsertSmartRemValidationHistoryMutationVariables
  >(UpsertSmartRemValidationHistoryDocument, baseOptions);
}
export type UpsertSmartRemValidationHistoryMutationHookResult = ReturnType<
  typeof useUpsertSmartRemValidationHistoryMutation
>;
export type UpsertSmartRemValidationHistoryMutationResult = ApolloReactCommon.MutationResult<UpsertSmartRemValidationHistoryMutation>;
export type UpsertSmartRemValidationHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertSmartRemValidationHistoryMutation,
  UpsertSmartRemValidationHistoryMutationVariables
>;
export const GetSmartRemValidationHistoryDocument = gql`
  query getSmartRemValidationHistory($locationId: uuid!) {
    getSmartRemValidationHistory(
      where: { locationId: { _eq: $locationId } }
      order_by: { validationStartDate: desc }
      limit: 1
    ) {
      id
      locationId
      validationStartDate
      validationEndDate
      automationMode
      smartsaveSetpoint
    }
  }
`;

/**
 * __useGetSmartRemValidationHistoryQuery__
 *
 * To run a query within a React component, call `useGetSmartRemValidationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartRemValidationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartRemValidationHistoryQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetSmartRemValidationHistoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSmartRemValidationHistoryQuery,
    GetSmartRemValidationHistoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetSmartRemValidationHistoryQuery, GetSmartRemValidationHistoryQueryVariables>(
    GetSmartRemValidationHistoryDocument,
    baseOptions
  );
}
export function useGetSmartRemValidationHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSmartRemValidationHistoryQuery,
    GetSmartRemValidationHistoryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetSmartRemValidationHistoryQuery, GetSmartRemValidationHistoryQueryVariables>(
    GetSmartRemValidationHistoryDocument,
    baseOptions
  );
}
export type GetSmartRemValidationHistoryQueryHookResult = ReturnType<typeof useGetSmartRemValidationHistoryQuery>;
export type GetSmartRemValidationHistoryLazyQueryHookResult = ReturnType<
  typeof useGetSmartRemValidationHistoryLazyQuery
>;
export type GetSmartRemValidationHistoryQueryResult = ApolloReactCommon.QueryResult<
  GetSmartRemValidationHistoryQuery,
  GetSmartRemValidationHistoryQueryVariables
>;
export const CreateRmaRequestDocument = gql`
  mutation createRMARequest($input: CreateRMARequestInput!) {
    createRMARequest(input: $input) {
      id
    }
  }
`;
export type CreateRmaRequestMutationFn = ApolloReactCommon.MutationFunction<
  CreateRmaRequestMutation,
  CreateRmaRequestMutationVariables
>;

/**
 * __useCreateRmaRequestMutation__
 *
 * To run a mutation, you first call `useCreateRmaRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRmaRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRmaRequestMutation, { data, loading, error }] = useCreateRmaRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRmaRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRmaRequestMutation, CreateRmaRequestMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateRmaRequestMutation, CreateRmaRequestMutationVariables>(
    CreateRmaRequestDocument,
    baseOptions
  );
}
export type CreateRmaRequestMutationHookResult = ReturnType<typeof useCreateRmaRequestMutation>;
export type CreateRmaRequestMutationResult = ApolloReactCommon.MutationResult<CreateRmaRequestMutation>;
export type CreateRmaRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateRmaRequestMutation,
  CreateRmaRequestMutationVariables
>;
export const GenerateRmaAssetUploadLinkDocument = gql`
  query generateRMAAssetUploadLink($type: String!, $extension: String!) {
    generateRMAAssetUploadLink(type: $type, extension: $extension) {
      url
      expiredInSecs
      assetUri
    }
  }
`;

/**
 * __useGenerateRmaAssetUploadLinkQuery__
 *
 * To run a query within a React component, call `useGenerateRmaAssetUploadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateRmaAssetUploadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateRmaAssetUploadLinkQuery({
 *   variables: {
 *      type: // value for 'type'
 *      extension: // value for 'extension'
 *   },
 * });
 */
export function useGenerateRmaAssetUploadLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GenerateRmaAssetUploadLinkQuery,
    GenerateRmaAssetUploadLinkQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GenerateRmaAssetUploadLinkQuery, GenerateRmaAssetUploadLinkQueryVariables>(
    GenerateRmaAssetUploadLinkDocument,
    baseOptions
  );
}
export function useGenerateRmaAssetUploadLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GenerateRmaAssetUploadLinkQuery,
    GenerateRmaAssetUploadLinkQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GenerateRmaAssetUploadLinkQuery, GenerateRmaAssetUploadLinkQueryVariables>(
    GenerateRmaAssetUploadLinkDocument,
    baseOptions
  );
}
export type GenerateRmaAssetUploadLinkQueryHookResult = ReturnType<typeof useGenerateRmaAssetUploadLinkQuery>;
export type GenerateRmaAssetUploadLinkLazyQueryHookResult = ReturnType<typeof useGenerateRmaAssetUploadLinkLazyQuery>;
export type GenerateRmaAssetUploadLinkQueryResult = ApolloReactCommon.QueryResult<
  GenerateRmaAssetUploadLinkQuery,
  GenerateRmaAssetUploadLinkQueryVariables
>;
export const GetRmaAssetDownloadLinkDocument = gql`
  query getRMAAssetDownloadLink($jobId: String!, $type: String!) {
    getRMAAssetDownloadLink(jobId: $jobId, type: $type) {
      url
      expiredInSecs
    }
  }
`;

/**
 * __useGetRmaAssetDownloadLinkQuery__
 *
 * To run a query within a React component, call `useGetRmaAssetDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRmaAssetDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRmaAssetDownloadLinkQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetRmaAssetDownloadLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetRmaAssetDownloadLinkQuery, GetRmaAssetDownloadLinkQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetRmaAssetDownloadLinkQuery, GetRmaAssetDownloadLinkQueryVariables>(
    GetRmaAssetDownloadLinkDocument,
    baseOptions
  );
}
export function useGetRmaAssetDownloadLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRmaAssetDownloadLinkQuery,
    GetRmaAssetDownloadLinkQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetRmaAssetDownloadLinkQuery, GetRmaAssetDownloadLinkQueryVariables>(
    GetRmaAssetDownloadLinkDocument,
    baseOptions
  );
}
export type GetRmaAssetDownloadLinkQueryHookResult = ReturnType<typeof useGetRmaAssetDownloadLinkQuery>;
export type GetRmaAssetDownloadLinkLazyQueryHookResult = ReturnType<typeof useGetRmaAssetDownloadLinkLazyQuery>;
export type GetRmaAssetDownloadLinkQueryResult = ApolloReactCommon.QueryResult<
  GetRmaAssetDownloadLinkQuery,
  GetRmaAssetDownloadLinkQueryVariables
>;
export const NodeByMacIdDocument = gql`
  query nodeByMacId($nodeMacId: String!) {
    sensorflow_node_to_slot_mapping(where: { nodeMacId: { _eq: $nodeMacId } }) {
      position {
        positionId
        positionName
      }
      nodeMacId
      nodeType
      mappedTime
      node {
        nodeSubType
        gateway {
          gatewayId
          gatewayName
        }
      }
    }
  }
`;

/**
 * __useNodeByMacIdQuery__
 *
 * To run a query within a React component, call `useNodeByMacIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeByMacIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeByMacIdQuery({
 *   variables: {
 *      nodeMacId: // value for 'nodeMacId'
 *   },
 * });
 */
export function useNodeByMacIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<NodeByMacIdQuery, NodeByMacIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<NodeByMacIdQuery, NodeByMacIdQueryVariables>(NodeByMacIdDocument, baseOptions);
}
export function useNodeByMacIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NodeByMacIdQuery, NodeByMacIdQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<NodeByMacIdQuery, NodeByMacIdQueryVariables>(NodeByMacIdDocument, baseOptions);
}
export type NodeByMacIdQueryHookResult = ReturnType<typeof useNodeByMacIdQuery>;
export type NodeByMacIdLazyQueryHookResult = ReturnType<typeof useNodeByMacIdLazyQuery>;
export type NodeByMacIdQueryResult = ApolloReactCommon.QueryResult<NodeByMacIdQuery, NodeByMacIdQueryVariables>;
export const GetRmaRootCausesDocument = gql`
  query getRMARootCauses {
    sf_support_root_causes(order_by: { id: asc }) {
      id
      root_cause
    }
  }
`;

/**
 * __useGetRmaRootCausesQuery__
 *
 * To run a query within a React component, call `useGetRmaRootCausesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRmaRootCausesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRmaRootCausesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRmaRootCausesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetRmaRootCausesQuery, GetRmaRootCausesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetRmaRootCausesQuery, GetRmaRootCausesQueryVariables>(
    GetRmaRootCausesDocument,
    baseOptions
  );
}
export function useGetRmaRootCausesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRmaRootCausesQuery, GetRmaRootCausesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetRmaRootCausesQuery, GetRmaRootCausesQueryVariables>(
    GetRmaRootCausesDocument,
    baseOptions
  );
}
export type GetRmaRootCausesQueryHookResult = ReturnType<typeof useGetRmaRootCausesQuery>;
export type GetRmaRootCausesLazyQueryHookResult = ReturnType<typeof useGetRmaRootCausesLazyQuery>;
export type GetRmaRootCausesQueryResult = ApolloReactCommon.QueryResult<
  GetRmaRootCausesQuery,
  GetRmaRootCausesQueryVariables
>;
export const GetLastNodeLifeCycleEventDocument = gql`
  query getLastNodeLifeCycleEvent($nodeMacId: String!) {
    sensorflow_node_life_cycle_events(
      where: { nodeMacId: { _eq: $nodeMacId } }
      limit: 1
      order_by: { creationDate: desc }
    ) {
      id
      nodeMacId
      eventType
      creationDate
      comment
    }
  }
`;

/**
 * __useGetLastNodeLifeCycleEventQuery__
 *
 * To run a query within a React component, call `useGetLastNodeLifeCycleEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastNodeLifeCycleEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastNodeLifeCycleEventQuery({
 *   variables: {
 *      nodeMacId: // value for 'nodeMacId'
 *   },
 * });
 */
export function useGetLastNodeLifeCycleEventQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLastNodeLifeCycleEventQuery,
    GetLastNodeLifeCycleEventQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetLastNodeLifeCycleEventQuery, GetLastNodeLifeCycleEventQueryVariables>(
    GetLastNodeLifeCycleEventDocument,
    baseOptions
  );
}
export function useGetLastNodeLifeCycleEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLastNodeLifeCycleEventQuery,
    GetLastNodeLifeCycleEventQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetLastNodeLifeCycleEventQuery, GetLastNodeLifeCycleEventQueryVariables>(
    GetLastNodeLifeCycleEventDocument,
    baseOptions
  );
}
export type GetLastNodeLifeCycleEventQueryHookResult = ReturnType<typeof useGetLastNodeLifeCycleEventQuery>;
export type GetLastNodeLifeCycleEventLazyQueryHookResult = ReturnType<typeof useGetLastNodeLifeCycleEventLazyQuery>;
export type GetLastNodeLifeCycleEventQueryResult = ApolloReactCommon.QueryResult<
  GetLastNodeLifeCycleEventQuery,
  GetLastNodeLifeCycleEventQueryVariables
>;
export const GetCurrentNodeToPositionMappingDocument = gql`
  query getCurrentNodeToPositionMapping($nodeMacId: String!) {
    sensorflow_node_to_position_mapping(
      where: { nodeMacId: { _eq: $nodeMacId } }
      limit: 1
      order_by: { installedTime: desc }
    ) {
      decomissionedTime
      installedTime
      nodeMacId
      positionId
    }
  }
`;

/**
 * __useGetCurrentNodeToPositionMappingQuery__
 *
 * To run a query within a React component, call `useGetCurrentNodeToPositionMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentNodeToPositionMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentNodeToPositionMappingQuery({
 *   variables: {
 *      nodeMacId: // value for 'nodeMacId'
 *   },
 * });
 */
export function useGetCurrentNodeToPositionMappingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCurrentNodeToPositionMappingQuery,
    GetCurrentNodeToPositionMappingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCurrentNodeToPositionMappingQuery, GetCurrentNodeToPositionMappingQueryVariables>(
    GetCurrentNodeToPositionMappingDocument,
    baseOptions
  );
}
export function useGetCurrentNodeToPositionMappingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCurrentNodeToPositionMappingQuery,
    GetCurrentNodeToPositionMappingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCurrentNodeToPositionMappingQuery,
    GetCurrentNodeToPositionMappingQueryVariables
  >(GetCurrentNodeToPositionMappingDocument, baseOptions);
}
export type GetCurrentNodeToPositionMappingQueryHookResult = ReturnType<typeof useGetCurrentNodeToPositionMappingQuery>;
export type GetCurrentNodeToPositionMappingLazyQueryHookResult = ReturnType<
  typeof useGetCurrentNodeToPositionMappingLazyQuery
>;
export type GetCurrentNodeToPositionMappingQueryResult = ApolloReactCommon.QueryResult<
  GetCurrentNodeToPositionMappingQuery,
  GetCurrentNodeToPositionMappingQueryVariables
>;
export const InsertNodeLifeCycleEventDocument = gql`
  mutation insertNodeLifeCycleEvent($object: sensorflow_node_life_cycle_events_insert_input!) {
    insert_sensorflow_node_life_cycle_events_one(object: $object) {
      id
      nodeMacId
      eventType
      creationDate
      comment
    }
  }
`;
export type InsertNodeLifeCycleEventMutationFn = ApolloReactCommon.MutationFunction<
  InsertNodeLifeCycleEventMutation,
  InsertNodeLifeCycleEventMutationVariables
>;

/**
 * __useInsertNodeLifeCycleEventMutation__
 *
 * To run a mutation, you first call `useInsertNodeLifeCycleEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNodeLifeCycleEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNodeLifeCycleEventMutation, { data, loading, error }] = useInsertNodeLifeCycleEventMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertNodeLifeCycleEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    InsertNodeLifeCycleEventMutation,
    InsertNodeLifeCycleEventMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<InsertNodeLifeCycleEventMutation, InsertNodeLifeCycleEventMutationVariables>(
    InsertNodeLifeCycleEventDocument,
    baseOptions
  );
}
export type InsertNodeLifeCycleEventMutationHookResult = ReturnType<typeof useInsertNodeLifeCycleEventMutation>;
export type InsertNodeLifeCycleEventMutationResult = ApolloReactCommon.MutationResult<InsertNodeLifeCycleEventMutation>;
export type InsertNodeLifeCycleEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InsertNodeLifeCycleEventMutation,
  InsertNodeLifeCycleEventMutationVariables
>;
export const InfrastructureListDocument = gql`
  subscription infrastructureList($where: sensorflow_v_infrastructures_bool_exp!, $offset: Int!, $limit: Int!) {
    infrastructures(where: $where, offset: $offset, limit: $limit, order_by: { name: asc }) {
      id
      meterPositionId
      name
      type
      phaseStreamIndex
      position {
        positionId
        positionName
        positionType
      }
      rooms: compressorPositionMappings {
        position {
          parentPosition {
            positionName
          }
        }
      }
      meterPosition {
        nodes: nodeJoinStatuses {
          nodeType
          slotName
          nodeMacId
          lastSeen
          status
          nodeStatusInInstallationMode
        }
      }
    }
  }
`;

/**
 * __useInfrastructureListSubscription__
 *
 * To run a query within a React component, call `useInfrastructureListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInfrastructureListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfrastructureListSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useInfrastructureListSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    InfrastructureListSubscription,
    InfrastructureListSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<InfrastructureListSubscription, InfrastructureListSubscriptionVariables>(
    InfrastructureListDocument,
    baseOptions
  );
}
export type InfrastructureListSubscriptionHookResult = ReturnType<typeof useInfrastructureListSubscription>;
export type InfrastructureListSubscriptionResult = ApolloReactCommon.SubscriptionResult<InfrastructureListSubscription>;
export const GetNodeSpareSetsDocument = gql`
  query getNodeSpareSets(
    $where: sensorflow_node_spare_set_bool_exp!
    $order_by: [sensorflow_node_spare_set_order_by!]
  ) {
    sensorflow_node_spare_set(where: $where, order_by: $order_by) {
      nodeMacId
      locationId
      mappedAt
      consumedAt
      node {
        node_type
        nodeSubType
      }
    }
  }
`;

/**
 * __useGetNodeSpareSetsQuery__
 *
 * To run a query within a React component, call `useGetNodeSpareSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeSpareSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeSpareSetsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetNodeSpareSetsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetNodeSpareSetsQuery, GetNodeSpareSetsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetNodeSpareSetsQuery, GetNodeSpareSetsQueryVariables>(
    GetNodeSpareSetsDocument,
    baseOptions
  );
}
export function useGetNodeSpareSetsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNodeSpareSetsQuery, GetNodeSpareSetsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetNodeSpareSetsQuery, GetNodeSpareSetsQueryVariables>(
    GetNodeSpareSetsDocument,
    baseOptions
  );
}
export type GetNodeSpareSetsQueryHookResult = ReturnType<typeof useGetNodeSpareSetsQuery>;
export type GetNodeSpareSetsLazyQueryHookResult = ReturnType<typeof useGetNodeSpareSetsLazyQuery>;
export type GetNodeSpareSetsQueryResult = ApolloReactCommon.QueryResult<
  GetNodeSpareSetsQuery,
  GetNodeSpareSetsQueryVariables
>;
export const GetNodeLifeCycleHistoryDocument = gql`
  query getNodeLifeCycleHistory(
    $where: sensorflow_v_node_life_cycle_events_bool_exp
    $order_by: [sensorflow_v_node_life_cycle_events_order_by!]
  ) {
    sensorflow_v_node_life_cycle_events(where: $where, order_by: $order_by) {
      nodeMacId
      changedAt
      eventType
      comment
      nodeSubType
      nodeType
    }
  }
`;

/**
 * __useGetNodeLifeCycleHistoryQuery__
 *
 * To run a query within a React component, call `useGetNodeLifeCycleHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeLifeCycleHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeLifeCycleHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetNodeLifeCycleHistoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetNodeLifeCycleHistoryQuery, GetNodeLifeCycleHistoryQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetNodeLifeCycleHistoryQuery, GetNodeLifeCycleHistoryQueryVariables>(
    GetNodeLifeCycleHistoryDocument,
    baseOptions
  );
}
export function useGetNodeLifeCycleHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNodeLifeCycleHistoryQuery,
    GetNodeLifeCycleHistoryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetNodeLifeCycleHistoryQuery, GetNodeLifeCycleHistoryQueryVariables>(
    GetNodeLifeCycleHistoryDocument,
    baseOptions
  );
}
export type GetNodeLifeCycleHistoryQueryHookResult = ReturnType<typeof useGetNodeLifeCycleHistoryQuery>;
export type GetNodeLifeCycleHistoryLazyQueryHookResult = ReturnType<typeof useGetNodeLifeCycleHistoryLazyQuery>;
export type GetNodeLifeCycleHistoryQueryResult = ApolloReactCommon.QueryResult<
  GetNodeLifeCycleHistoryQuery,
  GetNodeLifeCycleHistoryQueryVariables
>;
export const GetNodeSlotsDocument = gql`
  query getNodeSlots($roomId: uuid) {
    sensorflow_node_slots(where: { parentPositionId: { _eq: $roomId } }) {
      nodeSubType
      nodeType
      slotName
    }
  }
`;

/**
 * __useGetNodeSlotsQuery__
 *
 * To run a query within a React component, call `useGetNodeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeSlotsQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useGetNodeSlotsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetNodeSlotsQuery, GetNodeSlotsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetNodeSlotsQuery, GetNodeSlotsQueryVariables>(GetNodeSlotsDocument, baseOptions);
}
export function useGetNodeSlotsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNodeSlotsQuery, GetNodeSlotsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetNodeSlotsQuery, GetNodeSlotsQueryVariables>(
    GetNodeSlotsDocument,
    baseOptions
  );
}
export type GetNodeSlotsQueryHookResult = ReturnType<typeof useGetNodeSlotsQuery>;
export type GetNodeSlotsLazyQueryHookResult = ReturnType<typeof useGetNodeSlotsLazyQuery>;
export type GetNodeSlotsQueryResult = ApolloReactCommon.QueryResult<GetNodeSlotsQuery, GetNodeSlotsQueryVariables>;
export const GetInfrasByPositionMeterIdDocument = gql`
  query getInfrasByPositionMeterId($meterPositionId: uuid!) {
    infrastructures(where: { meterPositionId: { _eq: $meterPositionId } }) {
      id
      name
      phaseStreamIndex
      type
      meterPosition {
        slotMappings {
          nodeMacId
          slotName
        }
      }
    }
  }
`;

/**
 * __useGetInfrasByPositionMeterIdQuery__
 *
 * To run a query within a React component, call `useGetInfrasByPositionMeterIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfrasByPositionMeterIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfrasByPositionMeterIdQuery({
 *   variables: {
 *      meterPositionId: // value for 'meterPositionId'
 *   },
 * });
 */
export function useGetInfrasByPositionMeterIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetInfrasByPositionMeterIdQuery,
    GetInfrasByPositionMeterIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetInfrasByPositionMeterIdQuery, GetInfrasByPositionMeterIdQueryVariables>(
    GetInfrasByPositionMeterIdDocument,
    baseOptions
  );
}
export function useGetInfrasByPositionMeterIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetInfrasByPositionMeterIdQuery,
    GetInfrasByPositionMeterIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetInfrasByPositionMeterIdQuery, GetInfrasByPositionMeterIdQueryVariables>(
    GetInfrasByPositionMeterIdDocument,
    baseOptions
  );
}
export type GetInfrasByPositionMeterIdQueryHookResult = ReturnType<typeof useGetInfrasByPositionMeterIdQuery>;
export type GetInfrasByPositionMeterIdLazyQueryHookResult = ReturnType<typeof useGetInfrasByPositionMeterIdLazyQuery>;
export type GetInfrasByPositionMeterIdQueryResult = ApolloReactCommon.QueryResult<
  GetInfrasByPositionMeterIdQuery,
  GetInfrasByPositionMeterIdQueryVariables
>;
export const UpdateKeyEntryExpiredAtDocument = gql`
  mutation updateKeyEntryExpiredAt($keyEntryId: Int!, $expiredAt: timestamptz!) {
    update_keyEntries_by_pk(pk_columns: { keyEntryId: $keyEntryId }, _set: { expiredAt: $expiredAt }) {
      keyEntryId
      expiredAt
    }
  }
`;
export type UpdateKeyEntryExpiredAtMutationFn = ApolloReactCommon.MutationFunction<
  UpdateKeyEntryExpiredAtMutation,
  UpdateKeyEntryExpiredAtMutationVariables
>;

/**
 * __useUpdateKeyEntryExpiredAtMutation__
 *
 * To run a mutation, you first call `useUpdateKeyEntryExpiredAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeyEntryExpiredAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeyEntryExpiredAtMutation, { data, loading, error }] = useUpdateKeyEntryExpiredAtMutation({
 *   variables: {
 *      keyEntryId: // value for 'keyEntryId'
 *      expiredAt: // value for 'expiredAt'
 *   },
 * });
 */
export function useUpdateKeyEntryExpiredAtMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateKeyEntryExpiredAtMutation,
    UpdateKeyEntryExpiredAtMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateKeyEntryExpiredAtMutation, UpdateKeyEntryExpiredAtMutationVariables>(
    UpdateKeyEntryExpiredAtDocument,
    baseOptions
  );
}
export type UpdateKeyEntryExpiredAtMutationHookResult = ReturnType<typeof useUpdateKeyEntryExpiredAtMutation>;
export type UpdateKeyEntryExpiredAtMutationResult = ApolloReactCommon.MutationResult<UpdateKeyEntryExpiredAtMutation>;
export type UpdateKeyEntryExpiredAtMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateKeyEntryExpiredAtMutation,
  UpdateKeyEntryExpiredAtMutationVariables
>;
export const GetEntryHasUnResolvedCommentDocument = gql`
  query getEntryHasUnResolvedComment($keyPositionId: String!) {
    keyEntries(
      where: {
        _and: [
          { comment: { _is_null: false }, keyPositionId: { _eq: $keyPositionId } }
          { _or: [{ commentStatus: { _neq: "resolved" } }, { commentStatus: { _is_null: true } }] }
        ]
      }
    ) {
      keyEntryId
      comment
      commentStatus
    }
  }
`;

/**
 * __useGetEntryHasUnResolvedCommentQuery__
 *
 * To run a query within a React component, call `useGetEntryHasUnResolvedCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntryHasUnResolvedCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntryHasUnResolvedCommentQuery({
 *   variables: {
 *      keyPositionId: // value for 'keyPositionId'
 *   },
 * });
 */
export function useGetEntryHasUnResolvedCommentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetEntryHasUnResolvedCommentQuery,
    GetEntryHasUnResolvedCommentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetEntryHasUnResolvedCommentQuery, GetEntryHasUnResolvedCommentQueryVariables>(
    GetEntryHasUnResolvedCommentDocument,
    baseOptions
  );
}
export function useGetEntryHasUnResolvedCommentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEntryHasUnResolvedCommentQuery,
    GetEntryHasUnResolvedCommentQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetEntryHasUnResolvedCommentQuery, GetEntryHasUnResolvedCommentQueryVariables>(
    GetEntryHasUnResolvedCommentDocument,
    baseOptions
  );
}
export type GetEntryHasUnResolvedCommentQueryHookResult = ReturnType<typeof useGetEntryHasUnResolvedCommentQuery>;
export type GetEntryHasUnResolvedCommentLazyQueryHookResult = ReturnType<
  typeof useGetEntryHasUnResolvedCommentLazyQuery
>;
export type GetEntryHasUnResolvedCommentQueryResult = ApolloReactCommon.QueryResult<
  GetEntryHasUnResolvedCommentQuery,
  GetEntryHasUnResolvedCommentQueryVariables
>;
export const UpdateKeyEntryCommentDocument = gql`
  mutation updateKeyEntryComment($keyEntryId: Int!, $comment: String!, $commentStatus: String!) {
    update_keyEntries_by_pk(
      pk_columns: { keyEntryId: $keyEntryId }
      _set: { comment: $comment, commentStatus: $commentStatus, status: null }
    ) {
      keyEntryId
      commentStatus
    }
  }
`;
export type UpdateKeyEntryCommentMutationFn = ApolloReactCommon.MutationFunction<
  UpdateKeyEntryCommentMutation,
  UpdateKeyEntryCommentMutationVariables
>;

/**
 * __useUpdateKeyEntryCommentMutation__
 *
 * To run a mutation, you first call `useUpdateKeyEntryCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeyEntryCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKeyEntryCommentMutation, { data, loading, error }] = useUpdateKeyEntryCommentMutation({
 *   variables: {
 *      keyEntryId: // value for 'keyEntryId'
 *      comment: // value for 'comment'
 *      commentStatus: // value for 'commentStatus'
 *   },
 * });
 */
export function useUpdateKeyEntryCommentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateKeyEntryCommentMutation,
    UpdateKeyEntryCommentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateKeyEntryCommentMutation, UpdateKeyEntryCommentMutationVariables>(
    UpdateKeyEntryCommentDocument,
    baseOptions
  );
}
export type UpdateKeyEntryCommentMutationHookResult = ReturnType<typeof useUpdateKeyEntryCommentMutation>;
export type UpdateKeyEntryCommentMutationResult = ApolloReactCommon.MutationResult<UpdateKeyEntryCommentMutation>;
export type UpdateKeyEntryCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateKeyEntryCommentMutation,
  UpdateKeyEntryCommentMutationVariables
>;
export const GetMeterPositionIdMappedNodeMacIdDocument = gql`
  query getMeterPositionIdMappedNodeMacId($nodeMacId: String) {
    sensorflow_node_to_slot_mapping(where: { nodeMacId: { _eq: $nodeMacId } }) {
      parentPositionId
    }
  }
`;

/**
 * __useGetMeterPositionIdMappedNodeMacIdQuery__
 *
 * To run a query within a React component, call `useGetMeterPositionIdMappedNodeMacIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeterPositionIdMappedNodeMacIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeterPositionIdMappedNodeMacIdQuery({
 *   variables: {
 *      nodeMacId: // value for 'nodeMacId'
 *   },
 * });
 */
export function useGetMeterPositionIdMappedNodeMacIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMeterPositionIdMappedNodeMacIdQuery,
    GetMeterPositionIdMappedNodeMacIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMeterPositionIdMappedNodeMacIdQuery,
    GetMeterPositionIdMappedNodeMacIdQueryVariables
  >(GetMeterPositionIdMappedNodeMacIdDocument, baseOptions);
}
export function useGetMeterPositionIdMappedNodeMacIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMeterPositionIdMappedNodeMacIdQuery,
    GetMeterPositionIdMappedNodeMacIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMeterPositionIdMappedNodeMacIdQuery,
    GetMeterPositionIdMappedNodeMacIdQueryVariables
  >(GetMeterPositionIdMappedNodeMacIdDocument, baseOptions);
}
export type GetMeterPositionIdMappedNodeMacIdQueryHookResult = ReturnType<
  typeof useGetMeterPositionIdMappedNodeMacIdQuery
>;
export type GetMeterPositionIdMappedNodeMacIdLazyQueryHookResult = ReturnType<
  typeof useGetMeterPositionIdMappedNodeMacIdLazyQuery
>;
export type GetMeterPositionIdMappedNodeMacIdQueryResult = ApolloReactCommon.QueryResult<
  GetMeterPositionIdMappedNodeMacIdQuery,
  GetMeterPositionIdMappedNodeMacIdQueryVariables
>;
export const OnlineGatewayHealthDataByLocationDocument = gql`
  query onlineGatewayHealthDataByLocation($locationId: uuid!, $offlineTime: timestamptz) {
    gateways(where: { position: { locationId: { _eq: $locationId } }, deletedAt: { _is_null: true } }) {
      gatewayId
      gatewayHealthData(
        where: { measurementType: { _eq: "wlan_level" }, time: { _gt: $offlineTime } }
        limit: 1
        order_by: { time: desc }
      ) {
        measurementType
        time
        value
      }
      wlanLinkGatewayHealthData: gatewayHealthData(
        where: { measurementType: { _eq: "wlan_link" }, time: { _gt: $offlineTime } }
        limit: 1
        order_by: { time: desc }
      ) {
        measurementType
        time
        value
      }
      wlan1LinkGatewayHealthData: gatewayHealthData(
        where: { measurementType: { _eq: "wlan1_link" }, time: { _gt: $offlineTime } }
        limit: 1
        order_by: { time: desc }
      ) {
        measurementType
        time
        value
      }
    }
  }
`;

/**
 * __useOnlineGatewayHealthDataByLocationQuery__
 *
 * To run a query within a React component, call `useOnlineGatewayHealthDataByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineGatewayHealthDataByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineGatewayHealthDataByLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      offlineTime: // value for 'offlineTime'
 *   },
 * });
 */
export function useOnlineGatewayHealthDataByLocationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OnlineGatewayHealthDataByLocationQuery,
    OnlineGatewayHealthDataByLocationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    OnlineGatewayHealthDataByLocationQuery,
    OnlineGatewayHealthDataByLocationQueryVariables
  >(OnlineGatewayHealthDataByLocationDocument, baseOptions);
}
export function useOnlineGatewayHealthDataByLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OnlineGatewayHealthDataByLocationQuery,
    OnlineGatewayHealthDataByLocationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    OnlineGatewayHealthDataByLocationQuery,
    OnlineGatewayHealthDataByLocationQueryVariables
  >(OnlineGatewayHealthDataByLocationDocument, baseOptions);
}
export type OnlineGatewayHealthDataByLocationQueryHookResult = ReturnType<
  typeof useOnlineGatewayHealthDataByLocationQuery
>;
export type OnlineGatewayHealthDataByLocationLazyQueryHookResult = ReturnType<
  typeof useOnlineGatewayHealthDataByLocationLazyQuery
>;
export type OnlineGatewayHealthDataByLocationQueryResult = ApolloReactCommon.QueryResult<
  OnlineGatewayHealthDataByLocationQuery,
  OnlineGatewayHealthDataByLocationQueryVariables
>;
export const PositionConfigurationByPositionIdsDocument = gql`
  query positionConfigurationByPositionIds($positionIds: [uuid!]) {
    positionConfigurations(where: { positionId: { _in: $positionIds }, recordType: { _eq: "CURRENT" } }) {
      acModelId
      automationMode
      isDeviatedFromDefaultConfig
      creationDate
      occNightStart
      occNightEnd
      occTimeoutNightMins
      occTimeoutDayMins
      occupancySensitivity
      doorSensorTimeoutMins
      softstartWaitTime
      hysteresisOffDegrees
      hysteresisOnDegrees
      hideSetpoint
      heatingHideSetpoint
      unoccupiedTemp
      heatingUnoccupiedTemp
      minTemp
      maxTemp
      heatingMinTemp
      heatingMaxTemp
      acNightEnd
      acNightStart
      actuationTimeoutSeconds
      bridgeAdaptorSetting
      desiredEmonSwitchPosition
      freezeProtectionTemp
      modifiedByUser {
        userId
        name
      }
      recordType
      actingMode
      operationalMode
      expiredAt
      enableDoorSensor
    }
  }
`;

/**
 * __usePositionConfigurationByPositionIdsQuery__
 *
 * To run a query within a React component, call `usePositionConfigurationByPositionIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionConfigurationByPositionIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionConfigurationByPositionIdsQuery({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *   },
 * });
 */
export function usePositionConfigurationByPositionIdsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PositionConfigurationByPositionIdsQuery,
    PositionConfigurationByPositionIdsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PositionConfigurationByPositionIdsQuery,
    PositionConfigurationByPositionIdsQueryVariables
  >(PositionConfigurationByPositionIdsDocument, baseOptions);
}
export function usePositionConfigurationByPositionIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PositionConfigurationByPositionIdsQuery,
    PositionConfigurationByPositionIdsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PositionConfigurationByPositionIdsQuery,
    PositionConfigurationByPositionIdsQueryVariables
  >(PositionConfigurationByPositionIdsDocument, baseOptions);
}
export type PositionConfigurationByPositionIdsQueryHookResult = ReturnType<
  typeof usePositionConfigurationByPositionIdsQuery
>;
export type PositionConfigurationByPositionIdsLazyQueryHookResult = ReturnType<
  typeof usePositionConfigurationByPositionIdsLazyQuery
>;
export type PositionConfigurationByPositionIdsQueryResult = ApolloReactCommon.QueryResult<
  PositionConfigurationByPositionIdsQuery,
  PositionConfigurationByPositionIdsQueryVariables
>;
export const GetCurrencyListDocument = gql`
  query getCurrencyList {
    currencies: sensorflow_currencies(limit: 1000000) {
      name
      shortCode
      id
    }
  }
`;

/**
 * __useGetCurrencyListQuery__
 *
 * To run a query within a React component, call `useGetCurrencyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrencyListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrencyListQuery, GetCurrencyListQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetCurrencyListQuery, GetCurrencyListQueryVariables>(
    GetCurrencyListDocument,
    baseOptions
  );
}
export function useGetCurrencyListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrencyListQuery, GetCurrencyListQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetCurrencyListQuery, GetCurrencyListQueryVariables>(
    GetCurrencyListDocument,
    baseOptions
  );
}
export type GetCurrencyListQueryHookResult = ReturnType<typeof useGetCurrencyListQuery>;
export type GetCurrencyListLazyQueryHookResult = ReturnType<typeof useGetCurrencyListLazyQuery>;
export type GetCurrencyListQueryResult = ApolloReactCommon.QueryResult<
  GetCurrencyListQuery,
  GetCurrencyListQueryVariables
>;
export const GetTimeZoneListDocument = gql`
  query getTimeZoneList {
    sensorflow_timezones(limit: 1000000) {
      id
      timezone
    }
  }
`;

/**
 * __useGetTimeZoneListQuery__
 *
 * To run a query within a React component, call `useGetTimeZoneListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeZoneListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeZoneListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimeZoneListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTimeZoneListQuery, GetTimeZoneListQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetTimeZoneListQuery, GetTimeZoneListQueryVariables>(
    GetTimeZoneListDocument,
    baseOptions
  );
}
export function useGetTimeZoneListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTimeZoneListQuery, GetTimeZoneListQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetTimeZoneListQuery, GetTimeZoneListQueryVariables>(
    GetTimeZoneListDocument,
    baseOptions
  );
}
export type GetTimeZoneListQueryHookResult = ReturnType<typeof useGetTimeZoneListQuery>;
export type GetTimeZoneListLazyQueryHookResult = ReturnType<typeof useGetTimeZoneListLazyQuery>;
export type GetTimeZoneListQueryResult = ApolloReactCommon.QueryResult<
  GetTimeZoneListQuery,
  GetTimeZoneListQueryVariables
>;
export const PrecoolCommandsDocument = gql`
  query precoolCommands($where: sensorflow_precool_commands_bool_exp) {
    sensorflow_precool_commands(where: $where) {
      id
      positionId
      createdBy
      source
      updatedAt
      mode
      temperature
      startedAt
      user {
        name
      }
    }
  }
`;

/**
 * __usePrecoolCommandsQuery__
 *
 * To run a query within a React component, call `usePrecoolCommandsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrecoolCommandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrecoolCommandsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePrecoolCommandsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PrecoolCommandsQuery, PrecoolCommandsQueryVariables>
) {
  return ApolloReactHooks.useQuery<PrecoolCommandsQuery, PrecoolCommandsQueryVariables>(
    PrecoolCommandsDocument,
    baseOptions
  );
}
export function usePrecoolCommandsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrecoolCommandsQuery, PrecoolCommandsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<PrecoolCommandsQuery, PrecoolCommandsQueryVariables>(
    PrecoolCommandsDocument,
    baseOptions
  );
}
export type PrecoolCommandsQueryHookResult = ReturnType<typeof usePrecoolCommandsQuery>;
export type PrecoolCommandsLazyQueryHookResult = ReturnType<typeof usePrecoolCommandsLazyQuery>;
export type PrecoolCommandsQueryResult = ApolloReactCommon.QueryResult<
  PrecoolCommandsQuery,
  PrecoolCommandsQueryVariables
>;
export const PositionPrecoolStatusByLocationIdDocument = gql`
  subscription positionPrecoolStatusByLocationId($locationId: uuid!) {
    positionPrecoolStatus(where: { locationId: { _eq: $locationId } }) {
      precoolCommandId
      status
      startedAt
      temperature
      totalMinutes
      mode
      positionId
      parentPositionId
      createdBy
      creationDate
    }
  }
`;

/**
 * __usePositionPrecoolStatusByLocationIdSubscription__
 *
 * To run a query within a React component, call `usePositionPrecoolStatusByLocationIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePositionPrecoolStatusByLocationIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionPrecoolStatusByLocationIdSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function usePositionPrecoolStatusByLocationIdSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    PositionPrecoolStatusByLocationIdSubscription,
    PositionPrecoolStatusByLocationIdSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    PositionPrecoolStatusByLocationIdSubscription,
    PositionPrecoolStatusByLocationIdSubscriptionVariables
  >(PositionPrecoolStatusByLocationIdDocument, baseOptions);
}
export type PositionPrecoolStatusByLocationIdSubscriptionHookResult = ReturnType<
  typeof usePositionPrecoolStatusByLocationIdSubscription
>;
export type PositionPrecoolStatusByLocationIdSubscriptionResult = ApolloReactCommon.SubscriptionResult<PositionPrecoolStatusByLocationIdSubscription>;
export const ExportClickupProressSubscriptionDocument = gql`
  subscription exportClickupProressSubscription($locationId: uuid!) {
    locationOne(locationId: $locationId) {
      locationId
      clickupListId
      clickupExportProgress
    }
  }
`;

/**
 * __useExportClickupProressSubscriptionSubscription__
 *
 * To run a query within a React component, call `useExportClickupProressSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExportClickupProressSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportClickupProressSubscriptionSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useExportClickupProressSubscriptionSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ExportClickupProressSubscriptionSubscription,
    ExportClickupProressSubscriptionSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    ExportClickupProressSubscriptionSubscription,
    ExportClickupProressSubscriptionSubscriptionVariables
  >(ExportClickupProressSubscriptionDocument, baseOptions);
}
export type ExportClickupProressSubscriptionSubscriptionHookResult = ReturnType<
  typeof useExportClickupProressSubscriptionSubscription
>;
export type ExportClickupProressSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<ExportClickupProressSubscriptionSubscription>;
export const GetUsesForLocationDocument = gql`
  query getUsesForLocation($locationId: uuid!) {
    sensorflow_location_to_user_mapping(where: { location_id: { _eq: $locationId } }) {
      user {
        name
        email
      }
    }
  }
`;

/**
 * __useGetUsesForLocationQuery__
 *
 * To run a query within a React component, call `useGetUsesForLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsesForLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsesForLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetUsesForLocationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsesForLocationQuery, GetUsesForLocationQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetUsesForLocationQuery, GetUsesForLocationQueryVariables>(
    GetUsesForLocationDocument,
    baseOptions
  );
}
export function useGetUsesForLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsesForLocationQuery, GetUsesForLocationQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetUsesForLocationQuery, GetUsesForLocationQueryVariables>(
    GetUsesForLocationDocument,
    baseOptions
  );
}
export type GetUsesForLocationQueryHookResult = ReturnType<typeof useGetUsesForLocationQuery>;
export type GetUsesForLocationLazyQueryHookResult = ReturnType<typeof useGetUsesForLocationLazyQuery>;
export type GetUsesForLocationQueryResult = ApolloReactCommon.QueryResult<
  GetUsesForLocationQuery,
  GetUsesForLocationQueryVariables
>;
export const CreatePositionDocument = gql`
  mutation createPosition($input: sensorflow_positions_insert_input!) {
    createPosition(object: $input) {
      locationId
      positionId
    }
  }
`;
export type CreatePositionMutationFn = ApolloReactCommon.MutationFunction<
  CreatePositionMutation,
  CreatePositionMutationVariables
>;

/**
 * __useCreatePositionMutation__
 *
 * To run a mutation, you first call `useCreatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPositionMutation, { data, loading, error }] = useCreatePositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePositionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePositionMutation, CreatePositionMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreatePositionMutation, CreatePositionMutationVariables>(
    CreatePositionDocument,
    baseOptions
  );
}
export type CreatePositionMutationHookResult = ReturnType<typeof useCreatePositionMutation>;
export type CreatePositionMutationResult = ApolloReactCommon.MutationResult<CreatePositionMutation>;
export type CreatePositionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePositionMutation,
  CreatePositionMutationVariables
>;
export const GroupsDocument = gql`
  query groups($where: sensorflow_v_group_with_stats_bool_exp!, $offset: Int!, $limit: Int!) {
    sensorflow_v_group_with_stats(where: $where, offset: $offset, limit: $limit, order_by: { positionName: asc }) {
      positionId
      positionName
      positionType
      keysCount
      gatewaysCount
      compressorsCount
    }
    groupsAggregate: sensorflow_v_group_with_stats_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
  return ApolloReactHooks.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, baseOptions);
}
export function useGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, baseOptions);
}
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = ApolloReactCommon.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const GetGroupsForDropdownDocument = gql`
  query getGroupsForDropdown($where: sensorflow_positions_bool_exp) {
    positions(where: $where) {
      positionId
      positionName
    }
  }
`;

/**
 * __useGetGroupsForDropdownQuery__
 *
 * To run a query within a React component, call `useGetGroupsForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsForDropdownQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetGroupsForDropdownQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupsForDropdownQuery, GetGroupsForDropdownQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetGroupsForDropdownQuery, GetGroupsForDropdownQueryVariables>(
    GetGroupsForDropdownDocument,
    baseOptions
  );
}
export function useGetGroupsForDropdownLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupsForDropdownQuery, GetGroupsForDropdownQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetGroupsForDropdownQuery, GetGroupsForDropdownQueryVariables>(
    GetGroupsForDropdownDocument,
    baseOptions
  );
}
export type GetGroupsForDropdownQueryHookResult = ReturnType<typeof useGetGroupsForDropdownQuery>;
export type GetGroupsForDropdownLazyQueryHookResult = ReturnType<typeof useGetGroupsForDropdownLazyQuery>;
export type GetGroupsForDropdownQueryResult = ApolloReactCommon.QueryResult<
  GetGroupsForDropdownQuery,
  GetGroupsForDropdownQueryVariables
>;
export const GroupTasksDocument = gql`
  query groupTasks($where: sensorflow_v_group_tasks_bool_exp!, $offset: Int!, $limit: Int!) {
    sensorflow_v_group_tasks(where: $where, offset: $offset, limit: $limit, order_by: { positionName: asc }) {
      positionId
      positionName
      positionType
      keysCount
      roomsCount
      openJobEntryCount
    }
    groupsAggregate: sensorflow_v_group_tasks_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useGroupTasksQuery__
 *
 * To run a query within a React component, call `useGroupTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGroupTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GroupTasksQuery, GroupTasksQueryVariables>
) {
  return ApolloReactHooks.useQuery<GroupTasksQuery, GroupTasksQueryVariables>(GroupTasksDocument, baseOptions);
}
export function useGroupTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupTasksQuery, GroupTasksQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GroupTasksQuery, GroupTasksQueryVariables>(GroupTasksDocument, baseOptions);
}
export type GroupTasksQueryHookResult = ReturnType<typeof useGroupTasksQuery>;
export type GroupTasksLazyQueryHookResult = ReturnType<typeof useGroupTasksLazyQuery>;
export type GroupTasksQueryResult = ApolloReactCommon.QueryResult<GroupTasksQuery, GroupTasksQueryVariables>;
export const GetKeyIdsForGroupDocument = gql`
  query getKeyIdsForGroup($groupId: uuid!) {
    positions(where: { parentPositionId: { _eq: $groupId } }) {
      positionId
      positionName
    }
  }
`;

/**
 * __useGetKeyIdsForGroupQuery__
 *
 * To run a query within a React component, call `useGetKeyIdsForGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeyIdsForGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeyIdsForGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetKeyIdsForGroupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetKeyIdsForGroupQuery, GetKeyIdsForGroupQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetKeyIdsForGroupQuery, GetKeyIdsForGroupQueryVariables>(
    GetKeyIdsForGroupDocument,
    baseOptions
  );
}
export function useGetKeyIdsForGroupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetKeyIdsForGroupQuery, GetKeyIdsForGroupQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetKeyIdsForGroupQuery, GetKeyIdsForGroupQueryVariables>(
    GetKeyIdsForGroupDocument,
    baseOptions
  );
}
export type GetKeyIdsForGroupQueryHookResult = ReturnType<typeof useGetKeyIdsForGroupQuery>;
export type GetKeyIdsForGroupLazyQueryHookResult = ReturnType<typeof useGetKeyIdsForGroupLazyQuery>;
export type GetKeyIdsForGroupQueryResult = ApolloReactCommon.QueryResult<
  GetKeyIdsForGroupQuery,
  GetKeyIdsForGroupQueryVariables
>;
export const GetPositionDocument = gql`
  query getPosition($positionId: uuid!) {
    position(positionId: $positionId) {
      positionId
      positionName
      positionType
      positionFunction
    }
  }
`;

/**
 * __useGetPositionQuery__
 *
 * To run a query within a React component, call `useGetPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionQuery({
 *   variables: {
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useGetPositionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPositionQuery, GetPositionQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetPositionQuery, GetPositionQueryVariables>(GetPositionDocument, baseOptions);
}
export function useGetPositionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPositionQuery, GetPositionQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetPositionQuery, GetPositionQueryVariables>(GetPositionDocument, baseOptions);
}
export type GetPositionQueryHookResult = ReturnType<typeof useGetPositionQuery>;
export type GetPositionLazyQueryHookResult = ReturnType<typeof useGetPositionLazyQuery>;
export type GetPositionQueryResult = ApolloReactCommon.QueryResult<GetPositionQuery, GetPositionQueryVariables>;
export const GetWorkInfoDocument = gql`
  query getWorkInfo($where: sensorflow_v_tasks_with_keys_bool_exp!) {
    tasksWithKeys(where: $where) {
      keyId
      keyName
      roomId
      roomName
      associatedSlotId
      slotName
      nodeType
      nodeSubType
      taskId
      status
      taskType
      title
      description
      clickupTaskId
      parentEntityType
      claimerEmail
      executor
      createdAt
      keyEntryId
    }
  }
`;

/**
 * __useGetWorkInfoQuery__
 *
 * To run a query within a React component, call `useGetWorkInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkInfoQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetWorkInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkInfoQuery, GetWorkInfoQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetWorkInfoQuery, GetWorkInfoQueryVariables>(GetWorkInfoDocument, baseOptions);
}
export function useGetWorkInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkInfoQuery, GetWorkInfoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetWorkInfoQuery, GetWorkInfoQueryVariables>(GetWorkInfoDocument, baseOptions);
}
export type GetWorkInfoQueryHookResult = ReturnType<typeof useGetWorkInfoQuery>;
export type GetWorkInfoLazyQueryHookResult = ReturnType<typeof useGetWorkInfoLazyQuery>;
export type GetWorkInfoQueryResult = ApolloReactCommon.QueryResult<GetWorkInfoQuery, GetWorkInfoQueryVariables>;
export const WorkInfoDocument = gql`
  subscription workInfo($where: sensorflow_v_tasks_with_keys_bool_exp!) {
    tasksWithKeys(where: $where, order_by: { taskId: asc }) {
      keyId
      keyName
      roomId
      roomName
      associatedSlotId
      slotName
      nodeType
      nodeSubType
      taskId
      status
      taskType
      title
      description
      clickupTaskId
      parentEntityType
      claimerEmail
      executor
      createdAt
      keyEntryId
      action {
        action
        instructionLink
      }
    }
  }
`;

/**
 * __useWorkInfoSubscription__
 *
 * To run a query within a React component, call `useWorkInfoSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWorkInfoSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkInfoSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWorkInfoSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<WorkInfoSubscription, WorkInfoSubscriptionVariables>
) {
  return ApolloReactHooks.useSubscription<WorkInfoSubscription, WorkInfoSubscriptionVariables>(
    WorkInfoDocument,
    baseOptions
  );
}
export type WorkInfoSubscriptionHookResult = ReturnType<typeof useWorkInfoSubscription>;
export type WorkInfoSubscriptionResult = ApolloReactCommon.SubscriptionResult<WorkInfoSubscription>;
export const UpdateTaskDocument = gql`
  mutation updateTask($taskId: uuid!, $update: sensorflow_tasks_set_input!) {
    update_sensorflow_tasks(where: { id: { _eq: $taskId } }, _set: $update) {
      returning {
        id
        title
        status
      }
    }
  }
`;
export type UpdateTaskMutationFn = ApolloReactCommon.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, baseOptions);
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = ApolloReactCommon.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const TaskHistoryStreamDocument = gql`
  subscription taskHistoryStream($locationId: uuid!, $startTrackingTime: timestamptz!) {
    sensorflow_v_task_with_key_status_history_stream(
      where: { locationId: { _eq: $locationId }, status: { _eq: "pending" } }
      cursor: { initial_value: { createdAt: $startTrackingTime }, ordering: ASC }
      batch_size: 10
    ) {
      keyId
      keyName
      keyPositionType
      categoryName
      roomsCount
    }
  }
`;

/**
 * __useTaskHistoryStreamSubscription__
 *
 * To run a query within a React component, call `useTaskHistoryStreamSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskHistoryStreamSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskHistoryStreamSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startTrackingTime: // value for 'startTrackingTime'
 *   },
 * });
 */
export function useTaskHistoryStreamSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    TaskHistoryStreamSubscription,
    TaskHistoryStreamSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<TaskHistoryStreamSubscription, TaskHistoryStreamSubscriptionVariables>(
    TaskHistoryStreamDocument,
    baseOptions
  );
}
export type TaskHistoryStreamSubscriptionHookResult = ReturnType<typeof useTaskHistoryStreamSubscription>;
export type TaskHistoryStreamSubscriptionResult = ApolloReactCommon.SubscriptionResult<TaskHistoryStreamSubscription>;
export const UpdateCompressorInstallTaskToPendingDocument = gql`
  mutation updateCompressorInstallTaskToPending($compressorId: String!) {
    update_sensorflow_tasks(
      where: {
        parentEntityId: { _eq: $compressorId }
        parentEntityType: { _eq: "compressor" }
        type: { _eq: "installation" }
      }
      _set: { status: "pending" }
    ) {
      returning {
        id
        title
        status
      }
    }
  }
`;
export type UpdateCompressorInstallTaskToPendingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCompressorInstallTaskToPendingMutation,
  UpdateCompressorInstallTaskToPendingMutationVariables
>;

/**
 * __useUpdateCompressorInstallTaskToPendingMutation__
 *
 * To run a mutation, you first call `useUpdateCompressorInstallTaskToPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompressorInstallTaskToPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompressorInstallTaskToPendingMutation, { data, loading, error }] = useUpdateCompressorInstallTaskToPendingMutation({
 *   variables: {
 *      compressorId: // value for 'compressorId'
 *   },
 * });
 */
export function useUpdateCompressorInstallTaskToPendingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCompressorInstallTaskToPendingMutation,
    UpdateCompressorInstallTaskToPendingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCompressorInstallTaskToPendingMutation,
    UpdateCompressorInstallTaskToPendingMutationVariables
  >(UpdateCompressorInstallTaskToPendingDocument, baseOptions);
}
export type UpdateCompressorInstallTaskToPendingMutationHookResult = ReturnType<
  typeof useUpdateCompressorInstallTaskToPendingMutation
>;
export type UpdateCompressorInstallTaskToPendingMutationResult = ApolloReactCommon.MutationResult<UpdateCompressorInstallTaskToPendingMutation>;
export type UpdateCompressorInstallTaskToPendingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCompressorInstallTaskToPendingMutation,
  UpdateCompressorInstallTaskToPendingMutationVariables
>;
export const RemoveGuestFromComfortPlusActivationDocument = gql`
  mutation removeGuestFromComfortPlusActivation($userId: String!, $reservationId: bigint!) {
    deleteReservastionGuest(reservationId: $reservationId, userId: $userId) {
      userId
      reservationId
      comfortplusAccessToken
    }
  }
`;
export type RemoveGuestFromComfortPlusActivationMutationFn = ApolloReactCommon.MutationFunction<
  RemoveGuestFromComfortPlusActivationMutation,
  RemoveGuestFromComfortPlusActivationMutationVariables
>;

/**
 * __useRemoveGuestFromComfortPlusActivationMutation__
 *
 * To run a mutation, you first call `useRemoveGuestFromComfortPlusActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGuestFromComfortPlusActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGuestFromComfortPlusActivationMutation, { data, loading, error }] = useRemoveGuestFromComfortPlusActivationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useRemoveGuestFromComfortPlusActivationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveGuestFromComfortPlusActivationMutation,
    RemoveGuestFromComfortPlusActivationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveGuestFromComfortPlusActivationMutation,
    RemoveGuestFromComfortPlusActivationMutationVariables
  >(RemoveGuestFromComfortPlusActivationDocument, baseOptions);
}
export type RemoveGuestFromComfortPlusActivationMutationHookResult = ReturnType<
  typeof useRemoveGuestFromComfortPlusActivationMutation
>;
export type RemoveGuestFromComfortPlusActivationMutationResult = ApolloReactCommon.MutationResult<RemoveGuestFromComfortPlusActivationMutation>;
export type RemoveGuestFromComfortPlusActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveGuestFromComfortPlusActivationMutation,
  RemoveGuestFromComfortPlusActivationMutationVariables
>;
export const GetPrecoolSchedulesDocument = gql`
  query getPrecoolSchedules {
    sensorflow_precool_schedules {
      id
      startedAt
      endedAt
      weekDay
      time
      type
    }
  }
`;

/**
 * __useGetPrecoolSchedulesQuery__
 *
 * To run a query within a React component, call `useGetPrecoolSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrecoolSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrecoolSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrecoolSchedulesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrecoolSchedulesQuery, GetPrecoolSchedulesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetPrecoolSchedulesQuery, GetPrecoolSchedulesQueryVariables>(
    GetPrecoolSchedulesDocument,
    baseOptions
  );
}
export function useGetPrecoolSchedulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrecoolSchedulesQuery, GetPrecoolSchedulesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetPrecoolSchedulesQuery, GetPrecoolSchedulesQueryVariables>(
    GetPrecoolSchedulesDocument,
    baseOptions
  );
}
export type GetPrecoolSchedulesQueryHookResult = ReturnType<typeof useGetPrecoolSchedulesQuery>;
export type GetPrecoolSchedulesLazyQueryHookResult = ReturnType<typeof useGetPrecoolSchedulesLazyQuery>;
export type GetPrecoolSchedulesQueryResult = ApolloReactCommon.QueryResult<
  GetPrecoolSchedulesQuery,
  GetPrecoolSchedulesQueryVariables
>;
export const UpcomingPrecoolSchedulesDocument = gql`
  subscription upcomingPrecoolSchedules {
    sensorflow_precool_commands(where: { status: { _eq: "SCHEDULED" } }, limit: 10, order_by: { startedAt: asc }) {
      startedAt
    }
  }
`;

/**
 * __useUpcomingPrecoolSchedulesSubscription__
 *
 * To run a query within a React component, call `useUpcomingPrecoolSchedulesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingPrecoolSchedulesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingPrecoolSchedulesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingPrecoolSchedulesSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    UpcomingPrecoolSchedulesSubscription,
    UpcomingPrecoolSchedulesSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    UpcomingPrecoolSchedulesSubscription,
    UpcomingPrecoolSchedulesSubscriptionVariables
  >(UpcomingPrecoolSchedulesDocument, baseOptions);
}
export type UpcomingPrecoolSchedulesSubscriptionHookResult = ReturnType<typeof useUpcomingPrecoolSchedulesSubscription>;
export type UpcomingPrecoolSchedulesSubscriptionResult = ApolloReactCommon.SubscriptionResult<UpcomingPrecoolSchedulesSubscription>;
export const DomoEmbedsDocument = gql`
  query domoEmbeds {
    domoEmbeds {
      embed_id
      name
      holisticsSecretKey
      permissionDatasets
      isHolistics
    }
  }
`;

/**
 * __useDomoEmbedsQuery__
 *
 * To run a query within a React component, call `useDomoEmbedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomoEmbedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomoEmbedsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDomoEmbedsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DomoEmbedsQuery, DomoEmbedsQueryVariables>
) {
  return ApolloReactHooks.useQuery<DomoEmbedsQuery, DomoEmbedsQueryVariables>(DomoEmbedsDocument, baseOptions);
}
export function useDomoEmbedsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DomoEmbedsQuery, DomoEmbedsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<DomoEmbedsQuery, DomoEmbedsQueryVariables>(DomoEmbedsDocument, baseOptions);
}
export type DomoEmbedsQueryHookResult = ReturnType<typeof useDomoEmbedsQuery>;
export type DomoEmbedsLazyQueryHookResult = ReturnType<typeof useDomoEmbedsLazyQuery>;
export type DomoEmbedsQueryResult = ApolloReactCommon.QueryResult<DomoEmbedsQuery, DomoEmbedsQueryVariables>;
export const LocationDomoEmbedsDocument = gql`
  query locationDomoEmbeds($where: sensorflow_location_domo_embeds_bool_exp) {
    locationDomoEmbeds(where: $where) {
      domo_embed {
        embed_id
        name
        description
        holisticsSecretKey
        isHolistics
      }
    }
  }
`;

/**
 * __useLocationDomoEmbedsQuery__
 *
 * To run a query within a React component, call `useLocationDomoEmbedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDomoEmbedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDomoEmbedsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationDomoEmbedsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationDomoEmbedsQuery, LocationDomoEmbedsQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationDomoEmbedsQuery, LocationDomoEmbedsQueryVariables>(
    LocationDomoEmbedsDocument,
    baseOptions
  );
}
export function useLocationDomoEmbedsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationDomoEmbedsQuery, LocationDomoEmbedsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationDomoEmbedsQuery, LocationDomoEmbedsQueryVariables>(
    LocationDomoEmbedsDocument,
    baseOptions
  );
}
export type LocationDomoEmbedsQueryHookResult = ReturnType<typeof useLocationDomoEmbedsQuery>;
export type LocationDomoEmbedsLazyQueryHookResult = ReturnType<typeof useLocationDomoEmbedsLazyQuery>;
export type LocationDomoEmbedsQueryResult = ApolloReactCommon.QueryResult<
  LocationDomoEmbedsQuery,
  LocationDomoEmbedsQueryVariables
>;
export const AddLocationDomoEmbedDocument = gql`
  mutation addLocationDomoEmbed($object: sensorflow_location_domo_embeds_insert_input!) {
    addLocationDomoEmbed(object: $object) {
      __typename
    }
  }
`;
export type AddLocationDomoEmbedMutationFn = ApolloReactCommon.MutationFunction<
  AddLocationDomoEmbedMutation,
  AddLocationDomoEmbedMutationVariables
>;

/**
 * __useAddLocationDomoEmbedMutation__
 *
 * To run a mutation, you first call `useAddLocationDomoEmbedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocationDomoEmbedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocationDomoEmbedMutation, { data, loading, error }] = useAddLocationDomoEmbedMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddLocationDomoEmbedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddLocationDomoEmbedMutation,
    AddLocationDomoEmbedMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<AddLocationDomoEmbedMutation, AddLocationDomoEmbedMutationVariables>(
    AddLocationDomoEmbedDocument,
    baseOptions
  );
}
export type AddLocationDomoEmbedMutationHookResult = ReturnType<typeof useAddLocationDomoEmbedMutation>;
export type AddLocationDomoEmbedMutationResult = ApolloReactCommon.MutationResult<AddLocationDomoEmbedMutation>;
export type AddLocationDomoEmbedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddLocationDomoEmbedMutation,
  AddLocationDomoEmbedMutationVariables
>;
export const RemoveLocationDomoEmbedDocument = gql`
  mutation removeLocationDomoEmbed($location_id: uuid!, $embed_id: String!) {
    removeLocationDomoEmbed(location_id: $location_id, embed_id: $embed_id) {
      __typename
    }
  }
`;
export type RemoveLocationDomoEmbedMutationFn = ApolloReactCommon.MutationFunction<
  RemoveLocationDomoEmbedMutation,
  RemoveLocationDomoEmbedMutationVariables
>;

/**
 * __useRemoveLocationDomoEmbedMutation__
 *
 * To run a mutation, you first call `useRemoveLocationDomoEmbedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocationDomoEmbedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocationDomoEmbedMutation, { data, loading, error }] = useRemoveLocationDomoEmbedMutation({
 *   variables: {
 *      location_id: // value for 'location_id'
 *      embed_id: // value for 'embed_id'
 *   },
 * });
 */
export function useRemoveLocationDomoEmbedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveLocationDomoEmbedMutation,
    RemoveLocationDomoEmbedMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<RemoveLocationDomoEmbedMutation, RemoveLocationDomoEmbedMutationVariables>(
    RemoveLocationDomoEmbedDocument,
    baseOptions
  );
}
export type RemoveLocationDomoEmbedMutationHookResult = ReturnType<typeof useRemoveLocationDomoEmbedMutation>;
export type RemoveLocationDomoEmbedMutationResult = ApolloReactCommon.MutationResult<RemoveLocationDomoEmbedMutation>;
export type RemoveLocationDomoEmbedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveLocationDomoEmbedMutation,
  RemoveLocationDomoEmbedMutationVariables
>;
export const KeyWithTaskDuedateDocument = gql`
  subscription keyWithTaskDuedate($keyId: [String!]) {
    sensorflow_v_key_task_min_duedate(where: { keyId: { _in: $keyId } }) {
      keyId
      keyTaskDuedate
    }
  }
`;

/**
 * __useKeyWithTaskDuedateSubscription__
 *
 * To run a query within a React component, call `useKeyWithTaskDuedateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useKeyWithTaskDuedateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyWithTaskDuedateSubscription({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useKeyWithTaskDuedateSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    KeyWithTaskDuedateSubscription,
    KeyWithTaskDuedateSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<KeyWithTaskDuedateSubscription, KeyWithTaskDuedateSubscriptionVariables>(
    KeyWithTaskDuedateDocument,
    baseOptions
  );
}
export type KeyWithTaskDuedateSubscriptionHookResult = ReturnType<typeof useKeyWithTaskDuedateSubscription>;
export type KeyWithTaskDuedateSubscriptionResult = ApolloReactCommon.SubscriptionResult<KeyWithTaskDuedateSubscription>;
export const RoomStatusByGroupDocument = gql`
  subscription roomStatusByGroup(
    $keyIds: [uuid!]!
    $positionType: String!
    $filterSlotMappings: sensorflow_node_to_slot_mapping_bool_exp
  ) {
    positions(where: { parentPositionId: { _in: $keyIds }, positionType: { _eq: $positionType } }) {
      positionId
      positionName
      rooms {
        positionId
        positionName
        slotMappings(where: $filterSlotMappings) {
          node {
            node_mac_id
            nodeOnlineStatus {
              nodeStatus
              lastTransmission
              nodeStatusInInstallationMode
            }
            nodeJoinStrengthLive {
              signalStrength
            }
            node_type
            nodeSubType
          }
          slot {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useRoomStatusByGroupSubscription__
 *
 * To run a query within a React component, call `useRoomStatusByGroupSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomStatusByGroupSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomStatusByGroupSubscription({
 *   variables: {
 *      keyIds: // value for 'keyIds'
 *      positionType: // value for 'positionType'
 *      filterSlotMappings: // value for 'filterSlotMappings'
 *   },
 * });
 */
export function useRoomStatusByGroupSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    RoomStatusByGroupSubscription,
    RoomStatusByGroupSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<RoomStatusByGroupSubscription, RoomStatusByGroupSubscriptionVariables>(
    RoomStatusByGroupDocument,
    baseOptions
  );
}
export type RoomStatusByGroupSubscriptionHookResult = ReturnType<typeof useRoomStatusByGroupSubscription>;
export type RoomStatusByGroupSubscriptionResult = ApolloReactCommon.SubscriptionResult<RoomStatusByGroupSubscription>;
export const SimpleOnlineGatewayHealthDataByLocationDocument = gql`
  subscription simpleOnlineGatewayHealthDataByLocation($locationId: uuid!, $offlineTime: timestamptz) {
    gateways(where: { position: { locationId: { _eq: $locationId } }, deletedAt: { _is_null: true } }) {
      gatewayId
      gatewayName
      gatewayHealthData(
        where: { measurementType: { _eq: "wlan_level" }, time: { _gt: $offlineTime } }
        limit: 1
        order_by: { time: desc }
      ) {
        measurementType
        time
        value
      }
    }
  }
`;

/**
 * __useSimpleOnlineGatewayHealthDataByLocationSubscription__
 *
 * To run a query within a React component, call `useSimpleOnlineGatewayHealthDataByLocationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSimpleOnlineGatewayHealthDataByLocationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleOnlineGatewayHealthDataByLocationSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      offlineTime: // value for 'offlineTime'
 *   },
 * });
 */
export function useSimpleOnlineGatewayHealthDataByLocationSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    SimpleOnlineGatewayHealthDataByLocationSubscription,
    SimpleOnlineGatewayHealthDataByLocationSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    SimpleOnlineGatewayHealthDataByLocationSubscription,
    SimpleOnlineGatewayHealthDataByLocationSubscriptionVariables
  >(SimpleOnlineGatewayHealthDataByLocationDocument, baseOptions);
}
export type SimpleOnlineGatewayHealthDataByLocationSubscriptionHookResult = ReturnType<
  typeof useSimpleOnlineGatewayHealthDataByLocationSubscription
>;
export type SimpleOnlineGatewayHealthDataByLocationSubscriptionResult = ApolloReactCommon.SubscriptionResult<SimpleOnlineGatewayHealthDataByLocationSubscription>;
export const GetNonEmptyKeysRoomsByGroupDocument = gql`
  query getNonEmptyKeysRoomsByGroup($locationId: uuid!, $groupIds: [uuid!]!) {
    positions(
      where: {
        _and: {
          locationId: { _eq: $locationId }
          rooms_aggregate: { count: { predicate: { _gt: 0 } } }
          parentPositionId: { _in: $groupIds }
        }
      }
    ) {
      parentPositionId
      positionId
      positionName
      mappingCoordinates {
        coordinates
      }
      rooms {
        positionId
        positionName
        mappingCoordinates {
          coordinates
        }
        slotMappings(where: { decomissionedTime: { _is_null: true }, deleted_at: { _is_null: true } }) {
          slotName
          slot {
            mappingCoordinates {
              coordinates
            }
            id
          }
        }
      }
    }
    gateways(where: { _and: { positionId: { _eq: $locationId }, deletedAt: { _is_null: true } } }) {
      positionId
      gatewayId
      gatewayName
      mappingCoordinates {
        coordinates
      }
    }
  }
`;

/**
 * __useGetNonEmptyKeysRoomsByGroupQuery__
 *
 * To run a query within a React component, call `useGetNonEmptyKeysRoomsByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonEmptyKeysRoomsByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonEmptyKeysRoomsByGroupQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useGetNonEmptyKeysRoomsByGroupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetNonEmptyKeysRoomsByGroupQuery,
    GetNonEmptyKeysRoomsByGroupQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetNonEmptyKeysRoomsByGroupQuery, GetNonEmptyKeysRoomsByGroupQueryVariables>(
    GetNonEmptyKeysRoomsByGroupDocument,
    baseOptions
  );
}
export function useGetNonEmptyKeysRoomsByGroupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNonEmptyKeysRoomsByGroupQuery,
    GetNonEmptyKeysRoomsByGroupQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetNonEmptyKeysRoomsByGroupQuery, GetNonEmptyKeysRoomsByGroupQueryVariables>(
    GetNonEmptyKeysRoomsByGroupDocument,
    baseOptions
  );
}
export type GetNonEmptyKeysRoomsByGroupQueryHookResult = ReturnType<typeof useGetNonEmptyKeysRoomsByGroupQuery>;
export type GetNonEmptyKeysRoomsByGroupLazyQueryHookResult = ReturnType<typeof useGetNonEmptyKeysRoomsByGroupLazyQuery>;
export type GetNonEmptyKeysRoomsByGroupQueryResult = ApolloReactCommon.QueryResult<
  GetNonEmptyKeysRoomsByGroupQuery,
  GetNonEmptyKeysRoomsByGroupQueryVariables
>;
export const SaveMappingCoordinatesDocument = gql`
  mutation saveMappingCoordinates(
    $mappingCoordinates: [sensorflow_mapping_coordinates_insert_input!]!
    $constraint: sensorflow_mapping_coordinates_constraint!
  ) {
    insert_sensorflow_mapping_coordinates(
      objects: $mappingCoordinates
      on_conflict: { constraint: $constraint, update_columns: coordinates }
    ) {
      affected_rows
    }
  }
`;
export type SaveMappingCoordinatesMutationFn = ApolloReactCommon.MutationFunction<
  SaveMappingCoordinatesMutation,
  SaveMappingCoordinatesMutationVariables
>;

/**
 * __useSaveMappingCoordinatesMutation__
 *
 * To run a mutation, you first call `useSaveMappingCoordinatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMappingCoordinatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMappingCoordinatesMutation, { data, loading, error }] = useSaveMappingCoordinatesMutation({
 *   variables: {
 *      mappingCoordinates: // value for 'mappingCoordinates'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useSaveMappingCoordinatesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveMappingCoordinatesMutation,
    SaveMappingCoordinatesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SaveMappingCoordinatesMutation, SaveMappingCoordinatesMutationVariables>(
    SaveMappingCoordinatesDocument,
    baseOptions
  );
}
export type SaveMappingCoordinatesMutationHookResult = ReturnType<typeof useSaveMappingCoordinatesMutation>;
export type SaveMappingCoordinatesMutationResult = ApolloReactCommon.MutationResult<SaveMappingCoordinatesMutation>;
export type SaveMappingCoordinatesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveMappingCoordinatesMutation,
  SaveMappingCoordinatesMutationVariables
>;
export const DeleteMappingCoordinatesDocument = gql`
  mutation deleteMappingCoordinates($filter: [sensorflow_mapping_coordinates_bool_exp!]!) {
    delete_sensorflow_mapping_coordinates(where: { _or: $filter }) {
      affected_rows
    }
  }
`;
export type DeleteMappingCoordinatesMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMappingCoordinatesMutation,
  DeleteMappingCoordinatesMutationVariables
>;

/**
 * __useDeleteMappingCoordinatesMutation__
 *
 * To run a mutation, you first call `useDeleteMappingCoordinatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMappingCoordinatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMappingCoordinatesMutation, { data, loading, error }] = useDeleteMappingCoordinatesMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeleteMappingCoordinatesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMappingCoordinatesMutation,
    DeleteMappingCoordinatesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteMappingCoordinatesMutation, DeleteMappingCoordinatesMutationVariables>(
    DeleteMappingCoordinatesDocument,
    baseOptions
  );
}
export type DeleteMappingCoordinatesMutationHookResult = ReturnType<typeof useDeleteMappingCoordinatesMutation>;
export type DeleteMappingCoordinatesMutationResult = ApolloReactCommon.MutationResult<DeleteMappingCoordinatesMutation>;
export type DeleteMappingCoordinatesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMappingCoordinatesMutation,
  DeleteMappingCoordinatesMutationVariables
>;
export const GetGatewayAssociatedNodesDocument = gql`
  query getGatewayAssociatedNodes($gatewayId: String!) {
    gatewayToPositionMappings(where: { gatewayId: { _eq: $gatewayId } }) {
      positionId
    }
  }
`;

/**
 * __useGetGatewayAssociatedNodesQuery__
 *
 * To run a query within a React component, call `useGetGatewayAssociatedNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGatewayAssociatedNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGatewayAssociatedNodesQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useGetGatewayAssociatedNodesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetGatewayAssociatedNodesQuery,
    GetGatewayAssociatedNodesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetGatewayAssociatedNodesQuery, GetGatewayAssociatedNodesQueryVariables>(
    GetGatewayAssociatedNodesDocument,
    baseOptions
  );
}
export function useGetGatewayAssociatedNodesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGatewayAssociatedNodesQuery,
    GetGatewayAssociatedNodesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetGatewayAssociatedNodesQuery, GetGatewayAssociatedNodesQueryVariables>(
    GetGatewayAssociatedNodesDocument,
    baseOptions
  );
}
export type GetGatewayAssociatedNodesQueryHookResult = ReturnType<typeof useGetGatewayAssociatedNodesQuery>;
export type GetGatewayAssociatedNodesLazyQueryHookResult = ReturnType<typeof useGetGatewayAssociatedNodesLazyQuery>;
export type GetGatewayAssociatedNodesQueryResult = ApolloReactCommon.QueryResult<
  GetGatewayAssociatedNodesQuery,
  GetGatewayAssociatedNodesQueryVariables
>;
export const GetAllPositionsInLiveEntryModeDocument = gql`
  subscription getAllPositionsInLiveEntryMode($gatewayId: String!) {
    gatewayToPositionMappings(where: { gatewayId: { _eq: $gatewayId } }) {
      position {
        positionConfiguration(
          where: { _and: { recordType: { _eq: "CURRENT" }, specialInstalltionMode: { _eq: true } } }
        ) {
          positionId
        }
      }
    }
  }
`;

/**
 * __useGetAllPositionsInLiveEntryModeSubscription__
 *
 * To run a query within a React component, call `useGetAllPositionsInLiveEntryModeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPositionsInLiveEntryModeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPositionsInLiveEntryModeSubscription({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useGetAllPositionsInLiveEntryModeSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    GetAllPositionsInLiveEntryModeSubscription,
    GetAllPositionsInLiveEntryModeSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    GetAllPositionsInLiveEntryModeSubscription,
    GetAllPositionsInLiveEntryModeSubscriptionVariables
  >(GetAllPositionsInLiveEntryModeDocument, baseOptions);
}
export type GetAllPositionsInLiveEntryModeSubscriptionHookResult = ReturnType<
  typeof useGetAllPositionsInLiveEntryModeSubscription
>;
export type GetAllPositionsInLiveEntryModeSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetAllPositionsInLiveEntryModeSubscription>;
export const GetAmbientTemperatureAndHumidityDocument = gql`
  subscription getAmbientTemperatureAndHumidity($locationId: uuid!, $groupIds: [uuid!]!) {
    positions(
      where: {
        _and: {
          locationId: { _eq: $locationId }
          rooms_aggregate: { count: { predicate: { _gt: 0 } } }
          parentPositionId: { _in: $groupIds }
        }
      }
    ) {
      rooms(where: { deletedAt: { _is_null: true } }) {
        positionId
        positionName
        nodeMeasurements {
          ambientTemperature
          ambientHumidity
        }
      }
      positionName
      parentPositionId
    }
  }
`;

/**
 * __useGetAmbientTemperatureAndHumiditySubscription__
 *
 * To run a query within a React component, call `useGetAmbientTemperatureAndHumiditySubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAmbientTemperatureAndHumiditySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAmbientTemperatureAndHumiditySubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useGetAmbientTemperatureAndHumiditySubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    GetAmbientTemperatureAndHumiditySubscription,
    GetAmbientTemperatureAndHumiditySubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    GetAmbientTemperatureAndHumiditySubscription,
    GetAmbientTemperatureAndHumiditySubscriptionVariables
  >(GetAmbientTemperatureAndHumidityDocument, baseOptions);
}
export type GetAmbientTemperatureAndHumiditySubscriptionHookResult = ReturnType<
  typeof useGetAmbientTemperatureAndHumiditySubscription
>;
export type GetAmbientTemperatureAndHumiditySubscriptionResult = ApolloReactCommon.SubscriptionResult<GetAmbientTemperatureAndHumiditySubscription>;
export const GetAverageTemperatureOrHumidityDocument = gql`
  query getAverageTemperatureOrHumidity(
    $positionIds: _text
    $nmType: String!
    $fromDate: timestamptz!
    $toDate: timestamptz!
  ) {
    sensorflow_f_get_avg_position_data(
      args: { position_ids: $positionIds, nm_type: $nmType, from_date: $fromDate, to_date: $toDate }
    ) {
      position_id
      avg_data
      data_type
    }
  }
`;

/**
 * __useGetAverageTemperatureOrHumidityQuery__
 *
 * To run a query within a React component, call `useGetAverageTemperatureOrHumidityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAverageTemperatureOrHumidityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAverageTemperatureOrHumidityQuery({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *      nmType: // value for 'nmType'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetAverageTemperatureOrHumidityQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAverageTemperatureOrHumidityQuery,
    GetAverageTemperatureOrHumidityQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAverageTemperatureOrHumidityQuery, GetAverageTemperatureOrHumidityQueryVariables>(
    GetAverageTemperatureOrHumidityDocument,
    baseOptions
  );
}
export function useGetAverageTemperatureOrHumidityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAverageTemperatureOrHumidityQuery,
    GetAverageTemperatureOrHumidityQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAverageTemperatureOrHumidityQuery,
    GetAverageTemperatureOrHumidityQueryVariables
  >(GetAverageTemperatureOrHumidityDocument, baseOptions);
}
export type GetAverageTemperatureOrHumidityQueryHookResult = ReturnType<typeof useGetAverageTemperatureOrHumidityQuery>;
export type GetAverageTemperatureOrHumidityLazyQueryHookResult = ReturnType<
  typeof useGetAverageTemperatureOrHumidityLazyQuery
>;
export type GetAverageTemperatureOrHumidityQueryResult = ApolloReactCommon.QueryResult<
  GetAverageTemperatureOrHumidityQuery,
  GetAverageTemperatureOrHumidityQueryVariables
>;
export const GetAutosetMinimumSignalDocument = gql`
  query getAutosetMinimumSignal($gatewayId: String!) {
    sensorflow_v_gw_autoset_min_signal(where: { balena_id: { _eq: $gatewayId } }) {
      balena_id
      key
      min_signal_strength
      position_id
      position_name
    }
  }
`;

/**
 * __useGetAutosetMinimumSignalQuery__
 *
 * To run a query within a React component, call `useGetAutosetMinimumSignalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutosetMinimumSignalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutosetMinimumSignalQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useGetAutosetMinimumSignalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAutosetMinimumSignalQuery, GetAutosetMinimumSignalQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetAutosetMinimumSignalQuery, GetAutosetMinimumSignalQueryVariables>(
    GetAutosetMinimumSignalDocument,
    baseOptions
  );
}
export function useGetAutosetMinimumSignalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAutosetMinimumSignalQuery,
    GetAutosetMinimumSignalQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAutosetMinimumSignalQuery, GetAutosetMinimumSignalQueryVariables>(
    GetAutosetMinimumSignalDocument,
    baseOptions
  );
}
export type GetAutosetMinimumSignalQueryHookResult = ReturnType<typeof useGetAutosetMinimumSignalQuery>;
export type GetAutosetMinimumSignalLazyQueryHookResult = ReturnType<typeof useGetAutosetMinimumSignalLazyQuery>;
export type GetAutosetMinimumSignalQueryResult = ApolloReactCommon.QueryResult<
  GetAutosetMinimumSignalQuery,
  GetAutosetMinimumSignalQueryVariables
>;
export const GetLiveAutosetMinimumSignalDocument = gql`
  subscription getLiveAutosetMinimumSignal($gatewayId: String!) {
    sensorflow_v_gw_autoset_min_signal(where: { balena_id: { _eq: $gatewayId } }) {
      balena_id
      key
      min_signal_strength
      position_id
      position_name
    }
  }
`;

/**
 * __useGetLiveAutosetMinimumSignalSubscription__
 *
 * To run a query within a React component, call `useGetLiveAutosetMinimumSignalSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveAutosetMinimumSignalSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveAutosetMinimumSignalSubscription({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useGetLiveAutosetMinimumSignalSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    GetLiveAutosetMinimumSignalSubscription,
    GetLiveAutosetMinimumSignalSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    GetLiveAutosetMinimumSignalSubscription,
    GetLiveAutosetMinimumSignalSubscriptionVariables
  >(GetLiveAutosetMinimumSignalDocument, baseOptions);
}
export type GetLiveAutosetMinimumSignalSubscriptionHookResult = ReturnType<
  typeof useGetLiveAutosetMinimumSignalSubscription
>;
export type GetLiveAutosetMinimumSignalSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetLiveAutosetMinimumSignalSubscription>;
export const UpdateDomoEmbedDocument = gql`
  mutation updateDomoEmbed($object: sensorflow_domo_embeds_set_input!, $where: sensorflow_domo_embeds_bool_exp!) {
    update_sensorflow_domo_embeds(_set: $object, where: $where) {
      affected_rows
      __typename
    }
  }
`;
export type UpdateDomoEmbedMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDomoEmbedMutation,
  UpdateDomoEmbedMutationVariables
>;

/**
 * __useUpdateDomoEmbedMutation__
 *
 * To run a mutation, you first call `useUpdateDomoEmbedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomoEmbedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomoEmbedMutation, { data, loading, error }] = useUpdateDomoEmbedMutation({
 *   variables: {
 *      object: // value for 'object'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateDomoEmbedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDomoEmbedMutation, UpdateDomoEmbedMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateDomoEmbedMutation, UpdateDomoEmbedMutationVariables>(
    UpdateDomoEmbedDocument,
    baseOptions
  );
}
export type UpdateDomoEmbedMutationHookResult = ReturnType<typeof useUpdateDomoEmbedMutation>;
export type UpdateDomoEmbedMutationResult = ApolloReactCommon.MutationResult<UpdateDomoEmbedMutation>;
export type UpdateDomoEmbedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDomoEmbedMutation,
  UpdateDomoEmbedMutationVariables
>;
export const DeleteDomoEmbedDocument = gql`
  mutation deleteDomoEmbed($where: sensorflow_domo_embeds_bool_exp!) {
    delete_sensorflow_domo_embeds(where: $where) {
      affected_rows
      __typename
    }
  }
`;
export type DeleteDomoEmbedMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDomoEmbedMutation,
  DeleteDomoEmbedMutationVariables
>;

/**
 * __useDeleteDomoEmbedMutation__
 *
 * To run a mutation, you first call `useDeleteDomoEmbedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomoEmbedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomoEmbedMutation, { data, loading, error }] = useDeleteDomoEmbedMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteDomoEmbedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDomoEmbedMutation, DeleteDomoEmbedMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteDomoEmbedMutation, DeleteDomoEmbedMutationVariables>(
    DeleteDomoEmbedDocument,
    baseOptions
  );
}
export type DeleteDomoEmbedMutationHookResult = ReturnType<typeof useDeleteDomoEmbedMutation>;
export type DeleteDomoEmbedMutationResult = ApolloReactCommon.MutationResult<DeleteDomoEmbedMutation>;
export type DeleteDomoEmbedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDomoEmbedMutation,
  DeleteDomoEmbedMutationVariables
>;
export const AddDomoEmbedDocument = gql`
  mutation addDomoEmbed($objects: [sensorflow_domo_embeds_insert_input!]!) {
    insert_sensorflow_domo_embeds(objects: $objects) {
      __typename
    }
  }
`;
export type AddDomoEmbedMutationFn = ApolloReactCommon.MutationFunction<
  AddDomoEmbedMutation,
  AddDomoEmbedMutationVariables
>;

/**
 * __useAddDomoEmbedMutation__
 *
 * To run a mutation, you first call `useAddDomoEmbedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDomoEmbedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDomoEmbedMutation, { data, loading, error }] = useAddDomoEmbedMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddDomoEmbedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddDomoEmbedMutation, AddDomoEmbedMutationVariables>
) {
  return ApolloReactHooks.useMutation<AddDomoEmbedMutation, AddDomoEmbedMutationVariables>(
    AddDomoEmbedDocument,
    baseOptions
  );
}
export type AddDomoEmbedMutationHookResult = ReturnType<typeof useAddDomoEmbedMutation>;
export type AddDomoEmbedMutationResult = ApolloReactCommon.MutationResult<AddDomoEmbedMutation>;
export type AddDomoEmbedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddDomoEmbedMutation,
  AddDomoEmbedMutationVariables
>;
export const QueryBeaconSlotsByLocationIdDocument = gql`
  query queryBeaconSlotsByLocationId($locationId: uuid!) {
    sensorflow_beacon_slots(where: { locationId: { _eq: $locationId } }) {
      id
      enableSlotGap
      beaconFreqIndex
      locationId
      maxSlots
      startingSlot
      __typename
    }
  }
`;

/**
 * __useQueryBeaconSlotsByLocationIdQuery__
 *
 * To run a query within a React component, call `useQueryBeaconSlotsByLocationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBeaconSlotsByLocationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBeaconSlotsByLocationIdQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useQueryBeaconSlotsByLocationIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    QueryBeaconSlotsByLocationIdQuery,
    QueryBeaconSlotsByLocationIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<QueryBeaconSlotsByLocationIdQuery, QueryBeaconSlotsByLocationIdQueryVariables>(
    QueryBeaconSlotsByLocationIdDocument,
    baseOptions
  );
}
export function useQueryBeaconSlotsByLocationIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    QueryBeaconSlotsByLocationIdQuery,
    QueryBeaconSlotsByLocationIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<QueryBeaconSlotsByLocationIdQuery, QueryBeaconSlotsByLocationIdQueryVariables>(
    QueryBeaconSlotsByLocationIdDocument,
    baseOptions
  );
}
export type QueryBeaconSlotsByLocationIdQueryHookResult = ReturnType<typeof useQueryBeaconSlotsByLocationIdQuery>;
export type QueryBeaconSlotsByLocationIdLazyQueryHookResult = ReturnType<
  typeof useQueryBeaconSlotsByLocationIdLazyQuery
>;
export type QueryBeaconSlotsByLocationIdQueryResult = ApolloReactCommon.QueryResult<
  QueryBeaconSlotsByLocationIdQuery,
  QueryBeaconSlotsByLocationIdQueryVariables
>;
export const UpdateBeaconSlotsDocument = gql`
  mutation updateBeaconSlots($locationId: uuid!, $startingSlot: Int!, $maxSlots: Int!) {
    update_sensorflow_beacon_slots(
      where: { locationId: { _eq: $locationId } }
      _set: { maxSlots: $maxSlots, startingSlot: $startingSlot }
    ) {
      affected_rows
      __typename
    }
  }
`;
export type UpdateBeaconSlotsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBeaconSlotsMutation,
  UpdateBeaconSlotsMutationVariables
>;

/**
 * __useUpdateBeaconSlotsMutation__
 *
 * To run a mutation, you first call `useUpdateBeaconSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBeaconSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBeaconSlotsMutation, { data, loading, error }] = useUpdateBeaconSlotsMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startingSlot: // value for 'startingSlot'
 *      maxSlots: // value for 'maxSlots'
 *   },
 * });
 */
export function useUpdateBeaconSlotsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBeaconSlotsMutation, UpdateBeaconSlotsMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateBeaconSlotsMutation, UpdateBeaconSlotsMutationVariables>(
    UpdateBeaconSlotsDocument,
    baseOptions
  );
}
export type UpdateBeaconSlotsMutationHookResult = ReturnType<typeof useUpdateBeaconSlotsMutation>;
export type UpdateBeaconSlotsMutationResult = ApolloReactCommon.MutationResult<UpdateBeaconSlotsMutation>;
export type UpdateBeaconSlotsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBeaconSlotsMutation,
  UpdateBeaconSlotsMutationVariables
>;
export const SaveLevelsGroupDocument = gql`
  mutation saveLevelsGroup(
    $mapLevelsGroup: [sensorflow_smplrspace_levels_group_insert_input!]!
    $constraint: sensorflow_smplrspace_levels_group_constraint!
  ) {
    insert_sensorflow_smplrspace_levels_group(objects: $mapLevelsGroup, on_conflict: { constraint: $constraint }) {
      affected_rows
    }
  }
`;
export type SaveLevelsGroupMutationFn = ApolloReactCommon.MutationFunction<
  SaveLevelsGroupMutation,
  SaveLevelsGroupMutationVariables
>;

/**
 * __useSaveLevelsGroupMutation__
 *
 * To run a mutation, you first call `useSaveLevelsGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLevelsGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLevelsGroupMutation, { data, loading, error }] = useSaveLevelsGroupMutation({
 *   variables: {
 *      mapLevelsGroup: // value for 'mapLevelsGroup'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useSaveLevelsGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SaveLevelsGroupMutation, SaveLevelsGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<SaveLevelsGroupMutation, SaveLevelsGroupMutationVariables>(
    SaveLevelsGroupDocument,
    baseOptions
  );
}
export type SaveLevelsGroupMutationHookResult = ReturnType<typeof useSaveLevelsGroupMutation>;
export type SaveLevelsGroupMutationResult = ApolloReactCommon.MutationResult<SaveLevelsGroupMutation>;
export type SaveLevelsGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveLevelsGroupMutation,
  SaveLevelsGroupMutationVariables
>;
export const DeleteLevelsGroupDocument = gql`
  mutation deleteLevelsGroup($filter: [sensorflow_smplrspace_levels_group_bool_exp!]!) {
    delete_sensorflow_smplrspace_levels_group(where: { _or: $filter }) {
      affected_rows
    }
  }
`;
export type DeleteLevelsGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteLevelsGroupMutation,
  DeleteLevelsGroupMutationVariables
>;

/**
 * __useDeleteLevelsGroupMutation__
 *
 * To run a mutation, you first call `useDeleteLevelsGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLevelsGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLevelsGroupMutation, { data, loading, error }] = useDeleteLevelsGroupMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeleteLevelsGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLevelsGroupMutation, DeleteLevelsGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteLevelsGroupMutation, DeleteLevelsGroupMutationVariables>(
    DeleteLevelsGroupDocument,
    baseOptions
  );
}
export type DeleteLevelsGroupMutationHookResult = ReturnType<typeof useDeleteLevelsGroupMutation>;
export type DeleteLevelsGroupMutationResult = ApolloReactCommon.MutationResult<DeleteLevelsGroupMutation>;
export type DeleteLevelsGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteLevelsGroupMutation,
  DeleteLevelsGroupMutationVariables
>;
export const UpdateLevelsGroupDocument = gql`
  mutation updateLevelsGroup($groupName: String!, $levelIndex: [Int!]!, $groupId: uuid!) {
    update_sensorflow_smplrspace_levels_group(
      _set: { groupName: $groupName, levelIndex: $levelIndex }
      where: { groupId: { _eq: $groupId } }
    ) {
      affected_rows
    }
  }
`;
export type UpdateLevelsGroupMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLevelsGroupMutation,
  UpdateLevelsGroupMutationVariables
>;

/**
 * __useUpdateLevelsGroupMutation__
 *
 * To run a mutation, you first call `useUpdateLevelsGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLevelsGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLevelsGroupMutation, { data, loading, error }] = useUpdateLevelsGroupMutation({
 *   variables: {
 *      groupName: // value for 'groupName'
 *      levelIndex: // value for 'levelIndex'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useUpdateLevelsGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLevelsGroupMutation, UpdateLevelsGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateLevelsGroupMutation, UpdateLevelsGroupMutationVariables>(
    UpdateLevelsGroupDocument,
    baseOptions
  );
}
export type UpdateLevelsGroupMutationHookResult = ReturnType<typeof useUpdateLevelsGroupMutation>;
export type UpdateLevelsGroupMutationResult = ApolloReactCommon.MutationResult<UpdateLevelsGroupMutation>;
export type UpdateLevelsGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLevelsGroupMutation,
  UpdateLevelsGroupMutationVariables
>;
export const GetLevelsGroupDocument = gql`
  query getLevelsGroup($spaceId: uuid!) {
    sensorflow_smplrspace_levels_group(where: { spaceId: { _eq: $spaceId } }) {
      groupId
      groupName
      levelIndex
      spaceId
    }
  }
`;

/**
 * __useGetLevelsGroupQuery__
 *
 * To run a query within a React component, call `useGetLevelsGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLevelsGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLevelsGroupQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetLevelsGroupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetLevelsGroupQuery, GetLevelsGroupQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetLevelsGroupQuery, GetLevelsGroupQueryVariables>(
    GetLevelsGroupDocument,
    baseOptions
  );
}
export function useGetLevelsGroupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLevelsGroupQuery, GetLevelsGroupQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetLevelsGroupQuery, GetLevelsGroupQueryVariables>(
    GetLevelsGroupDocument,
    baseOptions
  );
}
export type GetLevelsGroupQueryHookResult = ReturnType<typeof useGetLevelsGroupQuery>;
export type GetLevelsGroupLazyQueryHookResult = ReturnType<typeof useGetLevelsGroupLazyQuery>;
export type GetLevelsGroupQueryResult = ApolloReactCommon.QueryResult<
  GetLevelsGroupQuery,
  GetLevelsGroupQueryVariables
>;
export const SaveFloorsToLevelsDocument = gql`
  mutation saveFloorsToLevels(
    $mapFloorsToLevels: [sensorflow_smplrspace_floors_to_levels_insert_input!]!
    $constraint: sensorflow_smplrspace_floors_to_levels_constraint!
  ) {
    insert_sensorflow_smplrspace_floors_to_levels(
      objects: $mapFloorsToLevels
      on_conflict: { constraint: $constraint }
    ) {
      affected_rows
    }
  }
`;
export type SaveFloorsToLevelsMutationFn = ApolloReactCommon.MutationFunction<
  SaveFloorsToLevelsMutation,
  SaveFloorsToLevelsMutationVariables
>;

/**
 * __useSaveFloorsToLevelsMutation__
 *
 * To run a mutation, you first call `useSaveFloorsToLevelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFloorsToLevelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFloorsToLevelsMutation, { data, loading, error }] = useSaveFloorsToLevelsMutation({
 *   variables: {
 *      mapFloorsToLevels: // value for 'mapFloorsToLevels'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useSaveFloorsToLevelsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SaveFloorsToLevelsMutation, SaveFloorsToLevelsMutationVariables>
) {
  return ApolloReactHooks.useMutation<SaveFloorsToLevelsMutation, SaveFloorsToLevelsMutationVariables>(
    SaveFloorsToLevelsDocument,
    baseOptions
  );
}
export type SaveFloorsToLevelsMutationHookResult = ReturnType<typeof useSaveFloorsToLevelsMutation>;
export type SaveFloorsToLevelsMutationResult = ApolloReactCommon.MutationResult<SaveFloorsToLevelsMutation>;
export type SaveFloorsToLevelsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveFloorsToLevelsMutation,
  SaveFloorsToLevelsMutationVariables
>;
export const DeleteFloorsToLevelsDocument = gql`
  mutation deleteFloorsToLevels($filter: [sensorflow_smplrspace_floors_to_levels_bool_exp!]!) {
    delete_sensorflow_smplrspace_floors_to_levels(where: { _or: $filter }) {
      affected_rows
    }
  }
`;
export type DeleteFloorsToLevelsMutationFn = ApolloReactCommon.MutationFunction<
  DeleteFloorsToLevelsMutation,
  DeleteFloorsToLevelsMutationVariables
>;

/**
 * __useDeleteFloorsToLevelsMutation__
 *
 * To run a mutation, you first call `useDeleteFloorsToLevelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFloorsToLevelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFloorsToLevelsMutation, { data, loading, error }] = useDeleteFloorsToLevelsMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeleteFloorsToLevelsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteFloorsToLevelsMutation,
    DeleteFloorsToLevelsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteFloorsToLevelsMutation, DeleteFloorsToLevelsMutationVariables>(
    DeleteFloorsToLevelsDocument,
    baseOptions
  );
}
export type DeleteFloorsToLevelsMutationHookResult = ReturnType<typeof useDeleteFloorsToLevelsMutation>;
export type DeleteFloorsToLevelsMutationResult = ApolloReactCommon.MutationResult<DeleteFloorsToLevelsMutation>;
export type DeleteFloorsToLevelsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteFloorsToLevelsMutation,
  DeleteFloorsToLevelsMutationVariables
>;
export const GetFloorsToLevelsDocument = gql`
  query getFloorsToLevels($positionIds: [uuid!]!) {
    sensorflow_smplrspace_floors_to_levels(where: { position_id: { _in: $positionIds } }) {
      group_id
      position_id
      levelsGroup {
        levelIndex
        groupName
      }
    }
  }
`;

/**
 * __useGetFloorsToLevelsQuery__
 *
 * To run a query within a React component, call `useGetFloorsToLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFloorsToLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFloorsToLevelsQuery({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *   },
 * });
 */
export function useGetFloorsToLevelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetFloorsToLevelsQuery, GetFloorsToLevelsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetFloorsToLevelsQuery, GetFloorsToLevelsQueryVariables>(
    GetFloorsToLevelsDocument,
    baseOptions
  );
}
export function useGetFloorsToLevelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFloorsToLevelsQuery, GetFloorsToLevelsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetFloorsToLevelsQuery, GetFloorsToLevelsQueryVariables>(
    GetFloorsToLevelsDocument,
    baseOptions
  );
}
export type GetFloorsToLevelsQueryHookResult = ReturnType<typeof useGetFloorsToLevelsQuery>;
export type GetFloorsToLevelsLazyQueryHookResult = ReturnType<typeof useGetFloorsToLevelsLazyQuery>;
export type GetFloorsToLevelsQueryResult = ApolloReactCommon.QueryResult<
  GetFloorsToLevelsQuery,
  GetFloorsToLevelsQueryVariables
>;
export const UpdateFloorsToLevelsDocument = gql`
  mutation updateFloorsToLevels($groupId: uuid!, $positionId: uuid!) {
    update_sensorflow_smplrspace_floors_to_levels(
      _set: { group_id: $groupId }
      where: { position_id: { _eq: $positionId } }
    ) {
      affected_rows
    }
  }
`;
export type UpdateFloorsToLevelsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFloorsToLevelsMutation,
  UpdateFloorsToLevelsMutationVariables
>;

/**
 * __useUpdateFloorsToLevelsMutation__
 *
 * To run a mutation, you first call `useUpdateFloorsToLevelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFloorsToLevelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFloorsToLevelsMutation, { data, loading, error }] = useUpdateFloorsToLevelsMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useUpdateFloorsToLevelsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateFloorsToLevelsMutation,
    UpdateFloorsToLevelsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateFloorsToLevelsMutation, UpdateFloorsToLevelsMutationVariables>(
    UpdateFloorsToLevelsDocument,
    baseOptions
  );
}
export type UpdateFloorsToLevelsMutationHookResult = ReturnType<typeof useUpdateFloorsToLevelsMutation>;
export type UpdateFloorsToLevelsMutationResult = ApolloReactCommon.MutationResult<UpdateFloorsToLevelsMutation>;
export type UpdateFloorsToLevelsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateFloorsToLevelsMutation,
  UpdateFloorsToLevelsMutationVariables
>;
export const GetDefaultHomepageDocument = gql`
  query getDefaultHomepage($locationId: uuid!, $userId: String!) {
    sensorflow_location_to_user_mapping(where: { location_id: { _eq: $locationId }, user_id: { _eq: $userId } }) {
      default_homepage
    }
  }
`;

/**
 * __useGetDefaultHomepageQuery__
 *
 * To run a query within a React component, call `useGetDefaultHomepageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultHomepageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultHomepageQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetDefaultHomepageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefaultHomepageQuery, GetDefaultHomepageQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetDefaultHomepageQuery, GetDefaultHomepageQueryVariables>(
    GetDefaultHomepageDocument,
    baseOptions
  );
}
export function useGetDefaultHomepageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefaultHomepageQuery, GetDefaultHomepageQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetDefaultHomepageQuery, GetDefaultHomepageQueryVariables>(
    GetDefaultHomepageDocument,
    baseOptions
  );
}
export type GetDefaultHomepageQueryHookResult = ReturnType<typeof useGetDefaultHomepageQuery>;
export type GetDefaultHomepageLazyQueryHookResult = ReturnType<typeof useGetDefaultHomepageLazyQuery>;
export type GetDefaultHomepageQueryResult = ApolloReactCommon.QueryResult<
  GetDefaultHomepageQuery,
  GetDefaultHomepageQueryVariables
>;
export const UpdateDefaultHomepageDocument = gql`
  mutation updateDefaultHomepage($locationId: uuid!, $userId: String!, $defaultHomepage: String!) {
    update_sensorflow_location_to_user_mapping(
      where: { location_id: { _eq: $locationId }, user_id: { _eq: $userId } }
      _set: { default_homepage: $defaultHomepage }
    ) {
      affected_rows
    }
  }
`;
export type UpdateDefaultHomepageMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDefaultHomepageMutation,
  UpdateDefaultHomepageMutationVariables
>;

/**
 * __useUpdateDefaultHomepageMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultHomepageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultHomepageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultHomepageMutation, { data, loading, error }] = useUpdateDefaultHomepageMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      userId: // value for 'userId'
 *      defaultHomepage: // value for 'defaultHomepage'
 *   },
 * });
 */
export function useUpdateDefaultHomepageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDefaultHomepageMutation,
    UpdateDefaultHomepageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateDefaultHomepageMutation, UpdateDefaultHomepageMutationVariables>(
    UpdateDefaultHomepageDocument,
    baseOptions
  );
}
export type UpdateDefaultHomepageMutationHookResult = ReturnType<typeof useUpdateDefaultHomepageMutation>;
export type UpdateDefaultHomepageMutationResult = ApolloReactCommon.MutationResult<UpdateDefaultHomepageMutation>;
export type UpdateDefaultHomepageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDefaultHomepageMutation,
  UpdateDefaultHomepageMutationVariables
>;
export const CreateLocationAddressDocument = gql`
  mutation createLocationAddress($locationAddress: sensorflow_location_addresses_insert_input!) {
    insert_sensorflow_location_addresses_one(object: $locationAddress) {
      location_id
      address1
      address2
      city
      zipCode
      country
    }
  }
`;
export type CreateLocationAddressMutationFn = ApolloReactCommon.MutationFunction<
  CreateLocationAddressMutation,
  CreateLocationAddressMutationVariables
>;

/**
 * __useCreateLocationAddressMutation__
 *
 * To run a mutation, you first call `useCreateLocationAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationAddressMutation, { data, loading, error }] = useCreateLocationAddressMutation({
 *   variables: {
 *      locationAddress: // value for 'locationAddress'
 *   },
 * });
 */
export function useCreateLocationAddressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLocationAddressMutation,
    CreateLocationAddressMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateLocationAddressMutation, CreateLocationAddressMutationVariables>(
    CreateLocationAddressDocument,
    baseOptions
  );
}
export type CreateLocationAddressMutationHookResult = ReturnType<typeof useCreateLocationAddressMutation>;
export type CreateLocationAddressMutationResult = ApolloReactCommon.MutationResult<CreateLocationAddressMutation>;
export type CreateLocationAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateLocationAddressMutation,
  CreateLocationAddressMutationVariables
>;
export const CreatePropertyInformationDocument = gql`
  mutation createPropertyInformation($propertyInfo: sensorflow_properties_information_insert_input!) {
    insert_sensorflow_properties_information_one(object: $propertyInfo) {
      locationId
      totalSquareFootage
      hospitalityType
      totalGuestRooms
      noOfStudios
      noOf1Bedrooms
      noOf2Bedrooms
      noOf3Bedrooms
      roomsFloorPercentage
      commonAreasFloorPercentage
      restaurantsFloorPercentage
      spasFloorPercentage
      backOfficesFloorPercentage
      otherAreasFloorPercentage
      hvacSystemType
      energyInitiatives
      createdAt
      updated_at
      electricityBillingPeriodEnd
      electricityBillingPeriodStart
      gasBillingPeriodEnd
      gasBillingPeriodStart
      hasEnergyInitiatives
      hasOtherHVACSolutions
      hvacConsumptionPercentage
      hvacSolutions
      wasteBillingPeriodEnd
      wasteBillingPeriodStart
      waterBillingPeriodEnd
      waterBillingPeriodStart
    }
  }
`;
export type CreatePropertyInformationMutationFn = ApolloReactCommon.MutationFunction<
  CreatePropertyInformationMutation,
  CreatePropertyInformationMutationVariables
>;

/**
 * __useCreatePropertyInformationMutation__
 *
 * To run a mutation, you first call `useCreatePropertyInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyInformationMutation, { data, loading, error }] = useCreatePropertyInformationMutation({
 *   variables: {
 *      propertyInfo: // value for 'propertyInfo'
 *   },
 * });
 */
export function useCreatePropertyInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePropertyInformationMutation,
    CreatePropertyInformationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreatePropertyInformationMutation, CreatePropertyInformationMutationVariables>(
    CreatePropertyInformationDocument,
    baseOptions
  );
}
export type CreatePropertyInformationMutationHookResult = ReturnType<typeof useCreatePropertyInformationMutation>;
export type CreatePropertyInformationMutationResult = ApolloReactCommon.MutationResult<CreatePropertyInformationMutation>;
export type CreatePropertyInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePropertyInformationMutation,
  CreatePropertyInformationMutationVariables
>;
export const CreateConsumptionRecordsDocument = gql`
  mutation createConsumptionRecords($records: [sensorflow_location_consumptions_insert_input!]!) {
    insert_sensorflow_location_consumptions(objects: $records) {
      affected_rows
      returning {
        locationId
        consumptionPeriodStart
        consumptionPeriodEnd
        actualValue
        clientForecast
        sensorflowForecast
        metricTypeId
        sourceId
        sourceType
        updatedAt
      }
    }
  }
`;
export type CreateConsumptionRecordsMutationFn = ApolloReactCommon.MutationFunction<
  CreateConsumptionRecordsMutation,
  CreateConsumptionRecordsMutationVariables
>;

/**
 * __useCreateConsumptionRecordsMutation__
 *
 * To run a mutation, you first call `useCreateConsumptionRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsumptionRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsumptionRecordsMutation, { data, loading, error }] = useCreateConsumptionRecordsMutation({
 *   variables: {
 *      records: // value for 'records'
 *   },
 * });
 */
export function useCreateConsumptionRecordsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateConsumptionRecordsMutation,
    CreateConsumptionRecordsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateConsumptionRecordsMutation, CreateConsumptionRecordsMutationVariables>(
    CreateConsumptionRecordsDocument,
    baseOptions
  );
}
export type CreateConsumptionRecordsMutationHookResult = ReturnType<typeof useCreateConsumptionRecordsMutation>;
export type CreateConsumptionRecordsMutationResult = ApolloReactCommon.MutationResult<CreateConsumptionRecordsMutation>;
export type CreateConsumptionRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateConsumptionRecordsMutation,
  CreateConsumptionRecordsMutationVariables
>;
export const UpdateUserProfileDocument = gql`
  mutation updateUserProfile($userId: String!, $userInput: sensorflow_users_set_input!) {
    update_sensorflow_users_by_pk(pk_columns: { userId: $userId }, _set: $userInput) {
      userId
      firstName
      lastName
      companyRole
      phoneNumber
      email
      name
      __typename
    }
  }
`;
export type UpdateUserProfileMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(
    UpdateUserProfileDocument,
    baseOptions
  );
}
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const CreateLocationOccCustomerDataDocument = gql`
  mutation createLocationOccCustomerData($customerData: sensorflow_location_occ_customer_data_insert_input!) {
    insert_sensorflow_location_occ_customer_data_one(object: $customerData) {
      locationId
      clientForecast
      occupancy
      periodStart
      periodEnd
      __typename
    }
  }
`;
export type CreateLocationOccCustomerDataMutationFn = ApolloReactCommon.MutationFunction<
  CreateLocationOccCustomerDataMutation,
  CreateLocationOccCustomerDataMutationVariables
>;

/**
 * __useCreateLocationOccCustomerDataMutation__
 *
 * To run a mutation, you first call `useCreateLocationOccCustomerDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationOccCustomerDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationOccCustomerDataMutation, { data, loading, error }] = useCreateLocationOccCustomerDataMutation({
 *   variables: {
 *      customerData: // value for 'customerData'
 *   },
 * });
 */
export function useCreateLocationOccCustomerDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLocationOccCustomerDataMutation,
    CreateLocationOccCustomerDataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateLocationOccCustomerDataMutation,
    CreateLocationOccCustomerDataMutationVariables
  >(CreateLocationOccCustomerDataDocument, baseOptions);
}
export type CreateLocationOccCustomerDataMutationHookResult = ReturnType<
  typeof useCreateLocationOccCustomerDataMutation
>;
export type CreateLocationOccCustomerDataMutationResult = ApolloReactCommon.MutationResult<CreateLocationOccCustomerDataMutation>;
export type CreateLocationOccCustomerDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateLocationOccCustomerDataMutation,
  CreateLocationOccCustomerDataMutationVariables
>;
export const CreateBulkLocationOccCustomerDataDocument = gql`
  mutation createBulkLocationOccCustomerData($records: [sensorflow_location_occ_customer_data_insert_input!]!) {
    insert_sensorflow_location_occ_customer_data(objects: $records) {
      affected_rows
      returning {
        locationId
        clientForecast
        occupancy
        periodStart
        periodEnd
        __typename
      }
    }
  }
`;
export type CreateBulkLocationOccCustomerDataMutationFn = ApolloReactCommon.MutationFunction<
  CreateBulkLocationOccCustomerDataMutation,
  CreateBulkLocationOccCustomerDataMutationVariables
>;

/**
 * __useCreateBulkLocationOccCustomerDataMutation__
 *
 * To run a mutation, you first call `useCreateBulkLocationOccCustomerDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkLocationOccCustomerDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkLocationOccCustomerDataMutation, { data, loading, error }] = useCreateBulkLocationOccCustomerDataMutation({
 *   variables: {
 *      records: // value for 'records'
 *   },
 * });
 */
export function useCreateBulkLocationOccCustomerDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBulkLocationOccCustomerDataMutation,
    CreateBulkLocationOccCustomerDataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateBulkLocationOccCustomerDataMutation,
    CreateBulkLocationOccCustomerDataMutationVariables
  >(CreateBulkLocationOccCustomerDataDocument, baseOptions);
}
export type CreateBulkLocationOccCustomerDataMutationHookResult = ReturnType<
  typeof useCreateBulkLocationOccCustomerDataMutation
>;
export type CreateBulkLocationOccCustomerDataMutationResult = ApolloReactCommon.MutationResult<CreateBulkLocationOccCustomerDataMutation>;
export type CreateBulkLocationOccCustomerDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateBulkLocationOccCustomerDataMutation,
  CreateBulkLocationOccCustomerDataMutationVariables
>;
export const UpdateLocationOccCustomerDataDocument = gql`
  mutation updateLocationOccCustomerData(
    $locationId: uuid!
    $periodStart: date!
    $periodEnd: date!
    $clientForecast: numeric
    $occupancy: numeric
  ) {
    update_sensorflow_location_occ_customer_data_by_pk(
      pk_columns: { locationId: $locationId, periodStart: $periodStart, periodEnd: $periodEnd }
      _set: { clientForecast: $clientForecast, occupancy: $occupancy }
    ) {
      locationId
      clientForecast
      occupancy
      periodStart
      periodEnd
      __typename
    }
  }
`;
export type UpdateLocationOccCustomerDataMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLocationOccCustomerDataMutation,
  UpdateLocationOccCustomerDataMutationVariables
>;

/**
 * __useUpdateLocationOccCustomerDataMutation__
 *
 * To run a mutation, you first call `useUpdateLocationOccCustomerDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationOccCustomerDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationOccCustomerDataMutation, { data, loading, error }] = useUpdateLocationOccCustomerDataMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *      clientForecast: // value for 'clientForecast'
 *      occupancy: // value for 'occupancy'
 *   },
 * });
 */
export function useUpdateLocationOccCustomerDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLocationOccCustomerDataMutation,
    UpdateLocationOccCustomerDataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateLocationOccCustomerDataMutation,
    UpdateLocationOccCustomerDataMutationVariables
  >(UpdateLocationOccCustomerDataDocument, baseOptions);
}
export type UpdateLocationOccCustomerDataMutationHookResult = ReturnType<
  typeof useUpdateLocationOccCustomerDataMutation
>;
export type UpdateLocationOccCustomerDataMutationResult = ApolloReactCommon.MutationResult<UpdateLocationOccCustomerDataMutation>;
export type UpdateLocationOccCustomerDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLocationOccCustomerDataMutation,
  UpdateLocationOccCustomerDataMutationVariables
>;
export const CreateSurveyResponseDocument = gql`
  mutation createSurveyResponse($surveyResponse: sensorflow_survey_responses_insert_input!) {
    insert_sensorflow_survey_responses_one(object: $surveyResponse) {
      responseId
      locationId
      userId
      submissionDate
      surveyResponse
      consumptionFileURL
      __typename
    }
  }
`;
export type CreateSurveyResponseMutationFn = ApolloReactCommon.MutationFunction<
  CreateSurveyResponseMutation,
  CreateSurveyResponseMutationVariables
>;

/**
 * __useCreateSurveyResponseMutation__
 *
 * To run a mutation, you first call `useCreateSurveyResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyResponseMutation, { data, loading, error }] = useCreateSurveyResponseMutation({
 *   variables: {
 *      surveyResponse: // value for 'surveyResponse'
 *   },
 * });
 */
export function useCreateSurveyResponseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSurveyResponseMutation,
    CreateSurveyResponseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateSurveyResponseMutation, CreateSurveyResponseMutationVariables>(
    CreateSurveyResponseDocument,
    baseOptions
  );
}
export type CreateSurveyResponseMutationHookResult = ReturnType<typeof useCreateSurveyResponseMutation>;
export type CreateSurveyResponseMutationResult = ApolloReactCommon.MutationResult<CreateSurveyResponseMutation>;
export type CreateSurveyResponseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSurveyResponseMutation,
  CreateSurveyResponseMutationVariables
>;
export const GetSurveyResponsesDocument = gql`
  query getSurveyResponses($locationId: uuid!, $limit: Int, $offset: Int) {
    sensorflow_survey_responses(
      where: { locationId: { _eq: $locationId } }
      limit: $limit
      offset: $offset
      order_by: { submissionDate: desc }
    ) {
      responseId
      locationId
      userId
      submissionDate
      surveyResponse
      consumptionFileURL
      user {
        userId
        firstName
        lastName
        email
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetSurveyResponsesQuery__
 *
 * To run a query within a React component, call `useGetSurveyResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyResponsesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSurveyResponsesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSurveyResponsesQuery, GetSurveyResponsesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetSurveyResponsesQuery, GetSurveyResponsesQueryVariables>(
    GetSurveyResponsesDocument,
    baseOptions
  );
}
export function useGetSurveyResponsesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSurveyResponsesQuery, GetSurveyResponsesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSurveyResponsesQuery, GetSurveyResponsesQueryVariables>(
    GetSurveyResponsesDocument,
    baseOptions
  );
}
export type GetSurveyResponsesQueryHookResult = ReturnType<typeof useGetSurveyResponsesQuery>;
export type GetSurveyResponsesLazyQueryHookResult = ReturnType<typeof useGetSurveyResponsesLazyQuery>;
export type GetSurveyResponsesQueryResult = ApolloReactCommon.QueryResult<
  GetSurveyResponsesQuery,
  GetSurveyResponsesQueryVariables
>;
export const GetLocationConsumptionsDocument = gql`
  query getLocationConsumptions(
    $locationId: uuid!
    $startDate: date
    $endDate: date
    $metricTypeIds: [Int!]
    $limit: Int
    $offset: Int
  ) {
    sensorflow_location_consumptions(
      where: {
        locationId: { _eq: $locationId }
        consumptionPeriodStart: { _gte: $startDate }
        consumptionPeriodEnd: { _lte: $endDate }
        metricTypeId: { _in: $metricTypeIds }
      }
      limit: $limit
      offset: $offset
      order_by: { consumptionPeriodStart: desc }
    ) {
      locationId
      consumptionPeriodStart
      consumptionPeriodEnd
      actualValue
      clientForecast
      sensorflowForecast
      metricTypeId
      sourceId
      sourceType
      updatedAt
      __typename
    }
  }
`;

/**
 * __useGetLocationConsumptionsQuery__
 *
 * To run a query within a React component, call `useGetLocationConsumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationConsumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationConsumptionsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      metricTypeIds: // value for 'metricTypeIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetLocationConsumptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationConsumptionsQuery, GetLocationConsumptionsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetLocationConsumptionsQuery, GetLocationConsumptionsQueryVariables>(
    GetLocationConsumptionsDocument,
    baseOptions
  );
}
export function useGetLocationConsumptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLocationConsumptionsQuery,
    GetLocationConsumptionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetLocationConsumptionsQuery, GetLocationConsumptionsQueryVariables>(
    GetLocationConsumptionsDocument,
    baseOptions
  );
}
export type GetLocationConsumptionsQueryHookResult = ReturnType<typeof useGetLocationConsumptionsQuery>;
export type GetLocationConsumptionsLazyQueryHookResult = ReturnType<typeof useGetLocationConsumptionsLazyQuery>;
export type GetLocationConsumptionsQueryResult = ApolloReactCommon.QueryResult<
  GetLocationConsumptionsQuery,
  GetLocationConsumptionsQueryVariables
>;
export const SensorflowLocationConsumptionsDocument = gql`
  query sensorflowLocationConsumptions($locationId: uuid!, $startDate: date!, $endDate: date!) {
    sensorflow_location_consumptions(
      where: {
        locationId: { _eq: $locationId }
        consumptionPeriodStart: { _gte: $startDate }
        consumptionPeriodEnd: { _lte: $endDate }
      }
      order_by: { consumptionPeriodStart: desc }
    ) {
      locationId
      metricTypeId
      consumptionPeriodStart
      consumptionPeriodEnd
      actualValue
      clientForecast
      sensorflowForecast
      sourceType
      sourceId
      updatedAt
    }
  }
`;

/**
 * __useSensorflowLocationConsumptionsQuery__
 *
 * To run a query within a React component, call `useSensorflowLocationConsumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorflowLocationConsumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorflowLocationConsumptionsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useSensorflowLocationConsumptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SensorflowLocationConsumptionsQuery,
    SensorflowLocationConsumptionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SensorflowLocationConsumptionsQuery, SensorflowLocationConsumptionsQueryVariables>(
    SensorflowLocationConsumptionsDocument,
    baseOptions
  );
}
export function useSensorflowLocationConsumptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SensorflowLocationConsumptionsQuery,
    SensorflowLocationConsumptionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SensorflowLocationConsumptionsQuery,
    SensorflowLocationConsumptionsQueryVariables
  >(SensorflowLocationConsumptionsDocument, baseOptions);
}
export type SensorflowLocationConsumptionsQueryHookResult = ReturnType<typeof useSensorflowLocationConsumptionsQuery>;
export type SensorflowLocationConsumptionsLazyQueryHookResult = ReturnType<
  typeof useSensorflowLocationConsumptionsLazyQuery
>;
export type SensorflowLocationConsumptionsQueryResult = ApolloReactCommon.QueryResult<
  SensorflowLocationConsumptionsQuery,
  SensorflowLocationConsumptionsQueryVariables
>;
export const SensorflowMetricTypesDocument = gql`
  query sensorflowMetricTypes {
    sensorflow_metric_types {
      metricTypeId
      metricName
    }
  }
`;

/**
 * __useSensorflowMetricTypesQuery__
 *
 * To run a query within a React component, call `useSensorflowMetricTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorflowMetricTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorflowMetricTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSensorflowMetricTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SensorflowMetricTypesQuery, SensorflowMetricTypesQueryVariables>
) {
  return ApolloReactHooks.useQuery<SensorflowMetricTypesQuery, SensorflowMetricTypesQueryVariables>(
    SensorflowMetricTypesDocument,
    baseOptions
  );
}
export function useSensorflowMetricTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SensorflowMetricTypesQuery, SensorflowMetricTypesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SensorflowMetricTypesQuery, SensorflowMetricTypesQueryVariables>(
    SensorflowMetricTypesDocument,
    baseOptions
  );
}
export type SensorflowMetricTypesQueryHookResult = ReturnType<typeof useSensorflowMetricTypesQuery>;
export type SensorflowMetricTypesLazyQueryHookResult = ReturnType<typeof useSensorflowMetricTypesLazyQuery>;
export type SensorflowMetricTypesQueryResult = ApolloReactCommon.QueryResult<
  SensorflowMetricTypesQuery,
  SensorflowMetricTypesQueryVariables
>;
export const UpdateLocationConsumptionDocument = gql`
  mutation updateLocationConsumption(
    $locationId: uuid!
    $metricTypeId: Int!
    $consumptionPeriodStart: date!
    $consumptionPeriodEnd: date!
    $actualValue: numeric
    $clientForecast: numeric
  ) {
    update_sensorflow_location_consumptions_by_pk(
      pk_columns: {
        locationId: $locationId
        metricTypeId: $metricTypeId
        consumptionPeriodStart: $consumptionPeriodStart
        consumptionPeriodEnd: $consumptionPeriodEnd
      }
      _set: { actualValue: $actualValue, clientForecast: $clientForecast, updatedAt: "now()" }
    ) {
      locationId
      metricTypeId
      consumptionPeriodStart
      consumptionPeriodEnd
      actualValue
      clientForecast
      updatedAt
    }
  }
`;
export type UpdateLocationConsumptionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLocationConsumptionMutation,
  UpdateLocationConsumptionMutationVariables
>;

/**
 * __useUpdateLocationConsumptionMutation__
 *
 * To run a mutation, you first call `useUpdateLocationConsumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationConsumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationConsumptionMutation, { data, loading, error }] = useUpdateLocationConsumptionMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      metricTypeId: // value for 'metricTypeId'
 *      consumptionPeriodStart: // value for 'consumptionPeriodStart'
 *      consumptionPeriodEnd: // value for 'consumptionPeriodEnd'
 *      actualValue: // value for 'actualValue'
 *      clientForecast: // value for 'clientForecast'
 *   },
 * });
 */
export function useUpdateLocationConsumptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLocationConsumptionMutation,
    UpdateLocationConsumptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateLocationConsumptionMutation, UpdateLocationConsumptionMutationVariables>(
    UpdateLocationConsumptionDocument,
    baseOptions
  );
}
export type UpdateLocationConsumptionMutationHookResult = ReturnType<typeof useUpdateLocationConsumptionMutation>;
export type UpdateLocationConsumptionMutationResult = ApolloReactCommon.MutationResult<UpdateLocationConsumptionMutation>;
export type UpdateLocationConsumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLocationConsumptionMutation,
  UpdateLocationConsumptionMutationVariables
>;
export const UpdateLocationConsumptionWithSourceDocument = gql`
  mutation updateLocationConsumptionWithSource(
    $locationId: uuid!
    $metricTypeId: Int!
    $consumptionPeriodStart: date!
    $consumptionPeriodEnd: date!
    $actualValue: numeric
    $clientForecast: numeric
    $sourceType: String!
    $sourceId: String!
  ) {
    update_sensorflow_location_consumptions_by_pk(
      pk_columns: {
        locationId: $locationId
        metricTypeId: $metricTypeId
        consumptionPeriodStart: $consumptionPeriodStart
        consumptionPeriodEnd: $consumptionPeriodEnd
      }
      _set: {
        actualValue: $actualValue
        clientForecast: $clientForecast
        sourceType: $sourceType
        sourceId: $sourceId
        updatedAt: "now()"
      }
    ) {
      locationId
      metricTypeId
      consumptionPeriodStart
      consumptionPeriodEnd
      actualValue
      clientForecast
      sourceType
      sourceId
      updatedAt
    }
  }
`;
export type UpdateLocationConsumptionWithSourceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLocationConsumptionWithSourceMutation,
  UpdateLocationConsumptionWithSourceMutationVariables
>;

/**
 * __useUpdateLocationConsumptionWithSourceMutation__
 *
 * To run a mutation, you first call `useUpdateLocationConsumptionWithSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationConsumptionWithSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationConsumptionWithSourceMutation, { data, loading, error }] = useUpdateLocationConsumptionWithSourceMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      metricTypeId: // value for 'metricTypeId'
 *      consumptionPeriodStart: // value for 'consumptionPeriodStart'
 *      consumptionPeriodEnd: // value for 'consumptionPeriodEnd'
 *      actualValue: // value for 'actualValue'
 *      clientForecast: // value for 'clientForecast'
 *      sourceType: // value for 'sourceType'
 *      sourceId: // value for 'sourceId'
 *   },
 * });
 */
export function useUpdateLocationConsumptionWithSourceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLocationConsumptionWithSourceMutation,
    UpdateLocationConsumptionWithSourceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateLocationConsumptionWithSourceMutation,
    UpdateLocationConsumptionWithSourceMutationVariables
  >(UpdateLocationConsumptionWithSourceDocument, baseOptions);
}
export type UpdateLocationConsumptionWithSourceMutationHookResult = ReturnType<
  typeof useUpdateLocationConsumptionWithSourceMutation
>;
export type UpdateLocationConsumptionWithSourceMutationResult = ApolloReactCommon.MutationResult<UpdateLocationConsumptionWithSourceMutation>;
export type UpdateLocationConsumptionWithSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLocationConsumptionWithSourceMutation,
  UpdateLocationConsumptionWithSourceMutationVariables
>;
export const GetPropertiesInformationDocument = gql`
  query getPropertiesInformation($locationId: uuid!) {
    sensorflow_properties_information(where: { locationId: { _eq: $locationId } }) {
      locationId
      totalSquareFootage
      hospitalityType
      totalGuestRooms
      noOfStudios
      noOf1Bedrooms
      noOf2Bedrooms
      noOf3Bedrooms
      roomsFloorPercentage
      commonAreasFloorPercentage
      restaurantsFloorPercentage
      spasFloorPercentage
      backOfficesFloorPercentage
      otherAreasFloorPercentage
      hvacSystemType
      energyInitiatives
      createdAt
      updated_at
      hasEnergyInitiatives
      hasOtherHVACSolutions
      hvacConsumptionPercentage
      hvacSolutions
      electricityBillingPeriodStart
      electricityBillingPeriodEnd
      gasBillingPeriodStart
      gasBillingPeriodEnd
      waterBillingPeriodStart
      waterBillingPeriodEnd
      wasteBillingPeriodStart
      wasteBillingPeriodEnd
      __typename
    }
  }
`;

/**
 * __useGetPropertiesInformationQuery__
 *
 * To run a query within a React component, call `useGetPropertiesInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesInformationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetPropertiesInformationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPropertiesInformationQuery, GetPropertiesInformationQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetPropertiesInformationQuery, GetPropertiesInformationQueryVariables>(
    GetPropertiesInformationDocument,
    baseOptions
  );
}
export function useGetPropertiesInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPropertiesInformationQuery,
    GetPropertiesInformationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetPropertiesInformationQuery, GetPropertiesInformationQueryVariables>(
    GetPropertiesInformationDocument,
    baseOptions
  );
}
export type GetPropertiesInformationQueryHookResult = ReturnType<typeof useGetPropertiesInformationQuery>;
export type GetPropertiesInformationLazyQueryHookResult = ReturnType<typeof useGetPropertiesInformationLazyQuery>;
export type GetPropertiesInformationQueryResult = ApolloReactCommon.QueryResult<
  GetPropertiesInformationQuery,
  GetPropertiesInformationQueryVariables
>;
export const GetLocationOccCustomerDataDocument = gql`
  query getLocationOccCustomerData($locationId: uuid!, $periodStart: date!, $periodEnd: date!) {
    sensorflow_location_occ_customer_data(
      where: { locationId: { _eq: $locationId }, periodStart: { _gte: $periodStart }, periodEnd: { _lte: $periodEnd } }
    ) {
      locationId
      clientForecast
      occupancy
      periodStart
      periodEnd
      __typename
    }
  }
`;

/**
 * __useGetLocationOccCustomerDataQuery__
 *
 * To run a query within a React component, call `useGetLocationOccCustomerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationOccCustomerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationOccCustomerDataQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *   },
 * });
 */
export function useGetLocationOccCustomerDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLocationOccCustomerDataQuery,
    GetLocationOccCustomerDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetLocationOccCustomerDataQuery, GetLocationOccCustomerDataQueryVariables>(
    GetLocationOccCustomerDataDocument,
    baseOptions
  );
}
export function useGetLocationOccCustomerDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLocationOccCustomerDataQuery,
    GetLocationOccCustomerDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetLocationOccCustomerDataQuery, GetLocationOccCustomerDataQueryVariables>(
    GetLocationOccCustomerDataDocument,
    baseOptions
  );
}
export type GetLocationOccCustomerDataQueryHookResult = ReturnType<typeof useGetLocationOccCustomerDataQuery>;
export type GetLocationOccCustomerDataLazyQueryHookResult = ReturnType<typeof useGetLocationOccCustomerDataLazyQuery>;
export type GetLocationOccCustomerDataQueryResult = ApolloReactCommon.QueryResult<
  GetLocationOccCustomerDataQuery,
  GetLocationOccCustomerDataQueryVariables
>;
export const DeleteLocationConsumptionByPkDocument = gql`
  mutation deleteLocationConsumptionByPk(
    $locationId: uuid!
    $metricTypeId: Int!
    $consumptionPeriodStart: date!
    $consumptionPeriodEnd: date!
  ) {
    delete_sensorflow_location_consumptions_by_pk(
      locationId: $locationId
      metricTypeId: $metricTypeId
      consumptionPeriodStart: $consumptionPeriodStart
      consumptionPeriodEnd: $consumptionPeriodEnd
    ) {
      locationId
      metricTypeId
      consumptionPeriodStart
      consumptionPeriodEnd
    }
  }
`;
export type DeleteLocationConsumptionByPkMutationFn = ApolloReactCommon.MutationFunction<
  DeleteLocationConsumptionByPkMutation,
  DeleteLocationConsumptionByPkMutationVariables
>;

/**
 * __useDeleteLocationConsumptionByPkMutation__
 *
 * To run a mutation, you first call `useDeleteLocationConsumptionByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationConsumptionByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationConsumptionByPkMutation, { data, loading, error }] = useDeleteLocationConsumptionByPkMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      metricTypeId: // value for 'metricTypeId'
 *      consumptionPeriodStart: // value for 'consumptionPeriodStart'
 *      consumptionPeriodEnd: // value for 'consumptionPeriodEnd'
 *   },
 * });
 */
export function useDeleteLocationConsumptionByPkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLocationConsumptionByPkMutation,
    DeleteLocationConsumptionByPkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteLocationConsumptionByPkMutation,
    DeleteLocationConsumptionByPkMutationVariables
  >(DeleteLocationConsumptionByPkDocument, baseOptions);
}
export type DeleteLocationConsumptionByPkMutationHookResult = ReturnType<
  typeof useDeleteLocationConsumptionByPkMutation
>;
export type DeleteLocationConsumptionByPkMutationResult = ApolloReactCommon.MutationResult<DeleteLocationConsumptionByPkMutation>;
export type DeleteLocationConsumptionByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteLocationConsumptionByPkMutation,
  DeleteLocationConsumptionByPkMutationVariables
>;
export const UpdatePropertyBillingPeriodsDocument = gql`
  mutation updatePropertyBillingPeriods(
    $locationId: uuid!
    $electricityBillingPeriodStart: Int
    $electricityBillingPeriodEnd: Int
    $waterBillingPeriodStart: Int
    $waterBillingPeriodEnd: Int
    $gasBillingPeriodStart: Int
    $gasBillingPeriodEnd: Int
    $wasteBillingPeriodStart: Int
    $wasteBillingPeriodEnd: Int
  ) {
    update_sensorflow_properties_information(
      where: { locationId: { _eq: $locationId } }
      _set: {
        electricityBillingPeriodStart: $electricityBillingPeriodStart
        electricityBillingPeriodEnd: $electricityBillingPeriodEnd
        waterBillingPeriodStart: $waterBillingPeriodStart
        waterBillingPeriodEnd: $waterBillingPeriodEnd
        gasBillingPeriodStart: $gasBillingPeriodStart
        gasBillingPeriodEnd: $gasBillingPeriodEnd
        wasteBillingPeriodStart: $wasteBillingPeriodStart
        wasteBillingPeriodEnd: $wasteBillingPeriodEnd
      }
    ) {
      affected_rows
      returning {
        locationId
        electricityBillingPeriodStart
        electricityBillingPeriodEnd
        waterBillingPeriodStart
        waterBillingPeriodEnd
        gasBillingPeriodStart
        gasBillingPeriodEnd
        wasteBillingPeriodStart
        wasteBillingPeriodEnd
      }
    }
  }
`;
export type UpdatePropertyBillingPeriodsMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePropertyBillingPeriodsMutation,
  UpdatePropertyBillingPeriodsMutationVariables
>;

/**
 * __useUpdatePropertyBillingPeriodsMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyBillingPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyBillingPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyBillingPeriodsMutation, { data, loading, error }] = useUpdatePropertyBillingPeriodsMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      electricityBillingPeriodStart: // value for 'electricityBillingPeriodStart'
 *      electricityBillingPeriodEnd: // value for 'electricityBillingPeriodEnd'
 *      waterBillingPeriodStart: // value for 'waterBillingPeriodStart'
 *      waterBillingPeriodEnd: // value for 'waterBillingPeriodEnd'
 *      gasBillingPeriodStart: // value for 'gasBillingPeriodStart'
 *      gasBillingPeriodEnd: // value for 'gasBillingPeriodEnd'
 *      wasteBillingPeriodStart: // value for 'wasteBillingPeriodStart'
 *      wasteBillingPeriodEnd: // value for 'wasteBillingPeriodEnd'
 *   },
 * });
 */
export function useUpdatePropertyBillingPeriodsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePropertyBillingPeriodsMutation,
    UpdatePropertyBillingPeriodsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePropertyBillingPeriodsMutation,
    UpdatePropertyBillingPeriodsMutationVariables
  >(UpdatePropertyBillingPeriodsDocument, baseOptions);
}
export type UpdatePropertyBillingPeriodsMutationHookResult = ReturnType<typeof useUpdatePropertyBillingPeriodsMutation>;
export type UpdatePropertyBillingPeriodsMutationResult = ApolloReactCommon.MutationResult<UpdatePropertyBillingPeriodsMutation>;
export type UpdatePropertyBillingPeriodsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePropertyBillingPeriodsMutation,
  UpdatePropertyBillingPeriodsMutationVariables
>;
export const JobEntryDataDocument = gql`
  subscription jobEntryData($where: sensorflow_v_key_position_latest_entry_bool_exp) {
    jobEntries: sensorflow_v_key_position_latest_entry(where: $where) {
      keyPositionId
      positionFunction
      status
      user {
        userId
        name
      }
      comment
      commentStatus
      startedAt
      endedAt
    }
  }
`;

/**
 * __useJobEntryDataSubscription__
 *
 * To run a query within a React component, call `useJobEntryDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useJobEntryDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobEntryDataSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useJobEntryDataSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<JobEntryDataSubscription, JobEntryDataSubscriptionVariables>
) {
  return ApolloReactHooks.useSubscription<JobEntryDataSubscription, JobEntryDataSubscriptionVariables>(
    JobEntryDataDocument,
    baseOptions
  );
}
export type JobEntryDataSubscriptionHookResult = ReturnType<typeof useJobEntryDataSubscription>;
export type JobEntryDataSubscriptionResult = ApolloReactCommon.SubscriptionResult<JobEntryDataSubscription>;
export const GetPmsReservationsDocument = gql`
  query getPmsReservations(
    $where: sf_pms_pms_reservations_bool_exp
    $orderBy: [sf_pms_pms_reservations_order_by!]
    $limit: Int! = 10
    $offset: Int! = 0
  ) {
    reservations: sf_pms_pms_reservations(where: $where, order_by: $orderBy, offset: $offset, limit: $limit) {
      id: reservation_id
      checkInAt: check_in_date
      checkOutAt: check_out_date
      lastUpdatedAt: modified_date
      category: pms_room_category {
        name: room_category_name
        id: room_category_id
      }
      recommendedRoom: pms_reservation_recommended_room_mapping {
        name: room_name
        id: room_id
      }
      allocatedRoom: pms_room_to_reservation_mapping {
        name: room_name
        id: room_id
      }
      status
      guestId: guest_id
    }
    aggregation: sf_pms_pms_reservations_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useGetPmsReservationsQuery__
 *
 * To run a query within a React component, call `useGetPmsReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPmsReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPmsReservationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPmsReservationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPmsReservationsQuery, GetPmsReservationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetPmsReservationsQuery, GetPmsReservationsQueryVariables>(
    GetPmsReservationsDocument,
    baseOptions
  );
}
export function useGetPmsReservationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPmsReservationsQuery, GetPmsReservationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetPmsReservationsQuery, GetPmsReservationsQueryVariables>(
    GetPmsReservationsDocument,
    baseOptions
  );
}
export type GetPmsReservationsQueryHookResult = ReturnType<typeof useGetPmsReservationsQuery>;
export type GetPmsReservationsLazyQueryHookResult = ReturnType<typeof useGetPmsReservationsLazyQuery>;
export type GetPmsReservationsQueryResult = ApolloReactCommon.QueryResult<
  GetPmsReservationsQuery,
  GetPmsReservationsQueryVariables
>;
export const GetPmsPropertyByLocationDocument = gql`
  query getPmsPropertyByLocation($locationId: uuid!) {
    property: sf_pms_pms_properties(
      where: { location_id: { _eq: $locationId }, is_ready: { _eq: true } }
      offset: 0
      limit: 1
    ) {
      pmsId: pms_id
      propertyId: property_id
      locationId: location_id
      isReady: is_ready
    }
  }
`;

/**
 * __useGetPmsPropertyByLocationQuery__
 *
 * To run a query within a React component, call `useGetPmsPropertyByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPmsPropertyByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPmsPropertyByLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetPmsPropertyByLocationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPmsPropertyByLocationQuery, GetPmsPropertyByLocationQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetPmsPropertyByLocationQuery, GetPmsPropertyByLocationQueryVariables>(
    GetPmsPropertyByLocationDocument,
    baseOptions
  );
}
export function useGetPmsPropertyByLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPmsPropertyByLocationQuery,
    GetPmsPropertyByLocationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetPmsPropertyByLocationQuery, GetPmsPropertyByLocationQueryVariables>(
    GetPmsPropertyByLocationDocument,
    baseOptions
  );
}
export type GetPmsPropertyByLocationQueryHookResult = ReturnType<typeof useGetPmsPropertyByLocationQuery>;
export type GetPmsPropertyByLocationLazyQueryHookResult = ReturnType<typeof useGetPmsPropertyByLocationLazyQuery>;
export type GetPmsPropertyByLocationQueryResult = ApolloReactCommon.QueryResult<
  GetPmsPropertyByLocationQuery,
  GetPmsPropertyByLocationQueryVariables
>;
export const GetPmsRoomCategoriesDocument = gql`
  query getPmsRoomCategories(
    $where: sf_pms_pms_room_categories_bool_exp
    $orderBy: [sf_pms_pms_room_categories_order_by!]
    $limit: Int! = 10000
    $offset: Int! = 0
  ) {
    categories: sf_pms_pms_room_categories(where: $where, order_by: $orderBy, offset: $offset, limit: $limit) {
      id: room_category_id
      name: room_category_name
    }
  }
`;

/**
 * __useGetPmsRoomCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPmsRoomCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPmsRoomCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPmsRoomCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPmsRoomCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPmsRoomCategoriesQuery, GetPmsRoomCategoriesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetPmsRoomCategoriesQuery, GetPmsRoomCategoriesQueryVariables>(
    GetPmsRoomCategoriesDocument,
    baseOptions
  );
}
export function useGetPmsRoomCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPmsRoomCategoriesQuery, GetPmsRoomCategoriesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetPmsRoomCategoriesQuery, GetPmsRoomCategoriesQueryVariables>(
    GetPmsRoomCategoriesDocument,
    baseOptions
  );
}
export type GetPmsRoomCategoriesQueryHookResult = ReturnType<typeof useGetPmsRoomCategoriesQuery>;
export type GetPmsRoomCategoriesLazyQueryHookResult = ReturnType<typeof useGetPmsRoomCategoriesLazyQuery>;
export type GetPmsRoomCategoriesQueryResult = ApolloReactCommon.QueryResult<
  GetPmsRoomCategoriesQuery,
  GetPmsRoomCategoriesQueryVariables
>;
export const GetSmartAllocReportDocument = gql`
  query getSmartAllocReport($where: sf_pms_smartalloc_report_bool_exp) {
    sf_pms_smartalloc_report(where: $where) {
      averageDailyRunningCompressors
      averageOccupancy
      billingCurrency
      followedRecommendationRatio
      locationId
      ninetyAdherenceSavingsKwh
      ninetyAdherenceSavingsSgd
      ninetyAdherenceSavingsLocalCurrency
      numberOfFollowedRecommendation
      numberOfRecommendation
      numberOfReservations
      savingsKwh
      savingsLocalCurrency
      savingsPercentage
      savingsSgd
      simulatedNoSmartallocConsumptionKwh
      simulatedNoSmartallocDailyCompressors
      totalConsumptionKwh
      date
      type
    }
  }
`;

/**
 * __useGetSmartAllocReportQuery__
 *
 * To run a query within a React component, call `useGetSmartAllocReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmartAllocReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmartAllocReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSmartAllocReportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSmartAllocReportQuery, GetSmartAllocReportQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetSmartAllocReportQuery, GetSmartAllocReportQueryVariables>(
    GetSmartAllocReportDocument,
    baseOptions
  );
}
export function useGetSmartAllocReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSmartAllocReportQuery, GetSmartAllocReportQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSmartAllocReportQuery, GetSmartAllocReportQueryVariables>(
    GetSmartAllocReportDocument,
    baseOptions
  );
}
export type GetSmartAllocReportQueryHookResult = ReturnType<typeof useGetSmartAllocReportQuery>;
export type GetSmartAllocReportLazyQueryHookResult = ReturnType<typeof useGetSmartAllocReportLazyQuery>;
export type GetSmartAllocReportQueryResult = ApolloReactCommon.QueryResult<
  GetSmartAllocReportQuery,
  GetSmartAllocReportQueryVariables
>;
export const PmsPropertyEodSurveysDocument = gql`
  subscription pmsPropertyEodSurveys($pmsId: Int!, $propertyId: String!, $offset: Int!, $limit: Int!) {
    surveys: sf_pms_pms_property_eod_surveys(
      where: { pms_id: { _eq: $pmsId }, property_id: { _eq: $propertyId } }
      offset: $offset
      limit: $limit
    ) {
      createdAt: created_at
      data
      pmsId: pms_id
      propertyId: property_id
      status
      surveyMonkeyId: survey_monkey_id
    }
  }
`;

/**
 * __usePmsPropertyEodSurveysSubscription__
 *
 * To run a query within a React component, call `usePmsPropertyEodSurveysSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePmsPropertyEodSurveysSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePmsPropertyEodSurveysSubscription({
 *   variables: {
 *      pmsId: // value for 'pmsId'
 *      propertyId: // value for 'propertyId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePmsPropertyEodSurveysSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    PmsPropertyEodSurveysSubscription,
    PmsPropertyEodSurveysSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    PmsPropertyEodSurveysSubscription,
    PmsPropertyEodSurveysSubscriptionVariables
  >(PmsPropertyEodSurveysDocument, baseOptions);
}
export type PmsPropertyEodSurveysSubscriptionHookResult = ReturnType<typeof usePmsPropertyEodSurveysSubscription>;
export type PmsPropertyEodSurveysSubscriptionResult = ApolloReactCommon.SubscriptionResult<PmsPropertyEodSurveysSubscription>;
export const UserWithAuthTokenDocument = gql`
  query userWithAuthToken {
    userWithAuthToken {
      userId
      name
      email
      status
      emailVerified
      lastLogin
      role {
        id
        name
        defaultRoute
        permissions {
          id
          permissionKey
          displayName
        }
      }
      isContractor
      isManager
      isDev
    }
  }
`;

/**
 * __useUserWithAuthTokenQuery__
 *
 * To run a query within a React component, call `useUserWithAuthTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithAuthTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithAuthTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithAuthTokenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserWithAuthTokenQuery, UserWithAuthTokenQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserWithAuthTokenQuery, UserWithAuthTokenQueryVariables>(
    UserWithAuthTokenDocument,
    baseOptions
  );
}
export function useUserWithAuthTokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWithAuthTokenQuery, UserWithAuthTokenQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserWithAuthTokenQuery, UserWithAuthTokenQueryVariables>(
    UserWithAuthTokenDocument,
    baseOptions
  );
}
export type UserWithAuthTokenQueryHookResult = ReturnType<typeof useUserWithAuthTokenQuery>;
export type UserWithAuthTokenLazyQueryHookResult = ReturnType<typeof useUserWithAuthTokenLazyQuery>;
export type UserWithAuthTokenQueryResult = ApolloReactCommon.QueryResult<
  UserWithAuthTokenQuery,
  UserWithAuthTokenQueryVariables
>;
export const LocationsAssignedToUserDocument = gql`
  query locationsAssignedToUser(
    $filter: LocationFilterInput
    $sort: LocationSortInput
    $pagination: GenericPaginationInput
  ) {
    locationsAssignedToUser(filter: $filter, sort: $sort, pagination: $pagination) {
      locations {
        id
        locationName
        locationMode
        timezone
        organisation {
          id
          name
        }
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useLocationsAssignedToUserQuery__
 *
 * To run a query within a React component, call `useLocationsAssignedToUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsAssignedToUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsAssignedToUserQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLocationsAssignedToUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationsAssignedToUserQuery, LocationsAssignedToUserQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationsAssignedToUserQuery, LocationsAssignedToUserQueryVariables>(
    LocationsAssignedToUserDocument,
    baseOptions
  );
}
export function useLocationsAssignedToUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationsAssignedToUserQuery,
    LocationsAssignedToUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<LocationsAssignedToUserQuery, LocationsAssignedToUserQueryVariables>(
    LocationsAssignedToUserDocument,
    baseOptions
  );
}
export type LocationsAssignedToUserQueryHookResult = ReturnType<typeof useLocationsAssignedToUserQuery>;
export type LocationsAssignedToUserLazyQueryHookResult = ReturnType<typeof useLocationsAssignedToUserLazyQuery>;
export type LocationsAssignedToUserQueryResult = ApolloReactCommon.QueryResult<
  LocationsAssignedToUserQuery,
  LocationsAssignedToUserQueryVariables
>;
export const LocationsDocument = gql`
  query locations($filter: LocationFilterInput, $sort: LocationSortInput, $pagination: GenericPaginationInput) {
    locationsAssignedToUser(filter: $filter, sort: $sort, pagination: $pagination) {
      locations {
        id
        locationName
        currency
        locationStats {
          mappingStatus
          keysStats {
            totalCount
            mappedCount
          }
          gatewaysStats {
            totalCount
            mappedCount
          }
        }
      }
      filterOptions {
        field
        value
        label
        count
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLocationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationsQuery, LocationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, baseOptions);
}
export function useLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, baseOptions);
}
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = ApolloReactCommon.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const LocationsForCustomerDocument = gql`
  query locationsForCustomer(
    $filter: LocationFilterInput
    $sort: LocationSortInput
    $pagination: GenericPaginationInput
  ) {
    locationsAssignedToUser(filter: $filter, sort: $sort, pagination: $pagination) {
      locations {
        id
        locationName
        locationMetadata {
          subscription
          showEnergyConsumption
        }
      }
      filterOptions {
        field
        value
        label
        count
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useLocationsForCustomerQuery__
 *
 * To run a query within a React component, call `useLocationsForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsForCustomerQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLocationsForCustomerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationsForCustomerQuery, LocationsForCustomerQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationsForCustomerQuery, LocationsForCustomerQueryVariables>(
    LocationsForCustomerDocument,
    baseOptions
  );
}
export function useLocationsForCustomerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationsForCustomerQuery, LocationsForCustomerQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationsForCustomerQuery, LocationsForCustomerQueryVariables>(
    LocationsForCustomerDocument,
    baseOptions
  );
}
export type LocationsForCustomerQueryHookResult = ReturnType<typeof useLocationsForCustomerQuery>;
export type LocationsForCustomerLazyQueryHookResult = ReturnType<typeof useLocationsForCustomerLazyQuery>;
export type LocationsForCustomerQueryResult = ApolloReactCommon.QueryResult<
  LocationsForCustomerQuery,
  LocationsForCustomerQueryVariables
>;
export const LocationsWithNodeHealthDocument = gql`
  query locationsWithNodeHealth(
    $filter: LocationFilterInput
    $sort: LocationSortInput
    $pagination: GenericPaginationInput
  ) {
    locationsAssignedToUser(filter: $filter, sort: $sort, pagination: $pagination) {
      locations {
        id
        locationName
        currency
        locationStats {
          mappingStatus
          keysStats {
            totalCount
            mappedCount
          }
          gatewaysStats {
            totalCount
            mappedCount
          }
          nodeHealthStats {
            totalCount
            onlineCount
            bootedCount
            offlineCount
          }
        }
      }
      filterOptions {
        field
        value
        label
        count
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useLocationsWithNodeHealthQuery__
 *
 * To run a query within a React component, call `useLocationsWithNodeHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsWithNodeHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsWithNodeHealthQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLocationsWithNodeHealthQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationsWithNodeHealthQuery, LocationsWithNodeHealthQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationsWithNodeHealthQuery, LocationsWithNodeHealthQueryVariables>(
    LocationsWithNodeHealthDocument,
    baseOptions
  );
}
export function useLocationsWithNodeHealthLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationsWithNodeHealthQuery,
    LocationsWithNodeHealthQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<LocationsWithNodeHealthQuery, LocationsWithNodeHealthQueryVariables>(
    LocationsWithNodeHealthDocument,
    baseOptions
  );
}
export type LocationsWithNodeHealthQueryHookResult = ReturnType<typeof useLocationsWithNodeHealthQuery>;
export type LocationsWithNodeHealthLazyQueryHookResult = ReturnType<typeof useLocationsWithNodeHealthLazyQuery>;
export type LocationsWithNodeHealthQueryResult = ApolloReactCommon.QueryResult<
  LocationsWithNodeHealthQuery,
  LocationsWithNodeHealthQueryVariables
>;
export const LocationsWithNodeHealthAndTasksDocument = gql`
  query locationsWithNodeHealthAndTasks(
    $filter: LocationFilterInput
    $sort: LocationSortInput
    $pagination: GenericPaginationInput
  ) {
    locationsAssignedToUser(filter: $filter, sort: $sort, pagination: $pagination) {
      locations {
        id
        locationName
        currency
        locationStats {
          mappingStatus
          keysStats {
            totalCount
            mappedCount
          }
          gatewaysStats {
            totalCount
            mappedCount
          }
          nodeHealthStats {
            totalCount
            onlineCount
            bootedCount
            offlineCount
          }
          tasksStats {
            totalCount
            pendingCount
            inProgressCount
            doneCount
          }
        }
      }
      filterOptions {
        field
        value
        label
        count
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useLocationsWithNodeHealthAndTasksQuery__
 *
 * To run a query within a React component, call `useLocationsWithNodeHealthAndTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsWithNodeHealthAndTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsWithNodeHealthAndTasksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLocationsWithNodeHealthAndTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationsWithNodeHealthAndTasksQuery,
    LocationsWithNodeHealthAndTasksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LocationsWithNodeHealthAndTasksQuery, LocationsWithNodeHealthAndTasksQueryVariables>(
    LocationsWithNodeHealthAndTasksDocument,
    baseOptions
  );
}
export function useLocationsWithNodeHealthAndTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationsWithNodeHealthAndTasksQuery,
    LocationsWithNodeHealthAndTasksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LocationsWithNodeHealthAndTasksQuery,
    LocationsWithNodeHealthAndTasksQueryVariables
  >(LocationsWithNodeHealthAndTasksDocument, baseOptions);
}
export type LocationsWithNodeHealthAndTasksQueryHookResult = ReturnType<typeof useLocationsWithNodeHealthAndTasksQuery>;
export type LocationsWithNodeHealthAndTasksLazyQueryHookResult = ReturnType<
  typeof useLocationsWithNodeHealthAndTasksLazyQuery
>;
export type LocationsWithNodeHealthAndTasksQueryResult = ApolloReactCommon.QueryResult<
  LocationsWithNodeHealthAndTasksQuery,
  LocationsWithNodeHealthAndTasksQueryVariables
>;
export const LocationNodeHealthDocument = gql`
  query locationNodeHealth($locationId: String!) {
    location(locationId: $locationId) {
      id
      locationStats {
        nodeHealthStats {
          totalCount
          onlineCount
          bootedCount
          offlineCount
        }
        nodeHealthOverallStats {
          nodeType
          nodeHealthStats {
            totalCount
            onlineCount
            offlineCount
            bootedCount
            __typename
          }
          __typename
        }
      }
    }
  }
`;

/**
 * __useLocationNodeHealthQuery__
 *
 * To run a query within a React component, call `useLocationNodeHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationNodeHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationNodeHealthQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationNodeHealthQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationNodeHealthQuery, LocationNodeHealthQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationNodeHealthQuery, LocationNodeHealthQueryVariables>(
    LocationNodeHealthDocument,
    baseOptions
  );
}
export function useLocationNodeHealthLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationNodeHealthQuery, LocationNodeHealthQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationNodeHealthQuery, LocationNodeHealthQueryVariables>(
    LocationNodeHealthDocument,
    baseOptions
  );
}
export type LocationNodeHealthQueryHookResult = ReturnType<typeof useLocationNodeHealthQuery>;
export type LocationNodeHealthLazyQueryHookResult = ReturnType<typeof useLocationNodeHealthLazyQuery>;
export type LocationNodeHealthQueryResult = ApolloReactCommon.QueryResult<
  LocationNodeHealthQuery,
  LocationNodeHealthQueryVariables
>;
export const KeysDocument = gql`
  query keys($filter: KeyFilterInput!, $sort: KeySortInput, $pagination: GenericPaginationInput) {
    keys(filter: $filter, sort: $sort, pagination: $pagination) {
      keys {
        keyId
        keyName
        categoryName
        group {
          groupId
          groupName
        }
        keyStats {
          mappingStatus
          nodesStats {
            totalCount
            mappedCount
          }
          roomsStats {
            totalCount
            mappedCount
          }
        }
      }
      filterOptions {
        field
        value
        label
        count
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useKeysQuery__
 *
 * To run a query within a React component, call `useKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeysQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useKeysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KeysQuery, KeysQueryVariables>) {
  return ApolloReactHooks.useQuery<KeysQuery, KeysQueryVariables>(KeysDocument, baseOptions);
}
export function useKeysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeysQuery, KeysQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<KeysQuery, KeysQueryVariables>(KeysDocument, baseOptions);
}
export type KeysQueryHookResult = ReturnType<typeof useKeysQuery>;
export type KeysLazyQueryHookResult = ReturnType<typeof useKeysLazyQuery>;
export type KeysQueryResult = ApolloReactCommon.QueryResult<KeysQuery, KeysQueryVariables>;
export const KeysWithNodeHealthDocument = gql`
  query keysWithNodeHealth($filter: KeyFilterInput!, $sort: KeySortInput, $pagination: GenericPaginationInput) {
    keys(filter: $filter, sort: $sort, pagination: $pagination) {
      keys {
        keyId
        keyName
        categoryName
        group {
          groupName
          groupId
        }
        keyStats {
          mappingStatus
          nodesStats {
            totalCount
            mappedCount
          }
          roomsStats {
            totalCount
            mappedCount
          }
          nodeHealthStats {
            totalCount
            onlineCount
            bootedCount
            offlineCount
          }
        }
      }
      filterOptions {
        field
        value
        label
        count
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useKeysWithNodeHealthQuery__
 *
 * To run a query within a React component, call `useKeysWithNodeHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeysWithNodeHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeysWithNodeHealthQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useKeysWithNodeHealthQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<KeysWithNodeHealthQuery, KeysWithNodeHealthQueryVariables>
) {
  return ApolloReactHooks.useQuery<KeysWithNodeHealthQuery, KeysWithNodeHealthQueryVariables>(
    KeysWithNodeHealthDocument,
    baseOptions
  );
}
export function useKeysWithNodeHealthLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeysWithNodeHealthQuery, KeysWithNodeHealthQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<KeysWithNodeHealthQuery, KeysWithNodeHealthQueryVariables>(
    KeysWithNodeHealthDocument,
    baseOptions
  );
}
export type KeysWithNodeHealthQueryHookResult = ReturnType<typeof useKeysWithNodeHealthQuery>;
export type KeysWithNodeHealthLazyQueryHookResult = ReturnType<typeof useKeysWithNodeHealthLazyQuery>;
export type KeysWithNodeHealthQueryResult = ApolloReactCommon.QueryResult<
  KeysWithNodeHealthQuery,
  KeysWithNodeHealthQueryVariables
>;
export const JobEntryDocument = gql`
  query jobEntry($filter: KeyTaskFilterInput!, $pagination: GenericPaginationInput) {
    keysTasks(filter: $filter, pagination: $pagination) {
      keys {
        keyId
        keyName
        categoryName
        possitionType
        taskDueDate
        group {
          groupId
          groupName
          groupType
        }
        keyStats {
          mappingStatus
          nodesStats {
            totalCount
            mappedCount
          }
          roomsStats {
            totalCount
            mappedCount
          }
          nodeHealthStats {
            totalCount
            onlineCount
            bootedCount
            offlineCount
          }
          tasksStats {
            totalCount
            pendingCount
            inProgressCount
            doneCount
          }
        }
      }
      filterOptions {
        field
        value
        label
        count
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useJobEntryQuery__
 *
 * To run a query within a React component, call `useJobEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobEntryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useJobEntryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<JobEntryQuery, JobEntryQueryVariables>
) {
  return ApolloReactHooks.useQuery<JobEntryQuery, JobEntryQueryVariables>(JobEntryDocument, baseOptions);
}
export function useJobEntryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobEntryQuery, JobEntryQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<JobEntryQuery, JobEntryQueryVariables>(JobEntryDocument, baseOptions);
}
export type JobEntryQueryHookResult = ReturnType<typeof useJobEntryQuery>;
export type JobEntryLazyQueryHookResult = ReturnType<typeof useJobEntryLazyQuery>;
export type JobEntryQueryResult = ApolloReactCommon.QueryResult<JobEntryQuery, JobEntryQueryVariables>;
export const SimpleKeyListDocument = gql`
  query simpleKeyList($filter: KeyFilterInput!, $sort: KeySortInput, $pagination: GenericPaginationInput) {
    keys(filter: $filter, sort: $sort, pagination: $pagination) {
      keys {
        keyId
        keyName
        categoryName
        group {
          groupId
          groupName
        }
        keyStats {
          nodesStats {
            totalCount
          }
          roomsStats {
            totalCount
          }
        }
      }
      filterOptions {
        field
        value
        label
        count
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useSimpleKeyListQuery__
 *
 * To run a query within a React component, call `useSimpleKeyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleKeyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleKeyListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSimpleKeyListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleKeyListQuery, SimpleKeyListQueryVariables>
) {
  return ApolloReactHooks.useQuery<SimpleKeyListQuery, SimpleKeyListQueryVariables>(SimpleKeyListDocument, baseOptions);
}
export function useSimpleKeyListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleKeyListQuery, SimpleKeyListQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SimpleKeyListQuery, SimpleKeyListQueryVariables>(
    SimpleKeyListDocument,
    baseOptions
  );
}
export type SimpleKeyListQueryHookResult = ReturnType<typeof useSimpleKeyListQuery>;
export type SimpleKeyListLazyQueryHookResult = ReturnType<typeof useSimpleKeyListLazyQuery>;
export type SimpleKeyListQueryResult = ApolloReactCommon.QueryResult<SimpleKeyListQuery, SimpleKeyListQueryVariables>;
export const KeyDocument = gql`
  query key($keyId: String!) {
    key(keyId: $keyId) {
      keyId
      keyName
      categoryName
      keyStats {
        mappingStatus
        nodesStats {
          totalCount
          mappedCount
        }
      }
      rooms {
        roomId
        roomName
        positionConfiguration {
          id
          acModelId
          actingMode
          operationalMode
        }
        slots {
          id
          slotName
          nodeType
          nodeSubType
          nodeTypeCodeIdentifier
          currentMappedNode {
            mappedNode {
              nodeMacId
              type
              subType
            }
          }
        }
      }
    }
  }
`;

/**
 * __useKeyQuery__
 *
 * To run a query within a React component, call `useKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyQuery({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KeyQuery, KeyQueryVariables>) {
  return ApolloReactHooks.useQuery<KeyQuery, KeyQueryVariables>(KeyDocument, baseOptions);
}
export function useKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeyQuery, KeyQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<KeyQuery, KeyQueryVariables>(KeyDocument, baseOptions);
}
export type KeyQueryHookResult = ReturnType<typeof useKeyQuery>;
export type KeyLazyQueryHookResult = ReturnType<typeof useKeyLazyQuery>;
export type KeyQueryResult = ApolloReactCommon.QueryResult<KeyQuery, KeyQueryVariables>;
export const LabelPrintingKeyRoomsDocument = gql`
  query labelPrintingKeyRooms($keyId: String!) {
    key(keyId: $keyId, filter: { mappingStatus: COMPLETED }) {
      keyId
      keyName
      rooms {
        roomId
        roomName
      }
    }
  }
`;

/**
 * __useLabelPrintingKeyRoomsQuery__
 *
 * To run a query within a React component, call `useLabelPrintingKeyRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelPrintingKeyRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelPrintingKeyRoomsQuery({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useLabelPrintingKeyRoomsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LabelPrintingKeyRoomsQuery, LabelPrintingKeyRoomsQueryVariables>
) {
  return ApolloReactHooks.useQuery<LabelPrintingKeyRoomsQuery, LabelPrintingKeyRoomsQueryVariables>(
    LabelPrintingKeyRoomsDocument,
    baseOptions
  );
}
export function useLabelPrintingKeyRoomsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LabelPrintingKeyRoomsQuery, LabelPrintingKeyRoomsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LabelPrintingKeyRoomsQuery, LabelPrintingKeyRoomsQueryVariables>(
    LabelPrintingKeyRoomsDocument,
    baseOptions
  );
}
export type LabelPrintingKeyRoomsQueryHookResult = ReturnType<typeof useLabelPrintingKeyRoomsQuery>;
export type LabelPrintingKeyRoomsLazyQueryHookResult = ReturnType<typeof useLabelPrintingKeyRoomsLazyQuery>;
export type LabelPrintingKeyRoomsQueryResult = ApolloReactCommon.QueryResult<
  LabelPrintingKeyRoomsQuery,
  LabelPrintingKeyRoomsQueryVariables
>;
export const LabelDetailsForKeyDocument = gql`
  query labelDetailsForKey($keyId: String!) {
    key(keyId: $keyId, filter: { mappingStatus: COMPLETED }) {
      keyId
      keyName
      rooms {
        roomId
        roomName
        slots {
          slotName
          nodeType
          nodeSubType
          nodeTypeCodeIdentifier
          currentMappedNode {
            mappedNode {
              nodeMacId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useLabelDetailsForKeyQuery__
 *
 * To run a query within a React component, call `useLabelDetailsForKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelDetailsForKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelDetailsForKeyQuery({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useLabelDetailsForKeyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LabelDetailsForKeyQuery, LabelDetailsForKeyQueryVariables>
) {
  return ApolloReactHooks.useQuery<LabelDetailsForKeyQuery, LabelDetailsForKeyQueryVariables>(
    LabelDetailsForKeyDocument,
    baseOptions
  );
}
export function useLabelDetailsForKeyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LabelDetailsForKeyQuery, LabelDetailsForKeyQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LabelDetailsForKeyQuery, LabelDetailsForKeyQueryVariables>(
    LabelDetailsForKeyDocument,
    baseOptions
  );
}
export type LabelDetailsForKeyQueryHookResult = ReturnType<typeof useLabelDetailsForKeyQuery>;
export type LabelDetailsForKeyLazyQueryHookResult = ReturnType<typeof useLabelDetailsForKeyLazyQuery>;
export type LabelDetailsForKeyQueryResult = ApolloReactCommon.QueryResult<
  LabelDetailsForKeyQuery,
  LabelDetailsForKeyQueryVariables
>;
export const LabelDetailsForRoomDocument = gql`
  query labelDetailsForRoom($roomId: String!) {
    room(roomId: $roomId, filter: { mappingStatus: COMPLETED }) {
      roomId
      roomName
      slots {
        slotName
        nodeType
        nodeSubType
        nodeTypeCodeIdentifier
        currentMappedNode {
          mappedNode {
            nodeMacId
          }
        }
      }
    }
  }
`;

/**
 * __useLabelDetailsForRoomQuery__
 *
 * To run a query within a React component, call `useLabelDetailsForRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelDetailsForRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelDetailsForRoomQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useLabelDetailsForRoomQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LabelDetailsForRoomQuery, LabelDetailsForRoomQueryVariables>
) {
  return ApolloReactHooks.useQuery<LabelDetailsForRoomQuery, LabelDetailsForRoomQueryVariables>(
    LabelDetailsForRoomDocument,
    baseOptions
  );
}
export function useLabelDetailsForRoomLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LabelDetailsForRoomQuery, LabelDetailsForRoomQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LabelDetailsForRoomQuery, LabelDetailsForRoomQueryVariables>(
    LabelDetailsForRoomDocument,
    baseOptions
  );
}
export type LabelDetailsForRoomQueryHookResult = ReturnType<typeof useLabelDetailsForRoomQuery>;
export type LabelDetailsForRoomLazyQueryHookResult = ReturnType<typeof useLabelDetailsForRoomLazyQuery>;
export type LabelDetailsForRoomQueryResult = ApolloReactCommon.QueryResult<
  LabelDetailsForRoomQuery,
  LabelDetailsForRoomQueryVariables
>;
export const LocationWithKeysDocument = gql`
  query locationWithKeys($locationId: String!, $sort: KeySortInput, $pagination: GenericPaginationInput) {
    keysInLocation(locationId: $locationId, sort: $sort, pagination: $pagination) {
      id
      locationName
      locationStats {
        mappingStatus
        keysStats {
          totalCount
          mappedCount
        }
      }
      keysResponse(sort: $sort, pagination: $pagination) {
        keys {
          keyId
          keyName
          categoryName
        }
        pagination {
          limit
          offset
          count
        }
      }
    }
  }
`;

/**
 * __useLocationWithKeysQuery__
 *
 * To run a query within a React component, call `useLocationWithKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationWithKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationWithKeysQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLocationWithKeysQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationWithKeysQuery, LocationWithKeysQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationWithKeysQuery, LocationWithKeysQueryVariables>(
    LocationWithKeysDocument,
    baseOptions
  );
}
export function useLocationWithKeysLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationWithKeysQuery, LocationWithKeysQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationWithKeysQuery, LocationWithKeysQueryVariables>(
    LocationWithKeysDocument,
    baseOptions
  );
}
export type LocationWithKeysQueryHookResult = ReturnType<typeof useLocationWithKeysQuery>;
export type LocationWithKeysLazyQueryHookResult = ReturnType<typeof useLocationWithKeysLazyQuery>;
export type LocationWithKeysQueryResult = ApolloReactCommon.QueryResult<
  LocationWithKeysQuery,
  LocationWithKeysQueryVariables
>;
export const UserDocument = gql`
  query user($userId: String!) {
    user(userId: $userId) {
      userId
      name
      email
      status
      emailVerified
      lastLogin
      organisation {
        id
        name
      }
      locations {
        id
        locationName
      }
      role {
        id
        name
      }
      isManager
      isContractor
      isDev
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
  return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
  query users($filter: UserFilterInput, $sort: UserSortInput, $pagination: GenericPaginationInput) {
    users(filter: $filter, sort: $sort, pagination: $pagination) {
      users {
        userId
        name
        email
        status
        emailVerified
        lastLogin
        role {
          id
          name
        }
        locations {
          locationName
        }
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
  return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
}
export function useUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const RolesDocument = gql`
  query roles {
    roles {
      id
      name
      defaultRoute
    }
  }
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
  return ApolloReactHooks.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
}
export function useRolesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = ApolloReactCommon.QueryResult<RolesQuery, RolesQueryVariables>;
export const RolesWithStatsDocument = gql`
  query rolesWithStats {
    roles {
      id
      name
      userCount
    }
  }
`;

/**
 * __useRolesWithStatsQuery__
 *
 * To run a query within a React component, call `useRolesWithStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesWithStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesWithStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesWithStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RolesWithStatsQuery, RolesWithStatsQueryVariables>
) {
  return ApolloReactHooks.useQuery<RolesWithStatsQuery, RolesWithStatsQueryVariables>(
    RolesWithStatsDocument,
    baseOptions
  );
}
export function useRolesWithStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesWithStatsQuery, RolesWithStatsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<RolesWithStatsQuery, RolesWithStatsQueryVariables>(
    RolesWithStatsDocument,
    baseOptions
  );
}
export type RolesWithStatsQueryHookResult = ReturnType<typeof useRolesWithStatsQuery>;
export type RolesWithStatsLazyQueryHookResult = ReturnType<typeof useRolesWithStatsLazyQuery>;
export type RolesWithStatsQueryResult = ApolloReactCommon.QueryResult<
  RolesWithStatsQuery,
  RolesWithStatsQueryVariables
>;
export const RapidMappingDocument = gql`
  query rapidMapping($locationId: String!) {
    rapidMapping(locationId: $locationId) {
      locationName
      keysStats {
        totalCount
        mappedCount
      }
      currentRapidMappingKey {
        keyId
        keyName
        categoryName
        currentRapidMappingRoom {
          roomId
          roomName
          slots {
            nodeType
            nodeSubType
            nodeTypeCodeIdentifier
            slotName
            isCurrentRapidMappingSlot
            mappingStatus
          }
        }
      }
      nextRapidMappingKey {
        keyId
        keyName
        nextRapidMappingRoom {
          roomId
          roomName
          slots {
            nodeType
            nodeSubType
            nodeTypeCodeIdentifier
            slotName
          }
        }
      }
    }
  }
`;

/**
 * __useRapidMappingQuery__
 *
 * To run a query within a React component, call `useRapidMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRapidMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRapidMappingQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRapidMappingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RapidMappingQuery, RapidMappingQueryVariables>
) {
  return ApolloReactHooks.useQuery<RapidMappingQuery, RapidMappingQueryVariables>(RapidMappingDocument, baseOptions);
}
export function useRapidMappingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RapidMappingQuery, RapidMappingQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<RapidMappingQuery, RapidMappingQueryVariables>(
    RapidMappingDocument,
    baseOptions
  );
}
export type RapidMappingQueryHookResult = ReturnType<typeof useRapidMappingQuery>;
export type RapidMappingLazyQueryHookResult = ReturnType<typeof useRapidMappingLazyQuery>;
export type RapidMappingQueryResult = ApolloReactCommon.QueryResult<RapidMappingQuery, RapidMappingQueryVariables>;
export const SkipRapidMappingDocument = gql`
  mutation skipRapidMapping($roomId: ID!, $slotName: String!, $nodeType: NodeType!) {
    skipRapidMapping(roomId: $roomId, slotName: $slotName, nodeType: $nodeType) {
      locationName
      keysStats {
        totalCount
        mappedCount
      }
      currentRapidMappingKey {
        keyId
        keyName
        categoryName
        currentRapidMappingRoom {
          roomId
          roomName
          slots {
            nodeType
            nodeSubType
            nodeTypeCodeIdentifier
            slotName
            isCurrentRapidMappingSlot
            mappingStatus
          }
        }
      }
      nextRapidMappingKey {
        keyId
        keyName
        nextRapidMappingRoom {
          roomId
          roomName
          slots {
            nodeType
            nodeSubType
            nodeTypeCodeIdentifier
            slotName
          }
        }
      }
    }
  }
`;
export type SkipRapidMappingMutationFn = ApolloReactCommon.MutationFunction<
  SkipRapidMappingMutation,
  SkipRapidMappingMutationVariables
>;

/**
 * __useSkipRapidMappingMutation__
 *
 * To run a mutation, you first call `useSkipRapidMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipRapidMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipRapidMappingMutation, { data, loading, error }] = useSkipRapidMappingMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      slotName: // value for 'slotName'
 *      nodeType: // value for 'nodeType'
 *   },
 * });
 */
export function useSkipRapidMappingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SkipRapidMappingMutation, SkipRapidMappingMutationVariables>
) {
  return ApolloReactHooks.useMutation<SkipRapidMappingMutation, SkipRapidMappingMutationVariables>(
    SkipRapidMappingDocument,
    baseOptions
  );
}
export type SkipRapidMappingMutationHookResult = ReturnType<typeof useSkipRapidMappingMutation>;
export type SkipRapidMappingMutationResult = ApolloReactCommon.MutationResult<SkipRapidMappingMutation>;
export type SkipRapidMappingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SkipRapidMappingMutation,
  SkipRapidMappingMutationVariables
>;
export const RapidMapDocument = gql`
  mutation rapidMap(
    $roomId: ID!
    $slotName: String!
    $nodeType: NodeType!
    $nodeMacInput: String!
    $nodeSubType: String
  ) {
    mapNode(
      roomId: $roomId
      slotName: $slotName
      nodeType: $nodeType
      nodeMacInput: $nodeMacInput
      nodeSubType: $nodeSubType
    ) {
      locationName
      keysStats {
        totalCount
        mappedCount
      }
      currentRapidMappingKey {
        keyId
        keyName
        categoryName
        currentRapidMappingRoom {
          roomId
          roomName
          slots {
            nodeType
            nodeSubType
            nodeTypeCodeIdentifier
            slotName
            isCurrentRapidMappingSlot
            mappingStatus
          }
        }
      }
      nextRapidMappingKey {
        keyId
        keyName
        nextRapidMappingRoom {
          roomId
          roomName
          slots {
            nodeType
            nodeSubType
            nodeTypeCodeIdentifier
            slotName
          }
        }
      }
    }
  }
`;
export type RapidMapMutationFn = ApolloReactCommon.MutationFunction<RapidMapMutation, RapidMapMutationVariables>;

/**
 * __useRapidMapMutation__
 *
 * To run a mutation, you first call `useRapidMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRapidMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rapidMapMutation, { data, loading, error }] = useRapidMapMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      slotName: // value for 'slotName'
 *      nodeType: // value for 'nodeType'
 *      nodeMacInput: // value for 'nodeMacInput'
 *      nodeSubType: // value for 'nodeSubType'
 *   },
 * });
 */
export function useRapidMapMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RapidMapMutation, RapidMapMutationVariables>
) {
  return ApolloReactHooks.useMutation<RapidMapMutation, RapidMapMutationVariables>(RapidMapDocument, baseOptions);
}
export type RapidMapMutationHookResult = ReturnType<typeof useRapidMapMutation>;
export type RapidMapMutationResult = ApolloReactCommon.MutationResult<RapidMapMutation>;
export type RapidMapMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RapidMapMutation,
  RapidMapMutationVariables
>;
export const SingleMapNodeDocument = gql`
  mutation singleMapNode(
    $roomId: ID!
    $slotName: String!
    $nodeType: NodeType!
    $nodeMacInput: String!
    $nodeSubType: String
  ) {
    mapNode(
      roomId: $roomId
      slotName: $slotName
      nodeType: $nodeType
      nodeMacInput: $nodeMacInput
      nodeSubType: $nodeSubType
    ) {
      currentKey {
        keyId
        keyName
        categoryName
        keyStats {
          mappingStatus
          nodesStats {
            totalCount
            mappedCount
          }
        }
        rooms {
          roomId
          roomName
          slots {
            nodeType
            nodeSubType
            nodeTypeCodeIdentifier
            slotName
            mappingStatus
            currentMappedNode {
              mappedNode {
                nodeMacId
                subType
                type
              }
            }
          }
        }
      }
    }
  }
`;
export type SingleMapNodeMutationFn = ApolloReactCommon.MutationFunction<
  SingleMapNodeMutation,
  SingleMapNodeMutationVariables
>;

/**
 * __useSingleMapNodeMutation__
 *
 * To run a mutation, you first call `useSingleMapNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingleMapNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singleMapNodeMutation, { data, loading, error }] = useSingleMapNodeMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      slotName: // value for 'slotName'
 *      nodeType: // value for 'nodeType'
 *      nodeMacInput: // value for 'nodeMacInput'
 *      nodeSubType: // value for 'nodeSubType'
 *   },
 * });
 */
export function useSingleMapNodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SingleMapNodeMutation, SingleMapNodeMutationVariables>
) {
  return ApolloReactHooks.useMutation<SingleMapNodeMutation, SingleMapNodeMutationVariables>(
    SingleMapNodeDocument,
    baseOptions
  );
}
export type SingleMapNodeMutationHookResult = ReturnType<typeof useSingleMapNodeMutation>;
export type SingleMapNodeMutationResult = ApolloReactCommon.MutationResult<SingleMapNodeMutation>;
export type SingleMapNodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SingleMapNodeMutation,
  SingleMapNodeMutationVariables
>;
export const UnmapNodeDocument = gql`
  mutation unmapNode(
    $roomId: ID!
    $slotName: String!
    $nodeType: NodeType!
    $nodeMacInput: String!
    $scannedMacId: String
    $rootCauseId: Int
    $comment: String
    $nodeSubType: String
  ) {
    unmapNode(
      roomId: $roomId
      slotName: $slotName
      nodeType: $nodeType
      nodeMacInput: $nodeMacInput
      scannedMacId: $scannedMacId
      rootCauseId: $rootCauseId
      comment: $comment
      nodeSubType: $nodeSubType
    ) {
      currentKey {
        keyId
        keyName
        categoryName
        keyStats {
          mappingStatus
          nodesStats {
            totalCount
            mappedCount
          }
        }
        rooms {
          roomId
          roomName
          slots {
            nodeType
            nodeSubType
            nodeTypeCodeIdentifier
            slotName
            mappingStatus
          }
        }
      }
    }
  }
`;
export type UnmapNodeMutationFn = ApolloReactCommon.MutationFunction<UnmapNodeMutation, UnmapNodeMutationVariables>;

/**
 * __useUnmapNodeMutation__
 *
 * To run a mutation, you first call `useUnmapNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmapNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmapNodeMutation, { data, loading, error }] = useUnmapNodeMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      slotName: // value for 'slotName'
 *      nodeType: // value for 'nodeType'
 *      nodeMacInput: // value for 'nodeMacInput'
 *      scannedMacId: // value for 'scannedMacId'
 *      rootCauseId: // value for 'rootCauseId'
 *      comment: // value for 'comment'
 *      nodeSubType: // value for 'nodeSubType'
 *   },
 * });
 */
export function useUnmapNodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UnmapNodeMutation, UnmapNodeMutationVariables>
) {
  return ApolloReactHooks.useMutation<UnmapNodeMutation, UnmapNodeMutationVariables>(UnmapNodeDocument, baseOptions);
}
export type UnmapNodeMutationHookResult = ReturnType<typeof useUnmapNodeMutation>;
export type UnmapNodeMutationResult = ApolloReactCommon.MutationResult<UnmapNodeMutation>;
export type UnmapNodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnmapNodeMutation,
  UnmapNodeMutationVariables
>;
export const ValidateInfraNodeMappingDocument = gql`
  query validateInfraNodeMapping(
    $infraId: ID!
    $slotName: String!
    $nodeType: NodeType!
    $nodeMacInput: String!
    $nodeSubType: String
  ) {
    validateInfraNodeMapping(
      infraId: $infraId
      slotName: $slotName
      nodeType: $nodeType
      nodeMacInput: $nodeMacInput
      nodeSubType: $nodeSubType
    )
  }
`;

/**
 * __useValidateInfraNodeMappingQuery__
 *
 * To run a query within a React component, call `useValidateInfraNodeMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateInfraNodeMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateInfraNodeMappingQuery({
 *   variables: {
 *      infraId: // value for 'infraId'
 *      slotName: // value for 'slotName'
 *      nodeType: // value for 'nodeType'
 *      nodeMacInput: // value for 'nodeMacInput'
 *      nodeSubType: // value for 'nodeSubType'
 *   },
 * });
 */
export function useValidateInfraNodeMappingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ValidateInfraNodeMappingQuery, ValidateInfraNodeMappingQueryVariables>
) {
  return ApolloReactHooks.useQuery<ValidateInfraNodeMappingQuery, ValidateInfraNodeMappingQueryVariables>(
    ValidateInfraNodeMappingDocument,
    baseOptions
  );
}
export function useValidateInfraNodeMappingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ValidateInfraNodeMappingQuery,
    ValidateInfraNodeMappingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ValidateInfraNodeMappingQuery, ValidateInfraNodeMappingQueryVariables>(
    ValidateInfraNodeMappingDocument,
    baseOptions
  );
}
export type ValidateInfraNodeMappingQueryHookResult = ReturnType<typeof useValidateInfraNodeMappingQuery>;
export type ValidateInfraNodeMappingLazyQueryHookResult = ReturnType<typeof useValidateInfraNodeMappingLazyQuery>;
export type ValidateInfraNodeMappingQueryResult = ApolloReactCommon.QueryResult<
  ValidateInfraNodeMappingQuery,
  ValidateInfraNodeMappingQueryVariables
>;
export const ValidateNodeBeforeMappingToSlotDocument = gql`
  query validateNodeBeforeMappingToSlot(
    $roomId: ID!
    $slotName: String!
    $nodeType: NodeType!
    $nodeMacInput: String!
    $nodeSubType: String
  ) {
    validateNodeBeforeMappingToSlot(
      roomId: $roomId
      slotName: $slotName
      nodeType: $nodeType
      nodeMacInput: $nodeMacInput
      nodeSubType: $nodeSubType
    )
  }
`;

/**
 * __useValidateNodeBeforeMappingToSlotQuery__
 *
 * To run a query within a React component, call `useValidateNodeBeforeMappingToSlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateNodeBeforeMappingToSlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateNodeBeforeMappingToSlotQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      slotName: // value for 'slotName'
 *      nodeType: // value for 'nodeType'
 *      nodeMacInput: // value for 'nodeMacInput'
 *      nodeSubType: // value for 'nodeSubType'
 *   },
 * });
 */
export function useValidateNodeBeforeMappingToSlotQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ValidateNodeBeforeMappingToSlotQuery,
    ValidateNodeBeforeMappingToSlotQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ValidateNodeBeforeMappingToSlotQuery, ValidateNodeBeforeMappingToSlotQueryVariables>(
    ValidateNodeBeforeMappingToSlotDocument,
    baseOptions
  );
}
export function useValidateNodeBeforeMappingToSlotLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ValidateNodeBeforeMappingToSlotQuery,
    ValidateNodeBeforeMappingToSlotQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ValidateNodeBeforeMappingToSlotQuery,
    ValidateNodeBeforeMappingToSlotQueryVariables
  >(ValidateNodeBeforeMappingToSlotDocument, baseOptions);
}
export type ValidateNodeBeforeMappingToSlotQueryHookResult = ReturnType<typeof useValidateNodeBeforeMappingToSlotQuery>;
export type ValidateNodeBeforeMappingToSlotLazyQueryHookResult = ReturnType<
  typeof useValidateNodeBeforeMappingToSlotLazyQuery
>;
export type ValidateNodeBeforeMappingToSlotQueryResult = ApolloReactCommon.QueryResult<
  ValidateNodeBeforeMappingToSlotQuery,
  ValidateNodeBeforeMappingToSlotQueryVariables
>;
export const MapInfraNodeDocument = gql`
  mutation mapInfraNode(
    $infraId: ID!
    $slotName: String!
    $nodeType: NodeType!
    $nodeMacInput: String!
    $emonSetting: Int
    $mapInput: Int
    $nodeSubType: String
  ) {
    mapInfraNode(
      infraId: $infraId
      slotName: $slotName
      nodeType: $nodeType
      nodeMacInput: $nodeMacInput
      emonSetting: $emonSetting
      mapInput: $mapInput
      nodeSubType: $nodeSubType
    ) {
      nodeType
      slotName
      mappedNode {
        nodeMacId
      }
    }
  }
`;
export type MapInfraNodeMutationFn = ApolloReactCommon.MutationFunction<
  MapInfraNodeMutation,
  MapInfraNodeMutationVariables
>;

/**
 * __useMapInfraNodeMutation__
 *
 * To run a mutation, you first call `useMapInfraNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMapInfraNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mapInfraNodeMutation, { data, loading, error }] = useMapInfraNodeMutation({
 *   variables: {
 *      infraId: // value for 'infraId'
 *      slotName: // value for 'slotName'
 *      nodeType: // value for 'nodeType'
 *      nodeMacInput: // value for 'nodeMacInput'
 *      emonSetting: // value for 'emonSetting'
 *      mapInput: // value for 'mapInput'
 *      nodeSubType: // value for 'nodeSubType'
 *   },
 * });
 */
export function useMapInfraNodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<MapInfraNodeMutation, MapInfraNodeMutationVariables>
) {
  return ApolloReactHooks.useMutation<MapInfraNodeMutation, MapInfraNodeMutationVariables>(
    MapInfraNodeDocument,
    baseOptions
  );
}
export type MapInfraNodeMutationHookResult = ReturnType<typeof useMapInfraNodeMutation>;
export type MapInfraNodeMutationResult = ApolloReactCommon.MutationResult<MapInfraNodeMutation>;
export type MapInfraNodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MapInfraNodeMutation,
  MapInfraNodeMutationVariables
>;
export const UnmapInfraNodeDocument = gql`
  mutation unmapInfraNode(
    $infraId: ID!
    $nodeMacId: String!
    $scannedMacId: String
    $rootCauseId: Int
    $comment: String
  ) {
    unmapInfraNode(
      infraId: $infraId
      nodeMacId: $nodeMacId
      scannedMacId: $scannedMacId
      rootCauseId: $rootCauseId
      comment: $comment
    ) {
      nodeType
      slotName
    }
  }
`;
export type UnmapInfraNodeMutationFn = ApolloReactCommon.MutationFunction<
  UnmapInfraNodeMutation,
  UnmapInfraNodeMutationVariables
>;

/**
 * __useUnmapInfraNodeMutation__
 *
 * To run a mutation, you first call `useUnmapInfraNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmapInfraNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmapInfraNodeMutation, { data, loading, error }] = useUnmapInfraNodeMutation({
 *   variables: {
 *      infraId: // value for 'infraId'
 *      nodeMacId: // value for 'nodeMacId'
 *      scannedMacId: // value for 'scannedMacId'
 *      rootCauseId: // value for 'rootCauseId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUnmapInfraNodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UnmapInfraNodeMutation, UnmapInfraNodeMutationVariables>
) {
  return ApolloReactHooks.useMutation<UnmapInfraNodeMutation, UnmapInfraNodeMutationVariables>(
    UnmapInfraNodeDocument,
    baseOptions
  );
}
export type UnmapInfraNodeMutationHookResult = ReturnType<typeof useUnmapInfraNodeMutation>;
export type UnmapInfraNodeMutationResult = ApolloReactCommon.MutationResult<UnmapInfraNodeMutation>;
export type UnmapInfraNodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnmapInfraNodeMutation,
  UnmapInfraNodeMutationVariables
>;
export const UpdateInfraNodeDocument = gql`
  mutation updateInfraNode($infraId: ID!, $nodeMacId: String!, $slotName: String!, $emonSetting: Int, $mapInput: Int) {
    updateInfraNode(
      infraId: $infraId
      nodeMacId: $nodeMacId
      slotName: $slotName
      emonSetting: $emonSetting
      mapInput: $mapInput
    )
  }
`;
export type UpdateInfraNodeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateInfraNodeMutation,
  UpdateInfraNodeMutationVariables
>;

/**
 * __useUpdateInfraNodeMutation__
 *
 * To run a mutation, you first call `useUpdateInfraNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfraNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfraNodeMutation, { data, loading, error }] = useUpdateInfraNodeMutation({
 *   variables: {
 *      infraId: // value for 'infraId'
 *      nodeMacId: // value for 'nodeMacId'
 *      slotName: // value for 'slotName'
 *      emonSetting: // value for 'emonSetting'
 *      mapInput: // value for 'mapInput'
 *   },
 * });
 */
export function useUpdateInfraNodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInfraNodeMutation, UpdateInfraNodeMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateInfraNodeMutation, UpdateInfraNodeMutationVariables>(
    UpdateInfraNodeDocument,
    baseOptions
  );
}
export type UpdateInfraNodeMutationHookResult = ReturnType<typeof useUpdateInfraNodeMutation>;
export type UpdateInfraNodeMutationResult = ApolloReactCommon.MutationResult<UpdateInfraNodeMutation>;
export type UpdateInfraNodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateInfraNodeMutation,
  UpdateInfraNodeMutationVariables
>;
export const ChangeBatteryDocument = gql`
  mutation changeBattery($nodeMacId: String!, $comment: String) {
    changeBattery(nodeMacId: $nodeMacId, comment: $comment) {
      id
      nodeMacId
      creationDate
    }
  }
`;
export type ChangeBatteryMutationFn = ApolloReactCommon.MutationFunction<
  ChangeBatteryMutation,
  ChangeBatteryMutationVariables
>;

/**
 * __useChangeBatteryMutation__
 *
 * To run a mutation, you first call `useChangeBatteryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBatteryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBatteryMutation, { data, loading, error }] = useChangeBatteryMutation({
 *   variables: {
 *      nodeMacId: // value for 'nodeMacId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useChangeBatteryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeBatteryMutation, ChangeBatteryMutationVariables>
) {
  return ApolloReactHooks.useMutation<ChangeBatteryMutation, ChangeBatteryMutationVariables>(
    ChangeBatteryDocument,
    baseOptions
  );
}
export type ChangeBatteryMutationHookResult = ReturnType<typeof useChangeBatteryMutation>;
export type ChangeBatteryMutationResult = ApolloReactCommon.MutationResult<ChangeBatteryMutation>;
export type ChangeBatteryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeBatteryMutation,
  ChangeBatteryMutationVariables
>;
export const EmonMapInputInfoDocument = gql`
  query emonMapInputInfo($infraId: ID!, $nodeMacId: String!) {
    emonMapInputInfo(infraId: $infraId, nodeMacId: $nodeMacId) {
      currentMapInput
      unavailableMapInputs
    }
  }
`;

/**
 * __useEmonMapInputInfoQuery__
 *
 * To run a query within a React component, call `useEmonMapInputInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmonMapInputInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmonMapInputInfoQuery({
 *   variables: {
 *      infraId: // value for 'infraId'
 *      nodeMacId: // value for 'nodeMacId'
 *   },
 * });
 */
export function useEmonMapInputInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EmonMapInputInfoQuery, EmonMapInputInfoQueryVariables>
) {
  return ApolloReactHooks.useQuery<EmonMapInputInfoQuery, EmonMapInputInfoQueryVariables>(
    EmonMapInputInfoDocument,
    baseOptions
  );
}
export function useEmonMapInputInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmonMapInputInfoQuery, EmonMapInputInfoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<EmonMapInputInfoQuery, EmonMapInputInfoQueryVariables>(
    EmonMapInputInfoDocument,
    baseOptions
  );
}
export type EmonMapInputInfoQueryHookResult = ReturnType<typeof useEmonMapInputInfoQuery>;
export type EmonMapInputInfoLazyQueryHookResult = ReturnType<typeof useEmonMapInputInfoLazyQuery>;
export type EmonMapInputInfoQueryResult = ApolloReactCommon.QueryResult<
  EmonMapInputInfoQuery,
  EmonMapInputInfoQueryVariables
>;
export const UserCreateDocument = gql`
  mutation userCreate($email: String!, $user: UserInput!) {
    userCreate(email: $email, user: $user) {
      userId
      name
      email
      status
      emailVerified
      lastLogin
      role {
        id
        name
      }
      organisation {
        id
        name
      }
      locations {
        id
        locationName
      }
      isManager
      isContractor
    }
  }
`;
export type UserCreateMutationFn = ApolloReactCommon.MutationFunction<UserCreateMutation, UserCreateMutationVariables>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      email: // value for 'email'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUserCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>
) {
  return ApolloReactHooks.useMutation<UserCreateMutation, UserCreateMutationVariables>(UserCreateDocument, baseOptions);
}
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = ApolloReactCommon.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserCreateMutation,
  UserCreateMutationVariables
>;
export const UserUpdateDocument = gql`
  mutation userUpdate($userId: String!, $user: UserInput!) {
    userUpdate(userId: $userId, user: $user) {
      userId
      name
      email
      status
      emailVerified
      lastLogin
      role {
        id
        name
      }
      organisation {
        id
        name
      }
      locations {
        id
        locationName
      }
      isManager
      isContractor
    }
  }
`;
export type UserUpdateMutationFn = ApolloReactCommon.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUserUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>
) {
  return ApolloReactHooks.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, baseOptions);
}
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = ApolloReactCommon.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;
export const UserResendWelcomeEmailDocument = gql`
  mutation userResendWelcomeEmail($userId: String!) {
    userResendWelcomeEmail(userId: $userId) {
      message
    }
  }
`;
export type UserResendWelcomeEmailMutationFn = ApolloReactCommon.MutationFunction<
  UserResendWelcomeEmailMutation,
  UserResendWelcomeEmailMutationVariables
>;

/**
 * __useUserResendWelcomeEmailMutation__
 *
 * To run a mutation, you first call `useUserResendWelcomeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserResendWelcomeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userResendWelcomeEmailMutation, { data, loading, error }] = useUserResendWelcomeEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserResendWelcomeEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserResendWelcomeEmailMutation,
    UserResendWelcomeEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UserResendWelcomeEmailMutation, UserResendWelcomeEmailMutationVariables>(
    UserResendWelcomeEmailDocument,
    baseOptions
  );
}
export type UserResendWelcomeEmailMutationHookResult = ReturnType<typeof useUserResendWelcomeEmailMutation>;
export type UserResendWelcomeEmailMutationResult = ApolloReactCommon.MutationResult<UserResendWelcomeEmailMutation>;
export type UserResendWelcomeEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserResendWelcomeEmailMutation,
  UserResendWelcomeEmailMutationVariables
>;
export const UserResetPasswordDocument = gql`
  mutation userResetPassword($email: String!) {
    userResetPassword(email: $email) {
      message
    }
  }
`;
export type UserResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  UserResetPasswordMutation,
  UserResetPasswordMutationVariables
>;

/**
 * __useUserResetPasswordMutation__
 *
 * To run a mutation, you first call `useUserResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userResetPasswordMutation, { data, loading, error }] = useUserResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UserResetPasswordMutation, UserResetPasswordMutationVariables>
) {
  return ApolloReactHooks.useMutation<UserResetPasswordMutation, UserResetPasswordMutationVariables>(
    UserResetPasswordDocument,
    baseOptions
  );
}
export type UserResetPasswordMutationHookResult = ReturnType<typeof useUserResetPasswordMutation>;
export type UserResetPasswordMutationResult = ApolloReactCommon.MutationResult<UserResetPasswordMutation>;
export type UserResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserResetPasswordMutation,
  UserResetPasswordMutationVariables
>;
export const UserDisableDocument = gql`
  mutation userDisable($userId: String!) {
    userDisable(userId: $userId) {
      message
    }
  }
`;
export type UserDisableMutationFn = ApolloReactCommon.MutationFunction<
  UserDisableMutation,
  UserDisableMutationVariables
>;

/**
 * __useUserDisableMutation__
 *
 * To run a mutation, you first call `useUserDisableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDisableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDisableMutation, { data, loading, error }] = useUserDisableMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserDisableMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UserDisableMutation, UserDisableMutationVariables>
) {
  return ApolloReactHooks.useMutation<UserDisableMutation, UserDisableMutationVariables>(
    UserDisableDocument,
    baseOptions
  );
}
export type UserDisableMutationHookResult = ReturnType<typeof useUserDisableMutation>;
export type UserDisableMutationResult = ApolloReactCommon.MutationResult<UserDisableMutation>;
export type UserDisableMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserDisableMutation,
  UserDisableMutationVariables
>;
export const LocationDocument = gql`
  query location($locationId: String!) {
    location(locationId: $locationId) {
      id
      locationName
      locationMode
      timezone
      ACModelId
      currency
      language
      organisation {
        id
        name
      }
      clickupExportDisabled
      beingExportedToClickup
      clickupListId
      enabledFeatures
    }
  }
`;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationQuery, LocationQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, baseOptions);
}
export function useLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationQuery, LocationQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, baseOptions);
}
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationQueryResult = ApolloReactCommon.QueryResult<LocationQuery, LocationQueryVariables>;
export const LocationKeyStatsDocument = gql`
  query locationKeyStats($locationId: String!) {
    location(locationId: $locationId) {
      id
      locationName
      locationStats {
        keysStats {
          totalCount
          mappedCount
        }
        nodeOverallStats {
          nodeType
          mappingStats {
            totalCount
            mappedCount
          }
        }
      }
    }
  }
`;

/**
 * __useLocationKeyStatsQuery__
 *
 * To run a query within a React component, call `useLocationKeyStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationKeyStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationKeyStatsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationKeyStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationKeyStatsQuery, LocationKeyStatsQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationKeyStatsQuery, LocationKeyStatsQueryVariables>(
    LocationKeyStatsDocument,
    baseOptions
  );
}
export function useLocationKeyStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationKeyStatsQuery, LocationKeyStatsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationKeyStatsQuery, LocationKeyStatsQueryVariables>(
    LocationKeyStatsDocument,
    baseOptions
  );
}
export type LocationKeyStatsQueryHookResult = ReturnType<typeof useLocationKeyStatsQuery>;
export type LocationKeyStatsLazyQueryHookResult = ReturnType<typeof useLocationKeyStatsLazyQuery>;
export type LocationKeyStatsQueryResult = ApolloReactCommon.QueryResult<
  LocationKeyStatsQuery,
  LocationKeyStatsQueryVariables
>;
export const LocationsForAutocompleteDocument = gql`
  query locationsForAutocomplete($filter: LocationFilterInput) {
    locations(filter: $filter, pagination: { offset: 0, limit: 1000 }) {
      locations {
        id
        locationName
        locationMode
      }
    }
  }
`;

/**
 * __useLocationsForAutocompleteQuery__
 *
 * To run a query within a React component, call `useLocationsForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsForAutocompleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLocationsForAutocompleteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationsForAutocompleteQuery, LocationsForAutocompleteQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationsForAutocompleteQuery, LocationsForAutocompleteQueryVariables>(
    LocationsForAutocompleteDocument,
    baseOptions
  );
}
export function useLocationsForAutocompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationsForAutocompleteQuery,
    LocationsForAutocompleteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<LocationsForAutocompleteQuery, LocationsForAutocompleteQueryVariables>(
    LocationsForAutocompleteDocument,
    baseOptions
  );
}
export type LocationsForAutocompleteQueryHookResult = ReturnType<typeof useLocationsForAutocompleteQuery>;
export type LocationsForAutocompleteLazyQueryHookResult = ReturnType<typeof useLocationsForAutocompleteLazyQuery>;
export type LocationsForAutocompleteQueryResult = ApolloReactCommon.QueryResult<
  LocationsForAutocompleteQuery,
  LocationsForAutocompleteQueryVariables
>;
export const OrganisationsDocument = gql`
  query organisations(
    $filter: OrganisationFilterInput
    $sort: OrganisationSortInput
    $pagination: GenericPaginationInput
  ) {
    organisations(filter: $filter, sort: $sort, pagination: $pagination) {
      organisations {
        id
        name
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useOrganisationsQuery__
 *
 * To run a query within a React component, call `useOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrganisationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, baseOptions);
}
export function useOrganisationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<OrganisationsQuery, OrganisationsQueryVariables>(
    OrganisationsDocument,
    baseOptions
  );
}
export type OrganisationsQueryHookResult = ReturnType<typeof useOrganisationsQuery>;
export type OrganisationsLazyQueryHookResult = ReturnType<typeof useOrganisationsLazyQuery>;
export type OrganisationsQueryResult = ApolloReactCommon.QueryResult<OrganisationsQuery, OrganisationsQueryVariables>;
export const OrganisationsForAutocompleteDocument = gql`
  query organisationsForAutocomplete($filter: OrganisationFilterInput, $pagination: GenericPaginationInput) {
    organisations(filter: $filter, pagination: $pagination) {
      organisations {
        id
        name
      }
    }
  }
`;

/**
 * __useOrganisationsForAutocompleteQuery__
 *
 * To run a query within a React component, call `useOrganisationsForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsForAutocompleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrganisationsForAutocompleteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganisationsForAutocompleteQuery,
    OrganisationsForAutocompleteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<OrganisationsForAutocompleteQuery, OrganisationsForAutocompleteQueryVariables>(
    OrganisationsForAutocompleteDocument,
    baseOptions
  );
}
export function useOrganisationsForAutocompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganisationsForAutocompleteQuery,
    OrganisationsForAutocompleteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<OrganisationsForAutocompleteQuery, OrganisationsForAutocompleteQueryVariables>(
    OrganisationsForAutocompleteDocument,
    baseOptions
  );
}
export type OrganisationsForAutocompleteQueryHookResult = ReturnType<typeof useOrganisationsForAutocompleteQuery>;
export type OrganisationsForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useOrganisationsForAutocompleteLazyQuery
>;
export type OrganisationsForAutocompleteQueryResult = ApolloReactCommon.QueryResult<
  OrganisationsForAutocompleteQuery,
  OrganisationsForAutocompleteQueryVariables
>;
export const OrganisationDocument = gql`
  query organisation($organisationId: String!) {
    organisation(organisationId: $organisationId) {
      id
      name
    }
  }
`;

/**
 * __useOrganisationQuery__
 *
 * To run a query within a React component, call `useOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useOrganisationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationQuery, OrganisationQueryVariables>
) {
  return ApolloReactHooks.useQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationDocument, baseOptions);
}
export function useOrganisationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationQuery, OrganisationQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<OrganisationQuery, OrganisationQueryVariables>(
    OrganisationDocument,
    baseOptions
  );
}
export type OrganisationQueryHookResult = ReturnType<typeof useOrganisationQuery>;
export type OrganisationLazyQueryHookResult = ReturnType<typeof useOrganisationLazyQuery>;
export type OrganisationQueryResult = ApolloReactCommon.QueryResult<OrganisationQuery, OrganisationQueryVariables>;
export const AcModelsDocument = gql`
  query ACModels($filter: ACModelFilterInput, $sort: ACModelSortInput, $pagination: GenericPaginationInput) {
    ACModels(filter: $filter, sort: $sort, pagination: $pagination) {
      ACModels {
        id
        brandName
        modelName
        status
        identifier
      }
      pagination {
        limit
        offset
        count
      }
    }
  }
`;

/**
 * __useAcModelsQuery__
 *
 * To run a query within a React component, call `useAcModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcModelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAcModelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AcModelsQuery, AcModelsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AcModelsQuery, AcModelsQueryVariables>(AcModelsDocument, baseOptions);
}
export function useAcModelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AcModelsQuery, AcModelsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AcModelsQuery, AcModelsQueryVariables>(AcModelsDocument, baseOptions);
}
export type AcModelsQueryHookResult = ReturnType<typeof useAcModelsQuery>;
export type AcModelsLazyQueryHookResult = ReturnType<typeof useAcModelsLazyQuery>;
export type AcModelsQueryResult = ApolloReactCommon.QueryResult<AcModelsQuery, AcModelsQueryVariables>;
export const AcModelDocument = gql`
  query ACModel($ACModelId: String!) {
    ACModel(ACModelId: $ACModelId) {
      id
      brandName
      modelName
      status
    }
  }
`;

/**
 * __useAcModelQuery__
 *
 * To run a query within a React component, call `useAcModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcModelQuery({
 *   variables: {
 *      ACModelId: // value for 'ACModelId'
 *   },
 * });
 */
export function useAcModelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AcModelQuery, AcModelQueryVariables>) {
  return ApolloReactHooks.useQuery<AcModelQuery, AcModelQueryVariables>(AcModelDocument, baseOptions);
}
export function useAcModelLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AcModelQuery, AcModelQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AcModelQuery, AcModelQueryVariables>(AcModelDocument, baseOptions);
}
export type AcModelQueryHookResult = ReturnType<typeof useAcModelQuery>;
export type AcModelLazyQueryHookResult = ReturnType<typeof useAcModelLazyQuery>;
export type AcModelQueryResult = ApolloReactCommon.QueryResult<AcModelQuery, AcModelQueryVariables>;
export const GatewayImageDownloadUrlDocument = gql`
  query gatewayImageDownloadUrl($gatewayId: String!) {
    gatewayImageDownloadUrl(gatewayId: $gatewayId)
  }
`;

/**
 * __useGatewayImageDownloadUrlQuery__
 *
 * To run a query within a React component, call `useGatewayImageDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayImageDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayImageDownloadUrlQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useGatewayImageDownloadUrlQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GatewayImageDownloadUrlQuery, GatewayImageDownloadUrlQueryVariables>
) {
  return ApolloReactHooks.useQuery<GatewayImageDownloadUrlQuery, GatewayImageDownloadUrlQueryVariables>(
    GatewayImageDownloadUrlDocument,
    baseOptions
  );
}
export function useGatewayImageDownloadUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GatewayImageDownloadUrlQuery,
    GatewayImageDownloadUrlQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GatewayImageDownloadUrlQuery, GatewayImageDownloadUrlQueryVariables>(
    GatewayImageDownloadUrlDocument,
    baseOptions
  );
}
export type GatewayImageDownloadUrlQueryHookResult = ReturnType<typeof useGatewayImageDownloadUrlQuery>;
export type GatewayImageDownloadUrlLazyQueryHookResult = ReturnType<typeof useGatewayImageDownloadUrlLazyQuery>;
export type GatewayImageDownloadUrlQueryResult = ApolloReactCommon.QueryResult<
  GatewayImageDownloadUrlQuery,
  GatewayImageDownloadUrlQueryVariables
>;
export const AcModelCreateDocument = gql`
  mutation ACModelCreate($ACModel: ACModelInput!) {
    ACModelCreate(ACModel: $ACModel) {
      id
      brandName
      modelName
      identifier
      description
    }
  }
`;
export type AcModelCreateMutationFn = ApolloReactCommon.MutationFunction<
  AcModelCreateMutation,
  AcModelCreateMutationVariables
>;

/**
 * __useAcModelCreateMutation__
 *
 * To run a mutation, you first call `useAcModelCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcModelCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acModelCreateMutation, { data, loading, error }] = useAcModelCreateMutation({
 *   variables: {
 *      ACModel: // value for 'ACModel'
 *   },
 * });
 */
export function useAcModelCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AcModelCreateMutation, AcModelCreateMutationVariables>
) {
  return ApolloReactHooks.useMutation<AcModelCreateMutation, AcModelCreateMutationVariables>(
    AcModelCreateDocument,
    baseOptions
  );
}
export type AcModelCreateMutationHookResult = ReturnType<typeof useAcModelCreateMutation>;
export type AcModelCreateMutationResult = ApolloReactCommon.MutationResult<AcModelCreateMutation>;
export type AcModelCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AcModelCreateMutation,
  AcModelCreateMutationVariables
>;
export const AcModelUpdateDocument = gql`
  mutation ACModelUpdate($ACModelId: String!, $ACModel: ACModelInput!) {
    ACModelUpdate(ACModelId: $ACModelId, ACModel: $ACModel) {
      id
      brandName
      modelName
      identifier
      description
    }
  }
`;
export type AcModelUpdateMutationFn = ApolloReactCommon.MutationFunction<
  AcModelUpdateMutation,
  AcModelUpdateMutationVariables
>;

/**
 * __useAcModelUpdateMutation__
 *
 * To run a mutation, you first call `useAcModelUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcModelUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acModelUpdateMutation, { data, loading, error }] = useAcModelUpdateMutation({
 *   variables: {
 *      ACModelId: // value for 'ACModelId'
 *      ACModel: // value for 'ACModel'
 *   },
 * });
 */
export function useAcModelUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AcModelUpdateMutation, AcModelUpdateMutationVariables>
) {
  return ApolloReactHooks.useMutation<AcModelUpdateMutation, AcModelUpdateMutationVariables>(
    AcModelUpdateDocument,
    baseOptions
  );
}
export type AcModelUpdateMutationHookResult = ReturnType<typeof useAcModelUpdateMutation>;
export type AcModelUpdateMutationResult = ApolloReactCommon.MutationResult<AcModelUpdateMutation>;
export type AcModelUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AcModelUpdateMutation,
  AcModelUpdateMutationVariables
>;
export const AcModelDisableDocument = gql`
  mutation ACModelDisable($ACModelId: String!) {
    ACModelDisable(ACModelId: $ACModelId) {
      message
    }
  }
`;
export type AcModelDisableMutationFn = ApolloReactCommon.MutationFunction<
  AcModelDisableMutation,
  AcModelDisableMutationVariables
>;

/**
 * __useAcModelDisableMutation__
 *
 * To run a mutation, you first call `useAcModelDisableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcModelDisableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acModelDisableMutation, { data, loading, error }] = useAcModelDisableMutation({
 *   variables: {
 *      ACModelId: // value for 'ACModelId'
 *   },
 * });
 */
export function useAcModelDisableMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AcModelDisableMutation, AcModelDisableMutationVariables>
) {
  return ApolloReactHooks.useMutation<AcModelDisableMutation, AcModelDisableMutationVariables>(
    AcModelDisableDocument,
    baseOptions
  );
}
export type AcModelDisableMutationHookResult = ReturnType<typeof useAcModelDisableMutation>;
export type AcModelDisableMutationResult = ApolloReactCommon.MutationResult<AcModelDisableMutation>;
export type AcModelDisableMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AcModelDisableMutation,
  AcModelDisableMutationVariables
>;
export const GatewaysCreateDocument = gql`
  mutation gatewaysCreate($gateways: [GatewaysCreateInput!]!) {
    gatewaysCreate(gateways: $gateways) {
      gatewayId
      gatewayName
      gatewayMac
      locationId
    }
  }
`;
export type GatewaysCreateMutationFn = ApolloReactCommon.MutationFunction<
  GatewaysCreateMutation,
  GatewaysCreateMutationVariables
>;

/**
 * __useGatewaysCreateMutation__
 *
 * To run a mutation, you first call `useGatewaysCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGatewaysCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gatewaysCreateMutation, { data, loading, error }] = useGatewaysCreateMutation({
 *   variables: {
 *      gateways: // value for 'gateways'
 *   },
 * });
 */
export function useGatewaysCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<GatewaysCreateMutation, GatewaysCreateMutationVariables>
) {
  return ApolloReactHooks.useMutation<GatewaysCreateMutation, GatewaysCreateMutationVariables>(
    GatewaysCreateDocument,
    baseOptions
  );
}
export type GatewaysCreateMutationHookResult = ReturnType<typeof useGatewaysCreateMutation>;
export type GatewaysCreateMutationResult = ApolloReactCommon.MutationResult<GatewaysCreateMutation>;
export type GatewaysCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GatewaysCreateMutation,
  GatewaysCreateMutationVariables
>;
export const DeleteGatewayDocument = gql`
  mutation deleteGateway($gatewayId: ID!) {
    deleteGateway(gatewayId: $gatewayId) {
      gatewayId
    }
  }
`;
export type DeleteGatewayMutationFn = ApolloReactCommon.MutationFunction<
  DeleteGatewayMutation,
  DeleteGatewayMutationVariables
>;

/**
 * __useDeleteGatewayMutation__
 *
 * To run a mutation, you first call `useDeleteGatewayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGatewayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGatewayMutation, { data, loading, error }] = useDeleteGatewayMutation({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useDeleteGatewayMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGatewayMutation, DeleteGatewayMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteGatewayMutation, DeleteGatewayMutationVariables>(
    DeleteGatewayDocument,
    baseOptions
  );
}
export type DeleteGatewayMutationHookResult = ReturnType<typeof useDeleteGatewayMutation>;
export type DeleteGatewayMutationResult = ApolloReactCommon.MutationResult<DeleteGatewayMutation>;
export type DeleteGatewayMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteGatewayMutation,
  DeleteGatewayMutationVariables
>;
export const RenameGatewayDocument = gql`
  mutation renameGateway($gatewayId: ID!, $gatewayName: String!) {
    renameGateway(gatewayId: $gatewayId, gatewayName: $gatewayName) {
      gatewayId
      gatewayName
    }
  }
`;
export type RenameGatewayMutationFn = ApolloReactCommon.MutationFunction<
  RenameGatewayMutation,
  RenameGatewayMutationVariables
>;

/**
 * __useRenameGatewayMutation__
 *
 * To run a mutation, you first call `useRenameGatewayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameGatewayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameGatewayMutation, { data, loading, error }] = useRenameGatewayMutation({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *      gatewayName: // value for 'gatewayName'
 *   },
 * });
 */
export function useRenameGatewayMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RenameGatewayMutation, RenameGatewayMutationVariables>
) {
  return ApolloReactHooks.useMutation<RenameGatewayMutation, RenameGatewayMutationVariables>(
    RenameGatewayDocument,
    baseOptions
  );
}
export type RenameGatewayMutationHookResult = ReturnType<typeof useRenameGatewayMutation>;
export type RenameGatewayMutationResult = ApolloReactCommon.MutationResult<RenameGatewayMutation>;
export type RenameGatewayMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RenameGatewayMutation,
  RenameGatewayMutationVariables
>;
export const UpdateGatewayHardwareTypeDocument = gql`
  mutation updateGatewayHardwareType($gatewayId: ID!, $type: GatewayHardwareType!) {
    result: updateGatewayHardwareType(gatewayId: $gatewayId, type: $type) {
      gatewayId
      gatewayName
      hardwareType
    }
  }
`;
export type UpdateGatewayHardwareTypeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateGatewayHardwareTypeMutation,
  UpdateGatewayHardwareTypeMutationVariables
>;

/**
 * __useUpdateGatewayHardwareTypeMutation__
 *
 * To run a mutation, you first call `useUpdateGatewayHardwareTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGatewayHardwareTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGatewayHardwareTypeMutation, { data, loading, error }] = useUpdateGatewayHardwareTypeMutation({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateGatewayHardwareTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateGatewayHardwareTypeMutation,
    UpdateGatewayHardwareTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateGatewayHardwareTypeMutation, UpdateGatewayHardwareTypeMutationVariables>(
    UpdateGatewayHardwareTypeDocument,
    baseOptions
  );
}
export type UpdateGatewayHardwareTypeMutationHookResult = ReturnType<typeof useUpdateGatewayHardwareTypeMutation>;
export type UpdateGatewayHardwareTypeMutationResult = ApolloReactCommon.MutationResult<UpdateGatewayHardwareTypeMutation>;
export type UpdateGatewayHardwareTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateGatewayHardwareTypeMutation,
  UpdateGatewayHardwareTypeMutationVariables
>;
export const DeleteSlotDocument = gql`
  mutation deleteSlot($id: ID!) {
    deleteSlot(id: $id) {
      id
    }
  }
`;
export type DeleteSlotMutationFn = ApolloReactCommon.MutationFunction<DeleteSlotMutation, DeleteSlotMutationVariables>;

/**
 * __useDeleteSlotMutation__
 *
 * To run a mutation, you first call `useDeleteSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSlotMutation, { data, loading, error }] = useDeleteSlotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSlotMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSlotMutation, DeleteSlotMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteSlotMutation, DeleteSlotMutationVariables>(DeleteSlotDocument, baseOptions);
}
export type DeleteSlotMutationHookResult = ReturnType<typeof useDeleteSlotMutation>;
export type DeleteSlotMutationResult = ApolloReactCommon.MutationResult<DeleteSlotMutation>;
export type DeleteSlotMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteSlotMutation,
  DeleteSlotMutationVariables
>;
export const DeletePositionDocument = gql`
  mutation deletePosition($positionId: ID!) {
    deletePosition(positionId: $positionId) {
      positionId
    }
  }
`;
export type DeletePositionMutationFn = ApolloReactCommon.MutationFunction<
  DeletePositionMutation,
  DeletePositionMutationVariables
>;

/**
 * __useDeletePositionMutation__
 *
 * To run a mutation, you first call `useDeletePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePositionMutation, { data, loading, error }] = useDeletePositionMutation({
 *   variables: {
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useDeletePositionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePositionMutation, DeletePositionMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeletePositionMutation, DeletePositionMutationVariables>(
    DeletePositionDocument,
    baseOptions
  );
}
export type DeletePositionMutationHookResult = ReturnType<typeof useDeletePositionMutation>;
export type DeletePositionMutationResult = ApolloReactCommon.MutationResult<DeletePositionMutation>;
export type DeletePositionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePositionMutation,
  DeletePositionMutationVariables
>;
export const DeletePositionsDocument = gql`
  mutation deletePositions($positionIds: [ID!]!) {
    deletePositions(positionIds: $positionIds) {
      positionId
    }
  }
`;
export type DeletePositionsMutationFn = ApolloReactCommon.MutationFunction<
  DeletePositionsMutation,
  DeletePositionsMutationVariables
>;

/**
 * __useDeletePositionsMutation__
 *
 * To run a mutation, you first call `useDeletePositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePositionsMutation, { data, loading, error }] = useDeletePositionsMutation({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *   },
 * });
 */
export function useDeletePositionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePositionsMutation, DeletePositionsMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeletePositionsMutation, DeletePositionsMutationVariables>(
    DeletePositionsDocument,
    baseOptions
  );
}
export type DeletePositionsMutationHookResult = ReturnType<typeof useDeletePositionsMutation>;
export type DeletePositionsMutationResult = ApolloReactCommon.MutationResult<DeletePositionsMutation>;
export type DeletePositionsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePositionsMutation,
  DeletePositionsMutationVariables
>;
export const CommonRoomNamesDocument = gql`
  query commonRoomNames($keyIds: [ID!]!) {
    commonRoomNames(keyIds: $keyIds)
  }
`;

/**
 * __useCommonRoomNamesQuery__
 *
 * To run a query within a React component, call `useCommonRoomNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonRoomNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonRoomNamesQuery({
 *   variables: {
 *      keyIds: // value for 'keyIds'
 *   },
 * });
 */
export function useCommonRoomNamesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CommonRoomNamesQuery, CommonRoomNamesQueryVariables>
) {
  return ApolloReactHooks.useQuery<CommonRoomNamesQuery, CommonRoomNamesQueryVariables>(
    CommonRoomNamesDocument,
    baseOptions
  );
}
export function useCommonRoomNamesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommonRoomNamesQuery, CommonRoomNamesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CommonRoomNamesQuery, CommonRoomNamesQueryVariables>(
    CommonRoomNamesDocument,
    baseOptions
  );
}
export type CommonRoomNamesQueryHookResult = ReturnType<typeof useCommonRoomNamesQuery>;
export type CommonRoomNamesLazyQueryHookResult = ReturnType<typeof useCommonRoomNamesLazyQuery>;
export type CommonRoomNamesQueryResult = ApolloReactCommon.QueryResult<
  CommonRoomNamesQuery,
  CommonRoomNamesQueryVariables
>;
export const SetupTestDataDocument = gql`
  mutation setupTestData {
    setupTestData
  }
`;
export type SetupTestDataMutationFn = ApolloReactCommon.MutationFunction<
  SetupTestDataMutation,
  SetupTestDataMutationVariables
>;

/**
 * __useSetupTestDataMutation__
 *
 * To run a mutation, you first call `useSetupTestDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupTestDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupTestDataMutation, { data, loading, error }] = useSetupTestDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetupTestDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SetupTestDataMutation, SetupTestDataMutationVariables>
) {
  return ApolloReactHooks.useMutation<SetupTestDataMutation, SetupTestDataMutationVariables>(
    SetupTestDataDocument,
    baseOptions
  );
}
export type SetupTestDataMutationHookResult = ReturnType<typeof useSetupTestDataMutation>;
export type SetupTestDataMutationResult = ApolloReactCommon.MutationResult<SetupTestDataMutation>;
export type SetupTestDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetupTestDataMutation,
  SetupTestDataMutationVariables
>;
export const TeardownTestDataDocument = gql`
  mutation teardownTestData {
    teardownTestData
  }
`;
export type TeardownTestDataMutationFn = ApolloReactCommon.MutationFunction<
  TeardownTestDataMutation,
  TeardownTestDataMutationVariables
>;

/**
 * __useTeardownTestDataMutation__
 *
 * To run a mutation, you first call `useTeardownTestDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeardownTestDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teardownTestDataMutation, { data, loading, error }] = useTeardownTestDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useTeardownTestDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TeardownTestDataMutation, TeardownTestDataMutationVariables>
) {
  return ApolloReactHooks.useMutation<TeardownTestDataMutation, TeardownTestDataMutationVariables>(
    TeardownTestDataDocument,
    baseOptions
  );
}
export type TeardownTestDataMutationHookResult = ReturnType<typeof useTeardownTestDataMutation>;
export type TeardownTestDataMutationResult = ApolloReactCommon.MutationResult<TeardownTestDataMutation>;
export type TeardownTestDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TeardownTestDataMutation,
  TeardownTestDataMutationVariables
>;
export const ResetPositionConfigurationsDocument = gql`
  mutation resetPositionConfigurations($positionIds: [String!]!) {
    resetPositionConfigurations(positionIds: $positionIds) {
      positionId
    }
  }
`;
export type ResetPositionConfigurationsMutationFn = ApolloReactCommon.MutationFunction<
  ResetPositionConfigurationsMutation,
  ResetPositionConfigurationsMutationVariables
>;

/**
 * __useResetPositionConfigurationsMutation__
 *
 * To run a mutation, you first call `useResetPositionConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPositionConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPositionConfigurationsMutation, { data, loading, error }] = useResetPositionConfigurationsMutation({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *   },
 * });
 */
export function useResetPositionConfigurationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetPositionConfigurationsMutation,
    ResetPositionConfigurationsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResetPositionConfigurationsMutation,
    ResetPositionConfigurationsMutationVariables
  >(ResetPositionConfigurationsDocument, baseOptions);
}
export type ResetPositionConfigurationsMutationHookResult = ReturnType<typeof useResetPositionConfigurationsMutation>;
export type ResetPositionConfigurationsMutationResult = ApolloReactCommon.MutationResult<ResetPositionConfigurationsMutation>;
export type ResetPositionConfigurationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPositionConfigurationsMutation,
  ResetPositionConfigurationsMutationVariables
>;
export const UpdatePositionConfigurationsAutomationModeDocument = gql`
  mutation updatePositionConfigurationsAutomationMode(
    $positionIds: [String!]!
    $automationMode: String!
    $unoccupiedTemp: Int
    $heatingUnoccupiedTemp: Int
  ) {
    updatePositionConfigurationsAutomationMode(
      positionIds: $positionIds
      automationMode: $automationMode
      unoccupiedTemp: $unoccupiedTemp
      heatingUnoccupiedTemp: $heatingUnoccupiedTemp
    ) {
      positionId
      automationMode
      unoccupiedTemp
      heatingUnoccupiedTemp
    }
  }
`;
export type UpdatePositionConfigurationsAutomationModeMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePositionConfigurationsAutomationModeMutation,
  UpdatePositionConfigurationsAutomationModeMutationVariables
>;

/**
 * __useUpdatePositionConfigurationsAutomationModeMutation__
 *
 * To run a mutation, you first call `useUpdatePositionConfigurationsAutomationModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionConfigurationsAutomationModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionConfigurationsAutomationModeMutation, { data, loading, error }] = useUpdatePositionConfigurationsAutomationModeMutation({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *      automationMode: // value for 'automationMode'
 *      unoccupiedTemp: // value for 'unoccupiedTemp'
 *      heatingUnoccupiedTemp: // value for 'heatingUnoccupiedTemp'
 *   },
 * });
 */
export function useUpdatePositionConfigurationsAutomationModeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePositionConfigurationsAutomationModeMutation,
    UpdatePositionConfigurationsAutomationModeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePositionConfigurationsAutomationModeMutation,
    UpdatePositionConfigurationsAutomationModeMutationVariables
  >(UpdatePositionConfigurationsAutomationModeDocument, baseOptions);
}
export type UpdatePositionConfigurationsAutomationModeMutationHookResult = ReturnType<
  typeof useUpdatePositionConfigurationsAutomationModeMutation
>;
export type UpdatePositionConfigurationsAutomationModeMutationResult = ApolloReactCommon.MutationResult<UpdatePositionConfigurationsAutomationModeMutation>;
export type UpdatePositionConfigurationsAutomationModeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePositionConfigurationsAutomationModeMutation,
  UpdatePositionConfigurationsAutomationModeMutationVariables
>;
export const UpdatePositionConfigurationDocument = gql`
  mutation updatePositionConfiguration($positionConfiguration: PositionConfigurationInput!) {
    updatePositionConfiguration(positionConfiguration: $positionConfiguration) {
      positionId
      positionName
      parentPositionId
      thermostatOfflineStatus
    }
  }
`;
export type UpdatePositionConfigurationMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePositionConfigurationMutation,
  UpdatePositionConfigurationMutationVariables
>;

/**
 * __useUpdatePositionConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePositionConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionConfigurationMutation, { data, loading, error }] = useUpdatePositionConfigurationMutation({
 *   variables: {
 *      positionConfiguration: // value for 'positionConfiguration'
 *   },
 * });
 */
export function useUpdatePositionConfigurationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePositionConfigurationMutation,
    UpdatePositionConfigurationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePositionConfigurationMutation,
    UpdatePositionConfigurationMutationVariables
  >(UpdatePositionConfigurationDocument, baseOptions);
}
export type UpdatePositionConfigurationMutationHookResult = ReturnType<typeof useUpdatePositionConfigurationMutation>;
export type UpdatePositionConfigurationMutationResult = ApolloReactCommon.MutationResult<UpdatePositionConfigurationMutation>;
export type UpdatePositionConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePositionConfigurationMutation,
  UpdatePositionConfigurationMutationVariables
>;
export const UpdateDefaultPositionConfigurationDocument = gql`
  mutation updateDefaultPositionConfiguration($positionConfiguration: PositionConfigurationInput!) {
    updateDefaultPositionConfiguration(positionConfiguration: $positionConfiguration) {
      positionId
      positionName
      parentPositionId
      thermostatOfflineStatus
    }
  }
`;
export type UpdateDefaultPositionConfigurationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDefaultPositionConfigurationMutation,
  UpdateDefaultPositionConfigurationMutationVariables
>;

/**
 * __useUpdateDefaultPositionConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultPositionConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultPositionConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultPositionConfigurationMutation, { data, loading, error }] = useUpdateDefaultPositionConfigurationMutation({
 *   variables: {
 *      positionConfiguration: // value for 'positionConfiguration'
 *   },
 * });
 */
export function useUpdateDefaultPositionConfigurationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDefaultPositionConfigurationMutation,
    UpdateDefaultPositionConfigurationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateDefaultPositionConfigurationMutation,
    UpdateDefaultPositionConfigurationMutationVariables
  >(UpdateDefaultPositionConfigurationDocument, baseOptions);
}
export type UpdateDefaultPositionConfigurationMutationHookResult = ReturnType<
  typeof useUpdateDefaultPositionConfigurationMutation
>;
export type UpdateDefaultPositionConfigurationMutationResult = ApolloReactCommon.MutationResult<UpdateDefaultPositionConfigurationMutation>;
export type UpdateDefaultPositionConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDefaultPositionConfigurationMutation,
  UpdateDefaultPositionConfigurationMutationVariables
>;
export const BulkUpdatePositionConfigurationsDocument = gql`
  mutation bulkUpdatePositionConfigurations($positionConfigurations: [PositionConfigurationInput!]!) {
    bulkUpdatePositionConfigurations(positionConfigurations: $positionConfigurations) {
      positionId
      positionName
      parentPositionId
      thermostatOfflineStatus
    }
  }
`;
export type BulkUpdatePositionConfigurationsMutationFn = ApolloReactCommon.MutationFunction<
  BulkUpdatePositionConfigurationsMutation,
  BulkUpdatePositionConfigurationsMutationVariables
>;

/**
 * __useBulkUpdatePositionConfigurationsMutation__
 *
 * To run a mutation, you first call `useBulkUpdatePositionConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdatePositionConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdatePositionConfigurationsMutation, { data, loading, error }] = useBulkUpdatePositionConfigurationsMutation({
 *   variables: {
 *      positionConfigurations: // value for 'positionConfigurations'
 *   },
 * });
 */
export function useBulkUpdatePositionConfigurationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdatePositionConfigurationsMutation,
    BulkUpdatePositionConfigurationsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BulkUpdatePositionConfigurationsMutation,
    BulkUpdatePositionConfigurationsMutationVariables
  >(BulkUpdatePositionConfigurationsDocument, baseOptions);
}
export type BulkUpdatePositionConfigurationsMutationHookResult = ReturnType<
  typeof useBulkUpdatePositionConfigurationsMutation
>;
export type BulkUpdatePositionConfigurationsMutationResult = ApolloReactCommon.MutationResult<BulkUpdatePositionConfigurationsMutation>;
export type BulkUpdatePositionConfigurationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BulkUpdatePositionConfigurationsMutation,
  BulkUpdatePositionConfigurationsMutationVariables
>;
export const BulkUpdateDefaultPositionConfigurationsDocument = gql`
  mutation bulkUpdateDefaultPositionConfigurations($positionConfigurations: [PositionConfigurationInput!]!) {
    bulkUpdateDefaultPositionConfigurations(positionConfigurations: $positionConfigurations) {
      positionId
      positionName
      parentPositionId
      thermostatOfflineStatus
    }
  }
`;
export type BulkUpdateDefaultPositionConfigurationsMutationFn = ApolloReactCommon.MutationFunction<
  BulkUpdateDefaultPositionConfigurationsMutation,
  BulkUpdateDefaultPositionConfigurationsMutationVariables
>;

/**
 * __useBulkUpdateDefaultPositionConfigurationsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateDefaultPositionConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateDefaultPositionConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateDefaultPositionConfigurationsMutation, { data, loading, error }] = useBulkUpdateDefaultPositionConfigurationsMutation({
 *   variables: {
 *      positionConfigurations: // value for 'positionConfigurations'
 *   },
 * });
 */
export function useBulkUpdateDefaultPositionConfigurationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateDefaultPositionConfigurationsMutation,
    BulkUpdateDefaultPositionConfigurationsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BulkUpdateDefaultPositionConfigurationsMutation,
    BulkUpdateDefaultPositionConfigurationsMutationVariables
  >(BulkUpdateDefaultPositionConfigurationsDocument, baseOptions);
}
export type BulkUpdateDefaultPositionConfigurationsMutationHookResult = ReturnType<
  typeof useBulkUpdateDefaultPositionConfigurationsMutation
>;
export type BulkUpdateDefaultPositionConfigurationsMutationResult = ApolloReactCommon.MutationResult<BulkUpdateDefaultPositionConfigurationsMutation>;
export type BulkUpdateDefaultPositionConfigurationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BulkUpdateDefaultPositionConfigurationsMutation,
  BulkUpdateDefaultPositionConfigurationsMutationVariables
>;
export const DeleteInfraDocument = gql`
  mutation deleteInfra($infraId: ID!) {
    deleteInfra(infraId: $infraId)
  }
`;
export type DeleteInfraMutationFn = ApolloReactCommon.MutationFunction<
  DeleteInfraMutation,
  DeleteInfraMutationVariables
>;

/**
 * __useDeleteInfraMutation__
 *
 * To run a mutation, you first call `useDeleteInfraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInfraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInfraMutation, { data, loading, error }] = useDeleteInfraMutation({
 *   variables: {
 *      infraId: // value for 'infraId'
 *   },
 * });
 */
export function useDeleteInfraMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInfraMutation, DeleteInfraMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteInfraMutation, DeleteInfraMutationVariables>(
    DeleteInfraDocument,
    baseOptions
  );
}
export type DeleteInfraMutationHookResult = ReturnType<typeof useDeleteInfraMutation>;
export type DeleteInfraMutationResult = ApolloReactCommon.MutationResult<DeleteInfraMutation>;
export type DeleteInfraMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteInfraMutation,
  DeleteInfraMutationVariables
>;
export const KeysWithEnergyConsumptionDocument = gql`
  query keysWithEnergyConsumption(
    $filter: KeyFilterInput!
    $fromDate: String!
    $toDate: String!
    $pagination: GenericPaginationInput
  ) {
    keys(filter: $filter, pagination: $pagination) {
      keys {
        keyId
        keyName
        rooms {
          roomId
          roomName
          energyConsumption(fromDate: $fromDate, toDate: $toDate)
        }
      }
      pagination {
        count
        limit
        offset
      }
    }
  }
`;

/**
 * __useKeysWithEnergyConsumptionQuery__
 *
 * To run a query within a React component, call `useKeysWithEnergyConsumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeysWithEnergyConsumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeysWithEnergyConsumptionQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useKeysWithEnergyConsumptionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    KeysWithEnergyConsumptionQuery,
    KeysWithEnergyConsumptionQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<KeysWithEnergyConsumptionQuery, KeysWithEnergyConsumptionQueryVariables>(
    KeysWithEnergyConsumptionDocument,
    baseOptions
  );
}
export function useKeysWithEnergyConsumptionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    KeysWithEnergyConsumptionQuery,
    KeysWithEnergyConsumptionQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<KeysWithEnergyConsumptionQuery, KeysWithEnergyConsumptionQueryVariables>(
    KeysWithEnergyConsumptionDocument,
    baseOptions
  );
}
export type KeysWithEnergyConsumptionQueryHookResult = ReturnType<typeof useKeysWithEnergyConsumptionQuery>;
export type KeysWithEnergyConsumptionLazyQueryHookResult = ReturnType<typeof useKeysWithEnergyConsumptionLazyQuery>;
export type KeysWithEnergyConsumptionQueryResult = ApolloReactCommon.QueryResult<
  KeysWithEnergyConsumptionQuery,
  KeysWithEnergyConsumptionQueryVariables
>;
export const TriggerToUploadGatewayImagesDocument = gql`
  mutation triggerToUploadGatewayImages($gatewayIds: [String!]!) {
    triggerToUploadGatewayImages(gatewayIds: $gatewayIds)
  }
`;
export type TriggerToUploadGatewayImagesMutationFn = ApolloReactCommon.MutationFunction<
  TriggerToUploadGatewayImagesMutation,
  TriggerToUploadGatewayImagesMutationVariables
>;

/**
 * __useTriggerToUploadGatewayImagesMutation__
 *
 * To run a mutation, you first call `useTriggerToUploadGatewayImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerToUploadGatewayImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerToUploadGatewayImagesMutation, { data, loading, error }] = useTriggerToUploadGatewayImagesMutation({
 *   variables: {
 *      gatewayIds: // value for 'gatewayIds'
 *   },
 * });
 */
export function useTriggerToUploadGatewayImagesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TriggerToUploadGatewayImagesMutation,
    TriggerToUploadGatewayImagesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    TriggerToUploadGatewayImagesMutation,
    TriggerToUploadGatewayImagesMutationVariables
  >(TriggerToUploadGatewayImagesDocument, baseOptions);
}
export type TriggerToUploadGatewayImagesMutationHookResult = ReturnType<typeof useTriggerToUploadGatewayImagesMutation>;
export type TriggerToUploadGatewayImagesMutationResult = ApolloReactCommon.MutationResult<TriggerToUploadGatewayImagesMutation>;
export type TriggerToUploadGatewayImagesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TriggerToUploadGatewayImagesMutation,
  TriggerToUploadGatewayImagesMutationVariables
>;
export const FirmwareUploadLinkDocument = gql`
  query firmwareUploadLink($fileName: String!) {
    firmwareUploadLink(fileName: $fileName)
  }
`;

/**
 * __useFirmwareUploadLinkQuery__
 *
 * To run a query within a React component, call `useFirmwareUploadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirmwareUploadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmwareUploadLinkQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useFirmwareUploadLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FirmwareUploadLinkQuery, FirmwareUploadLinkQueryVariables>
) {
  return ApolloReactHooks.useQuery<FirmwareUploadLinkQuery, FirmwareUploadLinkQueryVariables>(
    FirmwareUploadLinkDocument,
    baseOptions
  );
}
export function useFirmwareUploadLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FirmwareUploadLinkQuery, FirmwareUploadLinkQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<FirmwareUploadLinkQuery, FirmwareUploadLinkQueryVariables>(
    FirmwareUploadLinkDocument,
    baseOptions
  );
}
export type FirmwareUploadLinkQueryHookResult = ReturnType<typeof useFirmwareUploadLinkQuery>;
export type FirmwareUploadLinkLazyQueryHookResult = ReturnType<typeof useFirmwareUploadLinkLazyQuery>;
export type FirmwareUploadLinkQueryResult = ApolloReactCommon.QueryResult<
  FirmwareUploadLinkQuery,
  FirmwareUploadLinkQueryVariables
>;
export const LocationsAssignedToUserForAutocompleteDocument = gql`
  query locationsAssignedToUserForAutocomplete($filter: LocationFilterInput) {
    locationsAssignedToUser(filter: $filter, pagination: { offset: 0, limit: 1000 }) {
      locations {
        id
        locationName
      }
    }
  }
`;

/**
 * __useLocationsAssignedToUserForAutocompleteQuery__
 *
 * To run a query within a React component, call `useLocationsAssignedToUserForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsAssignedToUserForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsAssignedToUserForAutocompleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLocationsAssignedToUserForAutocompleteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationsAssignedToUserForAutocompleteQuery,
    LocationsAssignedToUserForAutocompleteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LocationsAssignedToUserForAutocompleteQuery,
    LocationsAssignedToUserForAutocompleteQueryVariables
  >(LocationsAssignedToUserForAutocompleteDocument, baseOptions);
}
export function useLocationsAssignedToUserForAutocompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationsAssignedToUserForAutocompleteQuery,
    LocationsAssignedToUserForAutocompleteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LocationsAssignedToUserForAutocompleteQuery,
    LocationsAssignedToUserForAutocompleteQueryVariables
  >(LocationsAssignedToUserForAutocompleteDocument, baseOptions);
}
export type LocationsAssignedToUserForAutocompleteQueryHookResult = ReturnType<
  typeof useLocationsAssignedToUserForAutocompleteQuery
>;
export type LocationsAssignedToUserForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useLocationsAssignedToUserForAutocompleteLazyQuery
>;
export type LocationsAssignedToUserForAutocompleteQueryResult = ApolloReactCommon.QueryResult<
  LocationsAssignedToUserForAutocompleteQuery,
  LocationsAssignedToUserForAutocompleteQueryVariables
>;
export const CreateInfraDocument = gql`
  mutation createInfra(
    $locationId: ID!
    $name: String!
    $type: String!
    $numberOfPhases: Int
    $hvacSystemType: String
    $compressorId: ID
    $ctMultiplier: Float
  ) {
    createInfra(
      locationId: $locationId
      name: $name
      type: $type
      numberOfPhases: $numberOfPhases
      hvacSystemType: $hvacSystemType
      compressorId: $compressorId
      ctMultiplier: $ctMultiplier
    ) {
      id
      name
    }
  }
`;
export type CreateInfraMutationFn = ApolloReactCommon.MutationFunction<
  CreateInfraMutation,
  CreateInfraMutationVariables
>;

/**
 * __useCreateInfraMutation__
 *
 * To run a mutation, you first call `useCreateInfraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInfraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInfraMutation, { data, loading, error }] = useCreateInfraMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      numberOfPhases: // value for 'numberOfPhases'
 *      hvacSystemType: // value for 'hvacSystemType'
 *      compressorId: // value for 'compressorId'
 *      ctMultiplier: // value for 'ctMultiplier'
 *   },
 * });
 */
export function useCreateInfraMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInfraMutation, CreateInfraMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateInfraMutation, CreateInfraMutationVariables>(
    CreateInfraDocument,
    baseOptions
  );
}
export type CreateInfraMutationHookResult = ReturnType<typeof useCreateInfraMutation>;
export type CreateInfraMutationResult = ApolloReactCommon.MutationResult<CreateInfraMutation>;
export type CreateInfraMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateInfraMutation,
  CreateInfraMutationVariables
>;
export const CreateRoomCompressorsDocument = gql`
  mutation createRoomCompressors($compressorsInput: [RoomCompressorCreateInput!]!) {
    createRoomCompressors(compressors: $compressorsInput) {
      compressorId
      compressorName
    }
  }
`;
export type CreateRoomCompressorsMutationFn = ApolloReactCommon.MutationFunction<
  CreateRoomCompressorsMutation,
  CreateRoomCompressorsMutationVariables
>;

/**
 * __useCreateRoomCompressorsMutation__
 *
 * To run a mutation, you first call `useCreateRoomCompressorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomCompressorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomCompressorsMutation, { data, loading, error }] = useCreateRoomCompressorsMutation({
 *   variables: {
 *      compressorsInput: // value for 'compressorsInput'
 *   },
 * });
 */
export function useCreateRoomCompressorsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRoomCompressorsMutation,
    CreateRoomCompressorsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateRoomCompressorsMutation, CreateRoomCompressorsMutationVariables>(
    CreateRoomCompressorsDocument,
    baseOptions
  );
}
export type CreateRoomCompressorsMutationHookResult = ReturnType<typeof useCreateRoomCompressorsMutation>;
export type CreateRoomCompressorsMutationResult = ApolloReactCommon.MutationResult<CreateRoomCompressorsMutation>;
export type CreateRoomCompressorsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateRoomCompressorsMutation,
  CreateRoomCompressorsMutationVariables
>;
export const CreateSlotsDocument = gql`
  mutation createSlots($slotsInput: [SlotCreateInput!]!) {
    createSlots(slots: $slotsInput) {
      id
      nodeType
      nodeSubType
      slotName
    }
  }
`;
export type CreateSlotsMutationFn = ApolloReactCommon.MutationFunction<
  CreateSlotsMutation,
  CreateSlotsMutationVariables
>;

/**
 * __useCreateSlotsMutation__
 *
 * To run a mutation, you first call `useCreateSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlotsMutation, { data, loading, error }] = useCreateSlotsMutation({
 *   variables: {
 *      slotsInput: // value for 'slotsInput'
 *   },
 * });
 */
export function useCreateSlotsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSlotsMutation, CreateSlotsMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateSlotsMutation, CreateSlotsMutationVariables>(
    CreateSlotsDocument,
    baseOptions
  );
}
export type CreateSlotsMutationHookResult = ReturnType<typeof useCreateSlotsMutation>;
export type CreateSlotsMutationResult = ApolloReactCommon.MutationResult<CreateSlotsMutation>;
export type CreateSlotsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSlotsMutation,
  CreateSlotsMutationVariables
>;
export const GetCoolingSystemDocument = gql`
  query getCoolingSystem($locationId: uuid!) {
    infrastructures(
      where: { position: { locationId: { _eq: $locationId } }, hvacSystemType: { _eq: "CHILLED_WATER" } }
    ) {
      name
      id
      positionId
    }
  }
`;

/**
 * __useGetCoolingSystemQuery__
 *
 * To run a query within a React component, call `useGetCoolingSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoolingSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoolingSystemQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetCoolingSystemQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoolingSystemQuery, GetCoolingSystemQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetCoolingSystemQuery, GetCoolingSystemQueryVariables>(
    GetCoolingSystemDocument,
    baseOptions
  );
}
export function useGetCoolingSystemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoolingSystemQuery, GetCoolingSystemQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetCoolingSystemQuery, GetCoolingSystemQueryVariables>(
    GetCoolingSystemDocument,
    baseOptions
  );
}
export type GetCoolingSystemQueryHookResult = ReturnType<typeof useGetCoolingSystemQuery>;
export type GetCoolingSystemLazyQueryHookResult = ReturnType<typeof useGetCoolingSystemLazyQuery>;
export type GetCoolingSystemQueryResult = ApolloReactCommon.QueryResult<
  GetCoolingSystemQuery,
  GetCoolingSystemQueryVariables
>;
export const ChangeInfraTypeDocument = gql`
  mutation changeInfraType(
    $infraId: ID!
    $oldType: String!
    $newType: String!
    $hvacSystemType: String
    $coolingSystemId: String
  ) {
    changeInfraType(
      infraId: $infraId
      oldType: $oldType
      newType: $newType
      hvacSystemType: $hvacSystemType
      coolingSystemId: $coolingSystemId
    ) {
      name
      id
    }
  }
`;
export type ChangeInfraTypeMutationFn = ApolloReactCommon.MutationFunction<
  ChangeInfraTypeMutation,
  ChangeInfraTypeMutationVariables
>;

/**
 * __useChangeInfraTypeMutation__
 *
 * To run a mutation, you first call `useChangeInfraTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeInfraTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeInfraTypeMutation, { data, loading, error }] = useChangeInfraTypeMutation({
 *   variables: {
 *      infraId: // value for 'infraId'
 *      oldType: // value for 'oldType'
 *      newType: // value for 'newType'
 *      hvacSystemType: // value for 'hvacSystemType'
 *      coolingSystemId: // value for 'coolingSystemId'
 *   },
 * });
 */
export function useChangeInfraTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeInfraTypeMutation, ChangeInfraTypeMutationVariables>
) {
  return ApolloReactHooks.useMutation<ChangeInfraTypeMutation, ChangeInfraTypeMutationVariables>(
    ChangeInfraTypeDocument,
    baseOptions
  );
}
export type ChangeInfraTypeMutationHookResult = ReturnType<typeof useChangeInfraTypeMutation>;
export type ChangeInfraTypeMutationResult = ApolloReactCommon.MutationResult<ChangeInfraTypeMutation>;
export type ChangeInfraTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeInfraTypeMutation,
  ChangeInfraTypeMutationVariables
>;
export const GetNodeMappedPreviouslyDocument = gql`
  query getNodeMappedPreviously($infraId: ID!) {
    getNodeMappedPreviously(infraId: $infraId) {
      slotName
      phaseStreamIndex
      nodeType
      nodeMappedPreviously
      desiredEmonSwitchPosition
    }
  }
`;

/**
 * __useGetNodeMappedPreviouslyQuery__
 *
 * To run a query within a React component, call `useGetNodeMappedPreviouslyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeMappedPreviouslyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeMappedPreviouslyQuery({
 *   variables: {
 *      infraId: // value for 'infraId'
 *   },
 * });
 */
export function useGetNodeMappedPreviouslyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetNodeMappedPreviouslyQuery, GetNodeMappedPreviouslyQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetNodeMappedPreviouslyQuery, GetNodeMappedPreviouslyQueryVariables>(
    GetNodeMappedPreviouslyDocument,
    baseOptions
  );
}
export function useGetNodeMappedPreviouslyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNodeMappedPreviouslyQuery,
    GetNodeMappedPreviouslyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetNodeMappedPreviouslyQuery, GetNodeMappedPreviouslyQueryVariables>(
    GetNodeMappedPreviouslyDocument,
    baseOptions
  );
}
export type GetNodeMappedPreviouslyQueryHookResult = ReturnType<typeof useGetNodeMappedPreviouslyQuery>;
export type GetNodeMappedPreviouslyLazyQueryHookResult = ReturnType<typeof useGetNodeMappedPreviouslyLazyQuery>;
export type GetNodeMappedPreviouslyQueryResult = ApolloReactCommon.QueryResult<
  GetNodeMappedPreviouslyQuery,
  GetNodeMappedPreviouslyQueryVariables
>;
export const EndKeyEntryDocument = gql`
  mutation endKeyEntry($keyEntryId: Int!, $status: String, $comment: String, $commentStatus: String) {
    exitInstallationMode(keyEntryId: $keyEntryId, status: $status, comment: $comment, commentStatus: $commentStatus) {
      keyEntryId
      keyPositionId
      startedAt
      userId
      endedAt
      positionFunction
      isResumedEntry
      lastNotifyAt
    }
  }
`;
export type EndKeyEntryMutationFn = ApolloReactCommon.MutationFunction<
  EndKeyEntryMutation,
  EndKeyEntryMutationVariables
>;

/**
 * __useEndKeyEntryMutation__
 *
 * To run a mutation, you first call `useEndKeyEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndKeyEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endKeyEntryMutation, { data, loading, error }] = useEndKeyEntryMutation({
 *   variables: {
 *      keyEntryId: // value for 'keyEntryId'
 *      status: // value for 'status'
 *      comment: // value for 'comment'
 *      commentStatus: // value for 'commentStatus'
 *   },
 * });
 */
export function useEndKeyEntryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<EndKeyEntryMutation, EndKeyEntryMutationVariables>
) {
  return ApolloReactHooks.useMutation<EndKeyEntryMutation, EndKeyEntryMutationVariables>(
    EndKeyEntryDocument,
    baseOptions
  );
}
export type EndKeyEntryMutationHookResult = ReturnType<typeof useEndKeyEntryMutation>;
export type EndKeyEntryMutationResult = ApolloReactCommon.MutationResult<EndKeyEntryMutation>;
export type EndKeyEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EndKeyEntryMutation,
  EndKeyEntryMutationVariables
>;
export const CreateRoomDocument = gql`
  mutation createRoom(
    $positionName: String!
    $locationId: ID!
    $parentPositionId: ID!
    $slots: [SlotCreateRoomInput]
    $acModelId: String
    $isDeviatedFromDefaultConfig: Boolean
  ) {
    createRoom(
      positionName: $positionName
      locationId: $locationId
      parentPositionId: $parentPositionId
      slots: $slots
      acModelId: $acModelId
      isDeviatedFromDefaultConfig: $isDeviatedFromDefaultConfig
    ) {
      positionName
      positionId
      locationId
    }
  }
`;
export type CreateRoomMutationFn = ApolloReactCommon.MutationFunction<CreateRoomMutation, CreateRoomMutationVariables>;

/**
 * __useCreateRoomMutation__
 *
 * To run a mutation, you first call `useCreateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomMutation, { data, loading, error }] = useCreateRoomMutation({
 *   variables: {
 *      positionName: // value for 'positionName'
 *      locationId: // value for 'locationId'
 *      parentPositionId: // value for 'parentPositionId'
 *      slots: // value for 'slots'
 *      acModelId: // value for 'acModelId'
 *      isDeviatedFromDefaultConfig: // value for 'isDeviatedFromDefaultConfig'
 *   },
 * });
 */
export function useCreateRoomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRoomMutation, CreateRoomMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateRoomMutation, CreateRoomMutationVariables>(CreateRoomDocument, baseOptions);
}
export type CreateRoomMutationHookResult = ReturnType<typeof useCreateRoomMutation>;
export type CreateRoomMutationResult = ApolloReactCommon.MutationResult<CreateRoomMutation>;
export type CreateRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateRoomMutation,
  CreateRoomMutationVariables
>;
export const CreateNodeSpareSetDocument = gql`
  mutation createNodeSpareSet($locationId: ID!, $nodeMacId: String!, $nodeType: NodeType!, $nodeSubType: NodeSubType) {
    createNodeSpareSet(locationId: $locationId, nodeMacId: $nodeMacId, nodeType: $nodeType, nodeSubType: $nodeSubType) {
      nodeMacId
      locationId
      mappedAt
      consumedAt
    }
  }
`;
export type CreateNodeSpareSetMutationFn = ApolloReactCommon.MutationFunction<
  CreateNodeSpareSetMutation,
  CreateNodeSpareSetMutationVariables
>;

/**
 * __useCreateNodeSpareSetMutation__
 *
 * To run a mutation, you first call `useCreateNodeSpareSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNodeSpareSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNodeSpareSetMutation, { data, loading, error }] = useCreateNodeSpareSetMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      nodeMacId: // value for 'nodeMacId'
 *      nodeType: // value for 'nodeType'
 *      nodeSubType: // value for 'nodeSubType'
 *   },
 * });
 */
export function useCreateNodeSpareSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNodeSpareSetMutation, CreateNodeSpareSetMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateNodeSpareSetMutation, CreateNodeSpareSetMutationVariables>(
    CreateNodeSpareSetDocument,
    baseOptions
  );
}
export type CreateNodeSpareSetMutationHookResult = ReturnType<typeof useCreateNodeSpareSetMutation>;
export type CreateNodeSpareSetMutationResult = ApolloReactCommon.MutationResult<CreateNodeSpareSetMutation>;
export type CreateNodeSpareSetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateNodeSpareSetMutation,
  CreateNodeSpareSetMutationVariables
>;
export const RemoveNodeSpareSetDocument = gql`
  mutation removeNodeSpareSet($nodeMacId: String!) {
    removeNodeSpareSet(nodeMacId: $nodeMacId)
  }
`;
export type RemoveNodeSpareSetMutationFn = ApolloReactCommon.MutationFunction<
  RemoveNodeSpareSetMutation,
  RemoveNodeSpareSetMutationVariables
>;

/**
 * __useRemoveNodeSpareSetMutation__
 *
 * To run a mutation, you first call `useRemoveNodeSpareSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNodeSpareSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNodeSpareSetMutation, { data, loading, error }] = useRemoveNodeSpareSetMutation({
 *   variables: {
 *      nodeMacId: // value for 'nodeMacId'
 *   },
 * });
 */
export function useRemoveNodeSpareSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveNodeSpareSetMutation, RemoveNodeSpareSetMutationVariables>
) {
  return ApolloReactHooks.useMutation<RemoveNodeSpareSetMutation, RemoveNodeSpareSetMutationVariables>(
    RemoveNodeSpareSetDocument,
    baseOptions
  );
}
export type RemoveNodeSpareSetMutationHookResult = ReturnType<typeof useRemoveNodeSpareSetMutation>;
export type RemoveNodeSpareSetMutationResult = ApolloReactCommon.MutationResult<RemoveNodeSpareSetMutation>;
export type RemoveNodeSpareSetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveNodeSpareSetMutation,
  RemoveNodeSpareSetMutationVariables
>;
export const StartKeyEntryDocument = gql`
  mutation startKeyEntry(
    $keyPositionId: ID!
    $userId: ID!
    $positionFunction: String!
    $isResumedEntry: Boolean!
    $automaticTestRun: Boolean
    $isMeterPosition: Boolean
  ) {
    startInstallationMode(
      keyPositionId: $keyPositionId
      userId: $userId
      positionFunction: $positionFunction
      isResumedEntry: $isResumedEntry
      automaticTestRun: $automaticTestRun
      isMeterPosition: $isMeterPosition
    ) {
      keyEntryId
      keyPositionId
      startedAt
      userId
      endedAt
      positionFunction
      isResumedEntry
      lastNotifyAt
    }
  }
`;
export type StartKeyEntryMutationFn = ApolloReactCommon.MutationFunction<
  StartKeyEntryMutation,
  StartKeyEntryMutationVariables
>;

/**
 * __useStartKeyEntryMutation__
 *
 * To run a mutation, you first call `useStartKeyEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartKeyEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startKeyEntryMutation, { data, loading, error }] = useStartKeyEntryMutation({
 *   variables: {
 *      keyPositionId: // value for 'keyPositionId'
 *      userId: // value for 'userId'
 *      positionFunction: // value for 'positionFunction'
 *      isResumedEntry: // value for 'isResumedEntry'
 *      automaticTestRun: // value for 'automaticTestRun'
 *      isMeterPosition: // value for 'isMeterPosition'
 *   },
 * });
 */
export function useStartKeyEntryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<StartKeyEntryMutation, StartKeyEntryMutationVariables>
) {
  return ApolloReactHooks.useMutation<StartKeyEntryMutation, StartKeyEntryMutationVariables>(
    StartKeyEntryDocument,
    baseOptions
  );
}
export type StartKeyEntryMutationHookResult = ReturnType<typeof useStartKeyEntryMutation>;
export type StartKeyEntryMutationResult = ApolloReactCommon.MutationResult<StartKeyEntryMutation>;
export type StartKeyEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StartKeyEntryMutation,
  StartKeyEntryMutationVariables
>;
export const ClaimKeyDocument = gql`
  mutation claimKey($keyPositionId: ID!, $userId: ID!, $positionFunction: String!, $isMeterPosition: Boolean) {
    claimKey(
      keyPositionId: $keyPositionId
      userId: $userId
      positionFunction: $positionFunction
      isMeterPosition: $isMeterPosition
    ) {
      keyEntryId
      keyPositionId
      startedAt
      userId
      endedAt
      positionFunction
      isResumedEntry
      lastNotifyAt
    }
  }
`;
export type ClaimKeyMutationFn = ApolloReactCommon.MutationFunction<ClaimKeyMutation, ClaimKeyMutationVariables>;

/**
 * __useClaimKeyMutation__
 *
 * To run a mutation, you first call `useClaimKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimKeyMutation, { data, loading, error }] = useClaimKeyMutation({
 *   variables: {
 *      keyPositionId: // value for 'keyPositionId'
 *      userId: // value for 'userId'
 *      positionFunction: // value for 'positionFunction'
 *      isMeterPosition: // value for 'isMeterPosition'
 *   },
 * });
 */
export function useClaimKeyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimKeyMutation, ClaimKeyMutationVariables>
) {
  return ApolloReactHooks.useMutation<ClaimKeyMutation, ClaimKeyMutationVariables>(ClaimKeyDocument, baseOptions);
}
export type ClaimKeyMutationHookResult = ReturnType<typeof useClaimKeyMutation>;
export type ClaimKeyMutationResult = ApolloReactCommon.MutationResult<ClaimKeyMutation>;
export type ClaimKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ClaimKeyMutation,
  ClaimKeyMutationVariables
>;
export const UnClaimKeyDocument = gql`
  mutation unClaimKey($keyPositionId: ID!, $userId: ID!) {
    unClaimKey(keyPositionId: $keyPositionId, userId: $userId) {
      keyEntryId
      keyPositionId
      startedAt
      userId
      endedAt
      positionFunction
      isResumedEntry
      lastNotifyAt
    }
  }
`;
export type UnClaimKeyMutationFn = ApolloReactCommon.MutationFunction<UnClaimKeyMutation, UnClaimKeyMutationVariables>;

/**
 * __useUnClaimKeyMutation__
 *
 * To run a mutation, you first call `useUnClaimKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnClaimKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unClaimKeyMutation, { data, loading, error }] = useUnClaimKeyMutation({
 *   variables: {
 *      keyPositionId: // value for 'keyPositionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnClaimKeyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UnClaimKeyMutation, UnClaimKeyMutationVariables>
) {
  return ApolloReactHooks.useMutation<UnClaimKeyMutation, UnClaimKeyMutationVariables>(UnClaimKeyDocument, baseOptions);
}
export type UnClaimKeyMutationHookResult = ReturnType<typeof useUnClaimKeyMutation>;
export type UnClaimKeyMutationResult = ApolloReactCommon.MutationResult<UnClaimKeyMutation>;
export type UnClaimKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnClaimKeyMutation,
  UnClaimKeyMutationVariables
>;
export const SwapChannelsDocument = gql`
  mutation swapChannels($startingCompressorId: ID!, $destinationCompressorId: ID!) {
    swapChannels(startingCompressorId: $startingCompressorId, destinationCompressorId: $destinationCompressorId)
  }
`;
export type SwapChannelsMutationFn = ApolloReactCommon.MutationFunction<
  SwapChannelsMutation,
  SwapChannelsMutationVariables
>;

/**
 * __useSwapChannelsMutation__
 *
 * To run a mutation, you first call `useSwapChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwapChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swapChannelsMutation, { data, loading, error }] = useSwapChannelsMutation({
 *   variables: {
 *      startingCompressorId: // value for 'startingCompressorId'
 *      destinationCompressorId: // value for 'destinationCompressorId'
 *   },
 * });
 */
export function useSwapChannelsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SwapChannelsMutation, SwapChannelsMutationVariables>
) {
  return ApolloReactHooks.useMutation<SwapChannelsMutation, SwapChannelsMutationVariables>(
    SwapChannelsDocument,
    baseOptions
  );
}
export type SwapChannelsMutationHookResult = ReturnType<typeof useSwapChannelsMutation>;
export type SwapChannelsMutationResult = ApolloReactCommon.MutationResult<SwapChannelsMutation>;
export type SwapChannelsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SwapChannelsMutation,
  SwapChannelsMutationVariables
>;
export const CancelTimerAndResetToDefaultConfigDocument = gql`
  mutation cancelTimerAndResetToDefaultConfig($roomIds: [ID!]!, $userId: ID) {
    cancelTimerAndResetToDefaultConfig(roomIds: $roomIds, userId: $userId) {
      positionId
    }
  }
`;
export type CancelTimerAndResetToDefaultConfigMutationFn = ApolloReactCommon.MutationFunction<
  CancelTimerAndResetToDefaultConfigMutation,
  CancelTimerAndResetToDefaultConfigMutationVariables
>;

/**
 * __useCancelTimerAndResetToDefaultConfigMutation__
 *
 * To run a mutation, you first call `useCancelTimerAndResetToDefaultConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTimerAndResetToDefaultConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTimerAndResetToDefaultConfigMutation, { data, loading, error }] = useCancelTimerAndResetToDefaultConfigMutation({
 *   variables: {
 *      roomIds: // value for 'roomIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCancelTimerAndResetToDefaultConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelTimerAndResetToDefaultConfigMutation,
    CancelTimerAndResetToDefaultConfigMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CancelTimerAndResetToDefaultConfigMutation,
    CancelTimerAndResetToDefaultConfigMutationVariables
  >(CancelTimerAndResetToDefaultConfigDocument, baseOptions);
}
export type CancelTimerAndResetToDefaultConfigMutationHookResult = ReturnType<
  typeof useCancelTimerAndResetToDefaultConfigMutation
>;
export type CancelTimerAndResetToDefaultConfigMutationResult = ApolloReactCommon.MutationResult<CancelTimerAndResetToDefaultConfigMutation>;
export type CancelTimerAndResetToDefaultConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelTimerAndResetToDefaultConfigMutation,
  CancelTimerAndResetToDefaultConfigMutationVariables
>;
export const CancelPrecoolCommandDocument = gql`
  mutation cancelPrecoolCommand($roomIds: [ID!]!) {
    cancelPrecoolCommand(roomIds: $roomIds) {
      canceledCommands {
        id
      }
      uncancelableRooms {
        roomId
        roomName
      }
    }
  }
`;
export type CancelPrecoolCommandMutationFn = ApolloReactCommon.MutationFunction<
  CancelPrecoolCommandMutation,
  CancelPrecoolCommandMutationVariables
>;

/**
 * __useCancelPrecoolCommandMutation__
 *
 * To run a mutation, you first call `useCancelPrecoolCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPrecoolCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPrecoolCommandMutation, { data, loading, error }] = useCancelPrecoolCommandMutation({
 *   variables: {
 *      roomIds: // value for 'roomIds'
 *   },
 * });
 */
export function useCancelPrecoolCommandMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelPrecoolCommandMutation,
    CancelPrecoolCommandMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CancelPrecoolCommandMutation, CancelPrecoolCommandMutationVariables>(
    CancelPrecoolCommandDocument,
    baseOptions
  );
}
export type CancelPrecoolCommandMutationHookResult = ReturnType<typeof useCancelPrecoolCommandMutation>;
export type CancelPrecoolCommandMutationResult = ApolloReactCommon.MutationResult<CancelPrecoolCommandMutation>;
export type CancelPrecoolCommandMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelPrecoolCommandMutation,
  CancelPrecoolCommandMutationVariables
>;
export const AddPrecoolCommandsDocument = gql`
  mutation addPrecoolCommands(
    $positionIds: [ID!]!
    $temperature: Int!
    $totalMinutes: Int!
    $mode: Int!
    $startedAt: String
  ) {
    addPrecoolCommands(
      positionIds: $positionIds
      temperature: $temperature
      totalMinutes: $totalMinutes
      mode: $mode
      startedAt: $startedAt
    ) {
      keyId
      keyName
      rooms {
        roomId
        roomName
      }
    }
  }
`;
export type AddPrecoolCommandsMutationFn = ApolloReactCommon.MutationFunction<
  AddPrecoolCommandsMutation,
  AddPrecoolCommandsMutationVariables
>;

/**
 * __useAddPrecoolCommandsMutation__
 *
 * To run a mutation, you first call `useAddPrecoolCommandsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPrecoolCommandsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPrecoolCommandsMutation, { data, loading, error }] = useAddPrecoolCommandsMutation({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *      temperature: // value for 'temperature'
 *      totalMinutes: // value for 'totalMinutes'
 *      mode: // value for 'mode'
 *      startedAt: // value for 'startedAt'
 *   },
 * });
 */
export function useAddPrecoolCommandsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddPrecoolCommandsMutation, AddPrecoolCommandsMutationVariables>
) {
  return ApolloReactHooks.useMutation<AddPrecoolCommandsMutation, AddPrecoolCommandsMutationVariables>(
    AddPrecoolCommandsDocument,
    baseOptions
  );
}
export type AddPrecoolCommandsMutationHookResult = ReturnType<typeof useAddPrecoolCommandsMutation>;
export type AddPrecoolCommandsMutationResult = ApolloReactCommon.MutationResult<AddPrecoolCommandsMutation>;
export type AddPrecoolCommandsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPrecoolCommandsMutation,
  AddPrecoolCommandsMutationVariables
>;
export const InitClickupExportProgressDocument = gql`
  mutation initClickupExportProgress($locationId: ID!, $clickupListId: String!) {
    initClickupExportProgress(locationId: $locationId, clickupListId: $clickupListId)
  }
`;
export type InitClickupExportProgressMutationFn = ApolloReactCommon.MutationFunction<
  InitClickupExportProgressMutation,
  InitClickupExportProgressMutationVariables
>;

/**
 * __useInitClickupExportProgressMutation__
 *
 * To run a mutation, you first call `useInitClickupExportProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitClickupExportProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initClickupExportProgressMutation, { data, loading, error }] = useInitClickupExportProgressMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      clickupListId: // value for 'clickupListId'
 *   },
 * });
 */
export function useInitClickupExportProgressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    InitClickupExportProgressMutation,
    InitClickupExportProgressMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<InitClickupExportProgressMutation, InitClickupExportProgressMutationVariables>(
    InitClickupExportProgressDocument,
    baseOptions
  );
}
export type InitClickupExportProgressMutationHookResult = ReturnType<typeof useInitClickupExportProgressMutation>;
export type InitClickupExportProgressMutationResult = ApolloReactCommon.MutationResult<InitClickupExportProgressMutation>;
export type InitClickupExportProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InitClickupExportProgressMutation,
  InitClickupExportProgressMutationVariables
>;
export const RecordNewNodeLifeCycleEventDocument = gql`
  mutation recordNewNodeLifeCycleEvent(
    $nodeMacId: String!
    $eventType: Int!
    $comment: String
    $nodeTypeCode: String
    $creationDate: String
  ) {
    createNodeLifeCycleEvent(
      nodeMacId: $nodeMacId
      eventType: $eventType
      comment: $comment
      nodeTypeCode: $nodeTypeCode
      creationDate: $creationDate
    ) {
      id
      nodeMacId
      eventType
      creationDate
      comment
    }
  }
`;
export type RecordNewNodeLifeCycleEventMutationFn = ApolloReactCommon.MutationFunction<
  RecordNewNodeLifeCycleEventMutation,
  RecordNewNodeLifeCycleEventMutationVariables
>;

/**
 * __useRecordNewNodeLifeCycleEventMutation__
 *
 * To run a mutation, you first call `useRecordNewNodeLifeCycleEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordNewNodeLifeCycleEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordNewNodeLifeCycleEventMutation, { data, loading, error }] = useRecordNewNodeLifeCycleEventMutation({
 *   variables: {
 *      nodeMacId: // value for 'nodeMacId'
 *      eventType: // value for 'eventType'
 *      comment: // value for 'comment'
 *      nodeTypeCode: // value for 'nodeTypeCode'
 *      creationDate: // value for 'creationDate'
 *   },
 * });
 */
export function useRecordNewNodeLifeCycleEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RecordNewNodeLifeCycleEventMutation,
    RecordNewNodeLifeCycleEventMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RecordNewNodeLifeCycleEventMutation,
    RecordNewNodeLifeCycleEventMutationVariables
  >(RecordNewNodeLifeCycleEventDocument, baseOptions);
}
export type RecordNewNodeLifeCycleEventMutationHookResult = ReturnType<typeof useRecordNewNodeLifeCycleEventMutation>;
export type RecordNewNodeLifeCycleEventMutationResult = ApolloReactCommon.MutationResult<RecordNewNodeLifeCycleEventMutation>;
export type RecordNewNodeLifeCycleEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RecordNewNodeLifeCycleEventMutation,
  RecordNewNodeLifeCycleEventMutationVariables
>;
export const EditGroupOfKeysDocument = gql`
  mutation editGroupOfKeys($keyIds: [String!], $groupId: String) {
    editGroupOfKeys(keyIds: $keyIds, groupId: $groupId)
  }
`;
export type EditGroupOfKeysMutationFn = ApolloReactCommon.MutationFunction<
  EditGroupOfKeysMutation,
  EditGroupOfKeysMutationVariables
>;

/**
 * __useEditGroupOfKeysMutation__
 *
 * To run a mutation, you first call `useEditGroupOfKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupOfKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupOfKeysMutation, { data, loading, error }] = useEditGroupOfKeysMutation({
 *   variables: {
 *      keyIds: // value for 'keyIds'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useEditGroupOfKeysMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<EditGroupOfKeysMutation, EditGroupOfKeysMutationVariables>
) {
  return ApolloReactHooks.useMutation<EditGroupOfKeysMutation, EditGroupOfKeysMutationVariables>(
    EditGroupOfKeysDocument,
    baseOptions
  );
}
export type EditGroupOfKeysMutationHookResult = ReturnType<typeof useEditGroupOfKeysMutation>;
export type EditGroupOfKeysMutationResult = ApolloReactCommon.MutationResult<EditGroupOfKeysMutation>;
export type EditGroupOfKeysMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditGroupOfKeysMutation,
  EditGroupOfKeysMutationVariables
>;
export const UpdateGroupDocument = gql`
  mutation updateGroup($groupId: ID!, $groupName: String!, $groupType: String!) {
    updateGroup(groupId: $groupId, groupName: $groupName, groupType: $groupType)
  }
`;
export type UpdateGroupMutationFn = ApolloReactCommon.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      groupName: // value for 'groupName'
 *      groupType: // value for 'groupType'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    baseOptions
  );
}
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = ApolloReactCommon.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const StartTestingDocument = gql`
  mutation startTesting(
    $keyPositionId: ID!
    $positionFunction: String!
    $resumePreviousSession: Boolean
    $isMeterPosition: Boolean
  ) {
    startTesting(
      keyPositionId: $keyPositionId
      positionFunction: $positionFunction
      resumePreviousSession: $resumePreviousSession
      isMeterPosition: $isMeterPosition
    )
  }
`;
export type StartTestingMutationFn = ApolloReactCommon.MutationFunction<
  StartTestingMutation,
  StartTestingMutationVariables
>;

/**
 * __useStartTestingMutation__
 *
 * To run a mutation, you first call `useStartTestingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTestingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTestingMutation, { data, loading, error }] = useStartTestingMutation({
 *   variables: {
 *      keyPositionId: // value for 'keyPositionId'
 *      positionFunction: // value for 'positionFunction'
 *      resumePreviousSession: // value for 'resumePreviousSession'
 *      isMeterPosition: // value for 'isMeterPosition'
 *   },
 * });
 */
export function useStartTestingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<StartTestingMutation, StartTestingMutationVariables>
) {
  return ApolloReactHooks.useMutation<StartTestingMutation, StartTestingMutationVariables>(
    StartTestingDocument,
    baseOptions
  );
}
export type StartTestingMutationHookResult = ReturnType<typeof useStartTestingMutation>;
export type StartTestingMutationResult = ApolloReactCommon.MutationResult<StartTestingMutation>;
export type StartTestingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StartTestingMutation,
  StartTestingMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation deleteGroup($groupId: String!) {
    deleteGroup(groupId: $groupId)
  }
`;
export type DeleteGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(
    DeleteGroupDocument,
    baseOptions
  );
}
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = ApolloReactCommon.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const MarkKeyAsDndDocument = gql`
  mutation markKeyAsDND($keyPositionId: ID!, $comment: String!, $isMeterPosition: Boolean) {
    keyEntry: markKeyAsDnD(keyPositionId: $keyPositionId, comment: $comment, isMeterPosition: $isMeterPosition) {
      keyPositionId
      startedAt
      endedAt
    }
  }
`;
export type MarkKeyAsDndMutationFn = ApolloReactCommon.MutationFunction<
  MarkKeyAsDndMutation,
  MarkKeyAsDndMutationVariables
>;

/**
 * __useMarkKeyAsDndMutation__
 *
 * To run a mutation, you first call `useMarkKeyAsDndMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkKeyAsDndMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markKeyAsDndMutation, { data, loading, error }] = useMarkKeyAsDndMutation({
 *   variables: {
 *      keyPositionId: // value for 'keyPositionId'
 *      comment: // value for 'comment'
 *      isMeterPosition: // value for 'isMeterPosition'
 *   },
 * });
 */
export function useMarkKeyAsDndMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<MarkKeyAsDndMutation, MarkKeyAsDndMutationVariables>
) {
  return ApolloReactHooks.useMutation<MarkKeyAsDndMutation, MarkKeyAsDndMutationVariables>(
    MarkKeyAsDndDocument,
    baseOptions
  );
}
export type MarkKeyAsDndMutationHookResult = ReturnType<typeof useMarkKeyAsDndMutation>;
export type MarkKeyAsDndMutationResult = ApolloReactCommon.MutationResult<MarkKeyAsDndMutation>;
export type MarkKeyAsDndMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkKeyAsDndMutation,
  MarkKeyAsDndMutationVariables
>;
export const EditGroupOfGatewaysDocument = gql`
  mutation editGroupOfGateways($gatewayIds: [String!], $groupId: String) {
    editGroupOfGateways(gatewayIds: $gatewayIds, groupId: $groupId)
  }
`;
export type EditGroupOfGatewaysMutationFn = ApolloReactCommon.MutationFunction<
  EditGroupOfGatewaysMutation,
  EditGroupOfGatewaysMutationVariables
>;

/**
 * __useEditGroupOfGatewaysMutation__
 *
 * To run a mutation, you first call `useEditGroupOfGatewaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupOfGatewaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupOfGatewaysMutation, { data, loading, error }] = useEditGroupOfGatewaysMutation({
 *   variables: {
 *      gatewayIds: // value for 'gatewayIds'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useEditGroupOfGatewaysMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<EditGroupOfGatewaysMutation, EditGroupOfGatewaysMutationVariables>
) {
  return ApolloReactHooks.useMutation<EditGroupOfGatewaysMutation, EditGroupOfGatewaysMutationVariables>(
    EditGroupOfGatewaysDocument,
    baseOptions
  );
}
export type EditGroupOfGatewaysMutationHookResult = ReturnType<typeof useEditGroupOfGatewaysMutation>;
export type EditGroupOfGatewaysMutationResult = ApolloReactCommon.MutationResult<EditGroupOfGatewaysMutation>;
export type EditGroupOfGatewaysMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditGroupOfGatewaysMutation,
  EditGroupOfGatewaysMutationVariables
>;
export const ValidateClickupListIdDocument = gql`
  query validateClickupListId($clickupListId: String!) {
    validateClickupListId(clickupListId: $clickupListId)
  }
`;

/**
 * __useValidateClickupListIdQuery__
 *
 * To run a query within a React component, call `useValidateClickupListIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateClickupListIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateClickupListIdQuery({
 *   variables: {
 *      clickupListId: // value for 'clickupListId'
 *   },
 * });
 */
export function useValidateClickupListIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ValidateClickupListIdQuery, ValidateClickupListIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<ValidateClickupListIdQuery, ValidateClickupListIdQueryVariables>(
    ValidateClickupListIdDocument,
    baseOptions
  );
}
export function useValidateClickupListIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidateClickupListIdQuery, ValidateClickupListIdQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ValidateClickupListIdQuery, ValidateClickupListIdQueryVariables>(
    ValidateClickupListIdDocument,
    baseOptions
  );
}
export type ValidateClickupListIdQueryHookResult = ReturnType<typeof useValidateClickupListIdQuery>;
export type ValidateClickupListIdLazyQueryHookResult = ReturnType<typeof useValidateClickupListIdLazyQuery>;
export type ValidateClickupListIdQueryResult = ApolloReactCommon.QueryResult<
  ValidateClickupListIdQuery,
  ValidateClickupListIdQueryVariables
>;
export const UpdateSlotTasksToPendingDocument = gql`
  mutation updateSlotTasksToPending($roomId: ID!) {
    updateSlotTasksToPending(roomId: $roomId)
  }
`;
export type UpdateSlotTasksToPendingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSlotTasksToPendingMutation,
  UpdateSlotTasksToPendingMutationVariables
>;

/**
 * __useUpdateSlotTasksToPendingMutation__
 *
 * To run a mutation, you first call `useUpdateSlotTasksToPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSlotTasksToPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSlotTasksToPendingMutation, { data, loading, error }] = useUpdateSlotTasksToPendingMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useUpdateSlotTasksToPendingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSlotTasksToPendingMutation,
    UpdateSlotTasksToPendingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateSlotTasksToPendingMutation, UpdateSlotTasksToPendingMutationVariables>(
    UpdateSlotTasksToPendingDocument,
    baseOptions
  );
}
export type UpdateSlotTasksToPendingMutationHookResult = ReturnType<typeof useUpdateSlotTasksToPendingMutation>;
export type UpdateSlotTasksToPendingMutationResult = ApolloReactCommon.MutationResult<UpdateSlotTasksToPendingMutation>;
export type UpdateSlotTasksToPendingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSlotTasksToPendingMutation,
  UpdateSlotTasksToPendingMutationVariables
>;
export const BulkAssignCompressorPositionsDocument = gql`
  mutation bulkAssignCompressorPositions($compressorIds: [String!], $positionId: String) {
    bulkAssignCompressorPositions(compressorIds: $compressorIds, positionId: $positionId)
  }
`;
export type BulkAssignCompressorPositionsMutationFn = ApolloReactCommon.MutationFunction<
  BulkAssignCompressorPositionsMutation,
  BulkAssignCompressorPositionsMutationVariables
>;

/**
 * __useBulkAssignCompressorPositionsMutation__
 *
 * To run a mutation, you first call `useBulkAssignCompressorPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAssignCompressorPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAssignCompressorPositionsMutation, { data, loading, error }] = useBulkAssignCompressorPositionsMutation({
 *   variables: {
 *      compressorIds: // value for 'compressorIds'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useBulkAssignCompressorPositionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkAssignCompressorPositionsMutation,
    BulkAssignCompressorPositionsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BulkAssignCompressorPositionsMutation,
    BulkAssignCompressorPositionsMutationVariables
  >(BulkAssignCompressorPositionsDocument, baseOptions);
}
export type BulkAssignCompressorPositionsMutationHookResult = ReturnType<
  typeof useBulkAssignCompressorPositionsMutation
>;
export type BulkAssignCompressorPositionsMutationResult = ApolloReactCommon.MutationResult<BulkAssignCompressorPositionsMutation>;
export type BulkAssignCompressorPositionsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BulkAssignCompressorPositionsMutation,
  BulkAssignCompressorPositionsMutationVariables
>;
export const ActivateComfortPlusDocument = gql`
  mutation activateComfortPlus($input: ActivateComfortPlusInput!) {
    activateComfortPlus(input: $input)
  }
`;
export type ActivateComfortPlusMutationFn = ApolloReactCommon.MutationFunction<
  ActivateComfortPlusMutation,
  ActivateComfortPlusMutationVariables
>;

/**
 * __useActivateComfortPlusMutation__
 *
 * To run a mutation, you first call `useActivateComfortPlusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateComfortPlusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateComfortPlusMutation, { data, loading, error }] = useActivateComfortPlusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateComfortPlusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateComfortPlusMutation, ActivateComfortPlusMutationVariables>
) {
  return ApolloReactHooks.useMutation<ActivateComfortPlusMutation, ActivateComfortPlusMutationVariables>(
    ActivateComfortPlusDocument,
    baseOptions
  );
}
export type ActivateComfortPlusMutationHookResult = ReturnType<typeof useActivateComfortPlusMutation>;
export type ActivateComfortPlusMutationResult = ApolloReactCommon.MutationResult<ActivateComfortPlusMutation>;
export type ActivateComfortPlusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ActivateComfortPlusMutation,
  ActivateComfortPlusMutationVariables
>;
export const EditComfortPlusDetailDocument = gql`
  mutation editComfortPlusDetail(
    $reservationId: Int!
    $checkInAt: String
    $checkOutAt: String
    $addedEmails: [String!]
  ) {
    editComfortPlusDetail(
      reservationId: $reservationId
      checkInAt: $checkInAt
      checkOutAt: $checkOutAt
      addedEmails: $addedEmails
    )
  }
`;
export type EditComfortPlusDetailMutationFn = ApolloReactCommon.MutationFunction<
  EditComfortPlusDetailMutation,
  EditComfortPlusDetailMutationVariables
>;

/**
 * __useEditComfortPlusDetailMutation__
 *
 * To run a mutation, you first call `useEditComfortPlusDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditComfortPlusDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editComfortPlusDetailMutation, { data, loading, error }] = useEditComfortPlusDetailMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      checkInAt: // value for 'checkInAt'
 *      checkOutAt: // value for 'checkOutAt'
 *      addedEmails: // value for 'addedEmails'
 *   },
 * });
 */
export function useEditComfortPlusDetailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditComfortPlusDetailMutation,
    EditComfortPlusDetailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<EditComfortPlusDetailMutation, EditComfortPlusDetailMutationVariables>(
    EditComfortPlusDetailDocument,
    baseOptions
  );
}
export type EditComfortPlusDetailMutationHookResult = ReturnType<typeof useEditComfortPlusDetailMutation>;
export type EditComfortPlusDetailMutationResult = ApolloReactCommon.MutationResult<EditComfortPlusDetailMutation>;
export type EditComfortPlusDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditComfortPlusDetailMutation,
  EditComfortPlusDetailMutationVariables
>;
export const DeactivateComfortPlusDocument = gql`
  mutation deactivateComfortPlus($reservationId: Int!, $comment: String!) {
    deactivateComfortPlus(reservationId: $reservationId, comment: $comment)
  }
`;
export type DeactivateComfortPlusMutationFn = ApolloReactCommon.MutationFunction<
  DeactivateComfortPlusMutation,
  DeactivateComfortPlusMutationVariables
>;

/**
 * __useDeactivateComfortPlusMutation__
 *
 * To run a mutation, you first call `useDeactivateComfortPlusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateComfortPlusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateComfortPlusMutation, { data, loading, error }] = useDeactivateComfortPlusMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useDeactivateComfortPlusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeactivateComfortPlusMutation,
    DeactivateComfortPlusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeactivateComfortPlusMutation, DeactivateComfortPlusMutationVariables>(
    DeactivateComfortPlusDocument,
    baseOptions
  );
}
export type DeactivateComfortPlusMutationHookResult = ReturnType<typeof useDeactivateComfortPlusMutation>;
export type DeactivateComfortPlusMutationResult = ApolloReactCommon.MutationResult<DeactivateComfortPlusMutation>;
export type DeactivateComfortPlusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeactivateComfortPlusMutation,
  DeactivateComfortPlusMutationVariables
>;
export const SaveGuestPrecoolScheduleDocument = gql`
  mutation saveGuestPrecoolSchedule($input: GuestPrecoolScheduleInput!) {
    saveGuestPrecoolSchedule(input: $input)
  }
`;
export type SaveGuestPrecoolScheduleMutationFn = ApolloReactCommon.MutationFunction<
  SaveGuestPrecoolScheduleMutation,
  SaveGuestPrecoolScheduleMutationVariables
>;

/**
 * __useSaveGuestPrecoolScheduleMutation__
 *
 * To run a mutation, you first call `useSaveGuestPrecoolScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGuestPrecoolScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGuestPrecoolScheduleMutation, { data, loading, error }] = useSaveGuestPrecoolScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveGuestPrecoolScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveGuestPrecoolScheduleMutation,
    SaveGuestPrecoolScheduleMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SaveGuestPrecoolScheduleMutation, SaveGuestPrecoolScheduleMutationVariables>(
    SaveGuestPrecoolScheduleDocument,
    baseOptions
  );
}
export type SaveGuestPrecoolScheduleMutationHookResult = ReturnType<typeof useSaveGuestPrecoolScheduleMutation>;
export type SaveGuestPrecoolScheduleMutationResult = ApolloReactCommon.MutationResult<SaveGuestPrecoolScheduleMutation>;
export type SaveGuestPrecoolScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveGuestPrecoolScheduleMutation,
  SaveGuestPrecoolScheduleMutationVariables
>;
export const DeleteGuestPrecoolScheduleDocument = gql`
  mutation deleteGuestPrecoolSchedule($scheduleId: Int!) {
    deleteGuestPrecoolSchedule(scheduleId: $scheduleId)
  }
`;
export type DeleteGuestPrecoolScheduleMutationFn = ApolloReactCommon.MutationFunction<
  DeleteGuestPrecoolScheduleMutation,
  DeleteGuestPrecoolScheduleMutationVariables
>;

/**
 * __useDeleteGuestPrecoolScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteGuestPrecoolScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGuestPrecoolScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGuestPrecoolScheduleMutation, { data, loading, error }] = useDeleteGuestPrecoolScheduleMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useDeleteGuestPrecoolScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteGuestPrecoolScheduleMutation,
    DeleteGuestPrecoolScheduleMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteGuestPrecoolScheduleMutation, DeleteGuestPrecoolScheduleMutationVariables>(
    DeleteGuestPrecoolScheduleDocument,
    baseOptions
  );
}
export type DeleteGuestPrecoolScheduleMutationHookResult = ReturnType<typeof useDeleteGuestPrecoolScheduleMutation>;
export type DeleteGuestPrecoolScheduleMutationResult = ApolloReactCommon.MutationResult<DeleteGuestPrecoolScheduleMutation>;
export type DeleteGuestPrecoolScheduleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteGuestPrecoolScheduleMutation,
  DeleteGuestPrecoolScheduleMutationVariables
>;
export const UpdateLocationGatewayHardwareTypeDocument = gql`
  mutation updateLocationGatewayHardwareType($locationId: ID!, $type: GatewayHardwareType!) {
    updateLocationGatewayHardwareType(locationId: $locationId, type: $type)
  }
`;
export type UpdateLocationGatewayHardwareTypeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLocationGatewayHardwareTypeMutation,
  UpdateLocationGatewayHardwareTypeMutationVariables
>;

/**
 * __useUpdateLocationGatewayHardwareTypeMutation__
 *
 * To run a mutation, you first call `useUpdateLocationGatewayHardwareTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationGatewayHardwareTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationGatewayHardwareTypeMutation, { data, loading, error }] = useUpdateLocationGatewayHardwareTypeMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateLocationGatewayHardwareTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLocationGatewayHardwareTypeMutation,
    UpdateLocationGatewayHardwareTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateLocationGatewayHardwareTypeMutation,
    UpdateLocationGatewayHardwareTypeMutationVariables
  >(UpdateLocationGatewayHardwareTypeDocument, baseOptions);
}
export type UpdateLocationGatewayHardwareTypeMutationHookResult = ReturnType<
  typeof useUpdateLocationGatewayHardwareTypeMutation
>;
export type UpdateLocationGatewayHardwareTypeMutationResult = ApolloReactCommon.MutationResult<UpdateLocationGatewayHardwareTypeMutation>;
export type UpdateLocationGatewayHardwareTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLocationGatewayHardwareTypeMutation,
  UpdateLocationGatewayHardwareTypeMutationVariables
>;
export const UpdateLocationGatewayPromiscuousModeDocument = gql`
  mutation updateLocationGatewayPromiscuousMode($locationId: ID!, $promiscuousModeEnabled: Boolean!) {
    updateLocationGatewayPromiscuousMode(locationId: $locationId, promiscuousModeEnabled: $promiscuousModeEnabled)
  }
`;
export type UpdateLocationGatewayPromiscuousModeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLocationGatewayPromiscuousModeMutation,
  UpdateLocationGatewayPromiscuousModeMutationVariables
>;

/**
 * __useUpdateLocationGatewayPromiscuousModeMutation__
 *
 * To run a mutation, you first call `useUpdateLocationGatewayPromiscuousModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationGatewayPromiscuousModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationGatewayPromiscuousModeMutation, { data, loading, error }] = useUpdateLocationGatewayPromiscuousModeMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      promiscuousModeEnabled: // value for 'promiscuousModeEnabled'
 *   },
 * });
 */
export function useUpdateLocationGatewayPromiscuousModeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLocationGatewayPromiscuousModeMutation,
    UpdateLocationGatewayPromiscuousModeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateLocationGatewayPromiscuousModeMutation,
    UpdateLocationGatewayPromiscuousModeMutationVariables
  >(UpdateLocationGatewayPromiscuousModeDocument, baseOptions);
}
export type UpdateLocationGatewayPromiscuousModeMutationHookResult = ReturnType<
  typeof useUpdateLocationGatewayPromiscuousModeMutation
>;
export type UpdateLocationGatewayPromiscuousModeMutationResult = ApolloReactCommon.MutationResult<UpdateLocationGatewayPromiscuousModeMutation>;
export type UpdateLocationGatewayPromiscuousModeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLocationGatewayPromiscuousModeMutation,
  UpdateLocationGatewayPromiscuousModeMutationVariables
>;
export const UpdateLocationSetEnableNoKeyCardStateDocument = gql`
  mutation updateLocationSetEnableNoKeyCardState($locationId: ID!, $enableNoKeyCardState: Boolean!) {
    updateLocationSetEnableNoKeyCardState(locationId: $locationId, enableNoKeyCardState: $enableNoKeyCardState)
  }
`;
export type UpdateLocationSetEnableNoKeyCardStateMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLocationSetEnableNoKeyCardStateMutation,
  UpdateLocationSetEnableNoKeyCardStateMutationVariables
>;

/**
 * __useUpdateLocationSetEnableNoKeyCardStateMutation__
 *
 * To run a mutation, you first call `useUpdateLocationSetEnableNoKeyCardStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationSetEnableNoKeyCardStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationSetEnableNoKeyCardStateMutation, { data, loading, error }] = useUpdateLocationSetEnableNoKeyCardStateMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      enableNoKeyCardState: // value for 'enableNoKeyCardState'
 *   },
 * });
 */
export function useUpdateLocationSetEnableNoKeyCardStateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLocationSetEnableNoKeyCardStateMutation,
    UpdateLocationSetEnableNoKeyCardStateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateLocationSetEnableNoKeyCardStateMutation,
    UpdateLocationSetEnableNoKeyCardStateMutationVariables
  >(UpdateLocationSetEnableNoKeyCardStateDocument, baseOptions);
}
export type UpdateLocationSetEnableNoKeyCardStateMutationHookResult = ReturnType<
  typeof useUpdateLocationSetEnableNoKeyCardStateMutation
>;
export type UpdateLocationSetEnableNoKeyCardStateMutationResult = ApolloReactCommon.MutationResult<UpdateLocationSetEnableNoKeyCardStateMutation>;
export type UpdateLocationSetEnableNoKeyCardStateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLocationSetEnableNoKeyCardStateMutation,
  UpdateLocationSetEnableNoKeyCardStateMutationVariables
>;
export const UpdateLocationBeaconSlotManagementDocument = gql`
  mutation updateLocationBeaconSlotManagement($locationId: ID!, $beaconSlotManagementEnabled: Boolean!) {
    updateLocationBeaconSlotManagement(
      locationId: $locationId
      beaconSlotManagementEnabled: $beaconSlotManagementEnabled
    )
  }
`;
export type UpdateLocationBeaconSlotManagementMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLocationBeaconSlotManagementMutation,
  UpdateLocationBeaconSlotManagementMutationVariables
>;

/**
 * __useUpdateLocationBeaconSlotManagementMutation__
 *
 * To run a mutation, you first call `useUpdateLocationBeaconSlotManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationBeaconSlotManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationBeaconSlotManagementMutation, { data, loading, error }] = useUpdateLocationBeaconSlotManagementMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      beaconSlotManagementEnabled: // value for 'beaconSlotManagementEnabled'
 *   },
 * });
 */
export function useUpdateLocationBeaconSlotManagementMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLocationBeaconSlotManagementMutation,
    UpdateLocationBeaconSlotManagementMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateLocationBeaconSlotManagementMutation,
    UpdateLocationBeaconSlotManagementMutationVariables
  >(UpdateLocationBeaconSlotManagementDocument, baseOptions);
}
export type UpdateLocationBeaconSlotManagementMutationHookResult = ReturnType<
  typeof useUpdateLocationBeaconSlotManagementMutation
>;
export type UpdateLocationBeaconSlotManagementMutationResult = ApolloReactCommon.MutationResult<UpdateLocationBeaconSlotManagementMutation>;
export type UpdateLocationBeaconSlotManagementMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLocationBeaconSlotManagementMutation,
  UpdateLocationBeaconSlotManagementMutationVariables
>;
export const LocationEmbeddedAnalyticsInfoDocument = gql`
  query locationEmbeddedAnalyticsInfo($locationId: ID!, $input: LocationEmbeddedAnalyticsInput!) {
    locationEmbeddedAnalyticsInfo(locationId: $locationId, input: $input) {
      token
      url
    }
  }
`;

/**
 * __useLocationEmbeddedAnalyticsInfoQuery__
 *
 * To run a query within a React component, call `useLocationEmbeddedAnalyticsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationEmbeddedAnalyticsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationEmbeddedAnalyticsInfoQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLocationEmbeddedAnalyticsInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationEmbeddedAnalyticsInfoQuery,
    LocationEmbeddedAnalyticsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LocationEmbeddedAnalyticsInfoQuery, LocationEmbeddedAnalyticsInfoQueryVariables>(
    LocationEmbeddedAnalyticsInfoDocument,
    baseOptions
  );
}
export function useLocationEmbeddedAnalyticsInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationEmbeddedAnalyticsInfoQuery,
    LocationEmbeddedAnalyticsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<LocationEmbeddedAnalyticsInfoQuery, LocationEmbeddedAnalyticsInfoQueryVariables>(
    LocationEmbeddedAnalyticsInfoDocument,
    baseOptions
  );
}
export type LocationEmbeddedAnalyticsInfoQueryHookResult = ReturnType<typeof useLocationEmbeddedAnalyticsInfoQuery>;
export type LocationEmbeddedAnalyticsInfoLazyQueryHookResult = ReturnType<
  typeof useLocationEmbeddedAnalyticsInfoLazyQuery
>;
export type LocationEmbeddedAnalyticsInfoQueryResult = ApolloReactCommon.QueryResult<
  LocationEmbeddedAnalyticsInfoQuery,
  LocationEmbeddedAnalyticsInfoQueryVariables
>;
export const OnEnterLiveDataModeConfigDocument = gql`
  mutation onEnterLiveDataModeConfig($positionIds: [ID!]!, $userId: String!) {
    onEnterLiveDataModeConfig(positionIds: $positionIds, userId: $userId)
  }
`;
export type OnEnterLiveDataModeConfigMutationFn = ApolloReactCommon.MutationFunction<
  OnEnterLiveDataModeConfigMutation,
  OnEnterLiveDataModeConfigMutationVariables
>;

/**
 * __useOnEnterLiveDataModeConfigMutation__
 *
 * To run a mutation, you first call `useOnEnterLiveDataModeConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnEnterLiveDataModeConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onEnterLiveDataModeConfigMutation, { data, loading, error }] = useOnEnterLiveDataModeConfigMutation({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnEnterLiveDataModeConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OnEnterLiveDataModeConfigMutation,
    OnEnterLiveDataModeConfigMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<OnEnterLiveDataModeConfigMutation, OnEnterLiveDataModeConfigMutationVariables>(
    OnEnterLiveDataModeConfigDocument,
    baseOptions
  );
}
export type OnEnterLiveDataModeConfigMutationHookResult = ReturnType<typeof useOnEnterLiveDataModeConfigMutation>;
export type OnEnterLiveDataModeConfigMutationResult = ApolloReactCommon.MutationResult<OnEnterLiveDataModeConfigMutation>;
export type OnEnterLiveDataModeConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OnEnterLiveDataModeConfigMutation,
  OnEnterLiveDataModeConfigMutationVariables
>;
export const OnExitLiveDataModeConfigDocument = gql`
  mutation onExitLiveDataModeConfig($positionIds: [ID!]!, $userId: String!) {
    onExitLiveDataModeConfig(positionIds: $positionIds, userId: $userId)
  }
`;
export type OnExitLiveDataModeConfigMutationFn = ApolloReactCommon.MutationFunction<
  OnExitLiveDataModeConfigMutation,
  OnExitLiveDataModeConfigMutationVariables
>;

/**
 * __useOnExitLiveDataModeConfigMutation__
 *
 * To run a mutation, you first call `useOnExitLiveDataModeConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnExitLiveDataModeConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onExitLiveDataModeConfigMutation, { data, loading, error }] = useOnExitLiveDataModeConfigMutation({
 *   variables: {
 *      positionIds: // value for 'positionIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnExitLiveDataModeConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OnExitLiveDataModeConfigMutation,
    OnExitLiveDataModeConfigMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<OnExitLiveDataModeConfigMutation, OnExitLiveDataModeConfigMutationVariables>(
    OnExitLiveDataModeConfigDocument,
    baseOptions
  );
}
export type OnExitLiveDataModeConfigMutationHookResult = ReturnType<typeof useOnExitLiveDataModeConfigMutation>;
export type OnExitLiveDataModeConfigMutationResult = ApolloReactCommon.MutationResult<OnExitLiveDataModeConfigMutation>;
export type OnExitLiveDataModeConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OnExitLiveDataModeConfigMutation,
  OnExitLiveDataModeConfigMutationVariables
>;
export const GetHolisticsTokenDocument = gql`
  query getHolisticsToken($embedId: ID!, $locationId: ID!, $filters: String) {
    getHolisticsToken(embedId: $embedId, locationId: $locationId, filters: $filters)
  }
`;

/**
 * __useGetHolisticsTokenQuery__
 *
 * To run a query within a React component, call `useGetHolisticsTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolisticsTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolisticsTokenQuery({
 *   variables: {
 *      embedId: // value for 'embedId'
 *      locationId: // value for 'locationId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetHolisticsTokenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetHolisticsTokenQuery, GetHolisticsTokenQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetHolisticsTokenQuery, GetHolisticsTokenQueryVariables>(
    GetHolisticsTokenDocument,
    baseOptions
  );
}
export function useGetHolisticsTokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHolisticsTokenQuery, GetHolisticsTokenQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetHolisticsTokenQuery, GetHolisticsTokenQueryVariables>(
    GetHolisticsTokenDocument,
    baseOptions
  );
}
export type GetHolisticsTokenQueryHookResult = ReturnType<typeof useGetHolisticsTokenQuery>;
export type GetHolisticsTokenLazyQueryHookResult = ReturnType<typeof useGetHolisticsTokenLazyQuery>;
export type GetHolisticsTokenQueryResult = ApolloReactCommon.QueryResult<
  GetHolisticsTokenQuery,
  GetHolisticsTokenQueryVariables
>;
